<template>
	<div class="box d-flex flex-column" style="min-height: 360px" id="dashboard-qc-results-box">
		<div v-if="qcType == 0" class="flex d-flex flex-row align-items-center justify-content-center p-5">
			<h1 class="text-muted text-center">{{ $t("Dashboard.no_qc_access") }}</h1>
		</div>
		<div v-if="qcType != 0" class="b-b nav-active-bg">
			<ul class="nav nav-tabs">
				<li v-if="user.role.page_dashboard_val" class="nav-item">
					<a class="nav-link" @click="setQCType(3)" :class="{ active: qcType == 3, show: qcType == 3 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.validity") }}</span>
							<span class="d-xl-none">Val</span>
						</div>
					</a>
				</li>
				<li v-if="user.role.page_dashboard_practice" class="nav-item">
					<a class="nav-link" @click="setQCType(1)" :class="{ active: qcType == 1, show: qcType == 1 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.practice") }}</span>
							<span class="d-xl-none">{{ $t("Dashboard.tra") }}</span>
						</div>
					</a>
				</li>
				<li v-if="user.role.page_dashboard_qual" class="nav-item">
					<a class="nav-link" @click="setQCType(2)" :class="{ active: qcType == 2, show: qcType == 2 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.qualification") }}</span>
							<span class="d-xl-none">{{ $t("Dashboard.qual") }}</span>
						</div>
					</a>
				</li>
				<li v-if="user.role.page_dashboard_cal" class="nav-item">
					<a class="nav-link" @click="setQCType(4)" :class="{ active: qcType == 4, show: qcType == 4 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.calibration") }}</span>
							<span class="d-xl-none">{{ $t("Dashboard.cal") }}</span>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div v-if="qcType != 0" class="tab-content d-flex flex-column px-3 pb-3 pt-1">
			<div class="flex d-flex flex-row">
				<div class="pt-2 d-flex flex-column" style="z-index: 2; min-width: 340px">
					<div class="no-grow mb-2">
						<label>{{ $t("fields.section") }} </label>
						<config-select :options="sections" v-model="selectedSection"></config-select>
						<label class="pt-1">{{ $t("fields.item") }} </label>
						<config-select :options="items" v-model="selectedItem"></config-select>
					</div>

					<dashboard-qc-results-val-comp
						v-if="valSelected"
						:user="user"
						:section="selectedSection"
						:item="selectedItem"
					/>
				</div>

				<dashboard-personal-val-item-stats
					v-if="valSelected"
					class="p-3"
					:user="user"
					:section="selectedSection"
					:item="selectedItem"
				/>
				<dashboard-qc-results-set-view
					v-else
					class="p-3"
					:user="user"
					:section="selectedSection"
					:item="selectedItem"
					:qcType="qcType"
				/>
				<span class="text-muted pt-3">
					<a
						:href="`#/my_qc_portfolio?qc=${qcType}&sect=${selectedSection.id}&item=${selectedItem.id}&sq=true&ss=true&si=true`"
						v-if="selectedSection && selectedItem"
						>{{ $t("Dashboard.view_details") }}</a
					>
				</span>
			</div>
		</div>
	</div>
</template>

<style scoped>
a.btn-subtle-lg i {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a.btn-subtle-lg:hover i {
	opacity: 1;
}
.buttons-position {
	padding: 0.7rem 1rem;
}
th {
	border-top: 0px !important;
}
.exclamation-fix {
	font-size: initial;
	vertical-align: initial;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<script>
import ConfigService from "@/services/ConfigService";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

import { QC_TYPES } from "@/services/Constants";
const { PRACTICE, QUALIFICATION, VALIDITY, CALIBRATION } = QC_TYPES;

import InlineScore from "@/components/InlineScore";
import ConfigSelect from "@/components/ConfigSelect";
import AgreementRow from "@/components/AgreementRow";
import DashboardQcResultsValComp from "./DashboardQcResultsValComp";
import DashboardQcResultsSetView from "./DashboardQcResultsSetView";
import DashboardPersonalValItemStats from "./DashboardPersonalValItemStats";

export default {
	name: "DashboardQCResults",

	components: {
		InlineScore,
		ConfigSelect,
		AgreementRow,
		DashboardQcResultsValComp,
		DashboardQcResultsSetView,
		DashboardPersonalValItemStats,
	},

	props: ["user"],

	data() {
		let qcType = store.getDefault(this, "dash.userqc.qcType", VALIDITY.id);

		const allowedQcTypes = [];
		if (this.user.role.page_dashboard_val) allowedQcTypes.push(VALIDITY.id);
		if (this.user.role.page_dashboard_practice) allowedQcTypes.push(PRACTICE.id);
		if (this.user.role.page_dashboard_qual) allowedQcTypes.push(QUALIFICATION.id);
		if (this.user.role.page_dashboard_cal) allowedQcTypes.push(CALIBRATION.id);

		if (allowedQcTypes.length == 0) {
			qcType = 0;
		} else if (!allowedQcTypes.includes(qcType)) {
			qcType = allowedQcTypes[0];
		}
		store.set(this, "dash.userqc.qcType", qcType);

		return {
			fs: fs,
			qcType: qcType,
			busy: false,

			sections: null,
			selectedSection: null,

			items: null,
			selectedItem: null,

			qcData: null,

			loading: false,
		};
	},
	created() {
		this.loadConfig();
	},
	watch: {
		selectedSection() {
			if (!this.selectedSection) {
				this.selectedItem = null;
				return;
			}

			this.loadItems();

			store.set(this, "dash.userqc.selectedSection", this.selectedSection ? this.selectedSection.id : null);
		},
		selectedItem() {
			if (!this.selectedItem) {
				return;
			}
			store.set(this, "dash.userqc.selectedItem", this.selectedItem ? this.selectedItem.id : null);
		},
	},

	computed: {
		valSelected() {
			return this.qcType === VALIDITY.id;
		},
	},

	methods: {
		loadConfig() {
			this.busy = true;
			ConfigService.listUserSections()
				.then((resp) => {
					this.sections = resp.data;
					this.selectedSection = store.resolveSelectedSection(
						this,
						this.sections,
						"dash.userqc.selectedSection"
					);

					if (this.selectedSection) {
						this.items = this.selectedSection.items;
						this.selectedItem = store.resolveSelectedItem(
							this,
							this.selectedSection,
							"dash.userqc.selectedItem"
						);
					} else if (this.sections.length) {
						this.selectedSection = this.sections[0];
					}
					this.busy = false;
				})
				.catch((err) => {
					notie.error("Failed to load config options", err);
					this.busy = false;
				});
		},

		loadItems() {
			if (!this.selectedSection) {
				return;
			}

			ConfigService.getSection(this.selectedSection.id)
				.then((resp) => {
					this.items = resp.data.items;

					if (!this.selectedItem || !_.find(this.items, { id: this.selectedItem.id })) {
						this.selectedItem = this.items[0];
					}
				})
				.catch((err) => {
					notie.error("Failed to load config options", err);
				});
		},

		setQCType(type) {
			this.qcType = type;
			store.set(this, "dash.userqc.qcType", this.qcType);
		},
	},
};
</script>
