import axios from "axios";
import Router from "@/router";
import AuthService from "@/services/AuthService";
import notie from "@/services/NotieService";

axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response;
	},
	function (error) {
		// Do something with response error
		console.log(error);
		if (error && error.response && error.response.status == 401) {
			error.suppress = true;
			if (location.hash != "#/") {
				AuthService.clearState();
				notie.info("<h4>You have been logged out</h4>");
				Router.push("/");
			}
			return Promise.reject(error);
		}
		return Promise.reject(error);
	}
);
