<template>
	<b-modal v-if="visible" :visible="visible" @hide="hideModal" :size="itemDetails || todEnabled ? 'lg' : 'md'">
		<template slot="modal-title">{{ $t("ItemEdit.IeaItemSetupModal.title") }}</template>
		<div class="row px-3 pt-4 modal-depad" :class="{ 'modal-scroll': itemDetails || todEnabled }">
			<div :class="{ 'col-12': !(itemDetails || todEnabled), 'col-6': itemDetails || todEnabled }">
				<div class="row align-items-center">
					<div class="col-4 mb-3">
						<h5 class="mb-0">{{ $t("ItemEdit.IeaItemSetupModal.item_model") }}</h5>
					</div>
					<div class="col-8 mb-3">
						<config-select
							class="v-select-apply"
							byField="id"
							:options="modeTypes"
							v-model="mode"
							label="mode"
						/>
					</div>

					<div v-if="!todEnabled" class="col-12 form-group">
						<div class="form-group">
							<label>{{ $t("ItemEdit.IeaItemSetupModal.cf_tenant") }}</label>
							<input class="form-control" v-model="tenantID" disabled />
						</div>
						<div>
							<label>{{ $t("ItemEdit.Details.item_model") }}</label>
							<template v-if="!loadingItems">
								<input
									v-if="tenantID == '' || (items && items.length == 0)"
									class="form-control"
									disabled
									:placeholder="tenantID == '' ? '' : 'No items found'"
								/>
								<v-select
									v-else
									class="v-select-apply"
									:options="items"
									v-model="itemDetails"
									label="itemId"
									:placeholder="'Pick an item...'"
								></v-select>
							</template>
							<loading v-else type="input" />
						</div>
						<div v-if="itemDetails" class="card p-3 mt-3">
							<div class="text-muted">Prompt Text:</div>
							{{ itemDetails.promptText }}
						</div>
					</div>
					<div v-else class="col-12 form-group">
						<div class="form-group">
							<label>{{ $t("ItemEdit.IeaItemSetupModal.cf_tenant") }}</label>
							<input class="form-control" v-model="tenantID" disabled />
						</div>

						<div
							class="form-group"
							:class="{ invalid: !(valid.field('todItemId') && valid.field('todItemIdChar')) }"
						>
							<label>{{ $t("ItemEdit.IeaItemSetupModal.item_id") }}</label>
							<input class="form-control" v-model="newItemID" />
						</div>

						<div class="form-group">
							<label>{{ $t("ItemEdit.IeaItemSetupModal.modeling_workflow") }}</label>
							<config-select
								class="v-select-apply"
								:options="modelingWorkflows"
								byField="id"
								sortBy="sequence"
								v-model="modelingWorkflow"
							></config-select>
						</div>

						<div class="form-group">
							<label>{{ $t("ItemEdit.Scoring.rubric") }}</label>
							<v-select
								class="v-select-apply"
								:options="rubrics"
								v-model="rubric"
								label="name"
								:disabled="item.isScored"
							></v-select>
							<small v-if="item.isScored" class="text-muted"
								><i class="fas fa-exclamation-triangle mr-1" style="font-size: 14px" />{{
									$t("ItemEdit.IeaItemSetupModal.rubric_cant_be_changed_warning")
								}}</small
							>
							<template v-if="todTraitDefinitionMatch">
								<div
									v-if="todTraitDefinitionMatch.match == 'compatible'"
									class="mt-2 text-xxs text-success"
								>
									<i class="fas fa-check text-success mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.tod_rubric_compatible")
									}}
								</div>
								<div
									v-if="todTraitDefinitionMatch.match == 'incompatible_step'"
									class="mt-2 text-xxs text-danger"
								>
									<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 14px" />{{
										$t(
											"ItemEdit.IeaItemSetupModal.tod_rubric_incompatible_step",
											todTraitDefinitionMatch
										)
									}}
								</div>
								<div
									v-if="todTraitDefinitionMatch.match == 'incompatible_economy_traits'"
									class="mt-2 text-xxs text-danger"
								>
									<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 14px" />{{
										$t(
											"ItemEdit.IeaItemSetupModal.tod_rubric_incompatible_economy_traits",
											todTraitDefinitionMatch
										)
									}}
								</div>
							</template>
						</div>

						<div class="card p-3 my-3">
							<div class="text-muted">Item XML:</div>
							<loading v-if="loadingItemXML" type="page" />
							<template v-else>
								<pre v-if="itemXML" class="mb-0">{{ itemXML }}</pre>
								<div v-else class="text-center text-muted">(None)</div>
							</template>
						</div>

						<div class="form-group">
							<label>{{ $t("ItemEdit.IeaItemSetupModal.subject") }}</label
							><v-select
								class="v-select-apply"
								:options="subjects"
								v-model="subject"
								label="name"
							></v-select>
						</div>
					</div>

					<template v-if="itemDetails">
						<div class="col-12">
							<hr />
						</div>

						<div class="col-12">
							<h5>{{ $t("ItemEdit.Scoring.title") }}</h5>
						</div>
						<div class="col-12 form-group">
							<div>
								<label>{{ $t("ItemEdit.Scoring.rubric") }}</label>
								<config-select
									class="v-select-apply"
									:options="rubrics"
									v-model="rubric"
									:disabled="item.isScored"
									:nullOption="true"
									:nullText="$t('ItemEdit.IeaItemSetupModal.automatically_configured_rubric')"
								></config-select>
								<small v-if="item.isScored" class="text-muted"
									><i class="fas fa-exclamation-triangle mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_cant_be_changed_warning")
									}}</small
								>
							</div>
							<div class="card p-3 mt-3">
								<table>
									<tr>
										<th v-if="rubric">
											<div>{{ $t("ItemEdit.IeaItemSetupModal.rubric_traits") }}:</div>
											<div class="text-muted text-xxs">({{ rubric.name }})</div>
										</th>
										<th>
											<div>{{ $t("ItemEdit.IeaItemSetupModal.cf_traits") }}:</div>
											<div class="text-muted text-xxs">({{ itemDetails.itemId }})</div>
										</th>
									</tr>
									<template v-if="traitDefinitionRows && traitDefinitionRows.length > 0">
										<tr v-for="(row, i) of traitDefinitionRows" :key="i">
											<td v-if="rubric">
												<span
													v-if="row.rubricTD"
													:key="row.rubricTD.ref_id"
													class="ml-1 badge badge-info wrap-badge"
												>
													{{ row.rubricTD.name }}
													<span class="text-muted"
														>({{ row.rubricTD.min }}-{{ row.rubricTD.max }})</span
													>
												</span>
												<div v-else class="text-muted text-xxs ml-1">(None)</div>
											</td>
											<td>
												<span
													v-if="row.cfTD"
													:key="row.cfTD.name"
													class="ml-1 badge badge-info wrap-badge"
												>
													{{ row.cfTD.description }}
													<span class="text-muted"
														>({{ row.cfTD.min }}-{{ row.cfTD.max }})</span
													>
												</span>
												<div v-else class="text-muted text-xxs ml-1">(None)</div>
											</td>
										</tr>
									</template>
									<template v-else>
										<tr>
											<td colspan="2" class="text-muted">
												{{ $t("ItemEdit.IeaItemSetupModal.item_has_no_trait_definitions") }}
											</td>
										</tr>
									</template>
								</table>
							</div>
							<template v-if="traitDefinitionMatch">
								<div v-if="traitDefinitionMatch.match == 'perfect'" class="mt-2 text-xxs text-success">
									<i class="fas fa-check text-success mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_match_perfect")
									}}
								</div>
								<div v-if="traitDefinitionMatch.match == 'partial'" class="mt-2 text-xxs text-warning">
									<i class="fas fa-info text-warning mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_match_partial")
									}}
								</div>
								<div
									v-if="traitDefinitionMatch.match == 'mismatch_traits'"
									class="mt-2 text-xxs text-danger"
								>
									<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_mismatch_traits", traitDefinitionMatch)
									}}
								</div>
								<div
									v-if="traitDefinitionMatch.match == 'mismatch_min'"
									class="mt-2 text-xxs text-danger"
								>
									<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_mismatch_min", traitDefinitionMatch)
									}}
								</div>
								<div
									v-if="traitDefinitionMatch.match == 'mismatch_max'"
									class="mt-2 text-xxs text-danger"
								>
									<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_mismatch_max", traitDefinitionMatch)
									}}
								</div>
								<div
									v-if="traitDefinitionMatch.match == 'no_trait_id'"
									class="mt-2 text-xxs text-danger"
								>
									<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_no_trait_id", traitDefinitionMatch)
									}}
								</div>
								<div
									v-if="traitDefinitionMatch.match == 'mismatch_trait_id'"
									class="mt-2 text-xxs text-danger"
								>
									<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 14px" />{{
										$t("ItemEdit.IeaItemSetupModal.rubric_mismatch_trait_id", traitDefinitionMatch)
									}}
								</div>
							</template>
						</div>

						<template v-if="!conditionsAsScores">
							<div class="col-12">
								<hr />
							</div>

							<div class="col-12">
								<h5>{{ $t("ItemEdit.Integrations.cf.condition_codes") }}</h5>
							</div>
							<div class="col-12 form-group">
								<div class="card p-3 mt-3">
									<table>
										<tr>
											<th>
												<div>{{ $t("ItemEdit.IeaItemSetupModal.flag_codes") }}:</div>
											</th>
											<th>
												<div>{{ $t("ItemEdit.IeaItemSetupModal.cf_codes") }}:</div>
											</th>
										</tr>
										<template v-if="flagCodeRows && flagCodeRows.length > 0">
											<tr v-for="(row, i) of flagCodeRows" :key="i">
												<td>
													<span v-if="row.alert" class="ml-1 badge badge-info wrap-badge">
														{{ row.alert.code }}
														<span class="text-muted">- {{ row.alert.description }}</span>
													</span>
													<div v-else class="text-danger text-xxs ml-1">No Match</div>
												</td>
												<td>
													<span v-if="row.ieaCode" class="ml-1 badge badge-info wrap-badge">
														{{ row.ieaCode }}
													</span>
													<div v-else class="text-muted text-xxs ml-1">-</div>
												</td>
											</tr>
										</template>
										<template v-else>
											<tr>
												<td colspan="2" class="text-muted">
													{{
														$t("ItemEdit.IeaItemSetupModal.tenant_has_no_flags", {
															tenant_ide: tenantID,
														})
													}}
												</td>
											</tr>
										</template>
									</table>
								</div>

								<div v-if="!flagCodesMatched" class="mt-2 text-xxs text-warning">
									<i
										class="fas fa-exclamation-triangle text-warning mr-1"
										style="font-size: 14px"
									/>{{ $t("ItemEdit.IeaItemSetupModal.flag_codes_mismatch") }}
								</div>
							</div>
						</template>
					</template>
				</div>
			</div>

			<div v-if="itemDetails || todEnabled" class="col-6">
				<div class="row shadow" style="margin-top: -8px">
					<div class="col-12" style="margin-top: 1rem">
						<h5>
							{{ $t("ItemEdit.IeaItemSetupModal.integration") }} <i class="fas fa-angle-right" />
							{{ $t("ItemEdit.Integrations.cf.title") }}
						</h5>
					</div>
					<div class="col-12 form-group">
						<label>
							{{ $t("ItemEdit.Integrations.cf.admin_name") }}
							<i
								v-if="anyProjects() && !anyProjectsCanDoIEA()"
								class="text-warning fas fa-exclamation-triangle"
								v-tippy="{ theme: 'popover' }"
								:title="$t('ItemEdit.Integrations.cf.no_iea_projects')"
							/>
							<i
								v-if="!anyProjects()"
								class="text-warning fas fa-exclamation-triangle"
								v-tippy="{ theme: 'popover' }"
								:title="$t('ItemEdit.Integrations.cf.no_projects')"
							/>
						</label>
						<input
							type="text"
							class="form-control"
							:class="{ 'text-force-muted': !anyProjectsCanDoIEA() }"
							disabled
							:value="getAllIeaAdminNames()"
						/>
					</div>
					<div class="col-12 form-group">
						<label>{{ $t("ItemEdit.Integrations.cf.item_id") }}</label>
						<input
							type="text"
							class="form-control"
							:value="itemDetails ? itemDetails.itemId : newItemID"
							disabled
						/>
					</div>
					<div class="col-12 form-group">
						<label
							v-tippy="{ theme: 'popover' }"
							:title="$t('ItemEdit.Integrations.cf.system_user_tooltip')"
						>
							{{ $t("ItemEdit.Integrations.cf.system_user") }}
						</label>
						<config-user-search v-model="cfSystemUser" :clientID="client.id" />
					</div>
					<div class="col-12 form-group">
						<label
							v-tippy="{ theme: 'popover' }"
							:title="$t('ItemEdit.Integrations.cf.catchall_flag_tooltip')"
						>
							{{ $t("ItemEdit.Integrations.cf.catchall_flag") }}
						</label>
						<v-select
							:options="item.avail_alerts"
							v-model="catchallFlagID"
							:reduce="(o) => o.alert_id"
							label="code"
						>
							<template slot="selected-option" slot-scope="option">
								<span>{{ option.description }} ({{ option.code }})</span>
							</template>

							<template slot="option" slot-scope="option">
								<span>{{ option.description }} ({{ option.code }})</span>
							</template>
						</v-select>
					</div>
					<div class="col-8 form-group">
						<label>{{ $t("ItemEdit.Integrations.cf.score_type") }}</label>
						<v-select :options="ieaScoringTypes" label="name" v-model="scoringType" :reduce="(o) => o.id" />
					</div>
					<div class="col-4 form-group">
						<label>{{ $t("ItemEdit.Integrations.cf.percentage") }}</label>
						<percent-input v-model="percent" />
					</div>
					<div class="col-6 form-group">
						<div class="mb-2">
							{{ $t("ItemEdit.Integrations.cf.score_passback") }}
						</div>
						<div>
							<label
								class="ui-switch ui-switch-md theme-accent"
								v-tippy="{ theme: 'popover' }"
								:title="$t('ItemEdit.Integrations.cf.score_passback_tooltip')"
							>
								<input type="checkbox" v-model="scorePassback" />
								<i></i>
							</label>
						</div>
					</div>
					<div class="col-6 form-group">
						<div class="mb-2">
							{{ $t("ItemEdit.Integrations.cf.conditions_as_scores") }}
						</div>
						<div>
							<label
								class="ui-switch ui-switch-md theme-accent"
								v-tippy="{ theme: 'popover' }"
								:title="$t('ItemEdit.Integrations.cf.conditions_as_scores_tooltip')"
							>
								<input type="checkbox" v-model="conditionsAsScores" />
								<i></i>
							</label>
						</div>
					</div>
					<div class="col-12 form-group">
						<div class="mb-2">
							{{ $t("ItemEdit.Integrations.cf.reliability_on_low_conf") }}
						</div>
						<div>
							<label
								class="ui-switch ui-switch-md theme-accent"
								v-tippy="{ theme: 'popover' }"
								:title="$t('ItemEdit.Integrations.cf.reliability_on_low_conf_tooltip')"
							>
								<input type="checkbox" v-model="reliabilityOnLowConf" />
								<i></i>
							</label>
						</div>
					</div>
					<div class="col-12">
						<hr class="mt-0" />
					</div>
					<div class="col-12 d-flex flex-row">
						<div>
							<div class="form-group">
								{{ $t("ItemEdit.Integrations.cf.flag_lid") }}
							</div>
							<div class="form-group">
								<label
									class="ui-switch ui-switch-md theme-accent"
									v-tippy="{ theme: 'popover' }"
									:title="$t('ItemEdit.Integrations.cf.flag_lid_tooltip')"
								>
									<input type="checkbox" v-model="flagLid" />
									<i></i>
								</label>
							</div>
						</div>
						<div v-if="flagLid" class="flex ml-4">
							<label>
								{{ $t("ItemEdit.Integrations.cf.lid_alert_flag") }}
							</label>
							<v-select
								:options="item.avail_alerts"
								v-model="lidFlagId"
								:reduce="(o) => o.alert_id"
								label="code"
							>
								<template slot="selected-option" slot-scope="option">
									<span>{{ option.description }} ({{ option.code }})</span>
								</template>

								<template slot="option" slot-scope="option">
									<span>{{ option.description }} ({{ option.code }})</span>
								</template>
							</v-select>
						</div>
					</div>
					<div v-if="flagLid" class="col-12">
						<div class="form-group">
							{{ $t("ItemEdit.Integrations.cf.ignore_score") }}
						</div>
						<div class="form-group">
							<label
								class="ui-switch ui-switch-md theme-accent"
								v-tippy="{ theme: 'popover' }"
								:title="$t('ItemEdit.Integrations.cf.ignore_score_tooltip')"
							>
								<input type="checkbox" v-model="ignoreScore" />
								<i></i>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template slot="modal-footer">
			<button class="btn btn-secondary btn-flat position" @click="cancel">{{ $t("buttons.cancel") }}</button>
			<button v-if="!valid.group('overall')" class="btn btn-primary btn-flat position-relative" disabled>
				{{ $t("buttons.ok") }}
				<div id="saveMouseover" class="disabled-mouseover"></div>
			</button>
			<button v-else class="btn btn-primary btn-flat" @click="applySettings">
				{{ $t("buttons.ok") }}
			</button>

			<b-popover target="saveMouseover" triggers="hover" placement="left" v-if="!valid.group('overall')">
				<template v-if="!itemDetails" slot="title">Before you can continue:</template>
				<template v-else slot="title">Before you can save:</template>
				<ul class="pl-4 mb-0">
					<li v-for="(error, index) in valid.groupErrors('overall')" :key="index">{{ error }}</li>
				</ul>
			</b-popover>
		</template>
	</b-modal>
</template>

<style scoped>
.wrap-badge {
	white-space: initial;
	text-align: left;
}

.disabled-mouseover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
}
</style>

<script>
import ItemService from "@/services/ItemService";
import IeaService from "@/services/IEAService";
import ValidationService from "@/services/ValidationService";
import RubricService from "@/services/RubricService";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import PercentInput from "@/components/PercentInput";

import Utils from "@/services/Utils";
import fs from "@/services/FormatService";

import moment from "moment";
import Notie from "@/services/NotieService";

export default {
	name: "IeaItemSetupModal",
	props: ["client", "item", "rubrics", "projects", "visible"],

	components: {
		ConfigUserSearch,
		ConfigSelect,
		ConfigDate,
		PercentInput,
	},

	data() {
		let modeTypes = [
			{
				id: "pretrained",
				name: this.$i18n.t("ItemEdit.IeaItemSetupModal.pretrained"),
				desc: this.$i18n.t("ItemEdit.IeaItemSetupModal.pretrained_details"),
			},
			{
				id: "tod",
				name: this.$i18n.t("ItemEdit.IeaItemSetupModal.tod"),
				desc: this.$i18n.t("ItemEdit.IeaItemSetupModal.tod_details"),
			},
			{
				id: "rangefinding",
				name: this.$i18n.t("ItemEdit.IeaItemSetupModal.rangefinding"),
				desc: this.$i18n.t("ItemEdit.IeaItemSetupModal.rangefinding_details"),
			},
		];

		return {
			ieaScoringTypes: fs.ieaScoringTypes(),

			valid: {},
			tenantID: "",
			newItemID: "",
			loadingItems: false,
			items: [],
			itemDetails: null,
			conditionCodes: [],
			admins: null,
			savingAdmin: false,

			rubric: this.item.rubric,
			modelingWorkflows: fs.ieaModelingWorkflows(),
			modelingWorkflow: "economy",
			modeTypes: modeTypes,
			mode: "pretrained",
			adminName: null,
			admin: {
				startDate: moment().format(),
				endDate: moment().add(1, "year").format(),
			},
			cfSystemUser: null,
			catchallFlagID: null,
			flagLid: false,
			lidFlagId: null,
			ignoreScore: false,
			scoringType: "FIRST",
			percent: 100,
			scorePassback: true,
			conditionsAsScores: false,
			reliabilityOnLowConf: false,

			loadingItemXML: false,
			itemXML: "",
			subjects: fs.subjectTypes(),
			subject: null,
		};
	},
	created() {
		this.loadItemsDebounced = _.debounce(() => {
			this.loadItems();
		}, 500);
		this.loadConditionCodesDebounced = _.debounce(() => {
			this.loadConditionCodes();
		}, 500);
		this.loadAdminsDebounced = _.debounce(() => {
			this.loadAdmins();
		});

		this.initValidation();
	},
	watch: {
		visible() {
			if (this.visible) {
				this.initializeModal();
			}
		},

		tenantID() {
			if (this.tenantID == "") {
				this.loadingItems = false;
				this.items = [];
				this.loadingItems = false;
				this.conditionCodes = [];
			} else {
				this.loadingItems = true;
				this.loadItemsDebounced();
				this.loadingCodes = true;
				this.loadConditionCodesDebounced();
				this.loadingAdmins = true;
				this.loadAdminsDebounced();
			}
		},

		adminName() {
			if (!this.adminName) {
				this.admin = {
					startDate: moment().format(),
					endDate: moment().add(1, "year").format(),
				};
			} else {
				let admin = _.find(this.admins, { projectId: this.adminName });
				if (admin) {
					this.admin = admin;
				}
			}
		},
	},
	computed: {
		todEnabled() {
			return this.mode == "tod" || this.mode == "rangefinding";
		},

		canApplySettings() {
			if (!this.todEnabled && !(this.itemDetails && this.itemDetails.itemId)) {
				return false;
			}
			if (this.todEnabled && !this.newItemID) {
				return false;
			}

			if (!this.catchallFlagID) {
				return false;
			}

			if (this.rubric) {
				if (
					!this.todEnabled &&
					!(
						this.traitDefinitionMatch &&
						(this.traitDefinitionMatch.match == "perfect" || this.traitDefinitionMatch.match == "partial")
					)
				) {
					return false;
				}

				if (
					this.todEnabled &&
					!(this.todTraitDefinitionMatch && this.todTraitDefinitionMatch.match == "compatible")
				) {
					return false;
				}
			}

			if (!(this.cfSystemUser && this.cfSystemUser.id)) {
				return false;
			}

			if (this.flagLid && !this.lidFlagId) {
				return false;
			}

			return true;
		},

		traitDefinitionRows() {
			if (
				!(this.itemDetails && this.itemDetails.traitDefinitions && this.itemDetails.traitDefinitions.length > 0)
			) {
				return null;
			}
			let cfTDs = this.itemDetails.traitDefinitions;
			let traits = RubricService.getNormalizedTraits(this.rubric);

			// console.log("traitDefinitionMatch");
			// console.log(cfTDs, rubric);

			let rows = [];
			let len = cfTDs.length;
			if (this.rubric && traits.length > len) {
				len = traits.length;
			}

			for (let i = 0; i < len; i++) {
				let newRow = {};
				if (i < cfTDs.length) {
					newRow.cfTD = cfTDs[i];
				}
				if (this.rubric && i < traits.length) {
					newRow.rubricTD = traits[i];
				}
				rows.push(newRow);
			}

			console.log("output");
			console.log(rows);
			return rows;
		},

		traitDefinitionMatch() {
			if (
				!(this.itemDetails && this.itemDetails.traitDefinitions && this.itemDetails.traitDefinitions.length > 0)
			) {
				return null;
			}
			if (!this.rubric) {
				return null;
			}

			let cfTDs = this.itemDetails.traitDefinitions;
			let traits = RubricService.getNormalizedTraits(this.rubric);

			if (cfTDs.length != traits.length) {
				return {
					match: "mismatch_traits",
					rubric_traits: traits.length,
					cf_traits: cfTDs.length,
				};
			}

			let perfectMatch = true;

			for (let i = 0; i < cfTDs.length; i++) {
				if (cfTDs[i].min < traits[i].min) {
					return {
						match: "mismatch_min",
						index: i + 1,
						rubric_trait_name: traits[i].name,
						rubric_min: traits[i].min,
						cf_trait_name: cfTDs[i].description,
						cf_min: cfTDs[i].min,
					};
				}
				if (cfTDs[i].max > traits[i].max) {
					return {
						match: "mismatch_max",
						index: i + 1,
						rubric_trait_name: traits[i].name,
						rubric_max: traits[i].max,
						cf_trait_name: cfTDs[i].description,
						cf_max: cfTDs[i].max,
					};
				}

				if (cfTDs[i].min != traits[i].min || cfTDs[i].max != traits[i].max) {
					perfectMatch = false;
				}
			}

			return {
				match: perfectMatch ? "perfect" : "partial",
			};
		},

		todTraitDefinitionMatch() {
			if (!this.rubric) {
				return null;
			}

			let traits = RubricService.getNormalizedTraits(this.rubric);

			if (this.modelingWorkflow == "economy" && traits.length > 1) {
				return { match: "incompatible_economy_traits" };
			}

			for (let i = 0; i < traits.length; i++) {
				let trait = traits[i];
				if (trait.step != 1) {
					return {
						match: "incompatible_step",
						index: i + 1,
						rubric_trait_name: trait.name,
						rubric_step: trait.step,
					};
				}
			}

			return { match: "compatible" };
		},

		flagCodeRows() {
			if (!(this.conditionCodes && this.conditionCodes.length > 0)) {
				return [];
			}

			let rows = [];
			let matchedIeaCodes = {};

			for (let alert of this.client.alerts) {
				let found = false;
				for (let ieaCode of this.conditionCodes) {
					if (alert.code == ieaCode) {
						rows.push({
							alert: alert,
							ieaCode: ieaCode,
						});
						found = true;
						matchedIeaCodes[ieaCode] = true;
					}
				}
				if (!found) {
					rows.push({
						alert: alert,
					});
				}
			}

			for (let ieaCode of this.conditionCodes) {
				if (!matchedIeaCodes[ieaCode]) {
					rows.push({
						ieaCode: ieaCode,
					});
				}
			}

			return rows;
		},

		flagCodesMatched() {
			if (!(this.conditionCodes && this.conditionCodes.length > 0)) {
				return false;
			}

			for (let ieaCode of this.conditionCodes) {
			}
		},
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				tenantID: {
					group: "overall",
					errorMsg: "You must specify an IEA tenant ID",
					func: () => {
						return this.tenantID;
					},
				},
				itemId: {
					group: "overall",
					errorMsg: "You must choose a valid item",
					func: () => {
						return this.todEnabled || (this.itemDetails && this.itemDetails.itemId);
					},
				},
				todItemId: {
					group: "overall",
					errorMsg: "You must assign a item ID",
					func: () => {
						return this.newItemID && this.newItemID != "";
					},
				},
				todItemIdChar: {
					group: "overall",
					errorMsg:
						"Reference IDs may not contain spaces, non-ascii characters, or any of the following characters: ! # % ^ * ( )  / [ ] { } < > ? | ' \" :",
					func: () => {
						return this.newItemID && fs.isGoodRefID(this.newItemID);
					},
				},
				todSubject: {
					group: "overall",
					errorMsg: "You must choose a subject",
					func: () => {
						return !this.todEnabled || this.subject;
					},
				},
				rubric: {
					group: "overall",
					errorMsg: "You must choose a compatible rubric",
					func: () => {
						if (this.todEnabled) {
							return (
								this.rubric &&
								this.todTraitDefinitionMatch &&
								this.todTraitDefinitionMatch.match == "compatible"
							);
						} else {
							return (
								!this.itemDetails ||
								!this.rubric ||
								(this.traitDefinitionMatch &&
									(this.traitDefinitionMatch.match == "perfect" ||
										this.traitDefinitionMatch.match == "partial"))
							);
						}
					},
				},
				cfSystemUser: {
					group: "overall",
					errorMsg: "You must choose a system user",
					func: () => {
						return (!this.itemDetails && !this.todEnabled) || (this.cfSystemUser && this.cfSystemUser.id);
					},
				},
				lidFlagId: {
					group: "overall",
					errorMsg: "You must choose a flag for LID",
					func: () => {
						return (!this.itemDetails && !this.todEnabled) || !this.flagLid || this.lidFlagId;
					},
				},
				catchallFlagID: {
					group: "overall",
					errorMsg: "You must choose a catchall flag",
					func: () => {
						return (!this.itemDetails && !this.todEnabled) || this.catchallFlagID;
					},
				},
			});
		},

		initializeModal() {
			this.loadItemXML();

			if (this.client.iea_tenant_id) {
				this.tenantID = this.client.iea_tenant_id;
			}
			this.newItemID = this.item.ref_id;

			if (this.item.cfCreateRubric) {
				this.rubric = null;
			} else {
				this.rubric = this.item.rubric;
			}

			this.$set(this.item, "cfCreateRubric", null);
		},

		loadItems() {
			if (this.tenantID == "") {
				return;
			}

			this.loadingItems = true;
			let requestedTenant = this.tenantID;
			IeaService.getIeaPretrainedItems(requestedTenant)
				.then((r) => {
					if (requestedTenant != this.tenantID) {
						console.log(`Ignore items result for ${requestedTenant}, it was superseded`);
					}
					this.loadingItems = false;
					this.items = r.data;

					// for some reason we are receiving duplicate items, this filters the duplicate items out
					this.items = this.items.reduce(
						(itemLst, item) =>
							itemLst.some(({ itemId }) => itemId === item.itemId) ? itemLst : itemLst.concat(item),
						[]
					);
				})
				.catch((e) => {
					this.loadingItems = false;
					console.error(e);
					Notie.error("Failed to get CF pretrained items", e);
				});
		},

		loadConditionCodes() {
			if (this.tenantID == "") {
				return;
			}

			this.loadingItems = true;
			let requestedTenant = this.tenantID;
			IeaService.getIeaTenantDetails(requestedTenant)
				.then((r) => {
					if (requestedTenant != this.tenantID) {
						console.log(`Ignore details result for ${requestedTenant}, it was superseded`);
					}
					this.loadingCodes = false;
					this.conditionCodes = [];
					console.log("TENANT DETAILS");
					console.log(r.data);
					_.each(r.data.responseCodeAliases, (k) => {
						this.conditionCodes.push(k);
					});
				})
				.catch((e) => {
					this.loadingItems = false;
					console.error(e);
					Notie.error("Failed to get CF tenant details", e);
				});
		},

		loadAdmins() {
			if (this.tenantID == "") {
				return;
			}

			this.loadingAdmins = true;
			let requestedTenant = this.tenantID;
			return IeaService.getIeaProjects(requestedTenant)
				.then((r) => {
					if (requestedTenant != this.tenantID) {
						console.log(`Ignore details result for ${requestedTenant}, it was superseded`);
					}
					this.loadingAdmins = false;
					this.admins = r.data;
					_.each(this.admins, (admin) => {
						admin.name = admin.projectId;
					});
				})
				.catch((e) => {
					this.loadingAdmins = false;
					console.error(e);
					Notie.error("Failed to get CF admins", e);
				});
		},

		saveAdmin() {
			this.admin.tenantId = this.tenantID;
			this.savingAdmin = true;
			IeaService.createIeaProject(this.admin)
				.then((r) => {
					this.loadAdmins().then((r) => {
						this.savingAdmin = false;
						this.adminName = this.admin.projectId;
					});
				})
				.catch((e) => {
					this.savingAdmin = false;
					console.error(e);
					Notie.error("Failed to save admin preset", e);
				});
		},

		loadItemXML() {
			if (!this.item.id) {
				return;
			}

			this.loadingItemXML = true;
			ItemService.getItemXML(this.item.id)
				.then((r) => {
					this.loadingItemXML = false;
					this.itemXML = r.data;
				})
				.catch((e) => {
					this.loadingItemXML = false;
					console.error(e);
					Notie.error("Failed to get item XML", e);
				});
		},

		applySettings() {
			if (!this.canApplySettings) {
				console.error("Tried to apply settings while canApplySettings was", this.canApplySettings);
				return;
			}

			if (!this.item.cf_config) {
				this.item.cf_config = {};
			}

			this.item.cf_config.enabled = true;
			this.item.cf_config.mode = this.mode;
			if (this.item.cf_config.mode == "tod") {
				this.item.cf_config.item_id = this.newItemID;
				this.item.cf_config.subject = this.subject.id;
				this.item.cf_config.modeling_workflow = this.modelingWorkflow;
			} else if (this.item.cf_config.mode == "rangefinding") {
				this.item.cf_config.item_id = this.newItemID;
				this.item.cf_config.subject = this.subject.id;
				this.item.cf_config.modeling_workflow = this.modelingWorkflow;
			} else if (this.item.cf_config.mode == "pretrained") {
				this.item.cf_config.item_id = this.itemDetails.itemId;
				this.item.cf_config.subject = "";
				this.item.cf_config.modeling_workflow = "";
				this.item.cf_config.composite = this.itemDetails.responseOutcomeList ? true : false;
			}
			this.item.cf_config.system_user_id = this.cfSystemUser.id;
			this.item.cf_config.catchall_flag_id = this.catchallFlagID;
			this.item.cf_config.flag_lid = this.flagLid;
			this.item.cf_config.scoring_type = this.scoringType;
			this.item.cf_config.percent = this.percent;
			this.item.cf_config.score_passback = this.scorePassback;
			this.item.cf_config.conditions_as_scores = this.conditionsAsScores;
			this.item.cf_config.reliability_on_low_conf = this.reliabilityOnLowConf;

			if (this.item.cf_config.flag_lid) {
				this.item.cf_config.lid_flag_id = this.lidFlagId;
				this.item.cf_config.ignore_score = this.ignoreScore;
			}
			if (this.rubric) {
				this.item.cfCreateRubric = null;
				this.item.rubric = this.rubric;
				this.item.rubric_id = this.rubric.id;
				Notie.info("CF Settings Applied");
			} else {
				this.item.cfCreateRubric = this.createRubric(this.itemDetails.traitDefinitions);
				Notie.info("CF Settings Applied", "Rubric will be created on Item save");
			}

			this.hideModal();
			this.$forceUpdate();
		},

		createRubric(tds) {
			let newRubric = {
				traits: [],
			};

			for (let td of tds) {
				let newTrait = {
					id: Utils.generateUUID(),
					external_trait_id: td.name,
					name: td.description,
					min: td.min,
					max: td.max,
					step: 1,
					weight: 1,
				};
				newRubric.traits.push(newTrait);
			}

			return newRubric;
		},

		cancel() {
			this.hideModal();
		},

		hideModal() {
			this.$emit("hide");
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},

		anyProjects() {
			if (!this.projects) {
				return false;
			}
			return this.projects.length > 0;
		},

		anyProjectsCanDoIEA() {
			if (!this.projects) {
				return false;
			}
			for (let project of this.projects) {
				if (project.iea_project && project.iea_project.projectId) {
					return true;
				}
			}
			return false;
		},

		getAllIeaAdminNames() {
			if (!this.projects) {
				return "(none)";
			}
			let nameMap = {};
			for (let project of this.projects) {
				if (project.iea_project && project.iea_project.projectId) {
					nameMap[project.iea_project.projectId] = true;
				}
			}
			let names = Object.keys(nameMap);
			if (names.length == 0) {
				return "(none)";
			}
			return names.join(",");
		},
	},
};
</script>
