<template>
	<b-modal visible v-if="editingQcReq" @hide="close" @close="close" :ok-disabled="okDisabled" size="md">
		<template slot="modal-header">
			<h5 class="modal-title pl-3">
				{{ $t("ItemEdit.Quality.qualification.requirement_configuration") }}
			</h5>
		</template>

		<div class="px-3 pt-3">
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label>
							{{ $t("ItemEdit.Quality.qualification.requirement_type") }}
						</label>
						<v-select
							:options="requirementOptions"
							:reduce="(o) => o.id"
							v-model="editingQcReq.type"
							label="name"
							class="v-select-apply"
						>
						</v-select>
					</div>
				</div>
				<div class="col-12" v-if="dropReqType(editingQcReq.type)">
					<label>
						{{ $t("ItemEdit.Quality.qualification.choose_dropped_set_by") }}
					</label>
					<v-select
						:options="fs.qualDropSetOptions()"
						:reduce="(o) => o.id"
						v-model="editingQcReq.requirement"
						label="name"
						class="v-select-apply"
					>
					</v-select>
				</div>
				<div class="col-12" v-else>
					<div v-if="percentageReqType(editingQcReq.type)" class="form-group">
						<label>
							{{ $t("ItemEdit.Quality.qualification.minimum_percentage") }}
						</label>
						<input type="number" class="form-control" v-model.number="editingQcReq.requirement" />
					</div>
					<div v-if="countReqType(editingQcReq.type)" class="form-group">
						<label>
							{{ $t("ItemEdit.Quality.qualification.maximum_count") }}
						</label>
						<input type="number" class="form-control" v-model.number="editingQcReq.requirement" />
					</div>
				</div>
				<div class="col-12" v-if="traitReqType(editingQcReq.type)">
					<label>
						{{ $t("ItemEdit.Quality.qualification.apply_to_trait") }}
					</label>
					<v-select
						:options="traitOptions"
						:reduce="(o) => o.id"
						v-model="editingQcReq.trait_id"
						label="name"
						class="v-select-apply"
					>
					</v-select>
				</div>
				<div class="col-12" v-if="traitKeepBestType(editingQcReq.type)">
					<label>
						{{ $t("ItemEdit.Quality.qualification.apply_to_trait") }}
					</label>
					<v-select
						:options="traitOptions"
						:reduce="(o) => o.id"
						v-model="editingQcReq.trait_id"
						label="name"
						class="v-select-apply"
					>
					</v-select>
				</div>
				<div class="col-12" v-if="traitPassType(editingQcReq.type)">
					<label>
						{{ $t("ItemEdit.Quality.qualification.apply_to_trait") }}
					</label>
					<v-select
						:options="traitOptions"
						:reduce="(o) => o.id"
						v-model="editingQcReq.trait_id"
						label="name"
						class="v-select-apply"
					>
					</v-select>
					<label> Must Pass </label>
					<input class="form-control" type="number" min="1" v-model.number="editingQcReq.requirement" />
				</div>
			</div>
		</div>

		<template slot="modal-footer">
			<div class="d-flex flex-row px-3 w-100">
				<button class="btn btn-danger mr-auto" @click="cancelAddQualReq">
					{{ $t("buttons.cancel") }}
				</button>

				<button v-if="editingQcReq.type" :disabled="okDisabled" class="theme-accent btn" @click="addQualReq">
					<div v-tippy :title="okDisabledText">{{ $t("buttons.ok") }}</div>
				</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import fs from "@/services/FormatService";
import QCService from "@/services/QCService";

import { QUAL_CROSS_SET_REQS } from "@/services/Constants";
const { TRAIT_MUST_PASS } = QUAL_CROSS_SET_REQS;

export default {
	name: "QcReqModal",

	props: {
		item: Object,
		editingQcReq: Object,
		isVal: Boolean,
	},

	data() {
		return {
			fs,
		};
	},

	watch: {
		"editingQcReq.type"() {
			if (this.editingQcReq) {
				switch (this.editingQcReq.type) {
					case "drop_set":
						this.editingQcReq.requirement = 0;
						break;
					case TRAIT_MUST_PASS.id:
						this.editingQcReq.requirement = 1;
						break;
				}
			}
		},
	},

	computed: {
		okDisabled() {
			return this.okDisabledText !== "";
		},

		okDisabledText() {
			const { editingQcReq, qcConfig } = this;
			const { type } = editingQcReq;

			if (!type) {
				return "you must select a qualification type";
			}

			if (QCService.traitReqMustPassGreaterThanMustSee(editingQcReq, qcConfig)) {
				return this.$i18n.t("ItemEdit.Quality.qualification.trait_must_pass_error");
			}

			if (QCService.needsTraitAgreementAddedForReq(editingQcReq, qcConfig)) {
				return this.$i18n.t("ItemEdit.Quality.qualification.trait_req_trait_agreement_error");
			}

			return "";
		},

		traitOptions() {
			const options = [
				{
					name: this.$i18n.t("ItemEdit.Quality.qualification.each_trait_separately"),
					id: true,
				},
			];

			if (!(this.item && this.item.rubric && this.item.rubric.traits && this.item.rubric.traits.length)) {
				console.warn("Could not find trait in rubric");
				return;
			}

			return options.concat(
				this.item.rubric.traits.map(({ name, id }) => ({
					name,
					id,
				}))
			);
		},

		qcConfig() {
			const { isVal, item } = this;
			if (!item) {
				return { requirements: [] };
			}
			return isVal ? item.validity_config : item.qualification_config;
		},

		requirementOptions() {
			return this.isVal ? fs.valRequirements() : fs.qualRequirements();
		},
	},

	methods: {
		close() {
			this.$emit("close");
		},

		addQualReq() {
			this.$emit("ok");
		},

		cancelAddQualReq() {
			this.close();
		},

		percentageReqType(reqType) {
			return (
				reqType == "exact" || reqType == "exact_adj" || reqType == "trait_exact" || reqType == "trait_exact_adj"
			);
		},

		countReqType(reqType) {
			return reqType == "max_discrepant" || reqType == "trait_max_discrepant";
		},

		traitReqType(reqType) {
			return reqType == "trait_exact" || reqType == "trait_exact_adj" || reqType == "trait_max_discrepant";
		},

		traitPassType(reqType) {
			return reqType === "trait_pass";
		},

		traitKeepBestType(reqType) {
			return reqType === "keep_best_trait";
		},

		dropReqType(reqType) {
			return reqType == "drop_set";
		},
	},
};
</script>
