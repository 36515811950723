<template>
	<page :title="$t('Dashboard.title')">
		<div class="row mx-0 no-grow px-2 pt-3 b-b box mb-0">
			<div class="flex d-flex flex-row flex-wrap">
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3" style="width: 50px">{{ $t("Dashboard.project") }}</label>
					<div class="flex">
						<config-select
							:options="projects"
							v-model="selectedProject"
							style="max-width: 300px"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3" style="width: 50px">{{ $t("Dashboard.labels") }}</label>
					<config-label-search
						v-model="labels"
						:project="selectedProject"
						style="width: 100%; max-width: 300px"
					/>
				</div>
			</div>
			<div class="d-flex flex-row flex-wrap justify-content-end ml-2">
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3 btn-container">
					<a
						v-if="!exporting"
						class="btn-subtle"
						@click="exportRefs"
						v-tippy
						:title="$t('tooltip.export_section_item_manifest')"
					>
						<i class="float-right fas fa-lg fa-file-export"></i>
					</a>
					<a v-if="exporting" class="btn-subtle">
						<i class="float-right fas fa-lg fa-sync fa-spin text-muted"></i>
					</a>
				</div>
				<!-- 
					TODO: Unhide this once it is okay to do so based on app performance and network reliability

					Hiding the export all scores for all projects button for now in reference
					to issue https://github.com/MZDevinc/oscar/issues/2184. The intention is
					to unhide this later once we have a better handle on performance.
				 -->
				<!-- <div class="col-12 col-sm-6 d-flex align-items-center pb-3 btn-container">
					<a
						v-if="!exportingZip"
						class="btn-subtle"
						@click="exportProjectsZip"
						v-tippy
						:title="$t('tooltip.export_all_projects')"
					>
						<i class="fas fa-download fa-lg"></i>
					</a>
					<a v-if="exportingZip" class="btn-subtle">
						<i class="float-right fas fa-lg fa-sync fa-spin text-muted"></i>
					</a>
				</div> -->
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3 btn-container">
					<div
						v-if="selectedProject && (selectedProject.enforce_dates || selectedProject.enforce_times)"
						class="btn-subtle"
					>
						<i
							class="fas fa-lg"
							:class="{
								[available(selectedProject) ? 'fa-calendar-check' : 'fa-calendar-exclamation']: true,
							}"
							v-tippy="{ theme: 'popover', html: `#avail-chart`, placement: 'bottom-end' }"
						></i>
						<div class="d-none p-0" id="avail-chart">
							<project-availability :project="selectedProject" :client="client" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="padding">
			<div class="row">
				<div class="col-12 col-xl-6">
					<project-dash-completion :project="selectedProject" :labels="labels" />
				</div>
				<div class="col-12 col-xl-6">
					<project-dash-score-volume :project="selectedProject" :labels="labels" />
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<project-dash-sections :project="selectedProject" :labels="labels" />
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-main {
	background-color: #f3f6f9;
}
@media (max-width: 575px) {
	.btn-container {
		width: 20px;
	}
}
div.btn-subtle {
	opacity: 0.7;
}
a.btn-subtle {
	opacity: 0.7;
	transition: opacity 0.25s;
}
a.btn-subtle:hover {
	opacity: 1;
}
</style>

<script>
import _ from "lodash";
import moment from "moment";

import ConfigService from "@/services/ConfigService";
import TenantService from "@/services/TenantService";
import FileService from "@/services/FileService";
import ProjectService from "@/services/ProjectService";
import DashboardService from "@/services/DashboardService";
import notie from "@/services/NotieService";
import store from "@/services/Store";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigLabelSearch from "@/components/ConfigLabelSearch";
import ProjectDashCompletion from "@/components/project_dashboard/ProjectDashCompletion";
import ProjectDashScoreVolume from "@/components/project_dashboard/ProjectDashScoreVolume";
import ProjectDashSections from "@/components/project_dashboard/ProjectDashSections";
import ProjectAvailability from "@/components/ProjectAvailability";

export default {
	name: "ProjectDashboard",
	props: ["user"],
	data() {
		return {
			exporting: false,
			exportingZip: false,

			chartData: null,
			doughnutData: null,
			barData: null,
			projects: [],
			client: null,
			selectedProject: null,
			labels: [],
		};
	},
	components: {
		ConfigSelect,
		ConfigLabelSearch,
		ProjectDashCompletion,
		ProjectDashScoreVolume,
		ProjectDashSections,
		ProjectAvailability,
	},
	watch: {
		"selectedProject.id"() {
			this.labels = [];
		},
	},
	created() {
		Promise.all([ConfigService.listProjectsShallow(), TenantService.getClient()])
			.then((resps) => {
				//resps[0] - projects
				this.projects = resps[0].data;
				this.selectedProject = store.bindProject(this, this.projects);

				//resps[1] - client
				this.client = resps[1].data;
				this.loading = false;
			})
			.catch((err) => {
				console.log(err);
				notie.error("Failed to load config options", err);
				this.loading = false;
			});
	},
	mounted() {},
	methods: {
		available(project) {
			return ProjectService.checkIfAvailable(project, this.client.timezone);
		},

		exportRefs() {
			this.exporting = true;
			ConfigService.listProjects()
				.then((r) => {
					this.exporting = false;
					let projects = r.data;
					let project = _.find(projects, { id: this.selectedProject.id });
					FileService.projectManifestCSV(this.client, project);
				})
				.catch((e) => {
					this.exporting = false;
					console.log(e);
					notie.error("Failed to load full project", e);
				});
		},

		async exportProjectsZip() {
			this.exportingZip = true;
			try {
				const { data } = await DashboardService.tenantExportCSV();
				FileService.downloadZipFile(`All_Project_Scores_${moment().format("YY-MM-DD")}.zip`, data);
			} catch (err) {
				notie.error("Failed to create csv exports", err);
			} finally {
				this.exportingZip = false;
			}
		},
	},
};
</script>
