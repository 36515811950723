<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportUserSummary.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportUserSummary.user_summary") }}
				</div>
			</div>
		</template>
		<div id="report" class="flex scroll-x scroll-y">
			<div class="row flex-fixed px-0 pt-3 box margin-trans mx-0" :class="{ 'm-4': !changeOptions }">
				<div class="options-buttons" :class="{ 'change-options': changeOptions }">
					<a
						class="text-muted pr-2"
						@click="printReport"
						v-tippy="{ placement: 'bottom', triggers: 'click' }"
						:title="$t('tooltip.print')"
					>
						<i class="far fa-print fa-lg"></i>
					</a>
					<a
						v-if="!changeOptions"
						class="text-muted"
						@click="toggleOptions"
						v-tippy="{ placement: 'bottom' }"
						:title="$t('tooltip.options_mode')"
					>
						<i class="far fa-cog fa-lg"></i>
					</a>
					<a
						v-if="changeOptions"
						class="text-muted hide-options-buttons"
						@click="toggleOptions"
						v-tippy="{ placement: 'bottom' }"
						:title="$t('tooltip.report_mode')"
					>
						<i class="far fa-chart-bar fa-lg"></i>
					</a>
				</div>

				<!-- Edit mode -->
				<div class="col-12 col-md-4" v-if="changeOptions">
					<div class="form-group">
						<label>{{ $t("ReportUserSummary.section") }}</label>
						<config-select
							:options="sections"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-md-4" v-if="changeOptions">
					<div class="form-group">
						<label>{{ $t("ReportUserSummary.item") }}</label>
						<config-select
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
							sortBy="section_item_sequence"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-md-4" v-if="changeOptions">
					<div class="form-group">
						<label>{{ $t("ReportUserSummary.user") }}</label>
						<config-user-search
							:clientID="user.client && user.client.id"
							v-model="selectedUser"
							:placeholder="$t('data_description.any')"
						/>
					</div>
				</div>

				<!-- Report mode -->
				<div class="col-12 col-md-4" v-if="!changeOptions">
					<div class="report-group">
						<h3>{{ selectedUser.full_name }}</h3>
						<div class="text-muted">
							<span>{{ selectedUser.email }}</span>
							<span>({{ selectedUser.scorer_id }})</span>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4" v-if="!changeOptions">
					<div class="report-group">
						<h6 class="py-2">
							<i class="fas fa-briefcase"></i>
							<span v-if="selectedSection">{{ selectedSection.name }}</span>
							<loading v-if="!selectedSection" type="inline-header" />
						</h6>
						<h6 class="py-2">
							<i class="fas fa-book"></i>
							<span v-if="selectedItem">{{ selectedItem.name }}</span>
							<loading v-if="!selectedItem" type="inline-header" />
						</h6>
					</div>
				</div>
				<div class="col-12 col-md-4" v-if="!changeOptions">
					<div class="report-group">
						<h3>{{ $t("ReportUserSummary.user_summary") }}</h3>
						<div class="text-muted">{{ fs.medDate(now) }}</div>
					</div>
				</div>
			</div>

			<div class="row mx-2 mb-3" v-if="selectedSection && selectedItem && selectedUser">
				<div class="col-12 col-xl-6">
					<user-stat-block :section="selectedSection" :item="selectedItem" :user="selectedUser" />
				</div>
				<div class="col-12 col-xl-6">
					<user-qc-sets :section="selectedSection" :item="selectedItem" :user="selectedUser" />
				</div>
				<div class="col-12 col-xl-8">
					<div class="box padding">
						<div class="row mb-0" v-if="anyScores">
							<div v-if="anyComps" class="col-12 col-sm-4">
								<h6 style="display: inline-block">{{ $t("ReportUserSummary.traits") }}</h6>
							</div>
							<div class="col-12 col-sm-8">
								<h6 style="display: inline-block">
									{{ $t("ReportUserSummary.frequency_distribution") }}
								</h6>
							</div>
						</div>

						<hr v-if="anyScores" />

						<div v-if="anyScores">
							<div class="row mb-0" v-for="trait in rubric.traits" :key="trait.id">
								<div class="page-break"></div>
								<div class="col-12 col-sm-4">
									<h6 v-if="anyComps" class="py-1 mb-3 b-b float-right">{{ trait.agreement }}%</h6>
									<h6 class="py-1 mb-3 b-b">{{ trait.reported_name || trait.name }}</h6>
								</div>
								<div v-if="anyComps" class="col-12 col-sm-8"></div>
								<div v-if="anyComps" class="col-12 col-sm-4">
									<div class="d-flex flex-row">
										<table class="confusion-matrix bordered center position-relative my-4 ml-4">
											<div class="text-center matrix-axis-left">
												{{ $t("ReportUserSummary.this_user") }}
											</div>
											<div class="text-center matrix-axis-top">
												{{ $t("ReportUserSummary.other_user") }}
											</div>
											<tbody>
												<tr v-for="(row, i) in trait.irrDataMatrix" :key="i" v-if="i == 0">
													<td v-for="(col, j) in row" :key="j">{{ col.value }}</td>
												</tr>
												<tr v-for="(row, i) in trait.irrDataMatrix" :key="i" v-if="i != 0">
													<td v-if="j == 0" v-for="(col, j) in row" :key="j">
														{{ col.value }}
													</td>
													<td
														v-if="j != 0 && selectedUser"
														:class="col.class"
														@click="matClick(trait.id, col.leftScore, col.rightScore)"
														v-for="(col, j) in row"
														:key="j"
													>
														{{ col.value }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div class="col-12" :class="{ 'col-sm-8': anyComps }">
									<canvas
										v-if="!running"
										v-chartjs="trait.chartData"
										style="width: 100%; height: 250px"
									></canvas>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-4">
					<user-activity-log :user="selectedUser" :size="10" />
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ar {
	text-align: center;
}
.d-long {
	display: none;
}
.d-abr {
	display: table-cell;
}
@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}
	.d-abr {
		display: none;
	}
}
.t-margin {
	width: 15px;
}
.matrix-axis-top {
	position: absolute;
	left: 0px;
	right: 0px;
	top: -24px;
}
.matrix-axis-left {
	position: absolute;
	right: calc(50% + 12px);
	width: 100%;
	top: 50%;
	transform: rotate(270deg);
	transform-origin: 50% 50%;
}
h5,
h6 {
	margin-bottom: 0;
}
.hover-darken {
	transition: background-color 0.25s;
}
.hover-darken:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.1);
}
.confusion-matrix > tbody > tr > td {
	width: initial;
}
.confusion-matrix {
	width: 100%;
}
.options-buttons {
	position: absolute;
	right: 1rem;
	top: 1rem;
	z-index: 1000;
	transition: right 0.25s;
}
.options-buttons.change-options {
	right: 2rem;
}
.margin-trans {
	transition: margin 0.25s;
}
a {
	opacity: 0.5;
	transition: opacity 0.25s;
}
a:hover {
	opacity: 1;
}
.report-group {
	height: 67px;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
@media print {
	#report {
		zoom: 100%;
		background-color: transparent;
		height: auto;
		overflow: visible;
	}
	.col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.options-buttons {
		display: none !important;
	}
}
</style>

<script>
//Directives
require("@/directives/chartjs");

//Services
import ReportingService from "@/services/ReportingService";
import ThemeService from "@/services/ThemeService";
import UserService from "@/services/UserService";
import ConfigService from "@/services/ConfigService";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import moment from "moment";
import $ from "jquery";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigUserSearch from "@/components/ConfigUserSearch";

import UserQcSets from "@/components/user_summary/UserQCSets";
import UserStatBlock from "@/components/user_summary/UserStatBlock";
import UserActivityLog from "@/components/user_summary/UserActivityLog";

export default {
	name: "UserAgreement",

	props: ["user"],

	components: {
		ConfigSelect,
		ConfigUserSearch,
		UserQcSets,
		UserStatBlock,
		UserActivityLog,
	},

	data() {
		return {
			fs: fs,
			now: moment(),
			chartData: null,
			overallResponseAgreement: 0,
			overallTraitAgreement: 0,
			fdName: "",
			rubric: null,
			fd_data: null,

			selectedUser: null,

			reportTotals: [],
			reportStats: [],

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			byRubric: false,

			changeOptions: true,
			running: false,
			loading: true,
		};
	},

	created() {
		this.storagePrefix = "userSummary.";
		this.selectedUser = store.bind(this, "selectedUser");
		if (store.get(this, "userSummary.auto")) {
			store.set(this, "userSummary.auto", false);
			this.changeOptions = false;
		}

		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		ConfigService.listSectionsShallow(ctx)
			.then((resp) => {
				this.sections = resp.data.sections;

				this.selectedSection = store.bindSection(this, this.sections);
				this.selectedItem = store.bindItem(this, this.selectedSection);
				if (!this.changeOptions) {
					const storedSection = store.get(this, "userSummary.selectedSection");
					if (storedSection) {
						this.selectedSection = this.sections.find(({ id }) => storedSection.id === id);
					}
					const storedItem = store.get(this, "userSummary.selectedItem");
					if (storedItem) {
						this.selectedItem = this.selectedSection.items.find(({ id }) => storedItem.id === id);
					}
				}
				this.loading = false;
			})
			.catch((err) => {
				notie.error(this.$i18n.t("notie.load_config_options_fail"), err);
				this.loading = false;
			});
	},

	computed: {
		anyScores() {
			if (!this.rubric || !this.fd_data) {
				return false;
			}

			return this.fd_data.length > 0;
		},

		anyComps() {
			if (!this.rubric) {
				return false;
			}
			var result = _.find(this.rubric.traits, (trait) => {
				if (trait.agreement != "NaN") {
					return true;
				}
			});
			return result ? true : false;
		},
	},

	watch: {
		selectedSection() {
			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
			} else {
				this.items = [];
			}
			this.getStats();
		},
		selectedItem() {
			if (this.selectedItem.validity_config) return;
			this.getStats();
		},
		selectedUser() {
			this.getStats();
		},
	},

	methods: {
		getStats() {
			this.running = true;
			var sec = this.selectedSection;
			var item = this.selectedItem;
			var userLeft = this.selectedUser;
			if (!(userLeft && userLeft.id)) {
				return;
			}
			//Server supports a second user for comparison, but client currently does not
			//var userRight = this.userRight;
			var userRight = { id: null };
			var leftStates = [12];
			var rightStates = [12];

			if (!this.canRun()) {
				return;
			}

			if (this.selectedSection != "All") {
				sec = this.selectedSection.ref_id;
			}

			if (this.selectedItem != "All") {
				item = this.selectedItem.ref_id;
			}

			function getOverallResponseAgreement(agreement) {
				return agreement * 100;
			}

			function getOverallTraitAgreement(stats) {
				// debugger;
				var agreed = 0;
				var total = 0;

				_.each(stats, (stat) => {
					if (stat.left_score == stat.right_score) {
						agreed += stat.count;
					}
					total += stat.count;
				});

				return (agreed / total) * 100;
			}

			function getTraitAgreement(stats, traitId) {
				var agreed = 0;
				var total = 0;

				_.each(stats, (stat) => {
					if (stat.trait_id == traitId) {
						if (stat.left_score == stat.right_score) {
							agreed += stat.count;
						}
						total += stat.count;
					}
				});

				return ((agreed / total) * 100).toFixed(2);
			}

			function getFd(stats, traitId, sp, field) {
				var count = 0;
				var total = 0;
				_.each(stats, (stat) => {
					if (stat.trait_id == traitId) {
						total += stat[field];
						if (stat.score == sp) {
							count += stat[field];
						}
					}
				});
				if (total > 0) {
					return {
						label: sp,
						value: (count / total) * 100,
						count: count,
						total: total,
					};
				} else {
					return {
						none: true,
					};
				}
			}

			function getStatValue(stats, trait, firstScore, secondScore) {
				var count = 0;

				_.each(stats, function (stat) {
					if (stat.trait_id == trait.id && stat.left_score == firstScore && stat.right_score == secondScore) {
						count = stat.count;
					}
				});
				return count;
			}

			this.data = [
				{
					values: [
						{ label: "1", value: 10, color: "#009688" },
						{ label: "2", value: 30, color: "#009688" },
						{ label: "3", value: 40, color: "#009688" },
						{ label: "4", value: 10, color: "#009688" },
					],
				},
			];

			ReportingService.getItemSummaryStats(
				this.selectedSection.id,
				this.selectedItem.id,
				leftStates,
				userLeft.id,
				rightStates,
				userRight.id
			)
				.then((resp) => {
					let data = resp.data;

					this.running = false;
					this.selectedItem = data.item;
					this.rubric = data.rubric;
					this.fd_data = data.fd_data;
					var rubric = this.rubric;

					//debugger;
					this.overallResponseAgreement = getOverallResponseAgreement(data.overall_response_agreement);
					this.overallTraitAgreement = getOverallTraitAgreement(data.matrix_data);

					rubric.traits = _.sortBy(rubric.traits, (t) => {
						return t.sequence;
					});

					_.each(rubric.traits, (trait) => {
						if (trait.separator) return;
						if (trait.is_parent) return;

						trait.chartData = {
							type: "bar",
							data: {
								labels: [],
								datasets: [
									{
										label: this.$i18n.t("ReportUserSummary.first_second"),
										fullName: "First & Second",
										xAxisID: "sp",
										yAxisID: "freq",
										backgroundColor: "rgba(0, 188, 212, 0.2)",
										borderColor: "rgba(0, 188, 212, 0.5)",
										borderWidth: 1,
										// hoverBackgroundColor:
										// 	"rgba(0, 188, 212, 0.3)",
										// hoverBorderColor:
										// 	"rgba(0, 188, 212, 1)",
										// hoverBorderWidth: 1,
										data: [],
										counts: [],
									},
								],
							},
							options: {
								tooltips: {
									mode: "point",
									displayColors: false,
									callbacks: {
										title: (items, data) => {
											let i = items[0].datasetIndex;
											let sp = items[0].xLabel;
											let fullName = data.datasets[i].fullName;
											return `${fullName}: ${sp}`;
										},
										label: (item, data) => {
											let i = item.datasetIndex;
											let j = item.index;
											let per = data.datasets[i].data[j];
											if (data.datasets[i].counts) {
												let count = data.datasets[i].counts[j].count;
												let total = data.datasets[i].counts[j].total;
												return `${count} ${this.$i18n.t("tooltip.out_of")} ${total} (${per}%)`;
											} else {
												return `${data.datasets[i].exceptionName} ${this.$i18n.t(
													"tooltip.frequency"
												)}: ${per}%`;
											}
										},
									},
								},
								maintainAspectRatio: false,
								legend: {
									display: true,
								},
								scales: {
									xAxes: [
										{
											id: "sp",
											scaleLabel: {
												display: true,
												labelString: this.$i18n.t("ReportUserSummary.score_point"),
											},
										},
									],
									yAxes: [
										{
											id: "freq",
											scaleLabel: {
												display: true,
												labelString: this.$i18n.t("ReportUserSummary.frequency"),
											},
											ticks: {
												suggestedMin: 0,
												suggestedMax: 25,
											},
										},
									],
								},
							},
						};
						trait.agreement = getTraitAgreement(data.matrix_data, trait.id);
						//Create empty matrix padded with scorepoint and FD data
						var sps = [];
						var firstRow = [" "];

						var step = trait.step;

						for (var i = trait.min; i <= trait.max; i += step) {
							firstRow.push({ value: i });
							sps.push(i);
						}

						//trait.chartData.data.datasets[0].data = [];
						//trait.chartData.data.labels = [];
						let resData = [];
						let resCounts = [];
						let brData = [];
						let brCounts = [];
						var irrDataMatrix = [firstRow];
						_.each(sps, (sp, i) => {
							var live = getFd(data.fd_data, trait.id, sp, "count");
							var res = getFd(data.fd_data, trait.id, sp, "res_count");
							var br = getFd(data.fd_data, trait.id, sp, "br_count");
							trait.chartData.data.datasets[0].data[i] = +(Math.round(live.value + "e+2") + "e-2");
							trait.chartData.data.datasets[0].counts[i] = live;
							if (!res.none) {
								resData[i] = +(Math.round(res.value + "e+2") + "e-2");
								resCounts[i] = res;
							}
							if (!br.none) {
								brData[i] = +(Math.round(br.value + "e+2") + "e-2");
								brCounts[i] = br;
							}
							trait.chartData.data.labels[i] = live.label;

							//Push new row
							var row = [{ value: sp }];
							_.each(sps, (sp2) => {
								var value = getStatValue(data.matrix_data, trait, sp, sp2);
								var classa = {};
								if (value > 0) {
									if (Math.abs(sp - sp2) == 0) {
										classa = { cmgreen: true };
									} else if (Math.abs(sp - sp2) == 1) {
										classa = { cmorange: true };
									} else {
										classa = { cmred: true };
									}
								}

								if (this.user.role.page_auditing) {
									classa["hover-darken"] = true;
								}

								row.push({
									value: value,
									class: classa,
									traitid: trait.id,
									leftScore: sp,
									rightScore: sp2,
								});
							});
							irrDataMatrix.push(row);
						});
						if (resData.length > 0) {
							trait.chartData.data.datasets.push({
								label: this.$i18n.t("ReportUserSummary.res"),
								fullName: "Resolution",
								xAxisID: "sp",
								yAxisID: "freq",
								backgroundColor: "rgba(92, 199, 50, 0.2)",
								borderColor: "rgba(92, 199, 50, 0.5)",
								borderWidth: 1,
								// hoverBackgroundColor: "rgba(92, 199, 50, 0.3)",
								// hoverBorderColor: "rgba(92, 199, 50, 1)",
								// hoverBorderWidth: 1,
								data: resData,
								counts: resCounts,
							});
						}
						if (brData.length > 0) {
							trait.chartData.data.datasets.push({
								label: this.$i18n.t("ReportUserSummary.br"),
								fullName: "Backread",
								xAxisID: "sp",
								yAxisID: "freq",
								backgroundColor: "rgba(212, 148, 0, 0.2)",
								borderColor: "rgba(212, 148, 0, 0.5)",
								borderWidth: 1,
								// hoverBackgroundColor: "rgba(212, 148, 0, 0.3)",
								// hoverBorderColor: "rgba(212, 148, 0, 1)",
								// hoverBorderWidth: 1,
								data: brData,
								counts: brCounts,
							});
						}
						if (this.selectedItem.exception_config.fd_enabled) {
							let fdt = _.find(this.selectedItem.exception_config.fd_traits, { trait_id: trait.id });
							if (fdt) {
								let fd_mins = [];
								let fd_maxes = [];
								_.each(fdt.score_point_percents, (spp) => {
									if (spp.enabled) {
										fd_mins.push(Math.max(spp.percent - fdt.variance_percent, 0));
										fd_maxes.push(Math.min(spp.percent + fdt.variance_percent, 100));
									} else {
										fd_mins.push(null);
										fd_maxes.push(null);
									}
								});
								trait.chartData.data.datasets.push({
									type: "line",
									label: this.$i18n.t("ReportUserSummary.min"),
									fullName: "Enforced FD Range",
									exceptionName: "Minimum",
									xAxisID: "sp",
									yAxisID: "freq",
									pointStyle: "line",
									pointRadius: 10,
									pointHoverRadius: 10,
									showLine: false,
									backgroundColor: "rgba(0, 0, 0, 0)",
									borderColor: "rgba(212, 0, 0, 0.5)",
									borderWidth: 1,
									data: fd_mins,
								});
								trait.chartData.data.datasets.push({
									type: "line",
									label: this.$i18n.t("ReportUserSummary.max"),
									fullName: "Enforced FD Range",
									exceptionName: "Maximum",
									xAxisID: "sp",
									yAxisID: "freq",
									pointStyle: "line",
									pointRadius: 10,
									pointHoverRadius: 10,
									showLine: false,
									backgroundColor: "rgba(0, 0, 0, 0)",
									borderColor: "rgba(212, 0, 0, 0.5)",
									borderWidth: 1,
									data: fd_maxes,
								});
							}
						}
						this.$forceUpdate();

						trait.irrDataMatrix = irrDataMatrix;

						// var test1 = [
						//     [2, 0, 0],
						//     [0, 2, 1],
						//     [0, 1, 1]
						// ]
						// console.log("TEST " + kappa(test1))
						// console.log("TEST " + lwk(test1))
						// console.log("TEST " + qwk(test1))
					});
				})
				.catch((err) => {
					this.running = false;
					console.log(err);
					notie.error(this.$i18n.t("notie.load_summary_fail"), err);
				});
		},

		sum(matrix, axis) {
			var result = [];
			//Return scalar sum of the matrix
			if (axis == 0) {
				var total = 0;
				for (var i = 0; i < matrix.length; i++) {
					for (var j = 0; j < matrix.length; j++) {
						total += matrix[i][j];
					}
				}
				return total;
			}

			//Calculate sum for each row and return histogram
			if (axis == 1) {
				for (var i = 0; i < matrix.length; i++) {
					var row = matrix[i];
					var total = 0;
					_.each(row, (entry) => {
						total += entry;
					});
					result[i] = total;
				}
				return result;
			}

			//Calculate sum for each column and return histogram
			if (axis == 2) {
				var mat = Array(matrix.length);
				for (var i = 0; i < matrix.length; i++) {
					mat[i] = Array(matrix.length);
					for (var j = 0; j < matrix.length; j++) {
						mat[i][j] = matrix[j][i];
					}
				}
				return this.sum(mat, 1);
			}
		},

		percentColor(val, t1, t2) {
			return {
				"text-danger": val < t1,
				"text-warning": val >= t1 && val < t2,
				"text-success": val > t2,
			};
		},

		matClick(traitId, firstScore, secondScore) {
			if (!this.user.role.page_auditing) {
				return;
			}

			var sps = [
				{
					score_type: 1,
					trait_id: traitId,
					score: firstScore,
				},
				{
					score_type: 2,
					trait_id: traitId,
					score: secondScore,
				},
			];

			//Set irrelevant things to blank
			store.set(this, "audit.r.selectedFlagType", null);
			store.set(this, "audit.r.selectedFlagReviewType", null);
			store.set(this, "audit.r.selectedState", null);
			store.set(this, "audit.r.response_id", "");
			store.set(this, "audit.r.labels", []);
			store.set(this, "audit.r.minScore", null);
			store.set(this, "audit.r.maxScore", null);

			store.set(this, "audit.r.users", [this.selectedUser]);
			store.set(this, "audit.r.scoreQueryType", 0);
			store.set(this, "audit.r.trait_scores", sps);
			store.set(this, "audit.r.selectedItem", this.selectedItem.id);
			store.set(this, "audit.r.selectedSection", this.selectedSection.id);
			store.set(this, "audit.r.selectedProject", this.selectedSection.project_id);
			store.set(this, "audit.r.score_specific_query", true);
			store.set(this, "audit.r.filter_types", [{ type: 1 }, { type: 2 }]);

			// if (this.selectedUser) {
			// 	store.set(this, "audit.r.users", [this.selectedUser]);
			// }

			this.$router.push("/auditing");
		},

		canRun() {
			return this.selectedSection && this.selectedSection.id && this.selectedItem && this.selectedItem.id;
		},

		toggleOptions() {
			this.changeOptions = !this.changeOptions;
		},

		printReport() {
			if (this.changeOptions) {
				this.changeOptions = false;
				setTimeout(this.printReport, 250);
			} else {
				window.print();
			}
		},
	},
};
</script>
