<template>
	<page customNavBar customBody>
		<template #navbar>
			<div id="navbar" class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("UserSetup.title") }}</div>

				<!-- Show inactive -->
				<div class="checkbox mr-3">
					<label class="md-check">
						<input v-model="showArchived" type="checkbox" />
						<i class="theme-accent"></i>
						{{ $t("UserSetup.show_archived") }}
					</label>
				</div>

				<!-- Export -->
				<div class="py-0 ml-2" style="margin-right: 8px">
					<div
						class="btn btn-sm hover-darken theme-accent btn-block"
						v-tippy="{ placement: 'bottom' }"
						:title="$t('tooltip.export_users')"
						@click="startExport"
					>
						<i class="fas fa-lg fa-download"></i>
					</div>
				</div>

				<!-- Batch import -->
				<div class="py-0">
					<button @click="startImport" class="btn btn-sm hover-darken theme-accent btn-block">
						<i class="far fa-fw fa-file-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("UserSetup.import_users") }}</span>
					</button>
				</div>

				<!-- Create new -->
				<div class="py-0 ml-2">
					<a href="#/users/new" class="btn btn-sm hover-darken theme-accent btn-block">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("UserSetup.new_user") }}</span>
					</a>
				</div>

				<!-- Search field -->
				<div class="collapse navbar-collapse no-grow order-lg-1" id="navbarToggler">
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('UserSetup.search_users_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>

		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Name Virtual Column -->
					<template #cell(name)="data">{{ data.item.first_name }} {{ data.item.last_name }}</template>

					<!-- Role -->
					<template #cell(role)="data">{{ getRoleName(data.item) || "Undefined" }}</template>

					<!-- Teams count -->
					<template #cell(teams)="data">
						<span
							v-if="data.item.teams && data.item.teams.length > 0"
							v-tippy
							:title="listNames(data.item.teams, $t('tooltip.teams'))"
						>
							<i class="fas fa-users faw"></i>
							{{ (data.item.teams && data.item.teams.length) || 0 }}
						</span>
						<span v-if="!(data.item.teams && data.item.teams.length > 0)" class="text-muted">{{
							$t("data_description.none")
						}}</span>
					</template>

					<!-- Scoring Allowed -->
					<template #cell(active)="data">
						<div class="mx-auto w40" v-tippy :title="$t('tooltip.inactive_cannot_score')">
							<label class="ui-switch ui-switch-md theme-accent">
								<input @change="setActive(data.item)" type="checkbox" v-model="data.item.active" />
								<i></i>
							</label>
						</div>
					</template>

					<!-- Login Allowed -->
					<template #cell(archived)="data">
						<div class="mx-auto w40" v-tippy :title="$t('tooltip.archived_cannot_login')">
							<label class="ui-switch ui-switch-md theme-accent">
								<input @change="setArchive(data.item)" type="checkbox" v-model="data.item.archived" />
								<i></i>
							</label>
						</div>
					</template>

					<!-- Actions -->
					<template #cell(actions)="data">
						<div class="mx-auto d-flex flex-row justify-content-center">
							<a
								:href="`#/users/${data.item.id}`"
								class="btn btn-sm btn-icon btn-rounded theme-accent text-white"
								v-tippy
								:title="$t('tooltip.edit')"
							>
								<i class="far fa-edit"></i>
							</a>
							<button
								v-if="user.superuser"
								:disabled="user.id == data.item.id"
								@click="loginAsUserObj = data.item"
								class="btn btn-sm btn-icon btn-rounded hover-darken btn-primary text-white ml-1"
								v-tippy
								:title="$t('tooltip.login_as_this_user')"
							>
								<i class="far fa-user-tag" style="margin-left: 1px"></i>
							</button>
							<button
								v-if="!data.item.inProgress"
								@click="deleting = data.item"
								class="btn btn-sm btn-icon btn-rounded hover-darken red text-white ml-1"
								v-tippy
								:title="$t('tooltip.delete')"
							>
								<i class="far fa-trash"></i>
							</button>
							<button
								v-if="data.item.inProgress"
								class="btn btn-sm btn-icon btn-rounded ml-1"
								v-tippy
								title="Removing..."
							>
								<loading type="icon" />
							</button>
						</div>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>

		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("UserSetup.delete_user") }}</template>
			<div class="row p-4">
				{{ $t("modal.confirm_delete") }}&nbsp;
				<strong class="text-theme">{{ deleting.first_name }} {{ deleting.last_name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">{{ $t("buttons.cancel") }}</button>
				<button class="btn danger btn-flat" @click="removeUser(deleting)">{{ $t("buttons.delete") }}</button>
			</template>
		</b-modal>

		<b-modal id="importModal" size="lg" v-model="importModal">
			<template slot="modal-title">{{ $t("UserSetup.import_users_modal.title") }}</template>

			<div class="row p-4">
				<div class="col-12 form-group">
					<h5>{{ $t("UserSetup.import_users_modal.batch_import") }}</h5>
				</div>

				<div class="col-12">
					<div class="form-group">
						<input id="file-input" class="d-none" type="file" @change="selectImportFile" />
						<button class="btn hover-darken theme-accent btn-block" @click="click('file-input')">
							{{ $t("UserSetup.import_users_modal.select_file") }}
						</button>
					</div>
					<div class="form-group">
						<input type="text" class="form-control" :value="importingFile && importingFile.name" readonly />
					</div>
					<div class="form-group" v-if="importStep == 'file select'">
						<p>{{ $t("UserSetup.import_users_modal.things_to_remember.title") }}</p>
						<ul style="list-style-type: circle" class="mb-0">
							<li style="list-style-type: circle">
								{{ $t("UserSetup.import_users_modal.things_to_remember.only_csv") }}
							</li>
							<li style="list-style-type: circle">
								{{ $t("UserSetup.import_users_modal.things_to_remember.header_row") }}
							</li>
							<li style="list-style-type: circle">
								{{ $t("UserSetup.import_users_modal.things_to_remember.data_format") }}
							</li>
						</ul>
						<table style="border: 0px solid transparent" class="my-2 mx-auto">
							<tr>
								<td class="p-2">{{ $t("fields.first_name") }}</td>
								<td class="p-2">{{ $t("fields.last_name") }}</td>
								<td class="p-2">{{ $t("fields.scorer_id") }}</td>
								<td class="p-2">{{ $t("fields.email") }}</td>
								<td class="p-2">{{ $t("fields.password") }}</td>
								<td class="p-2">{{ $t("fields.role") }}</td>
								<td class="p-2">
									{{ $t("fields.active") }}
									<small class="text-muted">(0/1)</small>
								</td>
								<td class="p-2">{{ $t("fields.school_codes") }}</td>
								<td class="p-2">{{ $t("fields.teams") }}</td>
							</tr>
							<tr>
								<td colspan="9" style="border: 0px solid transparent">
									<a
										class="float-right text-theme actual-link"
										href="/static/templates/OSCAR Template - Import Users.csv"
										download
										>{{
											$t("UserSetup.import_users_modal.things_to_remember.download_template")
										}}</a
									>
								</td>
							</tr>
						</table>
						<ul style="list-style-type: circle">
							<li style="list-style-type: circle">
								{{ $t("UserSetup.import_users_modal.things_to_remember.additional_teams") }}
							</li>
							<li style="list-style-type: circle">
								{{ $t("UserSetup.import_users_modal.things_to_remember.multiple_school_codes") }}
							</li>
						</ul>
					</div>

					<div class="col-12 form-group" v-if="importStep == 'file select'">
						<label>{{ $t("UserSetup.import_users_modal.password_creation_email") }}</label>
						<v-select :options="emailBehaviors" v-model="emailBehavior"></v-select>
						<div v-if="emailBehavior && emailBehavior.id == 2" class="text-small text-muted">
							{{ $t("UserSetup.import_users_modal.ignore_import_password") }}
						</div>
						<div v-if="emailBehavior && emailBehavior.id == 3" class="text-small text-muted">
							{{ $t("UserSetup.import_users_modal.no_password_inaccessible") }}
						</div>
					</div>

					<div class="col-12 form-group" v-if="importStep != 'file select'">
						<div v-for="(error, i) in importMessages" :key="i">
							<span
								class="_600"
								:class="{
									'text-danger': error.type == 'error',
									'text-warning': error.type == 'warning',
								}"
								>{{ error.message }}</span
							>
							<span class="text-small text-muted">{{ error.result }}</span>
						</div>
					</div>
				</div>
			</div>

			<template slot="modal-footer">
				<button
					class="btn primary btn-flat"
					@click="stopImport"
					v-if="!(importStep == 'failed' || importStep == 'succeeded')"
				>
					{{ $t("buttons.cancel") }}
				</button>
				<button
					class="btn btn-flat"
					@click="importUsers(importingFile)"
					v-if="importStep == 'file select' || importStep == 'processed'"
					:disabled="!importingFile"
					:class="{ success: importingFile }"
				>
					Import
				</button>
				<button class="btn btn-flat" v-if="importStep == 'processing'" disabled>
					{{ $t("UserSetup.import_users_modal.processing") }}&nbsp;&nbsp;
					<loading type="icon" />
				</button>
				<button class="btn btn-flat" v-if="importStep == 'importing'" disabled>
					{{ $t("UserSetup.import_users_modal.importing") }}&nbsp;&nbsp;
					<loading type="icon" />
				</button>
				<button class="btn primary btn-flat" @click="exportUserErrors" v-if="importStep == 'failed'">
					{{ $t("buttons.export_errors") }}
				</button>
				<button class="btn primary btn-flat" @click="stopImport" v-if="importStep == 'failed'">
					{{ $t("buttons.ok") }}
				</button>
				<button class="btn primary btn-flat" @click="stopImport" v-if="importStep == 'succeeded'">
					{{ $t("buttons.done") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="exportModal" size="lg" v-model="exportModal">
			<template slot="modal-title">{{ $t("UserSetup.export_users_modal.title") }}</template>

			<div class="row p-4">
				<div class="col-12 form-group">
					<h5>{{ $t("UserSetup.export_users_modal.batch_export") }}</h5>
				</div>

				<div class="col-12">
					<div class="form-group" v-if="exportStep == 'file select'">
						<p>{{ $t("UserSetup.export_users_modal.things_to_remember.title") }}</p>
						<ul style="list-style-type: circle" class="mb-0">
							<li style="list-style-type: circle">
								{{ $t("UserSetup.export_users_modal.things_to_remember.only_csv") }}
							</li>
							<li style="list-style-type: circle">
								{{ $t("UserSetup.export_users_modal.things_to_remember.header_row") }}
							</li>
							<li style="list-style-type: circle">
								{{ $t("UserSetup.export_users_modal.things_to_remember.additional_teams") }}
							</li>
							<li style="list-style-type: circle">
								{{ $t("UserSetup.export_users_modal.things_to_remember.multiple_school_codes") }}
							</li>
							<li style="list-style-type: circle">
								{{ $t("UserSetup.export_users_modal.things_to_remember.data_format") }}
							</li>
						</ul>
						<table style="border: 0px solid transparent" class="my-2 mx-auto">
							<tr>
								<td class="p-2">{{ $t("fields.first_name") }}</td>
								<td class="p-2">{{ $t("fields.last_name") }}</td>
								<td class="p-2">{{ $t("fields.scorer_id") }}</td>
								<td class="p-2">{{ $t("fields.email") }}</td>
								<td class="p-2">{{ $t("fields.role") }}</td>
								<td class="p-2">
									{{ $t("fields.active") }}
									<small class="text-muted">(0/1)</small>
								</td>
								<td class="p-2">
									{{ $t("fields.archived") }}
									<small class="text-muted">(0/1)</small>
								</td>
								<td class="p-2">{{ $t("fields.school_codes") }}</td>
								<td class="p2">{{ $t("fields.last_login") }}</td>
								<td class="p-2">{{ $t("fields.teams") }}</td>
							</tr>
						</table>
					</div>

					<div class="col-12 form-group" v-if="exportStep != 'file select'">
						<div v-for="(error, i) in importMessages" :key="i">
							<span
								class="_600"
								:class="{
									'text-danger': error.type == 'error',
									'text-warning': error.type == 'warning',
								}"
								>{{ error.message }}</span
							>
							<span class="text-small text-muted">{{ error.result }}</span>
						</div>
					</div>
				</div>
			</div>

			<template slot="modal-footer">
				<button
					class="btn primary btn-flat"
					@click="stopExport"
					v-if="!(exportStep == 'failed' || exportStep == 'succeeded')"
				>
					{{ $t("buttons.cancel") }}
				</button>
				<button
					class="btn btn-flat"
					@click="exportUsers()"
					v-if="exportStep == 'file select' || exportStep == 'processed'"
					:class="{ success: true }"
				>
					Export
				</button>
				<button class="btn btn-flat" v-if="exportStep == 'processing'" disabled>
					{{ $t("UserSetup.export_users_modal.processing") }}&nbsp;&nbsp;
					<loading type="icon" />
				</button>
				<button class="btn btn-flat" v-if="exportStep == 'importing'" disabled>
					{{ $t("UserSetup.export_users_modal.exporting") }}&nbsp;&nbsp;
					<loading type="icon" />
				</button>
				<button class="btn primary btn-flat" @click="stopExport" v-if="exportStep == 'failed'">
					{{ $t("buttons.ok") }}
				</button>
				<button class="btn primary btn-flat" @click="stopExport" v-if="exportStep == 'succeeded'">
					{{ $t("buttons.done") }}
				</button>
			</template>
		</b-modal>

		<b-modal
			id="loginAsUserConfirm"
			:visible="loginAsUserObj != null"
			v-if="loginAsUserObj"
			@hide="loginAsUserObj = null"
		>
			<template slot="modal-title">{{ $t("UserSetup.login_as_user") }}</template>
			<div
				class="row p-4"
				v-html="$t('UserSetup.login_as_user_confirm', { userName: loginAsUserObj.full_name })"
			></div>
			<template slot="modal-footer">
				<button class="btn btn-secondary btn-flat" @click="loginAsUserObj = null">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn btn-success btn-flat" @click="loginAsUser(loginAsUserObj.id)">
					{{ $t("buttons.login") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w30 {
	width: 30px;
}
.w40 {
	width: 40px;
}
.w60 {
	width: 62px;
}
table,
td,
th {
	border: 1px solid lightgray;
}
.actual-link {
	font-size: 0.8rem;
	opacity: 0.7;
	text-decoration: underline;
}
.actual-link:hover {
	opacity: 1;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import UserService from "@/services/UserService";
import TenantService from "@/services/TenantService";
import AuthService from "@/services/AuthService";
import FileService from "@/services/FileService";
import ThemeService from "@/services/ThemeService";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import rawnotie from "notie";
import BB from "bluebird";
import moment from "moment";
import sanitizeHtml from "sanitize-html";

export default {
	name: "UserSetup",

	props: ["user"],

	data() {
		//Dynamically bound fields
		return {
			//b-table automatically pulls values out of the given objects based on these fields
			fields: [
				{ key: "name", label: this.$i18n.t("fields.name"), sortable: true }, //Name is a "virtual field" and corresponds with a slotted template
				{
					key: "scorer_id",
					label: this.$i18n.t("fields.scorer_id"),
					sortable: true,
				},
				{ key: "email", label: this.$i18n.t("fields.email"), sortable: true },
				{ key: "role", label: this.$i18n.t("fields.role"), sortable: true },
				{ key: "teams", label: this.$i18n.t("fields.teams"), sortable: true },
				{
					key: "active",
					label: this.$i18n.t("fields.active"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid switch-fix",
				},
				{
					key: "archived",
					label: this.$i18n.t("fields.archived"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid switch-fix",
					sortable: true,
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				},
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			sortBy: null,
			sortDesc: false,
			showArchived: true,
			loading: true,

			teamNames: {},
			teamIDsByName: {},
			teamIDsByRef: {},
			roleNames: {},
			roleIDsByName: {},

			deleting: false,

			importModal: false,
			importingFile: null,
			importStep: "file select",
			importDone: 0,
			importTotal: 1,
			importMessages: [],
			emailBehaviors: [
				{ label: this.$i18n.t("UserSetup.import_users_modal.no_password_send"), id: 1 },
				{ label: this.$i18n.t("UserSetup.import_users_modal.send_all"), id: 2 },
				{ label: this.$i18n.t("UserSetup.import_users_modal.no_send"), id: 3 },
			],
			emailBehavior: false,

			themeColor: null,
			exportModal: false,
			exportStep: "file select",
			exportingFile: null,

			loginAsUserObj: null,
			userImportErrors: null,
		};
	},

	created() {
		//When resizing the window, change the number of rows in the BTable
		//based on the window height, but not too often
		// this.resizeFunc = _.debounce(() => {
		// 	ViewportService.resizeBTable("navbar", "pages", 43.2, 47.2, this);
		// }, 250);
		// window.addEventListener("resize", this.resizeFunc);

		// this.perPage = ViewportService.estimateBTable(120, 104, 43.2, 47.2);

		this.themeColor = ThemeService.getThemeColor();

		this.sortBy = store.get(this, "users.sortBy");
		this.sortDesc = store.getDefault(this, "users.sortDesc", false);
		this.showArchived = store.getDefault(this, "users.showArchived", true);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeFunc);
	},

	watch: {
		sortBy() {
			store.set(this, "users.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "users.sortDesc", this.sortDesc);
		},
		showArchived() {
			store.set(this, "users.showArchived", this.showArchived);
			this.refreshTable();
		},
	},

	methods: {
		provider(ctx) {
			ctx.active = this.showArchived;
			return BB.props({
				client: TenantService.getClient(),
				users: UserService.listUsers(this.user.client.id, ctx),
			})
				.then((resps) => {
					// client
					this.teamNames = {};
					_.each(resps.client.data.teams, (team) => {
						this.teamNames[team.id] = team.name;

						if (this.teamIDsByName[team.name] == undefined) {
							this.teamIDsByName[team.name] = team.id;
						} else {
							this.teamIDsByName[team.name] = null;
						}

						if (this.teamIDsByRef[team.ref_id] == undefined) {
							this.teamIDsByRef[team.ref_id] = team.id;
						} else {
							this.teamIDsByRef[team.ref_id] = null;
						}
					});

					this.roleNames = {};
					_.each(resps.client.data.roles, (role) => {
						this.roleNames[role.id] = role.name;

						if (this.roleIDsByName[role.name] == undefined) {
							this.roleIDsByName[role.name] = role.id;
						} else {
							this.roleIDsByName[role.name] = null;
						}
					});

					// users
					let users = resps.users.data.users;
					_.each(users, (user) => {
						let teams = [];
						_.each(user.team_ids, (team_id) => {
							var name = this.teamNames[team_id];
							if (name) {
								teams.push({ name: name });
							}
						});
						user.teams = teams;
					});

					this.totalRows = resps.users.data.totalRows;
					this.loading = false;
					return users;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_users_fail"), err);
					this.loading = false;
					return [];
				});
		},

		getRoleName(user) {
			return this.roleNames[user.role_id];
		},

		setActive(user) {
			var action = user.active ? "activate" : "deactivate";
			var failValue = !user.active;
			UserService[action](user)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.user_action", { action: action }));
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.user_action_fail", { action: action }), err);
					user.active = failValue;
				});
		},

		setArchive(user) {
			var action = user.archived ? "archive" : "unarchive";
			var failValue = !user.archived;
			UserService[action](user)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.user_action", { action: action }));
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.user_action_fail", { action: action }), err);
					user.archived = failValue;
				});
		},

		startImport() {
			this.importModal = true;
			this.importingFile = null;
			this.importingRequests = null;
			this.importMessages = [];
			this.importStep = "file select";
			this.emailBehavior = this.emailBehaviors[0];
		},

		stopImport() {
			this.importModal = false;
			this.importingFile = null;
			this.importingRequests = null;
			this.importMessages = [];
			this.importStep = "file select";
		},

		importUsers() {
			this.importStep = "importing";
			UserService.importUsers({
				send_emails: this.emailBehavior.id,
				imports: this.importRequests,
			})
				.then((resp) => {
					let result = resp.data;

					if (result.success) {
						this.importMessages = [
							{
								result: `${result.created} created, ${result.updated} updated, ${result.error_count} errors`,
							},
							{
								message: "Import successful",
							},
						];
						this.importStep = "succeeded";
						this.refreshTable();
					} else {
						this.importMessages = [
							{
								result: `${result.created} created, ${result.updated} updated, ${result.error_count} errors`,
							},
							{
								message: "Import had errors. Please export errors for more information",
							},
						];

						_.each(result.errors, (error) => {
							this.importMessages.push({
								message: `Line ${error.line}:`,
								result: error.error,
								type: "error",
							});
						});
						this.importStep = "failed";
						this.userImportErrors = result.errors;
						console.log(this.importRequests);
						this.refreshTable();
					}
				})
				.catch((err) => {
					this.importMessages = [
						{
							message: notie.extractErrorMessage(err),
							result: "Import failed",
							type: "error",
						},
					];
					this.importStep = "failed";
				});
		},

		exportUsers() {
			BB.props({
				userExport: UserService.exportUsers(),
				client: TenantService.getClient(),
			})
				.then((resps) => {
					let filename = resps.client.data.ref_id + "_user_export_" + moment().format("YY-MM-DD") + ".csv";
					let csv = resps.userExport.data;
					FileService.downloadText(filename, csv);
					this.stopExport();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.failed_to_export_scores"), err);
				});
		},

		exportUserErrors() {
			BB.props({
				userExportErrors: UserService.exportUserErrors({ exports: this.userImportErrors }),
				client: TenantService.getClient(),
			})
				.then((resps) => {
					let filename =
						resps.client.data.ref_id + "_user_errors_export_" + moment().format("YY-MM-DD") + ".csv";
					let csv = resps.userExportErrors.data;
					FileService.downloadText(filename, csv);
					this.stopExport();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.failed_to_export_errors"), err);
				});
		},

		validateImportUsers(file) {
			console.log(file.type);
			if (file.type != "text/csv" && file.type != "application/vnd.ms-excel") {
				this.importingFile = null;
				notie.error(this.$i18n.t("notie.must_be_csv"));
				return;
			}

			this.importStep = "processing";

			var reader = new FileReader();
			reader.onload = (e) => {
				console.log("File loaded");
				var usersCSV = FileService.CSVToArray(e.target.result);
				var processed = this.processUsersCSV(usersCSV);
				this.importMessages = processed.messages;
				this.importRequests = processed.requests;
				console.log(processed);

				if (processed.num == 0) {
					this.importMessages.push({
						message: "No valid users to import",
						result: "",
						type: "error",
					});
				} else {
					this.importMessages.unshift({
						message: "",
						result: `Processing: ${processed.num}`,
					});
				}
				if (processed.proceed) {
					UserService.validateImportUsers({
						send_emails: this.emailBehavior.id,
						imports: this.importRequests,
					})
						.then((resp) => {
							let result = resp.data;

							if (result.success) {
								if (result.warnings && result.warnings.length > 0) {
									this.importMessages.push({
										message: `Warnings:`,
										type: "warning",
									});
									_.each(result.warnings, (warning) => {
										this.importMessages.push({
											message: `Line ${warning.line}:`,
											result: warning.message,
											type: "warning",
										});
									});
								}

								this.importMessages.push({
									message: `Ready to import`,
								});
								this.importStep = "processed";
							} else {
								this.importMessages.push({
									message: `Errors:`,
									type: "error",
								});
								_.each(result.errors, (error) => {
									this.importMessages.push({
										message: `Line ${error.line}:`,
										result: error.error,
										type: "error",
									});
								});
								this.importMessages.push({
									message: `Ready to import`,
								});
								this.importStep = "processed";
							}
						})
						.catch((err) => {
							this.importMessages.push({
								message: notie.extractErrorMessage(err),
								result: "Import aborted",
								type: "error",
							});
							this.importStep = "failed";
						});
				} else {
					this.importStep = "failed";
				}
			};

			reader.readAsText(file);
		},

		processUsersCSV(usersCSV) {
			var messages = [];
			if (usersCSV.length == 0) {
				messages.push({
					message: "Could not parse input CSV",
					result: "",
					type: "error",
				});
			}
			var requests = [];
			var anyErrors = false;
			var numProcessed = 0;
			for (var i = 1; i < usersCSV.length; i++) {
				let userCSV = usersCSV[i];
				let error = false;
				let req = {};
				let user = {};
				console.log("userCSV.length");
				console.log(userCSV.length);
				if (userCSV.length >= 6) {
					numProcessed++;
					req.line = i;
					user.first_name = userCSV[0];
					user.last_name = userCSV[1];
					user.scorer_id = userCSV[2];
					user.email = userCSV[3].replace(/\s/g, "");
					req.password = userCSV[4];
					req.role = userCSV[5];
					if (userCSV[6] == "1") {
						user.active = true;
					} else {
						user.active = false;
					}
					if (userCSV[7]) {
						user.school_codes = userCSV[7].split("|");
					}
					req.teams = [];

					for (let j = 8; j < userCSV.length; j++) {
						if (userCSV[j] != "") {
							req.teams.push(userCSV[j]);
						}
					}
					req.user = user;
				} else if (userCSV.length == 1 && userCSV[0] == "") {
					//Don't even display an error if there's only one empty column
					error = true;
				} else {
					numProcessed++;
					messages.push({
						message: `Line ${i}: Not enough columns`,
						result: `User not processed`,
						type: "error",
					});
					error = true;
					anyErrors = true;
				}

				if (
					!error &&
					!_.every(userCSV, (row) => {
						return row == "";
					})
				) {
					requests.push(req);
				}
			}

			return {
				requests: requests,
				messages: messages,
				proceed: requests.length > 0 && !anyErrors,
				num: numProcessed,
			};
		},

		selectImportFile(event) {
			console.log("===CHOOSE FILE===");
			console.log(event.target.files);
			if (event.target.files[0]) {
				this.importingFile = event.target.files[0];
				this.validateImportUsers(this.importingFile);
			}
		},

		selectExportFile(event) {
			console.log("===CHOOSE FILE===");
			console.log(event.target.files);
			if (event.target.files[0]) {
				this.exportingFile = event.target.files[0];
			}
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.value = "";
				el.click();
			}
		},

		removeUser(user) {
			user.inProgress = true;
			UserService.removeUser(user.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.user_deleted"));
					this.refreshTable();
					user.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.user_delete_fail"), err);
					user.inProgress = false;
					this.$forceUpdate();
				});

			this.deleting = false;
		},

		listNames(objects, title) {
			if (objects && objects.length > 0) {
				let nameList = `${sanitizeHtml(title)}:<br /><br /><ul class='pl-4 pr-3'>`;
				_.each(objects, (object) => {
					nameList += `<li style="text-align: left;">${sanitizeHtml(object.name)}</li>`;
				});

				return nameList + "</ul>";
			} else {
				return "";
			}
		},

		refreshTable() {
			this.$refs.table.refresh();
		},

		startExport() {
			this.exportModal = true;
			this.importingFile = null;
			this.importingRequests = null;
			this.importMessages = [];
			this.importStep = "file select";
			this.emailBehavior = this.emailBehaviors[0];
		},

		stopExport() {
			this.exportModal = false;
			this.exportingFile = null;
			this.exportingRequests = null;
			this.exportMessages = [];
			this.exportStep = "file select";
		},

		loginAsUser(userID) {
			AuthService.loginAs(userID, this.user.client.id)
				.then((r) => {
					let successMsg = `
<h5>${this.$i18n.t("Navbar.logged_in_as")}</h5>
<div class="text-xs text-muted" style="line-height: 1.3">${this.$i18n.t("Navbar.logged_in_as_explanation")}</div>
<div class="card px-3 py-2 mt-2" style="background-color: transparent; border-color: rgba(255, 255, 255, 0.5)">
	<div>
		<span class="_600">${this.$i18n.t("Navbar.user")}</span>:
		<span>${r.user_name}</span>
	</div>
	<div>
		<span class="_600">${this.$i18n.t("Navbar.tenant")}</span>:
		<span>${r.client_name}</span>
	</div>
</div>`;
					let notieCtx = {
						type: "success",
						text: successMsg,
						time: 15,
					};
					rawnotie.alert(notieCtx);

					this.$router.push({ path: "/landing" });
				})
				.catch((e) => {
					console.error(e);
					notie.error("Failed to log in as other user", e);
				});
		},
	},
};
</script>
