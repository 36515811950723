import axios from "axios";

export default {
	icon: "gavel",
	name: "Adjudication",
	chooseKeepScore: true,
	showScores(item) {
		return true; // just return true for now
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: false,

	getNext(ctx) {
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		var promise = axios.get(`/v1/adjudication/${sid}/${iid}`);
		promise.then(function(resp) {
			console.log("AdjudicationSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},

	submitScore(score, response, keep, forcedRepeatScore) {
		score.response_id = response.id;

		var promise = axios.post("/v1/legacy/submit", score, {
			params: { keep: keep, r: forcedRepeatScore }
		});
		promise.then(function(resp) {
			console.log("AdjudicationSD Submitted");
		});
		return promise;
	}
};
