import axios from "axios";

export default {
	getQcSet(setID) {
		return axios.get(`/v1/admin/qc_set/${setID}`);
	},
	listQCSets(sectionID, itemID, qcType) {
		return axios.get(`/v1/admin/qc_sets/${sectionID}/${itemID}/${qcType}`);
	},
	saveSet(set) {
		return axios.post(`v1/admin/qc_sets`, set);
	},
	// v1.DELETE("admin/qc_sets/:setID", api.RemoveQcSet)
	// v1.POST("admin/qc_copy", api.CopyQcSet)

	deleteSet(set) {
		return axios.delete(`/v1/admin/qc_sets/${set.id}`);
	},

	listSetResponses(setID) {
		return axios.get(`/v1/admin/qc/responses/${setID}`);
	},

	listAvailableResponses(setID) {
		return axios.get(`/v1/admin/qc/available/${setID}`);
	},

	assignToSet(responseID, setID, comment) {
		let ctx = { text: comment };
		return axios.post(`/v1/admin/qc_assign/${responseID}/${setID}`, ctx);
	},

	assignManyToSet(responseIDs, setID, includeComments) {
		return axios.post(`/v1/admin/qc_assign_many/${setID}`, {
			rIDs: responseIDs,
			ic: includeComments,
		});
	},

	saveQcResponse(resp) {
		return axios.post(`/v1/admin/qc_response`, resp);
	},

	deleteQcResponse(resp) {
		return axios.delete(`/v1/admin/qc_response/${resp.id}`);
	},

	addQCResponseFile(sectionID, itemID, qcType, file, width, height) {
		let form = new FormData();
		form.set("file", file);
		form.set("width", width);
		form.set("height", height);

		return axios.post(`/v1/admin/qc_media/${sectionID}/${itemID}/${qcType}`, form);
	},

	addQCResponseFiles(sectionID, itemID, qcType, files, folderName) {
		let form = new FormData();
		_.each(files, (file) => {
			form.append("file", file);
		});

		return axios.post(`/v1/admin/qc_media_multi/${sectionID}/${itemID}/${qcType}/${folderName}`, form);
	},

	uploadResponses(formData) {
		return axios.post(`/v1/admin/qc_responses`, formData, {
			headers: { "Content-Type": undefined },
		});
	},

	uploadContentPackage(formData) {
		return axios.post("/v1/admin/qc_content_package", formData, {
			headers: { "Content-Type": undefined },
		});
	},

	copySet(request) {
		return axios.post("/v1/admin/qc_copy", request);
	},

	exportSetsToCSV(sectionID, itemID) {
		return axios.get(`/v1/admin/export/qc_sets/${sectionID}/${itemID}`);
	},
};
