<template>
	<div class="box">
		<div class="padding">
			<h5 class="_600">
				<span class="float-right text-muted">
					<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
						<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
					</a>
				</span>
				<span v-if="stats.total">{{ stats.total.toLocaleString() }}</span>
				<span v-if="!stats.total" class="text-very-muted">——</span>
			</h5>
			<h6 class="text-muted mb-4">{{ $t("fields.responses") }}</h6>
			<div class="py-3">
				<canvas id="projectdash-doughnut" v-chartjs="chartData" style="height: 223px"></canvas>
			</div>
		</div>
	</div>
</template>

<style scoped>
a {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a:hover {
	opacity: 1;
}
table {
	width: 100%;
}
td {
	padding-bottom: 2rem;
}
</style>

<script>
import DashboardService from "@/services/DashboardService";
import fs from "@/services/FormatService";
require("@/directives/chartjs");

export default {
	name: "ProjectDashCompletion",

	props: ["project", "labels"],

	data() {
		return {
			fs: fs,
			busy: false,
			stats: {},
			chartData: {
				type: "doughnut",
				data: {
					labels: ["Loading"],
					datasets: [
						{
							data: [0],
							borderColor: "transparent",
							backgroundColor: ["green", "orange", "red"],
						},
					],
				},
				options: {
					tooltips: {
						mode: "point",
						titleFontSize: 14,
						bodyFontSize: 14,
						callbacks: {
							label: (item, data) => {
								let i = item.datasetIndex;
								let j = item.index;

								let val = data.datasets[i].data[j];
								let label = data.labels[j];
								return ` ${label}:`;
							},
							afterLabel: (item, data) => {
								let i = item.datasetIndex;
								let j = item.index;
								console.log(item, item.datasetIndex, i, data.datasets[i]);
								let val = data.datasets[i].data[j];
								return ` ${val} (${fs.calcPercent1d(val, this.stats.total)})`;
							},
						},
					},
					maintainAspectRatio: false,
					legend: {
						position: "right",
						labels: {
							boxWidth: 12,
						},
					},
					cutoutPercentage: 70,
				},
			},
		};
	},
	created() {
		this.loadData();
	},
	watch: {
		"project.id"() {
			this.loadData();
		},
		labels() {
			this.loadData();
		},
	},
	methods: {
		loadData() {
			if (!this.project) {
				return;
			}
			if (this.busy) {
				return;
			}

			this.busy = true;
			DashboardService.getProjectCompletionTotals(this.project.id, this.labels)
				.then((resp) => {
					this.stats = resp.data;
					this.stats.pending = this.stats.total - (this.stats.complete + this.stats.withheld);
					this.loadChartData(this.stats);
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});
		},

		loadChartData(stats) {
			this.chartData.data.datasets[0].data[0] = stats.complete;
			this.chartData.data.labels[0] = this.$i18n.t("data_description.complete");
			this.chartData.data.datasets[0].data[1] = stats.pending;
			this.chartData.data.labels[1] = this.$i18n.t("data_description.pending");
			this.chartData.data.datasets[0].data[2] = stats.withheld;
			this.chartData.data.labels[2] = this.$i18n.t("data_description.withheld");
		},
	},
};
</script>
