<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">
			{{ $t("ItemEdit.Copyleaks.title") }}
		</div>

		<!-- Copyleaks -->
		<div v-if="item.copyleaks_config" class="row m-0 pt-4">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600">
					{{ $t("ItemEdit.Copyleaks.enabled") }}
				</div>
				<div class="form-group">
					<label v-if="item.copyleaks_config" class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.copyleaks_config.enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-if="item.copyleaks_config && item.copyleaks_config.enabled">
				<div class="row">
					<div class="col-md-5 col-6 form-group">
						<label>{{ $t("ItemEdit.Copyleaks.credential") }}</label>
						<v-select
							:options="creds"
							label="cred_id"
							v-model="item.copyleaks_config.credential_id"
							:reduce="(o) => o.id"
						/>
					</div>
					<div class="col-md-3 col-6 form-group">
						<label>{{ $t("ItemEdit.Copyleaks.repository_id") }}</label>
						<input class="form-control" type="text" v-model="item.copyleaks_config.repository_id" />
					</div>
				</div>

				<hr />
				<div class="row">
					<div class="col-md-5 col-6 form-group">
						<label
							v-tippy="{ theme: 'popover', placement: 'left' }"
							:title="$t('ItemEdit.Copyleaks.withhold_responses_tooltip')"
						>
							{{ $t("ItemEdit.Copyleaks.withhold_responses") }}
						</label>
						<v-select
							:options="thresholdTypes"
							v-model="item.copyleaks_config.flag_threshold_type"
							:reduce="(o) => o.id"
						/>
					</div>
					<div v-if="item.copyleaks_config.flag_threshold_type" class="col-md-3 col-6 form-group">
						<label>
							{{ $t("ItemEdit.Copyleaks.threshold_value") }}
						</label>
						<input
							class="form-control"
							type="number"
							v-model.number="item.copyleaks_config.flag_threshold"
						/>
					</div>
				</div>
				<div class="row" v-if="item.copyleaks_config.flag_threshold_type">
					<div class="col-md-4 col-6 form-group">
						<label>
							{{ $t("ItemEdit.Copyleaks.flag") }}
						</label>
						<config-select
							:options="flagOptions"
							v-model="item.copyleaks_config.flag_threshold_id"
							byField="id"
						/>
					</div>
					<div class="col-md-4 col-6 form-group">
						<label>
							{{ $t("ItemEdit.Copyleaks.system_user") }}
						</label>
						<config-user-search v-model="flagUser" :clientID="client.id" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
</style>

<script>
import ConfigSelect from "@/components/ConfigSelect";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import TenantService from "@/services/TenantService";
import UserService from "@/services/UserService";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import _ from "lodash";

export default {
	name: "ItemEditCopyleaks",

	props: ["id", "item", "client", "defaultTab", "user", "valid"],

	components: { ConfigSelect, ConfigUserSearch },

	data() {
		let thresholdTypes = [
			{ label: this.$i18n.t("data_description.none"), id: 0 },
			{ label: this.$i18n.t("Copyleaks.identical_words"), id: 1 },
			{ label: this.$i18n.t("Copyleaks.minor_changed_words"), id: 2 },
			{ label: this.$i18n.t("Copyleaks.paraphrased_words"), id: 3 },
			{ label: this.$i18n.t("Copyleaks.overall_similarity_score"), id: 4 },
		];

		return {
			creds: [],
			thresholdTypes: thresholdTypes,
			flagUser: null,
		};
	},

	created() {
		if (this.item.copyleaks_config && this.item.copyleaks_config.enabled) {
			this.enabled = true;
		}
		this.loadData();
		if (this.item.copyleaks_config && this.item.copyleaks_config.flag_user_id) {
			this.initFlagUser();
		}
	},

	computed: {
		flagOptions() {
			let alerts = _.filter(this.item.avail_alerts, { requires_score: false });
			let options = [];
			for (let alert of alerts) {
				options.push({ id: alert.alert_id, name: alert.code, desc: alert.description });
			}
			return options;
		},
	},

	watch: {
		flagUser() {
			if (!this.item.copyleaks_config) {
				this.item.copyleaks_config = {};
			}
			if (this.flagUser) {
				if (this.item.copyleaks_config.flag_user_id != this.flagUser.id) {
					this.item.copyleaks_config.flag_user_id = this.flagUser.id;
				}
			} else {
				if (this.item.copyleaks_config.flag_user_id) {
					this.item.copyleaks_config.flag_user_id = null;
				}
			}
		},
		"item.copyleaks_config.flag_user_id"() {
			this.initFlagUser();
		},
	},

	methods: {
		loadData() {
			TenantService.listCredentialsSafe(this.user.client.id)
				.then((r) => {
					this.creds = r.data;
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to load credentials", e);
				});
		},

		initFlagUser() {
			if (this.item.copyleaks_config.flag_user_id) {
				UserService.getUser(this.item.copyleaks_config.flag_user_id).then((r) => {
					this.flagUser = r.data;
					this.flagUser.name = fs.nameWithScorerID(this.flagUser);
				});
			} else {
				this.flagUser = null;
			}
		},
	},
};
</script>
