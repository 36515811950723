<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }">
		<div class="p-4 b-b _600 nav-title d-flex flex-row align-items-center">
			{{ $t("TenantEdit.ConfigSheets.title") }}
			<button
				@click="addConfigSheet"
				class="btn btn-success ml-auto"
				style="margin-top: -8px; margin-bottom: -8px"
			>
				<i class="fas fa-plus mr-1" />{{ $t("TenantEdit.ConfigSheets.add_sheet") }}
			</button>
		</div>
		<div class="px-4 pb-4">
			<h5
				v-if="!(client && client.config_sheets && client.config_sheets.length > 0)"
				class="text-center text-very-muted"
			>
				{{ $t("TenantEdit.ConfigSheets.no_sheets") }}
			</h5>
			<div class="row" ref="sheetsContainer" v-if="client.config_sheets && client.config_sheets.length > 0">
				<div class="col-12 col-xl-6 section-card" v-for="sheet in client.config_sheets" :key="sheet.id">
					<div class="box section mb-0 mt-4">
						<div class="box-header theme-lighten-3 d-flex flex-row">
							<div class="d-flex flex-row w-100">
								<div class="header-control-container" style="width: 66%">
									<label class="header-label">{{ $t("TenantEdit.ConfigSheets.sheet_name") }}</label>
									<input class="form-control" v-model="sheet.name" placeholder="Sheet Name" />
								</div>
								<div class="ml-2 header-control-container" style="width: 34%">
									<label class="header-label">{{ $t("TenantEdit.ConfigSheets.type") }}</label>
									<v-select
										style=""
										class="header-dropdown drop-up"
										:options="collectionOptions"
										v-model="sheet.acts_on"
										label="name"
										:reduce="(o) => o.id"
										appendToBody
									></v-select>
								</div>
							</div>
						</div>
						<div class="corner-button-container">
							<button
								class="btn btn-sm btn-icon btn-rounded btn-danger"
								@click="removeConfigSheet(sheet)"
							>
								<i class="fas fa-trash" v-tippy :title="$t('TenantEdit.ConfigSheets.remove_sheet')" />
							</button>
						</div>
						<div
							v-if="sheet.columns && sheet.columns.length > 0"
							class="p-3 text-center d-flex flex-row scroll-x"
						>
							<draggable
								class="d-flex flex-row spread-table mx-auto"
								v-model="sheet.columns"
								:element="'div'"
							>
								<div v-for="(column, i) in sheet.columns" :key="i">
									<div class="spread-cell spread-cell-header">
										{{ fs.spreadsheetColLetter(i) }}
									</div>
									<div v-if="column.selectedOption" class="spread-cell spread-cell-content">
										{{ column.selectedOption.name }}
									</div>
								</div>
							</draggable>
						</div>
						<div class="p-0">
							<table class="table mb-0">
								<thead>
									<tr>
										<th>
											<button
												v-if="!getFirstUnusedOption(sheet)"
												class="btn btn-success btn-sm btn-icon btn-rounded btn-table-header"
												@click="addSheetColumn(sheet)"
												disabled
											>
												<i class="fas fa-plus" />
											</button>
											<button
												v-else
												class="btn btn-success btn-sm btn-icon btn-rounded btn-table-header"
												@click="addSheetColumn(sheet)"
											>
												<i
													class="fas fa-plus"
													v-tippy
													:title="$t('TenantEdit.ConfigSheets.add_column')"
												/>
											</button>
										</th>
										<th style="text-align: center">
											{{ $t("TenantEdit.ConfigSheets.columns") }}
											<i
												v-if="!hasAllRequiredFields(sheet)"
												class="fas fa-exclamation-triangle text-warning"
												v-tippy="{ theme: 'popover' }"
												:title="newRecordsNotAllowedTooltip(sheet)"
											/>
											<i
												v-else
												class="fas fa-check text-success"
												v-tippy="{ theme: 'popover' }"
												:title="newRecordsAllowedTooltip(sheet)"
											/>
										</th>
									</tr>
								</thead>
								<tbody v-if="!(sheet.columns && sheet.columns.length > 0)">
									<tr>
										<td class="v-mid text-center text-muted" colspan="4">
											{{ $t("TenantEdit.ConfigSheets.no_columns") }}
										</td>
									</tr>
								</tbody>
								<draggable
									v-model="sheet.columns"
									:options="{ handle: '.drag-handle' }"
									:element="'tbody'"
								>
									<tr v-for="(column, i) in sheet.columns" :key="i">
										<td class="v-mid" style="width: 10px">
											<div class="d-flex flex-row align-items-center drag-handle">
												<i class="far fa-sort ml-2 mr-3" />{{ fs.spreadsheetColLetter(i) }}
											</div>
										</td>
										<td class="v-mid">
											<modal-select
												:options="CSSvc.getColumnOptions(sheet.acts_on)"
												v-model="column.selectedOption"
												:disabled="column.selectedOption && column.selectedOption.required"
												label="name"
												:id="`${sheet.id}-${
													column.selectedOption ? column.selectedOption.id : 'blank'
												}`"
											>
												<template #modal="{ options, select }">
													<div
														class="modal-selection-option d-flex flex-row"
														:class="{ disabled: sheetHasOption(sheet, option) }"
														v-for="(option, i) of options"
														:key="i"
														@click="select(option)"
													>
														<span class="position-relative">
															<div
																v-if="
																	column.selectedOption &&
																	column.selectedOption.id == option.id
																"
																class="modal-selection-option-margin"
															>
																<i class="fas fa-lg fa-angle-right" />
															</div>
															{{ option.name }}
														</span>
														<span class="ml-auto">
															<i
																class="text-muted"
																:class="{
																	[sheetHasOption(sheet, option)
																		? 'far'
																		: 'fas']: true,
																	[fs.dataTypeIcon(option.type)]: true,
																}"
																v-tippy
																:title="
																	$t(
																		`TenantEdit.ConfigSheets.data_tooltip_${option.type}`
																	)
																"
															/>
														</span>
													</div>
												</template>
											</modal-select>
										</td>
										<td class="v-mid" style="width: 1px; padding-left: 0">
											<button
												v-if="column.selectedOption && column.selectedOption.required"
												class="btn btn-danger btn-sm btn-icon btn-rounded"
												disabled
											>
												<i
													class="fas fa-minus"
													v-tippy
													:title="$t('TenantEdit.ConfigSheets.required_column')"
												/>
											</button>
											<button
												v-else
												class="btn btn-danger btn-sm btn-icon btn-rounded"
												@click="removeSheetColumn(sheet, column)"
											>
												<i
													class="fas fa-minus"
													v-tippy
													:title="$t('TenantEdit.ConfigSheets.remove_column')"
												/>
											</button>
										</td>
									</tr>
								</draggable>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.invalid {
	color: red;
}
.btn-table-header {
	height: 29px;
	margin-top: -10px;
	margin-bottom: -8px;
	padding-top: 3px;
}

.header-control-container {
	margin-top: -8px;
}
.header-label {
	margin-bottom: 4px;
	color: white;
}
.header-dropdown {
	background-color: white;
	min-width: 110px;
	border-color: rgba(120, 130, 140, 0.2);
}

.spread-table {
	box-shadow: -1px -1px 3px #aaa;
}
.spread-cell {
	padding-left: 2px;
	padding-right: 2px;
	border-bottom: 1px solid #aaa;
	border-right: 1px solid #aaa;
	min-width: 75px;
	cursor: ew-resize;
}
.spread-cell-header {
	text-align: center;
	background-color: #e0e0e0;
}
.spread-cell-content {
	text-align: left;
	font-weight: 600;
	white-space: nowrap;
}
.drag-handle {
	cursor: ns-resize;
}

.corner-button-container {
	position: absolute;
	top: 6px;
	right: 6px;
	transform: translate(50%, -50%);
}
</style>

<script>
import ConfigSheetService from "@/services/ConfigSheetService";
import ModalSelect from "@/components/ModalSelect";
import fs from "@/services/FormatService";
import Utils from "@/services/Utils";
import Notie from "@/services/NotieService";
import draggable from "vuedraggable";
import _ from "lodash";

export default {
	name: "ItemEditDetails",

	components: {
		ModalSelect,
		draggable,
	},

	props: ["client", "valid", "defaultTab"],

	data() {
		return {
			CSSvc: ConfigSheetService,
			fs: fs,
			collectionOptions: ConfigSheetService.collectionOptions(),
		};
	},

	watch: {},

	created() {},

	computed: {},

	methods: {
		addConfigSheet() {
			if (!this.client.config_sheets) {
				this.client.config_sheets = [];
			}

			let id = Utils.generateUUID();
			let newSheet = {
				id: id,
				name: "New Sheet",
				acts_on: "items",
				columns: [],
			};
			this.addRequiredColumns(newSheet);

			this.client.config_sheets.push(newSheet);
		},

		removeConfigSheet(sheet) {
			let index = this.client.config_sheets.indexOf(sheet);
			if (index < 0) return;
			this.$delete(this.client.config_sheets, index);
		},

		addRequiredColumns(sheet) {
			let collectionOption = _.find(ConfigSheetService.collectionOptions(), { id: sheet.acts_on });
			if (!collectionOption) {
				console.error(`Failed to find collection type "${sheet.acts_on}"`);
				return;
			}

			let options = ConfigSheetService.getColumnOptions(sheet.acts_on);
			for (let reqField of collectionOption.requiredFields) {
				let option = _.find(options, { id: reqField });
				if (!option) {
					console.error(`Failed to find option "${reqField}"`);
				}

				let exists = _.find(sheet.columns, (c) => {
					c.selectedOption && c.selectOption.id == reqField;
				});
				if (!exists) {
					sheet.columns.push({ selectedOption: option });
				}
			}
		},

		addSheetColumn(sheet) {
			let option = this.getFirstUnusedOption(sheet);
			if (!option) {
				Notie.error("Failed to add column", "There are no other column possibilities");
				return;
			}
			sheet.columns.push({ selectedOption: option });
			this.$nextTick(() => {
				this.clickSelector(sheet, option);
			});
		},

		clickSelector(sheet, option) {
			let id = `${sheet.id}-${option.id}`;
			let el = document.getElementById(id);
			if (!el) {
				console.error(`Failed to get selector with id ${id}`);
				return;
			}
			el.click();
		},

		getFirstUnusedOption(sheet) {
			let options = ConfigSheetService.getColumnOptions(sheet.acts_on);
			for (let option of options) {
				if (!this.sheetHasOption(sheet, option)) {
					return option;
				}
			}
		},

		sheetHasOption(sheet, option) {
			for (let column of sheet.columns) {
				if (column.selectedOption && column.selectedOption.id == option.id) {
					return true;
				}
			}
		},

		removeSheetColumn(sheet, column) {
			let index = sheet.columns.indexOf(column);
			if (index < 0) return;
			this.$delete(sheet.columns, index);
		},

		hasAllRequiredFields(sheet) {
			let collectionOption = _.find(ConfigSheetService.collectionOptions(), { id: sheet.acts_on });
			if (!collectionOption) {
				console.error(`Unknown collection type "${sheet.acts_on}"`);
				return;
			}

			let hasFields = [];
			for (let column of sheet.columns) {
				if (column && column.selectedOption) {
					hasFields.push(column.selectedOption.id);
				}
			}

			for (let requiredField of collectionOption.requiredFieldsForNew) {
				if (!hasFields.includes(requiredField)) {
					return false;
				}
			}
			return true;
		},

		newRecordsNotAllowedTooltip(sheet) {
			let collectionOption = _.find(ConfigSheetService.collectionOptions(), { id: sheet.acts_on });
			if (!collectionOption) {
				return `Unknown collection type "${sheet.acts_on}"`;
			}

			let text = `<div class="text-left p-1">`;
			text += this.$i18n.t("TenantEdit.ConfigSheets.new_records_not_allowed_tooltip", {
				collection: collectionOption.name,
			});
			text += `<ul class="mb-0">`;
			for (let column of collectionOption.requiredFieldsForNew) {
				let handler = ConfigSheetService.getHandler(sheet.acts_on, column);
				if (handler) {
					text += `<li>${handler.displayName}</li>`;
				} else {
					text += `<li>UNKNOWN FIELD ${column}</li>`;
				}
			}
			text += "</ul>";
			text += "</div>";

			return text;
		},

		newRecordsAllowedTooltip(sheet) {
			let collectionOption = _.find(ConfigSheetService.collectionOptions(), { id: sheet.acts_on });
			if (!collectionOption) {
				return `Unknown collection type "${sheet.acts_on}"`;
			}

			let text = `<div class="text-left p-1">`;
			text += this.$i18n.t("TenantEdit.ConfigSheets.new_records_allowed_tooltip", {
				collection: collectionOption.name,
			});
			text += "</div>";

			return text;
		},
	},
};
</script>
