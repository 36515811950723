import axios from "axios"

export default {
	get(vm, url, config) {
		if (!config) {
			config = {}
		}
		if (vm.environment) {
			config.headers = {
				"X-Mongo-Connection": vm.environment.connection,
				"X-Mongo-Database": vm.environment.db,
			};
		}

		return axios.get(url, config)
	},

	post(vm, url, ctx, config) {
		if (!config) {
			config = {}
		}
		if (vm.environment) {
			config.headers = {
				"X-Mongo-Connection": vm.environment.connection,
				"X-Mongo-Database": vm.environment.db,
			};
		}

		return axios.post(url, ctx, config)
	}
}