<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			Setup Performance Test
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<div class="col-12 col-sm-6">
				<div class="form-group">
					<label>Client</label>
					<v-select :options="clients || []" v-model="selectedClient">
						<template slot="selected-option" slot-scope="option">
							<span>{{ option.name }}</span>
							<span class="ml-1 text-muted text-xxs">({{ option.ref_id }})</span>
						</template>
						<template slot="option" slot-scope="option">
							<span>{{ option.name }}</span>
							<div class="text-muted pb-1" style="line-height: 0.85rem">
								{{ option.ref_id }} • {{ option.id }}
							</div>
						</template>
					</v-select>
				</div>
				<div v-for="(queue, i) in queues" class="d-flex flex-row" :key="i">
					<div class="flex form-group">
						<label>Section</label>
						<v-select
							:options="sections"
							v-model="queue.selectedSection"
							:disabled="sections.length == 0"
							label="name"
							@input="sectionSelected(queue)"
						>
						</v-select>
					</div>
					<div class="flex form-group ml-3">
						<label>Item</label>
						<v-select
							:options="queue.items"
							v-model="queue.selectedItem"
							:disabled="queue.items.length == 0"
							label="name"
						>
						</v-select>
					</div>
					<div class="form-group ml-3">
						<label>Weight</label>
						<input class="form-control" style="width: 70px" type="number" v-model.number="queue.weight" />
					</div>
					<div class="form-group align-self-end">
						<button @click="removeQueue(queue)" class="ml-3 btn btn-danger" :disabled="queues.length == 1">
							<i class="fas fa-minus" />
						</button>
					</div>
				</div>
				<div class="form-group d-flex flex-row">
					<button class="btn btn-success ml-auto" @click="addQueue">
						<i class="fas fa-plus mr-1" />Add Queue
					</button>
				</div>
				<div class="form-group">
					<label>User Role</label>
					<v-select :options="roles" v-model="selectedRole" :disabled="!selectedClient" label="name">
					</v-select>
				</div>
				<div class="row">
					<div class="col-6">
						<div class="form-group">
							<label>User Count</label>
							<input class="form-control" type="number" v-model.number="userCount" />
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label>Response Count</label>
							<input class="form-control" type="number" v-model.number="responseCount" />
						</div>
					</div>
				</div>
				<button v-if="!running && canRun" @click="showConfirmation = true" class="btn btn-success">Run</button>
				<button v-if="!running && !canRun" class="btn btn-secondary" disabled>Run</button>
				<button v-if="running" class="btn btn-success" disabled>
					<loading type="icon" class="mr-1" />Running...
				</button>
			</div>
			<div v-if="result" class="col-12 col-sm-6">
				<hr class="d-sm-none" />
				<label>Result</label>
				<div class="result-panel p-3">
					<pre v-if="result" class="form-control mb-0">{{ result }}</pre>
				</div>
			</div>
		</div>

		<b-modal id="environmentsModal" size="md" :visible="showConfirmation" @hide="showConfirmation = false">
			<template slot="modal-header">
				<h5 class="modal-title pl-3">Clear Responses</h5>
			</template>

			<div class="modal-scroll">
				<h5 class="mb-3">
					<i class="fas fa-exclamation-triangle text-danger mr-1" style="font-size: 22px" />
					<span v-if="queues.length == 1" class="text-danger"
						>All scores on responses in this Section / Item will be deleted!</span
					>
					<span v-if="queues.length > 1" class="text-danger"
						>All scores on responses in the following Section / Item queues will be deleted!</span
					>
				</h5>
				<div v-if="environment">
					<span class="_600 text-danger">Environment:</span> {{ environment.name }}
					<span class="text-xxs text-muted">({{ environment.db }})</span>
				</div>
				<div v-if="selectedClient">
					<span class="_600">Tenant:</span> {{ selectedClient.name }}
					<span class="text-xxs text-muted">({{ selectedClient.ref_id }})</span>
				</div>
				<template v-for="(queue, i) in queues">
					<hr :key="`break-${i}`" class="my-1" />
					<div v-if="queue.selectedSection" :key="`section-${i}`">
						<span class="_600">Section:</span> {{ queue.selectedSection.name }}
						<span class="text-xxs text-muted">({{ queue.selectedSection.ref_id }})</span>
					</div>
					<div v-if="queue.selectedItem" :key="`item-${i}`">
						<span class="_600">Item:</span> {{ queue.selectedItem.name }}
						<span class="text-xxs text-muted">({{ queue.selectedItem.ref_id }})</span>
					</div>
				</template>
				<div class="mt-3">Are you sure you want to continue?</div>
			</div>

			<template slot="modal-footer">
				<button class="btn btn-secondary btn-flat" @click="showConfirmation = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn btn-success btn-flat" @click="setupPerfTest">{{ $t("buttons.continue") }}</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import axios from "axios";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import ConfigDate from "@/components/ConfigDate";
import ConfigSelect from "@/components/ConfigSelect";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "SetupPerfTest",
	props: ["environment", "clients"],
	components: { ConfigDate, ConfigSelect, UserTimezone },

	data() {
		return {
			expanded: Store.get(this, "adminTasks.setupPerfTest.expanded"),
			running: false,
			showConfirmation: false,

			selectedClient: null,
			sections: [],
			queues: [
				{
					selectedSection: null,
					items: [],
					selectedItem: null,
					weight: 1,
				},
			],
			roles: [],
			selectedRole: null,
			userCount: Store.getDefault(this, "adminTasks.setupPerfTest.userCount", 100),
			responseCount: Store.getDefault(this, "adminTasks.setupPerfTest.responseCount", 10000),

			result: null,
		};
	},

	created() {},

	mounted() {},

	watch: {
		clients() {
			this.selectedClient = Store.resolveFromList(
				this,
				this.clients,
				"id",
				0,
				"adminTasks.setupPerfTest.selectedClient"
			);
			console.log("SELECTED CLIENT", this.selectedClient);
			if (this.selectedClient && this.selectedClient.id) {
				this.loadSections();
				this.loadRoles();
			}
		},
		expanded() {
			Store.set(this, "adminTasks.setupPerfTest.expanded", this.expanded);
		},
		selectedClient() {
			if (this.selectedClient && this.selectedClient.id) {
				Store.set(this, "adminTasks.setupPerfTest.selectedClient", this.selectedClient.id);
				this.loadSections();
				this.loadRoles();
				this.resetResults();
			}
		},
		selectedRole() {
			if (this.selectedRole) {
				Store.set(this, "adminTasks.setupPerfTest.selectedRole", this.selectedRole.id);
				this.resetResults();
			}
		},
		selectedItem() {
			if (this.selectedItem && this.selectedItem.id) {
				this.resetResults();
			}
		},
		userCount() {
			Store.set(this, "adminTasks.setupPerfTest.userCount", this.userCount);
			this.resetResults();
		},
		responseCount() {
			Store.set(this, "adminTasks.setupPerfTest.responseCount", this.responseCount);
			this.resetResults();
		},
	},

	computed: {
		canRun() {
			for (let q of this.queues) {
				if (!(q.selectedSection && q.selectedItem)) {
					return false;
				}
			}

			return this.selectedClient && this.selectedRole && this.userCount && this.responseCount;
		},
	},

	methods: {
		sectionSelected(queue) {
			this.loadItems(queue);
		},

		addQueue() {
			let newQueue = { selectedSection: null, items: [], selectedItem: null, weight: 1 };
			if (this.sections && this.sections.length > 0) {
				newQueue.selectedSection = this.sections[0];
				this.loadItems(newQueue);
			}

			this.queues.push(newQueue);
		},

		removeQueue(queue) {
			this.queues = _.without(this.queues, queue);
		},

		loadSections() {
			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.get(`/v1/admin_stats/client/${this.selectedClient.id}/sections`, config)
				.then((r) => {
					let sections = r.data;
					this.sections = sections;

					if (this.sections.length == 0) {
						return;
					}

					for (let queue of this.queues) {
						if (!queue.selectedSection || !_.find(this.sections, { id: queue.selectedSection.id })) {
							queue.selectedSection = this.sections[0];
							this.loadItems(queue);
						}
					}
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to load sections", e);
				});
		},

		loadRoles() {
			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.get(`/v1/admin_stats/client/${this.selectedClient.id}`, config)
				.then((r) => {
					let client = r.data;
					this.roles = client.roles;

					let stored = Store.get(this, "adminTasks.setupPerfTest.selectedRole");
					console.log("STORED ROLE", stored);

					this.selectedRole = Store.resolveFromList(
						this,
						this.roles,
						"id",
						0,
						"adminTasks.setupPerfTest.selectedRole"
					);
				})
				.catch((e) => {
					console.error(e);
					this.selectedProject = null;
				});
		},

		loadItems(queue) {
			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.get(`/v1/admin_stats/client/${this.selectedClient.id}/section/${queue.selectedSection.id}/items`)
				.then((r) => {
					let section = r.data;
					queue.items = section.items;

					if (!queue.selectedItem || !_.find(queue.items, { id: queue.selectedItem.id })) {
						queue.selectedItem = queue.items[0];
					}
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to load items", e);
					queue.selectedItem = null;
				});
		},

		resetResults() {
			this.result = null;
		},

		setupPerfTest() {
			this.showConfirmation = false;
			this.result = null;
			this.running = true;

			let queueCtxs = [];
			for (let queue of this.queues) {
				queueCtxs.push({
					section_id: queue.selectedSection.id,
					item_id: queue.selectedItem.id,
					weight: queue.weight,
				});
			}

			let ctx = {
				client_id: this.selectedClient && this.selectedClient.id,
				queues: queueCtxs,
				user_role_id: this.selectedRole && this.selectedRole.id,
				user_count: this.userCount,
				response_count: this.responseCount,
			};

			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.post("/v1/admin_tasks/setup_perf_test", ctx, config)
				.then((r) => {
					this.running = false;
					this.result = r.data;
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to setup performance test", e);
				});
		},
	},
};
</script>
