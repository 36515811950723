<template>
	<div id="aside" class="app-aside fade shadow white nav-expand white" aria-hidden="true">
		<sidenav-preview
			class="side-nav"
			v-if="user.role"
			:role="user.role"
			:configSheets="user.client.config_sheets"
			:unreadMail="unreadMail"
			:importantMail="importantMail"
			:meetingCount="meetingCount"
			link
		></sidenav-preview>
	</div>
</template>

<script>
import _ from "lodash";
import $ from "jquery";
import Notie from "@/services/NotieService";
import AuthService from "@/services/AuthService";
import MailService from "@/services/MailService";
import VideoMeetingService from "@/services/VideoMeetingService";

import SidenavPreview from "@/components/roles_edit/SidenavPreview";

export default {
	name: "Sidenav",
	components: {
		SidenavPreview,
	},
	props: ["important", "unread", "refresh"],
	data() {
		return {
			user: {},
			unreadMail: 0,
			importantMail: 0,

			videoMeetings: null,
			meetingCount: 0,
		};
	},
	created() {
		this.loadUser();
	},

	mounted() {
		$(window).on("resize.sidenav", function () {
			var $w = $(window).width(),
				$lg = 1200,
				$md = 991,
				$sm = 768;
			if ($w > $lg) {
				$(".aside-lg").modal("hide");
			}
			if ($w > $md) {
				$("#aside").modal("hide");
				$(".aside-md, .aside-sm").modal("hide");
			}
			if ($w > $sm) {
				$(".aside-sm").modal("hide");
			}
		});
	},
	beforeDestroy() {
		this.destroyModalBackdrop();
	},
	destroyed() {
		$(window).off("resize.sidenav");
	},

	watch: {
		important() {
			this.importantMail = this.important;
		},
		unread() {
			this.unreadMail = this.unread;
		},
		refresh() {
			console.log("REFRESH SIDENAV", this.refresh);
			AuthService.queueRefresh();
			this.loadUser();
		},
	},
	methods: {
		loadUser() {
			AuthService.getUser().then((user) => {
				this.user = user;
				if (user.role.page_mail) {
					this.loadMailCounts();

					// mockup
					// this.user.client.config_sheets = [
					// 	{
					// 		id: "000000000000000000000001",
					// 		name: "Item Spreadsheet",
					// 		acts_on: "items",
					// 		columns: ["ref_id", "name", "active", "rubric_id", "type", "reliability_percent"],
					// 	},
					// ];
				}
				if (user.role.page_video_meetings) {
					this.loadVideoMeetingNotifs();
				}
			});
		},

		loadMailCounts() {
			MailService.getUnread()
				.then((resp) => {
					this.unreadMail = resp.data.unread;
					this.importantMail = resp.data.important;
				})
				.catch((err) => {});
		},

		async loadVideoMeetingNotifs() {
			try {
				let { data } = await VideoMeetingService.getMyMeetings();
				this.videoMeetings = data.meetings;

				this.meetingCount = 0;
				for (let meeting of this.videoMeetings) {
					if (VideoMeetingService.meetingInProgress(meeting)) {
						this.meetingCount++;
					}
				}
			} catch (e) {
				let errMsg = "Failed to get video meetings";
				this.logError(errMsg, e);
				Notie.error(errMsg, e);
			}
		},

		destroyModalBackdrop() {
			$("body").removeClass("modal-open");
			$(".modal-backdrop").remove();
		},
	},
};
</script>

<style scoped>
/* Side nav visible */
@media (min-width: 992px) {
	.app-aside {
		min-height: calc(100% - 112px);
	}
}

.nav-expand.show {
	padding-top: 3.5rem;
}

.side-nav {
	height: calc(100% - 112px);
}
</style>
