<!-- Template for a new page -->
<template>
	<div
		id="videoFeeds"
		ref="videoFeeds"
		class="flex d-flex flex h-100 video-feeds"
		:class="{ 'flex-column': layoutDir == 'h', 'flex-row': layoutDir == 'v' }"
	>
		<div
			v-if="participants.length > 0"
			class="video-feed-container"
			:key="participants[0].uid"
			:style="{ width: `${focusFeedWidth}px`, height: `${focusFeedHeight}px` }"
		>
			<video-feed
				:participant="participants[0]"
				:local="participants[0].local"
				@videoResize="focusedVideoResized"
				class="video-feed"
			/>
		</div>
		<div
			class="position-relative"
			style="overflow: hidden"
			:class="{
				'h-100 video-feed-list-pad-bottom': layoutDir == 'v',
				'w-100 video-feed-list-pad-right': layoutDir == 'h',
			}"
		>
			<div
				v-if="participants.length >= 1"
				class="d-flex"
				:class="{
					'h-100 flex-column scroll-y': layoutDir == 'v',
					'flex-row scroll-x': layoutDir == 'h',
				}"
				@scroll="feedsScrolled"
				ref="scrollContainer"
			>
				<div
					v-if="participants.length <= 1 && layoutDir == 'v'"
					:style="{ width: `${feedWidth - 10}px`, height: `100%` }"
					class="waiting-container text-center"
				>
					<h3 class="mb-0 text-very-muted">Waiting for more users...</h3>
				</div>
				<div
					v-if="participants.length <= 1 && layoutDir == 'h'"
					:style="{ height: `${feedHeight - 10}px`, width: `100%` }"
					class="waiting-container"
				>
					<h3 class="mb-0 text-very-muted">Waiting for more users...</h3>
				</div>
				<template v-for="(parti, i) in participants">
					<div
						v-if="i > 0"
						:key="parti.uid"
						class="video-feed-container"
						:style="{
							width: `${feedWidth}px`,
							minWidth: `${feedWidth}px`,
							height: `${feedHeight}px`,
							minHeight: `${feedHeight}px`,
						}"
						:class="{
							'mb-0': layoutDir == 'v' && i == participants.length - 1,
							'mr-0': layoutDir == 'h' && i == participants.length - 1,
						}"
					>
						<video-feed :participant="parti" class="video-feed" />
					</div>
				</template>
			</div>

			<template v-if="layoutDir == 'h'">
				<Transition name="fade">
					<div v-if="scrollLeft > 1" class="scroller scroller-left">
						<button class="btn btn-rounded btn-icon btn-md btn-primary" @click="scrollFeedsLeft">
							<i class="fas fa-arrow-left" />
						</button>
					</div>
				</Transition>
				<Transition name="fade">
					<div v-if="scrollRight > 1" class="scroller scroller-right">
						<button class="btn btn-rounded btn-icon btn-md btn-primary" @click="scrollFeedsRight">
							<i class="fas fa-arrow-right" />
						</button>
					</div>
				</Transition>
			</template>
			<template v-if="layoutDir == 'v'">
				<Transition name="fade">
					<div v-if="scrollTop > 1" class="scroller scroller-up">
						<button class="btn btn-rounded btn-icon btn-md btn-primary" @click="scrollFeedsUp">
							<i class="fas fa-arrow-up" />
						</button>
					</div>
				</Transition>
				<Transition name="fade">
					<div v-if="scrollBottom > 1" class="scroller scroller-down">
						<button class="btn btn-rounded btn-icon btn-md btn-primary" @click="scrollFeedsDown">
							<i class="fas fa-arrow-down" />
						</button>
					</div>
				</Transition>
			</template>
		</div>
	</div>
</template>

<style scoped>
.video-feeds {
	padding-top: 10px;
	padding-left: 10px;
}
.video-feed-container {
	margin-right: 10px;
	margin-bottom: 10px;
}
.video-feed-list-pad-bottom {
	padding-bottom: 10px;
}
.video-feed-list-pad-right {
	padding-right: 10px;
}
.scroller {
	position: absolute;
	display: flex;
	justify-content: center;
}
.scroller-left {
	top: 0;
	height: calc(100% - 10px);
	left: 10px;
	flex-direction: column;
}
.scroller-right {
	top: 0;
	height: calc(100% - 10px);
	right: 20px;
	flex-direction: column;
}
.scroller-up {
	left: 0;
	width: calc(100% - 10px);
	top: 10px;
}
.scroller-down {
	left: 0;
	width: calc(100% - 10px);
	bottom: 20px;
}
.waiting-container {
	border: 2px solid #dee2e6;
	border-radius: 10px;
	padding: 1rem;
}
</style>

<script>
//UI Components
import VideoFeed from "@/vues/VideoMeeting/components/VideoFeed";

//Libraries
import _ from "lodash";

//Services

export default {
	name: "VideoLayoutFocus",
	props: ["participants", "uiState"],
	components: { VideoFeed },
	data() {
		return {
			focusFeedWidth: 480,
			focusFeedHeight: 272,
			feedWidth: 240,
			feedHeight: 135,
			layoutDir: "h",
			speakers: {},
			activeSpeaker: 0,

			scrollTop: 0,
			scrollBottom: 0,
			scrollLeft: 0,
			scrollRight: 0,
		};
	},
	created() {},
	mounted() {
		window.addEventListener("resize", this.windowResize);
		this.reorderFeeds();
		this.calculateLayout();
	},
	destroyed() {
		window.removeEventListener("resize", this.windowResize);
	},

	computed: {
		contentParticipants() {
			return this.participants.map((parti) => parti.isContent);
		},
	},

	watch: {
		"uiState.sidebarTab"() {
			this.calculateLayout();
		},
		participants() {
			this.calculateLayout();
		},
		"uiState.speaking"() {
			this.reorderFeeds();
		},
		contentParticipants() {
			this.debug("Content streams changed");
			this.reorderFeeds();
		},
	},

	methods: {
		reorderFeeds() {
			this.log("Reorder feeds");
			if (this.participants.length > 0 && this.participants[0].isContent) {
				this.log("Content is focused");
				// We're already focusing on a screensharing feed, so don't reorder anything
				return;
			}
			this.debug("1");

			let screenshares = _.remove(this.participants, { isContent: true });
			if (screenshares.length > 0) {
				this.logWarn("Focus content");
				this.participants.unshift(...screenshares);
				return;
			}
			this.debug("2");

			if (this.uiState.speaking.length == 0) {
				this.log("No active speaker");
				// No active speaker and no screensharers, so leave whoever was focused before
				// as the focus
				return;
			}
			this.debug("3");

			let speakingPartis = [];
			for (let attendeeId of this.uiState.speaking) {
				let speaker = _.remove(this.participants, { attendeeId: attendeeId });
				if (speaker.length > 0) {
					// Theoretically this should only be one element, but we'll handle it as an array for consistency
					speakingPartis.push(...speaker);
				} else {
					this.logError("Speaking attendeeId", attendeeId, "was not found in participants");
				}
			}

			this.participants.unshift(...speakingPartis);
			this.log("Participants reordered for focus layout");
		},

		focusedVideoResize() {
			this.log("Focused video resolution changed, recalculate layout");
			this.calculateLayout();
		},

		calculateLayout() {
			// First, determine the overall layout (horizontal vs. vertical) of the feeds. We want to make the focus
			// feed as big as possible, while also allowing room on the side/bottom for additional feeds.
			// This depends on the exact aspect ratio of the main feed.

			if (!(this.participants && this.participants.length > 0)) {
				// No focus feed, can't continue
				return;
			}

			let el = this.$refs.videoFeeds;
			if (!el) {
				ths.logError("Could not layout feeds because container was not found");
				return;
			}

			let aspectRatio = this.getAspectRatio();

			let widthToHeight = (width) => {
				return (width - paddingX) / (16 / 9) + paddingY;
			};
			let heightToWidth = (height) => {
				return (height - paddingY) * (16 / 9) + paddingX;
			};

			let paddingX = 10;
			let paddingY = 10;
			let containerWidth = el.offsetWidth - paddingX;
			let containerHeight = el.offsetHeight - paddingY;

			let vFeedWidth = 240 + paddingX;
			let vFeedHeight = 135 + paddingY;
			let hFeedWidth = 240 + paddingX;
			let hFeedHeight = 135 + paddingY;

			let vFocusWidth = 1;
			let vFocusHeight = containerHeight;
			if (containerWidth >= vFeedWidth * 3) {
				vFocusWidth = containerWidth - vFeedWidth;
			} else {
				vFocusWidth = containerWidth * (2 / 3);
				vFeedWidth = containerWidth * (1 / 3);
				vFeedHeight = widthToHeight(vFeedWidth);
			}

			let hFocusWidth = containerWidth;
			let hFocusHeight = 1;
			if (containerHeight >= hFeedHeight * 3) {
				hFocusHeight = containerHeight - hFeedHeight;
			} else {
				hFocusHeight = containerHeight * (2 / 3);
				hFeedHeight = containerHeight * (1 / 3);
				hFeedWidth = heightToWidth(hFeedHeight);
			}

			let vVideoWidth = this.getUseableWidth(vFocusWidth, vFocusHeight, aspectRatio, paddingX, paddingY);
			let hVideoWidth = this.getUseableWidth(hFocusWidth, hFocusHeight, aspectRatio, paddingX, paddingY);

			if (vVideoWidth > hVideoWidth) {
				this.layoutDir = "v";
				this.focusFeedWidth = vFocusWidth - paddingX;
				this.focusFeedHeight = vFocusHeight - paddingY;
				this.feedWidth = vFeedWidth;
				this.feedHeight = vFeedHeight;
			} else {
				this.layoutDir = "h";
				this.focusFeedWidth = hFocusWidth - paddingX;
				this.focusFeedHeight = hFocusHeight - paddingY;
				this.feedWidth = hFeedWidth;
				this.feedHeight = hFeedHeight;
			}

			let scrollEl = this.$refs.scrollContainer;
			if (scrollEl) {
				this.$nextTick(() => this.calculateScrolls(scrollEl));
			}
		},

		getAspectRatio() {
			if (!this.participants[0].video) {
				this.logError(
					"Tried getAspectRatio on focus participant with no video, using default aspect ratio",
					16 / 9
				);
				return 16 / 9;
			}

			let el = this.participants[0].video;
			if (el.videoWidth == 0 || el.videoHeight == 0) {
				this.logWarn("Focus video element had 0 value", el.videoWidth, el.videoHeight);
				return 16 / 9;
			}

			let aspectRatio = el.videoWidth / el.videoHeight;
			this.log("Using focus video aspect ratio", el.videoWith, el.videoHeight, aspectRatio);

			return aspectRatio;
		},

		getUseableWidth(containerWidth, containerHeight, contentAspectRatio, paddingX, paddingY) {
			containerWidth = containerWidth - paddingX;
			containerHeight = containerHeight - paddingY;

			let containerAspectRatio = containerWidth / containerHeight;
			if (containerAspectRatio > contentAspectRatio) {
				return containerHeight * contentAspectRatio;
			} else {
				return containerWidth;
			}
		},

		windowResize() {
			this.calculateLayout();
			this.$forceUpdate();
		},

		focusedVideoResized() {
			this.calculateLayout();
			this.$forceUpdate();
		},

		feedsScrolled(e) {
			this.calculateScrolls(e.target);
		},

		calculateScrolls(el) {
			if (this.layoutDir == "v") {
				this.scrollTop = el.scrollTop;
				this.scrollBottom = el.scrollHeight - (el.scrollTop + el.clientHeight);
			}
			if (this.layoutDir == "h") {
				this.scrollLeft = el.scrollLeft;
				this.scrollRight = el.scrollWidth - (el.scrollLeft + el.clientWidth);
			}
		},

		scrollFeedsLeft() {
			let el = this.$refs.scrollContainer;
			if (el) {
				el.scroll({ left: this.scrollLeft - (this.feedWidth + 10), behavior: "smooth" });
			}
		},

		scrollFeedsRight() {
			let el = this.$refs.scrollContainer;
			if (el) {
				el.scroll({ left: this.scrollLeft + (this.feedWidth + 10), behavior: "smooth" });
			}
		},

		scrollFeedsUp() {
			let el = this.$refs.scrollContainer;
			if (el) {
				el.scroll({ top: this.scrollTop - (this.feedHeight + 10), behavior: "smooth" });
			}
		},

		scrollFeedsDown() {
			let el = this.$refs.scrollContainer;
			if (el) {
				el.scroll({ top: this.scrollTop + (this.feedHeight + 10), behavior: "smooth" });
			}
		},
	},
};
</script>