<template>
	<v-select
		label="name"
		:options="sortedOptions"
		:searchable="searchable"
		:selectable="selectable"
		:value="correctedValue"
		@input="changeSelection"
		:disabled="disabled"
	>
		<template slot="selected-option" slot-scope="option">
			<span v-if="option.name" :class="{ 'disabled-option': option.disabled, 'text-muted': option.disabled }">
				<span v-if="option.warn" class="text-danger far faw fa-exclamation-triangle"></span>
				{{ option.name }}
			</span>
		</template>

		<template slot="option" slot-scope="option">
			<span v-if="option.name" :class="{ 'disabled-option': option.disabled, 'text-muted': option.disabled }">
				{{ option.name }}
			</span>
			<div v-if="option.desc" class="text-muted pb-1" style="line-height: 0.85rem" v-html="option.desc"></div>
			<span v-if="option.divider" class="config-select-divider"></span>
		</template>
	</v-select>
</template>

<style scoped>
.full {
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	padding: 3px 20px;
}

.dropdown-menu .disabled-option {
	pointer-events: none;
	text-decoration: line-through;
}
</style>

<script>
import AuthService from "@/services/AuthService";

export default {
	name: "ConfigSelect",
	props: {
		value: {},
		options: { type: Array },
		nullOption: { default: false },
		mustBeFilled: { default: false },
		hideNone: { type: Boolean, default: false },
		nullText: { type: String, default: "Any" },
		searchable: { type: Boolean, default: true },
		disabled: { default: null },
		byField: { default: null },
		sortBy: { default: null },
		useInactive: { type: Boolean, default: false },
	},

	data() {
		return {
			mID: 0,
			mut: null,
			showNull: false,
			nullString: "",
			role: null,
			limitInactive: false,
		};
	},
	created() {
		if (
			this.$router &&
			this.$router.app &&
			this.$router.app._route &&
			this.$router.app._route.meta &&
			this.$router.app._route.meta.user &&
			this.$router.app._route.meta.user.role &&
			this.$router.app._route.meta.user.role.limit_inactive
		) {
			this.limitInactive = true;
		}
		this.nullString = this.nullText;
		if (this.nullString === "Any") {
			this.nullString = this.$i18n.t("data_description.any");
		}
	},
	watch: {},
	computed: {
		sortedOptions: function () {
			this.mID = 0;

			_.each(this.options, (o) => {
				if (typeof o.id == "number" && o.id < this.mID) {
					this.mID = o.id;
				}
			});

			var meta = [];
			var sorted = [];
			var previous = [];

			if (this.nullOption) {
				var funcCheck = true;
				if (typeof this.nullOption == "function") {
					funcCheck = this.nullOption();
				}
				if (funcCheck) {
					meta[0] = {
						id: this.metaID(),
						name: this.nullString,
						metaNull: true,
					};
				}
			}

			meta = meta.concat(_.filter(this.options, { meta: true }));

			if (meta.length > 0) {
				if (previous.length > 0) {
					sorted = sorted.concat(this.divider());
				}
				sorted = sorted.concat(meta);
				previous = meta;
			}

			if (this.options && this.options.length > 0) {
				var active = _.filter(this.options, { active: true });
				active = _.orderBy(active, (option) => {
					if (this.sortBy) {
						return option[this.sortBy];
					}
					return option.name.toLowerCase();
				});

				var inactive = _.filter(this.options, { active: false });
				inactive = _.orderBy(inactive, (option) => {
					if (this.sortBy) {
						return option[this.sortBy];
					}
					return option.name.toLowerCase();
				});

				var unmarked = _.filter(this.options, (option) => {
					return !(option.active == true || option.active == false || option.meta == true);
				});
				unmarked = _.orderBy(unmarked, (option) => {
					if (this.sortBy) {
						return option[this.sortBy];
					}
					return option.name.toLowerCase();
				});

				if (active.length > 0) {
					if (previous.length > 0) {
						sorted = sorted.concat(this.divider());
					}
					sorted = sorted.concat(active);
					previous = active;
				}
				if (inactive.length > 0 && !this.limitInactive) {
					if (previous.length > 0) {
						sorted = sorted.concat(this.divider());
					}
					sorted = sorted.concat(inactive);
					previous = inactive;
				}

				if (unmarked.length > 0) {
					if (previous.length > 0) {
						sorted = sorted.concat(this.divider());
					}
					sorted = sorted.concat(unmarked);
					previous = inactive;
				}
			}

			if (this.useInactive) {
				sorted = _.orderBy(this.options, (option) => {
					if (this.sortBy) {
						return option[this.sortBy];
					}
					return option.name.toLowerCase();
				});
			}

			if (sorted.length == 0 && !this.hideNone) {
				sorted[0] = {
					id: this.metaID(),
					name: this.$i18n.t("data_description.none"),
					metaNull: true,
					warn: this.mustBeFilled,
				};
			}

			return sorted;
		},
		correctedValue: function () {
			let existingValue = this.pickExistingValue();
			return existingValue;
		},
	},
	methods: {
		changeSelection(newVal) {
			if (newVal != null || this.byField) {
				this.mut = newVal;
				if (this.mut && !this.mut.metaNull) {
					if (this.byField) {
						this.$emit("input", this.mut[this.byField]);
					} else {
						this.$emit("input", this.mut);
					}
				} else {
					this.$emit("input", null);
				}
			}
		},

		pickExistingValue() {
			let getValue;
			if (this.value != null) {
				if (this.byField) {
					getValue = _.find(this.sortedOptions, {
						[this.byField]: this.value,
					});
				} else {
					getValue = _.find(this.sortedOptions, {
						id: this.value.id,
					});
				}
			} else if (this.value == null) {
				getValue = _.find(this.sortedOptions, {
					metaNull: true,
				});
			}
			if (getValue || this.byField) {
				return getValue == undefined ? null : getValue;
			} else {
				this.changeSelection(this.sortedOptions[0]);
				return this.sortedOptions[0];
			}
		},

		divider() {
			return {
				id: this.metaID(),
				name: "",
				divider: true,
			};
		},

		metaID() {
			this.mID -= 1;
			return this.mID;
		},

		preventDeselect(option, event) {
			if (this.mut && option && this.mut.id == option.id) {
				event.stopPropagation();
			}
		},

		selectable(option) {
			return option.name || option.desc;
		},
	},
};
</script>
