<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			User Export
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="d-flex flex-row justify-content-center">
			<div class="my-3">
				<button v-if="!running" @click="exportUsers" class="btn btn-success">Export Users</button>
				<button v-if="running" class="btn btn-success" disabled>
					<loading type="icon" class="mr-1" />Exporting...
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import FileService from "@/services/FileService";

export default {
	name: "UserExport",
	props: ["environment"],

	data() {
		return {
			expanded: Store.get(this, "adminTasks.userExport.expanded"),
			running: false,
		};
	},
	created() {},
	watch: {
		expanded() {
			Store.set(this, "adminTasks.userExport.expanded", this.expanded);
		},
	},
	methods: {
		async exportUsers() {
			this.result = null;
			this.running = true;

			try {
				const csv = await this.getUserCsvText();
				const filename = `OSCAR_user_export_${moment().format("YY-MM-DD")}.csv`;
				FileService.downloadText(filename, csv);
			} catch (err) {
				Notie.error(this.$i18n.t("notie.failed_to_export_scores"), err);
			}
		},

		async getUserCsvText() {
			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			let { data } = await axios.get("/v1/admin_tasks/user_export", config);

			this.running = false;
			return data;
		},
	},
};
</script>
