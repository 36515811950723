import axios from "axios";

export default {
	listResGroups(ctx) {
		return axios.get("/v1/admin/res_groups", { params: ctx });
	},

	getResGroup(id) {
		return axios.get(`/v1/admin/res_group/${id}`);
	},

	saveResGroup(resGroup) {
		return axios.post("/v1/admin/res_group", resGroup);
	},

	removeResGroup(resGroupID) {
		return axios.delete(`/v1/admin/res_group/${resGroupID}`);
	},

	getResGroupRubric(resGroup) {
		return axios.post(`/v1/admin/res_group_rubric`, resGroup)
	},

	simResGroupScorePair(resGroup, firstRead, secondRead) {
		let ctx = {
			res_group: resGroup,
			first_read: firstRead,
			second_read: secondRead,
			compare: firstRead && secondRead ? true : false
		};
		return axios.post("/v1/admin/simresgroupscorepair", ctx);
	},
};
