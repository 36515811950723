
<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("Calendar.supervisor_calendar") }}
				</div>
				<div class="ml-2 d-flex flex-row align-items-center">
					<label class="mb-0 mr-2">{{ $t("Calendar.focus_on_user") }}:</label>
					<config-select
						:options="userOptions"
						v-model="focusUser"
						:nullOption="true"
						nullText="All users"
						sortBy="days"
						class="bg-white w-md dropdown-single-line"
					/>
				</div>
			</div>
		</template>
		<div class="h-100" v-if="!loading">
			<div class="d-sm-flex b-t h-100">
				<div class="w w-auto-xs light bg b-r">
					<div class="py-3">
						<div class="nav-active-border left b-primary">
							<ul class="nav flex-column nav-sm">
								<li class="ml-1 mb-2 _600">{{ $t("Calendar.projects") }}:</li>
								<li v-for="project in projects" :key="project.id" class="nav-item">
									<a
										v-if="sProject"
										class="nav-link"
										:class="{ active: sProject.id == project.id }"
										@click="sProject = project"
									>
										{{ project.name }}
									</a>
								</li>
								<li class="nav-item b-t pt-2 mt-2">
									<a
										class="nav-link no-top-border"
										:class="{ active: sProject && sProject.id == 'all' }"
										@click="selectAllProjects()"
										>All Projects</a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div id="calendar-container" class="tab-content pos-rlt w-100" data-app="true">
					<!-- User -->
					<div class="tab-pane active">
						<div class="d-flex flex-column">
							<div class="flex px-3 pb-3">
								<mz-calendar
									ref="calendar"
									v-if="sProject"
									:events="events"
									:scheduleConfigs="scheduleConfigMap[sProject.id]"
									@eventClick="eventClick"
									:viewMode.sync="viewMode"
									:project="sProject"
									:height="calcCalendarHeight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="configModal" v-model="selectedOpen" @hide="selectedOpen = false" :size="focusUser ? 'md' : 'lg'">
			<template slot="modal-header">
				<h5 v-if="!focusUser" class="modal-title pl-3">
					{{ $t("Calendar.configure_day") }}
				</h5>
				<h5 v-if="focusUser" class="modal-title pl-3">
					{{ $t("Calendar.attendance_details") }}
				</h5>
			</template>

			<div class="px-3">
				<div class="row">
					<h5 v-if="editingProject" class="col-9 align-self-center mb-0">
						{{ editingProject.name }}
					</h5>
					<div class="col-3 align-self-center d-flex flex-row justify-content-end">
						<div class="date-circle btn-primary">
							<div>{{ selectedEvent.month }}</div>
							<div style="margin-top: -6px">
								{{ selectedEvent.day }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<hr style="margin-left: -1rem; margin-right: -1rem" />
			<template v-if="!focusUser">
				<div v-if="selectedConfig" class="px-3">
					<div class="row">
						<div class="col-12 d-flex flex-row align-items-center pt-0">
							<h5 class="mb-0 flex">{{ $t("Calendar.settings") }}</h5>
							<button
								v-if="!locked(selectedConfig, editingProject)"
								class="btn btn-outline-danger"
								v-tippy
								:title="unlockedDayTooltip"
								@click="lockDay"
							>
								<i class="fas fa-lock-open mr-2" />{{ $t("Calendar.unlocked") }}
							</button>
							<button
								v-if="locked(selectedConfig, editingProject)"
								class="btn btn-danger"
								v-tippy
								:title="lockedDayTooltip"
								@click="unlockDay"
								:disabled="todayOrPast(selectedConfig)"
							>
								<i class="fas fa-lock mr-2" />{{ $t("Calendar.locked") }}
							</button>
						</div>

						<template v-if="!editingProject.use_shifts">
							<div class="col-6 pt-0">
								<label class="text-muted">{{ $t("Calendar.threshold_type") }}</label>
								<config-select
									:options="fs.scheduleThresholdTypes()"
									v-model="selectedConfig.threshold_type"
									byField="id"
									sortBy="sequence"
								></config-select>
							</div>
							<div v-if="selectedConfig.threshold_type != 0" class="col-6 pt-0">
								<label class="text-muted">{{ $t("Calendar.goal") }}</label>
								<input class="form-control" type="number" v-model.number="selectedConfig.threshold" />
							</div>
						</template>

						<template v-if="editingProject.use_shifts">
							<template v-for="(shift, i) in selectedEvent.shifts">
								<div class="col-2 pt-2" :key="`l-${shift.id}`">
									<strong>{{ $t("Calendar.shift_num", { num: i + 1 }) }}</strong>
								</div>

								<div class="col-4 pt-2" :key="`m-${shift.id}`">
									<strong>{{ shift.start }}</strong> to
									<strong>{{ shift.end }}</strong>
								</div>

								<div :key="`r-${shift.id}`" class="col-6 pt-0 d-flex flex-row align-items-center">
									<span class="mr-2">{{ $t("Calendar.goal_users") }}:</span>
									<input
										class="form-control"
										type="number"
										v-model.number="selectedConfig.shift_thresholds[shift.id]"
									/>
								</div>
							</template>
						</template>
					</div>
				</div>

				<hr />
				<div class="px-3">
					<div>
						<h5 class="flex">Roster</h5>
					</div>
					<table
						v-if="selectedEvent && selectedEvent.userSchedules && selectedEvent.userSchedules.length > 0"
						class="table condensed-table"
						style="margin-left: -4px"
					>
						<tr v-if="editingProject.use_shifts">
							<th class="no-border"></th>
							<th class="no-border"></th>

							<th
								v-for="(shift, i) in selectedEvent.shifts"
								:key="shift.id"
								class="no-border text-center"
							>
								{{ $t("Calendar.shift_num", { num: i + 1 }) }}
							</th>

							<th class="no-border"></th>
						</tr>

						<tr v-for="(us, i) in selectedEvent.userSchedules" :key="us.user_id">
							<td class="v-mid no-border">
								<div class="text-lg">{{ i + 1 }}.</div>
							</td>
							<td class="v-mid no-border">
								<div>{{ us.name }}</div>
								<div class="text-muted text-xxs" style="margin-top: -3px">
									{{ us.role }}
								</div>
							</td>

							<template v-if="editingProject.use_shifts">
								<td
									v-for="shift in selectedEvent.shifts"
									:key="shift.id"
									class="no-border v-mid text-center"
								>
									<i v-if="us.shift_ids.includes(shift.id)" class="fas fa-check fa-lg text-success" />
									<i
										v-if="!us.shift_ids.includes(shift.id)"
										class="fas fa-ban fa-lg text-very-muted"
									/>
								</td>
							</template>

							<template v-if="!editingProject.use_shifts">
								<td class="v-mid no-border">
									<span class="_600">
										{{ us.start_time }}
									</span>
									<span class="text-muted">{{ $t("Calendar.to") }}</span>
									<span class="_600">
										{{ us.end_time }}
									</span>
								</td>
								<td class="v-mid no-border">
									<span class="_600">
										{{ scheduleDuration(us) }}
									</span>
									<span class="text-muted">{{ $t("Calendar.hours") }}</span>
								</td>
							</template>

							<td class="v-mid no-border">
								<input
									type="text"
									class="form-control"
									v-model="selectedConfig.user_labels[us.user_id]"
									:placeholder="$t('Calendar.notes_placeholder')"
								/>
							</td>
						</tr>
					</table>

					<h3
						v-if="selectedEvent && selectedEvent.userSchedules && selectedEvent.userSchedules.length == 0"
						class="text-very-muted mb-0 text-center"
					>
						{{ $t("Calendar.no_users") }}
					</h3>
				</div>
			</template>

			<template v-if="focusUser && selectedEvent.past">
				<div class="py-3 mb-4" style="height: 250px">
					<canvas id="projectdash-doughnut" v-chartjs="atdChartData" style="height: 250px"></canvas>
				</div>
				<div class="d-flex flex-row flex-wrap">
					<span class="text-center py-2" style="width: 100%">
						<h1 class="_600 mb-0">
							{{ fs.calcPercent1d(selectedEvent.scoredSeconds, selectedEvent.scheduledSeconds) }}
						</h1>
						<div class="text-muted">{{ $t("Calendar.attendance") }}</div>
					</span>
					<span class="text-center py-2" style="width: 50%">
						<div class="d-flex flex-row justify-content-center align-items-baseline">
							<h5 class="_600 mb-0">{{ getTimeHour(selectedEvent.scheduledSeconds) }}</h5>
							<span class="text-muted mx-small">{{ $t("timestamp.hour_abr") }}</span>
							<h5 class="_600 mb-0">{{ getTimeMinute(selectedEvent.scheduledSeconds) }}</h5>
							<span class="text-muted mx-small">{{ $t("timestamp.min_abr") }}</span>
						</div>
						<div class="text-muted">{{ $t("Calendar.scheduled_time") }}</div>
					</span>
					<span class="text-center py-2" style="width: 50%">
						<div class="d-flex flex-row justify-content-center align-items-baseline">
							<h5 class="_600 mb-0">{{ getTimeHour(selectedEvent.scoredSeconds) }}</h5>
							<span class="text-muted mx-small">{{ $t("timestamp.hour_abr") }}</span>
							<h5 class="_600 mb-0">{{ getTimeMinute(selectedEvent.scoredSeconds) }}</h5>
							<span class="text-muted mx-small">{{ $t("timestamp.min_abr") }}</span>
						</div>
						<div class="text-muted">{{ $t("Calendar.score_time") }}</div>
					</span>
					<span class="text-center py-2" style="width: 50%">
						<div class="d-flex flex-row justify-content-center align-items-baseline">
							<h5 class="_600 mb-0">{{ getTimeHour(selectedEvent.systemSeconds) }}</h5>
							<span class="text-muted mx-small">{{ $t("timestamp.hour_abr") }}</span>
							<h5 class="_600 mb-0">{{ getTimeMinute(selectedEvent.systemSeconds) }}</h5>
							<span class="text-muted mx-small">{{ $t("timestamp.min_abr") }}</span>
						</div>
						<div class="text-muted">{{ $t("Calendar.system_time") }}</div>
					</span>
					<span class="text-center py-2" style="width: 50%">
						<h5 v-if="selectedEvent.res == 0" class="mb-0">{{ selectedEvent.scores }}</h5>
						<h5 v-if="selectedEvent.res > 0" class="mb-0" v-tippy :title="$t('Calendar.normal/resolution')">
							{{ selectedEvent.scores }} / {{ selectedEvent.res }}
						</h5>
						<div class="text-muted">{{ $t("Calendar.scores") }}</div>
					</span>
				</div>
			</template>

			<template v-if="focusUser && !selectedEvent.past">
				<div class="text-center mt-4 mb-2">
					<div class="d-flex flex-row justify-content-center align-items-baseline">
						<h5 class="_600 mb-0">{{ getTimeHour(selectedEvent.scheduledSeconds) }}</h5>
						<span class="text-muted mx-small">{{ $t("timestamp.hour_abr") }}</span>
						<h5 class="_600 mb-0">{{ getTimeMinute(selectedEvent.scheduledSeconds) }}</h5>
						<span class="text-muted mx-small">{{ $t("timestamp.min_abr") }}</span>
					</div>
					<div class="text-muted">{{ $t("Calendar.scheduled_time") }}</div>
				</div>
			</template>

			<template slot="modal-footer">
				<div class="d-flex px-3">
					<div class="align-self-end">
						<button v-if="!focusUser" class="btn btn-primary" @click="saveScheduleConfig">
							{{ $t("Calendar.save") }}
						</button>
						<button v-if="focusUser" class="btn btn-primary" @click="selectedOpen = false">
							{{ $t("buttons.close") }}
						</button>
					</div>
				</div>
			</template>
		</b-modal>
	</page>
</template>

<style scoped>
.w-select {
	min-width: 160px;
}
.w-dates {
	width: 150px;
}
.w-time {
	width: 100px;
}

a.btn-subtle {
	margin-top: -6px;
	margin-bottom: -6px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.cal-popup {
	width: 300px;
	contain: none !important;
}
.cal-popup > .card {
	contain: none !important;
}
.date-circle {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>

<script>
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";
import ConfigSelect from "@/components/ConfigSelect";
import ProjectAvailability from "@/components/ProjectAvailability";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import DatePicker from "vue-bootstrap-datetimepicker";
import ScheduleTagSelect from "@/components/ScheduleTagSelect";
import MzCalendar from "@/components/MzCalendar";

import TenantService from "@/services/TenantService";
import ConfigService from "@/services/ConfigService";
import ScheduleService from "@/services/ScheduleService";
import ReportingService from "@/services/ReportingService";
import ValidationService from "@/services/ValidationService";
import notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import BB from "bluebird";
import fs from "@/services/FormatService";
import moment from "moment";
import tippy from "tippy.js";
import chartjs from "chart.js";
require("@/directives/chartjs");

const dateFormat = "MMMM DD, YYYY";
const timeFormat = "h:mm A";
let now = moment();

export default {
	name: "Calendar",

	props: ["user"],

	components: {
		EditPane,
		SaveOptionalModal,
		ConfigSelect,
		ProjectAvailability,
		DatePicker,
		ScheduleTagSelect,
		MzCalendar,
	},

	data() {
		return {
			fs: fs,
			dateConfig: {
				format: dateFormat,
				inline: true,
				viewMode: "days",
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			dateTooltipConfig: {
				format: dateFormat,
				inline: false,
				viewMode: "days",
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			timeConfig: {
				format: timeFormat,
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			weekdays: [
				{ abr: "mon", name: "Monday", index: 0 },
				{ abr: "tue", name: "Tuesday", index: 1 },
				{ abr: "wed", name: "Wednesday", index: 2 },
				{ abr: "thu", name: "Thursday", index: 3 },
				{ abr: "fri", name: "Friday", index: 4 },
				{ abr: "sat", name: "Saturday", index: 5 },
				{ abr: "sun", name: "Sunday", index: 6 },
			],

			projects: null,
			sProject: null,
			editingProject: null,
			scheduleMap: null,
			scheduleConfigMap: null,
			scheduleValid: false,
			scheduleError: "",
			client: null,

			scheduleTypes: fs.scheduleTypes(),
			exceptionTypes: fs.scheduleExceptionTypes(),

			dirty: false,
			loading: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},

			today: moment().format("YYYY-MM-DD"),
			focus: moment().format("YYYY-MM-DD"),
			start: null,
			end: null,
			selectedEvent: {},
			selectedConfig: null,
			editEvent: {},
			selectedElement: null,
			selectedOpen: false,
			events: [],
			labels: [],
			viewMode: null,
			userOptions: [],
			focusUser: null,

			unlockedDayTooltip: this.$i18n.t("Calendar.unlocked_day_tooltip"),
			lockedDayTooltip: this.$i18n.t("Calendar.locked_day_tooltip"),

			refs: this.$refs,

			atdChartData: {
				type: "doughnut",
				data: {
					labels: ["Loading"],
					datasets: [
						{
							data: [0],
							borderColor: "transparent",
							backgroundColor: ["rgba(0, 128, 0, 0.2)", "orange", "red"],
							borderWidth: 3,
						},
					],
				},
				options: {
					tooltips: {
						mode: "point",
						titleFontSize: 14,
						bodyFontSize: 14,
						callbacks: {
							label: (item, data) => {
								let i = item.datasetIndex;
								let j = item.index;

								let val = data.datasets[i].data[j];
								let label = data.labels[j];
								return ` ${label}:`;
							},
							afterLabel: (item, data) => {
								let i = item.datasetIndex;
								let j = item.index;
								let val = data.datasets[i].data[j];
								let label = data.labels[j];

								if (label == this.$i18n.t("Calendar.scheduled")) {
									return this.$i18n.t("Calendar.title_hours", {
										n: (this.selectedEvent.scheduledSeconds / 3600).toFixed(1),
									});
								}
								return ` ${val}%`;
							},
						},
					},
					maintainAspectRatio: false,
					legend: {
						position: "right",
						labels: {
							boxWidth: 12,
						},
					},
					cutoutPercentage: 70,
				},
			},
		};
	},

	created() {
		this.loadData();
		this.initValidation();
	},

	mounted() {
		//this.$refs.calendar.checkChange();
	},

	watch: {
		sProject() {
			this.loadProjectEvents();
		},
		focusUser() {
			this.loadProjectEvents();
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({});
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"schedules",
				(newc, old) => {
					// console.log(Utils.diff(newc, old));
					// console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			return BB.props({
				scheduleDays: ScheduleService.getScheduleDaysPrefilled(),
				scheduleConfigs: ScheduleService.getScheduleConfigs(),
				projects: ConfigService.listUserProjects(true),
				client: TenantService.getClient(),
			})
				.then((resps) => {
					let scheduleDays = resps.scheduleDays.data;
					let scheduleConfigs = resps.scheduleConfigs.data;
					let projects = resps.projects.data;

					this.createUserOptions(scheduleDays);

					let scheduleMap = {};
					_.each(scheduleDays, (sd) => {
						if (!scheduleMap[sd.project_id]) {
							scheduleMap[sd.project_id] = {};
						}

						if (!scheduleMap[sd.project_id][sd.date]) {
							scheduleMap[sd.project_id][sd.date] = {};
						}

						scheduleMap[sd.project_id][sd.date][sd.user_id] = sd;
					});

					let scheduleConfigMap = {};
					_.each(projects, (project) => {
						if (!scheduleConfigMap[project.id]) {
							scheduleConfigMap[project.id] = {};
						}
					});
					_.each(scheduleConfigs, (sc) => {
						if (!scheduleConfigMap[sc.project_id]) {
							console.log("Failed to find config in map", sc.project_id);
							return;
						}
						scheduleConfigMap[sc.project_id][sc.date] = sc;
					});

					this.scheduleMap = scheduleMap;
					this.scheduleConfigMap = scheduleConfigMap;
					this.projects = projects;
					this.client = resps.client.data;

					if (this.projects.length > 0 && !this.sProject) {
						this.sProject = this.projects[0];
					}

					fs.colorfy(this.projects);
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to get schedules", err);
				});
		},

		createUserOptions(scheduleDays) {
			let userMap = {};
			_.each(scheduleDays, (sd) => {
				if (!userMap[sd.user_id]) {
					userMap[sd.user_id] = {
						id: sd.user_id,
						name: sd.name,
						days: 1,
					};
				} else {
					userMap[sd.user_id].days++;
				}
			});

			let userOptions = [];
			_.each(userMap, (o) => {
				o.desc = this.$i18n.t("Calendar.n_days_scheduled", { n: o.days });
				userOptions.push(o);
			});

			this.userOptions = userOptions;
		},

		//Checks whether the schedule time that the user has entered is valid (within daily range,
		//within range of recurrence days, etc.)
		checkScheduleEntry() {
			let date = this.selectedEvent.date;
			let project = this.sProject;
			let startTime = this.editEvent.startTime;
			let endTime = this.editEvent.endTime;
			if (!this.editEvent.recur) {
				this.scheduleValid = false;
				this.scheduleError = this.$i18n.t("Calendar.errors.recur_type");
			}
			let recur = this.editEvent.recur.id;

			if (!startTime) {
				this.scheduleValid = false;
				this.scheduleError = this.$i18n.t("Calendar.errors.start_time");
				return;
			}

			if (!endTime) {
				this.scheduleValid = false;
				this.scheduleError = this.$i18n.t("Calendar.errors.end_time");
				return;
			}

			let currDay = moment(date, "MM/DD/YYYY");

			let checkEvents = [];
			switch (recur) {
				case "once":
					let checkEvent = _.find(this.events, { date: date });
					if (!checkEvent) {
						this.scheduleValid = false;
						this.scheduleError = this.$i18n.t("Calendar.errors.current_day");
						return;
					}
					checkEvents = [checkEvent];
					break;
				case "week":
					let weekday = currDay.format("dddd");
					checkEvents = _.filter(this.events, { weekday: weekday });
					if (checkEvents.length == 0) {
						this.scheduleValid = false;
						this.scheduleError = this.$i18n.t("Calendar.errors.days");
						return;
					}
					break;
				case "all":
					checkEvents = this.events;
					if (checkEvents.length == 0) {
						this.scheduleValid = false;
						this.scheduleError = this.$i18n.t("Calendar.errors.days");
						return;
					}
					break;
			}

			let latestStart = checkEvents[0].startMoment;
			let earliestEnd = checkEvents[0].endMoment;
			_.each(checkEvents, (event) => {
				if (event.startMoment.isAfter(this.combineDay(latestStart, event.startMoment))) {
					latestStart = event.startMoment;
				}

				if (event.endMoment.isBefore(this.combineDay(earliestEnd, event.endMoment))) {
					earliestEnd = event.endMoment;
				}
			});

			let userStart = this.combineDay(moment(startTime, fs.timeFormat()), latestStart);
			let userEnd = this.combineDay(moment(endTime, fs.timeFormat()), earliestEnd);

			if (userStart.isBefore(latestStart)) {
				this.scheduleValid = false;
				switch (recur) {
					case "once":
						this.scheduleError = this.$i18n.t("Calendar.errors.start_too_early", {
							time: latestStart.format(fs.timeFormat()),
						});
						break;
					case "week":
						this.scheduleError = this.$i18n.t("Calendar.errors.start_too_early_weekday", {
							weekday: latestStart.format("dddd"),
							time: latestStart.format(fs.timeFormat()),
						});
						break;
					case "all":
						this.scheduleError = this.$i18n.t("Calendar.errors.start_too_early_all", {
							time: latestStart.format(fs.timeFormat()),
						});
						break;
				}
				return;
			}

			if (userEnd.isAfter(earliestEnd)) {
				this.scheduleValid = false;
				switch (recur) {
					case "once":
						this.scheduleError = this.$i18n.t("Calendar.errors.end_too_late", {
							time: earliestEnd.format(fs.timeFormat()),
						});
						break;
					case "week":
						this.scheduleError = this.$i18n.t("Calendar.errors.end_too_late_weekday", {
							weekday: earliestEnd.format("dddd"),
							time: earliestEnd.format(fs.timeFormat()),
						});
						break;
					case "all":
						this.scheduleError = this.$i18n.t("Calendar.errors.end_too_late_all", {
							time: earliestEnd.format(fs.timeFormat()),
						});
						break;
				}
				return;
			}

			this.scheduleValid = true;
			this.scheduleError = "";
		},

		saveScheduleConfig() {
			return ScheduleService.saveScheduleConfig(this.selectedConfig)
				.then((resp) => {
					notie.info(this.$i18n.t("Calendar.save_schedule_config"));

					this.selectedOpen = false;
					this.loadData().then(() => {
						this.loadProjectEvents();
					});
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("Calendar.errors.save_schedule_config"), err);
				});
		},

		availWeekdays(project) {
			let weekdays = [];
			let everyDay = _.find(project.avail_times, { day: 7 });
			if (everyDay) {
				for (let i = 0; i <= 6; i++) {
					let weekday = _.clone(this.weekdays[i]);
					weekday.avail_start = everyDay.start;
					weekday.avail_end = everyDay.end;
					weekdays.push(weekday);
				}
			} else {
				_.each(this.weekdays, (wd) => {
					let availDay = _.find(project.avail_times, { day: wd.index });
					if (availDay) {
						let weekday = _.clone(wd);
						weekday.avail_start = availDay.start;
						weekday.avail_end = availDay.end;
						weekdays.push(weekday);
					}
				});
			}

			return weekdays;
		},

		loadProjectEvents() {
			if (this.focusUser) {
				this.busy = true;
				ReportingService.getAttendanceDays(this.focusUser.id)
					.then((r) => {
						this.busy = false;

						this.focusUser.score_stats = r.data.scores;
						this.focusUser.time_stats = r.data.times;

						if (this.sProject && this.sProject.id == "all") {
							let events = [];
							_.each(this.projects, (project, i) => {
								events = events.concat(this.getUserProjectEvents(project, i, true, this.focusUser));
							});
							this.events = events;
						} else {
							this.events = this.getUserProjectEvents(this.sProject, 0, false, this.focusUser);
						}
					})
					.catch((e) => {
						this.busy = false;
						console.log(e);
						notie.error("Failed to load user stats", e);
					});
			} else {
				if (this.sProject && this.sProject.id == "all") {
					let events = [];
					_.each(this.projects, (project, i) => {
						events = events.concat(this.getAllProjectEvents(project, i, true));
					});
					this.events = events;
				} else {
					this.events = this.getAllProjectEvents(this.sProject, 0, false);
				}
			}
		},

		getAllProjectEvents(project, sequence, condensed) {
			let events = [];

			let dayTimes = [];
			if (project.enforce_times) {
				let everyDay = _.find(project.avail_times, { day: 7 });
				if (everyDay) {
					for (let i = 0; i <= 6; i++) {
						dayTimes[i] = {
							start: everyDay.start,
							end: everyDay.end,
						};
					}
				} else {
					for (let i = 0; i <= 6; i++) {
						let day = _.find(project.avail_times, { day: i });
						if (day) {
							dayTimes[i] = {
								start: day.start,
								end: day.end,
							};
						} else {
							dayTimes[i] = null;
						}
					}
				}
			} else {
				dayTimes = [
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
				];
			}

			let now = moment();
			for (
				let currDay = moment(project.scoring_start);
				currDay.isSameOrBefore(project.scoring_end);
				currDay.add(1, "days")
			) {
				var dayOfWeek = currDay.day() % 7;
				let times = dayTimes[dayOfWeek];
				let date = currDay.format("MM/DD/YYYY");
				if (times) {
					let shifts = [];
					if (project.use_shifts) {
						let weeklyShifts = _.filter(project.shifts, { weekday: dayOfWeek });
						let specificShifts = _.filter(project.shifts, { weekday: -1, date: date });
						shifts = weeklyShifts.concat(specificShifts);
						shifts = _.sortBy(shifts, (shift) => {
							return Utils.minutesFromMidnight(shift.start);
						});
					}

					let scheduleDays = this.scheduleMap[project.id];
					let scheduleDayUsers = scheduleDays && scheduleDays[date];
					let scheduleConfigs = this.scheduleConfigMap[project.id];
					let scheduleConfig = scheduleConfigs && scheduleConfigs[date];
					if (!scheduleConfig) {
						scheduleConfig = this.blankConfig();
					}
					if (project.use_shifts) {
						let dayEvents = [];
						_.each(shifts, (shift, i) => {
							//Process time range
							let startMoment = this.combineDay(moment(shift.start, fs.timeFormat()), currDay);
							let start = startMoment.format("YYYY-MM-DD HH:mm");
							let endMoment = this.combineDay(moment(shift.end, fs.timeFormat()), currDay);
							let end = endMoment.format("YYYY-MM-DD HH:mm");

							//Figure out what strings to display
							let userCount = 0;
							_.each(scheduleDayUsers, (schedule) => {
								if (schedule.shift_ids.includes(shift.id)) {
									userCount++;
								}
							});
							let users = this.$i18n.tc("Calendar.n_users", userCount, { n: userCount });

							let timeStr = "";
							if (startMoment.minute() == 0) {
								timeStr = startMoment.format("ha");
							} else {
								timeStr = startMoment.format("h:mma");
							}

							let shiftName = this.$i18n.t("Calendar.shift_n", { n: i + 1 });

							let { satisfied, filled } = this.getShiftEntryStatus(shift.id, userCount, scheduleConfig);

							//Sort user schedules, save for display in modal
							let userSchedules = [];
							_.each(scheduleDayUsers, (sdu) => {
								userSchedules.push(sdu);
							});
							userSchedules = _.sortBy(userSchedules, "created_at");

							dayEvents.push({
								title: "[not rendered]",
								start: start,
								end: end,
								weekday: currDay.format("dddd"),
								meta: {
									projectID: project.id,
									projectSeq: sequence,
									startMoment: startMoment,
									endMoment: endMoment,
									startTime: shift.start,
									endTime: shift.end,
									month: currDay.format("MMM"),
									day: currDay.format("D"),
									date: date,
									shifts: shifts,
									userSchedules: userSchedules,
								},
								render: {
									project: project.name,
									users: users,
									time: timeStr,
									shiftName: shiftName,
									satisfied: satisfied,
									filled: filled,
								},
							});
						});

						if (condensed && dayEvents.length > 0) {
							let allSatisfied = true;
							let anyUnsatisfied = false;
							let anyFilled = false;
							let earliestStart = null;
							let latestEnd = null;
							let shifts = null;
							let userSchedules = null;

							_.each(dayEvents, (event) => {
								if (!event.render.satisfied) {
									allSatisfied = false;
								}
								if (event.render.satisfied == false) {
									anyUnsatisfied = true;
								}
								if (event.render.filled) {
									anyFilled = true;
								}
								earliestStart = fs.earlierMoment(earliestStart, event.meta.startMoment);
								latestEnd = fs.laterMoment(latestEnd, event.meta.endMoment);
								shifts = event.meta.shifts;
								userSchedules = event.meta.userSchedules;
							});

							let satisfied = anyUnsatisfied ? 0 : allSatisfied ? 1 : -1;

							events.push({
								title: "[not rendered]",
								start: earliestStart && earliestStart.format("YYYY-MM-DD HH:mm"),
								end: latestEnd && latestEnd.format("YYYY-MM-DD HH:mm"),
								weekday: currDay.format("dddd"),
								meta: {
									projectID: project.id,
									projectSeq: sequence,
									month: currDay.format("MMM"),
									day: currDay.format("D"),
									date: date,
									shifts: shifts,
									userSchedules: userSchedules,
								},
								render: {
									project: project.name,
									satisfied: satisfied,
									filled: anyFilled,
								},
							});
						} else {
							events = events.concat(dayEvents);
						}
					} else {
						//Process time range
						let startMoment = this.combineDay(moment(times.start, fs.timeFormat()), currDay);
						let start = startMoment.format("YYYY-MM-DD HH:mm");
						let endMoment = this.combineDay(moment(times.end, fs.timeFormat()), currDay);
						let end = endMoment.format("YYYY-MM-DD HH:mm");

						//Figure out what strings to display
						let { users, peakUsers, workHours } = this.getCombinedDayStatsFree(scheduleDayUsers);

						let usersStr = this.$i18n.tc("Calendar.n_peak_users", peakUsers, { n: peakUsers });
						let hours = this.$i18n.t("Calendar.title_hours", { n: fs.fixed1d(workHours) });

						let { satisfied, filled } = this.getTimeEntryStatus(
							users,
							peakUsers,
							workHours,
							scheduleConfig
						);

						//Sort user schedules, save for display in modal
						let userSchedules = [];
						_.each(scheduleDayUsers, (sdu) => {
							userSchedules.push(sdu);
						});
						userSchedules = _.sortBy(userSchedules, "created_at");

						events.push({
							title: "[not rendered]",
							start: start,
							end: end,
							meta: {
								projectID: project.id,
								projectSeq: sequence,
								startMoment: startMoment,
								endMoment: endMoment,
								startTime: times.start,
								endTime: times.end,
								month: currDay.format("MMM"),
								day: currDay.format("D"),
								date: date,
								weekday: currDay.format("dddd"),
								shifts: shifts,
								userSchedules: userSchedules,
							},
							render: {
								project: project.name,
								users: usersStr,
								hours: hours,
								satisfied: satisfied,
								filled: filled,
							},
						});
					}
				}

				if (this.isDayLocked(date)) {
					events.push({
						title: "Locked",
						start: currDay.format("YYYY-MM-DD"),
						end: currDay.format("YYYY-MM-DD"),
						rendering: "background",
						classNames: "fc-locked-day",
						meta: {},
						render: {
							lock: true,
						},
					});
				}
			}

			return events;
		},

		getUserProjectEvents(project, sequence, condensed, focusUser) {
			let events = [];
			let scoreStats = focusUser.score_stats;
			let timeStats = focusUser.time_stats;
			let now = moment();

			let dayTimes = [];
			if (project.enforce_times) {
				let everyDay = _.find(project.avail_times, { day: 7 });
				if (everyDay) {
					for (let i = 0; i <= 6; i++) {
						dayTimes[i] = {
							start: everyDay.start,
							end: everyDay.end,
						};
					}
				} else {
					for (let i = 0; i <= 6; i++) {
						let day = _.find(project.avail_times, { day: i });
						if (day) {
							dayTimes[i] = {
								start: day.start,
								end: day.end,
							};
						} else {
							dayTimes[i] = null;
						}
					}
				}
			} else {
				dayTimes = [
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
				];
			}

			for (
				let currDay = moment(project.scoring_start);
				currDay.isSameOrBefore(project.scoring_end);
				currDay.add(1, "days")
			) {
				let dayOfWeek = currDay.day() % 7;
				let times = dayTimes[dayOfWeek];
				let date = currDay.format("MM/DD/YYYY");
				if (times) {
					let scheduleDays = this.scheduleMap[project.id];
					let scheduleUsers = scheduleDays && scheduleDays[date];
					let scheduleDay = scheduleUsers && scheduleUsers[focusUser.id];

					if (project.use_shifts) {
						let weeklyShifts = _.filter(project.shifts, { weekday: dayOfWeek });
						let specificShifts = _.filter(project.shifts, { weekday: -1, date: date });
						let shifts = weeklyShifts.concat(specificShifts);

						let scheduledSeconds = 0;
						let numShifts = 0;
						let earliestStart = null;
						let latestEnd = null;
						_.each(shifts, (shift, i) => {
							if (scheduleDay && scheduleDay.shift_ids && scheduleDay.shift_ids.includes(shift.id)) {
								let startMoment = this.combineDay(moment(shift.start, fs.timeFormat()), currDay);
								let endMoment = this.combineDay(moment(shift.end, fs.timeFormat()), currDay);

								earliestStart = fs.earlierMoment(earliestStart, startMoment);
								latestEnd = fs.laterMoment(latestEnd, endMoment);

								let shiftSeconds = endMoment.diff(startMoment, "seconds");
								scheduledSeconds += shiftSeconds;
								numShifts++;
							}
						});

						if (numShifts > 0) {
							let start = earliestStart.format("YYYY-MM-DD HH:mm");
							let end = latestEnd.format("YYYY-MM-DD HH:mm");

							let attendance = 0;
							let scoredSeconds = 0;
							let scores = 0;
							let res = 0;
							let systemSeconds = 0;
							if (scoreStats && scoreStats[project.id] && scoreStats && scoreStats[project.id][date]) {
								scoredSeconds = scoreStats[project.id][date].score_time;
								scores = scoreStats[project.id][date].scores;
								res = scoreStats[project.id][date].res;

								attendance = scoredSeconds / scheduledSeconds;
							}

							if (timeStats && timeStats[date]) {
								systemSeconds = timeStats[date];
							}

							let satisfied = null;
							let rightStr = "";
							let past = currDay.isBefore(now, "day");
							if (past) {
								rightStr = fs.fixedPercent1d(attendance * 100);
								if (attendance > 0.5) {
									satisfied = 1;
								} else if (attendance > 0) {
									satisfied = 0.5;
								} else {
									satisfied = 0;
									rightStr = this.$i18n.t("Calendar.absent");
								}
							} else {
								rightStr = "";
								satisfied = -1;
							}

							let leftStr = this.$i18n.tc("Calendar.n_shifts_with_hours", numShifts, {
								n: numShifts,
								hours: (scheduledSeconds / 3600).toFixed(1),
							});

							events.push({
								title: "[not rendered]",
								start: start,
								end: end,
								meta: {
									projectID: project.id,
									projectSeq: sequence,
									startMoment: earliestStart,
									endMoment: latestEnd,
									startTime: start,
									endTime: end,
									month: currDay.format("MMM"),
									day: currDay.format("D"),
									date: date,
									weekday: currDay.format("dddd"),
									shifts: shifts,
									scheduledSeconds: scheduledSeconds,
									scoredSeconds: scoredSeconds,
									scores: scores,
									res: res,
									systemSeconds: systemSeconds,
									past: past,
								},
								render: {
									project: project.name,
									time: leftStr,
									users: rightStr,
									shiftName: leftStr,
									satisfied: satisfied,
									filled: true,
								},
							});
						}
					} else {
						let startMoment = this.combineDay(moment(times.start, fs.timeFormat()), currDay);
						let start = startMoment.format("YYYY-MM-DD HH:mm");
						let endMoment = this.combineDay(moment(times.end, fs.timeFormat()), currDay);
						let end = endMoment.format("YYYY-MM-DD HH:mm");

						let timeStr = this.generateDayText(startMoment, endMoment);
						let hours = this.$i18n.t("MySchedule.available");

						if (scheduleDay) {
							let userStart = this.combineDay(moment(scheduleDay.start_time, fs.timeFormat()), currDay);
							start = userStart.format("YYYY-MM-DD HH:mm");
							let userEnd = this.combineDay(moment(scheduleDay.end_time, fs.timeFormat()), currDay);
							end = userEnd.format("YYYY-MM-DD HH:mm");

							let projectDuration = endMoment.diff(startMoment);
							let userDuration = userEnd.diff(userStart, "seconds");
							timeStr = this.generateDayText(userStart, userEnd);
							hours = this.$i18n.t("Calendar.title_hours", { n: fs.fixed1d(userDuration / (60 * 60)) });

							let attendance = 0;
							let scoredSeconds = 0;
							let scores = 0;
							let res = 0;
							let systemSeconds = 0;
							if (scoreStats && scoreStats[project.id] && scoreStats && scoreStats[project.id][date]) {
								scoredSeconds = scoreStats[project.id][date].score_time;
								scores = scoreStats[project.id][date].scores;
								res = scoreStats[project.id][date].res;

								attendance = scoredSeconds / userDuration;
							}

							if (timeStats && timeStats[date]) {
								systemSeconds = timeStats[date];
							}

							let satisfied = null;
							let rightStr = "";
							let past = currDay.isBefore(now, "day");
							if (past) {
								rightStr = fs.fixedPercent1d(attendance * 100);
								if (attendance > 0.5) {
									satisfied = 1;
								} else if (attendance > 0) {
									satisfied = 0.5;
								} else {
									satisfied = 0;
									rightStr = this.$i18n.t("Calendar.absent");
								}
							} else {
								rightStr = "";
								satisfied = -1;
							}

							let newEvent = {
								title: "[not rendered]",
								start: start,
								end: end,
								meta: {
									projectID: project.id,
									projectSeq: sequence,
									startMoment: startMoment,
									endMoment: endMoment,
									startTime: times.start,
									endTime: times.end,
									month: currDay.format("MMM"),
									day: currDay.format("D"),
									date: date,
									weekday: currDay.format("dddd"),
									scheduledSeconds: userDuration,
									scoredSeconds: scoredSeconds,
									scores: scores,
									res: res,
									systemSeconds: systemSeconds,
									past: past,
								},
								render: {
									project: project.name,
									time: timeStr,
									hours: hours,
									users: rightStr,
									satisfied: satisfied,
									filled: true,
								},
							};
							events.push(newEvent);
						}
					}
				}

				if (this.isDayLocked(date)) {
					events.push({
						title: "Locked",
						start: currDay.format("YYYY-MM-DD"),
						end: currDay.format("YYYY-MM-DD"),
						rendering: "background",
						classNames: "fc-locked-day",
						meta: {},
						render: {
							lock: true,
						},
					});
				}
			}

			return events;
		},

		generateDayText(start, end) {
			let startStr = start.minute() == 0 ? start.format("ha") : start.format("h:mma");
			let endStr = end.minute() == 0 ? end.format("ha") : end.format("h:mma");

			let name = this.$i18n.t("MySchedule.start_to_end", { start: startStr, end: endStr });

			return name;
		},

		getCombinedDayStatsFree(scheduleDayUsers) {
			let result = {
				users: 0,
				peakUsers: 0,
				workHours: 0,
			};

			let rangeValues = [];
			let thresholdTimes = {};
			let userMinutes = 0;
			_.each(scheduleDayUsers, (schedule) => {
				result.users += 1;

				let startMinutes = Utils.minutesFromMidnight(schedule.start_time);
				let endMinutes = Utils.minutesFromMidnight(schedule.end_time);
				rangeValues.push({
					start: startMinutes,
					end: endMinutes,
				});

				thresholdTimes[startMinutes] = true;
				thresholdTimes[endMinutes] = true;

				let duration = endMinutes - startMinutes;
				userMinutes += duration;
			});

			//Slice the day up into periods with thresholds at every start and end time defined by a user
			//Those slices of time can be used as buckets that will count number of users
			//Then, iterate through the schedule of each user and tally a mark in each bucket from their start to end time
			//
			//This is functionally equivalent to tallying the number of users in each minute, except pre-optimized to
			//eliminate consideration of minutes where no user starts or ends their schedule
			let timeSlices = [];
			for (let time in thresholdTimes) {
				timeSlices.push({ start: time, count: 0 });
			}

			timeSlices = _.sortBy(timeSlices, "start");

			_.each(rangeValues, (rv) => {
				for (let slice of timeSlices) {
					if (slice.start > rv.end) {
						break;
					}

					if (slice.start >= rv.start) {
						slice.count++;
					}
				}
			});

			_.each(timeSlices, (slice) => {
				result.peakUsers = Math.max(result.peakUsers, slice.count);
			});
			result.workHours = userMinutes / 60;

			return result;
		},

		getShiftEntryStatus(shiftID, users, config) {
			let shiftThreshold = config.shift_thresholds[shiftID] || 0;

			let satisfied = -1;
			if (shiftThreshold == 0) {
				satisfied = -1;
			} else {
				if (users >= shiftThreshold) {
					satisfied = 1;
				} else {
					satisfied = 0;
				}
			}

			let filled = null;
			if (users == 0) {
				filled = false;
				//color += "-outline-lh15";
			} else {
				filled = true;
				//color += "-cal";
			}

			return { satisfied: satisfied, filled: filled };
		},

		getTimeEntryStatus(users, peakUsers, workHours, config) {
			let satisfied = -1;
			switch (config.threshold_type) {
				case 0: //No threshold
					satisfied = -1;
					break;
				case 1: //Users
					if (users >= config.threshold) {
						satisfied = 1;
					} else {
						satisfied = 0;
					}
					break;
				case 2: //Peak users
					if (peakUsers >= config.threshold) {
						satisfied = 1;
					} else {
						satisfied = 0;
					}
					break;
				case 3: //Work-hours
					if (workHours >= config.threshold) {
						satisfied = 1;
					} else {
						satisfied = 0;
					}
					break;
			}

			let filled = null;
			if (users == 0) {
				filled = false;
			} else {
				filled = true;
			}

			return { satisfied: satisfied, filled: filled };
		},

		combineDay(time, day) {
			time.year(day.year());
			time.month(day.month());
			time.date(day.date());

			return time;
		},

		lockDay() {
			this.selectedConfig.locked = true;
			this.$forceUpdate();
		},

		unlockDay() {
			this.selectedConfig.locked = false;
			this.$forceUpdate();
		},

		scheduleDuration(schedule) {
			let startMinutes = Utils.minutesFromMidnight(schedule.start_time);
			let endMinutes = Utils.minutesFromMidnight(schedule.end_time);

			let duration = endMinutes - startMinutes;
			return fs.fixed1d(duration / 60);
		},

		blankConfig() {
			return {
				project_id: this.editingProject && this.editingProject.id,
				date: this.selectedEvent.date,
				threshold_type: 0,
				threshold: null,
				shift_thresholds: {},
				user_labels: {},
				locked: null,
			};
		},

		isDayLocked(date) {
			let scheduleConfigs = this.sProject && this.scheduleConfigMap[this.sProject.id];
			if (scheduleConfigs) {
				let config = scheduleConfigs[date];
				if (config) {
					return this.locked(config, this.sProject);
				}
			}
			return this.locked({ date: date, locked: null }, this.sProject);
		},

		locked(config, project) {
			if (!(config && project)) {
				return false;
			}

			if (config.locked == true) {
				//If locked is true, it should always be locked
				return true;
			}

			let lockoutHours = project.schedule_lockout || 0;

			let now = moment();
			let configDay = moment(config.date, "MM/DD/YYYY").startOf("day");
			if (config.locked == false) {
				//if locked is false, it should always be unlocked unless it is in the past
				return now.isAfter(configDay);
			}

			//Otherwise, locked is null, indicating that an admin has never changed it
			//In this case, the day should be locked if it's within the defined lockout period
			let lockoutMoment = configDay.clone().subtract(lockoutHours, "hours");
			return now.isAfter(lockoutMoment);
		},

		todayOrPast(config) {
			if (!config) {
				return false;
			}

			let now = moment();
			let configDay = moment(config.date, "MM/DD/YYYY").startOf("day");
			return now.isAfter(configDay);
		},

		convertDateFormat(date) {
			//Convert the Calendar's internal date format to the one we use for maps
			let dateParts = date.split("-");
			let newDateParts = [];
			newDateParts[0] = dateParts[1]; //Month
			newDateParts[1] = dateParts[2]; //Day
			newDateParts[2] = dateParts[0];
			return newDateParts.join("/");
		},

		selectAllProjects() {
			this.sProject = { id: "all" };
		},

		eventClick(info) {
			let projectID = info.event.extendedProps.meta.projectID;
			let project = null;
			_.each(this.projects, (p) => {
				if (p.id == projectID) {
					project = p;
				}
			});
			if (!project) {
				console.error("Failed to find project with ID", projectID);
				return;
			}
			this.editingProject = project;

			this.selectedEvent = info.event.extendedProps.meta;
			this.selectedElement = info.el;

			this.selectedConfig = null;
			if (project && this.scheduleConfigMap[project.id]) {
				this.selectedConfig = this.scheduleConfigMap[project.id][this.selectedEvent.date];
			}
			if (!this.selectedConfig) {
				this.selectedConfig = this.blankConfig();
			}

			if (project.use_shifts) {
				_.each(this.selectedEvent.shifts, (shift) => {
					if (!this.selectedConfig.shift_thresholds[shift.id]) {
						this.selectedConfig.shift_thresholds[shift.id] = 0;
					}
				});
			}

			_.each(this.selectedEvent.userSchedules, (us) => {
				if (!this.selectedConfig.user_labels[us.user_id]) {
					this.selectedConfig.user_labels[us.user_id] = "";
				}
			});

			this.editEvent = {
				startTime: this.selectedEvent.startTime,
				endTime: this.selectedEvent.endTime,
				recur: fs.getRecurTypes(this.selectedEvent.weekday)[0],
			};

			if (this.focusUser) {
				this.loadAtdChartData(this.selectedEvent);
			}

			this.selectedOpen = true;
		},
		updateRange({ start, end }) {
			// You could load events from an outside source (like database) now that we have the start and end dates on the calendar
			this.start = start;
			this.end = end;
		},
		nth(d) {
			return d > 3 && d < 21 ? "th" : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
		},

		calcCalendarHeight() {
			let el = document.getElementById("calendar-container");
			let height = el.offsetHeight;

			return height - 16;
		},

		getTimeHour(time) {
			if (time == 0) {
				return "—";
			} else if (!time) {
				return "—";
			} else {
				return parseInt(time / 3600);
			}
		},

		getTimeMinute(time) {
			if (time == 0) {
				return "—";
			} else if (!time) {
				return "—";
			} else {
				let minutes = (time % 3600) / 60;
				minutes = parseInt(minutes);
				minutes = minutes < 10 ? "0" + minutes : minutes;
				return minutes;
			}
		},

		loadAtdChartData(event) {
			let scheduled = event.scheduledSeconds;
			let system = event.systemSeconds;
			let scored = event.scoredSeconds;
			let other = 0;

			_.each(this.focusUser.score_stats, (ss, projectID) => {
				if (projectID != event.projectID && ss[event.date]) {
					other += ss[event.date].score_time;
				}
			});

			let systemRemainder = system - (scored + other);
			let scheduledRemainder = scheduled - system;

			let data = [];
			let labels = [];
			let colors = [];
			let borders = [];

			if (scored > 0) {
				data.push(((100 * scored) / scheduled).toFixed(1));
				labels.push(this.$i18n.t("Calendar.scoring_this_project"));
				colors.push("rgba(34, 182, 110, 0.85)");
				borders.push("rgba(34, 182, 110, 1)");
			}
			if (other > 0) {
				data.push(((100 * other) / scheduled).toFixed(1));
				labels.push(this.$i18n.t("Calendar.scoring_other_projects"));
				colors.push("rgba(83, 166, 250, 0.85)");
				borders.push("rgba(83, 166, 250, 1)");
			}
			if (systemRemainder > 0) {
				data.push(((100 * systemRemainder) / scheduled).toFixed(1));
				labels.push(this.$i18n.t("Calendar.logged_in_not_scoring"));
				colors.push("rgba(253, 171, 41, 0.85)");
				borders.push("rgba(253, 171, 41, 1)");
			}
			if (scheduledRemainder > 0) {
				data.push(((100 * scheduledRemainder) / scheduled).toFixed(1));
				labels.push(this.$i18n.t("Calendar.scheduled"));
				colors.push("rgba(244, 67, 54, 0.85)");
				borders.push("rgba(244, 67, 54, 1)");
			}

			this.atdChartData.data.datasets[0].data = data;
			this.atdChartData.data.labels = labels;
			this.atdChartData.data.datasets[0].backgroundColor = colors;
			this.atdChartData.data.datasets[0].borderColor = borders;
		},
	},
};
</script>
