<template>
	<div class="box" style="height: 360px">
		<div class="p-4 h-100">
			<span class="refresh text-muted">
				<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
					<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
				</a>
			</span>
			<div class="d-flex flex-row justify-content-center h-100">
				<table style="width: 33%; height: 100%; table-layout: fixed">
					<tr style="height: 33%">
						<td class="text-center v-mid b-r">
							<h4 :class="{ 'text-very-muted': busy }">{{ scoreStats.total }}</h4>
							<div class="text-muted mb-0 _500">{{ $t("ReportUserSummary.scores") }}</div>
						</td>
					</tr>
					<tr style="height: 33%">
						<td class="text-center v-mid b-r">
							<h4 :class="{ 'text-very-muted': busy }">
								{{ fs.medDurationLetters(scoreStats.total_time) }}
							</h4>
							<div class="text-muted mb-0 _500">{{ $t("ReportUserSummary.total_time") }}</div>
						</td>
					</tr>
					<tr style="height: 33%">
						<td class="text-center v-mid b-r">
							<h4 :class="{ 'text-very-muted': busy }">
								{{ fs.medDurationLetters(scoreStats.avg_time) }}
							</h4>
							<exception-gauge
								v-if="item.exception_config && item.exception_config.rate_enabled"
								:min="0"
								:max="600"
								:safeMin="item.exception_config.rate_min_seconds"
								:safeMax="item.exception_config.rate_max_seconds"
								:value="scoreStats.avg_time"
								class="gauge"
								:class="{ 'text-very-muted': busy }"
							/>
							<div class="text-muted mb-0 _500">{{ $t("ReportUserSummary.avg_time") }}</div>
						</td>
					</tr>
				</table>

				<table style="width: 67%; height: 100%; table-layout: fixed">
					<tr style="height: 33%" v-if="totals.irr_exact">
						<td class="text-center v-mid b-b">
							<h4 :class="{ 'text-very-muted': !totals.irr_exact || busy }">
								{{ fs.fixedPercent1d(totals.irr_exact_percent) }}
							</h4>
							<span class="text-muted mb-0 _500">{{ $t("ReportUserSummary.irr") }}</span>
							<span class="text-muted">({{ $t("ReportUserSummary.exact") }})</span>
						</td>
						<td class="text-center v-mid b-b">
							<h4 :class="{ 'text-very-muted': !totals.irr_exact_adj || busy }">
								{{ fs.fixedPercent1d(totals.irr_exact_adj_percent) }}
							</h4>
							<div class="text-muted">({{ $t("ReportUserSummary.exact_adjacent") }})</div>
						</td>
					</tr>
					<tr style="height: 33%" v-if="totals.val_total && showVal">
						<td class="text-center v-mid b-b">
							<h4 :class="{ 'text-very-muted': !totals.val_exact || busy }">
								{{ fs.fixedPercent1d(totals.val_exact_percent) }}
							</h4>
							<exception-gauge
								v-if="item.validity_config.enabled"
								:min="0"
								:max="100"
								:safeMin="valGaugeMin"
								:safeMax="100"
								:value="totals.val_exact_percent"
								class="gauge"
								:class="{ 'text-very-muted': !totals.val_exact || busy }"
							/>
							<span class="text-muted mb-0 _500">{{ $t("ReportUserSummary.validity") }}</span>
							<span class="text-muted">({{ $t("ReportUserSummary.exact_adjacent") }})</span>
						</td>
						<td class="text-center v-mid b-b">
							<a>
								<h4 :class="{ 'text-very-muted': !totals.val_exact_adj || busy }">
									{{ fs.fixedPercent1d(totals.val_exact_adj_percent) }}
								</h4>
								<div class="text-muted">({{ $t("ReportUserSummary.exact_adjacent") }})</div>
							</a>
						</td>
					</tr>
					<tr style="height: 33%">
						<td class="text-center v-mid">
							<a>
								<h4 :class="{ 'text-very-muted': totals.res_total == 0 || busy }">
									{{ fs.fixedPercent1d(totals.res_required_percent) }}
								</h4>
								<div class="text-muted mb-0 _500">
									{{ $t("ReportUserSummary.resolution_required") }}
								</div>
								<div class="text-muted">
									{{ totals.res_required }} {{ $t("ReportUserSummary.out_of") }}
									{{ totals.res_total }}
								</div>
							</a>
						</td>
						<td class="text-center v-mid">
							<a>
								<h4 :class="{ 'text-very-muted': totals.res_total == 0 || busy }">
									{{ fs.fixedPercent1d(totals.res_changed_percent) }}
								</h4>
								<div class="text-muted mb-0 _500">{{ $t("ReportUserSummary.resolution_changed") }}</div>
								<div class="text-muted">
									{{ totals.res_changed }} {{ $t("ReportUserSummary.out_of") }} {{ totals.res_total }}
								</div>
							</a>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<style scoped>
a {
	opacity: 0.35;
	transition: opacity 0.25s;
}

a:hover {
	opacity: 1;
}

a > h1 {
	border-radius: 0.5rem;
	transition: background-color 0.25s;
	padding-bottom: 2px;
}

a > h1:hover {
	background-color: rgb(0, 0, 0, 0.05);
}

td {
	padding: 0.25rem 0.5rem;
	text-overflow: ellipsis;
}

.refresh {
	position: absolute;
	top: 0.7rem;
	right: 1rem;
}

::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.gauge {
	width: 75%;
	max-width: 150px;
	min-width: 60px;
	margin: auto;
	margin-bottom: 4px;
}

@media print {
	.refresh {
		display: none !important;
	}
}
</style>

<script>
import ReportingService from "@/services/ReportingService";
import store from "@/services/Store";
import fs from "@/services/FormatService";
import chartjs from "chart.js";
require("@/directives/chartjs");
import moment from "moment";

import ExceptionGauge from "@/components/ExceptionGauge";

export default {
	name: "UserStatBlock",

	props: ["section", "item", "user"],

	components: { ExceptionGauge },

	data() {
		return {
			fs: fs,
			busy: false,
			showVal: true,
			totals: {},
			stats: {},
			scoreStats: {},
		};
	},
	created() {
		this.loadData();
	},
	watch: {
		section() {
			this.loadData();
		},
		item() {
			this.loadData();
		},
		user() {
			this.loadData();
		},
		labels() {
			this.loadData();
		},
	},

	computed: {
		valGaugeMin() {
			const { item } = this;
			if (!item) return 0;
			const { uses_threshold, window_rules, threshold_rule } = item.validity_config;
			if (uses_threshold) return threshold_rule.pass_percent;
			if (!(window_rules && window_rules.length > 0)) return 0;
			return window_rules[0].pass_percent;
		},
	},

	methods: {
		loadData() {
			if (!(this.section && this.item && this.user)) {
				return;
			}
			if (this.busy) {
				return;
			}

			this.busy = true;
			ReportingService.getAgreementUsers(this.section.id, this.item.id, this.user.id)
				.then((resp) => {
					console.log(resp);
					this.totals = resp.data.totals;
					this.showVal = !this.labels || this.labels.length == 0;
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});

			let ctx = {
				section_id: this.section.id,
				item_id: this.item.id,
				user_id: this.user.id,
			};
			ReportingService.getScorerStats(ctx).then((resp) => {
				this.scoreStats = {};
				if (resp.data.length > 0) {
					this.scoreStats = resp.data[0];
					this.scoreStats.rate = 3600 / this.scoreStats.avg_time;
				}
			});
		},
	},
};
</script>
