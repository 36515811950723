<!-- Template for a new page -->
<template>
	<div class="d-flex flex-column flex h-100 scroll-y page-body">
		<navbar @login="doReturn"></navbar>
		<div v-if="loading" class="text-center">
			<loading type="large" class="mt-5" />
		</div>
		<template v-if="!loading">
			<div class="header theme">
				<div class="text-muted mr-2 ml-3">{{ $t("TrainingResource.course") }}:</div>
				<div>{{ course.name }}</div>
				<div class="horizontal-divider ml-3"></div>
				<div class="text-muted mr-2 ml-3">{{ $t("TrainingResource.element") }}:</div>
				<div>{{ element.name }}</div>
				<div class="horizontal-divider ml-3"></div>

				<div class="horizontal-divider"></div>
				<div class="horizontal-divider ml-auto"></div>
				<a class="header-button" @click="doReturn"> {{ $t("tooltip.exit") }}<i class="ml-2 fas fa-times" /> </a>
			</div>
			<div class="h-100 w-100 text-center scroll-y" id="resources-container">
				<div class="resources-container my-3 mx-1" v-if="element && pickedResource">
					<resource
						:resource="pickedResource"
						:trainingCourse="course"
						:trainingElement="element"
						:user="user"
						:directRenderIFrame="true"
					/>
				</div>
				<div v-else>
					<h2 class="text-muted">{{ $t("ExternalResource.invalid_resource") }}</h2>
				</div>
			</div>
		</template>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
	width: 100%;
	min-height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.header-button {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding-left: 1rem;
	padding-right: 1rem;
	transition: background-color 0.15s ease-in-out;
}

.header-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.horizontal-divider {
	height: 100%;
	width: 1px;
	background-color: white;
}

.resources {
	position: absolute;
	height: 100%;
	bottom: 0;
	top: 0;
	width: calc(50vw);
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	box-shadow: inset 6px 0px 10px -4px rgba(184, 182, 184, 1);
}

.resources > .resources-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.dropdown-item.active {
	color: inherit;
	background-color: rgba(0, 0, 0, 0.15);
}

.resources-container {
	z-index: 2;
}
</style>

<script>
//Services
import TrainingService from "@/services/TrainingService";
import ResourceService from "@/services/ResourceService";
import Notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";

import Resource from "@/components/viewer/Resource";
import CompensationQueueTimeService from "@/services/CompensationQueueTimeService";
import TimeoutService from "../../services/TimeoutService";

var RESOURCE = 2;

var COMPLETE = 2;

const MESSAGE = "only_one_active_tab_or_window_allowed_for_training";

export default {
	name: "TrainingResource",

	props: ["user", "params"],

	components: { Resource },

	data() {
		return {
			broadcastChannel: null,
			fs: fs,
			Utils: Utils,

			loading: true,
			course: null,
			element: null,

			pickedResource: null,
			compensationStartTime: null,
		};
	},

	watch: {},

	computed: {},

	created() {
		this.broadcastChannel = new BroadcastChannel("oscar_broadcast_channel");
		this.broadcastChannel.postMessage(MESSAGE);
		this.broadcastChannel.onmessage = (event) => {
			if (event.data == MESSAGE) {
				console.log(event.data);
				this.$router.push("/landing");
			}
		};

		TrainingService.getTrainingCourse(this.params.course_id)
			.then((r) => {
				this.loading = false;
				this.course = r.data;
				this.compensationStartTime = this.course.compensation_start_time;
				console.log("TRAINING RESOURCE COMP");
				for (let element of this.course.elements) {
					if (element.id == this.params.element_id) {
						if (element.type == RESOURCE && element.resource) {
							this.element = element;

							if (Utils.isRenderableResource(element.resource)) {
								this.pickedResource = element.resource;
								TrainingService.updateElementProgress(this.course.id, element.id, COMPLETE);
							} else {
								console.error("Not a renderable resource");
								// ResourceService.viewResource(this.item, pickedResource);
							}
						} else {
							console.error("Specified element is not a resource type");
						}
					}
				}
			})
			.catch((e) => {
				console.error(e);
				Notie.error("Failed to load resource", e);
				this.loading = false;
			});

		window.addEventListener("beforeunload", this.updateCompensationQueueTime);
		var _this = this
		window.onpopstate = function () {
			_this.updateCompensationQueueTime();
		};
	},

	beforeDestroy() {
		if (this.broadcastChannel) {
			this.broadcastChannel.close();
		}
		window.removeEventListener("beforeunload", this.updateCompensationQueueTime);
		window.onpopstate = function () {};
		this.updateCompensationQueueTime();
	},

	methods: {
		updateCompensationQueueTime() {
			if (this.user && this.course)
			CompensationQueueTimeService.updateCompensationQueueTimeForTrainingResource(
				this.user.id,
				this.course.id,
				this.compensationStartTime
			);
		},

		pickResource(res) {
			this.pickedResource = res;
		},

		downloadResource(res) {
			ResourceService.downloadResource(this.item, res);
		},

		doReturn() {
			this.$router.go(-1);
		},
	},
};
</script>
