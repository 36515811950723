<template>
	<div class="box">
		<div class="box-header">
			<h3 class="pl-2 pb-2 border-bottom">
				{{ $t("fields.scoring") }}
			</h3>
		</div>
		<div class="row no-gutters" :class="{ 'text-muted': busy }">
			<div class="col-12 col-sm-4">
				<div class="padding">
					<week-select @newTimeSpan="newWeekSelected" />
					<table>
						<tr>
							<td>
								<div class="text-muted mt-4">{{ $t("fields.overall") }}</div>
							</td>
							<td>
								<div class="text-muted mt-4">{{ $t("fields.weekly") }}</div>
							</td>
						</tr>
						<tr>
							<td>
								<h5 class="_600">{{ totalScores }} {{ $t("fields.scores") }}</h5>
								<div v-if="includeQC" class="text-primary _600" style="margin-top: -8px">
									<span
										v-tippy="{ placement: 'bottom' }"
										:title="$t('Dashboard.includes_val_and_cal')"
										>+{{ qcScores }} QC</span
									>
								</div>
							</td>
							<td>
								<h5 class="_600">{{ totalScoresWeek }} {{ $t("fields.scores") }}</h5>
								<div v-if="includeQC" class="text-primary _600" style="margin-top: -8px">
									<span
										v-tippy="{ placement: 'bottom' }"
										:title="$t('Dashboard.includes_val_and_cal')"
										>+{{ qcScoresWeek }} QC</span
									>
								</div>
							</td>
						</tr>
						<tr></tr>
						<tr>
							<td>
								<div class="d-flex flex-row align-items-baseline">
									<h5 class="_600">{{ getTimeHour(totalTime) }}</h5>
									<span class="text-muted mx-small">{{ $t("timestamp.hour_abr") }}</span>
									<h5 class="_600">{{ getTimeMinute(totalTime) }}</h5>
									<span class="text-muted mx-small">{{ $t("timestamp.min_abr") }}</span>
								</div>
							</td>
							<td>
								<div class="d-flex flex-row align-items-baseline">
									<h5 class="_600">{{ getTimeHour(totalTimeWeek) }}</h5>
									<span class="text-muted mx-small">{{ $t("timestamp.hour_abr") }}</span>
									<h5 class="_600">{{ getTimeMinute(totalTimeWeek) }}</h5>
									<span class="text-muted mx-small">{{ $t("timestamp.min_abr") }}</span>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="col-12 col-sm-4">
				<div class="padding">
					<h5 class="_600">
						<span class="float-right text-muted">
							<a @click="loadData" class="no-select" v-tippy :title="$t('tooltip.refresh')">
								<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
							</a>
						</span>
						<span>{{ $t("fields.scores") }}</span>
					</h5>
					<!-- <p class="text-muted mb-4">Responses</p> -->
					<div class="py-3">
						<canvas
							id="chart-doughnut-2"
							v-chartjs="scoreChart"
							style="width: 100%; height: 180px"
						></canvas>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-4">
				<div class="padding">
					<h5 class="_600">
						<span class="float-right text-muted">
							<a @click="loadData" class="no-select" v-tippy :title="$t('tooltip.refresh')">
								<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
							</a>
						</span>
						<span>{{ $t("fields.score_time") }}</span>
					</h5>
					<!-- <div class="text-muted mb-4">Score Volume</div> -->
					<div class="py-3">
						<canvas id="chart-line-2" v-chartjs="timeChart" style="width: 100%; height: 180px"></canvas>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
a {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a:hover {
	opacity: 1;
}
table {
	width: 100%;
}
td {
	padding-bottom: 2rem;
}
</style>

<script>
import ReportingService from "@/services/ReportingService";
require("@/directives/chartjs");
import moment from "moment";
import WeekSelect from "@/components/WeekSelect";

export default {
	name: "DashboardPersonalStats",

	components: {
		WeekSelect,
	},

	props: ["user"],

	data() {
		return {
			includeQC: this.user.role.page_dashboard_qc_counts,
			totalTime: 0,
			totalTimeWeek: 0,
			totalScores: 0,
			totalScoresWeek: 0,
			qcScores: 0,
			qcScoresWeek: 0,
			busy: false,

			scoreChart: {
				type: "line",
				data: {
					labels: ["S", "M", "T", "W", "T", "F", "S"],
					datasets: [
						{
							label: this.$i18n.t("fields.scores"),
							data: [],
							fill: true,
							backgroundColor: "rgba(0, 188, 212, 0.1)",
							// borderColor: app.color.primary,
							borderWidth: 2,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBorderColor: "grey",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 2,
							pointHoverRadius: 4,
							// pointHoverBackgroundColor: app.color.primary,
							pointHoverBorderColor: "#fff",
							pointHoverBorderWidth: 2,
							pointRadius: [],
							pointHitRadius: 40,
							spanGaps: false,
						},
					],
				},
				options: {
					tooltips: {
						displayColors: false,
						titleFontSize: 14,
						bodyFontSize: 14,
						callbacks: {
							title: (items, data) => {
								let i = items[0].index;
								let day = this.requestTime.day(i);
								return `${day.format(this.$i18n.t("Dashboard.alternate_day_format"))}`;
							},
						},
					},
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								display: true,
							},
						],
						yAxes: [
							{
								display: false,
								ticks: {
									suggestedMin: 0,
								},
							},
						],
					},
					legend: {
						display: false,
					},
					layout: {
						padding: {
							top: 10,
						},
					},
				},
			},
			timeChart: {
				type: "line",
				data: {
					labels: ["S", "M", "T", "W", "T", "F", "S"],
					datasets: [
						{
							label: this.$i18n.t("fields.score_time"),
							data: [],
							fill: true,
							backgroundColor: "rgba(212, 148, 0, 0.1)",
							// borderColor: app.color.primary,
							borderWidth: 2,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBorderColor: "grey",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 2,
							pointHoverRadius: 4,
							// pointHoverBackgroundColor: app.color.primary,
							pointHoverBorderColor: "#fff",
							pointHoverBorderWidth: 2,
							pointRadius: [],
							pointHitRadius: 40,
							spanGaps: false,
						},
					],
				},
				options: {
					tooltips: {
						displayColors: false,
						titleFontSize: 14,
						bodyFontSize: 14,
						callbacks: {
							title: (items, data) => {
								let i = items[0].index;
								let day = this.requestTime.day(i);
								return `${day.format("ddd l")}`;
							},
							label: (tooltipItem, data) => {
								let label = data.datasets[tooltipItem.datasetIndex].label || "";

								return `${this.$i18n.t("tooltip.time")}: ${this.getTime(tooltipItem.yLabel)}`;
							},
						},
					},
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								display: true,
							},
						],
						yAxes: [
							{
								display: false,
								ticks: {
									suggestedMin: 0,
								},
							},
						],
					},
					legend: {
						display: false,
					},
					layout: {
						padding: {
							top: 10,
						},
					},
				},
			},
		};
	},
	created() {
		this.requestTime = moment();
		this.loadData();
	},
	methods: {
		loadData() {
			this.busy = true;
			ReportingService.getPersonalStats(this.requestTime, this.includeQC)
				.then((resp) => {
					this.loadChartData(resp.data.daily_stats, resp.data.start_time, resp.data.end_time);
					this.totalTime = resp.data.total_time;
					this.totalTimeWeek = resp.data.total_time_week;
					this.totalScores = resp.data.total_scores;
					this.totalScoresWeek = resp.data.total_scores_week;
					if (this.includeQC) {
						this.qcScoresWeek = resp.data.qc_scores_week;
						this.qcScores = resp.data.qc_scores;
					}
					this.busy = false;
				})
				.catch((err) => {
					console.error("Failed to load dashboard data for personal stats");
					console.error(err);
					this.busy = false;
				});
		},

		loadChartData(stats, start, end) {
			for (let d = 0; d < 7; d++) {
				let day = moment(start).day(d);
				let isFuture = day.isAfter(moment(), "day");
				let time = (stats[d] && stats[d].time) || 0;
				let score = (stats[d] && stats[d].count) || 0;
				if (isFuture) {
					score = NaN;
					time = NaN;
				}

				this.timeChart.data.datasets[0].data[d] = time;
				this.timeChart.data.datasets[0].pointRadius[d] = 3;
				this.scoreChart.data.datasets[0].data[d] = score;
				this.scoreChart.data.datasets[0].pointRadius[d] = 3;
			}
		},

		getTime(time) {
			if (time == 0) {
				return "——";
			} else if (!time) {
				return "——";
			} else if (time < 600) {
				let minutes = time / 60;
				let seconds = time % 60;
				minutes = parseInt(minutes);
				seconds = parseInt(seconds);
				return `${minutes}m${seconds}s`;
			} else {
				let hours = time / 3600;
				let minutes = (time % 3600) / 60;
				hours = parseInt(hours);
				minutes = parseInt(minutes);
				minutes = minutes < 10 ? "0" + minutes : minutes;
				return `${hours}h${minutes}m`;
			}
		},

		getTimeHour(time) {
			if (time == 0) {
				return "—";
			} else if (!time) {
				return "—";
			} else {
				return parseInt(time / 3600);
			}
		},

		getTimeMinute(time) {
			if (time == 0) {
				return "—";
			} else if (!time) {
				return "—";
			} else {
				let minutes = (time % 3600) / 60;
				minutes = parseInt(minutes);
				minutes = minutes < 10 ? "0" + minutes : minutes;
				return minutes;
			}
		},

		newWeekSelected(timeData) {
			this.requestTime = timeData.selectedTime;
			this.loadData();
		},
	},
};
</script>
