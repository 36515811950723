<template>
	<div class="tab-pane h-100" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="flex-v h-100">
			<div class="p-4 _600 nav-title flex-fixed">{{ $t("SiteConfig.users_title") }}</div>
			<div class="flex-grow flex-v">
				<dual-table
					:leftLabel="$t('SiteConfig.assign_users')"
					:leftSearch="$t('SiteConfig.search_assigned_placeholder')"
					:leftItems="hasProvider"
					:leftFields="hasFields"
					:leftControl="hasControl"
					:rightLabel="$t('SiteConfig.add')"
					:rightSearch="$t('SiteConfig.search_available_placeholder')"
					:rightItems="canProvider"
					:rightFields="canFields"
					:rightControl="canControl"
					:enableSorting="false"
				>
					<!-- Left table slots -->
					<!-- Name Virtual Column -->
					<template #cell(left:name)="data">{{ data.item.first_name }} {{ data.item.last_name }}</template>

					<!-- Actions -->
					<template #cell(left:actions)="data">
						<nobr>
							<div class="mx-auto w30">
								<button
									v-if="!data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									@click="attemptRemoveUser(data.item)"
									v-tippy
									:title="$t('tooltip.remove_from_site')"
								>
									<i class="far fa-minus"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Removing..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>

					<!-- Right table slots -->
					<!-- Name Virtual Column -->
					<template #cell(right:name)="data">{{ data.item.first_name }} {{ data.item.last_name }}</template>

					<!-- Actions -->
					<template #cell(right:actions)="data">
						<nobr>
							<div class="mx-auto w30">
								<button
									v-if="!data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded success text-white m-0"
									@click="attemptAddUser(data.item)"
									v-tippy
									title="Assign to Site"
								>
									<i class="far fa-plus"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Adding..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>
				</dual-table>
			</div>
		</div>

		<save-required-modal
			:dirty="dirty"
			:valid="valid"
			:save="save"
			:next="saveReqNext"
			objectText="Site"
			:objectName="site.name"
			:actionText="saveReqAction"
			v-model="saveReqModal"
		/>
	</div>
</template>

<style scoped>
.w90 {
	width: 90px;
}
.w30 {
	width: 30px;
}
.loader {
	font-size: 128px;
	line-height: 128px;
	color: gray;
	background-color: lightgray;
	text-align: center;
}
.overlay {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}
</style>

<script>
import ObservationService from "@/services/ObservationService";
import notie from "@/services/NotieService";

import DualTable from "@/components/DualTable";
import SaveRequiredModal from "@/components/SaveRequiredModal";

export default {
	name: "SiteEditUsers",

	props: ["id", "site", "dirty", "valid", "save", "defaultTab"],

	components: {
		"dual-table": DualTable,
		"save-required-modal": SaveRequiredModal,
	},

	data() {
		return {
			hasFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{
					key: "scorer_id",
					label: this.$i18n.t("fields.scorer_id"),
					sortable: true,
				},
				{
					key: "email",
					label: this.$i18n.t("fields.email"),
					sortable: true,
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			],
			hasControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},
			canFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{
					key: "scorer_id",
					label: this.$i18n.t("fields.scorer_id"),
					sortable: true,
				},
				{
					key: "email",
					label: this.$i18n.t("fields.email"),
					sortable: true,
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			],
			canControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},

			saveReqModal: false,
			saveReqAction: "continue",
			saveReqNext: () => {},
		};
	},

	created() {},

	methods: {
		hasProvider(ctx) {
			if (this.site.id) {
				this.hasControl.emptyText = this.$i18n.t("data_description.no_records");
				return ObservationService.getSiteUsers(this.site.id, ctx)
					.then((resp) => {
						this.hasControl.totalRows = resp.data.totalRows;
						return resp.data.rows;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.load_assignable_user_fail"), err);
						return [];
					});
			} else {
				this.hasControl.emptyText = this.$i18n.t("SiteConfig.cannot_assign");
				return [];
			}
		},

		canProvider(ctx) {
			ctx.inverse = "t";
			if (this.site.id) {
				this.canControl.emptyText = this.$i18n.t("data_description.no_records");
				return ObservationService.getSiteUsers(this.site.id, ctx)
					.then((resp) => {
						this.canControl.totalRows = resp.data.totalRows;
						return resp.data.rows;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.get_site_users_fail"), err);
						return [];
					});
			} else {
				this.canControl.emptyText = this.$i18n.t("SiteConfig.cannot_assign");
				return [];
			}
		},

		refreshTables() {
			if (this.hasControl && this.hasControl.refresh) {
				this.hasControl.refresh();
			} else {
				console.error("Left (has assigned) table had no refresh defined");
			}
			if (this.canControl && this.canControl.refresh) {
				this.canControl.refresh();
			} else {
				console.error("Right (can assign) table had no refresh defined");
			}
		},

		attemptAddUser(user) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.addUser(user);
				};
				this.saveReqAction = "add an user to the site";
				this.saveReqModal = true;
			} else {
				this.addUser(user);
			}
		},

		addUser(user) {
			user.inProgress = true;
			ObservationService.addSiteUser(this.site.id, user.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.user_added"));
					this.refreshTables();
					user.inProgress = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.add_user_fail"), err);
					user.inProgress = false;
				});
		},

		attemptRemoveUser(user) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.removeUser(user);
				};
				this.saveReqAction = "remove an user from the site";
				this.saveReqModal = true;
			} else {
				this.removeUser(user);
			}
		},

		removeUser(user) {
			user.inProgress = true;
			ObservationService.removeSiteUser(this.site.id, user.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.user_removed"));
					this.refreshTables();
					user.inProgress = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.remove_user_fail"), err);
					user.inProgress = false;
				});
		},

		sortTables() {
			this.availUsers = _.orderBy(this.availUsers, ["first_name"], ["asc"]);
			this.site.users = _.orderBy(this.site.users, ["first_name"], ["asc"]);
		},

		saveSite() {
			this.oldLoaded = this.site.loaded;
			this.save()
				.then((resp) => {
					console.log(resp);
				})
				.catch((resp) => {
					console.log(resp);
				});
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},
	},
};
</script>
