<template>
	<div class="d-flex flex-row justify-content-center mb-2">
		<div class="d-flex flex-row">
			<template v-if="!(disabledControls && disabledControls.audio)">
				<button
					v-if="uiState.permissions.microphone.allowed && canAudio"
					class="btn text-white btn-large control-button d-flex flex-column align-items-center"
					:class="{ [localParticipant.muted ? 'btn-secondary' : 'theme']: true }"
					@click="toggleMic"
				>
					<div class="control-button-icon-container">
						<Transition name="fade">
							<i key="1" v-if="localParticipant.muted" class="far fa-microphone-slash" />
							<i key="2" v-else class="far fa-microphone" />
						</Transition>
					</div>
					<div class="control-bar-text">{{ $t("VideoMeeting.mic") }}</div>
				</button>
				<button
					v-else
					class="btn btn-secondary position-relative text-white btn-large control-button d-flex flex-column align-items-center"
					disabled
				>
					<div class="control-button-icon-container">
						<i key="1" v-if="localParticipant.muted" class="far fa-microphone-slash" />
					</div>
					<div class="control-bar-text">{{ $t("VideoMeeting.mic") }}</div>
					<div
						v-if="uiState.permissions.microphone.blocked"
						v-tippy
						:title="$t('VideoMeeting.this_device_is_blocked')"
						class="disabled-mouseover"
					></div>
					<div
						v-else-if="!canAudio"
						v-tippy
						:title="$t('VideoMeeting.microphone_not_allowed')"
						class="disabled-mouseover"
					></div>
				</button>
			</template>
			<template v-if="!(disabledControls && disabledControls.camera)">
				<button
					v-if="uiState.permissions.camera.allowed && canVideo"
					class="btn text-white btn-large control-button d-flex flex-column align-items-center"
					:class="{ [localParticipant.video ? 'theme' : 'btn-secondary']: true }"
					@click="toggleCamera"
				>
					<div class="control-button-icon-container">
						<Transition name="fade">
							<loading
								key="1"
								v-if="localParticipant.videoLoading"
								type="icon"
								style="width: 24px; height: 24px; font-size: 24px; margin: 0"
							/>
							<i key="2" v-else-if="localParticipant.video" class="far fa-video" />
							<i key="3" v-else class="far fa-video-slash" />
						</Transition>
					</div>
					<div class="control-bar-text">{{ $t("VideoMeeting.camera") }}</div>
				</button>
				<button
					v-else
					class="btn btn-secondary position-relative disabled text-white btn-large control-button d-flex flex-column align-items-center"
					disabled
				>
					<div class="control-button-icon-container">
						<i key="3" class="far fa-video-slash" />
					</div>
					<div class="control-bar-text">{{ $t("VideoMeeting.camera") }}</div>
					<div
						v-if="uiState.permissions.camera.blocked"
						v-tippy
						:title="$t('VideoMeeting.this_device_is_blocked')"
						class="disabled-mouseover"
					></div>
					<div
						v-else-if="!canVideo"
						v-tippy
						:title="$t('VideoMeeting.camera_not_allowed')"
						class="disabled-mouseover"
					></div>
				</button>
			</template>
			<template v-if="!(disabledControls && disabledControls.share)">
				<button
					v-if="canScreenshare"
					class="btn text-white btn-large control-button d-flex flex-column align-items-center"
					:class="{ [localParticipant.screensharing ? 'btn-primary' : 'theme']: true }"
					@click="toggleShare"
				>
					<i v-if="localParticipant.screensharing" class="far fa-times-square" />
					<i v-else class="far fa-presentation" />
					<div
						v-if="localParticipant.screensharing"
						class="control-bar-text"
						style="white-space: normal; line-height: 1; margin-bottom: -4px"
					>
						{{ $t("VideoMeeting.stop_sharing") }}
					</div>
					<div v-else class="control-bar-text">{{ $t("VideoMeeting.share") }}</div>
				</button>
				<button
					v-else
					class="btn btn-secondary position-relative disabled text-white btn-large control-button d-flex flex-column align-items-center"
					disabled
				>
					<div class="control-button-icon-container">
						<i class="far fa-presentation" />
					</div>
					<div class="control-bar-text">{{ $t("VideoMeeting.share") }}</div>
					<div v-tippy :title="$t('VideoMeeting.screenshare_not_allowed')" class="disabled-mouseover"></div>
				</button>
			</template>
			<template v-if="!(disabledControls && disabledControls.raise)">
				<button
					v-if="canChat"
					class="btn text-white btn-large control-button d-flex flex-column align-items-center"
					:class="{ [localParticipant.handUp ? 'btn-primary' : 'theme']: true }"
					@click="toggleRaiseHand"
				>
					<i v-if="localParticipant.handUp" class="fas fa-hand-paper" />
					<i v-else class="far fa-hand-paper" />
					<div
						v-if="localParticipant.handUp"
						class="control-bar-text"
						style="white-space: normal; line-height: 1; margin-bottom: -4px"
					>
						{{ $t("VideoMeeting.lower_hand") }}
					</div>
					<div v-else class="control-bar-text">{{ $t("VideoMeeting.raise_hand") }}</div>
				</button>
				<button
					v-else
					class="btn btn-secondary position-relative disabled text-white btn-large control-button d-flex flex-column align-items-center"
					disabled
				>
					<div class="control-button-icon-container">
						<i class="far fa-hand-paper" />
					</div>
					<div class="control-bar-text">{{ $t("VideoMeeting.raise_hand") }}</div>
					<div v-tippy :title="$t('VideoMeeting.raise_hand_not_allowed')" class="disabled-mouseover"></div>
				</button>
			</template>
		</div>
		<div class="ml-3 d-flex flex-row">
			<button
				v-if="!(disabledControls && disabledControls.hangUp)"
				class="btn btn-danger btn-large control-button d-flex flex-column align-items-center"
				@click="hangUp"
			>
				<i class="far fa-phone" />
				<div class="control-bar-text">{{ $t("VideoMeeting.hang_up") }}</div>
			</button>
		</div>
	</div>
</template>

<style scoped>
.control-button {
	width: 80px;
	height: 80px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 0;
}
.control-button i {
	font-size: 24px;
}
.control-button-icon-container {
	width: 30px;
	height: 24px;
	position: relative;
}
.control-button-icon-container > i {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	font-size: 24px;
}

.control-button:first-of-type {
	border-radius: 8px 0 0 8px;
}
.control-button:last-of-type {
	border-radius: 0 8px 8px 0;
}
.control-button:only-of-type {
	border-radius: 8px 8px 8px 8px;
	flex: 0 1 25%;
	flex-direction: center;
}

.control-bar-text {
	padding-top: 6px;
	font-weight: 300;
	font-size: 0.8rem;
	margin-bottom: -10px;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import Chime from "@/vues/VideoMeeting/services/ChimeEngine";
import Signal from "@/vues/VideoMeeting/services/SignalEngine";
import Notie from "@/services/NotieService";
import Store from "@/services/Store";

export default {
	name: "ControlBar",
	props: ["uiState", "localParticipant", "disabledControls"],
	components: {},
	data() {
		return {};
	},
	created() {},
	watch: {},

	computed: {
		canAudio() {
			return (
				this.localParticipant &&
				(this.localParticipant.capabilities.audio == "SendReceive" ||
					this.localParticipant.capabilities.audio == "Send")
			);
		},
		canVideo() {
			return (
				this.localParticipant &&
				(this.localParticipant.capabilities.video == "SendReceive" ||
					this.localParticipant.capabilities.video == "Send")
			);
		},
		canScreenshare() {
			return (
				this.localParticipant &&
				(this.localParticipant.capabilities.content == "SendReceive" ||
					this.localParticipant.capabilities.content == "Send")
			);
		},
		canChat() {
			return (
				this.localParticipant &&
				(this.localParticipant.capabilities.chat == "SendReceive" || this.localParticipant.capabilities.chat == "Send")
			);
		},
	},

	methods: {
		async toggleMic() {
			await Chime.toggleLocalAudio();

			let shouldEnableAudio = !this.localParticipant.muted;
			Store.set(this, "VideoMeeting.shouldEnableAudio", shouldEnableAudio);
			this.log("Set LocalStorage: shouldEnableAudio", shouldEnableAudio);
		},

		async toggleCamera() {
			await Chime.toggleLocalVideo();

			let shouldEnableVideo = this.localParticipant.video ? true : false;
			Store.set(this, "VideoMeeting.shouldEnableVideo", shouldEnableVideo);
			this.log("Set LocalStorage: shouldEnableVideo", shouldEnableVideo);
		},

		async toggleShare() {
			if (this.localParticipant.screensharing) {
				Chime.stopScreenshare();
			} else {
				Chime.startScreenshare();
			}
		},

		async toggleRaiseHand() {
			if (this.localParticipant.handUp) {
				Signal.sendHandUp(false);
			} else {
				Signal.sendHandUp(true);
			}
		},

		hangUp() {
			Chime.hangUp();
			Signal.disconnect();
		},
	},
};
</script>
