<template>
	<!-- Navigation footer -->
	<div class="b-t set-nav-footer p-2 w-100 d-flex flex-row justify-content-center align-items-center">
		<div class="d-flex flex-row align-items-center limit-width-more" v-if="set">
			<div class="_600 mr-4 flex">{{ set.name }}</div>
			<div class="d-flex flex-row align-items-center">
				<button
					@click="pagePrev()"
					class="btn btn-sm"
					:class="{ theme: currentPage != 1 }"
					:disabled="currentPage == 1"
				>
					<i class="fas fa-arrow-left"></i>
				</button>
				<div class="flex text-center mx-2">
					{{ currentPage }} {{ $t("data_description.out_of") }} {{ numPages }}
				</div>
				<button
					@click="pageNext()"
					class="btn btn-sm"
					:class="{ theme: currentPage != numPages }"
					:disabled="currentPage == numPages"
				>
					<i class="fas fa-arrow-right"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.box-header {
	padding: 0.6rem 0.75rem !important;
}

.set-nav-footer {
	margin-left: -10px;
	/* margin-right: -10px; */
	background-color: #eeeeee;
}
.limit-width {
	max-width: 800px;
}
.limit-width-more {
	width: 100%;
	max-width: 800px;
	padding-left: 16px;
	padding-right: 16px;
}
.center-content {
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
}
.essay {
	margin-bottom: 20px !important;
}
</style>

<script>
import QCService from "@/services/QCService";

import Media from "@/components/viewer/Media";
import Score from "@/components/Score";

export default {
	name: "ExemplarNav",
	props: ["value", "set", "numPages"],

	data() {
		return {
			currentPage: this.value,
		};
	},
	components: { Media, Score },
	created() {},
	watch: {
		set() {
			this.currentPage = 1;
			this.emmitPage();
		},
	},
	computed: {},
	methods: {
		pagePrev() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.emmitPage();
			}
		},

		pageNext() {
			if (this.currentPage < this.numPages) {
				this.currentPage++;
				this.emmitPage();
			}
		},

		emmitPage() {
			this.$emit("input", this.currentPage);
		},
	},
};
</script>
