import axios from "axios";

export default {
	updateCompensationQueueTime: function (userId, itemId, taskId, getNextTimeUnix, responseId, qcType) {
		return axios.post(`/v1/compensation/update_queue_time`, {
			user_id: userId,
			item_id: itemId,
			task_id: taskId,
			get_next_time_unix: getNextTimeUnix,
			response_id: responseId,
			qc_type: qcType
		});
	},

	updateCompensationQueueTimeForTrainingResource: function (userId, courseId, compensationStartTime) {
		return axios.post(`/v1/compensation/update_queue_time_for_training_resource`, {
			user_id: userId,
			course_id: courseId,
			compensation_start_time: compensationStartTime
		});
	},

	getCompensationStartTime: function () {
		return axios.get(`/v1/compensation/getCompensationStartTime`, {});
	}
}
