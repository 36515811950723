<template>
	<b-modal
		:title="$t('RFReport.new_rf_tag')"
		@cancel="close"
		@ok="addNewTag"
		visible
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
	>
		<b-row align-content="center" align-v="center">
			<b-col cols="3" class="pl-3">
				<label>{{ $t("RFReport.tag_name") }}</label>
			</b-col>
			<b-col cols="9">
				<b-input v-model="tagName"></b-input>
			</b-col>
		</b-row>
	</b-modal>
</template> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import Notie from "@/services/NotieService";
import TenantService from "@/services/TenantService";

export default {
	name: "NewRfTagModal",

	props: {
		client: Object,
	},

	data() {
		return {
			tagName: "",
		};
	},

	methods: {
		async addNewTag() {
			const { client, tagName } = this;
			try {
				await TenantService.addCustomRFTag(tagName);
				client.custom_rf_tags = (client.custom_rf_tags || []).concat(tagName);
				this.close();
			} catch (err) {
				Notie.error("Failed to add Tag", err);
			}
		},

		close() {
			this.$emit("close");
		},
	},
};
</script>


