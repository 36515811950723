<template>
	<div class="footer p-3 b-t mt-auto theme">
		{{ $t("Footer.copyright", { year: new Date().getFullYear(), version: version }) }}
	</div>
</template>

<script>
import pack from "../../../package.json";

export default {
	name: "Footer",
	data() {
		return {
			version: pack.version,
		};
	},
	created() {
		//Maybe put the zendesk init here?
	},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
	/* The sidenav has z-index of 1030 */
	z-index: 1031;
}
</style>
