import Vue from "vue";
import chartjs from "chart.js";

function createChart(el, data) {}

Vue.directive("chartjs", {
	inserted(el, binding) {
		var ctx = el.getContext("2d");
		el.$chart = new chartjs.Chart(ctx, Object.assign({}, binding.value));
	},
	update(el, binding) {
		el.$chart.update();
	}
});
