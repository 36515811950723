<template>
	<div class="d-flex flex-row align-items-center top-bar theme px-2">
		<a class="top-bar-button" v-tippy :title="returnTooltip()" @click="goToLanding">
			<i class="fa-reply" :class="{ [uiState.sidebarTab == 'settings' ? 'fas' : 'far']: true }" />
		</a>
		<div class="meeting-title flex mx-auto">
			{{ uiState.meeting ? uiState.meeting.name : "Loading..." }}
		</div>
		<a class="top-bar-button" v-tippy="{ hideOnClick: false }" :title="layoutTooltip()" @click="toggleLayout">
			<i v-if="uiState.layout == 'focus'" class="fas fa-window-restore" />
			<i v-if="uiState.layout == 'gallery'" class="fas fa-th" />
		</a>
		<div class="vertical-spacer"></div>
		<a
			class="top-bar-button"
			v-tippy
			title="User List"
			@click="chooseTab('participant-list')"
			:class="{ active: uiState.sidebarTab == 'participant-list' }"
		>
			<div class="badge">{{ participants.length }}</div>
			<i class="fa-users" :class="{ [uiState.sidebarTab == 'participant-list' ? 'fas' : 'far']: true }" />
		</a>
		<a
			class="top-bar-button"
			v-tippy
			title="Chat"
			@click="chooseTab('chat')"
			:class="{ active: uiState.sidebarTab == 'chat' }"
		>
			<div v-if="uiState.unreadMessages > 0" class="badge badge-primary">{{ uiState.unreadMessages }}</div>
			<i class="fa-comment" :class="{ [uiState.sidebarTab == 'chat' ? 'fas' : 'far']: true }" />
		</a>
		<a
			class="top-bar-button"
			v-tippy
			title="Settings"
			@click="chooseTab('settings')"
			:class="{ active: uiState.sidebarTab == 'settings' }"
		>
			<i class="fa-cog" :class="{ [uiState.sidebarTab == 'settings' ? 'fas' : 'far']: true }" />
		</a>
	</div>
</template>

<style scoped>
.top-bar {
	min-height: 3.5rem;
	width: 100%;
}
.meeting-title {
	font-size: 20px;
	text-align: center;
}
.top-bar-button {
	margin-top: -6px;
	margin-bottom: -6px;
	opacity: 1;
	transition: background-color 0.25s;
	min-width: 40px;
	height: 40px;
	padding: 0 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
.top-bar-button > i {
	font-size: 24px;
}
.top-bar-button:hover,
.top-bar-button.active {
	background-color: rgba(0, 0, 0, 0.1);
}
.vertical-spacer {
	margin: 0.5rem;
	border: 1px solid rgba(255 255 255 / 25%);
	height: 60%;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import AgoraTypes from "@/vues/VideoMeeting/services/AgoraTypes";
import Notie from "@/services/NotieService";

export default {
	name: "TopBar",
	props: ["uiState", "participants"],
	components: {},
	data() {
		return {};
	},
	created() {},
	methods: {
		toggleLayout() {
			console.log("TOGGLE LAYOUT");
			if (this.uiState.layout == "gallery") {
				console.log("switch to focus");
				this.uiState.layout = "focus";
			} else if (this.uiState.layout == "focus") {
				console.log("switch to gallery");
				this.uiState.layout = "gallery";
			}
		},

		chooseTab(tab) {
			if (this.uiState.sidebarTab == tab) {
				this.uiState.sidebarTab = "";
			} else {
				this.uiState.sidebarTab = tab;
			}
		},

		goToLanding() {
			this.$router.push("/landing");
		},

		returnTooltip() {
			return `<div>Return to OSCAR<div><div class="text-muted">The meeting will continue running in the background</div>`;
		},

		layoutTooltip() {
			let res = `<div>Layout Style</div><div>`;
			if (this.uiState.layout == "gallery") {
				res += `<span class="text-success _600">Gallery</span>`;
				res += ` <span class="text-muted">|</span> `;
				res += `<span class="text-very-muted _600">Focus</span>`;
			} else {
				res += `<span class="text-very-muted _600">Gallery</span>`;
				res += ` <span class="text-muted">|</span> `;
				res += `<span class="text-success _600">Focus</span>`;
			}
			res += "</div>";
			return res;
		},
	},
};
</script>
