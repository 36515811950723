<template>
	<div class="d-flex flex-column flex h100">
		<div class="row px-4 pb-4">
			<div class="col-12">
				<div class="theme stat-box stat-count py-2">OSCAR Admin Stats</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-4">
				<div class="stat-box clickable" @click="openUsersModal">
					<div v-if="currentlyScoring" class="stat-count">{{ currentlyScoring.count }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Currently scoring</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-8">
				<div class="instance-container">
					<h5 class="mb-2">Instances</h5>
					<div v-if="instances" class="d-flex flex-row flex-wrap">
						<div v-for="instance in instances" :key="instance.instances_id">
							<div
								class="instance-box clickable nowrap d-flex flex-column"
								@click="openInstanceModal(instance.instance_id)"
							>
								<div><i class="fa fa-desktop mr-3" />{{ instance.disk_percent.toFixed(0) }}%</div>
								<div class="instance-label">
									{{ instance.instance_id }}
								</div>
							</div>
						</div>
					</div>
					<div v-else class="text-center" style="font-size: 52px">
						<loading type="icon" />
					</div>
				</div>
			</div>
			<div v-if="runningJobs && runningJobs.length > 0" class="col-12">
				<div v-for="job in runningJobs" :key="job.id" class="col-12 col-sm-6 stat-box">
					<div class="stat-count">{{ job.processed }} / {{ job.total }}</div>
					<div class="stat-label">
						<div>Running Background Process: {{ job.type }}</div>
						<h6 class="mb-0 mr-2">
							{{ job.item_name }} | <small class="text-muted">{{ job.client_id }}</small> |
							<small class="text-muted">{{ job.item_id }}</small>
						</h6>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div style="padding-top: 1.5rem">
					<hr />
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('incoming', 'month')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'incoming' && countBreakdown.period == 'month',
					}"
				>
					<div v-if="incoming" class="stat-count">{{ incoming.month.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Incoming (past 30 days)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('incoming', 'week')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'incoming' && countBreakdown.period == 'week',
					}"
				>
					<div v-if="incoming" class="stat-count">{{ incoming.week.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Incoming (past week)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('incoming', 'day')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'incoming' && countBreakdown.period == 'day',
					}"
				>
					<div v-if="incoming" class="stat-count">{{ incoming.day.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Incoming (past day)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('incoming', 'hour')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'incoming' && countBreakdown.period == 'hour',
					}"
				>
					<div v-if="incoming" class="stat-count">{{ incoming.hour.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Incoming (past hour)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('scored', 'month')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'scored' && countBreakdown.period == 'month',
					}"
				>
					<div v-if="scored" class="stat-count">{{ scored.month.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Scored (past 30 days)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('scored', 'week')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'scored' && countBreakdown.period == 'week',
					}"
				>
					<div v-if="scored" class="stat-count">{{ scored.week.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Scored (past week)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('scored', 'day')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'scored' && countBreakdown.period == 'day',
					}"
				>
					<div v-if="scored" class="stat-count">{{ scored.day.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Scored (past day)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('scored', 'hour')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'scored' && countBreakdown.period == 'hour',
					}"
				>
					<div v-if="scored" class="stat-count">{{ scored.hour.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Scored (past hour)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('exported', 'month')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'exported' && countBreakdown.period == 'month',
					}"
				>
					<div v-if="exported" class="stat-count">{{ exported.month.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Exported (past 30 days)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('exported', 'week')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'exported' && countBreakdown.period == 'week',
					}"
				>
					<div v-if="exported" class="stat-count">{{ exported.week.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Exported (past week)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('exported', 'day')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'exported' && countBreakdown.period == 'day',
					}"
				>
					<div v-if="exported" class="stat-count">{{ exported.day.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Exported (past day)</div>
				</div>
			</div>
			<div class="col-12 col-sm-6 col-lg-3">
				<div
					class="stat-box clickable"
					@click="openBreakdownModal('exported', 'hour')"
					:class="{
						active: countBreakdown && countBreakdown.type == 'exported' && countBreakdown.period == 'hour',
					}"
				>
					<div v-if="exported" class="stat-count">{{ exported.hour.toLocaleString() }}</div>
					<loading class="stat-loading" v-else type="icon" />
					<div class="stat-label">Exported (past hour)</div>
				</div>
			</div>
		</div>

		<b-modal id="breakdownModal" size="lg" :visible="countBreakdown != null" @hide="countBreakdown = null" ok-only>
			<template v-if="countBreakdown" slot="modal-header">
				<h5 class="modal-title pl-3">
					{{ capitalize(countBreakdown.type) }} Responses Breakdown (past {{ countBreakdown.period }})
				</h5>
			</template>

			<div class="modal-scroll">
				<div v-if="countBreakdown && countBreakdown.data">
					<table v-if="countBreakdown.data.length > 0" class="table table-striped table-bordered mb-0">
						<thead>
							<tr>
								<th colspan="3">Name</th>
								<th class="text-center">
									<i v-tippy title="Client" class="fa fa-building"></i>
								</th>
								<th class="text-center">
									<i v-tippy title="Section" class="fa fa-briefcase"></i>
								</th>
								<th class="text-center">
									<i v-tippy title="Item" class="fa fa-book"></i>
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="client in countBreakdown.data">
								<tr :key="client.id" class="clickable" @click="breakdownToggleDetails(client)">
									<td colspan="3">
										<i
											class="fas fa-lg fa-angle-right anim-rotate mr-2"
											:class="{ 'rotate-90p': client.showDetails }"
										></i>
										<i v-tippy title="Client" class="fa fa-building mr-2"></i>{{ client.name }}
									</td>
									<td class="p-custom px-0 text-center b-l">{{ client.count.toLocaleString() }}</td>
									<td class="p-custom px-0 b-l"></td>
									<td class="p-custom px-0 b-l"></td>
								</tr>
								<template v-if="client.showDetails">
									<tr
										v-if="!(client.section_counts && client.section_counts.length > 0)"
										:key="`loading-${client.id}`"
									>
										<td
											colspan="6"
											class="text-center p-custom pt-1 pb-0"
											style="height: 48.53px; font-size: 27px"
										>
											<loading type="icon" />
										</td>
									</tr>
									<template v-else>
										<template v-for="section in client.section_counts">
											<tr :key="section.id">
												<td style="width: 40px"></td>
												<td colspan="2">
													<i v-tippy title="Section" class="fa fa-briefcase mr-2"></i
													>{{ section.name }}
												</td>
												<td class="p-custom px-0 b-l"></td>
												<td class="p-custom px-0 text-center b-l">
													{{ section.count.toLocaleString() }}
												</td>
												<td class="p-custom px-0 b-l"></td>
											</tr>
											<template v-for="item in section.item_counts">
												<tr :key="item.id">
													<td style="width: 40px"></td>
													<td style="width: 40px"></td>
													<td>
														<i v-tippy title="Item" class="fa fa-book mr-2"></i
														>{{ item.name }}
													</td>
													<td class="p-custom px-0 b-l"></td>
													<td class="p-custom px-0 b-l"></td>
													<td class="p-custom px-0 text-center b-l">
														{{ item.count.toLocaleString() }}
													</td>
												</tr>
											</template>
										</template>
									</template>
								</template>
							</template>
						</tbody>
					</table>
					<div v-else class="text-center">
						<h3 class="text-muted py-4">No Results</h3>
					</div>
				</div>
				<div v-else class="text-center">
					<loading type="large" />
				</div>
			</div>
		</b-modal>

		<b-modal id="userModal" size="lg" :visible="userBreakdown != null" @hide="userBreakdown = null" ok-only>
			<template slot="modal-header">
				<h5 class="modal-title pl-3">Currently Scoring In Users</h5>
			</template>

			<div class="modal-scroll">
				<div v-if="userBreakdown && userBreakdown.data">
					<table v-if="userBreakdown.data.length > 0" class="table table-striped table-bordered mb-0">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Activity</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, i) in userBreakdown.data" :key="i">
								<td>{{ row.full_name }}</td>
								<td>{{ row.email }}</td>
								<td>{{ row.page }}</td>
							</tr>
						</tbody>
					</table>
					<div v-else class="text-center">
						<h3 class="text-muted py-4">No Results</h3>
					</div>
				</div>
				<div v-else class="text-center">
					<loading type="large" />
				</div>
			</div>
		</b-modal>

		<b-modal id="instanceModal" size="lg" :visible="instanceStats != null" @hide="instanceStats = null" ok-only>
			<template v-if="instanceStats" slot="modal-header">
				<h5 class="modal-title pl-3">
					Instance Details <small class="text-muted">({{ instanceStats.instance_id }})</small>
				</h5>
			</template>

			<div class="modal-scroll">
				<div v-if="instanceStats && instanceStats.data && instanceCharts">
					<div v-for="chart in instanceCharts" :key="chart.field">
						<label class="_600">{{ chart.name }}</label>
						<canvas id="chart-line-2" v-chartjs="chart.chart" style="height: 100px" class="mb-4"></canvas>
					</div>
					<div v-if="instanceCharts && instanceCharts.length == 0" class="text-center">
						<h3 class="text-muted py-4">No Results</h3>
					</div>
				</div>
				<div v-else class="text-center">
					<loading type="large" />
				</div>
			</div>
		</b-modal>
	</div>
</template>

<style scoped>
.stat-box {
	margin-top: 1.5rem;
	text-align: center;
	padding: 1rem 1rem 2rem 1rem;
	border-radius: 1rem;
	background-color: #e7e7e7;
	-webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
.clickable {
	cursor: pointer;
}
.stat-box.clickable:hover {
	background-color: #d0d0d0;
}
.stat-count {
	font-size: 40px;
}
.stat-loading {
	font-size: 40px;
	width: 40px;
	height: 40px;
	margin-top: 12px;
	margin-bottom: 6px;
}
.stat-label {
	font-size: 20px;
}

.instance-container {
	margin-top: 1.5rem;
	padding: 1rem 0.5rem 0.5rem 1rem;
	border-radius: 1rem;
	border: 2px solid #e7e7e7;
	-webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
.instance-box {
	background-color: #e7e7e7;
	padding: 0.5rem 0.75rem 1rem 1rem;
	border-radius: 1rem;
	font-size: 24px;
	height: 76px;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
.instance-box.clickable:hover {
	background-color: #d0d0d0;
}

.instance-label {
	opacity: 0.5;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 92px;
	min-height: 18px;
	font-size: 12px;
}

tr.clickable:hover {
	background-color: rgba(0, 0, 0, 0.075);
	cursor: pointer;
}
</style>

<script>
import AdminStatsService from "@/services/AdminStatsService";
import Notie from "@/services/NotieService";
import moment from "moment";
import _ from "lodash";

export default {
	name: "AdminStats",
	data() {
		return {
			incoming: null,
			scored: null,
			exported: null,

			currentlyScoring: null,
			instances: null,

			countBreakdown: null,
			userBreakdown: null,
			instanceStats: null,

			instanceCharts: null,

			runningJobs: [1],
		};
	},
	components: {},
	created() {
		this.loadCounts();
		this.loadScoring();
		this.loadInstances();
		this.loadRunningJobs();
	},
	methods: {
		loadCounts() {
			AdminStatsService.getIncomingCounts()
				.then((r) => {
					this.incoming = r.data;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get incoming counts", e);
				});

			AdminStatsService.getScoredCounts()
				.then((r) => {
					this.scored = r.data;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get scored counts", e);
				});

			AdminStatsService.getExportedCounts()
				.then((r) => {
					this.exported = r.data;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get exported counts", e);
				});
		},

		loadScoring() {
			AdminStatsService.getCurrentlyScoring()
				.then((r) => {
					this.currentlyScoring = r.data;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get currently scoring", e);
				});
		},

		loadInstances() {
			AdminStatsService.getInstances()
				.then((r) => {
					this.instances = r.data;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get instances", e);
				});
		},

		loadRunningJobs() {
			AdminStatsService.getRunningJobs()
				.then((r) => {
					this.runningJobs = r.data;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get running jobs", e);
				});
		},

		openBreakdownModal(rType, period) {
			this.countBreakdown = {
				type: rType,
				period: period,
				data: null,
			};

			let call = this.getBreakdownCall(rType, period);
			call.then((r) => {
				_.forEach(r.data, (c) => {
					this.$set(c, "showDetails", false);
					this.$set(c, "section_counts", []);
				});
				this.countBreakdown.data = _.orderBy(r.data, "count", "desc");
			}).catch((e) => {
				console.error(e);
				Notie.error("Failed to get count breakdown", e);
			});
		},

		getBreakdownCall(rType, period) {
			let startTime = moment();
			switch (period) {
				case "month":
					startTime.subtract(30, "days");
					break;
				case "week":
					startTime.subtract(7, "days");
					break;
				case "day":
					startTime.subtract(1, "days");
					break;
				case "hour":
					startTime.subtract(1, "hours");
					break;
			}

			switch (rType) {
				case "incoming":
					return AdminStatsService.getIncomingBreakdown(startTime);
				case "scored":
					return AdminStatsService.getScoredBreakdown(startTime);
				case "exported":
					return AdminStatsService.getExportedBreakdown(startTime);
			}
		},

		breakdownToggleDetails(client) {
			client.showDetails = !client.showDetails;
			if (client.showDetails) {
				client.section_counts = null;

				let call = this.getClientBreakdownCall(client, this.countBreakdown.type, this.countBreakdown.period);
				call.then((r) => {
					_.forEach(r.data, (s) => {
						s.item_counts = _.orderBy(s.item_counts, "count", "desc");
					});
					client.section_counts = _.orderBy(r.data, "count", "desc");
				}).catch((e) => {
					console.error(e);
					Notie.error("Failed to get client count breakdown", e);
				});
			}
		},

		getClientBreakdownCall(client, rType, period) {
			let startTime = moment();
			switch (period) {
				case "month":
					startTime.subtract(30, "days");
					break;
				case "week":
					startTime.subtract(7, "days");
					break;
				case "day":
					startTime.subtract(1, "days");
					break;
				case "hour":
					startTime.subtract(1, "hours");
					break;
			}

			switch (rType) {
				case "incoming":
					return AdminStatsService.getIncomingClientBreakdown(client.id, startTime);
				case "scored":
					return AdminStatsService.getScoredClientBreakdown(client.id, startTime);
				case "exported":
					return AdminStatsService.getExportedClientBreakdown(client.id, startTime);
			}
		},

		openUsersModal() {
			this.userBreakdown = {
				data: null,
			};
			AdminStatsService.getCurrentlyScoringBreakdown()
				.then((r) => {
					this.userBreakdown.data = r.data;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get currently scoring users breakdown", e);
				});
		},

		openInstanceModal(instanceID) {
			this.diskChart = null;
			this.ramChart = null;
			this.instanceStats = {
				instance_id: instanceID,
				data: null,
			};
			this.instanceCharts = [];

			let startTime = moment();
			startTime.subtract(1, "days");

			AdminStatsService.getInstanceStats(instanceID, startTime)
				.then((r) => {
					this.instanceStats.data = r.data;

					let field = "";
					let name = "";
					let chart = {};
					let points = [];

					field = "disk_percent";
					name = "Disk Usage %";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartPercentTooltipLabel;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "disk_used";
					name = "Disk Used";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "disk_free";
					name = "Disk Free";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "disk_total";
					name = "Disk Total";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "ram_used";
					name = "RAM Used";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "ram_free";
					name = "RAM Free";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "ram_cached";
					name = "RAM Cached";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "ram_total";
					name = "RAM Total";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "app_memory";
					name = "App Memory";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "app_sys";
					name = "App System Memory";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartBytesTooltipLabel;
					chart.options.scales.yAxes[0].ticks.callback = this.chartBytesTooltipAxis;
					chart.options.scales.yAxes[0].ticks.stepSize = 1024 * 1024 * 1024 * 10;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});

					field = "app_num_gc";
					name = "Garbage Collection Cycles";
					chart = this.blankDiskChart();
					points = this.getGraphPoints(this.instanceStats.data, field);
					chart.data.datasets[0].data = points;
					chart.options.tooltips.callbacks.title = this.chartTooltipTitle;
					chart.options.tooltips.callbacks.label = this.chartPlainTooltipLabel;
					this.instanceCharts.push({
						field: field,
						name: name,
						chart: chart,
					});
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to get instance stats", e);
				});
		},

		chartTooltipTitle() {
			return "Value(s)";
		},

		chartPercentTooltipLabel(item, data) {
			let i = item.datasetIndex;
			let j = item.index;
			let point = data.datasets[i].data[j];
			if (point) {
				let time = moment(point.x);
				return `${time.format("MM/DD LT")}: ${point.y}%`;
			} else {
				return `no data`;
			}
		},

		chartBytesTooltipLabel(item, data) {
			let i = item.datasetIndex;
			let j = item.index;
			let point = data.datasets[i].data[j];
			if (point) {
				let time = moment(point.x);
				let size = this.bytesToString(point.y);
				return `${time.format("MM/DD LT")}: ${size}`;
			} else {
				return `no data`;
			}
		},

		chartPlainTooltipLabel(item, data) {
			let i = item.datasetIndex;
			let j = item.index;
			let point = data.datasets[i].data[j];
			if (point) {
				let time = moment(point.x);
				return `${time.format("MM/DD LT")}: ${point.y}`;
			} else {
				return `no data`;
			}
		},

		chartBytesTooltipAxis(value) {
			let size = this.bytesToString(value);
			return size;
		},

		getGraphPoints(data, field) {
			let points = [];
			_.forEach(data, (stat) => {
				let time = new Date(stat.time);
				points.push({ x: time, y: stat[field] });
				console.log("ADD STAT", time, stat[field]);
			});
			return points;
		},

		blankDiskChart() {
			return {
				type: "line",
				data: {
					datasets: [
						{
							label: "Disk Usage",
							data: [],
							fill: false,
							backgroundColor: "rgba(212, 148, 0, 0.1)",
							borderColor: "rgba(212, 148, 0, 0.25)",
							pointBorderColor: "rgba(212, 148, 0, 0.4)",
							borderWidth: 4,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 3,
							pointHoverRadius: 4,
							pointHoverBorderColor: "rgba(212, 148, 0, 0.8)",
							pointHoverBorderWidth: 3,
							pointRadius: [],
							pointHitRadius: 10,
							spanGaps: false,
						},
					],
				},
				options: {
					tooltips: {
						mode: "point",
						displayColors: false,
						titleFontSize: 14,
						bodyFontSize: 14,
						callbacks: {},
					},
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								type: "time",
							},
						],
						yAxes: [
							{
								id: "usage",
								ticks: {
									suggestedMin: 0,
								},
							},
						],
					},
					legend: {
						display: false,
					},
					layout: {
						padding: {
							top: 10,
						},
					},
				},
			};
		},

		blankRamChart() {},

		capitalize(str) {
			return str && str[0].toUpperCase() + str.slice(1);
		},

		prettify(str) {
			return JSON.stringify(str, null, 2).trim();
		},

		bytesToString(size) {
			if (size == 0) {
				return "0";
			}
			var i = Math.floor(Math.log(size) / Math.log(1024));
			return (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i];
		},
	},
};
</script>