<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("TeamEdit.TeamDetails.title") }}</div>
		<div role="form" class="p-4 col-sm-6">
			<div class="form-group" :class="{ invalid: !valid.field('name') }">
				<label>{{ $t("TeamEdit.TeamDetails.name") }}</label>
				<input type="text" class="form-control" v-model="team.name" />
			</div>
			<div class="form-group" :class="{ invalid: !(valid.field('ref') && valid.field('ref_char')) }">
				<label>{{ $t("TeamEdit.TeamDetails.ref_id") }}</label>
				<input type="text" class="form-control" v-model.trim="team.ref_id" :disabled="team.inUse" />
			</div>
			<div class="form-group">
				<label>{{ $t("TeamEdit.TeamDetails.description") }}</label>
				<input type="text" class="form-control" v-model="team.desc" />
			</div>
			<div class="form-group _600">{{ $t("TeamEdit.TeamDetails.active") }}</div>
			<div class="form-group">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="team.active" />
					<i></i>
				</label>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import TeamService from "@/services/TeamService";
import fs from "@/services/FormatService";

export default {
	name: "TeamEditDetails",

	props: ["id", "team", "defaultTab", "valid"],

	data() {
		return {};
	},

	watch: {
		"team.name"() {
			if (this.autoRef) {
				let genRef = fs.toGoodRefID(this.team.name);
				if (this.team.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.team.ref_id = genRef;
				}
			}
		},
		"team.ref_id"() {
			this.checkAutoRef();
		},
	},

	created() {
		this.checkAutoRef();
	},

	methods: {
		checkAutoRef() {
			let genRef = fs.toGoodRefID(this.team.name);
			this.autoRef = this.team.ref_id.toLowerCase() == genRef.toLowerCase();
		},
	},
};
</script>
