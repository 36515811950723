<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ProjectEdit.Details.title") }}</div>
		<div role="form" class="p-4 col-sm-6">
			<div class="form-group" :class="{ invalid: !valid.field('name') }">
				<label>{{ $t("ProjectEdit.Details.project_name") }}</label>
				<input type="text" class="form-control" v-model="project.name" />
			</div>

			<div class="form-group" :class="{ invalid: !valid.field('name') }">
				<label>{{ $t("ProjectEdit.Details.ref_id") }}</label>
				<input type="text" class="form-control" v-model="project.ref_id" />
			</div>

			<div class="form-group">
				<label>{{ $t("ProjectEdit.Details.description") }}</label>
				<input type="text" class="form-control" v-model="project.desc" />
			</div>

			<div class="row">
				<div class="col-6">
					<div>
						<div class="form-group _600">{{ $t("ProjectEdit.Details.active") }}</div>
						<div class="form-group">
							<label class="ui-switch ui-switch-md theme-accent">
								<input type="checkbox" v-model="project.active" />
								<i></i>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
export default {
	name: "ProjectEditDetails",

	props: ["id", "project", "valid", "defaultTab"],

	data() {
		return {
			lockoutTooltip: `<p>${this.$i18n.t(
				"ProjectEdit.Details.schedule_entry_lockout_period_tooltip_main"
			)}</p><p class="mb-0"><small class="text-muted">${this.$i18n.t(
				"ProjectEdit.Details.schedule_entry_lockout_period_tooltip_sub"
			)}</small></p>`,
		};
	},

	created() {},

	methods: {},
};
</script>
