<template>
	<div>
		<b-container :class="loading && 'text-muted'" v-tippy :title="tooltipTitle">
			<b-row>
				<b-col
					><h5 class="text-theme">{{ $t("fields.overall") }}</h5></b-col
				>
				<b-col
					><h5 class="text-theme">{{ $t("fields.weekly") }}</h5></b-col
				>
				<b-col
					><h5 class="text-theme">{{ $t("fields.daily") }}</h5></b-col
				>
			</b-row>
			<b-row>
				<b-col
					><h5>{{ overallScores }} {{ $t("fields.scores") }}</h5>
				</b-col>
				<b-col
					><h5>{{ weeklyScores }} {{ $t("fields.scores") }}</h5>
				</b-col>
				<b-col
					><h5>{{ dailyScores }} {{ $t("fields.scores") }}</h5></b-col
				>
			</b-row>
			<b-row>
				<b-col
					><h5>{{ formatPercent(overallPercent) }}</h5></b-col
				>
				<b-col
					><h5>{{ formatPercent(weeklyPercent) }}</h5></b-col
				>
				<b-col
					><h5>{{ formatPercent(dailyPercent) }}</h5></b-col
				>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import moment from "moment";

import notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import QCService from "@/services/QCService";
import Utils from "@/services/Utils";

export default {
	name: "DashboardQcResultsValComp",

	props: {
		user: Object,
		section: Object,
		item: Object,
	},

	data() {
		return {
			loading: false,

			overallScores: 0,
			overallPercent: 0,

			weeklyScores: 0,
			weeklyPercent: 0,

			dailyScores: 0,
			dailyPercent: 0,
		};
	},

	created() {
		this.loadCompData();
	},

	watch: {
		section() {
			this.loadCompData();
		},

		item() {
			this.loadCompData();
		},
	},

	computed: {
		tooltipTitle() {
			const now = new Date();
			const timeStr = Utils.displayWeekRange(moment(now).startOf("week"), moment(now).endOf("week"));
			return `${this.$i18n.t("Dashboard.values_from_payroll")}<br/>Week Span: ${timeStr}`;
		},
	},

	methods: {
		formatPercent(percent) {
			return fs.fixedPercent2d(percent);
		},

		async loadCompData() {
			const { section, item, user } = this;
			if (!(section && item && user)) return;

			this.loading = true;
			try {
				const {
					data: {
						overall_scores,
						overall_percent,
						weekly_scores,
						weekly_percent,
						daily_scores,
						daily_percent,
					},
				} = await QCService.getValidityCompStats(section.id, item.id, user.id);
				this.overallScores = overall_scores;
				this.overallPercent = overall_percent;
				this.weeklyScores = weekly_scores;
				this.weeklyPercent = weekly_percent;
				this.dailyScores = daily_scores;
				this.dailyPercent = daily_percent;
			} catch (e) {
				notie.error("Failed to get validity compensation data", e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style></style>
