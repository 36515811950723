<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ItemEdit.Details.title") }}</div>
		<div class="row mx-0">
			<div class="p-4 col-sm-6">
				<div class="form-group" :class="{ invalid: !valid.field('name') }">
					<label>{{ $t("ItemEdit.Details.name") }}</label>
					<input type="text" class="form-control" v-model="item.name" />
					<small v-if="warnRefID" class="form-text text-warning">{{
						$t("ItemEdit.Details.ref_id_warning")
					}}</small>
				</div>
				<div class="form-group" :class="{ invalid: !(valid.field('ref') && valid.field('ref_char')) }">
					<label>{{ $t("ItemEdit.Details.ref_id") }}</label>
					<input type="text" class="form-control" v-model.trim="item.ref_id" :disabled="item.isScored" />
					<small v-if="!item.isScored" class="form-text text-muted">{{
						$t("ItemEdit.Details.ref_id_unique")
					}}</small>
					<small v-if="warnRefID" class="form-text text-muted">{{
						$t("ItemEdit.Details.ref_id_warning")
					}}</small>
				</div>
				<div class="form-group">
					<label>{{ $t("ItemEdit.Details.description") }}</label>
					<input type="text" class="form-control" v-model="item.description" />
				</div>
				<div class="form-group">
					<label>{{ $t("ItemEdit.Details.assigned_sections") }}</label>
					<v-select
						v-tippy="{ theme: 'popover' }"
						title="You cannot change this value here.<br/>Use the Section configuration page to change which items are assigned to which sections."
						:options="assignedSections"
						:value="assignedSections"
						label="name"
						:class="{ is_invalid: !valid.field('training_course') }"
						multiple
						disabled
					/>
				</div>
				<div class="row">
					<div class="col-6">
						<div class="form-group _600">{{ $t("ItemEdit.Details.active") }}</div>
						<div class="form-group">
							<label class="ui-switch ui-switch-md theme-accent">
								<input type="checkbox" v-model="item.active" />
								<i></i>
							</label>
						</div>
					</div>
					<!-- <div
						v-if="client.iea_integration && (!item.isScored || (item.cf_config && item.cf_config.enabled))"
						class="col-6"
					>
						<div class="form-group _600">{{ $t("ItemEdit.Details.cf_config") }}</div>
						<div class="form-group">
							<label
								class="ui-switch ui-switch-md theme-accent"
								v-tippy="{ placement: 'right', theme: 'popover' }"
								:title="$t('ItemEdit.Details.cf_config_tooltip')"
								:disabled="item.isScored"
							>
								<input type="checkbox" v-model="item.cf_config.enabled" :disabled="item.isScored" />
								<i></i>
							</label>
						</div>
					</div> -->
				</div>
			</div>
			<!-- <div v-if="item.cf_config.enabled" class="p-4 col-sm-6" style="margin-left: -15px">
				<div class="card p-4 ml-0">
					<div class="pb-3 _600 nav-title">{{ $t("ItemEdit.Details.cf_settings") }}</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<style scoped>
.invalid {
	color: red;
}
</style>

<script>
import fs from "@/services/FormatService";
import ItemService from "@/services/ItemService";
import Notie from "@/services/NotieService";
import _ from "lodash";

export default {
	name: "ItemEditDetails",

	props: ["id", "item", "client", "defaultTab", "valid"],

	data() {
		return {
			ld: _,
			fs: fs,
			autoRef: false,

			cfPretrainedItems: null,
			assignedSections: [],

			mockup_cf_items: [
				{
					name: "Mockup Item Model 1",
					ref_id: "mockup_1",
					description: "This model implements the rubric and AI scoring for Item Model 1",
					trait_definitions: [
						{ name: "Trait 1A", min: 1, max: 4 },
						{ name: "Trait 2A", min: 1, max: 4 },
						{ name: "Trait 3A", min: 1, max: 4 },
						{ name: "Trait 4A", min: 1, max: 4 },
					],
				},
				{
					name: "Mockup Item Model 2",
					ref_id: "mockup_2",
					description: "This model implements the rubric and AI scoring for Item Model 2",
					trait_definitions: [
						{ name: "Trait 1B", min: 2, max: 5 },
						{ name: "Trait 2B", min: 2, max: 5 },
						{ name: "Trait 3B", min: 2, max: 5 },
						{ name: "Trait 4B", min: 2, max: 5 },
					],
				},
				{
					name: "Mockup Item Model 3",
					ref_id: "mockup_3",
					description: "This model implements the rubric and AI scoring for Item Model 3",
					trait_definitions: [
						{ name: "Trait 1C", min: 3, max: 6 },
						{ name: "Trait 2C", min: 3, max: 6 },
						{ name: "Trait 3C", min: 3, max: 6 },
						{ name: "Trait 4C", min: 3, max: 6 },
					],
				},
			],
		};
	},

	watch: {
		"item.name"() {
			if (this.autoRef && !this.item.isScored) {
				let genRef = fs.toGoodRefID(this.item.name);
				if (this.item.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.item.ref_id = genRef;
				}
			}
		},
		"item.ref_id"() {
			this.checkAutoRef();
		},
	},

	created() {
		this.checkAutoRef();
		this.getAssignedSections();
	},

	computed: {
		warnRefID() {
			const { isScored, ref_id } = this.item;
			return !isScored && ref_id.match(fs.refIDRegExp);
		},
	},

	methods: {
		checkAutoRef() {
			let genRef = fs.toGoodRefID(this.item.name);
			this.autoRef = this.item.ref_id.toLowerCase() == genRef.toLowerCase() && !this.item.isScored;
		},

		async getAssignedSections() {
			if (!this.item.id) {
				return;
			}

			try {
				const { data } = await ItemService.getAssignedSections(this.item.id);
				this.assignedSections = data;
			} catch (err) {
				Notie.error("Failed to Get Assigned Sections", err);
			}
		},
	},
};
</script>
