"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isWASMSupported() {
    try {
        if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
            var module_1 = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module_1 instanceof WebAssembly.Module)
                return new WebAssembly.Instance(module_1) instanceof WebAssembly.Instance;
        }
    }
    catch (e) {
        return false;
    }
    return false;
}
exports.default = isWASMSupported;
