<template>
	<div v-if="ruleStats.length > 0" class="card box shadow my-3 mx-4">
		<div class="d-flex flex-row theme header">
			<div class="flex text-center px-3 py-1">
				<i class="fas fa-briefcase mr-2" />
				{{ section.name }}
			</div>
			<div class="flex text-center px-3 py-1">
				<i class="fas fa-book mr-2" />
				{{ item.name }}
			</div>
		</div>
		<div class="w-100 text-center">
			<h4 class="mx-4 my-3">{{ $t("ItemEdit.Quality.validity.validity_rules") }}</h4>
			<h4 class="mx-4 my-3 text-center" :class="overallStatus.theme">
				{{ $t("fields.overall") }} {{ overallStatus.name }}
			</h4>
		</div>

		<validity-rule-stat-row v-for="ruleStat in ruleStats" :key="ruleStat.rule" :ruleStat="ruleStat" />
	</div>
</template>

<style scoped>
.header {
	font-weight: 600;
	font-size: 1rem;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
</style>

<script>
import QCService from "@/services/QCService";
import Notie from "@/services/NotieService";
import ValidityRuleStatRow from "./ValidityRuleStatRow.vue";

export default {
	name: "ValidityRuleStats",

	components: { ValidityRuleStatRow },

	props: {
		section: Object,
		item: Object,
		user: Object,
	},

	data() {
		return {
			ruleStats: [],
		};
	},

	created() {
		this.getRuleStats();
	},

	computed: {
		overallStatus() {
			return QCService.getValidityOverallStatus(this.ruleStats);
		},
	},

	methods: {
		async getRuleStats() {
			const { section, item, user } = this;
			try {
				const { data } = await QCService.getValidityRuleStats(section.id, item.id, user.id);
				this.ruleStats = data;
			} catch (e) {
				Notie.error("Failed to get Validity rules", e);
			}
		},
	},
};
</script>
