import { render, staticRenderFns } from "./Viewer.vue?vue&type=template&id=54b6908e&scoped=true"
import script from "./Viewer.vue?vue&type=script&lang=js"
export * from "./Viewer.vue?vue&type=script&lang=js"
import style0 from "./Viewer.vue?vue&type=style&index=0&id=54b6908e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b6908e",
  null
  
)

export default component.exports