<!-- Template for a new page -->
<template>
	<div class="d-flex flex-column flex h-100">
		<!-- Nav bar -->
		<top-bar :uiState="uiState" :participants="participants" />

		<div class="flex d-flex flex-row h-100" style="overflow: hidden">
			<!-- Video feeds -->
			<video-layout :uiState="uiState" :participants="participants" />

			<div v-if="uiState.sidebarTab" class="video-meeting-side-bar">
				<!-- Chat -->
				<div v-if="uiState.sidebarTab == 'chat'" class="meeting-panel mx-0 border h-100">
					<div class="meeting-panel-header theme-lighten-3 text-white">{{ $t("VideoMeeting.chat") }}</div>
					<chat :uiState="uiState" :localParticipant="localParticipant" :chatMessages="chatState.messages" />
				</div>
				<!-- Participant List -->
				<div v-if="uiState.sidebarTab == 'participant-list'" class="meeting-panel mx-0 border">
					<div class="meeting-panel-header theme-lighten-3 text-white">
						{{ $t("VideoMeeting.user_list") }}
					</div>
					<div class="meeting-panel-content scroll-y p-0">
						<participant-list
							:uiState="uiState"
							:participants="participants"
							:meetingUsers="meetingUsers"
							:localParticipant="localParticipant"
						/>
					</div>
				</div>

				<!--Device  Settings -->
				<div v-if="uiState.sidebarTab == 'settings'" class="meeting-panel mx-0 border">
					<div class="meeting-panel-header theme-lighten-3 text-white">
						{{ $t("VideoMeeting.device_settings") }}
					</div>
					<div class="meeting-panel-content scroll-y">
						<device-settings :uiState="uiState" :devices="devices" />
					</div>
				</div>
			</div>
		</div>

		<control-bar :uiState="uiState" :localParticipant="localParticipant" />

		<lightbox />
	</div>
</template>

<style scoped></style>

<script>
//UI Components
import TopBar from "@/vues/VideoMeeting/components/TopBar";
import VideoLayout from "@/vues/VideoMeeting/components/VideoLayout";
import Chat from "@/vues/VideoMeeting/components/Chat";
import ParticipantList from "@/vues/VideoMeeting/components/ParticipantList";
import DeviceSettings from "@/vues/VideoMeeting/components/DeviceSettings";
import ControlBar from "@/vues/VideoMeeting/components/ControlBar";
import Lightbox from "@/components/Lightbox";

//Libraries
import _ from "lodash";

//Services
import Signal from "@/vues/VideoMeeting/services/SignalEngine";
import Store from "@/services/Store";

export default {
	name: "VideoCall",
	props: ["uiState", "devices", "participants", "localParticipant", "meetingUsers"],
	components: { TopBar, VideoLayout, Chat, ParticipantList, DeviceSettings, ControlBar, Lightbox },
	data() {
		return {
			Signal: Signal,
			sidebarTab: "",
			chatState: {
				messagesMap: {},
				messages: [],
			},
			unreadMessages: 0,
		};
	},
	created() {
		Signal.init(this);
	},
	destroyed() {},
	methods: {
		hangUp() {
			// this.$emit("hangUp", true);
		},
	},
};
</script>
