import axios from "axios";
import Router from "@/router";
import LegacyRoles from "./LegacyRoles";
import Utils from "@/services/Utils";
import Chime from "@/vues/VideoMeeting/services/ChimeEngine";
import { isNil } from "lodash";

//AuthService
export default {
	token: "",
	user: null,
	getUserPromise: null,
	queuedRefresh: null,

	//Gets the logged in user, pass refresh = true to ask the server for latest user
	getUser(refresh) {
		if (!this.getUserPromise || refresh || this.queuedRefresh) {
			this.queuedRefresh = null;
			this.getUserPromise = new Promise((resolve, reject) => {
				// Ensure this request has the proper anti-CSRF header even if it's fired before the Vue App is "Created"
				let config = {
					headers: {
						["X-Requested-With"]: "XMLHttpRequest",
					},
				};

				axios
					.get("/v1/auth", config)
					.then((resp) => {
						var user = resp.data.data;
						if (user) {
							resolve(user);
						} else {
							reject(resp.data);
						}
					})
					.catch((err) => {
						reject(err);
					});
			});
		}
		return this.getUserPromise;
	},

	queueRefresh() {
		this.queuedRefresh = true;
	},

	//clearState clears out the auth information
	clearState() {
		//TODO clear cookie just to be sure
		this.getUserPromise = null;
		this.token = "";
		axios.defaults.headers.common["X-Auth-Token"] = this.token;
	},

	//Logs the user in and set up auth for future requests
	login(username, password) {
		return new Promise((resolve, reject) => {
			axios
				.post("/v1/auth", {
					email: username,
					password: password,
					authToken: this.token,
				})
				.then((r) => {
					//We don't really have to store the token, we'll use the "oscar" cookie until we see reasons otherwise
					//Update -- used for re-login now
					console.log("RECEIVED LOGIN AS", r.data.data);
					this.token = r.data.data;
					//It seems like any requests that happen within this resolve do not carry the cookie so we'll set this here. Cookie will be used on page refresh
					axios.defaults.headers.common["X-Auth-Token"] = this.token;
					resolve(r);
				})
				.catch((e) => {
					reject(e);
				});
		});
	},

	authWithToken(token) {
		this.token = token;
		axios.defaults.headers.common["X-Auth-Token"] = this.token;
	},

	loginAs(userID, clientID, force, env, path) {
		let ctx = {};
		if (force) {
			ctx.force = true;
		}
		console.log("CTX", ctx);
		return new Promise((resolve, reject) => {
			axios
				.post(`/v1/login_as/${userID}/${clientID}`, null, { params: ctx })
				.then((r) => {
					//We don't really have to store the token, we'll use the "oscar" cookie until we see reasons otherwise
					//Update -- used for re-login now
					console.log("RECEIVED LOGIN AS", r.data);
					this.token = r.data.data.hash;
					//It seems like any requests that happen within this resolve do not carry the cookie so we'll set this here. Cookie will be used on page refresh
					axios.defaults.headers.common["X-Auth-Token"] = this.token;

					// Make sure the next query for a user hits the server
					this.queueRefresh();

					r.data.data.env = env;
					r.data.data.path = path;

					resolve(r.data.data);
				})
				.catch(function (err, er) {
					reject(err);
				});
		});
	},

	// Logs the user out and navigates to sign-in page
	logout() {
		window.location = "/redirect/logout";

		// var _this = this;
		// return new Promise((resolve, reject) => {
		// 	axios.get("/v1/logout").then(function () {
		// 		_this.clearState();
		// 		Router.push({ path: "/" });

		// 		if (Chime.inMeeting()) {
		// 			Chime.destroy();
		// 		}
		// 	});
		// });
	},

	acceptEula() {
		this.queueRefresh();
		return axios.post("/v1/accept_eula");
	},

	acceptTerms() {
		this.queueRefresh();
		return axios.post("/v1/accept_terms");
	},

	getTimeout() {
		return axios.get("/v1/timeout");
	},

	//Change the client
	changeClient(clientID) {
		return axios.post(`/v1/change_client/${clientID}`);
	},

	resetPassword(email, captchaToken) {
		return axios.post("/v1/resetPassEmail", {
			email: email,
			captchaToken: captchaToken,
		});
	},

	resetPasswordOnSession(email) {
		return axios.post("/v1/resetPassEmailSession", {
			email: email,
			captchaToken: "",
		});
	},

	setNewPassword(token, password) {
		return axios.post("/v1/resetPass", {
			token: token,
			password: password,
		});
	},

	checkResetToken(token) {
		return axios.get(`/v1/valid_token/${token}`);
	},

	getCaptchaSiteKey() {
		return axios.get("/v1/site_key");
	},

	followDebugCodeLink(debugCode, router, force) {
		console.log("FOLLOW DEBUG LINK", debugCode, router, force);
		let linkStr = "";
		try {
			linkStr = atob(debugCode);
		} catch (e) {
			console.log("return 1");
			return Utils.errorPromise("Could not decode debug code", e);
		}
		let parts = linkStr.split("|");
		if (parts.length < 3) {
			return Utils.errorPromise("Could not parse debug code", linkStr);
		}
		let userID = parts[0];
		let clientID = parts[1];
		let urlStr = parts.slice(2);
		let url = new URL(urlStr);
		let path = url.hash;
		if (path.startsWith("#")) {
			path = path.slice(1);
		}

		console.log("userID", userID);
		console.log("clientID", clientID);
		console.log("url", urlStr);
		console.log("path", path);

		let destEnv = Utils.detectEnvironment(url.hostname);
		let currentEnv = Utils.detectEnvironment(window.location.hostname);
		if (destEnv != currentEnv) {
			console.log("return 3");
			return Utils.errorPromise(
				"Could not follow link",
				`Current environemnt: ${currentEnv}, Destination environment: ${destEnv}`
			);
		}

		return this.loginAs(userID, clientID, force, destEnv, path).then((r) => {
			console.log("Logged in as", userID, "rerouting to", path);
			console.log("LOGIN AS", r);
			router.push(path);
			return r;
		});
	},

	getSecondaryAuthToken() {
		return axios.get(`/v1/auth/secondary_token`);
	},

	samlLogout() {
		return new Promise((resolve, reject) => {
			this.getSamlLogoutUrl()
				.then((r) => {
					let url = r.data;
					console.log("REDIRECT TO URL", url);
					window.location = url;
				})
				.catch((e) => {
					reject(e);
				});
		});
	},

	getSamlLogoutUrl() {
		return axios.get(`/identity/saml/logout`);
	},

	_legacyRoleMap: LegacyRoles,
};
