<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("Observation.observation_sessions") }}
					<span class="far fa-fw fa-angle-right"></span>
					<template v-if="tab == 1">{{ $t("Observation.schedules") }}</template>
					<template v-if="tab == 2">{{ $t("Observation.sessions") }}</template>
				</div>
				<button
					v-if="tab == 1 && schedules.numSelected > 0"
					class="btn btn-success tab-header-button"
					@click="openCreateSessionModal"
				>
					{{ $t("Observation.create_group") }}
				</button>
			</div>
		</template>

		<div
			class="d-flex flex flex-row h100 mb-auto align-items-stretch"
			style="background-color: white; overflow: auto"
		>
			<div class="aside d-flex scroll-y">
				<div class="modal-dialog w-md light b-r b-t white p-2 audit-search d-flex flex-column">
					<div v-for="(site, i) in sites" :key="site.id">
						<hr v-if="i != 0" />
						<h5 class="mb-0 mx-2">{{ site.name }}</h5>
						<div class="text-muted mb-2 mx-2">{{ site.location }}</div>
						<div
							v-for="form in site.forms"
							:key="form.id"
							class="card clickable-card px-3 py-2 mb-2 site-card"
							:class="{
								active:
									selectedSite &&
									selectedForm &&
									site.id == selectedSite.id &&
									form.id == selectedForm.id,
							}"
							@click="selectSiteForm(site, form)"
						>
							<div>{{ form.name }}</div>
							<div class="text-muted">
								{{ form.form_id }} <span v-if="form.test_id"> • {{ form.test_id }}</span
								><span v-if="form.version"> • v{{ form.version }}</span>
							</div>
							<div class="mt-3" v-if="site.counts">
								<i class="fas fa-users mr-2" />{{ site.counts[form.id].count }} Schedules
								<span class="text-muted">({{ site.counts[form.id].not_scheduled }} new)</span>
							</div>
						</div>
						<div v-if="!(site.forms && site.forms.length > 0)" class="my-3">
							<h5 class="text-extra-muted text-center">No forms</h5>
						</div>
					</div>
					<!-- <div
									v-for="site in sites"
									:key="site.id"
									class="card clickable-card px-3 py-2 mb-2 site-card"
									:class="{ active: site.id == selectedSite.id }"
									@click="selectedSite = site"
								>
									<div>{{ site.name }}</div>
									<div class="text-muted">{{ site.location }}</div>
									<div class="mt-3">
										<i class="fas fa-users mr-2" />{{ site.schedules }} Schedules
									</div>
								</div> -->

					<div v-if="!(sites && sites.length > 0)" class="p-3">
						<h5 class="text-extra-muted text-center">You are not assigned to any sites</h5>
					</div>
				</div>
			</div>
			<div class="flex d-flex flex-column" style="overflow: hidden">
				<div class="row options-header px-2">
					<div class="col-12 col-sm-4">
						<div class="form-group">
							<label class="d-flex flex-row align-items-center">
								{{ $t("Observation.from") }}
								<user-timezone class="ml-1" />
								<button
									v-if="startDate"
									class="ml-auto btn btn-sm btn-danger btn-inline"
									@click="startDate = ''"
								>
									{{ $t("buttons.clear") }}
								</button>
							</label>
							<config-date v-model="startDate" :config="{ showClear: true }"></config-date>
						</div>
					</div>
					<div class="col-12 col-sm-4">
						<div class="form-group">
							<label class="d-flex flex-row align-items-center">
								{{ $t("Observation.until") }}
								<user-timezone class="ml-1" />
								<button
									v-if="endDate"
									class="ml-auto btn btn-sm btn-danger btn-inline"
									@click="endDate = ''"
								>
									{{ $t("buttons.clear") }}
								</button>
							</label>
							<config-date
								v-model="endDate"
								defaultTime="11:59 PM"
								:config="{ showClear: true }"
							></config-date>
						</div>
					</div>
					<div class="col-12 col-sm-4">
						<label>
							{{ $t("Observation.status") }}
						</label>
						<config-select
							class="bg-white"
							:options="statusTypes"
							v-model="status"
							byField="id"
							sortBy="sequence"
							:nullOption="true"
						/>
					</div>
				</div>

				<div
					class="row px-2 d-none d-sm-flex"
					style="margin-bottom: -46px; margin-top: 10px; z-index: 2; pointer-events: none"
				>
					<div class="d-none d-sm-block col-sm-8"></div>
					<div class="d-none d-sm-block col-12 col-sm-4" style="pointer-events: all">
						<form class="input-group">
							<span class="input-group-btn" style="margin-left: -8px; margin-top: 2px">
								<button
									v-if="!candidateID"
									type="button"
									class="btn no-border no-bg no-shadow"
									style="cursor: default; width: 35px"
								>
									<i class="fa fa-search"></i>
								</button>
								<button
									v-if="candidateID"
									type="button"
									class="btn no-border no-bg no-shadow"
									style="width: 35px"
									@click="candidateID = ''"
								>
									<i class="fa fa-times"></i>
								</button>
							</span>
							<input
								type="text"
								v-model="candidateID"
								class="form-control"
								:class="{ focused: candidateID }"
								:placeholder="$t('Observation.candidate_id_placeholder')"
							/>
						</form>
					</div>
				</div>
				<div class="row px-2 d-sm-none" style="margin-top: 16px; background-color: rgba(0, 0, 0, 0.03)">
					<div class="d-sm-none col-12" style="margin-top: 10px">
						<label>
							{{ $t("Observation.candidate_id") }}
						</label>
						<form class="input-group">
							<span class="input-group-btn" style="margin-left: -8px; margin-top: 2px">
								<button
									v-if="!candidateID"
									type="button"
									class="btn no-border no-bg no-shadow"
									style="cursor: default; width: 35px"
								>
									<i class="fa fa-search"></i>
								</button>
								<button
									v-if="candidateID"
									type="button"
									class="btn no-border no-bg no-shadow"
									style="width: 35px"
									@click="candidateID = ''"
								>
									<i class="fa fa-times"></i>
								</button>
							</span>
							<input
								type="text"
								v-model="candidateID"
								class="form-control"
								:placeholder="$t('Observation.candidate_id_placeholder')"
							/>
						</form>
					</div>
				</div>
				<div class="row no-gutters white nav-active-bg">
					<div class="card-header tab-header" style="width: 100%">
						<ul class="nav nav-tabs card-header-tabs">
							<li class="nav-item">
								<a :class="{ active: tab == 1 }" class="nav-link no-select" @click="tab = 1">{{
									$t("Observation.schedules")
								}}</a>
							</li>
							<li class="nav-item">
								<a :class="{ active: tab == 2 }" class="nav-link no-select" @click="tab = 2">{{
									$t("Observation.sessions")
								}}</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="flex scroll-y scroll-x">
					<b-table
						v-if="tab == 1"
						ref="schedulesTable"
						striped
						hover
						:show-empty="true"
						:items="schedulesProvider"
						:fields="schedules.fields"
						:current-page="schedules.currentPage"
						:per-page="schedules.perPage"
						:filter="schedules.filter"
						:sort-by.sync="schedules.sortBy"
						:sort-desc.sync="schedules.sortDesc"
						:busy.sync="schedules.busy"
					>
						<!-- Select -->
						<template #head(select)>
							<table-multiselect
								v-show="false"
								v-model="schedules.selectedIDs"
								:selectAll.sync="schedules.selectAll"
								master
								:items="schedules.currentItems"
								byField="id"
								:totalRows="schedules.totalRows"
								:numSelected.sync="schedules.numSelected"
								:query="schedules.query"
								:reset="resetSelectionSchedules"
							></table-multiselect>
						</template>
						<template #cell(select)="data">
							<table-multiselect
								v-if="data.item.status == 0"
								:value="schedules.selectedIDs"
								:selectAll="schedules.selectAll"
								:id="data.item.id"
							></table-multiselect>
						</template>

						<template #cell(candidate)="data">
							<div v-if="data.item.candidateIDs && data.item.candidateIDs.length">
								<i class="fas fa-users" />
								{{ data.item.candidateIDs.length }} Candidates
							</div>
							<div v-else>
								<i
									class="fas fa-user"
									v-tippy="{ theme: 'popover' }"
									:title="bookingTooltip(data.item)"
								/>
								<span v-if="data.item.candidate"
									>{{ data.item.candidate.first_name }} {{ data.item.candidate.last_name }}</span
								>
								<span v-else class="text-very-muted">(Unknown)</span>
							</div>
						</template>

						<template #cell(status)="data">
							<div v-if="data.item.status == 0" class="d-flex flex-row">
								<div class="flex status-badge" style="background-color: rgba(0, 0, 0, 0.1)">
									{{ $t("Observation.not_scheduled") }}
								</div>
								<button
									class="ml-2 btn btn-danger"
									style="margin-top: -6px; margin-bottom: -6px"
									@click="deleting = data.item"
									v-tippy
									:title="$t('Observation.delete_schedule')"
								>
									<i class="fas fa-trash" />
								</button>
							</div>
							<div
								v-if="data.item.status == 1"
								class="status-badge"
								style="background-color: rgba(0, 0, 0, 0.1)"
							>
								{{ $t("Observation.scheduled") }}
							</div>
							<div v-if="data.item.status == 2" class="status-badge badge-warning">
								{{ $t("Observation.in_progress") }}
							</div>
							<div v-if="data.item.status == 3" class="status-badge badge-success">
								{{ $t("Observation.complete") }}
							</div>
							<div v-if="data.item.status == 4" class="status-badge badge-danger">
								{{ $t("Observation.no_show") }}
							</div>
						</template>

						<template #cell(date)="data">
							{{ fs.shortDate(data.item.start_time) }}
						</template>

						<template #cell(start_time)="data">
							{{ fs.time(data.item.start_time) }}
						</template>
					</b-table>

					<b-table
						v-if="tab == 2"
						ref="sessionsTable"
						striped
						hover
						:show-empty="true"
						:items="sessionsProvider"
						:fields="sessions.fields"
						:current-page="sessions.currentPage"
						:per-page="sessions.perPage"
						:filter="sessions.filter"
						:sort-by.sync="sessions.sortBy"
						:sort-desc.sync="sessions.sortDesc"
						:busy.sync="sessions.busy"
					>
						<template #cell(schedules)="data">
							<div v-if="data.item.schedule_ids">
								<i class="fas fa-users" />
								{{ data.item.schedule_ids.length }} Candidates
							</div>
						</template>

						<template #cell(status)="data">
							<div
								v-if="data.item.status == 0"
								class="status-badge"
								style="background-color: rgba(0, 0, 0, 0.1)"
							>
								{{ $t("Observation.not_scheduled") }}
							</div>
							<div
								v-if="data.item.status == 1"
								class="status-badge"
								style="background-color: rgba(0, 0, 0, 0.1)"
							>
								{{ $t("Observation.scheduled") }}
							</div>
							<div v-if="data.item.status == 2" class="status-badge badge-warning">
								{{ $t("Observation.in_progress") }}
							</div>
							<div v-if="data.item.status == 3" class="status-badge badge-success">
								{{ $t("Observation.complete") }}
							</div>
							<div v-if="data.item.status == 4" class="status-badge badge-danger">
								{{ $t("Observation.no_show") }}
							</div>
						</template>

						<template #cell(date)="data">
							{{ fs.shortDate(data.item.start_time) }}
						</template>

						<template #cell(start_time)="data">
							{{ fs.time(data.item.start_time) }}
						</template>

						<template #cell(actions)="data">
							<template v-if="data.item.status == 1">
								<button
									class="btn btn-primary"
									style="margin-top: -12px; margin-bottom: -6px"
									@click="openCheckInModal(data.item)"
								>
									{{ $t("Observation.check_in") }}
								</button>
								<button
									class="btn btn-danger"
									style="margin-top: -12px; margin-bottom: -6px"
									@click="deleteSession(data.item)"
									v-tippy
									:title="$t('Observation.delete_session')"
								>
									<i class="fas fa-trash" />
								</button>
							</template>
							<template v-if="data.item.status == 2">
								<button
									class="btn btn-primary"
									style="margin-top: -12px; margin-bottom: -6px"
									@click="observeSession(data.item)"
								>
									{{ $t("Observation.observe") }}
								</button>
								<button
									class="btn btn-danger"
									style="margin-top: -12px; margin-bottom: -6px"
									@click="deleteSession(data.item)"
									v-tippy
									:title="$t('Observation.delete_session')"
								>
									<i class="fas fa-trash" />
								</button>
							</template>
							<template v-if="data.item.status == 3">
								<button
									class="btn btn-primary"
									style="margin-top: -12px; margin-bottom: -6px"
									@click="viewScores(data.item)"
								>
									{{ $t("Observation.view_scores") }}
								</button>
							</template>
						</template>
					</b-table>
				</div>
				<div v-if="tab == 1" class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
					<a class="flex mx-3"
						>{{ $t("pagination.page") }} {{ schedules.totalRows > 0 ? schedules.currentPage : 0 }}
						{{ $t("pagination.of") }} {{ Math.ceil(schedules.totalRows / schedules.perPage) }} ({{
							schedules.totalRows
						}})</a
					>
					<div>
						<mz-pagination
							size="md"
							class="m-1"
							:total-rows="schedules.totalRows"
							v-model="schedules.currentPage"
							:per-page="schedules.perPage"
						></mz-pagination>
					</div>
				</div>
				<div v-if="tab == 2" class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
					<a class="flex mx-3"
						>{{ $t("pagination.page") }} {{ sessions.totalRows > 0 ? sessions.currentPage : 0 }}
						{{ $t("pagination.of") }} {{ Math.ceil(sessions.totalRows / sessions.perPage) }} ({{
							sessions.totalRows
						}})</a
					>
					<div>
						<mz-pagination
							size="md"
							class="m-1"
							:total-rows="sessions.totalRows"
							v-model="sessions.currentPage"
							:per-page="sessions.perPage"
						></mz-pagination>
					</div>
				</div>
			</div>
		</div>

		<b-modal
			id="createSessionModal"
			v-model="showCreateSessionModal"
			@hide="showCreateSessionModal = false"
			size="lg"
		>
			<template slot="modal-header">
				<h5 class="modal-title pl-3">
					{{ $t("Observation.create_observation_session") }}
				</h5>
			</template>

			<div class="px-3 pt-3">
				<h5>Create an observation group with schedules:</h5>
				<table class="table table-striped">
					<tbody>
						<tr v-for="session in selectedSchedules" :key="session.id">
							<td>{{ session.candidate.first_name }} {{ session.candidate.last_name }}</td>
							<td>
								{{ fs.shortDate(session.start_time) }}
							</td>
							<td>
								{{ fs.time(session.start_time) }}
							</td>
						</tr>
					</tbody>
				</table>
				<div class="row">
					<div class="col-12 col-sm-6">
						<div class="form-group">
							<label>
								{{ $t("Observation.start_time") }}
								<user-timezone />
							</label>
							<config-date
								v-if="showCreateSessionModal"
								v-model="createSessionStartDate"
								:config="{ showClear: true }"
							></config-date>
						</div>
					</div>
				</div>
			</div>

			<template slot="modal-footer">
				<div class="d-flex flex-row px-3 w-100">
					<button class="btn btn-danger mr-auto" @click="showCreateSessionModal = false">
						{{ $t("buttons.cancel") }}
					</button>
					<button v-if="!creatingSession" class="btn btn-primary mr-2" @click="createSessionAndCheckIn">
						{{ $t("Observation.create_and_check_in") }}
					</button>
					<button
						v-if="creatingSession && creatingSession != 'checkin'"
						class="btn btn-primary mr-2"
						disabled
					>
						{{ $t("Observation.create_and_check_in") }}
					</button>
					<button v-if="creatingSession == 'checkin'" class="btn btn-primary mr-2" disabled>
						{{ $t("Observation.creating...") }}<loading type="icon" />
					</button>

					<button v-if="!creatingSession" class="btn btn-primary" @click="createSession">
						{{ $t("Observation.create") }}
					</button>
					<button v-if="creatingSession && creatingSession != 'create'" class="btn btn-primary" disabled>
						{{ $t("Observation.create") }}
					</button>
					<button v-if="creatingSession == 'create'" class="btn btn-primary" disabled>
						{{ $t("Observation.creating...") }}<loading type="icon" />
					</button>
				</div>
			</template>
		</b-modal>

		<b-modal id="checkInModal" v-model="showCheckInModal" @hide="showCheckInModal = false" size="md">
			<template slot="modal-header">
				<h5 class="modal-title pl-3">
					{{ $t("Observation.check_in") }}
				</h5>
			</template>

			<div class="px-3 pt-3">
				<h5>{{ $t("Observation.choose_stations") }}</h5>
				<div v-if="checkInCandidates" class="d-flex flex-row box card">
					<div class="d-flex flex-column check-in-numbers">
						<div class="check-in-user check-in-row-height text-md _600 mt-3">
							{{ $t("Observation.station") }}
						</div>
						<div v-for="(session, i) in checkInCandidates" :key="i" class="check-in-row-height text-md">
							{{ i + 1 }}
						</div>
					</div>

					<div class="d-flex flex-column check-in-users">
						<div class="check-in-user check-in-row-height text-md _600 mt-3">
							{{ $t("Observation.candidate") }}
						</div>
						<draggable v-model="checkInCandidates" :options="{ handle: '.drag-handle', animation: 150 }">
							<div v-for="(candidate, i) in checkInCandidates" :key="i">
								<div
									v-if="!candidate.empty"
									class="check-in-user check-in-row-height text-md d-flex flex-row"
								>
									<div class="px-2 drag-handle" style="flex-grow: 0">
										<i class="far fa-sort text-muted"></i>
									</div>
									<template v-if="candidate.no_show">
										<div class="flex text-muted text-strikethrough">
											{{ candidate.first_name }} {{ candidate.last_name }}
										</div>
										<button
											class="btn btn-success btn-icon btn-sm btn-rounded mr-3"
											v-tippy
											:title="$t('Observation.mark_as_present')"
											@click="unmarkAsNoShow(candidate)"
										>
											<i class="fas fa-plus" />
										</button>
									</template>
									<template v-else>
										<div class="flex">{{ candidate.first_name }} {{ candidate.last_name }}</div>
										<button
											class="btn btn-danger btn-icon btn-sm btn-rounded mr-3"
											v-tippy
											:title="$t('Observation.mark_as_no_show')"
											@click="markAsNoShow(candidate)"
										>
											<i class="fas fa-times" />
										</button>
									</template>
								</div>
								<div v-if="candidate.empty" class="check-in-user check-in-row-height text-md">
									<div class="ml-4 text-very-muted">(Empty)</div>
								</div>
							</div>
						</draggable>
					</div>
				</div>
				<div v-else class="text-center text-extra-muted">
					<loading type="large" />
				</div>

				<h5>Co-raters:</h5>

				<config-user-search v-model="checkInCoRaters" multiple :clientID="user.client && user.client.id" />
			</div>

			<template slot="modal-footer">
				<div class="d-flex flex-row px-3 w-100">
					<button class="btn btn-danger mr-auto" @click="showCheckInModal = false">
						{{ $t("buttons.cancel") }}
					</button>
					<button class="btn btn-primary" @click="checkIn(checkInSession)">
						{{ $t("Observation.observe") }}
					</button>
				</div>
			</template>
		</b-modal>

		<b-modal id="scoreModal" v-model="showScoreModal" @hide="showScoreModal = false" size="lg">
			<template slot="modal-header">
				<h5 class="modal-title px-3 align-self-center">
					{{ $t("Observation.view_scores") }}
				</h5>
				<div class="pr-3 d-flex flex-column align-items-end">
					<div v-if="selectedForm" class="text-muted">
						{{ selectedForm.name }}
					</div>
					<div v-if="selectedSite && scoreSession" class="text-muted">
						{{ selectedSite.name }} • {{ fs.medDate(scoreSession.start_time) }}
					</div>
				</div>
			</template>

			<div v-if="scoreSession && scoreForm && scoreCandidates" class="px-3 modal-scroll">
				<!-- <h5>Choose stations:</h5>
				<pre>{{ scoreSession.scores }}</pre> -->
				<table class="table striped mb-0">
					<thead>
						<tr>
							<th class="text-muted _400">{{ $t("Observation.section") }}</th>
							<th v-for="score in scoreSession.scores" :key="score.id" class="text-center">
								{{ score.candidate.first_name }} {{ score.candidate.last_name }}
							</th>
						</tr>
						<tr v-for="(section, i) in scoreForm.sections" :key="section.id">
							<td>{{ i + 1 }}. {{ section.name }}</td>
							<template v-for="(section_score, j) in section.candidate_scores">
								<td
									v-if="!section_score.absent"
									:key="j"
									class="text-center"
									style="font-size: 18px"
									v-tippy="{ theme: 'popover' }"
									:title="sectionScoreTooltip(section, section_score)"
								>
									{{ section_score.total }}
									<span class="text-muted" style="font-size: 12px">/ {{ section_score.max }}</span>
								</td>
								<td v-if="section_score.absent" :key="j" class="text-center text-muted">
									{{ $t("Observation.absent") }}
								</td>
							</template>
						</tr>
						<template v-if="user.client.obs_score_enabled && user.role.manage_observation_schedules">
							<tr v-if="scoreSession && scoreSession.scores && scoreSession.scores.length">
								<th :colspan="scoreSession.scores.length + 1"></th>
							</tr>
							<tr></tr>
							<tr>
								<th>{{ $t("Observation.score_export") }}</th>
								<th v-for="score in scoreSession.scores" :key="score.id" class="text-center">
									<template v-if="score.schedule">
										<button
											v-if="score.schedule.exported"
											class="btn btn-success"
											style="margin-top: -6px; margin-bottom: -6px"
											@click="reExportSchedule(score.schedule)"
											v-tippy
											:title="exportTooltip(score.schedule)"
										>
											<i v-if="score.schedule.reExported" class="fas fa-sync" />
											<i v-else class="fas fa-arrow-from-left" />
										</button>
										<button
											v-else-if="score.schedule.export_error"
											class="btn btn-danger"
											style="margin-top: -6px; margin-bottom: -6px"
											@click="reExportSchedule(score.schedule)"
											v-tippy
											:title="exportTooltip(score.schedule)"
										>
											<i v-if="score.schedule.reExported" class="fas fa-sync" />
											<i v-else class="fas fa-arrow-from-left" />
										</button>
										<button
											v-else
											class="btn btn-secondary"
											style="margin-top: -6px; margin-bottom: -6px"
											@click="reExportSchedule(score.schedule)"
											v-tippy
											:title="exportTooltip(score.schedule)"
										>
											<i v-if="score.schedule.reExported" class="fas fa-sync" />
											<i v-else class="fas fa-arrow-from-left" />
										</button>
									</template>
									<div v-else class="text-muted">(Not found)</div>
								</th>
							</tr>
						</template>
					</thead>
				</table>
			</div>
			<div v-else>
				<div class="text-center my-5 text-muted"><loading type="large" /></div>
			</div>

			<template slot="modal-footer">
				<div v-if="scoreSession" class="d-flex flex-row px-3 w-100">
					<button class="btn btn-primary mr-auto" @click="showScoreModal = false">
						{{ $t("buttons.close") }}
					</button>
					<button
						v-if="user.role.edit_obs_scores"
						class="ml-auto btn theme"
						@click="changeScore(scoreSession)"
					>
						<i class="fas fa-edit mr-2" />{{ $t("Observation.edit_scores") }}
					</button>
				</div>
			</template>
		</b-modal>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("Observation.delete_schedule_modal.title") }}</template>
			<div class="row p-4">
				{{ $t("Observation.delete_schedule_modal.confirm_delete") }}
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">
					{{ $t("Observation.delete_schedule_modal.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="deleteSchedule(deleting)">
					{{ $t("Observation.delete_schedule_modal.delete") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}
.w30 {
	width: 30px;
}
.audit-search > .form-group {
	margin-bottom: 5px;
}
.score-badge {
	font-size: 1rem;
	line-height: 1.5rem;
}
.badge {
	width: 1.25rem;
	height: 1.5rem;
	padding: 0 0;
}
.empty-badge {
	box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.mi-small {
	font-size: 1rem;
}
.ats-table {
	white-space: nowrap;
}
label {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.dot {
	height: 5px;
	width: 5px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}
.nodot {
	height: 5px;
	width: 5px;
	border-radius: 50%;
	display: inline-block;
}
.btn-topnav {
	margin-top: -8px;
	margin-bottom: -8px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}
.btn-topnav:last-child {
	margin-right: -16px;
	margin-left: -16px;
}
.btn-topnav:hover {
	background-color: rgba(0, 0, 0, 0.075);
}
.btn-topnav a {
	line-height: 56px;
}
.topnav-margins {
	margin-top: -8px;
	margin-bottom: -8px;
}

.nav-link.active {
	background-color: white !important;
}

.options-header {
	margin-bottom: -16px;
	background-color: rgba(0, 0, 0, 0.03);
}

.tab-header {
	position: relative;
	z-index: 1;
}

.tab-header-button {
	position: absolute;
	right: 10px;
	bottom: 10px;
	z-index: 2;
}

.clickable-card {
	transition: background-color 0.15s ease-in-out;
	cursor: pointer;
}
.clickable-card:hover {
	background-color: rgba(0, 0, 0, 0.03);
}
.clickable-card.active {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(83, 166, 250, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(83, 166, 250, 0.25);
	border-color: #53a6fa;
}

.status-badge {
	border-radius: 5px;
	text-align: center;
}

.check-in-numbers {
	width: 20%;
	text-align: center;
}

.check-in-users {
	width: 80%;
}

.check-in-row-height {
	height: 40px;
}

.btn-inline {
	padding: 0 0.2rem;
	font-size: 0.7rem;
}
</style>

<script>
//UI Components
//Libraries
//import _ from "lodash";
// import moment from "moment";
// import $ from "jquery";
import BB from "bluebird";

//Services
import ObservationService from "@/services/ObservationService";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import store from "@/services/Store";

import draggable from "vuedraggable";
import TableMultiselect from "@/components/TableMultiselect";
import MzPagination from "@/components/MzPagination";
import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "ObservationSessions",
	props: ["user"],
	components: { draggable, TableMultiselect, MzPagination, ConfigSelect, ConfigDate, ConfigUserSearch, UserTimezone },
	data() {
		let schedulesCurrentPage = store.get(this, "observation.schedules.currentPage");
		let schedulesSortBy = store.get(this, "observation.schedules.sortBy");
		let schedulesSortDesc = store.get(this, "observation.schedules.sortDesc");

		let sessionsCurrentPage = store.get(this, "observation.sessions.currentPage");
		let sessionsSortBy = store.get(this, "observation.sessions.sortBy");
		let sessionsSortDesc = store.get(this, "observation.sessions.sortDesc");
		let tab = store.getDefault(this, "observation.tab", 1);

		let startDate = store.get(this, "observation.startDate");
		let endDate = store.get(this, "observation.endDate");
		let status = store.get(this, "observation.status");
		let candidateID = store.get(this, "observation.candidateID");

		return {
			vm: this,
			fs: fs,
			loading: true,

			tab: tab,
			sites: null,
			forms: null,
			counts: null,
			selectedSite: null,
			selectedForm: null,
			startDate: startDate,
			endDate: endDate,
			status: status,
			statusTypes: fs.observationStatusTypes(),
			candidateID: candidateID,

			showCreateSessionModal: false,
			showCheckInModal: false,
			selectedSchedules: null,
			checkInSession: null,
			checkInCandidates: null,
			noShowCandidates: null,
			checkInSchedules: null,
			checkInCoRaters: null,
			createSessionStartDate: null,
			creatingSession: null,

			showScoreModal: false,
			scoreSession: null,
			scoreCandidates: null,
			scoreSchedules: null,
			scoreForm: null,

			schedules: {
				fields: [
					{
						key: "select",
						label: this.$i18n.t("fields.select"),
						thClass: "p-custom p-0 v-mid",
						thStyle: "width: 40px;",
						tdClass: "p-custom p-0 v-mid",
					},
					{ key: "candidate", label: this.$i18n.t("fields.candidate"), sortable: false },
					{ key: "status", label: this.$i18n.t("fields.status"), sortable: true },
					{ key: "date", label: this.$i18n.t("fields.date"), sortable: false },
					{ key: "start_time", label: this.$i18n.t("fields.start_time"), sortable: true },
				],
				currentPage: schedulesCurrentPage,
				perPage: 20,
				totalRows: 0,
				filter: "",
				sortBy: schedulesSortBy,
				sortDesc: schedulesSortDesc,
				busy: null,
				currentItems: [],

				queueResetSelection: false,

				selectedIDs: [],
				selectAll: null,
			},
			deleting: false,

			sessionsList: [],

			sessions: {
				fields: [
					{ key: "schedules", label: this.$i18n.t("fields.candidates"), sortable: false },
					{ key: "status", label: this.$i18n.t("fields.status"), sortable: true },
					{ key: "date", label: this.$i18n.t("fields.date"), sortable: false },
					{ key: "start_time", label: this.$i18n.t("fields.start_time"), sortable: true },
					{ key: "actions", label: this.$i18n.t("fields.actions"), sortable: false },
				],
				currentPage: sessionsCurrentPage,
				perPage: 20,
				totalRows: 0,
				filter: "",
				sortBy: sessionsSortBy,
				sortDesc: sessionsSortDesc,
				busy: null,
				currentItems: [],

				queueResetSelection: false,
			},
		};
	},

	created() {
		this.loadConfigData();
		this.refreshTableSchedules = _.debounce(() => {
			this.$refs.schedulesTable.refresh();
		}, 500);
		this.refreshTableSessions = _.debounce(() => {
			this.$refs.sessionsTable.refresh();
		}, 500);
	},

	computed: {},

	watch: {
		tab() {
			store.set(this, "observation.tab", this.tab);
			// this.refreshTable();
		},

		selectedSite() {
			if (!this.selectedSite) return;
			store.set(this, "observation.siteID", this.selectedSite.id);
			this.refreshTable();
		},

		selectedForm() {
			if (!this.selectedForm) return;
			store.set(this, "observation.formID", this.selectedForm.id);
			this.refreshTable();
		},

		startDate() {
			store.set(this, "observation.startDate", this.startDate);
			this.refreshTable();
		},

		endDate() {
			store.set(this, "observation.endDate", this.endDate);
			this.refreshTable();
		},

		status() {
			store.set(this, "observation.status", this.status);
			this.refreshTable();
		},

		candidateID() {
			store.set(this, "observation.candidateID", this.candidateID);
			this.refreshTable();
		},
	},

	methods: {
		refreshTable() {
			if (this.tab == 1) {
				this.refreshTableSchedules();
			} else {
				this.refreshTableSessions();
			}
		},

		loadConfigData() {
			BB.props({
				sites: ObservationService.getMySites(),
				forms: ObservationService.getMyForms(),
				counts: ObservationService.getMyScheduleCounts(),
			})
				.then((r) => {
					this.loading = false;
					this.sites = r.sites.data;
					this.forms = r.forms.data;
					this.counts = r.counts.data;

					// Load forms on sites and counts on forms
					_.each(this.sites, (site) => {
						site.forms = [];
						site.counts = {};
						_.each(this.forms, (form) => {
							let stat = _.find(this.counts, { site_id: site.id, form_id: form.id });
							if (stat) {
								site.counts[form.id] = {
									count:
										stat.not_scheduled +
										stat.scheduled +
										stat.in_progress +
										stat.complete +
										stat.no_show,
									not_scheduled: stat.not_scheduled,
								};
								site.forms.push(form);
							}
						});
					});

					if (this.sites && this.sites.length > 0) {
						let siteID = store.get(this, "observation.siteID");
						_.each(this.sites, (site) => {
							if (site.id == siteID) {
								this.selectedSite = site;
							}
						});
						if (!this.selectedSite) {
							this.selectedSite = this.sites[0];
						}
					}

					if (this.forms && this.forms.length > 0) {
						let formID = store.get(this, "observation.formID");
						_.each(this.forms, (form) => {
							if (form.id == formID) {
								this.selectedForm = form;
							}
						});
						if (!this.selectedForm) {
							this.selectedForm = this.forms[0];
						}
					}
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to load observation sites", e);
				});
		},

		schedulesProvider(ctx) {
			console.log("SCHEDULES PROVIDER", this.selectedSite, this.selectedForm);
			if (!this.selectedSite) return;
			if (!this.selectedForm) return;
			if (this.schedules.currentPage <= 0) {
				this.schedules.currentPage = 1;
			}
			console.log("SCHEDULES PROVIDER");
			store.set(this, "observation.schedules.sortBy", this.schedules.sortBy);
			store.set(this, "observation.schedules.sortDesc", this.schedules.sortDesc);
			store.set(this, "observation.schedules.currentPage", this.schedules.currentPage);

			this.schedules.busy = true;
			let query = this.getSchedulesQuery();
			return ObservationService.querySchedules(query, ctx)
				.then((resp) => {
					console.log("SCHEDULES PROVIDER CALLBACK", resp.data);
					if (this.schedules.queueResetSelection) {
						this.resetSelection();
						this.schedules.queueResetSelection = false;
					}

					this.schedules.query = query;
					this.schedules.totalRows = resp.data.totalRows;
					this.schedules.currentItems = resp.data.rows;
					console.log("return", this.schedules);
					return this.schedules.currentItems;
				})
				.catch((err) => {
					return [];
				})
				.finally(() => {
					this.schedules.busy = false;
				});
		},

		sessionsProvider(ctx) {
			if (!this.selectedSite) return;
			if (!this.selectedForm) return;
			if (this.sessions.currentPage <= 0) {
				this.sessions.currentPage = 1;
			}
			store.set(this, "observation.sessions.sortBy", this.sessions.sortBy);
			store.set(this, "observation.sessions.sortDesc", this.sessions.sortDesc);
			store.set(this, "observation.sessions.currentPage", this.sessions.currentPage);

			this.sessions.busy = true;
			let query = this.getSessionsQuery();
			return ObservationService.querySessions(query, ctx)
				.then((resp) => {
					console.log("SESSIONS", resp.data);
					if (this.sessions.queueResetSelection) {
						this.resetSelection();
						this.sessions.queueResetSelection = false;
					}

					this.sessions.query = query;
					this.sessions.totalRows = resp.data.totalRows;
					this.sessions.currentItems = resp.data.rows;
					return this.sessions.currentItems;
				})
				.catch((err) => {
					return [];
				})
				.finally(() => {
					this.sessions.busy = false;
				});
		},

		getSchedulesQuery() {
			if (!this.selectedSite) return;
			if (!this.selectedForm) return;

			let query = {
				site_id: this.selectedSite.id,
				form_id: this.selectedForm.id,
				start_time: this.startDate,
				end_time: this.endDate,
				status: this.status,
				candidate_id: this.candidateID,
			};

			return query;
		},

		getSessionsQuery() {
			if (!this.selectedSite) return;
			if (!this.selectedForm) return;

			let query = {
				site_id: this.selectedSite.id,
				form_id: this.selectedForm.id,
				start_time: this.startDate,
				end_time: this.endDate,
				status: this.status,
				candidate_id: this.candidateID,
			};

			return query;
		},

		resetSelectionSchedules() {
			this.schedules.selectedIDs = [];
			this.schedules.selectAll = false;
		},

		refreshAndResetSelectionSchedules() {
			this.schedules.queueResetSelection = true;
			this.refreshTableSchedules();
		},

		resetSelectionSessions() {
			this.sessions.selectedIDs = [];
			this.sessions.selectAll = false;
		},

		refreshAndResetSelectionSessions() {
			this.sessions.queueResetSelection = true;
			this.refreshTableSchedules();
		},

		openCreateSessionModal() {
			let selectedSchedules = [];
			_.each(this.schedules.selectedIDs, (sid) => {
				let session = _.find(this.schedules.currentItems, { id: sid });
				if (!session) return;
				selectedSchedules.push(session);
			});

			this.selectedSchedules = selectedSchedules;
			this.createSessionStartDate = this.modeStartTime(selectedSchedules);

			this.showCreateSessionModal = true;
		},

		createSession() {
			let newSession = {
				schedule_ids: _.map(this.selectedSchedules, "id"),
				start_time: this.createSessionStartDate,
				status: 1,
			};

			ObservationService.saveSession(newSession)
				.then(() => {
					this.showCreateSessionModal = false;
					this.tab = 2;
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to save session", e);
				});
		},

		createSessionAndCheckIn() {
			let newSession = {
				schedule_ids: _.map(this.selectedSchedules, "id"),
				start_time: this.createSessionStartDate,
				status: 1,
			};

			return ObservationService.saveSession(newSession)
				.then((r) => {
					console.log("CREATE SESSION", r.data);
					this.showCreateSessionModal = false;
					this.tab = 2;

					this.openCheckInModal(r.data);
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to save session", e);
				});
		},

		openCheckInModal(session) {
			this.checkInCandidates = null;
			this.checkInSession = session;
			this.showCheckInModal = true;

			ObservationService.getSessionCandidatesAndSchedules(session.id)
				.then((r) => {
					this.checkInCandidates = r.data.candidates;
					this.checkInSchedules = r.data.schedules;

					let emptySeats = this.selectedSite.seats - this.checkInCandidates.length;
					if (emptySeats < 0) {
						Notie.error(`Too many candidates to fit in specified seats (${this.site.seats}`);
					} else {
						for (let i = 0; i < emptySeats; i++) {
							this.checkInCandidates.push({ empty: true });
						}
					}
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get candidates", e);
				});
		},

		viewScores(session) {
			this.scoreSession = session;
			this.scoreCandidates = null;
			this.scoreSchedules = null;
			this.showScoreModal = true;

			BB.props({
				cand: ObservationService.getSessionCandidatesAndSchedules(session.id),
				form: ObservationService.getForm(session.form_id),
			})

				.then((r) => {
					this.scoreCandidates = r.cand.data.candidates;
					this.scoreSchedules = r.cand.data.schedules;
					this.scoreForm = r.form.data;

					_.each(this.scoreSession.scores, (score) => {
						let candidate = _.find(this.scoreCandidates, { id: score.candidate_id });
						if (candidate) {
							score.candidate = candidate;
						} else {
							score.candidate = {
								first_name: "ERROR",
								last_name: "ERROR",
							};
						}

						let schedule = _.find(this.scoreSchedules, { id: score.schedule_id });
						if (schedule) {
							score.schedule = schedule;
						}
					});

					_.each(this.scoreForm.sections, (section) => {
						section.candidate_scores = [];
						_.each(this.scoreSession.scores, (score) => {
							let section_score = _.find(score.section_scores, { section_id: section.id });
							if (!section_score) {
								console.error(
									"Failed to find section score",
									this.scoreSession.id,
									section_id,
									score.id
								);
								Notie.error("Failed to load section score");
								this.scoreSession = null;
							} else {
								section_score.total = 0;
								section_score.max = 0;
								_.each(section_score.task_scores, (task_score) => {
									section_score.total += task_score.value;
									section_score.max++;
								});
							}
							section.candidate_scores.push(section_score);
						});
					});
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get candidates", e);
				});
		},

		checkIn(session) {
			// Load rater ids
			session.rater_ids = [this.user.id];
			_.each(this.checkInCoRaters, (cr) => {
				session.rater_ids.push(cr.id);
			});

			// Load stations
			session.stations = [];
			_.each(this.checkInCandidates, (c, i) => {
				if (c.empty) return;

				let schedule = _.find(this.checkInSchedules, { candidate_id: c.id });
				if (!schedule) {
					console.error("No schedule found for candidate", c.id, this.checkInSchedules);
					Notie.error("No schedule found for candidate");
					return;
				}
				session.stations.push({
					sequence: i + 1,
					candidate_id: c.id,
					schedule_id: schedule.id,
					no_show: c.no_show,
				});
			});

			// Perform check-in
			ObservationService.checkInSession(session)
				.then(() => {
					this.$router.push(`/observe/${session.id}`);
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to check in session", e);
				});
		},

		observeSession(session) {
			this.$router.push(`/observe/${session.id}`);
		},

		changeScore(session) {
			this.$router.push(`/observe/${session.id}?edit=true`);
		},

		async deleteSession(session) {
			const { $bvModal, $i18n } = this;

			const ok = await $bvModal.msgBoxConfirm($i18n.t("Observation.delete_session_modal.confirm_delete"), {
				title: $i18n.t("Observation.delete_session_modal.title"),
				centered: true,
			});

			if (!ok) return;

			try {
				await ObservationService.deleteSession(session.id);
				Notie.info("Deleted session");
				this.refreshTableSessions();
			} catch (e) {
				console.error(e);
				Notie.error("Failed to delete session", e);
			}
		},

		deleteSchedule(session) {
			ObservationService.deleteSchedule(session.id)
				.then(() => {
					Notie.info("Deleted schedule");
					this.deleting = false;
					this.refreshTableSchedules();
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to delete schedule", e);
				});
		},

		modeStartTime(schedules) {
			var result = _.head(_(schedules).countBy("start_time").entries().maxBy(_.last));
			return result;
		},

		selectSiteForm(site, form) {
			this.selectedSite = site;
			this.selectedForm = form;
		},

		sectionScoreTooltip(section, sectionScore) {
			let tooltip = `<table class="table striped">`;
			_.each(section.tasks, (task) => {
				_.each(sectionScore.task_scores, (task_score) => {
					if (task.id == task_score.task_id) {
						if (task_score.value == 1) {
							tooltip += `<tr><td class="text-success">✔️</td><td>${task.description}</td></tr>`;
						} else {
							tooltip += `<tr><td class="text-danger">❌</td><td>${task.description}<ul class="mb-0">`;
							_.each(task.subtasks, (subtask) => {
								_.each(task_score.codes, (code) => {
									if (subtask.id == code) {
										tooltip += `<li>${subtask.name}</li>`;
									}
								});
							});
							tooltip += `</ul></td></tr>`;
						}
					}
				});
			});

			tooltip += `</table>`;

			return tooltip;
		},

		markAsNoShow(candidate) {
			candidate.no_show = true;
			this.$forceUpdate();
		},

		unmarkAsNoShow(candidate) {
			candidate.no_show = false;
			this.$forceUpdate();
		},

		reExportSchedule(schedule) {
			if (schedule.reExported) return;

			ObservationService.reExportSchedule(schedule.id)
				.then((r) => {
					schedule.reExported = true;
					if (schedule.exported || schedule.export_error) {
						Notie.info("Queued score for re-export");
					} else {
						Notie.info("Queued score for export");
					}
					this.$forceUpdate();
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to queue score for export", e);
				});
		},

		bookingTooltip(row) {
			let tooltip = ``;
			if (row && row.candidate) {
				tooltip += `<div>Candidate ID:</div><div class="text-muted">${row.candidate.external_id}</div>`;
			}
			if (row) {
				tooltip += `<div class="mt-2">Booking ID:</div><div class="text-muted">${row.external_id}</div>`;
			}
			return tooltip;
		},

		exportTooltip(schedule) {
			let tooltip = "";
			if (schedule.reExported) {
				tooltip = `<div class="_600">Export Queued</div>`;
				tooltip += `<div class="text-muted">Exports may take several minutes to process; please check back later</div>`;
			} else if (schedule.exported) {
				tooltip = `<div class="_600">Export Successful</div>`;
				tooltip += `<div class="text-muted">${fs.medDate(schedule.exported_at)}</div>`;
				tooltip += `<div class="text-muted mt-2 text-xxs">Click to send export data again</div>`;
				tooltip += `<div class="text-muted text-xxs">(This may take several minutes to update)</div>`;
			} else if (schedule.export_error) {
				tooltip = `<div class="_600">Export Failed</div>`;
				tooltip += `<div class="text-muted">${schedule.export_error}</div>`;
				tooltip += `<div class="text-muted mt-2 text-xxs">Click to attempt export again</div>`;
				tooltip += `<div class="text-muted text-xxs">(This may take several minutes to update)</div>`;
			} else {
				tooltip = `<div class="_600">Not Exported</div>`;
				tooltip += `<div class="text-muted mt-2 text-xxs">Click to export</div>`;
				tooltip += `<div class="text-muted text-xxs">(This may take several minutes to update)</div>`;
			}

			return tooltip;
		},
	},
};
</script>
