<template>
	<div class="d-flex flex-column flex h100 page-body">
		<navbar @login="doReturn" versionCheckRefresh="nocheck"></navbar>
		<div class="app">
			<div id="content" class="app-content box-shadow-3">
				<!-- Optional navbar at top of page -->
				<div class="navbar navbar-light bg-light b-b">
					<div class="d-flex flex-row align-items-center">
						<div class="d-flex flex-row align-items-center">
							<i class="no-select fas fa-eye mr-1" style="padding-top: 2px" />
							<strong v-if="form">
								{{ form.name }}
							</strong>
							<strong v-if="!form">{{ $t("data_description.loading") }}</strong>
						</div>
					</div>

					<div class="d-flex flex-row align-items-center">
						<div
							class="px-2 mr-2 slow-color d-flex flex-row align-items-center"
							v-b-popover
							id="timeout"
							:class="timeoutColor(timeout)"
							style="padding-top: 2px"
						>
							<div
								class="d-flex flex-row align-items-baseline justify-content-center slow-color mr-1"
								:class="timeoutColor(timeout)"
							>
								<h6 class="_600 mb-0">{{ fs.shortDurationMinutes(timeout) }}</h6>
								<span class="text-muted mx-small">m</span>
								<h6 class="_600 mb-0">{{ fs.shortDurationSeconds(timeout) }}</h6>
								<span class="text-muted mx-small">s</span>
							</div>
							<i class="fas fa-clock fa-lg"></i>
						</div>

						<div
							class="exit-button b-l ml-0 d-flex flex-column justify-content-center"
							@click="doReturn()"
							v-tippy="{ placement: 'bottom' }"
							:title="$t('tooltip.exit')"
						>
							<!-- Exit -->
							<i class="fas fa-lg fa-times"></i>
						</div>
					</div>
				</div>

				<div class="content">
					<div
						class="viewer-container"
						:class="{
							'show-sidenav': showRubric,
						}"
					>
						<div
							class="viewer-viewport"
							:class="{ trans: !resizing }"
							id="media"
							:style="{ right: viewportRight, bottom: viewportBottom }"
						>
							<div v-if="!loading" class="score-card-container card box shadow">
								<observation-score-card
									v-if="form"
									:form="form"
									:session="session"
									:scores.sync="scores"
									:showSectionID="getCurrentSectionID()"
									@noShowsChanged="markNoShows"
								/>
							</div>
							<div v-else class="score-card-container card box shadow text-muted text-center py-5">
								<loading type="large" />
							</div>
							<div
								class="faux-scroll-border"
								:class="{ trans: relayouting }"
								:style="{
									right: viewportScrollBorderRight,
									bottom: viewportBottom,
									width: scrollWidth > 0 ? '3px' : 0,
								}"
							></div>
						</div>

						<div
							class="faux-scroll-border"
							:class="{ trans: relayouting }"
							:style="{
								right: resourcesScrollBorderRight,
								bottom: 0,
								width: 0,
							}"
							style="z-index: 3"
						></div>

						<div
							class="viewer-sidenav trans pst-rlt"
							id="sidenav"
							:style="{ width: sidenavWidth + 'px', right: sidenavRight + 'px' }"
						>
							<div v-if="form" class="d-flex flex-column p-2">
								<div v-for="(section, i) in form.sections" :key="section.id">
									<template v-if="section.active || editing">
										<div class="text-md">{{ section.name }}</div>
										<div
											class="card px-2 py-1"
											style="margin-left: -7px; margin-right: -4px"
											v-if="!editing && getSectionRater(section.id)"
										>
											<div>
												{{ getSectionRater(section.id).first_name }}
												{{ getSectionRater(section.id).last_name }}
											</div>
											<div class="text-muted text-xxs">Currently Scoring</div>
										</div>
										<div
											class="card px-2 py-1"
											style="margin-left: -7px; margin-right: -4px"
											v-if="editing"
										>
											<div class="d-flex flex-row align-items-center">
												<div class="flex">
													<div v-if="getSectionRater(section.id)">
														{{ getSectionRater(section.id).first_name }}
														{{ getSectionRater(section.id).last_name }}
													</div>
													<div v-else class="text-muted">
														{{ $t("fs.unknown") }} ({{ section.id }})
													</div>
													<div class="text-muted text-xxs">Scored By</div>
												</div>
												<div>
													<button v-if="section.active" class="btn btn-secondary" disabled>
														Editing
													</button>
													<button v-else class="btn btn-success" @click="goToSectionIndex(i)">
														Edit
													</button>
												</div>
											</div>
										</div>
										<div
											v-if="section.audio_prompts && section.audio_prompts.length"
											class="card px-2 py-1"
											style="margin-left: -7px; margin-right: -4px"
										>
											<table>
												<template v-for="(audio, i) in section.audio_prompts">
													<tr :key="`head-${i}`">
														<td :colspan="audio.variants.length">
															{{ audio.name }}
														</td>
													</tr>
													<tr :key="`row1-${i}`">
														<td
															v-for="(variant, v) in audio.variants"
															:key="v"
															class="text-xxs text-muted text-center"
														>
															{{ variant.name }}
														</td>
													</tr>
													<tr :key="`row2-${i}`">
														<td
															v-for="(variant, v) in audio.variants"
															:key="v"
															class="text-center"
														>
															<i
																class="fa-lg fa-play-circle"
																:class="{
																	[isPlayingAudio(section.id, i, v)
																		? 'fas'
																		: 'far']: true,
																}"
																@click="playAudio(section.id, i, v, variant)"
															/>
														</td>
													</tr>
													<tr :key="`player-${i}`">
														<td :colspan="audio.variants.length">
															<audio
																controls
																class="sidebar-audio"
																v-if="isPlayingAudio(section.id, i, null)"
																:src="isPlayingAudio(section.id, i, null)"
																:id="`audio-player-${section.id}-${i}`"
															/>
														</td>
													</tr>
												</template>
											</table>
										</div>
										<button
											v-if="!editing && i < form.sections.length - 1"
											class="btn btn-success mt-2"
											@click="goToNextSection"
										>
											Complete & Continue
										</button>
										<button
											v-if="!editing && i == form.sections.length - 1"
											class="btn btn-success mt-2"
											@click="goToNextSection"
										>
											Finish
										</button>
									</template>
									<template v-else>
										<div class="d-flex flex-row justify-content-between align-items-center">
											<div class="flex">
												<div class="flex text-muted">{{ section.name }}</div>
												<div v-if="getSectionRater(section.id)" class="text-muted text-xxs">
													{{ getSectionRater(section.id).first_name }}
													{{ getSectionRater(section.id).last_name }}
												</div>
											</div>
											<i
												v-if="section.complete"
												class="fas fa-lg fa-check-circle text-success mr-2"
											/>
										</div>
									</template>
									<hr />
								</div>
							</div>
						</div>

						<!-- Rubric Toggle -->
						<div
							class="rubric-collapse-btn side-btn toggles-overlay"
							@click="toggleRubric()"
							id="rubric-toggle"
							:class="{ show: toggle_hit || resizing, trans: !resizing }"
							:style="{ right: rubricCollapseRight }"
						>
							<i
								v-if="!showRubric"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.show_scoring_controls')"
								class="material-icons"
								>check</i
							>
							<i
								v-if="showRubric"
								v-tippy="{ placement: 'left' }"
								:title="$t('tooltip.hide_scoring_controls')"
								class="material-icons"
								>keyboard_arrow_right</i
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal
			id="startModal"
			v-model="showStartModal"
			@hide="showStartModal = false"
			no-close-on-backdrop
			no-close-on-esc
		>
			<template slot="modal-header">
				<h5 class="modal-title pl-3">Begin Observation</h5>
			</template>

			<div class="pt-3">
				<h6 class="mb-4 text-center">You are about to begin an observation session.</h6>

				<hr />

				<div v-if="form && session" class="d-flex flex-row justify-content-around">
					<div class="text-center mx-2">
						<h5>{{ form.name }}</h5>
						<div class="text-muted">Form</div>
					</div>
					<div class="text-center mx-2">
						<h5>{{ fs.medDate(session.start_time) }}</h5>
						<div class="text-muted">Scheduled Start</div>
					</div>
				</div>

				<hr />

				<div class="modal-scroll" style="max-height: calc(100vh - 350px)">
					<div v-if="session && session.stations" class="card">
						<h6 class="px-3 py-2 mb-0">Candidates</h6>
						<table class="table table-striped mb-0">
							<tbody>
								<tr v-for="(station, i) in session.stations" :key="i">
									<td class="v-mid text-center">
										<h4 class="mb-0">{{ station.sequence }}</h4>
									</td>
									<td class="v-mid">
										<h6 class="mb-0">
											{{ station.candidate.first_name }} {{ station.candidate.last_name }}
										</h6>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<hr />

				<div class="card" v-if="coraters && coraters.length > 0">
					<h6 class="px-3 py-2 mb-0">Primary Rater</h6>
					<table class="table table-striped mb-0">
						<tbody>
							<tr>
								<td class="v-mid">
									<h6 class="mb-0">{{ coraters[0].first_name }} {{ coraters[0].last_name }}</h6>
								</td>
							</tr>
						</tbody>
					</table>
					<template v-if="coraters && coraters.length > 1">
						<h6 class="px-3 py-2 mb-0 b-t">Additional Raters</h6>
						<table class="table table-striped mb-0">
							<tbody>
								<template v-for="(rater, i) in coraters">
									<tr :key="rater.id" v-if="i != 0">
										<td class="v-mid">
											<h6 class="mb-0">{{ rater.first_name }} {{ rater.last_name }}</h6>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</template>
				</div>

				<template v-if="form && form.audio_prompts && form.audio_prompts.length > 0">
					<hr />

					<div class="card px-2 py-1">
						<table>
							<template v-for="(audio, i) in form.audio_prompts">
								<tr :key="`head-${i}`">
									<td :colspan="audio.variants.length">
										<h6>{{ audio.name }}</h6>
									</td>
								</tr>
								<tr :key="`row1-${i}`">
									<td v-for="(variant, v) in audio.variants" :key="v" class="text-muted text-center">
										{{ variant.name }}
									</td>
								</tr>
								<tr :key="`row2-${i}`">
									<td v-for="(variant, v) in audio.variants" :key="v" class="text-center">
										<i
											class="fa-2x fa-play-circle"
											:class="{
												[isPlayingAudio(null, i, v) ? 'fas' : 'far']: true,
											}"
											@click="playAudio(null, i, v, variant)"
										/>
									</td>
								</tr>
								<tr :key="`player-${i}`">
									<td :colspan="audio.variants.length" class="text-center">
										<audio
											controls
											class="sidebar-audio"
											v-if="isPlayingAudio(null, i, null)"
											:src="isPlayingAudio(null, i, null)"
											:id="`audio-player-${i}`"
										/>
									</td>
								</tr>
							</template>
						</table>
					</div>
				</template>
			</div>

			<template slot="modal-footer">
				<button class="mx-auto btn btn-primary btn-lg" @click="beginObservation()">
					{{ $t("buttons.begin") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="nextRaterModal" v-model="showNextRaterModal" @hide="showNextRaterModal = false" hideFooter>
			<template slot="modal-header">
				<h5 class="modal-title pl-3">Choose Next Rater</h5>
			</template>

			<div class="px-3 pt-3" v-if="coraters">
				<div
					v-for="rater in coraters"
					:key="rater.id"
					class="card mb-3 clickable-card text-md px-3 py-2"
					@click="pickNextRater(rater)"
				>
					{{ rater.first_name }} {{ rater.last_name }}
				</div>
			</div>
		</b-modal>
	</div>
</template>


<script>
//Libraries
import _ from "lodash";
import BB from "bluebird";
import Notie from "@/services/NotieService";

//Services
import ObservationService from "@/services/ObservationService";
import TimeoutService from "@/services/TimeoutService";
import fs from "@/services/FormatService";

//Components
import ObservationScoreCard from "@/components/ObservationScoreCard";

export default {
	name: "Observe",

	props: ["user"],
	data() {
		return {
			fs: fs,
			loading: true,
			editing: false,
			resizing: false,
			relayouting: false,
			scrollWidth: 0,
			resourceScrollWidth: 0,
			toggle_hit: false,

			scrollCheckRepeat: null,
			timeoutTimer: null,
			timeout: 0,

			showRubric: true,
			scores: [],
			playingAudio: null,

			sessionID: null,
			session: null,
			form: null,
			noShowIDs: [],

			coraters: [],
			showNextRaterModal: false,
			showStartModal: false,
			nextRater: null,
		};
	},
	components: { ObservationScoreCard },
	created() {
		this.sessionID = this.$route.params.session_id;
		this.editing = this.$route.query.edit;

		this.scrollCheckRepeat = setInterval(this.scrollWidthCheck, 50);
		document.addEventListener("mousemove", this.checkToggleHitbox);

		this.loadForm();
	},

	beforeDestroy() {
		document.removeEventListener("mousemove", this.checkToggleHitbox);
	},

	computed: {
		viewportScrollOffset() {
			if (this.scrollWidth > 0) {
				return this.scrollWidth + 2;
			} else {
				return 0;
			}
		},
		sidenavWidth() {
			return 230;
		},
		sidenavOffset() {
			if (this.showRubric) {
				return this.sidenavWidth;
			} else {
				return 0;
			}
		},
		sidenavRight() {
			if (this.showRubric) {
				return 0;
			} else {
				return this.sidenavWidth * -1;
			}
		},
		viewportRight() {
			return this.sidenavOffset + "px";
		},
		viewportBottom() {
			return "0";
		},
		viewportScrollBorderRight() {
			return this.sidenavOffset + this.scrollWidth + "px";
		},
		resourcesScrollBorderRight() {
			return `calc(${this.splitPercent * -1}vw + ${this.resourceScrollWidth}px)`;
		},
		rubricCollapseRight() {
			let scrollOffset = this.viewportScrollOffset;
			if (this.showResources) {
				scrollOffset = this.resourceScrollOffset;
			}
			return this.sidenavOffset + scrollOffset + "px";
		},
	},

	watch: {
		scores: {
			handler: function (newVal) {
				console.log("Scores changed", newVal);
				ObservationService.saveScores(this.scores)
					.then(() => {
						console.log("Scores saved");
					})
					.catch((e) => {
						console.error(e);
						Notie.error("Failed to save scores", e);
					});
			},
			deep: true,
		},
	},

	methods: {
		startTimer() {
			this.timeoutTimer = setInterval(() => {
				let timeout = this.timeout + 1;
				this.$set(this, "timeout", timeout);
			}, 1000);
		},

		doReturn() {
			this.$router.go(-1);
		},

		loadForm() {
			BB.props({
				session: ObservationService.getSession(this.sessionID),
				scores: ObservationService.getScoresBySession(this.sessionID),
			}).then((r1) => {
				this.session = r1.session.data;
				this.scores = r1.scores.data;
				this.coraters = this.session.raters;

				ObservationService.getForm(this.session.form_id).then((r2) => {
					this.form = r2.data;

					this.initScores(this.form);
					this.initRaters(this.form, this.scores, this.coraters);

					if (this.session.in_progress_section_id) {
						for (let section of this.form.sections) {
							if (section.id == this.session.in_progress_section_id) {
								section.active = true;
							}
						}
						this.startTimer();
					} else {
						this.goToNextSection();
						this.showStartModal = true;
					}
					this.loading = false;
				});
			});
		},

		beginObservation() {
			this.startTimer();
			this.showStartModal = false;
		},

		markNoShows() {
			console.log("Stations changed");
			let noShowIDs = [];
			_.each(this.session.stations, (station) => {
				if (station.no_show) {
					noShowIDs.push(station.candidate_id);
				}
			});
			ObservationService.markNoShows(this.session.id, noShowIDs)
				.then(() => {
					console.log("No-shows saved");
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to save no-shows", e);
				});
		},

		initScores(form) {
			_.each(this.session.stations, (station) => {
				let candScore = _.find(this.scores, { candidate_id: station.candidate.id });
				let newCandScore = false;
				if (!candScore) {
					candScore = {
						session_id: this.session.id,
						candidate_id: station.candidate.id,
						schedule_id: station.schedule.id,
						form_id: this.form.id,
						section_scores: [],
						in_progress_section_id: null,
					};
					newCandScore = true;
				}
				if (!candScore.form_id) {
					candScore.form_id = this.form.id;
				}
				_.each(this.form.sections, (s) => {
					let sectScore = _.find(candScore.section_scores, { section_id: s.id });
					let newSectScore = false;
					if (!sectScore) {
						sectScore = {
							section_id: s.id,
							rater_id: null,
							task_scores: [],
						};
						newSectScore = true;
					}

					_.each(s.tasks, (t) => {
						let taskScore = _.find(sectScore.task_scores, { task_id: t.id });
						if (!taskScore) {
							sectScore.task_scores.push({
								task_id: t.id,
								value: 1,
								codes: [],
							});
						}
					});

					if (newSectScore) {
						candScore.section_scores.push(sectScore);
					}
				});

				if (newCandScore) {
					this.scores.push(candScore);
				}
			});
		},

		initRaters(form, scores, raters) {
			console.log("initRaters", form, scores, raters);
			if (this.scores.length <= 0) {
				// No scores to init
				return;
			}

			for (let s in scores[0].section_scores) {
				if (scores[0].section_scores[s].rater_id) {
					for (let ss in form.sections) {
						if (form.sections[ss].id == scores[0].section_scores[s].section_id) {
							for (let r in raters) {
								if (raters[r].id == this.scores[0].section_scores[s].rater_id) {
									form.sections[ss].rater = raters[r];
								}
							}
						}
					}
				}
			}
		},

		timeoutColor(timeout) {
			let warning_time = 300;
			let danger_time = 600;
			return {
				"text-danger": timeout > danger_time,
				"text-warning": timeout > warning_time && timeout <= danger_time,
			};
		},

		scrollWidthCheck() {
			let viewer = document.getElementById("media");
			let newVal = 0;
			let oldVal = 0;
			if (viewer) {
				//Update scrollbar width
				let newVal = viewer.offsetWidth - viewer.clientWidth;
				let oldVal = this.scrollWidth;
				this.scrollWidth = newVal;
			}
		},

		checkToggleHitbox(event) {
			let x = event.pageX;
			let y = event.pageY;
			if (event.touches && event.touches[0]) {
				x = event.touches[0].clientX;
				y = event.touches[0].clientY;
			}

			let sidenavAndResources = this.sidenavOffset;
			let notes = 0;
			if (this.showResources) {
				let viewportWidth = window.innerWidth - this.sidenavOffset;
				sidenavAndResources += viewportWidth * this.splitRatio;
			}
			if (this.showNotes) {
				let viewportHeight = window.innerHeight - 112;
				notes += viewportHeight * this.notesSplitRatio;
			}

			let xThreshold = window.innerWidth - (sidenavAndResources + 50);
			let yThreshold = window.innerHeight - (notes + 50);

			if (x > xThreshold || y > yThreshold) {
				this.toggle_hit = true;
			} else {
				this.toggle_hit = false;
			}
		},

		toggleRubric() {
			this.showRubric = !this.showRubric;
			// store.set(this, "viewer.showRubric", this.showRubric);
			this.reloayouting = true;
			setTimeout(() => {
				this.relayouting = false;
				this.$forceUpdate();
			}, 201);
		},

		getSectionRater(sectionID) {
			if (!(this.form && this.form.sections && this.form.sections.length)) return null;
			let section = _.find(this.form.sections, { id: sectionID });
			return section ? section.rater : null;
		},

		setSectionRater(sectionID, rater) {
			if (!(this.form && this.form.sections && this.form.sections.length > 0)) return;
			let index = _.findIndex(this.form.sections, { id: sectionID });
			if (index == -1) return;
			this.form.sections[index].rater = rater;
			for (let c in this.scores) {
				for (let s in this.scores[c].section_scores) {
					if (this.scores[c].section_scores[s].section_id == sectionID) {
						this.scores[c].section_scores[s].rater_id = rater.id;
					}
				}
			}
		},

		pickNextRater(rater) {
			this.nextRater = rater;
			this.showNextRaterModal = false;
			this.goToNextSection();
		},

		goToNextSection() {
			if (!(this.form && this.form.sections && this.form.sections.length > 0)) return;

			let index = _.findIndex(this.form.sections, { active: true });
			if (index == -1) {
				this.form.sections[0].active = true;
				this.setSectionRater(this.form.sections[index + 1].id, this.user);
				this.saveSectionProgress(this.form.sections[index + 1].id);
			} else if (index == this.form.sections.length - 1) {
				this.submit();
			} else {
				if (this.coraters && this.coraters.length > 1 && !this.nextRater) {
					this.showNextRaterModal = true;
				} else {
					this.form.sections[index].active = false;
					this.form.sections[index].complete = true;
					this.form.sections[index + 1].active = true;
					if (this.nextRater) {
						this.setSectionRater(this.form.sections[index + 1].id, this.nextRater);
						this.nextRater = null;
					} else {
						this.setSectionRater(this.form.sections[index + 1].id, this.user);
					}
					this.saveSectionProgress(this.form.sections[index + 1].id);

					this.scrollCardToTop();

					this.$forceUpdate();
				}
			}
		},

		goToSectionIndex(index) {
			for (let i in this.form.sections) {
				if (index == i) {
					this.form.sections[i].active = true;
				} else {
					this.form.sections[i].active = false;
				}
			}
		},

		scrollCardToTop() {
			let el = document.getElementById("media");
			if (!el) {
				console.error("Failed to scroll media viewport");
				return;
			}

			el.scrollTop = 0;
		},

		saveSectionProgress(sectionID) {
			ObservationService.saveSectionProgress(this.session.id, sectionID)
				.then(() => {
					console.log("Section updated to", sectionID);
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to update section progress", e);
				});
		},

		getCurrentSectionID() {
			if (!this.form) return null;
			let section = _.find(this.form.sections, { active: true });
			if (!section) return null;
			return section.id;
		},

		submit() {
			ObservationService.completeSession(this.session.id)
				.then(() => {
					console.log("Session completed");
					Notie.success("Session completed");
					this.doReturn();
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to complete session", e);
				});
		},

		playAudio(sectionID, index, vindex, variant) {
			console.log("PLAY AUDIO", sectionID, index, variant);
			this.playingAudio = {
				sectionID: sectionID,
				vindex: vindex,
				index: index,
				name: variant.name,
				url: variant.url,
			};
			this.$forceUpdate();
			this.$nextTick(() => {
				let audioEl = null;
				if (sectionID) {
					audioEl = document.getElementById(`audio-player-${sectionID}-${index}`);
				} else {
					audioEl = document.getElementById(`audio-player-${index}`);
				}
				console.log("AUDIO PLAYER EL", audioEl);
				audioEl.play();
			});
		},

		stopPlayingAudio() {
			this.playingAudio = null;
		},

		isPlayingAudio(sectionID, index, vindex) {
			if (!this.playingAudio) return null;

			if (vindex) {
				if (
					this.playingAudio.vindex == vindex &&
					this.playingAudio.index == index &&
					this.playingAudio.sectionID == sectionID
				) {
					return this.playingAudio.url;
				}
				return null;
			} else {
				if (this.playingAudio.index == index && this.playingAudio.sectionID == sectionID) {
					return this.playingAudio.url;
				}
				return null;
			}

			return null;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.score-card-container {
	margin: 2rem;
}

.sidebar-audio {
	width: 270px;
	transform: scale(0.75);
	margin-left: -40px;
	margin-right: -40px;
}

.rubric-col {
	background-color: white;
	width: 230px;
}

.viewer-container {
	border-top: 1px solid #d9d9d9;
	position: fixed;
	top: 112px;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #f5f5f5;
}

/* Default state, viewer is full width, rubric and resources are right:(their width) */

.viewer-viewport {
	position: absolute;
	display: inline;
	overflow-y: auto;
	overflow-x: auto;
	left: 0;
	top: 0;
	z-index: 1;
	/* background-color: #f5f5f5; */
}

.faux-scroll-border {
	position: fixed;
	top: 113px;
	width: 3px;
	background-color: #eeeeee;
	border-left: 1px solid rgba(120, 130, 140, 0.13);
	pointer-events: none;
	z-index: 1;
}

.viewport-border {
	margin-right: 2px;
	border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.viewport-media {
	margin-bottom: 20px;
}

.resources {
	position: absolute;
	height: 100%;
	bottom: 0;
	top: 0;
	width: calc(50vw);
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	box-shadow: inset 6px 0px 10px -4px rgba(184, 182, 184, 1);
}

.resources > .resources-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.resources-container {
	z-index: 2;
}

.viewer-sidenav {
	position: absolute;
	width: 230px;
	height: 100%;
	bottom: 0;
	right: -230px;
	top: 0;
	padding-left: 10px;
	padding-right: 0px;
	padding-top: 5px;

	overflow: auto;
	background-color: #eeeeee;
}

.side-btn {
	height: 25px;
	width: 25px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	position: absolute;
	cursor: pointer;
	z-index: 1000000000000000;
}

.bottom-btn {
	margin-bottom: -1px;
	margin-right: 25px;
	height: 25px;
	width: 25px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	position: absolute;
	cursor: pointer;
	z-index: 19;
}

.resize-btn {
	height: 28px;
	top: calc(50% - 14px);
	width: 12px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-bottomleft: 3px;
	-moz-border-radius-topright: 3px;
	-moz-border-radius-bottomright: 3px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	position: absolute;
	cursor: grab;
	z-index: 1000000000000000;
	visibility: hidden;
}
.resize-btn > i {
	font-size: 16px;
	margin-left: -3px;
	line-height: 26px;
}
.show-resources > .resize-btn {
	visibility: visible;
}

.resize-btn-h {
	height: 12px;
	left: calc(50% - 14px);
	width: 28px;
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	-webkit-border-top-left-radius: 3px;
	-webkit-border-bottom-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-bottomleft: 3px;
	-moz-border-radius-topright: 3px;
	-moz-border-radius-bottomright: 3px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	position: absolute;
	cursor: grab;
	z-index: 1000000000000000;
	visibility: hidden;
}
.resize-btn-h > i {
	font-size: 16px;
	margin-left: 5px;
	margin-top: -3px;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	position: absolute;
}
.show-notes > .resize-btn-h {
	visibility: visible;
}

.rubric-collapse-btn {
	right: 0;
	top: 10px;
}

.resource-collapse-btn {
	right: 0;
	top: 42px;
}

.exemplar-collapse-btn {
	right: 0;
	top: 74px;
}

.toggles-hitbox {
	position: absolute;
	right: 0;
	top: 0;
	width: 45px;
	height: 100%;
	opacity: 0;
	/* This was breaking the scrollbar */
	/* z-index: 1000000000000001; */
	z-index: -1;
}

.notes-container {
	position: absolute;
	height: 100%;
	left: 0;
	height: 200px;
	max-height: calc(100vh - 112px);

	border-top: 1px solid #d9d9d9;
	background-color: #eeeeee;
	box-shadow: inset 0px -6px 10px -4px rgba(184, 182, 184, 1);
}

/* When rubric is showing */

/* .show-sidenav > .viewer-sidenav {
	right: 0;
}
.show-sidenav.show-res > .viewer-sidenav {
	width: 460px;
}
.viewer-sidenav.show-res {
	bottom: 0;
	right: -460px;
} */

.trans {
	-webkit-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

	-webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.viewer-viewport::-webkit-scrollbar,
.resources-container::-webkit-scrollbar {
	position: absolute;
	z-index: -1;
	width: 13px;
	height: 13px;
}
.viewer-viewport::-webkit-scrollbar-thumb,
.resources-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

.viewer-viewport > .essay,
.resources > .essay {
	margin-left: auto;
	margin-right: auto;
	/*margin-bottom: 70px;*/

	border: none;
}

/* .prevScores {
	overflow: scroll;
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 800px;
	width: 80%;

	padding: 10px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
} */
.prevScores {
	/* position: absolute;
	top: 10px;
	left: 10px; */
}

/*background-color: #78909c;*/
/* } */

.viewer-viewport::-webkit-scrollbar {
	background-color: #eeeeee;
}

::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	padding-left: 4px;
}
::-webkit-scrollbar-corner {
	background-color: #eeeeee;
}

.loader {
	font-size: 128px;
	text-align: center;
}

.md-fab-sm {
	height: 40px !important;
	width: 40px !important;
	padding: 0px;
}

.exit-button {
	margin: -8px -16px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}
.exit-button:hover,
.exit-button.show-print {
	background-color: rgba(0, 0, 0, 0.075);
}
.exit-button a {
	line-height: 56px;
}
.slow-color {
	transition: color 10s;
}
.dropdown-toggle {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}

.r-controls-overlay {
	/* height: 0; */
	position: absolute;
	left: 20px;
	top: 20px;
	opacity: 0;
	z-index: 20;
}
.e-controls-overlay {
	/* height: 0; */
	position: absolute;
	left: 10px;
	top: 60px;
	opacity: 0;
	z-index: 20;
}
.resources:hover .r-controls-overlay,
.resources:hover .e-controls-overlay {
	opacity: 1;
	height: auto;
}
.r-controls-overlay button,
.e-controls-overlay button {
	display: block;
	font-size: 20px;
	line-height: 2;
}
.r-controls-overlay .dropdown-item.active,
.e-controls-overlay .dropdown-item.active {
	color: inherit;
	background-color: rgba(0, 0, 0, 0.1);
}

.controls-overlay {
	opacity: 0;
	z-index: 20;
}
.viewer-viewport:hover ~ .controls-overlay,
.controls-overlay:hover {
	opacity: 1;
}

.toggles-overlay {
	opacity: 0;
}

.viewer-sidenav:hover ~ .toggles-overlay,
.resources:hover ~ .toggles-overlay,
.viewer-container:not(.show-sidenav):not(.show-resources) .toggles-overlay,
.toggles-overlay.show {
	opacity: 1;
}

@media (hover: none) {
	.toggles-overlay,
	.controls-overlay,
	.tabs-overlay {
		opacity: 1;
	}
}

/* .nav-tabs {
	flex-wrap: nowrap;
} */
.nav-item {
	background-color: #ffffff;
	min-width: 50px;
	overflow: hidden;
	white-space: nowrap;
	border-bottom: 1px solid rgba(120, 130, 140, 0.13);
	border-right: 1px solid rgba(120, 130, 140, 0.13);
	flex: 1 1 50px;
	text-align: center;
}
.nav-item > a {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.set-nav-footer {
	margin-left: -8px;
	/* margin-right: -10px; */
	background-color: rgb(231, 231, 231);
	border-top-left-radius: 0.5rem;
}

.calc-box {
	padding-top: auto;
	position: absolute;
	top: 0px;
	pointer-events: none;
	z-index: 2;
}

.nav-current {
	border: 3px solid lightblue;
}

.nav-clickable {
	cursor: pointer;
}
.nav-clickable:hover {
	background-color: #eeeeee;
}
.nav-clickable:active {
	box-shadow: 0 0 0 2px lightblue inset;
}

.rubric-next-btn.faux-disabled {
	color: rgba(0, 0, 0, 0.26);
	background-color: transparent;
}

.rubric-prev-btn {
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
}
.rubric-prev-btn > i {
	font-size: 18px;
	margin-top: -3px;
}

.clickable-card {
	transition: background-color 0.15s ease-in-out;
	cursor: pointer;
}
.clickable-card:hover {
	background-color: rgba(0, 0, 0, 0.03);
}
.clickable-card.active {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(83, 166, 250, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(83, 166, 250, 0.25);
	border-color: #53a6fa;
}
</style>
