<template>
	<div class="d-flex flex-row align-items-center position-relative">
		<div class="volume-indicator-border" :class="{ active: participant.speaking }" />
		<div class="meeting-avatar-small ml-2 my-1">
			<h6 class="mb-0">{{ participant.initials }}</h6>
		</div>
		<div class="mx-2 flex overflow-hidden">
			<div>{{ participant.name }}</div>
			<div v-if="!participant.muted" class="volume-bar-container">
				<div class="volume-bar">
					<div class="volume-bar-tick" />
					<i class="volume-icon-overlay far fa-volume mr-1" />
					<div class="volume-bar-progress" :style="{ width: `${Math.ceil(participant.volumeLevel)}%` }"></div>
				</div>
			</div>
		</div>
		<div v-if="participant.handUp" class="mr-1 icon-container">
			<i class="fas text-warning fa-lg fa-hand-paper" v-tippy :title="$t('VideoMeeting.hand_is_raised')" />
		</div>
		<div class="mr-1 icon-container">
			<i
				v-if="!participant.muted"
				class="far text-theme fa-lg fa-microphone"
				v-tippy
				:title="$t('VideoMeeting.transmitting_audio')"
			/>
			<i
				v-else
				class="far text-muted fa-lg fa-microphone-slash"
				v-tippy
				:title="$t('VideoMeeting.not_transmitting_audio')"
			/>
		</div>
		<div class="icon-container" :class="{ [canAdmin ? 'mr-1' : 'mr-2']: true }">
			<!-- <i
				v-if="participant.screensharing"
				class="fas text-primary fa-lg fa-presentation"
				v-tippy
				:title="$t('VideoMeeting.sharing_screen')"
			/> -->
			<i
				v-if="participant.video"
				class="far text-theme fa-lg fa-video"
				v-tippy
				:title="$t('VideoMeeting.transmitting_video')"
			/>
			<i
				v-else
				class="far text-muted fa-lg fa-video-slash"
				v-tippy
				:title="$t('VideoMeeting.not_transmitting_video')"
			/>
		</div>
		<div v-if="participant.role == 'admin'" class="mr-1 icon-container">
			<span
				class="mb-1 badge badge-success"
				style="line-height: 1.2; width: 23px"
				v-tippy
				:title="$t('MeetingCalendar.admin')"
			>
				<i class="fas fa-user-lock" />
			</span>
		</div>
		<div v-else-if="canAdmin" class="mr-1 icon-container">
			<a :id="`participant-action-button-${participant.id}`" class="message-action-button" @click="selectParti">
				<i class="fas fa-ellipsis-v" />
			</a>
		</div>
	</div>
</template>

<style scoped>
.icon-container {
	width: 24px;
	text-align: center;
}

.volume-bar-container {
	margin-bottom: 4px;
}
.volume-bar {
	border: 1px solid rgba(70, 90, 110, 0.85);
}
.volume-bar-progress {
	opacity: 1;
	background-color: rgba(70, 90, 110, 0.85);
}
.volume-bar-tick {
	border-left: 1px solid rgba(70, 90, 110, 0.85);
}

.volume-indicator-border {
	position: absolute;
	border-right: 4px solid #0c9dfd;
	border-radius: 8px;
	left: 0;
	top: 4px;
	bottom: 4px;
	opacity: 0;
	transition: opacity 0.25s;
}
.volume-indicator-border.active {
	opacity: 0.75;
}

.message-action-button {
	width: 23px;
	height: 23px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.5;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 0.25s;
}
.message-action-button:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.hover-highlight {
	cursor: pointer;
}
.hover-highlight:hover {
	background-color: rgba(0, 0, 0, 0.05);
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import Notie from "@/services/NotieService";

export default {
	name: "ParticipantListRow",
	props: {
		engine: true,
		participant: true,
		canAdmin: true,
	},
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},

	watch: {},

	computed: {},

	methods: {
		selectParti() {
			this.$emit("popover", this.participant);
		},
	},
};
</script>
