<template>
	<v-select
		:options="labels"
		@search="searchLabels"
		label="name"
		@input="emitLabels"
		:value="value"
		multiple
		:taggable="taggable"
		:placeholder="placeholder"
	>
		<template slot="no-options" slot-scope="option">
			<span>{{ $t("config_select.search_labels") }}</span>
			<div v-if="taggable">Press enter to confirm new label</div>
		</template>

		<!-- <template slot="option" slot-scope="option">
			<span>{{display(option)}}</span>
		</template>

		<template slot="selected-option" slot-scope="option">
			<span>{{display(option)}}</span>
		</template> -->
	</v-select>
</template>

<style scoped>
</style>

<script>
import AuditingService from "@/services/AuditingService";

export default {
	name: "ConfigLabelSearch",

	props: {
		value: true,
		placeholder: true,
		selectedIDs: true,
		selectAll: true,
		query: true,
		project: true,
		taggable: {
			type: Boolean,
			default: false,
		},
		candidates: {
			type: Boolean,
			default: false,
		},
	},

	components: {},

	data() {
		return {
			labels: [],
		};
	},
	created() {},
	watch: {},
	computed: {},
	methods: {
		searchLabels(search, loading) {
			if (this.query) {
				let selection = {
					selected_ids: this.selectedIDs,
					select_all: this.selectAll,
					query: this.query,
					filter: search,
				};
				let call = AuditingService.getResponseLabels;
				if (this.candidates) {
					console.log("DETOUR");
					call = AuditingService.getCandidateLabels;
				}
				call(selection)
					.then((resp) => {
						this.labels = resp.data;
					})
					.catch((err) => {
						console.log(err);
						this.users = [{ name: "Error loading labels" }];
					});
			} else if (this.project) {
				AuditingService.getProjectResponseLabels(this.project.id)
					.then((resp) => {
						this.labels = resp.data;
					})
					.catch((err) => {
						console.log(err);
						this.users = [{ name: "Error loading labels" }];
					});
			} else {
				console.error("Cannot search labels with no query or project");
			}
		},

		emitLabels(labels) {
			if (!labels) {
				this.labels = [];
			}
			this.$emit("input", labels);
		},

		display(option) {
			let str = "";
			_.each(option, (o) => {
				str += o;
			});
			return str;
		},
	},
};
</script>
