<template>
	<b-modal :visible="visible" :title="$t('Auditing.escalate_rangefinding')" hide-footer hide-header-close>
		<div class="modal-body">
			<h6>
				{{
					$t("Auditing.escalate_num_rangefinding", {
						num: numSelected,
						r: numSelected == 1 ? "response" : "responses",
					})
				}}
			</h6>
			<div class="form-group mt-4">
				<label>{{ $t("Auditing.set") }}</label>
				<config-select :options="rfSets" v-model="rfSet"></config-select>
			</div>
		</div>
		<div class="modal-footer">
			<button v-if="!escalating" class="btn btn-flat btn-primary" @click="close">
				{{ $t("buttons.cancel") }}
			</button>
			<button v-if="!escalating" @click="escalateToRF" class="btn btn-flat theme">
				{{ $t("buttons.escalate") }}
			</button>
			<button v-if="escalating" class="btn btn-flat btn-primary" data-dismiss="modal" disabled>
				{{ $t("buttons.cancel") }}
			</button>
			<button v-if="escalating" class="btn btn-flat theme" disabled>
				<loading type="icon" />
			</button>
		</div>
	</b-modal>
</template>

<script>
import AuditingService from "@/services/AuditingService";
import QCConfigService from "@/services/QCConfigService";
import Notie from "@/services/NotieService";

import ConfigSelect from "../ConfigSelect";

export default {
	name: "RangeFindingModal",

	components: {
		ConfigSelect,
	},

	props: {
		visible: Boolean,
		selectedIDs: Array,
		selectAll: Boolean,
		query: Object,
		sectionId: String,
		itemId: String,
	},

	data() {
		return {
			escalating: false,
			rfSet: null,
			rfSets: [],
		};
	},

	watch: {
		visible(isVisible) {
			if (isVisible) this.openConfirmEscalateToRF();
		},
	},

	methods: {
		openConfirmEscalateToRF() {
			QCConfigService.listQCSets(this.sectionId, this.itemId, 5).then((resp) => {
				this.rfSets = resp.data;
				this.rfSets = _.sortBy(this.rfSets, "sequence");
				this.rfSets.unshift({ name: "☆ Pool", active: true, id: "" });
			});
		},

		escalateToRF() {
			let selection = {
				selected_ids: this.selectedIDs,
				num_selected: this.numSelected,
				select_all: this.selectAll,
				query: this.query,
			};
			let setID = null;
			if (this.rfSet && this.rfSet.id != "") {
				setID = this.rfSet.id;
			}

			this.escalating = true;
			AuditingService.escalateToRF(selection, setID)
				.then((resp) => {
					Notie.success(this.$i18n.t("notie.escalated_to_rangefinding"));
					this.$emit("onSubmit");
					this.escalating = false;
					this.close();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.failed_to_escalate_to_rangefinding"), err);
					this.escalating = false;
					this.close();
				});
		},

		close() {
			this.$emit("cancel");
		},
	},

	computed: {
		numSelected() {
			return this.selectedIDs.length;
		},
	},
};
</script>
