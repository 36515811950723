import axios from "axios";
import moment from "moment"

export default {
	getIncomingCounts() {
		return axios.get(`/v1/admin_stats/incoming`);
	},

	getIncomingBreakdown(startTime, endTime) {
		let ctx = {}
		if (startTime) {
			ctx.start = moment(startTime).toISOString(true)
		}
		if (endTime) {
			ctx.end = moment(endTime).toISOString(true)
		}

		return axios.get(`/v1/admin_stats/incoming/breakdown`, { params: ctx });
	},

	getIncomingClientBreakdown(clientID, startTime, endTime) {
		let ctx = {}
		if (startTime) {
			ctx.start = moment(startTime).toISOString(true)
		}
		if (endTime) {
			ctx.end = moment(endTime).toISOString(true)
		}

		return axios.get(`/v1/admin_stats/incoming/breakdown/${clientID}`, { params: ctx });
	},

	getScoredCounts() {
		return axios.get(`/v1/admin_stats/scored`);
	},

	getScoredBreakdown(startTime, endTime) {
		let ctx = {}
		if (startTime) {
			ctx.start = moment(startTime).toISOString(true)
		}
		if (endTime) {
			ctx.end = moment(endTime).toISOString(true)
		}

		return axios.get(`/v1/admin_stats/scored/breakdown`, { params: ctx });
	},

	getScoredClientBreakdown(clientID, startTime, endTime) {
		let ctx = {}
		if (startTime) {
			ctx.start = moment(startTime).toISOString(true)
		}
		if (endTime) {
			ctx.end = moment(endTime).toISOString(true)
		}

		return axios.get(`/v1/admin_stats/scored/breakdown/${clientID}`, { params: ctx });
	},

	getExportedCounts() {
		return axios.get(`/v1/admin_stats/exported`);
	},

	getExportedBreakdown(startTime, endTime) {
		let ctx = {}
		if (startTime) {
			ctx.start = moment(startTime).toISOString(true)
		}
		if (endTime) {
			ctx.end = moment(endTime).toISOString(true)
		}

		return axios.get(`/v1/admin_stats/exported/breakdown`, { params: ctx });
	},

	getExportedClientBreakdown(clientID, startTime, endTime) {
		let ctx = {}
		if (startTime) {
			ctx.start = moment(startTime).toISOString(true)
		}
		if (endTime) {
			ctx.end = moment(endTime).toISOString(true)
		}

		return axios.get(`/v1/admin_stats/exported/breakdown/${clientID}`, { params: ctx });
	},

	getCurrentlyScoring() {
		return axios.get(`/v1/admin_stats/currently_scoring`);
	},

	getCurrentlyScoringBreakdown() {
		return axios.get(`/v1/admin_stats/currently_scoring/breakdown`);
	},

	getInstances() {
		return axios.get(`/v1/admin_stats/instances`);
	},

	getInstanceStats(instanceID, startTime, endTime) {
		let ctx = {}
		if (startTime) {
			ctx.start = moment(startTime).toISOString(true)
		}
		if (endTime) {
			ctx.end = moment(endTime).toISOString(true)
		}

		return axios.get(`/v1/admin_stats/instance/${instanceID}/stats`, { params: ctx });
	},

	getRunningJobs() {
		return axios.get(`/v1/admin_stats/running_jobs`)
	}
};
