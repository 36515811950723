<template>
	<b-modal id="meetingEventModal" :visible="showModal" @hide="hide" :size="editing ? 'lg' : 'md'">
		<template slot="modal-header">
			<h5 v-if="!editing" class="modal-title pl-3">
				{{ $t("MeetingCalendar.meeting_details") }}
			</h5>
			<h5 v-else class="modal-title pl-3">
				{{ $t("MeetingCalendar.edit_meeting") }}
			</h5>
		</template>

		<div v-if="meeting" class="row modal-scroll" style="margin-top: -1rem; margin-bottom: -1rem">
			<div class="py-3" :class="{ 'col-7': editing, 'col-12': !editing }">
				<div class="row">
					<div v-if="!editing" class="col-9">
						<h4 class="mt-1 mb-0">{{ name }}</h4>
						<div>{{ start.format(timeFormat) }} to {{ end.format(timeFormat) }}</div>
					</div>
					<div v-else class="col-9">
						<label>{{ $t("MeetingCalendar.meeting.name") }}</label>
						<input ref="name" class="form-control" v-model="meeting.name" />
						<config-date v-model="meeting.start_time" class="mt-2" />
						<div class="ml-2">to</div>
						<config-date v-model="meeting.end_time" :invalid="duration <= 0" />
						<small v-if="duration <= 0" class="text-danger">
							{{ $t("MeetingCalendar.duration_warning") }}
						</small>
					</div>
					<div class="col-3 align-self-start d-flex flex-row justify-content-end">
						<div class="date-square border">
							<div class="_600 text-theme">{{ start.format("MMM") }}</div>
							<div class="_600 text-lg" style="margin-top: -6px; margin-bottom: -6px">
								{{ start.format("D") }}
							</div>
						</div>
					</div>
					<div v-if="editing" class="mt-2 col-12">
						<div class="d-flex flex-row align-items-center mr-2">
							<label class="ui-switch ui-switch-md theme-accent" v-tippy :title="joinLinkTooltip()">
								<input type="checkbox" :checked="meeting && meeting.password" @click="toggleJoinLink" />
								<i></i>
							</label>
							<span class="ml-2">
								{{ $t("MeetingCalendar.join_link") }}
							</span>
						</div>
						<div v-if="meeting && meeting.password" class="mt-2">
							<CopyInput :readonly="true" :value="getLink(meeting.password)" />
						</div>
					</div>
				</div>

				<template v-if="!loading">
					<template v-if="!editing && meeting && meeting.password">
						<hr style="margin-left: -15px; margin-right: -15px" />

						<label class="mb-0"><i class="fas fa-link mr-1" />External Join Link</label>
						<div class="mt-2">
							<CopyInput :readonly="true" :value="getLink(meeting.password)" />
						</div>
					</template>

					<hr style="margin-left: -15px; margin-right: -15px" />

					<template v-if="editing">
						<div class="flex box-body light lt">
							<RichText
								:initial="descriptionInit"
								:text.sync="meeting.description"
								class="mb-0"
								:options="richTextOptions"
							/>
						</div>
					</template>
					<template v-else-if="meeting.description">
						<h5 style="margin-top: -8px">{{ $t("MeetingCalendar.description") }}:</h5>
						<div class="p-3 shadow-inset" style="border-radius: 8px" v-html="meeting.description" />
					</template>
					<template v-else>
						<div class="my-3 d-flex flex-row justify-content-center">
							<h4 class="text-very-muted mb-0">No description</h4>
						</div>
					</template>

					<template v-if="!editing">
						<hr style="margin-left: -15px; margin-right: -15px" />

						<h5 class="mb-0 clickable" @click="showPartis = !showPartis" :class="{ 'mb-2': showPartis }">
							{{
								$tc("MeetingCalendar.num_participants", meeting.participants.length, {
									num: meeting.participants.length,
								})
							}}<i class="fas fa-angle-left ml-2 anim-rotate" :class="{ 'rotate-90': showPartis }" />
						</h5>
						<div v-if="showPartis" class="d-flex flex-row flex-wrap" style="margin-bottom: -4px">
							<div
								v-for="parti in partis"
								:key="parti.user_id"
								class="d-flex flex-row align-items-center my-1"
								style="width: 50%"
							>
								<div class="meeting-avatar-small">{{ fs.initials(parti.name) }}</div>
								<div class="ml-2">
									<div class="_600 d-flex flex-row align-items-center">
										<span
											v-if="parti.role == 'admin'"
											class="mr-1 badge badge-success"
											style="line-height: 1.2"
											v-tippy
											:title="$t('MeetingCalendar.admin')"
										>
											<i class="fas fa-user-lock" />
										</span>
										{{ parti.name }}
									</div>
									<div v-if="parti.scorer_id" class="text-muted" style="margin-top: -4px">
										{{ parti.scorer_id }}
									</div>
								</div>
							</div>
						</div>
					</template>
				</template>
				<loading v-else type="page" />
			</div>
			<div v-if="editing" class="col-5 py-3 border-left">
				<div class="row">
					<div class="col-12 mb-2">
						<label>{{ $t("MeetingCalendar.add_users_to_meeting") }}</label>
						<div class="d-flex flex-row align-items-center">
							<config-user-search
								class="flex"
								:clientID="user.client && user.client.id"
								v-model="addUsers"
								includeTeams
								multiple
								placeholder="Search to add users..."
								:exclude="partis.map((o) => o.user_id)"
							/>
							<button
								v-if="addUsers"
								class="ml-2 btn btn-success btn-sm btn-icon btn-rounded"
								v-tippy
								:title="$t('MeetingCalendar.invite_these_users')"
								@click="addParticipants"
							>
								<i class="fas fa-plus" />
							</button>
						</div>
					</div>
					<div v-if="meeting.participants && meeting.participants.length > 0" class="col-12">
						<div
							v-for="(parti, i) in partis"
							:key="parti.user_id"
							class="d-flex flex-row align-items-center py-1"
							:class="{ 'b-t': i > 0 }"
						>
							<div class="meeting-avatar-small">{{ fs.initials(parti.name) }}</div>
							<div class="flex ml-2">
								<div class="_600 d-flex flex-row align-items-center">
									<span
										v-if="parti.role == 'admin'"
										class="mr-1 badge badge-success"
										style="line-height: 1.2"
										v-tippy
										:title="$t('MeetingCalendar.admin')"
									>
										<i class="fas fa-user-lock" />
									</span>
									{{ parti.name }}
								</div>
								<div v-if="parti.scorer_id" class="text-muted" style="margin-top: -4px">
									{{ parti.scorer_id }}
								</div>
							</div>
							<div v-if="parti.user_id != user.id" class="ml-2">
								<a class="parti-action-button" data-toggle="dropdown">
									<i class="fas fa-ellipsis-v" />
								</a>
								<div class="dropdown-menu dropdown-menu-right py-0 animate fadeIn">
									<a class="dropdown-item" @click="removeParti(parti)">
										<i class="far fa-trash"></i>
										<span>{{ $t("MeetingCalendar.remove_from_meeting") }}</span>
									</a>
									<a v-if="parti.role != 'admin'" class="dropdown-item" @click="promoteToAdmin(parti)">
										<i class="far fa-user-plus"></i>
										<span>{{ $t("MeetingCalendar.promote_to_admin") }}</span>
									</a>
									<a
										v-if="parti.role == 'admin' && parti.user_id != user.id"
										class="dropdown-item"
										@click="demoteFromAdmin(parti)"
									>
										<i class="far fa-user-minus"></i>
										<span>{{ $t("MeetingCalendar.demote_from_admin") }}</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<loading v-else type="page" />

		<template slot="modal-footer">
			<button v-if="!editing" class="btn btn-secondary mr-auto" @click="close">
				{{ $t("buttons.close") }}
			</button>
			<button v-if="editing && meeting && meeting.id" class="btn btn-danger mr-auto" @click="deleteMeeting">
				<i class="fas fa-trash mr-2" />{{ $t("MeetingCalendar.meeting.delete") }}
			</button>
			<div class="d-flex flex-row">
				<button v-if="editing" class="btn mr-2" @click="cancelEdit">
					{{ $t("buttons.cancel") }}
				</button>
				<template v-if="editing">
					<button
						v-if="!saving"
						class="btn"
						:class="{ 'btn-success': dirty }"
						:disabled="!dirty || !canSave"
						@click="saveMeeting"
					>
						{{ $t("MeetingCalendar.save") }}
					</button>
					<button v-else class="btn btn-success" :disabled="true">
						<loading type="icon" class="mr-1" />
						{{ $t("MeetingCalendar.saving") }}
					</button>
				</template>
				<button v-if="canEdit && !editing" class="btn btn-success mr-2" @click="startEdit" :disabled="loading">
					<i class="fas fa-pencil mr-1" />{{ $t("MeetingCalendar.meeting.edit") }}
				</button>
				<template v-if="!editing">
					<button v-if="Chime.inMeeting()" class="btn btn-primary position-relative" disabled>
						<i class="fas fa-address-card mr-1" />{{ $t("MeetingCalendar.join_meeting") }}
						<div class="disabled-mouseover" v-tippy :title="$t('MeetingCalendar.already_in_meeting')"></div>
					</button>
					<button
						v-else-if="VMS.meetingInProgress(meeting)"
						class="btn btn-primary"
						@click="joinMeeting"
						:disabled="!canJoinMeeting"
					>
						<i class="fas fa-address-card mr-1" />{{ $t("MeetingCalendar.join_meeting") }}
					</button>
					<button
						v-else-if="canJoinMeeting"
						class="btn btn-primary-outline"
						@click="joinMeeting"
						v-tippy
						:title="$t('MeetingCalendar.start_meeting_early')"
					>
						<i class="fas fa-address-card mr-1" />{{ $t("MeetingCalendar.start_meeting") }}
					</button>
					<button v-else class="btn btn-primary position-relative" disabled>
						<i class="fas fa-address-card mr-1" />{{ $t("MeetingCalendar.join_meeting") }}
						<div class="disabled-mouseover" v-tippy :title="$t('MeetingCalendar.meeting_not_available')"></div>
					</button>
				</template>
			</div>
		</template>
	</b-modal>
</template>

<style scoped>
.w-select {
	min-width: 160px;
}
.w-dates {
	width: 150px;
}
.w-time {
	width: 100px;
}

a.btn-subtle {
	margin-top: -6px;
	margin-bottom: -6px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.cal-popup {
	width: 300px;
	contain: none !important;
}
.cal-popup > .card {
	contain: none !important;
}
.box-body {
	margin: -15px;
}
.align-switch-bottom {
	margin-bottom: 6px;
}
.date-square {
	padding: 0.25rem 0.5rem;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.parti-action-button {
	width: 30px;
	height: 30px;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 0.25s;
}
.parti-action-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
</style>

<script>
import ConfigDate from "@/components/ConfigDate";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import RichText from "@/components/RichText";
import CopyInput from "@/components/CopyInput";

import VideoMeetingService from "@/services/VideoMeetingService";
import Chime from "@/vues/VideoMeeting/services/ChimeEngine";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import moment from "moment";
import BB from "bluebird";
import Utils from "@/services/Utils";

export default {
	name: "MeetingCalendarEventModal",

	props: ["user", "event"],

	components: {
		ConfigDate,
		ConfigUserSearch,
		RichText,
		CopyInput,
	},

	data() {
		return {
			Chime: Chime,
			fs: fs,
			timeFormat: fs.timeFormat(),
			VMS: VideoMeetingService,

			richTextOptions: {
				toolbar: [
					["style", ["bold", "italic", "underline", "strikethrough", "clear"]],
					["fontsize", ["fontsize"]],
					["para", ["ul", "ol", "paragraph"]],
					["meta", ["undo", "redo"]],
					["insert", ["picture", "link", "video", "table", "hr", "mathEq"]],
				],
				popover: {
					image: [
						["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
						["float", ["floatLeft", "floatRight", "floatNone"]],
						["remove", ["removeMedia"]],
					],
					link: [["link", ["linkDialogShow", "unlink"]]],
					table: [
						["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
						["delete", ["deleteRow", "deleteCol", "deleteTable"]],
					],
					air: [
						["color", ["color"]],
						["font", ["bold", "underline", "clear"]],
						["para", ["ul", "paragraph"]],
						["table", ["table"]],
						["insert", ["link", "picture"]],
					],
				},
				height: 200,
				placeholder: this.$i18n.t("rich_text.placeholder"),
			},

			unwatch: null,
			dirty: false,

			loading: false,
			saving: false,
			origMeeting: null,
			meeting: null,
			meetingUsers: null,
			editing: false,
			startTime: null,
			addUsers: null,
			descriptionInit: null,
			showPartis: false,
			usePassword: false,
		};
	},

	created() {
		if (this.event.id) {
			this.loadMeeting();
		} else {
			this.createNewMeeting();
		}
	},

	computed: {
		showModal() {
			return this.event ? true : false;
		},

		canEdit() {
			let selfParti = this.meeting && _.find(this.meeting.participants, { user_id: this.user.id });
			return this.user.role.manage_all_video_meetings || (selfParti && selfParti.role == "admin");
		},

		canJoinMeeting() {
			if (!this.meeting) return false;

			if (Chime.inMeeting()) return false;

			let selfParti = _.find(this.meeting.participants, { user_id: this.user.id });
			this.debug("canJoinMeeting selfParti", selfParti);
			if (selfParti && selfParti.role == "admin") {
				// Admin of a meeting can always join the meeting, regardless of time
				return true;
			}

			return VideoMeetingService.meetingInProgress(this.meeting);
		},

		canSave() {
			return this.duration > 0 && this.meeting.name;
		},

		name() {
			if (this.meeting) {
				return this.meeting.name;
			} else {
				return this.event.title;
			}
		},

		start() {
			if (this.meeting) {
				return moment(this.meeting.start_time);
			} else {
				return moment(this.event.start);
			}
		},

		end() {
			if (this.meeting) {
				return moment(this.meeting.end_time);
			} else {
				return moment(this.event.end);
			}
		},

		duration() {
			return moment.duration(this.end.diff(this.start)).asSeconds();
		},

		partis() {
			if (this.meeting) {
				return _.sortBy(this.meeting.participants, (o) => {
					return o.role == "admin" ? 0 : 1;
				});
			} else {
				return [];
			}
		},
	},

	methods: {
		unwatchForChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
		},

		watchForChanges() {
			this.unwatchForChanges();
			this.unwatch = this.$watch(
				"meeting",
				(newc, old) => {
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		async loadMeeting() {
			this.loading = true;

			let call;
			if (this.canEdit) {
				call = {
					meeting: VideoMeetingService.adminGetMeeting(this.event.id),
					meetingUsers: VideoMeetingService.getMeetingUsers(this.event.id),
				};
			} else {
				call = {
					meeting: VideoMeetingService.getMeeting(this.event.id),
					meetingUsers: VideoMeetingService.getMeetingUsers(this.event.id),
				};
			}
			BB.props(call)
				.then((r) => {
					this.meeting = r.meeting.data.meeting;
					this.meetingUsers = r.meetingUsers.data.meetingUsers;
					this.loadUsersInParticipants(this.meeting.participants, this.meetingUsers);
					this.descriptionInit = _.cloneDeep(this.meeting.description) || "";
				})
				.catch((e) => {
					this.logError(e);
					Notie.error("Failed to load meeting", e);
					this.hide();
				})
				.finally(() => {
					this.loading = false;
				});
		},

		loadUsersInParticipants(participants, users) {
			this.debug("loadUsersInParticipants", participants, users);
			for (let parti of participants) {
				let user = _.find(users, { id: parti.user_id });
				if (user) {
					parti.name = user.full_name;
					parti.scorer_id = user.scorer_id;
					parti.email = user.email;
				} else {
					parti.name = "(Unknown)";
					parti.scorer_id = "(Unknown)";
					parti.email = "unknown@unknown.com";
				}
			}
		},

		createNewMeeting() {
			let start_time = this.event.start || moment().startOf("hour").add(1, "hours");
			let end_time = this.event.end || moment(start_time).add(30, "minutes");

			let meeting = {
				name: "",
				description: "",
				password: "",
				start_time: start_time,
				end_time: end_time,
				participants: [
					{
						user_id: this.user.id,
						name: this.user.full_name,
						scorer_id: this.user.scorer_id,
						email: this.user.email,
						role: "admin",
						capabilities: {
							audio: "SendReceive",
							video: "SendReceive",
							content: "SendReceive",
							chat: "SendReceive",
						},
					},
				],
			};
			this.meeting = meeting;

			this.startEdit();
		},

		getUsersFromUserSearch(userSearch) {
			let seenBefore = {};
			let users = [];
			_.each(userSearch, (val) => {
				if (val.users) {
					let team = val;
					_.each(team.users, (user) => {
						if (!seenBefore[user.id]) {
							seenBefore[user.id] = true;
							users.push(user);
						}
					});
				} else {
					let user = val;
					if (!seenBefore[user.id]) {
						seenBefore[user.id] = true;
						users.push(user);
					}
				}
			});

			return users;
		},

		addParticipants() {
			this.debug("addParticipants");
			let addUserMap = {};
			for (let parti of this.meeting.participants) {
				addUserMap[parti.user_id] = true;
			}

			let users = this.getUsersFromUserSearch(this.addUsers);
			this.debug("users", users);
			for (let user of users) {
				if (!addUserMap[user.id]) {
					let newParti = {
						user_id: user.id,
						role: "publisher",
						name: user.full_name,
						scorer_id: user.scorer_id,
						email: user.email,
						capabilities: {
							audio: "SendReceive",
							video: "SendReceive",
							content: "SendReceive",
							chat: "SendReceive",
						},
					};
					this.meeting.participants.push(newParti);
					addUserMap[user.id] = true;
				}
			}
			this.addUsers = [];
		},

		removeParti(parti) {
			let index = this.meeting.participants.indexOf(parti);
			if (index >= 0) {
				this.$delete(this.meeting.participants, index);
			}
		},

		promoteToAdmin(parti) {
			parti.role = "admin";
		},

		demoteFromAdmin(parti) {
			parti.role = "publisher";
		},

		joinLinkTooltip() {
			return `<div>${this.$i18n.t(
				"MeetingCalendar.join_link_tooltip_1"
			)}</div><div class="text-muted text-xxs">${this.$i18n.t("MeetingCalendar.join_link_tooltip_2")}</div>`;
		},

		toggleJoinLink() {
			if (!this.meeting) return;

			if (this.meeting.password) {
				this.$set(this.meeting, "password", "");
			} else {
				this.$set(this.meeting, "password", Utils.generatePassword(8));
			}
		},

		getLink(password) {
			return `${window.location.origin}/#/video_meeting/${this.meeting.id}/join/${password}`;
		},

		startEdit() {
			this.origMeeting = _.cloneDeep(this.meeting);
			this.editing = true;
			this.watchForChanges();

			if (!this.meeting.name) {
				setTimeout(() => {
					this.$refs.name.focus();
				}, 200);
			}
		},

		cancelEdit() {
			this.unwatchForChanges();
			this.meeting = _.cloneDeep(this.origMeeting);
			this.editing = false;
			this.dirty = false;

			if (!this.event.id) {
				this.debug("No event id", this.event);
				this.$emit("cancelNew");
				this.hide();
			}
		},

		async deleteMeeting() {
			let extraWarning;
			if (VideoMeetingService.meetingInProgress(this.meeting)) {
				extraWarning = this.$i18n.t("MeetingCalendar.delete_meeting_in_progress_warning");
			}

			const ok = await Utils.confirmDelete("meeting", this.meeting && this.meeting.name, extraWarning);
			if (!ok) return;

			try {
				this.deleting = true;
				await VideoMeetingService.deleteMeeting(this.meeting.id);
				Notie.info(`Deleted \"${this.meeting.name}\"`);

				this.cancelEdit();
				this.$emit("eventChanged");
				this.hide();
			} catch (e) {
				let errMsg = "Failed to delete meeting";
				this.logError(errMsg, e);
				Notie.error(errMsg, e);
			} finally {
				this.deleting = false;
			}
		},

		close() {
			this.hide();
		},

		async saveMeeting() {
			try {
				this.saving = true;
				let { data } = await VideoMeetingService.saveMeeting(this.meeting);
				this.debug("saveMeeting", data.id, this.meeting);
				if (!this.meeting.id) {
					this.debug("give it an ID");
					this.meeting.id = data.id;
					this.event.id = data.id;
					this.$emit("cancelNew");
				} else if (data.id != this.meeting.id) {
					let errMsg = "Got meeting with unexpected ID";
					this.logError(errMsg);
					Notie.error(errMsg);
				}

				await this.loadMeeting();
				this.cancelEdit();
				this.$emit("eventChanged");
			} catch (e) {
				this.logError(e);
				Notie.error("Failed to save meeting", e);
			} finally {
				this.saving = false;
			}
		},

		joinMeeting() {
			this.$router.push(`/video_meeting/${this.meeting.id}`);
		},

		hide() {
			if (this.editing) {
				this.cancelEdit();
			}
			this.$emit("hide");
		},

		printEvent() {
			this.debug(this.event);
		},
	},
};
</script>
