//MILES - I created this thinking we could back support old role settings but I think we'll just migrate data

export default {
	DIRECTOR: {
		id: "DIRECTOR",
		name: "Director",
		topRole: true,
		access: 100,
		page_dashboard: true,
		page_scoring: true,
		page_profile: true,
		page_project_summary: false,
		page_backreading: true,
		page_reporting: true,
		page_qc_report: true,
		page_qc_setup: true,
		page_auditing: true,
		page_config_sections: true,
		page_config_users: true,
		page_config_teams: true,
		page_config_tenants: true,
		page_config_projects: true,
		page_config_items: true,
		page_config_rubrics: true,

		score_time_stats_enabled: true,
		messaging_enabled: true
	},
	ADMIN: {
		id: "ADMIN",
		name: "Admin",
		access: 50,
		page_dashboard: true,
		page_scoring: true,
		page_profile: true,
		page_project_summary: false,
		page_backreading: true,
		page_reporting: true,
		page_qc_report: true,
		page_qc_setup: true,
		page_auditing: true,
		page_config_sections: true,
		page_config_users: true,
		page_config_teams: true,
		page_config_tenants: true,
		page_config_projects: true,
		page_config_items: true,
		page_config_rubrics: true,

		score_time_stats_enabled: true,
		messaging_enabled: true
	},
	RESOLUTION: {
		id: "RESOLUTION",
		name: "Supervisor",
		access: 40,
		page_dashboard: true,
		page_scoring: true,
		page_profile: true,
		page_project_summary: false,
		page_backreading: true,
		page_reporting: true,
		page_qc_report: true,
		page_qc_setup: true,
		page_auditing: true,
		page_config_sections: true,
		page_config_users: true,
		page_config_teams: false,
		page_config_tenants: false,
		page_config_projects: false,
		page_config_items: false,
		page_config_rubrics: false,

		score_time_stats_enabled: true,
		messaging_enabled: true
	},
	TEAM_LEAD: {
		id: "TEAM_LEAD",
		name: "Team Lead",
		access: 30,
		page_dashboard: false,
		page_scoring: true,
		page_profile: false,
		page_project_summary: false,
		page_backreading: true,
		page_reporting: false,
		page_qc_report: false,
		page_qc_setup: false,
		page_auditing: false,
		page_config_sections: false,
		page_config_users: false,
		page_config_teams: false,
		page_config_tenants: false,
		page_config_projects: false,
		page_config_items: false,
		page_config_rubrics: false,

		score_time_stats_enabled: false,
		messaging_enabled: false
	},
	SCORER: {
		id: "SCORER",
		name: "Scorer",
		access: 20,
		page_dashboard: true,
		page_scoring: true,
		page_profile: false,
		page_project_summary: false,
		page_backreading: false,
		page_reporting: false,
		page_qc_report: false,
		page_qc_setup: false,
		page_auditing: false,
		page_config_sections: false,
		page_config_users: false,
		page_config_teams: false,
		page_config_tenants: false,
		page_config_projects: false,
		page_config_items: false,
		page_config_rubrics: false,

		score_time_stats_enabled: true,
		messaging_enabled: true
	},
	BASIC_SCORER: {
		id: "BASIC_SCORER",
		name: "Basic Scorer",
		access: 10,
		page_dashboard: false,
		page_scoring: true,
		page_profile: false,
		page_project_summary: false,
		page_backreading: false,
		page_reporting: false,
		page_qc_report: false,
		page_qc_setup: false,
		page_auditing: false,
		page_config_sections: false,
		page_config_users: false,
		page_config_teams: false,
		page_config_tenants: false,
		page_config_projects: false,
		page_config_items: false,
		page_config_rubrics: false,

		score_time_stats_enabled: false,
		messaging_enabled: false
	}
};
