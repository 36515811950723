<template>
	<div class="d-flex flex-column flex h100 page-body">
		<div class="navbar theme bg box-shadow py-0">
			<div class="navbar-brand oscar-logo">
				<!-- Logo -->
				<img
					src="static/OSCAR-logo-white-cropped.png"
					alt="."
					class="d-none d-sm-none d-md-inline d-lg-inline mr-1"
				/>
			</div>
		</div>
		<div class="padding text-center h-100 d-flex flex-column justify-content-center">
			<h2 class="text-muted mb-4">
				{{ $t("Landing.welcome_to") }}
				<span class="text-theme">{{ $t("Landing.oscar") }} </span>
			</h2>
			<h5 class="text-muted mb-4 d-none d-lg-block">{{ $t("Landing.no_active_clients") }}</h5>
			<h5 class="text-theme text-muted mb-4 d-none d-lg-block">
				<a href="/"> {{ $t("Landing.return_to_login") }}</a>
			</h5>
		</div>
		<mzfooter />
	</div>
</template>

<style scoped>
.landing-box {
	min-height: 200px;
	height: 100%;
}
</style>

<script>
export default {
	name: "LandingNoClients",
};
</script>
