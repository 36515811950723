<template>
	<div class="box mb-0">
		<table class="table mb-0">
			<tbody>
				<tr>
					<td v-if="!nonCurrent" colspan="2" class="text-center">
						<h5 v-if="available(project)" class="text-success my-2">Currently Available</h5>
						<h5 v-if="!available(project)" class="text-danger my-2">Currently Not Available</h5>
						<div class="text-muted">{{ project.name }}</div>
					</td>
					<td v-if="nonCurrent" colspan="2" class="text-center">
						<h5 class="text-success my-2">{{ project.name }}</h5>
					</td>
				</tr>
				<tr v-if="project.enforce_dates">
					<td colspan="2" class="text-center">
						<div>
							<strong class="no-break">{{ fs.dateString(project.scoring_start) }}</strong>
						</div>
						<div>to</div>
						<div>
							<strong class="no-break">{{ fs.dateString(project.scoring_end) }}</strong>
						</div>
					</td>
				</tr>
				<tr v-for="i in days" :key="i">
					<td>
						<strong>{{ fs.intToDay(i) }}:</strong>
					</td>
					<td>
						<div v-if="times[i]" class="d-flex flex-row">
							<div>
								<div :class="{ 'text-danger _500': times[i].early && !nonCurrent }" class="no-break">
									{{ dispTime(times[i].start_local) }}
								</div>
								<div v-if="times[i].start_ahead && !nonCurrent" class="text-muted text-xxs no-break">
									(Next day)
								</div>
								<div v-if="times[i].start_behind && !nonCurrent" class="text-muted text-xxs no-break">
									(Prev day)
								</div>
							</div>
							<span class="px-2">–</span>
							<div>
								<div :class="{ 'text-danger _500': times[i].late && !nonCurrent }" class="no-break">
									{{ dispTime(times[i].end_local) }}
								</div>
								<div v-if="times[i].end_ahead && !nonCurrent" class="text-muted text-xxs no-break">
									(Next day)
								</div>
								<div v-if="times[i].end_behind && !nonCurrent" class="text-muted text-xxs no-break">
									(Prev day)
								</div>
							</div>
						</div>
						<span v-if="times[i] == null" class="no-break text-muted">Unavailable</span>
						<span v-if="times[i] == false" class="no-break text-muted">24 hours</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<style scoped>
.no-break {
	white-space: nowrap;
}
</style>

<script>
import ProjectService from "@/services/ProjectService";
import fs from "@/services/FormatService";

var moment = require("moment-timezone");

export default {
	name: "ProjectAvailability",

	props: {
		project: true,
		client: true,
		//nonCurrent - When true, prevents displaying indicators about whether the current time is in or out of windows
		nonCurrent: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			fs: fs,
			days: [0, 1, 2, 3, 4, 5, 6],
			timezone: null,
			now: null
		};
	},

	created() {
		this.timezone = this.client.timezone;
		if (!this.timezone) {
			this.timezone = "America/New_York";
		}
		moment.tz.setDefault(this.timezone);
		this.now = moment();
	},

	computed: {
		times() {
			let now = this.now;
			let times = [];
			_.each(this.days, i => {
				if (!this.project.enforce_times) {
					//Daily limits are not enforced, so every day is available 24 hours
					times[i] = false;
					return;
				} else {
					//Check if there's a limit for every day first
					let day = _.find(this.project.avail_times, { day: 7 });
					day = _.cloneDeep(day);
					if (!day) {
						//If not, get the limit for the individual day
						day = _.find(this.project.avail_times, { day: i });
					}
					if (!day) {
						//If no limit, this day is unavailable
						times[i] = null;
						return;
					}

					let dayStart = moment(day.start, fs.timeFormat()).day(i);
					let dayEnd = moment(day.end, fs.timeFormat()).day(i);
					if (now.isSame(dayStart, "day") && now.isBefore(dayStart)) {
						day.early = true;
					}
					if (now.isSame(dayStart, "day") && now.isAfter(dayEnd)) {
						day.late = true;
					}

					let localTz = moment.tz.guess();
					day.start_local = moment(day.start, fs.timeFormat())
						.day(i)
						.tz(localTz);
					day.end_local = moment(day.end, fs.timeFormat())
						.day(i)
						.tz(localTz);

					day.start_ahead = this.dayAhead(day.start_local, dayStart) && !this.isMidnight(day.start_local);
					day.start_behind = this.dayBehind(day.start_local, dayStart);
					day.end_ahead = this.dayAhead(day.end_local, dayEnd) && !this.isMidnight(day.end_local);
					day.end_behind = this.dayBehind(day.end_local, dayEnd);

					times[i] = day;
				}
			});

			return times;
		}
	},

	methods: {
		available(project) {
			return ProjectService.checkIfAvailable(project, this.timezone);
		},

		dayAhead(local, server) {
			if (local.year() > server.year()) {
				return true;
			}
			if (local.year() == server.year() && local.month() > server.month()) {
				return true;
			}
			if (local.year() == server.year() && local.month() == server.month() && local.date() > server.date()) {
				return true;
			}
			return false;
		},

		dayBehind(local, server) {
			if (local.year() < server.year()) {
				return true;
			}
			if (local.year() == server.year() && local.month() < server.month()) {
				return true;
			}
			if (local.year() == server.year() && local.month() == server.month() && local.date() < server.date()) {
				return true;
			}
			return false;
		},

		isMidnight(time) {
			return time.hour() == 0 && time.minute() == 0;
		},

		dispTime(time) {
			if (this.isMidnight(time)) {
				return "Midnight";
			} else {
				return time.format(fs.timeFormat());
			}
		}
	}
};
</script>
