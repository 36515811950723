<template>
	<a :class="outerStyle" v-tippy :title="title">
		<div :class="divStyle">
			<i :class="iconStyle" />
		</div>
		<div>{{ abbreviation }}</div>
	</a>
</template>

<script>
import moment from "moment";

export default {
	name: "UserLockoutIcon",

	props: {
		lockoutStat: Object,
		lockoutType: Object,
	},

	computed: {
		title() {
			const {
				isLockOutType,
				lockoutType: { lockoutText, noLockoutText },
				lockoutStat,
			} = this;
			if (!isLockOutType) return noLockoutText;

			const { section, item, lockout_date, detailed_reason } = lockoutStat;
			const titleParts = [
				lockoutText,
				`${section.name} - ${item.name}`,
				`Lockout Date ${moment(lockout_date).format("M/D/YY h:mm")}`,
			];

			if (detailed_reason) {
				titleParts.push(detailed_reason);
			}

			return titleParts.join("<br/>");
		},

		abbreviation() {
			return this.lockoutType.abbreviation;
		},

		outerStyle() {
			const unique = this.isLockOutType ? "exception text-danger" : "text-extra-muted";
			return `text-center icon ${unique}`;
		},

		divStyle() {
			return this.isLockOutType ? "" : "text-very-muted";
		},

		iconStyle() {
			return this.isLockOutType ? "fa fa-exclamation-triangle fa-lg" : "far fa-triangle fa-lg";
		},

		isLockOutType() {
			const {
				lockoutStat: { reason },
				lockoutType: { id },
			} = this;
			return reason === id;
		},
	},
};
</script>

<style>
</style>