<template>
	<!-- <div v-if="mresponse" class="essay">
		<p class="respText">{{mresponse.media[0].value}}</p>
	</div>-->
	<div class="px-2">
		<div
			v-if="!pages.length && !loadingError"
			class="essay loader"
			:class="{ 'no-margin': noMargin }"
			:style="{ color: themeColor + ' !important' }"
		>
			<p v-if="!loadingError" class="respText">
				<loading type="icon" />
			</p>
		</div>
		<div v-if="!pages.length && loadingError" class="essay essay-nopad grid-center padding">
			<div class="text-center">
				<i class="fas fa-file-code fa-2x text-extra-muted" />
				<div class="text-extra-muted font-initial">Response failed to load</div>
			</div>
		</div>
		<template v-for="(page, i) in pages">
			<!-- <text-media v-if="page.type == 'text'" :page="page"></text-media> -->
			<div
				v-if="(page.mediaSeq != undefined && showPrint) || (page.type != 'text' && page.label)"
				class="btn-position d-flex flex-row justify-content-between align-items-center"
				:key="`print-${page.id}`"
			>
				<div v-if="page.type != 'text' && page.label" class="label-box text-theme">
					{{ page.label }}
					<button
						v-if="page.raw_source && page.type == 'html'"
						class="btn btn-subtle"
						style="display: inline-flex; height: 30px; width: 30px; margin-top: -2px; margin-right: -6px"
						@click="toggleSource(page)"
						v-tippy
						:title="$t('tooltip.view_source')"
					>
						<i class="fa-file-code fa-lg" :class="{ fas: page.viewSource, far: !page.viewSource }" />
					</button>
				</div>
				<template v-if="page.mediaSeq != undefined && showPrint">
					<template v-if="page.type == 'text'">
						<button class="btn btn-primary" data-toggle="dropdown">
							<i class="fas fa-print mr-2" />{{ $t("tooltip.print") }}
						</button>
						<div class="dropdown-menu dropdown-menu-left">
							<a class="dropdown-item" @click="printMedia(page.mediaSeq)">
								<label class="mb-0">As Text</label>
							</a>
							<a class="dropdown-item" @click="printMediaStyled(page)">
								<label class="mb-0">As Displayed</label>
							</a>
						</div>
					</template>
					<button v-else class="btn btn-primary" @click="printMedia(page.mediaSeq)">
						<template v-if="page.type == 'video' || page.type == 'audio'">
							<i class="fas fa-print mr-2" />{{ $t("tooltip.open_in_new_window") }}
						</template>
						<template v-if="!(page.type == 'video' || page.type == 'audio')">
							<i class="fas fa-print mr-2" />{{ $t("tooltip.print") }}
						</template>
					</button>
				</template>
			</div>
			<TextMedia
				v-if="page.type == 'text'"
				:item="item"
				:score="score"
				:page="page"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:style="{ display: !showMedia(page.page_ref_id) ? 'none' : 'flex' }"
				:highlighter.sync="highlighters[i]"
				:id="`page-${page.id}`"
				:noPad="noPad"
				@goToCopyleaksReport="goToCopyleaksReport(page)"
			></TextMedia>
			<PDFMedia
				v-if="page.type == 'pdf'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:highlighter.sync="highlighters[i]"
				:id="`page-${page.id}`"
			></PDFMedia>
			<VideoMedia
				v-if="page.type == 'video'"
				v-show="showMedia(page.page_ref_id)"
				:page="page"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:id="`page-${page.id}`"
				:item="item"
				@viewedAll="viewedAllMedia(i)"
			></VideoMedia>
			<AudioMedia
				v-if="page.type == 'audio'"
				v-show="showMedia(page.page_ref_id)"
				:page="page"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:id="`page-${page.id}`"
				:item="item"
				@viewedAll="viewedAllMedia(i)"
			></AudioMedia>
			<ImageMedia
				v-if="page.type == 'image'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:annotations="filteredAnnotations.length > i && filteredAnnotations[i]"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:highlighter.sync="highlighters[i]"
				:id="`page-${page.id}`"
				:forPrinting="forPrinting"
			></ImageMedia>
			<ExcelMedia
				v-if="page.type == 'excel' || page.type == 'csv'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:resizeContainer="resizeContainer"
				:id="`page-${page.id}`"
				:unprotected="unprotected"
				:yPadding="70"
				:xPadding="56"
			></ExcelMedia>
			<LearnosityMedia
				v-if="page.type == 'learnosity'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:resizeContainer="resizeContainer"
				:id="`page-${page.id}`"
			></LearnosityMedia>
			<DownloadMedia
				v-if="page.type == 'download'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:resizeContainer="resizeContainer"
				:id="`page-${page.id}`"
				:response="mresponse"
			></DownloadMedia>
			<IframeMedia
				v-if="page.type == 'html'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:resizeContainer="resizeContainer"
				:id="`page-${page.id}`"
				:response="mresponse"
				:rawSource="page.raw_source"
				:viewSource="page.viewSource"
			>
			</IframeMedia>
			<IframeDocMedia
				v-if="page.type == 'srcdoc'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:resizeContainer="resizeContainer"
				:id="`page-${page.id}`"
				:response="mresponse"
			>
			</IframeDocMedia>
			<CogniaMedia
				v-if="page.type == 'cognia'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:resizeContainer="resizeContainer"
				:id="`page-${page.id}`"
				:response="mresponse"
			>
			</CogniaMedia>
			<iris-media
				v-if="page.type === 'iris'"
				v-show="showMedia(page.page_ref_id)"
				:item="item"
				:page="page"
				:index="i"
				:chan="chan"
				:key="page.id"
				:class="{ 'no-margin': noMargin }"
				:resizeContainer="resizeContainer"
				:id="`page-${page.id}`"
				:response="mresponse"
			/>
		</template>
	</div>
</template>

<script>
import _ from "lodash";
import Utils from "@/services/Utils";
import ResponseMediaService from "../../services/ResponseMediaService";
import ThemeService from "../../services/ThemeService";
import Notie from "@/services/NotieService";

import PDFMedia from "@/components/viewer/PDFMedia";
import VideoMedia from "@/components/viewer/VideoMedia";
import AudioMedia from "@/components/viewer/AudioMedia";
import ImageMedia from "@/components/viewer/ImageMedia";
import TextMedia from "@/components/viewer/TextMedia";
import ExcelMedia from "@/components/viewer/ExcelMedia";
import LearnosityMedia from "@/components/viewer/LearnosityMedia";
import DownloadMedia from "@/components/viewer/DownloadMedia";
import IframeMedia from "@/components/viewer/IframeMedia";
import IframeDocMedia from "@/components/viewer/IframeDocMedia";
import CogniaMedia from "@/components/viewer/CogniaMedia";
import IrisMedia from "@/components/viewer/IrisMedia";

export default {
	name: "Media",
	props: {
		response: true,
		chan: true,
		item: true,
		score: true,
		noMargin: { type: Boolean, default: false },
		noChan: { type: Boolean, default: false },
		annotations: true,
		pageRefID: true,
		resizeContainer: true,
		showPrint: true,
		unprotected: true,
		noPad: { type: Boolean, default: false },
		// forPrinting indicates that the response should be rendered in a way that makes it more
		// conducive to printing (e.g., no Canvas element for ImageMedia). However, this doesn't
		// mean that the content should not render normally, or close to normally.
		forPrinting: { type: Boolean, default: false },
	},
	components: {
		PDFMedia,
		VideoMedia,
		ImageMedia,
		TextMedia,
		AudioMedia,
		ExcelMedia,
		LearnosityMedia,
		DownloadMedia,
		IframeMedia,
		IframeDocMedia,
		CogniaMedia,
		IrisMedia,
	},
	data() {
		return {
			mresponse: _.cloneDeep(this.response),
			newChan: null,
			pages: [],
			themeColor: ThemeService.getThemeColorHex(),
			pdfObjs: null,
			filteredAnnotations: [],
			highlighters: [],
			annosToLoad: null,
			loadingError: false,
		};
	},

	watch: {
		response: {
			handler: function (newVal) {
				this.mresponse = _.cloneDeep(newVal);
				this.pages = [];
				this.initHighlightPlaceholder();
				this.setup();
			},
			deep: true,
			immediate: true,
		},

		pages() {
			this.$set(this, "highlighters", []);
			_.each(this.pages, (p, i) => {
				this.$set(this.highlighters, i, null);
			});
		},

		highlighters: {
			handler: function (newVal) {
				if (!(this.pages && this.pages.length > 0)) {
					return;
				}
				console.log("Highlighter interface changed", this.response.id);
				let allReady = true;
				_.each(this.pages, (page, i) => {
					console.log("page");
					console.log(page);
					let highlighter = this.highlighters[i];
					const highlightMediaTypes = ["text", "pdf", "image"];
					if (!highlighter && highlightMediaTypes.includes(page.type)) {
						allReady = false;
					}
				});

				if (allReady) {
					console.log("All highlighter interfaces ready");
					this.initHighlightInterface();
				}
			},
			deep: true,
			immediate: true,
		},
	},

	created() {
		this.initHighlightPlaceholder();
	},

	destroyed() {
		// if(this.chan){
		// 	// debugger;
		// 	this.chan.$destroy()
		// }
		if (this.chan) {
			this.chan.$off();
		}
		if (this.pdfObjs) {
			console.log("Destroy PDF media");
			_.each(this.pdfObjs, (pdf) => {
				pdf.cleanup();
				pdf.destroy();
			});
			this.pdfObjs = null;
		}
	},

	methods: {
		setup() {
			//Destroy any existing PDF objects
			if (this.pdfObjs) {
				console.log("Destroy PDF media");
				_.each(this.pdfObjs, (pdf) => {
					pdf.cleanup();
					pdf.destroy();
				});
				this.pdfObjs = null;
			}

			// this.chan.$destroy()
			// delete this.chan;

			var now = new Date().getTime();
			if (this.mresponse) {
				if (this.chan) {
					this.chan.$emit("increment-media-to-load");
				}
				ResponseMediaService.setupResponse(this.mresponse, this.item)
					.then((result) => {
						this.pages = result.pages.map((p) => {
							p.id = Math.random().toString(36).substr(2, 9);
							p.start = now;
							p.viewedAll = !(p.type == "audio" || p.type == "video");
							return p;
						});
						console.log("Render time:" + (new Date().getTime() - now));
						this.$emit("setup", true);
						this.$forceUpdate();

						this.pdfObjs = result.pdfs;
						this.viewedAllMedia();

						if (this.chan) {
							this.chan.$emit("increment-media-loaded");
						}
					})
					.catch((e) => {
						Notie.error("Failed to set up response", e);
						this.loadingError = true;
						if (this.chan) {
							this.chan.$emit("increment-media-failed");
						}
					});
			}
		},

		saveAnnotation(anno, i) {
			anno.page = i;
			this.$emit("saveAnnotation", anno);
		},

		deleteAnnotation(timestamp) {
			this.$emit("deleteAnnotation", timestamp);
		},

		showMedia(pageRefID) {
			if (!this.pageRefID || this.pageRefID == "") {
				return true;
			}
			return this.pageRefID == pageRefID;
		},

		printMedia(mediaSeq) {
			var windowRef = window.open();
			if (this.response.qc_type) {
				windowRef.location = `/v1/print/qcmedia/${this.response.id}/${mediaSeq}`;
			} else {
				windowRef.location = `/v1/print/media/${this.response.id}/${mediaSeq}`;
			}
		},

		printMediaStyled(page) {
			let id = `page-${page.id}`;
			let el = document.getElementById(id);
			if (!el) {
				console.log("Could not find element", id);
				Notie.error("Failed to print page", "Page not found");
				return;
			}
			Utils.printElement(el);
		},

		initHighlightPlaceholder() {
			if (this.highlightInterface) return; // highlighter is already set up, do not overwrite
			this.highlightInterface = {};
			this.highlightInterface.setAnnotations = (annos) => {
				this.annosToLoad = annos;
				console.log("Save annotations to load later", this.annosToLoad);

				if (!this.chan) return;
				if (annos.length > 0) {
					this.chan.$emit("has-annotations");
				} else {
					this.chan.$emit("has-no-annotations");
				}
			};
			this.highlightInterface.getAnnotations = () => {
				console.error("Cannot retrieve annotations: response is not yet set up");

				return [];
			};
			this.$emit("update:highlighter", this.highlightInterface);
		},

		initHighlightInterface() {
			console.log("Init highlighter interface", this.annosToLoad);
			this.highlightInterface.setAnnotations = (annos) => {
				let filtereds = [];
				_.each(this.pages, (page, i) => {
					let filtered = _.filter(annos, { page: i });
					let highlighter = this.highlighters[i];
					if (highlighter) {
						highlighter.setAnnotations(filtered);
					}
				});

				if (this.chan) {
					if (annos.length > 0) {
						this.chan.$emit("has-annotations");
					} else {
						this.chan.$emit("has-no-annotations");
					}
				}
			};
			this.highlightInterface.getAnnotations = () => {
				let annotations = [];
				_.each(this.highlighters, (highlighter, i) => {
					if (!highlighter) {
						return;
					}
					let annos = highlighter.getAnnotations();
					_.each(annos, (anno) => {
						anno.page = i;
					});
					annotations = annotations.concat(annos);
				});

				return annotations;
			};
			this.$emit("update:highlighter", this.highlightInterface);

			// Now that the highlight interface is ready, load any annotations that are waiting
			this.loadQueuedAnnos();
		},

		loadQueuedAnnos() {
			if (this.annosToLoad) {
				if (!this.highlightInterface) console.error("Highlight interface did not exist when expected");
				this.highlightInterface.setAnnotations(this.annosToLoad);
				this.annosToLoad = null;
			}
		},

		viewedAllMedia(i = null) {
			if (i !== null) {
				this.pages[i].viewedAll = true;
			}
			if (this.pages.length > 0 && this.pages.every(({ viewedAll }) => viewedAll)) {
				this.$emit("viewedAll");
			}
		},

		toggleSource(page) {
			page.viewSource = !page.viewSource;
		},
	},
};
</script>

<style>
.essay {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;

	min-width: 400px;
	max-width: 800px;
	width: 800px;
	font-size: 18px;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}

.essay:last-of-type:not(.no-margin) {
	margin-bottom: 50px;
}

.essay.no-margin:last-of-type {
	margin-bottom: 20px;
}

.essay::before {
	content: "";
	float: left;
	width: 1px;
	margin-left: -1px;
	padding-top: 125%;
}

.essay::after {
	content: "";
	display: table;
	clear: both;
}

.printing-page {
	break-inside: avoid;
}

.printing-page .text-muted {
	opacity: 1;
}

.printing-page .essay::before {
	padding-top: 0;
}

.printing-page .respText {
	padding: 40px;
}

.essay-nopad::before {
	padding-top: 0;
}

.with-annotation-aside .essay {
	margin-right: 10px;
}

.annotation-aside {
	margin-top: 20px;
	position: relative;
	margin-left: 0;
	margin-right: auto;

	min-width: 200px;
	width: 300px;
	font-size: 18px;

	padding-top: 80px;
	padding-bottom: 1.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}

.loader {
	font-size: 128px;
	text-align: center;
}

.respText {
	white-space: pre-wrap;
	/* css-3 */
	word-wrap: break-word;
	/* Internet Explorer 5.5+ */
	word-break: normal;
	padding: 80px;
}

.essay-nopad > .respText {
	padding: 16px 80px;
}

.essay-nopad p:last-of-type {
	margin-bottom: 0;
}

.essaySpinner {
	height: 200px;
	width: 200px;
	margin-left: 300px;
}

.btn-position {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	min-width: 400px;
	max-width: 800px;
	margin-bottom: -10px;
	margin-top: 20px;
}

.label-box {
	background: white;
	/* border-radius: 6px; */
	height: 35.59px;
	line-height: 35.59px;
	padding-left: 10px;
	padding-right: 10px;
	-webkit-box-shadow: 2px 2px 6px 2px rgb(184 182 184 / 75%);
	box-shadow: 2px 2px 6px 2px rgb(184 182 184 / 75%);
	background-color: white;
}
</style>
