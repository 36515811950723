<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("RubricSetup.title") }}</div>

				<!-- Create new -->
				<div class="py-0">
					<a href="#/rubrics/new" class="btn btn-sm hover-darken theme-accent btn-block">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("RubricSetup.new_rubric") }}</span>
					</a>
				</div>

				<!-- Search field -->
				<div class="collapse navbar-collapse no-grow order-lg-1" id="navbarToggler">
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('RubricSetup.search_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>

		<!-- Page Content -->
		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Numeric Traits -->
					<template #cell(numeric_traits)="data">
						<span v-if="data.item.traits && data.item.traits.length > 0">
							<i class="fas fa-sliders-h faw"></i>
							{{ getNumRealTraits(data.item.traits) }}
						</span>
						<span v-if="!(data.item.traits && data.item.traits.length > 0)" class="text-muted">{{
							$t("data_description.none")
						}}</span>
					</template>

					<!-- Text Traits -->
					<template #cell(text_traits)="data">
						<span v-if="data.item.meta_traits && data.item.meta_traits.length > 0">
							<i class="fas fa-font faw"></i>
							{{ (data.item.meta_traits && data.item.meta_traits.length) || 0 }}
						</span>
						<span v-if="!(data.item.meta_traits && data.item.meta_traits.length > 0)" class="text-muted">{{
							$t("data_description.none")
						}}</span>
					</template>

					<!-- Edit -->
					<template #cell(edit)="data">
						<div class="mx-auto w95">
							<a
								:href="`#/rubrics/${data.item.id}`"
								class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
								v-tippy
								:title="$t('tooltip.edit')"
							>
								<i class="far fa-edit"></i>
							</a>
							<button
								class="btn btn-sm btn-icon btn-rounded hover-darken btn-primary text-white m-0"
								v-tippy
								:title="$t('tooltip.copy_rubric')"
								@click="copyRubric(data.item)"
							>
								<i class="far fa-copy"></i>
							</button>
							<button
								v-if="!data.item.inProgress"
								@click="deleting = data.item"
								class="btn btn-sm btn-icon btn-rounded hover-darken red text-white m-0"
								v-tippy
								:title="$t('tooltip.delete')"
							>
								<i class="far fa-trash"></i>
							</button>
							<button
								v-if="data.item.inProgress"
								class="btn btn-sm btn-icon btn-rounded m-0"
								v-tippy
								title="Removing..."
							>
								<loading type="icon" />
							</button>
						</div>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>
		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("RubricSetup.delete_rubric_modal.title") }}</template>
			<div class="row p-4">
				{{ $t("RubricSetup.delete_rubric_modal.confirm_delete") }}&nbsp;
				<strong class="text-theme">{{ deleting.name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">
					{{ $t("RubricSetup.delete_rubric_modal.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="removeRubric(deleting)">
					{{ $t("RubricSetup.delete_rubric_modal.delete") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}

.w95 {
	width: 95px;
}
</style>

<script>
//UI Components
//@ ts-check
//Libraries
//import _ from "lodash";

//Services
import RubricService from "@/services/RubricService";
import notie from "@/services/NotieService";
import store from "@/services/Store";

export default {
	name: "RubricSetup",
	data() {
		//Dynamically bound fields
		return {
			//b-table automatically pulls values out of the given objects based on these fields
			fields: [
				{ key: "name", label: this.$i18n.t("fields.name"), sortable: true, thStyle: { width: "60%" } },
				{
					key: "numeric_traits",
					label: this.$i18n.t("fields.numeric_traits"),
					sortable: false,
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "text_traits",
					label: this.$i18n.t("fields.text_traits"),
					sortable: false,
					tdClass: "text-center",
					thClass: "text-center",
				},
				// {key: "role_id", sortable: true},
				// {key: "team_id", sortable: true},
				// {key: "scoring_allowed", label: "Scoring Allowed", thStyle: {"text-align":"center"}, tdClass: "p-0 pt-2"},
				// {key: "login_allowed", label: "Login Allowed", thStyle: {"text-align":"center"}, tdClass: "p-0 pt-2"},
				{
					key: "edit",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				},
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			sortBy: null,
			sortDesc: false,
			loading: true,

			deleting: false,
		};
	},

	components: {},

	created() {
		this.sortBy = store.get(this, "rubrics.sortBy");
		this.sortDesc = store.getDefault(this, "rubrics.sortDesc", false);
	},

	watch: {
		sortBy() {
			store.set(this, "rubrics.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "rubrics.sortDesc", this.sortDesc);
		},
	},

	methods: {
		provider(ctx) {
			return RubricService.listRubrics(ctx)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.loading = false;
					return resp.data.rubrics;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_rubrics_fail"), err);
					this.loading = false;
					return [];
				});
		},

		removeRubric(rubric) {
			rubric.inProgress = true;
			RubricService.removeRubric(rubric.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.rubric_deleted"));
					this.refreshTable();
					rubric.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.delete_rubric_fail"), err);
					rubric.inProgress = false;
					this.$forceUpdate();
				});
			this.deleting = false;
		},

		async copyRubric(rubric) {
			try {
				await RubricService.copyRubric(rubric.id);
				this.refreshTable();
				this.$forceUpdate();
			} catch (err) {
				notie.error("Failed to Copy Rubric", err);
			}
		},

		getNumRealTraits(traits) {
			let num = 0;
			_.each(traits, (trait) => {
				if (!(trait.separator || trait.is_parent)) {
					num++;
				}
			});
			return num;
		},

		refreshTable() {
			this.$refs.table.refresh();
		},
	},
};
</script>
