import axios from "axios";
import i18n from "@/i18n/setup";

import UserService from "@/services/UserService";
import { QUAL_CROSS_SET_REQS } from "@/services/Constants";
const { TRAIT_EXACT, TRAIT_EXACT_ADJ, KEEP_BEST_TRAIT, TRAIT_MUST_PASS } = QUAL_CROSS_SET_REQS;

export default {
	getQCSet(setid) {
		return axios.get(`/v1/admin/qc_set/${setid}`);
	},

	submitScore(score, response, comment, finishSet, getNextTime) {
		console.log(arguments);
		var url = "";
		var post = {};
		if (comment) {
			if (comment.text == "") {
				comment = null;
			}
			post = {
				score: score,
				comment: comment,
				finish_set: finishSet,
			};
		} else {
			post = score;
		}

		score.response_id = response.id;
		score.qc_response_id = response.id;
		score.get_next_time = getNextTime;
		switch (response.qc_type) {
			case 1:
				url = "/v1/practice/submit";
				break;
			case 2:
				url = "/v1/qualification/submit";
				break;
			case 3:
				url = "/v1/validity/submit";
				break;
			case 4:
				url = "/v1/calibration/submit";
				break;
			case 5:
				url = "/v1/rangefinding/submit";
				break;
			default:
				break;
		}

		var promise = axios.post(url, post);

		return promise;
	},

	submitTrueScore(score, responseID, feedback) {
		score.qc_response_id = responseID;
		return axios.post(`/v1/admin/qc_true_score`, {
			score: score,
			feedback: feedback,
		});
	},

	removeTrueScore(responseID) {
		return axios.delete(`/v1/admin/qc_true_score/${responseID}`);
	},

	getMyExemplarSets() {
		return axios.get("/v1/exemplar_sets");
	},

	getMyPracticeSets() {
		return axios.get("/v1/practice_sets");
	},

	getMyRfSets() {
		return axios.get("/v1/rf_sets");
	},

	//Used to populate QC Report tabs
	getSetStatuses(sectionID, itemID, setID, teamID) {
		var url = `/v1/admin/qc_states/${sectionID}/${itemID}/${setID}`;
		if (teamID) {
			url += `?teamID=${teamID}`;
		}
		return axios.get(url);
	},

	getAllSetStatuses(sectionID, itemID, qcType, teamID) {
		let ctx = {};
		if (teamID) {
			ctx.teamID = teamID;
		}
		return axios.get(`/v1/admin/qc_set_states/${sectionID}/${itemID}/${qcType}`, { params: ctx });
	},

	getAllSetStatusesForAllItems(sectionID, qcType, teamID) {
		let ctx = {};
		if (teamID) {
			ctx.teamID = teamID;
		}
		return axios.get(`/v1/admin/qc_set_states/all_items/${sectionID}/${qcType}`, { params: ctx });
	},

	getRFStatuses(sectionID, itemID, setID, teamID) {
		var url = `/v1/admin/rf_states/${sectionID}/${itemID}/${setID}`;
		if (teamID) {
			url += `?teamID=${teamID}`;
		}
		return axios.get(url);
	},

	getUserQcSetReport(setID, userID) {
		return axios.get(`/v1/qc_set_report/${setID}/${userID}`);
	},

	//Used to show QC information on a particular user
	getUserQcStat(sectionID, itemID, userID, setID) {
		return axios.get(`/v1/admin/user_qc_set/${sectionID}/${itemID}/${userID}/${setID}`);
	},

	getQcPortfolio(qcType, userID, sectionID, itemID) {
		let ctx = {
			qc: qcType,
			user: userID,
		};
		if (sectionID) {
			ctx["sect"] = sectionID;
		}
		if (itemID) {
			ctx["item"] = itemID;
		}

		return axios.get(`/v1/admin/qc_portfolio`, { params: ctx });
	},

	getMyQcPortfolio(qcType, sectionID, itemID) {
		let ctx = {
			qc: qcType,
		};
		if (sectionID) {
			ctx["sect"] = sectionID;
		}
		if (itemID) {
			ctx["item"] = itemID;
		}

		return axios.get(`/v1/my/qc_portfolio`, { params: ctx });
	},

	//Reporting, adjacency information (old style report)
	getQcStats(section, item, userID, qcType, setID) {
		var url = "/v1/admin/stat/qc/" + section + "/" + item + "/" + userID + "/" + qcType;
		if (setID) {
			url += "?setID=" + setID;
		}
		return axios.get(url, { setID: setID });
	},

	getUserQcReportData(sectionID, itemID, qcType, setID) {
		let ctx = {};
		if (setID) {
			ctx.setID = setID;
		}
		return axios.get(`/v1/user/stat/qc/${sectionID}/${itemID}/${qcType}`, {
			params: ctx,
		});
	},

	getQCSetAgreementStats(setID) {
		return axios.get(`/v1/admin/qc_set_agreements/${setID}`);
	},

	getQCSetTypeAgreementStats(sectionID, itemID, qcType) {
		return axios.get(`/v1/admin/qc_set_type_agreements/${sectionID}/${itemID}/${qcType}`);
	},

	getQCSetTypeAgreementStatsUserItemTotals(sectionID, itemID, qcType) {
		return axios.get(`/v1/admin/qc_set_type_agreements_user_item_totals/${sectionID}/${itemID}/${qcType}`);
	},

	getQCSetTypeAgreementStatsUserItemSets(sectionID, itemID, qcType) {
		return axios.get(`/v1/admin/qc_set_type_agreements_user_item_sets/${sectionID}/${itemID}/${qcType}`);
	},

	getQcResponse(respID, setID, sortedRFResponses) {
		if (setID) {
			return axios.get(`/v1/admin/qc_response/${respID}`, {
				params: { set: setID, sortedRFResponses: sortedRFResponses },
			});
		} else {
			return axios.get(`/v1/admin/qc_response/${respID}`);
		}
	},

	getQcResponseScores(respID) {
		return axios.get(`/v1/admin/qc_response_scores/${respID}`);
	},

	getEmmaScores(setID) {
		return axios.get(`/v1/admin/qc_set/${setID}/emma_scores`);
	},

	overrideQualSet(sectionID, itemID, userID, setID) {
		UserService.activate({ id: userID });
		return axios.post(`/v1/admin/qual/override_set/${sectionID}/${itemID}/${userID}/${setID}`);
	},

	overrideQual(sectionID, itemID, userID) {
		UserService.activate({ id: userID });
		return axios.post(`/v1/admin/qual/override/${sectionID}/${itemID}/${userID}`);
	},

	removeQual(sectionID, itemID, userID) {
		return axios.post(`/v1/admin/qual/remove/${sectionID}/${itemID}/${userID}`);
	},

	deleteAllQual(sectionID, itemID, userID) {
		return axios.delete(`/v1/admin/qual/delete_all/${sectionID}/${itemID}/${userID}`);
	},

	resetSet(sectionID, itemID, userID, setID) {
		UserService.activate({ id: userID });
		return axios.post(`/v1/admin/qual/reset/${sectionID}/${itemID}/${userID}/${setID}`);
	},

	fullResetSet(sectionID, itemID, userID, setID) {
		UserService.activate({ id: userID });
		return axios.post(`/v1/admin/qual/full_reset/${sectionID}/${itemID}/${userID}/${setID}`);
	},

	resetRangeFindingSet(sectionID, itemID, setID) {
		return axios.post(`/v1/admin/rf/reset_set/${sectionID}/${itemID}/${setID}`);
	},

	resetRangeFindingResponse(sectionID, itemID, responseID) {
		return axios.post(`/v1/admin/rf/reset_response/${sectionID}/${itemID}/${responseID}`);
	},

	clearValidity(sectionID, itemID, userID) {
		UserService.activate({ id: userID });
		return axios.post(`/v1/admin/val/reset/${sectionID}/${itemID}/${userID}`);
	},

	resetAllThresholds(itemID) {
		return axios.post(`/admin/val/reset_all/${itemID}`);
	},

	copySet(request) {
		return axios.post(`/v1/admin/qc_copy`, request);
	},

	applyQcTags(respID, tags, custom_tags) {
		console.log(tags);
		console.log(custom_tags);
		return axios.post(`/v1/admin/qc_response/${respID}/tags`, {
			tags,
			custom_tags,
		});
	},

	deliverCalibrationSet(payload) {
		return axios.post(`/v1/admin/qc_deliver`, payload);
	},

	finishRFSet(setID) {
		return axios.post(`/v1/rf_finish/${setID}`);
	},

	unfinishRFSet(setID, responseID) {
		let ctx = {};
		if (responseID) {
			ctx.r = responseID;
		}
		return axios.post(`/v1/rf_unfinish/${setID}`, null, { params: ctx });
	},

	getQcRuleTemplates() {
		return axios.get(`/v1/qc_templates`);
	},

	saveQcRuleTemplate(config) {
		return axios.post(`/v1/qc_templates`, config);
	},

	deleteQcRuleTemplate(config) {
		return axios.post(`/v1/qc_templates/delete`, config);
	},

	validateImportResponses(request) {
		return axios.post(`/v1/admin/import/qc_responses/valid`, request);
	},

	importResponses(request) {
		return axios.post(`/v1/admin/import/qc_responses`, request);
	},

	validateImportScores(request) {
		return axios.post(`/v1/admin/import/qc_scores/valid`, request);
	},

	importScores(request) {
		return axios.post(`/v1/admin/import/qc_scores`, request);
	},

	getQualStatus(sectionID, itemID, userID) {
		return axios.get(`/v1/admin/qual_status/${sectionID}/${itemID}/${userID}`);
	},

	getMyQualStatus(sectionID, itemID) {
		return axios.get(`/v1/my_qual_status/${sectionID}/${itemID}`);
	},

	resetValThresholds(itemID) {
		return axios.post(`/v1/admin/val/reset_all/${itemID}`);
	},

	hasValStats(itemID) {
		return axios.get(`/v1/admin/val/has_stats/${itemID}`);
	},

	getRequirementText($i18n, rubric, req) {
		let eachTrait = $i18n.t("ItemEdit.Quality.qualification.requirement_text.each_trait");
		let getTraitName = function () {
			let traitName = eachTrait;
			if (req.trait_id && req.trait_id != true) {
				traitName = `{${req.trait_id}}`;
				for (let trait of rubric.traits) {
					if (trait.id == req.trait_id) {
						return trait.name;
					}
				}
			}
			return traitName;
		};

		let traitName = "{unknown}";
		switch (req.type) {
			case "exact":
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.exact", {
					requirement: req.requirement,
				});
			case "exact_adj":
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.exact_adj", {
					requirement: req.requirement,
				});
			case "trait_exact":
				traitName = getTraitName(req);
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.trait_exact", {
					requirement: req.requirement,
					trait: traitName,
				});
			case "trait_exact_adj":
				traitName = getTraitName(req);
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.trait_exact_adj", {
					requirement: req.requirement,
					trait: traitName,
				});
			case "max_discrepant":
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.max_discrepant", {
					requirement: req.requirement,
				});
			case "trait_max_discrepant":
				traitName = getTraitName(req);
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.trait_max_discrepant", {
					requirement: req.requirement,
					trait: traitName,
				});
			case "drop_set":
				return this.getDropSetText($i18n, req);
			case "keep_best_trait":
				traitName = getTraitName(req);
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.keep_best_trait", { trait: traitName });
			case "trait_pass":
				traitName = getTraitName(req);
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.trait_pass", {
					trait: traitName,
					requirement: req.requirement,
				});
			default:
				return "{unknown requirement}";
		}
	},

	getRequirementValue(req, value) {
		switch (req.type) {
			case "exact":
				return `${value.toFixed(1)}%`;
			case "exact_adj":
				return `${value.toFixed(1)}%`;
			case "trait_exact":
				return `${value.toFixed(1)}%`;
			case "trait_exact_adj":
				return `${value.toFixed(1)}%`;
			case "max_discrepant":
				return value;
			case "trait_max_discrepant":
				return value;
			case "drop_set":
				return "Dropped";
			case "keep_best_trait":
				return `${value.toFixed(1)}%`;
			case "trait_pass":
				return value;
			default:
				return "{unknown value}";
		}
	},

	getDropSetText($i18n, req) {
		switch (req.requirement) {
			case 0:
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.lowest_exact");
			case 1:
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.highest_exact");
			case 2:
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.lowest_exact_adj");
			case 3:
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.highest_exact_adj");
			case 4:
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.lowest_discrepant");
			case 5:
				return $i18n.t("ItemEdit.Quality.qualification.requirement_text.highest_discrepant");
			default:
				return "{unknown drop type}";
		}
	},

	needsTraitAgreementAddedForReq(req, qualificationConfig) {
		if (!this.isTraitAggregate(req)) return false;
		return !(qualificationConfig.requirements || []).some((thisReq) => {
			const appliesToThisTrait = thisReq.trait_id === true || thisReq.trait_id === req.trait_id;
			return this.isTraitAgreementReq(thisReq) && appliesToThisTrait;
		});
	},

	isTraitAggregate(req) {
		return [KEEP_BEST_TRAIT.id, TRAIT_MUST_PASS.id].includes(req.type);
	},

	isTraitAgreementReq(req) {
		return [TRAIT_EXACT.id, TRAIT_EXACT_ADJ.id].includes(req.type);
	},

	traitReqMustPassGreaterThanMustSee(req, qualificationConfig) {
		return req.type === TRAIT_MUST_PASS.id && req.requirement > qualificationConfig.must_see;
	},

	getValidityRuleStats(sectionID, itemID, userID) {
		return axios.get(`/v1/admin/val/rule_stats/${sectionID}/${itemID}/${userID}`);
	},

	getValidityCompStats(sectionID, itemID, userID) {
		return axios.get(`/v1/admin/val/comp_stats/${sectionID}/${itemID}/${userID}`);
	},

	getValidityOverallStatus(ruleStats) {
		const inProgress = ruleStats.some(({ complete }) => !complete);
		if (inProgress)
			return {
				name: i18n.t("QCStates.in_progress"),
				theme: "text-warning",
			};
		const allPasses = ruleStats.every(({ passed }) => passed);
		return allPasses
			? { name: i18n.t("QCStates.passed"), theme: "text-success" }
			: { name: i18n.t("QCStates.failed"), theme: "text-danger" };
	},
};
