<template>
	<div class="h-sm-100 w-100 flex-v">
		<div class="d-sm-flex b-t h-100 flex-grow" style="overflow: hidden;">
			<div class="col p-0 auto-scroll">
				<slot></slot>
			</div>
		</div>
		<div id="editPaneFooter" class="flex-fixed p-2 b-t">
			<button id="editPaneSave" @click="doSave" :disabled="!(dirty && valid.all()) || canceling || saving"
				:class="{success: dirty && valid.all()}" class="btn ml-2 float-right position-relative">
				{{ $t('buttons.save') }}
				<span v-if="saving">
					&nbsp;
					<loading type="icon" />
				</span>
				<div id="editPaneSaveMouseover" class="disabled-mouseover"></div>
			</button>
			<button id="editPaneCancel" @click="doCancel" :disabled="!dirty || canceling || saving"
				:class="{ danger: dirty }" class="btn ml-2 float-right">
				{{ $t('buttons.cancel') }}
				<span v-if="canceling">
					&nbsp;
					<loading type="icon" />
				</span>
			</button>
			<button id="editPaneAutoBack" @click="goBack" :disabled="canceling || saving"
				class="btn primary float-right">
				<i class="fa fa-caret-left"></i> {{ $t('buttons.back') }}
			</button>
		</div>

		<b-popover target="editPaneSaveMouseover" triggers="hover" placement="left" v-if="!valid.all()">
			<template slot="title">Before you can save:</template>
			<ul class="pl-4 mb-0">
				<li v-for="(error, index) in valid.errors()" :key="index">{{ error }}</li>
			</ul>
		</b-popover>
	</div>
</template>

<script>
import ValidationService from "@/services/ValidationService";

export default {
	name: "EditPane",
	props: {
		cancel: { type: Function, default: () => { } },
		save: { type: Function, default: () => { } },
		dirty: { type: Boolean, default: false },
		valid: {
			type: Object,
			default: () => {
				return ValidationService.newValidator();
			}
		}
	},
	data() {
		return {
			saving: false,
			canceling: false
		};
	},
	created() { },
	methods: {
		doSave() {
			var savePromise = this.save();
			if (
				savePromise &&
				savePromise.finally &&
				typeof savePromise.finally == "function"
			) {
				this.saving = true;
				savePromise
					.then(() => {
						this.saving = false;
					})
					.catch(() => {
						this.saving = false;
					});
			} else {
				console.log("Save did not return promise");
			}
		},

		doCancel() {
			var cancelPromise = this.cancel();
			if (
				cancelPromise &&
				cancelPromise.finally &&
				typeof cancelPromise.finally == "function"
			) {
				this.canceling = true;
				cancelPromise
					.then(() => {
						this.canceling = false;
					})
					.catch(() => {
						this.canceling = false;
					});
			} else {
				console.log("Cancel did not return promise");
			}
		},

		goBack() {
			this.$router.go(-1);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.disabled-mouseover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
}
</style>
