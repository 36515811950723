import axios from "axios";

export default {
	getUserScheduleDays(projectID, date) {
		let ctx = {}
		if (projectID) {
			ctx.project_id = projectID
		}
		if (date) {
			ctx.date = date
		}
		return axios.get(`/v1/my_schedules`, { params: ctx })
	},

	saveUserScheduleDays(scheduleDays) {
		return axios.post(`/v1/my_schedules`, scheduleDays)
	},

	deleteUserScheduleDay(scheduleID) {
		return axios.delete(`/v1/my_schedules/${scheduleID}`)
	},

	getScheduleDays(projectID, date, userID) {
		let ctx = {}
		if (projectID) {
			ctx.project_id = projectID
		}
		if (date) {
			ctx.date = date
		}
		if (userID) {
			ctx.user_id = userID
		}
		return axios.get(`/v1/admin/schedules`, { params: ctx })
	},

	getScheduleDaysPrefilled(projectID, date, userID) {
		let ctx = {}
		if (projectID) {
			ctx.project_id = projectID
		}
		if (date) {
			ctx.date = date
		}
		if (userID) {
			ctx.user_id = userID
		}
		return axios.get(`/v1/admin/schedules/prefilled`, { params: ctx })
	},

	saveScheduleDays(scheduleDays) {
		return axios.post(`/v1/admin/schedules`, scheduleDays)
	},

	getScheduleConfigs(projectID, date) {
		let ctx = {}
		if (projectID) {
			ctx.project_id = projectID
		}
		if (date) {
			ctx.date = date
		}
		return axios.get(`/v1/admin/schedule_configs`, { params: ctx })
	},

	saveScheduleConfig(scheduleConfig) {
		return axios.post(`/v1/admin/schedule_configs`, scheduleConfig)
	},

	finishScheduling() {
		return axios.post(`/v1/finish_scheduling`)
	}
};
