<template>
	<div class="box" style="min-height: 406px">
		<div class="padding">
			<span class="float-right text-muted">
				<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
					<i class="fa fa-sync text-sm" :class="{'fa-spin': busy}"></i>
				</a>
			</span>
			<h6 class="mb-3">{{$t('Dashboard.recent_activity')}}</h6>
			<div class="streamline streamline-dotted mt-4">
				<div v-for="(act, i) in acts" :key="i" class="sl-item">
					<div class="sl-content">
						<span class="sl-date text-muted">{{fs.sinceString(act.time)}}</span>
						<div>
							<a v-if="hasSectionItem(act)" class="no-select btn-subtle float-right">
								<span v-tippy="{theme: 'popover', placement: 'top-end'}" :title="sectionItemTooltip(act)">
									<i class="fa fa-briefcase text-sm"></i>
									<i class="fa fa-book text-sm"></i>
								</span>
							</a>
							<span>{{getActivityString(act)}}</span>
							<span v-if="getActivityAside(act)" class="text-muted text-xs">{{getActivityAside(act)}}</span>
						</div>
					</div>
				</div>
				<!-- <div class="sl-item">
					<div class="sl-content">
						<span class="sl-date text-muted">08:00</span>
						<div>
							You logged in
						</div>
					</div>
				</div>-->
			</div>
		</div>
	</div>
</template>

<style scoped>
a.btn-subtle {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a.btn-subtle:hover {
	opacity: 1;
}
</style>

<script>
import ActivityService from "@/services/ActivityService";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

export default {
	name: "DashboardActivity",

	props: {
		size: {
			type: Number,
			default: 5
		}
	},

	data() {
		return {
			fs: fs,
			busy: false,
			acts: []
		};
	},
	created() {
		this.loadData();
	},
	watch: {},
	methods: {
		loadData() {
			this.busy = false;
			ActivityService.getOwnActivity(this.size)
				.then(resp => {
					this.acts = resp.data;
					this.busy = false;
				})
				.catch(err => {
					notie.error("Failed to load user activity", err);
					console.error(err);
					this.busy = false;
				})
		},

		getActivityString(act) {
			switch (act.activity_type) {
				case 1:
					return this.$i18n.t('Dashboard.you_logged_in');
				case 2:
					return this.$i18n.t('Dashboard.you_logged_out');
				case 3:
					return this.$i18n.t('Dashboard.scored_num_responses', {num: act.data && act.data.count});
			}
		},

		getActivityAside(act) {
			switch (act.activity_type) {
				case 3:
					return `(${fs.scoringMode(
						act.data && act.data.scoring_mode
					)})`;
				default:
					return null;
			}
		},

		hasSectionItem(act) {
			return act.data && act.data.section_name && act.data.item_name;
		},

		sectionItemTooltip(act) {
			return `
			<table class="tooltip-table">
			  <tr>
				<td>Section: </td>
				<td>${act.data.section_name}</td>
			  </tr>
			  <tr>
				<td>Item: </td>
				<td>${act.data.item_name}</td>
			  </tr>
			</table>`;
		}
	}
};
</script>
