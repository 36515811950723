
export function createIrisClient() {
    return (function (XDM) {

        XDM.init(window);

        var jQuery = XDM.getJQuery();

        var ready = [],
            instances = [];

        function checkReady(instance) {
            for (var i = ready.length; i--;) {
                var sourceFrame = ready[i];
                if (instance._frame === sourceFrame) {
                    instance._deferred.resolve();
                    ready.splice(i, 1);
                    return;
                }
            }
        }

        XDM.addListener('IRiS:ready', function () {
            var sourceFrame = this.source;  // don't yet have the channel set

            for (var i = instances.length; i--;) {
                var instance = instances[i];
                if (instance._frame === sourceFrame) {
                    instance._deferred.resolve();
                    return;
                }
            }

            ready.push(sourceFrame);
        });

        XDM.addListener('IRiS:unload', function () {
            var id = this.channel;

            for (var i = instances.length; i--;) {
                var instance = instances[i];
                if (id === instance.id) {
                    instance.dispose();
                    instances.splice(i, 1);
                    break;
                }
            }
        });

        XDM.addListener('IRiS:navUpdate', function (havePrev, haveNext) {
            var id = this.channel;

            for (var i = instances.length; i--;) {
                var instance = instances[i];
                if (id === instance.id) {
                    if (typeof instance.onnavUpdate === 'function') {
                        instance.onnavUpdate(havePrev, haveNext);
                    }
                    return;
                }
            }
        });

        XDM.addListener('IRiS:heightUpdate', function (contentHeight) {
            var id = this.channel;

            for (var i = instances.length; i--;) {
                var instance = instances[i];
                if (instance._options.autoFitToContents && id === instance.id) {
                    jQuery(instance._frameElement).css({ height: contentHeight + 'px' });
                    return;
                }
            }
        });

        var defaultOptions = {
            autoFitToContents: false
        };

        var _counter = 0;

        function Client(frame, options) {
            this.id = 'iris-client-' + ++_counter;

            this._options = null;

            this._frame = null;
            this._frameElement = null;

            this._xdm = null;

            this.onnavUpdate = null;

            this._deferred = jQuery.Deferred();
        }

        Client.prototype.dispose = function () {
            var error = new Error('frame has been disposed');
            var deferred = jQuery.Deferred();

            deferred.reject(error);
            this._deferred.reject(error);

            this._frame = null;
            this._frameElement = null;
        };

        function setFrame(client, frameWindow, options) {
            if (!frameWindow.postMessage) {
                frameWindow = frameWindow.contentWindow || frameWindow;
            }

            client._options = options || defaultOptions;

            client._frame = frameWindow;
            client._frameElement = jQuery('iframe').filter(function () {
                return this.contentWindow === frameWindow;
            }).get(0);

            client._xdm = XDM(client._frame);
            client._xdm.channel = client.id;

            checkReady(client);

            return client._deferred.promise();
        };

        Client.prototype.init = function () {
            return this._xdm.post('IRiS:init', this.id);
        };

        Client.prototype.loadToken = function (vendorId, token) {
            return this._xdm.post('IRiS:loadToken', vendorId, token);
        };

        Client.prototype.loadContent = function (vendorId, token) {
            return this._xdm.post('IRiS:loadContent', vendorId, token);
        };

        Client.prototype.setResponse = function (value) {
            return this._xdm.post('IRiS:setResponse', value);
        };

        Client.prototype.setResponses = function (itemResponses) {
            return this._xdm.post('IRiS:setResponses', itemResponses);
        };

        Client.prototype.getResponse = function () {
            return this._xdm.post('IRiS:getResponse');
        };

        Client.prototype.setReadOnly = function (enable) {
            return this._xdm.post('IRiS:setReadOnly');
        };

        Client.prototype.showNext = function () {
            return this._xdm.post('IRiS:showNext');
        };

        Client.prototype.showPrev = function () {
            return this._xdm.post('IRiS:showPrev');
        };

        Client.prototype.showPage = function (index) {
            return this._xdm.post('IRiS:showPage', index);
        };

        Client.prototype.showItem = function (id) {
            return this._xdm.post('IRiS:showItem', id);
        };

        Client.prototype.resetScroll = function (where) {
            return this._xdm.post('IRiS:resetScroll', where);
        };

        Client.prototype.printTest = function (headers) {
            return this._xdm.post('IRiS:printTest', headers);
        };

        Client.prototype.isPrintModeEnabled = function () {
            return this._xdm.post('IRiS:isPrintModeEnabled');
        };

        Client.prototype.showScores = function () {
            return this._xdm.post('IRiS:showScores');
        };

        Client.prototype.getData = function (name) {
            return this._xdm.post('IRiS:getData', name);
        };

        Client.prototype.suspend = function () {
            return this._xdm.post('IRiS:suspend');
        };

        Client.prototype.showTools = function () {
            return this._xdm.post('IRiS:showTools');
        };

        Client.prototype.resizeToFitContentHeight = function () {
            return this._xdm.post('IRiS:resizeToFitContentHeight');
        };

        function create(frame, options) {
            try {
                if (arguments.length < 1) {
                    throw new Error('must provide target frame or window');
                }

                var alreadyExists = instances.filter(function (client) {
                    return client._frame == frame || client._frameElement == frame;
                }).length > 0;

                if (alreadyExists) {
                    throw new Error('a client already exists for that frame');
                }

                var instance = new Client();

                instances.push(instance);

                return setFrame(instance, frame, options).then(function () {
                    return instance.init();
                }).then(function () {
                    return instance;
                });
            }
            catch (error) {
                var deferred = jQuery.Deferred();
                deferred.reject(error);

                return deferred.promise();
            }
        }

        var api = {};

        api.create = create;

        api.version = {
            major: 2,
            minor: 0
        };

        return api;

    })(window.Util && window.Util.XDM);
}