import axios from "axios";

export default {
	addImage(imageFile, progressFunc) {
		let form = new FormData();
		form.set("file", imageFile);
		return axios.post("/v1/image", form, {
			headers: { "Content-Type": undefined },
			onUploadProgress: progressFunc
		});
	},

	removeImage(imageURL) {
		return axios.delete(`/v1/image`, { params: { url: imageURL } });
	}
};