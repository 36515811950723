<!-- Template for a new page -->
<template>
	<div
		id="videoFeeds"
		ref="videoFeeds"
		class="flex d-flex flex-row flex-wrap justify-content-center h-100 video-feeds"
	>
		<div
			v-for="parti in participants"
			:key="parti.uid"
			class="video-feed-container"
			:style="{ width: `${feedWidth}px`, height: `${feedHeight}px` }"
		>
			<video-feed :uiState="uiState" :participant="parti" class="video-feed" />
		</div>
	</div>
</template>

<style scoped>
.video-feeds {
	padding-top: 10px;
	padding-left: 10px;
}
.video-feed-container {
	margin-right: 10px;
	margin-bottom: 10px;
}
</style>

<script>
//UI Components
import VideoFeed from "@/vues/VideoMeeting/components/VideoFeed";

//Libraries
import _ from "lodash";

//Services

export default {
	name: "VideoLayoutGallery",
	props: ["participants", "uiState"],
	components: { VideoFeed },
	data() {
		return {
			feedWidth: 480,
			feedHeight: 272,
		};
	},
	created() {
		window.addEventListener("resize", this.windowResize);
	},
	mounted() {
		this.calculateLayout();
	},
	destroyed() {
		window.removeEventListener("resize", this.windowResize);
	},

	computed: {},

	watch: {
		"uiState.sidebarTab"() {
			this.calculateLayout();
		},
		participants() {
			this.calculateLayout();
		},
	},

	methods: {
		userJoined(user) {
			console.log("User joined, recalculate layout");
			this.calculateLayout();
		},

		userLeft(user) {
			console.log("User left, recalculate layout");
			this.calculateLayout();
		},

		calculateLayout() {
			// Generate all possible widths of feeds within the gallery.
			//
			// For any given configuration, the feeds will either touch the bottom/top or left/right of the container,
			// which means we only need to test configurations where the total width or total height are divided by a
			// integer number within the range of the number of feeds.
			//
			// As such, we first generate all possible feed widths to test - first by dividing the screen width by each
			// possible number of feeds (representing layouts that have x number of columns where the feeds are
			// left/right contrained) and then by dividing the screen height by each possible number of feeds
			// (representing layouts that have x number of rows where the feeds are top/bottom contrained). Then we sort
			// those test widths from largest to smallest, and test them in order until we find one that fits. As a
			// result, we should have a feed width that creates a layout where each feed is as large as it can be.
			let testWidths = [];
			let el = this.$refs.videoFeeds;
			if (!el) {
				console.error("Could not layout feeds because container was not found");
				return;
			}

			let numFeeds = this.participants.length;

			let paddingX = 10;
			let paddingY = 10;
			let containerWidth = el.offsetWidth - paddingX;
			let containerHeight = el.offsetHeight - paddingY;
			let aspectRatio = 848 / 480;
			let widthToHeight = (width) => {
				return width / aspectRatio;
			};
			let heightToWidth = (height) => {
				return height * aspectRatio;
			};

			let getLayoutSize = (columns) => {
				let feedContainerWidth = containerWidth / columns;
				let feedWidth = feedContainerWidth - paddingX;
				let rows = Math.ceil(numFeeds / columns);
				let feedContainerHeight = containerHeight / rows;
				let feedHeight = feedContainerHeight - paddingY;

				let containerAspectRatio = feedWidth / feedHeight;
				if (containerAspectRatio > aspectRatio) {
					return {
						columns: columns,
						rows: rows,
						feedWidth: feedWidth,
						feedHeight: feedHeight,
						videoWidth: feedHeight * aspectRatio,
						videoHeight: feedHeight,
					};
				} else {
					return {
						columns: columns,
						rows: rows,
						feedWidth: feedWidth,
						feedHeight: feedHeight,
						videoWidth: feedWidth,
						videoHeight: feedWidth / aspectRatio,
					};
				}
			};

			let possibleLayouts = [];
			for (let i = 1; i <= numFeeds; i++) {
				possibleLayouts.push(getLayoutSize(i));
			}

			for (let i = 1; i <= numFeeds; i++) {
				let testWidth = containerWidth / i;
				let testHeight = containerHeight / i;

				testWidths.push(testWidth);
				testWidths.push(heightToWidth(testHeight));
			}
			// Sort descending by width of the video inside
			possibleLayouts.sort((a, b) => {
				return b.videoWidth - a.videoWidth;
			});
			console.log("LAYOUTS, RANKED", possibleLayouts);
			this.feedWidth = possibleLayouts[0].feedWidth;
			this.feedHeight = possibleLayouts[0].feedHeight;
		},

		doesLayoutFit(numFeeds, feedWidth, feedHeight, containerWidth, containerHeight) {
			let columns = Math.floor(containerWidth / feedWidth);
			let numRows = Math.ceil(numFeeds / columns);
			return feedHeight * numRows <= containerHeight;
		},

		windowResize() {
			this.calculateLayout();
			this.$forceUpdate();
		},
	},
};
</script>