import axios from "axios";
import AuthService from "@/services/AuthService";
import moment from "moment";
import socketio from "socket.io-client";

import PrettyLogger from "@/services/PrettyLogger";
const { log, debug, logWarn, logError } = PrettyLogger.forName("VideoMeetingService");

export default {
	getMyMeetings() {
		return axios.get(`/v1/my/video_meetings`);
	},

	getMyMeetingEvents(start, end) {
		return axios.get(`/v1/my/video_meeting_events`, { params: { start: start, end: end } });
	},

	saveMeeting(meeting) {
		return axios.post(`/v1/admin/video_meetings`, meeting);
	},

	updateMeetingTimes(meetingID, startTime, endTime) {
		let update = {
			meeting_id: meetingID,
			start_time: startTime,
			end_time: endTime,
		};

		return axios.post(`/v1/admin/video_meetings/times`, update);
	},

	adminGetMeeting(meetingID) {
		return axios.get(`/v1/admin/video_meetings/${meetingID}`);
	},

	deleteMeeting(meetingID) {
		return axios.delete(`/v1/admin/video_meetings/${meetingID}`);
	},

	getMeeting(meetingID) {
		return axios.get(`/v1/video_meetings/${meetingID}`);
	},

	anonGetMeeting(meetingID, password, username) {
		if (!username) {
			username = "Pseudo-User Joining";
		}
		let headers = {
			"X-Meeting-Password": password,
			"X-Meeting-Username": username,
		};
		return axios.get(`/v1/anon/video_meetings/${meetingID}`, { headers: headers });
	},

	getMeetingUsers(meetingID) {
		return axios.get(`/v1/video_meetings/${meetingID}/users`);
	},

	anonGetMeetingUsers(meetingID, password, username) {
		if (!username) {
			username = "Pseudo-User Joining";
		}
		let headers = {
			"X-Meeting-Password": password,
			"X-Meeting-Username": username,
		};
		return axios.get(`/v1/anon/video_meetings/${meetingID}/users`, { headers: headers });
	},

	joinMeeting(meetingID) {
		return axios.get(`/v1/video_meetings/${meetingID}/join`);
	},

	anonJoinMeeting(meetingID, password, username) {
		if (!username) {
			username = "Pseudo-User Joining";
		}
		let headers = {
			"X-Meeting-Password": password,
			"X-Meeting-Username": username,
		};
		return axios.get(`/v1/anon/video_meetings/${meetingID}/join`, { headers: headers });
	},

	getChatHistory(meetingID) {
		return axios.get(`/v1/video_meetings/${meetingID}/chat_messages`);
	},

	anonGetChatHistory(meetingID, password, username) {
		if (!username) {
			username = "Pseudo-User Joining";
		}
		let headers = {
			"X-Meeting-Password": password,
			"X-Meeting-Username": username,
		};
		return axios.get(`/v1/anon/video_meetings/${meetingID}/chat_messages`, { headers: headers });
	},

	getAccessToken(meetingID) {
		return axios.get(`/v1/video_meetings/${meetingID}/join`);
	},

	getMessagingWebsocket(meetingID) {
		return new Promise((resolve, reject) => {
			AuthService.getSecondaryAuthToken()
				.then((r) => {
					debug("response", r);
					let token = r.data.data;

					const socket = socketio({
						path: `/ws/video_meeting`,
						transports: ["websocket"],
					});
					socket.on("connect_error", (err) => {
						logError(`connect_error due to ${err.message}`);
					});
					debug("connect to socket");
					socket.on("connect", () => {
						logWarn("Messaging Websocket Connected");
						socket.emit("auth", JSON.stringify({ meeting_id: meetingID, token: token }));
						resolve(socket);
					});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},

	async anonGetMessagingWebsocket(meetingID, password, username) {
		debug("anonGetMessagingWebsocket", meetingID, password, username);
		if (!username) {
			username = "Pseudo-User Joining";
		}
		return new Promise((resolve, reject) => {
			const socket = socketio({
				path: `/ws/video_meeting`,
				transports: ["websocket"],
			});
			debug("TRY TO CONNECT");
			socket.on("connect", () => {
				logWarn("Messaging Websocket Connected");
				socket.emit(
					"auth",
					JSON.stringify({
						meeting_id: meetingID,
						password: password,
						username: username,
					})
				);
				resolve(socket);
			});
		});
	},

	getMeetingAttendee(meetingID, attendeeID, password, username) {
		if (password) {
			if (!username) {
				username = "Pseudo-User Joining";
			}
			let headers = {
				"X-Meeting-Password": password,
				"X-Meeting-Username": username,
			};
			return axios.get(`/v1/anon/video_meetings/${meetingID}/attendee/${attendeeID}`, { headers: headers });
		} else {
			return axios.get(`/v1/video_meetings/${meetingID}/attendee/${attendeeID}`);
		}
	},

	submitFeedback(msg) {
		return axios.post(`/v1/anon/video_meetings/feedback`, msg);
	},

	createMockMeeting() {
		return axios.post(`/v1/admin/video_meetings/create_mock`);
	},

	meetingInProgress(meeting) {
		if (!meeting) return false;

		let now = moment();
		let startTime = moment(meeting.start_time);
		let endTime = moment(meeting.end_time);
		let withinScheduled = now.isAfter(startTime.subtract(5, "minutes")) && now.isBefore(endTime);
		let withinActual = meeting.started_at && !meeting.ended_at;

		debug("withinScheduled", withinScheduled);
		debug("withinActual", withinActual, meeting.started_at, meeting.ended_at);

		return withinScheduled || withinActual;
	},

	setAttendeeAudioCapability(meetingID, attendeeID, audio) {
		let cap = {
			audio: audio,
		};
		return axios.post(`/v1/admin/video_meetings/${meetingID}/attendee/${attendeeID}/capabilities`, cap);
	},

	setAttendeeVideoCapability(meetingID, attendeeID, video) {
		let cap = {
			video: video,
		};
		return axios.post(`/v1/admin/video_meetings/${meetingID}/attendee/${attendeeID}/capabilities`, cap);
	},

	setAttendeeScreenshareCapability(meetingID, attendeeID, content) {
		let cap = {
			content: content,
		};
		return axios.post(`/v1/admin/video_meetings/${meetingID}/attendee/${attendeeID}/capabilities`, cap);
	},

	setAttendeeChatCapability(meetingID, attendeeID, chat) {
		let cap = {
			chat: chat,
		};
		return axios.post(`/v1/admin/video_meetings/${meetingID}/attendee/${attendeeID}/chat_capabilities`, cap);
	},

	removeAttendee(meetingID, attendeeID) {
		return axios.delete(`/v1/admin/video_meetings/${meetingID}/attendee/${attendeeID}`);
	},

	deleteChatMessage(meetingID, messageID) {
		return axios.delete(`/v1/admin/video_meetings/${meetingID}/chat_messages/${messageID}`);
	},
};
