<template>
	<div>
		<div class="h-100">
			<div style="height: 90%">
				<span class="float-right text-muted">
					<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
						<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
					</a>
				</span>
				<table style="width: 100%; height: 100%; table-layout: fixed">
					<tr style="height: 50%">
						<td class="text-center v-mid">
							<h1 :class="{ 'text-very-muted': !totals.irr_exact || busy }">
								{{ fs.fixedPercent1d(totals.irr_exact_percent) }}
							</h1>
							<h6 class="text-muted mb-0">{{ $t("fields.irr") }}</h6>
							<div class="text-muted">{{ $t("fields.exact_paren") }}</div>
						</td>
						<td style="width: 1rem"></td>
						<td class="text-center v-mid" v-if="totals.val_total && showVal">
							<h1 :class="{ 'text-very-muted': !totals.val_exact || busy }">
								{{ fs.fixedPercent1d(totals.val_exact_percent) }}
							</h1>
							<h6 class="text-muted mb-0">{{ $t("fields.validity") }}</h6>
							<div class="text-muted">{{ $t("fields.exact_paren") }}</div>
						</td>
					</tr>
					<tr style="height: 50%">
						<td class="text-center v-mid">
							<h1 :class="{ 'text-very-muted': !totals.irr_exact_adj || busy }">
								{{ fs.fixedPercent1d(totals.irr_exact_adj_percent) }}
							</h1>
							<h6 class="text-muted mb-0">{{ $t("fields.irr") }}</h6>
							<div class="text-muted">{{ $t("fields.exact_adjacent_paren") }}</div>
						</td>
						<td style="width: 1rem"></td>
						<td class="text-center v-mid" v-if="totals.val_total && showVal">
							<h1 :class="{ 'text-very-muted': !totals.val_exact_adj || busy }">
								{{ fs.fixedPercent1d(totals.val_exact_adj_percent) }}
							</h1>
							<h6 class="text-muted mb-0">{{ $t("fields.validity") }}</h6>
							<div class="text-muted">{{ $t("fields.exact_adjacent_paren") }}</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<style scoped>
a > h1 {
	border-radius: 0.5rem;
	transition: background-color 0.25s;
	padding-bottom: 2px;
}
a > h1:hover {
	background-color: rgb(0, 0, 0, 0.05);
}
table {
	width: 100%;
}
td {
	padding: 0.25rem 0.5rem;
	text-overflow: ellipsis;
}
.refresh {
	position: absolute;
	top: 2rem;
	right: 2rem;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<script>
import ReportingService from "@/services/ReportingService";
import fs from "@/services/FormatService";
import ConfigDate from "@/components/ConfigDate";
import WeekSelect from "@/components/WeekSelect";

require("@/directives/chartjs");

export default {
	name: "SuperDashAgreement",

	props: ["team", "fromDate", "toDate"],

	components: { ConfigDate, WeekSelect },

	data() {
		return {
			fs: fs,
			busy: false,
			showVal: true,
			totals: {},
			stats: {},
			totals: {},
		};
	},
	created() {
		this.loadData();
	},
	watch: {
		"team.id"() {
			this.loadData();
		},

		fromDate() {
			this.loadData();
		},

		toDate() {
			this.loadData();
		},
	},
	methods: {
		loadData() {
			const { team, toDate, fromDate } = this;

			if (!team || !(toDate && fromDate)) {
				return;
			}

			this.busy = true;
			ReportingService.getAgreementTeams(team.id, fromDate, toDate)
				.then((resp) => {
					this.totals = resp.data.totals;
					this.showVal = !this.labels || this.labels.length == 0;
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});
		},
	},
};
</script>
