<template>
	<div class="d-flex flex-row align-items-center position-relative">
		<div class="meeting-avatar-small ml-2 my-1">
			<h6 class="mb-0">
				<i
					class="fas fa-lg fa-presentation text-white"
					style="margin-bottom: -10px; line-height: 28px"
					v-tippy
					:title="$t('VideoMeeting.sharing_screen')"
				/>
			</h6>
		</div>
		<div class="mx-2 flex overflow-hidden">
			<div>{{ participant.name }}'s Screenshare</div>
		</div>
	</div>
</template>

<style scoped>
.icon-container {
	width: 24px;
	text-align: center;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import Notie from "@/services/NotieService";

export default {
	name: "ParticipantListRow",
	props: {
		engine: true,
		participant: true,
	},
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},

	watch: {},

	computed: {},

	methods: {},
};
</script>