<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					<i
						class="fas fa-fw fa-lock-alt"
						v-if="isScored"
						v-tippy="{ placement: 'bottom-start' }"
						:title="$t('ResGroupEdit.options_cannot_change')"
					></i>
					{{ resGroup.name }}
				</div>
			</div>
		</template>
		<div class="h-100" v-if="!loading">
			<edit-pane :cancel="loadData" :save="saveResGroup" :dirty="dirty" :valid="valid">
				<div class="padding">
					<!-- Page content goes here -->
					<div v-if="resGroup" class="row m-0">
						<!-- Resolution settings -->
						<div class="col-md-4">
							<div class="form-group" :class="{ invalid: !valid.field('name') }">
								<label>{{ $t("ResGroupEdit.group_name") }}</label>
								<input type="text" class="form-control" v-model="resGroup.name" />
							</div>
							<div class="form-group" :class="{ invalid: !valid.field('type') }">
								<label>{{ $t("ResGroupEdit.res_type") }}</label>
								<config-select
									:options="res_types"
									byField="id"
									v-model="resGroup.type"
									:disabled="isScored"
								></config-select>
							</div>
							<div class="form-group row mx-0 align-bottom" v-if="resGroup.type == 'DOUBLE_WEIGHTED'">
								<div class="p-0 col-12 mt-auto mb-2">
									<label>{{ $t("ResGroupEdit.max_weight_diff") }}</label>
									<input
										type="number"
										class="form-control"
										v-model.number="resGroup.weighted_resolution_threshold"
										:disabled="isScored"
									/>
								</div>
								<div class="p-0 col-6 mt-auto mb-2">
									<label>{{ $t("ResGroupEdit.range_min") }}</label>
									<input
										type="number"
										class="form-control"
										v-model.number="resGroup.weighted_resolution_range_min"
										:disabled="isScored"
									/>
								</div>
								<div class="p-0 pl-2 col-6 mt-auto mb-2">
									<label>{{ $t("ResGroupEdit.range_max") }}</label>
									<input
										type="number"
										class="form-control"
										v-model.number="resGroup.weighted_resolution_range_max"
										:disabled="isScored"
									/>
								</div>
							</div>
							<div class="form-group row mx-0 align-bottom" v-show="resGroup.type == 'OMAHA'">
								<div class="p-0 col-12 mt-auto checkbox mb-1">
									<label class="ui-check">
										<input type="checkbox" v-model="resGroup.omaha_min_max" :disabled="isScored" />
										<i class="dark-white"></i> {{ $t("ResGroupEdit.resolve_min_max") }}
									</label>
								</div>
								<div class="p-0 col-6 mt-auto mb-2">
									<label>{{ $t("ResGroupEdit.max_non_adjacent") }}</label>
									<input
										type="number"
										class="form-control"
										v-model.number="resGroup.omaha_max_non_adj"
										:disabled="isScored"
									/>
								</div>
								<div class="p-0 pl-2 col-6 mt-auto mb-2">
									<label>{{ $t("ResGroupEdit.max_score_diff") }}</label>
									<input
										type="number"
										class="form-control"
										v-model.number="resGroup.omaha_max_weighted_score_diff"
										:disabled="isScored"
									/>
								</div>
							</div>
						</div>
						<!-- Element list -->

						<div class="col-md-8 b-l">
							<label>{{ $t("ResGroupEdit.group_elements") }}</label>
							<table class="table mb-0" style="table-layout: fixed">
								<thead>
									<tr>
										<th style="width: 12px" class="p-custom p-0"></th>
										<th style="width: 50%">{{ $t("fields.section") }}</th>
										<th style="width: 50%">{{ $t("fields.item") }}</th>
										<th style="width: 40px"></th>
									</tr>
								</thead>
								<draggable
									v-model="resGroup.elements"
									:options="{
										handle: '.drag-handle',
										animation: 150,
									}"
									@end="dragEnd"
									:element="'tbody'"
								>
									<tr v-for="(element, i) in resGroup.elements" :key="i">
										<td
											:key="`c0-${i}`"
											class="p-custom pl-2 p-0 drag-handle v-mid"
											style="flex-grow: 0"
										>
											<i class="far fa-sort text-muted"></i>
										</td>
										<td
											:key="`c1-${i}`"
											class="v-mid"
											:class="{
												invalid: !element.section_id,
												'invalid-b': !element.section_id,
											}"
										>
											<config-select
												:options="sections"
												v-model="element.section_id"
												hideNone
												byField="id"
												@input="sectionChanged(i)"
												:disabled="isScored"
											/>
										</td>
										<td
											:key="`c2-${i}`"
											class="v-mid"
											:class="{
												invalid: !element.item_id,
												'invalid-b': !element.item_id,
											}"
										>
											<config-select
												:options="element.items"
												v-model="element.item_id"
												hideNone
												byField="id"
												@input="itemChanged(i)"
												:disabled="isScored"
											/>
										</td>
										<td :key="`c3-${i}`" class="v-mid">
											<button
												@click="removeElement(element)"
												class="btn btn-sm btn-icon btn-rounded red text-white m-0"
												:title="$t('tooltip.remove_element')"
												:disabled="isScored"
											>
												<i class="fa fa-trash"></i>
											</button>
										</td>
									</tr>
									<template slot="header">
										<tr v-if="!resGroup.elements.length">
											<td colspan="4" class="text-center">
												{{ $t("data_description.no_elements") }}
											</td>
										</tr>
									</template>
								</draggable>
							</table>
							<div class="d-flex flex-row justify-content-stretch" style="padding: 0.6rem 0.75rem">
								<button
									@click="addElement()"
									class="flex btn btn-sm btn-success mr-3"
									:disabled="isScored"
								>
									{{ $t("buttons.add_element") }}
								</button>
							</div>
						</div>

						<hr class="w-100" />

						<label>{{ $t("ResGroupEdit.combined_rubric") }}</label>
						<div class="col-12 border">
							<div class="px-4 pt-2" v-if="resGroup.elements.length > 0 && combinedRubric">
								<div class="pr-4" style="display: inline-block; vertical-align: top">
									<rubric
										:outer-rubric="combinedRubric"
										:score.sync="simRubric.leftScore"
										:complete.sync="simRubric.leftComplete"
										:reset="simRubric.leftReset"
										:user="user"
										noPages
									></rubric>
									<div class="my-2" v-if="simRubric.leftWeight != null">
										{{ $t("ResGroupEdit.weighted_score") }}
										<span class="_600 text-theme" v-if="!simRubric.leftLoading">
											{{ simRubric.leftWeight }}
										</span>
										<loading v-if="simRubric.leftLoading" type="icon" />
									</div>
								</div>
								<div
									style="display: inline-block; vertical-align: top"
									v-if="shouldCompareRubrics(resGroup.type)"
								>
									<rubric
										:outer-rubric="combinedRubric"
										:score.sync="simRubric.rightScore"
										:complete.sync="simRubric.rightComplete"
										:reset="simRubric.rightReset"
										:user="user"
										noPages
									></rubric>
									<div class="my-2" v-if="simRubric.rightWeight != null">
										{{ $t("ResGroupEdit.weighted_score") }}
										<span class="_600 text-theme" v-if="!simRubric.rightLoading">
											{{ simRubric.rightWeight }}
										</span>
										<loading v-if="simRubric.rightLoading" type="icon" />
									</div>
								</div>
								<div class="w-100 pb-4">
									<div>
										{{ $t("ResGroupEdit.needs_resolution") }}
										<template v-if="resGroup.type">
											<span
												v-if="!simRubric.resLoading && simRubric.needsRes == null"
												class="_600 text-theme"
												>?</span
											>
											<span
												v-if="!simRubric.resLoading && simRubric.needsRes == true"
												class="_600 text-theme"
											>
												{{ $t("data_description.yes") }}
											</span>
											<span
												v-if="!simRubric.resLoading && simRubric.needsRes == false"
												class="_600 text-theme"
											>
												{{ $t("data_description.no") }}
											</span>
											<loading v-if="simRubric.resLoading" type="icon" />
										</template>
										<span v-if="!resGroup.type" class="_600 text-danger">
											{{ $t("data_description.no_rule_selected") }}
										</span>
									</div>
								</div>
							</div>
							<h1 v-if="this.resGroup.elements.length == 0" class="my-3 text-center text-very-muted">
								{{ $t("data_description.no_elements_in_group") }}
							</h1>
							<div
								v-if="this.resGroup.elements.length > 0 && !combinedRubric"
								class="my-3 text-center text-extra-muted"
							>
								<loading type="large" />
							</div>
						</div>
					</div>
				</div>
			</edit-pane>
			<save-optional-modal
				:dirty="dirty"
				:valid="valid"
				:save="saveResGroup"
				:next="saveOptNext"
				:cancel="saveOptCancel"
				objectText="Resolution Group"
				:objectName="resGroup.name"
				actionText="leave the page"
				v-model="saveOptModal"
			/>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w90 {
	min-width: 116px;
}

.w100 {
	width: 90px;
}

.modal-lg {
	max-width: 1000px;
}

.drag-handle {
	cursor: move;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import ResGroupService from "@/services/ResGroupService";
import ConfigService from "@/services/ConfigService";
import SectionService from "@/services/SectionService";
import ValidationService from "@/services/ValidationService";
import AuthService from "@/services/AuthService";
import Notie from "../../services/NotieService";
import fs from "@/services/FormatService";

import ConfigTrait from "../../components/ConfigTrait";
import ConfigSelect from "../../components/ConfigSelect";
import Rubric from "../../components/Rubric";
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";
import RichText from "@/components/RichText";
import draggable from "vuedraggable";
import BB from "bluebird";

export default {
	name: "ResGroupEdit",
	data() {
		return {
			fs: fs,
			resGroup: {},
			resGroups: [],
			itemMap: [],
			validElements: false,
			usedCombos: {},
			isScored: false,
			dirty: false,
			loading: true,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
			user: null,

			combinedRubric: null,
			simRubric: {
				show: false,
				leftScore: {},
				leftComplete: null,
				rightScore: {},
				rightComplete: null,
				leftWeight: null,
				rightWeight: null,
				needsRes: null,
				leftLoading: false,
				rightLoading: false,
				resLoading: false,
				leftReset: 0,
				rightReset: 0,
			},
			res_types: [
				{
					id: "SINGLE",
					name: this.$i18n.t("res_types.single"),
				},
				{
					id: "DOUBLE_ONLY",
					name: this.$i18n.t("res_types.double_only"),
				},
				{
					id: "DOUBLE_EXACT",
					name: this.$i18n.t("res_types.double_exact"),
				},
				{
					id: "DOUBLE_EXACT_ADJACENT",
					name: this.$i18n.t("res_types.double_exact_adjacent"),
				},
				{
					id: "DOUBLE_WEIGHTED",
					name: this.$i18n.t("res_types.double_weighted"),
				},
				{
					id: "OMAHA",
					name: this.$i18n.t("res_types.omaha"),
				},
				{
					id: "THREE_READS",
					name: this.$i18n.t("res_types.three_reads"),
				},
				{
					id: "ADJACENT_EXACT_UNDER_THREE",
					name: this.$i18n.t("res_types.adjacent_exact_under_three"),
				},
			],
		};
	},

	components: {
		ConfigTrait,
		ConfigSelect,
		Rubric,
		EditPane,
		SaveOptionalModal,
		RichText,
		draggable,
	},

	created() {
		this.loadData();
		this.initValidation();
		AuthService.getUser().then((user) => {
			this.user = user;
		});
	},

	computed: {},

	watch: {
		"simRubric.leftScore"() {
			if (this.simRubric.leftComplete) {
				this.simRubric.leftLoading = true;
				this.checkSim();
			} else {
				this.simRubric.needsRes = null;
			}
		},
		"simRubric.rightScore"() {
			if (this.simRubric.rightComplete) {
				this.simRubric.rightLoading = true;
				this.checkSim();
			} else {
				this.simRubric.needsRes = null;
			}
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: "You must assign a name",
					func: () => {
						return this.resGroup && this.resGroup.name && this.resGroup.name != "";
					},
				},
				type: {
					group: "details",
					errorMsg: "You must choose a resolution rule",
					func: () => {
						return this.resGroup && this.resGroup.type;
					},
				},
				rubric: {
					group: "details",
					errorMsg: "All elements of the group must be valid section/item combos",
					func: () => {
						return this.resGroup && this.validElements;
					},
				},
			});
		},

		blankResGroup() {
			return {
				name: "",
				elements: [],
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"resGroup",
				(newc, old) => {
					// console.log(Utils.diff(newc, old));
					// console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;
			this.itemMap = [];

			if (this.$route.params.id == "new") {
				this.resGroup = this.blankResGroup();
				this.$set(this.resGroup, "name", this.$i18n.t("ResGroupEdit.new_group_name"));
				this.loading = false;
				this.watchChanges();

				BB.props({
					sections: ConfigService.listSectionsShallow(),
					resGroups: ResGroupService.listResGroups(),
				})
					.then((resps) => {
						console.log("Got sectionsa", resps.sections.data.sections);
						this.sections = resps.sections.data.sections;
						this.resGroups = resps.resGroups.data.res_groups;
						this.constructUsedCombos();
					})
					.catch((err) => {
						console.log(err);
						Notie.error("Failed to load data", err);
					});
			} else {
				let id = this.$route.params.id;
				return BB.props({
					sections: ConfigService.listSectionsShallow(),
					resGroups: ResGroupService.listResGroups(),
					resGroup: ResGroupService.getResGroup(id),
				})
					.then((resps) => {
						console.log("Got sections", resps.sections.data.sections);
						this.sections = resps.sections.data.sections;

						this.resGroup = resps.resGroup.data;
						_.each(this.resGroup.elements, (element, i) => {
							element.items = [{ id: element.item_id, name: "" }];
							this.sectionChanged(i);
						});

						this.resGroups = resps.resGroups.data.res_groups;
						this.constructUsedCombos();

						this.loadCombinedRubric();

						this.loading = false;
						this.watchChanges();
					})
					.catch((err) => {
						console.log(err);
						Notie.error("Resolution group not found, adding new resolution group instead", err);
						this.$router.replace("/resolution_groups/new");
					});
			}
		},

		constructUsedCombos() {
			this.usedCombos = {};
			_.each(this.resGroups, (resGroup) => {
				console.log("Check ids", this.resGroup.id, resGroup.id, this.resGroup.id == resGroup.id);
				if (this.resGroup.id == resGroup.id) {
					return;
				}

				_.each(resGroup.elements, (element) => {
					let slug = `${element.section_id}-${element.item_id}`;
					this.usedCombos[slug] = true;
				});
			});
		},

		loadCombinedRubric() {
			let validResGroup = _.cloneDeep(this.resGroup);
			validResGroup.elements = _.filter(validResGroup.elements, (el) => {
				return el.item_id;
			});
			if (validResGroup.elements.length == 0) {
				this.combinedRubric = null;
				return;
			}
			//Check rubric for validity
			ResGroupService.getResGroupRubric(validResGroup)
				.then((r) => {
					this.combinedRubric = r.data;
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to combine rubric", e);
				});
		},

		saveResGroup() {
			return ResGroupService.saveResGroup(this.resGroup)
				.then((resp) => {
					Notie.info("Resolution Group Saved");
					this.$router.replace("/resolution_groups/" + resp.data.id);
					this.loadData();
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to save resolution group", err);
				});
		},

		sectionChanged(i) {
			console.log("Section changed", i);
			if (this.unwatch) {
				this.unwatch();
			}
			let element = this.resGroup.elements[i];
			return BB.props({
				section: ConfigService.getSection(element.section_id),
				scored: SectionService.getScoredItems(element.section_id),
			})
				.then((resps) => {
					console.log("RESPS", resps);
					element.items = resps.section.data.items;
					element.scoredItems = resps.scored.data;

					this.filterItems();
					this.$forceUpdate();
					this.watchChanges();
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to get section", err);
					this.watchChanges();
				});
		},

		filterItems() {
			_.each(this.resGroup.elements, (el) => {
				if (!el.section_id) {
					return;
				}

				let usedRubrics = {};
				_.each(this.resGroup.elements, (innerEl) => {
					if (el === innerEl) {
						return;
					}

					_.each(innerEl.items, (item) => {
						if (item.selected) {
							usedRubrics[item.rubric_id] = true;
						}
					});
				});

				_.each(el.items, (item) => {
					delete item.disabled;
					delete item.desc;

					let slug = `${el.section_id}-${item.id}`;
					if (this.usedCombos[slug]) {
						item.disabled = true;
						item.desc = "Section/Item combo in use by another resolution group";
					} else if (usedRubrics[item.rubric_id]) {
						item.disabled = true;
						item.desc = "Rubric has already been used in this resolution rule";
					} else if (el.scoredItems && el.scoredItems[item.id]) {
						item.disabled = true;
						item.desc = "Section/Item combo has already been used for scoring";

						if (item.id == el.item_id) {
							this.isScored = true;
						}
					}
				});
			});
		},

		itemChanged() {
			this.unwatch();
			_.each(this.resGroup.elements, (element) => {
				_.each(element.items, (item) => {
					if (item.id == element.item_id) {
						item.selected = true;
					}
				});
			});
			this.watchChanges();
			this.combinedRubric = null;
			this.checkValidElements();
			this.loadCombinedRubric();
			this.filterItems();
		},

		checkValidElements() {
			let elements = this.resGroup.elements;

			let slugMap = {};
			for (let el of elements) {
				if (!(el.section_id && el.item_id)) {
					this.validElements = false;
					return;
				}
				let slug = `${el.section_id}-${el.item_id}`;
				if (slugMap[slug]) {
					this.validElements = false;
					return;
				}
			}

			this.validElements = true;
		},

		addElement() {
			this.resGroup.elements.push({
				section_id: null,
				item_id: null,
				sequence: this.resGroup.elements.length,
			});

			this.checkValidElements();
		},

		dragEnd() {
			let i = 0;
			_.each(this.resGroup.elements, (el) => {
				el.sequence = i;
				i++;
			});
		},

		removeElement(element) {
			let index = this.resGroup.elements.indexOf(element);
			if (index >= 0) {
				this.$delete(this.resGroup.elements, index);
			}
			this.checkValidElements();
			this.loadCombinedRubric();
		},

		shouldCompareRubrics(scoringRule) {
			switch (scoringRule) {
				case "SINGLE":
					return false;
				case "DOUBLE_ONLY":
					return true;
				case "DOUBLE_EXACT":
					return true;
				case "DOUBLE_EXACT_ADJACENT":
					return true;
				case "DOUBLE_WEIGHTED":
					return true;
				case "OMAHA":
					return true;
				case "THREE_READS":
					return true;
				case "ADJACENT_EXACT_UNDER_THREE":
					return true;
			}
		},

		clearSim() {
			console.log("=== CLEARING SIM ===");
			this.simRubric.leftReset++;
			this.simRubric.rightReset++;
			this.simRubric.leftComplete = null;
			this.simRubric.rightComplete = null;
			this.simRubric.leftWeight = null;
			this.simRubric.rightWeight = null;
			this.simRubric.needsRes = null;
			this.simRubric.leftLoading = false;
			this.simRubric.rightLoading = false;
			this.simRubric.resLoading = false;
		},

		checkSim() {
			if (!(this.combinedRubric && this.resGroup.type)) {
				return;
			}

			let first = this.simRubric.leftComplete ? this.simRubric.leftScore : null;
			let second = this.simRubric.rightComplete ? this.simRubric.rightScore : null;

			this.simRubric.resLoading = true;
			ResGroupService.simResGroupScorePair(this.resGroup, first, second)
				.then((resp) => {
					if (resp.data.has_weight) {
						if (this.simRubric.leftComplete) {
							this.simRubric.leftWeight = resp.data.first_weight;
						}
						if (this.simRubric.rightComplete) {
							this.simRubric.rightWeight = resp.data.second_weight;
						}
					}
					if (resp.data.compared) {
						this.simRubric.needsRes = resp.data.needsRes;
					}
					this.simRubric.leftLoading = false;
					this.simRubric.rightLoading = false;
					this.simRubric.resLoading = false;
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to simulate scores", err);
					this.simRubric.leftLoading = false;
					this.simRubric.rightLoading = false;
					this.simRubric.resLoading = false;
				});
		},
	},
};
</script>
