<template>
	<div class="box" style="min-height: 406px">
		<div class="p-3">
			<div class="box-header">
				<span class="float-right text-muted">
					<a @click="loadData" class="no-select" v-tippy :title="$t('tooltip.refresh')">
						<i class="fa fa-sync text-sm" :class="{'fa-spin': busy}"></i>
					</a>
				</span>
				<h3>{{$t('Dashboard.recent_mail')}}</h3>
			</div>
			<div v-for="mail in mails" :key="mail.id" class="list animate fadeIn list-element-hover">
				<mail-row
					:mail="mail"
					:rowClick="enterMail"
					:markImportant="isImportant(mail)"
					:markUnread="isUnread(mail)"
					:labels="getUserLabels(mail)"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
a {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a:hover {
	opacity: 1;
}
</style>

<script>
import MailService from "@/services/MailService";
import MailRow from "@/components/mail/MailRow";
import store from "@/services/Store";

export default {
	name: "DashboardRecentMail",

	props: ["user"],

	components: { MailRow },

	data() {
		return {
			mails: null,
			busy: false
		};
	},
	created() {
		this.loadData();
	},
	watch: {},
	methods: {
		loadData() {
			this.busy = true;
			let ctx = {
				perPage: 5,
				currentPage: 1
			};
			MailService.listMail("inbox", ctx)
				.then(resp => {
					this.mails = resp.data.mails;
					this.busy = false;
				})
				.catch(err => {
					console.error(
						"Failed to load dashboard data for recent mails"
					);
					console.error(err);
					this.busy = false;
				})
		},

		enterMail(mail) {
			store.set(this, "mail.showMail", mail.id);
			this.$router.push("/mail");
		},

		getUserLabels(mail) {
			let labels = [];
			_.each(mail.to, to => {
				if (to.user.id == this.user.id) {
					labels = to.labels;
				}
			});

			return labels;
		},

		isImportant(mail) {
			return mail.important;
		},

		isUnread(mail) {
			let to = _.find(mail.to, to => {
				return to.user.id == this.user.id;
			});
			return to && !to.viewed;
		}
	}
};
</script>
