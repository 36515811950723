<template>
	<div class="box scroll-y" style="height: 394px">
		<div class="padding h-100">
			<span class="actions text-muted">
				<a v-if="teams && teams.length == 1" @click="downloadRoster(teams[0])" class="no-select btn-subtle" v-tippy
				    :title="$t('tooltip.download_roster')">
					<i class="fa fa-file-export text-sm"></i>
				</a>
				<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
					<i class="fa fa-sync text-sm" :class="{'fa-spin': busy}"></i>
				</a>
			</span>

			<div v-if="teams && teams.length == 1" class="h-100 d-flex flex-column">
				<h2 class="pb-3 mb-3 b-b text-theme">
					<i class="fas fa-users fa-fw"></i>
					<span>{{teams[0].name}}</span>
				</h2>
				<div class="flex d-flex flex-row align-items-center">
					<div class="flex h-100 scroll-y">
						<h6>
							<i class="fas fa-briefcase fa-fw"></i>
							{{teams[0].sections.length}} {{$t('Dashboard.sections')}}
						</h6>
						<ul>
							<li v-for="section in teams[0].sections" :key="section.id">
								{{section.name}}
							</li>
						</ul>
					</div>
					<div style="width: 0.5rem;"></div>
					<div class="flex h-100 scroll-y">
						<h6>
							<i class="fas fa-user fa-fw"></i>
							{{teams[0].users.length}} {{$t('Dashboard.users')}}
						</h6>
						<ul>
							<li v-for="user in teams[0].users" :key="user.user.id">
								{{user.user.full_name}}
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div v-if="teams && teams.length > 1" class="pb-3 scroll-y" style="margin-top: -0.5rem; margin-right: -2px; min-height: 100%;">
				<div v-for="team in teams" :key="team.id" class="py-2 b-b">
					<h5 class="text-theme">
						<i class="fas fa-users fa-fw"></i>
						{{team.name}}
					</h5>
					<div class="flex d-flex flex-row">
						<div class="flex">
							<span v-if="team.sections.length > 0" v-tippy="{theme: 'popover', html:`#sections-${team.id}`, placement: 'bottom'}">
								<i class="fas fa-briefcase fa-fw"></i>
								{{team.sections.length}} {{$t('Dashboard.sections')}}
							</span>
							<div v-if="team.sections.length > 0" class="d-none p-0" :id="`sections-${team.id}`">
								<span class="p-2 float-right text-very-muted">
									<i class="fas fa-briefcase faw"></i>
									{{team.sections.length}}
								</span>
								<div class="p-2 b-b">Sections</div>
								<div class="p-2">
									<li v-for="section in team.active_sections" :key="section.id">
										{{section.name}}
									</li>
									<hr v-if="team.active_sections.length > 0 && team.inactive_sections.length > 0" class="mb-1" />
									<div v-if="team.inactive_sections.length > 0" class="text-xxs text-extra-muted mb-2">({{$t('Dashboard.inactive')}})</div>
									<li v-for="section in team.inactive_sections" :key="section.id" class="text-extra-muted">
										{{section.name}}
									</li>
								</div>
							</div>
							<span v-if="team.sections.length == 0" class="text-very-muted">
								{{$t('data_description.no_sections')}}
							</span>
						</div>
						<div class="flex">
							<span v-if="team.users.length > 0" v-tippy="{theme: 'popover', html:`#users-${team.id}`, placement: 'bottom'}">
								<i class="fas fa-briefcase fa-fw"></i>
								{{team.users.length}} {{$t('Dashboard.users')}}
							</span>
							<div v-if="team.users.length > 0" class="d-none p-0" :id="`users-${team.id}`">
								<span class="p-2 float-right text-very-muted">
									<i class="fas fa-briefcase faw"></i>
									{{team.users.length}}
								</span>
								<div class="p-2 b-b">{{$t('Dashboard.users')}}</div>
								<div class="p-2">
									<li v-for="user in team.users" :key="user.user.id">
										<span>{{user.user.full_name}}</span>
										<span v-if="user.user.scorer_id" class="text-muted">({{user.user.scorer_id}})</span>
									</li>
								</div>
							</div>
							<span v-if="team.users.length == 0" class="text-very-muted">
								{{$t('data_description.no_users')}}
							</span>
						</div>
						<div class="text-muted">
							<a @click="downloadRoster(team)" class="no-select btn-subtle" style="margin-right:" v-tippy :title="$t('tooltip.download_roster')">
								<i class="fa fa-file-export text-sm"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.actions {
	position: absolute;
	right: 2rem;
	top: 2rem;
}
a.btn-subtle {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a.btn-subtle:hover {
	opacity: 1;
}
ul {
	padding-left: 1.5rem;
	padding-right: 0.5rem;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<script>
import DashboardService from "@/services/DashboardService";
import FileService from "@/services/FileService";

export default {
	name: "SuperDashAssignments",

	data() {
		return {
			teams: null,
			sections: null,
			items: null,

			busy: false
		};
	},

	props: ["team", "user"],

	created() {
		this.loadData();
	},
	watch: {
		"team.id"() {
			this.loadData();
		}
	},
	methods: {
		loadData() {
			if (this.busy) {
				return;
			}

			let tid = this.team ? this.team.id : null;
			this.busy = true;
			DashboardService.getTeamAssignments(tid)
				.then(resp => {
					this.teams = resp.data;
					console.log(this.teams);
					_.each(this.teams, team => {
						team.active_sections = _.filter(team.sections, {
							active: true
						});
						team.inactive_sections = _.filter(team.sections, {
							active: false
						});
					});
					this.busy = false;
				})
				.catch(err => {
					console.error(
						"Failed to load dashboard data for assignments"
					);
					console.error(err);
					this.busy = false;
				})
		},

		downloadRoster(team) {
			FileService.teamRosterCSV(this, team);
		}
	}
};
</script>
