import VideoMeetingService from "@/services/VideoMeetingService";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import ChimeEngine from "@/vues/VideoMeeting/services/ChimeEngine";
import vm from "@/main.js";

import PrettyLogger from "@/services/PrettyLogger";
const { log, debug, logWarn, logError } = PrettyLogger.forName("SignalEngine");

export default {
	log: log,
	debug: debug,
	logWarn: logWarn,
	logError: logError,

	// Internal
	socket: null,

	// Two-way bound on init
	chatState: null,

	// Reactive, but bound elsewhere (ChimeEngine)
	uiState: null,
	meetingUsers: null,
	participants: null,

	init(vm) {
		this.uiState = vm.uiState;
		this.meetingUsers = vm.meetingUsers;
		this.participants = vm.participants;

		if (!this.socket) {
			this.initWebsocket();
			this.chatState = vm.chatState;
			this.getChatHistory();
		} else {
			vm.chatState = this.chatState;
		}
	},

	async initWebsocket() {
		if (this.uiState.authed) {
			this.socket = await VideoMeetingService.getMessagingWebsocket(this.uiState.meeting.id);
		} else {
			this.socket = await VideoMeetingService.anonGetMessagingWebsocket(
				this.uiState.meeting.id,
				this.uiState.password,
				this.uiState.username
			);
		}
		this.socket.on("chat", (msg) => {
			this.log("Got chat message", msg);
			this.receiveChatMessage(msg);
		});
		this.socket.on("hand", (msg) => {
			this.log("Got hand up message", msg, msg.hand_up);
			this.receiveHandUp(msg.user_id, msg.hand_up);
		});
		this.socket.on("screenshare", (msg) => {
			this.log("Got screenshare message", msg.screenshare);
			this.receiveScreenshare(msg.user_id, msg.screenshare);
		});
		this.socket.on("server-event", (msg) => {
			this.log("Got server-event message", msg);
			this.receiveServerEvent(msg.event_type);
		});
		this.socket.on("admin", (msg) => {
			this.log("Got admin message", msg);
			this.receiveAdminEvent(msg.action, msg.target_user_id);
		});
		this.socket.on("capabilities", (msg) => {
			this.log("Got capabilities message", msg.target_user_id, msg.audio, msg.video, msg.content, msg.chat);
			this.receiveCapabilitiesEvent(msg.target_user_id, msg.audio, msg.video, msg.content, msg.chat);
		});
		this.socket.on("error", (msg) => {
			this.logError("Socket error", msg);
			Notie.userError("Socket communication error", msg.message);
		});

		this.uiState.socketReady = true;
	},

	closeWebsocket() {
		if (this.socket) {
			this.socket.close();
		}
	},

	sendHandUp(handUp) {
		this.log("Send hand up", handUp);
		let msg = { hand_up: handUp };
		this.socket.emit("hand", JSON.stringify(msg));
	},

	receiveHandUp(userId, handUp) {
		const parti = _.find(this.participants, { userId: userId, isContent: false });
		if (!parti) {
			this.logError("Failed to find participant for hand up");
			return;
		}
		parti.handUp = handUp;
	},

	receiveServerEvent(eventType) {},

	receiveAdminEvent(action, targetUserId) {
		if (action == "chat_message_hidden") {
			this.hideChatMessage(targetUserId);
			return;
		}

		let parti = _.find(this.participants, { userId: targetUserId, isContent: false });
		this.log("Admin message", action, "on participant", parti);

		if (parti && parti.local && action == "remove") {
			this.uiState.leftMeetingMessage = "You were removed from the meeting";
			this.uiState.noRejoin = true;
			ChimeEngine.hangUp();
		}
		if (action == "deleted") {
			this.uiState.leftMeetingMessage = "The meeting was deleted";
			this.uiState.noRejoin = true;
			ChimeEngine.hangUp();
		}
	},

	receiveCapabilitiesEvent(targetUserId, audio, video, content, chat) {
		let parti = _.find(this.participants, { userId: targetUserId, isContent: false });
		if (!parti) {
			this.logError("Receive capabilities: couldn't find participant with id", targetUserId);
			return;
		}

		if (audio) {
			if (audio == "SendReceive" || audio == "Send") {
				if (parti.local && !(parti.capabilities.audio == "SendReceive" || parti.capabilities.audio == "Send")) {
					Notie.info(vm.$i18n.t("VideoMeeting.audio_capability_restored"));
				}
			} else {
				if (parti.local && (parti.capabilities.audio == "SendReceive" || parti.capabilities.audio == "Send")) {
					ChimeEngine.audioCapabilityRemoved();
					Notie.info(vm.$i18n.t("VideoMeeting.audio_capability_removed"));
				}
			}
			parti.capabilities.audio = audio;
		}

		if (video) {
			if (video == "SendReceive" || video == "Send") {
				if (parti.local && !(parti.capabilities.video == "SendReceive" || parti.capabilities.video == "Send")) {
					Notie.info(vm.$i18n.t("VideoMeeting.video_capability_restored"));
				}
			} else {
				if (parti.local && (parti.capabilities.video == "SendReceive" || parti.capabilities.video == "Send")) {
					ChimeEngine.videoCapabilityRemoved();
					Notie.info(vm.$i18n.t("VideoMeeting.video_capability_removed"));
				}
			}
			parti.capabilities.video = video;
		}

		if (content) {
			if (content == "SendReceive" || content == "Send") {
				if (
					parti.local &&
					!(parti.capabilities.content == "SendReceive" || parti.capabilities.content == "Send")
				) {
					Notie.info(vm.$i18n.t("VideoMeeting.content_capability_restored"));
				}
			} else {
				if (
					parti.local &&
					(parti.capabilities.content == "SendReceive" || parti.capabilities.content == "Send")
				) {
					ChimeEngine.contentCapabilityRemoved();
					Notie.info(vm.$i18n.t("VideoMeeting.content_capability_removed"));
				}
			}
			parti.capabilities.content = content;
		}

		if (chat) {
			if (chat == "SendReceive" || chat == "Send") {
				if (parti.local && !(parti.capabilities.chat == "SendReceive" || parti.capabilities.chat == "Send")) {
					Notie.info(vm.$i18n.t("VideoMeeting.chat_capability_restored"));
				}
			} else {
				if (parti.local && (parti.capabilities.chat == "SendReceive" || parti.capabilities.chat == "Send")) {
					Notie.info(vm.$i18n.t("VideoMeeting.chat_capability_removed"));
				}
			}
			parti.capabilities.chat = chat;
		}
	},

	async getChatHistory() {
		try {
			let messages = null;
			if (this.uiState.authed) {
				const r = await VideoMeetingService.getChatHistory(this.uiState.meeting.id);
				messages = r.data.messages;
			} else {
				const r = await VideoMeetingService.anonGetChatHistory(
					this.uiState.meeting.id,
					this.uiState.password,
					this.uiState.username
				);
				messages = r.data.messages;
			}
			for (let msg of messages) {
				this.prepIncomingChatMessage(msg);
				this.chatState.messagesMap[msg._id] = msg;
			}
			this.calcChatMessages();
		} catch (e) {
			this.logError(e);
			Notie.error("Failed to get chat history", e);
		}
	},

	// In order to make the scrolling of the chat box work nicely, we actually want to display them in reverse order
	// (starting with the newest, and getting older), and have flex-column-reverse layout render them bottom to top.
	//
	// Because we're computing a property to reverse the list, however, we may as well properly sort it by the
	// created_at field on the messages. That way, there'll be no possibility of things getting out of order even if
	// we mishandle the messages.
	calcChatMessages() {
		let reversed = _.orderBy(this.chatState.messagesMap, "created_at", "desc");

		let previousMessage = null;
		for (let i = reversed.length - 1; i >= 0; i--) {
			reversed[i].followup = false;
			if (
				previousMessage &&
				!previousMessage.hidden &&
				reversed[i].user_id == previousMessage.user_id &&
				Math.abs(reversed[i].created_at - previousMessage.created_at) < 300000
			) {
				reversed[i].followup = true;
			}
			previousMessage = reversed[i];
		}

		this.chatState.messages = reversed;
	},

	hideChatMessage(messageID) {
		this.log("Hide chat message", messageID);
		let message = this.chatState.messagesMap[messageID];
		if (message) {
			message.hidden = true;
			this.calcChatMessages();
		} else {
			this.logError("Failed to find message to delete", messageID);
		}
	},

	sendChatMessage(msg) {
		let str = JSON.stringify(msg);
		this.log("Sending chat message", str);
		this.socket.emit("chat", str);
	},

	receiveChatMessage(msg) {
		this.log("Received chat message", msg);
		this.prepIncomingChatMessage(msg);
		this.chatState.messagesMap[msg._id] = msg;
		if (!(this.uiState.sidebarTab == "chat" && !this.uiState.chatScrolledUp)) {
			this.log("User not looking at chat, adding to unread messages");
			this.uiState.unreadMessages++;
		}
		this.calcChatMessages();
	},

	prepIncomingChatMessage(msg) {
		let user = this.getUser(msg.user_id);
		user.initials = fs.initials(user);
		msg.user = user;
		msg.created_at = new Date(msg.created_at);
		// msg.created_at_long = msg.created_at.toLocaleTimeString();
		msg.created_at_short = msg.created_at.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
		// msg.created_at_short = msg.created_at_short.slice(0, msg.created_at_short.length - 3);
		msg.hidden = false;
	},

	getUser(user_id) {
		let user = _.find(this.meetingUsers, { id: user_id });
		if (user) {
			return user;
		}
		return {
			user_id: user_id,
			first_name: "?",
			full_name: `(Unknown user: ${user_id})`,
		};
	},

	disconnect() {
		this.logError("should disconnect");
	},
};
