import axios from "axios";
import _ from "lodash";

export default {
	auditQuery(query, ctx) {
		let body = _.extend(query, ctx);
		return axios.post("/v1/admin/audit", body);
	},

	getAuditSample(query, ctx, num) {
		let body = _.extend(query, ctx);
		return axios.post(`/v1/admin/audit/sample/${num}`, body);
	},

	scoreAuditQuery(query, ctx) {
		let body = _.extend(query, ctx);
		return axios.post("/v1/admin/audit/scores", body);
	},

	candidateAuditQuery(query, ctx) {
		let body = _.extend(query, ctx);
		return axios.post("/v1/admin/audit/candidates", body);
	},

	loadSectionItemNames(responseGroups) {
		console.log("loadSectionItemNames");
		let itemIDMap = {};
		let sectionIDMap = {};

		_.each(responseGroups, (responseGroup) => {
			_.each(responseGroup.responses, (resp) => {
				itemIDMap[resp.item_id] = true;
				sectionIDMap[resp.section_id] = true;
			});
		});

		let itemIDs = [];
		let sectionIDs = [];
		_.each(itemIDMap, (_, iID) => {
			itemIDs.push(iID);
		});
		_.each(sectionIDMap, (_, sID) => {
			sectionIDs.push(sID);
		});

		return axios.post("/v1/sections+items/names", { item_ids: itemIDs, section_ids: sectionIDs }).then((r) => {
			_.each(responseGroups, (responseGroup) => {
				_.each(responseGroup.responses, (resp) => {
					resp.item_name = r.data.item_names[resp.item_id];
					resp.section_name = r.data.section_names[resp.section_id];
				});
			});
		});
	},

	exportScores(selection, exportType, includeResponse, includeIncomplete, limitSelection, includeMetadata) {
		let request = {
			selection: selection,
			export_type: exportType,
			include_response: includeResponse,
			include_incomplete: includeIncomplete,
			limit_selection: limitSelection,
			include_metadata: includeMetadata,
		};
		return axios.post("/v1/admin/export/scores", request);
	},

	exportAllScoresByRubricConfig(selection, exportType, includeResponse, includeIncomplete, includeMetadata) {
		const request = {
			selection: selection,
			export_type: exportType,
			include_response: includeResponse,
			include_incomplete: includeIncomplete,
			include_metadata: includeMetadata,
		};
		return axios.post("/v1/admin/export/all_scores_by_rubric_config", request);
	},

	escalateTrueScore(response_id, qc_type, set_id, score, feedback) {
		return axios.post("v1/admin/response/escalateTrueScore", {
			response_id,
			qc_type,
			set_id,
			score,
			feedback,
		});
	},

	getSignedResponse(response_id) {
		return axios.get(`/v1/admin/signed/${response_id}`);
	},

	queueExportCheck(selection) {
		return axios.post("/v1/admin/batch/responses/export/check", selection);
	},

	queueExport(selection) {
		return axios.post("/v1/admin/batch/responses/export", selection);
	},

	markReliability(selection) {
		return axios.post("/v1/admin/batch/responses/reliability", selection);
	},

	setResponsePriority(selection, priority) {
		let request = {
			selection: selection,
			priority: priority,
		};
		return axios.post("/v1/admin/batch/responses/priority", request);
	},

	getResponseLabels(selection) {
		return axios.post("/v1/admin/batch/responses/labels/list", selection);
	},

	getProjectResponseLabels(projectID) {
		return axios.get(`/v1/admin/batch/responses/labels/list/${projectID}`);
	},

	addResponseLabels(selection, labels) {
		let request = {
			selection: selection,
			labels: labels,
		};
		return axios.post("/v1/admin/batch/responses/labels", request);
	},

	removeResponseLabels(selection, labels) {
		let request = {
			selection: selection,
			labels: labels,
		};
		return axios.post("/v1/admin/batch/responses/labels/remove", request);
	},

	deleteResponses(selection) {
		return axios.post("/v1/admin/batch/responses/delete", selection);
	},

	releaseWithheldResponses(selection, userId) {
		let request = {
			selection: selection,
			userId: userId,
		};
		return axios.post("/v1/admin/batch/responses/releaseWithheldResponses", request);
	},

	invalidateResponses(selection, refIdPattern) {
		let ctx = {
			selection: selection,
			ref_id_pattern: refIdPattern,
		};

		return axios.post(`/v1/admin/batch/responses/invalidate`, ctx);
	},

	resetResponses(selection) {
		return axios.post("/v1/admin/batch/responses/reset", selection);
	},

	unprotectResponses(selection) {
		return axios.post("/v1/admin/batch/responses/unprotect", selection);
	},

	moveResponses(selection, sectionID, itemID) {
		return axios.post(`/v1/admin/batch/responses/moveto/${sectionID}/${itemID}`, selection);
	},

	escalateToRF(selection, setID) {
		return axios.post(`/v1/admin/batch/responses/rf_escalate`, selection, {
			params: { set: setID },
		});
	},

	escalateToQC(selection, qcType, setID, apply) {
		return axios.post(`/v1/admin/batch/responses/qc_escalate`, selection, {
			params: { qcType: qcType, set: setID, apply: apply },
		});
	},

	getScoreCounts(selection) {
		return axios.post(`/v1/admin/batch/responses/score_counts`, selection);
	},

	assignScorer(selection, userID, scoreType) {
		return axios.post(`/v1/admin/batch/responses/assign_scorer/${userID}/${scoreType}`, selection);
	},

	getSelectionSchoolCodes(selection) {
		return axios.post(`/v1/admin/batch/responses/school_codes`, selection);
	},

	getCandidateLabels(selection) {
		return axios.post("/v1/admin/batch/candidates/labels/list", selection);
	},

	addCandidateLabels(selection, labels) {
		let request = {
			selection: selection,
			labels: labels,
		};
		return axios.post("/v1/admin/batch/candidates/labels", request);
	},

	removeCandidateLabels(selection, labels) {
		let request = {
			selection: selection,
			labels: labels,
		};
		return axios.post("/v1/admin/batch/candidates/labels/remove", request);
	},

	changeResponseRefID(respID, newRefID) {
		return axios.post(`/v1/admin/response/changeRefID/${respID}/${newRefID}`);
	},
};
