import Vue from "vue";
import $ from "jquery";

Vue.directive("spinner-overlay", {
	inserted(el) {
		console.log("Insert element", el)

		let overlay = document.createElement("div")
		overlay.classList.add("spinner-overlay")
		overlay.classList.add("d-flex")
		overlay.classList.add("flex-column")
		overlay.classList.add("justify-content-center")
		overlay.style.position = "absolute"
		overlay.style.top = "0"
		overlay.style.left = "0"
		overlay.style.bottom = "0"
		overlay.style.right = "0"
		overlay.style.backgroundColor = "rgba(255, 255, 255, 0.5)"

		let spinner = document.createElement("div")
		spinner.classList.add("mz-loading-large")
		spinner.classList.add("mz-loading-icon")
		spinner.style.borderTopColor = "rgba(70, 90, 110, 0.85)"
		spinner.style.borderLeftColor = "rgba(70, 90, 110, 0.85)"
		spinner.style.borderBottomColor = "rgba(70, 90, 110, 0.1)"
		spinner.style.borderRightColor = "rgba(70, 90, 110, 0.1)"

		overlay.appendChild(spinner)
		el.appendChild(overlay)
	},

	update(el, binding) {
		console.log("Update spinner-overlay", binding.value)
		for (let child of el.children) {
			if (child.classList.contains("spinner-overlay")) {
				if (binding.value) {
					child.classList.remove("d-none")
					child.classList.add("d-flex")
				} else {
					child.classList.add("d-none")
					child.classList.remove("d-flex")
				}
			}
		}
	}
});
