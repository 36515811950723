<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<a class="navbar-brand">{{ $t("ScormPool.title") }}</a>

				<!-- Create new -->
				<div class="py-0 ml-auto">
					<b-row>
						<b-col v-if="localS3Selected">
							<button v-if="!uploading" class="btn theme text-white" @click="showUploadModal = true">
								<i class="fas fa-file-upload mr-1" />
								{{ $t("buttons.upload") }}
							</button>
							<button v-else class="btn btn-secondary text-white" disabled>
								<loading type="icon" class="mr-1" />
								{{ $t("buttons.uploading") }}
							</button>
						</b-col>

						<b-col cols="4">
							<input
								type="text"
								v-model="packageFilter"
								class="form-control"
								:placeholder="$t('ScormPool.search_placeholder')"
							/>
						</b-col>

						<b-col>
							<b-dropdown
								v-if="selectedLocation"
								toggle-class="btn hover-darken theme-accent btn-block"
								:text="selectedLocation.bucket"
							>
								<b-dropdown-item
									v-for="location in locations"
									:key="location.id"
									@click="selectedLocation = location"
								>
									{{ location.bucket }}
								</b-dropdown-item>
							</b-dropdown>
						</b-col>

						<b-col>
							<button class="btn hover-darken theme-accent btn-block" @click="openNewLocationModal">
								<i class="far fa-fw fa-plus"></i>
								<span class="hidden-folded d-inline">{{ $t("ScormPool.new_location") }}</span>
							</button>
						</b-col>
					</b-row>
				</div>
			</div>
		</template>

		<loading type="page" v-if="loading" />
		<div class="content scroll-y" v-else>
			<div>
				<!-- Page content goes here -->
				<div v-if="!locations.length" class="box section m-auto no-assignments">
					<div class="box-header theme-lighten-2 text-white">
						{{ $t("ScormPool.no_buckets") }}
					</div>
					<div class="box-body light lt text-center p-4">
						<p class="m-0">{{ $t("ScormPool.no_buckets_defined") }}</p>
					</div>
				</div>
				<div class="flex">
					<div class="card border-0">
						<report-table :fields="tableFields" :content="filteredPackages" perPage="12">
							<template #cell(training_courses)="{ item: { training_courses } }">
								<span
									v-tippy
									:title="trainingCourseTooltip(training_courses)"
									:key="trainingCourseTooltip(training_courses)"
									>{{
										`${training_courses.length} Training Course${training_courses.length == 1 ? "" : "s"}`
									}}</span
								>
							</template>
							<template #cell(status)="{ item: { verified } }">
								<i
									v-if="verified"
									v-tippy
									:title="$t('ScormPool.verified_tooltip')"
									class="fas fa-check text-success"
								/>
								<i
									v-else
									v-tippy
									:title="$t('ScormPool.unverified_tooltip')"
									class="fas fa-question text-warning"
								/>
							</template>
							<template #cell(actions)="{ item }">
								<button
									@click="previewPackage(item)"
									class="btn btn-icon btn-sm btn-rounded lighten-2 theme text-white"
									v-tippy
									:title="$t('tooltip.preview')"
								>
									<i class="fas fa-presentation" style="margin-top: 2px" />
								</button>
								<button
									v-if="localS3Selected"
									@click="modifyPackage(item)"
									class="btn btn-icon btn-sm btn-rounded lighten-2 theme text-white"
									v-tippy
									:title="$t('tooltip.modify')"
								>
									<i class="fas fa-edit" />
								</button>
								<button
									@click="deletePackage(item)"
									class="btn btn-icon btn-sm btn-rounded lighten-2 theme text-white"
									v-tippy
									:title="$t('tooltip.delete')"
								>
									<i class="fas fa-trash" />
								</button>
							</template>
						</report-table>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="newLocationModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content" v-if="newLocation">
					<div class="modal-header">
						<h5>{{ $t("ScormPool.new_scorm_package_location") }}</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 form-group">
								<label>{{ $t("ScormPool.credential") }}</label>
								<v-select
									:options="creds"
									label="cred_id"
									v-model="newLocation.credential_id"
									:reduce="(o) => o.id"
								/>
							</div>
							<div class="col-12 form-group">
								<label>{{ $t("ScormPool.bucket") }}</label>
								<input
									disabled
									class="form-control"
									:class="{ 'is-invalid': !newLocationCredential }"
									v-model="newLocation.bucket"
								/>
							</div>
							<div class="col-12 form-group">
								<label>{{ $t("ScormPool.base_path") }}</label>
								<input class="form-control" v-model="newLocation.base_path" placeholder="scorm/" />
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button
							@click="saveLocation(newLocation)"
							class="btn btn-flat"
							:class="{
								'btn-success': canSaveNewLocation,
								'btn-secondary': !canSaveNewLocation,
							}"
							:disabled="!canSaveNewLocation"
						>
							{{ $t("buttons.save") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<scorm-upload-modal v-if="showUploadModal" @close="closeScormUploadModal" :modifyPkg="modifyPkg" />
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-assignments {
	max-width: 600px;
}

.location > .box-header {
	font-size: 28px;
}

.avail-icon {
	position: absolute;
	right: 7px;
	top: 2px;
	font-size: 16px;
	opacity: 0.8;
}

.button-pad {
	padding: 0.1rem;
}

.pack-progress-bar {
	border-left-color: rgba(120, 130, 140, 0.4);
}
</style>

<script>
//Libraries
import _ from "lodash";
import $ from "jquery";
import Masonry from "masonry-layout";
import BB from "bluebird";

//Services
import TrainingService from "@/services/TrainingService";
import TrainingScormService from "@/services/TrainingScormService";
import TenantService from "@/services/TenantService";
import ThemeService from "@/services/ThemeService";

import fs from "../../services/FormatService";
import Notie from "../../services/NotieService";

import ReportTable from "@/components/ReportTable";
import ScormUploadModal from "@/components/QC/ScormPool/ScormUploadModal";
import { ref } from "vue";

export default {
	name: "ScormPool",

	props: ["user"],

	components: { ReportTable, ScormUploadModal },

	data() {
		return {
			fs: fs,

			loading: true,
			uploading: false,
			creds: null,
			locations: null,
			newLocation: null,
			newLocationCredential: null,

			themeColor: null,
			masonry: null,

			selectedLocation: null,
			packages: [],
			loadingPkgs: false,

			showUploadModal: false,
			modifyPkg: null,

			packageFilter: "",
		};
	},

	created() {
		this.themeColor = ThemeService.getThemeColor();

		this.loadLocations(true);

		TenantService.listCredentialsSafe(this.user.client.id)
			.then((r) => {
				this.creds = r.data;
			})
			.catch((e) => {
				console.log(e);
				Notie.error("Failed to load credentials", e);
			});
	},

	computed: {
		localS3Selected() {
			const { selectedLocation } = this;
			return selectedLocation && selectedLocation.bucket === "local";
		},

		filteredPackages() {
			const { packages, packageFilter } = this;
			return packages.filter(
				({ file_name, ref_id }) => file_name.match(packageFilter) || ref_id.match(packageFilter)
			);
		},

		canSaveNewLocation() {
			if (!this.newLocation) return false;
			if (!this.newLocation.credential_id) return false;
			if (!this.newLocation.bucket) return false;
			if (!this.newLocation.base_path) return false;
			if (!this.newLocationCredential) return false;
			if (this.newLocationCredential.type != "S3") return false;

			return true;
		},

		tableFields() {
			return [
				{
					key: "ref_id",
					label: "Name",
					sortable: true,
				},
				{
					key: "file_name",
					label: "File Name",
					sortable: true,
				},
				{
					key: "training_courses",
					label: "Training Courses",
				},
				{
					key: "status",
					label: "Status",
				},
				{
					key: "actions",
					label: "Actions",
				},
			];
		},
	},

	watch: {
		"newLocation.credential_id"() {
			if (!this.newLocation) return;
			if (!this.newLocation.credential_id) return;
			let cred = _.find(this.creds, { id: this.newLocation.credential_id });
			if (!cred) {
				this.newLocation.bucket = "";
				this.newLocationCredential = false;
				return;
			}
			if (cred.type != "S3") {
				this.newLocation.bucket = "Invalid Credential Type (must be S3)";
				this.newLocationCredential = false;
				return;
			}
			this.newLocation.bucket = cred.bucket;
			this.newLocationCredential = cred;
		},

		selectedLocation() {
			this.loadLocationPkgs();
		},
	},

	methods: {
		loadLocations() {
			TrainingService.listScormLocations()
				.then((r) => {
					this.locations = r.data;
					this.selectedLocation = this.locations.find(({ bucket }) => bucket === "local");

					this.loading = false;

					this.$nextTick(() => {
						if (!this.masonry) {
							this.masonry = new Masonry(this.$refs.locationsContainer, {
								itemSelector: ".location-card",
								gutter: 0,
							});
						} else {
							this.masonry.layout();
						}
					});
				})
				.catch((err) => {
					console.error(err);
					Notie.error("Failed to load SCORM locations", err);
				});
		},

		trainingCourseTooltip(courses) {
			return courses.map(({ name }) => name).join("<br \\>");
		},

		closeScormUploadModal() {
			this.showUploadModal = false;
			this.modifyPkg = null;
			this.loadLocationPkgs();
		},

		async loadLocationPkgs() {
			this.loadingPkgs = true;
			const { selectedLocation } = this;
			if (!selectedLocation) return;
			const locID = selectedLocation.bucket === "local" ? "local" : selectedLocation.id;
			try {
				const {
					data: { packages },
				} = await TrainingService.getScormLocationWithPackages(locID);
				this.packages = packages;
				this.$forceUpdate();
				this.$nextTick(() => {
					this.masonry.layout();
				});
			} catch (err) {
				console.error(err);
				Notie.error(
					`Failed to load SCORM location: ${selectedLocation.bucket} - ${selectedLocation.base_path}`,
					err
				);
			} finally {
				this.loadingPkgs = false;
			}
		},

		saveLocation(location) {
			TrainingService.saveScormLocation(location)
				.then((r) => {
					Notie.success("Saved new location");

					this.loadLocations(true);
					$("#newLocationModal").modal("hide");
				})
				.catch((err) => {
					console.error(err);
					Notie.error("Failed to save location", err);
				});
		},

		async deleteLocation(location) {
			const ok = await this.$bvModal.msgBoxConfirm("Are you sure you want to Delete this SCORM location?", {
				title: "Remove Location",
				centered: true,
			});

			if (ok) {
				try {
					await TrainingService.deleteScormLocation(location.id);
					this.locations = this.locations.filter(({ id }) => id !== location.id);
				} catch (err) {
					Notie.error("Failed to Delete Location", err);
				}
			}
		},

		openNewLocationModal() {
			this.newLocation = {
				bucket: "",
				credential_id: null,
				base_path: "",
			};
			this.newLocationCredential = false;

			$("#newLocationModal").modal("show");
		},

		async previewPackage(pack) {
			const { ref_id, verified } = pack;
			let { import_record_id } = pack;

			if (!verified) {
				this.loading = true;
				import_record_id = await this.moveToLocal(this.selectedLocation.id, ref_id);
			}

			this.$router.push(`/scorm_preview/${import_record_id}`);
		},

		modifyPackage(pack) {
			this.modifyPkg = pack;
			this.showUploadModal = true;
		},

		async deletePackage(pack) {
			const { import_record_id } = pack;

			try {
				const confirmed = await this.$bvModal.msgBoxConfirm(
					"Are you sure you want to delete this SCORM package?",
					{ centered: true }
				);
				if (!confirmed) return;

				await TrainingScormService.deleteScormPackage(import_record_id);
				this.loadLocationPkgs();
				Notie.success("Deleted SCORM Package");
			} catch (e) {
				Notie.error("Failed to Delete Scorm Package", e);
			}
		},

		async moveToLocal(scormLocationID, scormRefID) {
			try {
				const {
					data: { import_record_id },
				} = await TrainingScormService.addExternalScormPackage(scormLocationID, scormRefID);
				return import_record_id;
			} catch (err) {
				console.log("Failed to add to local", err);
				Notie.error("Failed to add package to local S3", err);
			}
		},

		getCredentialName(credentialID) {
			let cred = _.find(this.creds, { id: credentialID });
			return cred ? cred.cred_id : "(Unknown credential)";
		},

		progressBarStyle(prog) {
			let r = this.themeColor.r;
			let g = this.themeColor.g;
			let b = this.themeColor.b;
			let p = 0;
			if (prog.loaded && prog.total) {
				p = (prog.loaded / prog.total) * 100;
			}
			return {
				background: `linear-gradient(90deg, rgba(${r}, ${g}, ${b}, 0.2) ${p}%, #00000000 0%)`,
			};
		},
	},
};
</script>
