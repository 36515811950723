<template>
	<page customNavbar>
		<template #navbar>
			<div class="navbar navbar-expand-lg navbar-text nav-title">
				<!-- Page title -->
				<span>
					IEA Item Model
					<i class="fas fa-angle-right mx-2" />
					<loading type="header" v-if="loading" style="display: inline" />
					<template v-else
						>{{ tenantId }} <i class="fas fa-angle-right mx-2" /> {{ projectId }}
						<i class="fas fa-angle-right mx-2" />{{ itemId }}</template
					>
				</span>

				<div class="ml-auto d-flex flex-row align-items-center card px-3">
					<div class="navbar-text nav-title mr-2">State:</div>
					<h4
						v-if="todItem && todItem.state"
						class="mb-0"
						:class="{
							'text-danger': todItem.state.sentiment() <= -1,
							'text-success': todItem.state.sentiment() >= 1,
						}"
						v-tippy="{ theme: 'popover', placement: 'bottom' }"
						:title="stateTooltip(todItem.state)"
					>
						{{ todItem.state.display($t) }}
					</h4>
					<h4 v-else class="mb-0 text-theme"><loading type="icon" /></h4>
				</div>
			</div>
		</template>
		<div class="h-100 bg-grey" v-if="!loading">
			<div class="b-t h-100 padding">
				<div class="row">
					<div class="col-12 col-sm-8">
						<div class="card">
							<h4 class="mx-3 mt-2 mb-2">{{ $t("IeaTodItemEdit.include_score_sets") }}</h4>
							<table class="table table-striped table-hover mb-0">
								<tbody>
									<tr>
										<th style="width: 1px; padding-right: 0px"></th>
										<th>{{ $t("fields.section") }}</th>
										<th>{{ $t("fields.item") }}</th>
										<th>{{ $t("fields.responses") }}</th>
										<th>{{ $t("fields.scores") }}</th>
									</tr>
									<tr
										v-for="(queue, i) in queues"
										:key="i"
										@click="toggleInclude(queue)"
										:class="{ disabled: snapshotFiles }"
									>
										<td class="v-mid" style="padding-right: 0px">
											<label v-if="!queue.loadingStats" class="md-check">
												<input
													:checked="queue.include"
													type="checkbox"
													@click="toggleInclude(queue)"
													:disabled="snapshotFiles"
												/>
												<i class="theme-accent" />
											</label>
										</td>
										<td :class="{ clickable: !queue.loadingStats }">
											{{ queue.section_name }}
										</td>
										<td :class="{ clickable: !queue.loadingStats }">
											{{ queue.item_name }}
										</td>
										<td v-if="queue.loadingStats"><loading type="icon" /></td>
										<td v-else class="clickable">
											{{ queue.responses }}
										</td>
										<td v-if="queue.loadingStats"><loading type="icon" /></td>
										<td v-else class="clickable">
											{{ queue.scores }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="col-12 col-sm-4 pl-0">
						<div v-if="numScores > 0" class="card px-3 h-100 pb-2">
							<div class="d-flex flex-row justify-content-around">
								<div class="my-3 text-center">
									<h4 class="mb-0">{{ numResponses }}</h4>
									<div class="text-muted">{{ $t("fields.responses") }}</div>
								</div>
								<div class="my-3 text-center">
									<h4 class="mb-0">{{ numScores }}</h4>
									<div class="text-muted">{{ $t("fields.scores") }}</div>
								</div>
							</div>
							<h6>
								{{ $t("IeaTodItemEdit.response_irr") }}:

								<span
									:class="percentColor(overallResponseAgreement, 40, 50)"
									v-if="comparableResponses > 0"
									v-tippy="{ theme: 'popover' }"
									:title="responseIrrTooltip(comparableResponses, agreedResponses)"
								>
									{{ overallResponseAgreement.toFixed(2) }}%
								</span>
								<span class="text-muted" v-if="comparableResponses <= 0">
									{{ $t("data_description.none_found") }}
								</span>
							</h6>
							<h6>
								{{ $t("IeaTodItemEdit.trait_irr") }}:

								<span
									:class="percentColor(overallTraitAgreement, 50, 65)"
									v-if="comparableTraits > 0"
									v-tippy="{ theme: 'popover' }"
									:title="traitIrrTooltip(comparableTraits, agreedTraits)"
								>
									{{ overallTraitAgreement.toFixed(2) }}%
								</span>
								<span class="text-muted" v-if="comparableTraits <= 0">
									{{ $t("data_description.none_found") }}
								</span>
							</h6>
							<table class="table-striped mt-1">
								<thead>
									<tr>
										<th></th>
										<th v-for="(fdTrait, i) in fdTraits" :key="i" class="text-center">
											<span v-tippy :title="getTraitNames(fdTrait)">T{{ i + 1 }}</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="val of combinedPossibleValues" :key="val">
										<td class="_800">{{ val }}</td>
										<td v-for="(fdTrait, i) in fdTraits" :key="i" class="text-center">
											<span v-if="fdTrait.fd[val] == undefined" class="text-muted"> - </span>
											<span v-else-if="!fdTrait.fd[val]" class="text-danger">
												{{ fdTrait.fd[val] }}
											</span>
											<span v-else>
												{{ fdTrait.fd[val] }}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
							<hr class="mb-2" />
							<div class="text-center">
								<h4 v-if="!mmr" class="mb-0">{{ $t("IeaTodItemEdit.unknown") }}</h4>
								<h4
									v-else-if="mmr.overall == 'not_met'"
									class="mb-0 text-danger"
									v-tippy="{ theme: 'popover' }"
									:title="$t('IeaTodItemEdit.mmr_not_met_tooltip')"
								>
									{{ $t("IeaTodItemEdit.mmr_not_met") }}
								</h4>
								<h4
									v-else-if="mmr.overall == 'met'"
									class="mb-0 text-warning"
									v-tippy="{ theme: 'popover' }"
									:title="$t('IeaTodItemEdit.mmr_met_tooltip')"
								>
									{{ $t("IeaTodItemEdit.mmr_met") }}
								</h4>
								<h4
									v-else-if="mmr.overall == 'exceeded'"
									class="mb-0 text-success"
									v-tippy="{ theme: 'popover' }"
									:title="$t('IeaTodItemEdit.mmr_exceeded_tooltip')"
								>
									{{ $t("IeaTodItemEdit.mmr_exceeded") }}
								</h4>
								<div class="text-muted">{{ $t("IeaTodItemEdit.minimum_modeling_requirements") }}</div>
							</div>
						</div>
						<div v-else class="card py-3 px-5 h-100 justify-content-center">
							<div class="text-center text-extra-muted">
								{{ $t("IeaTodItemEdit.select_before_stats") }}
							</div>
						</div>
					</div>
				</div>

				<div class="d-flex flex-row mt-3">
					<div class="align-self-stretch" style="width: 50%">
						<div class="card h-100 justify-content-center py-3">
							<div class="text-center">
								<button
									v-if="!snapshotFiles && !runningSnapshot"
									class="btn"
									:class="{ 'btn-primary': numScores > 0, 'btn-secondary': numScores <= 0 }"
									@click="runSnapshot"
									:disabled="numScores <= 0"
								>
									{{ $t("IeaTodItemEdit.create_modeling_snapshot") }}
								</button>

								<div v-if="runningSnapshot" class="text-center">
									<loading type="large" style="margin-top: -1rem; margin-bottom: -1rem" />
									<h6 class="mb-0 text-muted">{{ snapshotProgress }}</h6>
								</div>

								<div v-if="snapshotFiles" class="d-flex flex-row justify-content-around">
									<div>
										<a
											:href="snapshotFiles.responsesFileSigned"
											class="link d-flex flex-row align-items-center"
											target="_blank"
										>
											<i class="fas fa-2x fa-file-code mr-2" />
											<h6 class="text-left mb-0">Responses</h6>
										</a>
									</div>
									<div class="d-flex flex-row align-items-center" v>
										<a
											:href="snapshotFiles.scoresFileSigned"
											class="link d-flex flex-row align-items-center"
											target="_blank"
										>
											<i class="fas fa-2x fa-file-code mr-2" />
											<h6 class="text-left mb-0">Scores</h6>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<i
						class="fas fa-angle-right fa-3x align-self-center mx-4"
						:class="{ 'text-very-muted': !snapshotFiles, 'text-primary': snapshotFiles }"
					/>

					<div class="align-self-stretch" style="width: 50%">
						<div class="card h-100 justify-content-center py-3">
							<div v-if="snapshotFiles" class="text-center">
								<button v-if="sendingRequest" class="btn btn-primary" disabled>
									<loading type="icon" class="mr-2" />{{ $t("IeaTodItemEdit.sending") }}
								</button>
								<button v-else class="btn btn-danger" @click="showModelingPasswordModal = true">
									{{ $t("IeaTodItemEdit.send_modeling_request") }}
								</button>
							</div>
							<div v-else class="text-center text-extra-muted px-5">
								{{ $t("IeaTodItemEdit.snapshot_before_modeling_request") }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="modelingPasswordModal" :visible="showModelingPasswordModal">
			<template slot="modal-title">{{ $t("IeaTodItemEdit.modeling_password") }}</template>
			<div class="row p-4">
				<div class="col-12 form-group" v-html="$t('IeaTodItemEdit.modeling_password_description')"></div>
				<div class="col-12 text-center mt-3">
					<label>{{ $t("IeaTodItemEdit.password") }}</label>
					<input
						class="form-control mx-auto text-center"
						type="text"
						v-model="modelingPassword"
						style="width: 160px"
					/>
				</div>
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="showModelingPasswordModal = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="sendModelingRequest">
					{{ $t("buttons.send") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}

.invalid {
	color: red;
}

tr.disabled .clickable {
	cursor: initial;
}
tr.disabled {
	opacity: 0.5;
}
</style>

<script>
import IeaService, { TodItemState } from "@/services/IEAService";
import ProgressJobService from "@/services/ProgressJobService";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import BB from "bluebird";

export default {
	name: "IeaTodItemEdit",

	props: ["user", "params", "query"],

	components: {},

	data() {
		return {
			tenantId: null,
			projectId: null,
			itemId: null,

			fs: fs,
			loading: true,
			jobRunner: ProgressJobService.newJobRunner(),

			todItem: null,
			queues: null,

			runningSnapshot: false,
			snapshotProgress: null,
			snapshotFiles: null,
			sendingRequest: false,

			anyComps: false,
			numResponses: 0,
			numScores: 0,
			numTraits: 0,
			overallResponseAgreement: 0,
			overallTraitAgreement: 0,
			fdTraits: [],
			combinedPossibleValues: [],

			modelingPassword: "",
			showModelingPasswordModal: false,
		};
	},

	created() {
		this.loadData();
	},
	destroyed() {
		this.jobRunner.endAllJobs();
	},

	watch: {},

	methods: {
		loadData() {
			this.tenantId = this.params.tenantId;
			this.projectId = this.params.projectId;
			this.itemId = this.params.itemId;
			return BB.props({
				item: IeaService.getTODItem(this.tenantId, this.projectId, this.itemId),
				queues: IeaService.getTODItemQueues(this.tenantId, this.projectId, this.itemId),
			})
				.then((r) => {
					this.loading = false;
					this.todItem = r.item.data;
					this.todItem.state = new TodItemState(this.todItem.workflow_state, this.todItem.state_detail);
					this.todItem.state.setI18n(this.$i18n);
					this.queues = r.queues.data;

					_.each(this.queues, (queue) => {
						this.loadQueueStats(queue);
					});
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get IEA TOD item", e);
				});
		},

		loadQueueStats(queue) {
			this.$set(queue, "loadingStats", true);

			IeaService.getItemQueueStats(queue.section_id, queue.item_id)
				.then((r) => {
					queue.loadingStats = false;
					queue.total_responses = r.data.total_responses;
					queue.responses = r.data.responses;
					queue.scores = r.data.scores;
					queue.comp_responses = r.data.comp_responses;
					queue.comp_traits = r.data.comp_traits;
					queue.agreed_responses = r.data.agreed_responses;
					queue.agreed_traits = r.data.agreed_traits;
					queue.fd_traits = r.data.fd_traits;
					this.$set(queue, "include", false);
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to load queue stats", e);
				});
		},

		calculateResponseStats() {
			this.numResponses = 0;
			this.numScores = 0;
			this.numTraits = 0;
			this.comparableResponses = 0;
			this.comparableTraits = 0;
			this.agreedResponses = 0;
			this.agreedTraits = 0;
			this.overallResponseAgreement = 0;
			this.overallTraitAgreement = 0;
			this.fdTraits = [];
			let possibleValuesSet = new Set();
			this.combinedPossibleValues = [];

			function ensureQueueFdTrait(oFdTraits, qFdTrait, i) {
				if (oFdTraits.length <= i) {
					oFdTraits.push({
						traitNames: [],
						traitIds: [],
						fd: {},
					});
				}
				let oFdTrait = oFdTraits[i];
				if (!oFdTrait.traitIds.includes(qFdTrait.trait_id)) {
					oFdTrait.traitIds.push(qFdTrait.trait_id);
				}
				if (!oFdTrait.traitNames.includes(qFdTrait.trait_name)) {
					oFdTrait.traitNames.push(qFdTrait.trait_name);
				}

				return oFdTrait;
			}

			function accumulateFdValue(oFdTrait, val, count) {
				if (!oFdTrait.fd[val]) {
					oFdTrait.fd[val] = 0;
				}
				oFdTrait.fd[val] += count;
			}

			_.each(this.queues, (queue) => {
				if (queue.include) {
					console.log("process queue", queue.item_name);
					console.log(queue.responses, queue.comp_responses, queue.agreed_responses);
					this.numResponses += queue.responses;
					this.numScores += queue.scores;
					this.numTraits += queue.traits;
					this.comparableResponses += queue.comp_responses;
					this.comparableTraits += queue.comp_traits;
					this.agreedResponses += queue.agreed_responses;
					this.agreedTraits += queue.agreed_traits;

					_.each(queue.fd_traits, (fdTrait, i) => {
						let overallFdTrait = ensureQueueFdTrait(this.fdTraits, fdTrait, i);
						_.each(fdTrait.fd, (count, val) => {
							accumulateFdValue(overallFdTrait, val, count);
						});
					});
				}

				_.each(this.fdTraits, (fdTrait) => {
					_.each(fdTrait.fd, (_, val) => {
						possibleValuesSet.add(val);
					});
				});
				this.combinedPossibleValues = Array.from(possibleValuesSet).sort();
			});

			if (this.comparableResponses > 0) {
				this.overallResponseAgreement = (100 * this.agreedResponses) / this.comparableResponses;
				console.log(
					"OVERALL RESPONSE AGREEMENT",
					this.agreedResponses,
					this.comparableResponses,
					this.overallResponseAgreement
				);
			}
			if (this.comparableTraits > 0) {
				this.overallTraitAgreement = (100 * this.agreedTraits) / this.comparableTraits;
			}

			this.mmr = this.determineMinimumModelReqirements(this.numScores);
		},

		determineMinimumModelReqirements(numScores) {
			if (numScores == null || numScores == undefined) {
				return null;
			} else if (numScores <= 250) {
				return { overall: "not_met" };
			} else if (numScores < 450) {
				return { overall: "met" };
			} else {
				return { overall: "exceeded" };
			}
		},

		runSnapshot() {
			this.runningSnapshot = true;
			this.snapshotProgress = "";

			let updateProgress = (r) => {
				const { data } = r;
				if (data) {
					this.snapshotProgress = data.msg;
				}
			};
			let pollInterval = 500;

			this.jobRunner
				.startJob(
					IeaService.createModelingFiles(this.projectId, this.itemId, this.queues),
					updateProgress,
					pollInterval
				)
				.then((r) => {
					this.runningSnapshot = false;
					this.snapshotFiles = {
						responsesFileSigned: r.data.responsesFileSigned,
						responsesFilePath: r.data.responsesFilePath,
						scoresFileSigned: r.data.scoresFileSigned,
						scoresFilePath: r.data.scoresFilePath,
					};
				})
				.catch((e) => {
					this.runningSnapshot = false;
					let errMsg = "Failed to create modeling files";
					console.error({ errMsg: e });
					console.error(e);
					Notie.error(errMsg, e);
				});
		},

		sendModelingRequest() {
			if (!this.snapshotFiles) {
				Notie.error("Can't send modeling request without snapshot files");
				return;
			}

			this.showModelingPasswordModal = false;

			this.sendingRequest = true;
			IeaService.sendModelingRequest(
				this.tenantId,
				this.projectId,
				this.itemId,
				this.snapshotFiles.responsesFilePath,
				this.snapshotFiles.scoresFilePath,
				this.modelingPassword
			)
				.then((r) => {
					this.sendingRequest = false;
					Notie.success("Modeling request sent");
				})
				.catch((e) => {
					this.sendingRequest = false;
					console.error(e);
					Notie.error("Failed to send modeling request", e);
				});
		},

		toggleInclude(queue) {
			if (queue.loadingStats) return;
			if (this.snapshotFiles) return;

			queue.include = !queue.include;
			this.calculateResponseStats();
			this.$forceUpdate();
		},

		percentColor(val, t1, t2) {
			return {
				"text-danger": val < t1,
				"text-warning": val >= t1 && val < t2,
				"text-success": val >= t2,
			};
		},

		getTraitNames(fdTrait) {
			if (!(fdTrait.traitNames && fdTrait.traitNames.length > 0)) {
				return "Unknown";
			}

			return fdTrait.traitNames.join(", ");
		},

		responseIrrTooltip(comp, agreed) {
			return `
<table>
	<tr>
		<td>${this.$i18n.t("IeaTodItemEdit.comparable_responses")}:</td>
		<td class="_800">${comp}</td>
	</tr>
	<tr>
		<td colspan="2" class="text-xxs text-muted">${this.$i18n.t("IeaTodItemEdit.comparable_responses_explanation")}</td>
	</tr>
	<tr>
		<td>${this.$i18n.t("IeaTodItemEdit.agreed_responses")}:</td>
		<td class="_800">${agreed}</td>
	</tr>
	<tr>
		<td colspan="2" class="text-xxs text-muted">${this.$i18n.t("IeaTodItemEdit.agreed_responses_explanation")}</td>
	</tr>
</table>`;
		},

		traitIrrTooltip(comp, agreed) {
			return `
<table>
	<tr>
		<td>${this.$i18n.t("IeaTodItemEdit.comparable_traits")}:</td>
		<td class="_800">${comp}</td>
	</tr>
	<tr>
		<td colspan="2" class="text-xxs text-muted">${this.$i18n.t("IeaTodItemEdit.comparable_traits_explanation")}</td>
	</tr>
	<tr>
		<td>${this.$i18n.t("IeaTodItemEdit.agreed_traits")}:</td>
		<td class="_800">${agreed}</td>
	</tr>
	<tr>
		<td colspan="2" class="text-xxs text-muted">${this.$i18n.t("IeaTodItemEdit.agreed_traits_explanation")}</td>
	</tr>
</table>`;
		},

		stateTooltip(state) {
			let result = state.description();
			if (state.showError()) {
				console.log(`SHOW ERROR <${state.error()}>`);
				let errMsg = state.error();
				if (errMsg == "") {
					errMsg = `<span class="text-muted">(Blank)</span>`;
				}
				result += `<div class="text-xxs mt-2"><span class="">Error:</span> ${errMsg}</div>`;
			}
			result += `<div class="text-xxs text-muted mt-2">(${state.raw()})</div>`;
			return result;
		},
	},
};
</script>
