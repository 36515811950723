<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a class="navbar-brand flex">{{ $t("QCReport.title") }}</a>
				<div
					v-if="qcData && qcData.rows && qcData.rows.length > 0"
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.export_list')"
					@click="tryExport"
				>
					<i v-if="!exporting" class="fas fa-lg fa-download"></i>
					<i v-if="exporting" class="fas fa-lg fa-sync fa-spin"></i>
				</div>
				<div
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center align-items-center"
					@click="getData"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.refresh')"
				>
					<i class="far fa-lg fa-sync-alt" :class="{ 'fa-spin': refreshing }"></i>
				</div>
			</div>
		</template>

		<div class="container-fluid">
			<div class="row no-gutters white">
				<div class="col-sm-12 white nav-active-bg">
					<!-- {{tabIndex}} -->
					<!-- <b-card style="width:99%" no-body> -->
					<div class="card-header">
						<ul class="nav nav-tabs card-header-tabs">
							<li class="nav-item">
								<a :class="{ active: qc_type == 1 }" class="nav-link no-select" @click="qc_type = 1">{{
									$t("QCReport.practice")
								}}</a>
							</li>
							<li class="nav-item">
								<a :class="{ active: qc_type == 2 }" class="nav-link no-select" @click="qc_type = 2">{{
									$t("QCReport.qualification")
								}}</a>
							</li>
							<li class="nav-item">
								<a :class="{ active: qc_type == 3 }" class="nav-link no-select" @click="qc_type = 3">{{
									$t("QCReport.validity")
								}}</a>
							</li>
							<li class="nav-item">
								<a :class="{ active: qc_type == 4 }" class="nav-link no-select" @click="qc_type = 4">{{
									$t("QCReport.calibration")
								}}</a>
							</li>
						</ul>
					</div>

					<div class="row" :key="selectedQCSet && selectedQCSet.id">
						<div class="col-sm-5 clearfix">
							<div class="col-sm-6 pt-3 d-flex align-items-center" v-if="selectedItem && selectedQCSet">
								<i
									class="far fa-info-circle text-theme"
									v-tippy="{ theme: 'popover', placement: 'right', html: `#setInfo` }"
								></i>
								<div class="pt-3 d-none" id="setInfo">
									<table class="table" v-if="qc_type == 1">
										<tbody>
											<tr>
												<th>{{ $t("QCReport.mode") }}</th>
												<td>{{ $t("QCReport.practice") }}</td>
											</tr>
										</tbody>
									</table>
									<table class="table" v-if="qc_type == 2">
										<tbody>
											<tr>
												<th>{{ $t("QCReport.pass_percent") }}</th>
												<td>{{ selectedQCSet.pass_percent }}%</td>
											</tr>
											<tr>
												<th>{{ $t("QCReport.judge_by") }}</th>
												<td v-if="!selectedQCSet.pass_by_trait">
													{{ $t("QCReport.total") }}
												</td>
												<td v-if="selectedQCSet.pass_by_trait">
													{{ $t("QCReport.each_trait") }}
												</td>
											</tr>
										</tbody>
									</table>
									<table class="table" v-if="qc_type === 4">
										<tbody>
											<tr>
												<th>Rules</th>
											</tr>
											<tr
												v-for="(rule, i) in selectedQCSet.calibration_rules"
												:key="`${rule.rule}-${i}`"
											>
												<td>
													{{ fs.getQcRuleText(rule) }}
												</td>
											</tr>
										</tbody>
									</table>
									<table class="table" v-if="qc_type === 3 && !allItemsSelected">
										<tbody>
											<tr>
												<th>{{ $t("QCReport.mode") }}</th>
												<td v-if="selectedItem.validity_config.uses_threshold">
													{{ $t("QCReport.threshold") }}
												</td>
												<td v-if="!selectedItem.validity_config.uses_threshold">
													{{ $t("QCReport.window") }}
												</td>
											</tr>
											<tr v-if="!selectedItem.validity_config.uses_threshold">
												<th>{{ $t("QCReport.window_size") }}</th>
												<td>{{ selectedItem.validity_config.window }}</td>
											</tr>
											<tr>
												<th>{{ $t("QCReport.interval") }}</th>
												<td>{{ selectedItem.validity_config.interval }}</td>
											</tr>
											<tr>
												<th>{{ $t("QCReport.behavior") }}</th>
												<td v-if="!selectedItem.validity_config.calibration">Lockout</td>
												<td v-if="selectedItem.validity_config.calibration">Calibration</td>
											</tr>
											<tr v-if="selectedItem.validity_config.uses_threshold">
												<th>{{ $t("QCReport.threshold") }}</th>
												<td>{{ selectedItem.validity_config.threshold }}</td>
											</tr>
											<tr v-if="!selectedItem.validity_config.uses_threshold">
												<th>{{ $t("QCReport.pass_percent") }}</th>
												<td>
													{{
														(selectedItem.validity_config.window_rules || [])
															.map(({ pass_percent }) => `${pass_percent}%`)
															.join(" ")
													}}
												</td>
											</tr>
											<tr v-if="!selectedItem.validity_config.uses_threshold">
												<th>{{ $t("QCReport.judge_by") }}</th>
												<td v-if="!selectedItem.validity_config">
													{{ $t("QCReport.total") }}
												</td>
												<td v-if="selectedItem.validity_config">
													{{ $t("QCReport.each_trait") }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								&nbsp;{{ $t("QCReport.settings") }}
								<div
									v-if="
										qc_type == 4 &&
										!selectedQCSet.auto_deliver &&
										!(allSetsSelected || allItemsSelected)
									"
									data-toggle="modal"
									data-target="#deliverModal"
									class="col-sm-6 d-flex"
								>
									<div v-if="selectedItem.calibration_config.enabled">
										<button
											:disabled="!selectedItem.calibration_config.enabled"
											class="btn theme-accent"
										>
											{{ $t("buttons.deliver") }}
										</button>
									</div>
									<div v-else v-tippy :title="$t('QCReport.calibration_not_enabled')">
										<button disabled class="btn theme">
											{{ $t("buttons.deliver") }}
										</button>
									</div>
								</div>
							</div>

							<div class="col-sm-12 d-flex align-items-center pb-3 pt-3">
								<label class="w-80">{{ $t("QCReport.section") }}</label>
								<div class="flex">
									<config-select
										:nullText="$t('data_description.no_sections')"
										:options="sections"
										v-model="selectedSection"
										:mustBeFilled="true"
										useInactive
									>
									</config-select>
								</div>
							</div>

							<div class="col-sm-12 d-flex align-items-center pb-3">
								<label class="w-80">{{ $t("QCReport.item") }}</label>
								<div class="flex">
									<config-select
										:nullText="$t('data_description.no_items')"
										:options="selectableItems"
										v-model="selectedItem"
										:mustBeFilled="true"
									>
									</config-select>
								</div>
							</div>
							<div class="col-sm-12 d-flex align-items-center pb-3">
								<label class="w-80">{{ $t("QCReport.qc_set") }}</label>
								<div class="flex">
									<config-select
										sortBy="sequence"
										:options="qcSetsPlus"
										v-model="selectedQCSet"
										:mustBeFilled="true"
									></config-select>
								</div>
							</div>
							<div class="col-sm-12 d-flex align-items-center pb-3">
								<label class="w-80">{{ $t("QCReport.team") }}</label>
								<div class="flex">
									<config-select
										:nullOption="true"
										:options="teams"
										v-model="selectedTeam"
									></config-select>
								</div>
							</div>
						</div>

						<div class="col-sm-7">
							<table v-if="!allItemsSelected" class="table table-hover-cells mb-0">
								<thead>
									<tr>
										<th>{{ $t("QCReport.trait") }}</th>
										<th class="text-center" v-tippy :title="$t('tooltip.non_adjacent_low')">
											<i class="far fa-chevron-double-down text-danger"></i>
										</th>
										<th class="text-center" v-tippy :title="$t('tooltip.adjacent_low')">
											<i class="far fa-chevron-down text-warning"></i>
										</th>
										<th class="text-center" v-tippy :title="$t('tooltip.exact')">
											<i class="far fa-check text-success"></i>
										</th>
										<th class="text-center" v-tippy :title="$t('tooltip.adjacent_high')">
											<i class="far fa-chevron-up text-warning"></i>
										</th>
										<th class="text-center" v-tippy :title="$t('tooltip.non_adjacent_high')">
											<i class="far fa-chevron-double-up text-danger"></i>
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody v-if="selectedItem">
									<template v-for="trait in selectedItem.rubric.traits">
										<tr v-if="!(trait.separator || trait.is_parent)" :key="trait.id">
											<th>{{ trait.reported_name || trait.name }}</th>
											<td
												class="text-center"
												:class="{
													'text-danger': getCountAtAdjacency(trait.id, 'nal') > 0,
												}"
											>
												{{ getCountAtAdjacency(trait.id, "nal") }}
											</td>
											<td
												class="text-center"
												:class="{
													'text-warning': getCountAtAdjacency(trait.id, 'al') > 0,
												}"
											>
												{{ getCountAtAdjacency(trait.id, "al") }}
											</td>
											<td
												class="text-center"
												:class="{
													'text-success': getCountAtAdjacency(trait.id, 'agreed') > 0,
												}"
											>
												{{ getCountAtAdjacency(trait.id, "agreed") }}
											</td>
											<td
												class="text-center"
												:class="{
													'text-warning': getCountAtAdjacency(trait.id, 'ah') > 0,
												}"
											>
												{{ getCountAtAdjacency(trait.id, "ah") }}
											</td>
											<td
												class="text-center"
												:class="{
													'text-danger': getCountAtAdjacency(trait.id, 'nah') > 0,
												}"
											>
												{{ getCountAtAdjacency(trait.id, "nah") }}
											</td>
											<th class="text-right">{{ getAgreePCT(trait.id) }}</th>
										</tr>
									</template>
									<tr style="border-top: solid 2px">
										<th>{{ $t("fields.totals") }}</th>
										<td
											class="text-center"
											:class="{ 'text-danger': getCountAtAdjacencyTotal('nal') > 0 }"
										>
											{{ getCountAtAdjacencyTotal("nal") }}
										</td>
										<td
											class="text-center"
											:class="{ 'text-warning': getCountAtAdjacencyTotal('al') > 0 }"
										>
											{{ getCountAtAdjacencyTotal("al") }}
										</td>
										<td
											class="text-center"
											:class="{
												'text-success': getCountAtAdjacencyTotal('agreed') > 0,
											}"
										>
											{{ getCountAtAdjacencyTotal("agreed") }}
										</td>
										<td
											class="text-center"
											:class="{ 'text-warning': getCountAtAdjacencyTotal('ah') > 0 }"
										>
											{{ getCountAtAdjacencyTotal("ah") }}
										</td>
										<td
											class="text-center"
											:class="{ 'text-danger': getCountAtAdjacencyTotal('nah') > 0 }"
										>
											{{ getCountAtAdjacencyTotal("nah") }}
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="w-100 tableWrapper">
						<table
							class="table b-table table-responsive table-hover-cells center-table w-100 qc-table"
							v-if="qcData"
						>
							<thead class="qc-thread">
								<tr>
									<th
										class="hover"
										style="text-align: left"
										:aria-sort="sortRowConfig.sortScorer === 1 ? 'ascending' : 'descending'"
										@click="reverseScorerSorting"
									>
										{{ $t("fields.scorer") }}
									</th>
									<th
										class="text-left hover"
										:aria-sort="sortRowConfig.sortState === -1 ? 'ascending' : 'descending'"
										@click="reverseStateSorting"
										v-if="showState"
									>
										{{ $t("fields.state") }}
									</th>
									<th
										class="text-left"
										@click="reverseUpdatedDateSorting"
										:aria-sort="sortRowConfig.sortUpdatedDate === -1 ? 'ascending' : 'descending'"
										v-if="!qcData.allSets && selectedQCSet.qc_type == 2"
									>
										{{ $t("fields.completed") }}
									</th>
									<th
										class="min"
										style="white-space: nowrap"
										v-for="resp in qcData.allSets ? [] : qcData.responses"
										:key="resp.id"
										:title="resp.ref_id"
										v-tippy="resp.ref_id"
									>
										{{ resp.agreePercent }}%
									</th>

									<th
										v-if="
											qcData.allSets &&
											selectedQCSet.id == -1 &&
											qc_type == 3 &&
											selectedItem.validity_config &&
											selectedItem.validity_config.enabled &&
											selectedItem.validity_config.uses_threshold
										"
									>
										{{ $t("fields.failed_responses") }}
									</th>
									<th
										class="min nowrap"
										v-for="set in qcData.allSets ? resultSets : []"
										:key="set.id"
									>
										{{ set.name }}
									</th>
									<th v-if="showActions">{{ $t("fields.actions") }}</th>
								</tr>
							</thead>

							<tbody class="qc-tbody" v-if="qcData && qcData.rows">
								<tr v-for="row in qcData.rows" :key="row.user.id">
									<td style="text-align: left">
										<span
											v-tippy="{
												theme: 'popover',
												html: `#ur2-${row.user.id}`,
												placement: 'top',
											}"
											v-if="!qcData.allSets"
											class="nowrap"
										>
											<a :href="`#/qc_set_report/${selectedQCSet.id}?userID=${row.user.id}`">{{
												row.user.scorer_id
											}}</a>

											<div :id="`ur2-${row.user.id}`" class="d-none p-0">
												<p class="pt-2 pb-0">User: {{ row.user.scorer_id }}</p>
												<table class="table table-hover-cells mb-0 pt-0">
													<thead>
														<tr>
															<th>{{ $t("fields.trait") }}</th>

															<th class="text-center">
																<i class="far fa-chevron-double-down text-danger"></i>
															</th>
															<th class="text-center">
																<i class="far fa-chevron-down text-warning"></i>
															</th>
															<th class="text-center">
																<i class="far fa-check text-success"></i>
															</th>
															<th class="text-center">
																<i class="far fa-chevron-up text-warning"></i>
															</th>
															<th class="text-center">
																<i class="far fa-chevron-double-up text-danger"></i>
															</th>
															<th></th>
														</tr>
													</thead>
													<tbody v-if="selectedItem && !allItemsSelected && row.adjStats">
														<template v-for="trait in selectedItem.rubric.traits">
															<tr
																v-if="!(trait.separator || trait.is_parent)"
																:key="trait.id"
															>
																<th>
																	{{ trait.reported_name || trait.name }}
																</th>
																<td
																	class="text-center"
																	:class="{
																		'text-danger': row.adjStats[trait.id].nal > 0,
																	}"
																>
																	{{ row.adjStats[trait.id].nal }}
																</td>
																<td
																	class="text-center"
																	:class="{
																		'text-warning': row.adjStats[trait.id].al > 0,
																	}"
																>
																	{{ row.adjStats[trait.id].al }}
																</td>
																<td
																	class="text-center"
																	:class="{
																		'text-success': row.adjStats[trait.id].e > 0,
																	}"
																>
																	{{ row.adjStats[trait.id].e }}
																</td>
																<td
																	class="text-center"
																	:class="{
																		'text-warning': row.adjStats[trait.id].ah > 0,
																	}"
																>
																	{{ row.adjStats[trait.id].ah }}
																</td>
																<td
																	class="text-center"
																	:class="{
																		'text-danger': row.adjStats[trait.id].nah > 0,
																	}"
																>
																	{{ row.adjStats[trait.id].nah }}
																</td>
																<!-- <th class="text-right">{{getAgreePCT(trait.id)}}</th>-->
															</tr>
														</template>
													</tbody>
												</table>
											</div>
										</span>
										<span v-tippy :title="row.user.scorer_id" v-if="qcData.allSets">
											{{ row.user.scorer_id }}
										</span>
									</td>
									<td class="text-left nowrap" v-if="showState" :class="getStateClass(row)">
										<span
											v-tippy="{
												theme: 'popover',
												html:
													row.stat &&
													row.stat.explanations &&
													row.stat.explanations.length > 0
														? `#pf-${row.user.id}`
														: '',
												placement: 'right',
											}"
										>
											<i
												v-if="!row.user.active"
												class="fas fa-lock"
												v-tippy
												:title="$t('tooltip.user_locked_out')"
											></i>
											{{ getStateString(row) }}

											<div
												v-if="
													row.stat &&
													row.stat.explanations &&
													row.stat.explanations.length > 0
												"
												:id="`pf-${row.user.id}`"
												:key="`${row.user.id}-${selectedQCSet && selectedQCSet.id}`"
												class="d-none p-0"
											>
												<table class="table table-hover-cells mb-0">
													<thead>
														<tr>
															<th colspan="2">
																{{ $t("QCReport.pass_fail_determination") }}
															</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(expRow, i) in row.stat.explanations" :key="i">
															<td>{{ expRow.label }}</td>
															<td
																:class="{
																	'text-danger': expRow.color == 1,
																	'text-warning': expRow.color == 2,
																	'text-success': expRow.color == 3,
																}"
															>
																{{ expRow.stat }}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</span>
									</td>
									<td class="text-left nowrap" v-if="selectedQCSet.qc_type == 2">
										<span v-if="row.stat">{{ format(row.stat.updated_at) }}</span>
									</td>

									<td
										class="min"
										v-for="resp in qcData.responses"
										:key="resp.id"
										:class="{
											inactive: getScore(resp, row) && getScore(resp, row).invalid,
										}"
									>
										<span
											v-if="getScore(resp, row)"
											v-tippy="{
												theme: 'popover',
												html: `#s-${getScore(resp, row).id}`,
												placement: 'left',
											}"
										>
											<i
												class="far fa-check text-success"
												v-if="getStatus(resp, row) == 'Y'"
												aria-hidden="false"
											></i>
											<i
												class="far fa-ban text-danger"
												v-if="getStatus(resp, row) == 'N'"
												aria-hidden="false"
											></i>
											<i
												class="far fa-exclamation-square text-warning"
												v-if="getStatus(resp, row) == 'M'"
												aria-hidden="false"
											></i>
											<span v-if="getStatus(resp, row) == '-'">-</span>

											<div
												v-if="getScore(resp, row).id"
												class="d-none p-0"
												:id="`s-${getScore(resp, row).id}`"
											>
												<Score
													:score="getScore(resp, row)"
													:compareScore="resp.true_score"
													:rubric="selectedItem.rubric"
													:type="selectedQCSet.qc_type + 10"
												>
												</Score>
											</div>
										</span>

										<span v-if="!getScore(resp, row)">-</span>
									</td>
									<td
										class="nowrap"
										v-if="
											selectedQCSet.id == -1 &&
											qc_type == 3 &&
											selectedItem.validity_config &&
											selectedItem.validity_config.enabled &&
											selectedItem.validity_config.uses_threshold
										"
										:class="getThresholdClass(row.stat, selectedItem.validity_config.threshold)"
									>
										<i
											v-if="!row.user.active"
											class="fas fa-lock"
											v-tippy
											:title="$t('tooltip.user_locked_out')"
										></i>
										<span>
											{{ row.stat ? row.stat.consecutive_failures : 0 }}
											/
											{{ selectedItem.validity_config.threshold }}
										</span>
									</td>
									<td v-for="set in qcData.allSets ? resultSets : []" :key="set.id">
										<span class="clickable" @click="pickSet(set.id)">{{
											fs.fixedPercent1d(getAgreementStat(row, set.id))
										}}</span>
									</td>
									<td v-if="showActions" class="p-custom m-0 center text-center" style="padding: 8px">
										<div class="dropdown">
											<button
												class="btn btn-sm theme-accent m-0"
												data-toggle="dropdown"
												aria-expanded="false"
											>
												<i class="fas fa-server"></i>
											</button>
											<div class="dropdown-menu dropdown-menu-right">
												<a
													v-if="selectedQCSet.qc_type == 2 && !allItemsSelected"
													class="dropdown-item"
													@click="reset(row)"
												>
													<i class="far fa-sync faw"></i>
													<label class="mb-0">{{ $t("QCReport.reset_set") }}</label>
												</a>
												<a
													v-if="selectedQCSet.qc_type == 2 && !allItemsSelected"
													class="dropdown-item"
													@click="fullReset(row)"
												>
													<i class="far fa-trash faw"></i>
													<label class="mb-0">{{ $t("QCReport.full_reset_set") }}</label>
												</a>
												<a
													v-if="selectedQCSet.qc_type == 2 && !allItemsSelected"
													class="dropdown-item"
													@click="manualPass(row)"
												>
													<i class="far fa-sort-amount-down faw"></i>
													<label class="mb-0">{{ $t("QCReport.manual_pass") }}</label>
												</a>
												<a
													v-if="selectedQCSet.id == -1 && qc_type == 3 && !allItemsSelected"
													class="dropdown-item"
													@click="clearVal(row)"
												>
													<i class="far fa-sort-amount-down faw"></i>
													<label class="mb-0">{{ $t("QCReport.clear_validity") }}</label>
												</a>
												<a
													v-if="
														selectedQCSet.qc_type == 2 &&
														!row.user.active &&
														!allItemsSelected
													"
													class="dropdown-item"
													@click="setActive(row.user)"
												>
													<i class="far fa-lock-open faw"></i>
													<label class="mb-0">{{ $t("QCReport.unlock_user") }}</label>
												</a>
												<a
													v-if="qc_type == 2 && !allItemsSelected"
													class="dropdown-item"
													@click="manualQualify(row.user)"
												>
													<i class="far fa-sort-amount-down faw"></i>
													<label class="mb-0">{{
														$t("QCReport.grant_qualification_override")
													}}</label>
												</a>
												<a
													v-if="qc_type == 2"
													class="dropdown-item"
													@click="goToPortfolio(row.user)"
												>
													<i class="far fa-balance-scale faw"></i>
													<label class="mb-0">{{ $t("QCReport.portfolio") }}</label>
												</a>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div id="deliverModal" class="modal" data-backdrop="static" data-keyboard="false" style>
			<div class="modal-dialog modal-lg">
				<div class="modal-content" v-if="selectedQCSet">
					<div class="modal-header">
						<h5>{{ $t("QCReport.deliver_set", { name: selectedQCSet.name }) }}</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col col-sm-12">
								<p>
									{{ $t("QCReport.deliver_set_description") }}
								</p>
								<config-user-search
									:clientID="user && user.client && user.client.id"
									v-model="deliverUsers"
									includeTeams
									:supervisorOnly="false"
									multiple
									:filterSectionID="(selectedSection && selectedSection.id) || ''"
								/>
							</div>
						</div>
						<div class="modal-footer">
							<button data-dismiss="modal" class="btn modal-action modal-closebtn-flat">
								{{ $t("buttons.cancel") }}
							</button>
							<button
								data-dismiss="modal"
								@click="deliver()"
								class="btn btn-flat btn-success theme-accent"
								:disabled="deliverUsers.length === 0"
							>
								{{ $t("buttons.deliver") }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="exportModal">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("QCReport.export_scores") }}</h5>
					</div>
					<div class="modal-body">
						<div class="col-12 form-group">
							<label>{{ $t("QCReport.export_type") }}</label>
							<v-select :options="qcExportOptions" v-model="exportType"></v-select>
						</div>
						<div class="col-12">
							<ul style="list-style-type: circle">
								<li style="list-style-type: circle">
									<strong>{{ $t("QCReport.response_per_column") }}:</strong>
									{{ $t("QCReport.each_response_column") }}
								</li>
								<li style="list-style-type: circle">
									<strong>{{ $t("QCReport.expand_traits") }}:</strong>
									{{ $t("QCReport.multiple_columns") }}
								</li>
							</ul>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn primary btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button
							v-if="!exporting"
							class="btn btn-flat"
							@click="doExport(exportType)"
							:disabled="!exportType"
							:class="{ 'btn-success': exportType, 'btn-secondary': !exportType }"
						>
							{{ $t("buttons.export") }}
						</button>
						<button v-if="exporting" class="btn btn-flat btn-secondary" disabled>
							<loading type="icon" class="mr-1" />
							{{ $t("buttons.exporting") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
	height: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.tippy-tooltip.popover-theme {
	max-width: auto;
}

.tbody > tr > td,
.center-wrapper {
	overflow: auto;
	width: calc(100vw - 400px);
}

.card-header {
	background-color: white;
}

.min {
	/* width: 75px; */
	min-width: 50px;
}

table tr {
	height: 42px;
}

.qc-table {
	display: table;
	width: 100%;
	overflow-y: scroll;
}

.qc-thread > tr > td,
.qc-thread > tr > th {
	text-align: center;
}

.qc-tbody > tr > td,
.qc-tbody > tr > th {
	text-align: center;
}

.tableWrapper {
	height: 50vh;
	overflow-x: scroll;
	overflow-y: scroll;
	padding-bottom: 0%;

	border-top-style: solid;
	border-top-width: 1px;
	border-top-color: rgba(120, 130, 140, 0.2);
}

.inactive {
	background-color: rgba(31, 40, 40, 0.079);
}

.long {
	background: yellow;
	letter-spacing: 1em;
}

.btn-topnav {
	margin-top: -8px;
	margin-bottom: -8px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}

.btn-topnav:last-child {
	margin-right: -16px;
	margin-left: -16px;
}

.btn-topnav:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.btn-topnav a {
	line-height: 56px;
}

.topnav-margins {
	margin-top: -8px;
	margin-bottom: -8px;
}

.clickable {
	cursor: pointer;
}

.table-hover-cells > tbody > tr > th:hover,
.table-hover-cells > tbody > tr > td:hover {
	background-color: #e8e8e8;
}

.table-hover-cells > thead > tr > th:hover,
.table-hover-cells > thead > tr > td:hover {
	background-color: #e8e8e8;
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCConfigService from "@/services/QCConfigService";
import ConfigService from "@/services/ConfigService";
import UserService from "@/services/UserService";
import ConfigSelect from "@/components/ConfigSelect";
import Notie from "@/services/NotieService";
import draggable from "vuedraggable";
import $ from "jquery";
import Score from "../../components/Score";
import store from "../../services/Store";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import fs from "@/services/FormatService";

import FileService from "../../services/FileService";
import QCService from "../../services/QCService";
import moment from "moment";

import { QC_TYPES, idToName } from "@/services/Constants";

const JSZip = require("jszip");

export default {
	name: "QCReport",
	data() {
		var qc_type = store.getDefault(this, "qc.report.qc_type", 1);

		return {
			fs: fs,

			user: null,
			sections: [],
			items: [],
			tabIndex: 1,
			refreshing: false,

			qc_type: qc_type,

			selectedSection: null,
			selectedItem: null,

			editSet: null,
			setToDelete: null,

			qcSets: [],
			teams: [],
			selectedTeam: null,

			selectedQCSet: null,
			qcData: null,

			adjacencyStats: null,
			adjacencyUserStatTotals: null,
			adjacencyUserItemSets: null,

			deliverUsers: [],

			exporting: false,
			qcExportOptions: [
				{ label: this.$i18n.t("QCReport.response_per_column"), id: 1 },
				{ label: this.$i18n.t("QCReport.expand_traits"), id: 2 },
			],
			exportType: null,

			sortRowConfig: {
				sortScorer: 1,
				sortState: 1,
				sortUpdatedDate: 1,
				sortStateFirst: true,
				sortByScorer: false,
			},

			resultSets: [],
		};
	},
	components: { ConfigSelect, draggable, Score, ConfigUserSearch },
	created() {
		AuthService.getUser().then((user) => {
			this.user = user;
			let teamCall = ConfigService.listTeams;
			if (this.user.role.limit_teams) {
				teamCall = ConfigService.listUserTeams;
			}

			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			Promise.all([ConfigService.listSectionsNoPreload(ctx), teamCall()]).then((resps) => {
				this.sections = resps[0].data.sections;
				this.selectedSection = store.resolveSelectedSection(this, this.sections, "audit.r.selectedSection");

				if (this.selectedSection) {
					this.items = this.selectedSection.items;
					this.selectedItem = store.resolveSelectedItem(this, this.selectedSection, "audit.r.selectedItem");
				}

				this.teams = resps[1].data.teams;
				if (this.user.role.limit_teams) {
					this.teams = resps[1].data;
				}

				this.selectedTeam = store.bindTeam(this, this.teams, !this.user.role.limit_teams);
			});
		});
	},
	watch: {
		selectedSection() {
			store.set(this, "audit.r.selectedSection", this.selectedSection ? this.selectedSection.id : null);
			this.getItems();
		},
		selectedItem() {
			store.set(this, "audit.r.selectedItem", this.selectedItem ? this.selectedItem.id : null);
			this.querySets();
			if (this.allItemsSelected) {
				this.getData();
			}
		},
		selectedQCSet() {
			store.set(this, `${this.qc_type}-selectedQCSet`, this.selectedQCSet ? this.selectedQCSet : null);
			this.getData();
		},
		selectedTeam() {
			store.set(this, "selectedTeam", this.selectedTeam);
			this.getData();
		},
		qc_type() {
			store.set(this, "qc.report.qc_type", this.qc_type);
			this.querySets();
			if (this.allItemsSelected) {
				this.getData();
			}
		},
	},
	computed: {
		showStateAllSets() {
			const { qc_type, selectedQCSet, allItemsSelected } = this;
			const { QUALIFICATION, VALIDITY } = QC_TYPES;
			return (
				selectedQCSet.id == -1 && !allItemsSelected && (qc_type === QUALIFICATION.id || qc_type === VALIDITY.id)
			);
		},

		qcSetsPlus() {
			const { qcSets, allSets } = this;
			return (qcSets || []).concat(allSets);
		},

		allSetsSelected() {
			return this.selectedQCSet.id === this.allSets.id;
		},

		allSets() {
			return { id: -1, name: this.$i18n.t("data_description.all"), meta: true };
		},

		selectableItems() {
			const { items, allItems } = this;
			return (items || []).concat(allItems);
		},

		allItemsSelected() {
			const { selectedItem, allItems } = this;
			return selectedItem && selectedItem.id === allItems.id;
		},

		allItems() {
			return { id: -1, name: this.$i18n.t("data_description.all"), meta: true };
		},

		showActions() {
			const { PRACTICE, CALIBRATION, VALIDITY } = QC_TYPES;
			const { qc_type, selectedQCSet, allItemsSelected } = this;
			return !(
				qc_type === PRACTICE.id ||
				qc_type === CALIBRATION.id ||
				(qc_type === VALIDITY.id && selectedQCSet.id != -1) ||
				allItemsSelected
			);
		},

		showState() {
			const { qcData, selectedQCSet, allItemsSelected, showStateAllSets } = this;
			const { QUALIFICATION, CALIBRATION } = QC_TYPES;

			if (!(qcData && selectedQCSet)) return false;
			if (qcData.allSets) {
				return showStateAllSets;
			}
			switch (selectedQCSet.qc_type) {
				case QUALIFICATION.id:
				case CALIBRATION.id:
					return !allItemsSelected;
			}
			return false;
		},
	},
	methods: {
		async getItems() {
			const { selectedSection } = this;
			if (!selectedSection) return;
			try {
				const { data } = await ConfigService.getSection(selectedSection.id);
				if (data) {
					this.items = data.items || [];
					if (this.items.length > 0) {
						this.selectedItem = this.items[0];
					}
				}
			} catch (e) {
				Notie.error("Failed to get Items", e);
			}
		},

		querySets() {
			this.qcSets = [];
			if (this.selectedItem && !this.allItemsSelected && this.selectedSection) {
				QCConfigService.listQCSets(this.selectedSection.id, this.selectedItem.id, 0).then((resp) => {
					_.each(resp.data, (set) => {
						if (set.qc_type == this.qc_type) {
							this.qcSets.push(set);
						}
					});
					this.selectedQCSet = store.get(this, `${this.qc_type}-selectedQCSet`);
					if (this.selectedQCSet) {
						if (this.selectedQCSet.id != -1) {
							this.selectedQCSet = store.resolveFromList(
								this,
								this.qcSets,
								"id",
								0,
								`${this.qc_type}-selectedQCSet`
							);
						}
					} else {
						this.selectedQCSet = { id: -1, name: this.$i18n.t("data_description.all"), meta: true };
					}
					this.sortSets();
					this.getData();
				});
			}
		},
		sortSets() {
			this.qcSets = _.sortBy(this.qcSets, (s) => {
				return s.sequence;
			});
		},

		reverseScorerSorting() {
			this.sortRowConfig.sortByScorer = true;
			this.sortRowConfig.sortScorer *= -1;
			this.sortRows();
		},

		reverseStateSorting() {
			this.sortRowConfig.sortByScorer = false;
			this.sortRowConfig.sortState *= -1;
			this.sortRowConfig.sortStateFirst = true;
			this.sortRows();
		},

		reverseUpdatedDateSorting() {
			this.sortRowConfig.sortByScorer = false;
			this.sortRowConfig.sortUpdatedDate *= -1;
			this.sortRowConfig.sortStateFirst = false;
			this.sortRows();
		},

		sortRows() {
			const { sortStateFirst, sortUpdatedDate, sortState, sortByScorer, sortScorer } = this.sortRowConfig;
			this.qcData.rows = this.qcData.rows.sort((rowA, rowB) => {
				if (sortByScorer) {
					return rowA.user.scorer_id > rowB.user.scorer_id ? sortScorer : sortScorer * -1;
				}

				if (rowA.stat == null) return 1;
				if (rowB.stat == null) return -1;

				if (rowA.stat.state === rowB.stat.state || !sortStateFirst)
					return (new Date(rowB.stat.updated_at) - new Date(rowA.stat.updated_at)) * sortUpdatedDate;
				return (rowB.stat.state - rowA.stat.state) * sortState;
			});
		},

		async getData() {
			this.refreshing = true;
			this.qcData = null;
			let teamID = null;
			if (this.selectedTeam) {
				teamID = this.selectedTeam.id;
			}
			if (!(this.selectedSection && this.selectedItem && this.selectedQCSet)) {
				return;
			}
			if (this.allItemsSelected) {
				try {
					const {
						data: { rows, sets },
					} = await QCService.getAllSetStatusesForAllItems(this.selectedSection.id, this.qc_type, teamID);
					this.qcData = {
						allSets: true,
						rows,
					};
					this.sortRows();
					this.resultSets = sets;
					this.refreshing = false;
				} catch (e) {
					Notie.error("Failed to get all set statuses", e);
				}
			} else if (this.allSetsSelected) {
				//Load data for all sets
				try {
					const resp = await QCService.getAllSetStatuses(
						this.selectedSection.id,
						this.selectedItem.id,
						this.qc_type,
						teamID
					);

					this.qcData = {
						allSets: true,
						rows: resp.data.rows,
					};
					this.sortRows();
					this.resultSets = resp.data.sets;
				} catch (e) {
					let emsg = "Failed to get all set statuses";
					console.error(emsg, e);
					Notie.error(emsg, e);
				}

				// Bubble up "active" field from userclient to top level user struct
				let clientID = this.user.client.id;
				for (let row of this.qcData.rows) {
					if (row.user) {
						for (let client of row.user.clients) {
							if (client.client_id == clientID) {
								row.user.active = client.active;
							}
						}
					}
				}
				try {
					const resp = await QCService.getQCSetTypeAgreementStats(
						this.selectedSection.id,
						this.selectedItem.id,
						this.qc_type
					);
					this.refreshing = false;
					if (resp.data) {
						this.adjacencyStats = resp.data[0];
					}
				} catch (e) {
					let emsg = "Failed to get QC agreement stats";
					console.error(emsg, e);
					Notie.error(emsg, e);
				}
				try {
					const resp = await QCService.getQCSetTypeAgreementStatsUserItemTotals(
						this.selectedSection.id,
						this.selectedItem.id,
						this.qc_type
					);
					this.refreshing = false;
					if (resp.data) {
						this.adjacencyUserStatTotals = resp.data;
					}
				} catch (e) {
					let emsg = "Failed to get QC agreement stats";
					console.error(emsg, e);
					Notie.error(emsg, e);
				}
				try {
					const resp = await QCService.getQCSetTypeAgreementStatsUserItemSets(
						this.selectedSection.id,
						this.selectedItem.id,
						this.qc_type
					);
					this.refreshing = false;
					if (resp.data) {
						this.adjacencyUserItemSets = resp.data;
					}
				} catch (e) {
					let emsg = "Failed to get QC agreement stats";
					console.error(emsg, e);
					Notie.error(emsg, e);
				}
			} else {
				try {
					const resp = await QCService.getSetStatuses(
						this.selectedSection.id,
						this.selectedItem.id,
						this.selectedQCSet.id,
						teamID
					);
					this.qcData = resp.data;
					this.qcData.responses = _.sortBy(this.qcData.responses, ({ sequence }) => sequence);

					_.each(this.qcData.responses, this.setupRespStats);
					this.setupUserStats();

					const { data } = await QCService.getQCSetAgreementStats(this.selectedQCSet.id);
					this.refreshing = false;
					if (data) {
						this.adjacencyStats = data[0];
					}
				} catch (e) {
					let emsg = "Failed to get set statues";
					console.error(emsg, e);
					Notie.error(emsg, e);
				}
			}
		},

		async deliver() {
			const ids = [];
			_.each(this.deliverUsers, (u) => {
				//check if we have a team object in the users array (design ??)
				if (u.users) {
					_.each(u.users, (subUser) => {
						ids.push(subUser.id);
					});
				} else if (u.email) {
					ids.push(u.id);
				}
			});
			const payload = {
				qc_set_id: this.selectedQCSet.id,
				user_ids: ids,
			};
			try {
				await QCService.deliverCalibrationSet(payload);
				Notie.info(this.$i18n.t("notie.set_delivered"));
				this.getData();
			} catch (e) {
				Notie.error("Failed to Deliver Calibration Set", e);
			}
		},

		setupRespStats(resp) {
			// console.log(resp);
			var total = 0;
			var agreed = 0;
			_.each(this.qcData.rows, (row) => {
				_.each(row.qc_scores, (qc_score) => {
					if (qc_score.qc_response_id == resp.id) {
						total++;
						if (this.agreed(resp, qc_score)) {
							agreed++;
						}
					}
				});
			});
			if (total > 0) {
				resp.agreePercent = ((agreed / total) * 100).toFixed(2);
			} else {
				resp.agreePercent = "-";
			}
		},

		setupUserStats() {
			if (this.allItemsSelected) return;
			this.sortRows();

			_.each(this.qcData.rows, (row) => {
				var stat = {};
				_.each(this.selectedItem.rubric.traits, (trait) => {
					stat[trait.id] = {
						nal: 0,
						al: 0,
						e: 0,
						ah: 0,
						nah: 0,
						total: 0,
					};
					// debugger;
				});

				_.each(row.qc_scores, (qc_score) => {
					_.each(this.qcData.responses, (response) => {
						if (response.id == qc_score.qc_response_id) {
							_.each(response.true_score.trait_scores, (true_ts) => {
								_.each(qc_score.trait_scores, (user_ts) => {
									if (user_ts.trait_id == true_ts.trait_id) {
										if (!stat[user_ts.trait_id]) {
											return;
										}
										var num = user_ts.score - true_ts.score;
										if (num < -1) {
											stat[user_ts.trait_id].nal++;
										}
										if (num == -1) {
											stat[user_ts.trait_id].al++;
										}
										if (num == 0) {
											stat[user_ts.trait_id].e++;
										}
										if (num == 1) {
											stat[user_ts.trait_id].ah++;
										}
										if (num > 1) {
											stat[user_ts.trait_id].nah++;
										}
										stat[user_ts.trait_id].total++;
									}
								});
							});
						}
					});
				});

				//For each score, calculate adj stats
				row.adjStats = stat;
			});
		},

		getStatus(resp, row) {
			var status = "-";
			_.each(this.qcData.responses, (r) => {
				if (r.id == resp.id) {
					_.each(row.qc_scores, (score) => {
						if (score.qc_response_id == r.id) {
							if (this.agreed(r, score)) {
								status = "Y";
							} else if (this.adjacent(r, score)) {
								status = "M";
							} else {
								status = "N";
							}
						}
					});
				}
			});
			return status;
		},

		agreed(qcResponse, qcScore) {
			var agreed = false;

			var totalTraits = qcResponse.true_score.trait_scores.length;
			var numAgreed = 0;
			_.each(qcResponse.true_score.trait_scores, function (ts) {
				_.each(qcScore.trait_scores, function (uts) {
					if (ts.trait_id == uts.trait_id) {
						if (ts.score == uts.score) {
							numAgreed++;
						}
					}
				});
			});
			if (numAgreed == totalTraits) {
				agreed = true;
			}

			return agreed;
		},

		adjacent(qcResponse, qcScore) {
			var agreed = false;

			var totalTraits = qcResponse.true_score.trait_scores.length;
			var numAgreed = 0;
			_.each(qcResponse.true_score.trait_scores, function (ts) {
				_.each(qcScore.trait_scores, function (uts) {
					if (ts.trait_id == uts.trait_id) {
						if (Math.abs(ts.score - uts.score) <= 1) {
							numAgreed++;
						}
					}
				});
			});
			if (numAgreed == totalTraits) {
				agreed = true;
			}

			return agreed;
		},

		getScore(resp, row) {
			var s = null;
			_.each(this.qcData.responses, (r) => {
				if (r.id == resp.id) {
					_.each(row.qc_scores, (score) => {
						if (score.qc_response_id == r.id) {
							s = score;
							s.user = row.user;
						}
					});
				}
			});
			return s;
		},

		getAgreementStat(row, setID) {
			let stat = _.find(row.cells, { qc_set_id: setID });
			if (!stat || !stat.any_results) {
				return null;
			}
			return stat.agreement;
		},

		getCountAtAdjacency(traitID, key) {
			var result = "-";
			if (this.adjacencyStats) {
				_.each(this.adjacencyStats.trait_agreements, (ta) => {
					if (ta.trait_id == traitID) {
						result = ((ta[key] / ta.total) * 100).toFixed(2);
					}
				});
			}
			return result;
		},

		getCountAtAdjacencyTotal(key) {
			var result = 0;
			var total = 0;
			if (this.adjacencyStats) {
				_.each(this.adjacencyStats.trait_agreements, (ta) => {
					if (!ta) return;
					result += ta[key];
					total += ta.total;
				});
			}
			if (result == 0) {
				return "-";
			}
			return ((result / total) * 100).toFixed(2);
		},

		getAgreePCT(traitID) {
			var result = "-";
			if (this.adjacencyStats) {
				_.each(this.adjacencyStats.trait_agreements, (ta) => {
					if (ta.trait_id == traitID) {
						result = ((ta.agreed / ta.total) * 100).toFixed(2) + "%";
					}
				});
			}
			return result;
		},

		//qual states
		getStateString(row) {
			if (row.stat) {
				if (row.stat.state == 1) {
					return "In Progress";
				}
				if (row.stat.state == 2) {
					return "Passed";
				}
				if (row.stat.state == 3) {
					if (this.qc_type === QC_TYPES.QUALIFICATION.id) {
						return "Not Qualified";
					}
					return "Failed";
				}
			}
			return "-";
		},

		getStateClass(row) {
			var _class = "";
			if (row.stat) {
				if (row.stat.state == 1) {
					_class = "";
				}
				if (row.stat.state == 2) {
					_class = "text-success";
				}
				if (row.stat.state == 3) {
					_class = "text-danger";
				}
			}
			return _class;
		},

		getThresholdClass(stat, threshold) {
			var _class = "";
			if (stat) {
				if (stat.consecutive_failures == threshold - 1) {
					_class = "text-warning";
				} else if (stat.consecutive_failures < threshold) {
					_class = "text-success";
				} else {
					_class = "text-danger";
				}
			}
			return _class;
		},

		format(date) {
			return moment(date).format("MM/DD/YY h:mm:ss a");
		},

		reset(data) {
			QCService.resetSet(this.selectedSection.id, this.selectedItem.id, data.user.id, this.selectedQCSet.id).then(
				(resp) => {
					this.getData();
				}
			);
		},

		fullReset(data) {
			QCService.fullResetSet(
				this.selectedSection.id,
				this.selectedItem.id,
				data.user.id,
				this.selectedQCSet.id
			).then((resp) => {
				this.getData();
			});
		},

		manualPass(data) {
			QCService.overrideQualSet(
				this.selectedSection.id,
				this.selectedItem.id,
				data.user.id,
				this.selectedQCSet.id
			).then((resp) => {
				this.getData();
			});
		},
		clearVal(data) {
			QCService.clearValidity(
				this.selectedSection.id,
				this.selectedItem.id,
				data.user.id,
				this.selectedQCSet.id
			).then((resp) => {
				this.getData();
			});
		},
		setActive(user) {
			UserService.activate(user)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.user_reactivated", { name: user.full_name }));
					user.active = true;
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.reactivate_user_fail"), err);
					user.active = failValue;
				});
		},

		manualQualify(user) {
			QCService.overrideQual(this.selectedSection.id, this.selectedItem.id, user.id)
				.then(() => {
					Notie.info(this.$i18n.t("notie.user_granted_qualification"));
					this.getData();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.user_granted_qualification"), err);
				});
		},

		goToPortfolio(user) {
			this.$router.push(
				`/qc_portfolio?qc=2&sect=${this.selectedSection.id}&item=${this.selectedItem.id}&user=${user.id}`
			);
		},

		mapStateOrder(row) {
			if (!row.stat) {
				return 0;
			} else {
				return row.stat.state;
			}
		},

		pickSet(setID) {
			// console.log("PICK SET", setID);
			this.selectedQCSet = _.find(this.qcSets, { id: setID });
		},

		tryExport() {
			if (this.allSetsSelected) {
				this.doExport();
			} else {
				$("#exportModal").modal("show");
			}
		},

		doExport(exportType) {
			const {
				exporting,
				qcData: { responses, rows },
				selectedQCSet,
				selectedItem,
				allItemsSelected,
				allSetsSelected,
				resultSets,
				qc_type,
				selectedSection,
				adjacencyUserStatTotals,
				adjacencyUserItemSets,
			} = this;

			if (exporting) return;

			if (allItemsSelected) {
				this.exportAllItems();
			} else if (allSetsSelected) {
				FileService.qcReportAllSetsCSV(
					rows,
					resultSets,
					qc_type,
					selectedSection,
					selectedItem,
					adjacencyUserItemSets,
					adjacencyUserStatTotals,
					this.selectedItem.rubric.traits
				);
			} else {
				if (!exportType && exportType.id) return;
				FileService.qcReportCSV(
					responses,
					rows,
					exportType.id,
					selectedQCSet,
					selectedItem.rubric,
					adjacencyUserStatTotals,
					this.selectedItem.rubric.traits
				);
			}

			$("#exportModal").modal("hide");
		},

		async exportAllItems() {
			this.exporting = true;
			const { selectedSection, items, qc_type, selectedTeam, resultSets } = this;
			let teamID = null;
			if (selectedTeam) {
				teamID = selectedTeam.id;
			}

			const zip = new JSZip();
			await Promise.all(
				resultSets.map(async (qcSet) => {
					try {
						const {
							data: { responses, rows },
						} = await QCService.getSetStatuses(selectedSection.id, qcSet.item_id, qcSet.id, teamID);
						const item = items.find(({ id }) => id === qcSet.item_id);
						const [csvFile, fileName] = FileService.qcReportFileInfo(
							_.sortBy(responses, ({ sequence }) => sequence),
							rows,
							2,
							qcSet,
							item.rubric
						);

						zip.file(`${item.name}/${fileName}`, csvFile);
					} catch (e) {
						console.log(e);
					}
				})
			);

			const zipData = await zip.generateAsync({ type: "base64" });
			FileService.downloadZipFile(`QC_Report_${selectedSection.name}_${idToName(QC_TYPES, qc_type)}`, zipData);
			this.exporting = false;
		},
	},
};
</script>
