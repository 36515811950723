var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"videoFeeds",staticClass:"flex d-flex flex h-100 video-feeds",class:{ 'flex-column': _vm.layoutDir == 'h', 'flex-row': _vm.layoutDir == 'v' },attrs:{"id":"videoFeeds"}},[(_vm.participants.length > 0)?_c('div',{key:_vm.participants[0].uid,staticClass:"video-feed-container",style:({ width: `${_vm.focusFeedWidth}px`, height: `${_vm.focusFeedHeight}px` })},[_c('video-feed',{staticClass:"video-feed",attrs:{"participant":_vm.participants[0],"local":_vm.participants[0].local},on:{"videoResize":_vm.focusedVideoResized}})],1):_vm._e(),_c('div',{staticClass:"position-relative",class:{
			'h-100 video-feed-list-pad-bottom': _vm.layoutDir == 'v',
			'w-100 video-feed-list-pad-right': _vm.layoutDir == 'h',
		},staticStyle:{"overflow":"hidden"}},[(_vm.participants.length >= 1)?_c('div',{ref:"scrollContainer",staticClass:"d-flex",class:{
				'h-100 flex-column scroll-y': _vm.layoutDir == 'v',
				'flex-row scroll-x': _vm.layoutDir == 'h',
			},on:{"scroll":_vm.feedsScrolled}},[(_vm.participants.length <= 1 && _vm.layoutDir == 'v')?_c('div',{staticClass:"waiting-container text-center",style:({ width: `${_vm.feedWidth - 10}px`, height: `100%` })},[_c('h3',{staticClass:"mb-0 text-very-muted"},[_vm._v("Waiting for more users...")])]):_vm._e(),(_vm.participants.length <= 1 && _vm.layoutDir == 'h')?_c('div',{staticClass:"waiting-container",style:({ height: `${_vm.feedHeight - 10}px`, width: `100%` })},[_c('h3',{staticClass:"mb-0 text-very-muted"},[_vm._v("Waiting for more users...")])]):_vm._e(),_vm._l((_vm.participants),function(parti,i){return [(i > 0)?_c('div',{key:parti.uid,staticClass:"video-feed-container",class:{
						'mb-0': _vm.layoutDir == 'v' && i == _vm.participants.length - 1,
						'mr-0': _vm.layoutDir == 'h' && i == _vm.participants.length - 1,
					},style:({
						width: `${_vm.feedWidth}px`,
						minWidth: `${_vm.feedWidth}px`,
						height: `${_vm.feedHeight}px`,
						minHeight: `${_vm.feedHeight}px`,
					})},[_c('video-feed',{staticClass:"video-feed",attrs:{"participant":parti}})],1):_vm._e()]})],2):_vm._e(),(_vm.layoutDir == 'h')?[_c('Transition',{attrs:{"name":"fade"}},[(_vm.scrollLeft > 1)?_c('div',{staticClass:"scroller scroller-left"},[_c('button',{staticClass:"btn btn-rounded btn-icon btn-md btn-primary",on:{"click":_vm.scrollFeedsLeft}},[_c('i',{staticClass:"fas fa-arrow-left"})])]):_vm._e()]),_c('Transition',{attrs:{"name":"fade"}},[(_vm.scrollRight > 1)?_c('div',{staticClass:"scroller scroller-right"},[_c('button',{staticClass:"btn btn-rounded btn-icon btn-md btn-primary",on:{"click":_vm.scrollFeedsRight}},[_c('i',{staticClass:"fas fa-arrow-right"})])]):_vm._e()])]:_vm._e(),(_vm.layoutDir == 'v')?[_c('Transition',{attrs:{"name":"fade"}},[(_vm.scrollTop > 1)?_c('div',{staticClass:"scroller scroller-up"},[_c('button',{staticClass:"btn btn-rounded btn-icon btn-md btn-primary",on:{"click":_vm.scrollFeedsUp}},[_c('i',{staticClass:"fas fa-arrow-up"})])]):_vm._e()]),_c('Transition',{attrs:{"name":"fade"}},[(_vm.scrollBottom > 1)?_c('div',{staticClass:"scroller scroller-down"},[_c('button',{staticClass:"btn btn-rounded btn-icon btn-md btn-primary",on:{"click":_vm.scrollFeedsDown}},[_c('i',{staticClass:"fas fa-arrow-down"})])]):_vm._e()])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }