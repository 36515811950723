<template>
	<b-modal
		:visible="visible"
		:title="$t('Auditing.escalate_qc')"
		hide-footer
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
	>
		<div class="modal-body">
			<h6>
				{{
					$t("Auditing.escalate_num_qc", {
						num: numSelected,
						r: numSelected == 1 ? "response" : "responses",
					})
				}}
			</h6>
			<div class="form-group mt-4">
				<label>{{ $t("Auditing.qc_type") }}</label>
				<config-select sortBy="sequence" :options="qcTypes" v-model="qcType"></config-select>
			</div>
			<div v-if="qcType" class="form-group">
				<label>{{ $t("Auditing.set") }}</label>
				<config-select sortBy="sequence" :options="qcSets" v-model="qcSet"></config-select>
			</div>
			<div v-if="qcType" class="form-group">
				<label>{{ $t("Auditing.apply_scores") }}</label>
				<config-select
					sortBy="sequence"
					:options="qcApplyScoreTypes"
					v-model="qcApplyScoreType"
					:disabled="!scoreCounts"
				></config-select>
			</div>
		</div>
		<div class="modal-footer">
			<button v-if="!qcEscalating" @click="close" class="btn btn-flat btn-primary" data-dismiss="modal">
				{{ $t("buttons.cancel") }}
			</button>
			<button v-else class="btn btn-flat btn-primary" data-dismiss="modal" disabled>
				{{ $t("buttons.cancel") }}
			</button>

			<button v-if="qcEscalating" class="btn btn-flat theme" disabled>
				<loading type="icon" />
			</button>
			<button
				v-else-if="scoreCounts && qcApplyScoreType && !qcApplyScoreType.disabled"
				@click="escalateToQC()"
				class="btn btn-flat theme"
			>
				{{ $t("buttons.escalate") }}
			</button>
			<button v-else class="btn btn-flat btn-secondary" disabled>
				{{ $t("buttons.escalate") }}
			</button>
		</div>
	</b-modal>
</template>

<script>
import AuditingService from "@/services/AuditingService";
import QCConfigService from "@/services/QCConfigService";
import { QC_TYPES } from "@/services/Constants";
import Notie from "@/services/NotieService";

import ConfigSelect from "../ConfigSelect";

export default {
	name: "QcModal",

	components: {
		ConfigSelect,
	},

	props: {
		visible: Boolean,
		selectedIDs: Array,
		selectAll: Boolean,
		query: Object,
		sectionId: String,
		itemId: String,
	},

	data() {
		const { PRACTICE, QUALIFICATION, VALIDITY, CALIBRATION, EXEMPLAR } = QC_TYPES;
		const qcTypes = [PRACTICE, QUALIFICATION, VALIDITY, CALIBRATION, EXEMPLAR];
		const qcApplyScoreTypes = [
			{ name: this.$i18n.t("qc_apply_score_types.none"), active: true, id: 0, sequence: 1 },
			{ name: this.$i18n.t("qc_apply_score_types.most_recent_score"), active: true, id: 10, sequence: 2 },
			{ name: this.$i18n.t("qc_apply_score_types.first_score"), active: true, id: 1, sequence: 3 },
			{ name: this.$i18n.t("qc_apply_score_types.second_score"), active: true, id: 2, sequence: 4 },
			{ name: this.$i18n.t("qc_apply_score_types.resolution_score"), active: true, id: 3, sequence: 5 },
			{ name: this.$i18n.t("qc_apply_score_types.backread_score"), active: true, id: 4, sequence: 6 },
		];

		return {
			scoreCounts: null,
			qcEscalating: false,
			qcSet: null,
			qcType: qcTypes[0],
			qcApplyScoreType: qcApplyScoreTypes[0],
			qcSets: [],
			qcTypes,
			qcApplyScoreTypes,
		};
	},

	computed: {
		numSelected() {
			return this.selectedIDs.length;
		},
	},

	watch: {
		visible(value) {
			if (value) {
				this.loadQCSets();
				this.loadScoreCounts();
				this.qcApplyScoreType = this.qcApplyScoreTypes[0];
			}
		},

		qcType() {
			this.loadQCSets();
		},

		qcSet() {
			this.constructApplyScoreTypes();
		},
	},

	methods: {
		loadQCSets() {
			if (!(this.sectionId && this.itemId && this.qcType)) {
				return;
			}
			QCConfigService.listQCSets(this.sectionId, this.itemId, this.qcType.id).then((resp) => {
				this.qcSets = resp.data;
				this.qcSets = _.sortBy(this.qcSets, "sequence");
				this.qcSets.unshift({ name: "☆ Pool", active: true, id: "" });
			});
		},

		loadScoreCounts() {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.query,
			};

			this.scoreCounts = null;
			AuditingService.getScoreCounts(selection)
				.then((resp) => {
					this.scoreCounts = resp.data;
					this.constructApplyScoreTypes();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("calculate_scores_on_responses_fail"), err);
				});
		},

		constructApplyScoreTypes() {
			if (!this.scoreCounts) {
				return;
			}

			//None
			if (this.qcSet && this.qcSet.id != "") {
				this.qcApplyScoreTypes[0].name = this.$i18n.t("data_description.none_must_apply_scores");
				this.qcApplyScoreTypes[0].disabled = true;
			} else {
				this.qcApplyScoreTypes[0].name = this.$i18n.t("data_description.none");
				this.qcApplyScoreTypes[0].disabled = false;
			}

			//Most Recent
			if (this.scoreCounts.any == this.scoreCounts.total) {
				this.qcApplyScoreTypes[1].name = this.$i18n.t("qc_apply_score_types.most_recent_score");
				this.qcApplyScoreTypes[1].disabled = false;
			} else {
				let non = this.scoreCounts.total - this.scoreCounts.any;
				if (non == 1) {
					this.qcApplyScoreTypes[1].name = this.$i18n.t("qc_apply_score_types.none_most_recent", {
						non: non,
					});
					this.qcApplyScoreTypes[1].disabled = true;
				} else {
					this.qcApplyScoreTypes[1].name = this.$i18n.t("qc_apply_score_types.nones_most_recent", {
						non: non,
					});
					this.qcApplyScoreTypes[1].disabled = true;
				}
			}

			//First
			if (this.scoreCounts.first == this.scoreCounts.total) {
				this.qcApplyScoreTypes[2].name = this.$i18n.t("qc_apply_score_types.first_score");
				this.qcApplyScoreTypes[2].disabled = false;
			} else {
				let non = this.scoreCounts.total - this.scoreCounts.first;
				if (non == 1) {
					this.qcApplyScoreTypes[2].name = this.$i18n.t("qc_apply_score_types.none_first", { non: non });
					this.qcApplyScoreTypes[2].disabled = true;
				} else {
					this.qcApplyScoreTypes[2].name = this.$i18n.t("qc_apply_score_types.nones_first", { non: non });
					this.qcApplyScoreTypes[2].disabled = true;
				}
			}

			//Second
			if (this.scoreCounts.second == this.scoreCounts.total) {
				this.qcApplyScoreTypes[3].name = this.$i18n.t("qc_apply_score_types.second_score");
				this.qcApplyScoreTypes[3].disabled = false;
			} else {
				let non = this.scoreCounts.total - this.scoreCounts.second;
				if (non == 1) {
					this.qcApplyScoreTypes[3].name = this.$i18n.t("qc_apply_score_types.none_second", { non: non });
					this.qcApplyScoreTypes[3].disabled = true;
				} else {
					this.qcApplyScoreTypes[3].name = this.$i18n.t("qc_apply_score_types.nones_second", { non: non });
					this.qcApplyScoreTypes[3].disabled = true;
				}
			}

			//Resolution
			if (this.scoreCounts.resolution == this.scoreCounts.total) {
				this.qcApplyScoreTypes[4].name = this.$i18n.t("qc_apply_score_types.resolution_score");
				this.qcApplyScoreTypes[4].disabled = false;
			} else {
				let non = this.scoreCounts.total - this.scoreCounts.resolution;
				if (non == 1) {
					this.qcApplyScoreTypes[4].name = this.$i18n.t("qc_apply_score_types.none_resolution", { non: non });
					this.qcApplyScoreTypes[4].disabled = true;
				} else {
					this.qcApplyScoreTypes[4].name = this.$i18n.t("qc_apply_score_types.nones_resolution", {
						non: non,
					});
					this.qcApplyScoreTypes[4].disabled = true;
				}
			}

			//Backread
			if (this.scoreCounts.backread == this.scoreCounts.total) {
				this.qcApplyScoreTypes[5].name = this.$i18n.t("qc_apply_score_types.backread_score");
				this.qcApplyScoreTypes[5].disabled = false;
			} else {
				let non = this.scoreCounts.total - this.scoreCounts.backread;
				if (non == 1) {
					this.qcApplyScoreTypes[5].name = this.$i18n.t("qc_apply_score_types.none_backread", { non: non });
					this.qcApplyScoreTypes[5].disabled = true;
				} else {
					this.qcApplyScoreTypes[5].name = this.$i18n.t("qc_apply_score_types.nones_backread", { non: non });
					this.qcApplyScoreTypes[5].disabled = true;
				}
			}
		},

		escalateToQC() {
			const selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.query,
			};

			const qcType = this.qcType && this.qcType.id;

			let setID = null;
			if (this.qcSet && this.qcSet.id != "") {
				setID = this.qcSet.id;
			}

			const scoreAction = this.qcApplyScoreType && this.qcApplyScoreType.id;

			if (qcType == null || scoreAction == null) {
				Notie.error(this.$i18n.t("notie.invalid_request"));
				return;
			}

			this.qcEscalating = true;
			AuditingService.escalateToQC(selection, qcType, setID, scoreAction)
				.then((resp) => {
					Notie.success(this.$i18n.t("notie.escalated_to_qc"));
					this.$emit("onSubmit");
					this.qcEscalating = false;
					this.close();
				})
				.catch((err) => {
					Notie.error("Failed to escalate responses to QC", err);
					this.qcEscalating = false;
					this.close();
				});
		},

		close() {
			this.$emit("close");
		},
	},
};
</script>
