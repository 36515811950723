<template>
	<page :title="$t('RFSetup.title')">
		<div class>
			<div data-v-14f1ec3c class="row mx-0 no-grow px-2 pt-3 b-b box mb-0">
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("RFSetup.section") }}</label>
					<div class="flex">
						<config-select
							nullText="No sections"
							:options="sections"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>

				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("RFSetup.item") }}</label>
					<div class="flex">
						<config-select
							nullText="No items"
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
			</div>

			<div class="row no-gutters">
				<!-- Training -->
				<div class="col-sm-5">
					<div class="p-lg-3">
						<div class="box-header">
							<h3>
								{{ $t("RFSetup.sets") }}
								<span
									data-toggle="modal"
									data-target="#setModal"
									@click="newSet(5)"
									class="float-right pointer"
									v-tippy
									:title="$t('tooltip.rangefinding_set')"
								>
									<i class="fal fa-plus"></i>
								</span>
							</h3>
						</div>
						<ul class="list inset" v-if="!rfSets.length">
							<li class="list-item">
								<div class="list-body">
									<div>{{ $t("data_description.no_sets") }}</div>
								</div>
							</li>
						</ul>
						<ul class="list inset">
							<draggable v-model="rfSets" :options="{ handle: '.drag-handle' }" @sort="rfSorted">
								<li class="list-item hover" v-for="set in rfSets" :key="set.id">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
									<a>
										<span class="w-40 circle theme avatar">
											<span style="margin-bottom: 2px">{{ set.responses.length }}</span>
										</span>
									</a>
									<div class="list-body align-middle">
										<div class="align-middlea">
											<a>{{ set.name }}</a>
											<br />
											<a>
												<span v-if="set.active" class="text-success text-xxs">{{
													$t("data_description.active")
												}}</span>
												<span v-if="!set.active" class="text-muted text-xxs">{{
													$t("data_description.inactive")
												}}</span>
											</a>
										</div>
									</div>
									<div class="dropdown">
										<button class="btn" data-toggle="dropdown" aria-expanded="false">
											<i class="fas fa-ellipsis-v faw"></i>
										</button>
										<div
											class="dropdown-menu dropdown-menu-right w"
											x-placement="bottom-end"
											style="
												position: absolute;
												transform: translate3d(-70px, 35px, 0px);
												top: 0px;
												left: 0px;
												will-change: transform;
											"
										>
											<a
												class="dropdown-item"
												data-toggle="modal"
												data-target="#setModal"
												@click="modSet(set)"
											>
												<i class="far fa-edit faw"></i>
												<label class="mb-0">{{ $t("RFSetup.edit_set") }}</label>
											</a>
											<a class="dropdown-item" :href="`#/rf_setup/set/${set.id}`">
												<i class="far fa-list faw"></i>
												<label class="mb-0">{{ $t("RFSetup.manage_responses") }}</label>
											</a>
											<a
												class="dropdown-item"
												:href="`#/qc_set_document/${selectedSection.id}/${selectedItem.id}/${set.id}`"
											>
												<i class="far fa-clipboard-list faw"></i>
												<label class="mb-0">{{ $t("QCSetup.view_as_document") }}</label>
											</a>
											<a class="dropdown-item" @click="copySetModal(set)">
												<i class="far fa-copy faw"></i>
												<label class="mb-0">{{ $t("RFSetup.copy_set") }}</label>
												<span class="text-xxs text-muted float-right"></span>
											</a>
											<a
												class="dropdown-item"
												@click="maybeDeleteSet(set)"
												data-toggle="modal"
												data-target="#setDeleteModal"
											>
												<i class="far fa-trash faw"></i>
												<label class="mb-0">{{ $t("RFSetup.delete") }}</label>
											</a>
											<a
												class="dropdown-item"
												data-toggle="modal"
												data-target="#resetSetModal"
												@click="setResetSet(set)"
											>
												<i class="far fa-edit faw"></i>
												<label class="mb-0">{{ $t("RFSetup.reset_set") }}</label>
											</a>
										</div>
									</div>
								</li>
							</draggable>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="setModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content" v-if="editSet">
					<div class="modal-header">
						<h5>{{ $t("RFSetup.configure_set_modal.title", { qc_type: fs.qcType(editSet.qc_type) }) }}</h5>
					</div>
					<div class="modal-body">
						<form role="form" class>
							<div class="form-group">
								<label>{{ $t("RFSetup.configure_set_modal.name") }}</label>
								<input type="text" class="form-control" v-model="editSet.name" />
							</div>

							<div class="d-flex flex-row align-items-center mb-2">
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="editSet.randomize" />
									<i></i>
								</label>
								<span class="ml-2">{{ $t("RFSetup.configure_set_modal.randomize") }}</span>
							</div>
							<div class="d-flex flex-row align-items-center mb-2">
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="editSet.hide_resolved" />
									<i></i>
								</label>
								<span class="ml-2">{{ $t("RFSetup.configure_set_modal.hide_resolved") }}</span>
							</div>
							<div class="d-flex flex-row align-items-center mb-2">
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="editSet.active" />
									<i></i>
								</label>
								<span class="ml-2">{{ $t("RFSetup.configure_set_modal.active") }}</span>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button @click="saveSet(editSet)" class="btn btn-flat theme">{{ $t("buttons.save") }}</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="setDeleteModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content" v-if="setToDelete">
					<div class="modal-header">
						<h5>{{ $t("modal.confirm_delete_title") }}</h5>
					</div>
					<div class="modal-body">
						{{ $t("modal.confirm_delete") }} {{ $t("RFSetup.set") }}
						<span class="text-danger">
							<strong>{{ setToDelete.name }}</strong>
						</span>
						{{ $t("special_chars.question_mark") }}
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button @click="deleteSet()" class="btn btn-flat red">{{ $t("buttons.delete") }}</button>
					</div>
				</div>
			</div>
		</div>

		<div id="copySetModal" class="modal" data-backdrop="static" data-keyboard="false" style>
			<div class="modal-dialog modal-lg">
				<div class="modal-content" v-if="copySet">
					<div class="modal-header">
						<h5>{{ $t("RFSetup.copy_set_modal.title1") }}</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col col-sm-12">
								<h5>{{ $t("RFSetup.copy_set_modal.title2", { name: copySet.name }) }}</h5>
								<p>{{ $t("RFSetup.copy_set_modal.description") }}</p>
							</div>
							<div class="col col-sm-12">
								<p></p>
								<div class="form-group col-sm-12">
									<label class="active">{{ $t("RFSetup.copy_set_modal.duplicate_set_name") }}</label>
									<input type="text" class="form-control" v-model="copySetDest.name" />
								</div>
							</div>
							<div class="col-sm-12">
								<div class="form-group col-sm-6">
									<label>{{ $t("RFSetup.copy_set_modal.new_set_type") }}</label>
									<v-select
										class="v-select-apply"
										label="name"
										:options="qcTypes"
										v-model="copyType"
									></v-select>
								</div>
							</div>
							<div class="col-sm-12">
								<div class="col-sm-6">
									<label>{{ $t("RFSetup.copy_set_modal.section") }}</label>
									<v-select
										class="v-select-apply"
										label="name"
										v-model="copySetDest.section"
										:options="selectedItemSections"
									></v-select>
								</div>
							</div>
							<div class="col-sm-12">
								<div class="col-sm-6">
									<label>{{ $t("RFSetup.copy_set_modal.item") }}</label>
									<v-select
										disabled
										class="v-select-apply"
										:options="[selectedItem.name]"
										v-model="selectedItem.name"
									></v-select>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button data-dismiss="modal" class="btn modal-action modal-closebtn-flat">
							{{ $t("buttons.cancel") }}
						</button>
						<button data-dismiss="modal" @click="saveCopySet()" class="btn btn-flat btn-success">
							{{ $t("buttons.copy") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="resetSetModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content" v-if="setToBeReset">
					<div class="modal-header">
						<h5>{{ $t("RFSetup.reset_rangefinding_set") }}</h5>
					</div>
					<div v-if="renderResetSetSpinner" v-spinner-overlay />
					<div class="modal-body">
						<div class="py-3 px-2 mx-3 border modal-details-box">
							<div v-if="selectedSection" class="col-12">
								<span class="_600 mr-1">{{ $t("fields.section") }}:</span>
								<span>{{ selectedSection.name }}</span>
							</div>
							<div v-if="selectedItem" class="col-12">
								<span class="_600 mr-1">{{ $t("fields.item") }}:</span>
								<span>{{ selectedItem.name }}</span>
							</div>
							<div v-if="setToBeReset" class="col-12">
								<span class="_600 mr-1">{{ $t("fields.set_name") }}:</span>
								<span>{{ setToBeReset.name }}</span>
							</div>
						</div>
					</div>
					<div class="col-12 mt-4">{{ $t("RFSetup.reset_set_confirm") }}</div>
					<div class="modal-footer">
						<button class="btn primary btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button @click="resetSet(setToBeReset)" class="btn btn-flat red">
							{{ $t("RFSetup.reset_set") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<script>
import AuthService from "@/services/AuthService";
import QCConfigService from "@/services/QCConfigService";
import ConfigService from "@/services/ConfigService";
import ConfigSelect from "@/components/ConfigSelect";
import Notie from "@/services/NotieService";
import draggable from "vuedraggable";
import $ from "jquery";
import PercentInput from "@/components/PercentInput";
import store from "../../services/Store";
import QCService from "@/services/QCService";
import fs from "@/services/FormatService";

export default {
	name: "RFSetup",
	data() {
		var QC_TYPE_PRACTICE = { name: this.$i18n.t("qc_types.practice"), id: 1 };
		var QC_TYPE_QUALIFICATION = { name: this.$i18n.t("qc_types.qualification"), id: 2 };
		var QC_TYPE_VALIDITY = { name: this.$i18n.t("qc_types.validity"), id: 3 };
		var QC_TYPE_CALIBRATION = { name: this.$i18n.t("qc_types.calibration"), id: 4 };
		var QC_TYPE_RANGEFINDING = { name: this.$i18n.t("qc_types.rangefinding"), id: 5 };

		return {
			fs: fs,
			user: null,
			sections: [],
			items: [],
			qcTypes: [
				QC_TYPE_PRACTICE,
				QC_TYPE_QUALIFICATION,
				QC_TYPE_VALIDITY,
				QC_TYPE_CALIBRATION,
				QC_TYPE_RANGEFINDING,
			],

			selectedSection: null,
			selectedItem: null,

			editSet: null,
			setToDelete: null,

			rfSets: [],

			copySet: null,
			copyType: QC_TYPE_RANGEFINDING,
			copySetDest: null,
			selectedItemSections: [],

			setToBeReset: null,
			renderResetSetSpinner: false,
		};
	},
	components: { ConfigSelect, draggable, PercentInput },
	created() {
		AuthService.getUser().then((user) => {
			this.user = user;
			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			ConfigService.listSectionsShallow(ctx).then((resp) => {
				this.sections = resp.data.sections;
				this.selectedSection = store.resolveSelectedSection(this, this.sections, "rf.selectedSection");

				if (this.selectedSection) {
					this.items = this.selectedSection.items;
					this.selectedItem = store.resolveSelectedItem(this, this.selectedSection, "rf.selectedItem");
				}
			});
		});
	},
	watch: {
		selectedSection() {
			store.set(this, "rf.selectedSection", this.selectedSection ? this.selectedSection.id : null);
			this.items = this.selectedSection.items;
			this.selectedItem = store.resolveSelectedItem(this, this.selectedSection, "rf.selectedItem");
		},
		selectedItem() {
			store.set(this, "rf.selectedItem", this.selectedItem ? this.selectedItem.id : null);
			this.querySets();
		},
	},
	methods: {
		copySetModal(set) {
			this.copySet = set;
			this.copySetDest = {};
			this.copySetDest.name = set.name + " copy";
			this.copySetDest.QCType = set.qc_type;
			this.copySetDest.section = this.selectedSection;

			this.selectedItemSections = [];
			_.each(this.sections, (section) => {
				if (_.find(section.items, { id: this.selectedItem.id })) {
					this.selectedItemSections.push(section);
				}
			});

			$("#copySetModal").modal("show");
		},
		saveCopySet() {
			var dest_set = {};
			dest_set.name = this.copySetDest.name;
			dest_set.qc_type = this.copyType.id;
			dest_set.section_id = this.copySetDest.section.id;

			dest_set.item_id = this.selectedItem.id;

			dest_set.is_blind = this.copySet.is_blind;
			dest_set.auto_deliver = this.copySet.auto_deliver;
			dest_set.active = this.copySet.active;
			dest_set.sequence = this.copySet.sequence; //Good enough for now

			var request = {
				section_id: this.selectedSection.id,
				item_id: this.selectedItem.id,
				qc_set_id: this.copySet.id,
				qc_type: dest_set.qc_type,
				dest_set: dest_set,
			};
			QCService.copySet(request)
				.then(() => {
					this.querySets();
				})
				.catch((err) => {
					console.log(err);
				});
		},
		newSet(type) {
			this.editSet = {};
			this.editSet.qc_type = type;
			this.editSet.client_id = this.selectedSection.client_id;
			this.editSet.item_id = this.selectedItem.id;
			this.editSet.section_id = this.selectedSection.id;
			var typeName = fs.qcType(type);
			this.editSet.name = this.$i18n.t("RFSetup.configure_set_modal.blank_name", { qc_type: typeName });
			var relevantSets = this.getRelevantSets(type);
			this.editSet.sequence = relevantSets.length + 1;
		},
		modSet(set) {
			this.editSet = set;
		},

		setResetSet(set) {
			this.setToBeReset = set;
		},

		resetSet(set) {
			this.renderResetSetSpinner = true;
			QCService.resetRangeFindingSet(this.selectedSection.id, this.selectedItem.id, set.id)
				.then((resp) => {
					$("#resetSetModal").modal("hide");
					this.renderResetSetSpinner = false;
				})
				.catch((err) => {
					console.log(err);
					Notie.error(err);
				});
		},

		saveSet(set, noop) {
			QCConfigService.saveSet(set)
				.then(() => {
					Notie.success("Set Saved");
					if (!noop) {
						this.querySets();
					}
					$("#setModal").modal("hide");
				})
				.catch((err) => {
					console.log(err);
					Notie.error(err);
				});
		},

		maybeDeleteSet(set) {
			this.setToDelete = set;
		},

		deleteSet() {
			QCConfigService.deleteSet(this.setToDelete)
				.then(() => {
					Notie.success("Set Deleted");
					this.querySets();
					$("#setDeleteModal").modal("hide");
				})
				.catch((err) => {
					console.log(err);
					Notie.error(err);
				});
		},

		querySets() {
			this.practiceSets = [];
			this.qualSets = [];
			this.validitySets = [];
			this.calibrationSets = [];
			if (this.selectedItem && this.selectedSection) {
				QCConfigService.listQCSets(this.selectedSection.id, this.selectedItem.id, 5).then((resp) => {
					console.log(resp.data);
					this.rfSets = resp.data;
				});
			}
		},
		getRelevantSets(type) {
			switch (type) {
				case 1:
					return this.practiceSets;
					break;
				case 2:
					return this.qualSets;
					break;
				case 3:
					return this.validitySets;
					break;
				case 4:
					return this.calibrationSets;
					break;
				default:
					return [];
					break;
			}
		},
		rfSorted(a, b, c) {
			var counter = 1;
			_.each(this.calibrationSets, (set) => {
				// if (set) {
				if (set.sequence != counter) {
					set.sequence = counter;
					this.saveSet(set, true);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},
	},
};
</script>
