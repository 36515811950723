<!-- Template for a new page -->
<template>
	<div class="d-flex flex-column flex h-100 scroll-y page-body">
		<div v-if="loading" class="text-center">
			<loading type="large" class="mt-5" />
		</div>
		<div v-if="!loading && !(resources && resources.length > 0)">{{ $("ExternalResource.no_resources") }}</div>
		<template v-if="!loading && resources && resources.length > 0">
			<div class="header theme">
				<div class="text-muted mr-2 ml-3">{{ $t("ExternalResource.item") }}:</div>
				<div>{{ item.name }}</div>
				<div class="horizontal-divider ml-3"></div>
				<a class="header-button" data-toggle="dropdown" aria-expanded="false">
					<div class="text-muted mr-2">{{ $t("ExternalResource.resource") }}:</div>
					<div>{{ pickedResource.name }}</div>
					<i class="ml-2 fas fa-lg fa-caret-down" />
				</a>
				<div v-if="resources && resources.length > 0" class="dropdown-menu">
					<div v-if="!resources || resources.length == 0">
						<h6 class="text-muted text-center">
							{{ $t("data_description.no_resources") }}
						</h6>
					</div>
					<template v-for="res in resources">
						<a
							v-if="Utils.isRenderableResource(res)"
							:key="res.id"
							class="dropdown-item theme-hover d-flex flex-row align-items-center"
							@click="pickedResource = res"
							:class="{ active: pickedResource && res.id == pickedResource.id }"
						>
							<i
								class="fa-lg"
								:class="{
									[pickedResource && res.id == pickedResource.id ? 'fas' : 'far']: true,
									[fs.mimeTypeIcon(res.mime_type)]: true,
								}"
							></i>
							<span class="flex">{{ res.name }}</span>
						</a>
						<a
							v-if="
								!Utils.isRenderableResource(res) &&
								!user.role.prevent_resource_download &&
								res.type != 3 &&
								res.type != 4
							"
							:key="res.id"
							class="dropdown-item theme-hover d-flex flex-row align-items-center"
							@click="downloadResource(res)"
							:class="{ active: pickedResource && res.id == pickedResource.id }"
						>
							<i
								class="fa-lg"
								:class="{
									[pickedResource && res.id == pickedResource.id ? 'fas' : 'far']: true,
									[fs.mimeTypeIcon(res.mime_type)]: true,
								}"
							></i>
							<div class="flex d-flex flex-column">
								<div>
									<span class="flex">{{ res.name }}</span>
								</div>
								<div class="text-xxs text-muted mb-1" style="line-height: 0.8">
									{{ $t("ExternalResource.download_only") }}
								</div>
							</div>
							<i
								class="far ml-3 fa-lg fa-download text-extra-muted float-right"
								style="margin-right: -16px"
							></i>
						</a>
						<div
							v-if="!Utils.isRenderableResource(res) && user.role.prevent_resource_download"
							:key="res.id"
							class="dropdown-item"
						>
							<div>
								<i
									class="fa-lg"
									:class="{
										[pickedResource && res.id == pickedResource.id ? 'fas' : 'far']: true,
										[fs.mimeTypeIcon(res.mime_type)]: true,
									}"
								></i>
								<span class="flex">{{ res.name }}</span>
							</div>
							<div class="text-xxs text-muted">
								{{ $t("ExternalResource.cannot_render_or_download") }}
							</div>
						</div>
					</template>
				</div>

				<div class="horizontal-divider"></div>
				<template v-if="!user.role.prevent_resource_download && !(pickedResource && pickedResource.type == 3)">
					<div class="horizontal-divider ml-auto"></div>
					<a v-if="pickedResource.type != 4" class="header-button" @click="downloadResource(pickedResource)">
						{{ $t("ExternalResource.download") }}<i class="ml-2 fas fa-download" />
					</a>
					<a
						v-if="pickedResource.type == 4"
						class="header-button"
						:href="pickedResource.path"
						target="_blank"
					>
						{{ $t("ExternalResource.download") }}<i class="ml-2 fas fa-download" />
					</a>
				</template>
			</div>
			<div class="h-100 w-100 text-center scroll-y" id="resources-container">
				<div class="resources-container my-3 mx-1" v-if="pickedResource">
					<resource :resource="pickedResource" :item="item" :user="user" :directRenderIFrame="true" />
				</div>
				<div v-else>
					<h2 class="text-muted">{{ $t("ExternalResource.invalid_resource") }}</h2>
				</div>
			</div>
		</template>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
	width: 100%;
	min-height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.header-button {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding-left: 1rem;
	padding-right: 1rem;
	transition: background-color 0.15s ease-in-out;
}

.header-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.horizontal-divider {
	height: 100%;
	width: 1px;
	background-color: white;
}

.resources {
	position: absolute;
	height: 100%;
	bottom: 0;
	top: 0;
	width: calc(50vw);
	background-color: #eeeeee;
	border-left: 1px solid #d9d9d9;
	box-shadow: inset 6px 0px 10px -4px rgba(184, 182, 184, 1);
}

.resources > .resources-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.dropdown-item.active {
	color: inherit;
	background-color: rgba(0, 0, 0, 0.15);
}

.resources-container {
	z-index: 2;
}
</style>

<script>
//Services
import ScoringService from "@/services/ScoringService";
import ResourceService from "@/services/ResourceService";
import Notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";

import Resource from "@/components/viewer/Resource";

export default {
	name: "ExternalResources",

	props: ["user", "params"],

	components: { Resource },

	data() {
		return {
			fs: fs,
			Utils: Utils,

			loading: true,
			item: null,
			resources: null,

			pickedResource: null,
		};
	},

	watch: {
		pickedResource() {
			if (this.item && this.pickedResource) {
				this.$router.push(`/resource/${this.item.id}/${this.pickedResource.id}`);
			}
		},
	},

	computed: {},

	created() {
		ScoringService.getItem(this.params.item_id)
			.then((r) => {
				console.log("GOT ITEM", r.data);
				this.item = r.data;
				this.resources = this.item.resources;
				if (this.user.role.prevent_resource_download)
					_.each(this.resources, (r) => {
						if (!Utils.isRenderableResource(r)) {
							r.active = false;
							r.disabled = true;
							r.desc = this.$i18n.t("ExternalResource.cannot_render_or_download");
						}
					});
				this.loading = false;

				let pickedResource = _.find(this.resources, { id: this.params.resource_id });
				if (Utils.isRenderableResource(pickedResource)) {
					this.pickedResource = pickedResource;
				} else {
					ResourceService.viewResource(this.item, pickedResource);
				}
			})
			.catch((e) => {
				Notie.error("Failed to load resources", e);
				this.loading = false;
			});
	},

	methods: {
		pickResource(res) {
			this.pickedResource = res;
		},

		downloadResource(res) {
			ResourceService.downloadResource(this.item, res);
		},
	},
};
</script>
