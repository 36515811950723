import axios from "axios";

export default {
	icon: "account_balance",
	name: "Appeal",
	chooseKeepScore: true,
	showScores(item) {
		return true; 
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: false,

	getNext(ctx) {
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		var promise = axios.get(`/v1/appeal/${sid}/${iid}`);
		promise.then(function(resp) {
			console.log("AppealSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},

	submitScore(score, response, keep, forcedRepeatScore) {
		score.response_id = response.id;

		var promise = axios.post("/v1/legacy/submit", score, {
			params: { keep: keep, r: forcedRepeatScore }
		});
		promise.then(function(resp) {
			console.log("AppealSD Submitted");
		});
		return promise;
	}
};
