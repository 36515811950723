<template>
	<button :disabled="loading || !clickable" @click="handleClick">
		<template v-if="loading">
			<loading type="icon" class="mr-1" />
			{{ loadingName }}
		</template>
		<template v-else>
			{{ name }}
		</template>
	</button>
</template>

<script>
const AsyncFunction = (async () => {}).constructor;

export default {
	name: "AwaitButton",
	props: {
		name: true,
		loadingName: true,
		clickable: {
			default: true,
		},
		click: {
			type: AsyncFunction,
			default: () => {
				this.logError("No click handler defined");
			},
		},
	},

	data() {
		return {
			loading: false,
		};
	},

	methods: {
		async handleClick() {
			if (!this.clickable) {
				this.logError("Clicked when 'clickable' prop was false");
				return;
			}

			this.loading = true;
			try {
				await this.click();
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
