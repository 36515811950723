<template>
	<div class="position-relative" style="height: 35px;">
		<input type="number" :value="value" ref="input" @input="limitInput" class="form-control clip-input">
		<label class="text-muted">%</label>
	</div>
</template>

<style scoped>
label {
	position: absolute;
	right: 9px;
	top: 6px;
	z-index: 2;
}
input {
	position: absolute;
	left: 0px;
	top: 0px;
	padding-right: 26px;
	z-index: 1;
}
</style>

<script>
export default {
	name: "PercentInput",
	props: ["value", "negative"],

	data() {
		return {};
	},
	created() {},
	watch: {},
	methods: {
		limitInput() {
			if (this.$refs.input.value > 100) {
				this.$refs.input.value = 100;
			} else if (this.$refs.input.value < 0 && !this.negative) {
				this.$refs.input.value = 0;
			} else if (this.$refs.input.value < -100) {
				this.$refs.input.value = -100;
			}
			var number = Number(this.$refs.input.value);
			this.$emit("input", number);
		}
	}
};
</script>
