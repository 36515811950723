<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ProjectEdit.Scheduling.title") }}</div>
		<div role="form" class="p-4 col-sm-6">
			<div class="row">
				<div class="col-6 form-group">
					<label>{{ $t("ProjectEdit.Scheduling.response_turnaround_time") }}</label>
					<input type="number" class="form-control" v-model.number="project.turnaround" />
				</div>
				<div class="col-6 form-group">
					<label v-tippy="{ placement: 'right-bottom', theme: 'popover' }" :title="lockoutTooltip">{{
						$t("ProjectEdit.Scheduling.schedule_entry_lockout_period")
					}}</label>
					<input type="number" class="form-control" v-model.number="project.schedule_lockout" />
				</div>
				<hr class="col-12" />
				<div class="col-12 form-group">
					<div class="checkbox mr-3">
						<label class="md-check">
							<input v-model="project.send_schedule_reminders" type="checkbox" />
							<i class="theme-accent"></i>
							{{ $t("ProjectEdit.Scheduling.send_schedule_reminders") }}
						</label>
					</div>
				</div>
				<template v-if="project.send_schedule_reminders">
					<div class="col-12 col-sm-6">
						<label
							v-tippy="{ theme: 'popover', placement: 'right-bottom' }"
							:title="$t('ProjectEdit.Scheduling.reminder_time_tooltip')"
							>{{ $t("ProjectEdit.Scheduling.reminder_time") }}</label
						>
						<input type="number" class="form-control" v-model.number="project.reminder_time" />
					</div>
				</template>
				<hr class="col-12" />
				<div class="col-12 form-group">
					<div class="checkbox mr-3">
						<label class="md-check">
							<input v-model="project.send_cancel_notifications" type="checkbox" />
							<i class="theme-accent"></i>
							{{ $t("ProjectEdit.Scheduling.send_cancel_notifications") }}
						</label>
					</div>
				</div>
				<template v-if="project.send_cancel_notifications">
					<div class="col-12 col-sm-6">
						<label
							v-tippy="{ theme: 'popover', placement: 'right-bottom' }"
							:title="$t('ProjectEdit.Scheduling.cancel_time_tooltip')"
							>{{ $t("ProjectEdit.Scheduling.cancel_time") }}</label
						>
						<input type="number" class="form-control" v-model.number="project.cancel_time" />
					</div>
					<div class="col-12 col-sm-6">
						<label
							v-tippy="{ theme: 'popover', placement: 'right-bottom' }"
							:title="$t('ProjectEdit.Scheduling.cancel_roles_tooltip')"
							>{{ $t("ProjectEdit.Scheduling.cancel_roles") }}</label
						>
						<v-select
							:options="client.roles"
							:reduce="o => o.id"
							v-model="project.cancel_role_ids"
							label="name"
							multiple
						></v-select>
					</div>
				</template>
				<hr class="col-12" />
				<div class="col-12 form-group">
					<div class="checkbox mr-3">
						<label class="md-check">
							<input v-model="project.send_signup_requests" type="checkbox" />
							<i class="theme-accent"></i>
							{{ $t("ProjectEdit.Scheduling.send_signup_requests") }}
						</label>
					</div>
				</div>
				<template v-if="project.send_signup_requests">
					<div class="col-12 col-sm-6">
						<label
							v-tippy="{ theme: 'popover', placement: 'right-bottom' }"
							:title="$t('ProjectEdit.Scheduling.request_time_tooltip')"
							>{{ $t("ProjectEdit.Scheduling.request_time") }}</label
						>
						<input type="number" class="form-control" v-model.number="project.request_time" />
					</div>
					<div class="col-12 col-sm-6">
						<label
							v-tippy="{ theme: 'popover', placement: 'right-bottom' }"
							:title="$t('ProjectEdit.Scheduling.request_roles_tooltip')"
							>{{ $t("ProjectEdit.Scheduling.request_roles") }}</label
						>
						<v-select
							:options="client.roles"
							:reduce="o => o.id"
							v-model="project.request_role_ids"
							label="name"
							multiple
						></v-select>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import Notie from "@/services/NotieService";

export default {
	name: "ProjectEditDetails",

	props: ["id", "project", "valid", "defaultTab", "client"],

	data() {
		return {
			lockoutTooltip: `<p>${this.$i18n.t(
				"ProjectEdit.Scheduling.schedule_entry_lockout_period_tooltip_main"
			)}</p><p class="mb-0"><small class="text-muted">${this.$i18n.t(
				"ProjectEdit.Scheduling.schedule_entry_lockout_period_tooltip_sub"
			)}</small></p>`
		};
	},

	created() {},

	methods: {}
};
</script>
