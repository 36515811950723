<template>
	<span>
		<a @click="back" v-tippy :title="forwardText" class="pr-2 no-select">
			<i class="fa fa-caret-left" :class="showBack || 'invisible'"></i>
		</a>
		<a @click="forward" v-if="showForward" v-tippy :title="backText" class="pr-2 no-select">
			<i class="fa fa-caret-right"></i>
		</a>
	</span>
</template>

<script>
export default {
	name: "ArrowSelect",

	props: {
		forwardText: String,
		backText: String,
		showForward: Boolean,
		showBack: Boolean,
	},

	methods: {
		back() {
			if (this.showBack) this.$emit("back");
		},

		forward() {
			if (this.showForward) this.$emit("forward");
		},
	},
};
</script>
