<template>
    <div>
        <a @click="modelOpen = true" class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0" v-tippy
            :title="$t('tooltip.escalate_response')">
            <i class="far fa-bookmark"></i>
        </a>
        <escalate-score-modal v-if="modelOpen" @close="modelOpen = false" :item="item" :response="response"
            :user="user"></escalate-score-modal>
    </div>
</template>

<style scoped>

</style>

<script>
import EscalateScoreModal from './EscalateScoreModal.vue';

export default {
    name: "EscalateScoreButton",

    components: {
        EscalateScoreModal
    },

    props: {
        item: Object,
        response: Object,
        user: Object,
    },

    data() {
        return {
            modelOpen: false
        }
    },
}
</script>
