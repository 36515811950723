<template>
	<div>
		<div v-if="showSection || showItem" class="d-flex flex-row theme header">
			<div class="flex text-center px-3 py-1">
				<i class="fas fa-briefcase mr-2" />
				{{ sectionName }}
			</div>
			<div class="flex text-center px-3 py-1">
				<i class="fas fa-book mr-2" />
				{{ itemName }}
			</div>
		</div>
		<div class="d-flex flex-row align-items-center justify-content-between w-100">
			<h4 class="mx-4 my-3">{{ set.name }}</h4>
			<button class="btn btn-theme mr-2" @click="goToSet(set.id)">{{ $t("QCPortfolio.view_responses") }}</button>
		</div>
		<calibration-set-rules v-if="isCalibrationSet" :set="set" :stat="stat" />
		<table class="table mb-0">
			<portfolio-set-header
				:hideState="hideState || isCalibrationSet"
				:stat="stat"
				:numericTraits="numericTraits"
			/>
			<tbody class="text-center">
				<tr v-for="resp in set.responses" :key="resp.id">
					<td colspan="2" class="v-mid _600">{{ resp.ref_id }}</td>
					<template v-if="resp.comps">
						<template v-for="(comp, i) in resp.comps">
							<td :key="`score-${i}`" class="b-l scored-cell v-mid">
								<span
									class="score-text nowrap"
									:class="{
										nonadj: comp.nonadj_high || comp.nonadj_low,
										adj: comp.adj_high || comp.adj_low,
									}"
								>
									{{ comp.scored.score }}
									<i v-if="comp.nonadj_low" class="far fa-chevron-double-down text-muted"></i>
									<i v-if="comp.adj_low" class="far fa-chevron-down text-muted"></i>
									<i v-if="comp.adj_high" class="far fa-chevron-up text-muted"></i>
									<i v-if="comp.nonadj_high" class="far fa-chevron-double-up text-muted"></i>
								</span>
							</td>
							<td :key="`true-${i}`" class="actual-cell v-mid">{{ comp.actual.score }}</td>
						</template>
					</template>
					<template v-else>
						<template v-for="(_, i) in numericTraits">
							<td :key="`score-${i}`" class="b-l scored-cell v-mid">
								<span class="score-text nowrap"> ___ </span>
							</td>
							<td :key="`true-${i}`" class="actual-cell v-mid">___</td>
						</template>
					</template>
				</tr>
			</tbody>
		</table>
		<div class="w-100 b-t text-right">
			<div class="item-date text-xs text-muted mr-3">{{ fs.medDate(stat.updated_at) }}</div>
		</div>
	</div>
</template>

<script>
import fs from "@/services/FormatService";
import { QC_TYPES } from "@/services/Constants";
import PortfolioSetHeader from "./PortfolioSetHeader.vue";
import CalibrationSetRules from "./CalibrationSetRules";
import BB from "bluebird";
import CompensationQueueTimeService from "@/services/CompensationQueueTimeService";

export default {
	name: "PortfolioSet",
	props: ["set", "stat", "rubric", "sectionName", "itemName", "showSection", "showItem", "hideState", "queryUser", "fromMyTrainingPage"],
	components: { PortfolioSetHeader, CalibrationSetRules, CalibrationSetRules },
	data() {
		return {
			fs: fs,
			badgeWidth: null,
		};
	},
	created() {},

	watch: {},
	methods: {
		checkAgreement(score, actual) {
			_.each(score.trait_scores, (ts) => {
				ts.disagreed = false;
				ts.nonadj = false;
				ts.adjacent = false;
				let comp = _.find(actual.trait_scores, {
					trait_id: ts.trait_id,
				});
				if (comp && ts.score != comp.score) {
					ts.disagreed = true;
					if (Math.abs(comp.score - ts.score) > 1) {
						ts.nonadj = true;
					}
					if (Math.abs(comp.score - ts.score) == 1) {
						ts.adjacent = true;
					}
				}
			});
		},

		goToSet(setID) {
			if (this.queryUser) {
				if (this.fromMyTrainingPage){
					this.$router.push(`/my_training/qc_set_report/${setID}?userID=${this.queryUser.id}`);
				}else{
					this.$router.push(`/qc_set_report/${setID}?userID=${this.queryUser.id}`);		
				}
			} else {
				if (this.fromMyTrainingPage){
					this.$router.push(`/my_training/qc_set_report/${setID}`);
				}else{
					this.$router.push(`/qc_set_report/${setID}`);
				}
			}
		},
	},

	computed: {
		numericTraits() {
			return this.rubric.traits.filter(({ separator, is_parent }) => !(separator || is_parent));
		},

		isCalibrationSet() {
			return this.set.qc_type === QC_TYPES.CALIBRATION.id;
		},
	},
};
</script>

<style scoped>
.badge {
	width: 1.25rem;
	height: 1.5rem;
	line-height: 1.4rem;
	padding: 0 0;
	position: relative;
	color: rgba(70, 90, 110, 1);
}

.wide-badge {
	font-size: 1rem;
	width: initial !important;
}

.empty-badge {
	box-shadow: inset 0px 0px 0px 1.2px rgba(0, 0, 0, 0.2);
	background-color: white;
}

.disagreed::before {
	content: "";
	position: absolute;
	top: 0px;
	bottom: 2px;
	left: 0px;
	right: 0px;
	background-color: rgba(255, 0, 0, 0.2);
	border-radius: 0.4rem;
}

.disagreed.nonadj::before {
	border-bottom: 3px solid rgba(255, 0, 0, 0.5);
	border-top: 3px solid rgba(255, 0, 0, 0.5);
}

.adjacent {
}

.adjacent::before {
	background-color: rgba(253, 168, 41, 0.25);
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > th {
	border-color: rgba(120, 130, 140, 0.2);
	border-width: 1px;
}

.scored-cell {
	font-size: 1.25rem !important;
}

.scored-cell i {
	font-size: 1rem;
}

.actual-cell {
	opacity: 0.5;
}

.score-text {
	padding-left: 6px;
	padding-right: 6px;
	padding-bottom: 2px;
}

.nonadj {
	background-color: rgb(255, 0, 0, 0.2);
	border-radius: 0.5rem;
}

.adj {
	background-color: rgba(253, 168, 41, 0.25);
	border-radius: 0.5rem;
}

.header {
	font-weight: 600;
	font-size: 1rem;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
</style>
