<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ItemEdit.Resources.title") }}</div>
		<div id="drop-form" style="position: absolute; height: 100%; width: 100%; opacity:0 z-index: 1000"
			:class="{ dropping: droppingFiles }" @drop.prevent="doDropFiles" @dragenter="addDropFiles"
			@dragleave="removeDropFiles" @dragover.prevent></div>
		<div class="row m-0" style="position: relative; z-index: 0">
			<div class="p-4 col-md-10">
				<label class="_600">{{ $t("ItemEdit.Resources.resources") }}</label>
				<div class="list-group box">
					<draggable v-model="item.resources" :options="{ handle: '.drag-handle', animation: 150 }">
						<div v-for="resource in item.resources" :key="resource.id"
							class="list-group-item py-2 pl-0 pr-0" :class="{ missing: resource.missing }">
							<div style="display: flex" class>
								<div class="m-auto px-2 drag-handle" style="flex-grow: 0">
									<i class="far fa-sort text-muted"></i>
								</div>
								<div style="flex-grow: 1">
									<input type="text" class="form-control" v-model="resource.name" />
								</div>
								<div v-if="Utils.isRenderableResource(resource)" class="m-auto pl-2"
									style="flex-grow: 0">
									<button :disabled="!isOriginal(resource)"
										class="btn btn-sm btn-icon btn-rounded hover-darken text-white m-0"
										:class="{ [isOriginal(resource) ? 'theme-accent' : 'btn-secondary']: true }"
										v-tippy title="View" @click="viewResource(resource)">
										<i style="font-size: 1.05rem" class="far fa-file"></i>
									</button>
								</div>
								<div v-if="!Utils.isRenderableResource(resource)" class="m-auto pl-2"
									style="flex-grow: 0">
									<button :disabled="!isOriginal(resource)"
										class="btn btn-sm btn-icon btn-rounded hover-darken text-white m-0"
										:class="{ [isOriginal(resource) ? 'theme-accent' : 'btn-secondary']: true }"
										v-tippy title="Download" @click="downloadResource(resource)">
										<i style="font-size: 1.05rem" class="far fa-download"></i>
									</button>
								</div>
								<div class="m-auto px-2" style="flex-grow: 0" @click="deleteResource(resource)">
									<button class="btn btn-sm btn-icon btn-rounded hover-darken danger text-white m-0"
										v-tippy title="Delete">
										<i v-if="!resource.deleting" class="far fa-trash"></i>
									</button>
								</div>
							</div>
						</div>
					</draggable>
				</div>
			</div>
			<div class="p-4 col-md-10">
				<label class="_600">{{ $t("ItemEdit.Resources.upload.title") }}</label>
				<div class="list-group box">
					<draggable v-model="newResources" :options="{ handle: '.drag-handle' }">
						<div v-for="(resource, index) in newResources" :key="index"
							class="list-group-item pt-2 pb-1 pl-0 pr-0">
							<div style="display: flex">
								<div class="m-auto px-2 drag-handle flex-fixed">
									<i class="far fa-sort text-muted"></i>
								</div>
								<div class="pr-2 flex">
									<input type="text" class="form-control" v-model="resource.name"
										:style="progressBarStyle(resource)" />
									<div class="d-flex flex-row align-items-center">
										<small class="text-muted">{{ $t("ItemEdit.Resources.upload.file") }} {{
											resource.file.name
										}}</small>
										<div v-if="resource.zip || resource.iframeType"
											class="ml-auto d-flex flex-column">
											<div v-if="resource.zip" class="ml-auto checkbox">
												<label class="ui-check mb-0">
													<input type="checkbox" v-model="resource.cpack" />
													<i style="margin-top: 2px"></i>
													<small class="text-muted ml-auto">{{
														$t("ItemEdit.Resources.upload.html_content_package")
													}}</small>
												</label>
											</div>
											<div v-if="resource.iframeType && (!resource.zip || resource.cpack)"
												class="d-flex flex-row" style="margin-top: 4px">
												<small class="mr-1 text-muted" v-tippy
													:title="$t('ItemEdit.Resources.upload.preferred_width')">W</small>
												<input type="number" class="form-control" style="
														height: 20px;
														width: 80px;
														font-size: 0.8rem;
														padding-top: 4px;
													" v-model="resource.width" />
												<small class="ml-2 mr-1 text-muted" v-tippy
													:title="$t('ItemEdit.Resources.upload.preferred_height')">H</small>
												<input type="number" class="form-control" style="
														height: 20px;
														width: 80px;
														font-size: 0.8rem;
														padding-top: 4px;
													" v-model="resource.height" />
											</div>
										</div>
									</div>
								</div>
								<div class="m-auto pr-2" style="flex-grow: 0" v-show="resource.uploading" v-tippy
									title="Uploading">
									<loading type="icon" />
								</div>
								<div class="m-auto pr-2" style="flex-grow: 0" @click="deleteNewResource(resource)"
									v-if="!uploadingFiles">
									<button class="btn btn-sm btn-icon btn-rounded hover-darken red text-white m-0"
										v-tippy :title="$t('tooltip.delete')">
										<i class="far fa-trash"></i>
									</button>
								</div>
							</div>
						</div>
					</draggable>
				</div>
				<div>
					<div class="form-file form-group" v-show="newResources.length == 0">
						<div>
							<input id="file-input" class="d-none" type="file" @change="selectFiles" multiple />
							<button class="btn hover-darken theme-accent btn-block" @click="clickFileInput">
								{{ $t("ItemEdit.Resources.select_files.title") }}
							</button>
						</div>
					</div>
					<button class="btn btn-sm hover-darken theme-accent btn-block" @click="attemptAddResource"
						:disabled="uploadingFiles" v-show="newResources.length != 0">
						<i class="fa fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ addButtonText() }}</span>
					</button>
				</div>
			</div>
		</div>

		<save-required-modal :dirty="dirty" :valid="valid" :save="save" :next="saveReqNext" objectText="Item"
			:objectName="item.name" :actionText="saveReqAction" v-model="saveReqModal" />
	</div>
</template>

<style>
.dropping {
	background-color: lightgrey;
}

.missing {
	background: repeating-linear-gradient(38deg,
			rgb(255, 200, 200),
			rgb(255, 200, 200) 10px,
			rgb(255, 255, 255) 10px,
			rgb(255, 255, 255) 20px);
}
</style>

<script>
import draggable from "vuedraggable";
import ResourceService from "@/services/ResourceService";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import ThemeService from "@/services/ThemeService";
import notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import bb from "bluebird";

import SaveRequiredModal from "@/components/SaveRequiredModal";

export default {
	name: "ItemEditResources",

	props: ["id", "item", "originalResources", "dirty", "valid", "save", "defaultTab"],

	components: { draggable, SaveRequiredModal },

	data() {
		return {
			Utils: Utils,

			newResources: [],
			droppingFiles: false,
			uploadingFiles: false,
			themeColor: null,

			saveReqModal: false,
			saveReqAction: "continue",
			saveReqNext: () => { },
		};
	},

	created() {
		this.themeColor = ThemeService.getThemeColor();
	},

	watch: {},

	methods: {
		clickFileInput() {
			document.getElementById("file-input").click();
		},

		addNewResource(file) {
			var suggestedName = file.name.split(".");
			if (suggestedName.length > 1) {
				//Pop off the extension if it exists
				suggestedName.pop();
			}
			suggestedName = suggestedName.join("");
			suggestedName = suggestedName.replace(/_/g, " ");
			if (fs.hasUnicodeChars(file.name)) {
				Notie.error(this.$i18n.t("notie.add_file_fail"), this.$i18n.t("error.file_ascii"));
				return;
			}

			let newResource = {
				name: suggestedName,
				file: file,
				uploading: false,
			};
			if (file.type == "application/x-zip-compressed" || file.type == "application/zip") {
				newResource.zip = true;
				newResource.cpack = true;
				newResource.iframeType = true;
			}
			if (file.type == "text/html") {
				newResource.iframeType = true;
			}
			this.newResources.push(newResource);
		},

		selectFiles(event) {
			console.log("___FILE SELECT___");
			console.log(event.target.files);
			_.each(event.target.files, (file) => {
				this.addNewResource(file);
			});
		},

		addDropFiles(event) {
			console.log("___FILE ENTER___");
			console.log(event.dataTransfer.files);
			this.droppingFiles = true;
		},

		removeDropFiles(event) {
			console.log("___FILE LEAVE___");
			console.log(event.dataTransfer.files);
			this.droppingFiles = false;
		},

		doDropFiles(event) {
			console.log("___FILE DROP___");
			console.log(event.dataTransfer.files);
			_.each(event.dataTransfer.files, (file) => {
				this.addNewResource(file);
			});
			this.droppingFiles = false;
		},

		addButtonText() {
			switch (this.newResources.length) {
				case 0:
					return "No files to add";
				case 1:
					if (this.uploadingFiles) {
						return "Adding file...";
					} else {
						return this.$i18n.t("ItemEdit.Resources.select_files.add");
					}
				default:
					if (this.uploadingFiles) {
						return `Adding... ${this.newResources.length} files`;
					} else {
						return `Add ${this.newResources.length} files`;
					}
			}
		},

		attemptAddResource() {
			if (this.dirty || !this.valid.all()) {
				this.saveReqNext = () => {
					this.addResource();
				};
				this.saveReqAction = "add resources";
				this.saveReqModal = true;
			} else {
				this.addResource();
			}
		},

		addResource() {
			var sequence = 0;
			if (this.resources) {
				sequence = this.resources.length;
			}

			this.uploadingFiles = true;
			bb.map(
				this.newResources,
				(resource) => {
					let form = new FormData();
					form.set("file", resource.file);
					form.set("name", resource.name);
					if (resource.iframeType && resource.width && resource.height) {
						form.set("width", resource.width);
						form.set("height", resource.height);
						console.log("ADDED WIDTH/HEIGHT", resource.width, resource.height);
					}
					form.set("itemID", this.item.id);
					form.set("sequence", sequence);
					if (resource.cpack) {
						form.set("cpack", true);
					}
					sequence++;

					resource.uploading = true;
					return ResourceService.newResource(form, (e) => {
						this.$set(resource, "loaded", e.loaded);
						this.$set(resource, "total", e.total);
						console.log(resource.loaded, "out of", resource.total);
					})
						.then((resp) => {
							console.log("___UPLOADED___");
							console.log(resp);
							notie.info(this.$i18n.t("notie.resource_uploaded", { name: resource.name }));
							this.newResources = _.without(this.newResources, resource);
							this.item.resources.push(resp.data);
							resource.uploading = false;
						})
						.catch((err) => {
							notie.error(this.$i18n.t("notie.upload_resource_fail", { name: resource.name }), err);
							console.error(`Failed to upload '${resource.name}'`);
							console.error(err);
							resource.uploading = false;
						});
				},
				{ concurrency: 1 }
			).then(() => {
				console.log("DONE WITH ALL RESOURCES");
				this.uploadingFiles = false;
			});
		},

		deleteResource(resource) {
			this.item.resources = _.without(this.item.resources, resource);
		},

		deleteNewResource(resource) {
			this.newResources = _.without(this.newResources, resource);
		},

		viewResource(resource) {
			ResourceService.checkResource(this.item.id, resource.id)
				.then((resp) => {
					window.open(`#/resource/${this.item.id}/${resource.id}`);
				})
				.catch((err) => {
					if (err.response.status == 404) {
						notie.error(this.$i18n.t("notie.no_resource"), `${this.item.name} > ${resource.name}`);
						resource.missing = true;
						this.$forceUpdate();
					} else {
						notie.error(this.$i18n.t("notie.check_resource_fail"), err);
					}
				});
		},

		downloadResource(resource) {
			ResourceService.checkResource(this.item.id, resource.id)
				.then((resp) => {
					ResourceService.downloadResource(this.item, resource);
				})
				.catch((err) => {
					if (err.response.status == 404) {
						notie.error(this.$i18n.t("notie.no_resource"), `${this.item.name} > ${resource.name}`);
						resource.missing = true;
						this.$forceUpdate();
					} else {
						notie.error(this.$i18n.t("notie.check_resource_fail"), err);
					}
				});
		},

		isOriginal(resource) {
			let orig = _.find(this.originalResources, { id: resource.id });
			return orig != null;
		},

		progressBarStyle(prog) {
			let r = this.themeColor.r;
			let g = this.themeColor.g;
			let b = this.themeColor.b;
			let p = 0;
			if (prog.loaded && prog.total) {
				p = (prog.loaded / prog.total) * 100;
			}
			return {
				background: `linear-gradient(90deg, rgba(${r}, ${g}, ${b}, 0.2) ${p}%, #00000000 0%)`,
			};
		},
	},
};
</script>
