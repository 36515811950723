import axios from "axios";

export default {
	listRubrics(ctx) {
		return axios.get("/v1/admin/rubrics", { params: ctx });
	},

	listAllRubrics() {
		return this.listRubrics({ perPage: 0, currentPage: 0 });
	},

	saveRubric(rubric) {
		return axios.post("/v1/admin/rubric", rubric);
	},

	copyRubric(id) {
		return axios.post(`/v1/admin/rubric/${id}/copy`);
	},

	removeRubric(id) {
		return axios.delete(`/v1/admin/rubric/${id}`);
	},

	getRubric(id) {
		return axios.get(`/v1/admin/rubric/${id}`);
	},

	isScored(id) {
		return axios.get(`/v1/admin/rubric_scored/${id}`);
	},

	isUsedInResGroup(id) {
		return axios.get(`/v1/admin/rubric_in_res_group/${id}`);
	},

	getScorableTraits(rubric) {
		if (!(rubric && rubric.traits)) {
			return [];
		}
		let filteredTraits = _.filter(rubric.traits, (trait) => {
			return !(trait.separator || trait.is_parent);
		});
		let sortedTraits = _.sortBy(filteredTraits, "sequence");
		return sortedTraits;
	},

	getNormalizedTraits(rubric) {
		if (!(rubric && rubric.traits)) {
			return [];
		}
		let filteredTraits = _.filter(rubric.traits, (trait) => {
			return !(trait.separator || trait.is_parent || trait.hidden);
		});
		let sortedTraits = _.sortBy(filteredTraits, "sequence");
		return sortedTraits;
	},

	prepIncomingData(rubric) {
		if (rubric.score_mapping) {
			let rows = [];
			for (const [key, val] of Object.entries(rubric.mapping_table)) {
				rows.push({ oscar: key, export: val });
			}
			rubric.score_mapping_rows = rows;
		}
	},

	prepOutgoingData(inRubric) {
		let rubric = _.cloneDeep(inRubric);
		if (rubric.score_mapping) {
			let map = {};
			for (const row of rubric.score_mapping_rows) {
				if (row.oscar == "" || row.export == "") {
					continue;
				}
				map[row.oscar] = row.export;
			}
			rubric.mapping_table = map;
		} else {
			rubric.mapping_table = {};
		}
		return rubric;
	},
};
