var styleTemplate = `
.text-theme,
.text-hover-theme a:hover,
.text-hover-theme .active > a {
  color: rgb($RGBColors) !important; }

.nav-active-theme .nav-link.active,
.nav-active-theme .nav > li.active > a,
.btn.theme,
.btn.b-theme:hover,
.btn.b-theme:focus,
.pace .pace-progress,
.theme {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgb($RGBColors) !important;
  transition: background-color 0.3s ease;}

.theme-lighten-1 {
  background-color: rgb($RGBColors, 0.85);
}
.theme-lighten-2 {
  background-color: rgb($RGBColors, 0.65);
}
.theme-lighten-3 {
  background-color: rgb($RGBColors, 0.45);
}
.theme-lighten-4 {
  background-color: rgb($RGBColors, 0.3);
}
.theme-lighten-5 {
  background-color: rgb($RGBColors, 0.15);
}

.theme-border {
	border: 2px solid rgb($RGBColors);
}

:root {
  --theme-color: rgb($RGBColors);
  --theme-color-lighten-1: rgb($RGBColors, 0.85);
  --theme-color-lighten-2: rgb($RGBColors, 0.65);
  --theme-color-lighten-3: rgb($RGBColors, 0.45);
  --theme-color-lighten-4: rgb($RGBColors, 0.3);
  --theme-color-lighten-5: rgb($RGBColors, 0.15);
}

.theme-hover:hover {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgb($RGBColors) !important;
}
.theme-hover-lighten-6:hover {
  background-color: rgb($RGBColors, 0.1);
}
.theme-hover-lighten-7:hover {
  background-color: rgb($RGBColors, 0.05);
}

.b-theme,
.nav-border,
nav.nav-border {
  border-color: rgb($RGBColors) !important; }

.btn.theme-accent,
.btn.b-theme-accent:hover,
.btn.b-theme-accent:focus {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgb($RGBColors) !important; }

.theme-accent {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgb($RGBColors) !important; }

.sidenav .nav-border .active > a,
.sidenav .nav-active-text .active > a {
  color: rgb($RGBColors); }
.nav-active {
  border-left: 5px solid !important;
  border-color: rgb($RGBColors) !important;
  background-color: ghostwhite;
}

.fill-theme {
  fill: rgb($RGBColors); }

.stroke-theme {
  stroke: rgb($RGBColors); }

.modal-header {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgb($RGBColors) !important; }


/* Rubric */
.trait-row > button {
  background-color: rgb($RGBColors) !important;
  opacity : 0.3;
  transition: background-color 0.1s
}
.trait-row > button.active {
  background-color: rgb($RGBDark) !important;
  opacity:1;
  box-shadow: none !important
}
.trait-row > button.spdisabled {
  background-color: rgb($RGBColors, 0.15) !important;
}
.trait-row > button.active:hover, .mt-2 ~ .mt-2 .trait-row > button.spdisabled:hover {
  box-shadow: none !important
}

/* vue-select highlighting */
.v-select .dropdown-menu > .highlight > a:not(:empty)
 {
	color: rgba(255, 255, 255, 1) !important;
	background-color: rgb($RGBColors) !important;
}

/* Date picker highlighting */
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:active {
    background-image: none;
    color: rgba(255, 255, 255, 0.85);
    background-color: rgb($RGBColors) !important;
}
`;

var themes = [
	{ name: "info", label: "Oscar™ Green", r: 0, g: 150, b: 136 },
	{ name: "warning", label: "Yellow", r: 173, g: 145, b: 45 },
	{ name: "danger", label: "Red", r: 150, g: 35, b: 0 },
	{ name: "accent", label: "Blue", r: 57, g: 64, b: 158 },
	{ name: "primary", label: "Cyan", r: 115, g: 154, b: 212 },
	{ name: "warn", label: "Purple", r: 106, g: 54, b: 158 },
	{ name: "success", label: "Green", r: 56, g: 128, b: 56 },
	{ name: "black", label: "Black", r: 64, g: 64, b: 64 }
];

import AuthService from "@/services/AuthService";

export default {
	currentTheme: null,

	getThemes() {
		return Promise.resolve(themes);
	},

	getStylesheet(r, g, b) {
		return styleTemplate
			.replace(/\$RGBColors/g, `${r}, ${g}, ${b}`)
			.replace(/\$RGBDark/g, `${r * 0.75}, ${g * 0.75}, ${b * 0.75}`);
	},

	setTheme(themeName) {
		if (!themeName) {
			themeName = "info";
		}
		var theme = _.find(themes, { name: themeName });

		if (theme) {
			if (this.currentTheme != themeName) {
				console.log("Set theme to", theme.label);
				var styleElement = document.getElementById("theme-css");
				if (!styleElement) {
					var head =
						document.head ||
						document.getElementsByTagName("head")[0];
					styleElement = document.createElement("style");
					styleElement.id = "theme-css";
					styleElement.type = "text/css";
					head.appendChild(styleElement);
				}

				var existingTextNode = styleElement.childNodes[0];
				if (existingTextNode) {
					styleElement.replaceChild(
						document.createTextNode(
							this.getStylesheet(theme.r, theme.g, theme.b)
						),
						existingTextNode
					);
				} else {
					styleElement.appendChild(
						document.createTextNode(
							this.getStylesheet(theme.r, theme.g, theme.b)
						)
					);
				}

				this.currentTheme = themeName;
			}
		} else {
			notie.error(`Failed to load theme "${themeName}"`);
		}
	},

	getTheme(themeName) {
		if (!themeName) {
			themeName = this.currentTheme;
		}
		return _.find(themes, { name: themeName });
	},

	getThemeColor(themeName) {
		var theme = this.getTheme(themeName);
		if (theme) {
			return { r: theme.r, g: theme.g, b: theme.b };
		} else {
			return { r: 0, g: 0, b: 0 };
		}
	},

	getThemeColorHex(themeName) {
		var theme = this.getTheme(themeName);
		if (theme) {
			let rhex = theme.r.toString(16).padStart(2, "0");
			let ghex = theme.g.toString(16).padStart(2, "0");
			let bhex = theme.b.toString(16).padStart(2, "0");
			return `#${rhex}${ghex}${bhex}`;
		} else {
			return `#000000`;
		}
	},

	refresh() {
		return AuthService.getUser(true).then(user => {
			this.setTheme(user.role.theme);
		});
	}
};
