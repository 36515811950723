<template>
	<div class="sidenav modal-dialog white page-lst" ui-class="white">
		<div>
			<div class="scroll-y">
				<div class="data-nav">
					<ul class="nav sub">
						<div v-for="group in sidenavGroups" :key="group.name">
							<li class="nav-header mt-2">
								<span class="text-xs text-theme hidden-folded">{{ group.name }}</span>
							</li>
							<li v-for="task in group.tasks" :key="task.name" :class="isActivePage(task)">
								<a :href="link && `#${task.path}`" class="active" v-if="!task.subtasks">
									<span class="nav-icon" v-if="task.icon">
										<i class="fa" :class="{ [task.icon]: true }"></i>
									</span>
									<span class="nav-icon" v-if="task.m_icon">
										<i class="material-icons tiny">{{ task.m_icon }}</i>
									</span>
									<span v-if="task.showMailCounts" class="nav-badge">
										<b v-if="importantMail > 0" class="badge badge-pill danger">{{
											importantMail
										}}</b>
										<b
											v-if="importantMail == 0 && unreadMail > 0"
											class="badge badge-pill success"
											>{{ unreadMail }}</b
										>
									</span>
									<span v-if="task.showMeetingCount" class="nav-badge">
										<b v-if="meetingCount > 0" class="badge badge-pill success">
											<i class="far fa-calendar-exclamation text-white" />
											<span v-if="meetingCount > 1" class="ml-1">{{ meetingCount }}</span>
										</b>
									</span>
									<span class="nav-text">{{ task.name }}</span>
								</a>
								<a @click="toggleExpand(task)" class="active" v-if="task.subtasks">
									<span class="nav-icon" v-if="task.icon">
										<i class="fa" :class="{ [task.icon]: true }"></i>
									</span>
									<span class="nav-icon" v-if="task.m_icon">
										<i class="material-icons tiny">{{ task.m_icon }}</i>
									</span>
									<span class="nav-caret">
										<i class="fa fa-caret-left"></i>
									</span>
									<span class="nav-text">{{ task.name }}</span>
								</a>
								<ul
									class="nav-sub"
									v-if="task.subtasks"
									:style="{ 'max-height': `${32 * task.subtasks.length}px` }"
								>
									<li
										v-for="subtask in task.subtasks"
										:key="subtask.name"
										:class="isActivePage(subtask)"
									>
										<a :href="link && `#${subtask.path}`" class="waves-effect">
											<span class="nav-text">{{ subtask.name }}</span>
										</a>
									</li>
								</ul>
							</li>
						</div>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";

let superseded_by = {
	page_config_current_tenant: ["page_config_tenants"],
	page_landing: ["page_bulletin_board", "page_dashboard"],
};

export default {
	name: "SidenavPreview",
	props: {
		role: Object,
		configSheets: Array,
		link: Boolean,
		unreadMail: Number,
		importantMail: Number,
		meetingCount: Number,
	},
	data() {
		return {
			scoringTasks: [],
			operationsTasks: [],
			configTasks: [],
			userManagementTasks: [],
			qualityManagementTasks: [],
			expandedTask: false,
		};
	},
	created() {
		this.instantiateTaskObjects();
	},
	computed: {
		sidenavGroups() {
			let groupDefinitions = {};
			groupDefinitions[this.$i18n.t("Sidenav.scoring")] = this.scoringTasks;
			groupDefinitions[this.$i18n.t("Sidenav.observation")] = this.observationTasks;
			groupDefinitions[this.$i18n.t("Sidenav.operations")] = this.operationsTasks;
			groupDefinitions[this.$i18n.t("Sidenav.quality_management")] = this.qualityManagementTasks;
			groupDefinitions[this.$i18n.t("Sidenav.user_management")] = this.userManagementTasks;
			groupDefinitions[this.$i18n.t("Sidenav.setup_configuration")] = this.configTasks;
			if (
				this.configSheets &&
				this.configSheets.length > 0 &&
				this.hasPermission("use_config_sheets", this.role)
			) {
				groupDefinitions[this.$i18n.t("Sidenav.config_sheets")] = this.makeConfigSheetTasks(this.configSheets);
			}
			groupDefinitions[this.$i18n.t("Sidenav.api_logs")] = this.apiTasks;

			let groups = [];
			_.each(groupDefinitions, (groupDef, name) => {
				//Only construct a group if the user has access to at least one task in it
				if (this.hasAnyPermissionInGroup(groupDef, this.role)) {
					let group = { name: name, tasks: [] };
					_.each(groupDef, (taskDef) => {
						if (taskDef.subtasks) {
							let subtasks = this.getPermittedSubtasks(taskDef);
							if (subtasks.length == 0) {
								//No permitted subtasks, don't make the task group at all
							} else if (subtasks.length == 1) {
								//One permitted subtask, promote the subtask to a normal task
								let task = _.cloneDeep(taskDef);
								if (subtasks[0].singleName) {
									task.name = subtasks[0].singleName;
								}
								delete task.subtasks;
								task.path = subtasks[0].path;
								group.tasks.push(task);
							} else {
								//Multiple permitted subtasks, create a collapsible group for them
								let task = _.cloneDeep(taskDef);
								task.subtasks = subtasks;
								group.tasks.push(task);
							}
						} else {
							//No subtasks at all, just a normal task
							if (this.hasPermission(taskDef.permission, this.role)) {
								group.tasks.push(taskDef);
							}
						}
					});
					groups.push(group);
				}
			});

			return groups;
		},
	},
	methods: {
		hasPermission(perm, role) {
			if (typeof perm === "object") {
				for (let p of perm) {
					if (this.hasPermission(p, role)) {
						return true;
					}
				}
				return false;
			}
			//Don't count this permission if the user has a permission that completely includes its functionality
			//(to prevent redundancy)
			var super_perms = superseded_by[perm];
			if (super_perms) {
				let has_super = false;
				_.each(super_perms, (perm) => {
					if (role[perm]) {
						has_super = true;
					}
				});
				if (has_super) {
					return false;
				}
			}

			//Handle temporary landing page, which is always available unless it's superseded
			if (perm === "page_landing") {
				return true;
			}

			return role[perm];
		},
		hasAnyPermissionInGroup(group, role) {
			var hasPerm = false;
			_.each(group, (task) => {
				if (task.subtasks) {
					_.each(task.subtasks, (subtask) => {
						if (!subtask.permission || this.hasPermission(subtask.permission, this.role)) {
							hasPerm = true;
						}
					});
				} else {
					if (this.hasPermission(task.permission, role)) {
						hasPerm = true;
					}
				}
			});
			return hasPerm;
		},
		getPermittedSubtasks(task) {
			var subtasks = [];
			_.each(task.subtasks, (subtask) => {
				if (!subtask.permission || this.hasPermission(subtask.permission, this.role)) {
					subtasks.push(subtask);
				}
			});
			return subtasks;
		},
		isActivePage(task) {
			if (task.path) {
				var active = this.$route.path == task.path || _.startsWith(this.$route.path, task.path + "/");
				return { "nav-active": active };
			} else {
				var active = false;
				_.each(task.subtasks, (subtask) => {
					active = active || _.startsWith(this.$route.path, subtask.path);
				});
				active = active || this.expandedTask == task;

				return { /* "nav-active": active, */ active: active };
			}
		},
		toggleExpand(task) {
			if (this.expandedTask == task) {
				this.expandedTask = false;
			} else {
				this.expandedTask = task;
			}
		},
		instantiateTaskObjects() {
			this.scoringTasks = [
				{
					name: this.$i18n.t("Sidenav.landing"),
					path: "/landing",
					permission: "page_landing",
					icon: "fa-browser",
				},
				{
					name: this.$i18n.t("Sidenav.bulletin_board"),
					path: "/bulletin_board",
					permission: "page_bulletin_board",
					icon: "fa-browser",
				},
				{
					name: this.$i18n.t("Sidenav.training"),
					path: "/my_training",
					permission: "page_scoring",
					icon: "fa-chalkboard-teacher",
				},
				{
					name: this.$i18n.t("Sidenav.scoring"),
					path: "/scoring",
					permission: "page_scoring",
					m_icon: "check",
				},
				{
					name: this.$i18n.t("Sidenav.project_summary"),
					path: "/project_summary",
					permission: "page_project_summary",
				},
				{
					name: this.$i18n.t("Sidenav.mail"),
					path: "/mail",
					permission: "page_mail",
					showMailCounts: true,
					icon: "fa-envelope",
				},
				// {
				// 	name: "Backreading",
				// 	path: "/backreading",
				// 	permission: "page_backreading",
				// 	icon: { "fa-dashboard": true }
				// }
			];

			this.observationTasks = [
				{
					name: this.$i18n.t("Sidenav.sessions"),
					path: "/observation_sessions",
					permission: "page_observation_sessions",
					icon: "fa-clock",
				},
				{
					name: this.$i18n.t("Sidenav.sites"),
					path: "/sites",
					permission: "page_config_sites",
					icon: "fa-map-marked-alt",
				},
				{
					name: this.$i18n.t("Sidenav.forms"),
					path: "/forms",
					permission: "page_config_forms",
					icon: "fa-grip-horizontal",
				},
			];
			this.operationsTasks = [
				{
					name: this.$i18n.t("Sidenav.meetings"),
					path: "/video_meetings",
					permission: "page_video_meetings",
					showMeetingCount: true,
					icon: "fa-address-card",
				},
				{
					name: this.$i18n.t("Sidenav.my_schedule"),
					path: "/my_schedule",
					permission: "page_my_schedule",
					icon: "fa-clock",
				},
				{
					name: this.$i18n.t("Sidenav.calendar"),
					path: "/calendar",
					permission: "page_calendar",
					icon: "fa-calendar",
				},
				{
					name: this.$i18n.t("Sidenav.forecast"),
					path: "/forecast",
					permission: "page_forecast",
					icon: "fa-forward",
				},
				{
					name: this.$i18n.t("Sidenav.dashboards"),
					icon: "fa-tachometer-alt",
					subtasks: [
						{
							name: this.$i18n.t("Sidenav.personal"),
							singleName: this.$i18n.t("Sidenav.dashboard"),
							path: "/dashboard",
							permission: "page_dashboard",
						},
						{
							name: this.$i18n.t("Sidenav.supervisor"),
							singleName: this.$i18n.t("Sidenav.dashboard"),
							path: "/supervisor_dashboard",
							permission: "page_supervisor_dashboard",
						},
						{
							name: this.$i18n.t("Sidenav.project"),
							singleName: this.$i18n.t("Sidenav.dashboard"),
							path: "/project_dashboard",
							permission: "page_project_dashboard",
						},
						{
							name: this.$i18n.t("Sidenav.tenant"),
							singleName: this.$i18n.t("Sidenav.dashboard"),
							path: "/tenant_dashboard",
							permission: "page_tenant_dashboard",
						},
					],
				},
			];

			this.userManagementTasks = [
				{
					name: this.$i18n.t("Sidenav.users"),
					path: "/users",
					permission: "page_config_users",
					icon: "fa-user",
				},
				{
					name: this.$i18n.t("Sidenav.teams"),
					path: "/teams",
					permission: "page_config_teams",
					icon: "fa-users",
				},
				{
					name: this.$i18n.t("Sidenav.roles"),
					path: "/roles",
					permission: ["page_config_current_tenant", "page_config_tenants"],
					icon: "fa-user-shield",
				},
			];

			this.qualityManagementTasks = [
				{
					name: this.$i18n.t("Sidenav.auditing"),
					icon: "fa-barcode-alt",
					subtasks: [
						{
							name: this.$i18n.t("Sidenav.responses"),
							path: "/auditing",
							permission: "page_auditing",
						},
						{
							name: this.$i18n.t("Sidenav.scores"),
							path: "/score_auditing",
							permission: "page_score_auditing",
						},
						{
							name: this.$i18n.t("Sidenav.candidates"),
							path: "/candidate_auditing",
							permission: "page_candidate_auditing",
						},
						{
							name: this.$i18n.t("Sidenav.reset_history"),
							path: "/reset_history",
							permission: "reset_history",
						},
					],
				},
				{
					name: this.$i18n.t("Sidenav.reporting"),
					icon: "fa-chart-bar",
					subtasks: [
						{
							name: this.$i18n.t("Sidenav.completion"),
							singleName: this.$i18n.t("Sidenav.completion"),
							path: "/reporting/completion",
							permission: "page_completion",
						},
						{
							name: this.$i18n.t("Sidenav.scorers"),
							singleName: this.$i18n.t("Sidenav.scorers_report"),
							path: "/reporting/scorers",
							permission: "page_scorers",
						},
						{
							name: this.$i18n.t("Sidenav.payroll"),
							singleName: this.$i18n.t("Sidenav.payroll"),
							path: "/reporting/payroll",
							permission: "page_payroll",
						},
						{
							name: this.$i18n.t("Sidenav.daily"),
							singleName: this.$i18n.t("Sidenav.daily_report"),
							path: "/reporting/daily",
							permission: "page_daily",
						},
						{
							name: this.$i18n.t("Sidenav.daily_scoring_mgmt"),
							singleName: this.$i18n.t("Sidenav.daily_scoring_mgmt"),
							path: "/reporting/daily_scoring_mgmt",
							permission: "page_daily_scoring_mgmt",
						},
						{
							name: this.$i18n.t("Sidenav.quotas"),
							singleName: this.$i18n.t("Sidenav.quotas_report"),
							path: "/reporting/quotas",
							permission: "page_quotas",
						},
						{
							name: this.$i18n.t("Sidenav.user_summary"),
							singleName: this.$i18n.t("Sidenav.user_summary"),
							path: "/reporting/user_summary",
							permission: "page_user_summary",
						},
						{
							name: this.$i18n.t("Sidenav.item_summary"),
							singleName: this.$i18n.t("Sidenav.item_summary"),
							path: "/reporting/item_summary",
							permission: "page_item_summary",
						},
						{
							name: this.$i18n.t("Sidenav.user_agreement"),
							singleName: this.$i18n.t("Sidenav.agreement"),
							path: "/reporting/user_agreement",
							permission: "page_user_agreement",
						},
						{
							name: this.$i18n.t("Sidenav.backread_agreement"),
							singleName: this.$i18n.t("Sidenav.backread_agreement"),
							path: "/reporting/backread_agreement",
							permission: "page_backread_agreement",
						},
						{
							name: this.$i18n.t("Sidenav.project_agreement"),
							singleName: this.$i18n.t("Sidenav.agreement"),
							path: "/reporting/project_agreement",
							permission: "page_project_agreement",
						},
						{
							name: this.$i18n.t("Sidenav.training_completion"),
							singleName: this.$i18n.t("Sidenav.training_completion"),
							path: "/reporting/training_completion",
							permission: "page_training_completion",
						},
						{
							name: this.$i18n.t("Sidenav.report_sla"),
							singleName: this.$i18n.t("Sidenav.report_sla"),
							path: "/reporting/sla",
							permission: "page_sla_report",
						},
					],
				},
				{
					name: this.$i18n.t("Sidenav.qc"),
					icon: "fa-balance-scale",
					subtasks: [
						{
							name: this.$i18n.t("Sidenav.setup"),
							singleName: this.$i18n.t("Sidenav.qc_setup"),
							path: "/qc_setup",
							permission: "page_qc_setup",
						},
						{
							name: this.$i18n.t("Sidenav.reporting"),
							singleName: this.$i18n.t("Sidenav.qc_reporting"),
							path: "/qc_reporting",
							permission: "page_qc_report",
						},
						{
							name: this.$i18n.t("Sidenav.user_portfolio"),
							singleName: this.$i18n.t("Sidenav.user_portfolio"),
							path: "/qc_portfolio",
							permission: "qc_portfolio",
						},
					],
				},
				{
					name: this.$i18n.t("Sidenav.rangefinding"),
					icon: "fa-drafting-compass",
					subtasks: [
						{
							name: this.$i18n.t("Sidenav.setup"),
							singleName: this.$i18n.t("Sidenav.rf_setup"),
							path: "/rf_setup",
							permission: "page_rf_setup",
						},
						{
							name: this.$i18n.t("Sidenav.reporting"),
							singleName: this.$i18n.t("Sidenav.rf_reporting"),
							path: "/rf_reporting",
							permission: "page_rf_report",
						},
					],
				},
			];

			this.configTasks = [
				{
					name: this.$i18n.t("Sidenav.tenant_setup"),
					path: "/tenants",
					permission: "page_config_tenants",
					icon: "fa-building",
				},
				{
					name: this.$i18n.t("Sidenav.tenant_config"),
					path: "/tenant",
					permission: "page_config_current_tenant",
					icon: "fa-building",
				},
				{
					name: this.$i18n.t("Sidenav.project_setup"),
					path: "/projects",
					permission: "page_config_projects",
					icon: "fa-box-open",
				},
				{
					name: this.$i18n.t("Sidenav.section_setup"),
					path: "/sections",
					permission: "page_config_sections",
					icon: "fa-briefcase",
				},
				{
					name: this.$i18n.t("Sidenav.item_setup"),
					path: "/items",
					permission: "page_config_items",
					icon: "fa-book",
				},
				{
					name: this.$i18n.t("Sidenav.rubric_setup"),
					path: "/rubrics",
					permission: "page_config_rubrics",
					icon: "fa-tasks",
				},
				{
					name: this.$i18n.t("Sidenav.res_groups"),
					path: "/resolution_groups",
					permission: "page_config_res_groups",
					icon: "fa-layer-plus",
				},
				{
					name: this.$i18n.t("Sidenav.training_setup"),
					path: "/training",
					permission: "page_config_training",
					icon: "fa-chalkboard-teacher",
				},
				{
					name: this.$i18n.t("Sidenav.scorm_pool"),
					path: "/scorm_pool",
					permission: "page_config_training",
					icon: "fa-presentation",
				},
				{
					name: this.$i18n.t("Sidenav.iea_models"),
					path: "/iea_tod_items",
					permission: "manage_iea_models",
					icon: "fa-ticket-alt",
				},
			];

			this.apiTasks = [
				{
					name: this.$i18n.t("Sidenav.loopback"),
					path: "/api_logs/loopback",
					permission: "page_api_logs",
					icon: "fa-sync",
				},
				{
					name: this.$i18n.t("Sidenav.outgoing"),
					path: "/api_logs/outgoing",
					permission: "page_api_logs",
					icon: "fa-arrow-alt-from-left",
				},
				{
					name: this.$i18n.t("Sidenav.obs_outgoing"),
					path: "/api_logs/observation/outgoing",
					permission: "page_api_logs",
					icon: "fa-grip-horizontal",
				},
				// {
				// 	name: this.$i18n.t("Sidenav.incoming"),
				// 	path: "/api_logs/incoming",
				// 	permission: "page_api_logs",
				// 	icon: "fa-arrow-alt-to-right",
				// },
			];
		},

		makeConfigSheetTasks(configSheets) {
			let tasks = [];
			_.each(configSheets, (configSheet) => {
				let task = {
					name: configSheet.name,
					path: `/config_sheets/${configSheet.id}`,
					permission: "use_config_sheets",
					icon: "fa-table",
				};
				tasks.push(task);
			});
			return tasks;
		},
	},
};
</script>

<style scoped>
.sidenav .flex::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.sidenav .flex::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.sidenav {
	overflow-y: scroll;
}

/* Side nav visible */
@media (min-width: 992px) {
}

/* Side nav collapsed */
@media (max-width: 991px) {
}

.nav-sub {
	transition: max-height 0.25s ease 0s;
}

:not(.active) > .nav-sub {
	max-height: 0 !important;
}
</style>
