<template>
	<span class="text-xxs" v-tippy :title="`${display}`">
		({{code ? code : offset}})
	</span>
</template>

<style scoped>
span {
	opacity: 0.4;
}
</style>

<script>
var moment = require("moment-timezone");

export default {
	name: "UserTimezone",
	props: ["value"],

	data() {
		return {
			zone: null,
			code: null
		};
	},
	created() {
		this.zone = moment.tz.guess();
		if (this.zone) {
			this.code = moment()
				.tz(this.zone)
				.format("zz");
		}
		this.offset = moment().format("Z");
	},
	computed: {
		display() {
			if (this.zone) {
				let disp = this.displayZone(this.zone);
				return `${this.$i18n.t('timestamp.current_timezone')} ${
					this.code
				}<br />${disp} <span class="text-muted text-xxs">(UTC${
					this.offset
				})<span>`;
			} else {
				return this.$i18n.t('UserTimezone.current_timezone', {offset:this.offset});
			}
		}
	},
	methods: {
		displayZone(zone) {
			return zone.replace("_", " ");
		}
	}
};
</script>
