<template>
	<b-container>
		<b-row align-h="center">
			<h4>{{ $t("fields.overall") }}:</h4>
			<h4 class="pl-2" :class="overallStatus.theme">{{ overallStatus.name }}</h4>
		</b-row>
		<b-row class="pl-2">
			<b-col cols="9">
				<h5 class="text-theme">
					{{ "Rule"
					}}<arrow-select
						class="pl-3"
						@forward="rulePage++"
						@back="rulePage--"
						:showForward="rulePage < maxRulePages - 1"
						:showBack="rulePage > 0"
					/>
				</h5>
			</b-col>
			<b-col>
				<h5 class="text-theme">{{ "State" }}</h5>
			</b-col>
		</b-row>
		<validity-rule-stat-row v-for="ruleStat in displayedRules" :key="ruleStat.rule" :ruleStat="ruleStat" />
		<div v-if="noRules" class="text-muted pt-3 text-center h-100 d-flex container justify-content-center">
			<h5>{{ $t("Dashboard.no_val_rules") }}</h5>
		</div>
	</b-container>
</template>

<script>
import Notie from "@/services/NotieService";
import QCService from "@/services/QCService";

import ArrowSelect from "@/components/ArrowSelect";
import ValidityRuleStatRow from "@/components/QC/QCPortfolio/ValidityRuleStatRow";

const RULES_PER_PAGE = 4;

export default {
	name: "DashboardPersonalValItemStats",

	components: { ArrowSelect, ValidityRuleStatRow },

	props: {
		user: Object,
		section: Object,
		item: Object,
	},

	data() {
		return {
			ruleStats: [],
			rulePage: 0,
		};
	},

	created() {
		this.getRuleStats();
	},

	watch: {
		section() {
			this.getRuleStats();
		},

		item() {
			this.getRuleStats();
		},
	},

	computed: {
		noRules() {
			return this.ruleStats.length == 0;
		},
		overallStatus() {
			return QCService.getValidityOverallStatus(this.ruleStats);
		},

		displayedRules() {
			const { ruleStats, rulePage } = this;
			return ruleStats.slice(rulePage * RULES_PER_PAGE, (rulePage + 1) * RULES_PER_PAGE);
		},

		maxRulePages() {
			return Math.floor(this.ruleStats.length / RULES_PER_PAGE);
		},
	},

	methods: {
		async getRuleStats() {
			const { section, item, user } = this;
			if (!(section && item && user)) return;
			try {
				const { data } = await QCService.getValidityRuleStats(section.id, item.id, user.id);
				this.ruleStats = data;
			} catch (e) {
				Notie.error("Failed to get Validity rules", e);
			}
		},
	},
};
</script>

<style></style>
