<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap no-grow">
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					<span> {{ $t("Backreading.backreading") }} </span>
					<span v-if="section">
						<i class="far fa-fw fa-angle-right"></i>
						{{ section.name }}
					</span>
					<span v-if="item">
						<i class="far fa-fw fa-angle-right"></i>
						{{ item.name }}
					</span>
				</div>
				<loading v-if="loading" type="header" />
			</div>
		</template>
		<div class="d-flex flex flex-column scroll-y">
			<div class="row mx-0 no-grow px-2 pt-3 b-b box mb-0">
				<div class="col-4 col-sm-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("fields.team") }}:</label>
					<div class="flex">
						<config-select :nullOption="true" :options="teams" v-model="selectedTeam"></config-select>
					</div>
				</div>

				<div class="col-4 col-sm-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("fields.user") }}:</label>
					<div class="flex">
						<config-user-search
							:users="userOptions"
							:clientID="client && client.id"
							v-model="selectedUser"
						/>
					</div>
				</div>

				<div v-if="!this.lockBackreadTarget" class="col-12 col-sm-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("Backreading.backread_target") }}:</label>
					<percent-input v-model="percent" style="min-width: 85px" />
					<label class="no-grow mb-0 ml-3">({{ displayInterval }})</label>
				</div>
				<div v-if="this.lockBackreadTarget" class="col-12 col-sm-3 d-flex align-items-center pb-3">
					<label v-tippy title="This target is locked" class="no-grow mb-0 mr-3"
						>{{ $t("Backreading.backread_target") }}: <b> {{ this.backreadTargetPercent }}%</b></label
					>
					<label class="no-grow mb-0 ml-0">({{ displayInterval }})</label>
				</div>

				<div class="col-4 col-sm-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("Backreading.backread_target") }}:</label>
					<div class="flex">
						<config-select
							v-model="belowBrTarget"
							:options="[
								{ id: null, name: '　' },
								{ id: true, name: 'Below' },
								{ id: false, name: 'Not Below' },
							]"
							byField="id"
						></config-select>
					</div>
				</div>

				<div class="col-12 d-flex align-items-center pb-3">
					<label
						class="no-grow mb-0 mr-3"
						v-tippy
						title="This action filters responses by<br/>the time they entered OSCAR"
						>{{ $t("Backreading.responses_from") }}</label
					>
					<div class="flex">
						<config-select
							nullText="All time"
							:options="timeframes"
							v-model="timeframe"
							sortBy="sequence"
							byField="id"
						></config-select>
					</div>
					<label class="mb-0 pl-3 pr-2"> {{ $t("Backreading.score_type") }}: </label>
					<div class="flex">
						<config-select byField="id" :options="selectableScoreTypes" v-model="selectedScoreType" />
					</div>
					<score-query
						v-if="item"
						brScoreQuery
						:rubric="item.rubric"
						:initTraitScores="traitScores"
						@newScoreFilters="newScoreFilters"
						class="pl-3 mt-2"
					/>
					<!-- <label class="mb-0 pl-3 pr-2"> {{ $t("Backreading.trait_agreement") }}: </label>
					<div class="flex">
						<config-select
							v-model="selectedTraitAgreement"
							byField="id"
							:options="[
								{ id: '', name: '　' },
								{ id: 'Exact', name: 'Exact' },
								{ id: 'Adjacent', name: 'Adjacent' },
								{ id: 'NonExact', name: 'Non-Exact' },
								{ id: 'NonAdjacent', name: 'Non-Adjacent' },
							]"
						/>
					</div> -->
					<label class="mb-0 pl-3 pr-2"> {{ $t("Backreading.exported") }}: </label>
					<div class="flex">
						<config-select
							:disabled="client && client.backreading_exclude_exported_resps"
							v-model="exported"
							byField="id"
							:options="[
								{ id: null, name: '　' },
								{ id: true, name: 'Yes' },
								{ id: false, name: 'No' },
							]"
						/>
					</div>
				</div>
				<div v-if="timeframe == 'custom'" class="col-12 col-sm-6 col-lg-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("fields.from") }}:</label>
					<config-date v-model="timeframeFrom" :config="{ showClear: true }"></config-date>
				</div>
				<div v-if="timeframe == 'custom'" class="col-12 col-sm-6 col-lg-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("fields.until") }}:</label>
					<config-date
						v-model="timeframeUntil"
						:config="{ showClear: true }"
						defaultTime="11:59 PM"
					></config-date>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("fields.scores") }} {{ $t("fields.from") }}:</label>
					<config-date v-model="scoreFrom" :config="{ showClear: true }"></config-date>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 d-flex align-items-center pb-3">
					<label class="no-grow mb-0 mr-3">{{ $t("fields.scores") }} {{ $t("fields.until") }}:</label>
					<config-date
						v-model="scoreUntil"
						:config="{ showClear: true }"
						defaultTime="11:59 PM"
					></config-date>
				</div>
			</div>

			<div class="col-12 p-0 flex">
				<div class="p-3">
					<b-card no-body>
						<b-tabs card v-model="tabIndex">
							<b-tab
								:title="$t('Backreading.by_response')"
								:title-item-class="tabIndex === 0 ? 'theme-accent' : ''"
							>
								<b-row>
									<b-col>
										<div class="text-lg pb-2">{{ $t("Backreading.all_responses") }}</div>
										<b-button
											@click="enterResponseBacking"
											:disabled="byResponseCard.scored === byResponseCard.backread"
											class="theme-accent"
											><span class="text-md"
												>{{ $t("Backreading.backread") }}
												<i class="material-icons tiny" style="position: relative; top: 3px"
													>verified_user</i
												></span
											></b-button
										>
									</b-col>
									<b-col>
										<div class="text-lg pb-3">
											{{ $t("Backreading.scored") }}: {{ byResponseCard.scored }}
										</div>
										<div
											class="text-lg w65"
											:class="colorPercent(byResponseCard)"
											style="margin-top: -6px"
										>
											{{ $t("Backreading.backread") }}:
											{{
												byResponseCard.scored > 0
													? ((byResponseCard.backread * 100) / byResponseCard.scored).toFixed(
															1
														) + "%"
													: "——"
											}}
										</div>
									</b-col>
									<b-col>
										<div class="text-lg pb-3">
											{{ $t("Backreading.last_backread") }}:
											{{
												byResponseCard.last_backread_at
													? fs.medDate(byResponseCard.last_backread_at)
													: ""
											}}
										</div>
										<div class="text-lg w65">
											{{ $t("Backreading.scores_left") }}: {{ byResponseCard.scored_since }}
										</div>
									</b-col>
								</b-row>
							</b-tab>
							<b-tab
								:title="$t('Backreading.by_user')"
								:title-item-class="tabIndex === 1 ? 'theme-accent' : ''"
								><div class="row">
									<div
										v-for="card in backreadCards.filter(({ user_id }) => user_id !== user.id)"
										:key="card.user_id"
										class="col-12 col-sm-6 col-xl-4"
									>
										<div class="box d-flex align-items-center justify-content-between">
											<div class="align-self-stretch">
												<div class="h-100 d-flex flex-column align-items-start">
													<div
														class="no-grow _600 theme-lighten-2 text-white py-1 px-2 curved-br"
														style="max-width: 150px"
													>
														{{ card.first_name }}
														{{ card.last_name }}
													</div>
													<div class="flex"></div>
													<div class="no-grow text-xs text-muted pl-2 pb-2">
														<div style="max-width: 150px">
															{{ card.scorer_id }}
														</div>
														<template v-if="user.role.backreading_stats">
															<i
																class="ml-1 fa fa-lg fa-chart-bar"
																@mouseover="loadBackreadStats(card)"
																v-tippy="{
																	html: `#stat-tooltip-${card.user_id}`,
																	theme: 'popover',
																}"
															/>
															<div
																:id="`stat-tooltip-${card.user_id}`"
																class="hidden text-danger"
																style="position: absolute"
															>
																<div class="text-muted">Loading...</div>
															</div>
														</template>
													</div>
												</div>
											</div>
											<div class="p-2">
												<table style="margin: auto">
													<tr>
														<td class="pr-1">
															<strong>{{ $t("fields.rate") }}:</strong>
														</td>
														<td>
															{{
																card.avg_time > 0
																	? (3600 / card.avg_time).toFixed(1)
																	: 0
															}}/h
														</td>
													</tr>
													<tr>
														<td class="pr-1">
															<strong>Scored:</strong>
														</td>
														<td>{{ card.scored }}</td>
													</tr>
												</table>
											</div>
											<div class="d-flex align-items-center flex-column">
												<div
													class="text-lg text-center w65"
													:class="colorPercent(card)"
													style="margin-top: -6px"
													v-tippy
													:title="`Last backread response: ${fs.medDate(
														card.last_backread_at
													)}<br />Scores left in sequential backreading: ${
														card.scored_since
													}`"
												>
													{{ ((card.backread * 100) / card.scored).toFixed(1) }}%
												</div>
												<div
													class="target-percent btn-subtle"
													v-tippy
													:title="targetPercentTooltip()"
													data-toggle="dropdown"
													aria-expanded="false"
													@click="prepIndDropdown(card)"
												>
													<i
														class="fas text-extra-muted mr-1"
														:class="{
															[hasIndTarget(card)
																? 'fa-bullseye-pointer'
																: 'fa-bullseye']: true,
														}"
													/><span class="text-muted"> {{ getCardTarget(card) }}% </span>
												</div>
												<div class="dropdown-menu dropdown-menu-right">
													<div class="py-2 px-3">
														<label>Individual Backread % Target</label>
														<percent-input v-model="indTarget" />
														<div class="d-flex mt-3">
															<button
																style="width: 50%"
																class="btn btn-sm btn-danger mr-3"
															>
																Cancel
															</button>
															<button
																style="width: 50%"
																class="btn btn-sm btn-success"
																@click="saveIndTarget(card, indTarget)"
															>
																{{ $t("buttons.save") }}
															</button>
														</div>
													</div>
												</div>
											</div>
											<div class="pl-2 py-2 pr-3 d-flex align-items-center">
												<button
													@click="enterBackreading(section.id, item.id, card)"
													:disabled="card.loading || card.scored === card.backread"
													class="btn btn-icon btn-rounded theme text-white"
													v-tippy
													:title="$t('tooltip.backread')"
												>
													<i class="material-icons tiny">verified_user</i>
												</button>
											</div>
										</div>
									</div>
								</div></b-tab
							>
						</b-tabs>
					</b-card>
				</div>
			</div>
		</div>

		<b-modal :visible="confirmingCard != null" v-if="confirmingCard" @hidden="confirmingCard = null">
			<template slot="modal-title">{{ $t("Backreading.random_backreading") }}</template>
			<div class="py-4">
				{{ $t("Backreading.no_more_sequential") }}
				<span class="_600 text-theme"> {{ confirmingCard.first_name }} {{ confirmingCard.last_name }}</span
				>.
				<br />
				<br />{{ $t("Backreading.backread_responses_random") }} <strong>{{ $t("Backreading.all") }}</strong>
				{{
					confirmingCard.user_id
						? $t("Backreading.users_have_been_backread")
						: $t("Backreading.scores_have_been_backread")
				}}
			</div>
			<template slot="modal-footer">
				<button class="btn btn-primary btn-flat" @click="confirmingCard = null">Cancel</button>
				<button
					class="btn btn-success btn-flat"
					@click="enterRandomBackreading(section.id, item.id, confirmingCard)"
				>
					{{ $t("buttons.backread_randomly") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w65 {
	min-width: 65px;
}
.curved-br {
	border-bottom-right-radius: 0.75rem;
}
.target-percent {
	font-size: 0.8rem;
	line-height: 1rem;
	opacity: 0.35;
}
.btn-subtle {
	cursor: pointer;
	margin-top: -4px;
	padding-left: 4px;
	padding-right: 4px;
	opacity: 1;
	transition: background-color 0.15s;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.2);
}
</style>

<script>
//Directives
require("@/directives/chartjs");

//Services
import ScoringService from "@/services/ScoringService";
import ConfigService from "@/services/ConfigService";
import SectionService from "@/services/SectionService";
import ItemService from "@/services/ItemService";
import ReportingService from "@/services/ReportingService";
import TeamService from "@/services/TeamService";
import UserService from "@/services/UserService";
import driver from "@/services/ScoringDrivers/QueueBackreadDriver";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import fs from "@/services/FormatService";
import moment from "moment";
import BB from "bluebird";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import ConfigDate from "@/components/ConfigDate";
import PercentInput from "@/components/PercentInput";
import ScoreQuery from "@/components/ScoreQuery";

import TenantService from "@/services/TenantService";

import { SCORE_TYPES } from "@/services/Constants";
const PAST_WEEK = { id: "week", name: "Past week", sequence: 1 };
const timeframes = [
	{ id: "all_time", name: "All Time", sequence: 0 },
	PAST_WEEK,
	{ id: "month", name: "Past month", sequence: 2 },
	{ id: "3months", name: "Past 3 months", sequence: 3 },
	{ id: "year", name: "Past year", sequence: 4 },
	{ id: "custom", name: "Custom timeframe...", sequence: 5 },
];

export default {
	name: "Backreading",

	props: ["user", "params"],

	components: { ConfigSelect, ConfigUserSearch, ConfigDate, PercentInput, ScoreQuery },

	data() {
		return {
			fs: fs,
			loading: true,

			section: null,
			item: null,

			teams: [],
			selectedTeam: null,
			percent: 100,
			timeframes: timeframes,
			timeframe: null,
			timeframeFrom: null,
			timeframeUntil: null,
			scoreFrom: null,
			scoreUntil: null,
			backreadCards: [],
			byResponseCard: {},
			loadingCards: false,

			indTargets: {},
			indTarget: null,

			confirmingCard: null,
			lockBackreadTarget: false,
			backreadTargetPercent: null,
			client: null,

			selectedScoreType: null,
			selectedTraitAgreement: "",
			exported: null,

			users: [],
			selectedUser: null,
			belowBrTarget: null,
			traitScores: [],

			tabIndex: 0,
		};
	},

	watch: {
		selectedTeam() {
			this.getCards();
			this.getUsers();
		},

		timeframe() {
			this.setTimeFrameVals();
		},

		timeframeFrom() {
			if (!(this.section && this.item)) {
				return;
			}
			if (this.timeframe == "custom") {
				store.set(this, `${this.storeKey}.timeframeFrom`, this.timeframeFrom);
			}
			this.getCards();
		},

		timeframeUntil() {
			if (!(this.section && this.item)) {
				return;
			}
			if (this.timeframe == "custom") {
				store.set(this, `${this.storeKey}.timeframeUntil`, this.timeframeUntil);
			}
			this.getCards();
		},

		scoreFrom() {
			this.getCards();
			const { scoreFrom, storeKey } = this;
			store.set(this, `${storeKey}.scoreFrom`, scoreFrom);
		},

		scoreUntil() {
			this.getCards();
			const { scoreUntil, storeKey } = this;
			store.set(this, `${storeKey}.scoreUntil`, scoreUntil);
		},

		percent() {
			if (this.belowBrTarget !== null) {
				this.getCards();
			}
		},

		query() {
			this.getCards();
		},
	},

	computed: {
		query() {
			//used to getCards if any of these change
			const { belowBrTarget, selectedScoreType, selectedTraitAgreement, exported, selectedUser } = this;
			return `${belowBrTarget}-${selectedScoreType}-${selectedTraitAgreement}-${exported}-${
				selectedUser && selectedUser.id
			}`;
		},

		storeKey() {
			const { section, item } = this;
			if (!(section && item)) return "backreading.none.none";
			return `backreading.${section.id}.${item.id}`;
		},

		interval() {
			if (this.percent && this.percent > 0) {
				return Math.floor(100 / this.percent);
			} else {
				return 0;
			}
		},

		displayInterval() {
			switch (this.interval) {
				case 0:
					return "Random";
				case 1:
					return "All";
				default:
					return `1 ${this.$i18n.t("data_description.every")} ${this.interval}`;
			}
		},

		selectableScoreTypes() {
			const { FIRST, SECOND } = SCORE_TYPES;
			return [{ id: null, name: "　" }, FIRST, SECOND];
		},

		userOptions() {
			const { users } = this;
			return [{ scorer_id: "All", id: null }].concat(users);
		},
	},

	created() {
		this.storagePrefix = "backreading.";

		let teamCall = ConfigService.listTeams;
		if (this.user.role.limit_backreading) {
			teamCall = ConfigService.listUserTeams;
		}

		let sID = this.params.section_id;
		let iID = this.params.item_id;

		BB.props({
			section: SectionService.getSection(sID),
			item: ItemService.getItem(iID),
			targets: ScoringService.getIndTargets(sID, iID),
			teams: teamCall(),
			client: TenantService.getClient(),
		})
			.then((resps) => {
				this.section = resps.section.data;

				this.item = resps.item.data;

				this.teams = resps.teams.data.teams;
				if (this.user.role.limit_backreading) {
					this.teams = resps.teams.data;
				}
				this.selectedTeam = store.bindTeam(this, this.teams, true);

				this.client = resps.client.data;
				if (this.client.backreading_exclude_exported_resps) {
					this.exported = false;
				}

				this.getUsers();

				this.indTargets = {};
				_.each(resps.targets.data, (target) => {
					this.indTargets[target.user_id] = target.percent;
				});

				const { storeKey } = this;
				this.percent = store.bind(this, "percent", `${storeKey}.percent`, 10);
				this.belowBrTarget = store.bind(this, "belowBrTarget", `${storeKey}.belowBrTarget`, null);
				this.selectedScoreType = store.bind(this, "selectedScoreType", `${storeKey}.selectedScoreType`, null);
				this.selectedTraitAgreement = store.bind(
					this,
					"selectedTraitAgreement",
					`${storeKey}.selectedTraitAgreement`,
					""
				);
				this.exported = store.bind(this, "exported", `${storeKey}.exported`, null);
				this.traitScores = store.bind(this, "traitScores", `${storeKey}.traitScores`, []);

				this.timeframe = store.bind(this, "timeframe", `${storeKey}.timeframe`, PAST_WEEK.id);
				if (this.timeframe == "custom") {
					this.timeframeFrom = store.getDefault(this, `${storeKey}.timeframeFrom`, null);
					this.timeframeUntil = store.getDefault(this, `${storeKey}.timeframeUntil`, null);
				}
				this.scoreFrom = store.getDefault(this, `${storeKey}.scoreFrom`, null);
				this.scoreUntil = store.getDefault(this, `${storeKey}.scoreUntil`, null);

				this.setTimeFrameVals();

				this.getCards();

				this.lockBackreadTarget = this.section.project.backreading_target_lock;
				if (this.lockBackreadTarget) {
					this.backreadTargetPercent = this.section.project.backreading_target_percent;
					this.percent = this.backreadTargetPercent;
				}
			})
			.catch((err) => {
				console.log(err);
				notie.error("Failed to load config options", err);
				this.loading = false;
			});
	},

	methods: {
		async getCards() {
			const {
				loadingCards,
				section,
				item,
				selectedTeam,
				timeframeFrom,
				timeframeUntil,
				scoreFrom,
				scoreUntil,
				selectedUser,
				percent,
				belowBrTarget,
				selectedScoreType,
				exported,
				traitScores,
			} = this;
			if (loadingCards) {
				return;
			}
			this.loadingCards = true;
			try {
				const {
					data: { by_response_stat, user_stats },
				} = await ScoringService.getBackreadingStats(
					section.id,
					item.id,
					selectedTeam && [selectedTeam.id],
					timeframeFrom,
					timeframeUntil,
					scoreFrom,
					scoreUntil,
					selectedUser && selectedUser.id,
					percent,
					belowBrTarget,
					selectedScoreType,
					// selectedTraitAgreement,
					"", // TODO: make trait agreement performant
					exported,
					traitScores
				);
				this.loading = false;
				this.loadingCards = false;
				this.backreadCards = user_stats;
				this.byResponseCard = by_response_stat;
				this.backreadCards = _.sortBy(this.backreadCards, (card) => {
					if (card.scorer_id) {
						return card.scorer_id;
					} else {
						return card.last_name + " " + card.first_name;
					}
				});
			} catch (err) {
				this.loading = false;
				this.loadingCards = false;
				notie.error("Failed to load backreading stats", err);
			}
		},

		async getUsers() {
			const { selectedTeam, client } = this;
			try {
				if (selectedTeam) {
					const { data } = await TeamService.getTeamUsers(selectedTeam.id);
					this.users = data.users;
				} else {
					const { data } = await UserService.listUsers(client.id, {});
					this.users = data.users;
				}
			} catch (e) {
				notie.error("Failed to get users", e);
			}
		},

		newScoreFilters({ auditTraitScores }) {
			this.traitScores = auditTraitScores;
			this.getCards();
		},

		setIfLoaded(key, value) {
			if (!(this.section && this.item)) {
				return;
			}
			store.set(this, `${this.storeKey}.${key}`, value);
		},

		loadBackreadStats(card) {
			if (!(this.section && this.item)) {
				return;
			}

			BB.props({
				user: ReportingService.getBackreadAgreement(this.section.id, this.item.id, card.user_id, this.user.id),
				all: ReportingService.getBackreadAgreement(this.section.id, this.item.id, card.user_id),
			}).then((r) => {
				this.injectBackreadStats(card, r.user.data, r.all.data);
				this.$forceUpdate();
			});
		},

		injectBackreadStats(card, userData, allData) {
			let userStat = null;
			if (userData && userData.stats && userData.stats[0]) {
				userStat = userData.stats[0];
			}

			let allStat = null;
			if (allData && allData.stats && allData.stats[0]) {
				allStat = allData.stats[0];
			}

			let el = document.querySelector(`[data-template-id="#stat-tooltip-${card.user_id}"] .tippy-content`);
			if (!el) {
				console.error(`Failed to tooltip "#stat-tooltip-${card.user_id}"`);
				return;
			}

			el.innerHTML = `
<table>
	<tr>
		<th colspan="2">${card.scorer_id}'s agreement vs. your backreads</th>
	</tr>
		<td>Exact</td>
		<td>${this.formatBackreadStat(userStat, "exact")}</td>
	</tr>
	<tr>
		<td>Exact/Adjacent</td>
		<td>${this.formatBackreadStat(userStat, "exact_adj")}</td>
	</tr>
	<tr>
		<th colspan="2">${card.scorer_id}'s agreement vs. all backreads</th>
	</tr>
	<tr>
		<td>Exact</td>
		<td>${this.formatBackreadStat(allStat, "exact")}</td>
	</tr>
	<tr>
		<td>Exact/Adjacent</td>
		<td>${this.formatBackreadStat(allStat, "exact_adj")}</td>
	</tr>
</table>
`;
		},

		formatBackreadStat(stat, adj) {
			if (!stat) {
				return `<span class="text-muted">(None)</span>`;
			}

			if (adj == "exact") {
				let percent = (100 * stat.irr_exact) / stat.irr_total;
				return fs.fixedPercent1d(percent);
			}
			if (adj == "exact_adj") {
				let percent = (100 * stat.irr_exact_adj) / stat.irr_total;
				return fs.fixedPercent1d(percent);
			}
		},

		colorPercent(card) {
			let p = (card.backread * 100) / card.scored;
			let tp = this.getCardTarget(card);
			let classes = {
				"text-success": p >= tp,
				"text-warning": p < tp && p >= tp / 2,
				"text-danger": p < tp / 2,
			};
			return classes;
		},

		enterBackreading(sectionID, itemID, card) {
			const percent = this.getCardTarget(card);
			const ctx = this.makeParams(card.user_id, card.scored_since, percent, false);

			if (ctx.skip_interval === 0 || ctx.skip_interval === "random") {
				driver
					.getNext(ctx)
					.then((resp) => {
						console.log(resp);
						this.confirmingCard = card;
					})
					.catch((err) => {
						console.log(err);
						console.log("ERROR1");
						notie.error("Cannot enter backreading", err);
					});
			} else {
				driver
					.getNext(ctx)
					.then((resp) => {
						console.log(resp);

						this.$router.push({
							path: `/qbackread/${sectionID}/${itemID}/${card.user_id}/${ctx.skip_interval}`,
							query: ctx,
						});
					})
					.catch((err) => {
						console.log(err);
						if (err.response.status == 404) {
							driver
								.getNext(ctx)
								.then((resp) => {
									console.log(resp);
									this.confirmingCard = card;
								})
								.catch((err) => {
									console.log(err);
									console.log("ERROR2");
									notie.error("Cannot enter backreading", err);
								});
						} else {
							console.log("ERROR3");
							notie.error("Cannot enter backreading", err);
						}
					});
			}
		},

		enterRandomBackreading(sectionID, itemID, card) {
			this.$router.push({
				path: `/qbackread/${sectionID}/${itemID}/${card.user_id}/random`,
				query: this.makeParams(card.user_id, card.scored_since, this.getCardTarget(card), true),
			});
		},

		enterResponseBacking() {
			const ctx = this.makeParams(null, this.byResponseCard.scored_since, this.percent, false);
			if (ctx.skip_interval === "random") {
				this.confirmingCard = this.byResponseCard;
			} else {
				const { section_id, item_id } = ctx;
				this.$router.push({
					path: `/qbackread/${section_id}/${item_id}/no_user/${ctx.skip_interval}`,
					query: ctx,
				});
			}
		},

		makeParams(userID, seqScores, target, isRandom) {
			const {
				section,
				item,
				selectedUser,
				selectedTeam,
				timeframeFrom,
				timeframeUntil,
				scoreFrom,
				scoreUntil,
				belowBrTarget,
				exported,
				selectedScoreType,
				selectedTraitAgreement,
				traitScores,
			} = this;

			let skip_interval = "random";
			if (!isRandom && target && target > 0) {
				skip_interval = Math.floor(100 / target);
				if (skip_interval > seqScores) {
					skip_interval = "random";
				}
			}

			return {
				section_id: section.id,
				item_id: item.id,
				team_ids: selectedTeam ? [selectedTeam.id] : [],
				skip_interval,
				from: timeframeFrom,
				until: timeframeUntil,
				score_from: scoreFrom,
				score_until: scoreUntil,
				user_id: userID || (selectedUser && selectedUser.id),
				target,
				below_target: belowBrTarget,
				score_type: selectedScoreType,
				trait_agreement: "", // TODO: make performant
				exported,
				trait_scores: traitScores,
			};
		},

		targetPercentTooltip(user) {
			return `<div>Backread % target</div><div class="text-muted text-xs">Click to set an individual target</div>`;
		},

		hasIndTarget(card) {
			let indTarget = this.indTargets[card.user_id];
			if (indTarget == undefined) {
				return false;
			} else {
				return true;
			}
		},

		getCardTarget(card) {
			const { indTargets, percent } = this;
			return Number(indTargets[card.user_id] || percent);
		},

		prepIndDropdown(card) {
			this.indTarget = this.getCardTarget(card);
		},

		saveIndTarget(card) {
			let sID = this.params.section_id;
			let iID = this.params.item_id;
			let uID = card.user_id;
			ScoringService.setIndTarget(sID, iID, uID, this.indTarget)
				.then((resp) => {
					this.indTargets[uID] = this.indTarget;
					this.$forceUpdate();
				})
				.catch((e) => {
					console.log(e);
					notie.error("Failed to update individual backreading target");
				});
		},

		setTimeFrameVals() {
			switch (this.timeframe) {
				case (null, "all_time"):
					this.timeframeFrom = null;
					this.timeframeUntil = null;
					break;
				case "week":
					this.timeframeFrom = moment().subtract(1, "weeks").startOf("day").toISOString();
					this.timeframeUntil = moment().endOf("day").toISOString();
					break;
				case "month":
					this.timeframeFrom = moment().subtract(1, "months").startOf("day").toISOString();
					this.timeframeUntil = moment().endOf("day").toISOString();
					break;
				case "3months":
					this.timeframeFrom = moment().subtract(3, "months").startOf("day").toISOString();
					this.timeframeUntil = moment().endOf("day").toISOString();
					break;
				case "year":
					this.timeframeFrom = moment().subtract(1, "years").startOf("day").toISOString();
					this.timeframeUntil = moment().endOf("day").toISOString();
					break;
			}

			this.setIfLoaded("timeframe", this.timeframe);
			this.setIfLoaded("timeframeFrom", this.timeframeFrom);
			this.setIfLoaded("timeframeUntil", this.timeframeUntil);
		},
	},
};
</script>
