import _ from "lodash";

function addClientIDToKey(vue, key) {
	let clientID = "MISSING";
	const { user } = vue.$attrs;
	if (user && user.client) {
		clientID = user.client.id;
	}
	return `${clientID}.${key}`;
}

/**
 * Selection Service
 *
 * Utility functions for resolving selections with local storage values.
 */
export default {
	//Wrappers for getting and setting from $ls since it doesn't handle raw JSON objects
	set(vue, key, value) {
		vue.$ls.set(addClientIDToKey(vue, key), JSON.stringify(value));
	},

	get(vue, key) {
		const val = vue.$ls.get(addClientIDToKey(vue, key));
		return val != "undefined" && JSON.parse(val);
	},

	getDefault(vue, key, defaultVal) {
		let val = this.get(vue, key);
		if (val == null || val == undefined) {
			return defaultVal;
		} else {
			return val;
		}
	},

	//2-way bind a value
	//Equivalent to calling 'getDefault' on the key and also setting a watch to 'set' it on change
	bind(vue, localKey, storeKey, defaultVal) {
		if (!storeKey) {
			vue.storagePrefix = vue.storagePrefix || "";
			storeKey = vue.storagePrefix + localKey;
		}

		vue.$watch(localKey, () => {
			this.set(vue, storeKey, vue[localKey]);
		});
		return this.getDefault(vue, storeKey, defaultVal);
	},

	//Resolve a value from a given list
	//Param 'compBy' determines the field in the list element that needs to match the stored element
	//Param 'defaultIndex' determines what element of the list is returned if there's no store value
	resolveFromList(vue, list, compBy, defaultIndex, key) {
		var stored = this.get(vue, key);
		var resolved;
		if (stored != null) {
			resolved = _.find(list, (el) => {
				if (key == "audit.r.selectedFlagType" || key == "audit.r.selectedFlagReviewType") {
					return el[compBy] == stored.id;
				} else {
					return el[compBy] == stored;
				}
			});
		}

		if (resolved) {
			return resolved;
		} else if (defaultIndex != null && list) {
			return list[defaultIndex];
		} else {
			return null;
		}
	},

	//2-way bind a value from an input list
	//Equivalent to calling 'resolveFromList' and setting a watch
	bindFromList(vue, list, compBy, defaultIndex, localKey, storeKey) {
		if (!storeKey) {
			vue.storagePrefix = vue.storagePrefix || "";
			storeKey = vue.storagePrefix + localKey;
		}

		vue.$watch(
			localKey,
			() => {
				if (vue[localKey] == null) {
					this.set(vue, storeKey, vue[localKey]);
				} else {
					this.set(vue, storeKey, vue[localKey][compBy]);
				}
			},
			{ deep: true }
		);
		return this.resolveFromList(vue, list, compBy, defaultIndex, storeKey);
	},

	bindProject(vue, projects, key = "selectedProject") {
		return this.bindFromList(vue, projects, "id", 0, key);
	},

	//Returns a selected section based off of local storage
	resolveSelectedSection(vue, sections, key = "selectedSection") {
		return this.resolveFromList(vue, sections, "id", 0, key);
	},

	resolveSelectedProject(vue, projects, key = "selectedProject") {
		return this.resolveFromList(vue, projects, "id", 0, key);
	},

	bindSection(vue, sections, key = "selectedSection") {
		return this.bindFromList(vue, sections, "id", 0, key);
	},

	resolveSelectedItem(vue, section, key = "selectedItem") {
		return this.resolveFromList(vue, section && section.items, "id", 0, key);
	},

	bindItem(vue, section, key = "selectedItem") {
		return this.bindFromList(vue, section && section.items, "id", 0, key);
	},

	bindTeam(vue, teams, canChooseAny, key = "selectedTeam") {
		let def = canChooseAny ? null : 0;
		return this.bindFromList(vue, teams, "id", def, key);
	},

	bindTrait(vue, item, key = "selectedTrait") {
		return this.bindFromList(vue, item && item.rubric && item.rubric.traits, "id", 0, key);
	},

	resolveSelectedRespState(vue, states, key = "selectedState") {
		return this.resolveFromList(vue, states, "value", 0, key);
	},

	resolveAuditingTraitScores(vue, item, key = "audit_trait_scores") {
		var stored = this.get(vue, key);
		if (!stored) {
			return [];
		}
		var ok = true;
		_.each(stored, (s) => {
			var found = false;
			_.each(item.rubric.traits, (t) => {
				if (t.id == s.trait_id) {
					found = true;
				}
			});
			if (!found) {
				ok = false;
			}
		});
		return ok ? stored : [];
	},

	resolveSelectedScoreTypes(vue, types, key = "filter_types") {
		var stored = this.get(vue, key);
		if (!stored) {
			return [];
		}
		var result = [];
		_.each(types, (t) => {
			_.each(stored, (s) => {
				if (s.type == t.type) {
					result.push(t);
				}
			});
		});
		return result;
	},
};
