<template>
	<div class="flex w-100" id="exemplar-container">
		<div v-for="(response, i) in set.responses" :key="i" class="printable-page">
			<!-- Title bar -->
			<div v-if="i == 0" class="pl-2 pr-3 limit-width center-content print-header">
				<div class="mb-0 box card py-2 px-3 box-shadow theme w-100 d-flex flex-row align-items-center">
					<div>
						<h3 class="flex _600">{{ set.name }}</h3>
						<h5 class="text-muted mb-0">
							{{ section.name }} • {{ item.name }} • {{ fs.qcType(set.qc_type) }}
						</h5>
					</div>
					<i class="text-muted far fa-balance-scale fa-3x ml-auto" />
				</div>
			</div>

			<!-- Title bar -->
			<div class="pl-2 pr-3 limit-width center-content">
				<div class="mb-0 box card py-2 px-3 box-shadow d-flex flex-row" style="width: 776px">
					<span class="flex _600">{{ $t("fields.response") }} {{ i + 1 }}</span>
					<span class="text-muted">{{ response.ref_id }}</span>
				</div>
			</div>

			<!-- Content with response -->
			<div class="limit-width center-content mt-0 mb-3">
				<div class="d-flex flex-row justify-content-center limit-width pl-2 pr-3 mt-3">
					<div class="flex box-shadow card">
						<template v-if="response.feedback">
							<div class="box-header b-b _600 py-2">
								<span>{{ $t("ExemplarSet.annotation") }}</span>
							</div>
							<div class="box-body" v-html="response.feedback"></div>
						</template>
						<template v-else>
							<div class="text-center text-muted m-3">
								{{ $t("ExemplarSet.no_final_annotation") }}
							</div>
						</template>
					</div>
					<div style="flex-basis: 33%" class="ml-3">
						<score
							v-if="response.true_score"
							class="mb-0 box-shadow b-l b-r b-b"
							style="border-radius: 0.25rem"
							:score="response.true_score"
							:rubric="item.rubric"
							:type="20"
							hideTime
							fullWidth
						></score>

						<div v-else class="flex box-shadow card">
							<div class="text-center text-muted my-3">
								{{ $t("ExemplarSet.no_resolved_score") }}
							</div>
						</div>
					</div>
				</div>

				<media
					:response="response"
					:item="item"
					noMargin
					noChan
					forPrinting
					class="flex pb-2 mb-3 pr-3 scroll-x"
				></media>
			</div>
		</div>
	</div>
</template>

<style scoped>
.box-header {
	padding: 0.6rem 0.75rem !important;
}

.set-nav-footer {
	margin-left: -10px;
	/* margin-right: -10px; */
	background-color: rgb(231, 231, 231);
}
.limit-width {
	max-width: 800px;
}
.limit-width-more {
	width: 100%;
	max-width: 800px;
	padding-left: 16px;
	padding-right: 16px;
}
.center-content {
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
}
.essay {
	margin-bottom: 20px !important;
}
.box-shadow {
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
}

.scp-full-page {
	width: 990px;
	height: 1283px;
	page-break-after: always;
}

@media print {
	body,
	html {
		width: 100%;
	}
}
</style>

<script>
import Media from "@/components/viewer/Media";
import Score from "@/components/Score";
import fs from "@/services/FormatService";

export default {
	name: "QCSetDocument",
	props: ["set", "item", "section"],

	data() {
		return {
			fs: fs,
		};
	},
	components: { Media, Score },
	created() {},
	watch: {},
	computed: {},
	methods: {},
};
</script>
