<template>
	<div class="text-center my-border">
		<h3 :class="overallTheme" class="pt-2">{{ $t("fields.overall") }}: {{ overallName }}</h3>
		<b-container fluid>
			<b-row v-for="(rule, i) in set.calibration_rules" :key="`${set.id}_${i}`">
				<b-col class="my-border py-2"
					><h5>{{ ruleDisplay(rule) }}</h5></b-col
				>
				<b-col class="my-border py-2" :class="rulePassedTheme(rule)"
					><h5>{{ rulePassedText(rule) }}</h5></b-col
				>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import fs from "@/services/FormatService";
import { idToName, QC_STATES } from "@/services/Constants";

export default {
	name: "CalibrationSetRules",

	props: {
		set: Object,
		stat: Object,
	},

	computed: {
		overallTheme() {
			const { MISSING, SCORING, PASSED, FAILED } = QC_STATES;
			switch (this.stat.state) {
				case MISSING.id:
				case SCORING.id:
					return "text-warning";
				case PASSED.id:
					return "text-success";
				case FAILED.id:
					return "text-danger";
			}
			return "";
		},

		overallName() {
			return idToName(QC_STATES, this.stat.state);
		},
	},

	methods: {
		ruleDisplay(rule) {
			return fs.getQcRuleText(rule);
		},

		rulePassedText(rule) {
			return this.rulePassed(rule) ? this.$i18n.t("QCStates.passed") : this.$i18n.t("QCStates.failed");
		},

		rulePassedTheme(rule) {
			return this.rulePassed(rule) ? "text-success" : "text-danger";
		},

		rulePassed(rule) {
			console.log(this.stat.explanations);
			return (this.stat.explanations || []).filter((exp) => exp.rule === rule.rule).every((exp) => exp.passed);
		},
	},
};
</script>

<style>
.my-border {
	border-top-style: solid;
	border-width: 1px;
	border-color: rgba(120, 130, 140, 0.2);
}
</style>