<template>
	<div>
		<template v-if="!testSucceeded && !testError && !testRunning">
			<button class="ml-auto btn btn-primary" @click="testIeaIntegration">{{ label }}</button>
		</template>
		<template v-if="testRunning">
			<button class="ml-auto btn btn-primary" disabled><loading type="icon" class="mr-2" />{{ label }}</button>
		</template>
		<template v-if="!testRunning && testSucceeded">
			<button class="ml-auto btn btn-success" @click="testIeaIntegration">
				<i class="fas fa-check mr-2" />{{ label }}
			</button>
			<div class="text-success">{{ successText }}</div>
		</template>
		<template v-if="!testRunning && !testSucceeded && testError">
			<button class="ml-auto btn btn-danger" @click="testIeaIntegration">
				<i class="fas fa-times mr-2" />{{ label }}
			</button>
			<button class="ml-2 btn btn-warning" @click="reinit" :disabled="reinitRunning">
				<i class="fas fa-sync mr-2" /> <loading v-if="reinitRunning" type="icon" class="mr-2" />Re-Init
			</button>
			<div class="text-danger">{{ testError }}</div>
			<div v-if="reinitResult">{{ reinitResult }}</div>
		</template>
	</div>
</template>

<script>
import ItemService from "@/services/ItemService";
import Notie from "@/services/NotieService";

export default {
	name: "TestIeaItemButton",

	props: {
		client: true,
		item: true,
		adminName: true,
		label: { type: String, default: "Test IEA" },
		successText: { type: String, default: "IEA Item is properly initialized" },
	},

	data() {
		return {
			testRunning: false,
			testSucceeded: false,
			testError: null,

			reinitRunning: false,
			reinitResult: null,
		};
	},

	computed: {},

	watch: {},

	methods: {
		testIeaIntegration() {
			this.testRunning = true;
			ItemService.getIeaItem(this.item.id, this.adminName)
				.then((r) => {
					this.testSucceeded = true;
				})
				.catch((e) => {
					this.testSucceeded = false;
					this.testError = Notie.extractErrorMessage(e);
				})
				.finally(() => {
					this.testRunning = false;
				});
		},

		reinit() {
			ItemService.reinitIeaItem(this.item.id, this.adminName)
				.then((r) => {
					this.reinitResult = "Re-initialization attempt started (may take 1-2 minutes to take effect)";
				})
				.catch((e) => {
					this.reinitResult = Notie.extractErrorMessage(e);
				});
		},
	},
};
</script>

<style scoped></style>
