<template>
	<div class="position-relative">
		<input
			type="text"
			:value="copied ? 'Copied!' : value"
			ref="input"
			:disabled="disabled"
			:readonly="readonly"
			class="form-control clip-input"
			:class="{ 'is-valid': copied }"
		/>
		<button class="btn btn-sm btn-overlay" :class="{ 'btn-primary': !copied, 'btn-success': copied }" @click="copy">
			<i v-if="!copied" class="fas fa-copy" /><i v-else class="fas fa-check" />
		</button>
	</div>
</template>

<style scoped>
input {
	padding-right: 40px;
}
.btn-overlay {
	z-index: 2;
	position: absolute;
	top: 4px;
	right: 4px;
	height: 28px;
	line-height: 16px;
}
</style>

<script>
export default {
	name: "PercentInput",
	props: ["value", "disabled", "readonly"],

	data() {
		return {
			copied: false,
		};
	},
	created() {},
	watch: {},
	methods: {
		copy() {
			this.log("Copy link")
			let el = this.$refs.input
			el.select();
  			el.setSelectionRange(0, 99999);
			navigator.clipboard.writeText(el.value);

			this.copied = true;
			setTimeout(() => {
				this.copied = false;
				el.blur();
			}, 2000);
			el.focus();
		},
	},
};
</script>
