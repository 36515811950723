<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportPayroll.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportPayroll.payroll") }}
				</div>
			</div>
		</template>
		<div class="d-flex flex-column flex scroll-x scroll-y">
			<div class="row mx-0 px-3 pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportPayroll.project") }}</label>
						<config-select :options="projects" v-model="selectedProject"></config-select>
					</div>
				</div>
				<div v-if="!pearson_comp" class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportPayroll.team") }}</label>
						<config-select :nullOption="true" :options="teams" v-model="selectedTeam"></config-select>
					</div>
				</div>
				<div v-if="pearson_comp" class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportPayroll.section") }}</label>
						<config-select
						    :nullOption="anyItems"
							:options="sections"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div v-if="pearson_comp" class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportPayroll.item") }}</label>
						<config-select
						    :nullOption="anyItems"
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
							sortBy="section_item_sequence"
						></config-select>
					</div>
				</div>
				<div v-if="!pearson_comp" class="col-12 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportPayroll.show_rows") }}</label>
						<config-select :options="displayTypes" v-model="displayType"></config-select>
					</div>
				</div>
				<div v-if="pearson_comp" class="col-12 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportPayroll.scorer_id") }}</label>
						<config-user-search
							:clientID="user.client && user.client.id"
							v-model="selectedUser"
							:placeholder="$t('data_description.any')"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("ReportPayroll.from_date") }}
							<user-timezone />
						</label>
						<config-date v-model="fromDate" :config="{ showClear: true }"></config-date>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("ReportPayroll.to_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="toDate"
							:config="{ showClear: true }"
							:defaultTime="$t('ReportPayroll.default_time')"
						></config-date>
					</div>
				</div>
				<div v-if="pearson_comp" class="col-12 col-sm-6 col-lg-4">
				</div>
				<div v-if="pearson_comp" class="col-12 col-sm-6 col-lg-4">
				</div>
				<div class="col-12 col-lg-4 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-center form-group">
						<button
							v-if="reportStats && reportStats.length > 0"
							class="btn btn-sm btn-icon btn-rounded theme-accent mx-1"
							style="padding-top: 10px"
							v-tippy
							:title="$t('tooltip.export')"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							<i class="fas fa-file-export" style="margin-left: 2px"></i>
						</button>
						<div v-if="reportStats && reportStats.length > 0" class="dropdown-menu dropdown-menu-right w">
							<a v-if="!pearson_comp" class="dropdown-item" @click="dlPdf()">
								<i class="far fa-file-pdf fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportPayroll.export_to_pdf") }}</label>
							</a>
							<a class="dropdown-item" @click="dlCsv()">
								<i class="far fa-file-excel fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportPayroll.export_to_csv") }}</label>
							</a>
						</div>
						<div class="py-0 ml-1">
							<button
								@click="getStats"
								class="btn btn-sm theme-accent btn-block flex-fixed"
								v-b-tooltip
								:title="$t('tooltip.run_report')"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline">&nbsp;{{ $t("ReportPayroll.run_report") }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="reportStats && reportStats.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div v-if="!pearson_comp" class="flex">
					<div class="card border-0">
						<report-table
							class="condensed-table mb-0"
							perPage="15"
							:fields="fields"
							:totals="reportTotals"
							:content="reportStats"
							totalsClass="theme-lighten-3 text-on-color"
						>
							<template #cell(first_name)="data">
								<span v-if="data.item.user">{{ data.item.user.first_name }}</span>
							</template>
							<template #cell(last_name)="data">
								<span v-if="data.item.user">{{ data.item.user.last_name }}</span>
							</template>
							<template #cell(scorer_id)="data">
								<span v-if="data.item.user">{{ data.item.user.scorer_id }}</span>
							</template>
							<template #cell(total_time)="data">{{ fs.medDuration(data.item.total_time) }}</template>
							<template #cell(flag_count)="data">
								{{ data.item.flag_unscored_count + data.item.flag_scored_count }}
								<span
									v-if="data.item.flag_scored_count > 0"
									class="text-xxs text-muted"
									v-tippy
									:title="$t('flagged_with_score')"
									>({{ data.item.flag_scored_count }})</span
								>
							</template>
						</report-table>
					</div>
				</div>
				<div v-if="pearson_comp" class="flex">
					<div class="card border-0">
						<report-table
							class="condensed-table mb-0"
							perPage="15"
							:fields="fields"
							:totals="reportTotals"
							:content="reportStats"
							totalsClass="theme-lighten-3 text-on-color"
						>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="reportStats && reportStats.length == 0" class="d-flex flex-row justify-content-center">
				<h3 class="text-muted">{{ $t("ReportPayroll.report_returned_no_results") }}</h3>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-long {
	display: none;
}

.d-abr {
	display: table-cell;
}

.spread-bg {
	background-color: rgb(245 245 245 / 100%);
}

.spread-host {
	width: 100%;
	height: 800px;
}

.spread-container {
	position: relative;
	margin-top: 16px;
	margin-bottom: 16px;
	margin-left: 16px;
	margin-right: 16px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
}

@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}

	.d-abr {
		display: none;
	}
}

.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ConfigService from "@/services/ConfigService";
import TenantService from "@/services/TenantService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import fs from "@/services/FormatService";
import FileService from "@/services/FileService";
import moment from "moment";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import ReportTable from "@/components/ReportTable";
import UserTimezone from "@/components/UserTimezone";
import ConfigUserSearch from "@/components/ConfigUserSearch";

export default {
	name: "ReportPayroll",

	props: ["user"],

	components: { ConfigDate, ConfigSelect, ReportTable, UserTimezone, ConfigUserSearch },

	data() {
		return {
			fs: fs,
			scorerStats: null,
			totalsRow: null,

			fields: [],
			reportTotals: null,
			reportStats: null,

			projects: [],
			selectedProject: null,

			teams: [],
			selectedTeam: null,
			selectedUser: null,

			displayTypes: [
				{
					name: this.$i18n.t("ReportPayroll.by_user_section_item"),
					id: 1,
				},
				{
					name: this.$i18n.t("ReportPayroll.by_user"),
					id: 2,
				},
			],
			displayType: null,

			fromDate: null,
			toDate: null,

			client: null,
			running: false,
			loading: true,
			pearson_comp: false,

			sections: [],
			selectedSection: null,
			items: [],
			selectedItem: null,
		};
	},

	created() {
		this.storagePrefix = "payroll.";
		//this.selectedUser = store.bind(this, "selectedUser");
		this.displayType = store.bindFromList(this, this.displayTypes, "id", 0, "displayType");
		this.fromDate = store.bind(this, "fromDate");
		this.toDate = store.bind(this, "toDate");

		let teamCall = ConfigService.listTeams;
		if (this.user.role.limit_teams) {
			teamCall = ConfigService.listUserTeams;
		}

		Promise.all([ConfigService.listProjectsShallowPreloaded(), teamCall(), TenantService.getClient()])
			.then((resps) => {
				console.log(resps);
				//resps[0] - projects
				this.projects = resps[0].data;
				this.selectedProject = store.bindProject(this, this.projects);

				if (this.selectedProject) {
					this.sections = this.selectedProject.sections;
					this.selectedSection = store.bindSection(this, this.sections);
					this.selectedItem = store.bindItem(this, this.selectedSection);
				}
				//resps[1] - teams
				this.teams = resps[1].data.teams;
				if (this.user.role.limit_teams) {
					this.teams = resps[1].data;
				}
				this.selectedTeam = store.bindTeam(this, this.teams, true);

				//resps[2] - client
				this.client = resps[2].data;
				this.loading = false;
				this.pearson_comp = this.client.pearson_comp;
			})
			.catch((err) => {
				console.log(err);
				notie.error(this.$i18n.t("notie.load_config_options_fail"), err);
				this.loading = false;
			});
	},

	computed: {
		sumPerUser() {
			return this.displayType && this.displayType.id == 2;
		},
	},

	watch: {
		selectedProject() {
			if (this.selectedProject && this.selectedProject.sections) {
				this.sections = this.selectedProject.sections;
			} else {
				this.sections = [];
			}
		},
		selectedSection() {
			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
			} else {
				this.items = [];
			}
		},
	},

	methods: {
		anyItems() {
			let hasItems = false;
			if (this.selectedProject && this.selectedProject.sections) {
				_.each(this.selectedProject.sections, (section) => {
					if (section.items && section.items.length > 0) {
						hasItems = true;
					}
				});
			}
			return hasItems;
		},

		pcr(num, den) {
			if (den == 0) {
				return "-";
			}
			if (num == 0) {
				return "0.00";
			}

			var res = (num / den) * 100;
			return res.toFixed(2);
		},

		async getStats() {
			if (this.running) {
				console.log("HEY");
				return;
			}
			var proj = this.selectedProject;
			var team = this.selectedTeam;
			var sec = this.selectedSection;
			var item = this.selectedItem;
			var fDate = this.fromDate;
			var tDate = this.toDate;
			var userSearch = this.userSearch;
			var selectedUser = this.selectedUser;

			var ctx = {};

			if (userSearch) {
				ctx.user_search = userSearch;
			}

			if (selectedUser && this.pearson_comp) {
				ctx.user_id = selectedUser.scorer_id;
			}

			if (proj) {
				ctx.project_id = proj.id;
			}

			if (sec && this.pearson_comp) {
				ctx.section_id = sec.id;
			}

			if (item && this.pearson_comp) {
				ctx.item_id = item.id;
			}

			if (fDate && fDate != "") {
				ctx.start_date = fDate;
			}
			if (tDate && tDate != "") {
				ctx.end_date = tDate;
			}

			if (team) {
				ctx.team_id = team.id;
			}

			this.running = true;
			ReportingService.getPayrollStats(ctx)
				.then((resp) => {
					console.log(resp);
					this.running = false;

					this.scorerStats = resp.data;

					if (this.pearson_comp) {
						this.pearsonCompConstructFields();
						this.pearsonCompCompileResults(this.scorerStats);
					} else {
						this.constructFields();
						this.compileResults(this.scorerStats);
					}
				})
				.catch((err) => {
					this.running = false;
					console.log(err);
					notie.error(this.$i18n.t("notie.load_stats_fail"), err);
				});
		},

		compileResults(stats) {
			function emptyRow() {
				return {
					total: 0,
					val_count: 0,
					cal_count: 0,
					res_count: 0,
					br_count: 0,
					flag_unscored_count: 0,
					flag_scored_count: 0,
					sum: 0,
					total_time: 0,
					total_system_time: 0,
				};
			}

			_.each(stats, (stat) => {
				stat.total = stat.total - stat.res_count;
				stat.total = stat.total - stat.br_count;
				stat.sum =
					stat.val_count +
					stat.cal_count +
					stat.res_count +
					stat.br_count +
					stat.total +
					stat.flag_unscored_count;
			});

			let totals = emptyRow();
			let userRowMap = {};
			_.each(stats, (stat) => {
				//Convert total time to millis
				stat.total_system_time = this.getTimeMillis(stat.total_system_time);

				totals.total += stat.total;
				totals.val_count += stat.val_count;
				totals.cal_count += stat.cal_count;
				totals.res_count += stat.res_count;
				totals.br_count += stat.br_count;
				totals.flag_scored_count += stat.flag_scored_count;
				totals.flag_unscored_count += stat.flag_unscored_count;
				totals.sum += stat.sum;
				totals.total_time += stat.total_time;
				totals.total_system_time += stat.total_system_time;

				if (this.sumPerUser) {
					let userRow = userRowMap[stat.user.id];
					if (!userRow) {
						userRow = emptyRow();
						userRow.user = stat.user;
						userRow.role_name = stat.role_name;
					}

					userRow.total += stat.total;
					userRow.val_count += stat.val_count;
					userRow.cal_count += stat.cal_count;
					userRow.res_count += stat.res_count;
					userRow.br_count += stat.br_count;
					userRow.flag_scored_count += stat.flag_scored_count;
					userRow.flag_unscored_count += stat.flag_unscored_count;
					userRow.sum += stat.sum;
					userRow.total_time += stat.total_time;
					userRow.total_system_time += stat.total_system_time;

					userRowMap[stat.user.id] = userRow;
				}
			});
			totals.total_system_time = this.getTimeMillis(totals.total_system_time);
			totals.avg_time = totals.total_time / (totals.total + totals.res_count + totals.br_count);
			totals.user = {
				first_name: `${this.$i18n.t("fields.totals")} (${stats ? stats.length : 0})`,
			};

			if (this.sumPerUser) {
				let userRows = [];
				_.each(userRowMap, (userRow) => {
					userRow.total_system_time = this.getTimeMillis(userRow.total_system_time);
					userRow.avg_time = userRow.total_time / (userRow.total + userRow.res_count + userRow.br_count);
					userRows.push(userRow);
				});

				this.reportStats = _.orderBy(
					userRows,
					["user.last_name", "user.first_name", "user.scorer_id"],
					["asc", "asc", "asc"]
				);
			} else {
				this.reportStats = _.orderBy(
					stats,
					["user.last_name", "user.first_name", "user.scorer_id", "section_name", "item_name"],
					["asc", "asc", "asc", "asc", "asc"]
				);
			}

			this.reportTotals = totals;
		},

		pearsonCompCompileResults(stats) {
			this.reportStats = stats;
		},
		pearsonCompConstructFields() {
			let fields = [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
				{
					key: "EmployeeNumber",
					label: this.$i18n.t("ReportPayroll.scorer_id"),
					tdClass: "",
					thClass: "",
				},
				{
					key: "EmployeeRole",
					label: this.$i18n.t("ReportPayroll.role"),
					tdClass: "",
					thClass: "",
				},
				{
					key: "ItemID",
					label: this.$i18n.t("ReportPayroll.item"),
					tdClass: "table-border-right",
					thClass: "table-border-right",
				},
			];

			fields = fields.concat([
				{
					key: "ScoringTimeOnItem",
					label: this.$i18n.t("ReportPayroll.scoring_time"),
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "TrainingTimeOnItem",
					label: this.$i18n.t("ReportPayroll.training_time"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "OperationalReads",
					label: this.$i18n.t("ReportPayroll.scores"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "ReviewUnits",
					label: this.$i18n.t("ReportPayroll.review"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "ResolutionUnits",
					label: this.$i18n.t("ReportPayroll.resolution"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "AdjudicationUnits",
					label: this.$i18n.t("ReportPayroll.adjudication"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "BackreadUnits",
					label: this.$i18n.t("ReportPayroll.backread"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "PracticeReads",
					label: this.$i18n.t("ReportPayroll.practice"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "QualificationReads",
					label: this.$i18n.t("ReportPayroll.qualification"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "ValidityReads",
					label: this.$i18n.t("ReportPayroll.validity"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "Validity",
					label: this.$i18n.t("ReportPayroll.validity_percent"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "CumulativeValidityAgreementPercent",
					label: this.$i18n.t("ReportPayroll.cumulative_validity_percent"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "IrrPerfectAgreement",
					label: this.$i18n.t("ReportPayroll.irr_exact"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "IrrAdjacentAgreement",
					label: this.$i18n.t("ReportPayroll.irr_adjacent"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
			]);

			this.fields = fields;
		},

		constructFields() {
			let fields = [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
				{
					key: "first_name",
					label: this.$i18n.t("fields.first"),
					tdClass: "",
					thClass: "",
				},
				{
					key: "last_name",
					label: this.$i18n.t("fields.last"),
					tdClass: "",
					thClass: "",
				},
				{
					key: "scorer_id",
					label: this.$i18n.t("fields.id"),
					tdClass: "",
					thClass: "",
				},
				{
					key: "role_name",
					label: this.$i18n.t("fields.role"),
					tdClass: "table-border-right",
					thClass: "table-border-right",
				},
			];

			if (!this.sumPerUser) {
				fields = fields.concat([
					{
						key: "section_name",
						label: this.$i18n.t("fields.section"),
						tdClass: "",
						thClass: "",
					},
					{
						key: "item_name",
						label: this.$i18n.t("fields.item"),
						tdClass: "table-border-right",
						thClass: "table-border-right",
					},
				]);
			}

			fields = fields.concat([
				{
					key: "total_time",
					label: this.$i18n.t("fields.score_time"),
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "total",
					label: this.$i18n.t("fields.scores"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "res_count",
					label: this.$i18n.t("fields.resolution"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "br_count",
					label: this.$i18n.t("fields.backread"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "val_count",
					label: this.$i18n.t("fields.validity"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "cal_count",
					label: this.$i18n.t("fields.calibration"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "flag_count",
					label: this.$i18n.t("fields.flags"),
					tdClass: "text-right",
					thClass: "text-center",
				},
				{
					key: "sum",
					label: this.$i18n.t("fields.total"),
					tdClass: "text-right table-border-left",
					thClass: "text-center table-border-left",
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
			]);

			this.fields = fields;
		},

		fixed(time) {
			if (time == Number.POSITIVE_INFINITY || isNaN(time)) {
				return "——";
			} else {
				return time.toFixed(1);
			}
		},

		fixedPercent(percent) {
			if (percent == Number.POSITIVE_INFINITY || isNaN(percent)) {
				return "——";
			} else {
				return `${percent.toFixed(1)}%`;
			}
		},

		getTime(time) {
			if (time == 0) {
				return "——";
			} else if (!time) {
				return "——";
			} else {
				return moment().startOf("day").seconds(time).format(this.$i18n.t("ReportPayroll.time_format"));
			}
		},

		getTimeMillis(duration) {
			//duration+=86400000
			var milliseconds = parseInt((duration % 1000) / 100),
				seconds = parseInt((duration / 1000) % 60),
				minutes = parseInt((duration / (1000 * 60)) % 60),
				hours = parseInt(duration / (1000 * 60 * 60));

			if (isNaN(seconds)) {
				seconds = 0;
			}
			if (isNaN(minutes)) {
				minutes = 0;
			}
			if (isNaN(hours)) {
				hours = 0;
			}

			hours = hours < 10 ? "0" + hours : hours;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			return hours + ":" + minutes + ":" + seconds;
		},

		getTimeSeconds(duration) {
			if (duration == 0) {
				return "——";
			} else if (!duration) {
				return "——";
			} else {
				//duration+=86400000
				var milliseconds = parseInt((duration % 1000) / 100),
					seconds = parseInt(duration % 60),
					minutes = parseInt((duration / 60) % 60),
					hours = parseInt(duration / (60 * 60));

				hours = hours < 10 ? "0" + hours : hours;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;

				return hours + ":" + minutes + ":" + seconds;
			}
		},

		getDate(date) {
			if (!date) {
				return "——";
			} else if (date.startsWith("000")) {
				return "——";
			} else {
				date = moment(date).format("l LT");
				if (date) {
					return date;
				}
			}
		},

		dlPdf() {
			FileService.payrollReportPDF(this, this.sumPerUser);
		},

		dlCsv() {
			if (this.pearson_comp) {
				FileService.payrollReportPearsonCompCSV(this);
			} else {
				FileService.payrollReportCSV(this, this.sumPerUser);
			}
		},
	},
};
</script>
