<template>
	<div class="box p-3">
		<span class="float-right text-muted">
			<a class="no-select" v-tippy :title="$t('tooltip.refresh')" @click="loadCount">
				<i class="fa fa-sync text-sm" :class="{ 'fa-spin': loading }"></i>
			</a>
		</span>
		<h4>{{ title }}</h4>
		<loading v-if="loading" type="large" />
		<h2 v-else class="text-theme">{{ count }}</h2>
	</div>
</template>

<script>
import ReportingService from "@/services/ReportingService";
import Notie from "@/services/NotieService";

export default {
	name: "TenantDashRespCount",

	props: {
		lastWeek: Boolean,
		exported: Boolean,
		uniqueRefs: Boolean,
	},

	data() {
		return {
			loading: true,
			count: 0,
		};
	},

	created() {
		this.loadCount();
	},

	watch: {
		exported() {
			this.loadCount();
		},

		uniqueRefs() {
			this.loadCount();
		},
	},

	methods: {
		async loadCount() {
			const { lastWeek, exported, uniqueRefs } = this;
			this.loading = true;
			try {
				const { data } = await ReportingService.getTenantRespCounts(lastWeek, exported, uniqueRefs);
				this.count = data;
			} catch (err) {
				Notie.error("Failed to get response count", err);
				console.log(err);
			}
			this.loading = false;
		},
	},

	computed: {
		title() {
			const { lastWeek, exported, $i18n } = this;
			if (exported) {
				return lastWeek
					? $i18n.t("TenantDashboard.exported_resps_last_week")
					: $i18n.t("TenantDashboard.exported_resps_overall");
			}
			return lastWeek
				? $i18n.t("TenantDashboard.imported_resps_last_week")
				: $i18n.t("TenantDashboard.imported_resps_overall");
		},
	},
};
</script>
