<template>
	<b-modal visible no-close-on-backdrop no-close-on-esc @close="close" size="md" :title="$t('Auditing.export_api')">
		<div>
			<h6>
				{{
					$t("Auditing.export_num_api", {
						num: numSelected,
						r: numSelected == 1 ? "response" : "responses",
					})
				}}
			</h6>
			<div>
				<div class="form-group" v-if="selectedItem && selectedItem.api_export_config">
					<input
						v-if="!selectedItem.api_export_config.loopback"
						class="form-control"
						readonly
						v-model="selectedItem.api_export_config.url"
					/>
					<input
						v-if="selectedItem.api_export_config.loopback"
						class="form-control"
						readonly
						value="API Logs > Loopback"
					/>
				</div>
			</div>
		</div>
		<div v-if="exportRespStates">
			<span v-if="exportRespStates.unscored > 0"> {{ unscoredExportText }} </span>
			<div v-if="exportRespStates.incomplete > 0" class="checkbox form-group pt-2">
				<label class="md-check">
					<input v-model="exportIncomplete" type="checkbox" />
					<i class="theme-accent"></i>
					{{ incompleteExportText }}
				</label>
			</div>
		</div>
		<loading v-else type="icon" />
		<template #modal-footer>
			<button class="btn btn-flat btn-primary" @click="close">
				{{ $t("buttons.cancel") }}
			</button>
			<button @click="queueExport" :disabled="!exportRespStates" class="btn btn-flat theme">
				{{ $t("buttons.deliver") }}
			</button>
		</template>
	</b-modal>
</template>

<script>
import AuditingService from "@/services/AuditingService";
import Notie from "@/services/NotieService";

export default {
	name: "QueueExportModal",

	props: {
		numSelected: Number,
		selectedItem: Object,
		selectedIDs: Array,
		selectAll: Boolean,
		query: Object,
	},

	data() {
		return {
			exportRespStates: null,
			exportIncomplete: false,
		};
	},

	created() {
		this.checkExport();
	},

	computed: {
		selection() {
			const { selectedIDs, selectAll, query, exportIncomplete } = this;
			return {
				selected_ids: selectedIDs,
				select_all: selectAll,
				query,
				must_be_complete: !exportIncomplete,
			};
		},

		incompleteExportText() {
			const { exportRespStates, $i18n } = this;
			if (!exportRespStates) return "";
			return exportRespStates.incomplete === 1
				? $i18n.t("Auditing.incomplete_queued_resp")
				: $i18n.t("Auditing.incomplete_queued_resps", { n: exportRespStates.incomplete });
		},

		unscoredExportText() {
			const { exportRespStates, $i18n } = this;
			if (!exportRespStates) return "";
			return exportRespStates.unscored === 1
				? $i18n.t("Auditing.unscored_queued_resp")
				: $i18n.t("Auditing.unscored_queued_resps", { n: exportRespStates.unscored });
		},
	},

	methods: {
		async checkExport() {
			try {
				const { data } = await AuditingService.queueExportCheck(this.selection);
				this.exportRespStates = data;
			} catch (e) {
				Notie.error("failed to check the response states", e);
			}
		},

		async queueExport() {
			const { selection } = this;

			try {
				const resp = await AuditingService.queueExport(selection);
				let success = resp.data.success;
				let unscored = resp.data.unscored + resp.data.invalidated;
				let errored = resp.data.errored;
				let messages = [];
				if (success > 0) {
					messages.push(
						this.$i18n.t("notie.queued_responses", {
							success: success,
							r: success > 1 ? "responses" : "response",
						})
					);
				}
				if (unscored > 0) {
					if (unscored > 1) {
						messages.push(this.$i18n.t("notie.responses_not_exported", { unscored: unscored }));
					} else {
						messages.push(this.$i18n.t("notie.response_not_exported", { unscored: unscored }));
					}
				}
				if (errored > 0) {
					messages.push(
						this.$i18n.t("notie.export_response_fail", {
							errored: errored,
							r: errored > 1 ? "responses" : "response",
						})
					);
				}
				let good = success > 0;
				let bad = unscored > 0 || errored > 0;
				let disposition = good
					? bad
						? this.$i18n.t("data_description.info")
						: this.$i18n.t("data_description.success")
					: this.$i18n.t("data_description.error");

				console.log("disposition");
				console.log(disposition);
				disposition = this.convertDispositionToEnglish(disposition);
				Notie[disposition](messages.join("<br/><br/>"));

				this.$emit("onSubmit");
				this.close();
			} catch (err) {
				Notie.error(this.$i18n.t("notie.queue_api_export_fail"), err);
			}
		},

		convertDispositionToEnglish(disposition) {
			if (disposition == "succès") {
				disposition = "success";
			}
			if (disposition == "info") {
				disposition = "info";
			}
			if (disposition == "erreur") {
				disposition = "error";
			}
			if (disposition == "data_description.success") {
				disposition = "success";
			}
			if (disposition == "data_description.info") {
				disposition = "info";
			}
			if (disposition == "data_description.error") {
				disposition = "error";
			}
			return disposition;
		},

		close() {
			this.$emit("close");
		},
	},
};
</script>
