<template>
	<div>
		<div v-if="element.resource && element.resource.path">
			<div class="col-8 mb-2">
				<div class="mb-2">{{ $t("TrainingEdit.Details.file") }}</div>
				<div class="d-flex flex-row align-items-center">
					<input type="text" class="form-control" :value="fileNameFromPath(element.resource.path)" readonly />
					<div class="ml-2">
						<button
							v-if="Utils.isRenderableResource(element.resource)"
							class="btn btn-primary"
							@click="goToResource(element)"
						>
							{{ $t("TrainingEdit.Details.preview") }}
						</button>
						<button v-else class="btn btn-primary" @click="downloadResource(course, element)">
							{{ $t("TrainingEdit.Details.download") }}
						</button>
					</div>
				</div>
			</div>
			<div class="col-2 mb-2"></div>
		</div>
		<div v-else-if="!element.upload_resource_file" class="col-10 mb-2">
			<div class="mb-2">{{ $t("TrainingEdit.Details.file") }}</div>
			<input class="d-none" type="file" ref="resourceUploadInput" @change="selectResourceFile($event)" />
			<button class="btn btn-success" @click="$refs.resourceUploadInput.click()">
				{{ $t("TrainingEdit.Details.select_file") }}
			</button>
		</div>
		<div v-else>
			<div class="col-8 mb-2">
				<div class="mb-2">{{ $t("TrainingEdit.Details.file") }}</div>
				<div class="d-flex flex-row align-items-center">
					<input type="text" class="form-control" v-model="element.upload_resource_file.name" readonly />
					<div class="ml-2">
						<button
							class="btn btn-danger btn-sm btn-icon btn-rounded"
							@click="element.upload_resource_file = null"
						>
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
			</div>
			<div class="col-2 mb-2"></div>
		</div>
	</div>
</template>

<script>
import Utils from "@/services/Utils";
import ResourceService from "@/services/ResourceService";
import fs from "@/services/FormatService";

export default {
	name: "DetailsResource",

	props: {
		course: Object,
		element: Object,
	},

	data() {
		return {
			Utils,
		};
	},

	methods: {
		fileNameFromPath(path) {
			return path.split("/").at(-1).split(".")[0];
		},

		downloadResource(course, element) {
			ResourceService.downloadTrainingResource(course, element).catch((e) => {
				console.error(e);
				Notie.error("Failed to download resource", e);
			});
		},

		goToResource(element) {
			// ???
			if (!element.scorm) {
				console.error("Can't go to SCORM player, element did not contain scorm field");
				return;
			}

			this.$router.push(`/training_resource/${this.course.id}/${element.id}`);
		},

		downloadResource(course, element) {
			ResourceService.downloadTrainingResource(course, element).catch((e) => {
				console.error(e);
				Notie.error("Failed to download resource", e);
			});
		},

		selectResourceFile(event) {
			const { element } = this;
			const file = event.target.files[0];
			if (file) {
				if (fs.hasUnicodeChars(file.name)) {
					this.nonAsciiError();
					return;
				}
				element.upload_resource_file = file;
				this.$forceUpdate();
			}
		},
	},
};
</script>
