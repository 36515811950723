<template>
	<div>
		<!-- Condition 1: Object is dirty and can't be saved because of validation errors -->
		<b-modal :visible="value" @hidden="closed" @shown="interacted = false" v-if="dirty && !valid.all()">
			<template slot="modal-title">{{
				$t("SaveOptionalModal.object_cannot_be_saved", { object: objectText })
			}}</template>
			<div class="py-4">
				{{ $t("SaveOptionalModal.the_object", { object: objectText }) }}
				<strong class="text-theme">'{{ objectName }}'</strong>
				{{ $t("SaveOptionalModal.unsaved_changes_cannot_save") }}
				<ul class="pl-4 mb-0">
					<li v-for="(error, index) in valid.errors()" :key="index">{{ error }}</li>
				</ul>
				<br />
				{{ $t("SaveOptionalModal.action_without_saving", { action: actionText }) }}
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="doCancel">{{ $t("buttons.cancel") }}</button>
				<button class="btn danger btn-flat" @click="doNext">{{ $t("buttons.continue_without_saving") }}</button>
			</template>
		</b-modal>

		<!-- Condition 2: Object is dirty and can be saved before continuing to the action -->
		<b-modal :visible="value" @hidden="closed" @shown="interacted = false" v-if="dirty && valid.all()">
			<template slot="modal-title">{{
				$t("SaveOptionalModal.would_you_like_to_save", { object: objectText })
			}}</template>
			<div class="py-4">
				{{ $t("SaveOptionalModal.the_object", { object: objectText }) }}&nbsp;
				<span class="text-theme _700">'{{ objectName }}'</span>
				{{ $t("SaveOptionalModal.like_to_save_this", { action: actionText }) }}
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="doCancel">{{ $t("buttons.cancel") }}</button>
				<button class="btn danger btn-flat" @click="doNext">{{ $t("buttons.discard_changes") }}</button>
				<button class="btn success btn-flat" @click="doSave" v-if="!saving">{{ $t("buttons.save") }}</button>
				<button class="btn btn-secondary btn-flat" v-if="saving" disabled>
					{{ $t("buttons.saving") }}&nbsp;&nbsp;
					<loading type="icon" />
				</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped></style>

<script>
import notie from "@/services/NotieService";
import ValidationService from "@/services/ValidationService";

export default {
	name: "SaveOptionalModal",
	props: {
		dirty: { type: Boolean, default: false },
		valid: {
			type: Object,
			default: ValidationService.newValidator(),
		},
		save: {
			type: Function,
		},
		next: {
			type: Function,
			default: () => {},
		},
		cancel: {
			type: Function,
			default: () => {},
		},
		objectText: { type: String, default: "Object" },
		objectName: { type: String, default: "Undefined" },
		actionText: { type: String, default: "continue" },
		value: { type: Boolean, default: false },
	},

	data() {
		return {
			saving: false,
			interacted: false,
		};
	},
	created() {},
	watch: {},
	methods: {
		doSave() {
			this.interacted = true;
			this.saving = true;
			this.save()
				.then((resp) => {
					this.next();
					this.saving = false;
					this.visible(false);
				})
				.catch((err) => {
					this.saving = false;
					this.visible(false);
				});
		},

		doNext() {
			this.interacted = true;
			this.next();
			this.visible(false);
		},

		doCancel() {
			this.interacted = true;
			this.cancel();
			this.visible(false);
		},

		closed() {
			if (!this.interacted) {
				this.cancel();
				this.visible(false);
			}
		},

		visible(val) {
			this.$emit("input", val);
		},
	},
};
</script>
