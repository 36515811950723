<template>
	<span v-tippy="{ theme: 'popover', placement: placement }" :title="tooltip">
		<span :class="{ 'text-success _600': exact > 0, 'text-very-muted': exact == 0 }" class="px-1">{{ exact }}</span
		>/<span :class="{ 'text-warning _600': adj > 0, 'text-very-muted': adj == 0 }" class="px-1">{{ adj }}</span
		>/<span :class="{ 'text-danger _600': nonadj > 0, 'text-very-muted': nonadj == 0 }" class="px-1">{{
			nonadj
		}}</span>
	</span>
</template>

<style scoped>
</style>

<script>
import fs from "@/services/FormatService";

export default {
	name: "AgreementRow",
	props: {
		exact: true,
		adj: true,
		nonadj: true,
		placement: {
			type: String,
			default: "top"
		}
	},

	data() {
		return {
			fs: fs
		};
	},

	computed: {
		total() {
			return this.exact + this.adj + this.nonadj;
		},

		tooltip() {
			return `<table>
				<tr>
					<td class="text-left pr-4 _600 text-success">Exact</td>
					<td class="pr-4 _600">${this.exact}</td>
					<td>${this.percent(this.exact)}</td>
				</tr>
				<tr>
					<td class="text-left pr-4 _600 text-warning">Adjacent</td>
					<td class="pr-4 _600">${this.adj}</td>
					<td>${this.percent(this.adj)}</td>
				</tr>
				<tr>
					<td class="text-left pr-4 pb-2 _600 text-danger">Non-adjacent</td>
					<td class="pr-4 pb-2 _600">${this.nonadj}</td>
					<td class="pb-2">${this.percent(this.nonadj)}</td>
				</tr>
				<tr>
					<td class="text-left pr-4 pt-2 b-t _600">Total</td>
					<td class="pr-4 pt-2 b-t _600">${this.total}</td>
					<td class="pt-2 b-t"></td>
				</tr>
			</table>`;
		}
	},

	created() {},
	watch: {},
	methods: {
		percent(count) {
			return fs.calcPercent1d(count, this.total);
		}
	}
};
</script>
