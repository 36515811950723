<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportDailyScoringMgmt.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportDailyScoringMgmt.daily_scoring_mgmt") }}
				</div>
			</div>
		</template>

		<div class="d-flex flex-column flex scroll-x scroll-y">
			<div class="row mx-0 flex-fixed px-3 pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="col-12 col-lg-5">
					<div class="form-group">
						<label>{{ $t("ReportDailyScoringMgmt.project") }}</label>
						<config-select
							:options="projects"
							v-model="selectedProject"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-lg-5">
					<div class="form-group">
						<label>{{ $t("ReportDailyScoringMgmt.section") }}</label>
						<config-select
							:options="sectionOptions"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<!-- <div class="col-12 col-lg-3">
							<div class="form-group">
								<label>
									{{ $t("ReportDailyScoringMgmt.from_date") }}
								</label>
								<config-date v-model="startDate" :config="{ showClear: true }"></config-date>
							</div>
						</div>
						<div class="col-12 col-lg-3">
							<div class="form-group">
								<label>
									{{ $t("ReportDailyScoringMgmt.to_date") }}
								</label>
								<config-date v-model="endDate" :config="{ showClear: true }"></config-date>
							</div>
						</div> -->
				<div class="col-12 col-lg-2 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-end form-group">
						<button
							v-if="rows && rows.length > 0"
							class="btn btn-sm btn-icon btn-rounded theme-accent mx-1"
							style="padding-top: 10px"
							v-tippy
							:title="$t('tooltip.export')"
							@click="exportCSV()"
						>
							<i class="fas fa-file-export" style="margin-left: 2px"></i>
						</button>
						<div class="py-0 ml-1">
							<button
								class="btn btn-sm btn-secondary theme-accent btn-block flex-fixed"
								v-b-tooltip
								:disabled="!selectedProject"
								@click="runReport"
								:title="$t('tooltip.run_report')"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline"
									>&nbsp;{{ $t("ReportDailyScoringMgmt.run_report") }}</span
								>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="rows && rows.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div class="flex">
					<div class="card border-0 box-shadow-3">
						<report-table
							class="condensed-table mb-0"
							style="max-width: 89vw; overflow-y: scroll"
							:fields="fields"
							:content="rows"
							:totals="totals"
							per-page="15"
							totalsClass="theme-lighten-3 text-on-color theme-accent"
						>
							<template #header v-if="date_computed">
								<b-col align-self="end">
									<h4 class="text-end pl-5" style="text-align: center">
										Last Calculated At: {{ date_computed.toLocaleDateString() }}
									</h4>
								</b-col>
							</template>
							<template #cell(resp_complete_percent)="{ value }">{{ fs.fixedPercent1d(value) }}</template>

							<template #cell(score_rate)="{ value }">
								{{ fs.fixed1d(value) }}
							</template>
							<template v-slot:[`cell(${tf.key})`]="{ item: { traits } }" v-for="tf in traitFields">
								<div v-for="(trait, i) in traits" :key="tf + i" :class="tf.class">
									{{ getTraitVal(tf, trait) }}
								</div>
							</template>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="rows && rows.length == 0">
				<h3 class="text-muted d-flex justify-content-center flex-row">
					{{ $t("ReportDaily.report_returned_no_results") }}
				</h3>
				<h3 class="text-muted d-flex justify-content-center flex-row">
					{{ $t("ReportDailyScoringMgmt.not_computed_yet") }}
				</h3>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ar {
	text-align: center;
}

.d-long {
	display: none;
}

.d-abr {
	display: table-cell;
}

@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}

	.d-abr {
		display: none;
	}
}

.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}

.t-margin {
	width: 15px;
}

.trait-name {
	width: 8rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import FileService from "@/services/FileService";
import ConfigService from "@/services/ConfigService";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";

import ConfigSelect from "@/components/ConfigSelect";
import ReportTable from "@/components/ReportTable";
import ConfigDate from "@/components/ConfigDate";

export default {
	name: "ReportDailyScoringMgmt",

	components: {
		ConfigSelect,
		ReportTable,
		ConfigDate,
	},

	data() {
		return {
			rows: null,
			totals: [],
			min_sp: 0,
			max_sp: 0,
			condition_codes: [],
			date_computed: null,

			projects: [],
			selectedProject: null,

			selectedSection: null,

			startDate: null,
			endDate: null,

			loading: false,
			running: false,
			fs,
		};
	},

	created() {
		this.getProjects();
	},

	computed: {
		sectionOptions() {
			const { allSectionOption, selectedProject } = this;
			return [allSectionOption].concat((selectedProject && selectedProject.sections) || []);
		},

		allSectionOption() {
			return {
				name: "All",
				id: "",
			};
		},

		fields() {
			const fields = [
				{
					key: "item_name",
					label: this.$i18n.t("fields.item"),
				},
				{
					key: "single_or_multi_trait",
					label: this.$i18n.t("ReportDailyScoringMgmt.single_or_multi_trait"),
				},
				{
					key: "unscored",
					label: this.$i18n.t("ReportDailyScoringMgmt.unscored"),
				},
				{
					key: "resp_complete_percent",
					label: this.$i18n.t("ReportDailyScoringMgmt.complete"),
					percent: true,
				},
				{
					key: "human_1st_score",
					label: this.$i18n.t("ReportDailyScoringMgmt.human_1st_score"),
				},
				{
					key: "ai_1st_score",
					label: this.$i18n.t("ReportDailyScoringMgmt.ai_1st_score"),
				},
				{
					key: "human_2nd_score",
					label: this.$i18n.t("ReportDailyScoringMgmt.human_2nd_score"),
				},
				{
					key: "ai_2nd_score",
					label: this.$i18n.t("ReportDailyScoringMgmt.ai_2nd_score"),
				},
				{
					key: "sys",
					label: this.$i18n.t("ReportDailyScoringMgmt.sys"),
				},
				{
					key: "score_rate",
					label: this.$i18n.t("ReportDailyScoringMgmt.score_rate"),
					round: true,
				},
			];

			return fields.concat(this.traitFields);
		},

		traitFields() {
			let fields = [
				{
					key: "name",
					label: this.$i18n.t("ReportDailyScoringMgmt.traits"),
					class: "trait-name",
				},
				{
					key: "score_point",
					label: this.$i18n.t("ReportDailyScoringMgmt.score_points"),
				},
				{
					key: "val_count",
					label: this.$i18n.t("ReportDailyScoringMgmt.val_count"),
				},
				{
					key: "val_exact",
					label: this.$i18n.t("ReportDailyScoringMgmt.val_exact"),
					percent: true,
				},
				{
					key: "val_exact_adj",
					label: this.$i18n.t("ReportDailyScoringMgmt.val_exact_adj"),
					percent: true,
				},
				{
					key: "irr_count",
					label: this.$i18n.t("ReportDailyScoringMgmt.irr_count"),
				},
				{
					key: "irr_exact",
					label: this.$i18n.t("ReportDailyScoringMgmt.irr_exact"),
					percent: true,
				},
				{
					key: "irr_exact_adj",
					label: this.$i18n.t("ReportDailyScoringMgmt.irr_exact_adj"),
					percent: true,
				},
			];

			const { condition_codes, scorePoints } = this;

			fields = fields.concat(
				scorePoints.map((sp) => ({
					key: sp,
					label: sp,
					isSP: true,
				}))
			);

			fields = fields.concat(
				condition_codes.map((cc) => ({
					key: cc,
					label: cc,
					isSP: true,
				}))
			);

			return fields;
		},

		scorePoints() {
			const { min_sp, max_sp } = this;
			return [...Array(1 + max_sp - min_sp).keys()].map((i) => String(i + min_sp));
		},
	},

	methods: {
		getTraitVal(ts, trait) {
			const { isSP, percent, key } = ts;
			if (isSP) {
				return fs.fixedPercent1d(trait.score_point_freqs[key]);
			}
			if (percent) {
				return fs.fixedPercent1d(trait[key]);
			}
			return trait[key];
		},

		exportCSV() {
			const { fields, rows, selectedProject, totals, date_computed } = this;
			const headers = fields.map(({ label }) => label);
			const body = rows
				.concat(totals)
				.map((row) => {
					return (row.traits || []).map((trait) => {
						return fields
							.map(({ key, percent, isSP, round }) => {
								let value = trait[key];
								if (value == null) {
									value = row[key];
								}
								if (isSP) {
									value = trait.score_point_freqs[key];
								}
								if (isSP || percent) {
									return fs.fixedPercent1d(value);
								}
								if (round) {
									return fs.fixed1d(value);
								}
								return value;
							})
							.map((val) => (val == null || val === "——" ? "N/A" : val));
					});
				})
				.flat(1);
			const note = [[], ["data updated daily at 3 am ET"]];

			const dateStr = date_computed.toLocaleDateString().split("/").join(".");
			const timeStr = date_computed.toLocaleTimeString("en-US").split(":").join(".").split(" ").join("");
			FileService.exportCSV([headers].concat(body).concat(note), [
				"DailyScoringManagement",
				selectedProject.name,
				dateStr,
				timeStr,
			]);
		},

		async runReport() {
			try {
				this.running = true;
				const { selectedProject, selectedSection, startDate, endDate } = this;
				const { data } = await ReportingService.getDailyScoreMgmtStat(
					selectedProject.id,
					selectedSection.id,
					startDate,
					endDate
				);
				const { rows, totals, min_sp, max_sp, condition_codes, date_computed } = data;

				this.rows = rows.sort((a, b) => {
					if (a.item_name === b.item_name) {
						return a.single_or_multi_trait === "single" ? -1 : 1;
					}
					return a.item_name.localeCompare(b.item_name);
				});
				this.rows = this.addNulls(this.rows);
				this.totals = this.addBlanksToTotals(totals);
				this.min_sp = min_sp;
				this.max_sp = max_sp;
				this.condition_codes = condition_codes;
				this.date_computed = new Date(date_computed);
			} catch (e) {
				notie.error("Failed to run report", e);
			} finally {
				this.running = false;
			}
		},

		addBlanksToTotals(totals) {
			totals.single_or_multi_trait = "——";
			totals.unscored = "——";
			totals.resp_complete_percent = "——";
			totals.human_1st_score = "——";
			totals.ai_1st_score = "——";
			totals.human_2nd_score = "——";
			totals.ai_2nd_score = "——";
			totals.traits[0].score_point = "——";
			totals.traits[0].val_count = "——";
			totals.traits[0].irr_count = "——";
			return totals;
		},

		addNulls(rows) {
			return rows.map((row) => {
				const { traits } = row;
				return {
					...row,
					traits: traits.map((trait) => {
						const { val_count, val_exact, val_exact_adj, irr_count, irr_exact, irr_exact_adj } = trait;
						return {
							...trait,
							val_exact: val_count > 0 ? val_exact : null,
							val_exact_adj: val_count > 0 ? val_exact_adj : null,
							irr_exact: irr_count > 0 ? irr_exact : null,
							irr_exact_adj: irr_count > 0 ? irr_exact_adj : null,
						};
					}),
				};
			});
		},

		async getProjects() {
			try {
				this.loading = true;
				const { data } = await ConfigService.listProjects();
				this.projects = data;
			} catch (e) {
				notie.error("Failed to get Sections", e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
