<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("ResetHistory.title") }}</div>

				<!-- Show inactive -->
				<div class="checkbox mr-1">
					<label class="md-check">
						<input v-model="showComplete" type="checkbox" />
						<i class="theme-accent"></i>
						{{ $t("ResetHistory.show_complete") }}
					</label>
				</div>

				<!-- Search field -->
				<div>
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button type="button" class="btn no-border no-bg no-shadow">
								<i class="fa fa-search"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('ResetHistory.search_jobs_placeholder')"
						/>
					</form>
				</div>

				<div class="ml-2">
					<button
						type="button"
						@click="loadData"
						class="btn no-border no-bg no-shadow"
						v-tippy
						:title="$t('tooltip.refresh')"
					>
						<i class="fa fa-sync" :class="{ 'fa-spin': refreshing }"></i>
					</button>
				</div>
			</div>
		</template>

		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="jobs"
					:fields="table_fields"
					:current-page="1"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:busy.sync="busy"
				>
					<!-- Query -->
					<template #cell(deletion_set_size)="data">
						<span v-tippy="{ theme: 'popover', html: `#selection-${data.item.id}` }">
							<i class="fas fa-inventory faw"></i>
							{{ data.item.deletion_set_size }}
						</span>
						<div class="d-none p-0" :id="`selection-${data.item.id}`">
							<span class="p-2 float-right text-very-muted">
								<i class="fas fa-inventory faw"></i>
								{{ data.item.deletion_set_size }}
							</span>
							<div class="p-2 b-b">{{ $t("ResetHistory.selection") }}</div>
							<div class="p-2">
								<table class="tooltip-table">
									<tr>
										<td>
											<li>
												<strong>{{ $t("fields.section") }}:</strong>
											</li>
										</td>
										<td>{{ data.item.section_name }}</td>
									</tr>
									<tr>
										<td>
											<li>
												<strong>{{ $t("fields.item") }}:</strong>
											</li>
										</td>
										<td>{{ data.item.item_name }}</td>
									</tr>
									<tr
										v-if="
											data.item.selection.select_all &&
											data.item.selection.query &&
											data.item.selection.query.response_id &&
											data.item.selection.query.response_id != ''
										"
									>
										<td>
											<li>
												<strong>{{ $t("fields.ref_id_short") }}:</strong>
											</li>
										</td>
										<td>
											<span>"{{ data.item.selection.query.response_id }}"</span>
										</td>
									</tr>
									<tr
										v-if="
											data.item.selection.select_all &&
											data.item.selection.query &&
											data.item.selection.query.users &&
											data.item.selection.query.users.length > 0
										"
									>
										<td>
											<li>
												<strong v-if="data.item.selection.query.users.length == 1"
													>{{ $t("fields.scorer") }}:</strong
												>
												<strong v-if="data.item.selection.query.users.length > 1"
													>{{ $t("fields.scorers") }}:</strong
												>
											</li>
										</td>
										<td>
											<span v-for="(user, i) in data.item.selection.query.users" :key="user.id"
												>{{ i == 0 ? "" : ", " }}{{ user.first_name }}
												{{ user.last_name }}</span
											>
										</td>
									</tr>
									<tr
										v-if="
											data.item.selection.select_all &&
											data.item.selection.query &&
											data.item.selection.query.state
										"
									>
										<td>
											<li>
												<strong>{{ $t("fields.response_state") }}:</strong>
											</li>
										</td>
										<td>{{ fs.responseState(data.item.selection.query.state, $i18n) }}</td>
									</tr>
									<tr
										v-if="
											data.item.selection.select_all &&
											data.item.selection.query &&
											data.item.selection.query.score_types &&
											data.item.selection.query.score_types.length > 0
										"
									>
										<td>
											<li>
												<strong v-if="data.item.selection.query.score_types.length == 1"
													>{{ $t("fields.score_type") }}:</strong
												>
												<strong v-if="data.item.selection.query.score_types.length > 1"
													>{{ $t("fields.score_types") }}:</strong
												>
											</li>
										</td>
										<td>
											<span v-for="(type, i) in data.item.selection.query.score_types" :key="type"
												>{{ i == 0 ? "" : ", " }}{{ fs.scoreTypeName(type, $i18n) }}</span
											>
										</td>
									</tr>
									<tr
										v-if="
											data.item.selection.select_all &&
											data.item.selection.query &&
											data.item.selection.query.start_date
										"
									>
										<td>
											<li>
												<strong>{{ $t("fields.from") }}:</strong>
											</li>
										</td>
										<td>{{ fs.shortDate(data.item.selection.query.start_date) }}</td>
									</tr>
									<tr
										v-if="
											data.item.selection.select_all &&
											data.item.selection.query &&
											data.item.selection.query.end_date
										"
									>
										<td>
											<li>
												<strong>{{ $t("fields.until") }}:</strong>
											</li>
										</td>
										<td>{{ fs.shortDate(data.item.selection.query.end_date) }}</td>
									</tr>
									<tr v-if="!data.item.selection.select_all">
										<td>
											<li>
												<strong>{{ $t("fields.selected") }}:</strong>
											</li>
										</td>
										<td>
											{{ data.item.selection.selected_ids.length }}
											{{
												data.item.selection.selected_ids.length > 1
													? $t("fields.scores")
													: $t("fields.score")
											}}
										</td>
									</tr>
									<tr v-if="data.item.selection.select_all">
										<td>
											<li>
												<strong>{{ $t("fields.selected") }}:</strong>
											</li>
										</td>
										<td>All</td>
									</tr>
									<tr
										v-if="
											data.item.selection.select_all &&
											data.item.selection.selected_ids.length > 0
										"
									>
										<td>
											<li>
												<strong>{{ $t("fields.exclude") }}:</strong>
											</li>
										</td>
										<td>
											{{ data.item.selection.selected_ids.length }}
											{{
												data.item.selection.selected_ids.length > 1
													? $t("fields.scores")
													: $t("fields.score")
											}}
										</td>
									</tr>
								</table>
							</div>
						</div>
					</template>

					<!-- Status -->
					<template #cell(state)="data">
						<span>{{ getStateName(data.item) }}</span>
					</template>

					<!-- User -->
					<template #cell(first_name)="data">
						<span>{{ data.item.user.first_name }} {{ data.item.user.last_name }}</span>
						<span class="text-xxs text-muted">({{ data.item.user.scorer_id }})</span>
					</template>

					<!-- Reporting -->
					<template #cell(reports)="data">
						<button
							@click="showReport(data.item)"
							class="btn btn-sm btn-block"
							:class="{
								'theme-accent': data.item.before && data.item.after,
								'btn-secondary': !(data.item.before && data.item.after),
							}"
							:disabled="!(data.item.before && data.item.after)"
						>
							<i class="far fa-fw fa-clipboard-list"></i>
							<span class="hidden-folded d-inline">{{ $t("buttons.show_report") }}</span>
						</button>
					</template>

					<!-- Started At -->
					<template #cell(started_at)="data">
						<span v-if="data.item.started_at">{{ fs.fullDate(data.item.started_at) }}</span>

						<span v-if="!data.item.started_at && data.item.status == 1">
							<loading type="icon" />
						</span>
					</template>

					<!-- Finished At -->
					<template #cell(finished_at)="data">
						<span v-if="data.item.finished_at">{{ fs.fullDate(data.item.finished_at) }}</span>

						<span v-if="!data.item.finished_at && (data.item.status == 1 || data.item.status == 3)">
							<loading type="icon" />
						</span>
					</template>

					<!-- Actions -->
					<template #cell(actions)="data">
						<nobr>
							<div class="mx-auto" :class="{ [user.role.delete_data ? 'w60' : 'w30']: true }">
								<button
									v-if="!data.item.do_execute && (data.item.state == 0 || data.item.state == 2)"
									@click="removeJob(data.item)"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									v-tippy
									:title="$t('tooltip.cancel_job')"
								>
									<i class="far fa-trash"></i>
								</button>
								<button
									v-if="!data.item.do_execute && data.item.state == 1"
									disabled
									class="btn btn-sm btn-icon btn-rounded btn-secondary text-white m-0"
									v-tippy
									:title="$t('tooltip.cancel_job')"
								>
									<loading type="icon" />
								</button>
								<button
									v-if="data.item.do_execute"
									disabled
									class="btn btn-sm btn-icon btn-rounded btn-secondary text-white m-0"
									v-tippy
									:title="$t('tooltip.cancel_job')"
								>
									<i class="far fa-trash"></i>
								</button>

								<button
									v-if="user.role.delete_data && !data.item.do_execute && data.item.state == 2"
									@click="confirmExecute(data.item)"
									class="btn btn-sm btn-icon btn-rounded theme-accent m-0"
									v-tippy
									:title="$t('tooltip.execute')"
								>
									<i class="far fa-play"></i>
								</button>
								<button
									v-if="user.role.delete_data && data.item.do_execute && data.item.state == 1"
									disabled
									class="btn btn-sm btn-icon btn-rounded btn-secondary m-0"
									v-tippy
									:title="$t('tooltip.execute')"
								>
									<loading type="icon" />
								</button>
								<button
									v-if="
										user.role.delete_data &&
										!(
											(!data.item.do_execute && data.item.state == 2) ||
											(data.item.do_execute && data.item.state == 1)
										)
									"
									disabled
									class="btn btn-sm btn-icon btn-rounded btn-secondary m-0"
									v-tippy
									:title="$t('tooltip.execute')"
								>
									<i class="far fa-play"></i>
								</button>
							</div>
						</nobr>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>
		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="executeConfirm" v-model="executing" v-if="executingJob">
			<template slot="modal-title">{{ $t("ResetHistory.execute_job") }}</template>
			<h6>{{ $t("ResetHistory.confirm_reset", { num: executingJob.deletion_set_size }) }}</h6>
			<h6>
				<span class="text-danger far faw fa-exclamation-triangle"></span>
				{{ $t("ResetHistory.cannot_cancel_reverse_reset") }}
			</h6>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="executing = false">{{ $t("buttons.cancel") }}</button>
				<button class="btn danger btn-flat" @click="executeJob()">{{ $t("buttons.execute") }}</button>
			</template>
		</b-modal>

		<b-modal id="beforeVsAfter" size="lg" v-model="reporting" v-if="reporting">
			<template slot="modal-title"
				>{{ $t("ResetHistory.score_reset_report") }} -
				{{ reportJob.do_execute ? "Finished" : "Planned" }}</template
			>
			<div class="row flex-nowrap text-muted top-bar b-b">
				<div>
					<i class="fa fa-fw fa-briefcase"></i>
					{{ reportJob.section_name }}
				</div>
				<div>
					<i class="far fa-fw fa-angle-right"></i>
				</div>
				<div class="flex">
					<i class="fa fa-fw fa-book"></i>
					{{ reportJob.item_name }}
				</div>
				<div>
					<i class="fa fa-fw fa-clock"></i>
					{{ fs.fullDate(reportJob.finished_at) }}
				</div>
			</div>
			<div class="row flex-nowrap modal-scroll">
				<div class="col-6 pr-0">
					<div class="card border-0 p-3">
						<div class="row">
							<div class="col-12">
								<div class="float-right text-muted">
									{{ reportBefore.score_count }} {{ $t("ResetHistory.scores") }}
								</div>
								<h5>{{ $t("ResetHistory.before") }}</h5>
								<hr />
								<table>
									<tr>
										<td class="pr-3">
											<h5>{{ $t("ResetHistory.score_irr") }}:</h5>
										</td>
										<td>
											<h5 :class="percentColor(reportBefore.scoreIRR, 50, 65)">
												{{ reportBefore.scoreIRR.toFixed(1) }}%
											</h5>
										</td>
									</tr>
									<tr>
										<td class="pr-3">
											<h5>{{ $t("ResetHistory.trait_irr") }}:</h5>
										</td>
										<td>
											<h5 :class="percentColor(reportBefore.traitIRR, 40, 50)">
												{{ reportBefore.traitIRR.toFixed(1) }}%
											</h5>
										</td>
									</tr>
								</table>
							</div>
						</div>

						<template v-for="trait in reportBefore.traits">
							<div
								v-if="!(trait.separator || trait.is_parent)"
								:key="trait.trait_id"
								class="row b-t mt-2 pt-2"
							>
								<div class="col-5">
									<h5>{{ trait.reported_name || trait.name }}</h5>
								</div>
								<div class="col-7">
									<h5 class="float-right text-muted">{{ trait.agreement.toFixed(1) }}%</h5>
								</div>
								<div class="col-12">
									<canvas v-chartjs="trait.fd" style="width: 100%; height: 250px"></canvas>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div class="col-6 pl-0">
					<div class="card border-0 p-3">
						<div class="row">
							<div class="col-12">
								<div class="float-right text-muted">
									{{ reportAfter.score_count }} {{ $t("ResetHistory.scores") }}
								</div>
								<h5>{{ $t("ResetHistory.after") }}</h5>
								<hr />
								<table>
									<tr>
										<td class="pr-3">
											<h5>{{ $t("ResetHistory.score_irr") }}:</h5>
										</td>
										<td>
											<h5 :class="percentColor(reportAfter.scoreIRR, 50, 65)">
												{{ reportAfter.scoreIRR.toFixed(1) }}%
											</h5>
										</td>
									</tr>
									<tr>
										<td class="pr-3">
											<h5>{{ $t("ResetHistory.trait_irr") }}:</h5>
										</td>
										<td>
											<h5 :class="percentColor(reportAfter.traitIRR, 40, 50)">
												{{ reportAfter.traitIRR.toFixed(1) }}%
											</h5>
										</td>
									</tr>
								</table>
							</div>
						</div>

						<template v-for="trait in reportAfter.traits">
							<div
								v-if="!(trait.separator || trait.is_parent)"
								:key="trait.trait_id"
								class="row b-t mt-2 pt-2"
							>
								<div class="col-5">
									<h5>{{ trait.reported_name || trait.name }}</h5>
								</div>
								<div class="col-7">
									<h5 class="float-right text-muted">{{ trait.agreement.toFixed(1) }}%</h5>
								</div>
								<div class="col-12">
									<canvas v-chartjs="trait.fd" style="width: 100%; height: 250px"></canvas>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="reporting = false">{{ $t("buttons.close") }}</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w30 {
	width: 30px;
}
.w60 {
	width: 60px;
}
.constrained-cell {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.modal-scroll {
	max-height: calc(100vh - 250px);
}
.top-bar {
	padding-left: calc(15px + 1rem);
	padding-right: calc(15px + 1rem);
	margin-top: -6px;
	padding-bottom: 6px;
}
</style>

<script>
//UI Components
//Libraries
import moment from "moment";

//Services
import ResetJobService from "@/services/ResetJobService";
import ThemeService from "@/services/ThemeService";
import fs from "@/services/FormatService";
import store from "@/services/Store";
import notie from "@/services/NotieService";

const REFRESH_INTERVAL = 5 * 1000; //30 seconds

export default {
	name: "ResetHistory",

	props: ["user"],

	data() {
		return {
			vm: this,
			fs: fs,
			table_fields: [
				{
					key: "section_name",
					label: this.$i18n.t("fields.section"),
					sortable: true,
				},
				{
					key: "item_name",
					label: this.$i18n.t("fields.item"),
					sortable: true,
				},
				{
					key: "first_name",
					label: this.$i18n.t("fields.user"),
					sortable: true,
				},
				{
					key: "deletion_set_size",
					label: this.$i18n.t("fields.selection"),
					sortable: true,
				},
				{
					key: "state",
					label: this.$i18n.t("fields.state"),
					sortable: true,
				},
				{
					key: "started_at",
					label: this.$i18n.t("fields.started_at"),
					sortable: true,
				},
				{
					key: "finished_at",
					label: this.$i18n.t("fields.finished_at"),
					sortable: true,
				},
				{
					key: "reports",
					label: this.$i18n.t("fields.before_vs_after"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			],

			jobs: [],

			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			active: false,
			sortBy: null,
			sortDesc: false,
			busy: false,

			reportBefore: {
				scoreIRR: 0,
				traitIRR: 0,
				traits: [],
				score_count: 0,
			},
			reportAfter: {
				scoreIRR: 0,
				traitIRR: 0,
				traits: [],
				score_count: 0,
			},
			reportJob: null,

			refreshTimeout: 1000,
			showComplete: true,
			loading: true,
			refreshing: false,
			executing: false,
			executingJob: null,
			reporting: false,
			autoRefresh: null,
		};
	},

	created() {
		this.loadData();

		this.autoRefreshFunction = () => {
			this.loadData(true);
			this.updateRefreshTimeout();
			this.scheduleRefresh();
		};

		this.scheduleRefresh();
	},

	destroyed() {
		clearTimeout(this.autoRefresh);
	},

	watch: {
		currentPage() {
			this.loadData();
		},
		filter() {
			this.loadData();
		},
		sortBy() {
			this.loadData();
		},
		sortDesc() {
			this.loadData();
		},
	},

	methods: {
		loadData(noBusy) {
			let ctx = {
				currentPage: this.currentPage,
				perPage: this.perPage,
				filter: this.filter,
				sortBy: this.sortBy,
				sortDesc: this.sortDesc,
				active: this.showComplete,
			};

			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			ctx.showHiddenProjects = this.showHiddenProjects;

			this.refreshing = true;
			if (!noBusy) {
				this.busy = true;
			}
			return ResetJobService.listJobs(ctx, noBusy)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.jobs = resp.data.jobs;
					this.loading = false;
					this.refreshing = false;
					this.busy = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("load_jobs_fail"), err);
					return [];
					this.loading = false;
					this.refreshing = false;
					this.busy = false;
				});
		},

		updateRefreshTimeout() {
			//Exponential backoff until 60 second interval
			this.refreshTimeout *= 2;
			if (this.refreshTimeout > 60000) {
				this.refreshTimeout = 60000;
			}
		},

		scheduleRefresh() {
			if (this.autoRefresh) {
				clearTimeout(this.autoRefresh);
			}
			this.autoRefresh = setTimeout(this.autoRefreshFunction, this.refreshTimeout);
		},

		touchRefresh() {
			this.refreshTimeout = 1000;
			this.scheduleRefresh();
		},

		showReport(job) {
			this.reporting = true;
			this.reportJob = job;
			this.loadReportData(this.reportBefore, job.before);
			this.loadReportData(this.reportAfter, job.after);
		},

		loadReportData(stats, report) {
			stats.scoreIRR = report.score_irr * 100;
			stats.traitIRR = report.trait_irr * 100;
			let total = 0;
			_.each(report.traits, (trait, i) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				if (!stats.traits[i]) {
					stats.traits[i] = {
						agreement: 0,
						fd: this.getBlankChart(),
					};
				}

				stats.traits[i].name = trait.reported_name || trait.name;
				stats.traits[i].agreement = trait.agreement * 100;

				let labels = [];
				let data = [];
				let counts = [];

				total = 0;
				_.each(trait.fd, (sp) => {
					total += sp.count;
				});

				_.each(_.orderBy(trait.fd, "value"), (sp) => {
					labels.push(sp.value);
					let percent = +(Math.round((sp.count / total) * 100 + "e+2") + "e-2");
					data.push(percent);
					counts.push({
						label: sp.value,
						value: percent,
						count: sp.count,
						total: total,
					});
				});

				stats.traits[i].fd.data.labels = labels;
				stats.traits[i].fd.data.datasets[0].fullName = trait.reported_name || trait.name;
				stats.traits[i].fd.data.datasets[0].data = data;
				stats.traits[i].fd.data.datasets[0].counts = counts;
			});
			stats.score_count = total;
		},

		removeJob(job) {
			job.inProgress = true;
			ResetJobService.removeJob(job.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.job_canceled"));
					this.loadData();
					job.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.delete_job_fail"), err);
					job.inProgress = false;
					this.$forceUpdate();
				});
			this.deleting = false;
		},

		confirmExecute(job) {
			this.executingJob = job;
			this.executing = true;
		},

		executeJob() {
			ResetJobService.executeJob(this.executingJob.id)
				.then((resp) => {
					notie.success(this.$i18n.t("notie.reset_job_execution"));
					this.loadData();
					this.touchRefresh();
					this.executingJob = null;
					this.executing = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.reset_job_execution_fail"), err);
					this.executingJob = null;
					this.executing = false;
				});
		},

		getStateName(job) {
			switch (job.state) {
				case 0:
					return this.$i18n.t("job_state.waiting");
				case 1:
					return job.do_execute ? this.$i18n.t("job_state.executing") : this.$i18n.t("job_state.planning");
				case 2:
					return job.do_execute ? this.$i18n.t("job_state.finished") : this.$i18n.t("job_state.planned");
				case 3:
					return this.$i18n.t("job_state.failed");
			}
		},

		percentColor(val, t1, t2) {
			return {
				"text-danger": val < t1,
				"text-warning": val >= t1 && val < t2,
				"text-success": val >= t2,
			};
		},

		getBlankChart() {
			return {
				type: "bar",
				data: {
					labels: [],
					datasets: [
						{
							label: "%",
							xAxisID: "sp",
							yAxisID: "freq",
							backgroundColor: `${ThemeService.getThemeColorHex()}77`,
							borderColor: `${ThemeService.getThemeColorHex()}C0`,
							borderWidth: 1,
							hoverBackgroundColor: `${ThemeService.getThemeColorHex()}C0`,
							hoverBorderColor: `${ThemeService.getThemeColorHex()}FF`,
							hoverBorderWidth: 1,
							pointHitRadius: 40,
							data: [],
							counts: [],
						},
					],
				},
				options: {
					tooltips: {
						mode: "point",
						displayColors: false,
						callbacks: {
							title: (items, data) => {
								let sp = items[0].xLabel;
								let fullName = data.datasets[0].fullName;
								return `${fullName}: ${sp}`;
							},
							label: (item, data) => {
								let i = item.datasetIndex;
								let j = item.index;
								let count = data.datasets[i].counts[j].count;
								let total = data.datasets[i].counts[j].total;
								let per = data.datasets[i].data[j];
								return `${count} ${this.$i18n.t("data_description.out_of")} ${total} (${per}%)`;
							},
						},
					},
					legend: {
						display: false,
					},
					scales: {
						xAxes: [
							{
								id: "sp",
								scaleLabel: {
									display: "true",
									labelString: this.$i18n.t("fields.score_point"),
								},
							},
						],
						yAxes: [
							{
								id: "freq",
								scaleLabel: {
									display: "true",
									labelString: this.$i18n.t("fields.frequency"),
								},
								ticks: {
									suggestedMin: 0,
									suggestedMax: 100,
								},
							},
						],
					},
				},
			};
		},
	},
};
</script>
