<template>
	<div class="d-flex flex-row align-items-center position-relative">
		<div class="dtp-date position-relative mr-2" :class="{ dtpFull: noTime }">
			<i class="fa fa-calendar"></i>
			<date-picker
				:value="mut_date"
				@input="updateDate"
				:config="dateConfig"
				:disabled="disabled"
				:class="{ 'invalid is-invalid': invalid }"
			></date-picker>
		</div>
		<div v-if="!noTime" class="dtp-time position-relative">
			<i class="fa fa-clock"></i>
			<date-picker
				:value="mut_time"
				@input="updateTime"
				:config="timeConfig"
				:disabled="disabled"
				:class="{ 'invalid is-invalid': invalid }"
			></date-picker>
		</div>
	</div>
</template>

<style scoped>
input.form-control {
	padding: 0.375rem 0.375rem 0.375rem calc(20px + 0.375rem);
}
i {
	position: absolute;
	left: 8px;
	top: 10px;
	font-size: 15px;
	color: rgba(120, 130, 140, 0.4);
}
.dtp-date {
	flex: 0.55;
}
.dtp-time {
	flex: 0.45;
}
.dtpFull {
	flex: 1;
}
</style>

<script>
const dateFormat = "L";
const timeFormat = "h:mm A";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import DatePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";

export default {
	name: "ConfigDate",

	props: {
		value: {},
		config: {},
		disabled: {},
		defaultTime: {
			type: String,
			default: "12:00 AM",
		},
		noTime: {},
		invalid: true,
	},

	components: { DatePicker },

	data() {
		return {
			mut_date: null,
			mut_time: null,
			dateConfig: {
				format: dateFormat,
				locale: moment.locale(),
				viewMode: "days",
				icons: {
					time: "fa fa-clock",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun",
					clear: "fa fa-calendar-times",
					close: "fa fa-remove",
				},
			},
			timeConfig: {
				format: timeFormat,
				viewMode: "days",
				icons: {
					time: "fa fa-clock",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun",
					clear: "fa fa-calendar-times",
					close: "fa fa-remove",
				},
			},
		};
	},
	created() {
		_.each(this.config, (option, name) => {
			this.dateConfig[name] = option;
		});

		if (this.value) {
			this.mut_date = moment(this.value).format(dateFormat);
			this.mut_time = moment(this.value).format(timeFormat);
		}
	},
	watch: {
		value() {
			console.log("VALUE CHANGED", this.value);
			if (this.value) {
				this.mut_date = moment(this.value).format(dateFormat);
				this.mut_time = moment(this.value).format(timeFormat);
			} else {
				this.mut_date = "";
				this.mut_time = "";
			}
		},
	},
	computed: {},
	methods: {
		updateDate(val) {
			this.mut_date = val;
			if (!val) {
				this.mut_time = null;
			} else if (!this.mut_time) {
				this.mut_time = this.defaultTime;
			}
			this.$emit("input", this.combineDate(this.mut_date, this.mut_time));
		},
		updateTime(val) {
			console.log("change time", val);
			if (val && !this.mut_time) {
				this.mut_time = this.defaultTime;
			} else {
				this.mut_time = val;
			}
			if (this.mut_time && !this.mut_date) {
				this.mut_date = moment().format(dateFormat);
			}
			this.$emit("input", this.combineDate(this.mut_date, this.mut_time));
		},
		combineDate(date, time) {
			if (!date && time) {
				return null;
			}
			date = moment(date, dateFormat);
			time = moment(time, timeFormat);
			let combined = moment([
				date.year(),
				date.month(),
				date.date(),
				time.hour(),
				time.minute(),
				0,
				0,
			]).toISOString();
			return combined;
		},
	},
};
</script>
