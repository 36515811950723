//Driver for normal scoring (1st/2nd) Reads

import axios from "axios";

export default {
	icon: "check",
	name: "Scoring",
	allowUpdateScore: true, //Only for QC sets
	showScores() {
		return false;
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: true,
	canChoosePreviousAnnotations: true,

	getNext(ctx) {
		console.log("Qual Only Driver", ctx)
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		let params = {};

		params.qualonly = true

		var promise = axios.get(`/v1/score/${sid}/${iid}`, { params: params });
		promise.then(function (resp) {
			console.log("Qual Only SD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	}
	
};
