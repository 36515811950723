<template>
	<div ref="dropZone" class="drop-zone" :class="{ active: dropping || uploading }">
		<div v-if="uploading" class="">
			<div class="overlay d-flex flex-row align-items-center justify-content-center">
				<slot name="uploading">
					<div class="progress my-1" style="height: 20px; width: 150px">
						<div class="progress-bar theme-lighten-3" :style="{ width: '50%' }"></div>
					</div>
				</slot>
			</div>
		</div>
		<div v-else-if="dropping">
			<div
				class="overlay d-flex flex-row align-items-center justify-content-center"
				:class="{ 'dropping-over': droppingOver }"
			>
				<slot name="drop">
					<h3 class="text-muted mb-0">Drop files here!</h3>
				</slot>
			</div>
		</div>
	</div>
</template>

<style scoped>
.drop-zone {
	background-color: rgba(128 128 128 / 50%);
	transition: opacity 0.25s;
}
.drop-zone:not(.active) {
	display: none;
	opacity: 0;
}
.drop-zone.active {
	display: initial;
	position: absolute;
	inset: 0;
	opacity: 1;
}
.overlay {
	position: absolute;
	inset: 0;
	background: white;
}
.dropping-over {
	background-color: lightgrey;
}
</style>

<script>
export default {
	name: "DropZone",
	props: ["uploading"],

	data() {
		return {
			dropping: false,
			droppingOver: false,
			leaveTimeout: null,
		};
	},
	created() {
		document.addEventListener("dragenter", this.documentDragEnter);
		document.addEventListener("dragover", this.documentDragEnter);
		document.addEventListener("dragleave", this.documentDragLeave);
		document.addEventListener("drop", this.drop);
	},
	destroyed() {
		document.removeEventListener("dragenter", this.documentDragEnter);
		document.removeEventListener("dragover", this.documentDragEnter);
		document.removeEventListener("dragleave", this.documentDragLeave);
		document.removeEventListener("drop", this.drop);
	},
	watch: {},
	methods: {
		documentDragEnter(e) {
			e.preventDefault();

			if (this.leaveTimeout) {
				clearTimeout(this.leaveTimeout);
				this.leaveTimeout = null;
			}
			if (this.$refs.dropZone.contains(e.target)) {
				this.debug("Dropping over");
				this.droppingOver = true;
			} else {
				this.debug("Not Dropping over");
				this.droppingOver = false;
			}

			this.dropping = true;
		},

		documentDragLeave(e) {
			e.preventDefault();

			if (this.leaveTimeout) {
				clearTimeout(this.leaveTimeout);
				this.leaveTimeout = null;
			}
			this.leaveTimeout = setTimeout(() => {
				this.dropping = false;
			}, 200);
		},

		drop(e) {
			if (this.uploading) {
				this.log("Can't drop while uploading");
				return;
			}

			this.dropping = false;
			this.log("Drop", e);
			if (this.$refs.dropZone.contains(e.target)) {
				e.preventDefault();
				this.$emit("fileDropped", e);
			} else {
				this.log("Drop outside of drop zone", e);
			}
		},
	},
};
</script>
