<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ProjectEdit.Backreading.title") }}</div>
		<div role="form" class="p-4 col-sm-6">
			<div class="row">
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
					<label style="min-width:115px" class="no-grow mb-0 mr-3">{{ $t("ProjectEdit.Backreading.backread_target") }}:</label>
					<percent-input v-model="project.backreading_target_percent" style="min-width: 82px" />
					<label style="min-width:100px" class="no-grow mb-0 ml-3">({{ displayInterval }})</label>
				</div>
			</div>
            	<div class="row">
				<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
                    <div class="form-group">
                        <label style="min-width:150px" class="no-grow mb-0 mr-3">{{ $t("ProjectEdit.Backreading.lock_target") }}:</label>
                        <label class="ui-switch ui-switch-md theme-accent">
                            <input type="checkbox" v-model="project.backreading_target_lock" />
                            <i></i>
                        </label>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import PercentInput from "@/components/PercentInput";
import store from "@/services/Store";

export default {
	name: "ProjectEditBackreading",

	props: ["id", "project", "defaultTab", "client"],

    components: { PercentInput },

	data() {
		return {
			percent: 100,
		};
	},

	computed: {
		interval() {
			if (this.project.backreading_target_percent && this.project.backreading_target_percent > 0) {
				return Math.floor(100 / this.project.backreading_target_percent);
			} else {
				return 0;
			}
		},

		displayInterval() {
			switch (this.interval) {
				case 0:
					return "Random";
				case 1:
					return "All";
				default:
					return `1 ${this.$i18n.t("data_description.every")} ${this.interval}`;
			}
		},
	},

	created() {},

	methods: {}
};
</script>
