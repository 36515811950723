<template>
	<div :class="{ show: showingDropdown, 'no-w': !master }">
		<!-- Master -->
		<a v-if="master" class="btn white dropdown-toggle p-1 ml-1" aria-expanded="false" @click="toggleDropdown">
			<span>
				<i class="material-icons checkbox" :class="{ 'theme-accent': checkedAggregate != 'none' }">{{
					iconAggregate
				}}</i>
			</span>
		</a>
		<div
			v-if="master"
			class="dropdown-menu dropdown-menu-right w animate fadeIn"
			:class="{ show: showingDropdown }"
		>
			<a
				@click="
					($event) => {
						select('all', $event);
					}
				"
				class="dropdown-item"
			>
				<div>
					<i class="theme-accent material-icons checkbox">done_all</i>
					<label class="mb-0">All</label>
					<span class="text-xxs text-muted float-right">({{ this.totalRows }})</span>
				</div>
			</a>
			<a
				@click="
					($event) => {
						select('page-all', $event);
					}
				"
				class="dropdown-item"
			>
				<div>
					<i class="theme-accent material-icons checkbox">remove</i>
					<label class="mb-0">All on this page</label>
					<span class="text-xxs text-muted float-right">({{ this.items.length }})</span>
				</div>
			</a>
			<a
				@click="
					($event) => {
						select('page-none', $event);
					}
				"
				class="dropdown-item"
			>
				<div>
					<i class="material-icons checkbox unchecked">remove</i>
					<label class="mb-0">None on this page</label>
				</div>
			</a>
			<a
				@click="
					($event) => {
						select('none', $event);
					}
				"
				class="dropdown-item"
			>
				<div>
					<i class="material-icons checkbox unchecked"></i>
					<label class="mb-0">None</label>
				</div>
			</a>
			<a v-if="sampleCallback" @click="showSampleModal = true" class="dropdown-item">
				<div>
					<i class="far fa-dice"></i>
					<label class="mb-0">Random Sample</label>
				</div>
			</a>

			<b-modal
				v-if="sampleCallback"
				ref="modal"
				size="sm"
				:visible="showSampleModal"
				@hide="showSampleModal = false"
			>
				<template #modal-header>
					<div>
						<h5 class="mb-0">Select Responses Randomly</h5>
					</div>
				</template>
				<div class="row">
					<div class="col-12">
						<label># of responses to select</label>
						<input class="form-control" v-model.number="sampleNum" />
						<small class="mt-1 text-muted d-block"
							><i class="far fa-sm fa-exclamation-circle mr-1" />Note: If the number is large, this may
							take a while.</small
						>
						<small class="mt-1 text-muted d-block">
							<i class="far fa-sm fa-exclamation-circle mr-1" />Note: If the number is small compared to
							the total query set, you may not see any selections on the current page.
						</small>
					</div>
				</div>
				<template #modal-footer>
					<button class="btn btn-secondary" @click="showSampleModal = false">
						{{ $t("buttons.cancel") }}
					</button>
					<button v-if="!sampling" class="ml-2 btn btn-primary" @click="doSample">Select</button>
					<button v-else class="ml-2 btn btn-primary" disabled>
						<loading type="icon" class="mr-1" />Selecting...
					</button>
				</template>
			</b-modal>
		</div>

		<!-- One row -->
		<div v-if="!master" class="checkbox left-align">
			<label class="md-check md-check-md">
				<input type="checkbox" @click="toggle" :checked="checked(id)" />
				<i class="theme-accent"></i>
			</label>
		</div>
	</div>
</template>

<style scoped>
.left-align {
	margin-left: calc(0.5rem + 1px);
}
.no-w {
	max-width: 0px;
}
i.checkbox {
	width: 18px;
	height: 18px;
	font-size: 18px;
	border-radius: 2px;
	line-height: 1;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: -2px;
	outline: 2px solid rgba(120, 130, 140, 0.2);
	outline-offset: -2px;
	opacity: 0.7;
	transition: opacity 0.25s;
}
a:hover i.checkbox {
	opacity: 1;
}
.md-check > i:before {
	left: 0;
	opacity: 0.7;
	transition: opacity 0.25s;
}
.md-check > i:hover:before {
	opacity: 1;
}
</style>

<script>
import $ from "jquery";

export default {
	name: "TableMultiselect",

	props: {
		//A list of ids representing all selected elements
		//When "selectAll" is true, this represents all *excluded* elements instead
		//Intended to be shared between many TableMultiselect components (1 master and several non-master)
		value: {
			type: Array,
			default: () => {
				return [];
			},
		},
		//.sync prop for master
		//Regular prop for non-master
		//True if the multiselect should select records even outside of the current page
		//When this is true, the 'value' prop represents exclusions instead of inclusions
		//On a non-master, this simply inverts the checkbox behavior
		selectAll: {
			default: false,
		},

		//Props for master

		//Optional
		//When true, this will be the master muiltiselect dropdown that controls all other multiselect checkboxes on the same vmodel
		master: {
			type: Boolean,
			default: false,
		},
		//A array of elements that defines which are displayed on the current page
		//If prop "byField" is true, this can be a list of objects with that field treated as the id
		//If prop "byField" is false, this should be a list of ids
		items: {
			type: Array,
			default: () => {
				return [];
			},
		},
		//Optional
		//Defines a field of the elements in the "items" prop to use as an id
		byField: {
			default: null,
		},
		//The total number of elements across all pages of the table
		//Needed so the component can display information about how many elements are selected
		totalRows: {
			default: 0,
		},
		//.sync prop
		//Equal to the number of expected selected elements, with logic for inverting based on selectAll
		numSelected: {
			default: null,
		},
		//An object representing the query determining which elements are available overall
		//Changing this will reset the selection, although certain parameter will not count as an actual change
		//(Standard sorting and pagination parameters: sortBy, sortDesc, currentPage, perPage)
		query: {
			default: null,
		},
		// When defined, the selector dropdown will contain a "Random Sample" option. Clicking that option will first
		// open a modal allowing the user to enter a number to sample, and then call sampleCallback with that number.
		// This should either return a list of ids, keyed off of the "byField" property, that represents a random sample
		// of the selectable set; or a promise that resolves to the same.
		sampleCallback: {
			default: null,
		},

		//Props for non-master

		//Key to denote this component in the master's list
		//Should be unique
		id: {
			default: null,
		},
	},

	data() {
		return {
			showingDropdown: false,
			mutSelectAll: false,
			showSampleModal: false,
			sampleNum: 1,
			sampling: false,
		};
	},

	created() {
		if (this.master) {
			$(window).on("click.tablemultiselect", (event) => {
				if (!event.target.matches(".dropdown-toggle")) {
					this.showingDropdown = false;
				}
			});
		}
	},

	destroyed() {
		if (this.master) {
			$(window).off("click.tablemultiselect");
		}
	},

	watch: {
		selectAll() {
			if (this.mutSelectAll != this.selectAll) {
				this.mutSelectAll = this.selectAll;
			}
		},
		mutSelectAll() {
			this.updateNumSelected();
			this.$emit("update:selectAll", this.mutSelectAll);
		},
		value() {
			this.updateNumSelected();
		},

		query(newVal, oldVal) {
			let n = this.depaginate(newVal);
			let o = this.depaginate(oldVal);
			if (!_.isEqual(n, o)) {
				this.select("none");
			}
		},
	},

	computed: {
		checkedAggregate() {
			if (this.mutSelectAll) {
				if (this.value.length == 0) {
					return "all";
				} else if (this.value.length < this.totalRows) {
					return "some";
				} else {
					return "none";
				}
			} else {
				if (this.value.length == 0) {
					return "none";
				} else if (this.value.length < this.totalRows) {
					return "some";
				} else {
					return "all";
				}
			}
		},

		iconAggregate() {
			switch (this.checkedAggregate) {
				case "none":
					return "";
				case "some":
					return "remove";
				case "all":
					return "done_all";
			}
		},
	},

	methods: {
		toggleDropdown(event) {
			event.stopPropagation();
			event.preventDefault();
			this.count++;
			this.showingDropdown = !this.showingDropdown;
		},

		select(selection, event) {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}
			switch (selection) {
				case "all":
					while (this.value.length > 0) {
						this.value.pop();
					}
					this.mutSelectAll = true;
					this.$emit("input", this.value);
					break;
				case "page-all":
					_.each(this.items, (item) => {
						let id = item;
						if (this.byField) {
							id = id[this.byField];
						}
						this.toggleOn(id);
					});
					this.$emit("input", this.value);
					break;
				case "page-none":
					_.each(this.items, (item) => {
						let id = item;
						if (this.byField) {
							id = id[this.byField];
						}
						this.toggleOff(id);
					});
					this.$emit("input", this.value);
					break;
				case "none":
					while (this.value.length > 0) {
						this.value.pop();
					}
					this.mutSelectAll = false;
					this.$emit("input", this.value);
					break;
			}
			this.showingDropdown = false;
		},

		toggle() {
			if (!this.checked(this.id)) {
				this.toggleOn(this.id);
			} else {
				this.toggleOff(this.id);
			}
		},

		toggleOn(id) {
			if (this.selectAll) {
				this.pull(id);
			} else {
				this.push(id);
			}
		},

		toggleOff(id) {
			if (this.selectAll) {
				this.push(id);
			} else {
				this.pull(id);
			}
		},

		push(id) {
			if (!_.includes(this.value, id)) {
				this.value.push(id);
				this.$emit("input", this.value);
			}
		},

		pull(id) {
			_.pull(this.value, id);

			//This forces Vue to see the value as having updated
			//I don't really know why
			//We're not really supposed to directly alter prop arrays in the first place
			this.value.push("");
			this.value.pop();

			this.$emit("input", this.value);
		},

		selectIDs(ids) {
			while (this.value.length > 0) {
				this.value.pop();
			}
			this.mutSelectAll = false;
			for (let id of ids) {
				this.value.push(id);
			}
			this.$emit("input", this.value);
		},

		checked(id) {
			if (this.selectAll) {
				return !_.includes(this.value, id);
			} else {
				return _.includes(this.value, id);
			}
		},

		updateNumSelected() {
			if (this.master) {
				let count = 0;
				if (this.mutSelectAll) {
					count = this.totalRows - this.value.length;
				} else {
					count = this.value.length;
				}
				this.$emit("update:numSelected", count);
			}
		},

		//Remove
		depaginate(ctx) {
			let newCtx = _.cloneDeep(ctx);
			if (newCtx && typeof newCtx == "object") {
				delete newCtx.sortBy;
				delete newCtx.sortDesc;
				delete newCtx.currentPage;
				delete newCtx.perPage;
				delete newCtx.apiUrl;
			}
			return newCtx;
		},

		async doSample() {
			if (!this.sampleCallback) {
				this.logError("No sampleCallback", this.sampleCallback);
			}
			this.sampling = true;
			let res = this.sampleCallback(this.sampleNum);
			if (typeof res == "array") {
				this.log("Got random IDs", res);
				this.selectIDs(res);
			} else if (typeof res == "object" && res.then) {
				let ids = await res;
				this.log("Got random IDs", ids);
				this.selectIDs(ids);
			}
			this.sampling = false;
			this.showSampleModal = false;
			this.showingDropdown = false;
		},
	},
};
</script>
