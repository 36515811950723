<template>
	<page :title="$t('BulletinBoard.title')">
		<template #navbar>
			<div class="py-0">
				<button
					v-if="user.role.bulletin_board_edit"
					@click="newBulletin"
					class="btn btn-sm theme-accent btn-block"
					v-tippy
					:title="$t('tooltip.new_bulletin')"
				>
					<i class="far fa-fw fa-plus"></i>
					<span class="hidden-folded d-inline">
						{{ $t("buttons.new_bulletin") }}
					</span>
				</button>
			</div>
		</template>

		<div v-if="bulletins.length > 0" class="padding">
			<!-- Page content goes here -->
			<div class="row justify-content-start">
				<div v-for="bulletin in bulletins" :key="bulletin.id" class="col-sm-12 col-md-6 col-lg-6 fadeIn">
					<Bulletin :bulletin="bulletin" :user="user" @change="loadData" @cancel="removeUnsaved" />
				</div>
			</div>
			<div v-if="bulletins.length == 0">
				<loading type="large" />
			</div>
		</div>

		<div
			v-if="bulletins.length == 0 && !loading"
			class="padding text-center h-100 d-flex flex-column justify-content-center"
		>
			<h1 class="text-very-muted _600 mb-4">{{ $t("data_description.no_bulletins") }}</h1>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.landing-box {
	min-height: 200px;
	height: 100%;
}
</style>

<script>
import BulletinService from "@/services/BulletinService";
import notie from "@/services/NotieService";

import Bulletin from "@/components/Bulletin";
import Utils from "@/services/Utils";

export default {
	name: "BulletinBoard",

	props: ["user"],

	components: { Bulletin },

	data() {
		return {
			bulletins: [],
			loading: true,
		};
	},
	created() {
		this.loadData();
		console.log(Bulletin);
		console.log(this);
	},
	methods: {
		loadData() {
			this.loading = true;
			let ctx = {
				perPage: 10,
				currentPage: 0,
			};
			BulletinService.listBulletins(ctx)
				.then((resp) => {
					this.bulletins = resp.data.bulletins;
					this.loading = false;
					Utils.throttleMathJAX();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.list_bulletins_fail"), err);
					this.loading = false;
				});
		},

		newBulletin() {
			if (this.bulletins.length >= 10) {
				this.bulletins.pop();
			}
			this.bulletins.unshift({
				editing: true,
				title: this.$i18n.t("BulletinBoard.new_bulletin"),
				body: "",
				role_id: null,
			});
		},

		removeUnsaved() {
			let prevLength = this.bulletins.length;
			this.bulletins = _.filter(this.bulletins, "id");
			Utils.throttleMathJAX();
			if (this.bulletins.length != prevLength) {
				this.loadData();
			}
		},
	},
};
</script>
