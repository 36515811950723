import {
	ConsoleLogger,
	DefaultDeviceController,
	DefaultActiveSpeakerPolicy,
	DefaultMeetingSession,
	DefaultModality,
	LogLevel,
	MeetingSessionConfiguration,
	MeetingSessionStatusCode,
	VoiceFocusDeviceTransformer,
	VideoFxProcessor,
	DefaultVideoTransformDevice,
} from "amazon-chime-sdk-js";
import VideoMeetingService from "@/services/VideoMeetingService";
import Notie from "@/services/NotieService";
import vm from "@/main";
import Store from "@/services/Store";
import fs from "@/services/FormatService";

import PrettyLogger from "@/services/PrettyLogger";
const { log, debug, logWarn, logError } = PrettyLogger.forName("ChimeEngine");

export default {
	log: log,
	debug: debug,
	logWarn: logWarn,
	logError: logError,

	chimeLogger: null,
	meetingSession: null,
	av: null,

	meetingMagic: null,
	attendeeMagic: null,
	selfId: null,
	selfAttendeeId: null,

	audioOutputElement: null,
	precallAudioAnalyser: null,
	shouldEnableAudio: false,
	shouldEnableVideo: false,
	isAudioPublished: false,
	isVideoPublished: false,
	initialized: false,
	anonUsername: "",

	voiceFocusTransformer: null,
	videoFxProcessor: null,
	voiceFocusDevices: {},
	videoTransformDevices: {},

	meeting: null,
	meetingUsers: null,
	authed: false,

	attendees: [],
	unusedVideoElements: [],

	meeting: null,
	meetingUsers: null,

	uiState: null,
	participants: null,
	localParticipant: null,
	devices: null,

	deviceChangeObserver: null,
	sessionObserver: null,

	isLocal(userId) {
		return userId == this.selfId;
	},

	handleError(...inputs) {
		this.logError(...inputs);
		Notie.error(...inputs);
	},

	handleUserError(...inputs) {
		this.logError(...inputs);
		Notie.userError(...inputs);
	},

	inMeeting() {
		return this.uiState && this.uiState.inSession ? true : false;
	},

	async init(vm, meetingID) {
		this.log("Init with meeting ID", meetingID);

		if (!this.initialized) {
			this.chimeLogger = new ConsoleLogger("ChimeSDK", LogLevel.INFO);
			this.av = new DefaultDeviceController(this.chimeLogger, { enableWebAudio: true });
			// av will hold the DeviceController for now, and will eventually be upgraded to
			// hold the full meeting session.

			this.uiState = vm.uiState;
			this.devices = vm.devices;
			this.participants = vm.participants;
			this.localParticipant = vm.localParticipant;
			this.meetingUsers = vm.meetingUsers;

			await this.queryMicrophonePermissions();
			await this.queryCameraPermissions();

			await this.initNew(vm, meetingID);
			this.initialized = true;
		} else {
			vm.uiState = this.uiState;
			vm.devices = this.devices;
			vm.participants = this.participants;
			vm.localParticipant = this.localParticipant;
			vm.meetingUsers = this.meetingUsers;
			this.connectVideoTiles();
		}
		this.uiState.engineReady = true;
		this.uiState.permissions.voiceFocus = await VoiceFocusDeviceTransformer.isSupported();
		this.uiState.permissions.backgroundBlur = await VideoFxProcessor.isSupported();
	},

	async initNew(vm, meetingID) {
		this.initStoredValues(vm);
		this.initDevices();

		await this.loadMeeting(meetingID);
		this.addLocalParticipant();

		this.initVoiceFocus();
		this.initBackgroundBlur();
	},

	initStoredValues(vm) {
		this.shouldEnableAudio =
			Store.getDefault(vm, "VideoMeeting.shouldEnableAudio", false) &&
			this.uiState.permissions.microphone.allowed;
		this.shouldEnableVideo =
			Store.getDefault(vm, "VideoMeeting.shouldEnableVideo", false) && this.uiState.permissions.camera.allowed;

		this.devices.audioInputId = Store.get(vm, "VideoMeeting.audioInputId");
		this.devices.audioOutputId = Store.get(vm, "VideoMeeting.audioOutputId");
		this.devices.videoInputId = Store.get(vm, "VideoMeeting.videoInputId");

		if (Store.getDefault(vm, "VideoMeeting.voiceFocus", false)) {
			this.uiState.changingVoiceFocus = true;
		}
		if (Store.getDefault(vm, "VideoMeeting.backgroundBlur", false)) {
			this.uiState.changingBackgroundBlur = true;
		}
		this.anonUsername = Store.getDefault(vm, "VideoMeeting.anonUsername", "");
	},

	async initDevices() {
		this.initAudioInputDevices();
		this.initAudioOutputDevices();
		this.initVideoInputDevices();

		this.deviceChangeObserver = {
			audioInputsChanged: (deviceList) => this.audioInputsChanged(deviceList),
			audioOutputsChanged: (deviceList) => this.audioOutputsChanged(deviceList),
			videoInputsChanged: (deviceList) => this.audioInputsChanged(deviceList),
			audioInputMuteStateChanged: (device, muted) => this.audioInputMuteStateChanged(device, muted),
			videoInputStreamEnded: (deviceId) => this.videoInputStreamEnded(deviceId),
			audioInputStreamEnded: (deviceId) => this.audioInputStreamEnded(deviceId),
			eventDidReceive: (name, attributes) => this.eventDidReceive(name, attributes),
		};
		this.av.addDeviceChangeObserver(this.deviceChangeObserver);
	},

	async initAudioInputDevices(forceUpdate) {
		this.devices.audioInputs = await this.av.listAudioInputDevices(forceUpdate);
		this.logWarn("Got audio input devices", this.devices.audioInputs);
		this.devices.audioInputs = this.unwrapDevices(this.devices.audioInputs);
		this.devices.audioInputId = this.getCorrectedValue(this.devices.audioInputs, this.devices.audioInputId);
		this.pickAudioInput(this.devices.audioInputId);
	},

	async initAudioOutputDevices(forceUpdate) {
		this.devices.audioOutputs = await this.av.listAudioOutputDevices(forceUpdate);
		this.logWarn("Got audio output devices", this.devices.audioOutputs);
		this.devices.audioOutputs = this.unwrapDevices(this.devices.audioOutputs);
		this.devices.audioOutputId = this.getCorrectedValue(this.devices.audioOutputs, this.devices.audioOutputId);
		this.pickAudioOutput(this.devices.audioOutputId);
	},

	async initVideoInputDevices(forceUpdate) {
		this.devices.videoInputs = await this.av.listVideoInputDevices(forceUpdate);
		this.logWarn("Got video input devices", this.devices.videoInputs);
		this.devices.videoInputs = this.unwrapDevices(this.devices.videoInputs);
		this.devices.videoInputId = this.getCorrectedValue(this.devices.videoInputs, this.devices.videoInputId);
		this.pickVideoInput(this.devices.videoInputId);
	},

	unwrapDevices(deviceList) {
		let groupMap = {};
		let unwrapped = [];
		for (let device of deviceList) {
			if (groupMap[device.groupId]) {
				// We already have this device
				continue;
			} else {
				groupMap[device.groupId] = true;
				unwrapped.push({
					deviceId: device.deviceId,
					groupId: device.groupId,
					label: device.label,
				});
			}
		}
		return unwrapped;
	},

	getCorrectedValue(deviceList, deviceId) {
		if (!(deviceList && deviceList.length)) {
			return null;
		}

		let device = _.find(deviceList, { deviceId: deviceId });
		if (!device) {
			device = deviceList[0];
		}
		return device ? device.deviceId : null;
	},

	async pickAudioInput(deviceId) {
		this.log("Pick audio input", deviceId);
		if (deviceId != this.audioInputId) {
			if (this.localParticipant.muted || !this.uiState.permissions.microphone.allowed) {
				// If audio isn't recording, just change the device ID for whenever we start it again
				this.devices.audioInputId = deviceId;
				return;
			}

			try {
				await this.startAudioInput(deviceId);
				this.audioInputId = deviceId;
			} catch (e) {
				this.handleError("Failed to pick audio input", e);
			}
		}
	},

	async pickAudioOutput(deviceId) {
		if (deviceId != this.audioOutputId) {
			try {
				await this.av.chooseAudioOutput(deviceId);
				this.audioOutputId = deviceId;
			} catch (e) {
				this.handleError("Failed to pick audio output", e);
			}
		}
	},

	async pickVideoInput(deviceId) {
		this.log("Pick video input", deviceId);
		if (deviceId != this.videoInputId) {
			if (!this.localParticipant.video) {
				// If video isn't recording, just change the device ID for whenever we start it again
				this.videoInputId = deviceId;
				return;
			}

			this.localParticipant.videoLoading = true;
			try {
				await this.startVideoInput(deviceId);
				this.videoInputId = deviceId;

				if (!this.uiState.inSession) {
					await this.av.startVideoPreviewForVideoInput(this.localParticipant.video);
				}
			} catch (e) {
				this.localParticipant.videoLoading = false;
				this.handleError("Failed to pick video input", e);
			}
			// participant.videoLoading will be set to false when the video element's 'play' event fires
		}
	},

	audioInputsChanged(newDeviceList) {
		this.devices.audioInputs = this.unwrapDevices(newDeviceList);
		this.log("Audio inputs changed", this.devices.audioInputs);
		this.devices.audioInputId = this.getCorrectedValue(this.devices.audioInputs, this.devices.audioInputId);
		this.pickAudioInput(this.devices.audioInputId, this.precallAudioAnalyser);
	},

	audioOutputsChanged(newDeviceList) {
		this.devices.audioOutputs = this.unwrapDevices(newDeviceList);
		this.log("Audio outputs changed", this.devices.audioOutputs);
		this.devices.audioOutputId = this.getCorrectedValue(this.devices.audioOutputs, this.devices.audioOutputId);
		this.pickAudioOutput(this.devices.audioOutputId);
	},

	videoInputsChanged(newDeviceList) {
		this.devices.videoInputs = this.unwrapDevices(newDeviceList);
		this.log("Video inputs changed", this.devices.videoInputs);
		this.devices.videoInputId = this.getCorrectedValue(this.devices.videoInputs, this.devices.videoInputId);
		this.pickVideoInput(this.devices.videoInputId);
	},

	audioInputMuteStateChanged(device, muted) {
		console.log("audioInputMuteStateChanged", this, this._this);
		this.log("Device", device, "hardware mute state changed", muted);
	},

	audioInputStreamEnded(deviceId) {
		this.handleUserError("Your audio input stream ended unexpectedly");
		this.localParticipant.muted = true;
	},

	videoInputStreamEnded(deviceId) {
		this.handleUserError("Your video input stream ended unexpectedly");
		if (this.localParticipant.video) {
			if (this.uiState.inSession) {
				this.av.stopLocalVideoTile();
				// await this.av.stopVideoInput();
				this.av.removeLocalVideoTile();
			} else {
				this.av.stopVideoPreviewForVideoInput(this.localParticipant.video);
				this.releasedVideoElement(this.localParticipant.video);
				this.localParticipant.video = null;
			}
		}
		this.localParticipant.videoLoading = false;
	},

	async loadMeeting(meetingID) {
		try {
			if (this.uiState.password) {
				await this.loadMeetingWithPassword(meetingID, this.uiState.password, this.uiState.username);
			} else {
				await this.loadMeetingAsUser(meetingID);
			}
		} catch (e) {
			console.warn(e);
			Notie.error("Failed to initialize meeting", e);
			throw e;
		}
	},

	async loadMeetingWithPassword(meetingID, password, username) {
		let {
			data: { meeting },
		} = await VideoMeetingService.anonGetMeeting(meetingID, password, username);

		this.debug("Got meeting", meeting);
		this.uiState.meeting = meeting;

		let {
			data: { meetingUsers },
		} = await VideoMeetingService.anonGetMeetingUsers(meetingID, password, username);
		this.meetingUsers.length = 0;
		this.meetingUsers.push(...meetingUsers);
	},

	async loadMeetingAsUser(meetingID) {
		let {
			data: { meeting, userID },
		} = await VideoMeetingService.getMeeting(meetingID);
		this.debug("Got meeting", meeting);
		this.uiState.meeting = meeting;
		this.selfId = userID;

		let {
			data: { meetingUsers },
		} = await VideoMeetingService.getMeetingUsers(meetingID);
		this.meetingUsers.length = 0;
		this.meetingUsers.push(...meetingUsers);

		this.uiState.authed = true;
	},

	async initVoiceFocus() {
		try {
			this.voiceFocusTransformer = await VoiceFocusDeviceTransformer.create();
			this.uiState.permissions.voiceFocus = this.voiceFocusTransformer.isSupported();
		} catch (e) {
			// Will only occur due to invalid input or transient errors (e.g., network).
			this.uiState.permissions.voiceFocus = false;
		}
		this.uiState.permissions.voiceFocus = true;

		this.uiState.voiceFocus = Store.getDefault(vm, "VideoMeeting.voiceFocus", false);
		if (this.uiState.voiceFocus) {
			await this.enableVoiceFocus();
		}
		this.uiState.changingVoiceFocus = false;
	},

	async initBackgroundBlur() {
		this.log("Initialize background blur processing pipeline");
		this.uiState.backgroundBlur = Store.getDefault(vm, "VideoMeeting.backgroundBlur", false);
		const videoFxConfig = {
			backgroundBlur: {
				isEnabled: this.uiState.backgroundBlur,
				strength: "high",
			},
			backgroundReplacement: {
				isEnabled: false,
				backgroundImageURL: undefined,
				defaultColor: undefined,
			},
		};

		try {
			this.videoFxProcessor = await VideoFxProcessor.create(this.chimeLogger, videoFxConfig);
		} catch (error) {
			this.handleError("Failed to initialize background blur processing pipeline", error.toString());
			this.uiState.permissions.backgroundBlur = false;
		}
		this.uiState.changingBackgroundBlur = false;

		this.debug("initBackgroundBlur", this.devices.videoInputId);
		if (this.shouldEnableVideo) {
			await this.startVideoInput(this.devices.videoInputId);
			if (this.localParticipant.video) {
				this.av.startVideoPreviewForVideoInput(this.localParticipant.video);
			}
		}
	},

	addLocalParticipant() {
		let smu = null;
		if (this.uiState.password) {
			smu = {
				id: "pseudo",
				full_name: this.anonUsername,
				initials: fs.initials(this.anonUsername),
				attendee_id: "pseudo",
			};
		} else {
			smu = _.find(this.meetingUsers, { id: this.selfId });
			if (!smu) {
				this.handleError("Failed to find self in meeting");
				return;
			}
		}

		this.debug("selfMeetingUser", smu);

		this.localParticipant.userId = smu.id;
		this.localParticipant.attendeeId = smu.attendee_id;
		this.localParticipant.name = smu.full_name;
		this.localParticipant.initials = fs.initials(smu.full_name);
		this.localParticipant.video = null;
		this.localParticipant.videoLoading = true;
		this.localParticipant.tileID = null;
		this.localParticipant.screensharing = false;
		this.localParticipant.muted = !this.shouldEnableAudio;
		this.localParticipant.volumeLevel = null;
		this.localParticipant.volumeBuffer = [];
		this.localParticipant.speaking = false;
		this.localParticipant.handUp = false;
		this.localParticipant.local = true;
		this.localParticipant.isContent = false;
		if (smu.id == "pseudo") {
			this.localParticipant.placeholder_name = "Enter Name";
			this.localParticipant.placeholder_initials = "EN";
		}

		this.participants.push(this.localParticipant);
	},

	maybeStartPrecallVideo() {
		if (this.shouldEnableVideo) {
			this.playPrecallVideo();
		} else {
			this.localParticipant.videoLoading = false;
		}
	},

	async maybeStartPrecallAudio() {
		if (this.shouldEnableAudio) {
			this.precallEnableLocalAudio();
		} else {
			this.precallDisableLocalAudio();
		}
	},

	async playPrecallVideo() {
		this.localParticipant.videoLoading = true;
		await this.startVideoInput(this.devices.videoInputId);
		this.log("Starting local video input from device", this.devices.videoInputId);
		let videoEl = this.acquireVideoElement();
		this.logWarn("playPrecallVideo video element", videoEl);
		try {
			await this.av.startVideoPreviewForVideoInput(videoEl);
			this.localParticipant.video = videoEl;
		} catch {}
	},

	analysePrecallAudio() {
		if (this.precallAudioAnalyser) {
			this.stopPrecallAudioAnalyser();
		}

		this.log(`Start precall audio analyser`);

		// Create and connect the analyser node. Chime manages the input and output for you,
		// but this can connect to anything the implements the AudioNode interface, which includes
		// <audio> and <video> elements.
		// See https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode
		// and https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createAnalyser
		this.precallAudioAnalyser = this.av.createAnalyserNodeForAudioInput();

		// fftSize is the number of samples the analyser does when performing a Fast Fourier Transform,
		// which is used for both main outputs of the analyzer. Frequency data produces a number of outputs
		// that is 1/2 of fftSize (this half-value is automatically calculated and stored in frequencyBinCount),
		// while Time Domain data produces a number of outputs equal to fftSize.
		this.precallAudioAnalyser.fftSize = 2048;

		// smoothingTimeConstant determines how much the data is smoothed over time. 0 means no smoothing is
		// applied, and 1 means a maximum amount of smoothing.
		this.precallAudioAnalyser.smoothingTimeConstant = 1;

		// You can also clamp the inputs volumes to a minimum and maximum using
		// 'minDecibels' and 'maxDecibels', but we're not doing that here, the defaults should be fine.

		// When we get data from the analyser, it will be dumped into an array. To keep it from constantly
		// re-allocating that array, we define one array to start with and keep reusing it.
		this.precallAudioAnalyser.dataArray = new Float32Array(this.precallAudioAnalyser.fftSize);

		const { calcSpeaking, calcSpeakingDebounce, cancelDebounce } = this.getCalcSpeakingCallbacks(
			this.localParticipant,
			40000
		);
		this.precallAudioAnalyser.cancelDebounce = cancelDebounce;
		// Now that the analyser is ready, we can repeatedly use it to grab data.
		this.precallAudioAnalyser.intervalWatcher = setInterval(() => {
			this.updatePrecallAudioVolume();
			calcSpeaking();
			calcSpeakingDebounce();
		}, 100);
		this.debug("Created watcher", this.precallAudioAnalyser.intervalWatcher);
	},

	updatePrecallAudioVolume() {
		// Get Time Domain data and dump it into the array.
		this.precallAudioAnalyser.getFloatTimeDomainData(this.precallAudioAnalyser.dataArray);
		// this.debug("this.precallAudioAnalyser.dataArray", this.precallAudioAnalyser.dataArray)

		// We now have 2048 values that range from -0.5 to 0.5 (I think?), representing a snapshot of the current
		// audio waveform. In terms of volume, we care about the amplitude of the these values, so
		// we need to do some math on them to get a final value.

		let round = function (num) {
			return Math.floor(num * 1000) / 1000;
		};

		// Get the sum of the squares of the waveform amplitudes. This will function as an inherent
		// absolute value, while also deemphasizing small volumes and emphasizing loud ones.
		//
		// I actually changed this to the absolute value of the cube instead of just squaring each
		// amplitude, and I think that "feels" better.
		let sum = 0;
		for (const amplitude of this.precallAudioAnalyser.dataArray) {
			sum += amplitude * amplitude * Math.abs(amplitude);
		}
		// Then take the mean, this gives us some sort of average of amplitudes of the waveform.
		let mean = sum / this.precallAudioAnalyser.dataArray.length;

		// I don't really understand this part, but it's what Agora uses.
		// This produces a final value between 0 and 100 that seems not too bad.
		let volume = Math.max(10 * Math.log10(mean) + 100, 0);

		let time = new Date().getTime();
		this.localParticipant.volumeLevel = volume;
		this.localParticipant.volumeBuffer.push({ time: time, volume: volume });
	},

	stopPrecallAudioAnalyser() {
		this.log(`Stop precall audio analyser`);
		this.debug("clear Interval", this.precallAudioAnalyser.intervalWatcher);
		clearInterval(this.precallAudioAnalyser.intervalWatcher);
		this.precallAudioAnalyser.cancelDebounce();
		this.precallAudioAnalyser.removeOriginalInputs();
		this.precallAudioAnalyser = null;
	},

	ensureParticipant(attendeeId) {
		let parti = _.find(this.participants, { attendeeId: attendeeId });
		if (!parti) {
			parti = {
				userId: null,
				attendeeId: attendeeId,
				name: null,
				initials: null,
				video: null,
				videoLoading: false,
				tileID: null,
				screensharing: false,
				muted: false,
				volumeLevel: null,
				volumeBuffer: [],
				speaking: null,
				handUp: false,
				local: false,
				isContent: false,
				role: null,
				capabilities: {
					audio: "SendReceive",
					video: "SendReceive",
					content: "SendReceive",
					chat: "SendReceive",
				},
			};
			this.participants.push(parti);
		}
		return parti;
	},

	connectVideoTiles() {
		for (let parti of this.participants) {
			this.connectVideoTile(parti);
		}
	},

	async connectVideoTile(parti) {
		this.log("Reconnecting video tile", parti.tileID, "to element");
		if (parti.video && parti.tileID) {
			parti.video.play();
		}

		// parti.videoLoading = true
		// await this.av.bindVideoElement(parti.tileID, parti.video);
		// parti.videoLoading = false
	},

	acquireVideoElement() {
		if (this.unusedVideoElements.length == 0) {
			let el = this.createNewUnusedVideoElement();
			this.unusedVideoElements.push(el);
		}

		return this.unusedVideoElements.pop();
	},

	createNewUnusedVideoElement() {
		let el = document.createElement("video");
		return el;
	},

	releasedVideoElement(videoEl) {
		this.unusedVideoElements.push(videoEl);
	},

	async videoTileDidUpdate(tileState) {
		if (!tileState.boundAttendeeId) {
			this.logWarn("videoTileDidUpdate with no boundAttendeeId", tileState);
			return;
		}

		const attendeeId = tileState.boundAttendeeId;
		this.debug("videoTileDidUpdate", tileState, attendeeId);
		const baseAttendeeId = new DefaultModality(attendeeId).base();

		let videoEl = null;
		const parti = this.ensureParticipant(attendeeId);
		if (!parti.video) {
			parti.video = this.acquireVideoElement();
		}
		parti.tileID = tileState.tileId;

		await this.av.bindVideoElement(tileState.tileId, parti.video);
		parti.videoLoading = false;
	},

	videoTileWasRemoved(tileID) {
		const parti = _.find(this.participants, { tileID: tileID });
		if (!parti) {
			this.logError("videoTileWasRemoved could not find participant with tileID", tileID);
			return;
		}

		this.releasedVideoElement(parti.video);
		parti.video = null;
		parti.videoLoading = false;
	},

	async toggleLocalVideo() {
		if (this.uiState.inSession) {
			if (this.localParticipant.video) {
				await this.inSessionDisableLocalVideo();
			} else {
				await this.inSessionEnableLocalVideo();
			}
		} else {
			if (this.localParticipant.video) {
				await this.precallDisableLocalVideo();
			} else {
				await this.precallEnableLocalVideo();
			}
		}
	},

	async precallEnableLocalVideo() {
		if (!this.localParticipant.video) {
			this.localParticipant.video = this.acquireVideoElement();
		}
		this.localParticipant.videoLoading = true;
		await this.startVideoInput(this.videoInputId);
		this.av.startVideoPreviewForVideoInput(this.localParticipant.video);
		// Results of turning on precall video will be handle when the 'play' event occurs on the video element
	},

	async precallDisableLocalVideo() {
		this.localParticipant.videoLoading = true;
		this.av.stopVideoPreviewForVideoInput(this.localParticipant.video);
		this.releasedVideoElement(this.localParticipant.video);
		this.localParticipant.video = null;
		this.localParticipant.videoLoading = false;
		this.debug("this.localParticipant.video", this.localParticipant.video);
		await this.av.stopVideoInput();
	},

	async inSessionEnableLocalVideo() {
		this.localParticipant.videoLoading = true;
		await this.startVideoInput(this.videoInputId);
		this.av.startLocalVideoTile();
		// Results of publishing local video will be handled in videoTileDidUpdate
		// (and then later when 'play' event occurs on the video element)
	},

	async inSessionDisableLocalVideo() {
		this.av.stopLocalVideoTile();
		await this.av.stopVideoInput();
		this.av.removeLocalVideoTile();
		// Results of unpublishing local video will be handled in videoTileRemoved
	},

	async toggleLocalAudio() {
		if (this.uiState.inSession) {
			if (this.localParticipant.muted) {
				await this.inSessionEnableLocalAudio();
			} else {
				await this.inSessionDisableLocalAudio();
			}
		} else {
			if (this.localParticipant.muted) {
				await this.precallEnableLocalAudio();
			} else {
				await this.precallDisableLocalAudio();
			}
		}
	},

	async precallEnableLocalAudio() {
		await this.startAudioInput(this.devices.audioInputId);
		this.analysePrecallAudio();
		this.localParticipant.muted = false;
		this.log("Enabled audio input");
	},

	async precallDisableLocalAudio() {
		await this.av.stopAudioInput();
		if (this.precallAudioAnalyser) {
			this.stopPrecallAudioAnalyser();
		}
		if (this.localParticipant) {
			this.localParticipant.muted = true;
		}
		this.log("Disabled audio input");
	},

	async inSessionEnableLocalAudio() {
		await this.startAudioInput(this.devices.audioInputId);
		let unmuted = this.av.realtimeUnmuteLocalAudio();
		if (!unmuted) {
			this.handleError("Cannot unmute audio", "Unmute not allowed");
		} else {
			this.localParticipant.muted = false;
			this.log("Enabled audio input");
		}
	},

	async inSessionDisableLocalAudio() {
		await this.av.realtimeMuteLocalAudio();
		this.localParticipant.muted = true;
		this.log("Disabled audio input");
	},

	// Wraps the DeviceController's startAudioInput and handles Voice Focus as needed.
	async startAudioInput(deviceId) {
		if (this.uiState.voiceFocus) {
			if (!this.voiceFocusDevices[deviceId]) {
				let vfDevice = await this.voiceFocusTransformer.createTransformDevice(deviceId);
				this.voiceFocusDevices[deviceId] = vfDevice;
			}
			deviceId = this.voiceFocusDevices[deviceId];
		}

		// If we're switching audio while an analyser is open (during Precall), disconnect it first
		let precallAnalyser = this.precallAudioAnalyser;
		if (precallAnalyser) {
			this.stopPrecallAudioAnalyser();
		}

		await this.av.startAudioInput(deviceId);

		// And then reconnect a new audio analyser
		if (precallAnalyser) {
			this.analysePrecallAudio();
		}
	},

	// Wraps the DeviceController's startVideoInput with a processor pipeline to handle background blur as needed.
	// Unlike the Voice Focus processor, this processor is always piped when it's available; it's not piped and unpiped
	// depending on the state of the background blur UI element. Instead, the blur option in the processor itself is
	// turned on and off as needed.
	async startVideoInput(deviceId) {
		debug("startVideoInput", deviceId);
		if (!deviceId) {
			// No device id, we can't continue
			this.logError("startVideoInput called with no device ID");
		}

		if (this.videoFxProcessor) {
			debug("had videoFxProcessor");
			if (!this.videoTransformDevices[deviceId]) {
				let vtDevice = new DefaultVideoTransformDevice(this.chimeLogger, deviceId, [this.videoFxProcessor]);
				this.videoTransformDevices[deviceId] = vtDevice;
			}
			deviceId = this.videoTransformDevices[deviceId];
		}

		await this.av.startVideoInput(deviceId);
	},

	enableVoiceFocus() {
		this.uiState.voiceFocus = true;
		if (!this.localParticipant.muted) {
			this.startAudioInput(this.devices.audioInputId);
		}
	},

	disableVoiceFocus() {
		this.uiState.voiceFocus = false;
		if (!this.localParticipant.muted) {
			this.startAudioInput(this.devices.audioInputId);
		}
	},

	async enableBackgroundBlur() {
		this.uiState.backgroundBlur = true;
		if (this.videoFxProcessor) {
			const videoFxConfig = {
				backgroundBlur: {
					isEnabled: this.uiState.backgroundBlur,
					strength: "high",
				},
				backgroundReplacement: {
					isEnabled: false,
					backgroundImageURL: undefined,
					defaultColor: undefined,
				},
			};
			try {
				await this.videoFxProcessor.setEffectConfig(videoFxConfig);
			} catch (error) {
				this.handleError("Failed to enable background blur", error.toString());
			}
		}
	},

	async disableBackgroundBlur() {
		this.uiState.backgroundBlur = false;
		if (this.videoFxProcessor) {
			const videoFxConfig = {
				backgroundBlur: {
					isEnabled: this.uiState.backgroundBlur,
					strength: "high",
				},
				backgroundReplacement: {
					isEnabled: false,
					backgroundImageURL: undefined,
					defaultColor: undefined,
				},
			};
			try {
				await this.videoFxProcessor.setEffectConfig(videoFxConfig);
			} catch (error) {
				this.handleError("Failed to enable background blur", error.toString());
			}
		}
	},

	registerAudioOutputElement(audioElement) {
		this.audioOutputElement = audioElement;
		if (this.uiState.inSession) {
			this.av.bindAudioElement(this.audioOutputElement);
		}
	},

	async createSession() {
		this.uiState.joiningSession = true;
		this.log("createSession");

		await this.joinMeeting(this.uiState.meeting.id);

		this.debug("configObjects", this.meetingMagic, this.attendeeMagic);
		// You need responses from server-side Chime API. See below for details.
		const configuration = new MeetingSessionConfiguration(this.meetingMagic, this.attendeeMagic);
		this.debug("MeetingSessionConfiguration", configuration);

		// In the usage examples below, you will use this meetingSession object.
		this.meetingSession = new DefaultMeetingSession(configuration, this.chimeLogger, this.av);
		this.av = this.meetingSession.audioVideo;
		debug("Assign meetingSession to this.av");

		this.uiState.sessionReady = true;
	},

	async joinMeeting(meetingID) {
		let promise = null;
		if (this.uiState.authed) {
			promise = VideoMeetingService.joinMeeting(meetingID);
		} else {
			promise = VideoMeetingService.anonJoinMeeting(meetingID, this.uiState.password, this.uiState.username);
		}

		try {
			let {
				data: { meetingMagic, attendeeMagic, meetingUser },
			} = await promise;

			this.meetingMagic = meetingMagic;
			this.attendeeMagic = attendeeMagic;
			this.selfId = this.attendeeMagic.ExternalUserId;
			this.selfAttendeeId = this.attendeeMagic.AttendeeId;

			this.localParticipant.userId = this.attendeeMagic.ExternalUserId;
			this.localParticipant.attendeeId = this.attendeeMagic.AttendeeId;
			this.localParticipant.role = meetingUser.role;
			this.localParticipant.capabilities = meetingUser.capabilities;
		} catch (e) {
			this.handleError("Failed to join meeting", e);
		}
	},

	startSession() {
		debug("startSession");
		this.av.bindAudioElement(this.audioOutputElement);

		this.sessionObserver = {
			audioVideoDidStart: () => this.sessionDidStart(),
			audioVideoDidStop: (status) => this.sessionDidStop(status),
			videoTileDidUpdate: (tileState) => this.videoTileDidUpdate(tileState),
			videoTileWasRemoved: (tileID) => this.videoTileWasRemoved(tileID),
			contentShareDidStart: () => this.contentShareDidStart(),
			contentShareDidStop: () => this.contentShareDidStop(),
		};
		this.av.addObserver(this.sessionObserver);
		this.av.realtimeSubscribeToAttendeeIdPresence((attendeeId, present) => {
			if (present) {
				this.attendeeJoined(attendeeId);
			} else {
				this.attendeeLeft(attendeeId);
			}
		});
		this.av.start();
	},

	sessionDidStart() {
		this.uiState.joiningSession = false;
		this.uiState.inSession = true;

		this.switchInputsFromPreviewToPublish();

		const speakerPolicy = new DefaultActiveSpeakerPolicy();
		this.av.subscribeToActiveSpeakerDetector(speakerPolicy, (attendeeIds) => {
			debug("speaking attendees", attendeeIds);
			this.uiState.prioritySpeakers = attendeeIds;
		});
	},

	switchInputsFromPreviewToPublish() {
		this.switchVideoFromPreviewToPublish();
		this.switchAudioFromPreviewToPublish();
	},

	async switchVideoFromPreviewToPublish() {
		if (!this.localParticipant.video) {
			log("Video is disabled, so it will not be published");
			// Not publishing video, so we don't need to do anything
			return;
		}

		this.localParticipant.videoLoading = true;
		this.av.stopVideoPreviewForVideoInput(this.localParticipant.video);
		await this.av.startLocalVideoTile();
		this.localParticipant.videoLoading = false;
		log("Switched video feed from preview to publish");
	},

	async switchAudioFromPreviewToPublish() {
		this.debug("switchAudioFromPreviewToPublish", this.localParticipant, this.localParticipant.muted);
		if (this.precallAudioAnalyser) {
			this.stopPrecallAudioAnalyser();
		}

		if (this.localParticipant.muted) {
			await this.inSessionDisableLocalAudio();
		} else {
			await this.inSessionEnableLocalAudio();
		}
		log("Switched audio feed from preview to publish");
		this.subscribeForAudioUpdates(this.localParticipant);
	},

	async attendeeJoined(attendeeId) {
		if (this.selfAttendeeId == attendeeId) {
			log("Self joined meeting");
		} else {
			const baseAttendeeId = new DefaultModality(attendeeId).base();
			const attendeeType = new DefaultModality(attendeeId).modality();
			log(`${attendeeType == "content" ? "Attendee" : "Content share"} joined meeting`, attendeeId);

			try {
				let { data } = await VideoMeetingService.getMeetingAttendee(
					this.uiState.meeting.id,
					attendeeId,
					this.uiState.password,
					this.uiState.username
				);
				this.debug("got meeting attendee", data);
				let { meetingUser } = data;

				let existingMeetingUser = _.find(this.meetingUsers, { id: meetingUser.id });
				if (!existingMeetingUser) {
					this.meetingUsers.push(meetingUser);
				}

				const parti = this.ensureParticipant(attendeeId);
				parti.name = meetingUser.full_name;
				parti.initials = fs.initials(meetingUser.full_name);
				parti.userId = meetingUser.id;
				parti.role = meetingUser.role;
				parti.capabilities = meetingUser.capabilities;

				if (attendeeType == "content") {
					const baseParti = _.find(this.participants, { attendeeId: baseAttendeeId });
					if (baseParti) {
						baseParti.screensharing = true;
					}

					parti.screensharing = true;
					parti.muted = true;
					parti.isContent = true;
				} else {
					this.subscribeForAudioUpdates(parti);
				}
			} catch (e) {
				this.handleError("Failed to get user on join", e);
			}
		}
	},

	subscribeForAudioUpdates(parti) {
		log("Subscribe to volume updates for", parti.attendeeId);

		let { calcSpeaking, calcSpeakingDebounce } = this.getCalcSpeakingCallbacks(parti);

		this.av.realtimeSubscribeToVolumeIndicator(parti.attendeeId, (attendeeId, volume, muted, signalStrength) => {
			const baseAttendeeId = new DefaultModality(attendeeId).base();
			if (baseAttendeeId !== attendeeId) {
				// See the "Screen and content share" section for details.
				log(`The volume of ${baseAttendeeId}'s content changes`);
			}

			if (muted != null) {
				this.debug("SET MUTED", parti, muted);
				parti.muted = muted;
			}
			if (volume != null) {
				let time = new Date().getTime();
				let volumeLevel = volume * 100;
				parti.volumeLevel = volumeLevel;
				parti.volumeBuffer.push({ time: time, volume: volumeLevel });

				calcSpeaking();
				calcSpeakingDebounce();
			}

			// A null value for any field means that it has not changed.
			// debug(`${attendeeId}'s volume data: `, {
			// 	volume, // a fraction between 0 and 1
			// 	muted, // a boolean
			// 	signalStrength, // 0 (no signal), 0.5 (weak), 1 (strong)
			// });
		});
	},

	getCalcSpeakingCallbacks(parti, limit) {
		const calcSpeaking = function () {
			let currentTime = new Date().getTime();
			let volumeArea = 0;

			for (let i = parti.volumeBuffer.length - 1; i >= 0; i--) {
				// We're traversing the array backwards, and new elements are always added to the end,
				// so if we reach an element that's older than 1000ms, splice out everything up to that point
				// and end the loop.
				if (currentTime - parti.volumeBuffer[i].time > 1000) {
					parti.volumeBuffer.splice(0, i + 1);
					break;
				}

				let nextTime;
				if (i + 1 < parti.volumeBuffer.length) {
					nextTime = parti.volumeBuffer[i + 1].time;
				} else {
					nextTime = currentTime;
				}
				// For each volume update, multiply the length of time until the next update by the volumeLevel.
				// This basically gives us a crude integration of the volume over the past second.
				volumeArea += (nextTime - parti.volumeBuffer[i].time) * parti.volumeBuffer[i].volume;
			}

			// volumeArea will a value in units of amplitude * milliseconds. Over 1 second, the maximum value
			// would be 100,000. We'll consider a user to be "speaking" if their value is over 10,000
			parti.speaking = volumeArea > (limit || 10000);
		};

		let timeoutId = null;
		const calcSpeakingDebounce = function () {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(calcSpeaking, 1000);
		};

		const cancelDebounce = function () {
			clearTimeout(timeoutId);
		};

		return { calcSpeaking, calcSpeakingDebounce, cancelDebounce };
	},

	attendeeLeft(attendeeId) {
		const baseAttendeeId = new DefaultModality(attendeeId).base();
		const attendeeType = new DefaultModality(attendeeId).modality();

		if (attendeeType == "content") {
			const baseParti = _.find(this.participants, { attendeeId: baseAttendeeId });
			if (baseParti) {
				baseParti.screensharing = false;
			}
		}

		let index = _.findIndex(this.participants, { attendeeId: attendeeId });
		if (index < 0) return;
		vm.$delete(this.participants, index);
		log(`${attendeeType == "content" ? "Attendee" : "Content share"} left meeting`, attendeeId);
	},

	async startScreenshare() {
		try {
			const contentShareStream = await this.av.startContentShareFromScreenCapture();
		} catch (e) {
			if (e.name == "NotAllowedError" && e.message == "Permission denied") {
				// This is expected if the user presses cancel on the share window choice screen, so ignore it
				this.logWarn("User canceled screenshare choice");
			} else if (e.name == "NotAllowedError" && e.message == "Permission denied by system") {
				this.handleUserError(
					"Full screen sharing not allowed",
					"Your browser does not have permission to share your full screen.<br>This cannot be changed from within the browser.<br>You may have to change your computer's system settings.",
					10000
				);
			} else {
				this.handleError("Content share error", e);
			}
		}
	},

	async stopScreenshare() {
		await this.av.stopContentShare();
	},

	contentShareDidStart() {
		this.log("Content share did start");
		this.localParticipant.screensharing = true;
	},

	contentShareDidStop() {
		this.handleError("Failed to start content share stream");
	},

	async queryMicrophonePermissions() {
		this.uiState.permissions.microphone.changing = true;
		let updateState = (state) => {
			if (state == "granted") {
				this.logWarn("Microphone permissions were granted");
				this.uiState.permissions.microphone.allowed = true;
				this.uiState.permissions.microphone.blocked = false;
				this.initAudioInputDevices(true);
				this.initAudioOutputDevices(true);
			} else if (state == "prompt") {
				this.logWarn("Microphone permissions were prompted");
				this.uiState.permissions.microphone.allowed = false;
				this.uiState.permissions.microphone.blocked = false;
			} else if (state == "denied") {
				this.logWarn("Microphone permissions were denied");
				this.uiState.permissions.microphone.allowed = false;
				this.uiState.permissions.microphone.blocked = true;
			}
		};
		try {
			let perm = await navigator.permissions.query({ name: "microphone" });
			this.log("Microphone permission", perm);
			updateState(perm.state);
			perm.onchange = () => {
				updateState(perm.state);
			};
		} catch (e) {
			this.handleError("Unable to request permission to access microphone");
		} finally {
			this.uiState.permissions.microphone.changing = false;
		}
	},

	async queryCameraPermissions() {
		this.uiState.permissions.camera.changing = true;
		let updateState = (state) => {
			if (state == "granted") {
				this.logWarn("Camera permissions were granted");
				this.uiState.permissions.camera.allowed = true;
				this.uiState.permissions.camera.blocked = false;
				this.initVideoInputDevices(true);
			} else if (state == "prompt") {
				this.logWarn("Camera permissions were prompted");
				this.uiState.permissions.camera.allowed = false;
				this.uiState.permissions.camera.blocked = false;
			} else if (state == "denied") {
				this.logWarn("Camera permissions were blocked");
				this.uiState.permissions.camera.allowed = false;
				this.uiState.permissions.camera.blocked = true;
			}
		};
		try {
			let perm = await navigator.permissions.query({ name: "camera" });
			this.log("Camera permission", perm);
			updateState(perm.state);
			perm.onchange = () => {
				updateState(perm.state);
			};
		} catch (e) {
			this.handleError("Unable to request permission to access camera");
		} finally {
			this.uiState.permissions.camera.changing = false;
		}
	},

	audioCapabilityRemoved() {
		if (!this.localParticipant.muted) {
			this.toggleLocalAudio();
		}
	},

	videoCapabilityRemoved() {
		if (this.localParticipant.video) {
			this.toggleLocalVideo();
		}
	},

	contentCapabilityRemoved() {
		if (this.localParticipant.screensharing) {
			this.stopScreenshare();
		}
	},

	eventDidReceive(name, attributes) {
		this.logWarn("Meeting event", name, attributes);
	},

	hangUp() {
		this.logError("Ending meeting session");
		this.uiState.inSession = false;
		this.uiState.leftMeeting = true;
		this.av.stopVideoInput();
		this.av.stopAudioInput();
		this.av.stop();
		if (this.deviceChangeObserver) {
			this.av.removeDeviceChangeObserver(this.deviceChangeObserver);
			this.deviceChangeObserver = null;
		}
		if (this.sessionObserver) {
			this.av.removeObserver(this.sessionObserver);
			this.sessionObserver = null;
		}
	},

	destroy() {
		this.logWarn("Destroying video session and all related processes");
		if (this.uiState.inSession) {
			this.hangUp();
		} else {
			this.precallDisableLocalVideo();
			this.precallDisableLocalAudio();
		}
		if (this.deviceChangeObserver) {
			this.av.removeDeviceChangeObserver(this.deviceChangeObserver);
		}
		if (this.sessionObserver) {
			this.av.removeObserver(this.sessionObserver);
		}

		this.uiState = null;
		this.devices = null;
		this.participants = null;
		this.localParticipant = null;
		this.meetingUsers = null;
		this.initialized = null;
	},

	sessionDidStop(status) {
		const statusCode = status.statusCode();
		const errString = status.toString();
		this.logError("Session did stop", status, errString, status.isTerminal());
		switch (statusCode) {
			case MeetingSessionStatusCode.Left:
				// Normal hang up, don't show any special message
				break;
			case MeetingSessionStatusCode.AudioJoinedFromAnotherDevice:
				this.uiState.leftMeetingMessage = "You joined from another device";
				break;
			case MeetingSessionStatusCode.MeetingEnded:
				this.uiState.leftMeetingMessage = "The meeting has ended";
				this.uiState.noRejoin = true;
				break;
			case MeetingSessionStatusCode.SignalingBadRequest:
				this.uiState.leftMeetingMessage = "You were removed from the meeting";
				this.uiState.noRejoin = true;
				break;
			default:
				this.uiState.leftMeetingMessage = `Meeting ended with error: ${errString} (${statusCode})`;
		}

		if (status.isTerminal() && this.uiState.inSession) {
			this.debug("Event caused hang up");
			this.hangUp();
		}
	},
};
