<template>
	<div
		class="editable-div"
		ref="editable"
		:style="{
			'--placeholderText': `${placeholderText}`,
		}"
		contenteditable
		v-on="listeners"
	/>
</template>

<style>
.editable-div::after {
	content: "";
}
.editable-div::after {
	content: var(--placeholderText);
	opacity: 0.5;
}
</style>

<script>
export default {
	name: "EditableDiv",
	props: {
		initial: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			value: "",
			controller: {
				getText: this.getText,
				reset: this.reset,
			},
		};
	},
	computed: {
		listeners() {
			return { ...this.$listeners, input: this.onInput };
		},
		placeholderText() {
			return this.value ? `""` : `"${this.placeholder}"`;
		},
	},
	mounted() {
		this.reset();

		this.$emit("update:controller", this.controller);
	},
	watch: {},
	methods: {
		onInput(e) {
			console.log("ON INPUT");
			this.value = e.target.innerText;
			this.$emit("update:text", e.target.innerText);
		},

		getText() {
			return this.$refs.editable.innerText;
		},

		reset() {
			console.log("RESET");
			this.$refs.editable.innerText = this.initial;
			this.value = this.initial;
			this.$emit("update:text", this.$refs.editable.innerText);
		},
	},
};
</script>