import axios from "axios";
import BB from "bluebird";
import UserService from "@/services/UserService";

var SCORM = 1;
var RESOURCE = 2;
var QUALIFICATION = 3;
var PRACTICE = 4;

var INCOMPLETE = 0;
var IN_PROGRESS = 1;
var COMPLETE = 2;
var FAILED = -1;

var QC_PASSED = 2;

export default {
	getMyTrainingCourses() {
		return axios.get(`/v1/my/training/courses`);
	},

	getMyTrainingProgress() {
		return axios.get(`/v1/my/training/progress`);
	},

	initProgress(courseID) {
		return axios.post(`/v1/my/training/course/${courseID}/init`);
	},

	updateElementProgress(courseID, elementID, progress) {
		return axios.post(`/v1/my/training/course/${courseID}/element/${elementID}/progress/${progress}`);
	},

	// Get the user's assigned courses and progress within those courses, and combine the objects for easier use
	getCombinedCoursesAndProgress() {
		return new Promise((resolve, reject) => {
			BB.props({
				courses: this.getMyTrainingCourses(),
				progress: this.getMyTrainingProgress(),
			})
				.then((r) => {
					let names = r.courses.data.names;
					let courses = r.courses.data.courses;
					let progresses = r.progress.data;

					for (let i in courses) {
						let course = courses[i];
						for (let progress of progresses) {
							if (course.id == progress.course_id) {
								courses[i].progress = progress;
								let allComplete = course.elements.length > 0;
								for (let j in course.elements) {
									let element = course.elements[j];
									for (let pelement of progress.progress_elements) {
										if (element.id == pelement.element_id) {
											if (element.type != pelement.type) {
												element.error = "type mismatch";
											}

											courses[i].elements[j].progress = pelement;
										}
									}
									if (!courses[i].elements[j].progress) {
										courses[i].elements[j].progress = { progress: INCOMPLETE };
									}
									if (courses[i].elements[j].progress.progress != COMPLETE) {
										allComplete = false;
									}

									if (element.practice_location) {
										element.practice_location.section_name =
											names[element.practice_location.section_id];
										element.practice_location.item_name = names[element.practice_location.item_id];
									}
									if (element.qual_location) {
										element.qual_location.section_name = names[element.qual_location.section_id];
										element.qual_location.item_name = names[element.qual_location.item_id];
									}
								}

								if (allComplete) {
									courses[i].progress.overall_progress = COMPLETE;
								}

								// Delete the progress object's elements array to prevent double referencing elements
								delete course.progress.elements;
							}
						}

						if (!courses[i].progress) {
							courses[i].progress = { progress: INCOMPLETE };
							for (let j in course.elements) {
								courses[i].elements[j].progress = { progress: INCOMPLETE };
							}
						}
					}

					resolve(courses);
				})
				.catch((e) => {
					reject(e);
				});
		});
	},

	listAllTrainingCourses() {
		return axios.get(`/v1/admin/training/courses/all`);
	},

	listTrainingCourses(ctx) {
		return axios.get(`/v1/admin/training/courses`, { params: ctx });
	},

	getTrainingCourse(id) {
		return axios.get(`/v1/training/course/${id}`);
	},

	saveTrainingCourse(course) {
		return axios.post(`/v1/admin/training/course`, course);
	},

	deleteTrainingCourse(id) {
		return axios.delete(`/v1/admin/training/course/${id}`);
	},

	resetCourse(courseID, userID) {
		UserService.activate({ id: userID });
		return axios.post(`/v1/admin/training/course/reset/${courseID}/${userID}`);
	},

	manualPassCourse(courseID, userID) {
		return axios.post(`/v1/admin/training/course/pass/${courseID}/${userID}`);
	},

	getCourseSections(id) {
		return axios.get(`/v1/admin/training/sections/${id}`);
	},

	getCourseItems(id) {
		return axios.get(`/v1/admin/training/items/${id}`);
	},

	getUserProgress(courseID, teamID) {
		let url = `/v1/admin/training/progresses/${courseID}`;
		if (teamID) {
			url += `?teamID=${teamID}`;
		}
		return axios.get(url);
	},

	uploadScormPackage(file, refID, progressFunc) {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("refID", refID);

		const ctx = {
			headers: { "Content-Type": undefined },
		};
		if (progressFunc) {
			ctx.onUploadProgress = progressFunc;
		}

		return axios.put(`/v1/admin/training/scorm`, formData, ctx);
	},

	modifyScormPackage(file, id, refID, preserveTrainingStatus) {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("refID", refID);
		formData.append("preserveTrainingStatus", preserveTrainingStatus);

		const ctx = {
			headers: { "Content-Type": undefined },
		};

		return axios.post(`/v1/admin/training/scorm/modify/${id}`, formData, ctx);
	},

	uploadResourceFile(file) {
		let formData = new FormData();
		formData.append("file", file);
		if (file.type == "application/x-zip-compressed" || file.type == "application/zip") {
			formData.append("cpack", true);
		}

		return axios.put(`/v1/admin/training/resource`, formData, {
			headers: { "Content-Type": undefined },
		});
	},

	getScormPackagePool() {
		return axios.get(` /v1/admin/training/scorm/pool`);
	},

	listScormLocations() {
		return axios.get(`/v1/admin/scorm_locations/shallow`);
	},

	getScormLocationsPrefilled() {
		return axios.get(`/v1/admin/scorm_locations/prefilled`);
	},

	getScormLocationWithPackages(locationID) {
		return axios.get(`/v1/admin/scorm_location/${locationID}`);
	},

	saveScormLocation(location) {
		return axios.post(`/v1/admin/scorm_location`, location);
	},

	deleteScormLocation(id) {
		return axios.delete(`/v1/admin/scorm_location/${id}`);
	},

	findMyTrainingIncompleteCourses(itemID, sectionID) {
		return axios.get(`/v1/my/training/incomplete/courses/${itemID}/${sectionID}`);
	},
};
