<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ItemEdit.Scoring.title") }}</div>
		<div class="row m-0">
			<div class="px-4 pt-4 col-sm-6">
				<div v-if="!item.cfCreateRubric" class="form-group" :class="{ invalid: !valid.field('rubric') }">
					<label>{{ $t("ItemEdit.Scoring.rubric") }}</label>
					<v-select
						class="v-select-apply"
						:options="rubrics"
						v-model="item.rubric"
						@input="updateRubricID"
						label="name"
						:disabled="item.isScored"
					></v-select>
				</div>
				<div v-else class="form-group cf-configured">
					<label>{{ $t("ItemEdit.Scoring.rubric") }}</label>
					<input class="form-control" type="text" disabled :value="$t('ItemEdit.Scoring.configured_by_cf')" />
				</div>
				<div class="form-group" :class="{ invalid: !valid.field('type') }">
					<label>{{ $t("ItemEdit.Scoring.item_resolution") }} </label>
					<span class="fas fa-check-double fa-s" />
					<config-select :options="item_types" byField="id" v-model="item.type"></config-select>
				</div>
				<div
					class="form-group"
					v-if="!noRes && item.adjudication_enabled"
					:class="{ invalid: !valid.field('type') }"
				>
					<span>
						<label>{{ $t("ItemEdit.Scoring.item_adjudication") }}</label>
						<i class="material-icons" style="font-size: 1.2rem">gavel</i>
					</span>
					<config-select
						:options="adjudicationOptions"
						byField="id"
						sortBy="sort"
						v-model="item.adjudication_rule"
					></config-select>
				</div>
				<div class="form-group row mx-0 align-bottom" v-if="item.type == 'DOUBLE_WEIGHTED'">
					<div class="p-0 col-4 mt-auto">
						<label>Max Weight Diff.</label>
						<input type="number" class="form-control" v-model.number="item.weighted_resolution_threshold" />
					</div>
					<div class="p-0 pl-2 col-4 mt-auto">
						<label>Range Min</label>
						<input type="number" class="form-control" v-model.number="item.weighted_resolution_range_min" />
					</div>
					<div class="p-0 pl-2 col-4 mt-auto">
						<label>Range Max</label>
						<input type="number" class="form-control" v-model.number="item.weighted_resolution_range_max" />
					</div>
				</div>
				<div class="form-group row mx-0 align-bottom" v-show="item.type == 'OMAHA'">
					<div class="p-0 col-4 mt-auto checkbox">
						<label class="ui-check">
							<input type="checkbox" v-model="item.omaha_min_max" />
							<i class="dark-white"></i> Resolve Min/Max
						</label>
					</div>
					<div class="p-0 pl-2 col-4 mt-auto">
						<label>Max Non-adjacent</label>
						<input type="number" class="form-control" v-model.number="item.omaha_max_non_adj" />
					</div>
					<div class="p-0 pl-2 col-4 mt-auto">
						<label>Max Score Diff</label>
						<input type="number" class="form-control" v-model.number="item.omaha_max_weighted_score_diff" />
					</div>
				</div>
				<div>
					<custom-adj v-if="customAdjSelected" :item="item" />
				</div>
				<div>
					<div class="light" v-if="item.type == 'TRAIT_RESOLUTION'" style="background-color: #009688">
						<hr />
						<center style="color: #ffffff">
							<h6 class="full-bar-title">{{ $t("ItemEdit.Scoring.configure_traits_for_resolution") }}</h6>
						</center>
						<table class="table mb-0">
							<thead>
								<tr style="color: #ffffff">
									<th>{{ $t("fields.name") }}</th>
									<th>{{ $t("fields.resolutionread") }}</th>
								</tr>
							</thead>
							<tr v-for="rawTrait in traits" :key="rawTrait.id">
								<template v-for="trait in [traitDetails(rawTrait)]">
									<template>
										<td :key="`c1-${trait.id}`" class="v-mid">
											<input class="form-control" v-model="trait.name" type="text" readonly />
										</td>
										<td :key="`c5-${trait.id}`" class="text-center v-mid">
											<config-select
												style="background-color: #e9ecef"
												:options="fs.traitResolutionAdjacentTypes()"
												byField="id"
												sortBy="sequence"
												v-model="trait.trait_resolution_adjacency"
											/>
										</td>
									</template>
								</template>
							</tr>
						</table>
					</div>
					<hr />
					<br />
				</div>

				<div>
					<div class="checkbox">
						<label
							class="ui-check"
							v-tippy="{ theme: 'popover', placement: 'left-start' }"
							:title="$t('ItemEdit.Scoring.options.resolve_zeros_tooltip')"
						>
							<input v-model="item.resolve_zeros" type="checkbox" />
							<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.resolve_zeros") }}
						</label>
					</div>
				</div>
				<div>
					<div class="checkbox">
						<label
							class="ui-check"
							v-tippy="{ theme: 'popover', placement: 'left-start' }"
							:title="$t('tooltip.blank_scoring')"
						>
							<input v-model="item.blank_scoring_config.enabled" type="checkbox" />
							<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.blank_scoring") }}
						</label>
					</div>
				</div>
				<div class="form-group" v-if="item.blank_scoring_config.enabled">
					<label
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.blank_scoring_flag_code')"
						style="padding-left: 20px"
						>{{ $t("ItemEdit.Scoring.options.blank_scoring_flag_code") }}</label
					>
					<config-select
						style="padding-left: 20px"
						:options="flag_code_options"
						byField="id"
						v-model="item.blank_scoring_config.alert_id"
					></config-select>
				</div>
				<div
					class="form-group"
					:class="{ invalid: !valid.field('blank_scoring_system_first_scorer_id') }"
					v-if="item.blank_scoring_config.enabled && item.blank_scoring_config.action == 'apply_score'"
				>
					<label style="padding-left: 20px"
						>{{ $t("ItemEdit.Scoring.options.blank_scoring_system_first_scorer_id") }}
					</label>
					<config-user-search-for-item-edit-scoring
						style="padding-left: 20px"
						v-model="item.blank_scoring_config.system_first_scorer_id"
						:clientID="client.id"
					/>
				</div>
				<div
					class="form-group"
					:class="{ invalid: !valid.field('blank_scoring_system_reliability_scorer_id') }"
					v-if="
						item.blank_scoring_config.enabled &&
						item.blank_scoring_config.action == 'apply_score' &&
						item.reliability_percent > 0 &&
						!item.blank_scoring_config.ignore_reliability
					"
				>
					<label
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.blank_scoring_item_has_reliability_percent')"
						style="padding-left: 20px"
						>{{ $t("ItemEdit.Scoring.options.blank_scoring_system_reliability_scorer_id") }}
					</label>
					<config-user-search-for-item-edit-scoring
						style="padding-left: 20px"
						v-model="item.blank_scoring_config.system_reliability_scorer_id"
						:clientID="client.id"
					/>
				</div>
				<div v-if="item.blank_scoring_config.enabled && item.reliability_percent > 0 && !noReliability">
					<div class="checkbox">
						<label
							class="ui-check"
							v-tippy="{ theme: 'popover', placement: 'left-start' }"
							:title="$t('tooltip.blank_scoring')"
						>
							<input v-model="item.blank_scoring_config.ignore_reliability" type="checkbox" />
							<i class="dark-white"></i>
							{{ $t("ItemEdit.Scoring.options.blank_scoring_ignore_reliability") }}
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-6">
						<div class="checkbox">
							<label
								class="ui-check"
								v-tippy="{ theme: 'popover', placement: 'left-start' }"
								:title="$t('tooltip.individual_reliability')"
							>
								<input v-model="item.stateless_reliability" type="checkbox" />
								<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.individual_reliability") }}
							</label>
						</div>
					</div>
					<div class="col-6">
						<div class="checkbox">
							<label
								class="ui-check"
								v-tippy="{ theme: 'popover', placement: 'left-start' }"
								:title="$t('tooltip.metadata_reliability')"
							>
								<input v-model="item.metadata_reliability_enabled" type="checkbox" />
								<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.metadata_reliability") }}
							</label>
						</div>
					</div>
				</div>
				<div v-if="changedResSettings">
					<div class="checkbox">
						<label
							class="ui-check"
							v-tippy="{ theme: 'popover', placement: 'left-start' }"
							:title="$t('tooltip.recalculate_existing_resolutions')"
						>
							<input v-model="item.recalcRes" type="checkbox" />
							<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.recalc_res") }}
						</label>
					</div>
				</div>
				<div v-if="changedRelSettings && !item.stateless_reliability">
					<div class="checkbox">
						<label
							class="ui-check"
							v-tippy="{ theme: 'popover', placement: 'left-start' }"
							:title="$t('tooltip.recalculate_reliability')"
						>
							<input v-model="item.recalcRel" type="checkbox" />
							<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.recalc_rel") }}
						</label>
					</div>
				</div>
				<div v-if="item.metadata_reliability_enabled" class="card mb-3">
					<table class="table mb-0">
						<thead>
							<tr>
								<th style="width: 50%">
									{{ $t("fields.key") }}
									<button
										v-if="!hasMetadataKey"
										:disabled="
											item.metadata_reliability_config &&
											item.metadata_reliability_config.length > 0 &&
											!item.metadata_reliability_config[0].key
										"
										@click="addMetaDataKeyForReliability()"
										class="btn btn-sm btn-success ml-2"
										v-tippy
										:title="$t('buttons.add_metadata_reliability_key')"
										style="margin-top: -1rem; margin-bottom: -0.75rem"
									>
										<i class="fas fa-plus" />
									</button>
								</th>
								<th style="width: 50%">
									{{ $t("fields.value") }}
									<button
										v-if="hasMetadataKey"
										@click="addMetaDataKeyForReliability()"
										class="btn btn-sm btn-success ml-2"
										v-tippy
										:title="$t('buttons.add_metadata_reliability_value')"
										style="margin-top: -1rem; margin-bottom: -0.75rem"
									>
										<i class="fas fa-plus" />
									</button>
								</th>
								<th style="min-width: 100px" class="pr-0">
									{{ $t("ItemEdit.Scoring.reliability_percent") }}
								</th>
								<th style="min-width: 100px"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="!item.metadata_reliability_config.length">
								<td colspan="3" class="text-center text-muted">
									{{ $t("data_description.no_metadata_scoring_setup") }}
								</td>
							</tr>
							<tr v-for="(mk, i) in item.metadata_reliability_config" :key="mk.id">
								<td class="pr-0">
									<config-select
										v-if="i == 0"
										:options="clientMetadataKeys"
										byField="key"
										v-model="mk.key"
										@input="updateMetadataKey"
									></config-select>
								</td>
								<td class="pr-0">
									<input
										type="text"
										class="form-control"
										v-model="mk.value"
										:placeholder="$t('data_description.any')"
										v-tippy="{ theme: 'popover' }"
										:title="$t('ItemEdit.Scoring.metadata_reliability_value_tooltip')"
									/>
								</td>
								<td class="pr-0">
									<percent-input v-model="mk.percent" />
								</td>
								<td>
									<button @click="removeMetadataKey(mk)" class="btn btn-danger btn-sm">
										{{ $t("TenantEdit.FlagCodes.remove") }}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="form-group row mx-0 align-bottom" v-if="!noReliability">
					<div class="p-0 col-4 mt-auto" v-if="!item.metadata_reliability_enabled">
						<label>{{ $t("ItemEdit.Scoring.reliability_percent") }}</label>
						<percent-input v-model="item.reliability_percent" />
					</div>
					<div
						v-if="!noRes"
						class="col-8 mt-auto d-flex flex-column justify-content-end pr-0"
						:class="{ 'pl-0': item.metadata_reliability_enabled }"
					>
						<button
							class="btn theme-accent btn-block"
							@click="simRubric.show = !simRubric.show"
							:disabled="!valid.field('rubric')"
						>
							{{ $t("ItemEdit.Scoring.simulate_resolution") }}
							<i class="far fa-fw fa-angle-up anim-rotate" :class="{ 'rotate-180': simRubric.show }"></i>
						</button>
					</div>
				</div>
				<div class="px-4" v-if="valid.field('rubric') && !noRes" v-show="simRubric.show">
					<div class="pr-4" style="display: inline-block; vertical-align: top">
						<rubric
							:outer-rubric="item.rubric"
							:score.sync="simRubric.leftScore"
							:complete.sync="simRubric.leftComplete"
							:reset="simRubric.leftReset"
							:user="user"
							:item="item"
						></rubric>
						<div class="my-2" v-if="simRubric.leftWeight != null">
							{{ $t("ItemEdit.Scoring.weighted_score") }}
							<span class="_600 text-theme" v-if="!simRubric.leftLoading">{{
								simRubric.leftWeight
							}}</span>
							<loading v-if="simRubric.leftLoading" type="icon" />
						</div>
					</div>
					<div style="display: inline-block; vertical-align: top" v-if="shouldCompareRubrics(item.type)">
						<rubric
							:outer-rubric="item.rubric"
							:item="item"
							:score.sync="simRubric.rightScore"
							:complete.sync="simRubric.rightComplete"
							:reset="simRubric.rightReset"
							:user="user"
						>
						</rubric>
						<div class="my-2" v-if="simRubric.rightWeight != null">
							{{ $t("ItemEdit.Scoring.weighted_score") }}
							<span class="_600 text-theme" v-if="!simRubric.rightLoading">{{
								simRubric.rightWeight
							}}</span>
							<loading v-if="simRubric.rightLoading" type="icon" />
						</div>
					</div>
					<div class="w-100 pb-4">
						<div>
							{{ $t("ItemEdit.Scoring.needs_resolution") }}
							<span v-if="!simRubric.resLoading && simRubric.needsRes == null" class="_600 text-theme"
								>?</span
							>
							<span v-if="!simRubric.resLoading && simRubric.needsRes == true" class="_600 text-theme"
								>Yes</span
							>
							<span v-if="!simRubric.resLoading && simRubric.needsRes == false" class="_600 text-theme"
								>No</span
							>
							<loading v-if="simRubric.resLoading" type="icon" />
						</div>
					</div>
				</div>
			</div>
			<div class="px-4 pt-4 col-sm-6">
				<p>{{ $t("ItemEdit.Scoring.options.title") }}</p>
				<div class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.assigned_delivery')"
					>
						<input v-model="item.assigned_delivery" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.assigned_delivery") }}
					</label>
				</div>
				<div v-if="item.assigned_delivery" class="checkbox" style="padding-left: 25px">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.deliver_either_order')"
					>
						<input v-model="item.deliver_either_order" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.deliver_either_order") }}
					</label>
				</div>
				<div v-if="item.assigned_delivery" class="checkbox" style="padding-left: 25px">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.show_other_assigned_scorer')"
					>
						<input v-model="item.show_other_assigned_scorer" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.show_other_assigned_scorer_id") }}
					</label>
				</div>
				<div class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.show_first_score')"
					>
						<input v-model="item.show_first_score" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.show_first_score") }}
					</label>
				</div>
				<div class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.live_res_tooltip')"
					>
						<input v-model="item.live_res" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.live_res") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check" :class="{ 'text-muted': item.isScored }">
						<input
							v-model="item.resolution_choose_score"
							type="checkbox"
							:disabled="item.isScored || item.live_res"
						/>
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.keep_score") }}
					</label>
				</div>
				<div class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.cannot_submit_until_viewed')"
					>
						<input v-model="item.require_view_all" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.whole_response") }}
					</label>
					<b-row v-if="item.require_view_all" class="pl-4">
						<b-col cols="4">
							<config-select
								:options="requireViewAllTypes"
								v-model="item.require_view_all_config.type"
								byField="id"
							/>
						</b-col>
						<b-col v-if="requireViewPercentSelected" cols="8">
							<label> {{ $t("ItemEdit.Scoring.options.percent") }}: </label>
							<input
								v-model.number="item.require_view_all_config.percent"
								min="0"
								max="100"
								type="number"
								class="h-100 w-25 ml-2"
							/>
							%
						</b-col>
						<b-col v-if="requireViewExactSelected" cols="8">
							<label> {{ $t("fields.time") }}: </label>
							<input
								v-model.number="item.require_view_all_config.seconds"
								min="0"
								type="number"
								class="h-100 w-25 mx-2"
							/>
							<label>{{ $t("fields.seconds") }}</label>
						</b-col>
					</b-row>
				</div>

				<div class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.cannot_see_prev_during_res')"
					>
						<input v-model="item.resolution_blind" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.blind_resolution") }}
					</label>
				</div>
				<div v-if="!item.resolution_blind" class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.cannot_see_names_during_res')"
					>
						<input v-model="item.resolution_hide_users" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.hide_pii") }}
					</label>
				</div>
				<div class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.backread_to_resolution')"
					>
						<input v-model="item.backread_to_resolution" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.backread_to_resolution") }}
					</label>
				</div>
				<div class="checkbox" v-if="!singleScore">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.allow_manual_reliability')"
					>
						<input v-model="item.allow_manual_reliability" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.manual_reliability") }}
					</label>
				</div>
				<div class="checkbox" v-if="!noRes">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'left-start' }"
						:title="$t('tooltip.adjudication_enabled')"
					>
						<input v-model="item.adjudication_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.Scoring.options.adjudication") }}
					</label>
				</div>
				<hr />

				<div class="form-group">
					<label>{{ $t("ItemEdit.Scoring.school_code_behavior.title") }}</label>
					<config-select
						:options="schoolCodeModes"
						v-model="item.school_code_config.mode"
						byField="id"
						sortBy="sequence"
					/>
				</div>
				<label>{{ $t("ItemEdit.Scoring.school_code_or_score_types.title") }}</label>
				<div class="row">
					<div class="col-4">
						<div class="checkbox">
							<label class="ui-check">
								<input v-model="item.school_code_config.first" type="checkbox" />
								<i class="dark-white"></i>
								<span class="fas fa-check mx-1" />{{
									$t("ItemEdit.Scoring.school_code_or_score_types.first")
								}}
							</label>
						</div>
					</div>
					<div class="col-4">
						<div class="checkbox">
							<label class="ui-check">
								<input v-model="item.school_code_config.second" type="checkbox" />
								<i class="dark-white"></i>
								<span class="fas fa-check-double mx-1" />{{
									$t("ItemEdit.Scoring.school_code_or_score_types.second")
								}}
							</label>
						</div>
					</div>
					<div class="col-4">
						<div class="checkbox">
							<label class="ui-check">
								<input v-model="item.school_code_config.res" type="checkbox" />
								<i class="dark-white"></i>
								<span class="fas fa-shield-check mx-1" />{{
									$t("ItemEdit.Scoring.school_code_or_score_types.resolution")
								}}
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.invalid {
	color: red;
}

.cf-configured {
	color: rgb(23, 162, 184);
}
.cf-configured input.form-control {
	border-color: rgb(23, 162, 184);
}

.v-select-apply .dropdown-toggle {
	border-radius: 0px;
	/* should work but doesn't :( */
}

button {
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>

<script>
import ItemService from "@/services/ItemService";
import notie from "@/services/NotieService";
import { ADJUDICATION_RULES, ITEM_TYPES, REQUIRE_VIEW_ALL_TYPES } from "@/services/Constants";

import PercentInput from "@/components/PercentInput";
import ConfigSelect from "@/components/ConfigSelect";
import Rubric from "@/components/Rubric";
import CustomAdj from "./ItemEditScoring/CustomAdj";
import ConfigUserSearchForItemEditScoring from "../../components/ConfigUserSearchForItemEditScoring";
import Utils from "../../services/Utils";
import TenantService from "@/services/TenantService";
import fs from "@/services/FormatService";
import ScoringService from "@/services/ScoringService";

const itemTypes = fs.itemTypes();

export default {
	name: "ItemEditScoring",

	props: [
		"id",
		"item",
		"client",
		"defaultTab",
		"validationHints",
		"valid",
		"changedResSettings",
		"changedRelSettings",
		"user",
		"rubrics",
	],

	components: {
		PercentInput,
		ConfigSelect,
		Rubric,
		ConfigUserSearchForItemEditScoring,
		CustomAdj,
	},

	data() {
		return {
			isScored: false,
			item_types: itemTypes,
			flag_code_options: [],
			schoolCodeModes: fs.schoolCodeModes(),
			simRubric: {
				show: false,
				leftScore: {},
				leftComplete: null,
				rightScore: {},
				rightComplete: null,
				leftWeight: null,
				rightWeight: null,
				needsRes: null,
				leftLoading: false,
				rightLoading: false,
				resLoading: false,
				leftReset: 0,
				rightReset: 0,
			},
			clientMetadataKeys: [],
			metadataReliabilityKeys: [],
			itemTraitResolution: {
				show: false,
			},
			trait_resolution_adjacency: [],
			traits: [],
			fs: fs,
		};
	},

	created() {
		this.buildItemAlerts(false);
		TenantService.getClient()
			.then((resp) => {
				this.clientMetadataKeys = resp.data.metadata_keys;
				//remap
				let counter = 0;
				this.clientMetadataKeys.forEach((x) => {
					this.clientMetadataKeys[counter].name = x.key;
					counter = counter + 1;
				});
			})
			.catch(() => {
				notie.error(this.$i18n.t("notie.load_client_fail"), err);
			});

		if (this.item.id) {
			ScoringService.getItem(this.item.id).then((results) => {
				let i = results.data;
				console.log("i.rubric.traits");
				console.log(i.rubric.traits);
				_.each(i.rubric.traits, (trait) => {
					let adj = 1;
					_.each(i.trait_resolution_adjacency, (t) => {
						if (t.trait_id == trait.id) {
							adj = t.trait_resolution_adjacent_type_id;
						}
					});
					this.traits.push({ trait_id: trait.id, name: trait.name, trait_resolution_adjacency: adj });
				});
			});
		}

		//initialize this.item.trait_resolution_adjacency
		//adjacency of 0 means no adjacency has been selected for trait
		if (this.item.trait_resolution_adjacency.length == 0 && this.item.rubric) {
			_.each(this.item.rubric.traits, (trait) => {
				this.item.trait_resolution_adjacency.push({ trait_id: trait.id, trait_resolution_adjacent_type_id: 1 });
			});
		}

		this.setupCustomTraitAdjacency();
	},

	computed: {
		singleScore() {
			return this.item && this.item.type == ITEM_TYPES.SINGLE.id;
		},
		noRes() {
			const { SINGLE, DOUBLE_ONLY } = ITEM_TYPES;
			return this.item && (this.item.type === SINGLE.id || this.item.type == DOUBLE_ONLY.id);
		},
		noReliability() {
			const { item } = this;
			return item && item.type === ITEM_TYPES.SINGLE.id;
		},

		customAdjSelected() {
			const { item } = this;
			return item && item.type === ITEM_TYPES.DOUBLE_EXACT_CUSTOM_ADJACENT.id;
		},

		adjudicationOptions() {
			return Object.values(ADJUDICATION_RULES);
		},

		requireViewAllTypes() {
			return Object.values(REQUIRE_VIEW_ALL_TYPES);
		},

		requireViewPercentSelected() {
			return this.item.require_view_all_config.type === REQUIRE_VIEW_ALL_TYPES.PERCENT.id;
		},

		requireViewExactSelected() {
			return this.item.require_view_all_config.type === REQUIRE_VIEW_ALL_TYPES.EXACT_TIME.id;
		},

		hasMetadataKey() {
			if (this.item.metadata_reliability_config && this.item.metadata_reliability_config.length > 0) {
				return this.item.metadata_reliability_config[0].key;
			}
			return false;
		},
	},

	watch: {
		"item.rubric"() {
			this.clearSim();
			this.traits = [];
			_.each(this.item.rubric.traits, (trait) => {
				let adj = 1;
				let found = false;
				_.each(this.item.trait_resolution_adjacency, (t) => {
					if (t.trait_id == trait.id) {
						adj = t.trait_resolution_adjacent_type_id;
						found = true;
					}
				});
				if (!found) {
					this.item.trait_resolution_adjacency = [];
				}
				this.traits.push({ trait_id: trait.id, name: trait.name, trait_resolution_adjacency: adj });
			});
			//initialize this.item.trait_resolution_adjacency
			//adjacency of 0 means no adjacency has been selected for trait
			if (this.item.trait_resolution_adjacency.length == 0) {
				_.each(this.item.rubric.traits, (trait) => {
					this.item.trait_resolution_adjacency.push({
						trait_id: trait.id,
						trait_resolution_adjacent_type_id: 1,
					});
				});
			}

			this.setupCustomTraitAdjacency();
		},
		"item.type"() {
			this.clearSim();
		},
		"item.weighted_resolution_threshold"() {
			this.checkSim();
		},
		"item.weighted_resolution_range_min"() {
			this.checkSim();
		},
		"item.weighted_resolution_range_max"() {
			this.checkSim();
		},
		"item.omaha_min_max"() {
			this.checkSim();
		},
		"item.omaha_max_non_adj"() {
			this.checkSim();
		},
		"item.omaha_max_weighted_score_diff"() {
			this.checkSim();
		},
		"simRubric.leftScore"() {
			if (this.simRubric.leftComplete) {
				this.simRubric.leftLoading = true;
				this.checkSim();
			} else {
				this.simRubric.needsRes = null;
			}
		},
		"simRubric.rightScore"() {
			if (this.simRubric.rightComplete) {
				this.simRubric.rightLoading = true;
				this.checkSim();
			} else {
				this.simRubric.needsRes = null;
			}
		},
		"item.blank_scoring_config.alert_id"() {
			_.each(this.item.avail_alerts, (alert) => {
				if (_.includes(this.item.blank_scoring_config.alert_id, alert.alert_id)) {
					this.item.blank_scoring_config.code = alert.code;
					if (alert.action == 1) {
						this.item.blank_scoring_config.action = "apply_score";
					} else {
						this.item.blank_scoring_config.action = "withhold";
					}
				}
			});
		},

		"item.alert_ids"() {
			this.buildItemAlerts(false);
		},
	},

	methods: {
		updateRubricID(rubric) {
			this.item.rubric_id = (rubric && rubric.id) || "";
		},

		setupCustomTraitAdjacency() {
			const {
				item: { rubric, custom_adjacency },
			} = this;

			this.item.custom_adjacency = [];
			if (!rubric) return;

			this.item.custom_adjacency = rubric.traits.map(
				({ id }) =>
					(custom_adjacency || []).find(({ trait_id }) => trait_id === id) || {
						trait_id: id,
						adjacency: 1,
					}
			);
		},

		shouldCompareRubrics(scoringRule) {
			switch (scoringRule) {
				case "SINGLE":
					return false;
				case "DOUBLE_ONLY":
					return true;
				case "DOUBLE_EXACT":
					return true;
				case "DOUBLE_EXACT_ADJACENT":
					return true;
				case "DOUBLE_WEIGHTED":
					return true;
				case "OMAHA":
					return true;
				case "THREE_READS":
					return true;
				case "ADJACENT_EXACT_UNDER_THREE":
					return true;
				case "TRAIT_RESOLUTION":
					return true;
			}
		},

		clearSim() {
			console.log(this.item);
			console.log("=== CLEARING SIM ===");
			this.simRubric.leftReset++;
			this.simRubric.rightReset++;
			this.simRubric.leftComplete = null;
			this.simRubric.rightComplete = null;
			this.simRubric.leftWeight = null;
			this.simRubric.rightWeight = null;
			this.simRubric.needsRes = null;
			this.simRubric.leftLoading = false;
			this.simRubric.rightLoading = false;
			this.simRubric.resLoading = false;
		},

		checkSim() {
			if (!(this.item.rubric && this.item.type && this.simRubric.show)) {
				return;
			}

			let first = this.simRubric.leftComplete ? this.simRubric.leftScore : null;
			let second = this.simRubric.rightComplete ? this.simRubric.rightScore : null;

			this.simRubric.resLoading = true;
			let simItem = ItemService.prepOutgoingData(this.item);
			ItemService.simScorePair(simItem, first, second)
				.then((resp) => {
					if (resp.data.has_weight) {
						if (this.simRubric.leftComplete) {
							this.simRubric.leftWeight = resp.data.first_weight;
						}
						if (this.simRubric.rightComplete) {
							this.simRubric.rightWeight = resp.data.second_weight;
						}
					}
					if (resp.data.compared) {
						this.simRubric.needsRes = resp.data.needsRes;
					}
					this.simRubric.leftLoading = false;
					this.simRubric.rightLoading = false;
					this.simRubric.resLoading = false;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.simulate_scores_fail"), err);
					this.simRubric.leftLoading = false;
					this.simRubric.rightLoading = false;
					this.simRubric.resLoading = false;
				});
		},

		buildItemAlerts(enableAll) {
			let newAlerts = [];
			_.each(this.item.avail_alerts, (alert) => {
				if (enableAll || _.includes(this.item.alert_ids, alert.alert_id)) {
					if (alert.action == 1) {
						newAlerts.push({ id: alert.alert_id, name: alert.description + ",   action=" + "apply_score" });
					} else {
						newAlerts.push({ id: alert.alert_id, name: alert.description + ",   action=" + "withhold" });
					}
				}
			});
			this.flag_code_options = newAlerts;
		},

		addMetaDataKeyForReliability() {
			let id = Utils.generateUUID();
			let newMetaDataKey = {
				id: id,
			};
			if (this.item.metadata_reliability_config && this.item.metadata_reliability_config.length > 0) {
				newMetaDataKey.key = this.item.metadata_reliability_config[0].key;
			}
			this.item.metadata_reliability_config.push(newMetaDataKey);
		},

		updateMetadataKey(key) {
			if (!this.item.metadata_reliability_config) {
				return;
			}
			for (let i in this.item.metadata_reliability_config) {
				this.item.metadata_reliability_config[i].key = key;
			}
		},

		removeMetadataKey(key) {
			this.item.metadata_reliability_config = _.without(this.item.metadata_reliability_config, key);
		},

		traitDetails(trait) {
			this.updateItem(trait);
			return trait;
		},

		updateItem(trait) {
			_.each(this.item.trait_resolution_adjacency, (t) => {
				if (t.trait_id == trait.trait_id) {
					t.trait_resolution_adjacent_type_id = trait.trait_resolution_adjacency;
				}
			});
		},
	},
};
</script>
