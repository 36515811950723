import axios from "axios";
import _ from "lodash";

export default {
	listItems(ctx) {
		return axios.get("/v1/admin/items", { params: ctx });
	},

	getItem(id) {
		return axios.get(`/v1/item/${id}`);
	},

	getIeaItem(itemID, adminName) {
		let ctx = { adminName: adminName };
		return axios.get(`/v1/admin/item/${itemID}/iea_item`, { params: ctx });
	},

	reinitIeaItem(itemID, adminName) {
		let ctx = { adminName: adminName };
		return axios.get(`/v1/admin/item/${itemID}/iea_reinit`, { params: ctx });
	},

	getItemResponsesCount(id) {
		return axios.get(`/v1/item_responses_count/${id}`);
	},

	getItemNoCache(id) {
		return axios.get(`/v1/item/${id}?nocache=true`);
	},

	isScored(id) {
		return axios.get(`/v1/admin/item_scored/${id}`);
	},

	isResScored(id) {
		return axios.get(`/v1/admin/item_res_scored/${id}`);
	},

	saveItem(item) {
		return axios.post("/v1/admin/item", item);
	},

	bulkUpdateItems(updateDocs) {
		return axios.post(`/v1/admin/bulk_update/items`, updateDocs);
	},

	removeItem(id) {
		return axios.delete(`/v1/admin/item/${id}`);
	},

	copyItem(id, enableIntegrations = false, nCopies = 1) {
		if (nCopies <= 0) {
			throw new Error("nCopies parameter must be 1 or greater");
		}
		return axios.post(`/v1/admin/item/${id}/copy?enableIntegrations=${enableIntegrations}&nCopies=${nCopies}`);
	},

	recalculateResolution(id) {
		return axios.post(`/v1/admin/recalc_res/${id}`);
	},

	recalculateReliability(id) {
		return axios.post(`/v1/admin/recalc_rel/${id}`);
	},

	simScorePair(item, firstRead, secondRead) {
		let ctx = {
			item: item,
			first_read: firstRead,
			second_read: secondRead,
			compare: firstRead && secondRead ? true : false,
		};
		return axios.post("/v1/admin/simscorepair", ctx);
	},

	adamFullResync(id) {
		return axios.post(`/v1/admin/adam_resync/${id}`);
	},

	getItemXML(id) {
		return axios.get(`/v1/admin/item/${id}/xml`);
	},

	prepIncomingData(item) {
		if (item.quotas) {
			_.each(item.quotas, (quota) => {
				if (quota.role_id == null) {
					quota.role_id = true;
				}
			});
		}

		if (
			item.qualification_config &&
			item.qualification_config.requirements &&
			item.qualification_config.requirements.length > 0
		) {
			_.each(item.qualification_config.requirements, (req) => {
				if (!req.trait_id) {
					req.trait_id = true;
				}
			});
		}

		if (item.validity_config && item.validity_config.requirements && item.validity_config.requirements.length > 0) {
			_.each(item.validity_config.requirements, (req) => {
				if (!req.trait_id) {
					req.trait_id = true;
				}
			});
		}

		if (!item.copyleaks_config) {
			item.copyleaks_config = {};
		}

		if (!item.cf_config) {
			item.cf_config = {};
		}

		item.reset_val = false;
	},

	prepOutgoingData(item) {
		let outItem = _.cloneDeep(item);
		if (outItem.quotas) {
			_.each(outItem.quotas, (quota) => {
				if (quota.role_id == true) {
					quota.role_id = null;
				}
			});
		}

		if (outItem.metadata_reliability_enabled) {
			_.each(outItem.metadata_reliability_config, (meta) => {
				if (meta.key == "" || meta.key == null || typeof meta.key === "undefined") {
					outItem.metadata_reliability_config = _.without(outItem.metadata_reliability_config, meta);
				}
			});
		}

		if (
			outItem.qualification_config &&
			outItem.qualification_config.requirements &&
			outItem.qualification_config.requirements.length > 0
		) {
			_.each(outItem.qualification_config.requirements, (req) => {
				if (req.trait_id == true) {
					req.trait_id = null;
				}
			});
		}

		if (
			outItem.validity_config &&
			outItem.validity_config.requirements &&
			outItem.validity_config.requirements.length > 0
		) {
			_.each(outItem.validity_config.requirements, (req) => {
				if (req.trait_id == true) {
					req.trait_id = null;
				}
			});
		}

		if (outItem.copyleaks_config && !outItem.copyleaks_config.flag_threshold_type) {
			outItem.copyleaks_config.flag_threshold_id = null;
			outItem.copyleaks_config.flag_user_id = null;
		}

		if (outItem.writeshift_config && !outItem.writeshift_config.reroute_errors) {
			outItem.writeshift_config.reroute_item_id = null;
			outItem.writeshift_config.reroute_section_id = null;
		}

		return outItem;
	},

	getAssignedSections(itemID) {
		return axios.get(`/v1/admin/item/sections/${itemID}`);
	},
};
