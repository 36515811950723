<template>
	<b-modal
		visible
		hide-footer
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
		:title="$t('Auditing.change_ref_id')"
		@close="close"
		><div
			class="form-group"
			:class="{
				invalid: invalidRefID,
			}"
		>
			<label>{{ $t("Auditing.new_ref_id") }}</label>
			<input v-model="newRef" type="text" class="form-control" />
			<small v-if="invalidRefID" class="form-text text-muted">{{ $t("Auditing.invalid_ref_id") }}</small>
		</div>
		<div class="modal-footer">
			<button @click="save" class="btn btn-flat theme-accent" :disabled="invalidRefID">
				{{ $t("buttons.save") }}
			</button>
			<button @click="close" class="btn btn-flat">
				{{ $t("buttons.cancel") }}
			</button>
		</div>
	</b-modal>
</template>

<style scoped>
.invalid {
	color: red;
}
</style>

<script>
import AuditingService from "@/services/AuditingService";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";

export default {
	name: "RefIDModal",

	props: {
		response: Object,
	},

	data() {
		return {
			newRef: this.response.ref_id,
		};
	},

	watch: {
		"response.id"() {
			this.newRef = this.response.ref_id;
		},
	},

	computed: {
		invalidRefID() {
			return this.newRef.match(fs.refIDRegExp);
		},
	},

	methods: {
		async save() {
			try {
				const { response, newRef } = this;
				await AuditingService.changeResponseRefID(response.id, newRef);
				notie.success("changed Response ID");
				response.ref_id = newRef;
				this.close();
			} catch (e) {
				notie.error("Failed to change Ref ID", e);
			}
		},

		close() {
			this.$emit("close");
		},
	},
};
</script>
