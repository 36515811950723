<template>
	<list-config
		:title="title"
		:elemName="$t('fields.rule')"
		@input="emitRules"
		:value="value"
		:newElem="addNewRule"
		:elemText="getRuleText"
	>
		<template #modal="{ editingElem, ok, close }">
			<val-rule-modal :rule="editingElem" :title="title" @ok="ok" @close="close" />
		</template>
	</list-config>
</template>

<script>
import ListConfig from "@/components/ListConfig";
import ValRuleModal from "./ValRuleModal";
import fs from "@/services/FormatService";

export default {
	name: "ValRuleConfig",

	components: {
		ListConfig,
		ValRuleModal,
	},

	props: {
		title: String,
		value: Array,
	},

	methods: {
		addNewRule() {
			return {
				rule: fs.qcRules()[0].id,
				pass_percent: 0,
				trait_pass_percent: 0,
				omaha_min_max: false,
				omaha_max_non_adj: 0,
				omaha_max_weighted_score_diff: 0,
			};
		},

		getRuleText(rule) {
			return fs.getQcRuleText(rule);
		},

		emitRules(newRules) {
			console.log(newRules);
			this.$emit("input", newRules);
		},
	},
};
</script>
