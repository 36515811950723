<template>
	<b-modal visible no-close-on-backdrop hide-header-close no-close-on-esc :title="title">
		<b-row class="px-4">
			<b-button class="btn theme-accent w-100" @click="selectScormFile">{{
				$t("ScormPool.select_package")
			}}</b-button>
			<b-row v-if="file || modifyPkg" class="pt-2 px-4">
				<b-col>
					<label>{{ $t("fields.name") }}</label>
					<input class="form-control" type="text" v-model="name" />
				</b-col>
				<b-col>
					<label>{{ $t("fields.file_name") }}</label>
					<input v-if="file" class="form-control" type="text" v-model="file.name" disabled />
					<input v-else class="form-control" type="text" v-model="modifyPkg.file_name" disabled />
				</b-col>
			</b-row>
			<div v-if="modifyPkg" class="checkbox form-group pt-3 px-4">
				<label class="md-check">
					<input v-model="preserveTrainingStatus" type="checkbox" />
					<i class="theme-accent"></i>
					{{ $t("ScormPool.preserve_training_status") }}
					<br />
				</label>
				<slot />
			</div>
		</b-row>
		<input
			ref="scormUploadInput"
			class="d-none"
			type="file"
			multiple
			accept=".zip"
			@change="fileSelected($event)"
		/>

		<template #modal-footer>
			<button class="btn btn-flat btn-primary" @click="close">
				{{ $t("buttons.cancel") }}
			</button>
			<button v-if="uploading" class="btn btn-flat theme" disabled>
				<loading type="icon" />{{ $t("buttons.uploading") }}
			</button>
			<button v-else-if="modifyPkg" class="btn btn-flat theme" @click="modify">
				{{ $t("buttons.modify") }}
			</button>
			<button v-else class="btn btn-flat theme" :disabled="cantUpload" @click="upload">
				{{ $t("buttons.upload") }}
			</button>
		</template>
	</b-modal>
</template>

<script>
import TrainingService from "@/services/TrainingService";
import Notie from "@/services/NotieService";

export default {
	name: "ScormUploadModal",

	props: {
		modifyPkg: Object,
	},

	data() {
		const { modifyPkg } = this;
		return {
			file: null,
			name: modifyPkg ? modifyPkg.ref_id : "",
			uploading: false,
			preserveTrainingStatus: false,
		};
	},

	computed: {
		title() {
			const { modifyPkg, $i18n } = this;
			return modifyPkg ? $i18n.t("ScormPool.modify_scorm_package") : $i18n.t("ScormPool.upload_scorm_package");
		},

		cantUpload() {
			const { file, name, uploading } = this;
			return uploading || !(file && name);
		},
	},

	methods: {
		selectScormFile() {
			this.$refs.scormUploadInput.click();
		},

		fileSelected(event) {
			this.file = event.target.files[0];
			this.name = this.file.name.split(".")[0];
		},

		async modify() {
			const { file, modifyPkg, name, preserveTrainingStatus } = this;
			try {
				if (!preserveTrainingStatus) {
					const confirmed = await this.$bvModal.msgBoxConfirm(
						"Are you sure you want to modify this SCORM package? Any existing user progresses will be reset."
					);
					if (!confirmed) return;
				}
				this.uploading = true;
				await TrainingService.modifyScormPackage(file, modifyPkg.id, name, preserveTrainingStatus);
				Notie.success("Modified SCORM Package");
				this.close();
			} catch (e) {
				Notie.error("Failed to modify SCORM package", e);
				console.log(e);
			} finally {
				this.uploading = false;
			}
		},

		async upload() {
			const { file, name } = this;
			this.uploading = true;
			try {
				await TrainingService.uploadScormPackage(file, name);
				Notie.success("Added SCORM Package");
				this.close();
			} catch (e) {
				Notie.error("Failed to upload SCORM package", e);
				console.log(e);
			} finally {
				this.uploading = false;
			}
		},

		close() {
			this.$emit("close");
		},
	},
};
</script>

<style></style>
