<template>
	<div @click="openModal">
		<v-select
			label="name"
			v-model="value"
			:disabled="disabled"
			:dropdownShouldOpen="suppressOpens"
			:searchable="false"
			ref="dropdown"
			class="v-select-highlight"
			:class="{ highlighted: visible }"
		>
			<template slot="selected-option" slot-scope="option">
				<span v-if="option.name" :class="{ 'disabled-option': option.disabled, 'text-muted': option.disabled }">
					<span v-if="option.warn" class="text-danger far faw fa-exclamation-triangle"></span>
					{{ option.name }}
				</span>
			</template>
		</v-select>

		<b-modal ref="modal" @hide="hideModal" hide-header hide-footer>
			<div class="modal-select-scroll">
				<slot name="modal" v-bind="{ options: options, select: selectOption, cancel: closeModal }"> </slot>
			</div>
		</b-modal>
	</div>
</template>

<style scoped>
.modal-select-scroll {
	margin: -1rem;
	max-height: calc(100vh - 114px);
	overflow-y: auto;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.modal-select-scroll::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

.modal-select-scroll::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<script>
import AuthService from "@/services/AuthService";

export default {
	name: "ModalSelect",
	props: {
		value: {},
		options: { type: Array },
		disabled: { default: null },
	},

	data() {
		return {
			visible: false,
		};
	},
	created() {},
	watch: {},
	computed: {},
	methods: {
		suppressOpens() {
			return false;
		},

		openModal() {
			if (this.disabled) {
				return;
			}
			console.log("open modal");
			this.$refs.modal.show();
			this.visible = true;
		},

		closeModal() {
			console.log("close modal");
			this.$refs.modal.hide();
			this.hideModal();
		},

		hideModal() {
			setTimeout(() => {
				this.visible = false;
				this.$forceUpdate();
			}, 200);
		},

		selectOption(option) {
			console.log("Select option", option);
			this.$emit("input", option);
			this.closeModal();
		},
	},
};
</script>
