
<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("MeetingCalendar.meetings") }}
				</div>

				<div v-if="canCreate && !loading" class="py-0">
					<a class="btn btn-sm theme-accent btn-block" @click="addEvent">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("MeetingCalendar.new_meeting") }}</span>
					</a>
				</div>
			</div>
		</template>
		<div class="h-100" v-if="!loading">
			<div class="d-sm-flex b-t h-100">
				<div id="calendar-container" class="tab-content pos-rlt w-100" data-app="true">
					<!-- User -->
					<div class="tab-pane active">
						<div class="d-flex flex-column">
							<div class="flex px-3 pb-3">
								<FullCalendar
									ref="fullCalendar"
									defaultView="timeGridWeek"
									scrollTime="08:00:00"
									:allDaySlot="false"
									:plugins="calendarPlugins"
									:header="header"
									:events="getEvents"
									:displayEventTime="viewMode != 'dayGridMonth'"
									class="full-calendar"
									themeSystem="bootstrap"
									:height="calcCalendarHeight"
									:nowIndicator="true"
									:editable="true"
									:selectable="canCreate"
									:selectMirror="true"
									:unselectAuto="false"
									@eventClick="eventClick"
									@eventDrop="eventDrop"
									@eventResize="eventResize"
									@select="select"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<MeetingCalendarEventModal
			v-if="selectedEvent"
			:event="selectedEvent"
			:user="user"
			@hide="selectedEvent = null"
			@eventChanged="refresh"
			@cancelNew="unselect"
		/>
	</page>
</template>

<style scoped>
.w-select {
	min-width: 160px;
}
.w-dates {
	width: 150px;
}
.w-time {
	width: 100px;
}

a.btn-subtle {
	margin-top: -6px;
	margin-bottom: -6px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.cal-popup {
	width: 300px;
	contain: none !important;
}
.cal-popup > .card {
	contain: none !important;
}
.date-circle {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import $ from "jquery";
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";
import ConfigSelect from "@/components/ConfigSelect";
import MeetingCalendarEventModal from "@/components/MeetingCalendarEventModal";

import VideoMeetingService from "@/services/VideoMeetingService";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import moment from "moment";

const dateFormat = "MMMM DD, YYYY";
const timeFormat = "h:mm A";
let now = moment();

export default {
	name: "MeetingCalendar",

	props: ["user"],

	components: {
		EditPane,
		SaveOptionalModal,
		ConfigSelect,
		FullCalendar,
		MeetingCalendarEventModal,
	},

	data() {
		return {
			loading: false,

			calendarPlugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin],
			header: {
				left: "prev,next today",
				center: "title",
				right: "dayGridMonth,timeGridWeek,listMonth",
			},

			viewMode: "timeGridWeek",
			today: moment().format("YYYY-MM-DD"),
			focus: moment().format("YYYY-MM-DD"),
			start: null,
			end: null,

			events: [],
			selectedEvent: null,

			unlockedDayTooltip: this.$i18n.t("Calendar.unlocked_day_tooltip"),
			lockedDayTooltip: this.$i18n.t("Calendar.locked_day_tooltip"),

			calendar: null,
		};
	},

	created() {},

	mounted() {
		this.calendar = this.$refs.fullCalendar.getApi();
	},

	watch: {},

	computed: {
		meetingEvents() {
			return [];
		},

		canCreate() {
			return this.user.role.manage_all_video_meetings || this.user.role.create_video_meetings
		}
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		getEvents(fetchInfo, resolve, reject) {
			this.debug("getEvents", fetchInfo);
			VideoMeetingService.getMyMeetingEvents(fetchInfo.startStr, fetchInfo.endStr)
				.then((r) => {
					this.events = r.data;
					for (let event of this.events) {
						if (event.editable) {
							event.backgroundColor = "#378006";
						}
						delete event.editable
					}
					resolve(this.events);
				})
				.catch((e) => {
					this.logError("Failed to load events", e);
					Notie.error("Failed to load events", e);
					reject(e);
				});
		},

		generateDayText(start, end) {
			let startStr = start.minute() == 0 ? start.format("ha") : start.format("h:mma");
			let endStr = end.minute() == 0 ? end.format("ha") : end.format("h:mma");

			let name = this.$i18n.t("MySchedule.start_to_end", { start: startStr, end: endStr });

			return name;
		},

		eventClick(info) {
			this.log("Event picked", info);
			this.selectedEvent = info.event;
		},

		calcCalendarHeight() {
			let el = document.getElementById("calendar-container");
			let height = el.offsetHeight;

			return height - 16;
		},

		refresh() {
			this.calendar.refetchEvents();
		},

		async eventDrop(e) {
			this.log("Event drop: time changed");
			this.updateEventTimes(e.event);
		},

		eventResize(e) {
			this.log("Event resize: time changed");
			this.updateEventTimes(e.event);
		},

		addEvent() {
			this.log("New event button clicked")
			let newEvent = {
			}
			this.selectedEvent = newEvent
		},

		select(e) {
			this.log("New event selection made")
			this.debug(e, e.event)
			let newEvent = {
				start: e.start,
				end: e.end,
			}
			this.selectedEvent = newEvent
		},

		unselect() {
			this.calendar.unselect()
		},

		async updateEventTimes(event) {
			try {
				await VideoMeetingService.updateMeetingTimes(event.id, event.start, event.end);
			} catch (e) {
				let errMsg = "Failed to update meeting times";
				this.logError(errMsg, e);
				Notie.error(errMsg, e);
			}
		},
	},
};
</script>
