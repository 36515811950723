//Driver for rescoring

import axios from "axios";

export default {
	icon: "check",
	name: "Rescore",
	showScores() {
		return false;
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: false,
	canChoosePreviousAnnotations: true,

	getNext(ctx) {
		console.log("RESCORE GETNEXT", ctx)
		var promise = axios.get(`/v1/rescore/${ctx.response_id}/${ctx.score_id}`, { return: true });
		if (ctx.flag_id) {
			promise = axios.get(`/v1/rescore_flag/${ctx.response_id}/${ctx.flag_id}`, { return: true });
		}
		promise.then(function (resp) {
			console.log("RescoreSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},

	submitScore(score, response) {
		score.response_id = response.id;

		var promise = axios.post("/v1/legacy/submit_rescore", score);
		if (score.rescoringFlag) {
			promise = axios.post("/v1/legacy/submit_flag_rescore", score)
		}
		promise.then(function (resp) {
			console.log("RescoreSD Submitted");
		});
		return promise;
	}
};
