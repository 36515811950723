<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ProjectEdit.ScoringAvailability.title") }}</div>

		<!-- Time Zone -->
		<div class="row m-0 pt-4" v-if="project.enforce_dates || project.enforce_times">
			<div class="px-4 col-6">
				<div>
					<span class="_600">{{ $t("ProjectEdit.ScoringAvailability.time_zone") }}:</span>
					{{ fs.timezone(timezone) }}
				</div>
			</div>
		</div>

		<!-- Dates -->
		<div class="row m-0 pt-4">
			<div class="px-4 col-2">
				<div class="form-group _600 margin-overlap">
					{{ $t("ProjectEdit.ScoringAvailability.limit_dates") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="project.enforce_dates" />
						<i></i>
					</label>
				</div>
			</div>

			<div class="px-4 col-10">
				<div class="row">
					<div class="col-xl-4 col-lg-5 col-md-6 form-group">
						<label>{{ $t("ProjectEdit.ScoringAvailability.start_date") }}</label>
						<input
							type="text"
							class="form-control"
							v-model="project.scoring_start"
							v-show="project.enforce_dates"
						/>
						<input type="text" class="form-control" disabled v-if="!project.enforce_dates" />
						<date-picker v-model="project.scoring_start" :config="dateConfig"></date-picker>
					</div>
					<div class="col-xl-4 col-lg-5 col-md-6 form-group">
						<label>{{ $t("ProjectEdit.ScoringAvailability.end_date") }}</label>
						<input
							type="text"
							class="form-control"
							v-model="project.scoring_end"
							v-show="project.enforce_dates"
						/>
						<input type="text" class="form-control" disabled v-if="!project.enforce_dates" />
						<date-picker v-model="project.scoring_end" :config="dateConfig"></date-picker>
					</div>
				</div>
			</div>
		</div>
		<hr class="mt-0 mb-2 mx-4" />

		<!-- Times -->
		<div class="row m-0">
			<div class="px-4 col-12">
				<div class="row">
					<div class="col-2">
						<div class="form-group _600 margin-overlap">
							{{ $t("ProjectEdit.ScoringAvailability.limit_times") }}
						</div>
					</div>
					<div class="pl-4 col-2">
						<div class="form-group _600 margin-overlap" v-show="project.enforce_times">
							{{ $t("ProjectEdit.ScoringAvailability.per_day") }}
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-2">
						<div class="form-group">
							<label class="ui-switch ui-switch-md theme-accent">
								<input type="checkbox" v-model="project.enforce_times" />
								<i></i>
							</label>
						</div>
					</div>
					<div class="pl-4 col-2">
						<div class="form-group" v-show="project.enforce_times">
							<label class="ui-switch ui-switch-md theme-accent">
								<input type="checkbox" v-model="project.times.all.disabled" />
								<i></i>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row m-0">
			<div class="px-4 col-12">
				<div class="row" v-show="project.enforce_times && !project.times.all.disabled">
					<div class="col-2">
						<div class="form-group">{{ $t("ProjectEdit.ScoringAvailability.every_day") }}</div>
						<div class="form-group">
							<div class="mx-auto">
								<label class="ui-switch ui-switch-md disabled">
									<input type="checkbox" checked="true" disabled />
									<i></i>
								</label>
							</div>
						</div>
					</div>
					<div class="px-4 col-10">
						<div class="row">
							<div class="col-xl-4 col-lg-5 col-md-6 form-group">
								<label>{{ $t("ProjectEdit.ScoringAvailability.start_time") }}</label>
								<date-picker v-model="project.times.all.start" :config="timeConfig"></date-picker>
							</div>
							<div class="col-xl-4 col-lg-5 col-md-6 form-group">
								<label>{{ $t("ProjectEdit.ScoringAvailability.end_time") }}</label>
								<date-picker v-model="project.times.all.end" :config="timeConfig"></date-picker>
							</div>
						</div>
					</div>
				</div>
				<div
					class="row"
					v-show="project.enforce_times && project.times.all.disabled"
					v-for="(weekday, index) in weekdays"
					:key="index"
				>
					<div class="col-2">
						<div class="form-group">{{ weekday.name }}</div>
						<div class="form-group">
							<div class="mx-auto">
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="project.times[weekday.abr].enabled" />
									<i></i>
								</label>
							</div>
						</div>
					</div>
					<div class="px-4 col-10">
						<div class="row">
							<div
								class="col-xl-4 col-lg-5 col-md-6 form-group"
								v-show="!project.times[weekday.abr].enabled"
							>
								<label>{{ $t("ProjectEdit.ScoringAvailability.start_time") }}</label>
								<input
									type="text"
									class="form-control"
									:value="$t('ProjectEdit.ScoringAvailability.unavailable')"
									disabled
								/>
							</div>
							<div
								class="col-xl-4 col-lg-5 col-md-6 form-group"
								v-show="!project.times[weekday.abr].enabled"
							>
								<label>{{ $t("ProjectEdit.ScoringAvailability.end_time") }}</label>
								<input
									type="text"
									class="form-control"
									:value="$t('ProjectEdit.ScoringAvailability.unavailable')"
									disabled
								/>
							</div>
							<div
								class="col-xl-4 col-lg-5 col-md-6 form-group"
								v-show="project.times[weekday.abr].enabled"
							>
								<label>{{ $t("ProjectEdit.ScoringAvailability.start_time") }}</label>
								<date-picker
									v-model="project.times[weekday.abr].start"
									:config="timeConfig"
								></date-picker>
							</div>
							<div
								class="col-xl-4 col-lg-5 col-md-6 form-group"
								v-show="project.times[weekday.abr].enabled"
							>
								<label>{{ $t("ProjectEdit.ScoringAvailability.end_time") }}</label>
								<date-picker
									v-model="project.times[weekday.abr].end"
									:config="timeConfig"
								></date-picker>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr class="mt-0 mb-2 mx-4" />

		<!-- Shifts -->
		<div class="row m-0 pt-4">
			<div class="px-4 col-2">
				<div class="form-group _600 margin-overlap">{{ $t("ProjectEdit.ScoringAvailability.use_shifts") }}</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="project.use_shifts" />
						<i></i>
					</label>
				</div>
			</div>

			<div class="px-4 col-10" v-show="project.use_shifts">
				<table class="w-100 table">
					<thead>
						<tr>
							<th>{{ $t("ProjectEdit.ScoringAvailability.day") }}</th>
							<th>{{ $t("ProjectEdit.ScoringAvailability.start_time") }}</th>
							<th>{{ $t("ProjectEdit.ScoringAvailability.end_time") }}</th>
							<th></th>
						</tr>
					</thead>
					<tr v-for="(shift, i) in project.shifts" :key="i">
						<td style="width: 188px">
							<config-select
								:options="fs.weekdayTypes"
								byField="id"
								sortBy="sequence"
								v-model="shift.weekday"
								style="width: 164px"
							/>
							<date-picker
								v-if="shift.weekday == -1"
								class="position-relative mt-1"
								v-model="shift.date"
								:config="dateConfigSmall"
							></date-picker>
						</td>
						<td class="position-relative v-mid">
							<date-picker v-model="shift.start" :config="timeConfig"></date-picker>
						</td>
						<td class="position-relative v-mid">
							<date-picker v-model="shift.end" :config="timeConfig"></date-picker>
						</td>
						<td class="v-mid">
							<button
								@click="removeShift(shift)"
								class="btn btn-sm btn-icon btn-rounded red text-white m-0"
								title="Remove Shift"
							>
								<i class="fa fa-trash"></i>
							</button>
						</td>
					</tr>

					<tr>
						<td class="text-center" colspan="4">
							<button
								@click="addShift()"
								style="max-width: 600px"
								class="btn btn-sm btn-block btn-success m-auto"
							>
								{{ $t("ProjectEdit.ScoringAvailability.add_shift") }}
							</button>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<style scoped>
label.disabled {
	background-color: lightgrey;
}
</style>

<script>
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import DatePicker from "vue-bootstrap-datetimepicker";
import ConfigSelect from "@/components/ConfigSelect";
import Utils from "@/services/Utils";
import moment from "moment";

import ProjectService from "@/services/ProjectService";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

const dateFormat = "MMMM DD, YYYY";
const timeFormat = "h:mm A";
let now = moment();

export default {
	name: "ProjectEditAvail",

	props: ["id", "project", "defaultTab", "timezone"],

	components: {
		"date-picker": DatePicker,
		"config-select": ConfigSelect,
	},

	data() {
		return {
			fs: fs,
			dateConfig: {
				format: dateFormat,
				inline: true,
				viewMode: "days",
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			timeConfig: {
				format: timeFormat,
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			dateConfigSmall: {
				format: dateFormat,
				viewMode: "days",
				icons: {
					time: "fa fa-clock",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun",
					clear: "fa fa-calendar-times",
					close: "fa fa-remove",
				},
			},
			start_date: "",
			end_date: "",
			weekdays: [
				{ abr: "mon", name: "Monday" },
				{ abr: "tue", name: "Tuesday" },
				{ abr: "wed", name: "Wednesday" },
				{ abr: "thu", name: "Thursday" },
				{ abr: "fri", name: "Friday" },
				{ abr: "sat", name: "Saturday" },
				{ abr: "sun", name: "Sunday" },
			],
			per_day: false,
		};
	},

	created() {},

	watch: {},

	methods: {
		addShift() {
			this.project.shifts.push({
				id: Utils.generateUUID(),
				weekday: 0,
			});
		},

		removeShift(shift) {
			let index = this.project.shifts.indexOf(shift);
			if (index != -1) {
				this.$delete(this.project.shifts, index);
			}
		},
	},
};
</script>
