<template>
	<div class="d-flex flex-column flex h100">
		<div class="row px-4 pb-4">
			<div class="col-12 mb-3" style="position: fixed; z-index: 2">
				<div style="height: 1.5rem; background-color: #f3f6f9"></div>
				<div class="theme stat-box stat-count py-2 d-flex flex-row align-items-center">
					<span class="ml-auto">OSCAR Admin Tasks</span>

					<button
						class="btn ml-auto"
						:class="{ 'btn-danger': environment, 'btn-secondary': !environment }"
						data-toggle="dropdown"
					>
						{{ getEnvName() }}
						<i class="fas fa-caret-down ml-1" />
					</button>
					<div class="dropdown-menu dropdown-menu-right animate fadeIn">
						<div class="px-4 dropdown-title">Target Environment</div>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" @click="environment = null">
							<strong>Default</strong>
						</a>
						<div class="dropdown-divider"></div>
						<a v-for="(env, i) in environments" :key="i" @click="environment = env" class="dropdown-item">{{
							env.name
						}}</a>
						<div v-if="!(environments && environments.length > 0)" class="px-4 text-muted">
							No custom environments...
						</div>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" @click="editEnvs = true">
							<i class="fas fa-edit" />
							Edit...
						</a>
					</div>
				</div>
			</div>

			<div class="col-12" style="height: 96px"></div>

			<div class="col-12">
				<TenantOptions :environment="environment"></TenantOptions>
			</div>
			<div class="col-12">
				<GmacCounts :environment="environment"></GmacCounts>
			</div>
			<div class="col-12">
				<IngestCounts :environment="environment" :clients="clients"></IngestCounts>
			</div>
			<div class="col-12">
				<PsiObsCounts :environment="environment" :clients="clients"></PsiObsCounts>
			</div>
			<div class="col-12">
				<FixCopyleaksReports :environment="environment" :clients="clients"></FixCopyleaksReports>
			</div>
			<div class="col-12">
				<AuthAccounts :environment="environment" :clients="clients"></AuthAccounts>
			</div>
			<div class="col-12">
				<UserExport :environment="environment" :clients="clients"></UserExport>
			</div>
			<div class="col-12">
				<TestBucket :environment="environment" :clients="clients"></TestBucket>
			</div>
			<div class="col-12">
				<SetupPerfTest :environment="environment" :clients="clients"></SetupPerfTest>
			</div>
			<div class="col-12">
				<MongoProfiler :environment="environment" :clients="clients"></MongoProfiler>
			</div>
		</div>

		<b-modal id="environmentsModal" size="lg" :visible="editEnvs != false" @hide="editEnvs = false">
			<template slot="modal-header">
				<h5 class="modal-title pl-3">Environments</h5>
			</template>

			<div class="modal-scroll">
				<table class="table">
					<tr v-for="(env, i) in environments" :key="i">
						<td>
							<input class="form-control" placeholder="Name" v-model="env.name" />
						</td>
						<td>
							<input
								class="form-control"
								placeholder="Mongo connection string"
								v-model="env.connection"
							/>
						</td>
						<td>
							<input class="form-control" placeholder="Database name" v-model="env.db" />
						</td>
						<td>
							<button class="btn btn-danger" v-tippy title="Delete" @click="removeEnvironment(env)">
								<i class="fas fa-trash" />
							</button>
						</td>
					</tr>
				</table>
				<button class="btn btn-success" @click="addEnvironment"><i class="fas fa-plus mr-2" />Add</button>
			</div>

			<template slot="modal-footer">
				<button class="btn btn-secondary btn-flat" @click="editEnvs = false">{{ $t("buttons.cancel") }}</button>
				<button class="btn btn-success btn-flat" @click="saveEnvironments">{{ $t("buttons.save") }}</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.stat-box {
	text-align: center;
	padding: 1rem 1rem 2rem 1rem;
	border-radius: 1rem;
	background-color: #e7e7e7;
	-webkit-box-shadow:
		0 2px 5px 0 rgb(0 0 0 / 16%),
		0 2px 10px 0 rgb(0 0 0 / 12%);
	box-shadow:
		0 2px 5px 0 rgb(0 0 0 / 16%),
		0 2px 10px 0 rgb(0 0 0 / 12%);
}
.clickable {
	cursor: pointer;
}
.stat-box.clickable:hover {
	background-color: #d0d0d0;
}
.dropdown-title {
	background-color: #f0f0f0;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	margin-top: -0.5rem;
	margin-bottom: -0.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
</style>

<script>
import AdminStatsService from "@/services/AdminStatsService";
import Notie from "@/services/NotieService";
import Store from "@/services/Store";
import moment from "moment";
import _ from "lodash";
import axios from "axios";

import TenantOptions from "@/components/AdminTasks/TenantOptions";
import GmacCounts from "@/components/AdminTasks/GmacCounts";
import IngestCounts from "@/components/AdminTasks/IngestCounts";
import PsiObsCounts from "@/components/AdminTasks/PsiObsCounts";
import FixCopyleaksReports from "@/components/AdminTasks/FixCopyleaksReports";
import AuthAccounts from "@/components/AdminTasks/AuthAccounts";
import UserExport from "@/components/AdminTasks/UserExport";
import TestBucket from "@/components/AdminTasks/TestBucket";
import SetupPerfTest from "@/components/AdminTasks/SetupPerfTest";
import MongoProfiler from "@/components/AdminTasks/MongoProfiler";

export default {
	name: "AdminTasks",
	components: {
		TenantOptions,
		GmacCounts,
		IngestCounts,
		PsiObsCounts,
		FixCopyleaksReports,
		AuthAccounts,
		UserExport,
		TestBucket,
		SetupPerfTest,
		MongoProfiler,
	},

	data() {
		let environments = Store.getDefault(this, "AdminTasks.environments", []);
		let env = Store.get(this, "AdminTasks.environment");

		console.log("GET ENV", env);

		return {
			clients: null,

			environments: environments,
			environment: env,

			editEnvs: false,
		};
	},

	watch: {
		environment() {
			console.log("SET ENV", this.environment);
			Store.set(this, "AdminTasks.environment", this.environment);
			this.listClients();
		},
	},

	created() {
		this.listClients();
	},
	methods: {
		async listClients() {
			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			try {
				const r = await axios.get(`/v1/admin_stats/clients`, config);
				this.clients = r.data;
				for (let i in this.clients) {
					this.clients[i].label = `${this.clients[i].name} ${this.clients[i].ref_id} ${this.clients[i].id}`;
				}
			} catch (e) {
				console.error(e);
				Notie.error("Failed to list clients", e);
			}
		},

		getEnvName() {
			if (!this.environment) {
				return "Default";
			}
			return this.environment.name;
		},

		addEnvironment() {
			this.environments.push({
				name: "",
				connection: "",
				db: "",
			});
		},

		removeEnvironment(env) {
			let index = this.environments.indexOf(env);
			if (index < 0) {
				console.error("Index not found");
				return;
			}

			this.$delete(this.environments, index);
			if (this.environment == env) {
				this.environment = null;
			}
		},

		saveEnvironments() {
			Store.set(this, "AdminTasks.environments", this.environments);
			Notie.success("Environment details saved", "(in local storage!)");
			this.editEnvs = false;
		},
	},
};
</script>
