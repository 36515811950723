<template>
	<div>
		<div class="form-group">
			<label>{{ $t("ReportTrainingCompletion.course") }}</label>
			<config-select :options="courses" v-model="selectedFilters.course"></config-select>
		</div>
		<div class="form-group">
			<label>{{ $t("ReportTrainingCompletion.team") }}</label>
			<config-select :options="teams" v-model="selectedFilters.team"></config-select>
		</div>
		<b-row>
			<b-col>
				<label>{{ $t("fields.sections") }}</label>
				<v-select
					v-tippy="{ theme: 'popover' }"
					:options="sections"
					:value="sections"
					label="name"
					multiple
					disabled
				/>
			</b-col>
			<b-col>
				<label>{{ $t("fields.items") }}</label>
				<v-select
					v-tippy="{ theme: 'popover' }"
					:options="items"
					:value="items"
					label="name"
					multiple
					disabled
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import TrainingService from "@/services/TrainingService";
import TeamService from "@/services/TeamService";
import Notie from "@/services/NotieService";

import ConfigSelect from "@/components/ConfigSelect";

export default {
	name: "TrainingCompletionFilters",

	components: { ConfigSelect },

	props: {
		selectedFilters: Object,
	},

	model: {
		prop: "selectedFilters",
		event: "changeSelectedFilters",
	},

	data() {
		return {
			courses: [],
			teams: [],

			sections: [],
			items: [],
		};
	},

	async created() {
		await this.loadCourses();
		if (!this.selectedFilters.course) this.changeCourse(this.courses[0]);
	},

	watch: {
		"selectedFilters.course"() {
			this.loadSections();
			this.loadItems();
			this.loadTeams();
			this.loadUserProgresses();
		},

		"selectedFilters.team"() {
			this.loadUserProgresses();
		},
	},

	methods: {
		changeSelectedFilters(newFilters) {
			this.$emit("changeSelectedFilters", newFilters);
		},

		emitRows(rows) {
			this.$emit("newRows", rows);
		},

		async loadUserProgresses() {
			const { course, team } = this.selectedFilters;
			if (!(course && team)) {
				this.emitRows([]);
				return;
			}
			try {
				const { data } = await TrainingService.getUserProgress(course.id, team.id);
				this.emitRows(data);
			} catch (err) {
				console.log("Failed to load rows", err);
				Notie.error(this.$i18n.t("ReportTrainingCompletion.load_rows_fail"), err);
			}
		},

		async loadCourses() {
			try {
				this.courses = (await TrainingService.listAllTrainingCourses()).data;
			} catch (err) {
				console.log("Failed to load Courses", err);
				Notie.error(this.$i18n.t("ReportTrainingCompletion.load_courses_fail"), err);
			}
		},

		async loadSections() {
			const {
				selectedFilters: { course },
			} = this;
			if (!course) return;
			try {
				this.sections = (await TrainingService.getCourseSections(course.id)).data;
			} catch (err) {
				console.log("Failed to load Sections", err);
				Notie.error(this.$i18n.t("ReportTrainingCompletion.load_sections_fail"), err);
			}
		},

		async loadItems() {
			const {
				selectedFilters: { course },
			} = this;
			if (!course) return;
			try {
				this.items = (await TrainingService.getCourseItems(course.id)).data;
			} catch (err) {
				console.log("Failed to load Items", err);
				Notie.error(this.$i18n.t("ReportTrainingCompletion.load_items_fail"), err);
			}
		},

		async loadTeams() {
			this.teams = [{ name: "All", id: null }];
			try {
				const {
					data: { teams },
				} = await TeamService.listTeams();
				this.teams = this.teams.concat(teams);
			} catch (err) {
				console.log("Failed to load Teams", err);
				Notie.error(this.$i18n.t("ReportTrainingCompletion.load_team_fail"), err);
			}
		},
	},
};
</script>
