import axios from "axios";

export default {
	icon: "verified_user",
	name: "Queue_Backreading",
	showScores(item, isBackreadingBlindScoring) {
		if (isBackreadingBlindScoring) {
			return false;
		} else {
			return true;
		}
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: true,

	getNext(ctx) {
		ctx.target = Number(ctx.target);
		ctx.score_type = ctx.score_type && Number(ctx.score_type);
		ctx.below_target = ctx.below_target && String(ctx.exported) === "true";
		ctx.exported = ctx.exported && String(ctx.exported) === "true";
		const p = axios.post(`/v1/queuebackread/${ctx.skip_interval}`, ctx);
		p.then(function (resp) {
			console.log("QueueBackreadSD GetNext:" + resp.data.response.ref_id);
		});
		return p;
	},

	submitScore(score, response, keep, forcedRepeatScore) {
		score.response_id = response.id;

		var promise = axios.post("/v1/legacy/submit", score, {
			params: { keep: keep, r: forcedRepeatScore },
		});
		promise.then(function (resp) {
			console.log("QueueBackreadSD Submitted");
		});
		return promise;
	},

	selectUserScore: true,
};
