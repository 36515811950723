import axios from "axios";

export default {
	listBulletins(ctx) {
		return axios.get("/v1/bulletins", { params: ctx });
	},

	saveBulletin(bulletin) {
		return axios.post("/v1/bulletins", bulletin);
	},

	removeBulletin(bulletinID) {
		return axios.delete(`/v1/bulletins/${bulletinID}`);
	}
};
