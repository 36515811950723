<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					<i
						class="fas fa-fw fa-lock-alt"
						v-if="rubric.isScored"
						v-tippy="{ placement: 'bottom-start' }"
						:title="$t('tooltip.rubric_used_for_scoring')"
					></i>
					{{ rubric.name }}
				</div>
			</div>
		</template>

		<div class="h-100" v-if="!loading">
			<edit-pane :cancel="loadData" :save="saveRubric" :dirty="dirty" :valid="valid">
				<div class="padding">
					<!-- Page content goes here -->
					<b-container v-if="rubric" fluid>
						<b-row>
							<b-col class="pr-5">
								<!-- Rubric Settings -->
								<div class="row">
									<form class="col-sm-7">
										<div class="form-group" :class="{ invalid: !valid.field('name') }">
											<label>{{ $t("RubricEdit.name") }}</label>
											<input
												v-model="rubric.name"
												type="text"
												class="form-control"
												:placeholder="$t('RubricEdit.rubric_name_placeholder')"
											/>
											<!-- <small class="form-text text-muted">Name of the rubric</small> -->
										</div>
										<div
											class="form-group"
											:class="{
												invalid: !(valid.field('ref') && valid.field('ref_char')),
											}"
										>
											<label>{{ $t("RubricEdit.ref_id") }}</label>
											<input
												v-model.trim="rubric.ref_id"
												type="text"
												class="form-control"
												:placeholder="$t('RubricEdit.ref_id_placeholder')"
												:disabled="rubric.isScored"
											/>
											<small class="form-text text-muted">{{
												$t("RubricEdit.ref_id_unique")
											}}</small>
										</div>
									</form>
									<div class="col-sm-4">
										<form role="form">
											<p>{{ $t("RubricEdit.settings") }}</p>
											<div class="checkbox">
												<label class="md-check" :class="{ disabled: rubric.isScored }">
													<input v-model="rubric.collect_evidence" type="checkbox" />
													<i class="theme"></i>
													{{ $t("RubricEdit.collect_evidence") }}
												</label>
											</div>
											<div v-if="rubric.collect_evidence" class="checkbox">
												<label class="md-check">
													<input v-model="rubric.large_evidence" type="checkbox" />
													<i class="theme"></i>
													{{ $t("RubricEdit.large_evidence_field") }}
												</label>
											</div>
											<div class="checkbox">
												<label class="md-check" :class="{ disabled: rubric.isScored }">
													<input v-model="rubric.reverse_score_points" type="checkbox" />
													<i class="theme"></i>
													{{ $t("RubricEdit.reverse_score_points") }}
												</label>
											</div>
											<div class="checkbox">
												<label class="md-check" :class="{ disabled: rubric.isScored }">
													<input v-model="rubric.prefill_score" type="checkbox" />
													<i class="theme"></i>
													{{ $t("RubricEdit.prefill_scores") }}
												</label>
											</div>
											<div class="checkbox">
												<label class="md-check" :class="{ disabled: rubric.isScored }">
													<input v-model="rubric.checklist" type="checkbox" />
													<i class="theme"></i> {{ $t("RubricEdit.checklist") }}
												</label>
											</div>
										</form>
									</div>
								</div>

								<!-- Checklist -->
								<div v-if="rubric.checklist" class="row mb-3">
									<div class="col">
										<label>{{ $t("RubricEdit.text_traits") }}</label>
										<table class="table">
											<thead>
												<tr>
													<th class="p-custom p-0"></th>
													<th class="trait-name">{{ $t("fields.text") }}</th>
													<th class="text-center" style="width: 150px">
														{{ $t("fields.choose_annotation") }}
													</th>
													<th class="text-center" style="width: 150px">
														{{ $t("fields.require_check") }}
													</th>
													<th></th>
												</tr>
											</thead>
											<draggable
												v-model="rubric.checklist_items"
												:options="{ handle: '.drag-handle', animation: 150 }"
												@end="dragEnd"
												:element="'tbody'"
											>
												<tr>
													<td
														class="text-center"
														v-if="!rubric.checklist_items.length"
														colspan="3"
													>
														{{ $t("data_description.no_checklist_items") }}
													</td>
												</tr>

												<tr v-for="(ci, i) in rubric.checklist_items" :key="ci.id">
													<td
														:key="`cl-${i}`"
														class="p-custom pl-2 p-0 drag-handle v-mid"
														style="flex-grow: 0"
													>
														<i class="far fa-sort text-muted"></i>
													</td>
													<td>
														<input
															v-model="ci.name"
															type="text"
															class="form-control"
															:disabled="rubric.isScored"
														/>
													</td>
													<td>
														<div class="checkbox text-center">
															<label
																class="md-check"
																:class="{ disabled: rubric.isScored }"
															>
																<input v-model="ci.annotation" type="checkbox" />
																<i class="theme"></i>
															</label>
														</div>
													</td>
													<td>
														<div class="checkbox text-center">
															<label
																class="md-check"
																:class="{ disabled: rubric.isScored }"
															>
																<input v-model="ci.required" type="checkbox" />
																<i class="theme"></i>
															</label>
														</div>
													</td>
													<td>
														<button
															@click="removeChecklistItem(ci)"
															class="btn btn-sm btn-icon btn-rounded red text-white m-0"
															:disabled="rubric.isScored"
														>
															<i class="fa fa-trash"></i>
														</button>
													</td>
												</tr>
											</draggable>
										</table>
										<div
											class="d-flex flex-row justify-content-stretch"
											style="padding: 0.6rem 0.75rem"
										>
											<button
												@click="addChecklistItem()"
												class="flex btn btn-sm btn-success mr-3"
												:disabled="rubric.isScored"
											>
												{{ $t("buttons.add_item") }}
											</button>
										</div>
									</div>
								</div>

								<!-- Numeric Traits -->
								<div class="row mb-3">
									<div class="col">
										<label>{{ $t("RubricEdit.numeric_traits") }}</label>
										<table class="table mb-0">
											<thead>
												<tr>
													<th class="p-custom p-0"></th>
													<th class="trait-name">{{ $t("fields.name") }}</th>
													<th class="num-select">{{ $t("fields.min") }}</th>
													<th class="num-select">{{ $t("fields.max") }}</th>
													<th>{{ $t("fields.step") }}</th>
													<th>{{ $t("fields.style") }}</th>
													<th>{{ $t("fields.weight") }}</th>
													<th></th>
												</tr>
											</thead>
											<draggable
												v-model="rubric.traits"
												:options="{ handle: '.drag-handle', animation: 150 }"
												@end="dragEnd"
												:element="'tbody'"
											>
												<tr v-for="rawTrait in rubric.traits" :key="rawTrait.id">
													<template v-for="trait in [traitWithLinks(rawTrait)]">
														<template v-if="trait.is_parent">
															<td
																:key="`c0-${trait.id}`"
																class="p-custom pl-2 p-0 v-mid"
																style="flex-grow: 0"
															></td>
															<td :key="`c1-${trait.id}`" class="v-mid" colspan="7">
																<div class="d-flex flex-row align-items-center">
																	<div>
																		Group:
																		<span class="_800">{{ trait.name }}</span>
																	</div>
																	<div class="ml-auto">
																		Min:
																		<span
																			class="_800"
																			:class="{
																				[getGroupMin(trait.external_trait_id) ==
																				trait.min
																					? 'text-success'
																					: 'text-danger']: true,
																			}"
																			>{{
																				getGroupMin(trait.external_trait_id)
																			}}/{{ trait.min }}</span
																		>
																	</div>
																	<div class="ml-2">
																		Max:
																		<span
																			class="_800"
																			:class="{
																				[getGroupMax(trait.external_trait_id) ==
																				trait.max
																					? 'text-success'
																					: 'text-danger']: true,
																			}"
																			>{{
																				getGroupMax(trait.external_trait_id)
																			}}/{{ trait.max }}</span
																		>
																	</div>
																	<button
																		@click="addTrait(trait.external_trait_id)"
																		class="ml-auto btn btn-sm btn-success"
																		:disabled="rubric.isScored"
																	>
																		{{ $t("buttons.add_subtrait") }}
																	</button>
																</div>
															</td>
														</template>
														<template v-else>
															<td
																:key="`c0-${trait.id}`"
																class="p-custom pl-2 p-0 drag-handle v-mid"
																style="flex-grow: 0"
															>
																<i class="far fa-sort text-muted"></i>
															</td>
															<td :key="`c1-${trait.id}`" class="v-mid">
																<input
																	class="form-control"
																	v-model="trait.name"
																	type="text"
																/>
															</td>
															<template v-if="!trait.linked_to">
																<template v-if="!trait.separator">
																	<td :key="`c2-${trait.id}`" class="w100 v-mid">
																		<input
																			class="form-control"
																			v-model.number="trait.min"
																			type="number"
																			:disabled="rubric.isScored"
																		/>
																	</td>
																	<td :key="`c3-${trait.id}`" class="w100 v-mid">
																		<input
																			class="form-control"
																			v-model.number="trait.max"
																			type="number"
																			:disabled="rubric.isScored"
																		/>
																	</td>
																	<td
																		:key="`c4-${trait.id}`"
																		class="text-center v-mid"
																	>
																		<config-select
																			:disabled="rubric.isScored"
																			:options="fs.stepTypes()"
																			byField="id"
																			sortBy="sequence"
																			v-model="trait.step"
																		/>
																	</td>
																	<td
																		:key="`c5-${trait.id}`"
																		class="text-center v-mid"
																	>
																		<b-row class="text-center" align-h="center">
																			<button
																				@click="trait.style = 0"
																				class="btn btn-sm btn-icon btn-rounded text-white"
																				:class="{
																					'theme-accent': trait.style === 0,
																				}"
																				v-tippy
																				:title="$t('fields.buttons')"
																				:disabled="rubric.isScored"
																			>
																				<i class="fa fa-stop"></i>
																			</button>
																			<button
																				@click="trait.style = 1"
																				class="btn btn-sm btn-icon btn-rounded text-white"
																				:class="{
																					'theme-accent': trait.style === 1,
																				}"
																				v-tippy
																				:title="$t('fields.slider')"
																				:disabled="rubric.isScored"
																			>
																				<i class="fa fa-sliders-h"></i>
																			</button>
																		</b-row>
																	</td>
																	<td :key="`c6-${trait.id}`" class="w-80 v-mid">
																		<input
																			class="form-control"
																			v-model="trait.weight"
																			type="number"
																			:disabled="rubric.isScored"
																		/>
																	</td>
																</template>
																<td
																	:key="`c7-${trait.id}`"
																	colspan="4"
																	class="v-mid text-center v-mid"
																	v-if="trait.separator"
																>
																	<div class="d-flex flex-row align-items-center">
																		<span class="text-muted mr-2">{{
																			$t("RubricEdit.show_media_with_ref_id")
																		}}</span>
																		<input
																			class="form-control"
																			style="width: 120px"
																			v-model="trait.page_ref_id"
																		/>
																	</div>
																</td>
															</template>
															<template v-if="trait.linked_to">
																<td
																	:key="`c8-${trait.id}`"
																	colspan="4"
																	class="text-muted v-mid"
																>
																	<i class="fas fa-link" />
																	{{ $t("RubricEdit.linked_to") }}
																	{{ getTrait(trait.linked_to).name }}
																	<template v-if="trait.hidden">
																		&amp;
																		<i class="fas fa-eye-slash" />
																		{{ $t("RubricEdit.trait_edit_modal.hidden") }}
																	</template>
																</td>
																<td :key="`c6-${trait.id}`" class="w-80 v-mid">
																	<input
																		class="form-control"
																		v-model="trait.weight"
																		type="number"
																		:disabled="rubric.isScored"
																	/>
																</td>
															</template>
															<td :key="`c9-${trait.id}`" class="w90 v-mid">
																<button
																	v-if="!trait.separator"
																	@click="editSPDs(trait)"
																	data-toggle="modal"
																	data-target="#spdEditModal"
																	class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
																	:disabled="trait.linked_to"
																	v-tippy
																	:title="$t('buttons.score_points_descriptors')"
																>
																	<i
																		class="fa fa-comment-alt-lines"
																		style="font-size: 0.9rem"
																	></i>
																</button>
																<button
																	v-if="!trait.separator"
																	@click="editTrait(trait)"
																	data-toggle="modal"
																	data-target="#traitEditModal"
																	class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
																	v-tippy
																	:title="$t('buttons.trait_settings')"
																>
																	<i class="fa fa-cog"></i>
																</button>
																<button
																	@click="removeTrait(trait)"
																	class="btn btn-sm btn-icon btn-rounded red text-white m-0"
																	v-tippy
																	:title="$t('buttons.remove_trait')"
																	:disabled="rubric.isScored"
																>
																	<i class="fa fa-trash"></i>
																</button>
															</td>
														</template>
													</template>
												</tr>
												<template slot="header">
													<tr v-if="!rubric.traits.length">
														<td colspan="7" class="text-center">
															{{ $t("data_description.no_traits_configured") }}
														</td>
													</tr>
												</template>
											</draggable>
										</table>
										<div
											v-if="!rubric.restrict_subtraits"
											class="d-flex flex-row justify-content-stretch"
											style="padding: 0.6rem 0.75rem"
										>
											<button
												@click="addTrait()"
												class="flex btn btn-sm btn-success mr-3 theme-accent"
												:disabled="rubric.isScored"
											>
												{{ $t("buttons.add_trait") }}
											</button>
											<button
												@click="addPage()"
												class="flex btn btn-sm btn-success theme-accent"
												:disabled="rubric.isScored"
											>
												{{ $t("buttons.add_page") }}
											</button>
										</div>
									</div>
								</div>

								<!-- Text Traits -->
								<div class="row">
									<div class="col">
										<label>{{ $t("RubricEdit.text_traits") }}</label>
										<table class="table">
											<thead>
												<tr>
													<th>{{ $t("fields.name") }}</th>
													<th>{{ $t("fields.type") }}</th>
													<th>{{ $t("fields.required") }}</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td
														class="text-center"
														v-if="!rubric.meta_traits.length"
														colspan="4"
													>
														{{ $t("data_description.no_text_traits") }}
													</td>
												</tr>

												<tr v-for="meta_trait in rubric.meta_traits" :key="meta_trait.id">
													<td>
														<input
															v-model="meta_trait.name"
															type="text"
															class="form-control"
															:disabled="rubric.isScored"
														/>
													</td>
													<td>
														<select
															class="form-control"
															v-model="meta_trait.meta_trait_type"
															:disabled="rubric.isScored"
														>
															<option :value="1">
																{{ $t("RubricEdit.text") }}
															</option>
															<option :value="2">
																{{ $t("RubricEdit.long_text") }}
															</option>
															<option :value="3">
																{{ $t("RubricEdit.text_select") }}
															</option>
														</select>
													</td>
													<td>
														<label
															class="ui-switch ui-switch-md"
															:class="[
																rubric.isScored ? 'theme-lighten-4' : 'theme-accent',
															]"
														>
															<input
																type="checkbox"
																v-model="meta_trait.required"
																:disabled="rubric.isScored"
															/>
															<i></i>
														</label>
													</td>
													<td>
														<button
															v-if="meta_trait.meta_trait_type == 3"
															@click="editMetaTrait(meta_trait)"
															v-b-modal.metaTraitEditModal
															class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
															:title="$t('tooltip.text_select_settings')"
															:disabled="rubric.isScored"
														>
															<i class="fa fa-cog"></i>
														</button>
														<button
															@click="removeMetaTrait(meta_trait)"
															class="btn btn-sm btn-icon btn-rounded red text-white m-0"
															:title="$t('tooltip.remove_text_trait')"
															:disabled="rubric.isScored"
														>
															<i class="fa fa-trash"></i>
														</button>
													</td>
												</tr>

												<tr>
													<td class="text-center" colspan="3">
														<button
															@click="addMetaTrait()"
															style="max-width: 600px"
															class="btn btn-sm btn-block btn-success m-auto"
															:disabled="rubric.isScored"
														>
															{{ $t("RubricEdit.add_text_trait") }}
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</b-col>

							<b-col>
								<b-row no-gutters>
									<b-col>
										<div :class="{ invalid: !valid.field('anyTraits') }">
											<label>{{ $t("RubricEdit.preview") }}</label>
											<rubric
												:outer-rubric="rubricWithLinks"
												:score.sync="score"
												:complete.sync="complete"
												:pageNav.sync="pageNav"
												:user="user"
											>
											</rubric>
										</div>
									</b-col>
									<b-col>
										<score v-if="rubric && score" :score="score" :rubric="rubric" :type="1">
										</score>
									</b-col>
									<b-col v-if="score && score.doFlag">
										<flag
											:flag="{ user, alert_id: score.doFlag }"
											:alerts="client && client.alerts"
											hide-actions
										/>
									</b-col>
									<div v-if="pageNav" class="mx-3 my-2">
										<button
											class="btn btn-flat btn-primary"
											@click="pageNav.prev"
											:disabled="!pageNav.canGoPrev"
											v-tippy="{ placement: 'bottom', hideOnClick: false }"
											:title="$t('tooltip.previous_rubric_page')"
										>
											{{ $t("buttons.prev") }}
										</button>
										<button
											class="btn btn-flat btn-primary"
											@click="pageNav.next"
											:disabled="!pageNav.canGoNext"
											v-tippy="{ placement: 'bottom', hideOnClick: false }"
											:title="$t('tooltip.next_rubric_page')"
										>
											{{ $t("buttons.next") }}
										</button>
									</div>
								</b-row>
								<!-- Uncomment to debug the scores generated -->
								<!-- <div class="row">
											Complete:{{complete}}
											<br><pre>{{score}}</pre>
										</div>-->
							</b-col>
						</b-row>
					</b-container>
				</div>
			</edit-pane>
			<save-optional-modal
				:dirty="dirty"
				:valid="valid"
				:save="saveRubric"
				:next="saveOptNext"
				:cancel="saveOptCancel"
				objectText="Rubric"
				:objectName="rubric.name"
				actionText="leave the page"
				v-model="saveOptModal"
			/>
		</div>

		<trait-edit-modal
			:editingTrait="editingTrait"
			:rubric="rubric"
			:alerts="client && client.alerts"
			@close="closeTraitEditModal"
		>
		</trait-edit-modal>

		<!-- Text Trait Edit Modal -->
		<b-modal id="metaTraitEditModal" v-if="editingMetaTrait" data-backdrop="static" data-keyboard="false">
			<div slot="modal-header" class="modal-header">
				<h5>
					{{ $t("RubricEdit.text_edit_modal.title") }}
					<strong class="text-theme">{{ editingMetaTrait.name }}</strong>
				</h5>
			</div>
			<div class="modal-body">
				<table class="table">
					<thead>
						<tr>
							<th>{{ $t("RubricEdit.text_edit_modal.option_text") }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!textOpts.length">
							No Options Configured
						</tr>

						<tr v-for="(opt, i) in textOpts" :key="i">
							<td>
								<input type="text" v-model="opt.val" class="form-control" />
							</td>
						</tr>

						<tr>
							<td>
								<button
									@click="textOpts.push({ val: 'Option' })"
									class="btn btn-sm btn-block btn-success"
								>
									{{ $t("RubricEdit.text_edit_modal.add_option") }}
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div slot="modal-footer">
				<button class="btn btn-flat" @click="finishEditingMetaTrait()">{{ $t("buttons.done") }}</button>
			</div>
		</b-modal>

		<!-- Score Point Desciptor Edit Modal -->
		<div class="modal" id="spdEditModal" data-backdrop="static" data-keyboard="false">
			<div v-if="editingSPDTrait" class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5>
							{{ $t("RubricEdit.descriptors_modal.title") }}
							<strong>{{ editingSPDTrait.name }}</strong>
						</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12">
								<table class="table">
									<thead>
										<tr>
											<th style="width: 60px">{{ $t("fields.score_point") }}</th>
											<th>{{ $t("fields.descriptor") }}</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="sp in editingSPDTrait.score_point_descriptors"
											:key="`${sp.sequence}`"
										>
											<td class="v-mid text-center">
												<h3>{{ sp.val >= 0 ? sp.val : sp.code }}</h3>
											</td>
											<td>
												<RichText
													:text.sync="sp.desc"
													:initial="sp.init_desc"
													:options="{ height: 104 }"
													integrated
													:refresh="editingSPDTrait.id"
												>
												</RichText>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.done") }}</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w90 {
	min-width: 116px;
}

.w100 {
	width: 90px;
}

.trait-name {
	min-width: 400px;
}

.num-select {
	min-width: 75px;
}

.modal-lg {
	max-width: 1000px;
}

.drag-handle {
	cursor: move;
}

.table > tbody > tr > td {
	padding-right: 0%;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import RubricService from "@/services/RubricService";
import TenantService from "@/services/TenantService";
import ValidationService from "@/services/ValidationService";
import AuthService from "@/services/AuthService";
import notie from "../../services/NotieService";
import fs from "@/services/FormatService";
import Utils from "../../services/Utils";
import BB from "bluebird";

import ConfigTrait from "../../components/ConfigTrait";
import ConfigSelect from "../../components/ConfigSelect";
import Rubric from "../../components/Rubric";
import Score from "../../components/Score";
import Flag from "../../components/Flag";
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";
import TraitEditModal from "@/components/rubric_edit/TraitEditModal.vue";
import RichText from "@/components/RichText";
import draggable from "vuedraggable";

export default {
	name: "RubricEdit",
	data() {
		return {
			fs: fs,
			user: null,
			client: null,
			rubric: {},
			dirty: false,
			editingTrait: null,
			selected: [],
			editingMetaTrait: null,
			editingSPDTrait: null,
			textOpts: [],
			score: null,
			complete: false,
			pageNav: null,
			autoRef: false,
			loading: true,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
			linkedTo: null,
		};
	},

	components: {
		ConfigTrait,
		ConfigSelect,
		Rubric,
		Score,
		Flag,
		EditPane,
		SaveOptionalModal,
		TraitEditModal,
		RichText,
		draggable,
	},

	created() {
		this.loadData();
		this.initValidation();
		AuthService.getUser().then((user) => {
			this.user = user;
		});
	},

	computed: {
		rubricWithLinks() {
			let traits = [];
			_.each(this.rubric.traits, (trait) => {
				let traitWithLinks = this.traitWithLinks(trait);

				traits.push(traitWithLinks);
			});

			let rubricWithLinks = _.cloneDeep(this.rubric);
			rubricWithLinks.traits = traits;
			return rubricWithLinks;
		},
	},

	watch: {
		"rubric.name"() {
			if (this.autoRef && !this.rubric.isScored) {
				let genRef = fs.toGoodRefID(this.rubric.name);
				if (this.rubric.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.rubric.ref_id = genRef;
				}
			}
		},
		"rubric.ref_id"() {
			this.checkAutoRef();
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: "You must assign a name",
					func: () => {
						return this.rubric && this.rubric.name && this.rubric.name != "";
					},
				},
				ref: {
					group: "details",
					errorMsg: "You must assign a reference ID",
					func: () => {
						return this.rubric && this.rubric.ref_id && this.rubric.ref_id != "";
					},
				},
				ref_char: {
					group: "details",
					errorMsg:
						"Reference IDs may not contain spaces or any of the following characters: ! # % ^ * ( )  / [ ] { } < > ? | ' \" :",
					func: () => {
						return this.rubric && this.rubric.ref_id && fs.isGoodRefID(this.rubric.ref_id);
					},
				},
				anyTraits: {
					group: "rubric",
					errorMsg: "You must specify at least one trait",
					func: () => {
						return (
							this.rubric &&
							this.rubric.meta_traits &&
							this.rubric.traits &&
							(this.rubric.meta_traits.length > 0 || this.rubric.traits.length > 0)
						);
					},
				},
				valueConditionCodes: {
					group: "rubric",
					errorMsg:
						"You may not define a condition code that applies a score that is outside the trait's normal range",
					func: () => {
						if (!(this.rubric && this.rubric.traits)) return true;

						let inside = true;
						_.each(this.rubric.traits, (t) => {
							_.each(t.condition_codes, (cc) => {
								if (cc.action == 3 || cc.action == 4) {
									if (!this.isInScoreRange(t, cc.score)) {
										inside = false;
									}
								}
							});
						});

						return inside;
					},
				},
				groupMinMax: {
					group: "rubric",
					errorMsg: "Trait min and max values in each group must add up to the required min/max of the group",
					func: () => {
						let valid = true;
						_.each(this.rubric.traits, (t) => {
							if (t.is_parent) {
								let satisfiedMin = t.min == this.getGroupMin(t.external_trait_id);
								let satisfiedMax = t.max == this.getGroupMax(t.external_trait_id);
								if (!(satisfiedMin && satisfiedMax)) {
									valid = false;
								}
							}
						});
						return valid;
					},
				},
			});
		},

		blankRubric() {
			return {
				name: "",
				traits: [],
				meta_traits: [],
				score_point_descriptors: [],
				checklist_items: [],
				checklist: false,
				score_mapping_rows: [],
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"rubric",
				(newc, old) => {
					// console.log(Utils.diff(newc, old));
					// console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;

			TenantService.getClient()
				.then((resp) => {
					this.client = resp.data;
					if (this.client && this.rubric) {
						this.fillAlerts();
					}
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_flag_codes_fail"), err);
				});

			if (this.$route.params.id == "new") {
				this.rubric = this.blankRubric();
				this.$set(this.rubric, "name", "New Rubric");
				this.$set(this.rubric, "ref_id", "new_rubric");
				this.loading = false;
				this.watchChanges();
			} else {
				let id = this.$route.params.id;
				return BB.props({
					rubric: RubricService.getRubric(id),
					scored: RubricService.isScored(id),
					resGroup: RubricService.isUsedInResGroup(id),
				})
					.then((resps) => {
						let rubric = resps.rubric.data;
						RubricService.prepIncomingData(rubric);
						this.rubric = rubric;
						this.cleanupRubric();

						if (this.rubric && this.client) {
							this.fillAlerts();
						}

						this.rubric.isScored = resps.scored.data || resps.resGroup.data;
						this.markExistingComments();

						this.loading = false;
						this.checkAutoRef();
						this.watchChanges();
					})
					.catch((err) => {
						console.log(err);
						notie.error(this.$i18n.t("notie.rubric_not_found"), err);
						this.$router.replace("/rubrics/new");
					});
			}
		},

		fillAlerts() {
			_.each(this.rubric.traits, (trait) => {
				_.each(trait.condition_codes, (code) => {
					if (code.action == 1) {
						code.selectedAction = 1;
					} else {
						code.selectedAction = code.alert_id;
					}
				});
			});
		},

		async saveRubric() {
			this.cleanupRubric();

			let saveRubric = RubricService.prepOutgoingData(this.rubric);
			this.debug("saveRubric", saveRubric);
			try {
				const resp = await RubricService.saveRubric(saveRubric);
				notie.info(this.$i18n.t("notie.rubric_saved"));
				if (!saveRubric.id) {
					this.$router.replace("/rubrics/" + resp.data.id);
				}
				this.loadData();
			} catch (err) {
				console.log(err);
				notie.error(this.$i18n.t("notie.save_rubric_fail"), err);
			}
		},

		addTrait(groupRefID) {
			let numRealTraits = 0;
			_.each(this.rubric.traits, (t) => {
				if (!(t.separator || t.is_parent)) {
					numRealTraits++;
				}
			});
			let id = Utils.generateUUID();
			let newTrait = {
				name: this.$i18n.t("RubricEdit.trait") + " " + (numRealTraits + 1),
				id: id,
				linked_to: null,
				sequence: this.rubric.traits.length + 1,
				min: 0,
				max: 5,
				step: 1,
				style: 0,
				weight: 1,
				condition_codes: [],
				score_point_codes: [],
				score_point_code_limits: [],
				score_point_descriptors: [],
				masked_score_points: [],
			};
			if (groupRefID) {
				newTrait.external_trait_id = `${groupRefID}||${id}`;
				this.insertInGroupPosition(this.rubric.traits, newTrait, groupRefID);
			} else {
				this.rubric.traits.push(newTrait);
			}
			this.cleanupRubric();
		},

		insertInGroupPosition(traits, newTrait, groupRefID) {
			let pos = 0;
			let i = 0;
			_.each(traits, (trait) => {
				let grID = this.getGroupRefID(trait);
				if (grID == groupRefID || trait.external_trait_id == groupRefID) {
					pos = i;
				}
				i++;
			});
			pos++;

			this.rubric.traits.splice(pos, 0, newTrait);
		},

		addPage() {
			let numPages = 0;
			_.each(this.rubric.traits, (t) => {
				if (t.separator) {
					numPages++;
				}
			});
			this.rubric.traits.push({
				name: this.$i18n.t("pagination.page") + " " + (numPages + 1),
				id: Utils.generateUUID(),
				sequence: this.rubric.traits.length + 1,
				min: 0,
				max: 5,
				weight: 1,
				condition_codes: [],
				score_point_codes: [],
				score_point_descriptors: [],
				masked_score_points: [],
				separator: true,
			});
			this.cleanupRubric();
		},

		removeTrait(trait) {
			this.rubric.traits = _.without(this.rubric.traits, trait);
			this.cleanupRubric();
		},

		removeMetaTrait(meta_trait) {
			this.rubric.meta_traits = _.without(this.rubric.meta_traits, meta_trait);
			this.cleanupRubric();
		},

		editTrait(trait) {
			_.each(trait.condition_codes, (cc) => {
				cc.selectedAction = cc.action;
			});
			this.editingTrait = trait;
		},

		addMetaTrait() {
			this.rubric.meta_traits.push({
				id: Utils.generateUUID(),
				meta_trait_type: 1,
				sequence: this.rubric.meta_traits.length + 1,
				name: this.$i18n.t("RubricEdit.new_trait"),
				required: false,
				options: "",
			});
			this.cleanupRubric();
		},

		editMetaTrait(meta_trait) {
			this.editingMetaTrait = meta_trait;
			this.textOpts = meta_trait.options.split("|").map((v) => {
				return { val: v };
			});
		},

		addChecklistItem() {
			this.rubric.checklist_items.push({
				id: Utils.generateUUID(),
				sequence: this.rubric.checklist_items.length + 1,
				name: this.$i18n.t("RubricEdit.new_item"),
				required: false,
				annotation: false,
			});
		},

		removeChecklistItem(ci) {
			this.rubric.checklist_items = _.without(this.rubric.checklist_items, ci);
			this.cleanupRubric();
		},

		finishEditingMetaTrait() {
			this.editingMetaTrait.options = this.textOpts
				.map((v) => {
					return v.val;
				})
				.join("|");
			this.textOpts = [];
			this.editingMetaTrait = null;
			this.$bvModal.hide("metaTraitEditModal");
		},

		cleanupRubric() {
			var seq = 0;
			_.each(this.rubric.traits, (trait) => {
				trait.min = trait.min * 1;
				trait.max = trait.max * 1;
				trait.weight = trait.weight * 1;
				trait.sequence = seq;
				seq++;
			});

			seq = 0;
			_.each(this.rubric.meta_traits, (meta_trait) => {
				meta_trait.sequence = seq;
				seq++;
			});
			this.rubric.traits = _.sortBy(this.rubric.traits, "sequence");
			console.log("Sorted traits", this.rubric.traits);
		},

		editSPDs(trait) {
			this.setupDescriptors(trait);
			this.editingSPDTrait = trait;
		},

		setupDescriptors(trait) {
			var seq = 0;

			var step = trait.step;
			if (!trait.score_point_descriptors) {
				this.$set(trait, "score_point_descriptors", []);
			}
			var min = trait.min;
			var max = trait.max;

			var skip = trait.masked_score_points.map(function (mask) {
				return mask.mask_value;
			});

			if (this.rubric.reverse_score_points) {
				for (var i = max; i >= min; i -= step) {
					if (_.includes(skip, i)) {
						continue;
					}
					let existing = _.find(trait.score_point_descriptors, {
						val: i,
					});
					if (existing) {
						existing.sequence = seq;
						existing.init_desc = existing.desc;
						existing.keep = true;
					} else {
						trait.score_point_descriptors.push({
							val: i,
							desc: "",
							init_desc: "",
							sequence: seq,
							keep: true,
						});
					}
					seq++;
				}
			} else {
				for (var i = min; i <= max; i += step) {
					if (_.includes(skip, i)) {
						continue;
					}
					let existing = _.find(trait.score_point_descriptors, {
						val: i,
					});
					if (existing) {
						existing.sequence = seq;
						existing.init_desc = existing.desc;
						existing.keep = true;
					} else {
						trait.score_point_descriptors.push({
							val: i,
							desc: "",
							init_desc: "",
							sequence: seq,
							keep: true,
						});
					}
					seq++;
				}
			}

			trait.score_point_descriptors = _.sortBy(trait.score_point_descriptors, "sequence");

			_.each(trait.condition_codes, (cc) => {
				let existing = _.find(trait.score_point_descriptors, {
					code: cc.symbol,
				});
				if (existing) {
					existing.sequence = seq;
					existing.init_desc = existing.desc;
					existing.keep = true;
				} else {
					trait.score_point_descriptors.push({
						val: -1,
						code: cc.symbol,
						desc: "",
						init_desc: "",
						sequence: seq,
						keep: true,
					});
				}
				seq++;
			});

			trait.score_point_descriptors = _.filter(trait.score_point_descriptors, { keep: true });
			_.each(trait.score_point_descriptors, (spd) => {
				delete spd.keep;
			});
		},

		checkAutoRef() {
			console.log("checkAutoRef");
			let genRef = fs.toGoodRefID(this.rubric.name);
			this.autoRef = this.rubric.ref_id.toLowerCase() == genRef.toLowerCase() && !this.rubric.isScored;
		},

		markExistingComments() {
			_.each(this.rubric.traits, (trait) => {
				_.each(trait.score_point_codes, (spc) => {
					spc.locked = true;
				});
			});
		},

		getTrait(traitID) {
			return _.find(this.rubric.traits, { id: traitID });
		},

		traitWithLinks(trait) {
			if (trait.linked_to) {
				let linkedToTrait = _.find(this.rubric.traits, {
					id: trait.linked_to,
				});
				if (linkedToTrait) {
					// Comparing in this way allows us to do deep comparisons within objects.
					// Thus, we prevent changing anything in the trait unless it actually needs to be
					// changed, which in turn prevent superfluous occurrences of dirty==true
					let ensureEqual = function (a, b, prop) {
						if (!_.isEqual(a[prop], b[prop])) {
							a[prop] = b[prop];
						}
					};
					ensureEqual(trait, linkedToTrait, "min");
					ensureEqual(trait, linkedToTrait, "max");
					ensureEqual(trait, linkedToTrait, "masked_score_points");
					ensureEqual(trait, linkedToTrait, "step");
					ensureEqual(trait, linkedToTrait, "inline_condition_codes");
					ensureEqual(trait, linkedToTrait, "condition_codes");
					ensureEqual(trait, linkedToTrait, "score_point_codes");
					ensureEqual(trait, linkedToTrait, "score_point_descriptors");
				} else {
					trait.linked_to = false;
				}
			}

			return trait;
		},

		dragEnd() {
			let seq = 0;
			console.log("Drag end");
			_.each(this.rubric.traits, (t) => {
				t.sequence = seq;
				seq++;
			});
		},

		closeTraitEditModal() {
			this.editingTrait = null;
		},

		isInScoreRange(trait, val) {
			if (typeof val !== "number") return false;

			if (val < trait.min) {
				return false;
			}
			if (val > trait.max) {
				return false;
			}

			return true;
		},

		getGroupMin(groupRefID) {
			let min = 0;
			_.each(this.rubric.traits, (trait) => {
				if (trait.separator) return null;
				if (trait.is_parent) return null;
				if (!trait.external_trait_id) return null;

				let parts = trait.external_trait_id.split("||");
				if (parts.length != 2) return null;

				if (parts[0] == groupRefID) {
					min += trait.min;
				}
			});
			return min;
		},

		getGroupMax(groupRefID) {
			let max = 0;
			_.each(this.rubric.traits, (trait) => {
				if (trait.separator) return null;
				if (trait.is_parent) return null;
				if (!trait.external_trait_id) return null;

				let parts = trait.external_trait_id.split("||");
				if (parts.length != 2) return null;

				if (parts[0] == groupRefID) {
					max += trait.max;
				}
			});
			return max;
		},

		getGroupRefID(trait) {
			let parts = trait.external_trait_id.split("||");
			if (parts.length != 2) return null;
			return parts[0];
		},
	},
};
</script>
