<template>
	<div class="row">
		<div class="col-12 form-group mt-2">
			<h5 class="mb-0 text-center"><i class="fas fa-microphone mr-2" />{{ $t("VideoMeeting.audio") }}</h5>
		</div>
		<template v-if="uiState.permissions.microphone.allowed">
			<div class="col-12 form-group smooth-opacity">
				<label><i class="fas fa-microphone mx-2" />{{ $t("VideoMeeting.input_devices") }}</label>
				<v-select
					:options="devices.audioInputs"
					:reduce="(o) => o.deviceId"
					v-model="devices.audioInputId"
					@option:selected="audioInputSelected"
					:searchable="false"
					appendToBody
				/>
			</div>
			<div v-if="uiState.permissions.voiceFocus" class="col-12">
				<div class="ml-auto form-group d-flex flex-row align-items-center">
					<label class="ui-switch ui-switch-md theme-accent">
						<input
							type="checkbox"
							@click="toggleVoiceFocus"
							:checked="uiState.voiceFocus || uiState.changingVoiceFocus"
							:disabled="uiState.changingVoiceFocus"
						/>
						<i></i>
					</label>
					<span class="ml-2">
						{{ $t("VideoMeeting.noise_suppression") }}
					</span>
				</div>
			</div>
			<div class="col-12 form-group smooth-opacity">
				<label><i class="fas fa-volume mx-2" />{{ $t("VideoMeeting.output_devices") }}</label>
				<v-select
					:options="devices.audioOutputs"
					:reduce="(o) => o.deviceId"
					v-model="devices.audioOutputId"
					@option:selected="audioOutputSelected"
					:searchable="false"
					appendToBody
				/>
			</div>
		</template>
		<template v-else>
			<div class="perm-disabled-box shadow-inset text-center">
				<template v-if="uiState.permissions.microphone.blocked">
					<div class="perm-blocked-text text-danger">
						<i class="fas fa-exclamation-triangle mr-2" />Blocked
					</div>
					<div class="text-muted">
						This page has been blocked from accessing your microphone. This can only be fixed in your
						browser settings or navigation bar.
					</div>
				</template>
				<template v-else-if="uiState.permissions.microphone.changing">
					<loading type="large" />
					<div class="text-muted" style="margin-top: -20px; margin-bottom: 10px">
						Requesting permission...
					</div>
				</template>
				<template v-else>
					<button class="btn btn-primary mx-auto my-2" @click="requestInitAudio">
						Request Microphone Access
					</button>
					<div class="text-muted">
						You don't have to do this to join the meeting, but you won't be able to speak until you do.
					</div>
				</template>
			</div>
		</template>
		<hr class="w-100" />
		<div class="col-12 form-group mt-2">
			<h5 class="mb-0 text-center"><i class="fas fa-video mr-2" />{{ $t("VideoMeeting.video") }}</h5>
		</div>
		<template v-if="uiState.permissions.camera.allowed">
			<div class="col-12 form-group smooth-opacity">
				<label><i class="fas fa-camera mx-2" />{{ $t("VideoMeeting.camera_devices") }}</label>
				<v-select
					:options="devices.videoInputs"
					:reduce="(o) => o.deviceId"
					v-model="devices.videoInputId"
					@option:selected="videoInputSelected"
					:searchable="false"
					appendToBody
				/>
			</div>
			<div v-if="uiState.permissions.backgroundBlur" class="col-12">
				<div class="ml-auto form-group d-flex flex-row align-items-center">
					<label class="ui-switch ui-switch-md theme-accent">
						<input
							type="checkbox"
							@click="toggleBackgroundBlur"
							:checked="uiState.backgroundBlur || uiState.changingBackgroundBlur"
							:disabled="uiState.changingBackgroundBlur"
						/>
						<i></i>
					</label>
					<span class="ml-2">
						{{ $t("VideoMeeting.background_blur") }}
					</span>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="perm-disabled-box shadow-inset text-center">
				<template v-if="uiState.permissions.camera.blocked">
					<div class="perm-blocked-text text-danger">
						<i class="fas fa-exclamation-triangle mr-2" />Blocked
					</div>
					<div class="text-muted">
						This page has been blocked from accessing your camera. This can only be fixed in your browser
						settings or navigation bar.
					</div>
				</template>
				<template v-else-if="uiState.permissions.camera.changing">
					<loading type="large" />
					<div class="text-muted" style="margin-top: -20px; margin-bottom: 10px">
						Requesting permission...
					</div>
				</template>
				<template v-else>
					<button class="btn btn-primary mx-auto my-2" @click="requestInitVideo">
						Request Camera Access
					</button>
					<div class="text-muted">
						You don't have to do this to join the meeting, but you won't be able to show your camera until
						you do.
					</div>
				</template>
			</div>
		</template>
	</div>
</template>

<style scoped>
.perm-disabled-box {
	width: 100%;
	margin: 0 1rem 0.5rem 1rem;
	padding: 1rem;
	border: 1px solid #dee2e6 !important;
	border-radius: 10px;
	background-color: rgba(0 0 0 / 5%);
}
.perm-blocked-text {
	font-size: 24px;
}
.perm-blocked-text > i {
	font-size: 24px;
}
</style>

<script>
//UI Components
import ConfigSelect from "@/components/ConfigSelect";

//Libraries
import _ from "lodash";

//Services
import Chime from "@/vues/VideoMeeting/services/ChimeEngine";
import Notie from "@/services/NotieService";
import Store from "@/services/Store";
import Utils from "@/services/Utils";

export default {
	name: "DeviceSettings",
	props: ["uiState", "devices"],
	components: { ConfigSelect },
	data() {
		return {
			backgroundBlur: false,
		};
	},
	created() {},
	mounted() {},

	computed: {},

	methods: {
		async audioInputSelected(e) {
			this.log("Audio input selected", e.label, e.deviceId);
			try {
				await Chime.pickAudioInput(e.deviceId);
				Store.set(this, "VideoMeeting.audioInputId", e.deviceId);
			} catch (e) {
				throw e;
			}
		},
		async audioOutputSelected(e) {
			this.log("Audio output selected", e.label, e.deviceId);
			try {
				await Chime.pickAudioOutput(e.deviceId);
				Store.set(this, "VideoMeeting.audioOutputId", e.deviceId);
			} catch (e) {
				throw e;
			}
		},
		async videoInputSelected(e) {
			this.log("Video input selected", e.label, e.deviceId);
			try {
				await Chime.pickVideoInput(e.deviceId);
				Store.set(this, "VideoMeeting.videoInputId", e.deviceId);
			} catch (e) {
				throw e;
			}
		},

		async toggleBackgroundBlur() {
			if (this.uiState.backgroundBlur) {
				this.log("Disabling background blur");
				await Chime.disableBackgroundBlur();
				Store.set(this, "VideoMeeting.backgroundBlur", false);
			} else {
				this.log("Enabling background blur");
				await Chime.enableBackgroundBlur();
				Store.set(this, "VideoMeeting.backgroundBlur", true);
			}
		},

		async toggleVoiceFocus() {
			if (this.uiState.voiceFocus) {
				this.log("Disabling noise suppression");
				Chime.disableVoiceFocus();
				Store.set(this, "VideoMeeting.voiceFocus", false);
			} else {
				this.log("Enabling noise suppression");
				Chime.enableVoiceFocus();
				Store.set(this, "VideoMeeting.voiceFocus", true);
			}
		},

		requestInitAudio() {
			this.uiState.shouldEnableAudio = true;
			this.$emit("requestInitAudio", true);
		},

		requestInitVideo() {
			this.uiState.shouldEnableVideo;
			this.$emit("requestInitVideo", true);
		},
	},
};
</script>