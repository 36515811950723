<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ProjectEdit.IEA.title") }}</div>
		<div class="p-4 row">
			<div v-if="!project.initial_iea_project" role="form" class="px-4 col-2">
				<div class="form-group _600 margin-overlap">
					{{ $t("ProjectEdit.IEA.enabled") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="enabled" />
						<i></i>
					</label>
				</div>
			</div>
			<div v-if="project.iea_project && enabled" class="col-10 col-sm-4">
				<div class="form-group">
					<label>{{ $t("ProjectEdit.IEA.iea_tenant_id") }}</label>
					<input type="text" class="form-control" v-model="project.iea_project.tenantId" disabled />
				</div>
				<div class="form-group">
					<label>{{ $t("ProjectEdit.IEA.admin_name") }}</label>
					<small v-if="!valid.field('admin_name_unique')" class="text-danger float-right"
						>Name already in use</small
					>
					<input
						type="text"
						class="form-control"
						:class="{ 'is-invalid': !valid.field('admin_name_unique') }"
						v-model="project.iea_project.projectId"
						:disabled="hasAnyIeaItems && project.initial_iea_project"
					/>
				</div>
				<div class="row">
					<div class="col-6 form-group">
						<label>{{ $t("ProjectEdit.IEA.start_date") }}</label>
						<config-date :noTime="true" v-model="project.iea_project.startDate" />
					</div>
					<div class="col-6 form-group">
						<label>{{ $t("ProjectEdit.IEA.end_date") }}</label>
						<config-date :noTime="true" v-model="project.iea_project.endDate" />
					</div>
				</div>
				<div>
					<div class="form-group">
						{{ $t("ProjectEdit.IEA.lid_enabled") }}
					</div>
					<div>
						<label class="ui-switch ui-switch-md theme-accent">
							<input type="checkbox" v-model="project.iea_project.lidEnabled" />
							<i></i>
						</label>
					</div>
				</div>
				<template v-if="project.initial_iea_project">
					<hr />
					<test-iea-admin-button :client="client" :project="project" />
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped></style>

<script>
import moment from "moment";
import Notie from "@/services/NotieService";
import ConfigDate from "@/components/ConfigDate";
import TestIeaAdminButton from "@/components/TestIeaAdminButton";

export default {
	name: "ProjectEditIea",

	props: ["id", "project", "valid", "defaultTab", "client", "items"],

	components: {
		ConfigDate,
		TestIeaAdminButton,
	},

	data() {
		return {
			enabled: false,
			storedDisabledConfig: null,

			testRunning: false,
			testSucceeded: false,
			testError: null,
		};
	},

	watch: {
		enabled() {
			if (this.project.initial_iea_project) {
				// Don't load anything new
			} else if (this.enabled) {
				if (this.storedDisabledConfig) {
					this.$set(this.project, "iea_project", this.storedDisabledConfig);
				} else {
					this.$set(this.project, "iea_project", {
						tenantId: this.client.iea_tenant_id,
						projectId: "",
						startDate: moment().format(),
						endDate: moment().add(1, "year").format(),
						lidEnabled: false,
					});
				}
			} else {
				this.storedDisabledConfig = this.project.iea_project;
				this.project.iea_project = null;
			}
		},
	},

	computed: {
		hasAnyIeaItems() {
			if (!(this.items && this.items.length > 0)) {
				return false;
			}

			for (let item of this.items) {
				if (item.cf_config.item_id != "") {
					return true;
				}
			}

			return false;
		},
	},

	created() {
		if (this.project.iea_project) {
			this.enabled = true;
		} else {
			this.enabled = false;
		}
	},

	methods: {},
};
</script>
