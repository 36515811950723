<template>
	<div class="viewer-controls" :class="{ 'position-right': positionRight }">
		<div class="position-toggle" @click="togglePositionRight">
			<i class="fas fa-lg" :class="{ 'fa-caret-right': !positionRight, 'fa-caret-left': positionRight }"></i>
		</div>
		<div v-tippy="{ placement: 'right', hideOnClick: false }"
			:title="tooltipWithShortcut($t('tooltip.zoom', { size: sizes[size] }), '-+')">
			<button v-if="item.zoom_enabled" class="md-btn md-fab md-fab-sm mb-2 theme" @click="zoomIn()" tabindex="-1">
				<i class="far fa-search-plus"></i>
			</button>
			<button v-if="item.zoom_enabled" class="md-btn md-fab md-fab-sm mb-2 theme" @click="zoomOut()"
				tabindex="-1">
				<i class="far fa-search-minus"></i>
			</button>
		</div>
		<button v-if="item.angle_enabled" class="md-btn md-fab md-fab-sm mb-2 theme" @click="angleOn()"
			v-tippy="{ placement: 'right' }" :title="tooltipWithShortcut($t('tooltip.angle_tool'), 'v')" tabindex="-1">
			<i class="far fa-ruler-triangle" :class="{ [this.angleEnabled ? 'fas' : 'far']: true }"></i>
		</button>
		<button v-if="item.ruler_enabled" class="md-btn md-fab md-fab-sm mb-2 theme" @click="ruler()"
			v-tippy="{ placement: 'right' }" :title="tooltipWithShortcut($t('tooltip.ruler_tool'), 'u')" tabindex="-1">
			<i class="far fa-ruler-horizontal" :class="{ [this.rulerEnabled ? 'fas' : 'far']: true }"></i>
		</button>
		<button v-if="item.rotate_enabled" class="md-btn md-fab md-fab-sm mb-2 theme" @click="rotate()"
			v-tippy="{ placement: 'right' }" :title="tooltipWithShortcut($t('tooltip.rotate'), 'r')" tabindex="-1">
			<i class="far fa-sync-alt"></i>
		</button>
		<button v-if="item.invert_enabled" class="md-btn md-fab md-fab-sm mb-2 theme" @click="invert()"
			v-tippy="{ placement: 'right' }" :title="tooltipWithShortcut($t('tooltip.invert'), 'i')" tabindex="-1">
			<i class="fas fa-image"></i>
		</button>
		<button v-if="item.annotations_enabled && canAnnotate" class="md-btn md-fab md-fab-sm mb-2 theme"
			@click="annotationOn()" v-tippy="{ placement: 'right' }"
			:title="tooltipWithShortcut($t('tooltip.highlighter'), 'a')" tabindex="-1">
			<i class="fa-highlighter" :class="{ [this.selectingAnnotation ? 'fas' : 'far']: true }"></i>
		</button>
		<button v-if="item.annotations_enabled" class="md-btn md-fab md-fab-sm mb-2" @click="toggleAllAnnotations()"
			v-tippy="{ placement: 'right' }" :title="tooltipWithShortcut($t('tooltip.show_all_annotations'), 't')"
			:disabled="!hasAnnotations" :class="{ theme: hasAnnotations }" tabindex="-1">
			<i class="fas fa-comments-alt"></i>
		</button>
		<button v-if="item.calc_enabled" class="md-btn md-fab md-fab-sm mb-2 theme" @click="toggleCalc()"
			v-tippy="{ placement: 'right' }" :title="tooltipWithShortcut($t('tooltip.calculator'), 'c')" tabindex="-1">
			<i class="fas fa-calculator"></i>
		</button>
		<button v-if="item.use_mathjax && item.mathjax_toggle_enabled" class="md-btn md-fab md-fab-sm mb-2 theme"
			@click="toggleMathjax()" v-tippy="{ placement: 'right' }"
			:title="tooltipWithShortcut($t('tooltip.formula_formatting'), 'm')" tabindex="-1">
			<i class="far fa-square-root-alt"></i>
		</button>

		<div v-if="item.playback_speed_enabled" class="d-flex" style="flex-direction: row;">
			<button v-b-toggle.collapse-playback class="md-btn md-fab md-fab-sm mb-2"
				:class="[user.playback_speed === 1 ? ['text-muted', 'white'] : 'theme']"
				v-tippy="{ placement: 'right' }" :title="$t('tooltip.playback_speed')" tabindex="-1">
				<i class="far fa-fast-forward"></i>
			</button>
			<b-collapse id="collapse-playback">
				<b-list-group class="playback-select" :class="{ 'position-right': positionRight }">
					<b-list-group-item v-for="speed in playbackSpeeds" :key="speed" @click="setPlaybackSpeed(speed)"
						style="font-size: small"
						:class="[speed === user.playback_speed && ['theme-lighten-1', 'text-white']]"
						class="flex-fill p-2" v-b-toggle.collapse-playback variant="secondary" button>
						{{ speed }}x
					</b-list-group-item>
				</b-list-group>
			</b-collapse>
		</div>
	</div>
</template>

<script>
import store from "../../services/Store";
import RAS from "../../services/ResponseAuditService";
import UserService from "@/services/UserService";

export default {
	name: "ViewerControls",
	props: ["chan", "item", "showNotes", "canAnnotate", "user"],
	data() {
		return {
			size: 1,
			sizes: [50, 65, 80, 100, 120, 150, 180, 200],
			showCalc: false,
			angleEnabled: false,
			rulerEnabled: false,
			selectingAnnotation: false,
			mathjaxEnabled: true,
			hasAnnotations: false,
			positionRight: store.getDefault(this, "viewer.controls.positionRight", false),
			playbackSpeeds: [0.5, 0.75, 1, 1.25, 1.5]
		};
	},

	created() {
		this.size = store.get(this, "viewerScale");
		if (!this.size && this.size != 0) {
			this.size = 1;
		}

		this.chan.$on("angle-on", () => {
			this.angleEnabled = true;
		});
		this.chan.$on("angle-off", () => {
			this.angleEnabled = false;
		});

		this.chan.$on("ruler-on", () => {
			this.rulerEnabled = true;
		});
		this.chan.$on("ruler-off", () => {
			this.rulerEnabled = false;
		});

		this.chan.$on("annotation-on", () => {
			this.selectingAnnotation = true;
		});
		this.chan.$on("annotation-off", () => {
			this.selectingAnnotation = false;
			this.chan.$emit("annotation-cancel");
		});
		this.chan.$on("has-annotations", () => {
			this.hasAnnotations = true;
		});
		this.chan.$on("has-no-annotations", () => {
			this.hasAnnotations = false;
		});

		this.$emit("update:keypressHandler", this.keypressHandler)
		this.$emit("update:positionRight", this.positionRight)
	},

	watch: {
		positionRight() {
			this.$emit("update:positionRight", this.positionRight)
		}
	},

	methods: {
		togglePositionRight() {
			this.positionRight = !this.positionRight
			store.set(this, "viewer.controls.positionRight", this.positionRight)
		},
		zoomIn() {
			this.size++;
			if (this.size > 7) {
				this.size = 7;
			}
			store.set(this, "viewerScale", this.size);
			this.chan.$emit("zoom-in");
		},
		zoomOut() {
			this.size--;
			if (this.size < 0) {
				this.size = 0;
			}
			store.set(this, "viewerScale", this.size);
			this.chan.$emit("zoom-out");
		},
		invert() {
			this.chan.$emit("invert");
		},
		rotate() {
			this.chan.$emit("rotate");
		},
		angleOn() {
			this.chan.$emit("angle");
		},
		ruler() {
			this.chan.$emit("ruler");
		},
		annotationOn() {
			RAS.recordAction("Click button", { button: "Toggle annotations" });
			this.chan.$emit("annotation");
		},
		toggleCalc() {
			this.$emit("calculator");
		},
		toggleMathjax() {
			this.chan.$emit("mathjax");
		},
		toggleAllAnnotations() {
			RAS.recordAction("Click button", { button: "Toggle all annotations" });
			this.chan.$emit("all-annotations");
		},
		setPlaybackSpeed(speed) {
			this.user.playback_speed = speed;
			this.chan.$emit("set-playback-speed", speed);
			UserService.changePlaybackSpeed(speed);
		},
		keypressHandler(e) {
			console.log("handler", e)
			if (!e.altKey) return

			switch (e.keyCode) {
				case 109: // Numpad minus or inline minus
				case 189:
					if (!this.item.zoom_enabled) return
					this.zoomOut()
					break;
				case 107:
				case 187: // Numpad plus or inline plus
					if (!this.item.zoom_enabled) return
					this.zoomIn()
					break;
				case 86: // v
					if (!this.item.angle_enabled) return
					this.angleOn()
					break;
				case 85: // u
					if (!this.item.ruler_enabled) return
					this.ruler()
					break;
				case 82: // r
					if (!this.item.rotate_enabled) return
					this.rotate()
					break;
				case 73: // i
					if (!this.item.invert_enabled) return
					this.invert()
					break;
				case 65: // a
					if (!(this.item.annotations_enabled && this.canAnnotate)) return
					this.annotationOn()
					break;
				case 84: // t
					if (!(this.item.annotations_enabled && this.hasAnnotations)) return
					this.toggleAllAnnotations()
					break;
				case 67: // c
					if (!this.item.calc_enabled) return
					this.toggleCalc()
					break;
				case 77: // m
					if (!this.item.use_mathjax && this.item.mathjax_toggle_enabled) return
					this.toggleMathjax()
					break;
			}
		},
		tooltipWithShortcut(text, shortcut) {
			if (shortcut.length > 1) { // special case for Zoom
				return `<div>${text}</div>
<div class="text-xxs mt-1"><span class="text-muted">Shortcut: </span><span class="text-primary">Alt+Minus/Alt+Plus</span></div>`
			} else {
				return `<div>${text}</div>
<div class="text-xxs mt-1"><span class="text-muted">Shortcut: </span><span class="text-primary">Alt+${shortcut.toUpperCase()}</span></div>`
			}
		}
	},
};
</script>

<style scoped>
.viewer-controls {
	position: absolute;
	top: 20px;
}

.viewer-controls button {
	display: block;
	font-size: 20px;
	line-height: 2;
}

.md-fab-sm {
	height: 40px !important;
	width: 40px !important;
	padding: 0px;
}

.position-toggle {
	position: absolute;
	top: -8px;
	width: 14px;
	height: 14px;
	padding-left: 4px;
	cursor: pointer;
}

.viewer-controls:not(.position-right) .position-toggle {
	left: -8px;
}

.viewer-controls.position-right .position-toggle {
	left: -8px;
}

.position-toggle>i {
	position: absolute;
	top: 0;
}

.playback-select {
	flex-direction: row;
	width: 270px;
	position: absolute;
}

.playback-select.position-right {
	right: 0;
	margin-right: 40px;
}
</style>
