<!-- Template for a new page -->
<template>
	<div class="d-flex flex-column flex h-100">
		<navbar></navbar>
		<div class="app" style="overflow: hidden">
			<sidenav></sidenav>
			<div id="content" class="app-content box-shadow-3 d-flex flex flex-column">
				<!-- Optional navbar at top of page -->
				<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
					<div class="navbar-text nav-title flex" id="pageTitle">
						Reporting
						<span class="far fa-fw fa-angle-right"></span>Completion
						<span class="far fa-fw fa-angle-right"></span>Burndown
					</div>
				</div>

				<div class="flex scroll-x scroll-y">
					<div class="row mx-0 flex-fixed px-3 pt-3 box">
						<div class="col-12 col-lg-4">
							<div class="form-group">
								<label>Section</label>
								<config-select
									:nullOption="anyItems"
									nullText="No sections"
									:options="sections"
									v-model="selectedSection"
								></config-select>
							</div>
						</div>
						<div class="col-12 col-lg-4">
							<div class="form-group">
								<label>Item</label>
								<config-select
									:nullOption="anyItems"
									nullText="No items"
									:options="items"
									v-model="selectedItem"
								></config-select>
							</div>
						</div>
						<div class="col-12 col-lg-4 d-flex flex-row align-items-end justify-content-end">
							<div class="d-flex flex-row align-items-center form-group">
								<div class="py-0 ml-1">
									<button
										@click="getStats"
										class="btn btn-sm btn-secondary btn-block flex-fixed"
										v-b-tooltip
										title="Run Report"
										:class="{ 'theme-accent': selectedSection && selectedItem }"
										:disabled="!(selectedSection && selectedItem)"
									>
										<i v-if="!running" class="far fa-clipboard-list"></i>
										<loading v-if="running" type="icon" />
										<span class="hidden-folded d-inline">&nbsp;Run Report</span>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div v-if="burndownStats && burndownStats.length > 0" class="d-flex flex-row">
						<div class="flex">
							<div class="card border-0 box-shadow-3 mb-3">
								<div class="p-0">
									<table class="table condensed-table mb-0">
										<thead>
											<tr>
												<th>
													<div class="t-margin"></div>
												</th>
												<th class="ar d-long">Day</th>
												<th class="ar d-abr" v-tippy title="Day">Day</th>
												<th class="ar d-long">Scores</th>
												<th class="ar d-abr" v-tippy title="Scored">Scs</th>
												<th class="ar d-long">Time/Score</th>
												<th class="ar d-abr" v-tippy title="Time/Score">T/Sc</th>
												<th class="ar d-long">Completed/Scorer</th>
												<th class="ar d-abr" v-tippy title="Completed/Scorer">Comp/Scr</th>
												<th class="ar d-long">Scorers</th>
												<th class="ar d-abr" v-tippy title="Scorers">Scrs</th>
												<th class="ar d-long">Completed</th>
												<th class="ar d-abr" v-tippy title="Completed">Comp</th>
												<th class="ar d-long">Remaining</th>
												<th class="ar d-abr" v-tippy title="Remaining Responses">Rem</th>
												<th>
													<div class="t-margin"></div>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr class="theme-lighten-3 text-on-color">
												<td></td>
												<td colspan="6">
													<span>History</span>
													<span class="text-muted text-small">(1 week)</span>
												</td>
												<td>
													<span v-if="prediction.adjustment > 0" class="_600"
														>+{{ prediction.adjustment }}</span
													>
													<span v-if="prediction.adjustment < 0" class="_600">{{
														prediction.adjustment
													}}</span>
												</td>
												<td></td>
											</tr>
											<tr v-for="stat in burndownStats" :key="getDate(stat.date)">
												<td></td>
												<td>{{ getDate(stat.date) }}</td>
												<td>{{ stat.scores }}</td>
												<td>{{ getTime(stat.time_per_score) }}</td>
												<td>{{ stat.complete_per_scorer }}</td>
												<td>{{ stat.scorers }}</td>
												<td>{{ stat.complete }}</td>
												<td>
													{{ stat.remaining + prediction.adjustment }} ({{
														pcr(
															stat.remaining + prediction.adjustment,
															prevTotal + prediction.adjustment
														)
													}}%)
												</td>
												<td></td>
											</tr>
											<tr class="theme-lighten-5 text-on-color">
												<td></td>
												<td>{{ burndownAvg.date }}</td>
												<td>{{ burndownAvg.scores.toFixed(1) }}/d</td>
												<td>{{ getTime(burndownAvg.time_per_score) }}</td>
												<td>{{ burndownAvg.complete_per_scorer.toFixed(1) }}/d</td>
												<td>{{ burndownAvg.scorers.toFixed(1) }}/d</td>
												<td>{{ burndownAvg.complete.toFixed(1) }}/d</td>
												<td></td>
												<td></td>
											</tr>
											<tr class="theme-lighten-3 text-on-color">
												<td></td>
												<td colspan="7" class="pl-4">
													<span>Projected</span>
													<span class="text-muted text-small">(1 week)</span>
												</td>
												<td></td>
											</tr>
											<tr v-for="stat in projectedStats" :key="getDate(stat.date)">
												<td></td>
												<td>{{ getDate(stat.date) }}</td>
												<td>{{ stat.scores.toFixed(0) }}</td>
												<td>{{ getTime(stat.time_per_score) }}</td>
												<td>{{ stat.complete_per_scorer }}</td>
												<td>{{ stat.scorers }}</td>
												<td>{{ stat.complete }}</td>
												<td>
													{{ stat.remaining }} ({{
														pcr(stat.remaining, prevTotal + prediction.adjustment)
													}}%)
												</td>
												<td></td>
											</tr>
										</tbody>
									</table>
								</div>

								<hr />

								<div class="col-12 px-3 pb-3">
									<div class="row mx-0" v-if="burndownStats">
										<div class="col-6">
											<div class="row m-0">
												<div
													class="col-6 col-lg-3 form-group d-flex flex-column justify-content-end"
												>
													<label style>Complete/Scorer/Day</label>
													<input
														type="number"
														class="form-control"
														v-model="prediction.comp_per_scorer"
													/>
												</div>
												<div
													class="col-6 col-lg-3 form-group d-flex flex-column justify-content-end"
												>
													<label>Scorers</label>
													<input
														type="number"
														class="form-control"
														v-model="prediction.scorers"
													/>
												</div>
												<div
													class="col-6 col-lg-3 form-group d-flex flex-column justify-content-end"
												>
													<label style="hyphens: none">Complete/Day</label>
													<input
														type="number"
														class="form-control"
														v-model="prediction.comp_per_day"
													/>
												</div>
												<div
													class="col-6 col-lg-3 form-group d-flex flex-column justify-content-end"
												>
													<label style="hyphens: none">Responses</label>
													<input
														type="number"
														class="form-control"
														v-model="prediction.responses"
													/>
												</div>
											</div>
										</div>
										<div
											class="col-3 form-group d-flex flex-row flex-wrap align-content-end justify-content-start"
										>
											<label class="md-check" style="flex-basis: 20%">
												<input type="checkbox" v-model="workOnDays[1]" />
												<i class="theme-accent"></i>
												<span class="mr-2">M</span>
											</label>
											<label class="md-check" style="flex-basis: 20%">
												<input type="checkbox" v-model="workOnDays[2]" />
												<i class="theme-accent"></i>
												<span class="mr-2">T</span>
											</label>
											<label class="md-check" style="flex-basis: 20%">
												<input type="checkbox" v-model="workOnDays[3]" />
												<i class="theme-accent"></i>
												<span class="mr-2">W</span>
											</label>
											<label class="md-check" style="flex-basis: 20%">
												<input type="checkbox" v-model="workOnDays[4]" />
												<i class="theme-accent"></i>
												<span class="mr-2">T</span>
											</label>
											<label class="md-check" style="flex-basis: 20%">
												<input type="checkbox" v-model="workOnDays[5]" />
												<i class="theme-accent"></i>
												<span class="mr-2">F</span>
											</label>
											<label class="md-check" style="flex-basis: 20%">
												<input type="checkbox" v-model="workOnDays[6]" />
												<i class="theme-accent"></i>
												<span class="mr-2">S</span>
											</label>
											<label class="md-check" style="flex-basis: 20%">
												<input type="checkbox" v-model="workOnDays[7]" />
												<i class="theme-accent"></i>
												<span class="mr-2">S</span>
											</label>
										</div>
										<div class="col-3 form-group d-flex flex-column justify-content-end">
											<div>
												<h5 class="d-inline-block">Scores left:</h5>
												<h5 class="d-inline-block text-muted">{{ prediction.scores }}</h5>
											</div>
											<div>
												<h5 class="d-inline-block">Score-time left:</h5>
												<h5
													v-if="prediction.scoreHours > 0 && prediction.scoreHours < Infinity"
													class="d-inline-block text-muted"
												>
													{{ prediction.scoreHours.toFixed(1) }}h
												</h5>
												<h6
													v-if="
														prediction.scoreHours <= 0 || prediction.scoreHours == Infinity
													"
													class="d-inline-block text-muted"
												>
													Unknown
												</h6>
											</div>
											<div>
												<h5 class="d-inline-block">Finish date:</h5>
												<h5
													v-if="prediction.finishDate"
													class="d-inline-block _600 text-success"
												>
													{{ getDate(prediction.finishDate) }}
												</h5>
												<h6 v-if="prediction.finishComp" class="d-inline-block text-muted">
													({{
														(
															(prediction.finishComp * 100) /
															prediction.comp_per_day
														).toFixed(0)
													}}%)
												</h6>
												<h6 v-if="!prediction.finishDate" class="d-inline-block text-muted">
													>1 year
												</h6>
											</div>
										</div>
									</div>
									<div class="row mx-0" v-if="burndownStats">
										<canvas v-chartjs="chartData" style="width: 100%; height: 400px"></canvas>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="burndownStats && burndownStats.length == 0"
						class="d-flex flex-row justify-content-center"
					>
						<h3 class="text-muted">No activity in the past week</h3>
					</div>
				</div>
			</div>
		</div>
		<mzfooter></mzfooter>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-long {
	display: none;
}
.d-abr {
	display: table-cell;
}
@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}
	.d-abr {
		display: none;
	}
}
.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}
.t-margin {
	width: 15px;
}
.table td,
.table th {
	white-space: nowrap;
}
h5,
h6 {
	margin-bottom: 0;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ConfigService from "@/services/ConfigService";
import ThemeService from "@/services/ThemeService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import moment from "moment";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import ReportTable from "@/components/ReportTable";

export default {
	name: "ReportScorers",

	props: ["user"],

	components: { ConfigDate, ConfigSelect, ReportTable },

	data() {
		return {
			burndownAvg: null,
			burndownStats: null,
			projectedStats: null,
			prevTotal: 0,
			prevComp: 0,
			nowComp: 0,
			nowScored: 0,
			workOnDays: [
				null, //no "0" in ISO weekdays
				true, //Monday
				true, //Tuesday
				true, //Wednesday
				true, //Thursday
				true, //Friday
				false, //Saturday
				false, //Sunday
			],

			prediction: {
				comp_per_scorer: 0,
				scorers: 0,
				comp_per_day: 0,
				scores_per_response: 0,
				scores: 0,
				finishDate: null,
				finishComp: 0,
				scoreHours: 0,
				responses: 0,
				min_responses: 0,
				adjustment: 0,
			},

			fields: [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
				{
					key: "date",
					label: "Day",
					tdClass: "text-center table-border-right",
					thClass: "text-center table-border-right",
				},
				{
					key: "scores",
					label: "Scores",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "time_per_score",
					label: "Time/Score",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "complete",
					label: "Completed",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "scorers",
					label: "Scorers",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "complete_per_scorer",
					label: "Completed/Scorer",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "remaining",
					label: "Remaining",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
			],

			projects: [],
			selectedProject: null,

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			fromDate: null,
			toDate: null,

			running: false,
			loading: true,

			chartData: {
				type: "line",
				data: {
					labels: [],
					datasets: [
						{
							label: "History",
							data: [],
							fill: true,
							lineTension: 0.4,
							// backgroundColor: hexToRGB(app.color.primary, 0.2),
							// borderColor: app.color.primary,
							borderWidth: 2,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							// pointBorderColor: app.color.primary,
							pointBackgroundColor: "#fff",
							pointBorderWidth: 2,
							pointHoverRadius: 4,
							// pointHoverBackgroundColor: app.color.primary,
							pointHoverBorderColor: "#fff",
							pointHoverBorderWidth: 2,
							pointRadius: 4,
							pointHitRadius: 10,
							spanGaps: false,
						},
						{
							label: "Projected",
							data: [],
							fill: false,
							lineTension: 0.4,
							// backgroundColor: hexToRGB(app.color.primary, 0.2),
							borderColor: ThemeService.getThemeColorHex(),
							borderWidth: 2,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 1.0,
							borderJoinStyle: "miter",
							// pointBorderColor: app.color.primary,
							pointBackgroundColor: "#fff",
							pointBorderWidth: 2,
							pointHoverRadius: 4,
							// pointHoverBackgroundColor: app.color.primary,
							pointHoverBorderColor: "#fff",
							pointHoverBorderWidth: 2,
							pointRadius: 4,
							pointHitRadius: 10,
							spanGaps: false,
						},
					],
				},
				options: {
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								id: "sp",
								scaleLabel: {
									display: "true",
									labelString: "Day",
								},
							},
						],
						yAxes: [
							{
								id: "freq",
								scaleLabel: {
									display: "true",
									labelString: "Remaining Responses",
								},
								ticks: {
									suggestedMin: 0,
									suggestedMax: 25,
								},
							},
						],
					},
				},
			},
		};
	},

	created() {
		if (store.get(this, "burndown.auto")) {
			this.running = true;
		}
		ConfigService.listSectionsShallow()
			.then((resp) => {
				this.sections = resp.data.sections;
				this.selectedSection = store.resolveSelectedSection(this, this.sections, "burndown.section");

				this.items = this.selectedSection.items;
				this.selectedItem = store.resolveSelectedItem(this, this.selectedSection, "burndown.item");

				console.log(store.get(this, "burndown.section"), store.get(this, "burndown.item"));
				console.log(this.selectedSection);
				console.log(this.selectedItem);

				if (store.get(this, "burndown.auto")) {
					store.set(this, "burndown.auto", null);
					if (this.selectedSection && this.selectedItem) {
						this.running = false;
						this.getStats();
					} else {
						notie.error("Failed to transfer from completion report");
						console.log(resp.data);
						this.running = false;
					}
				}

				this.loading = false;
			})
			.catch((err) => {
				console.log(err);
				notie.error("Failed to load config options", err);
				this.loading = false;
				this.running = false;
			});
	},

	watch: {
		selectedProject() {
			store.set(this, "selectedProject", this.selectedProject ? this.selectedProject.id : null);

			if (this.selectedProject && this.selectedProject.sections) {
				this.sections = this.selectedProject.sections;
			} else {
				this.sections = [];
			}
		},
		selectedSection() {
			store.set(this, "selectedSection", this.selectedSection ? this.selectedSection.id : null);

			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
			} else {
				this.items = [];
			}
		},
		selectedItem() {
			store.set(this, "selectedItem", this.selectedItem);
		},

		"prediction.comp_per_scorer"() {
			let calc = this.prediction.comp_per_scorer * this.prediction.scorers;
			if (this.prediction.comp_per_day != calc) {
				this.prediction.comp_per_day = calc;
			}
			this.$nextTick(this.makeProjection);
		},

		"prediction.scorers"(newVal, oldVal) {
			if (newVal <= 0 && oldVal && oldVal > 0) {
				this.prediction.scorers = oldVal;
			}

			let calc = this.prediction.comp_per_scorer * this.prediction.scorers;
			if (this.prediction.comp_per_day != calc) {
				this.prediction.comp_per_day = calc;
			}
			this.$nextTick(this.makeProjection);
		},

		"prediction.comp_per_day"() {
			let calc = this.prediction.comp_per_day / this.prediction.scorers;
			if (this.prediction.comp_per_scorer != calc) {
				this.prediction.comp_per_scorer = calc;
			}
			this.$nextTick(this.makeProjection);
		},

		"prediction.responses"() {
			console.log("RESPONSE CHANGED");
			if (this.prediction.responses < 0) {
				this.prediction.responses = 0;
			}
			this.prediction.adjustment = this.prediction.responses - this.prevTotal;
			this.$nextTick(this.makeProjection);
		},

		workOnDays() {
			this.$nextTick(this.makeProjection);
		},
	},

	methods: {
		anyItems() {
			let hasItems = false;
			if (this.selectedProject && this.selectedProject.sections) {
				_.each(this.selectedProject.sections, (section) => {
					if (section.items && section.items.length > 0) {
						hasItems = true;
					}
				});
			}
			return hasItems;
		},

		getStats() {
			if (this.running) {
				return;
			}
			var proj = this.selectedProject;
			var sec = this.selectedSection;
			var item = this.selectedItem;

			var ctx = {};

			if (proj) {
				ctx.project_id = proj.id;
			}

			if (sec) {
				ctx.section_id = sec.id;
			}

			if (item) {
				ctx.item_id = item.id;
			}

			ctx.now = moment().toISOString(true);

			this.running = true;
			ReportingService.getBurndown(ctx)
				.then((resp) => {
					console.log(resp);
					this.running = false;

					let bds = resp.data.days;

					this.prevTotal = resp.data.total;
					let total = this.prevTotal;
					this.prediction.responses = total;
					this.prevComp = resp.data.complete_before;
					let compBefore = this.prevComp;
					this.prediction.min_responses = compBefore;

					_.each(bds, (stat) => {
						stat.complete_per_scorer = (stat.complete / stat.scorers).toFixed(1);
						stat.time_per_score = (stat.score_time / stat.scores).toFixed(0);

						stat.starting = total - compBefore;
						compBefore += stat.complete;
						stat.remaining = total - compBefore;
					});
					let bda = {
						scores: 0,
						score_time: 0,
						complete: 0,
						scorers: 0,
					};
					_.each(bds, (stat) => {
						bda.scores += stat.scores;
						bda.score_time += stat.score_time;
						bda.complete += stat.complete;
						bda.scorers += stat.scorers;
					});

					this.nowScored = resp.data.complete_scored_before;
					this.nowComp = resp.data.complete_before + bda.complete;
					this.prediction.scores_per_response = this.nowScored / this.nowComp;
					if (this.prediction.scores_per_response == Infinity) {
						this.prediction.scores_per_response = 0;
					}

					bda.complete_per_scorer = bda.complete / bda.scorers;
					bda.time_per_score = bda.score_time / bda.scores;

					bda.scores /= bds.length;
					bda.score_time /= bds.length;
					bda.complete /= bds.length;
					bda.scorers /= bds.length;

					bda.date = `Average`;

					this.burndownStats = bds;
					this.burndownAvg = bda;

					this.prediction.comp_per_scorer = Math.round(bda.complete / bda.scorers);
					this.prediction.scorers = Math.round(bda.scorers);
				})
				.catch((err) => {
					this.running = false;
					console.log(err);
					notie.error("Failed to load stats", err);
				});
		},

		fixed(time) {
			if (time == Number.POSITIVE_INFINITY) {
				return "——";
			} else {
				return time.toFixed(2);
			}
		},

		getTime(time) {
			if (!time) {
				return "——";
			} else if (time < 3600) {
				return moment().startOf("day").seconds(time).format("mm:ss");
			} else {
				return moment().startOf("day").seconds(time).format("HH:mm:ss");
			}
		},

		getTimeMillis(duration) {
			//duration+=86400000
			var milliseconds = parseInt((duration % 1000) / 100),
				seconds = parseInt((duration / 1000) % 60),
				minutes = parseInt((duration / (1000 * 60)) % 60),
				hours = parseInt(duration / (1000 * 60 * 60));

			if (isNaN(seconds)) {
				seconds = 0;
			}
			if (isNaN(minutes)) {
				minutes = 0;
			}
			if (isNaN(hours)) {
				hours = 0;
			}

			hours = hours < 10 ? "0" + hours : hours;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			return hours + ":" + minutes + ":" + seconds;
		},

		getTimeSeconds(duration) {
			if (duration == 0) {
				return "——";
			} else if (!duration) {
				return "——";
			} else {
				//duration+=86400000
				var milliseconds = parseInt((duration % 1000) / 100),
					seconds = parseInt(duration % 60),
					minutes = parseInt((duration / 60) % 60),
					hours = parseInt(duration / (60 * 60));

				hours = hours < 10 ? "0" + hours : hours;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;

				return hours + ":" + minutes + ":" + seconds;
			}
		},

		getDate(date) {
			if (!(date.year && date.month && date.day)) {
				return "——";
			} else {
				date = moment()
					.year(date.year)
					.month(date.month - 1)
					.date(date.day)
					.format("MMM D");
				if (date) {
					return date;
				}
			}
		},

		pcr(num, den) {
			if (den == 0) {
				return "-";
			}
			if (num == 0) {
				return "0.00";
			}

			var res = (num / den) * 100;
			return res.toFixed(2);
		},

		clearChart() {
			this.chartData.data.labels = [];
			this.chartData.data.datasets[0].data = [];
			this.chartData.data.datasets[1].data = [];
		},

		addChartStat(label, actual, projected) {
			this.chartData.data.labels.push(label);
			this.chartData.data.datasets[0].data.push(actual);
			this.chartData.data.datasets[1].data.push(projected);
		},

		isToday(date) {
			let today = moment();
			return date.year == today.year() && date.month - 1 == today.month() && date.day == today.date();
		},

		makeProjection() {
			console.log("Make chart");
			let bds = this.burndownStats;
			this.clearChart();
			this.prediction.finishDate = null;
			this.prediction.finishComp = 0;
			this.prediction.scoreHours = 0;

			let now = moment();

			//Actual data
			this.addChartStat("Start", this.prevTotal + this.prediction.adjustment - this.prevComp, null);

			let workOnDays = {};
			let remaining = 0;
			let completeToday = 0;
			let scoredToday = false;
			_.each(bds, (stat) => {
				//Take note of which days of the week have work done
				let weekday = moment()
					.year(stat.date.year)
					.month(stat.date.month - 1)
					.date(stat.date.day)
					.isoWeekday();

				if (!this.isToday(stat.date)) {
					remaining = stat.remaining + this.prediction.adjustment;
					this.addChartStat(this.getDate(stat.date), remaining, null);
				} else {
					console.log("Found today", stat);
					scoredToday = true;
					let starting = stat.remaining + this.prediction.adjustment + stat.complete;
					remaining = stat.remaining + this.prediction.adjustment;
					let projectedRemaining = starting - this.prediction.comp_per_day;
					remaining = Math.min(projectedRemaining, remaining);
					remaining = Math.max(0, remaining);
					this.addChartStat(this.getDate(stat.date), remaining, remaining);
					completeToday = stat.complete;
				}
			});

			let remainingScores = remaining * this.prediction.scores_per_response;
			this.prediction.scores = Math.round(remainingScores);
			let remainingScoreTime = remainingScores * this.burndownAvg.time_per_score;
			this.prediction.scoreHours = remainingScoreTime / 3600;

			//Projected data

			let projectFor = 364; //Project 364 days in the future
			let projectRows = 7;
			let projectDay = now;
			if (!scoredToday) {
				console.log("Did not score today");
				projectDay.add(-1, "days");
				projectFor = 365;
				this.addChartStat(projectDay.format("MMM D"), remaining, remaining);
			}
			this.projectedStats = [];
			for (let i = 1; i <= projectFor && remaining > 0; i++) {
				projectDay.add(1, "days");
				let projectDate = {
					year: projectDay.year(),
					month: projectDay.month() + 1,
					day: projectDay.date(),
				};
				let weekday = projectDay.isoWeekday();

				if (this.workOnDays[weekday]) {
					let completeToday = this.prediction.comp_per_day;
					if (remaining < completeToday) {
						completeToday = remaining;
					}
					remaining = remaining - completeToday;
					if (remaining == 0) {
						this.prediction.finishDate = projectDate;
						this.prediction.finishComp = completeToday;
					}

					if (i <= projectRows) {
						this.addChartStat(projectDay.format("MMM D"), null, remaining);
						this.projectedStats.push({
							date: projectDate,
							scores: completeToday * this.prediction.scores_per_response,
							time_per_score: this.burndownAvg.time_per_score,
							complete: completeToday,
							scorers: this.prediction.scorers,
							complete_per_scorer: this.prediction.comp_per_scorer,
							remaining: remaining,
						});
					}
				}
			}
		},
	},
};
</script>
