import axios from "axios";

export default {
	getUserExceptions(userID) {
		return axios.get(`/v1/admin/exceptions/user/${userID}`);
	},

	getLastLockoutStat(userID) {
		return axios.get(`/v1/admin/lockout/user/${userID}`)
	}
}
