<template>
	<div class="row">
		<div
			v-if="setLocation"
			class="col-4 mb-2"
			:class="{
				invalid: !setLocation.section_id,
				'invalid-b': !setLocation.section_id,
			}"
		>
			<label>{{ $t("fields.section") }}</label>
			<config-select
				:options="sections"
				v-model="setLocation.section_id"
				hideNone
				byField="id"
				@input="sectionChanged"
				:disabled="course.isCompleted"
			/>
		</div>
		<div
			v-if="setLocation"
			class="col-4 mb-2"
			:class="{
				invalid: !setLocation.item_id,
				'invalid-b': !setLocation.item_id,
			}"
		>
			<label>{{ $t("fields.item") }}</label>
			<config-select
				:options="element.meta('items')"
				v-model="setLocation.item_id"
				hideNone
				byField="id"
				@input="itemChanged"
				:disabled="course.isCompleted || !setLocation.section_id"
			/>
		</div>
		<div
			v-if="setLocation"
			class="col-4 mb-2"
			:class="{
				invalid: !setLocation.set_id,
				'invalid-b': !setLocation.set_id,
			}"
		>
			<label>{{ setLabel }}</label>
			<config-select
				:options="element.meta('sets')"
				v-model="setLocation.set_id"
				hideNone
				byField="id"
				@input="setChanged"
				:disabled="course.isCompleted || !setLocation.item_id"
			/>
		</div>
		<div class="col-10 mt-3 mb-2 align-self-end">
			<div v-if="setLocation.set_id" class="border px-3 py-2">
				<loading v-if="!element.meta('set_responses')" type="table" class="text-muted" />
				<div v-else>
					<div class="d-flex flex-row align-items-center">
						<h5 class="flex mb-0">
							{{
								$tc("data_description.num_responses", element.meta("set_responses").length, {
									num: element.meta("set_responses").length,
								})
							}}
						</h5>
						<button
							class="btn btn-icon btn-rounded btn-subtle"
							@click="toggleDetails(element)"
							v-tippy
							:title="$t('TrainingEdit.Details.details')"
						>
							<i
								class="far fa-angle-up anim-rotate"
								:class="{ 'rotate-180': element.meta('showDetails') }"
							/>
						</button>
					</div>
					<div v-if="element.meta('showDetails')">
						<hr class="my-2" />
						<div
							v-for="(resp, i) in element.meta('set_responses')"
							:key="resp.id"
							class="d-flex flex-row pb-2"
						>
							<div class="flex">{{ i + 1 }}. {{ resp.ref_id }}</div>
							<div>
								<span v-if="resp.true_score">
									<inline-score
										:score="resp.true_score"
										:rubric="element.meta('item') && element.meta('item').rubric"
										:type="20"
										tooltip
									/>
								</span>
								<span v-if="!resp.true_score" class="text-muted"
									>({{ $t("TrainingEdit.Details.no_true_score") }})</span
								>
							</div>
						</div>
						<button class="btn btn-primary mr-2 mb-1" @click="goToSet(element)">
							<i class="far fa-balance-scales" />
							{{ $t("TrainingEdit.Details.configure") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import BB from "bluebird";

import ConfigService from "@/services/ConfigService";
import QCConfigService from "@/services/QCConfigService";
import Notie from "@/services/NotieService";
import Store from "@/services/Store";
import fs from "@/services/FormatService";

import ConfigSelect from "@/components/ConfigSelect";
import InlineScore from "@/components/InlineScore";

export default {
	name: "DetailsQcSet",

	components: {
		ConfigSelect,
		InlineScore,
	},

	props: {
		course: Object,
		element: Object,
		setLocation: Object,
		sections: Array,
		qcType: Number,
	},

	created() {
		const { setLocation } = this;
		if (!setLocation) {
			return;
		}

		if (setLocation.section_id) {
			this.sectionChanged();
		}

		if (setLocation.item_id) {
			this.itemChanged();
		}

		if (setLocation.set_id) {
			this.setChanged();
		}
	},

	computed: {
		setLabel() {
			return fs.trainingElementType(this.element.type);
		},
	},

	methods: {
		sectionChanged() {
			const { element, setLocation } = this;
			if (!setLocation) {
				console.error("Malformed element has no set location field");
				return;
			}
			ConfigService.getSection(setLocation.section_id)
				.then((r) => {
					element.setMeta("items", r.data.items);
					let item = _.find(element.meta("items"), { id: setLocation.item_id });
					if (!item) {
						setLocation.item_id = null;
						element.setMeta("sets", null);
						setLocation.set_id = null;
						// element.setMeta("set_responses", null);
						element.setMeta("item", null);
					}

					this.$forceUpdate();
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get section", e);
				});
		},

		itemChanged() {
			const { element, setLocation, qcType } = this;
			if (!setLocation) {
				console.error("Malformed element has no set location field");
				return;
			}

			if (!setLocation.item_id) {
				// changed to null, short circuit
				setLocation.set_id = null;
				return;
			}

			QCConfigService.listQCSets(setLocation.section_id, setLocation.item_id, qcType)
				.then((r) => {
					element.setMeta("sets", r.data);
					let set = _.find(element.meta("sets"), { id: setLocation.set_id });
					if (!set) {
						setLocation.set_id = null;
					}

					this.$forceUpdate();
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get sets", e);
				});
		},

		setChanged() {
			const { element, setLocation } = this;
			if (!setLocation) {
				console.error("Malformed element has no set location field");
				return;
			}

			if (!setLocation.set_id) {
				// changed to null, short circuit
				element.set_responses = null;
				return;
			}

			element.setMeta("set_responses", null);
			BB.props({
				responses: QCConfigService.listSetResponses(setLocation.set_id),
				item: ConfigService.getItem(setLocation.item_id),
			})
				.then((r) => {
					element.setMeta("set_responses", r.responses.data);
					element.setMeta("item", r.item.data);

					this.$forceUpdate();
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to load set", e);
				});
			this.$forceUpdate();
		},

		goToSet() {
			const { setLocation, qcType } = this;
			if (!setLocation) {
				console.error("Malformed element has no set location field");
				return;
			}
			Store.set(this, "audit.r.selectedSection", setLocation.section_id);
			Store.set(this, "audit.r.selectedItem", setLocation.item_id);
			Store.set(this, "qc.setup.qc_type", qcType);
			this.$router.push(`/qc_setup/set/${setLocation.set_id}`);
		},

		toggleDetails(element) {
			element.setMeta("showDetails", !element.meta("showDetails"));
			this.$forceUpdate();
		},
	},
};
</script>
