<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="form.loaded == 0" />
				<div v-if="form.loaded != 0" class="navbar-text nav-title" id="pageTitle">
					<i
						class="fas fa-fw fa-lock-alt"
						v-if="form.isScored"
						v-tippy="{ placement: 'bottom-start' }"
						:title="$t('tooltip.form_used_for_scoring')"
					></i>
					{{ form.name }}
				</div>

				<!-- Import -->
				<div class="ml-auto py-0">
					<button v-if="!form.isScored" class="btn btn-sm theme-accent btn-block" @click="openImportModal">
						<i class="far fa-fw fa-upload"></i>
						<span class="hidden-folded d-inline">{{ $t("buttons.import") }}</span>
					</button>
				</div>
			</div>
		</template>
		<div class="h-100" v-if="!loading">
			<edit-pane :cancel="loadData" :save="saveForm" :dirty="dirty" :valid="valid">
				<div class="padding">
					<!-- Page content goes here -->
					<div v-if="form" class="row m-0">
						<div class="col-12">
							<div class="mb-3">
								<div class="row">
									<div class="col-8">
										<div class="form-group">
											<label>{{ $t("fields.name") }}</label>
											<input class="form-control" v-model="form.name" />
										</div>
										<div class="form-group">
											<label>{{ $t("FormEdit.form_id") }}</label>
											<input
												class="form-control"
												v-model="form.form_id"
												:disabled="form.isScored"
											/>
										</div>
									</div>
									<div class="col-4 form-group">
										<h5>{{ $t("FormEdit.introductory_audio_prompts") }}</h5>
										<div v-if="form.id" class="card p-3 mb-3">
											<div class="row" v-for="(prompt, i) in form.audio_prompts" :key="i">
												<div
													class="col-8"
													:class="{ 'form-group': prompt.variants.length == 0 }"
												>
													<label>{{ $t("FormEdit.prompt_name") }}</label>
													<input class="form-control" v-model="prompt.name" />
												</div>
												<div
													class="col-4 align-self-end pl-0"
													:class="{ 'form-group': prompt.variants.length == 0 }"
													style="padding-bottom: 2px"
												>
													<input
														:id="`audio-import-${i}`"
														class="d-none"
														type="file"
														@change="selectAudioFile(null, prompt, $event)"
													/>
													<div
														class="d-flex flex-row align-items-center"
														style="margin-left: -6px"
													>
														<button
															@click="removeAudio(null, prompt)"
															class="btn btn-sm btn-icon btn-rounded red text-white m-0 mr-2 mt-3"
															style="min-width: 28px"
															v-tippy
															:title="$t('FormEdit.remove_audio_prompt')"
														>
															<i class="fa fa-trash"></i>
														</button>
														<button
															v-if="!prompt.busy"
															@click="addAudioFile(null, prompt, i)"
															class="btn btn-block btn-sm btn-primary mt-3"
														>
															<i class="fas fa-plus mr-2" />{{ $t("FormEdit.add_file") }}
														</button>
														<button
															v-if="prompt.busy"
															class="btn btn-block btn-sm btn-primary mt-3"
															disabled
														>
															<div class="mz-loading-icon mr-2" />
															{{ $t("FormEdit.add_file") }}
														</button>
													</div>
												</div>
												<div
													v-if="prompt.variants && prompt.variants.length > 0"
													class="col-12 form-group"
												>
													<table class="w-100 ml-3">
														<thead>
															<tr>
																<th class="_400">
																	{{ $t("FormEdit.variant_name") }}
																</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															<tr
																class="d-flex flex-row align-items-center"
																v-for="(variant, j) in prompt.variants"
																:key="j"
															>
																<td style="width: 50%">
																	<div class="d-flex flex-row align-items-center">
																		<input
																			class="form-control"
																			v-model="variant.name"
																		/>
																		<button
																			@click="removeAudioFile(prompt, variant)"
																			class="btn btn-sm btn-icon btn-rounded red text-white m-0 ml-2"
																			style="min-width: 28px"
																			v-tippy
																			:title="$t('FormEdit.remove_audio_prompt')"
																		>
																			<i class="fa fa-trash"></i>
																		</button>
																	</div>
																</td>
																<td style="width: 50%">
																	<audio
																		controls
																		class="audio-player"
																		:src="variant.url"
																		:id="`audio-player-${i}`"
																	/>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
											<button @click="addAudio()" class="btn btn-sm btn-primary mt-3">
												<i class="fas fa-plus mr-2" />{{ $t("FormEdit.add_audio") }}
											</button>
										</div>
										<div v-else>
											<h3 class="text-extra-muted">
												{{ $t("FormEdit.form_must_be_saved") }}
											</h3>
										</div>
									</div>
									<!-- <div class="col-6 form-group">
													<label>{{ $t("fields.external_id") }}</label>
													<input class="form-control" v-model="form.external_id" />
												</div> -->

									<!-- <div class="col-4 form-group">
													<label>{{ $t("FormEdit.test_id") }}</label>
													<input class="form-control" v-model="form.test_id" />
												</div> -->
									<!-- <div class="col-4 form-group">
													<label>{{ $t("FormEdit.form_version") }}</label>
													<input class="form-control" v-model="form.version" />
												</div> -->
								</div>
								<hr class="mt-2" />
								<div class="d-flex flex-row align-items-center mb-3">
									<h5 class="mb-0 mr-2">{{ $t("FormEdit.sections") }}</h5>
									<button
										@click="addSection(form)"
										:disabled="form.isScored"
										class="btn btn-sm btn-primary ml-auto"
									>
										<i class="fas fa-plus mr-2" />{{ $t("FormEdit.add_section") }}
									</button>
								</div>
								<div class="b-b nav-active-bg">
									<ul class="nav nav-tabs">
										<li v-for="(sec, s) of form.sections" :key="sec.id" class="nav-item">
											<a
												class="nav-link"
												@click="selectSection(sec)"
												:class="{ active: sec === section }"
											>
												<div v-if="sec.name">
													<span class="text-muted" style="font-size: 12px">{{ s + 1 }}.</span>
													{{ sec.name }}
												</div>
												<div v-else class="text-muted">
													{{ $t("FormEdit.no_name") }}
												</div>
											</a>
										</li>
									</ul>
								</div>
								<div v-if="section" class="card shadow p-3">
									<div class="row">
										<div class="col-12">
											<div class="d-flex flex-row align-items-end form-group">
												<div style="flex: 4">
													<label>{{ $t("FormEdit.section_name") }}</label>
													<input
														class="form-control"
														v-model="section.name"
														:placeholder="$t('fields.name')"
													/>
													<!-- <div>{{ section.name }}</div> -->
												</div>
												<div class="ml-3" style="flex: 1">
													<label>{{ $t("FormEdit.section_id") }}</label>
													<input
														class="form-control"
														v-model="section.id"
														:disabled="form.isScored"
														:placeholder="$t('fields.id')"
													/>
													<!-- <div>{{ task.name }}</div> -->
												</div>
												<button
													@click="removeSection(form, section)"
													class="btn btn-sm btn-icon btn-rounded red text-white m-0 ml-3 btn-align-adjust"
													v-tippy
													:disabled="form.isScored"
													:title="$t('FormEdit.remove_section')"
												>
													<i class="fa fa-trash"></i>
												</button>
											</div>
										</div>
										<div class="col-8">
											<h5>{{ $t("FormEdit.tasks") }}</h5>
											<div class="card p-3 mb-3" v-for="(task, t) in section.tasks" :key="t">
												<div class="d-flex flex-row align-items-end pr-3">
													<div class="form-group" style="flex: 1">
														<label>{{ $t("FormEdit.task_name") }}</label>
														<input
															class="form-control"
															v-model="task.name"
															:placeholder="$t('fields.name')"
														/>
														<!-- <div>{{ task.name }}</div> -->
													</div>
													<div class="form-group ml-3" style="flex: 3">
														<label>{{ $t("fields.description") }}</label>
														<input
															class="form-control"
															v-model="task.description"
															:placeholder="$t('fields.description')"
														/>

														<!-- <div>{{ task.description }}</div> -->
													</div>
													<div class="form-group ml-3" style="flex: 1">
														<label>{{ $t("FormEdit.task_id") }}</label>
														<input
															class="form-control"
															v-model="task.id"
															:disabled="form.isScored"
															:placeholder="$t('fields.id')"
														/>
														<!-- <div>{{ task.name }}</div> -->
													</div>
													<div class="form-group">
														<button
															@click="removeTask(section, task)"
															class="btn btn-sm btn-icon btn-rounded red text-white m-0 ml-3 btn-align-adjust"
															v-tippy
															:disabled="form.isScored"
															:title="$t('FormEdit.remove_task')"
														>
															<i class="fa fa-trash"></i>
														</button>
													</div>
												</div>
												<h5>{{ $t("FormEdit.subtasks") }}</h5>
												<div class="px-3">
													<table
														v-if="task.subtasks && task.subtasks.length > 0"
														class="w-100"
													>
														<thead>
															<tr>
																<th class="_400">
																	{{ $t("FormEdit.code") }}
																</th>
																<th class="_400">
																	{{ $t("FormEdit.subtask_name") }}
																</th>
																<th class="_400">
																	{{ $t("FormEdit.id") }}
																</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															<tr v-for="(subtask, st) in task.subtasks" :key="st">
																<td style="width: 100px">
																	<input
																		class="form-control"
																		v-model="subtask.code"
																		:placeholder="$t('fields.code')"
																	/>

																	<!-- <div>{{ subtask.code }}</div> -->
																</td>
																<td>
																	<input
																		class="form-control"
																		v-model="subtask.name"
																		:placeholder="$t('fields.name')"
																	/>

																	<!-- <div>{{ subtask.name }}</div> -->
																</td>
																<td style="width: 130px">
																	<input
																		class="form-control"
																		v-model="subtask.id"
																		:disabled="form.isScored"
																		:placeholder="$t('fields.id')"
																	/>

																	<!-- <div>{{ subtask.code }}</div> -->
																</td>
																<td style="width: 1px">
																	<button
																		@click="removeSubtask(task, subtask)"
																		class="btn btn-sm btn-icon btn-rounded red text-white m-0 ml-3 btn-align-adjust"
																		v-tippy
																		:disabled="form.isScored"
																		:title="$t('FormEdit.remove_subtask')"
																	>
																		<i class="fa fa-trash"></i>
																	</button>
																</td>
															</tr>
														</tbody>
													</table>
													<button
														@click="addSubtask(task)"
														class="btn btn-sm btn-primary mx-auto mt-3"
														:disabled="form.isScored"
													>
														<i class="fas fa-plus mr-2" />{{ $t("FormEdit.add_subtask") }}
													</button>
												</div>
											</div>
											<button
												@click="addTask(section)"
												class="btn btn-sm btn-primary m-auto"
												:disabled="form.isScored"
											>
												<i class="fas fa-plus mr-2" />{{ $t("FormEdit.add_task") }}
											</button>
										</div>
										<div class="col-4">
											<h5>{{ $t("FormEdit.audio_prompts") }}</h5>
											<div v-if="form.id" class="card p-3 mb-3">
												<div class="row" v-for="(prompt, i) in section.audio_prompts" :key="i">
													<div
														class="col-8"
														:class="{ 'form-group': prompt.variants.length == 0 }"
													>
														<label>{{ $t("FormEdit.prompt_name") }}</label>
														<input class="form-control" v-model="prompt.name" />
													</div>
													<div
														class="col-4 align-self-end pl-0"
														:class="{ 'form-group': prompt.variants.length == 0 }"
														style="padding-bottom: 2px"
													>
														<input
															:id="`audio-import-${section.id}-${i}`"
															class="d-none"
															type="file"
															@change="selectAudioFile(section, prompt, $event)"
														/>
														<div
															class="d-flex flex-row align-items-center"
															style="margin-left: -6px"
														>
															<button
																@click="removeAudio(section, prompt)"
																class="btn btn-sm btn-icon btn-rounded red text-white m-0 mr-2 mt-3"
																style="min-width: 28px"
																v-tippy
																:title="$t('FormEdit.remove_audio_prompt')"
															>
																<i class="fa fa-trash"></i>
															</button>
															<button
																v-if="!prompt.busy"
																@click="addAudioFile(section, prompt, i)"
																class="btn btn-block btn-sm btn-primary mt-3"
															>
																<i class="fas fa-plus mr-2" />{{
																	$t("FormEdit.add_file")
																}}
															</button>
															<button
																v-if="prompt.busy"
																class="btn btn-block btn-sm btn-primary mt-3"
																disabled
															>
																<div class="mz-loading-icon mr-2" />
																{{ $t("FormEdit.add_file") }}
															</button>
														</div>
													</div>
													<div
														v-if="prompt.variants && prompt.variants.length > 0"
														class="col-12 form-group"
													>
														<table class="w-100 ml-3">
															<thead>
																<tr>
																	<th class="_400">
																		{{ $t("FormEdit.variant_name") }}
																	</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																<tr
																	class="d-flex flex-row align-items-center"
																	v-for="(variant, j) in prompt.variants"
																	:key="j"
																>
																	<td style="width: 50%">
																		<div class="d-flex flex-row align-items-center">
																			<input
																				class="form-control"
																				v-model="variant.name"
																			/>
																			<button
																				@click="
																					removeAudioFile(prompt, variant)
																				"
																				class="btn btn-sm btn-icon btn-rounded red text-white m-0 ml-2"
																				style="min-width: 28px"
																				v-tippy
																				:title="
																					$t('FormEdit.remove_audio_prompt')
																				"
																			>
																				<i class="fa fa-trash"></i>
																			</button>
																		</div>
																	</td>
																	<td style="width: 50%">
																		<audio
																			controls
																			class="audio-player"
																			:src="variant.url"
																			:id="`audio-player-${section.id}-${i}`"
																		/>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<button @click="addAudio(section)" class="btn btn-sm btn-primary mt-3">
													<i class="fas fa-plus mr-2" />{{ $t("FormEdit.add_audio") }}
												</button>
											</div>
											<div v-else>
												<h3 class="text-extra-muted">
													{{ $t("FormEdit.form_must_be_saved") }}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</edit-pane>
			<save-optional-modal
				:dirty="dirty"
				:valid="valid"
				:save="saveForm"
				:next="saveOptNext"
				:cancel="saveOptCancel"
				objectText="Form"
				:objectName="form.name"
				actionText="leave the page"
				v-model="saveOptModal"
			/>
		</div>

		<b-modal id="importModal" v-model="showImportModal" @hide="showImportModal = false" size="md">
			<template slot="modal-header">
				<h5 class="modal-title pl-3">
					{{ $t("FormEdit.import_from_spreadsheet") }}
				</h5>
			</template>

			<div class="px-3 pt-3">
				<template v-if="!importingFile">
					<h5>{{ $t("FormEdit.choose_a_spreadsheet_to_import") }}</h5>

					<input id="import" class="d-none" type="file" @change="parseImportFile" />
					<div
						@drop="dropFileSelect"
						@click="clickFileSelect"
						class="drag-drop-zone d-flex flex-column justify-content-center align-items-center"
					>
						<div class="drag-drop-text text-muted">{{ $t("FormEdit.click_to_upload") }}</div>
						<div class="drag-drop-text text-muted">{{ $t("FormEdit.drag_and_drop") }}</div>
					</div>
					<div v-if="importingError">
						<div class="text-danger">{{ $t("FormEdit.error") }}</div>
						<div>{{ importingError }}</div>
					</div>
				</template>

				<template v-if="importingFile">
					<h5>{{ $t("FormEdit.importing_from_spreadsheet") }}</h5>
					<input class="form-control" disabled :value="importingFile.name" />
				</template>
				<loading v-if="importingFile && !importingForm" type="page" />
				<template v-if="importingFile && importingForm">
					<div class="card p-2 mt-3">
						<div class="text-md">{{ importingForm.name }}</div>
						<div class="text-muted">{{ $t("FormEdit.form_name") }}</div>
					</div>
					<div class="d-flex flex-row w-100 mt-3">
						<div class="flex card p-2 text-center">
							<div class="text-lg _800">{{ getNumSections(importingForm) }}</div>
							<div class="text-muted">{{ $t("FormEdit.sections") }}</div>
						</div>
						<div class="ml-3 flex card p-2 text-center">
							<div class="text-lg _800">{{ getNumTasks(importingForm) }}</div>
							<div class="text-muted">{{ $t("FormEdit.tasks") }}</div>
						</div>
						<div class="ml-3 flex card p-2 text-center">
							<div class="text-lg _800">{{ getNumSubtasks(importingForm) }}</div>
							<div class="text-muted">{{ $t("FormEdit.subtasks") }}</div>
						</div>
					</div>
				</template>
			</div>

			<template slot="modal-footer">
				<div class="d-flex flex-row px-3 w-100">
					<button class="btn btn-danger ml-auto mr-3" @click="showImportModal = false">
						{{ $t("buttons.cancel") }}
					</button>
					<button
						class="btn"
						:class="{ 'btn-primary': importingForm, 'btn-secondary': !importingForm }"
						:disabled="!importingForm"
						@click="confirmImport"
					>
						{{ $t("buttons.ok") }}
					</button>
				</div>
			</template>
		</b-modal>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}
.vali-hint {
	color: transparent;
	transition: color 0.3s;
	font-size: 1rem;
}
.invalid {
	color: red;
}
.drag-drop-zone {
	background-color: #f0f0f0;
	border: 2px dashed #d0d0d0;
	padding-top: 2rem;
	padding-bottom: 2rem;
	cursor: pointer;
	transition: background-color 0.15s ease-in-out;
}
.drag-drop-zone:hover {
	background-color: #e7e7e7;
}
.drag-drop-zone:active {
	background-color: #d7d7d7;
}
.drag-drop-text {
	font-size: 18px;
}

.btn-align-adjust {
	margin-bottom: 4.5px !important;
}

.nav-link {
	border-color: rgba(120, 130, 140, 0.13) !important;
	border-bottom-color: transparent;
}
.nav-link:hover {
	color: rgba(70, 90, 110, 0.85) !important;
	background-color: #f3f6f9 !important;
}
.nav-active-bg .nav-link.active,
.nav-active-bg .nav > li.active > a {
	color: #fff !important;
	background-color: #007bff !important;
}
.audio-player {
	height: 35px;
	width: 90%;
	margin-bottom: -6px;
	margin-left: 4px;
}
</style>

<script>
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import ObservationService from "@/services/ObservationService";
import ValidationService from "@/services/ValidationService";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import BB from "bluebird";
import XLSX from "xlsx";

export default {
	name: "FormEdit",

	props: ["user", "params", "query"],

	components: {
		EditPane,
		SaveOptionalModal,
	},

	data() {
		return {
			loading: true,
			showImportModal: false,

			fs: fs,
			tab: 1,
			form: {
				time_limits: [],
				loaded: 0,
			},
			section: null,
			client: null,

			importingFile: null,
			importingForm: null,
			importingError: null,

			dirty: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	computed: {},

	created() {
		this.loadData();
		this.initValidation();

		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: this.$i18n.t("FormEdit.error.no_name"),
					func: () => {
						return this.form && this.form.name && this.form.name != "";
					},
				},
			});
		},

		blankForm() {
			return {
				name: this.$i18n.t("FormEdit.new_form"),
				external_id: this.$i18n.t("FormEdit.new_form_external_id"),
				sections: [],
			};
		},

		addSection(form) {
			let newSection = {
				id: Utils.generateUUID(),
				name: "",
				audio_prompts: [],
				tasks: [],
			};

			form.sections.push(newSection);
		},

		removeSection(form, section) {
			let index = form.sections.indexOf(section);
			if (index < 0) return;

			this.$delete(form.sections, index);
		},

		addTask(section) {
			let newTask = {
				id: Utils.generateUUID(),
				name: "",
				description: "",
				subtasks: [],
			};

			section.tasks.push(newTask);
		},

		removeTask(section, task) {
			let index = section.tasks.indexOf(task);
			if (index < 0) return;

			this.$delete(section.tasks, index);
		},

		addSubtask(task) {
			let newSubtask = {
				id: Utils.generateUUID(),
				name: "",
				code: "",
			};

			task.subtasks.push(newSubtask);
		},

		removeSubtask(task, subtask) {
			let index = task.subtasks.indexOf(subtask);
			if (index < 0) return;

			this.$delete(task.subtasks, index);
		},

		addAudio(section) {
			let newAudio = {
				id: Utils.generateUUID(),
				name: "",
				variants: [],
				busy: false,
			};

			if (section) {
				section.audio_prompts.push(newAudio);
			} else {
				if (!this.form) return;
				if (!this.form.audio_prompts) {
					this.form.audio_prompts = [];
				}
				this.form.audio_prompts.push(newAudio);
			}
		},

		removeAudio(section, prompt) {
			if (section) {
				let index = section.audio_prompts.indexOf(prompt);
				if (index < 0) return;

				this.$delete(section.audio_prompts, index);
			} else {
				let index = this.form.audio_prompts.indexOf(prompt);
				if (index < 0) return;

				this.$delete(this.form.audio_prompts, index);
			}
		},

		addAudioFile(section, prompt, i) {
			let el = null;
			if (section) {
				el = document.getElementById(`audio-import-${section.id}-${i}`);
			} else {
				el = document.getElementById(`audio-import-${i}`);
			}
			if (!el) {
				Notie.error(this.$i18n.t("notie.add_audio_file_fail"));
				return;
			}

			el.click();
		},

		selectAudioFile(section, prompt, event) {
			prompt.busy = true;
			this.$forceUpdate();

			console.log("SELECTED AUDIO FILE", event, event.target);
			console.log(event.target.files[0]);
			console.log(section, prompt);

			let form = new FormData();
			form.set("file", event.target.files[0]);
			form.set("formID", this.form.id);

			ObservationService.saveAudioFile(form)
				.then((r) => {
					console.log("___UPLOADED___");
					console.log(r.data);
					prompt.variants.push(r.data);
					prompt.busy = false;
					this.$forceUpdate();
					event.target.value = "";
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to upload audio file", e);
					prompt.busy = false;
					this.$forceUpdate();
					event.target.value = "";
				});
		},

		removeAudioFile(prompt, variant) {
			let index = prompt.variants.indexOf(variant);
			if (index < 0) return;

			this.$delete(prompt.variants, index);
		},

		removeSubtask(task, subtask) {
			let index = task.subtasks.indexOf(subtask);
			if (index < 0) return;

			this.$delete(task.subtasks, index);
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"form",
				(newc, old) => {
					console.log(newc, old);
					console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;

			if (this.params.id != "new") {
				return BB.props({
					form: ObservationService.getForm(this.params.id),
					isScored: ObservationService.isFormScored(this.params.id),
				})
					.then((resp) => {
						this.form = resp.form.data;
						this.form.isScored = resp.isScored.data;
						this.loading = false;

						// Re-select a section with the same ID if one is already picked
						// (Useful for data reloads)
						if (this.section && this.section.id && this.form.sections && this.form.sections.length > 0) {
							_.each(this.form.sections, (sec) => {
								if (sec.id == this.section.id) {
									this.section = sec;
								}
							});
						}

						// Pick the first section if none is picked
						if (!this.section && this.form.sections && this.form.sections.length > 0) {
							this.section = this.form.sections[0];
						}

						// Otherwise, there should be no sections, so don't pick one

						this.watchChanges();
					})
					.catch((err) => {
						Notie.error(this.$i18n.t("notie.form_not_found"));
						this.$router.replace("/forms/new");
					});
			} else {
				this.form = this.blankForm();
				this.loading = false;
				this.form.name = this.$i18n.t("FormSetup.new_form");

				this.watchChanges();
			}
		},

		selectSection(sec) {
			this.section = sec;
		},

		saveForm() {
			return ObservationService.saveForm(this.form)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.form_saved"));
					if (this.form.id != resp.data.id) {
						this.$router.replace("/forms/" + resp.data.id);
					}
					this.loadData();
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.save_form_fail"), err);
				});
		},

		canSave() {
			return this.dirty;
		},

		openImportModal() {
			this.importingFile = null;
			this.importingForm = null;
			this.showImportModal = true;
		},

		dropFileSelect(event) {
			console.log("dropFileSelect", event);
		},

		clickFileSelect() {
			let el = document.getElementById("import");
			if (!el) {
				console.error("Could not find import input element");
				return;
			}

			el.click();
		},

		parseImportFile(event) {
			console.log("parseImportFile", event, event.target, event.target.files);
			let file = event.target.files[0];
			this.importingFile = file;

			const reader = new FileReader();
			reader.onload = (e) => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: "binary" });

				let parts = file.name.split(".");
				if (parts.length > 1) {
					parts.pop();
				}
				let formName = parts.join(".");

				this.parseToForm(wb, formName);
			};
			reader.readAsBinaryString(file);
		},

		parseToForm(wb, formName) {
			this.importingForm = {};

			/* Get worksheet named 'FORM' */
			const ws = wb.Sheets["FORM"];
			if (!ws) {
				this.importingForm = null;
				this.importingError = "No worksheet named 'FORM' was found";
				return;
			}
			/* Convert array of arrays */
			const rows = XLSX.utils.sheet_to_json(ws, { header: 1 });

			let header = rows[0];
			let codeCol = -1;
			let sectionCol = -1;
			let taskCol = -1;
			let subtaskCol = -1;
			let sectionIDCol = -1;
			let taskIDCol = -1;
			let subtaskIDCol = -1;

			for (let i = 0; i < header.length; i++) {
				if (header[i].toLowerCase() == "exam code") {
					codeCol = i;
				}
				if (header[i].toLowerCase() == "topic name") {
					sectionCol = i;
				}
				if (header[i].toLowerCase() == "sub section name") {
					taskCol = i;
				}
				if (header[i].toLowerCase() == "ineffective") {
					subtaskCol = i;
				}
				if (header[i].toLowerCase() == "topic id") {
					sectionIDCol = i;
				}
				if (header[i].toLowerCase() == "task id") {
					taskIDCol = i;
				}
				if (header[i].toLowerCase() == "item id" || header[i].toLowerCase() == "item  id") {
					subtaskIDCol = i;
				}
			}

			if (codeCol < 0) {
				this.abortImportWithError("No column named 'Exam Code' was found");
				return;
			}
			if (sectionCol < 0) {
				this.abortImportWithError("No column named 'Topic Name' was found");
				return;
			}
			if (taskCol < 0) {
				this.abortImportWithError("No column named 'Sub Section Name' was found");
				return;
			}
			if (subtaskCol < 0) {
				this.abortImportWithError("No column named 'Ineffective' was found");
				return;
			}
			if (sectionIDCol < 0) {
				this.abortImportWithError("No column named 'Topic ID' was found");
				return;
			}
			if (taskIDCol < 0) {
				this.abortImportWithError("No column named 'Task ID' was found");
				return;
			}
			if (subtaskCol < 0) {
				this.abortImportWithError("No column named 'Ineffective' was found");
				return;
			}
			if (subtaskIDCol < 0) {
				this.abortImportWithError("No column named 'Item  ID' was found");
				return;
			}

			let formID = rows[1][codeCol];
			if (!formID) {
				this.abortImportWithError("No Exam Code was found");
				return;
			}

			this.importingForm = {
				name: formName,
				form_id: formID,
				sections: [],
			};
			let sectionID = "";
			let taskID = "";
			let sectionName = "";
			let taskName = "";
			for (let i = 1; this.importingForm && i < rows.length; i++) {
				if (
					rows[i][sectionIDCol] &&
					rows[i][sectionIDCol].trim() &&
					rows[i][subtaskIDCol] &&
					rows[i][subtaskIDCol].trim() &&
					!(rows[i][taskCol] && rows[i][taskCol].trim())
				) {
					sectionID = rows[i][subtaskIDCol].trim();
				}
				if (rows[i][taskIDCol] && rows[i][taskIDCol].trim()) {
					taskID = rows[i][taskIDCol].trim();
				}
				if (rows[i][sectionCol] && rows[i][sectionCol].trim()) {
					sectionName = rows[i][sectionCol].trim();
				}
				if (rows[i][taskCol] && rows[i][taskCol].trim()) {
					taskName = rows[i][taskCol].trim();
				}
				this.addLineToForm(
					this.importingForm,
					i,
					rows[i],
					sectionName,
					taskName,
					subtaskCol,
					subtaskIDCol,
					sectionID,
					taskID
				);
			}
		},

		abortImportWithError(msg) {
			this.importingFile = null;
			this.importingForm = null;
			this.importingError = msg;
		},

		addLineToForm(form, lineIndex, line, sectionName, taskName, subtaskCol, subtaskIDCol, sectionID, taskID) {
			let subtaskName = line[subtaskCol] && line[subtaskCol].trim();
			let subtaskID = line[subtaskIDCol] && line[subtaskIDCol].trim();
			console.log("subtaskID", subtaskIDCol, subtaskID);
			if (!subtaskName) return; // Blank/unprocessable line

			if (!(sectionName && taskName && subtaskName && subtaskID)) {
				console.error("Line did not have expected values", {
					line: line,
					taskName: taskName,
					subtaskCol: subtaskCol,
					sectionName: sectionName,
					taskName: taskName,
					subtaskName: subtaskName,
					sectionID: sectionID,
					subtaskID: subtaskID,
				});
				this.importingFile = null;
				this.importingForm = null;
				this.importingError = `Row ${lineIndex + 1} of Exam Plan sheet did not have expected values`;
				return;
			}

			let section = _.find(form.sections, { name: sectionName });
			if (!section) {
				section = {
					name: sectionName,
					id: sectionID,
					tasks: [],
					audio_prompts: [],
				};
				form.sections.push(section);
			}

			let parts = taskName.split(" ");
			let tn = parts.shift();
			let desc = parts.join(" ");
			let task = _.find(section.tasks, { name: tn });
			if (!task) {
				task = {
					name: tn,
					id: taskID,
					description: desc,
					subtasks: [],
				};
				section.tasks.push(task);
			}

			let subtask = _.find(task.subtasks, { name: subtaskName });
			if (!subtask) {
				subtask = {
					code: String.fromCharCode(65 + task.subtasks.length),
					name: subtaskName,
					id: subtaskID,
				};
				task.subtasks.push(subtask);
			}
		},

		confirmImport() {
			if (!this.importingForm) {
				console.error("Cannot confirm import, there was no form to import");
				return;
			}

			this.form = this.importingForm;
			this.showImportModal = false;
		},

		getNumSections(form) {
			return form.sections.length;
		},

		getNumTasks(form) {
			let numTasks = 0;
			_.each(form.sections, (section) => {
				numTasks += section.tasks.length;
			});
			return numTasks;
		},

		getNumSubtasks(form) {
			let numSubtasks = 0;
			_.each(form.sections, (section) => {
				_.each(section.tasks, (task) => {
					numSubtasks += task.subtasks.length;
				});
			});
			return numSubtasks;
		},
	},
};
</script>
