<template>
	<div class="d-flex flex-column">
		<h6 class="ml-3 my-3 parti-header mb-2 clickable" @click="showBroadcasters = !showBroadcasters">
			{{ $t("VideoMeeting.broadcasters") }}
			<span v-if="!showBroadcasters" class="badge badge-secondary">{{ lists.broadcasters.length }}</span>

			<i class="fas fa-lg fa-angle-left anim-rotate ml-1" :class="{ 'rotate-90': showBroadcasters }"></i>
		</h6>
		<div v-if="showBroadcasters">
			<component
				:is="parti.isContent ? 'ParticipantListRowContent' : 'ParticipantListRow'"
				v-for="(parti, i) in lists.broadcasters"
				:key="parti.id"
				class="participant-row b-b"
				:class="{ 'b-t': i == 0 }"
				:participant="parti"
				:canAdmin="canAdmin"
				@popover="selectParti"
			/>
		</div>
		<template v-if="lists.listeners.length > 0">
			<h6 class="ml-3 my-3 parti-header mb-2 clickable" @click="showListeners = !showListeners">
				{{ $t("VideoMeeting.listeners") }}
				<span v-if="!showListeners" class="badge badge-secondary">{{ lists.listeners.length }}</span>

				<i class="fas fa-lg fa-angle-left anim-rotate ml-1" :class="{ 'rotate-90': showListeners }"></i>
			</h6>
			<div v-if="showListeners">
				<participant-list-row
					v-for="(parti, i) in lists.listeners"
					:key="parti.id"
					class="participant-row b-b"
					:class="{ 'b-t': i == 0 }"
					:participant="parti"
					:canAdmin="canAdmin"
					@popover="selectParti"
				/>
			</div>
		</template>
		<div v-if="lists.offlines.length > 0">
			<h6 class="ml-3 my-3 parti-header mb-2 clickable" @click="showOfflines = !showOfflines">
				{{ $t("VideoMeeting.offline_users") }}
				<span v-if="!showOfflines" class="badge badge-secondary">{{ lists.offlines.length }}</span>

				<i class="fas fa-lg fa-angle-left anim-rotate ml-1" :class="{ 'rotate-90': showOfflines }"></i>
			</h6>
			<div v-if="showOfflines">
				<participant-list-row-offline
					v-for="(meetingUser, i) in lists.offlines"
					:key="meetingUser.id"
					class="participant-row b-b"
					:class="{ 'b-t': i == 0 }"
					:meetingUser="meetingUser"
					@popover="selectParti"
				/>
			</div>
		</div>

		<div tabindex="0" class="d-none" id="participant-action-button-none"></div>

		<b-modal
			v-if="canAdmin"
			ref="modal"
			:hideFooter="!confirmingRemove"
			:headerBgVariant="confirmingRemove ? 'danger' : ''"
			size="sm"
		>
			<template #modal-header>
				<div style="margin: -0.5rem 0">
					<h5 v-if="!confirmingRemove" class="mb-0">{{ $t("VideoMeeting.permissions") }}</h5>
					<h5 v-else class="mb-0">{{ $t("VideoMeeting.remove_participant") }}</h5>
					<div v-if="selectedParti" class="text-muted">{{ selectedParti.name }}</div>
				</div>
			</template>
			<div v-if="confirmingRemove && selectedParti">
				{{ $t("VideoMeeting.confirm_remove", { name: selectedParti.name }) }}
			</div>
			<div v-else-if="selectedParti" style="margin: -1rem">
				<div v-if="selectedParti" class="row mx-0">
					<div class="col-6 b-t py-1 hover-highlight" :class="{ 'text-danger text-strikethrough': !canAudio }">
						<div><i class="fas fa-microphone mr-1" />{{ $t("VideoMeeting.audio") }}</div>
						<label class="ui-switch ui-switch-md theme-accent mt-1">
							<input type="checkbox" :checked="canAudio" @click="toggleAudioCapability" />
							<i></i>
						</label>
					</div>
					<div class="col-6 b-t b-l py-1 hover-highlight" :class="{ 'text-danger text-strikethrough': !canVideo }">
						<div><i class="fas fa-camera mr-1" />{{ $t("VideoMeeting.video") }}</div>
						<label class="ui-switch ui-switch-md theme-accent mt-1">
							<input type="checkbox" :checked="canVideo" @click="toggleVideoCapability" />
							<i></i>
						</label>
					</div>
					<div class="col-6 b-t b-b py-1 hover-highlight" :class="{ 'text-danger text-strikethrough': !canContent }">
						<div><i class="fas fa-presentation mr-1" />{{ $t("VideoMeeting.screenshare") }}</div>
						<label class="ui-switch ui-switch-md theme-accent mt-1">
							<input type="checkbox" :checked="canContent" @click="toggleScreenshareCapability" />
							<i></i>
						</label>
					</div>
					<div class="col-6 b-t b-b b-l py-1 hover-highlight" :class="{ 'text-danger text-strikethrough': !canChat }">
						<div><i class="fas fa-comment mr-1" />{{ $t("VideoMeeting.chat") }}</div>
						<label class="ui-switch ui-switch-md theme-accent mt-1">
							<input type="checkbox" :checked="canChat" @click="toggleChatCapability" />
							<i></i>
						</label>
					</div>
				</div>
				<a class="dropdown-item py-2" @click="confirmRemoveFromMeeting">
					<i class="fas fa-lg fa-ban text-danger"></i>
					<span>{{ $t("VideoMeeting.remove_from_meeting") }}</span>
				</a>
			</div>
			<template #modal-footer>
				<button class="btn btn-secondary" @click="cancelRemoveFromMeeting">{{ $t("buttons.cancel") }}</button>
				<button v-if="!removingFromMeeting" class="ml-2 btn btn-danger" @click="doRemoveFromMeeting">
					{{ $t("VideoMeeting.remove") }}
				</button>
				<button v-else class="ml-2 btn btn-danger" disabled>
					<loading type="icon" class="mr-1" />{{ $t("VideoMeeting.removing") }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.card {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: 0.25rem;
	padding: 0.25rem 0.5rem;
}
/* .participant-row {
	border-top: 1px solid #dee2e6;
}
.participant-row:last-child {
	border-bottom: 1px solid #dee2e6;
}
.participant-row:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.025);
} */
.badge {
	vertical-align: top;
}
</style>

<script>
//UI Components
import ParticipantListRow from "@/vues/VideoMeeting/components/ParticipantListRow";
import ParticipantListRowContent from "@/vues/VideoMeeting/components/ParticipantListRowContent";
import ParticipantListRowOffline from "@/vues/VideoMeeting/components/ParticipantListRowOffline";

//Libraries
import _ from "lodash";

//Services
import Notie from "@/services/NotieService";
import VideoMeetingService from "@/services/VideoMeetingService";

export default {
	name: "ParticipantList",
	props: ["uiState", "participants", "meetingUsers", "localParticipant"],
	components: { ParticipantListRow, ParticipantListRowContent, ParticipantListRowOffline },
	data() {
		return {
			showBroadcasters: true,
			showListeners: true,
			showOfflines: false,

			selectedParti: null,
			showPopover: false,
			confirmingRemove: false,
			removingFromMeeting: false,
		};
	},
	created() {},
	mounted() {},
	destroyed() {},

	computed: {
		lists() {
			let lists = {};
			let broadcasters = [];
			let listeners = [];
			for (let parti of this.participants) {
				if (parti.video || parti.screensharing || !parti.muted) {
					broadcasters.push(parti);
				} else {
					listeners.push(parti);
				}
			}
			lists.broadcasters = broadcasters;
			lists.listeners = listeners;

			let offlines = [];
			for (let meetingUser of this.meetingUsers) {
				let onlineParti = _.find(this.participants, { uid: meetingUser.uid });
				if (!onlineParti && meetingUser.full_name != "Pseudo-User Joining") {
					offlines.push(meetingUser);
				}
			}
			lists.offlines = offlines;

			return lists;
		},

		videoParticipants() {
			return this.participants.map((parti) => parti.video);
		},
		screensharingParticipants() {
			return this.participants.map((parti) => parti.screensharing);
		},
		mutedParticipants() {
			return this.participants.map((parti) => parti.muted);
		},

		canAdmin() {
			return this.localParticipant && this.localParticipant.role == "admin";
		},

		canAudio() {
			return (
				this.selectedParti &&
				(this.selectedParti.capabilities.audio == "SendReceive" || this.selectedParti.capabilities.audio == "Send")
			);
		},
		canVideo() {
			return (
				this.selectedParti &&
				(this.selectedParti.capabilities.video == "SendReceive" || this.selectedParti.capabilities.video == "Send")
			);
		},
		canContent() {
			return (
				this.selectedParti &&
				(this.selectedParti.capabilities.content == "SendReceive" || this.selectedParti.capabilities.content == "Send")
			);
		},
		canChat() {
			return (
				this.selectedParti &&
				(this.selectedParti.capabilities.chat == "SendReceive" || this.selectedParti.capabilities.chat == "Send")
			);
		},
	},

	watch: {},

	methods: {
		selectParti(parti) {
			this.debug("selectedParti", parti);
			this.selectedParti = parti;
			this.$refs.modal.show();
		},
		toggleAudioCapability() {
			this.log("Toggle audio capability", this.selectedParti.attendeeId, this.selectedParti.capabilities.audio);
			let orig = this.selectedParti.capabilities.audio;
			this.selectedParti.capabilities.audio = this.reverseCapability(orig);
			VideoMeetingService.setAttendeeAudioCapability(
				this.uiState.meeting.id,
				this.selectedParti.attendeeId,
				this.selectedParti.capabilities.audio
			)
				.then(() => {
					Notie.info(
						`${this.selectedParti.name}'s permission to send audio was ${
							this.selectedParti.capabilities.audio.includes("Send") ? "enabled" : "disabled"
						}`
					);
				})
				.catch((e) => {
					Notie.error("Failed to set attendee audio capability", e);
					this.selectedParti.capabilities.audio = orig;
				});
		},
		toggleVideoCapability() {
			this.log("Toggle video capability", this.selectedParti.attendeeId, this.selectedParti.capabilities.video);
			let orig = this.selectedParti.capabilities.video;
			this.selectedParti.capabilities.video = this.reverseCapability(orig);
			VideoMeetingService.setAttendeeVideoCapability(
				this.uiState.meeting.id,
				this.selectedParti.attendeeId,
				this.selectedParti.capabilities.video
			)
				.then(() => {
					Notie.info(
						`${this.selectedParti.name}'s permission to send video was ${
							this.selectedParti.capabilities.video.includes("Send") ? "enabled" : "disabled"
						}`
					);
				})
				.catch((e) => {
					Notie.error("Failed to set attendee video capability", e);
					this.selectedParti.capabilities.video = orig;
				});
		},
		toggleScreenshareCapability() {
			this.log("Toggle content capability", this.selectedParti.attendeeId, this.selectedParti.capabilities.content);
			let orig = this.selectedParti.capabilities.content;
			this.selectedParti.capabilities.content = this.reverseCapability(orig);
			VideoMeetingService.setAttendeeScreenshareCapability(
				this.uiState.meeting.id,
				this.selectedParti.attendeeId,
				this.selectedParti.capabilities.content
			)
				.then(() => {
					Notie.info(
						`${this.selectedParti.name}'s permission to screenshare was ${
							this.selectedParti.capabilities.content.includes("Send") ? "enabled" : "disabled"
						}`
					);
				})
				.catch((e) => {
					Notie.error("Failed to set attendee screenshare capability", e);
					this.selectedParti.capabilities.content = orig;
				});
		},
		toggleChatCapability() {
			this.log("Toggle chat capability", this.selectedParti.attendeeId, this.selectedParti.capabilities.chat);
			let orig = this.selectedParti.capabilities.chat;
			this.selectedParti.capabilities.chat = this.reverseCapability(orig);
			VideoMeetingService.setAttendeeChatCapability(
				this.uiState.meeting.id,
				this.selectedParti.attendeeId,
				this.selectedParti.capabilities.chat
			)
				.then(() => {
					Notie.info(
						`${this.selectedParti.name}'s permission to chat was ${
							this.selectedParti.capabilities.chat.includes("Send") ? "enabled" : "disabled"
						}`
					);
				})
				.catch((e) => {
					Notie.error("Failed to set attendee chat capability", e);
					this.selectedParti.capabilities.chat = orig;
				});
		},
		async confirmRemoveFromMeeting() {
			this.confirmingRemove = true;
		},

		cancelRemoveFromMeeting() {
			this.confirmingRemove = false;
			this.$refs.modal.hide();
		},

		async doRemoveFromMeeting() {
			this.removingFromMeeting = true;
			try {
				await VideoMeetingService.removeAttendee(this.uiState.meeting.id, this.selectedParti.attendeeId);
			} catch (e) {
				this.logError(e);
				Notie.error("Failed to remove attendee", e);
			} finally {
				this.$refs.modal.hide();
				this.confirmingRemove = false;
				this.removingFromMeeting = false;
			}
		},

		reverseCapability(cap) {
			this.debug("reverseCapability", cap);
			if (cap == "SendReceive") {
				this.debug("return", "Receive");
				return "Receive";
			} else if (cap == "Receive") {
				this.debug("return", "SendReceive");
				return "SendReceive";
			} else if (cap == "Send") {
				this.debug("return", "None");
				return "None";
			} else if (cap == "None") {
				this.debug("return", "Send");
				return "Send";
			}
			return "(error)";
		},
	},
};
</script>
