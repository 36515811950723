<template>
	<div class="box p-3" style="height: 394px">
		<div v-if="team">
			<week-select @newTimeSpan="newWeekSpan" />
			<div class="row">
				<super-dash-agreement :fromDate="fromDate" :toDate="toDate" class="col-6" :team="team" />
				<super-dash-score-volume :date="fromDate" class="col-6" :team="team" />
			</div>
		</div>
		<table v-else style="width: 100%; height: 100%">
			<tbody>
				<tr>
					<td class="text-center align-middle text-muted">{{ $t("Dashboard.must_select_team") }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import SuperDashScoreVolume from "@/components/super_dashboard/SuperDashScoreVolume";
import SuperDashAgreement from "@/components/super_dashboard/SuperDashAgreement";
import WeekSelect from "@/components/WeekSelect";

export default {
	name: "SuperDashWeekly",

	components: {
		SuperDashAgreement,
		SuperDashScoreVolume,
		WeekSelect,
	},

	props: {
		team: Object,
	},

	data() {
		return {
			fromDate: null,
			toDate: null,
		};
	},

	methods: {
		newWeekSpan({ start, end }) {
			this.fromDate = start;
			this.toDate = end;
		},
	},
};
</script>
