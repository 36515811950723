<template>
	<div class="tab-pane h-100" :class="{ active: defaultTab, show: defaultTab }">
		<div class="flex-v h-100">
			<div class="p-4 b-b _600 nav-title flex-fixed">{{ $t("TrainingEdit.Items.title") }}</div>

			<div class="flex-grow flex-v">
				<dual-table
					:leftLabel="$t('TrainingEdit.Items.assign_items')"
					:leftSearch="$t('TrainingEdit.Items.search_assigned')"
					:leftItems="assignedItems"
					:leftFields="hasFields"
					:leftControl="hasControl"
					:rightLabel="$t('TrainingEdit.Items.add')"
					:rightSearch="$t('TrainingEdit.Items.search_available')"
					:rightItems="availableItems"
					:rightFields="canFields"
					:rightControl="canControl"
					:enableSorting="false"
				>
					<!-- Actions -->
					<template #cell(left:actions)="{ item: item }">
						<nobr>
							<div class="mx-auto w30">
								<button
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									@click="removeItem(item)"
									v-tippy
									title="Remove from Item"
								>
									<i class="far fa-minus"></i>
								</button>
							</div>
						</nobr>
					</template>

					<!-- Actions -->
					<template #cell(right:actions)="{ item: item }">
						<nobr>
							<div class="mx-auto w30">
								<button
									class="btn btn-sm btn-icon btn-rounded success text-white m-0"
									@click="addItem(item)"
									v-tippy
									title="Assign to Item"
								>
									<i class="far fa-plus"></i>
								</button>
							</div>
						</nobr>
					</template>
				</dual-table>
			</div>
		</div>
	</div>
</template>

<style scoped>
.w90 {
	width: 90px;
}
.w30 {
	width: 30px;
}
.loader {
	font-size: 128px;
	line-height: 128px;
	color: gray;
	background-color: lightgray;
	text-align: center;
}
</style>

<script>
import DualTable from "@/components/DualTable";
import draggable from "vuedraggable";

export default {
	name: "TrainingEditItems",

	props: {
		course: Object,
		defaultTab: Boolean,
		allItems: Array,
		value: Array,
	},

	emits: ["update:value"],

	components: {
		"dual-table": DualTable,
		draggable,
	},

	data() {
		return {
			items: [],
			saveReqModal: false,
		};
	},

	computed: {
		assignedItems() {
			return this.value;
		},

		availableItems() {
			const { allItems, value } = this;
			return allItems.filter(({ id }) => !value.some((selectedItem) => selectedItem.id === id));
		},

		hasFields() {
			return [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			];
		},

		hasControl() {
			return {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			};
		},

		canFields() {
			return [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			];
		},

		canControl() {
			return {
				totalRows: this.availableItems.length,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			};
		},
	},

	methods: {
		emitItems(items) {
			this.$emit("input", items);
		},

		addItem(item) {
			this.emitItems(this.value.concat(item));
		},

		removeItem(item) {
			this.emitItems(this.value.filter(({ id }) => id !== item.id));
		},
	},
};
</script>
