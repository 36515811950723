import axios from "axios";

export default {
	getUserActivity(userID, limit) {
		let ctx = {};
		if (limit) {
			ctx.limit = limit;
		}
		return axios.get(`/v1/activity/user/${userID}`, { params: ctx });
	},

	getOwnActivity(limit) {
		let ctx = {};
		if (limit) {
			ctx.limit = limit;
		}
		return axios.get(`/v1/activity/own`, { params: ctx });
	},

	logScoringRecord(scoreAct) {
		return axios.post(`/v1/activity/scoring`, scoreAct);
	},

	getUsersCurrentActivity(ctx) {
		return axios.get(`/v1/activity/current/users`, { params: ctx });
	},

	getUserExceptionLogs(teamID) {
		return axios.get(`/v1/activity/exception/logs`, { params: { teamID } });
	}
};
