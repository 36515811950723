<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			Fix Copyleaks Reports
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<div class="col-12 col-sm-6 col-xl-4">
				<div class="form-group">
					<label> Client </label>
					<v-select :options="clients || []" v-model="selectedClient">
						<template slot="selected-option" slot-scope="option">
							<span>{{ option.name }}</span>
							<span class="ml-1 text-muted text-xxs">({{ option.ref_id }})</span>
						</template>
						<template slot="option" slot-scope="option">
							<span>{{ option.name }}</span>
							<div class="text-muted pb-1" style="line-height: 0.85rem">
								{{ option.ref_id }} • {{ option.id }}
							</div>
						</template>
					</v-select>
				</div>
				<div class="form-group">
					<label
						class="md-check"
						v-tippy
						title="Look through S3 for every report object and check that it actually exists, re-requesting it if not. This can take a long time."
					>
						<input v-model="checkS3" type="checkbox" />
						<i class="theme-accent"></i> Check all reports in S3
					</label>
				</div>
				<button v-if="!running" @click="getCounts" class="btn btn-success">Run</button>
				<button v-if="running" class="btn btn-success" disabled>
					<loading type="icon" class="mr-1" />Running...
				</button>
			</div>
			<div v-if="result" class="col-12 col-sm-6 col-xl-8">
				<hr class="d-sm-none" />
				<label>Result</label>
				<div class="result-panel p-3">
					<pre v-if="result" class="form-control mb-0 reverse-console" style="max-height: 300px">{{
						result
					}}</pre>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import FileService from "@/services/FileService";
import ConfigDate from "@/components/ConfigDate";
import ConfigSelect from "@/components/ConfigSelect";
import UserTimezone from "@/components/UserTimezone";
import ProgressJobService from "@/services/ProgressJobService";

export default {
	name: "FixCopyleaksReports",
	props: ["environment", "clients"],
	components: { ConfigDate, ConfigSelect, UserTimezone },

	data() {
		return {
			expanded: Store.get(this, "adminTasks.fixCopyleaksReports.expanded"),
			running: false,

			selectedClient: Store.get(this, "adminTasks.fixCopyleaksReports.selectedClient"),
			checkS3: Store.get(this, "adminTasks.fixCopyleaksReports.checkS3"),
			fromDate: null,
			toDate: null,

			result: null,
			filename: null,
			csvData: null,

			jobRunner: ProgressJobService.newJobRunner(),
		};
	},

	created() {
		let now = moment();
		now.subtract(1, "week");
		this.fromDate = now.clone().startOf("month");
		this.toDate = now.clone().endOf("month");
	},

	destroyed() {
		this.jobRunner.endAllJobs();
	},

	watch: {
		expanded() {
			Store.set(this, "adminTasks.fixCopyleaksReports.expanded", this.expanded);
		},
		selectedClient() {
			Store.set(this, "adminTasks.fixCopyleaksReports.selectedClient", this.selectedClient);
		},
		checkS3() {
			Store.set(this, "adminTasks.fixCopyleaksReports.checkS3", this.checkS3);
		},
	},
	methods: {
		getCounts() {
			this.result = "Fixing Copyleaks Reports...\n\n";
			this.running = true;

			let ctx = {
				clientID: this.selectedClient && this.selectedClient.id,
			};

			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
				this.jobRunner.setConfig(config);
			}

			let url = "/v1/admin_tasks/fix_copyleaks_reports";
			if (this.checkS3) {
				url += "?checkS3=true";
			}

			let call = axios.post(url, ctx, config);
			let updatesFunc = (r) => {
				let msgs = r.data;
				console.log(msgs.length, "msgs processed");
				for (let msg of msgs) {
					this.result += msg + "\n";
				}
			};
			let pollInterval = 500;

			this.jobRunner
				.startJob(call, updatesFunc, pollInterval, true)
				.then((r) => {
					this.running = false;
					this.result += r.data;
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to fix Copyleaks reports", e);
				});
		},
	},
};
</script>
