import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

//Pages
import Login from "@/vues/Login/Login";
import ResetPW from "@/vues/Login/ResetPW";

import Landing from "@/vues/Landing/Landing";
import LandingNoClients from "@/vues/Landing/LandingNoClients";
import MyTraining from "@/vues/Landing/MyTraining";
import TrainingScormMedia from "@/components/training_scorm/TrainingScormMedia";
import TrainingResource from "@/vues/Scoring/TrainingResource";
import UserSchedule from "@/vues/Profile/UserSchedule";
import BulletinBoard from "@/vues/Landing/BulletinBoard";
import Dashboard from "@/vues/Landing/Dashboard";
import SupervisorDashboard from "@/vues/Landing/SupervisorDashboard";
import ProjectDashboard from "@/vues/Landing/ProjectDashboard";
import TenantDashboard from "@/vues/Landing/TenantDashboard";
import InvalidLink from "@/vues/Landing/InvalidLink";
import Profile from "@/vues/Profile/Profile";
import Scoring from "@/vues/Scoring/Scoring";
import AssignedResponseList from "@/vues/Scoring/AssignedResponseList";
import ExternalResource from "@/vues/Scoring/ExternalResource";
import Backreading from "@/vues/Scoring/Backreading";
import Mail from "@/vues/Scoring/Mail";
import MeetingCalendar from "@/vues/Scoring/MeetingCalendar";

import ObservationSessions from "@/vues/Observation/ObservationSessions";
import Observe from "@/vues/Observation/Observe";
import FormSetup from "@/vues/Observation/FormSetup";
import FormEdit from "@/vues/Observation/FormEdit";
import SiteSetup from "@/vues/Observation/SiteSetup";
import SiteEdit from "@/vues/Observation/SiteEdit";

import Viewer from "@/vues/Scoring/Viewer";

import Calendar from "@/vues/QualityMgmt/Calendar";
import ProjectForecast from "@/vues/QualityMgmt/ProjectForecast";
import ReportCompletion from "@/vues/QualityMgmt/ReportCompletion";
import ReportScorers from "@/vues/QualityMgmt/ReportScorers";
import ReportPayroll from "@/vues/QualityMgmt/ReportPayroll";
import ReportDaily from "@/vues/QualityMgmt/ReportDaily";
import ReportDailyScoringMgmt from "@/vues/QualityMgmt/ReportDailyScoringMgmt";
import ReportQuotas from "@/vues/QualityMgmt/ReportQuotas";
import ReportUserSummary from "@/vues/QualityMgmt/ReportUserSummary";
import ReportItemSummary from "@/vues/QualityMgmt/ReportItemSummary";
import ReportUserAgreement from "@/vues/QualityMgmt/ReportUserAgreement";
import ReportBackreadAgreement from "@/vues/QualityMgmt/ReportBackreadAgreement";
import ReportProjectAgreement from "@/vues/QualityMgmt/ReportProjectAgreement";
import ReportTrainingCompletion from "@/vues/QualityMgmt/ReportTrainingCompletion";
import ReportSla from "@/vues/QualityMgmt/ReportSla";
import Burndown from "@/vues/QualityMgmt/Burndown";
import Auditing from "@/vues/QualityMgmt/Auditing";
import ScoreAuditing from "@/vues/QualityMgmt/ScoreAuditing";
import CandidateAuditing from "@/vues/QualityMgmt/CandidateAuditing";
import ResetHistory from "@/vues/QualityMgmt/ResetHistory";

import ExemplarSetPage from "@/vues/QC/ExemplarSetPage";
import QCSetDocumentPage from "@/vues/QC/QCSetDocumentPage";
import QCSetup from "@/vues/QC/QCSetup";
import QCSetResponses from "@/vues/QC/QCSetResponses";
import QCReport from "@/vues/QC/QCReport";
import QCUserSetReport from "@/vues/QC/QCUserSetReport";
import QCPortfolio from "@/vues/QC/QCPortfolio";
import RFSetup from "@/vues/QC/RFSetup";
import RFSetResponses from "@/vues/QC/RFSetResponses";
import RFReport from "@/vues/QC/RFReport";
import RFResponseReport from "@/vues/QC/RFResponseReport";
import RFUserSetReport from "@/vues/QC/RFUserSetReport";
import TrainingSetup from "@/vues/QC/TrainingSetup";
import TrainingEdit from "@/vues/QC/TrainingEdit";
import ScormPool from "@/vues/QC/ScormPool";

import RolesEdit from "@/vues/UserMgmt/RolesEdit";
import UserSetup from "@/vues/UserMgmt/UserSetup";
import UserEdit from "@/vues/UserMgmt/UserEdit";
import TeamSetup from "@/vues/UserMgmt/TeamSetup";
import TeamEdit from "@/vues/UserMgmt/TeamEdit";

import TenantSetup from "@/vues/SetupConfig/TenantSetup";
import TenantEdit from "@/vues/SetupConfig/TenantEdit";
import ProjectSetup from "@/vues/SetupConfig/ProjectSetup";
import ProjectEdit from "@/vues/SetupConfig/ProjectEdit";
import SectionSetup from "@/vues/SetupConfig/SectionSetup";
import SectionEdit from "@/vues/SetupConfig/SectionEdit";
import ItemSetup from "@/vues/SetupConfig/ItemSetup";
import ItemEdit from "@/vues/SetupConfig/ItemEdit";
import RubricSetup from "@/vues/SetupConfig/RubricSetup";
import RubricEdit from "@/vues/SetupConfig/RubricEdit";
import ResGroupSetup from "@/vues/SetupConfig/ResGroupSetup";
import ResGroupEdit from "@/vues/SetupConfig/ResGroupEdit";
import ConfigSheetView from "@/vues/SetupConfig/ConfigSheetView";
import APILogsLoopback from "@/vues/APILogs/Loopback";
import APILogsScoreExport from "@/vues/APILogs/ScoreExport";
import APILogsObservationScoreExport from "@/vues/APILogs/ObservationScoreExport";
import IeaTodItemSetup from "@/vues/SetupConfig/IeaTodItemSetup";
import IeaTodItemEdit from "@/vues/SetupConfig/IeaTodItemEdit";
import VideoMeeting from "@/vues/VideoMeeting/VideoMeeting";

import AdminStats from "@/vues/AdminStats";
import AdminTasks from "@/vues/AdminTasks";

import vm from "../main.js";
import moment from "moment";

//Services
import AuthService from "@/services/AuthService";
import ThemeService from "@/services/ThemeService";
import ExternalLinkService from "@/services/ExternalLinkService";
import notie from "@/services/NotieService";
import _ from "lodash";

const router = new Router({
	routes: [
		{ path: "/", name: "Login", component: Login },
		{ path: "/resetpw", name: "ResetPW", component: ResetPW },
		{ path: "/setpw", name: "SetPW", component: ResetPW },
		{
			path: "/landing",
			name: "Landing",
			component: Landing,
			props: initProps,
		},
		{
			path: "/landing/no_clients",
			name: "LandingNoClients",
			component: LandingNoClients,
			props: initProps,
		},
		{
			path: "/my_training/:itemID/:sectionID",
			name: "MyTraining",
			component: MyTraining,
			props: initProps,
		},
		{
			path: "/my_training",
			name: "MyTraining",
			component: MyTraining,
			props: initProps,
		},
		{
			path: "/my_training/scorm/:course_id/:element_id",
			name: "TrainingScormMedia",
			component: TrainingScormMedia,
			props: initProps,
		},
		{
			path: "/scorm_preview/:import_id",
			name: "TrainingScormMedia",
			component: TrainingScormMedia,
			props: initProps,
		},
		{
			path: "/my_schedule",
			name: "UserSchedule",
			component: UserSchedule,
			props: initProps,
		},
		{
			path: "/bulletin_board",
			name: "BulletinBoard",
			component: BulletinBoard,
			props: initProps,
		},
		{
			path: "/dashboard",
			name: "Dashboard",
			component: Dashboard,
			props: initProps,
		},
		{
			path: "/supervisor_dashboard",
			name: "SupervisorDashboard",
			component: SupervisorDashboard,
			props: initProps,
		},
		{
			path: "/project_dashboard",
			name: "ProjectDashboard",
			component: ProjectDashboard,
			props: initProps,
		},
		{
			path: "/tenant_dashboard",
			name: "TenantDashboard",
			component: TenantDashboard,
			props: initProps,
		},
		{
			path: "/profile",
			name: "Profile",
			component: Profile,
			props: initProps,
		},
		{
			path: "/backreading/:section_id/:item_id",
			name: "Backreading",
			component: Backreading,
			props: initProps,
		},
		{
			path: "/scoring",
			name: "Scoring",
			component: Scoring,
			props: initProps,
		},
		{
			path: "/assigned_responses/:section_id/:item_id",
			name: "AssignedResponses",
			component: AssignedResponseList,
			props: initProps,
		},
		{
			path: "/resource/:item_id/:resource_id",
			name: "ExternalResource",
			component: ExternalResource,
			props: initProps,
		},
		{
			path: "/training_resource/:course_id/:element_id",
			name: "TrainingResource",
			component: TrainingResource,
			props: initProps,
		},
		{
			path: "/scoring/:section_id/:item_id",
			name: "Viewer",
			component: Viewer,
			props: modeScoring,
		},
		{
			path: "/my_training/scoring/:section_id/:item_id",
			name: "Viewer",
			component: Viewer,
			props: modeScoring,
		},
		{
			path: "/review/:section_id/:item_id",
			name: "ReviewScoring",
			component: Viewer,
			props: modeReview,
		},
		{
			path: "/resolution/:section_id/:item_id",
			name: "Resolution",
			component: Viewer,
			props: modeResolution,
		},
		{
			path: "/adjudication/:section_id/:item_id",
			name: "Adjudication",
			component: Viewer,
			props: modeAdjudication,
		},
		{
			path: "/appeal/:section_id/:item_id",
			name: "Appeal",
			component: Viewer,
			props: modeAppeal,
		},
		{
			path: "/backread/:section_id/:item_id",
			name: "BackreadViewer",
			component: Viewer,
			props: modeBackread,
		},
		{
			path: "/qbackread/:section_id/:item_id/:queue_user_id/:skip_interval",
			name: "QueueBackreadViewer",
			component: Viewer,
			props: modeQueueBackread,
		},
		{
			path: "/practice/:section_id/:item_id/:set_id",
			name: "PracticeViewer",
			component: Viewer,
			props: modePractice,
		},
		{
			path: "/my_training/practice/:section_id/:item_id/:set_id",
			name: "PracticeViewer",
			component: Viewer,
			props: modePractice,
		},
		{
			path: "/rangefinding/:section_id/:item_id/:set_id",
			name: "RangefindingViewer",
			component: Viewer,
			props: modeRangefinding,
		},
		{
			path: "/rf_true_score/:section_id/:item_id/:rf_response_id",
			name: "RFTrueScoreViewer",
			component: Viewer,
			props: modeRFTrueScore,
		},
		{
			path: "/exemplar_set/:section_id/:item_id/:set_id/:isForTraining",
			name: "ExemplarSetPage",
			component: ExemplarSetPage,
			props: initProps,
		},
		{
			path: "/qc_set_document/:section_id/:item_id/:set_id",
			name: "QCSetDocumentPage",
			component: QCSetDocumentPage,
			props: initProps,
		},
		{
			path: "/qc_set_document/:link_id",
			name: "QCSetDocumentPage",
			component: QCSetDocumentPage,
			props: initProps,
			meta: {
				external: true,
			},
		},
		{
			path: "/qc_set_report/:set_id",
			name: "QCUserSetReport",
			component: QCUserSetReport,
			props: initProps,
		},
		{
			path: "/my_training/qc_set_report/:set_id",
			name: "QCUserSetReport",
			component: QCUserSetReport,
			props: initProps,
		},
		{
			path: "/qc_portfolio",
			name: "QCUserSetReport",
			component: QCPortfolio,
			props: initProps,
		},
		{
			path: "/my_qc_portfolio",
			name: "QCUserSetReport",
			component: QCPortfolio,
			props: mineOnlyProps,
		},
		{
			path: "/my_training/my_qc_portfolio",
			name: "QCUserSetReport",
			component: QCPortfolio,
			props: mineOnlyProps,
		},
		{
			path: "/rf_set_report/:set_id",
			name: "RFUserSetReport",
			component: RFUserSetReport,
			props: initProps,
		},
		{
			path: "/flag_queue/:section_id/:item_id/:alert_id",
			name: "FlagQueueViewer",
			component: Viewer,
			props: modeFlagQueue,
		},
		{
			path: "/qc_true_score/:section_id/:item_id/:qc_response_id",
			name: "QCTrueScoreViewer",
			component: Viewer,
			props: modeQCTrueScore,
		},
		{
			path: "/rescore/:section_id/:item_id/:response_id/:score_id",
			name: "RescoreViewer",
			component: Viewer,
			props: modeRescore,
		},
		{
			path: "/rescore/auditing/:section_id/:item_id/:response_id/:score_id",
			name: "RescoreViewer",
			component: Viewer,
			props: modeRescore,
		},
		{
			path: "/rescore_flag/:section_id/:item_id/:response_id/:flag_id",
			name: "RescoreViewer",
			component: Viewer,
			props: modeRescore,
		},
		{
			path: "/mail",
			name: "Mail",
			component: Mail,
			props: initProps,
		},
		{
			path: "/video_meetings",
			name: "MeetingCalendar",
			component: MeetingCalendar,
			props: initProps,
		},
		{
			path: "/calendar",
			name: "Calendar",
			component: Calendar,
			props: initProps,
		},
		{
			path: "/forecast",
			name: "ProjectForecast",
			component: ProjectForecast,
			props: initProps,
		},
		{
			path: "/reporting/completion",
			name: "ReportCompletion",
			component: ReportCompletion,
			props: initProps,
		},
		{
			path: "/reporting/scorers",
			name: "ReportScorers",
			component: ReportScorers,
			props: initProps,
		},
		{
			path: "/reporting/payroll",
			name: "ReportPayroll",
			component: ReportPayroll,
			props: initProps,
		},
		{
			path: "/reporting/daily",
			name: "ReportDaily",
			component: ReportDaily,
			props: initProps,
		},
		{
			path: "/reporting/daily_scoring_mgmt",
			name: "ReportDailyScoringMgmt",
			component: ReportDailyScoringMgmt,
			props: initProps,
		},
		{
			path: "/reporting/quotas",
			name: "ReportQuotas",
			component: ReportQuotas,
			props: initProps,
		},
		{
			path: "/reporting/user_summary",
			name: "ReportUserSummary",
			component: ReportUserSummary,
			props: initProps,
		},
		{
			path: "/reporting/item_summary",
			name: "ReportItemSummary",
			component: ReportItemSummary,
			props: initProps,
		},
		{
			path: "/reporting/user_agreement",
			name: "ReportUserAgreement",
			component: ReportUserAgreement,
			props: initProps,
		},
		{
			path: "/reporting/backread_agreement",
			name: "ReportBackreadAgreement",
			component: ReportBackreadAgreement,
			props: initProps,
		},
		{
			path: "/reporting/project_agreement",
			name: "ReportProjectAgreement",
			component: ReportProjectAgreement,
			props: initProps,
		},
		{
			path: "/reporting/training_completion",
			name: "ReportTrainingCompletion",
			component: ReportTrainingCompletion,
			props: initProps,
		},
		{
			path: "/reporting/sla",
			name: "ReportSla",
			component: ReportSla,
			props: initProps,
		},
		{
			path: "/calc/burndown",
			name: "Burndown",
			component: Burndown,
			props: initProps,
		},
		{
			path: "/auditing",
			name: "Auditing",
			component: Auditing,
			props: initProps,
		},
		{
			path: "/score_auditing",
			name: "ScoreAuditing",
			component: ScoreAuditing,
			props: initProps,
		},
		{
			path: "/candidate_auditing",
			name: "CandidateAuditing",
			component: CandidateAuditing,
			props: initProps,
		},
		{
			path: "/reset_history",
			name: "ResetHistory",
			component: ResetHistory,
			props: initProps,
		},
		{
			path: "/audit/:section_id/:item_id/:response_id",
			name: "AuditViewer",
			component: Viewer,
			props: modeAuditing,
		},
		{
			path: "/score_audit/:section_id/:item_id/:response_id",
			name: "ScoreAuditViewer",
			component: Viewer,
			props: modeScoreAuditing,
		},
		{
			path: "/qc_setup",
			name: "QCSetup",
			component: QCSetup,
			props: initProps,
		},
		{
			path: "/qc_setup/set/:setID",
			name: "QCSetResponses",
			component: QCSetResponses,
			props: initProps,
		},
		{
			path: "/qc_reporting",
			name: "QCReport",
			component: QCReport,
			props: initProps,
		},
		{
			path: "/rf_setup",
			name: "RFSetup",
			component: RFSetup,
			props: initProps,
		},
		{
			path: "/rf_setup/set/:setID",
			name: "RFSetResponses",
			component: RFSetResponses,
			props: initProps,
		},
		{
			path: "/rf_reporting",
			name: "RFReport",
			component: RFReport,
			props: initProps,
		},
		{
			path: "/rf_response/:respID",
			name: "RFResponseReport",
			component: RFResponseReport,
			props: initProps,
		},
		{
			path: "/training",
			name: "TrainingSetup",
			component: TrainingSetup,
			props: initProps,
		},
		{
			path: "/training/:id",
			name: "TrainingEdit",
			component: TrainingEdit,
			props: initProps,
		},
		{
			path: "/scorm_pool",
			name: "ScormPool",
			component: ScormPool,
			props: initProps,
		},
		{
			path: "/roles",
			name: "RolesEdit",
			component: RolesEdit,
			props: initProps,
		},
		{
			path: "/users",
			name: "UserSetup",
			component: UserSetup,
			props: initProps,
		},
		{
			path: "/users/:id",
			name: "UserEdit",
			component: UserEdit,
			props: initProps,
		},
		{
			path: "/teams",
			name: "TeamSetup",
			component: TeamSetup,
			props: initProps,
		},
		{
			path: "/teams/:id",
			name: "TeamEdit",
			component: TeamEdit,
			props: initProps,
		},
		{
			path: "/tenant",
			name: "TenantEditCurrent",
			component: TenantEdit,
			props: currentTenantProps,
		},
		{
			path: "/tenants",
			name: "TenantSetup",
			component: TenantSetup,
			props: initProps,
		},
		{
			path: "/tenants/:id",
			name: "TenantEdit",
			component: TenantEdit,
			props: initProps,
		},
		{
			path: "/projects",
			name: "ProjectSetup",
			component: ProjectSetup,
			props: initProps,
		},
		{
			path: "/projects/:id",
			name: "ProjectEdit",
			component: ProjectEdit,
			props: initProps,
		},
		{
			path: "/sections",
			name: "SectionSetup",
			component: SectionSetup,
			props: initProps,
		},
		{
			path: "/sections/:id",
			name: "SectionEdit",
			component: SectionEdit,
			props: initProps,
		},
		{
			path: "/items",
			name: "ItemSetup",
			component: ItemSetup,
			props: initProps,
		},
		{
			path: "/items/:id",
			name: "ItemEdit",
			component: ItemEdit,
			props: initProps,
		},
		{
			path: "/rubrics",
			name: "RubricSetup",
			component: RubricSetup,
			props: initProps,
		},
		{
			path: "/rubrics/:id",
			name: "RubricEdit",
			component: RubricEdit,
			props: initProps,
		},
		{
			path: "/resolution_groups",
			name: "ResGroupSetup",
			component: ResGroupSetup,
			props: initProps,
		},
		{
			path: "/resolution_groups/:id",
			name: "ResGroupEdit",
			component: ResGroupEdit,
			props: initProps,
		},
		{
			path: "/config_sheets/:id",
			name: "ConfigSheetView",
			component: ConfigSheetView,
			props: initProps,
		},
		{
			path: "/api_logs/loopback",
			name: "APILogsLoopback",
			component: APILogsLoopback,
			props: initProps,
		},
		{
			path: "/api_logs/outgoing",
			name: "APILogsOutgoing",
			component: APILogsScoreExport,
			props: initProps,
		},
		{
			path: "/api_logs/observation/outgoing",
			name: "APILogsObservationOutgoing",
			component: APILogsObservationScoreExport,
			props: initProps,
		},
		{
			path: "/api_logs/incoming",
			name: "APILogsIncoming",
			component: APILogsLoopback,
			props: initProps,
		},
		{
			path: "/iea_tod_items",
			name: "IeaTodItemSetup",
			component: IeaTodItemSetup,
			props: initProps,
		},
		{
			path: "/iea_tod_items/:tenantId/:projectId/:itemId",
			name: "IeaTodItemEdit",
			component: IeaTodItemEdit,
			props: initProps,
		},
		{
			path: "/observation_sessions",
			name: "ObservationSessions",
			component: ObservationSessions,
			props: initProps,
		},
		{
			path: "/observe/:session_id",
			name: "Observe",
			component: Observe,
			props: initProps,
		},
		{
			path: "/forms",
			name: "FormSetup",
			component: FormSetup,
			props: initProps,
		},
		{
			path: "/forms/:id",
			name: "FormEdit",
			component: FormEdit,
			props: initProps,
		},
		{
			path: "/sites",
			name: "SiteSetup",
			component: SiteSetup,
			props: initProps,
		},
		{
			path: "/sites/:id",
			name: "SiteEdit",
			component: SiteEdit,
			props: initProps,
		},
		{
			path: "/invalid_link",
			name: "InvalidLink",
			component: InvalidLink,
			props: initProps,
		},
		{
			path: "/admin_stats",
			name: "AdminStats",
			component: AdminStats,
			props: initProps,
		},
		{
			path: "/admin_tasks",
			name: "AdminTasks",
			component: AdminTasks,
			props: initProps,
		},
		{
			path: "/video_meeting/:id",
			name: "VideoMeeting",
			component: VideoMeeting,
			props: initProps,
		},
		{
			path: "/video_meeting/:id/join/:password",
			name: "VideoMeeting",
			component: VideoMeeting,
			props: initProps,
		},
	],
});

function initProps(route) {
	let props = {
		user: route.meta.user,
		params: route.params,
		query: route.query,
	};
	return props;
}

function extendProps(obj) {
	let func = function (route) {
		let props = {
			user: route.meta.user,
			params: route.params,
			query: route.query,
		};
		_.each(obj, (v, k) => {
			props[k] = v;
		});
		return props;
	};
	return func;
}

function currentTenantProps(route) {
	let props = {
		user: route.meta.user,
		params: { id: "current" },
		query: route.query,
	};
	return props;
}

function mineOnlyProps(route) {
	route.params.mineOnly = true;
	return initProps(route);
}

function modeScoring(route) {
	route.params.mode = "SCORING";
	return initProps(route);
}

function modeReview(route) {
	route.params.mode = "REVIEW";
	return initProps(route);
}

function modeResolution(route) {
	route.params.mode = "RESOLUTION";
	return initProps(route);
}

function modeAdjudication(route) {
	route.params.mode = "ADJUDICATION";
	return initProps(route);
}

function modeAppeal(route) {
	route.params.mode = "APPEAL";
	return initProps(route);
}

function modeBackread(route) {
	route.params.mode = "BACKREAD";
	return initProps(route);
}

function modeQueueBackread(route) {
	route.params.mode = "QUEUE BACKREAD";
	return initProps(route);
}

function modeFlagQueue(route) {
	route.params.mode = "FLAG QUEUE";
	return initProps(route);
}

function modeAuditing(route) {
	route.params.mode = "AUDITING";
	return initProps(route);
}

function modeScoreAuditing(route) {
	route.params.mode = "SCORE_AUDITING";
	return initProps(route);
}

function modeQCTrueScore(route) {
	route.params.mode = "QC_TRUE_SCORE";
	return initProps(route);
}

function modeRescore(route) {
	route.params.mode = "RESCORE";
	return initProps(route);
}

function modePractice(route) {
	route.params.mode = "PRACTICE";
	return initProps(route);
}

function modeRangefinding(route) {
	route.params.mode = "RANGEFINDING";
	return initProps(route);
}

function modeRFTrueScore(route) {
	route.params.mode = "RF_TRUE_SCORE";
	return initProps(route);
}

//Map routes to permission fields in the User.Roles struct
let userPermissions = {
	"/my_schedule": "page_my_schedule",
	"/my_training": "page_scoring",
	"/my_training_for_scoring_item": "page_scoring",
	"/my_training/scorm/:course_id/:element_id": ["page_scoring", "page_config_training"],
	"/scorm_preview/:import_id": ["page_scoring", "page_config_training"],
	"/training_resource/:course_id/:element_id": ["page_scoring", "page_config_training"],
	"/bulletin_board": "page_bulletin_board",
	"/dashboard": "page_dashboard",
	"/supervisor_dashboard": "page_supervisor_dashboard",
	"/project_dashboard": "page_project_dashboard",
	"/tenant_dashboard": "page_tenant_dashboard",
	"/scoring": "page_scoring",
	"/assigned_responses/:section_id/:item_id": "page_scoring",
	"/scoring/:section_id/:item_id": "page_scoring",
	"/my_training/scoring/:section_id/:item_id": "page_scoring",
	"/resource/:item_id/:resource_id": "page_scoring",
	"/review/:section_id/:item_id": "review_scoring",
	"/practice/:section_id/:item_id/:set_id": "page_scoring",
	"/my_training/practice/:section_id/:item_id/:set_id": "page_scoring",
	"/rangefinding/:section_id/:item_id/:set_id": "rf_score",
	"/exemplar_set/:section_id/:item_id/:set_id/:isForTraining": "page_scoring",
	"/qc_set_document/:section_id/:item_id/:set_id": "page_qc_setup",
	"/qc_set_report/:set_id": "page_scoring",
	"/my_training/qc_set_report/:set_id": "page_scoring",
	"/rf_set_report/:set_id": "rf_score",
	"/resolution/:section_id/:item_id": "has_resolution",
	"/adjudication/:section_id/:item_id": "has_adjudication",
	"/appeal/:section_id/:item_id": "has_appeal",
	"/backread/:section_id/:item_id": "page_backreading",
	"/qbackread/:section_id/:item_id/:queue_user_id/:skip_interval": "page_backreading",
	"/flag_queue/:section_id/:item_id/:alert_id": "flag_queue_scoring",
	"/project_summary": "page_project_summary",
	"/backreading/:section_id/:item_id": "page_backreading",
	"/mail": "page_mail",
	"/video_meetings": "page_video_meetings",
	"/video_meeting/:id": "page_video_meetings",

	"/observation_sessions": "page_observation_sessions",
	"/observe/:session_id": "page_observation_sessions",
	"/forms": "page_config_forms",
	"/forms/:id": "page_config_forms",
	"/sites": "page_config_sites",
	"/sites/:id": "page_config_sites",

	"/calendar": "page_calendar",
	"/forecast": "page_forecast",
	"/reporting/completion": "page_completion",
	"/reporting/scorers": "page_scorers",
	"/reporting/payroll": "page_payroll",
	"/reporting/daily": "page_daily",
	"/reporting/daily_scoring_mgmt": "page_daily_scoring_mgmt",
	"/reporting/quotas": "page_quotas",
	"/reporting/user_summary": "page_user_summary",
	"/reporting/item_summary": "page_item_summary",
	"/reporting/user_agreement": "page_user_agreement",
	"/reporting/backread_agreement": "page_backread_agreement",
	"/reporting/project_agreement": "page_project_agreement",
	"/reporting/training_completion": "page_training_completion",
	"/reporting/sla": "page_sla_report",
	"/calc/burndown": "page_completion",

	"/auditing": "page_auditing",
	"/score_auditing": "page_score_auditing",
	"/candidate_auditing": "page_candidate_auditing",
	"/reset_history": "reset_history",
	"/audit/:section_id/:item_id/:response_id": "page_auditing",
	"/score_audit/:section_id/:item_id/:response_id": "page_score_auditing",
	"/rescore/:section_id/:item_id/:response_id/:score_id": "page_mail",
	"/rescore/auditing/:section_id/:item_id/:response_id/:score_id": ["page_score_auditing", "delete_data"],
	"/rescore_flag/:section_id/:item_id/:response_id/:flag_id": "page_mail",

	"/qc_setup": "page_qc_setup",
	"/qc_reporting": "page_qc_report",
	"/qc_true_score/:section_id/:item_id/:qc_response_id": "page_qc_setup",
	"/qc_setup/set/:setID": "page_qc_setup",
	"/qc_portfolio": "qc_portfolio",
	"/my_qc_portfolio": "page_scoring",
	"/my_training/my_qc_portfolio": "page_scoring",
	"/rf_setup": "page_rf_setup",
	"/rf_setup/set/:setID": "page_rf_setup",
	"/rf_true_score/:section_id/:item_id/:rf_response_id": "page_rf_setup",
	"/rf_reporting": "page_rf_report",
	"/rf_response/:respID": "page_rf_report",
	"/training": "page_config_training",
	"/training/:id": "page_config_training",
	"/scorm_pool": "page_config_training",

	"/roles": ["page_config_tenants", "page_config_current_tenant"],
	"/users": "page_config_users",
	"/users/:id": "page_config_users",
	"/teams": "page_config_teams",
	"/teams/:id": "page_config_teams",

	"/tenants": "page_config_tenants",
	"/tenants/:id": "page_config_tenants",
	"/tenant": "page_config_current_tenant",
	"/projects": "page_config_projects",
	"/projects/:id": "page_config_projects",
	"/sections": "page_config_sections",
	"/sections/:id": "page_config_sections",
	"/items": "page_config_items",
	"/items/:id": "page_config_items",
	"/items/:id/:tab": "page_config_items",
	"/rubrics": "page_config_rubrics",
	"/rubrics/:id": "page_config_rubrics",
	"/resolution_groups": "page_config_res_groups",
	"/resolution_groups/:id": "page_config_res_groups",
	"/config_sheets/:id": "use_config_sheets",

	"/api_logs/loopback": "page_api_logs",
	"/api_logs/outgoing": "page_api_logs",
	"/api_logs/observation/outgoing": "page_api_logs",
	"/api_logs/incoming": "page_api_logs",
	"/iea_tod_items": "manage_iea_models",
	"/iea_tod_items/:tenantId/:projectId/:itemId": "manage_iea_models",
	"/my_training/:itemID/:sectionID": "page_scoring",
};

//But some routes should be allowed for all users
let globalPermissions = {
	"/landing": true,
	"/profile": true,
	"/admin_stats": true,
	"/admin_tasks": true,
};

//And some routes should be allowed to external visitors to the website
let externalPermissions = {
	"": true,
	"/": true,
	"/landing/no_clients": true,
	"/setpw": true,
	"/resetpw": true,
	"/invalid_link": true,
	"/video_meeting/:id/join/:password": true,
};

var externalLinkRegex = /^\/ex\/([a-zA-Z0-9_-]{8})$/;

router.beforeEach((to, from, next) => {
	if (window.hashToken) {
		AuthService.token = window.hashToken;
	}

	console.log("to object", to);
	console.log("to matched");
	for (let match of to.matched) {
		console.log(match);
	}
	console.log("----Routing '" + to.path + "' to " + to.name + "----");
	console.log("META", to.meta);

	if (matchedAnyRoute(to, externalPermissions) || to.path.startsWith("/ex/") || to.meta.external) {
		let match = to.path.match(externalLinkRegex);
		console.log("REGEX THING", match);
		if (match) {
			to.meta.linkID = match[1];
			handleExternalLink(to, next);
			return;
		}

		ThemeService.setTheme("info");
		console.log("Router go next");
		next();
		return;
	}

	AuthService.getUser()
		.then((user) => {
			console.log("GOT USER", user);
			to.meta.user = user;
			if (user.role.require_lockdown && !window.kiosk) {
				notie.error("User requires secure browser");
				next("/");
				return;
			}
			setUserLocale(user.locale, user.client.locale);
			ThemeService.setTheme(user.role.theme);
			if (hasPermissions(to, user)) {
				console.log("User", user, user.full_name, "had permission", to);
				next();
			} else {
				console.log(to);
				notie.info(`<h4>You are not allowed here!</h4><div class="text-muted">${to.path}</div>`);
				next(false);
			}
		})
		.catch((err) => {
			console.log(err);
			notie.info("<h4>You are not logged in</h4>");
			next("/");
		});
});

function setUserLocale(userLocale, clientLocale) {
	console.log("setUserLocale");
	if (vm.$i18n.showKeys) return;
	if (clientLocale) {
		console.log("clientLocale", clientLocale);
		vm.$i18n.locale = clientLocale;
		moment.locale(clientLocale);
	} else {
		console.log("userLocale", userLocale);
		vm.$i18n.locale = userLocale;
		moment.locale(userLocale);
	}
}

function hasPermissions(route, user) {
	//We have to look at the matched routes, not the path itself so we can have dynamic paths
	//i.e. /items/afd78baxy0x cannot be compared to a page directly, but /items/:id can
	var hasPermission = false;

	_.each(route.matched, function (match) {
		//First check global permissions
		if (globalPermissions[match.path]) {
			hasPermission = true;
			return hasPermission;
		}

		//Now check the role permissions
		var permissionNeeded = userPermissions[match.path];
		if (typeof permissionNeeded == "object") {
			_.each(permissionNeeded, (p) => {
				if (user.role[p]) {
					hasPermission = true;
				}
			});
		} else {
			if (user.role[permissionNeeded]) {
				hasPermission = true;
			}
		}
	});

	return hasPermission;
}

function matchedAnyRoute(route, permissions) {
	for (let match of route.matched) {
		if (permissions[match.path]) {
			return true;
		}
	}
}

var externalLinkBasePaths = {
	QCSetDocument: "/qc_set_document",
};

function handleExternalLink(to, next) {
	console.log("handleExternalLink", to);
	ExternalLinkService.getExternalLink(to.meta.linkID)
		.then((r) => {
			if (!externalLinkBasePaths[r.data.component]) {
				console.log("Unknown external link component", r.data.component);
				next("/invalid_link");
				return;
			}

			let nextURL = `${externalLinkBasePaths[r.data.component]}/${to.meta.linkID}`;
			console.log("nextURL", nextURL, to.meta);
			next(nextURL);
			return;
		})
		.catch((e) => {
			console.error(e);
			console.log("Invalid external link", to.meta.linkID);
			next("/invalid_link");
			return;
		});
}

export default router;
