<template>
	<div class="h-100">
		<link href="static/css/Reenie-Beanie.css" rel="stylesheet" type="text/css" />
		<div class="h-100 scroll-y scroll-x">
			<div v-if="!editing" @click="editNote" class="personal-note note-padding clickable">
				<div v-if="!editing && note">
					<div v-html="note.text"></div>
				</div>
				<div v-if="!editing && !note" class="text-center">
					<div class="no-note text-very-muted py-3">Your notes here</div>
				</div>
			</div>
			<div v-if="editing" @click="editNote" class="personal-note editor-padding">
				<RichText
					class="snote-full-height"
					:initial="orig_text"
					:text.sync="note.text"
					:options="{ toolbarContainer: '#snote-toolbar', disableResizeEditor: true }"
					integrated
				/>
			</div>
		</div>
		<div v-if="editing" class="detached-toolbar d-flex flex-row align-items-end">
			<div id="snote-toolbar" class="flex"></div>
			<button
				v-if="orig_text != note.text"
				class="btn btn-toolbar theme"
				style="margin-bottom: 1px"
				@click="saveNote"
			>
				Save
			</button>
			<button
				v-if="orig_text == note.text"
				class="btn btn-toolbar btn-secondary"
				style="margin-bottom: 1px"
				@click="stopEditNote"
			>
				Close
			</button>
		</div>
	</div>
</template>

<style scoped>
.personal-note {
	margin-top: 1rem;
	margin-bottom: 1rem;
	position: relative;
	height: calc(100% - 2rem);
	overflow: auto;
	margin-left: auto;
	margin-right: auto;

	min-width: 400px;
	max-width: 800px;
	background-color: rgba(255, 255, 224, 1);
	transition: background-color 0.15s;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
}
.personal-note.clickable:hover {
	background-color: rgba(245, 245, 214, 1);
}
.note-padding {
	padding-top: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 10px;
}
.editor-padding {
	padding: calc(1rem - 10px);
}
.clickable {
	cursor: pointer;
}
.no-note {
	font-family: "Reenie Beanie", arial, sans-serif;
	font-size: 4rem;
}
.detached-toolbar {
	min-width: 400px;
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;

	padding-left: calc(1rem - 5px);
	padding-right: calc(1rem - 5px);

	position: absolute;
	left: 0;
	right: 0;
	bottom: calc(100% + 4px);
	background-color: transparent;
	z-index: 10;
}
.btn-toolbar {
	height: 31px;
	margin-top: 5px;
	padding-top: 4px;
	margin-bottom: 6px;
}
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<script>
import UserService from "@/services/UserService";
import notie from "@/services/NotieService";

import RichText from "@/components/RichText";

export default {
	name: "PersonalNote",
	props: ["item"],
	components: { RichText },

	data() {
		return {
			note: null,
			orig_text: null,
			editing: false,
		};
	},
	created() {
		this.loadData();
	},
	watch: {
		height() {},
	},
	computed: {
		changed() {
			return this.note && this.orig_text != this.note.text;
		},
	},
	methods: {
		loadData() {
			UserService.getPersonalNote(this.item.id).then((resp) => {
				console.log("PERSONAL NOTE", resp.data);
				if (resp.data) {
					this.note = resp.data;
				}
			});
		},

		editNote() {
			if (!this.note) {
				this.note = {
					text: "",
				};
			}
			this.orig_text = this.note.text;
			this.editing = true;
		},

		stopEditNote() {
			this.editing = false;
		},

		saveNote() {
			UserService.savePersonalNote(this.item.id, this.note)
				.then((resp) => {
					//Do nothing
					this.stopEditNote();
				})
				.catch((err) => {
					notie.error("Failed to save personal note", err);
					this.stopEditNote();
				});
		},
	},
};
</script>
