<template>
	<div class="row m-0">
		<form role="form" class="px-4 col-2">
			<div class="form-group _600 margin-overlap">
				{{ $t("ItemEdit.Quality.calibration") }}
			</div>
			<div class="form-group">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="calConfig.enabled" />
					<i></i>
				</label>
			</div>
		</form>
		<div class="px-4 pt-2 col-10" v-if="calConfig.enabled">
			<div class="row">
				<div class="checkbox form-group">
					<label class="md-check pr-2">
						<input v-model="calConfig.lockout" type="checkbox" />
						<i class="theme-accent"></i> {{ $t("ItemEdit.Quality.validity.lockout") }}
						<div v-if="calConfig.lockout" class="py-1 pl-1">
							<label class="md-check">
								<input v-model="calConfig.unlock_on_pass" type="checkbox" />
								<i class="theme-accent"></i> {{ $t("ItemEdit.Quality.validity.unlock_on_pass") }}
							</label>
						</div>
					</label>
				</div>
				<div class="checkbox form-group pl-5">
					<label class="md-check">
						<input v-model="calConfig.continue_cal" type="checkbox" />
						<i class="theme-accent"></i> {{ $t("ItemEdit.Quality.validity.continue_cal") }}
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CalConfig",

	props: {
		calConfig: Object,
	},
};
</script>

<style>
</style>