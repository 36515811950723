<template>
	<div class="tab-pane h-100" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="flex-v h-100">
			<div class="p-4 b-b _600 nav-title flex-fixed">{{ $t("SectionEdit.Items.title") }}</div>

			<div class="flex-grow flex-v">
				<dual-table
					:key="tableKey"
					:leftLabel="$t('SectionEdit.Items.assign_items')"
					:leftSearch="$t('SectionEdit.Items.search_assigned')"
					:leftItems="hasProvider"
					:leftFields="hasFields"
					:leftControl="hasControl"
					:rightLabel="$t('SectionEdit.Items.add')"
					:rightSearch="$t('SectionEdit.Items.search_available')"
					:rightItems="canProvider"
					:rightFields="canFields"
					:rightControl="canControl"
					:enableSorting="true"
					@showsortsectionitemsmodalevent="openSortModal"
				>
					<!-- Actions -->
					<template #cell(left:actions)="data">
						<nobr>
							<div class="mx-auto w30">
								<button
									v-if="!data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									@click="attemptRemoveItem(data.item)"
									v-tippy
									:title="$t('tooltip.remove_from_section')"
								>
									<i class="far fa-minus"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Removing..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>

					<!-- Actions -->
					<template #cell(right:actions)="data">
						<nobr>
							<div class="mx-auto w30">
								<button
									v-if="!data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded success text-white m-0"
									@click="attemptAddItem(data.item)"
									v-tippy
									title="Assign to Section"
								>
									<i class="far fa-plus"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Adding..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>
				</dual-table>
			</div>
		</div>

		<save-required-modal
			:dirty="dirty"
			:valid="valid"
			:save="save"
			:next="saveReqNext"
			objectText="Section"
			:objectName="section.name"
			:actionText="saveReqAction"
			v-model="saveReqModal"
		/>

		<b-modal ref="sortSectionItemsModal">
			<template slot="modal-header">
				<div class="mx-3">
					<h5 class="modal-title">{{ $t("sortSectionItems.title") }}</h5>
				</div>
			</template>
			<div class="box-body light lt p-0">
				<div class="box-body light lt p-0">
					<table class="table">
						<thead style="display: block">
							<tr>
								<th style="width: 10%">{{ $t("sortSectionItems.item_name") }}</th>
								<th style="width: 10%">{{ $t("sortSectionItems.sequence") }}</th>
							</tr>
						</thead>
						<tbody style="display: block">
							<draggable v-model="sectionItems" :options="{ handle: '.drag-handle' }">
								<tr v-for="(item, i) in sectionItems" :key="i">
									<td style="width: 10%" class="px-2 drag-handle v-mid">
										<i class="fa fa-sort"></i>
									</td>
									<td style="width: 50%" class="px-2 drag-handle v-mid">
										<div>{{ item.name }}</div>
									</td>
									<td style="width: 8%" class="px-2 drag-handle v-mid">
										<div>{{ item.section_item_sequence }}</div>
									</td>
								</tr>
							</draggable>
						</tbody>
					</table>
				</div>
			</div>

			<template slot="modal-footer">
				<button class="btn btn-primary" @click="applySortSectionItemsModal()">
					{{ $t("buttons.apply") }}
				</button>
				<button class="btn btn-primary" @click="cancelSortSectionItemsModal()">
					{{ $t("buttons.cancel") }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.w90 {
	width: 90px;
}
.w30 {
	width: 30px;
}
.loader {
	font-size: 128px;
	line-height: 128px;
	color: gray;
	background-color: lightgray;
	text-align: center;
}
</style>

<script>
import SectionService from "@/services/SectionService";
import notie from "@/services/NotieService";

import DualTable from "@/components/DualTable";
import SaveRequiredModal from "@/components/SaveRequiredModal";
import draggable from "vuedraggable";

export default {
	name: "SectionEditItems",

	props: ["id", "section", "dirty", "valid", "save", "defaultTab"],

	components: {
		"dual-table": DualTable,
		"save-required-modal": SaveRequiredModal,
		draggable,
	},

	data() {
		return {
			tableKey: 0,
			hasFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			],
			hasControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},
			canFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id") },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
				},
			],
			canControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},

			saveReqModal: false,
			saveReqAction: "continue",
			saveReqNext: () => {},
			sectionItems: null,
		};
	},

	methods: {
		openSortModal() {
			this.hasProvider("").then((result) => {
				this.sectionItems = result;
				var counter = 1;
				_.each(result, function (item) {
					if (item.section_item_sequence == 0) {
						item.section_item_sequence = counter;
					}
					counter = counter + 1;
				});
				this.$refs["sortSectionItemsModal"].show();
			});
		},

		hasProvider(ctx) {
			if (this.section.id) {
				this.hasControl.emptyText = this.$i18n.t("data_description.no_records");
				return SectionService.getSectionItems(this.section.id, ctx)
					.then((resp) => {
						this.hasControl.totalRows = resp.data.totalRows;
						return resp.data.items;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.get_section_items_fail"), err);
						return [];
					});
			} else {
				this.hasControl.emptyText = "Cannot assign items to a section before saving";
				return [];
			}
		},

		canProvider(ctx) {
			ctx.inverse = "t";
			if (this.section.id) {
				this.canControl.emptyText = this.$i18n.t("data_description.no_records");
				return SectionService.getSectionItems(this.section.id, ctx)
					.then((resp) => {
						console.log(resp);
						this.canControl.totalRows = resp.data.totalRows;
						return resp.data.items;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.load_assignable_items_fail"), err);
						return [];
					});
			} else {
				this.canControl.emptyText = "Cannot assign items to a section before saving";
				return [];
			}
		},

		attemptAddItem(item) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.addItem(item);
				};
				this.saveReqAction = "add an item to the section";
				this.saveReqModal = true;
			} else {
				this.addItem(item);
			}
		},

		addItem(item) {
			item.inProgress = true;
			SectionService.addItem(this.section.id, item.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.item_added"));
					this.refreshTables();
					item.inProgress = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.add_item_fail"), err);
					item.inProgress = false;
				});
		},

		attemptRemoveItem(item) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.removeItem(item);
				};
				this.saveReqAction = "remove an item from the section";
				this.saveReqModal = true;
			} else {
				this.removeItem(item);
			}
		},

		removeItem(item) {
			item.inProgress = true;

			// if (this.itemsWithResponses[item.id]) {
			// 	notie.error(this.$i18n.t("notie.cannot_remove_item"), this.$i18n.t("notie.item_has_responses"));
			// }

			SectionService.removeItem(this.section.id, item.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.item_removed"));
					this.refreshTables();
					item.inProgress = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.remove_item_fail"), err);
					item.inProgress = false;
				});
		},

		refreshTables() {
			this.tableKey++;
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},

		cancelSortSectionItemsModal() {
			this.$refs["sortSectionItemsModal"].hide();
		},

		applySortSectionItemsModal() {
			var si = [];
			_.each(this.sectionItems, function (item) {
				si.push(item.id);
			});
			//console.log(si)
			var x = JSON.stringify(si);
			SectionService.updateSectionItemSequence(this.section.id, x)
				.then((resp) => {
					notie.info(this.$i18n.t("sortSectionItems.sequences_updated"));
					this.refreshTables();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("sortSectionItems.failed_to_update_sequences"), err);
				});
			this.$refs["sortSectionItemsModal"].hide();
		},
	},
};
</script>
