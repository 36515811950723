<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			Mongo Profiler
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<div class="col-12">
				<div class="form-group d-flex flex-row">
					<button class="btn btn-primary" :disabled="running" @click="getRecords">
						<i class="mr-1 fa fa-server" />
						Get Profiler Records
					</button>
					<div v-if="records && records.length > 0 && startTime && endTime">
						<div>{{ numDocs }} ops</div>
						<div>{{ startTime.toLocaleString() }}</div>
						<div>{{ endTime.toLocaleString() }}</div>
					</div>
					<button class="ml-auto btn btn-success" :disabled="running" @click="enableProfiling">
						<i class="mr-1 fa fa-folder-plus" />
						Enable Profiling
					</button>
					<button class="ml-2 btn btn-danger" :disabled="running" @click="disableProfiling">
						<i class="mr-1 fa fa-folder-minus" />
						Disable Profiling
					</button>
				</div>
			</div>
			<div class="col-12">
				<b-table
					ref="table"
					class="table-condensed"
					striped
					hover
					:show-empty="true"
					:items="records"
					:fields="fields"
					sort-by="millis"
					:sort-desc="true"
					sort-direction="desc"
				>
					<template #cell(collection)="data">
						<i class="ml-1 mr-2 fas fa-server text-muted" />{{ data.item.collection }}
					</template>
					<template #cell(millis)="data">
						{{ data.item.millis }} <span class="text-muted text-xxs">ms</span>
					</template>
					<template #cell(millis_per_req)="data">
						{{ parseFloat(data.item.millis_per_req.toFixed(3)) }}
						<span class="text-muted text-xxs">ms</span>
					</template>
					<template #cell(plan)="data">
						<span v-if="data.item.type == 'insert'" class="text-extra-muted">--</span>
						<span v-else class="_600" :class="{ [`text-${getPlanColor(data.item.docs[0])}`]: true }">{{
							getPlanTextShort(data.item.docs[0])
						}}</span>
					</template>
					<template #cell(ratio)="data">
						<div :class="{ [`text-${getRatioColor(data.item.examined, data.item.returned)}`]: true }">
							{{ getRatio(data.item.examined, data.item.returned) }}
						</div>
					</template>
					<template #cell(docs)="data">
						<div class="d-flex flex-row">
							<button class="btn btn-primary" @click="openDocsModal(data.item)">
								<i class="mr-1 fa fa-database" />
								Docs
							</button>
						</div>
					</template>
				</b-table>
			</div>
		</div>

		<b-modal id="docsModal" :visible="showModal" @hide="showModal = false" size="lg">
			<template slot="modal-header">
				<div v-if="sRecord" class="d-flex flex-row align-items-center w-100">
					<h5 class="modal-title pl-3 mr-3">
						<span class="_600">{{ sRecord.collection }}</span>
					</h5>
					<div class="ml-auto d-flex flex-row align-items-center">
						<button
							class="btn"
							:disabled="docIndex <= 0"
							:class="{ [docIndex <= 0 ? 'btn-secondary' : 'btn-primary']: true }"
							style="opacity: 1"
							@click="docLeft"
						>
							<i class="fa fa-fw fa-arrow-left" />
						</button>
						<span class="nowrap mx-2 font-monospace">{{ docIndex + 1 }}/{{ sRecord.docs.length }}</span>
						<button
							class="ml-auto btn"
							:disabled="docIndex >= sRecord.docs.length - 1"
							:class="{ [docIndex >= sRecord.docs.length - 1 ? 'btn-secondary' : 'btn-primary']: true }"
							style="opacity: 1"
							@click="docRight"
						>
							<i class="fa fa-fw fa-arrow-right" />
						</button>
					</div>
				</div>
			</template>

			<div v-if="sRecord" class="modal-v-limit d-flex flex-column">
				<div class="query-box pt-2 pl-2">
					<div class="badge text-left badge-primary mr-2 mb-2">
						<div class="_500 text-muted">Type:</div>
						<div class="mt-1">{{ sRecord.type }}</div>
					</div>
					<div class="badge text-left badge-info mr-2 mb-2">
						<div class="_500 text-muted">Total:</div>
						<div class="mt-1">{{ sRecord.millis }} <span class="text-muted">ms</span></div>
					</div>
					<div class="badge text-left badge-info mr-2 mb-2">
						<div class="_500 text-muted">Average:</div>
						<div class="mt-1">{{ sRecord.millis_per_req }} <span class="text-muted">ms</span></div>
					</div>
					<div class="badge text-left badge-info mr-2 mb-2">
						<div class="_500 text-muted">This:</div>
						<div class="mt-1">{{ sRecord.docs[docIndex].millis }} <span class="text-muted">ms</span></div>
					</div>
					<div
						class="badge text-left mr-2 mb-2"
						:class="{ [`badge-${getPlanColor(sRecord.docs[docIndex])}`]: true }"
					>
						<div class="_500 text-muted">Strategy:</div>
						<div class="mt-1">{{ getPlanText(sRecord.docs[docIndex]) }}</div>
					</div>
				</div>

				<div class="scroll-y query-box mt-3">
					<JsonTree :data="sRecord.docs[docIndex]" />
				</div>

				<div v-if="sRecord.docs[docIndex].execStats" class="mt-3 query-box d-flex flex-row flex-wrap pt-2 pl-2">
					<div
						v-for="(stage, i) in getExecStages(sRecord.docs[docIndex].execStats)"
						:key="i"
						class="mb-2 mr-2 d-flex flex-row align-items-center"
					>
						<i v-if="i > 0" class="mr-2 fa fa-lg fa-arrow-right" />
						<div class="card p-2 inline" v-tippy :title="getStageJSON(stage)" style="max-width: 150px">
							<div class="_600" :class="{ inline: stage.filter || stage.keyPattern }">
								{{ stage.stage }}
							</div>
							<div :class="{ 'inline-right': stage.filter || stage.keyPattern }">
								Est:
								<span class="_600"
									>{{ stage.executionTimeMillisEstimate }} <span class="text-muted">ms</span></span
								>
							</div>
							<div v-if="stage.filter" class="small-query-block">
								{{ stage.filter }}
							</div>
							<div v-if="stage.keyPattern" class="small-query-block">
								{{ stage.keyPattern }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<template slot="modal-footer">
				<button class="btn btn-secondary" @click="showModal = false">
					{{ $t("buttons.done") }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.query-box {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
.small-query-block {
	padding-top: 4px;
	line-height: 1.2;
	font-size: 0.7rem;
}
.inline {
	display: inline;
}
.inline-right {
	display: inline;
	float: right;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import EnvRequest from "./EnvRequest";
import JsonTree from "vue-json-tree";

export default {
	name: "PsiObsCounts",
	props: ["environment", "clients"],
	components: { JsonTree },

	data() {
		return {
			expanded: Store.get(this, "adminTasks.mongoProfiler.expanded"),
			running: false,

			records: [],
			startTime: null,
			endTime: null,
			numDocs: 0,

			sRecord: null,
			docIndex: 0,
			showModal: false,

			fields: [
				{
					key: "collection",
					sortable: true,
					tdClass: "v-mid _600",
				},
				{
					key: "type",
					sortable: true,
					tdClass: "v-mid",
				},
				{
					key: "count",
					sortable: true,
					tdClass: "v-mid",
				},
				{
					key: "millis",
					label: "Total Time",
					sortable: true,
					tdClass: "v-mid",
				},
				{
					key: "millis_per_req",
					label: "Time/Req",
					sortable: true,
					tdClass: "v-mid",
				},
				{
					key: "plan",
					label: "Plan",
					sortable: true,
					tdClass: "v-mid",
				},
				{
					key: "ratio",
					label: "Ratio",
					sortable: true,
					tdClass: "v-mid",
				},
				{
					key: "docs",
					label: "Details",
					tdClass: "v-mid",
				},
			],
		};
	},
	created() {},
	watch: {
		expanded() {
			Store.set(this, "adminTasks.mongoProfiler.expanded", this.expanded);
		},
	},
	methods: {
		enableProfiling() {
			this.running = true;
			EnvRequest.post(this, "/v1/admin_tasks/mongoprofiler/enable")
				.then((r) => {
					this.running = false;
					Notie.info("Mongo profiling enabled");
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to enable mongo profiling", e);
				});
		},

		disableProfiling() {
			this.running = true;
			EnvRequest.post(this, "/v1/admin_tasks/mongoprofiler/disable")
				.then((r) => {
					this.running = false;
					Notie.info("Mongo profiling disabled");
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to disable mongo profiling", e);
				});
		},

		getRecords() {
			this.records = [];
			this.startTime = null;
			this.endTime = null;
			this.numDocs = 0;

			this.result = null;
			this.running = true;

			EnvRequest.get(this, "/v1/admin_tasks/mongoprofiler/records")
				.then((r) => {
					this.debug("GOT RECORDS", r.data);
					this.running = false;
					this.records = r.data.records;
					this.startTime = new Date(r.data.start_time);
					this.endTime = new Date(r.data.end_time);
					this.numDocs = r.data.num_docs;
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to get response ingest counts", e);
				});
		},

		openDocsModal(sRecord) {
			this.sRecord = sRecord;
			this.docIndex = 0;
			this.showModal = true;
		},

		docLeft() {
			this.docIndex--;
		},

		docRight() {
			this.docIndex++;
		},

		getPlanText(doc) {
			return doc.planSummary || "N/A";
		},

		getPlanTextShort(doc) {
			if (!doc.planSummary) return "(unknown)";
			return doc.planSummary.split(" ")[0];
		},

		getPlanColor(doc) {
			if (!doc.planSummary) return "secondary";
			let plan = this.getPlanTextShort(doc);
			switch (plan) {
				case "IXSCAN":
					return "warning";
				case "IDHACK":
					return "success";
				case "COLLSCAN":
					return "danger";
				default:
					return "dark";
			}
		},

		getExecStages(execStats) {
			let stageList = [];
			this.getExecStagesRecurse(_.cloneDeep(execStats), stageList);
			return stageList;
		},

		getExecStagesRecurse(inputStage, stageList) {
			if (inputStage.inputStage) {
				this.getExecStagesRecurse(inputStage.inputStage, stageList);
				delete inputStage.inputStage;
				stageList.push(inputStage);
			} else {
				stageList.push(inputStage);
			}
		},

		getStageJSON(stage) {
			let json = JSON.stringify(stage, null, 4);
			let lines = json.split("\n");
			for (let i in lines) {
				let indentation = lines[i].search(/\S/);
				lines[i] = `<div style=\"padding-left: ${indentation * 4}px\">${lines[i]}</div>`;
			}
			let html = lines.join("");
			return `<div class="text-xxs text-left">${html}</div>`;
		},

		getRatio(examined, returned) {
			if (examined == 0 || returned == 0) {
				return "--";
			}

			let ratio = examined / returned;
			if (ratio < 2) {
				return (examined / returned).toFixed(2);
			} else {
				return Math.ceil(examined / returned);
			}
		},

		getRatioColor(examined, returned) {
			if (examined == 0) {
				return "extra-muted";
			}

			let ratio = examined / returned;
			if (ratio < 2) {
				return `success`;
			} else if (ratio < 100) {
				return `warning`;
			} else {
				return `danger`;
			}
		},
	},
};
</script>
