<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("SectionEdit.Details.title") }}</div>
		<div role="form" class="p-4 col-sm-6">
			<div class="form-group" :class="{ invalid: !valid.field('name') }">
				<label>{{ $t("SectionEdit.Details.name") }}</label>
				<input type="text" class="form-control" v-model="section.name" />
			</div>
			<div class="form-group" :class="{ invalid: !(valid.field('ref') && valid.field('ref_char')) }">
				<label>{{ $t("SectionEdit.Details.ref_id") }}</label>
				<input type="text" class="form-control" v-model.trim="section.ref_id" :disabled="section.isScored" />
				<small v-if="!section.isScored" class="form-text text-muted">{{
					$t("SectionEdit.Details.ref_id_unique")
				}}</small>
			</div>
			<div class="form-group" :class="{ invalid: !valid.field('project') }">
				<label>{{ $t("SectionEdit.Details.project") }}</label>
				<v-select :options="projects" v-model="sProject" label="name"> </v-select>
			</div>
			<div class="form-group _600">{{ $t("SectionEdit.Details.require_training") }}</div>
			<div class="form-group">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="section.requiresTraining" />
					<i></i>
				</label>
			</div>
			<div
				v-if="section.requiresTraining"
				class="form-group"
				:class="{ invalid: !valid.field('training_course') }"
			>
				<label>{{ $t("SectionEdit.Details.training_courses") }}</label>
				<v-select
					:options="trainingCourses"
					:reduce="(o) => o.id"
					v-model="section.training_course_ids"
					label="name"
					:class="{ is_invalid: !valid.field('training_course') }"
					multiple
				>
				</v-select>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import ProjectService from "@/services/ProjectService";
import TrainingService from "@/services/TrainingService";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

export default {
	name: "SectionEditDetails",

	props: ["id", "section", "defaultTab", "valid"],

	data() {
		return {
			fs: fs,
			projects: [],
			sProject: {},
			trainingCourses: [],
		};
	},

	watch: {
		sProject: function (newVal, oldVal) {
			if (!newVal) {
				this.section.project = null;
				this.section.project_id = null;
			} else if (this.section.project_id != newVal.id) {
				this.section.project = newVal;
				this.section.project_id = newVal.id;
			}
		},
		"section.loaded": function (newVal, oldVal) {
			this.loadData();
		},
		"section.name"() {
			if (this.autoRef && !this.section.isScored) {
				let genRef = fs.toGoodRefID(this.section.name);
				if (this.section.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.section.ref_id = genRef;
				}
			}
		},
		"section.ref_id"() {
			this.checkAutoRef();
		},
	},

	created() {
		this.loadData();
		this.checkAutoRef();
	},

	methods: {
		loadData() {
			this.sProject = this.section.project;

			ProjectService.listAllProjects()
				.then((resp) => {
					this.projects = resp.data.projects;
					this.sProject = _.find(this.projects, {
						id: this.section.project_id,
					});
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.load_projects_fail"), err);
					console.error(err);
				});

			TrainingService.listAllTrainingCourses()
				.then((r) => {
					this.trainingCourses = r.data;
				})
				.catch((e) => {
					notie.error(this.$i18n("notie.load_training_courses_fail"));
					console.error(e);
				});
		},
		checkAutoRef() {
			let genRef = fs.toGoodRefID(this.section.name);
			this.autoRef = this.section.ref_id.toLowerCase() == genRef.toLowerCase() && !this.section.isScored;
		},
	},
};
</script>
