import axios from "axios";
import Notie from "@/services/NotieService";

/**
 * @description
 * Service for anything training scorm related.
 */
export default {
	/**
	 * @description
	 * Returns a promise of a signed scorm course
	 */
	getSignedTrainingScormMedia(folder) {
		return axios.get("/v1/signed/training_scorm_media?folder=" + folder);
	},
	/**
	 * @description
	 * moves a scorm package from an external S3 location in the local S3
	 */
	addExternalScormPackage(scormLocationID, scormRefID) {
		return axios.post(`/v1/admin/training/scorm/external/${scormLocationID}/${scormRefID}`);
	},
	/**
	 * @description
	 * deletes a SCORM Package, if it is not being used by an training courses
	 */
	deleteScormPackage(import_id) {
		return axios.delete(`/v1/admin/training/scorm/${import_id}`);
	},
	/**
	 * @description
	 * Returns a promise of training scorm details
	 */
	getTrainingScormDetails(course_id, element_id) {
		return axios.get(`/v1/my/training/course/${course_id}/element/${element_id}/scorm_details`);
	},
	/**
	 * @description
	 * Returns a promise of training scorm preview from ScormRecordImport Id
	 */
	getScormPreview(scorm_import_id) {
		return axios.get(`/v1/admin/training/scorm/preview/${scorm_import_id}`);
	},
	/**
	 * @description
	 * updateTrainingScormStat
	 */
	updateTrainingScormStat(import_record_id, varname, varvalue) {
		return axios.post(`/v1/my/training/course/scorm_stat/${import_record_id}/${varname}/${varvalue}`);
	},
	/**
	 * @description
	 * updateTrainingTime
	 */
	updateTrainingTime(trainingScormDetails, course_id, element_id) {
		return axios.post(`/v1/my/training/course/${course_id}/element/${element_id}/time`, trainingScormDetails);
	},
};
