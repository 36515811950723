<template>
	<canvas ref="chart" :key="userProgress + ''" v-chartjs="chartConfig"></canvas>
</template>

<script>
import { TRAINING_PROGRESS_STATES } from "@/services/Constants";
const TRAINING_PROGRESS_STATES_VALUES = Object.values(TRAINING_PROGRESS_STATES);

require("@/directives/chartjs");

export default {
	name: "TrainingCompletionGraph",

	props: {
		userProgress: Array,
	},

	computed: {
		chartConfig() {
			return {
				type: "pie",
				data: {
					labels: TRAINING_PROGRESS_STATES_VALUES.map(({ name }) => name),
					datasets: [
						{
							data: this.chartData,
							borderColor: "transparent",
							backgroundColor: ["green", "orange", "lightblue", "red"],
						},
					],
				},
				options: {
					maintainAspectRatio: false,
					legend: {
						position: "bottom",
						labels: {
							boxWidth: 12,
						},
					},
				},
			};
		},

		chartData() {
			const groupedStates = _.groupBy(this.userProgress, "training_progress.overall_progress");
			return TRAINING_PROGRESS_STATES_VALUES.map(({ id }) => (groupedStates[id] || []).length);
		},
	},

	methods: {
		getImageData() {
			const { $refs, chartConfig } = this;
			chartConfig.options.animation = { duration: 0 };
			return new Chart($refs.chart.getContext("2d"), chartConfig).toBase64Image("image/png", 1);
		},
	},
};
</script>