<template>
	<div class="h-100 d-flex flex-column" style="overflow: hidden; position: relative">
		<div class="chat-messages flex d-flex flex-column-reverse scroll-y" ref="chat" @scroll="chatScrolled">
			<chat-message
				v-for="(msg, i) in chatMessages"
				:key="msg._id"
				:meetingID="uiState && uiState.meeting ? uiState.meeting.id : ''"
				:canAdmin="canAdmin"
				:message="msg"
				:class="{ 'mt-auto': i == chatMessages.length - 1 }"
			/>
		</div>
		<div class="chat-input-container d-flex flex-column align-items-center border-top">
			<Transition name="fade">
				<div v-if="uiState.chatScrolledUp" class="scroll-button-container fadeIn">
					<button class="ml-2 btn btn-rounded btn-icon btn-md btn-primary" @click="scrollToBottom">
						<i class="fas fa-arrow-down" />
					</button>
				</div>
			</Transition>

			<chat-input :canChat="canChat" @submit="submitCurrentChatMessage" />
		</div>
	</div>
</template>

<style scoped>
.chat-messages {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.scroll-button-container {
	position: absolute;
	top: -50px;
	width: 100%;
	display: flex;
	justify-content: center;
}
</style>

<script>
//UI Components
import ChatMessage from "@/vues/VideoMeeting/components/ChatMessage";
import ChatInput from "@/components/ChatInput";
import EditableDiv from "@/components/EditableDiv";

//Libraries
import _ from "lodash";

//Services
import Signal from "@/vues/VideoMeeting/services/SignalEngine";

export default {
	name: "Chat",
	props: ["uiState", "localParticipant", "chatMessages"],
	components: { ChatMessage, ChatInput, EditableDiv },
	data() {
		return {
			inputController: null,

			text: null,
		};
	},
	created() {
		this.uiState.chatScrolledUp = false;
		this.uiState.unreadMessages = 0;
	},
	mounted() {},

	watch: {},

	computed: {
		canChat() {
			return (
				this.localParticipant &&
				(this.localParticipant.capabilities.chat == "SendReceive" ||
					this.localParticipant.capabilities.chat == "Send")
			);
		},

		canAdmin() {
			return this.localParticipant && this.localParticipant.role == "admin";
		},
	},

	methods: {
		submitCurrentChatMessage(text) {
			let trimmedText = text.replace(/\s+/g, " ").trim();
			if (trimmedText == "") {
				return;
			}

			const msg = {
				body: {
					text: trimmedText,
				},
			};
			this.log("Send chat message:", trimmedText);
			Signal.sendChatMessage(msg);
			this.$emit("sendChatMessage", msg);
		},

		chatScrolled(e) {
			if (e.target.scrollTop < 0) {
				this.uiState.chatScrolledUp = true;
			} else {
				this.uiState.chatScrolledUp = false;
				if (this.uiState.sidebarTab == "chat") {
					this.uiState.unreadMessages = 0;
				}
			}
		},

		scrollToBottom() {
			this.$refs.chat.scrollTo({ top: 0, behavior: "smooth" });
		},

		addImage() {
			this.debug("add Image");
		},
	},
};
</script>
