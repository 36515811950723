<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportProjectAgreement.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportProjectAgreement.project_agreement") }}
				</div>
			</div>
		</template>

		<div class="d-flex flex-column flex scroll-x scroll-y">
			<div class="px-3 pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="row mx-0">
					<div class="col-12 col-lg-6 col-xl-4">
						<div class="form-group">
							<label>{{ $t("ReportProjectAgreement.project") }}</label>
							<config-select :options="projects" v-model="selectedProject"></config-select>
						</div>
					</div>
					<div class="col-12 col-lg-6 col-xl-4">
						<div class="form-group">
							<label>{{ $t("ReportProjectAgreement.labels") }}</label>
							<config-label-search v-model="labels" :project="selectedProject" />
						</div>
					</div>
				</div>
				<div class="row mx-0">
					<div class="col-12 col-lg-6 col-xl-4">
						<div class="form-group">
							<label>
								{{ $t("ReportDaily.from_date") }}
								<user-timezone />
							</label>
							<config-date v-model="fromDate" :config="{ showClear: true }"></config-date>
						</div>
					</div>
					<div class="col-12 col-lg-6 col-xl-4">
						<div class="form-group">
							<label>
								{{ $t("ReportDaily.to_date") }}
								<user-timezone />
							</label>
							<config-date v-model="toDate" :config="{ showClear: true }" defaultTime="11:59 PM">
							</config-date>
						</div>
					</div>
				</div>
				<div class="row mx-0">
					<div class="col-12 d-flex flex-row align-items-end justify-content-end">
						<div class="d-flex flex-row align-items-center form-group">
							<button
								v-if="reportStats && reportStats.length > 0"
								class="btn btn-sm btn-icon btn-rounded theme-accent mx-1"
								style="padding-top: 10px"
								v-tippy
								:title="$t('tooltip.export')"
								data-toggle="dropdown"
								aria-expanded="false"
							>
								<i class="fas fa-file-export" style="margin-left: 2px"></i>
							</button>
							<div
								v-if="reportStats && reportStats.length > 0"
								class="dropdown-menu dropdown-menu-right w-md"
							>
								<a class="dropdown-item" @click="dlPdf()">
									<i class="far fa-file-pdf fa-fw fa-lg"></i>
									<label class="mb-0">{{ $t("ReportProjectAgreement.export_to_pdf") }}</label>
								</a>
								<a class="dropdown-item" @click="dlPdfWithTraits()">
									<i class="far fa-file-pdf fa-fw fa-lg"></i>
									<label class="mb-0">
										{{ $t("ReportProjectAgreement.export_to_pdf") }}
										<span class="text-muted">{{ $t("ReportProjectAgreement.detailed") }}</span>
									</label>
								</a>
								<a class="dropdown-item" @click="dlCsv()">
									<i class="far fa-file-excel fa-fw fa-lg"></i>
									<label class="mb-0">{{ $t("ReportProjectAgreement.export_to_csv") }}</label>
								</a>
								<a class="dropdown-item" @click="dlCsvWithTraits()">
									<i class="far fa-file-excel fa-fw fa-lg"></i>
									<label class="mb-0">
										{{ $t("ReportProjectAgreement.export_to_csv") }}
										<span class="text-muted">{{ $t("ReportProjectAgreement.detailed") }}</span>
									</label>
								</a>
							</div>
							<div class="py-0 ml-1">
								<button
									@click="getStats"
									:disabled="!selectedProject"
									class="btn btn-sm theme-accent btn-block flex-fixed"
									v-b-tooltip
									:title="$t('tooltip.run_report')"
								>
									<i v-if="!running" class="far fa-clipboard-list"></i>
									<loading v-if="running" type="icon" />
									<span class="hidden-folded d-inline"
										>&nbsp;{{ $t("ReportProjectAgreement.run_report") }}</span
									>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="reportStats && reportStats.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div class="flex">
					<div class="card border-0">
						<report-table
							class="condensed-table mb-0"
							:fields="fields"
							:totals="reportTotals"
							:content="reportStats"
							totalsClass="theme-lighten-3 text-on-color"
						>
							<!-- Section name -->
							<template #cell(section_name)="data">
								<span>{{ data.item.section_name }}</span>
							</template>
							<!-- Item name -->
							<template #cell(item_name)="data">
								<a
									v-if="!isTotalRow(data.item)"
									@click="toggleExpand(data)"
									class="float-right btn-subtle"
								>
									<i
										v-if="!data.item.expand"
										class="far fa-plus"
										v-tippy
										:title="$t('tooltip.show_details')"
									></i>
									<i
										v-if="data.item.expand"
										class="far fa-minus"
										v-tippy
										:title="$t('tooltip.hide_details')"
									></i>
								</a>
								<span>{{ data.item.item_name }}</span>
								<div v-show="data.item.expand">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ trait.trait_name }}
										</div>
									</template>
								</div>
							</template>

							<!-- IRR Trait Agreement -->
							<template #cell(irr_exact_percent)="data">
								<span :class="{ 'text-very-muted': data.item.irr_total == 0 }">{{
									fs.fixedPercent1d(data.item.irr_exact_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.irr_total > 0">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.irr_exact_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(irr_exact_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>
							<!-- IRR Score Agreement -->
							<template #cell(irr_exact_adj_percent)="data">
								<span :class="{ 'text-very-muted': data.item.irr_total == 0 }">{{
									fs.fixedPercent1d(data.item.irr_exact_adj_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.irr_total > 0">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.irr_exact_adj_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(irr_exact_adj_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>

							<!-- Validity Trait Agreement -->
							<template #cell(val_exact_percent)="data">
								<span v-if="data.item.val_total > 0 && showVal">{{
									fs.fixedPercent1d(data.item.val_exact_percent)
								}}</span>
								<span v-if="data.item.val_total == 0 || !showVal" class="text-very-muted">{{
									fs.fixedPercent1d(NaN)
								}}</span>
								<div v-show="data.item.expand && data.item.val_total > 0 && showVal">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.val_exact_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(val_exact_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>
							<!-- Validity Score Agreement -->
							<template #cell(val_exact_adj_percent)="data">
								<span v-if="data.item.val_total > 0 && showVal">{{
									fs.fixedPercent1d(data.item.val_exact_adj_percent)
								}}</span>
								<span v-if="data.item.val_total == 0 || !showVal" class="text-very-muted">{{
									fs.fixedPercent1d(NaN)
								}}</span>
								<div v-show="data.item.expand && data.item.val_total > 0 && showVal">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.val_exact_adj_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(val_exact_adj_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>

							<!-- Response Total -->
							<template #cell(completed_resps)="data">
								<span v-if="data.item.completed_resps > 0">{{ data.item.completed_resps }}</span>
								<span v-if="data.item.completed_resps == 0" class="text-very-muted">{{
									fs.fixed1d(NaN)
								}}</span>
							</template>
							<template #head(completed_resps)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="reportStats && reportStats.length == 0" class="d-flex flex-row justify-content-center">
				<h3 class="text-muted">{{ $t("ReportProjectAgreement.report_returned_no_results") }}</h3>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-long {
	display: none;
}

.d-abr {
	display: table-cell;
}

@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}

	.d-abr {
		display: none;
	}
}

.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}

i[class*="ion-"],
i[class*=" ion-"] {
	font-size: inherit;
	vertical-align: inherit;
}

a.btn-subtle i {
	opacity: 0.35;
	transition: opacity 0.25s;
}

a.btn-subtle:hover i {
	opacity: 1;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ConfigService from "@/services/ConfigService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import fs from "@/services/FormatService";
import FileService from "@/services/FileService";
import moment from "moment";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigLabelSearch from "@/components/ConfigLabelSearch";
import ReportTable from "@/components/ReportTable";
import ConfigDate from "@/components/ConfigDate";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "ReportProjectAgreement",

	props: ["user"],

	components: { ConfigLabelSearch, ConfigSelect, ReportTable, ConfigDate, UserTimezone },

	data() {
		return {
			fs: fs,

			fields: [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
				},
				{
					key: "section_name",
					label: this.$i18n.t("fields.section"),
					tdClass: "",
					thClass: "v-mid",
					sortable: true,
				},
				{
					key: "item_name",
					label: this.$i18n.t("fields.item"),
					tdClass: "table-border-right v-mid",
					thClass: "table-border-right v-mid",
					sortable: true,
				},
				{
					key: "irr_exact_percent",
					label: this.$i18n.t("fields.irr"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact"),
					tdClass: "v-mid",
					thClass: "v-mid",
					sortable: true,
				},
				{
					key: "irr_exact_adj_percent",
					label: this.$i18n.t("fields.irr"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact_adjacent"),
					tdClass: "table-border-right v-mid",
					thClass: "table-border-right v-mid",
					sortable: true,
				},
				{
					key: "val_exact_percent",
					label: this.$i18n.t("fields.validity"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact"),
					tdClass: "v-mid",
					thClass: "v-mid",
					sortable: true,
				},
				{
					key: "val_exact_adj_percent",
					label: this.$i18n.t("fields.validity"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact_adjacent"),
					tdClass: "table-border-right v-mid",
					thClass: "table-border-right v-mid",
					sortable: true,
				},
				{
					key: "completed_resps",
					label: this.$i18n.t("fields.responses"),
					sublabel: this.$i18n.t("ReportProjectAgreement.total_completed"),
					tdClass: "v-mid",
					thClass: "v-mid",
					sortable: true,
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
				},
			],
			itemStats: null,

			reportStats: null,
			reportTotals: null,

			projects: [],
			selectedProject: null,

			fromDate: null,
			toDate: null,

			labels: [],
			showVal: true,

			loading: true,
			running: false,
		};
	},

	created() {
		this.storagePrefix = "project_agreement.";
		this.showIncomplete = store.bind(this, "showIncomplete");
		this.fromDate = store.bind(this, "fromDate");
		this.toDate = store.bind(this, "toDate");

		if (store.get(this, "project_agreement.auto")) {
			this.running = true;
		}

		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		ConfigService.listProjectsShallow(ctx)
			.then((resp) => {
				this.projects = resp.data;
				this.selectedProject = store.bindFromList(this, this.projects, "id", null, "selectedProject");

				if (store.get(this, "project_agreement.auto")) {
					store.set(this, "project_agreement.auto", null);
					this.running = false;
					this.getStats();
				}
				this.loading = false;
			})
			.catch((err) => {
				console.log(err);
				notie.error("Failed to load config options", err);
				this.loading = false;
			});
	},

	watch: {},

	methods: {
		getStats() {
			if (this.running) {
				return;
			}
			this.itemStats = null;
			this.reportStats = null;
			this.reportTotals = null;

			this.running = true;
			ReportingService.getAgreementItems(this.selectedProject.id, this.labels, this.fromDate, this.toDate)
				.then((resp) => {
					this.reportStats = resp.data.stats.sort((a, b) => {
						console.log(a);
						if (a.section_name === b.section_name) {
							return a.item_name < b.item_name ? -1 : 1;
						}
						return a.section_name < b.section_name ? -1 : 1;
					});
					this.reportTotals = resp.data.totals;
					if (this.reportTotals.section_name.includes("Totals")) {
						this.reportTotals.section_name = this.$i18n
							.t("fields.totals")
							.toString()
							.concat(this.reportTotals.section_name.substring(6, this.reportTotals.section_name.length));
					}
					this.showVal = !this.labels || this.labels.length == 0;
					this.running = false;
				})
				.catch((err) => {
					console.log(err);
					this.running = false;
				});
		},

		fillItem(row) {
			//real
			let section = _.find(this.sections, { id: row.section_id });

			//mockup
			section = this.sections[0];

			if (section && section.items) {
				//real
				let item = _.find(section.items, { id: row.item_id });

				//mockup
				item = section.items[0];

				if (item) {
					this.selectedSection = section;
					this.selectedItem = item;
					this.getStats();
				}
			}
		},

		isTotalRow(item) {
			if (item.forceHead || item.forceFoot) {
				return true;
			} else {
				return false;
			}
		},

		toggleExpand(data) {
			this.$set(data.item, "expand", !data.item.expand);
		},

		dlPdf() {
			FileService.projectAgreementReportPDF(this, false);
		},

		dlCsv() {
			FileService.projectAgreementReportCSV(this, false);
		},

		dlPdfWithTraits() {
			FileService.projectAgreementReportPDF(this, true);
		},

		dlCsvWithTraits() {
			FileService.projectAgreementReportCSV(this, true);
		},
	},
};
</script>
