var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex h-100 scroll-y page-body"},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('loading',{staticClass:"mt-5",attrs:{"type":"large"}})],1):_vm._e(),(!_vm.loading && !(_vm.resources && _vm.resources.length > 0))?_c('div',[_vm._v(_vm._s(_vm.$("ExternalResource.no_resources")))]):_vm._e(),(!_vm.loading && _vm.resources && _vm.resources.length > 0)?[_c('div',{staticClass:"header theme"},[_c('div',{staticClass:"text-muted mr-2 ml-3"},[_vm._v(_vm._s(_vm.$t("ExternalResource.item"))+":")]),_c('div',[_vm._v(_vm._s(_vm.item.name))]),_c('div',{staticClass:"horizontal-divider ml-3"}),_c('a',{staticClass:"header-button",attrs:{"data-toggle":"dropdown","aria-expanded":"false"}},[_c('div',{staticClass:"text-muted mr-2"},[_vm._v(_vm._s(_vm.$t("ExternalResource.resource"))+":")]),_c('div',[_vm._v(_vm._s(_vm.pickedResource.name))]),_c('i',{staticClass:"ml-2 fas fa-lg fa-caret-down"})]),(_vm.resources && _vm.resources.length > 0)?_c('div',{staticClass:"dropdown-menu"},[(!_vm.resources || _vm.resources.length == 0)?_c('div',[_c('h6',{staticClass:"text-muted text-center"},[_vm._v(" "+_vm._s(_vm.$t("data_description.no_resources"))+" ")])]):_vm._e(),_vm._l((_vm.resources),function(res){return [(_vm.Utils.isRenderableResource(res))?_c('a',{key:res.id,staticClass:"dropdown-item theme-hover d-flex flex-row align-items-center",class:{ active: _vm.pickedResource && res.id == _vm.pickedResource.id },on:{"click":function($event){_vm.pickedResource = res}}},[_c('i',{staticClass:"fa-lg",class:{
								[_vm.pickedResource && res.id == _vm.pickedResource.id ? 'fas' : 'far']: true,
								[_vm.fs.mimeTypeIcon(res.mime_type)]: true,
							}}),_c('span',{staticClass:"flex"},[_vm._v(_vm._s(res.name))])]):_vm._e(),(
							!_vm.Utils.isRenderableResource(res) &&
							!_vm.user.role.prevent_resource_download &&
							res.type != 3 &&
							res.type != 4
						)?_c('a',{key:res.id,staticClass:"dropdown-item theme-hover d-flex flex-row align-items-center",class:{ active: _vm.pickedResource && res.id == _vm.pickedResource.id },on:{"click":function($event){return _vm.downloadResource(res)}}},[_c('i',{staticClass:"fa-lg",class:{
								[_vm.pickedResource && res.id == _vm.pickedResource.id ? 'fas' : 'far']: true,
								[_vm.fs.mimeTypeIcon(res.mime_type)]: true,
							}}),_c('div',{staticClass:"flex d-flex flex-column"},[_c('div',[_c('span',{staticClass:"flex"},[_vm._v(_vm._s(res.name))])]),_c('div',{staticClass:"text-xxs text-muted mb-1",staticStyle:{"line-height":"0.8"}},[_vm._v(" "+_vm._s(_vm.$t("ExternalResource.download_only"))+" ")])]),_c('i',{staticClass:"far ml-3 fa-lg fa-download text-extra-muted float-right",staticStyle:{"margin-right":"-16px"}})]):_vm._e(),(!_vm.Utils.isRenderableResource(res) && _vm.user.role.prevent_resource_download)?_c('div',{key:res.id,staticClass:"dropdown-item"},[_c('div',[_c('i',{staticClass:"fa-lg",class:{
									[_vm.pickedResource && res.id == _vm.pickedResource.id ? 'fas' : 'far']: true,
									[_vm.fs.mimeTypeIcon(res.mime_type)]: true,
								}}),_c('span',{staticClass:"flex"},[_vm._v(_vm._s(res.name))])]),_c('div',{staticClass:"text-xxs text-muted"},[_vm._v(" "+_vm._s(_vm.$t("ExternalResource.cannot_render_or_download"))+" ")])]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"horizontal-divider"}),(!_vm.user.role.prevent_resource_download && !(_vm.pickedResource && _vm.pickedResource.type == 3))?[_c('div',{staticClass:"horizontal-divider ml-auto"}),(_vm.pickedResource.type != 4)?_c('a',{staticClass:"header-button",on:{"click":function($event){return _vm.downloadResource(_vm.pickedResource)}}},[_vm._v(" "+_vm._s(_vm.$t("ExternalResource.download"))),_c('i',{staticClass:"ml-2 fas fa-download"})]):_vm._e(),(_vm.pickedResource.type == 4)?_c('a',{staticClass:"header-button",attrs:{"href":_vm.pickedResource.path,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("ExternalResource.download"))),_c('i',{staticClass:"ml-2 fas fa-download"})]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"h-100 w-100 text-center scroll-y",attrs:{"id":"resources-container"}},[(_vm.pickedResource)?_c('div',{staticClass:"resources-container my-3 mx-1"},[_c('resource',{attrs:{"resource":_vm.pickedResource,"item":_vm.item,"user":_vm.user,"directRenderIFrame":true}})],1):_c('div',[_c('h2',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("ExternalResource.invalid_resource")))])])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }