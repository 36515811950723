<style scoped>
/* Loading icons css now defined in global scope (app.css) so that v-spinner directive can access it too */
</style>

<script>
import vSelect from "vue-select";

export default {
	name: "Loading",
	props: ["type"],

	data() {
		return {};
	},
	created() {},
	watch: {},

	render(h) {
		switch (this.type) {
			case "icon":
				return this.loadingIcon(h);
			case "header":
				return <div>Loading...&nbsp;{this.loadingIcon(h)}</div>;
			case "inline-header":
				return <span>Loading...&nbsp;{this.loadingIcon(h)}</span>;
			case "table":
				return <div class="text-center my-2">Loading...&nbsp;{this.loadingIcon(h)}</div>;
			case "large":
				return this.loadingLarge(h);
			case "page":
				return <div class="text-center my-4">{this.loadingLarge(h)}</div>;
			case "input":
				return <div class="text-center my-2">Loading...&nbsp;{this.loadingIcon(h)}</div>;
		}
	},
	methods: {
		loadingIcon(h) {
			return <div class="mz-loading-icon" />;
			//return <i class="far fa-spinner-third fa-spin" />;
		},
		loadingLarge(h) {
			return <div class="mz-loading-large">{this.loadingIcon(h)}</div>;
		},
	},
};
</script>
