//Driver for normal scoring (1st/2nd) Reads

import axios from "axios";

export default {
	icon: "done_all",
	name: "Resolution",
	chooseKeepScore: true,
	showScores(item) {
		return !item.resolution_blind;
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: true,

	getNext(ctx) {
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		var promise = axios.get(`/v1/resolution/${sid}/${iid}`);
		promise.then(function(resp) {
			console.log("ResolutionSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},

	submitScore(score, response, keep, forcedRepeatScore) {
		score.response_id = response.id;

		var promise = axios.post("/v1/legacy/submit", score, {
			params: { keep: keep, r: forcedRepeatScore }
		});
		promise.then(function(resp) {
			console.log("ResolutionlSD Submitted");
		});
		return promise;
	}
};
