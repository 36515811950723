<template>
	<h5>
		<arrow-select
			@back="weekBack"
			@forward="weekForward"
			:backText="$t('tooltip.previous_week')"
			:forwardText="$t('tooltip.next_week')"
			showBack
			:showForward="hasWeekForward"
		/>
		<span>{{ displayWeekRange }}</span>
	</h5>
</template>

<script>
import moment from "moment";
import ArrowSelect from "./ArrowSelect";

import Utils from "@/services/Utils";

export default {
	name: "WeekSelect",

	components: {
		ArrowSelect,
	},

	data() {
		return {
			selectedTime: moment(),
		};
	},

	created() {
		this.emitTimeSpan();
	},

	computed: {
		start() {
			return moment(this.selectedTime).startOf("week");
		},

		end() {
			return moment(this.selectedTime).endOf("week");
		},

		hasWeekForward() {
			return this.selectedTime.isBefore(moment(), "week");
		},

		displayWeekRange() {
			const { start, end } = this;
			return Utils.displayWeekRange(start, end);
		},
	},

	methods: {
		emitTimeSpan() {
			const { selectedTime, start, end } = this;
			this.$emit("newTimeSpan", { selectedTime, start, end });
		},

		weekBack() {
			this.selectedTime = moment(this.selectedTime).subtract(1, "weeks");
			this.emitTimeSpan();
		},

		weekForward() {
			this.selectedTime = moment(this.selectedTime).add(1, "weeks");
			this.emitTimeSpan();
		},
	},
};
</script>
