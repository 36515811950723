<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="project.loaded == 0" />
				<div v-if="project.loaded != 0" class="navbar-text nav-title flex" id="pageTitle">
					{{ project.name }}
				</div>
			</div>
		</template>

		<div class="h-100" v-if="project.loaded != 0">
			<div class="d-sm-flex b-t h-100">
				<div class="w w-auto-xs light bg b-r">
					<div class="py-3">
						<div class="nav-active-border left b-primary">
							<ul class="nav flex-column nav-sm">
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 1, show: tab == 1 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-1"
									>
										{{ $t("ProjectEdit.Details.title") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('details') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 2, show: tab == 2 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-2"
									>
										{{ $t("ProjectEdit.ScoringAvailability.title") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('avail') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 3, show: tab == 3 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-3"
									>
										{{ $t("ProjectEdit.Scheduling.title") }}
										<i class="valid-hint text fa fa-angle-left"></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 4, show: tab == 4 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-4"
									>
										{{ $t("ProjectEdit.Sidebar.backreading") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('avail') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										v-if="user.client.iea_integration"
										class="nav-link"
										:class="{ active: tab == 5, show: tab == 5 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-5"
									>
										{{ $t("ProjectEdit.Sidebar.iea_config") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('iea') }"
										></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<edit-pane :cancel="loadData" :save="saveProject" :dirty="dirty" :valid="valid">
					<div class="tab-content pos-rlt">
						<!-- Project Details -->
						<project-edit-details
							id="tab-1"
							:project="project"
							:valid="valid"
							:defaultTab="tab == 1"
						></project-edit-details>

						<!-- Scoring Setup -->
						<project-edit-avail
							id="tab-2"
							:project="project"
							:defaultTab="tab == 2"
							:timezone="client.timezone"
						></project-edit-avail>

						<!-- Scoring Setup -->
						<project-edit-scheduling
							id="tab-3"
							:project="project"
							:defaultTab="tab == 3"
							:client="client"
						></project-edit-scheduling>

						<!-- Backreading -->
						<project-edit-backreading
							id="tab-4"
							:project="project"
							:defaultTab="tab == 4"
							:client="client"
						></project-edit-backreading>

						<project-edit-iea
							id="tab-5"
							:project="project"
							:valid="valid"
							:defaultTab="tab == 5"
							:client="client"
							:items="items"
						></project-edit-iea>
					</div>
				</edit-pane>
				<save-optional-modal
					:dirty="dirty"
					:valid="valid"
					:save="saveProject"
					:next="saveOptNext"
					:cancel="saveOptCancel"
					objectText="Project"
					:objectName="project.name"
					actionText="leave the page"
					v-model="saveOptModal"
				/>
			</div>
		</div>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}
.vali-hint {
	color: transparent;
	transition: color 0.3s;
	font-size: 1rem;
}
.invalid {
	color: red;
}
</style>

<script>
import ProjectEditDetails from "@/components/project/ProjectEditDetails";
import ProjectEditAvail from "@/components/project/ProjectEditAvail";
import ProjectEditScheduling from "@/components/project/ProjectEditScheduling";
import ProjectEditBackreading from "@/components/project/ProjectEditBackreading";
import ProjectEditIea from "@/components/project/ProjectEditIea";
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import ProjectService from "@/services/ProjectService";
import TenantService from "@/services/TenantService";
import ValidationService from "@/services/ValidationService";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import BB from "bluebird";

var moment = require("moment-timezone");
const dateFormat = "MMMM DD, YYYY";
const timeFormat = "h:mm A";

export default {
	name: "ProjectEdit",

	props: ["user", "params", "query"],

	components: {
		ProjectEditDetails,
		ProjectEditAvail,
		ProjectEditScheduling,
		ProjectEditBackreading,
		ProjectEditIea,
		EditPane,
		SaveOptionalModal,
	},

	data() {
		return {
			fs: fs,
			tab: 1,
			project: {
				time_limits: [],
				loaded: 0,
			},
			client: null,
			dirty: false,
			valid: {},

			items: [],

			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	computed: {},

	created() {
		this.loadData();
		this.initValidation();
		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {
		"project.name"() {
			if (this.autoRef) {
				let genRef = fs.toGoodRefID(this.project.name);
				if (this.project.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.project.ref_id = genRef;
				}
			}
		},
		"project.ref_id"() {
			this.checkAutoRef();
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: this.$i18n.t("ProjectEdit.ScoringAvailability.error.no_name"),
					func: () => {
						return this.project && this.project.name && this.project.name != "";
					},
				},
				start_date: {
					group: "avail",
					errorMsg: this.$i18n.t("ProjectEdit.ScoringAvailability.error.no_start_date"),
					func: () => {
						console.log(
							"start_date",
							this.project && (!this.project.enforce_dates || this.project.scoring_start)
						);
						return this.project && (!this.project.enforce_dates || this.project.scoring_start);
					},
				},
				end_date: {
					group: "avail",
					errorMsg: this.$i18n.t("ProjectEdit.ScoringAvailability.error.no_end_date"),
					func: () => {
						return this.project && (!this.project.enforce_dates || this.project.scoring_end);
					},
				},
				date_seq: {
					group: "avail",
					errorMsg: this.$i18n.t("ProjectEdit.ScoringAvailability.error.start_date_after_end"),
					func: () => {
						return (
							this.project &&
							(!this.project.enforce_dates ||
								!(this.project.scoring_start && this.project.scoring_end) ||
								moment(this.project.scoring_end, dateFormat).isAfter(
									moment(this.project.scoring_start, dateFormat)
								))
						);
					},
				},
				start_time: {
					group: "avail",
					errorMsg: this.$i18n.t("ProjectEdit.ScoringAvailability.error.start_time_each_day"),
					func: () => {
						if (!this.project) {
							return false;
						}

						if (!this.project.times.all.disabled) {
							return this.project.times.all.start;
						} else {
							_.each(this.project.times, (limit, day) => {
								if (day != "all" && limit.enabled) {
									if (!limit.start) {
										return false;
									}
								}
							});
							return true;
						}
					},
				},
				end_time: {
					group: "avail",
					errorMsg: this.$i18n.t("ProjectEdit.ScoringAvailability.error.end_time_each_day"),
					func: () => {
						if (!this.project) {
							return false;
						}

						if (!this.project.enforce_times) {
							return true;
						}

						if (!this.project.times.all.disabled) {
							return this.project.times.all.end;
						} else {
							_.each(this.project.times, (limit, day) => {
								if (day != "all" && limit.enabled) {
									if (!limit.end) {
										return false;
									}
								}
							});
							return true;
						}
					},
				},
				time_seq: {
					group: "avail",
					errorMsg: this.$i18n.t("ProjectEdit.ScoringAvailability.error.start_time_after_end"),
					func: () => {
						if (!this.project) {
							return false;
						}

						if (!this.project.enforce_times) {
							return true;
						}

						if (!this.project.times.all.disabled) {
							return moment(this.project.times.all.end, timeFormat).isAfter(
								moment(this.project.times.all.start, timeFormat)
							);
						} else {
							_.each(this.project.times, (limit, day) => {
								if (day != "all" && limit.enabled) {
									if (!moment(limit.end, timeFormat).isAfter(moment(limit.start, timeFormat))) {
										return false;
									}
								}
							});
							return true;
						}
					},
				},
				admin_name: {
					group: "iea",
					errorMsg: "You must choose a valid IEA admin name",
					func: () => {
						return !this.project.iea_project || this.project.iea_project.projectId != "";
					},
				},
				admin_name_unique: {
					group: "iea",
					errorMsg: "This admin name is already in use",
					func: () => {
						if (!this.project.iea_project) {
							return true;
						}
						if (!this.project.iea_project || this.project.iea_project.projectId != "") {
							return true;
						}
						if (!this.admins) {
							return true;
						}
						let existing = _.find(this.admins, { projectId: this.admin.projectId });
						return !existing;
					},
				},
			});
		},

		blankProject() {
			return {
				name: this.$i18n.t("ProjectSetup.new_project"),
				avail_times: [],
				shifts: [],
				timezone: "America/New_York",
				scoring_start: null,
				scoring_end: null,
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"project",
				(newc, old) => {
					console.log(newc, old);
					console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;
			var loaded = this.project.loaded;
			loaded++;

			if (this.params.id != "new") {
				return BB.props({
					project: ProjectService.getProject(this.params.id).catch((e) => {
						console.error(e);
						notie.error(this.$i18n.t("notie.project_not_found"), e);
						this.$router.replace("/projects/new");
					}),
					client: TenantService.getClient().catch((e) => {
						console.error(e);
						notie.error("Failed to get client", e);
					}),
					items: ProjectService.getItemsForProject(this.params.id).catch((e) => {
						console.error(e);
						notie.error("Failed to get items assigned to project", e);
					}),
				}).then((resps) => {
					// project
					this.project = resps.project.data;
					this.project.loaded = loaded;
					if (!this.project.shifts) {
						this.project.shifts = [];
					}
					this.project.initial_iea_project = this.project.iea_project;

					// client
					this.client = resps.client.data;
					if (!this.client.timezone) {
						this.$set(this.client, "timezone", "America/New_York");
					}
					this.denormalizeTimes();

					// items
					this.items = resps.items.data;

					this.watchChanges();
				});
			} else {
				TenantService.getClient().then((resp) => {
					this.project = this.blankProject();
					this.project.loaded = loaded;
					this.project.name = this.$i18n.t("ProjectSetup.new_project");
					this.project.ref_id = "new_project";

					this.client = resp.data;
					if (!this.client.timezone) {
						this.$set(this.client, "timezone", "America/New_York");
					}
					this.denormalizeTimes();
					this.watchChanges();
					this.checkAutoRef();
				});
			}
		},

		checkAutoRef() {
			let genRef = fs.toGoodRefID(this.project.name);
			this.autoRef = this.project.ref_id.toLowerCase() == genRef.toLowerCase();
		},

		//Read availability times from server format into a Vue-model-friendly format
		denormalizeTimes() {
			this.$set(this.project, "times", {
				all: { disabled: false, start: null, end: null },
				mon: { enabled: false, start: null, end: null },
				tue: { enabled: false, start: null, end: null },
				wed: { enabled: false, start: null, end: null },
				thu: { enabled: false, start: null, end: null },
				fri: { enabled: false, start: null, end: null },
				sat: { enabled: false, start: null, end: null },
				sun: { enabled: false, start: null, end: null },
			});

			_.each(this.project.avail_times, (avail_time) => {
				let abr_day = fs.intToDay(avail_time.day, true);
				abr_day = this.convertLocaleToEnglish(this.client.locale, abr_day);
				if (avail_time.day != 7) {
					this.project.times[abr_day].enabled = true;
					this.project.times.all.disabled = true;
				}
				this.project.times[abr_day].start = avail_time.start;
				this.project.times[abr_day].end = avail_time.end;
			});

			//Define defaults if none exist
			if (!this.project.scoring_start) {
				this.project.scoring_start = moment().format(dateFormat);
			}
			if (!this.project.scoring_end) {
				this.project.scoring_end = moment().add(1, "day").format(dateFormat);
			}

			_.each(this.project.times, (time) => {
				if (!time.start) {
					time.start = moment().hour(8).minute(0).second(0).format(timeFormat);
				}
				if (!time.end) {
					time.end = moment().hour(18).minute(0).second(0).format(timeFormat);
				}
			});
		},

		//Convert availability times from client format to server format
		normalizeTimes() {
			if (!this.project.times.all.disabled) {
				let all = this.project.times.all;
				this.project.avail_times = [
					{
						day: 7,
						start: all.start,
						end: all.end,
					},
				];
			} else {
				this.project.avail_times = [];
				_.each(this.project.times, (limit, day) => {
					if (day != "all" && limit.enabled) {
						day = this.convertDayToLocale(this.client.locale, day);
						let avail_time = {
							day: fs.dayToInt(day),
							start: limit.start,
							end: limit.end,
						};

						this.project.avail_times.push(avail_time);
					}
				});
			}
		},

		sortAndExpandShifts() {
			let shifts = this.project.shifts;
			let everyDayShifts = _.remove(shifts, { weekday: -2 });
			if (everyDayShifts.length > 0) {
				let days = [];
				if (this.project.enforce_times) {
					let everyDay = _.find(project.avail_times, { day: 7 });
					if (everyDay) {
						days = [0, 1, 2, 3, 4, 5, 6];
					} else {
						for (let i = 0; i <= 6; i++) {
							let day = _.find(project.avail_times, { day: i });
							if (day) {
								days.push(i);
							}
						}
					}
				} else {
					days = [0, 1, 2, 3, 4, 5, 6];
				}

				_.each(everyDayShifts, (shift) => {
					_.each(days, (weekday) => {
						shifts.push({
							id: Utils.generateUUID(),
							start: shift.start,
							end: shift.end,
							weekday: weekday,
						});
					});
				});
			}

			// shifts = _.sortBy(shifts, "weekday", (s) => {
			// 	return Utils.minutesFromMidnight(s.start);
			// });

			// this.project.shifts = shifts;
		},

		saveProject() {
			this.normalizeTimes();
			this.sortAndExpandShifts();
			return ProjectService.saveProject(this.project)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.project_saved"));
					if (this.project.id != resp.data.id) {
						this.$router.replace("/projects/" + resp.data.id);
					}
					this.loadData();
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.save_project_fail"), err);
				});
		},

		canSave() {
			return this.dirty;
		},

		convertDayToLocale(tenantLocale, day) {
			if (tenantLocale == "fr" || this.user.locale == "fr") {
				if (day == "mon") {
					day = "lun";
				}
				if (day == "tue") {
					day = "mar";
				}
				if (day == "wed") {
					day = "mer";
				}
				if (day == "thu") {
					day = "jeu";
				}
				if (day == "fri") {
					day = "ven";
				}
				if (day == "sat") {
					day = "sam";
				}
				if (day == "sun") {
					day = "dim";
				}
			}
			return day;
		},

		convertLocaleToEnglish(tenantLocale, abr_day) {
			if (tenantLocale == "fr" || this.user.locale == "fr") {
				if (abr_day == "Tous") {
					abr_day = "all";
				}
				if (abr_day == "lun") {
					abr_day = "mon";
				}
				if (abr_day == "mar") {
					abr_day = "tue";
				}
				if (abr_day == "mer") {
					abr_day = "wed";
				}
				if (abr_day == "jeu") {
					abr_day = "thu";
				}
				if (abr_day == "ven") {
					abr_day = "fri";
				}
				if (abr_day == "sam") {
					abr_day = "sat";
				}
				if (abr_day == "dim") {
					abr_day = "sun";
				}
			}
			return abr_day;
		},
	},
};
</script>
