<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a class="navbar-brand flex">{{ $t("RFReport.title") }}</a>
				<div
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					@click="getDataRefresh"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.refresh')"
				>
					<i class="far fa-lg fa-sync-alt" :class="{ 'fa-spin': refreshing }"></i>
				</div>
				<div
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					@click="exportScores"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.export_scores')"
				>
					<i class="fas fa-lg fa-download"></i>
				</div>
			</div>
		</template>

		<div class="container-fluid h-100">
			<div class="row no-gutters white h-100">
				<div class="col-sm-12 white nav-active-bg h-100 d-flex flex-column">
					<div class="row">
						<div class="col-sm-5 clearfix">
							<div class="col-sm-12 d-flex align-items-center pb-3 pt-3">
								<label class="w-80">{{ $t("RFReport.section") }}</label>
								<div class="flex">
									<config-select
										:nullText="$t('data_description.no_sections')"
										:options="sections"
										v-model="selectedSection"
										:mustBeFilled="true"
										useInactive
									></config-select>
								</div>
							</div>
							<div class="col-sm-12 d-flex align-items-center pb-3">
								<label class="w-80">{{ $t("RFReport.item") }}</label>
								<div class="flex">
									<config-select
										:nullText="$t('data_description.no_items')"
										:options="items"
										v-model="selectedItem"
										:mustBeFilled="true"
									></config-select>
								</div>
							</div>
							<div class="col-sm-12 d-flex align-items-center pb-3">
								<label class="w-80">{{ $t("RFReport.rf_set") }}</label>
								<div class="flex">
									<config-select
										sortBy="sequence"
										:nullText="$t('data_description.no_sets_configured')"
										:options="rfSets"
										v-model="selectedRFSet"
										:mustBeFilled="true"
									></config-select>
								</div>
							</div>
							<div class="col-sm-12 d-flex align-items-center pb-3">
								<label class="w-80">{{ $t("RFReport.team") }}</label>
								<div class="flex">
									<config-select
										:nullOption="true"
										:options="teams"
										v-model="selectedTeam"
									></config-select>
								</div>
							</div>
						</div>
						<div class="col-sm-6 clearfix d-flex flex-column">
							<div class="flex d-flex flex-column card my-3">
								<div class="d-flex flex-row p-3">
									<div class="flex text-center">
										<h4>{{ totalCount }}</h4>
										<span class="text-muted">{{ $t("RFReport.total") }}</span>
									</div>
									<div class="flex text-center">
										<h4>{{ consensusCount }}</h4>
										<span class="text-muted">{{ $t("RFReport.consensus") }}</span>
									</div>
									<div class="flex text-center">
										<h4>{{ trueCount }}</h4>
										<span class="text-muted">{{ $t("RFReport.true_score") }}</span>
									</div>
								</div>
								<hr class="mt-0 mb-2" />
								<div class="flex d-flex flex-row flex-wrap pl-2">
									<div v-for="tag in allTags" :key="tag.id" class="btn-tag px-2 py-0 mr-2 mb-2">
										<span @click="selectFilterTag(tag.id)">
											<i
												class="fa-tag"
												:class="{ [activeFilterTag(tag.id) ? 'fas' : 'far']: true }"
											></i>
											{{ getTagCount(tag.id) }} -
											{{ tag.label }}
										</span>
										<b-button-close
											v-if="tag.isCustom"
											@click="removeCustomTag(tag.id)"
											class="py-1 pl-1"
											text-variant="danger"
										></b-button-close>
										<!-- <i class="fas fa-times" style="font-size: 1rem"></i> -->
									</div>
									<div @click="selectFilterTag()" class="btn-tag px-2 py-0 mr-2 mb-2">
										<i class="fas fa-times" style="font-size: 1rem"></i>
									</div>
									<div @click="newRfTagModalOpen = true" class="btn-tag px-2 py-0 mr-2 mb-2">
										<i class="fas fa-plus" style="font-size: 1rem"></i>
										{{ $t("RFReport.new_custom_tag") }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="overlap-margin scroll-y">
						<div>
							<div class="d-flex flex-row">
								<table class="table b-table" v-if="rfData" style="max-width: 100px">
									<thead>
										<tr>
											<th class="p-custom px-1"></th>
											<th
												class="sorting"
												:class="{
													sorting_asc: sorting == 'sequence' && sortDir == 'asc',
													sorting_desc: sorting == 'sequence' && sortDir == 'desc',
												}"
												@click="sortBy('sequence')"
											>
												{{ $t("fields.response") }}
											</th>
											<th
												class="nowrap sorting b-r"
												:class="{
													sorting_asc: sorting == 'true_score' && sortDir == 'asc',
													sorting_desc: sorting == 'true_score' && sortDir == 'desc',
												}"
												@click="sortBy('true_score')"
												colspan="2"
											>
												{{ $t("fields.true_score") }}
											</th>
											<th
												class="nowrap sorting"
												:class="{
													sorting_asc: sorting == 'consensus' && sortDir == 'asc',
													sorting_desc: sorting == 'consensus' && sortDir == 'desc',
												}"
												@click="sortBy('consensus')"
											>
												{{ $t("fields.consensus") }}
											</th>
											<th
												class="text-center nowrap sorting"
												:class="{
													sorting_asc: sorting == 'agreement' && sortDir == 'asc',
													sorting_desc: sorting == 'agreement' && sortDir == 'desc',
												}"
												@click="sortBy('agreement')"
											>
												<span class="pr-1">{{ $t("fields.agree") }}</span>
											</th>
											<th
												class="text-center nowrap sorting"
												:class="{
													sorting_asc: sorting == 'num_scores' && sortDir == 'asc',
													sorting_desc: sorting == 'num_scores' && sortDir == 'desc',
												}"
												@click="sortBy('num_scores')"
											>
												<span class="pr-1">{{ $t("special_chars.pound") }}</span>
											</th>
										</tr>
									</thead>
									<tbody v-if="rfData && rfData.rows">
										<tr v-for="resp in sortedResponses" :key="resp.id">
											<td class="p-custom px-1"></td>
											<td class="clickable fix-h nowrap p-custom py-0 v-mid">
												<div class="d-flex flex-row align-items-center">
													<a
														class="btn-subtle"
														data-toggle="modal"
														data-target="#resetResponseModal"
														@click="setResetResponseId(resp)"
														v-tippy
														:title="$t('RFSetup.reset_rangefinding_response')"
													>
														<i class="far fa-strikethrough faw"></i>
													</a>
													&nbsp;
													<qc-tag-select
														:tags="allTags"
														compact
														v-model="resp.qc_tags"
														:customTags.sync="resp.custom_tags"
														@added="
															(qcTags, customTags) =>
																addQcTags(resp.id, qcTags, customTags)
														"
														@removed="removeQcTags(resp.id, $event)"
													/>
													<div
														@click="goToReport(resp.id, selectedRFSet.id)"
														class="ml-1 w-100"
													>
														<div>{{ resp.ref_id }}</div>
														<div class="d-flex flex-row text-tiny text-muted w-100">
															<span class="mr-1">{{
																fs.qcTagAbrs(
																	(resp.qc_tags || []).concat(resp.custom_tags || [])
																)
															}}</span>
															<a
																v-if="resp.true_score"
																class="ml-auto btn-subtle"
																@click.stop.prevent="removeTrueScore(resp)"
																v-tippy
																:title="$t('RFReport.remove_true_score')"
															>
																<i class="text-danger fas fa-times" />
															</a>
														</div>
													</div>
												</div>
											</td>
											<td
												v-if="getScore(resp)"
												@click="goToReport(resp.id, selectedRFSet.id)"
												class="clickable fix-h min"
											>
												<inline-score
													:score="getScore(resp)"
													:rubric="selectedItem.rubric"
													:type="20"
													tooltip
												></inline-score>
											</td>
											<td v-if="!getScore(resp)" click class="fix-h min text-center">-</td>
											<td class="b-r p-custom p-0 pr-2 v-mid">
												<final-annotation-button
													v-if="getScore(resp)"
													:response="resp"
												></final-annotation-button>
											</td>
											<td
												v-if="resp.consensus_score && resp.full_consensus"
												@click="applyTrueScore(resp, resp.consensus_score)"
												class="clickable fix-h min"
											>
												<inline-score
													:score="resp.consensus_score"
													:rubric="selectedItem.rubric"
													:type="21"
													tooltip
												></inline-score>
											</td>
											<td
												v-if="resp.consensus_score && !resp.full_consensus"
												@click="goToReport(resp.id, selectedRFSet.id)"
												class="clickable fix-h min"
											>
												<inline-score
													:score="resp.consensus_score"
													:rubric="selectedItem.rubric"
													:type="21"
													tooltip
												></inline-score>
											</td>
											<td v-if="!resp.consensus_score" click class="fix-h min text-center">-</td>
											<td class="text-center">
												{{ fs.fixedPercent1d(resp.agreement) }}
											</td>
											<td class="text-center b-r">{{ resp.num_scores }}</td>
										</tr>
									</tbody>
								</table>
								<div class="center-wrapper flex">
									<table class="table mb-0" v-if="rfData">
										<thead class="center-table">
											<tr>
												<th
													class="min"
													style="white-space: nowrap"
													v-for="row in rfData.rows"
													:key="row.user.id"
												>
													<span
														v-if="row.user"
														v-tippy
														:title="row.user.scorer_id != '' ? row.user.full_name : null"
														>{{ fs.scorerID(row.user) }}</span
													>
												</th>
												<th class="p-custom px-1" style="max-width: 1px"></th>
											</tr>
										</thead>
										<tbody class="center-table">
											<tr v-for="resp in sortedResponses" :key="resp.id">
												<template v-for="row in rfData.rows">
													<td
														v-if="getScore(resp, row)"
														@click="applyTrueScore(resp, getScore(resp, row))"
														:key="row.user.id"
														class="clickable fix-h min"
													>
														<inline-score
															:score="getScore(resp, row)"
															:rubric="selectedItem.rubric"
															:compareScore="getScore(resp)"
															:type="15"
															tooltip
														></inline-score>
													</td>
													<td
														v-if="!getScore(resp, row)"
														:key="row.user.id"
														class="fix-h min"
													>
														-
													</td>
												</template>
												<td class="p-custom px-1" style="max-width: 1px"></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<!-- </b-card> -->
				</div>
			</div>
		</div>

		<div class="modal" id="resetResponseModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content" v-if="responseToBeReset">
					<div class="modal-header">
						<h5>{{ $t("RFSetup.reset_rangefinding_response") }}</h5>
					</div>
					<div class="modal-body">
						<div class="py-3 px-2 mx-3 border modal-details-box">
							<div v-if="selectedSection" class="col-12">
								<span class="_600 mr-1">{{ $t("fields.section") }}:</span>
								<span>{{ selectedSection.name }}</span>
							</div>
							<div v-if="selectedItem" class="col-12">
								<span class="_600 mr-1">{{ $t("fields.item") }}:</span>
								<span>{{ selectedItem.name }}</span>
							</div>
							<div v-if="responseToBeReset" class="col-12">
								<span class="_600 mr-1">{{ $t("fields.response") }}:</span>
								<span>{{ responseToBeReset.ref_id }}</span>
							</div>
						</div>
					</div>
					<div class="col-12 mt-4">{{ $t("RFSetup.reset_response_confirm") }}</div>
					<div class="modal-footer">
						<button class="btn primary btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button @click="resetResponse(responseToBeReset)" class="btn btn-flat red">
							{{ $t("RFSetup.reset_response") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<new-rf-tag-modal
			v-if="newRfTagModalOpen"
			@close="newRfTagModalOpen = false"
			:client="user.client"
		></new-rf-tag-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tippy-tooltip.popover-theme {
	max-width: auto;
}

.center-table > tr > td,
.center-table > tr > th {
	text-align: center;
}

.center-wrapper {
	overflow: auto;
	/* width: calc(100vw - 650px); */
}

.card-header {
	background-color: white;
}

.min {
	/* width: 75px; */
	min-width: 50px;
}

.fix-h {
	line-height: 24px;
}

.clickable:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.075);
}

.text-tiny {
	font-size: 0.6rem;
}

.btn-tag {
	cursor: pointer;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	height: 33px;
	line-height: 33px;
}

.btn-tag:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

/* table {
	border-collapse: separate;
	border-spacing: 0;
	border-top: 1px solid grey;
} */

/* td,
th {
	margin: 0;
	border: 1px solid grey;
	white-space: nowrap;
	border-top-width: 0px;
	text-align: center;
} */

.tableWrapper {
	width: 80%;
	overflow-x: scroll;
	margin-left: 100px;
	margin-right: -100px;
	overflow-y: visible;
	padding: 0;
}

.inactive {
	background-color: rgba(31, 40, 40, 0.079);
}

.long {
	background: yellow;
	letter-spacing: 1em;
}

a.btn-subtle {
	border-radius: 0.35rem;
	padding-left: 3px;
	padding-right: 3px;
}

a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.overlap-margin {
	margin-left: -15px;
	margin-right: -15px;
}

.btn-topnav {
	margin-top: -8px;
	margin-bottom: -8px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}

.btn-topnav:last-child {
	margin-right: -16px;
	margin-left: -16px;
}

.btn-topnav:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.btn-topnav a {
	line-height: 56px;
}

.topnav-margins {
	margin-top: -8px;
	margin-bottom: -8px;
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCConfigService from "@/services/QCConfigService";
import ConfigService from "@/services/ConfigService";
import FileService from "@/services/FileService";
import TenantService from "@/services/TenantService";
import ConfigSelect from "@/components/ConfigSelect";
import QcTagSelect from "@/components/QcTagSelect";
import InlineScore from "../../components/InlineScore";
import FinalAnnotationButton from "@/components/range_finding/FinalAnnotationButton.vue";
import NewRfTagModal from "@/components/QC/RFReport/NewRfTagModal";
import Notie from "@/services/NotieService";
import draggable from "vuedraggable";
import $ from "jquery";
import Score from "../../components/Score";
import store from "../../services/Store";
import fs from "../../services/FormatService";
import QCService from "../../services/QCService";

export default {
	name: "RFReport",
	data() {
		var filterTags = store.getDefault(this, "rf.filterTags", []);
		var qc_type = 5;

		return {
			fs: fs,
			user: null,
			sections: [],
			items: [],
			scores: {},
			filterTags: filterTags,
			sorting: "sequence",
			sortDir: "asc",
			qcTags: null,

			qc_type: qc_type,

			selectedSection: null,
			selectedItem: null,

			editSet: null,
			setToDelete: null,

			rfSets: [],
			teams: [],
			selectedTeam: null,

			selectedRFSet: null,
			rfData: null,

			feedback: null,

			submitting: false,
			refreshing: false,

			responseToBeReset: null,

			newRfTagModalOpen: false,
		};
	},
	components: {
		ConfigSelect,
		QcTagSelect,
		draggable,
		Score,
		InlineScore,
		FinalAnnotationButton,
		NewRfTagModal,
	},
	created() {
		this.sorting = store.getDefault(this, "rf.sorting", "sequence");
		this.sortDir = store.getDefault(this, "rf.sortDir", false);
		this.qcTags = fs.qcTags();

		AuthService.getUser().then((user) => {
			this.user = user;
			let teamCall = ConfigService.listTeams;
			if (this.user.role.limit_teams) {
				teamCall = ConfigService.listUserTeams;
			}

			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			Promise.all([ConfigService.listSectionsNoPreload(ctx), teamCall()]).then((resps) => {
				this.sections = resps[0].data.sections;
				this.selectedSection = store.resolveSelectedSection(this, this.sections, "rf.selectedSection");
				if (!this.selectedSection && this.sections.length > 0) {
					this.selectedSection = this.sections[0];
				}

				if (this.selectedSection) {
					this.getItems();
				}

				this.selectedRFSet = store.resolveFromList(this, this.rfSets, "id", 0, "rf.selectedRFSet");

				this.teams = resps[1].data.teams;
				if (this.user.role.limit_teams) {
					this.teams = resps[1].data;
				}

				this.selectedTeam = store.bindTeam(this, this.teams, !this.user.role.limit_teams, "rf.selectedTeam");
			});
		});
	},
	watch: {
		selectedSection() {
			store.set(this, "rf.selectedSection", this.selectedSection ? this.selectedSection.id : null);
			this.getItems();
		},
		selectedItem(newVal) {
			store.set(this, "rf.selectedItem", this.selectedItem ? this.selectedItem.id : null);
			this.querySets();
		},
		selectedRFSet() {
			this.getData();

			if (!this.selectedRFSet) {
				return;
			}
			store.set(this, "rf.selectedRFSet", this.selectedRFSet ? this.selectedRFSet.id : null);
		},
		selectedTeam() {
			store.set(this, "rf.selectedTeam", this.selectedTeam ? this.selectedTeam.id : null);
			this.getData();
		},
		sorting() {
			store.set(this, "rf.sorting", this.sorting);
		},
		sortDir() {
			store.set(this, "rf.sortDir", this.sortDir);
		},
	},
	computed: {
		sortedResponses() {
			if (!this.rfData) {
				return [];
			}

			let sorted = this.rfData.responses;

			if (this.filterTags.length > 0) {
				sorted = sorted.filter(({ qc_tags, custom_tags }) =>
					(qc_tags || []).concat(custom_tags || []).some((tag) => this.filterTags.includes(tag))
				);
			}

			let softExclude = this.sortDir == "asc" ? Number.POSITIVE_INFINITY : -1;

			let sortObj = this.sorting;
			if (this.sorting == "true_score") {
				sortObj = function (r) {
					if (!r.true_score) {
						return softExclude;
					}

					let total = 0;
					_.each(r.true_score.trait_scores, (s) => {
						if (s.score >= 0) {
							total += s.score;
						}
					});

					return total;
				};
			}
			if (this.sorting == "consensus") {
				sortObj = function (r) {
					if (!r.consensus_score) {
						return softExclude;
					}

					let total = 0;
					_.each(r.consensus_score.trait_scores, (s) => {
						if (s.score >= 0) {
							total += s.score;
						}
					});

					return total;
				};
			}

			sorted = _.orderBy(sorted, [sortObj], [this.sortDir]);

			var sortedRFResponses = [];
			for (var data in sorted) {
				sortedRFResponses.push(sorted[data].id);
			}
			const jsonObj = [];
			sortedRFResponses.forEach((v, i) => (jsonObj[i] = v));
			store.set(this, "rf.sorted", jsonObj);
			return sorted;
		},
		totalCount() {
			if (!this.rfData) {
				return 0;
			}
			return this.rfData.responses.length;
		},
		consensusCount() {
			if (!this.rfData) {
				return 0;
			}
			let count = 0;
			_.each(this.rfData.responses, (resp) => {
				if (resp.full_consensus) {
					count++;
				}
			});
			return count;
		},
		trueCount() {
			if (!this.rfData) {
				return 0;
			}
			let count = 0;
			_.each(this.rfData.responses, (resp) => {
				if (resp.true_score) {
					count++;
				}
			});
			return count;
		},

		allTags() {
			const { qcTags, customTags } = this;
			return (qcTags || []).concat(customTags);
		},

		customTags() {
			if (!this.user) return [];
			return (this.user.client.custom_rf_tags || []).map((tagName) => ({
				label: tagName,
				abr: tagName,
				id: tagName, // should all be unique strs
				isCustom: true,
			}));
		},
	},
	methods: {
		querySets() {
			this.rfSets = [];
			if (this.selectedItem && this.selectedSection) {
				QCConfigService.listQCSets(this.selectedSection.id, this.selectedItem.id, 5).then((resp) => {
					this.rfSets = resp.data;
					this.selectedRFSet = store.resolveFromList(this, this.rfSets, "id", 0, `rf.selectedRFSet`);
					this.sortSets();
					this.getData();
				});
			}
		},
		sortSets() {
			this.rfSets = _.sortBy(this.rfSets, (s) => {
				return s.sequence;
			});
		},

		getDataRefresh() {
			this.sorting = null;
			this.getData();
		},

		getData() {
			this.refreshing = true;
			this.rfData = null;
			var teamID = null;
			if (this.selectedTeam) {
				teamID = this.selectedTeam.id;
			}
			if (this.selectedSection && this.selectedItem && this.selectedRFSet) {
				QCService.getRFStatuses(this.selectedSection.id, this.selectedItem.id, this.selectedRFSet.id, teamID)
					.then((resp) => {
						this.rfData = resp.data;
						this.rfData.responses = _.sortBy(this.rfData.responses, (d) => {
							return d.sequence;
						});

						_.each(this.rfData.rows, (row) => {
							this.scores[row.user.id] = {};
							_.each(this.rfData.responses, (resp) => {
								let score = _.find(row.qc_scores, {
									qc_response_id: resp.id,
								});
								if (score) {
									score.user = row.user;
									this.scores[row.user.id][resp.id] = score;
								}
							});
						});

						this.calculateConsensuses(this.rfData.responses, this.rfData.rows);
						this.refreshing = false;
					})
					.catch((err) => {
						console.log(err);
						Notie.error(this.$i18n.t("notie.rf_response_data_load_fail"), err);
						this.refreshing = false;
					});
			}
		},

		async getItems() {
			const { selectedSection } = this;
			if (!selectedSection) return;
			try {
				const { data } = await ConfigService.getSection(selectedSection.id);
				if (data) {
					this.items = data.items || [];
					if (this.items.length > 0) {
						this.selectedItem = this.items[0];
					}
				}
			} catch (e) {
				Notie.error("Failed to get Items", e);
			}
		},

		calculateConsensuses(resps, rows) {
			_.each(resps, (resp) => {
				let anyConsensus = false;
				let fullConsensus = true;
				let consensusSps = [];
				let agreedTraits = 0;
				let totalTraits = 0;
				let numTraits = 0;

				_.each(this.selectedItem.rubric.traits, (trait) => {
					if (trait.separator) return;
					if (trait.is_parent) return;

					numTraits++;
					let counts = {};
					let total = 0;
					_.each(rows, (row) => {
						let score = _.find(row.qc_scores, {
							qc_response_id: resp.id,
						});
						if (score) {
							total++;
							let sp = _.find(score.trait_scores, {
								trait_id: trait.id,
							});
							if (sp) {
								if (!counts[sp.score]) {
									counts[sp.score] = 0;
								}
								counts[sp.score]++;
							}
						}
					});

					let maxCount = 0;
					for (var sp in counts) {
						maxCount = Math.max(maxCount, counts[sp]);
					}
					let matched = [];
					for (var sp in counts) {
						if (counts[sp] >= maxCount) {
							matched.push({ val: sp, count: counts[sp] });
						}
					}
					if (matched.length != 1) {
						fullConsensus = false;
						consensusSps.push({
							trait_id: trait.id,
							val: "?",
							count: 0,
							total: total,
						});
					} else {
						anyConsensus = true;
						consensusSps.push({
							trait_id: trait.id,
							val: matched[0].val,
							count: matched[0].count,
							total: total,
						});
						agreedTraits += matched[0].count;
					}
					totalTraits += total;
				});

				if (anyConsensus) {
					resp.consensus_score = this.createScore(resp, consensusSps);
					resp.full_consensus = fullConsensus;
				}
				if (totalTraits > 0) {
					resp.agreement = (agreedTraits * 100) / totalTraits;
				}

				resp.num_scores = totalTraits / numTraits;
			});
		},

		createScore(resp, sps) {
			let score = {
				score_id: `cons-${resp.id}`,
				qc_response_id: resp.id,
				qc_set_id: resp.qc_set_id,
				qc_type: 5,
				trait_scores: [],
			};

			_.each(sps, (sp) => {
				let scoreVal = parseInt(sp.val);
				if (isNaN(scoreVal) || scoreVal == -1) {
					score.trait_scores.push({
						condition: sp.val,
						evidence: "",
						score: -1,
						trait_id: sp.trait_id,
					});
				} else {
					score.trait_scores.push({
						condition: "",
						evidence: "",
						score: parseInt(sp.val),
						trait_id: sp.trait_id,
					});
				}
			});

			return score;
		},

		getScore(resp, row) {
			if (row) {
				return this.scores[row.user.id][resp.id];
			} else {
				return resp.true_score;
			}
		},

		goToReport(respID, setID) {
			var sortedRFResponses = store.getDefault(this, "rf.sorted", []);
			sortedRFResponses = JSON.stringify(sortedRFResponses);
			this.$router.push(`/rf_response/${respID}?set=${setID}&sortedRFResponses=${sortedRFResponses}`);
		},

		applyFeedback(response, feedback) {
			if (!response.true_score) {
				Notie.error(this.$i18n.t("notie.no_annotation_without_true_score"));
			}

			this.submitting = true;
			QCService.submitTrueScore(response.true_score, response.id, feedback)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.annotation_applied"));
					if (feedback != null) {
						response.feedback = feedback;
					}
					this.$forceUpdate();
					this.submitting = false;
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.apply_annotation_fail"), err);
					this.submitting = false;
				});
		},

		applyTrueScore(response, score) {
			this.submitting = true;
			QCService.submitTrueScore(score, response.id)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.resolved_score_applied"));
					response.true_score = score;
					this.$forceUpdate();
					this.submitting = false;
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.apply_resolved_score_fail"), err);
					this.submitting = false;
				});
		},

		removeTrueScore(response) {
			this.submitting = true;
			QCService.removeTrueScore(response.id)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.true_score_removed"));
					response.true_score = null;
					this.$forceUpdate();
					this.submitting = false;
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.remove_true_score_fail"), err);
					this.submitting = false;
				});
		},

		addQcTags(respID, tags, customTags) {
			console.log(tags);
			QCService.applyQcTags(respID, tags, customTags)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.tag_applied"));
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.apply_tag_fail"), err);
				});
		},

		removeQcTags(respID, tags, customTags) {
			QCService.applyQcTags(respID, tags, customTags)
				.then((resp) => {
					if (tags.length == 0) {
						Notie.info(this.$i18n.t("notie.tags_removed"));
					} else {
						Notie.info(this.$i18n.t("notie.tag_removed"));
					}
				})
				.catch((err) => {
					if (tags.length == 0) {
						Notie.error(this.$i18n.t("notie.remove_tags_fail"), err);
					} else {
						Notie.error(this.$i18n.t("notie.remove_tag_fail"), err);
					}
				});
		},

		sortBy(field) {
			if (this.sorting == field) {
				this.sortDir = this.sortDir == "asc" ? "desc" : "asc";
			} else {
				this.sorting = field;
			}
		},

		getTagCount(tagID) {
			if (!this.rfData) {
				return "";
			}

			return this.rfData.responses.filter(
				({ qc_tags, custom_tags }) => (qc_tags || []).includes(tagID) || (custom_tags || []).includes(tagID)
			).length;
		},

		selectFilterTag(tagID) {
			if (!tagID) {
				this.filterTags = [];
			} else {
				let index = this.filterTags.indexOf(tagID);
				if (index >= 0) {
					this.$delete(this.filterTags, index);
				} else {
					this.filterTags.push(tagID);
				}
			}

			store.set(this, "rf.filterTags", this.filterTags);
		},

		activeFilterTag(tagID) {
			return this.filterTags.includes(tagID);
		},

		exportScores() {
			FileService.rfReportCSV(
				this.sortedResponses,
				this.rfData.rows,
				this.scores,
				this.selectedSection,
				this.selectedItem,
				this.selectedRFSet,
				this.selectedTeam
			);
		},

		resetResponse(response) {
			QCService.resetRangeFindingResponse(this.selectedSection.id, this.selectedItem.id, response.id)
				.then((resp) => {
					$("#resetResponseModal").modal("hide");
					this.responseToBeReset = null;
					this.getData();
				})
				.catch((err) => {
					console.log(err);
					Notie.error(err);
				});
		},

		setResetResponseId(response) {
			this.responseToBeReset = response;
		},

		async removeCustomTag(tagName) {
			const ok = await this.$bvModal.msgBoxConfirm(
				`"${tagName}" tag will also be removed from all QC responses`,
				{
					title: `Are you sure you want to remove the "${tagName}" tag?`,
				}
			);

			if (!ok) return;

			try {
				await TenantService.removeCustomRFTag(tagName);
				this.user.client.custom_rf_tags = this.user.client.custom_rf_tags.filter((tag) => tag !== tagName);
				this.getDataRefresh();
				Notie.info("Removed Custom Tag");
			} catch (err) {
				Notie.error("Failed to Remove Tag", err);
			}
		},
	},
};
</script>


