import axios from "axios";

export default {
	getTenants() {
		return axios.get(`/v1/admin/previewer/tenants`)
	},

	getIeaPretrainedItems(tenantID) {
		return axios.get(`/v1/admin/iea/pretrained_items/${tenantID}`)
	},

	getIeaPretrainedItemDetails(itemID) {
		return axios.get(`/v1/admin/iea/pretrained_item/${itemID}`)
	},

	getIeaTenantDetails(tenantID) {
		return axios.get(`/v1/admin/iea/tenant_details/${tenantID}`)
	},

	getIeaProjects() {
		return axios.get(`/v1/admin/iea/projects`)
	},

	createIeaProject(ieap) {
		return axios.post(`/v1/admin/iea/project`, ieap)
	},

	getTODItems(tenantID, projectID, itemID) {
		let ctx = {}
		if (projectID) {
			ctx["projectID"] = projectID
		}
		if (itemID) {
			ctx["itemID"] = itemID
		}
		return axios.get(`/v1/admin/iea/tenant/${tenantID}/tod_items`, { params: ctx })
	},

	listTODItems(ctx, tenantID, projectID, itemID) {
		if (projectID) {
			ctx["projectID"] = projectID
		}
		if (itemID) {
			ctx["itemID"] = itemID
		}
		return axios.get(`/v1/admin/iea/tenant/${tenantID}/tod_items_list`, { params: ctx })
	},

	getTODItem(tenantID, projectID, itemID) {
		return axios.get(`/v1/admin/iea/tenant/${tenantID}/tod_item/${projectID}/${itemID}`)
	},

	getTODItemQueues(tenantID, projectID, itemID) {
		return axios.get(`/v1/admin/iea/tenant/${tenantID}/tod_item_queues/${projectID}/${itemID}`)
	},

	getItemQueueStats(sectionID, itemID) {
		return axios.get(`/v1/admin/iea/tod_item_queue_stats/${sectionID}/${itemID}`)
	},

	createModelingFiles(projectId, itemId, queues) {
		let ctx = {
			"projectId": projectId,
			"itemId": itemId,
			"queues": queues
		}
		return axios.post(`/v1/admin/iea/tod/modeling/create_files`, ctx)
	},

	sendModelingRequest(tenantId, projectId, itemId, responsesFilePath, scoresFilePath, password) {
		let ctx = {
			"tenantId": tenantId,
			"projectId": projectId,
			"itemId": itemId,
			"responsesFile": responsesFilePath,
			"scoresFile": scoresFilePath,
			"password": password
		}

		return axios.post(`/v1/admin/iea/tod/modeling/send_job`, ctx)
	},
};

export class TodItemState {
	state = ""
	stateNoSpaces = ""
	detail = ""
	i18n
	stateDef
	stateDefs = [
		{
			// display: "Uninitialized"
			// OSCAR - IEA has not been informed about the existence of this item
			id: "Uninitialized",
			sentiment: -1,
			oscarOnly: true,
			showError: true,
		},
		{
			// display: "Initializing"
			// OSCAR - IEA is being 
			id: "Sent CreateTodItem",
			sentiment: 0,
			oscarOnly: true,
			showError: false,
		},
		{
			// display: "Error"
			// OSCAR - An error occurred when informing IEA about the creation of this item
			id: "Initialization Error",
			sentiment: -1,
			oscarOnly: true,
			showError: true,
		},
		{
			// display: "Started"
			// Only in fromState when toState="Prompt Creation Error" 
			// We may never have this in OSCAR?
			id: "START",
			sentiment: 0,
			oscarOnly: false,
			showError: false
		},
		{
			// display: "Initializing"
			// Only in fromState when toState="Item Configured"
			id: "Prompt Created",
			sentiment: 0,
			oscarOnly: false,
			showError: false
		},
		{
			// display: "Uninitialized"
			// Only in toState when the prompt/item had an issue being created - the field to pay attention to for the
			// error case. The item/prompt is automatically deleted from CF during this case. 
			id: "Prompt Creation Error",
			sentiment: -1,
			oscarOnly: false,
			showError: true
		},
		{
			// display: "Ready"
			// Only toState when the prompt/item are successfully configured and ready for modeling data
			// upload/initiation - the field to pay attention to for the success case after creating an item.
			id: "Item Configured",
			sentiment: 1,
			oscarOnly: false,
			showError: false,
		},
		{
			// display: "Ready"
			// Seems to happen automatically after the Item Configured state? I'm not sure
			// what distinguishes them. But it should represent that the item is ready for modeling.
			id: "END",
			sentiment: 1,
			oscarOnly: false,
			showError: false,
		},
		{
			// display: "Error"
			// An issue has occurred in the workflow process which requires a re-initiation of the TOD training process.
			// The item will still exist in these cases.
			id: "TOD Terminal Error",
			sentiment: -1,
			oscarOnly: false,
			showError: true,
		}
	]

	constructor(state, detail) {
		this.state = state
		this.stateNoSpaces = this.state.replace(/\s/g, "_")
		this.detail = detail
		this.stateDef = _.find(this.stateDefs, { id: state })
	}

	setI18n(i18n) {
		this.i18n = i18n
	}

	raw() {
		return this.state
	}

	display() {
		if (!this.stateDef) {
			return `(Unknown: ${this.state})`
		}
		if (!this.i18n) {
			return this.state
		}

		let display = this.i18n.t("IeaTodItemStates." + this.stateNoSpaces)
		return display
	}

	description() {
		if (!this.stateDef) {
			return `(Unknown: ${this.state})`
		}
		if (!this.i18n) {
			return this.state
		}

		let description = this.i18n.t("IeaTodItemStateDescriptions." + this.stateNoSpaces)
		return description
	}

	sentiment() {
		if (!this.stateDef) {
			return -2
		}

		return this.stateDef.sentiment
	}

	showError() {
		if (!this.stateDef) {
			return true
		}

		return this.stateDef.showError
	}

	error() {
		return this.detail
	}

	canModel() {
		return this.state == "Item Configured" || this.state == "END"
	}

	canResync() {
		return this.state == "Uninitialized" || this.state == "Initialization Error" || this.state == "TOD Terminal Error"
	}
}
