<template>
	<b-modal visible @hide="close" @close="close" size="md">
		<template slot="modal-header">
			<h5 class="modal-title pl-3">
				{{ title }}
			</h5>
		</template>

		<qc-rule-config :config="rule" isValidity />

		<template slot="modal-footer">
			<div class="d-flex flex-row px-3 w-100">
				<button class="btn btn-danger mr-auto" @click="close">
					{{ $t("buttons.cancel") }}
				</button>

				<button :disabled="okDisabled" class="theme-accent btn" @click="ok">
					<div v-tippy>{{ $t("buttons.ok") }}</div>
				</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import QcRuleConfig from "@/components/QcRuleConfig";

export default {
	name: "ValRuleModal",

	components: { QcRuleConfig },

	props: {
		title: String,
		rule: Object,
	},

	methods: {
		ok() {
			this.$emit("ok");
		},
		close() {
			this.$emit("close");
		},
	},

	computed: {
		okDisabled() {
			return false;
		},
	},
};
</script>

<style>
</style>