<template>
	<div class="text-center mx-4">
		<div class="card d-flex flex-column align-items-center mt-4 mx-auto shadow" style="max-width: 600px">
			<div class="card-header" style="width: 100%; background-color: rgba(0 150 136 / 100%)">
				<img src="static/OSCAR-logo-white-cropped.png" alt="." height="100" />
			</div>
			<dic class="card-body">
				<h1>Invalid Link</h1>
				<hr />
				<h4>This link is either invalid, expired, or exceeded its limited number of uses.</h4>
			</dic>
		</div>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
export default {
	name: "InvalidLink",
	data() {
		return {};
	},

	components: {},

	props: [],

	watch: {},

	computed: {},

	created() {},
	methods: {},
};
</script>


