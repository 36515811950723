<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportDaily.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportDaily.daily") }}
				</div>
			</div>
		</template>

		<div class="d-flex flex-column flex scroll-x scroll-y">
			<div class="row mx-0 flex-fixed px-3 pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.project") }}</label>
						<config-select required :options="projects" v-model="selectedProject"></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.section") }}</label>
						<config-select
							:options="sections"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.item") }}</label>
						<config-select
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
							sortBy="section_item_sequence"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.team") }}</label>
						<config-select :options="teams" :nullOption="true" v-model="selectedTeam"></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.trait") }}</label>
						<config-select :options="traits" v-model="selectedTrait" :mustBeFilled="true"></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<div class="d-flex flex-row">
							<label>{{ $t("ReportDaily.user") }}</label>
							<label class="ml-auto md-check">
								<input type="checkbox" v-model="excludeUser" />
								<i class="theme-accent"></i>
								<span class="mr-2">{{ $t("fields.exclude") }}</span>
							</label>
						</div>
						<config-user-search v-model="selectedUser" :clientID="user.client.id" placeholder="Any" />
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("ReportDaily.from_date") }}
							<user-timezone />
						</label>
						<config-date v-model="fromDate" :config="{ showClear: true }"></config-date>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("ReportDaily.to_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="toDate"
							:config="{ showClear: true }"
							defaultTime="11:59 PM"
						></config-date>
					</div>
				</div>
				<div class="col-12 col-lg-4 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-center form-group">
						<button
							v-if="reportStats && reportStats.length > 0"
							class="btn btn-sm btn-icon btn-rounded theme-accent mx-1"
							style="padding-top: 10px"
							v-tippy
							:title="$t('tooltip.export')"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							<i class="fas fa-file-export" style="margin-left: 2px"></i>
						</button>
						<div v-if="reportStats && reportStats.length > 0" class="dropdown-menu dropdown-menu-right w">
							<a class="dropdown-item" @click="dlPdf()">
								<i class="far fa-file-pdf fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportDaily.export_to_pdf") }}</label>
							</a>
							<a class="dropdown-item" @click="dlCsv()">
								<i class="far fa-file-excel fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportDaily.export_to_csv") }}</label>
							</a>
						</div>
						<div class="py-0 ml-1">
							<button
								@click="getDailyStats"
								:disabled="!canRun()"
								class="btn btn-sm btn-secondary btn-block flex-fixed"
								:class="{ 'theme-accent': canRun() }"
								v-b-tooltip
								:title="$t('tooltip.run_report')"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline">&nbsp;{{ $t("ReportDaily.run_report") }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="reportStats && reportStats.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div class="flex">
					<div class="card border-0 box-shadow-3">
						<report-table
							class="condensed-table mb-0"
							:fields="fields"
							:totals="reportTotals"
							:content="reportStats"
							per-page="10"
							totalsClass="theme-lighten-3 text-on-color"
						>
							<template #cell(scorer)="data">
								<span v-if="!data.item.user">{{ data.item.scorer }}</span>
								<span
									v-if="data.item.user"
									v-tippy
									:title="data.item.user.scorer_id != '' ? data.item.user.full_name : null"
									>{{ fs.scorerID(data.item.user) }}</span
								>
							</template>
							<template #cell(avg_time)="data">{{ fs.medDuration(data.item.avg_time) }}</template>
							<template v-for="sp in scorePoints" :slot="`fd_percent_${sp.val}`" slot-scope="data">
								<span
									:key="`${sp.id}-t`"
									v-if="!isTotalRow(data.item)"
									:title="`${data.item[`fd_stat_${sp.val}`].count} ${$t('data_description.out_of')} ${
										data.item.fd_total
									}`"
									v-tippy
									>{{ pc(data.item[`fd_stat_${sp.val}`].val) }}</span
								>
								<br :key="`${sp.id}-br`" v-if="!isTotalRow(data.item)" />
								<span :key="`${sp.id}-b`" v-if="!isTotalRow(data.item)">{{
									data.item[`fd_stat_${sp.val}`].offset
								}}</span>
								<span :key="sp.id" v-if="isTotalRow(data.item)">{{
									pc(data.item[`fd_stat_${sp.val}`].val)
								}}</span>
							</template>
							<template #cell(exact)="data">{{ pc(data.item.exact) }}</template>
							<template #cell(adjacent)="data">{{ pc(data.item.adjacent) }}</template>
							<template #cell(adjacent_high)="data">{{ pc(data.item.adjacent_high) }}</template>
							<template #cell(adjacent_low)="data">{{ pc(data.item.adjacent_low) }}</template>
							<template #cell(non_adjacent)="data">{{ pc(data.item.non_adjacent) }}</template>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="reportStats && reportStats.length == 0" class="d-flex flex-row justify-content-center">
				<h3 class="text-muted">{{ $t("ReportDaily.report_returned_no_results") }}</h3>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ar {
	text-align: center;
}

.d-long {
	display: none;
}

.d-abr {
	display: table-cell;
}

@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}

	.d-abr {
		display: none;
	}
}

.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}

.t-margin {
	width: 15px;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ConfigService from "@/services/ConfigService";
import FileService from "@/services/FileService";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import moment from "moment";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import ReportTable from "@/components/ReportTable";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "ReportDaily",

	props: ["user"],

	components: {
		ConfigDate,
		ConfigSelect,
		ConfigUserSearch,
		ReportTable,
		UserTimezone,
	},

	data() {
		return {
			fs: fs,
			reportTotals: null,
			reportStats: null,
			reportTrait: null,

			fields: [],

			fieldsLeft: [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
				},
				{
					key: "scorer",
					label: this.$i18n.t("fields.scorer"),
					tdClass: "table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
				{
					key: "fd_total",
					label: this.$i18n.t("fields.total_scored"),
					tdClass: "text-center v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
				{
					key: "adj_total",
					label: this.$i18n.t("fields.has_other_score"),
					tdClass: "text-center v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
				{
					key: "avg_time",
					label: this.$i18n.t("fields.average_time"),
					tdClass: "text-center v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
			],
			spFieldTemplate: {
				key: "fd_percent",
				label: this.$i18n.t("fields.score_points"),
				tdClass: "text-right v-mid compact",
				thClass: "text-center v-mid",
				sortable: true,
			},
			fieldsRight: [
				{
					key: "exact",
					label: this.$i18n.t("fields.exact"),
					tdClass: "text-right v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
				{
					key: "adjacent",
					label: this.$i18n.t("fields.adjacent"),
					tdClass: "text-right v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
				{
					key: "adjacent_high",
					label: this.$i18n.t("fields.adjacent_high"),
					tdClass: "text-right v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
				{
					key: "adjacent_low",
					label: this.$i18n.t("fields.adjacent_low"),
					tdClass: "text-right v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
				{
					key: "non_adjacent",
					label: this.$i18n.t("fields.nonadjacent"),
					tdClass: "text-right v-mid",
					thClass: "text-center v-mid",
					sortable: true,
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
				},
			],

			projects: [],
			selectedProject: null,

			teams: [],
			selectedTeam: null,

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			traits: [],
			selectedTrait: null,

			scorePoints: [],

			users: [],
			selectedUser: null,
			excludeUser: false,

			fromDate: null,
			toDate: null,

			running: false,
			loading: true,
		};
	},

	created() {
		this.storagePrefix = "daily.";
		this.selectedUser = store.bind(this, "selectedUser");
		this.fromDate = store.bind(this, "fromDate");
		this.toDate = store.bind(this, "toDate");
		this.excludeUser = store.bind(this, "excludeUser");

		let teamCall = ConfigService.listTeams;
		if (this.user.role.limit_teams) {
			teamCall = ConfigService.listUserTeams;
		}

		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		Promise.all([ConfigService.listProjects(ctx), teamCall()])
			.then((resps) => {
				console.log(resps);
				//resps[0] - projects
				this.projects = resps[0].data;
				this.selectedProject = store.bindProject(this, this.projects);
				this.sections = this.selectedProject.sections;
				this.selectedSection = store.bindSection(this, this.sections);
				this.selectedItem = store.bindItem(this, this.selectedSection);
				this.selectedTrait = store.bindTrait(this, this.selectedItem);

				//resps[1] - teams
				this.teams = resps[1].data.teams;
				if (this.user.role.limit_teams) {
					this.teams = resps[1].data;
				}
				this.selectedTeam = store.bindTeam(this, this.teams, true);
				this.loading = false;
			})
			.catch((err) => {
				notie.error(this.$i18n.t("notie.load_config_options_fail"), err);
				this.loading = false;
			});
	},

	watch: {
		selectedProject() {
			if (this.selectedProject && this.selectedProject.sections) {
				this.sections = this.selectedProject.sections;
			} else {
				this.sections = [];
			}
		},
		selectedSection() {
			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
			} else {
				this.items = [];
			}
		},
		selectedItem() {
			if (this.selectedItem && this.selectedItem.rubric.traits) {
				this.traits = _.filter(this.selectedItem.rubric.traits, {
					separator: false,
					is_parent: false,
				});
				_.each(this.traits, (trait) => {
					if (trait.reported_name) {
						trait.name = trait.reported_name;
					}
				});
			} else {
				this.traits = [];
			}
		},
	},

	methods: {
		constructScorePoints(trait) {
			if (trait) {
				var step = trait.step;
				trait.scorePoints = [];
				for (var i = trait.min; i <= trait.max; i += step) {
					let val = null;
					if (i % 0.5 == 0) {
						val = i.toFixed(1);
					} else {
						val = i.toFixed(2);
					}
					trait.scorePoints.push({
						val: val,
						id: i.toFixed(1),
						selected: false,
					});
				}
				_.each(trait.condition_codes, (c) => {
					if (c.action == 1) {
						trait.scorePoints.push({
							val: c.symbol,
							id: c.symbol,
						});
					}
				});

				return trait.scorePoints;
			} else {
				return [];
			}
		},

		constructScorePointFields(scorePoints, trait) {
			let cFields = [];
			cFields = cFields.concat(this.fieldsLeft);

			if (trait) {
				_.each(scorePoints, (sp) => {
					let spField = _.clone(this.spFieldTemplate);
					spField.label = sp.val;
					spField.key = `${spField.key}_${sp.val}`;
					cFields.push(spField);
				});
			} else {
				cFields.push(this.spFieldTemplate);
			}

			return cFields.concat(this.fieldsRight);
		},

		pcr(num, den) {
			if (den == 0) {
				return "——";
			}
			if (num == 0) {
				return "0.00";
			}

			var res = (num / den) * 100;
			return res.toFixed(2);
		},

		pc(n) {
			if (isNaN(n)) {
				return "——";
			} else {
				return (n * 100).toFixed(2);
			}
		},

		pct(n) {
			if (isNaN(n)) {
				return "";
			} else {
				return (n * 100).toFixed(2);
			}
		},

		getSpPercent(sp, percent) {
			if (this.traitScoreMap) {
				var value = this.traitScoreMap.get(sp);
				if (value) {
					value = (value / this.totalStats.scored) * 100;
					if (percent && percent > 0) {
						value = percent - value;
					}
					return Number(value).toFixed(2);
				}
			}
			return 0.0;
		},

		getTotalSpPercent(sp) {
			if (this.traitScoreMap) {
				var value = this.traitScoreMap.get(sp);
				if (value) {
					return value;
				}
			}
			return 0.0;
		},

		getDailyStats() {
			if (this.running) {
				return;
			}
			var proj = this.selectedProject;
			var team = this.selectedTeam;
			var sec = this.selectedSection;
			var item = this.selectedItem;
			var trait = this.selectedTrait;
			var userSearch = this.selectedUser;
			var fDate = this.fromDate;
			var tDate = this.toDate;

			//Just return if we don't have required fields
			if (!this.canRun()) {
				this.data = [];
				return;
			}

			var queryJson = {
				section_id: this.selectedSection.id,
				item_id: this.selectedItem.id,
				trait_id: this.selectedTrait.id,
			};
			if (this.selectedUser && this.selectedUser.id) {
				queryJson.user_id = this.selectedUser.id;
				queryJson.exclude_user = this.excludeUser;
			}

			if (this.fromDate) {
				queryJson.start_date = this.fromDate;
			}
			if (this.toDate) {
				queryJson.end_date = this.toDate;
			}

			if (this.selectedTeam && this.selectedTeam.id) {
				queryJson.team_id = this.selectedTeam.id;
			}

			this.running = true;
			ReportingService.getDailyStats(queryJson)
				.then((resp) => {
					console.log("GET DAILY STATS", resp.data);
					this.running = false;
					this.reportTrait = _.cloneDeep(this.selectedTrait);
					let scorePoints = this.constructScorePoints(this.reportTrait);
					let reportData = this.tablefyStats(_.cloneDeep(resp.data), scorePoints);
					this.reportTotals = reportData.total_stats;
					this.reportStats = reportData.user_stats;
					this.fields = this.constructScorePointFields(scorePoints, this.reportTrait);
					this.scorePoints = scorePoints;
				})
				.catch((err) => {
					this.running = false;
					console.log(err);
					notie.error(this.$i18n.t("notie.load_daily_stat_fail"), err);
				});
		},

		tablefyStats(data, scorePoints) {
			function getOffset(trait, n, total) {
				var val = (n / total) * 100;
				var totals = (data.total_stats.fd[trait] / data.total_stats.fd_total) * 100;
				var result = (val - totals).toFixed(2);
				if (result >= 0) {
					result = "+" + result;
				}
				return result;
			}

			data.user_stats = _.filter(data.user_stats, (stat) => {
				return stat.fd_total > 0;
			});

			data.total_stats.scorer = `Totals (${data.user_stats.length})`;
			data.total_stats.avg_time = data.total_stats.think_time / data.total_stats.fd_total;
			data.total_stats.fd_percent = [];
			data.total_stats.fd_cell = [];
			_.each(scorePoints, (sp) => {
				let percentKey = `fd_percent_${sp.val}`;
				let statKey = `fd_stat_${sp.val}`;

				var total = (data.total_stats.fd[sp.val] / data.total_stats.fd_total) * 100;
				data.total_stats[percentKey] = total.toFixed(2);

				data.total_stats[statKey] = {
					count: data.total_stats.fd[sp.val],
					val: data.total_stats.fd[sp.val] / data.total_stats.fd_total,
					offset: getOffset(sp.val, data.total_stats.fd[sp.val], data.total_stats.fd_total),
				};
			});
			data.total_stats.exact = data.total_stats.agree / data.total_stats.adj_total;
			data.total_stats.adjacent =
				(data.total_stats.adj_high + data.total_stats.adj_low) / data.total_stats.adj_total;
			data.total_stats.adjacent_high = data.total_stats.adj_high / data.total_stats.adj_total;
			data.total_stats.adjacent_low = data.total_stats.adj_low / data.total_stats.adj_total;
			data.total_stats.non_adjacent =
				(data.total_stats.non_adj_high + data.total_stats.non_adj_low) / data.total_stats.adj_total;

			_.each(data.user_stats, (stat) => {
				console.log("STAT", stat.fd);
				stat.scorer = fs.scorerID(stat.user);
				stat.avg_time = stat.think_time / stat.fd_total;
				_.each(scorePoints, (sp) => {
					console.log("SP", sp.val);
					let percentKey = `fd_percent_${sp.val}`;
					let statKey = `fd_stat_${sp.val}`;

					stat[percentKey] = getOffset(sp.val, stat.fd[sp.val], stat.fd_total);
					stat[statKey] = {
						count: stat.fd[sp.val],
						val: stat.fd[sp.val] / stat.fd_total,
						offset: getOffset(sp.val, stat.fd[sp.val], stat.fd_total),
					};
				});

				stat.exact = stat.agree / stat.adj_total;
				stat.adjacent = (stat.adj_high + stat.adj_low) / stat.adj_total;
				stat.adjacent_high = stat.adj_high / stat.adj_total;
				stat.adjacent_low = stat.adj_low / stat.adj_total;
				stat.non_adjacent = (stat.non_adj_high + stat.non_adj_low) / stat.adj_total;
				//console.log("Constructed stat", stat.scorer_id, stat);
			});

			return data;
		},

		isTotalRow(item) {
			if (item.forceHead || item.forceFoot) {
				return true;
			} else {
				return false;
			}
		},

		fixed(time) {
			if (time == Number.POSITIVE_INFINITY) {
				return "∞";
			} else {
				return time.toFixed(2);
			}
		},

		getTime(time) {
			if (time == 0) {
				return "——";
			} else if (!time) {
				return "——";
			} else {
				return moment().startOf("day").seconds(time).format("HH:mm:ss");
			}
		},

		getTimeMillis(duration) {
			//duration+=86400000
			var milliseconds = parseInt((duration % 1000) / 100),
				seconds = parseInt((duration / 1000) % 60),
				minutes = parseInt((duration / (1000 * 60)) % 60),
				hours = parseInt(duration / (1000 * 60 * 60));

			if (isNaN(seconds)) {
				seconds = 0;
			}
			if (isNaN(minutes)) {
				minutes = 0;
			}
			if (isNaN(hours)) {
				hours = 0;
			}

			hours = hours < 10 ? "0" + hours : hours;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			return hours + ":" + minutes + ":" + seconds;
		},

		getTimeSeconds(duration) {
			if (duration == 0) {
				return "——";
			} else if (!duration) {
				return "——";
			} else {
				//duration+=86400000
				var milliseconds = parseInt((duration % 1000) / 100),
					seconds = parseInt(duration % 60),
					minutes = parseInt((duration / 60) % 60),
					hours = parseInt(duration / (60 * 60));

				hours = hours < 10 ? "0" + hours : hours;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;

				return hours + ":" + minutes + ":" + seconds;
			}
		},

		getDate(date) {
			if (!date) {
				return "——";
			} else if (date.startsWith("000")) {
				return "——";
			} else {
				date = moment(date).format("l LT");
				if (date) {
					return date;
				}
			}
		},

		canRun() {
			return this.selectedSection && this.selectedItem && this.selectedTrait;
		},

		dlPdf() {
			FileService.dailyReportPDF(this);
		},

		dlCsv() {
			FileService.dailyReportCSV(this);
		},
	},
};
</script>
