import axios from "axios";

export default {
	listJobs(ctx, noTimeout) {
		let config = {
			params: ctx
		};
		if (noTimeout) {
			config.headers = { "X-No-Timeout": true };
		}
		return axios.get("/v1/admin/reset_jobs", config);
	},

	addJob(job) {
		return axios.post("/v1/admin/reset_jobs", job);
	},

	removeJob(jobID) {
		return axios.delete(`/v1/admin/reset_jobs/${jobID}`);
	},

	executeJob(jobID) {
		return axios.post(`/v1/admin/reset_jobs/${jobID}/execute`);
	}
};
