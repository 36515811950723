<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportItemSummary.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportItemSummary.item_summary") }}
				</div>
			</div>
		</template>

		<div class="flex scroll-x scroll-y">
			<div class="row mx-0 flex-fixed px-3 pt-3 box" v-spinner-overlay="loading">
				<div class="col-12 col-sm-4">
					<div class="form-group">
						<label>{{ $t("ReportItemSummary.section") }}</label>
						<config-select
							:options="sections"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="form-group">
						<label>{{ $t("ReportItemSummary.item") }}</label>
						<config-select
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
							sortBy="section_item_sequence"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="form-group">
						<label>{{ $t("ReportItemSummary.user") }}</label>
						<config-user-search
							:clientID="user.client && user.client.id"
							v-model="selectedUser"
							:placeholder="$t('data_description.any')"
						/>
					</div>
				</div>

				<div class="col-12 col-sm-4">
					<div class="form-group">
						<label>{{ $t("ReportItemSummary.comparison") }}</label>
						<v-select :options="compTypes" v-model="selectedComp" class="noclear"></v-select>
					</div>
				</div>

				<div class="col-12 col-sm-4">
					<div class="form-group">
						<label>
							{{ $t("ReportDaily.from_date") }}
							<user-timezone />
						</label>
						<config-date v-model="fromDate" :config="{ showClear: true }"></config-date>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="form-group">
						<label>
							{{ $t("ReportDaily.to_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="toDate"
							:config="{ showClear: true }"
							defaultTime="11:59 PM"
						></config-date>
					</div>
				</div>

				<div class="col-12 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-center form-group">
						<a
							class="text-muted pr-2"
							@click="csvExport"
							v-tippy="{ placement: 'bottom', triggers: 'click' }"
							:title="$t('tooltip.csv_export')"
						>
							<i class="far fa-file-csv fa-lg"></i>
						</a>
						<a
							class="text-muted pr-2"
							@click="printReport"
							v-tippy="{ placement: 'bottom', triggers: 'click' }"
							:title="$t('tooltip.print')"
						>
							<i class="far fa-print fa-lg"></i>
						</a>
						<div class="py-0 ml-1">
							<button
								@click="getStats"
								:disabled="!canRun()"
								class="btn btn-sm btn-block flex-fixed"
								:class="{
									[canRun() ? 'theme-accent' : 'btn-secondary']: true,
								}"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline"
									>&nbsp;{{ $t("ReportItemSummary.run_report") }}</span
								>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div v-if="rubric" class="d-flex flex-row box-shadow-3 mb-3">
				<div class="flex">
					<div class="card border-0 p-3">
						<div class="row">
							<div v-if="anyComps" class="col-12 col-sm-6">
								<table>
									<tr>
										<td class="pr-3">
											<h5>{{ $t("ReportItemSummary.score_irr") }}:</h5>
										</td>
										<td class="d-flex flex-row align-items-center">
											<h5 :class="percentColor(overallResponseAgreement, 40, 50)" v-if="anyComps">
												{{ overallResponseAgreement.toFixed(2) }}%
											</h5>
											<h6 class="text-muted" v-if="!anyComps">
												{{ $t("data_description.none_found") }}
											</h6>

											<i
												class="fas fa-info-circle ml-1 clickable"
												style="margin-top: 2px"
												@click="showIrrModal = true"
											/>
										</td>
									</tr>
									<tr>
										<td class="pr-3">
											<h5>{{ $t("ReportItemSummary.trait_irr") }}:</h5>
										</td>
										<td>
											<h5 :class="percentColor(overallTraitAgreement, 50, 65)" v-if="anyComps">
												{{ overallTraitAgreement.toFixed(2) }}%
											</h5>
											<h6 class="text-muted" v-if="!anyComps">
												{{ $t("data_description.none_found") }}
											</h6>
										</td>
									</tr>
								</table>
							</div>
							<div v-if="anyScores" class="col-12 col-sm-5 d-flex flex-column">
								<div class="flex-grow"></div>
								<div class="flex-fixed">
									<h5 style="display: inline-block">
										{{ $t("ReportItemSummary.frequency_distribution") }}
									</h5>

									<h6 style="display: inline-block" class="text-muted">({{ fdName }})</h6>
								</div>
								<h5 v-if="selectedComp.id === '12v50'" class="text-muted">
									{{ `${$t("ReportItemSummary.final_score_rule")}: ${finalScoreRule}` }}
								</h5>
							</div>
							<h1 v-if="!anyScores" class="w-100 text-very-muted text-center">
								{{ $t("data_description.no_results") }}
							</h1>
						</div>

						<template v-if="anyScores">
							<hr />

							<div class="row" v-for="trait in rubric.traits" :key="trait.id">
								<div class="col-4 col-sm-2">
									<table
										class="table bordered is_table"
										style="min-width: 180px; table-layout: fixed"
									>
										<thead>
											<tr>
												<th colspan="2">{{ trait.reported_name || trait.name }}</th>
											</tr>
										</thead>
										<tbody v-if="anyComps">
											<tr>
												<td>{{ $t("ReportItemSummary.irr") }}</td>
												<td>{{ trait.agreement }}</td>
											</tr>
											<tr>
												<td>{{ $t("ReportItemSummary.kappa") }}</td>
												<td>{{ trait.kappa }}</td>
											</tr>
											<tr>
												<td>{{ $t("ReportItemSummary.lwk") }}</td>
												<td>{{ trait.lwk }}</td>
											</tr>
											<tr>
												<td>{{ $t("ReportItemSummary.qwk") }}</td>
												<td>{{ trait.qwk }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="anyComps" class="col-2 col-sm-1"></div>
								<div v-if="anyComps" class="col-6 col-sm-3">
									<table class="confusion-matrix bordered center position-relative mt-4 ml-4">
										<div class="text-center matrix-axis-left">{{ yAxisName }}</div>
										<div class="text-center matrix-axis-top">{{ xAxisName }}</div>
										<tbody>
											<template v-for="(row, i) in trait.irrDataMatrix">
												<tr :key="i" v-if="i == 0">
													<td v-for="(col, j) in row" :key="j">{{ col.value }}</td>
												</tr>
												<tr :key="i" v-if="i != 0">
													<template v-for="(col, j) in row">
														<td v-if="j == 0" :key="j">
															{{ col.value }}
														</td>
														<td
															v-if="j != 0 && !selectedUser"
															:class="col.class"
															@click="matClick(trait.id, col.leftScore, col.rightScore)"
															:key="j"
														>
															{{ col.value }}
														</td>
														<td v-if="j != 0 && selectedUser" :class="col.class" :key="j">
															{{ col.value }}
														</td>
													</template>
												</tr>
											</template>
										</tbody>
									</table>
								</div>
								<div class="col-12" :class="{ 'col-sm-6': anyComps, 'px-5': !anyComps }">
									<canvas
										v-if="!running"
										v-chartjs="trait.chartData"
										style="width: 100%; height: 250px"
									></canvas>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="irrModal" :visible="showIrrModal" @hide="showIrrModal = false" size="md">
			<template #modal-header>
				<h5 class="mb-0">IRR Details</h5>
			</template>

			<table class="table table-condensed">
				<thead>
					<tr>
						<th style="min-width: 120px"></th>
						<th style="width: 33%" class="text-center">Current</th>
						<th style="width: 33%">Adjustment</th>
						<th style="width: 33%" class="text-center">New</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="v-mid">Exact</td>
						<td class="v-mid text-center">
							{{ initialIrr.exact }}
							<span
								class="float-right _600"
								:class="{ 'text-success': adjustIrr.exact > 0, 'text-danger': adjustIrr.exact < 0 }"
							></span>
						</td>
						<td class="v-mid">
							<input
								class="form-control"
								type="number"
								:min="-1 * initialIrr.exact"
								v-model.number="adjustIrr.exact"
							/>
						</td>
						<td class="v-mid text-center">{{ adjustedIrr.exact }}</td>
					</tr>
					<tr>
						<td class="v-mid">Adjacent</td>
						<td class="v-mid text-center">
							{{ initialIrr.adj }}
							<span
								class="float-right _600"
								:class="{ 'text-success': adjustIrr.adj > 0, 'text-danger': adjustIrr.adj < 0 }"
							></span>
						</td>
						<td class="v-mid">
							<input
								class="form-control"
								type="number"
								:min="-1 * initialIrr.adj"
								v-model.number="adjustIrr.adj"
							/>
						</td>
						<td class="v-mid text-center">{{ adjustedIrr.adj }}</td>
					</tr>
					<tr>
						<td class="v-mid">Non-Adjacent</td>
						<td class="v-mid text-center">
							{{ initialIrr.nonadj }}
							<span
								class="float-right _600"
								:class="{ 'text-success': adjustIrr.nonadj > 0, 'text-danger': adjustIrr.nonadj < 0 }"
							></span>
						</td>
						<td class="v-mid">
							<input
								class="form-control"
								type="number"
								:min="-1 * initialIrr.nonadj"
								v-model.number="adjustIrr.nonadj"
							/>
						</td>
						<td class="v-mid text-center">{{ adjustedIrr.nonadj }}</td>
					</tr>
					<tr>
						<td class="v-mid _600">Total</td>
						<td class="v-mid _600 text-center">{{ initialIrr.total }}</td>
						<td class="v-mid"></td>
						<td class="v-mid _600 text-center">{{ adjustedIrr.total }}</td>
					</tr>
				</tbody>
			</table>
			<div class="d-flex flex-row justify-content-center">
				<h5 v-if="!adjustedIrr.adjusted" class="mr-2">{{ $t("ReportItemSummary.score_irr") }}:</h5>
				<h5 v-else class="mr-2">{{ $t("ReportItemSummary.old_irr") }}:</h5>
				<h5 :class="percentColor(overallResponseAgreement, 40, 50)" v-if="anyComps">
					{{ overallResponseAgreement.toFixed(2) }}%
				</h5>
				<h6 class="text-muted" v-if="!anyComps">
					{{ $t("data_description.none_found") }}
				</h6>

				<template v-if="adjustedIrr.adjusted">
					<h5 class="ml-3 mr-2">{{ $t("ReportItemSummary.new_irr") }}:</h5>
					<h5 :class="percentColor(adjustedIrr.irr, 40, 50)" v-if="anyComps">
						{{ adjustedIrr.irr.toFixed(2) }}%
					</h5>
					<h6 class="text-muted" v-if="!anyComps">
						{{ $t("data_description.none_found") }}
					</h6>
				</template>
			</div>

			<template #modal-footer>
				<button class="btn btn-secondary" @click="showIrrModal = false">
					{{ $t("buttons.done") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ar {
	text-align: center;
}
.d-long {
	display: none;
}
.d-abr {
	display: table-cell;
}
@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}
	.d-abr {
		display: none;
	}
}
.t-margin {
	width: 15px;
}
.matrix-axis-top {
	position: absolute;
	left: 0px;
	right: 0px;
	top: -24px;
}
.matrix-axis-left {
	position: absolute;
	right: calc(50% + 12px);
	width: 100%;
	top: 50%;
	transform: rotate(270deg);
	transform-origin: 50% 50%;
}
h5,
h6 {
	margin-bottom: 0;
}
.hover-darken {
	cursor: pointer;
	transition: background-color 0.25s;
}
.hover-darken:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

@media print {
	#report {
		zoom: 100%;
		background-color: transparent;
		height: auto;
		overflow: visible;
	}
	.col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.options-buttons {
		display: none !important;
	}
}
</style>

<script>
//Directives
require("@/directives/chartjs");

//Services
import ReportingService from "@/services/ReportingService";

import ConfigService from "@/services/ConfigService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import FileService from "@/services/FileService";
import fs from "@/services/FormatService";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import ConfigDate from "@/components/ConfigDate";
import UserTimezone from "@/components/UserTimezone";

import { CONDITION_CODE_ACTIONS } from "@/services/Constants";

export default {
	name: "ReportItemSummary",

	props: ["user"],

	components: { ConfigSelect, ConfigUserSearch, ConfigDate, UserTimezone },

	data() {
		return {
			chartData: null,
			overallResponseAgreement: 0,
			overallTraitAgreement: 0,
			fdName: "",
			xAxisName: "",
			yAxisName: "",
			rubric: null,
			fd_data: null,

			compTypes: [
				{
					label: this.$i18n.t("ReportItemSummary.first_vs_second"),
					leftStates: [12],
					rightStates: [12],
					id: "1v2",
					fdName: this.$i18n.t("ReportItemSummary.pool"),
					xAxisLabel: this.$i18n.t("ReportItemSummary.second_read"),
					yAxisLabel: this.$i18n.t("ReportItemSummary.first_read"),
					xAxisWithUserLabel: this.$i18n.t("ReportItemSummary.other_read"),
					yAxisWithUserLabel: this.$i18n.t("ReportItemSummary.this_user"),
				},
				{
					label: this.$i18n.t("ReportItemSummary.first_vs_res"),
					leftStates: [1],
					rightStates: [3],
					id: "1v3",
					fdName: this.$i18n.t("ReportItemSummary.resolution"),
					xAxisLabel: this.$i18n.t("ReportItemSummary.resolution"),
					yAxisLabel: this.$i18n.t("ReportItemSummary.first_read"),
					xAxisWithUserLabel: this.$i18n.t("ReportItemSummary.resolution"),
					yAxisWithUserLabel: this.$i18n.t("ReportItemSummary.this_user"),
				},
				{
					label: this.$i18n.t("ReportItemSummary.second_vs_res"),
					leftStates: [2],
					rightStates: [3],
					id: "2v3",
					fdName: this.$i18n.t("ReportItemSummary.resolution"),
					xAxisLabel: this.$i18n.t("ReportItemSummary.resolution"),
					yAxisLabel: this.$i18n.t("ReportItemSummary.second_read"),
					xAxisWithUserLabel: this.$i18n.t("ReportItemSummary.resolution"),
					yAxisWithUserLabel: this.$i18n.t("ReportItemSummary.this_user"),
				},
				{
					label: this.$i18n.t("ReportItemSummary.first_vs_back"),
					leftStates: [1],
					rightStates: [4],
					id: "1v4",
					fdName: this.$i18n.t("ReportItemSummary.backread"),
					xAxisLabel: this.$i18n.t("ReportItemSummary.backread"),
					yAxisLabel: this.$i18n.t("ReportItemSummary.first_read"),
					xAxisWithUserLabel: this.$i18n.t("ReportItemSummary.backread"),
					yAxisWithUserLabel: this.$i18n.t("ReportItemSummary.this_user"),
				},
				{
					label: this.$i18n.t("ReportItemSummary.second_vs_back"),
					leftStates: [2],
					rightStates: [4],
					id: "2v4",
					fdName: this.$i18n.t("ReportItemSummary.backread"),
					xAxisLabel: this.$i18n.t("ReportItemSummary.backread"),
					yAxisLabel: this.$i18n.t("ReportItemSummary.second_read"),
					xAxisWithUserLabel: this.$i18n.t("ReportItemSummary.backread"),
					yAxisWithUserLabel: this.$i18n.t("ReportItemSummary.this_user"),
				},
				{
					label: this.$i18n.t("ReportItemSummary.first_and_second_vs_final"),
					leftStates: [1, 2],
					rightStates: [50],
					id: "12v50",
					fdName: this.$i18n.t("ReportItemSummary.final"),
					xAxisLabel: this.$i18n.t("ReportItemSummary.final"),
					yAxisLabel: this.$i18n.t("ReportItemSummary.first_and_second"),
					xAxisWithUserLabel: "1st/2nd",
					yAxisWithUserLabel: this.$i18n.t("ReportItemSummary.this_user"),
				},
			],
			selectedComp: null,

			selectedUser: null,

			reportTotals: [],
			reportStats: [],

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			fromDate: null,
			toDate: null,

			byRubric: false,

			running: false,
			loading: true,

			finalScoreRule: "",
			showIrrModal: false,
			initialIrr: {
				exact: 0,
				adj: 0,
				nonadj: 0,
			},
			adjustIrr: {
				exact: 0,
				adj: 0,
				nonadj: 0,
			},
		};
	},

	created() {
		this.storagePrefix = "itemSummary.";
		this.selectedUser = store.bind(this, "selectedUser");
		this.selectedComp = store.bindFromList(this, this.compTypes, "id", 0, "selectedComp");
		this.fromDate = store.bind(this, "fromDate");
		this.toDate = store.bind(this, "toDate");

		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		ConfigService.listSectionsShallow(ctx)
			.then((resp) => {
				this.sections = resp.data.sections;

				this.selectedSection = store.bindSection(this, this.sections);
				this.selectedItem = store.bindItem(this, this.selectedSection);
				this.loading = false;
			})
			.catch((err) => {
				notie.error(this.$i18n.t("notie.load_config_options_fail"), err);
				this.loading = false;
			});
	},

	computed: {
		anyScores() {
			if (!this.rubric || !this.fd_data) {
				return false;
			}

			return this.fd_data.length > 0;
		},

		anyComps() {
			if (!this.rubric) {
				return false;
			}
			var result = _.find(this.rubric.traits, (trait) => {
				if (trait.agreement != "NaN") {
					return true;
				}
			});
			return result ? true : false;
		},

		adjustedIrr() {
			let adjustExact = this.adjustIrr.exact;
			adjustExact = adjustExact && !isNaN(adjustExact) ? adjustExact : 0;
			let adjustAdj = this.adjustIrr.adj;
			adjustAdj = adjustAdj && !isNaN(adjustAdj) ? adjustAdj : 0;
			let adjustNonadj = this.adjustIrr.nonadj;
			adjustNonadj = adjustNonadj && !isNaN(adjustNonadj) ? adjustNonadj : 0;

			let adjustedIrr = {
				exact: this.initialIrr.exact + adjustExact,
				adj: this.initialIrr.adj + adjustAdj,
				nonadj: this.initialIrr.nonadj + adjustNonadj,
				total: 0,
				irr: 0,
				adjusted: false,
			};
			adjustedIrr.total = adjustedIrr.exact + adjustedIrr.adj + adjustedIrr.nonadj;
			adjustedIrr.irr = (100 * adjustedIrr.exact) / adjustedIrr.total;
			adjustedIrr.adjusted = !(adjustExact == 0 && adjustAdj == 0 && adjustNonadj == 0);
			return adjustedIrr;
		},
	},

	watch: {
		selectedSection() {
			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
			} else {
				this.items = [];
			}
		},
	},

	methods: {
		getStats() {
			this.running = true;
			var sec = this.selectedSection;
			var item = this.selectedItem;
			var userLeft = this.selectedUser;
			if (!(userLeft && userLeft.id)) {
				userLeft = { id: null };
			}
			//Server supports a second user for comparison, but client currently does not
			//var userRight = this.userRight;
			var userRight = { id: null };
			var leftStates = this.selectedComp.leftStates;
			var rightStates = this.selectedComp.rightStates;

			if (!this.canRun()) {
				return;
			}

			if (this.selectedSection != "All") {
				sec = this.selectedSection.ref_id;
			}

			if (this.selectedItem != "All") {
				item = this.selectedItem.ref_id;
			}

			function getOverallResponseAgreement(agreement) {
				return agreement * 100;
			}

			function getOverallTraitAgreement(stats) {
				// debugger;
				var agreed = 0;
				var total = 0;

				_.each(stats, (stat) => {
					if (stat.left_score == stat.right_score) {
						agreed += stat.count;
					}
					total += stat.count;
				});

				return (agreed / total) * 100;
			}

			function getTraitAgreement(stats, traitId) {
				var agreed = 0;
				var total = 0;

				_.each(stats, (stat) => {
					if (stat.trait_id == traitId) {
						if (stat.left_score == stat.right_score) {
							agreed += stat.count;
						}
						total += stat.count;
					}
				});

				return ((agreed / total) * 100).toFixed(2);
			}

			function getStatValue(stats, trait, firstScore, secondScore) {
				var count = 0;

				_.each(stats, (stat) => {
					if (
						stat.trait_id == trait.id &&
						((stat.left_score == firstScore && stat.right_score == secondScore) ||
							(stat.left_condition != "" &&
								stat.left_condition == firstScore &&
								stat.right_condition != "" &&
								stat.right_condition == secondScore))
					) {
						count = stat.count;
					}
				});
				return count;
			}

			this.data = [
				{
					values: [
						{ label: "1", value: 10, color: "#009688" },
						{ label: "2", value: 30, color: "#009688" },
						{ label: "3", value: 40, color: "#009688" },
						{ label: "4", value: 10, color: "#009688" },
					],
				},
			];

			ReportingService.getItemSummaryStats(
				this.selectedSection.id,
				this.selectedItem.id,
				leftStates,
				userLeft.id,
				rightStates,
				userRight.id,
				this.fromDate,
				this.toDate
			)
				.then((resp) => {
					let data = resp.data;

					this.running = false;
					this.rubric = data.rubric;
					this.fd_data = data.fd_data;
					var rubric = this.rubric;

					const { final_score_role } = data;
					this.finalScoreRule = final_score_role;
					if (this.final_score_role === "") this.final_score_role = "average";

					//debugger;
					this.overallResponseAgreement = getOverallResponseAgreement(data.overall_response_agreement);
					this.initialIrr.exact = data.overall_response_exact;
					this.initialIrr.adj = data.overall_response_adj;
					this.initialIrr.nonadj = data.overall_response_nonadj;
					this.initialIrr.total = data.overall_reponse_total;
					this.overallTraitAgreement = getOverallTraitAgreement(data.matrix_data);
					if (this.selectedUser && this.selectedUser.id) {
						var userName = `${this.selectedUser.first_name} ${this.selectedUser.last_name}`;
						this.fdName = userName;
						this.xAxisName = this.selectedComp.xAxisWithUserLabel;
						this.yAxisName = this.selectedComp.yAxisWithUserLabel;
						if (this.xAxisName == "[user]") {
							this.xAxisName = userName;
						}
						if (this.yAxisName == "[user]") {
							this.yAxisName = userName;
						}
					} else {
						this.fdName = this.selectedComp.fdName;
						this.xAxisName = this.selectedComp.xAxisLabel;
						this.yAxisName = this.selectedComp.yAxisLabel;
					}

					rubric.traits = rubric.traits.filter(({ separator, is_parent }) => !(separator || is_parent));
					rubric.traits = _.sortBy(rubric.traits, (t) => {
						return t.sequence;
					});

					_.each(rubric.traits, (trait) => {
						trait.chartData = {
							type: "bar",
							data: {
								labels: [],
								datasets: [
									{
										label: "1st/2nd",
										fullName: "First & Second",
										xAxisID: this.$i18n.t("ReportItemSummary.sp"),
										yAxisID: this.$i18n.t("ReportItemSummary.freq"),
										backgroundColor: "rgba(0, 188, 212, 0.2)",
										borderColor: "rgba(0, 188, 212, 0.5)",
										borderWidth: 1,
										data: [],
										counts: [],
									},
								],
							},
							options: {
								tooltips: {
									mode: "point",
									displayColors: false,
									callbacks: {
										title: (items, data) => {
											let i = items[0].datasetIndex;
											let sp = items[0].xLabel;
											let fullName = data.datasets[i].fullName;
											return `${fullName}: ${sp}`;
										},
										label: (item, data) => {
											let i = item.datasetIndex;
											let j = item.index;
											let count = data.datasets[i].counts[j].count;
											let total = data.datasets[i].counts[j].total;
											let per = data.datasets[i].data[j];
											return `${count} ${this.$i18n.t(
												"data_description.out_of"
											)} ${total} (${per}%)`;
										},
									},
								},
								maintainAspectRatio: false,
								legend: {
									display: true,
								},
								scales: {
									xAxes: [
										{
											id: "sp",
											scaleLabel: {
												display: true,
												labelString: this.$i18n.t("ReportItemSummary.score_point"),
											},
										},
									],
									yAxes: [
										{
											id: "freq",
											scaleLabel: {
												display: true,
												labelString: this.$i18n.t("ReportItemSummary.frequency"),
											},
											ticks: {
												suggestedMin: 0,
												suggestedMax: 25,
											},
										},
									],
								},
							},
						};
						trait.agreement = getTraitAgreement(data.matrix_data, trait.id);
						//Create empty matrix padded with scorepoint and FD data
						var sps = [];
						var firstRow = [" "];

						var step = trait.step;

						for (var i = trait.min; i <= trait.max; i += step) {
							firstRow.push({ value: i });
							sps.push(i);
						}

						const { SYMBOL, SYMBOL_LINKED } = CONDITION_CODE_ACTIONS;
						_.each(trait.condition_codes, (cc) => {
							if (cc.action === SYMBOL.id || cc.action === SYMBOL_LINKED.id) {
								firstRow.push({ value: cc.symbol });
								sps.push(cc.symbol);
							}
						});

						// sp calculated using final score avg - decimal points
						const finalScoreSPs = [];
						data.fd_data.forEach(({ score }) => {
							if (score >= 0 && !sps.includes(score)) {
								sps.push(score);
								finalScoreSPs.push(score);
							}
						});

						sps = sps.sort((a, b) => {
							if (typeof a === "string") {
								if (typeof b === "string") return 0;
								return 1;
							}
							if (typeof b === "string") return 0;

							return a - b;
						});

						const resData = [];
						const resCounts = [];
						const brData = [];
						const brCounts = [];
						const finalScoreData = [];
						const finalScoreCounts = [];
						const irrDataMatrix = [firstRow];
						const { getFd } = this;
						_.each(sps, (sp, i) => {
							const live = getFd(data.fd_data, trait.id, sp, "count");
							const res = getFd(data.fd_data, trait.id, sp, "res_count");
							const br = getFd(data.fd_data, trait.id, sp, "br_count");
							const finalScore = getFd(data.fd_data, trait.id, sp, "final_score_count");
							trait.chartData.data.datasets[0].data[i] = +(Math.round(live.value + "e+2") + "e-2");
							trait.chartData.data.datasets[0].counts[i] = live;
							if (!res.none) {
								resData[i] = +(Math.round(res.value + "e+2") + "e-2");
								resCounts[i] = res;
							}
							if (!br.none) {
								brData[i] = +(Math.round(br.value + "e+2") + "e-2");
								brCounts[i] = br;
							}
							finalScoreData[i] = +(Math.round(finalScore.value + "e+2") + "e-2");
							finalScoreCounts[i] = finalScore;

							trait.chartData.data.labels[i] = live.label;

							//Push new row
							var row = [{ value: sp }];
							_.each(sps, (sp2) => {
								var value = getStatValue(data.matrix_data, trait, sp, sp2);
								var classa = {};
								if (value > 0) {
									if (isNaN(sp) || isNaN(sp2)) {
										// No agreement between non-number scores
									} else if (Math.abs(sp - sp2) == 0) {
										classa = { cmgreen: true };
									} else if (Math.abs(sp - sp2) == step) {
										classa = { cmorange: true };
									} else {
										classa = { cmred: true };
									}
								}

								if (this.user.role.page_auditing) {
									classa["hover-darken"] = true;
								}

								if (!finalScoreSPs.includes(sp2)) {
									row.push({
										value: value,
										class: classa,
										traitid: trait.id,
										leftScore: sp,
										rightScore: sp2,
									});
								}
							});
							// don't want decimal point values in Matrix
							if (!finalScoreSPs.includes(sp)) irrDataMatrix.push(row);
						});
						if (resData.length > 0) {
							trait.chartData.data.datasets.push({
								label: this.$i18n.t("ReportItemSummary.res"),
								fullName: "Resolution",
								xAxisID: this.$i18n.t("ReportItemSummary.sp"),
								yAxisID: this.$i18n.t("ReportItemSummary.freq"),
								backgroundColor: "rgba(92, 199, 50, 0.2)",
								borderColor: "rgba(92, 199, 50, 0.5)",
								borderWidth: 1,
								// hoverBackgroundColor: "rgba(92, 199, 50, 0.3)",
								// hoverBorderColor: "rgba(92, 199, 50, 1)",
								// hoverBorderWidth: 1,
								data: resData,
								counts: resCounts,
							});
						}
						if (brData.length > 0) {
							trait.chartData.data.datasets.push({
								label: this.$i18n.t("ReportItemSummary.br"),
								fullName: "Backread",
								xAxisID: this.$i18n.t("ReportItemSummary.sp"),
								yAxisID: this.$i18n.t("ReportItemSummary.freq"),
								backgroundColor: "rgba(212, 148, 0, 0.2)",
								borderColor: "rgba(212, 148, 0, 0.5)",
								borderWidth: 1,
								// hoverBackgroundColor: "rgba(212, 148, 0, 0.3)",
								// hoverBorderColor: "rgba(212, 148, 0, 1)",
								// hoverBorderWidth: 1,
								data: brData,
								counts: brCounts,
							});
						}

						trait.chartData.data.datasets.push({
							label: this.$i18n.t("ReportItemSummary.final"),
							fullName: "Final Score",
							xAxisID: this.$i18n.t("ReportItemSummary.sp"),
							yAxisID: this.$i18n.t("ReportItemSummary.freq"),
							backgroundColor: "rgba(145, 6, 161, 0.2)",
							borderColor: "rgba(145, 6, 161, 0.5)",
							borderWidth: 1,
							data: finalScoreData,
							counts: finalScoreCounts,
						});

						this.$forceUpdate();

						trait.irrDataMatrix = irrDataMatrix;

						//QWK stuff
						var size = (trait.max - trait.min) / trait.step + 1 + trait.condition_codes.length;
						var arr = [];
						for (var i = 0; i < size; i++) {
							arr[i] = [];
						}

						for (var i = 1; i < irrDataMatrix.length; i++) {
							for (var j = 1; j < irrDataMatrix.length; j++) {
								if (!isNaN(irrDataMatrix[i][j].value)) {
									arr[i - 1][j - 1] = irrDataMatrix[i][j].value;
								}
							}
						}
						// console.log(arr)
						// console.log(qwk(arr))

						trait.qwk = this.qwk(arr).toFixed(4);
						trait.lwk = this.lwk(arr).toFixed(4);
						trait.kappa = this.kappa(arr).toFixed(4);

						// var test1 = [
						//     [2, 0, 0],
						//     [0, 2, 1],
						//     [0, 1, 1]
						// ]
						// console.log("TEST " + kappa(test1))
						// console.log("TEST " + lwk(test1))
						// console.log("TEST " + qwk(test1))
					});
				})
				.catch((err) => {
					console.log(err);
					this.running = false;
					notie.error(this.$i18n.t("notie.load_summary_fail"), err);
				});
		},

		getFd(stats, traitId, sp, field) {
			var count = 0;
			var total = 0;
			_.each(stats, (stat) => {
				if (stat.trait_id == traitId) {
					total += stat[field];
					if (stat.score == sp || (stat.score == -1 && stat.condition == sp)) {
						count += stat[field];
					}
				}
			});
			if (total > 0) {
				return {
					label: sp,
					value: (count / total) * 100,
					count: count,
					total: total,
				};
			} else {
				return {
					label: sp,
					none: true,
				};
			}
		},

		qwk(conf_mat) {
			var num_ratings = conf_mat.length;
			var num_scored = this.sum(conf_mat, 0);

			var hist_rater_a = this.sum(conf_mat, 1);
			var hist_rater_b = this.sum(conf_mat, 2);

			var numerator = 0.0;
			var denominator = 0.0;

			for (var i = 0; i < conf_mat.length; i++) {
				for (var j = 0; j < conf_mat.length; j++) {
					var expected = (hist_rater_a[i] * hist_rater_b[j]) / num_scored;
					var d = Math.pow(i - j, 2) / Math.pow(num_ratings - 1, 2);
					numerator += (d * conf_mat[i][j]) / num_scored;
					denominator += (d * expected) / num_scored;
				}
			}

			return 1.0 - numerator / denominator;
		},

		lwk(conf_mat) {
			var num_ratings = conf_mat.length;
			var num_scored = this.sum(conf_mat, 0);

			var hist_rater_a = this.sum(conf_mat, 1);
			var hist_rater_b = this.sum(conf_mat, 2);

			var numerator = 0.0;
			var denominator = 0.0;

			for (var i = 0; i < conf_mat.length; i++) {
				for (var j = 0; j < conf_mat.length; j++) {
					var expected = (hist_rater_a[i] * hist_rater_b[j]) / num_scored;
					var d = Math.abs(i - j) / (num_ratings - 1);
					numerator += (d * conf_mat[i][j]) / num_scored;
					denominator += (d * expected) / num_scored;
				}
			}

			return 1.0 - numerator / denominator;
		},

		kappa(conf_mat) {
			var num_ratings = conf_mat.length;
			var num_scored = this.sum(conf_mat, 0);

			var hist_rater_a = this.sum(conf_mat, 1);
			var hist_rater_b = this.sum(conf_mat, 2);

			var numerator = 0.0;
			var denominator = 0.0;

			for (var i = 0; i < conf_mat.length; i++) {
				for (var j = 0; j < conf_mat.length; j++) {
					var expected = (hist_rater_a[i] * hist_rater_b[j]) / num_scored;
					var d = 1.0;
					if (i == j) {
						d = 0.0;
					}
					numerator += (d * conf_mat[i][j]) / num_scored;
					denominator += (d * expected) / num_scored;
				}
			}

			return 1.0 - numerator / denominator;
		},

		sum(matrix, axis) {
			var result = [];
			//Return scalar sum of the matrix
			if (axis == 0) {
				var total = 0;
				for (var i = 0; i < matrix.length; i++) {
					for (var j = 0; j < matrix.length; j++) {
						total += matrix[i][j];
					}
				}
				return total;
			}

			//Calculate sum for each row and return histogram
			if (axis == 1) {
				for (var i = 0; i < matrix.length; i++) {
					var row = matrix[i];
					var total = 0;
					_.each(row, (entry) => {
						total += entry;
					});
					result[i] = total;
				}
				return result;
			}

			//Calculate sum for each column and return histogram
			if (axis == 2) {
				var mat = Array(matrix.length);
				for (var i = 0; i < matrix.length; i++) {
					mat[i] = Array(matrix.length);
					for (var j = 0; j < matrix.length; j++) {
						mat[i][j] = matrix[j][i];
					}
				}
				return this.sum(mat, 1);
			}
		},

		percentColor(val, t1, t2) {
			return {
				"text-danger": val < t1,
				"text-warning": val >= t1 && val < t2,
				"text-success": val > t2,
			};
		},

		matClick(traitId, firstScore, secondScore) {
			if (!this.user.role.page_auditing) {
				return;
			}
			let comp = this.selectedComp;
			if (!(comp && comp.leftStates && comp.leftStates[0] && comp.rightStates && comp.rightStates[0])) {
				return;
			}

			const { leftStates, rightStates } = comp;
			const allStates = leftStates.concat(rightStates);
			let responseState = null;
			let firstType = leftStates[0];
			let secondType = rightStates[0];
			if (allStates.includes(12) || allStates.includes(50)) {
				firstType = 1;
				secondType = 2;
			}

			if (allStates.includes(50)) {
				responseState = 10; //complete
			}

			const sps = [
				{
					score_type: firstType,
					trait_id: traitId,
					score: firstScore,
				},
				{
					score_type: secondType,
					trait_id: traitId,
					score: secondScore,
				},
			];

			//Set irrelevant things to blank
			store.set(this, "audit.r.selectedFlagType", null);
			store.set(this, "audit.r.selectedFlagReviewType", null);
			store.set(this, "audit.r.selectedState", responseState);
			store.set(this, "audit.r.response_id", "");
			store.set(this, "audit.r.labels", []);
			store.set(this, "audit.r.users", []);
			store.set(this, "audit.r.minScore", null);
			store.set(this, "audit.r.maxScore", null);

			store.set(this, "audit.r.scoreQueryType", 0);
			store.set(this, "audit.r.trait_scores", sps);
			store.set(this, "audit.r.selectedItem", this.selectedItem.id);
			store.set(this, "audit.r.selectedSection", this.selectedSection.id);
			store.set(this, "audit.r.selectedProject", this.selectedSection.project_id);
			store.set(this, "audit.r.score_specific_query", true);
			store.set(this, "audit.r.filter_types", [{ type: firstType }, { type: secondType }]);

			// if (this.selectedUser) {
			// 	store.set(this, "audit.r.users", [this.selectedUser]);
			// }

			this.$router.push("/auditing");
		},

		canRun() {
			return this.selectedSection && this.selectedSection.id && this.selectedItem && this.selectedItem.id;
		},

		printReport() {
			window.print();
		},

		csvExport() {
			const { selectedComp, rubric, selectedItem, selectedSection, fd_data } = this;

			if (!rubric) return;
			const { xAxisLabel, yAxisLabel, label } = selectedComp;
			const { traits } = rubric;

			const scoreLabels = traits[0].chartData.data.datasets[0].counts.map(({ label }) => label);

			const headers = ["Rater Type", "Trait Name", "IRR", "Kappa", "LWK", "QWK"].concat(
				scoreLabels.map((label) => [`Score Count ${label}`, `Score Frequency ${label}`]).flat(1)
			);

			const readTypes = {
				"First & Second": "count",
				"First Read": "fst_count",
				"Second Read": "snd_count",
				Resolution: "res_count",
				Backread: "br_count",
				"Final Score": "final_score_count",
			};

			const allReads = selectedComp.label === "1st/2nd vs. Final Score";

			const reads = allReads
				? Object.entries(readTypes)
				: [
						[xAxisLabel, readTypes[xAxisLabel]],
						[yAxisLabel, readTypes[yAxisLabel]],
				  ];

			const body = traits
				.map(({ id, name, agreement, kappa, lwk, qwk }) =>
					reads.map(([raterType, readType]) => {
						const scores = scoreLabels
							.map((label) => {
								const { count, value, none } = this.getFd(fd_data, id, label, readType);
								if (none) {
									return [0, 0];
								}
								return [count, fs.fixedPercent2d(value)];
							})
							.flat(1);

						let stats = Array(4).fill("N/A");
						if (agreement !== "NaN" && (!allReads || ["count", "final_score_count"].includes(readType))) {
							stats = [fs.fixedPercent2d(parseFloat(agreement)), kappa, lwk, qwk];
						}

						return [raterType, name].concat(stats).concat(scores);
					})
				)
				.flat(1);

			FileService.exportCSV([headers].concat(body), [
				selectedSection.name,
				selectedItem.name,
				label,
				new Date().toLocaleDateString().split("/").join("."),
			]);
		},
	},
};
</script>
