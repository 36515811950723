<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			PSI Observation Counts
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<div class="col-12 col-sm-6">
				<div class="form-group">
					<label> Client </label>
					<v-select :options="clients || []" v-model="selectedClient">
						<template slot="selected-option" slot-scope="option">
							<span>{{ option.name }}</span>
							<span class="ml-1 text-muted text-xxs">({{ option.ref_id }})</span>
						</template>
						<template slot="option" slot-scope="option">
							<span>{{ option.name }}</span>
							<div class="text-muted pb-1" style="line-height: 0.85rem">
								{{ option.ref_id }} • {{ option.id }}
							</div>
						</template>
					</v-select>
				</div>
				<div class="form-group">
					<div class="d-flex flex-row">
						<label>
							{{ $t("ReportScorers.from_date") }}
							<user-timezone />
						</label>
						<div class="ml-auto">
							<button class="btn btn-xs btn-primary" v-tippy title="Previous Month" @click="prevMonth">
								<i class="fas fa-lg fa-caret-left" />
							</button>
							<button class="btn btn-xs btn-primary" v-tippy title="Next Month" @click="nextMonth">
								<i class="fas fa-lg fa-caret-right" />
							</button>
						</div>
					</div>
					<config-date v-model="fromDate" :config="{ showClear: true }"></config-date>
				</div>
				<div class="form-group">
					<label>
						{{ $t("ReportScorers.to_date") }}
						<user-timezone />
					</label>
					<config-date v-model="toDate" :config="{ showClear: true }" defaultTime="11:59 PM"></config-date>
				</div>
				<button v-if="!running" @click="getCounts" class="btn btn-success">Run</button>
				<button v-if="running" class="btn btn-success" disabled>
					<loading type="icon" class="mr-1" />Running...
				</button>
			</div>
			<div v-if="result" class="col-12 col-sm-6">
				<hr class="d-sm-none" />
				<label>Result</label>
				<div class="result-panel p-3 scroll-y" style="max-height: 200px">
					<pre v-if="result" class="form-control mb-0">{{ result }}</pre>
				</div>
				<button v-if="csvData" class="mt-3 btn btn-primary" @click="downloadResult">Download</button>
				<div v-if="filename" class="text-muted">{{ filename }}</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import FileService from "@/services/FileService";
import ConfigDate from "@/components/ConfigDate";
import ConfigSelect from "@/components/ConfigSelect";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "PsiObsCounts",
	props: ["environment", "clients"],
	components: { ConfigDate, ConfigSelect, UserTimezone },

	data() {
		return {
			expanded: Store.get(this, "adminTasks.psiObsCounts.expanded"),
			running: false,

			selectedClient: Store.get(this, "adminTasks.psiObsCounts.selectedClient"),
			fromDate: null,
			toDate: null,

			result: null,
			filename: null,
			csvData: null,
		};
	},
	created() {
		let now = moment();
		now.subtract(1, "week");
		this.fromDate = now.clone().startOf("month");
		this.toDate = now.clone().endOf("month");
	},
	watch: {
		expanded() {
			Store.set(this, "adminTasks.psiObsCounts.expanded", this.expanded);
		},
		fromDate() {
			this.resetResults();
		},
		toDate() {
			this.resetResults();
		},
		selectedClient() {
			Store.set(this, "adminTasks.psiObsCounts.selectedClient", this.selectedClient);
			this.resetResults();
		},
	},
	methods: {
		resetResults() {
			this.result = null;
			this.filename = null;
			this.csvData = null;
		},

		prevMonth() {
			let now = moment(this.fromDate);
			now.subtract(1, "month");
			this.fromDate = now.clone().startOf("month");
			this.toDate = now.clone().endOf("month");
		},

		nextMonth() {
			let now = moment(this.toDate);
			now.add(1, "month");
			this.fromDate = now.clone().startOf("month");
			this.toDate = now.clone().endOf("month");
		},

		getCounts() {
			this.result = null;
			this.running = true;

			let ctx = {
				clientID: this.selectedClient && this.selectedClient.id,
				fromDate: this.fromDate,
				toDate: this.toDate,
			};

			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.post("/v1/admin_tasks/psi_obs_counts", ctx, config)
				.then((r) => {
					let filename = FileService.getAttachmentFilename(r);
					if (filename) {
						this.filename = filename;
						this.csvData = r.data;
						this.result = `CSV File:\n${filename}\n\n` + r.data;
					} else {
						this.result = r.data;
					}

					this.running = false;
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to get PSI Observation Counts", e);
				});
		},

		downloadResult() {
			FileService.downloadText(this.filename, this.csvData);
		},
	},
};
</script>
