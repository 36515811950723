<template>
	<div class="d-flex flex-column flex h100 page-body">
		<div class="navbar teal bg box-shadow">
			<div>
				<a href="#/" class="navbar-brand oscar-logo">
					<img src="static/OSCAR-logo-white-cropped.png" alt="." class="" />
				</a>
			</div>
		</div>
		<div id="content-body">
			<div class="banner"></div>
			<div class="py-2 text-center w-100">
				<div class="mx-auto col-6 w-auto-xs" style="min-width: 320px">
					<div class="px-6 mt-3">
						<div class="box page-shadow">
							<div class="box-body p-5">
								<img
									style="width: 90%; opacity: 0.7"
									class="logo ng-scope pb-3"
									src="static/OSCAR-logo-black-cropped.png"
								/>
								<hr />
								<form @submit.prevent name="emailForm" v-if="!this.token">
									<h3>{{ $t("ResetPW.reset_password") }}</h3>

									<p>{{ $t("ResetPW.will_email_instructions") }}</p>
									<div class="form-group">
										<input
											v-model="email"
											type="email"
											class="form-control form-control-lg"
											:placeholder="$t('ResetPW.email_placeholder')"
											required
										/>
									</div>

									<template v-if="captchaSiteKey != ''">
										<div
											class="my-3 d-flex flex-row justify-content-center"
											style="max-width: 304px"
										>
											<vue-recaptcha
												ref="captcha"
												:sitekey="captchaSiteKey"
												@verify="captchaVerify"
												@expired="captchaExpired"
											>
											</vue-recaptcha>
										</div>
									</template>

									<button
										@click="reset()"
										type="submit"
										class="btn btn-block btn-lg teal"
										:disabled="!email || (captchaSiteKey != '' && !captchaResponseToken)"
									>
										{{ $t("ResetPW.reset_password") }}
									</button>
								</form>
								<form @submit.prevent name="resetForm" v-if="this.token">
									<h3 v-if="newUser">{{ $t("ResetPW.set_password") }}</h3>
									<h3 v-else>{{ $t("ResetPW.reset_password") }}</h3>

									<template v-if="checkingToken">
										<loading type="large" />
									</template>
									<template v-else-if="!validToken">
										<p class="text-danger">{{ $t("ResetPW.link_invalid_or_expired") }}</p>
										<div class="form-group">
											<input
												v-model="email"
												type="email"
												class="form-control form-control-lg"
												:placeholder="$t('ResetPW.email_placeholder')"
												required
												:readonly="requestEmail"
												:class="{ 'text-center': requestEmail }"
											/>
										</div>

										<template v-if="captchaSiteKey != ''">
											<div
												class="my-3 d-flex flex-row justify-content-center"
												style="max-width: 304px"
											>
												<vue-recaptcha
													ref="captcha"
													:sitekey="captchaSiteKey"
													@verify="captchaVerify"
													@expired="captchaExpired"
												>
												</vue-recaptcha>
											</div>
										</template>

										<button @click="reset()" type="submit" class="btn btn-block btn-lg teal">
											{{ $t("ResetPW.resend_email") }}
										</button>
									</template>
									<template v-else>
										<p v-if="newUser">{{ $t("ResetPW.please_enter_password") }}</p>
										<p v-else>{{ $t("ResetPW.please_enter_new_password") }}</p>
										<div
											class="form-group"
											:class="{
												invalid:
													!valid.field('passwordLength') ||
													!valid.field('passwordCharacters'),
											}"
										>
											<label>{{ $t("ResetPW.new_password") }}</label>
											<input
												type="password"
												class="form-control form-control-lg"
												v-model="password"
											/>
										</div>
										<div class="form-group" :class="{ invalid: !valid.field('passwordMatch') }">
											<label>{{ $t("ResetPW.confirm_password") }}</label>
											<input
												type="password"
												class="form-control form-control-lg"
												v-model="password2"
											/>
										</div>

										<button
											v-if="newUser"
											@click="newPW()"
											type="submit"
											class="btn btn-block btn-lg teal mb-2"
											:disabled="!valid.group('reset')"
										>
											{{ $t("ResetPW.set_password") }}
										</button>
										<button
											v-else
											@click="newPW()"
											type="submit"
											class="btn btn-block btn-lg teal mb-2"
											:disabled="!valid.group('reset')"
										>
											{{ $t("ResetPW.reset_password") }}
										</button>

										<div
											class="mt-1 mb-0 text-xs text-muted"
											v-for="error in valid.errors()"
											:key="error"
										>
											{{ error }}
										</div>
									</template>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-3 b-t mt-auto teal">
			{{ $t("Footer.copyright", { year: new Date().getFullYear(), version: version }) }}
		</div>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";
import ValidationService from "@/services/ValidationService";
import notie from "@/services/NotieService";
import pack from "../../../package.json";
import VueRecaptcha from "vue-recaptcha";

export default {
	name: "ResetPW",

	props: ["query"],

	components: { VueRecaptcha },

	data() {
		return {
			checkingToken: false,
			validToken: false,
			token: null,
			email: null,
			password: null,
			password2: null,
			version: pack.version,
			valid: {},
			newUser: false,
			requestEmail: null,

			captchaSiteKey: "",
			captchaResponseToken: null,
		};
	},
	created() {
		this.token = this.$route.query.token;
		this.requestEmail = this.$route.query.email;
		this.newUser = this.$route.path != "/resetpw";
		AuthService.clearState();
		this.initValidation();

		if (this.token) {
			this.checkValidToken();
		}

		AuthService.getCaptchaSiteKey().then((resp) => {
			if (resp.data.site_key) {
				this.captchaSiteKey = resp.data.site_key;
			}
		}).catch(e => {
			this.logError("Failed to get captcha site key", e)
		});
	},
	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				anyPassword: {
					group: "reset",
					errorMsg: this.$i18n.t("ResetPW.must_provide_new_password"),
					func: () => {
						return this.password;
					},
				},
				passwordLength: {
					group: "reset",
					errorMsg: this.$i18n.t("error.password_length"),
					func: () => {
						return !this.password || this.password.length >= 8;
					},
				},
				passwordCharacters: {
					group: "reset",
					errorMsg: this.$i18n.t("error.password_characters"),
					func: () => {
						if (!this.password) return true;
						let hasLower = false;
						let hasUpper = false;
						let hasDigit = false;

						for (let i = 0; i < this.password.length; i++) {
							let charCode = this.password.charCodeAt(i);
							console.log("Check", this.password[i], charCode);
							if (charCode >= 48 && charCode <= 57) {
								hasDigit = true;
							}
							if (charCode >= 65 && charCode <= 90) {
								hasUpper = true;
							}
							if (charCode >= 97 && charCode <= 122) {
								hasLower = true;
							}
						}
						console.log(hasLower, hasUpper, hasDigit);
						return hasLower && hasUpper && hasDigit;
					},
				},
				passwordMatch: {
					group: "reset",
					errorMsg: this.$i18n.t("error.password_match"),
					func: () => {
						return this.password == null || this.password == this.password2;
					},
				},
			});
		},

		checkValidToken() {
			if (!this.token) {
				console.error("No token");
				return;
			}

			this.checkingToken = true;
			AuthService.checkResetToken(this.token)
				.then((r) => {
					this.checkingToken = false;
					this.validToken = r.data;
					this.email = this.requestEmail;
				})
				.catch((e) => {
					this.checkingToken = false;
					console.error(e);
					notie.error("Could not verify reset token", e);
				});
		},

		reset() {
			var _this = this;
			AuthService.resetPassword(this.email, this.captchaResponseToken)
				.then((resp) => {
					this.$router.push("/");
					notie.success(
						this.$i18n.t("notie.reset_email_sent", { email: this.email }),
						this.$i18n.t("notie.check_email_to_continue")
					);
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.invalid_email_to_reset"), err);
					if (this.$refs && this.$refs.captcha) {
						this.$refs.captcha.reset();
					} else {
						console.error("Captcha couldn't be reset, no element found");
					}
					this.captchaResponseToken = null;
				});
		},

		newPW() {
			AuthService.setNewPassword(this.token, this.password)
				.then((resp) => {
					this.$router.push("/");
					if (this.newUser) {
						notie.success(
							this.$i18n.t("notie.password_set"),
							this.$i18n.t("notie.user_email_and_password")
						);
					} else {
						notie.success(this.$i18n.t("notie.password_reset"), this.$i18n.t("notie.new_password"));
					}
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.failed_to_reset_password"), err);
				});
		},

		captchaVerify(responseToken) {
			this.captchaResponseToken = responseToken;
		},
		captchaExpired() {
			this.captchaResponseToken = null;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
