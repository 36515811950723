import axios from "axios";

export default {
	getTenants() {
		return axios.get(`/v1/admin/previewer/tenants`)
	},

	getItemsWithDetails(tenantID) {
		return axios.get(`/v1/admin/previewer/tenant/${tenantID}/items+details`)
	},

	getTestmaps(tenantID) {
		return axios.get(`/v1/admin/previewer/tenant/${tenantID}/testmaps`)
	},

	getTestmapItems(tenantID, tmIdentifier, formID) {
		return axios.get(`/v1/admin/previewer/tenant/${tenantID}/testmap/${tmIdentifier}/${formID}/items`)
	},

	getTestmapItemsWithDetails(tenantID, tmIdentifier, formID) {
		return axios.get(`/v1/admin/previewer/tenant/${tenantID}/testmap/${tmIdentifier}/${formID}/items+details`)
	},

	importItemSet(options) {
		return axios.post(`/v1/admin/previewer/import_item_set`, options)
	},

	increment() {
		return axios.get(`/v1/admin/previewer/increment`)
	}
};
