<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("Profile.name_profile", { full_name: full_name }) }}
				</div>
			</div>
		</template>
		<div class="h-100" v-if="!loading">
			<div class="d-sm-flex b-t h-100">
				<div class="w w-auto-xs light bg b-r">
					<div class="py-3">
						<div class="nav-active-border left b-primary">
							<ul class="nav flex-column nav-sm">
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 1, show: tab == 1 }"
										data-toggle="tab"
										data-target="#tab-1"
									>
										{{ $t("Profile.personal_details") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('details') }"
										></i>
									</a>
								</li>
								<!-- <li class="nav-item">
												<a class="nav-link" :class="{active: tab == 2, show: tab == 2}" data-toggle="tab" data-target="#tab-2">
													Settings
													<i class="valid-hint text fa fa-angle-left" :class="{invalid: !valid.group('settings')}"></i>
												</a>
											</li>-->
							</ul>
						</div>
					</div>
				</div>

				<edit-pane :cancel="loadData" :save="saveProfile" :dirty="dirty" :valid="valid">
					<div class="tab-content pos-rlt">
						<!-- User -->
						<div id="tab-1" class="tab-pane" :class="{ active: tab == 1, show: tab == 1 }">
							<div class="p-4 b-b _600 nav-title">{{ $t("Profile.personal_details") }}</div>
							<div class="col-md-10 col-12">
								<div class="row m-0 pt-4">
									<div class="col-sm-6 col-12">
										<div class="form-group" :class="{ invalid: !valid.field('name') }">
											<label>{{ $t("fields.first_name") }}</label>
											<input
												type="text"
												class="form-control"
												v-model="profile.first_name"
												maxlength="64"
											/>
										</div>
									</div>
									<div class="col-sm-6 col-12">
										<div class="form-group" :class="{ invalid: !valid.field('name') }">
											<label>{{ $t("fields.last_name") }}</label>
											<input
												type="text"
												class="form-control"
												v-model="profile.last_name"
												maxlength="64"
											/>
										</div>
									</div>
								</div>
								<div class="row m-0">
									<div class="col-12 col-sm-6 form-group" :class="{ invalid: !valid.field('email') }">
										<label>{{ $t("fields.email") }}</label>
										<input
											type="text"
											class="form-control"
											v-model="profile.email"
											maxlength="128"
										/>
									</div>
									<div v-if="profile.scorer_id" class="col-12 col-sm-6 form-group">
										<label>{{ $t("fields.scorer_id") }}</label>
										<input
											type="text"
											class="form-control"
											v-model="profile.scorer_id"
											readonly
											maxlength="64"
										/>
									</div>
								</div>
								<div class="row m-0">
									<div class="col-12 col-sm-6 form-group">
										<div class="checkbox mr-3">
											<label
												class="md-check"
												v-tippy="{ theme: 'popover', placement: 'bottom-start' }"
												:title="$t('Profile.prevents_email_notifications')"
											>
												<input v-model="profile.suppress_emails" type="checkbox" />
												<i class="theme-accent"></i>
												{{ $t("Profile.suppress_email_notifications") }}
											</label>
										</div>
									</div>
									<div class="col-12 col-sm-6 form-group">
										<label>{{ $t("TenantEdit.Setup.language") }}</label>
										<v-select :options="langs" v-model="locale"></v-select>
									</div>
								</div>

								<div class="col-12 col-sm-6 form-group">
									<button class="btn theme-accent btn-block" @click="togglePass">
										{{ $t("UserEdit.set_password") }}
										<i
											class="far fa-fw fa-angle-up anim-rotate"
											:class="{ 'rotate-180': setPass }"
										></i>
									</button>
								</div>
								<div class="col-12 col-sm-6"></div>
								<div class="row m-0 w-100" v-show="setPass">
									<div class="col-12 col-sm-6 form-group">
										<label>{{ $t("Profile.current_password") }}</label>
										<input
											type="password"
											class="form-control"
											v-model="profile.current_password"
											autocomplete="new-password"
										/>
									</div>
									<div
										class="col-12 col-sm-6 form-group d-flex flex-column justify-content-end"
									></div>
									<div
										class="col-12 col-sm-6 form-group"
										:class="{
											invalid:
												!valid.field('passwordLength') || !valid.field('passwordCharacters'),
										}"
									>
										<label>{{ $t("Profile.new_password") }}</label>
										<input
											type="password"
											class="form-control"
											v-model="profile.password"
											autocomplete="new-password"
											maxlength="128"
										/>
									</div>
									<div class="col-12 col-sm-6 form-group d-flex flex-column justify-content-end">
										<div style="min-height: 28px">
											<div
												class="my-0 text-xs text-muted"
												v-for="error in valid.errors(['passwordLength', 'passwordCharacters'])"
												:key="error"
											>
												{{ error }}
											</div>
										</div>
									</div>
									<div
										class="col-12 col-sm-6 form-group"
										:class="{ invalid: !valid.field('passwordMatch') }"
									>
										<label>{{ $t("Profile.confirm_password") }}</label>
										<input
											type="password"
											class="form-control"
											v-model="password2"
											autocomplete="new-password"
											maxlength="128"
										/>
									</div>
									<div class="col-12 col-sm-6 form-group d-flex flex-column justify-content-end">
										<div
											class="mt-1 mb-0 text-xs text-muted"
											v-for="error in valid.errors('passwordMatch')"
											:key="error"
											style="min-height: 28px"
										>
											{{ error }}
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Settings -->
						<div id="tab-2" class="tab-pane" :class="{ active: tab == 2, show: tab == 2 }">
							<div class="p-4 b-b _600 nav-title">{{ $t("Profile.settings") }}</div>
							<form role="form" class="p-4 col-sm-6">
								<div class="form-group">
									<label>{{ $t("Profile.more_settings_here") }}</label>
									<input type="text" class="form-control" v-model="profile.more" />
								</div>
							</form>
						</div>
					</div>
				</edit-pane>
				<save-optional-modal
					:dirty="dirty"
					:valid="valid"
					:save="saveProfile"
					:next="saveOptNext"
					:cancel="saveOptCancel"
					objectText="Profile"
					:objectName="full_name"
					actionText="leave the page"
					v-model="saveOptModal"
				/>
			</div>
		</div>
	</page>
</template>

<style scoped>
</style>

<script>
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import fs from "@/services/FormatService";
import UserService from "@/services/UserService";
import ValidationService from "@/services/ValidationService";
import notie from "@/services/NotieService";
import Utils from "@/services/Utils";

export default {
	name: "Profile",

	props: ["user", "params", "query"],

	components: { EditPane, SaveOptionalModal },

	data() {
		return {
			tab: 1,
			mut_user: null,
			profile: {
				password: "",
				current_password: "",
			},
			langs: fs.langs,
			locale: null,
			password2: "",
			setPass: false,
			dirty: false,
			loading: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	created() {
		this.mut_user = _.cloneDeep(this.user);
		this.loadData();
		this.initValidation();

		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {
		"profile.email"() {
			let trimmed = this.profile.email.replace(/\s/g, "");
			if (trimmed != this.profile.email) {
				this.profile.email = trimmed;
			}
		},
		locale() {
			if (this.locale && this.profile.locale != this.locale.string) {
				this.profile.locale = this.locale.string;
			}
		},
	},

	computed: {
		full_name() {
			if (this.profile.first_name && this.profile.first_name != "") {
				if (this.profile.last_name && this.profile.last_name != "") {
					return `${this.profile.first_name} ${this.profile.last_name}`;
				} else {
					return this.profile.first_name;
				}
			} else if (this.profile.last_name && this.profile.last_name != "") {
				return this.profile.last_name;
			} else {
				return "____";
			}
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: this.$i18n.t("error.must_assign_first_or_last"),
					func: () => {
						return (
							this.profile &&
							((this.profile.first_name && this.profile.first_name != "") ||
								(this.profile.last_name && this.profile.last_name != ""))
						);
					},
				},
				email: {
					group: "details",
					errorMsg: this.$i18n.t("error.must_assign_valid_email"),
					func: () => {
						return this.profile && this.profile.email && this.profile.email.match(/.+\@.+\..+/);
					},
				},
				passwordLength: {
					group: "details",
					errorMsg: this.$i18n.t("error.password_length"),
					func: () => {
						return (
							this.profile &&
							(!this.profile.password || this.profile.password == "" || this.profile.password.length >= 8)
						);
					},
				},
				passwordCharacters: {
					group: "details",
					errorMsg: this.$i18n.t("error.password_characters"),
					func: () => {
						if (!(this.profile && this.profile.password)) return true;
						let hasLower = false;
						let hasUpper = false;
						let hasDigit = false;

						for (let i = 0; i < this.profile.password.length; i++) {
							let charCode = this.profile.password.charCodeAt(i);
							if (charCode >= 48 && charCode <= 57) {
								hasDigit = true;
							}
							if (charCode >= 65 && charCode <= 90) {
								hasUpper = true;
							}
							if (charCode >= 97 && charCode <= 122) {
								hasLower = true;
							}
						}
						return hasLower && hasUpper && hasDigit;
					},
				},
				passwordMatch: {
					group: "details",
					errorMsg: this.$i18n.t("error.your_passwords_must_match"),
					func: () => {
						return (
							this.profile &&
							(!this.profile.password ||
								this.profile.password == "" ||
								this.profile.password == this.password2)
						);
					},
				},
			});
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"profile",
				(newc, old) => {
					// console.log(Utils.diff(newc, old));
					// console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			var dummyPromise = Promise.resolve();

			return dummyPromise.then(() => {
				this.dirty = false;
				this.profile = _.cloneDeep(this.mut_user);
				this.$set(this.profile, "password", "");
				this.$set(this.profile, "current_password", "");
				this.password2 = "";
				this.setPass = false;
				if (!this.profile.locale) {
					this.profile.locale = this.profile.client.locale;
				}
				this.locale = _.find(this.langs, {
					string: this.profile.locale,
				});
				this.watchChanges();
			});
		},

		saveProfile() {
			return UserService.updateProfile(this.profile)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.profile_updated"));
					this.profile.locale = this.locale.string;
					this.mut_user = this.profile;
					this.loadData();
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.update_profile_fail"), err);
				});
		},

		togglePass() {
			this.setPass = !this.setPass;
		},
	},
};
</script>
