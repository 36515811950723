<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("ProjectSetup.title") }}</div>

				<!-- Show hidden -->
				<div class="checkbox mr-3" style="padding-right: 3.5em">
					<label class="md-check" v-tippy :title="$t('tooltip.show_hidden_projects')">
						<input v-model="showHiddenProjects" type="checkbox" />
						<i class="theme-accent"></i>
						{{ $t("ProjectSetup.show_hidden_projects") }}
					</label>
				</div>

				<!-- Show inactive -->
				<div class="checkbox mr-3">
					<label class="md-check">
						<input v-model="showInactive" type="checkbox" />
						<i class="theme-accent"></i>
						{{ $t("ProjectSetup.show_inactive") }}
					</label>
				</div>

				<!-- Create new -->
				<div class="py-0">
					<a href="#/projects/new" class="btn btn-sm theme-accent btn-block">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("ProjectSetup.new_project") }}</span>
					</a>
				</div>

				<!-- Search field -->
				<div>
					<div class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('ProjectSetup.search_placeholder')"
						/>
					</div>
				</div>
			</div>
		</template>

		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="table_fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Archive -->
					<template #cell(hidden)="data">
						<div class="mx-auto w40">
							<label class="ui-switch ui-switch-md theme-accent">
								<input @change="setHidden(data.item)" type="checkbox" v-model="data.item.hidden" />
								<i></i>
							</label>
						</div>
					</template>

					<!-- Active -->
					<template #cell(active)="data">
						<div class="mx-auto w40">
							<label class="ui-switch ui-switch-md theme-accent">
								<input @change="setActive(data.item)" type="checkbox" v-model="data.item.active" />
								<i></i>
							</label>
						</div>
					</template>

					<!-- Actions -->
					<template #cell(actions)="data">
						<nobr>
							<div class="mx-auto w60">
								<a
									:href="'#/projects/' + data.item.id"
									class="btn btn-sm btn-icon btn-rounded hover-darken theme-accent m-0"
									v-tippy
									:title="$t('tooltip.edit')"
								>
									<i class="far fa-edit"></i>
								</a>
								<button
									v-if="!data.item.inProgress"
									@click="deleting = data.item"
									class="btn btn-sm btn-icon btn-rounded hover-darken red text-white m-0"
									v-tippy
									:title="$t('tooltip.delete')"
								>
									<i class="far fa-trash"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Removing..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>

		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("ProjectSetup.delete_project_modal.title") }}</template>
			<div class="row p-4">
				{{ $t("ProjectSetup.delete_project_modal.confirm_delete") }}&nbsp;
				<strong class="text-theme">{{ deleting.name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">
					{{ $t("ProjectSetup.delete_project_modal.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="removeProject(deleting)">
					{{ $t("ProjectSetup.delete_project_modal.delete") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<style scoped>
.w60 {
	width: 60px;
}
.w40 {
	width: 40px;
}
.w30 {
	width: 30px;
}
</style>

<script>
import ProjectService from "@/services/ProjectService";
import notie from "@/services/NotieService";
import store from "@/services/Store";

export default {
	name: "ProjectSetup",

	props: ["user"],

	data() {
		return {
			table_fields: [
				{ key: "name", label: this.$i18n.t("fields.name"), sortable: true },
				{ key: "desc", label: this.$i18n.t("fields.description"), sortable: true },
				{
					key: "hidden",
					label: this.$i18n.t("fields.hidden"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid switch-fix",
					sortable: true,
				},
				{
					key: "active",
					label: this.$i18n.t("fields.active"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid switch-fix",
					sortable: true,
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				},
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			sortBy: null,
			sortDesc: false,
			showInactive: true,
			loading: true,

			deleting: false,
			showHiddenProjects: true,
		};
	},

	created() {
		this.sortBy = store.get(this, "projects.sortBy");
		this.sortDesc = store.getDefault(this, "projects.sortDesc", false);
		this.showInactive = store.getDefault(this, "projects.showInactive", true);
		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects", true);
	},

	watch: {
		sortBy() {
			store.set(this, "projects.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "projects.sortDesc", this.sortDesc);
		},
		showInactive() {
			store.set(this, "projects.showInactive", this.showInactive);
			this.refreshTable();
		},
		showHiddenProjects() {
			store.set(this, "projects.showHiddenProjects", this.showHiddenProjects);
			this.refreshTable();
		},
	},

	methods: {
		provider(ctx) {
			ctx.active = !this.showInactive;
			ctx.showHiddenProjects = this.showHiddenProjects;
			return ProjectService.listProjects(ctx)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.loading = false;
					return resp.data.projects;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_projects_fail"), err);
					this.loading = false;
					return [];
				});
		},

		setHidden(project) {
			var hiddenState = (project.hidden && "hidden") || "not hidden";
			ProjectService.saveProject(project)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.project_set_to", { name: project.name, activeState: hiddenState }));
				})
				.catch((err) => {
					this.$i18n.t("notie.save_project_fail");
				});
		},

		setActive(project) {
			var activeState = (project.active && "active") || "inactive";
			ProjectService.saveProject(project)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.project_set_to", { name: project.name, activeState: activeState }));
				})
				.catch((err) => {
					this.$i18n.t("notie.save_project_fail");
				});
		},

		removeProject(project) {
			project.inProgress = true;
			ProjectService.removeProject(project.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.project_deleted"));
					this.refreshTable();
					project.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.delete_project_fail"), err);
					project.inProgress = false;
					this.$forceUpdate();
				});
			this.deleting = false;
		},

		refreshTable() {
			this.$refs.table.refresh();
		},
	},
};
</script>
