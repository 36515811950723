<template>
	<div class="theme-accent">
		<h6 class="pr-2 text-center">{{ $t("ItemEdit.Scoring.options.custom_adj") }}</h6>
		<b-container>
			<b-row>
				<b-col
					><b>{{ $t("fields.trait") }}</b></b-col
				>
				<b-col
					><b>{{ $t("fields.adjacency") }}</b></b-col
				>
			</b-row>
			<b-row
				v-for="traitAdj in item.custom_adjacency"
				:key="traitAdj.trait_id"
				class="py-2 border-bottom text-secondary"
			>
				<b-col
					><input class="form-control" :value="getTraitName(traitAdj.trait_id)" type="text" readonly
				/></b-col>
				<b-col
					><input
						:key="invalidAdjacency(traitAdj.adjacency)"
						v-tippy
						:title="invalidAdjacency(traitAdj.adjacency) && 'Invalid Adjacency'"
						class="form-control"
						:class="{ 'invalid-adjacency': invalidAdjacency(traitAdj.adjacency) }"
						type="number"
						v-model.number="traitAdj.adjacency"
				/></b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
	name: "CustomAdj",

	props: {
		item: Object,
	},

	methods: {
		getTraitName(trait_id) {
			const {
				item: { rubric },
			} = this;
			if (!rubric) return "";
			const trait = rubric.traits.find(({ id }) => trait_id === id);
			return (trait && trait.name) || "";
		},

		invalidAdjacency(adj) {
			return !(Number(adj) > 0);
		},
	},
};
</script>

<style>
.invalid-adjacency {
	border: solid;
	border-width: 1px;
	border-color: red;
}
</style>
