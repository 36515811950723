import axios from "axios";
var moment = require("moment-timezone");

export default {
	listProjects(ctx) {
		return axios.get("/v1/admin/projects", { params: ctx });
	},

	listAllProjects() {
		return axios.get("/v1/admin/projects/all");
	},

	getItemsForProject(projectID) {
		return axios.get(`/v1/admin/project/${projectID}/items`);
	},

	getProjectsForItem(itemID) {
		return axios.get(`/v1/admin/project_items/${itemID}`);
	},

	getIeaAdmin(projectID) {
		return axios.get(`/v1/admin/project/${projectID}/iea_admin`);
	},

	reinitIeaAdmin(projectID) {
		return axios.get(`/v1/admin/project/${projectID}/iea_reinit`);
	},

	getProject(id) {
		console.log("getProject");
		console.log(id);
		return axios.get(`/v1/admin/project/${id}`);
	},

	saveProject(project) {
		return axios.post("/v1/admin/project", project);
	},

	removeProject(id) {
		return axios.delete(`/v1/admin/project/${id}`);
	},

	isProjectAvailable(id) {
		return axios.get(`/v1/project/available/${id}`);
	},

	getProjectAvailability(id) {
		return axios.get(`/v1/project/availability/${id}`);
	},

	checkIfAvailable(project, timezone) {
		if (!timezone) {
			console.error("checking project availability with no time zone!");
			timezone = "America/New_York";
		}
		moment.tz.setDefault(timezone);
		const dateFormat = "MMMM DD, YYYY";
		const timeFormat = "h:mm A";

		let now = moment();
		let start = moment(project.scoring_start, dateFormat);
		let end = moment(project.scoring_end, dateFormat);
		let weekday = now.day();

		let allowedDate = true;
		if (project.enforce_dates) {
			allowedDate = false;
			if (now.isAfter(start) && now.isBefore(end.add(24, "hours"))) {
				allowedDate = true;
			}
		}

		let allowedTime = true;
		if (project.enforce_times) {
			allowedTime = false;
			_.each(project.avail_times, (day) => {
				if (day.day == 7 || day.day == weekday) {
					let start_time = moment(day.start, timeFormat);
					let end_time = moment(day.end, timeFormat);
					let day_start = moment();
					day_start.hour(start_time.hour());
					day_start.minute(start_time.minute());
					let day_end = moment();
					day_end.hour(end_time.hour());
					day_end.minute(end_time.minute());

					if (now.isAfter(day_start) && now.isBefore(day_end)) {
						allowedTime = true;
					}
				}
			});
		}

		return allowedDate && allowedTime;
	},

	// Angular service
	// service.listProjects = function (query) {
	//     var url = "/v1/admin/projects";
	//     if (query) {
	//         url += "?query=" + query;
	//     }
	//     return serviceUtil.makePromise($http.get(url, serviceUtil.makeHeader()))
	// };

	// //List Project Items
	// service.listProjectsItems = function (query) {
	//     var url = "/v1/admin/projects/items";
	//     if (query) {
	//         url += "?query=" + query;
	//     }
	//     return serviceUtil.makePromise($http.get(url, serviceUtil.makeHeader()))
	// };

	// service.saveProject = function (resp) {
	//     var url = "/v1/admin/project";
	//     return serviceUtil.makePromise($http.post(url, resp, serviceUtil.makeHeader()))
	// };

	// service.removeProject = function (id) {
	//     var url = "/v1/admin/project/" + id;
	//     return serviceUtil.makePromise($http.delete(url, serviceUtil.makeHeader()))
	// };
};
