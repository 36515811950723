<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("SiteConfig.details_title") }}</div>
		<div role="form" class="p-4 col-sm-6">
			<div class="form-group">
				<label>{{ $t("SiteConfig.location_code") }}</label>
				<input type="text" class="form-control" v-model="site.external_id" />
			</div>
			<div class="form-group" :class="{ invalid: !valid.field('name') }">
				<label>{{ $t("SiteConfig.name") }}</label>
				<input type="text" class="form-control" v-model="site.name" />
			</div>
			<div class="form-group">
				<label>{{ $t("SiteConfig.location") }}</label>
				<input type="text" class="form-control" v-model="site.location" />
			</div>
			<div class="row">
				<div class="col-12 col-sm-6 form-group" :class="{ invalid: !valid.field('seats') }">
					<label>{{ $t("SiteConfig.seats") }}</label>
					<input type="number" class="form-control" v-model.number="site.seats" />
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
export default {
	name: "SiteEditDetails",

	props: ["id", "site", "defaultTab", "valid"],

	data() {
		return {};
	},

	watch: {},

	created() {},

	methods: {},
};
</script>
