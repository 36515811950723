import axios from "axios";

export default {
	listTeams(ctx) {
		return axios.get("/v1/admin/teams", { params: ctx });
	},

	listTeamsWithUsers(ctx) {
		ctx.users = "y";
		return axios.get("/v1/admin/teams", { params: ctx });
	},

	getTeam(id) {
		return axios.get(`/v1/admin/team/${id}`);
	},

	addUser(teamId, userId) {
		return axios.post(`/v1/admin/teamr/${teamId}/add/${userId}`);
	},

	removeUser(teamId, userId) {
		return axios.post(`/v1/admin/teamr/${teamId}/remove/${userId}`);
	},

	saveTeam(team) {
		return axios.post("/v1/admin/team", team);
	},

	removeTeam(id) {
		return axios.delete(`/v1/admin/team/${id}`);
	},

	getTeamUsers(teamId, ctx) {
		return axios.get(`/v1/admin/team/${teamId}/users`, { params: ctx });
	},

	setTeamUsers(teamId, users) {
		return axios.post(`/v1/admin/team/${teamId}/users`, {
			users: users
		});
	}

	// Is this used?
	// fetchTeam(projectId) {
	// 	return axios.get(`/v1/admin/team/${projectId}`)
	// }
};
