<!-- Template for a new page -->
<template>
	<div class="d-flex flex-column justify-content-start flex h-100">
		<div class="top-bar theme d-flex flex-row align-items-center justify-content-center px-3">
			<div v-if="meeting" class="meeting-title"><span class="text-muted">Left:</span> {{ meeting.name }}</div>
		</div>
		<div
			class="mt-4 d-flex flex-row justify-content-center align-items-start w-100 px-5 py-3 scroll-y"
			style="overflow: hidden"
		>
			<div class="d-flex flex-column scroll-y" style="width: 532px; max-height: 100%">
				<div v-if="this.uiState.leftMeetingMessage" class="meeting-panel preview-container border mb-4">
					<div class="meeting-panel-header text-white" style="background-color: var(--danger)">Meeting Ended</div>
					<h6 class="meeting-panel-content my-2">
						{{ this.uiState.leftMeetingMessage }}
					</h6>
				</div>
				<div class="meeting-panel preview-container border">
					<div class="meeting-panel-header theme text-white">Feedback</div>
					<div class="meeting-panel-content">
						<template v-if="submitted">
							<div class="d-flex flex-column align-items-center w-100">
								<div class="big-check theme my-4">
									<i class="material-icons">check</i>
								</div>
								<h3 class="text-center">Thank you for helping to improve OSCAR!</h3>
							</div>
						</template>
						<template v-else>
							<h3>How was your experience?</h3>
							<div class="trait-row">
								<button
									@click="score = 1"
									class="score-point btn waves-effect waves-light"
									:class="{ active: score == 1 }"
								>
									1
								</button>
								<button
									@click="score = 2"
									class="score-point btn waves-effect waves-light"
									:class="{ active: score == 2 }"
								>
									2
								</button>
								<button
									@click="score = 3"
									class="score-point btn waves-effect waves-light"
									:class="{ active: score == 3 }"
								>
									3
								</button>
								<button
									@click="score = 4"
									class="score-point btn waves-effect waves-light"
									:class="{ active: score == 4 }"
								>
									4
								</button>
								<button
									@click="score = 5"
									class="score-point btn waves-effect waves-light"
									:class="{ active: score == 5 }"
								>
									5
								</button>
							</div>
							<template v-if="score && !submitted">
								<label class="mt-2">Feedback</label>
								<textarea
									class="form-control"
									rows="4"
									v-model="feedback"
									placeholder="If you experienced any issues, please describe them"
								>
								</textarea>

								<button
									@click="submit"
									v-tippy="{ delay: [500, 0], placement: 'bottom' }"
									:title="$t('tooltip.submit_score')"
									:disabled="submitting"
									class="float-right mt-2 md-btn md-fab success mb-2"
								>
									<loading v-if="submitting" type="icon" />
									<i v-else class="material-icons pt-2">check</i>
								</button>
							</template>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!uiState.noRejoin" class="bottom-bar my-4 d-flex flex-row justify-content-center align-items-center">
			<button class="btn btn-large px-4 btn-primary" @click="refresh">
				{{ $t("VideoMeeting.rejoin_meeting") }}
			</button>
		</div>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-bar {
	min-height: 3.5rem;
	width: 100%;
}
.meeting-title {
	font-size: 20px;
	text-align: center;
}
.bottom-bar {
	width: 100%;
}
.btn-large {
	font-size: 24px;
	font-weight: 300;
}
.trait-row {
	height: 100px;
}
.trait-row button {
	font-size: 1.5rem !important;
}
.big-check {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.big-check i.material-icons {
	font-size: 64px;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import VideoMeetingService from "@/services/VideoMeetingService";
import Notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";

export default {
	name: "Postcall",
	props: ["meeting", "uiState", "localParticipant"],
	components: {},
	data() {
		return {
			score: 0,
			submitting: false,
			submitted: false,
			feedback: "",
		};
	},

	created() {
		if (this.engine && this.engine.destroy) {
			this.engine.destroy();
		}
	},

	destroyed() {},

	watch: {},

	methods: {
		async submit() {
			this.log("Submitting feedback", this.score, this.feedback);
			let msg = {
				meeting_id: this.meeting.id,
				user_id: this.localParticipant.userId,
				score: this.score,
				feedback: this.feedback,
			};
			this.submitting = true;
			try {
				await VideoMeetingService.submitFeedback(msg);
			} catch (e) {
				this.logError("Failed to submit feedback", e);
				Notie.error("Failed to submit feedback", e);
			}
			this.submitting = false;
			this.submitted = true;
		},

		refresh() {
			window.location.reload(true);
		},

		close() {},
	},
};
</script>
