/**
 * @description
 * Service for validating config objects
 *
 * Validation options follow this format:
 *
 * name: {             //A name for the validation check
 *     group: "x",     //A group name for the validation check, to group validators into groups
 * 	   errorMsg: "y",  //The error message to be displayed if the validator fails its check
 *     func: () => {   //Function to determine whether the check passes or fails
 *         return x
 * 	   }
 * }
 */

class Validator {
	constructor(validators) {
		this.validators = validators || {};
	}

	//Returns whether the validator of the given name passes its check
	field(name) {
		return this.validators[name] && this.validators[name].func();
	}

	//Returns whether all validators in the given group pass their checks
	group(group) {
		var allValid = true;
		_.each(this.validators, va => {
			if (va.group == group) {
				allValid = allValid && va.func();
			}
		});
		return allValid;
	}

	//Returns whether all defined validators pass their checks
	all() {
		var allValid = true;
		_.each(this.validators, va => {
			allValid = allValid && va.func();
		});
		return allValid;
	}

	//Return an array of error messages for all validators that do not pass their checks
	errors(fields) {
		var errors = [];
		if (fields && typeof fields != "object") {
			fields = [fields];
		}
		_.each(this.validators, (v, k) => {
			if (!v.func()) {
				if (!fields || _.includes(fields, k)) {
					errors.push(v.errorMsg);
				}
			}
		});
		return errors;
	}

	// Return an array of error messages for validators in the specified group that do not pass their checks
	groupErrors(group) {
		var errors = [];
		_.each(this.validators, va => {
			if (va.group == group && !va.func()) {
				errors.push(va.errorMsg);
			}
		});
		return errors;
	}
}

export default {
	newValidator(validators) {
		return new Validator(validators);
	}
};
