//DrawingService
export default {
	//Methods for angle tool
	drawCrossPoint(ctx, x, y) {
		ctx.rect(x - 10, y - 2, 18, 2);
		ctx.rect(x - 2, y - 10, 2, 18);
		ctx.fillStyle = "red";
		ctx.fill();
	},

	drawLine(ctx, start, end) {
		ctx.beginPath();
		ctx.moveTo(start.x, start.y);
		ctx.lineTo(end.x, end.y);
		ctx.strokeStyle = "red";
		ctx.stroke();
	},

	drawText(ctx, txt, x, y, limitx, limity) {
		/// lets save current state as we make a lot of changes
		ctx.save();

		/// set font
		ctx.font = "30px Arial";

		/// draw text from top - makes life easier at the moment
		ctx.textBaseline = "top";

		/// color for background
		ctx.fillStyle = "#ffffff";

		/// get dimensions of text
		var dims = ctx.measureText(txt);
		var width = dims.width;
		var height = 30; //For 30 point font

		/// Adjust coordinates if text would be drawn off the canvas
		if (limitx && x + width > limitx) {
			x = limitx - width;
		}
		if (limity && y + height > limity) {
			y = limity - height;
		}

		/// draw background rect assuming height of font
		ctx.fillRect(x, y, width, 30);

		/// text color
		ctx.fillStyle = "red";

		/// draw text on top
		ctx.fillText(txt, x, y + 2);

		/// restore original state
		ctx.restore();
	},

	find_angle(p0, p1, c) {
		var p0c = Math.sqrt(Math.pow(c.x - p0.x, 2) + Math.pow(c.y - p0.y, 2)); // p0->c (b)
		var p1c = Math.sqrt(Math.pow(c.x - p1.x, 2) + Math.pow(c.y - p1.y, 2)); // p1->c (a)
		var p0p1 = Math.sqrt(
			Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2)
		); // p0->p1 (c)
		return Math.acos(
			(p1c * p1c + p0c * p0c - p0p1 * p0p1) / (2 * p1c * p0c)
		);
	},

	find_distance(p0, p1, zoomScale, item) {
		var rawDistance = Math.sqrt(
			Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2)
		);
		console.log("rawDistance:", rawDistance);
		var scaledDistance = rawDistance / zoomScale;
		console.log("totalScale:", zoomScale);
		console.log("scaledDistance:", scaledDistance);
		var dpi = 72;
		if (item && item.ruler_dpi) {
			dpi = item.ruler_dpi;
		}
		var units = scaledDistance / dpi;
		console.log("dpi:", dpi);
		console.log(item.ruler_units, units);
		return units;
	},

	calculateAngle(ctx, vert, one, two) {
		var lenV1 = len(vert, one);
		var lenV2 = len(vert, two);
		var len12 = len(one, two);

		console.log(lenV1, lenV2, len12);

		var top = Math.pow(lenV1, 2) + Math.pow(lenV2) - Math.pow(len12);
		var bottom = 2 * lenV1 * lenV2;
		return Math.acos(top / bottom);
	},

	len(point1, point2) {
		return Math.sqrt(
			Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
		);
	}
};
