<template>
	<div class="checkbox form-group">
		<label class="md-check" :class="disabled && 'text-muted'" v-tippy="{ theme: 'popover' }" :title="tooltipText">
			<input v-if="checkedIfChildIs" v-model="childChecked" type="checkbox" :disabled="disabled || !hasAccess" />
			<input v-else v-model="sRole[sRoleKey]" type="checkbox" :disabled="disabled || !hasAccess" />
			<i class="theme-accent"></i>
			{{ label }}
			<br />
			<small v-if="limitsAccess" class="text-muted pl-1">limits access</small>
		</label>
		<slot />
		<div v-if="children.length > 0" class="pt-3 pl-3">
			<permissions-check-box
				v-for="child in children"
				v-bind="child"
				:hasAccess="hasAccess"
				:sRole="sRole"
				:key="child.value"
				:client="client"
			>
				<component v-if="child.slot" :is="child.slot" :client="client" />
			</permissions-check-box>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
export default {
	name: "PermissionsCheckBox",

	props: {
		label: String,
		hasAccess: Boolean,
		disabled: Boolean,
		tooltipText: String,
		sRole: Object,
		sRoleKey: String,
		limitsAccess: Boolean,
		children: { type: Array, default: () => [] },
		checkedIfChildIs: Boolean,
		client: Object,
	},

	computed: {
		childChecked() {
			const { sRole, children } = this;
			return children.some(({ sRoleKey }) => sRole[sRoleKey]);
		},
	},
};
</script>
