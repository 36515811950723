import axios from "axios";
import ConfigSheetService from "@/services/ConfigSheetService";
import _ from "lodash"

//AuthService
export default {
	listClients(ctx) {
		return axios.get("/v1/admin/clients", { params: ctx });
	},

	getClient(clientId) {
		if (!clientId || clientId == "" || clientId == "current") {
			return axios.get(`/v1/admin/client`);
		} else {
			return axios.get(`/v1/admin/client/${clientId}`);
		}
	},

	getEmptyClient() {
		return axios.get(`/v1/admin/default/client`);
	},

	saveClient(client) {
		return axios.post("/v1/admin/client", client);
	},

	removeClient(clientId) {
		return axios.delete(`/v1/admin/client/${clientId}`);
	},

	saveCredential(credential) {
		return axios.post("/v1/admin/credential", credential);
	},

	// listCredentials(clientID) {
	// 	return axios.get(`/v1/admin/credentials/${clientID}`);
	// },

	listCredentialsSafe(clientID) {
		return axios.get(`/v1/admin/credentials/${clientID}/safe`);
	},

	removeCredential(credID) {
		return axios.delete(`/v1/admin/credential/${credID}`);
	},

	canDeleteRole(roleID) {
		return axios.get(`/v1/admin/role/can_delete/${roleID}`);
	},

	checkIfFlagCodesAreRemoveable(clientId) {
		if (!clientId || clientId == "" || clientId == "current") {
			return axios.get(`/v1/admin/checkIfFlagCodesAreRemoveable`);
		} else {
			return axios.get(`/v1/admin/checkIfFlagCodesAreRemoveable/${clientId}`);
		}
	},

	addCustomRFTag(tagName) {
		return axios.post(`/v1/admin/client/new_rf_tag/${tagName}`);
	},

	removeCustomRFTag(tagName) {
		return axios.delete(`/v1/admin/client/remove_rf_tag/${tagName}`);
	},

	prepIncomingData(client) {
		// Expand client.config_sheets.columns field from string format to objects, for the UI
		if (client.config_sheets) {
			for (let sheet of client.config_sheets) {
				let options = ConfigSheetService.getColumnOptions(sheet.acts_on)
				let expandedColumns = []
				if (options && sheet.columns) {
					for (let columnKey of sheet.columns) {
						let column = _.find(options, { id: columnKey })
						if (column) {
							expandedColumns.push({ selectedOption: column })
						}
					}
				}
				sheet.columns = expandedColumns
			}
		}
	},

	prepOutgoingData(client) {
		let outClient = _.cloneDeep(client)
		// Collapse client.config_sheets.columns fields from objects to string format, for saving in DB
		if (outClient.config_sheets) {
			for (let sheet of outClient.config_sheets) {
				let columnKeys = []
				if (sheet.columns) {
					for (let column of sheet.columns) {
						if (column && column.selectedOption) {
							columnKeys.push(column.selectedOption.id)
						}
					}
				}
				sheet.columns = columnKeys
			}
		}

		return outClient
	}
};
