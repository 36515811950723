<template>
	<div>
		<!-- Condition 1: Object is dirty and can't be saved because of validation errors -->
		<b-modal :visible="value" @hidden="closed" @shown="interacted = false" v-if="!valid.all()">
			<template slot="modal-title">{{objectText}} Cannot Be Saved</template>
			<div class="py-4">
				The {{objectText}}
				<strong class="text-theme">'{{objectName}}'</strong>
				must be saved before you can {{actionText}}. However, it cannot currently
				be saved because:
				<ul class="pl-4 mb-0">
					<li v-for="(error, index) in valid.errors()" :key="index">{{error}}</li>
				</ul>
				<br>Please fix these issues before continuing.
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="doCancel">OK</button>
			</template>
		</b-modal>

		<!-- Condition 2: Object is dirty and can be saved before continuing to the action -->
		<b-modal
			:visible="value"
			@hidden="closed"
			@shown="interacted = false"
			v-if="dirty && valid.all()"
		>
			<template slot="modal-title">{{objectText}} Will Be Saved</template>
			<div class="py-4">
				The {{objectText}}&nbsp;
				<span class="text-theme _700">'{{objectName}}'</span>
				must be saved before you can {{actionText}}.
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="doCancel">Cancel</button>
				<button class="btn success btn-flat" @click="doSave" v-if="!saving">Save</button>
				<button class="btn btn-secondary btn-flat" v-if="saving" disabled>
					Saving&nbsp;&nbsp;
					<loading type="icon"/>
				</button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
</style>

<script>
import notie from "@/services/NotieService";
import ValidationService from "@/services/ValidationService";

export default {
	name: "SaveRequiredModal",
	props: {
		dirty: { type: Boolean, default: false },
		valid: {
			type: Object,
			default: ValidationService.newValidator()
		},
		save: {
			type: Function
		},
		next: {
			type: Function,
			default: () => {}
		},
		cancel: {
			type: Function,
			default: () => {}
		},
		objectText: { type: String, default: "Object" },
		objectName: { type: String, default: "Undefined" },
		actionText: { type: String, default: "continue" },
		value: { type: Boolean, default: false }
	},

	data() {
		return {
			saving: false,
			interacted: false
		};
	},
	created() {},
	watch: {},
	methods: {
		doSave() {
			this.interacted = true;
			this.saving = true;
			this.save()
				.then(resp => {
					notie.info(`${this.objectText} saved`);
					this.next();
					this.saving = false;
					this.visible(false);
				})
				.catch(err => {
					notie.error(`Failed to save ${this.objectText}`, err);
					this.saving = false;
					this.visible(false);
				});
		},

		doNext() {
			this.interacted = true;
			this.next();
			this.visible(false);
		},

		doCancel() {
			this.interacted = true;
			this.cancel();
			this.visible(false);
		},

		closed() {
			if (!this.interacted) {
				this.cancel();
				this.visible(false);
			}
		},

		visible(val) {
			this.$emit("input", val);
		}
	}
};
</script>
