<template>
	<b-modal
		@cancel="close"
		:title="$t('Auditing.escalate_response_w_score')"
		hide-footer
		size="xxl"
		visible
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
	>
		<div class="modal-body modal-v-limit d-flex flex-row scrollable">
			<div class="div-scroll px-3 col-6">
				<media
					v-if="signedResponse"
					:response="signedResponse"
					:chan="chan"
					:noMargin="true"
					:resizeContainer="true"
				/>
			</div>
			<div class="col-6">
				<div class="row d-flex align-items-end flex-row">
					<div class="col-6">
						<label>{{ $t(" Auditing.qc_type") }}</label>
						<config-select sortBy="sequence" :options="qcTypes" v-model="selectedQcType"></config-select>
					</div>
					<div class="col-6">
						<label class="pt-4">{{ $t("Auditing.set") }}</label>
						<config-select :options="sets" v-model="selectedSet"></config-select>
					</div>
				</div>
				<div class="row py-4">
					<rubric class="col-6" :outer-rubric="item.rubric" :score.sync="score" :user="user"></rubric>
					<div class="col-6">
						<label>{{ $t("fields.feedback") }}</label>
						<RichText
							:initial="initFeedback"
							:text.sync="feedback"
							:refresh="feedbackRefresh"
							class="mb-3 border"
							integrated
							useEquationEditor="true"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button class="btn btn-flat btn-primary" @click="close" :disabled="escalating">
				{{ $t("buttons.cancel") }}
			</button>

			<button v-if="escalating" class="btn btn-flat theme" disabled>
				<loading type="icon" />
			</button>
			<button v-else @click="escalate" class="btn btn-flat theme" :disabled="!canEscalate">
				{{ $t("buttons.escalate") }}
			</button>
		</div>
	</b-modal>
</template>

<script>
import Vue from "vue";

import Rubric from "../Rubric.vue";
import Media from "../viewer/Media.vue";
import RichText from "@/components/RichText";
import ConfigSelect from "../ConfigSelect";

import { QC_TYPES } from "@/services/Constants";
import QCConfigService from "@/services/QCConfigService";
import AuditingService from "@/services/AuditingService";
import Notie from "@/services/NotieService";

export default {
	name: "EscalateScoreModal",

	components: {
		Rubric,
		Media,
		RichText,
		ConfigSelect,
	},

	props: {
		item: Object,
		response: Object,
		user: Object,
	},

	data() {
		const { feedback } = this.response;
		return {
			chan: new Vue(),
			feedback,
			initFeedback: feedback,
			feedbackRefresh: new Date(),
			selectedQcType: null,
			qcTypes: Object.values(QC_TYPES).sort((a, b) => a.id - b.id),
			selectedSet: null,
			sets: [],
			score: undefined,
			escalating: false,
			signedResponse: null,
		};
	},

	created() {
		this.signResponse();
	},

	computed: {
		canEscalate() {
			return this.score && this.selectedSet;
		},
	},

	watch: {
		selectedQcType() {
			this.loadQCSets();
		},
	},

	methods: {
		close() {
			this.$emit("close");
		},

		signResponse() {
			AuditingService.getSignedResponse(this.response.id)
				.then((resp) => {
					this.signedResponse = resp.data;
				})
				.catch((e) => {
					console.log(e);
				});
		},

		loadQCSets() {
			if (!(this.response && this.item && this.selectedQcType)) {
				return;
			}

			QCConfigService.listQCSets(this.response.section_id, this.item.id, this.selectedQcType.id)
				.then((resp) => {
					this.qcSets = resp.data;
					this.sets = _.sortBy(this.qcSets, "sequence");
					this.sets.unshift({ name: "☆ Pool", active: true, id: null });
				})
				.catch((e) => console.log(e));
		},

		escalate() {
			if (!this.canEscalate) {
				return;
			}

			this.escalating = true;
			AuditingService.escalateTrueScore(
				this.response.id,
				this.selectedQcType.id,
				this.selectedSet.id,
				this.score,
				this.feedback
			)
				.then((resp) => {
					Notie.success(this.$i18n.t("notie.response_escalated"));
					this.escalating = false;
					this.close();
				})
				.catch((err) => {
					Notie.error("Failed to escalate response", err);
					this.escalating = false;
					console.log(err);
				});
		},
	},
};
</script>
