import axios from "axios";

export default {
	listLoopbackMessages(ctx) {
		return axios.get("/v1/api_logs/loopback/messages", { params: ctx });
	},

	listScoreExportMessages(ctx) {
		return axios.get("/v1/api_logs/score_export/messages", { params: ctx });
	},

	listObsScoreExportMessages(ctx) {
		return axios.get("/v1/api_logs/obs_score_export/messages", { params: ctx });
	}
};
