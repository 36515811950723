<template>
	<span>
		<span v-if="tooltip && rubric" class="empty-badge wide-badge badge px-1" :id="mutScore.score_id">
			<span
				v-if="mutScore.trait_scores.length && !tooLong"
				v-for="ts in mutScore.trait_scores"
				:key="ts.trait_id"
				class="badge"
				:class="{ disagreed: ts.disagreed, adjacent: ts.adjacent, 'text-extra-muted': ts.condition == '?' }"
				:style="{ width: badgeWidth }"
			>
				{{ traitScore(ts) }} <br />
			</span>
			<span v-if="mutScore.trait_scores.length && tooLong" class="wide-badge px-2">
				<span class="badge mr-1">
					<i class="fas fa-sliders-h faw mr-1"></i>{{ mutScore.trait_scores.length }}</span
				>
			</span>
			<b-popover :target="mutScore.score_id" class="d-none p-0" triggers="hover">
				<Score :score="score" :compareScore="compareScore" :rubric="rubric" :type="type" hideTime></Score>
			</b-popover>
		</span>
		<span v-else class="empty-badge wide-badge badge px-1">
			<span
				v-if="mutScore.trait_scores.length && !tooLong"
				v-for="ts in mutScore.trait_scores"
				:key="ts.trait_id"
				class="badge"
				:style="{ width: badgeWidth }"
			>
				<span :class="{ disagreed: ts.disagreed, nonadj: ts.nonad, adjacent: ts.adjacentj }">{{
					traitScore(ts)
				}}</span>
			</span>
			<span v-if="mutScore.trait_scores.length && tooLong" class="wide-badge px-2">
				<span class="badge mr-1">
					<i class="fas fa-sliders-h faw mr-1"></i>{{ mutScore.trait_scores.length }}</span
				>
			</span>
		</span>
	</span>
</template>

<script>
import Score from "@/components/Score";

export default {
	name: "InlineScore",
	props: {
		score: {
			default: null,
		},
		compareScore: {
			default: null,
		},
		tooltip: {
			type: Boolean,
			default: false,
		},
		rubric: {
			default: null,
		},
		type: {
			type: Number,
			default: null,
		},
		tooltipPlacement: {
			type: String,
			default: "right-start",
		},
	},
	components: { Score },
	data() {
		return {
			mutScore: null,
			badgeWidth: null,
		};
	},
	created() {
		this.loadData();
		this.computeBadgeWidth();
	},
	watch: {
		score() {
			this.loadData();
		},
		compareScore() {
			this.checkAgreement(this.mutScore, this.compareScore);
		},
	},

	computed: {
		tooLong() {
			const { mutScore } = this;
			if (!mutScore) return true;
			const trait_scores = mutScore.trait_scores || [];
			return trait_scores.length > 5 || trait_scores.some((ts) => `${this.traitScore(ts)}`.length >= 3);
		},
	},

	methods: {
		traitScore(ts) {
			if (ts.condition_code_id && ts.condition_code_id !== "" && this.rubric != null) {
				let score = "";
				_.each(this.rubric.traits, (rubric_trait) => {
					if (rubric_trait.id === ts.trait_id) {
						// found trait, now let's try to find a matching condition code
						_.each(rubric_trait.condition_codes, (condition_code) => {
							if (condition_code.id === ts.condition_code_id) {
								score = _.cloneDeep(condition_code.symbol);
							}
						});
					}
				});
				// The score should be a non-empty string if the condition code and trait exist
				// If the score is still an empty string, console log an error because we don't
				// want to overwhelm the user with a ton of notie errors since this is called
				// on load
				if (score == "") {
					console.error("Failed to look up expected condition code for the following trait score:", ts);
				}
				return score;
			} else {
				return ts.score >= 0 ? ts.score : ts.condition;
			}
		},

		loadData() {
			this.mutScore = _.cloneDeep(this.score);
			if (!this.mutScore.score_id) {
				this.mutScore.score_id = this.mutScore.id;
			}
			if (this.compareScore) {
				this.checkAgreement(this.mutScore, this.compareScore);
			}
		},

		checkAgreement(score, actual) {
			_.each(score.trait_scores, (ts) => {
				ts.disagreed = false;
				ts.nonadj = false;
				ts.adjacent = false;
				let comp = _.find(actual.trait_scores, {
					trait_id: ts.trait_id,
				});
				if (comp && ts.score != comp.score) {
					ts.disagreed = true;
					if (Math.abs(comp.score - ts.score) > 1) {
						ts.nonadj = true;
					}
					if (Math.abs(comp.score - ts.score) == 1) {
						ts.adjacent = true;
					}
				}
			});
		},

		computeBadgeWidth() {
			let smallestStep = 1;
			if (this.rubric) {
				_.each(this.rubric.traits, (trait) => {
					if (trait.separator || trait.is_parent) return;
					smallestStep = Math.min(smallestStep, trait.step);
				});
			}

			switch (smallestStep) {
				case 0.25:
					this.badgeWidth = "1.8rem";
					break;
				case 0.5:
					this.badgeWidth = "1.5rem";
					break;
				default:
					this.badgeWidth = "1.25rem";
					break;
			}
		},
	},
};
</script>

<style scoped>
.badge {
	width: 1.25rem;
	height: 1.5rem;
	line-height: 1.4rem;
	padding: 0 0;
	position: relative;
	color: rgba(70, 90, 110, 1);
}
.wide-badge {
	font-size: 1rem;
	width: initial !important;
}
.empty-badge {
	box-shadow: inset 0px 0px 0px 1.2px rgba(0, 0, 0, 0.2);
	background-color: white;
}
.disagreed::before {
	content: "";
	position: absolute;
	top: 0px;
	bottom: 2px;
	left: 0px;
	right: 0px;
	background-color: rgba(255, 0, 0, 0.2);
	border-radius: 0.4rem;
}
.disagreed.nonadj::before {
	border-bottom: 3px solid rgba(255, 0, 0, 0.5);
	border-top: 3px solid rgba(255, 0, 0, 0.5);
}
.adjacent {
}
.adjacent::before {
	background-color: rgba(253, 168, 41, 0.25);
}
</style>
