<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("MySchedule.work_schedule") }}
				</div>
			</div>
		</template>

		<div class="h-100" v-if="!loading">
			<div class="d-sm-flex b-t h-100">
				<div class="w w-auto-xs light bg b-r">
					<div class="py-3">
						<div class="nav-active-border left b-primary">
							<ul class="nav flex-column nav-sm">
								<li class="ml-1 mb-2 _600">{{ $t("MySchedule.my_projects") }}:</li>
								<li v-for="project in projects" :key="project.id" class="nav-item">
									<a
										class="nav-link"
										:class="{ active: project.id == sProject.id }"
										@click="sProject = project"
									>
										{{ project.name }}
										<!-- <i
															class="valid-hint text fa fa-angle-left"
															:class="{invalid: !valid.group('details')}"
														></i> -->
									</a>
								</li>
								<li class="nav-item b-t pt-2 mt-2">
									<a
										class="nav-link no-top-border"
										:class="{ active: sProject && sProject.id == 'all' }"
										@click="selectAllProjects()"
										>{{ $t("MySchedule.all_projects") }}</a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div id="calendar-container" class="tab-content pos-rlt w-100" data-app="true">
					<!-- User -->
					<div class="tab-pane active">
						<div class="d-flex flex-column">
							<div class="flex px-3 pb-3">
								<mz-calendar
									ref="calendar"
									v-if="sProject"
									:events="events"
									:scheduleConfigs="scheduleConfigMap[sProject.id]"
									@eventClick="eventClick"
									:viewMode.sync="viewMode"
									:project="sProject"
									:personal="true"
									:height="calcCalendarHeight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="editModal" v-model="selectedOpen" @hide="selectedOpen = false">
			<template slot="modal-header">
				<h5 v-if="!locked(selectedConfig, editingProject)" class="modal-title pl-3">
					{{ $t("MySchedule.enter_work_time") }}
				</h5>
				<h5 v-if="locked(selectedConfig, editingProject)" class="modal-title pl-3">
					{{ $t("MySchedule.scheduled_work_time") }}
				</h5>
				<i
					v-if="locked(selectedConfig, editingProject)"
					class="fas fa-lg fa-lock align-self-center mr-2"
					v-tippy="{ placement: 'right', theme: 'popover' }"
					:title="lockedTooltip"
				/>
			</template>
			<div class="px-3" v-if="editingProject && selectedEvent && editEvent">
				<div class="row">
					<h5 class="col-9 align-self-center mb-0">
						{{ editingProject.name }}
					</h5>
					<div class="col-3 align-self-center d-flex flex-row justify-content-end">
						<div class="date-circle btn-primary">
							<div>{{ selectedEvent.month }}</div>
							<div style="margin-top: -6px">
								{{ selectedEvent.day }}
							</div>
						</div>
					</div>

					<template v-if="!editingProject.use_shifts">
						<div class="col-12 mt-3 pb-0 text-muted">{{ $t("MySchedule.available") }}:</div>
						<div class="col-8 ml-2 pt-0 d-flex flex-row">
							<div class="flex _600">
								{{ selectedEvent.startTime }}
							</div>
							<div class="flex text-muted">
								{{ $t("MySchedule.to") }}
							</div>
							<div class="align-self-end _600">
								{{ selectedEvent.endTime }}
							</div>
						</div>

						<div class="col-12 mt-3 text-muted">{{ $t("MySchedule.your_work_schedule") }}:</div>
						<template v-if="!locked(selectedConfig, editingProject)">
							<div class="col-6 pt-0">
								<label class="text-muted">{{ $t("MySchedule.start") }}</label>
								<date-picker
									v-model="editEvent.startTime"
									:config="timeConfig"
									@input="checkScheduleEntry"
								></date-picker>
							</div>
							<div class="col-6 pt-0">
								<label class="text-muted">{{ $t("MySchedule.end") }}</label>
								<date-picker
									v-model="editEvent.endTime"
									:config="timeConfig"
									@input="checkScheduleEntry"
								></date-picker>
							</div>
						</template>
						<template v-if="locked(selectedConfig, editingProject)">
							<div class="col-8 ml-2 pt-0 d-flex flex-row">
								<div class="flex _600">
									{{ editEvent.startTime }}
								</div>
								<div class="flex text-muted">
									{{ $t("MySchedule.to") }}
								</div>
								<div class="align-self-end _600">
									{{ editEvent.endTime }}
								</div>
							</div>
						</template>
					</template>

					<template v-if="editingProject.use_shifts && selectedEvent">
						<div class="col-12 mt-3 mb-2 text-muted">{{ $t("MySchedule.your_shifts") }}:</div>
						<div class="col-12">
							<div v-if="selectedEvent.shifts && selectedEvent.shifts.length == 0">
								{{ $t("MySchedule.no_shifts_message") }}
							</div>
							<div v-for="shift in selectedEvent.shifts" :key="shift.id">
								<div v-if="!locked(selectedConfig, editingProject)" class="checkbox form-group">
									<label class="md-check">
										<input
											type="checkbox"
											v-model="shift.enabled"
											:disabled="locked(selectedConfig, editingProject)"
										/>
										<i class="theme-accent"></i>
										{{ shift.start }}
										{{ $t("MySchedule.to") }}
										{{ shift.end }}
									</label>
								</div>
								<div v-if="locked(selectedConfig, editingProject)">
									<label v-if="shift.enabled">
										<i class="fas fa-check text-success" style="width: 18px" />
										{{ shift.start }}
										{{ $t("MySchedule.to") }}
										{{ shift.end }}
									</label>
									<label v-if="!shift.enabled">
										<del class="text-extra-muted">
											<i class="fas fa-times" style="padding-left: 2px; width: 18px" />
											{{ shift.start }}
											{{ $t("MySchedule.to") }}
											{{ shift.end }}
										</del>
									</label>
								</div>
							</div>
						</div>
					</template>

					<div v-if="scheduleError" class="col-12 py-0 text-danger">
						<i class="fas fa-exclamation-triangle" style="vertical-align: -1px" />
						{{ scheduleError }}
					</div>
				</div>
			</div>
			<template slot="modal-footer">
				<template v-if="!locked(selectedConfig, editingProject)">
					<div class="d-flex px-3 mb-2 w-100">
						<div class="align-self-end flex">
							<button
								v-if="selectedSchedule"
								class="btn btn-danger"
								:disabled="!scheduleValid"
								@click="deleteScheduleEntry"
							>
								{{ $t("MySchedule.delete") }}
							</button>
						</div>
						<div class="mr-3" style="width: 200px">
							<label class="text-muted">{{ $t("MySchedule.recur") }}</label>
							<v-select
								:options="fs.getRecurTypes(selectedEvent.weekday)"
								v-model="editEvent.recur"
								@input="checkScheduleEntry"
							></v-select>
						</div>
						<div class="align-self-end">
							<button class="btn btn-primary" :disabled="!scheduleValid" @click="saveScheduleEntry">
								{{ $t("MySchedule.save") }}
							</button>
						</div>
					</div>
				</template>
				<template v-if="locked(selectedConfig, editingProject)">
					<button class="btn btn-primary" @click="selectedOpen = false">
						{{ $t("MySchedule.ok") }}
					</button>
				</template>
			</template>
		</b-modal>
	</page>
</template>

<style scoped>
.w-select {
	min-width: 160px;
}
.w-dates {
	width: 150px;
}
.w-time {
	width: 100px;
}

a.btn-subtle {
	margin-top: -6px;
	margin-bottom: -6px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.cal-popup {
	width: 300px;
	contain: none !important;
}
.cal-popup > .card {
	contain: none !important;
}
.date-circle {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>

<script>
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";
import ConfigSelect from "@/components/ConfigSelect";
import ProjectAvailability from "@/components/ProjectAvailability";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import DatePicker from "vue-bootstrap-datetimepicker";
import MzCalendar from "@/components/MzCalendar";

import TenantService from "@/services/TenantService";
import ConfigService from "@/services/ConfigService";
import ScheduleService from "@/services/ScheduleService";
import ValidationService from "@/services/ValidationService";
import notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import BB from "bluebird";
import fs from "@/services/FormatService";
import moment from "moment";

const dateFormat = "MMMM DD, YYYY";
const timeFormat = "h:mm A";
let now = moment();

export default {
	name: "UserSchedule",

	props: ["user"],

	components: {
		EditPane,
		SaveOptionalModal,
		ConfigSelect,
		ProjectAvailability,
		DatePicker,
		MzCalendar,
	},

	data() {
		return {
			fs: fs,
			dateConfig: {
				format: dateFormat,
				inline: true,
				viewMode: "days",
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			dateTooltipConfig: {
				format: dateFormat,
				inline: false,
				viewMode: "days",
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			timeConfig: {
				format: timeFormat,
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},
			weekdays: [
				{ abr: "mon", name: "Monday", index: 0 },
				{ abr: "tue", name: "Tuesday", index: 1 },
				{ abr: "wed", name: "Wednesday", index: 2 },
				{ abr: "thu", name: "Thursday", index: 3 },
				{ abr: "fri", name: "Friday", index: 4 },
				{ abr: "sat", name: "Saturday", index: 5 },
				{ abr: "sun", name: "Sunday", index: 6 },
			],

			projects: null,
			sProject: null,
			editingProject: null,
			scheduleMap: null,
			scheduleConfigMap: null,
			scheduleValid: false,
			scheduleError: "",
			client: null,

			scheduleTypes: fs.scheduleTypes(),
			exceptionTypes: fs.scheduleExceptionTypes(),

			dirty: false,
			loading: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},

			today: moment().format("YYYY-MM-DD"),
			focus: moment().format("YYYY-MM-DD"),
			start: null,
			end: null,
			selectedEvent: {},
			selectedConfig: null,
			editEvent: {},
			selectedSchedule: null,
			selectedElement: null,
			selectedOpen: false,
			events: [],
			viewMode: null,

			lockedTooltip: `<p>${this.$i18n.t(
				"MySchedule.day_is_locked_main"
			)}</p><p class="mb-0"><small class="text-muted">${this.$i18n.t(
				"MySchedule.day_is_locked_sub"
			)}</small></p>`,

			refs: this.$refs,
		};
	},

	created() {
		this.loadData();
		this.initValidation();
	},

	mounted() {
		//this.$refs.calendar.checkChange();
	},

	destroyed() {
		//Inform the server that we're done scheduling so it will send schedule change emails immediately
		console.log("EXITING");
		ScheduleService.finishScheduling();
	},

	watch: {
		sProject() {
			this.scheduleValid = true;
			this.scheduleError = "";
			this.loadProjectEvents();
		},
	},

	computed: {},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({});
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"schedules",
				(newc, old) => {
					// console.log(Utils.diff(newc, old));
					// console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			return BB.props({
				scheduleDays: ScheduleService.getUserScheduleDays(),
				scheduleConfigs: ScheduleService.getScheduleConfigs(),
				projects: ConfigService.listUserProjects(true),
				client: TenantService.getClient(),
			})
				.then((resps) => {
					let scheduleDays = resps.scheduleDays.data;
					let scheduleConfigs = resps.scheduleConfigs.data;
					let projects = resps.projects.data;

					let scheduleMap = {};
					_.each(scheduleDays, (sd) => {
						if (!scheduleMap[sd.project_id]) {
							scheduleMap[sd.project_id] = {};
						}

						scheduleMap[sd.project_id][sd.date] = sd;
					});

					let scheduleConfigMap = {};
					_.each(projects, (project) => {
						if (!scheduleConfigMap[project.id]) {
							scheduleConfigMap[project.id] = {};
						}
					});
					_.each(scheduleConfigs, (sc) => {
						if (!scheduleConfigMap[sc.project_id]) {
							// scheduleConfigMap[sc.project_id] could be non-existent if we received schedule configs
							// from a project that has since been deleted
							console.log("Failed to find config in map", sc.project_id);
							return;
						}
						scheduleConfigMap[sc.project_id][sc.date] = sc;
					});

					this.scheduleMap = scheduleMap;
					this.scheduleConfigMap = scheduleConfigMap;
					this.projects = projects;
					this.client = resps.client.data;

					if (this.projects.length > 0 && !this.sProject) {
						this.sProject = this.projects[0];
					}
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to get schedules", err);
				});
		},

		//Checks whether the schedule time that the user has entered is valid (within daily range,
		//within range of recurrence days, etc.)
		checkScheduleEntry() {
			let project = this.editingProject;

			if (project.use_shifts) {
				this.scheduleValid = true;
				this.scheduleError = "";
				return;
			}

			let date = this.selectedEvent.date;
			let startTime = this.editEvent.startTime;
			let endTime = this.editEvent.endTime;
			if (!this.editEvent.recur) {
				this.scheduleValid = false;
				this.scheduleError = this.$i18n.t("MySchedule.errors.recur_type");
			}
			let recur = this.editEvent.recur.id;

			if (!startTime) {
				this.scheduleValid = false;
				this.scheduleError = this.$i18n.t("MySchedule.errors.start_time");
				return;
			}

			if (!endTime) {
				this.scheduleValid = false;
				this.scheduleError = this.$i18n.t("MySchedule.errors.end_time");
				return;
			}

			let currDay = moment(date, "MM/DD/YYYY");

			let checkEvents = [];
			switch (recur) {
				case "once":
					let checkEvent = _.find(this.events, (o) => {
						return o.meta.projectID == project.id && o.meta.date == date;
					});
					if (!checkEvent) {
						this.scheduleValid = false;
						this.scheduleError = this.$i18n.t("MySchedule.errors.current_day");
						return;
					}
					checkEvents = [checkEvent];
					break;
				case "week":
					let weekday = currDay.format("dddd");
					checkEvents = _.filter(this.events, (o) => {
						return o.meta.projectID == project.ID && o.meta.weekday == weekday;
					});
					if (checkEvents.length == 0) {
						this.scheduleValid = false;
						this.scheduleError = this.$i18n.t("MySchedule.errors.days");
						return;
					}
					break;
				case "all":
					checkEvents = _.filter(this.events, (o) => {
						return o.meta.projectID == project.ID;
					});
					if (checkEvents.length == 0) {
						this.scheduleValid = false;
						this.scheduleError = this.$i18n.t("MySchedule.errors.days");
						return;
					}
					break;
			}

			let latestStart = checkEvents[0].meta.startMoment;
			let earliestEnd = checkEvents[0].meta.endMoment;
			_.each(checkEvents, (event) => {
				if (event.meta.startMoment.isAfter(this.combineDay(latestStart, event.meta.startMoment))) {
					latestStart = event.meta.startMoment;
				}

				if (event.meta.endMoment.isBefore(this.combineDay(earliestEnd, event.meta.endMoment))) {
					earliestEnd = event.meta.endMoment;
				}
			});

			let userStart = this.combineDay(moment(startTime, fs.timeFormat()), latestStart);
			let userEnd = this.combineDay(moment(endTime, fs.timeFormat()), earliestEnd);

			if (userStart.isBefore(latestStart)) {
				this.scheduleValid = false;
				switch (recur) {
					case "once":
						this.scheduleError = this.$i18n.t("MySchedule.errors.start_too_early", {
							time: latestStart.format(fs.timeFormat()),
						});
						break;
					case "week":
						this.scheduleError = this.$i18n.t("MySchedule.errors.start_too_early_weekday", {
							weekday: latestStart.format("dddd"),
							time: latestStart.format(fs.timeFormat()),
						});
						break;
					case "all":
						this.scheduleError = this.$i18n.t("MySchedule.errors.start_too_early_all", {
							time: latestStart.format(fs.timeFormat()),
						});
						break;
				}
				return;
			}

			if (userEnd.isAfter(earliestEnd)) {
				this.scheduleValid = false;
				switch (recur) {
					case "once":
						this.scheduleError = this.$i18n.t("MySchedule.errors.end_too_late", {
							time: earliestEnd.format(fs.timeFormat()),
						});
						break;
					case "week":
						this.scheduleError = this.$i18n.t("MySchedule.errors.end_too_late_weekday", {
							weekday: earliestEnd.format("dddd"),
							time: earliestEnd.format(fs.timeFormat()),
						});
						break;
					case "all":
						this.scheduleError = this.$i18n.t("MySchedule.errors.end_too_late_all", {
							time: earliestEnd.format(fs.timeFormat()),
						});
						break;
				}
				return;
			}

			this.scheduleValid = true;
			this.scheduleError = "";
		},

		saveScheduleEntry() {
			let date = this.selectedEvent.date;
			let project = this.editingProject;
			let startTime = this.editEvent.startTime;
			let endTime = this.editEvent.endTime;
			let recur = this.editEvent.recur.id;

			let saveEvents = [];
			switch (recur) {
				case "once":
					let saveEvent = _.find(this.events, (o) => {
						return o.meta.date == date;
					});
					saveEvents = [saveEvent];
					break;
				case "week":
					let weekday = this.selectedEvent.weekday;
					saveEvents = _.filter(this.events, (o) => {
						return o.meta.weekday == weekday;
					});
					break;
				case "all":
					saveEvents = this.events;
					break;
			}

			let scheduleMap = this.scheduleMap[project.id];
			if (!scheduleMap) {
				scheduleMap = {};
			}

			let saveSchedules = [];
			_.each(saveEvents, (event) => {
				let scheduleDay = {
					project_id: project.id,
					date: event.meta.date,
					start_time: startTime,
					end_time: endTime,
					shift_ids: [],
				};

				let existingSchedule = scheduleMap[event.meta.date];
				if (existingSchedule) {
					scheduleDay.id = existingSchedule.id;
				}

				_.each(this.selectedEvent.shifts, (shift) => {
					if (shift.enabled) {
						scheduleDay.shift_ids.push(shift.id);
					}
				});

				saveSchedules.push(scheduleDay);
			});

			return ScheduleService.saveUserScheduleDays(saveSchedules)
				.then((resp) => {
					let msg =
						saveSchedules.length > 1
							? this.$i18n.t("MySchedule.schedule_saved_plural")
							: this.$i18n.t("MySchedule.schedule_saved_singular");

					notie.info(msg);

					this.selectedOpen = false;
					this.loadData().then(() => {
						this.loadProjectEvents();
					});
				})
				.catch((err) => {
					let msg =
						this.schedules.length > 1
							? this.$i18n.t("MySchedule.errors.save_schedule_plural")
							: this.$i18n.t("MySchedule.errors.save_schedule_singular");
					console.log(err);
					notie.error(msg, err);
				});
		},

		deleteScheduleEntry() {
			return ScheduleService.deleteUserScheduleDay(this.selectedSchedule.id)
				.then((resp) => {
					notie.info(this.$i18n.t("MySchedule.delete_schedule"));

					this.selectedOpen = false;
					this.loadData().then(() => {
						this.loadProjectEvents();
					});
				})
				.catch((err) => {
					let msg = this.$i18n.t("MySchedule.errors.delete_schedule");
					console.log(err);
					notie.error(msg, err);
				});
		},

		availWeekdays(project) {
			let weekdays = [];
			let everyDay = _.find(project.avail_times, { day: 7 });
			if (everyDay) {
				for (let i = 0; i <= 6; i++) {
					let weekday = _.clone(this.weekdays[i]);
					weekday.avail_start = everyDay.start;
					weekday.avail_end = everyDay.end;
					weekdays.push(weekday);
				}
			} else {
				_.each(this.weekdays, (wd) => {
					let availDay = _.find(project.avail_times, { day: wd.index });
					if (availDay) {
						let weekday = _.clone(wd);
						weekday.avail_start = availDay.start;
						weekday.avail_end = availDay.end;
						weekdays.push(weekday);
					}
				});
			}

			return weekdays;
		},

		loadProjectEvents() {
			if (this.sProject && this.sProject.id == "all") {
				let events = [];
				_.each(this.projects, (project, i) => {
					events = events.concat(this.getProjectEvents(project, i));
				});
				this.events = events;
			} else {
				this.events = this.getProjectEvents(this.sProject, 0);
			}
		},

		getProjectEvents(project, sequence) {
			let events = [];

			let dayTimes = [];
			if (project.enforce_times) {
				let everyDay = _.find(project.avail_times, { day: 7 });
				if (everyDay) {
					for (let i = 0; i <= 6; i++) {
						dayTimes[i] = {
							start: everyDay.start,
							end: everyDay.end,
						};
					}
				} else {
					for (let i = 0; i <= 6; i++) {
						let day = _.find(project.avail_times, { day: i });
						if (day) {
							dayTimes[i] = {
								start: day.start,
								end: day.end,
							};
						} else {
							dayTimes[i] = null;
						}
					}
				}
			} else {
				dayTimes = [
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
					{ start: "12:00am", end: "11:59pm" },
				];
			}

			for (
				let currDay = moment(project.scoring_start);
				currDay.isSameOrBefore(project.scoring_end);
				currDay.add(1, "days")
			) {
				let dayOfWeek = currDay.day() % 7;
				let times = dayTimes[dayOfWeek];
				let date = currDay.format("MM/DD/YYYY");
				if (times) {
					let scheduleDays = this.scheduleMap[project.id];
					let scheduleDay = scheduleDays && scheduleDays[date];

					if (project.use_shifts) {
						let weeklyShifts = _.filter(project.shifts, { weekday: dayOfWeek });
						let specificShifts = _.filter(project.shifts, { weekday: -1, date: date });
						let shifts = weeklyShifts.concat(specificShifts);

						_.each(shifts, (shift, i) => {
							let startMoment = this.combineDay(moment(shift.start, fs.timeFormat()), currDay);
							let start = startMoment.format("YYYY-MM-DD HH:mm");
							let endMoment = this.combineDay(moment(shift.end, fs.timeFormat()), currDay);
							let end = endMoment.format("YYYY-MM-DD HH:mm");

							let timeStr = this.$i18n.t("Calendar.time_to_time", {
								start: fs.shortTime(startMoment),
								end: fs.shortTime(endMoment),
							});
							let shiftName = this.$i18n.t("Calendar.shift_n", { n: i + 1 });

							let filled = false;
							if (scheduleDay && scheduleDay.shift_ids && scheduleDay.shift_ids.includes(shift.id)) {
								filled = true;
							}

							events.push({
								title: "[not rendered]",
								start: start,
								end: end,
								meta: {
									projectID: project.id,
									projectSeq: sequence,
									startMoment: startMoment,
									endMoment: endMoment,
									startTime: shift.start,
									endTime: shift.end,
									month: currDay.format("MMM"),
									day: currDay.format("D"),
									date: date,
									weekday: currDay.format("dddd"),
									shifts: shifts,
								},
								render: {
									project: project.name,
									time: timeStr,
									shiftName: shiftName,
									satisfied: -1,
									filled: filled,
								},
							});
						});
					} else {
						let startMoment = this.combineDay(moment(times.start, "h:mm A"), currDay);
						let start = startMoment.format("YYYY-MM-DD HH:mm");
						let endMoment = this.combineDay(moment(times.end, "h:mm A"), currDay);
						let end = endMoment.format("YYYY-MM-DD HH:mm");

						let filled = false;
						let timeStr = this.generateDayText(startMoment, endMoment);
						let hours = this.$i18n.t("MySchedule.available");

						if (scheduleDay) {
							let userStart = this.combineDay(moment(scheduleDay.start_time, "h:mm A"), currDay);
							start = userStart.format("YYYY-MM-DD HH:mm");
							let userEnd = this.combineDay(moment(scheduleDay.end_time, "h:mm A"), currDay);
							end = userEnd.format("YYYY-MM-DD HH:mm");

							let projectDuration = endMoment.diff(startMoment);
							let userDuration = userEnd.diff(userStart);
							timeStr = this.generateDayText(userStart, userEnd);
							hours = `(${(userDuration / (60 * 60 * 1000)).toFixed(1)}h)`;
							filled = true;
						}

						let newEvent = {
							title: "[not rendered]",
							start: start,
							end: end,
							meta: {
								projectID: project.id,
								projectSeq: sequence,
								startMoment: startMoment,
								endMoment: endMoment,
								startTime: times.start,
								endTime: times.end,
								month: currDay.format("MMM"),
								day: currDay.format("D"),
								date: date,
								weekday: currDay.format("dddd"),
							},
							render: {
								project: project.name,
								time: timeStr,
								hours: hours,
								satisfied: -1,
								filled: filled,
							},
						};
						events.push(newEvent);
					}
				}

				if (this.isDayLocked(date)) {
					events.push({
						title: "Locked",
						start: currDay.format("YYYY-MM-DD"),
						end: currDay.format("YYYY-MM-DD"),
						rendering: "background",
						classNames: "fc-locked-day",
						meta: {},
						render: {
							lock: true,
						},
					});
				}
			}

			return events;
		},

		generateDayText(start, end) {
			let startStr = start.minute() == 0 ? start.format("ha") : start.format("h:mma");
			let endStr = end.minute() == 0 ? end.format("ha") : end.format("h:mma");

			let name = this.$i18n.t("MySchedule.start_to_end", { start: startStr, end: endStr });

			return name;
		},

		combineDay(time, day) {
			time.year(day.year());
			time.month(day.month());
			time.date(day.date());

			return time;
		},

		addException(schedule) {
			if (!schedule.time_exceptions) {
				this.$set(schedule, "time_exceptions", []);
			}
			schedule.time_exceptions.push({
				type: 1,
			});
		},

		removeException(schedule, exception) {
			let index = schedule.time_exceptions.findIndex(exception);
			if (index > -1) {
				this.$delete(schedule.time_exceptions, index);
			}
		},

		blankConfig() {
			return {
				project_id: this.editingProject && this.editingProject.id,
				date: this.selectedEvent.date,
				threshold_type: 0,
				threshold: null,
				shift_thresholds: {},
				user_labels: {},
				locked: null,
			};
		},

		isDayLocked(date) {
			let scheduleConfigs = this.sProject && this.scheduleConfigMap[this.sProject.id];
			if (scheduleConfigs) {
				let config = scheduleConfigs[date];
				if (config) {
					return this.locked(config, this.sProject);
				}
			}
			return this.locked({ date: date, locked: null }, this.sProject);
		},

		locked(config, project) {
			if (!(config && project)) {
				return false;
			}

			if (config.locked == true) {
				//If locked is true, it should always be locked
				return true;
			}

			let lockoutHours = project.schedule_lockout || 0;

			let now = moment();
			let configDay = moment(config.date, "MM/DD/YYYY").startOf("day");
			if (config.locked == false) {
				//if locked is false, it should always be unlocked unless it is in the past
				return now.isAfter(configDay);
			}

			//Otherwise, locked is null, indicating that an admin has never changed it
			//In this case, the day should be locked if it's within the defined lockout period
			let lockoutMoment = configDay.clone().subtract(lockoutHours, "hours");
			return now.isAfter(lockoutMoment);
		},

		getEventColor(event) {
			return event.color;
		},

		selectAllProjects() {
			this.sProject = { id: "all" };
		},

		eventClick(info) {
			let projectID = info.event.extendedProps.meta.projectID;
			let project = null;
			_.each(this.projects, (p) => {
				if (p.id == projectID) {
					project = p;
				}
			});
			if (!project) {
				console.error("Failed to find project with ID", projectID);
				return;
			}
			this.editingProject = project;

			this.selectedEvent = info.event.extendedProps.meta;
			this.selectedElement = info.el;

			if (project.use_shifts && !this.selectedEvent.shifts) {
				return;
			}

			this.selectedConfig = null;
			if (this.scheduleConfigMap[projectID]) {
				this.selectedConfig = this.scheduleConfigMap[projectID][this.selectedEvent.date];
			}
			if (!this.selectedConfig) {
				this.selectedConfig = this.blankConfig();
			}

			this.selectedSchedule = null;
			if (this.scheduleMap[projectID]) {
				this.selectedSchedule = this.scheduleMap[projectID][this.selectedEvent.date];

				if (project.use_shifts) {
					_.each(this.selectedEvent.shifts, (shift) => {
						if (this.selectedSchedule) {
							shift.enabled = this.selectedSchedule.shift_ids.includes(shift.id);
						} else {
							shift.enabled = false;
						}
					});
				}
			}

			if (this.selectedSchedule) {
				this.editEvent = {
					startTime: this.selectedSchedule.start_time,
					endTime: this.selectedSchedule.end_time,
					recur: fs.getRecurTypes(this.selectedEvent.weekday)[0],
				};
			} else {
				this.editEvent = {
					startTime: this.selectedEvent.startTime,
					endTime: this.selectedEvent.endTime,
					recur: fs.getRecurTypes(this.selectedEvent.weekday)[0],
				};
			}
			this.selectedOpen = true;
		},

		nth(d) {
			return d > 3 && d < 21 ? "th" : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
		},

		calcCalendarHeight() {
			let el = document.getElementById("calendar-container");
			let height = el.offsetHeight;

			return height - 16;
		},
	},
};
</script>