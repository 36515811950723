<template>
	<div>
		<div ref="box" class="lrn-box d-flex flex-column align-items-center" :width="width">
			<template>
				<iframe :srcdoc="page.srcdoc" :width="width" :height="height" @load="loadedData" />
			</template>
			<div v-if="mediaLoaded == null" class="loading-overlay text-muted">
				<loading type="page" />
			</div>
		</div>
	</div>
</template>

<script>
import ResponseMediaService from "@/services/ResponseMediaService";
import JsonTree from "vue-json-tree";

export default {
	name: "IframeDocMedia",
	props: [
		"item",
		"page",
		"response",
		"resource",
		"trainingElement",
		"directRender",
		"rawSource",
		"viewSource",
		"chan",
	],
	components: { JsonTree },

	data() {
		return {
			width: 800,
			height: 450,
			windowWidth: 800,
			windowHeight: 450,
			labelHeight: 46,
			observer: null,
			previewerItem: false,
			previewerParsingError: null,
			previewerErrorColumn: null,
			mediaLoaded: null,
		};
	},

	computed: {
		hasLabel() {
			return (page.mediaSeq != undefined && showPrint) || (page.type != "text" && page.label);
		},
	},

	watch: {},

	created() {
		if (this.chan) {
			this.chan.$emit("increment-media-to-load");
		}

		console.log("created - iFrameDocMedia");
		console.log(this.page);
	},

	mounted() {
		this.resizeIframe();

		if (this.previewerItem) {
			this.$refs.form.submit();
		}

		window.addEventListener("resize", this.resizeIframe);
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.resizeIframe);
	},

	methods: {
		loadedData() {
			if (this.chan) {
				this.chan.$emit("increment-media-loaded");
			}
			this.mediaLoaded = true;
		},

		resizeIframe() {
			console.log("RESIZE IFRAME");
			// Width/Height on 'this.page' are preferred values, while Width/Height on 'this' are actual rendered values

			let aspectRatio = 0.6105; // Cognia content package aspect ratio, use as a default
			if (this.page && this.page.width && this.page.height) {
				aspectRatio = this.page.height / this.page.width;
			}
			let containerWidth = this.$refs.box.offsetWidth;
			this.width = containerWidth;
			if (!this.previewerItem) {
				this.height = this.width * aspectRatio;
			} else if (this.previewerItem) {
				this.height = this.width - 100;
			}
		},
	},
};
</script>

<style scoped>
.lrn-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 1000px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}
iframe {
	resize: vertical;
	user-select: none;
}
.iframe-media {
	resize: both;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
}
.loading-overlay {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
</style>
