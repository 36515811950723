import { render, staticRenderFns } from "./ReportUserAgreement.vue?vue&type=template&id=1844add8&scoped=true"
import script from "./ReportUserAgreement.vue?vue&type=script&lang=js"
export * from "./ReportUserAgreement.vue?vue&type=script&lang=js"
import style0 from "./ReportUserAgreement.vue?vue&type=style&index=0&id=1844add8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1844add8",
  null
  
)

export default component.exports