<template>
	<div class="row">
		<div class="col-12">
			<label>{{ $t("QCSetup.configure_set_modal.rule") }}</label>
			<v-select
				:options="selectableRules"
				:reduce="(o) => o.id"
				v-model="config.rule"
				label="name"
				class="v-select-apply"
			>
			</v-select>
		</div>

		<!-- Per-response settings -->
		<template v-if="ruleUsesField('score_agreement')">
			<hr class="w-100" />
			<div class="col-12 pb-1">
				<div class="_600">{{ $t("QCSetup.configure_set_modal.score_agreement") }}:</div>
			</div>
			<div v-if="ruleUsesField('exact')" class="col-12">
				<div class="text-muted">
					{{ $t("QCSetup.configure_set_modal.exact_agreement") }}
				</div>
			</div>
			<div v-if="ruleUsesField('exact_adjacent')" class="col-12">
				<div class="text-muted">
					{{ $t("QCSetup.configure_set_modal.exact_adjacent_agreement") }}
				</div>
			</div>
		</template>

		<template v-if="ruleUsesField('response_agreement')">
			<hr class="w-100" />
			<div class="col-12 pb-1">
				<div class="_600">{{ $t("QCSetup.configure_set_modal.each_response") }}:</div>
			</div>
			<div v-if="ruleUsesField('full_response')" class="col-12">
				<div class="text-muted">
					{{ $t("QCSetup.configure_set_modal.needs_full_response") }}
				</div>
			</div>
			<div v-if="ruleUsesField('weighted_threshold')" class="col-12 mb-2">
				<label>Max Weight Diff.</label>
				<input type="number" class="form-control" v-model.number="config.weighted_threshold" />
			</div>
			<div v-if="ruleUsesField('weighted_range_min')" class="col-6">
				<label>Range Min</label>
				<input type="number" class="form-control" v-model.number="config.weighted_range_min" />
			</div>
			<div v-if="ruleUsesField('weighted_range_max')" class="col-6">
				<label>Range Max</label>
				<input type="number" class="form-control" v-model.number="config.weighted_range_max" />
			</div>
			<div v-if="ruleUsesField('omaha_min_max')" class="col-12 mb-2">
				<div class="p-0 col-4 mt-auto checkbox">
					<label class="ui-check">
						<input type="checkbox" v-model="config.omaha_min_max" />
						<i class="dark-white"></i> Resolve Min/Max
					</label>
				</div>
			</div>
			<div v-if="ruleUsesField('omaha_max_non_adj')" class="col-6">
				<label>Max Non-adjacent</label>
				<input type="number" class="form-control" v-model.number="config.omaha_max_non_adj" />
			</div>
			<div v-if="ruleUsesField('omaha_max_weighted_score_diff')" class="col-6">
				<label>Max Score Diff</label>
				<input type="number" class="form-control" v-model.number="config.omaha_max_weighted_score_diff" />
			</div>
		</template>

		<!-- Overall set settings -->
		<template v-if="!validityThreshold">
			<hr class="w-100" />
			<div class="col-12 pb-1">
				<div class="_600">{{ overallTitle }}:</div>
			</div>
			<div v-if="ruleUsesField('pass_percent')" class="col-sm-6 pb-1">
				<label>{{ $t("QCSetup.configure_set_modal.pass_percent") }}</label>
				<percent-input v-model="config.pass_percent" />
			</div>
			<div v-if="ruleUsesField('trait_overall_pass_percent')" class="col-sm-6 pb-1">
				<label>{{ $t("QCSetup.configure_set_modal.trait_overall_pass_percent") }}</label>
				<percent-input v-model="config.pass_percent" />
			</div>
			<div v-if="ruleUsesField('trait_pass_percent')" class="col-sm-6 pb-1">
				<label>{{ $t("QCSetup.configure_set_modal.trait_pass_percent") }}</label>
				<percent-input v-model="config.trait_pass_percent" />
			</div>
			<div v-if="ruleUsesField('maximum_non_adjacent')" class="col-sm-6 pb-1">
				<label>{{ $t("QCSetup.configure_set_modal.maximum_non_adjacent") }}</label>
				<input type="number" class="form-control" v-model.number="config.maximum_non_adjacent" />
			</div>
			<div v-if="ruleUsesField('splits')" class="col-sm-6 pb-1 d-flex flex-column justify-content-end">
				<button class="btn btn-flat theme">
					{{ $t("QCSetup.configure_set_modal.splits") }}
				</button>
			</div>
		</template>
	</div>
</template>

<style scoped>
</style>

<script>
import fs from "@/services/FormatService";
import PercentInput from "@/components/PercentInput";

export default {
	name: "QcRuleConfig",
	props: {
		config: Object,
		isValidity: Boolean,
		validityThreshold: Boolean,
	},
	components: { PercentInput },

	data() {
		return {
			fs: fs,
		};
	},

	watch: {
		validityThreshold(newVal) {
			const { config } = this;
			if (newVal && this.isTraitRule(config.rule)) {
				config.rule = config.rule.replace(/by_trait$/, "by_response");
			}
		},
	},

	computed: {
		selectableRules() {
			if (this.validityThreshold) {
				return fs
					.qcRules()
					.filter(({ id }) => !this.isTraitRule(id))
					.map((rule) => {
						const uses_fields = rule.uses_fields.filter((rule) => rule !== "pass_percent");
						return { ...rule, uses_fields };
					});
			}
			return fs.qcRules();
		},

		overallTitle() {
			const { isValidity, $i18n } = this;
			return isValidity
				? $i18n.t("QCSetup.configure_set_modal.overall_set")
				: $i18n.t("QCSetup.configure_set_modal.overall");
		},
	},

	methods: {
		isTraitRule(id) {
			return id.endsWith("by_trait");
		},

		ruleUsesField(field) {
			let rule = _.find(this.selectableRules, { id: this.config.rule });
			return rule && rule.uses_fields && rule.uses_fields.includes(field);
		},
	},
};
</script>
