<template>
	<div class="box d-flex flex-column" style="min-height: 360px" id="dashboard-qc-results-box">
		<div class="b-b nav-active-bg">
			<span class="float-right text-muted buttons-position">
				<a @click="loadSetLists" class="no-select btn-subtle ml-2" v-tippy :title="$t('tooltip.refresh')">
					<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
				</a>
			</span>
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<a class="nav-link" @click="setQCType(3)" :class="{ active: qcType == 3, show: qcType == 3 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("ReportUserSummary.validity") }}</span>
							<span class="d-xl-none">{{ $t("ReportUserSummary.val") }}</span>
						</div>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" @click="setQCType(1)" :class="{ active: qcType == 1, show: qcType == 1 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("ReportUserSummary.practice") }}</span>
							<span class="d-xl-none">{{ $t("ReportUserSummary.tra") }}</span>
						</div>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" @click="setQCType(2)" :class="{ active: qcType == 2, show: qcType == 2 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("ReportUserSummary.qualification") }}</span>
							<span class="d-xl-none">{{ $t("ReportUserSummary.qual") }}</span>
						</div>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" @click="setQCType(4)" :class="{ active: qcType == 4, show: qcType == 4 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("ReportUserSummary.calibration") }}</span>
							<span class="d-xl-none">{{ $t("ReportUserSummary.cal") }}</span>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div class="flex tab-content d-flex flex-column px-3 pb-3 pt-1">
			<div class="flex d-flex flex-row">
				<div
					v-if="qcSetsAny"
					class="pt-2 d-flex flex-column justify-content-center"
					style="width: 33%; z-index: 2"
				>
					<div class="text-center my-2">
						<h1 :class="{ 'text-very-muted': !qcAgreementAny }">{{ fs.fixed1d(qcAgreementPercent) }}%</h1>
						<h6 class="text-muted">{{ $t("ReportUserSummary.agreement") }}</h6>
					</div>
					<div class="text-center my-2">
						<h1 v-if="qcAgreementAny">
							<agreement-row :exact="qcExact" :adj="qcAdj" :nonadj="qcNonadj" placement="right" />
						</h1>
						<h1 v-if="!qcAgreementAny" class="text-very-muted">——</h1>
						<h6 class="text-muted">{{ $t("ReportUserSummary.score_points") }}</h6>
					</div>
				</div>
				<div
					v-if="qcSetsAny"
					class="d-flex flex-row scroll-y"
					style="margin-left: -33%; width: 100%; max-height: 295px; z-index: 1"
				>
					<div style="width: calc(33% + 10px)"></div>
					<div style="width: calc(67% - 10px)">
						<table class="table">
							<tr>
								<th>{{ $t("ReportUserSummary.set") }}</th>
								<th>{{ $t("ReportUserSummary.results") }}</th>
							</tr>
							<tbody>
								<template v-for="row in qcSetResults">
									<tr :key="row.id">
										<td>
											<span :class="{ 'text-very-muted': !row.any }">{{ row.name }}</span>
										</td>
										<td>
											<h6
												v-if="row.result.explanations && row.result.explanations.length > 0"
												:class="{
													'text-very-muted': !row.any,
													'text-success': row.result.state == 2,
													'text-danger': row.result.state == 3,
												}"
												v-tippy="{
													theme: 'popover',
													html: `#pf_${row.id}`,
													placement: 'right',
												}"
											>
												{{ fs.fixedPercent1d(row.result.percent) }}

												<div :id="`pf_${row.id}`" class="d-none p-0" :key="row.id">
													<table class="table table-hover mb-0">
														<thead>
															<tr>
																<th colspan="2">
																	{{ $t("QCReport.pass_fail_determination") }}
																</th>
															</tr>
														</thead>
														<tbody>
															<tr v-for="(expRow, i) in row.result.explanations" :key="i">
																<td>{{ expRow.label }}</td>
																<td
																	:class="{
																		'text-danger': expRow.color == 1,
																		'text-warning': expRow.color == 2,
																		'text-success': expRow.color == 3,
																	}"
																>
																	{{ expRow.stat }}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</h6>
											<h6
												v-if="!(row.result.explanations && row.result.explanations.length > 0)"
												class="mb-0"
												:class="{ 'text-very-muted': !row.any }"
											>
												{{ fs.fixedPercent1d(row.result.percent) }}
											</h6>
										</td>
										<td>
											<h6 class="mb-0" v-if="row.any">
												<agreement-row
													:exact="row.result.exact"
													:adj="row.result.adj"
													:nonadj="row.result.nonadj"
												/>
											</h6>
											<h6 class="mb-0 text-very-muted" v-if="!row.any">——</h6>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<h1 v-if="!qcSetsAny && !busy" class="w-100 text-center text-very-muted mt-5">
					{{ $t("ReportUserSummary.no_sets") }}
				</h1>
				<h1 v-if="!qcSetsAny && busy" class="w-100 text-center text-very-muted mt-5">
					<loading type="icon" />
				</h1>
			</div>
		</div>
	</div>
</template>

<style scoped>
a.btn-subtle i {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a.btn-subtle:hover i {
	opacity: 1;
}
.buttons-position {
	padding: 0.7rem 1rem;
}
th {
	border-top: 0px !important;
}
.exclamation-fix {
	font-size: initial;
	vertical-align: initial;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
@media print {
	.buttons-position {
		display: none !important;
	}
}
</style>

<script>
import DashboardService from "@/services/DashboardService";
import ConfigService from "@/services/ConfigService";
import QCConfigService from "@/services/QCConfigService";
import QCService from "@/services/QCService";
import AgreementRow from "@/components/AgreementRow";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

export default {
	name: "UserQCSets",

	components: {},

	props: ["section", "item", "user"],

	components: { AgreementRow },

	data() {
		return {
			fs: fs,
			qcType: store.getDefault(this, "dash.userqc.qcType", 3),
			busy: false,

			qcSets: [],
			qcSetResults: [],

			qcAgreementAny: false,
			qcAgreementComplete: false,
			qcAgreementPercent: 0,
			qcAgreedTraits: 0,
			qcTotalTraits: 0,
		};
	},
	created() {
		this.loadSetLists();
	},

	computed: {
		qcSetsAny() {
			return this.qcSetResults && this.qcSetResults.length > 0;
		},
	},

	watch: {
		section() {
			this.loadSetLists();
		},
		item() {
			this.loadSetLists();
		},
	},
	methods: {
		loadSetLists() {
			if (!(this.section && this.item)) {
				return;
			}
			this.resetSetAgreement();
			this.busy = true;
			QCConfigService.listQCSets(this.section.id, this.item.id, this.qcType)
				.then((resp) => {
					this.qcSets = resp.data;
					let calls = [];
					for (let i = 0; i < this.qcSets.length; i++) {
						let set = this.qcSets[i];
						calls.push(QCService.getUserQcSetReport(set.id, this.user.id));
					}
					Promise.all(calls)
						.then((resps) => {
							this.resetSetAgreement();
							let totalResponses = 0;
							let summedPercents = 0;
							_.each(resps, (resp, i) => {
								let result = this.calculateSetAgreement(resp.data.responses);
								result.percent = resp.data.stat.percent;
								if (resp.data.stat.qc_type == 2 || resp.data.stat.qc_type == 4) {
									//Qual or Cal
									result.state = resp.data.stat.state;
									result.explanations = resp.data.stat.explanations;
								}

								if (result.completeResponses) {
									this.qcAgreementAny = true;
									this.qcExact += result.exact;
									this.qcAdj = result.adj;
									this.qcNonadj = result.nonadj;
									totalResponses += result.completeResponses;
									summedPercents += result.percent * result.completeResponses;
								}
								this.qcSetResults[i] = {
									id: this.qcSets[i].id,
									name: this.qcSets[i].name,
									result: result,
									any: result.completeResponses > 0,
									complete: result.completeResponses == result.totalResponses,
								};
							});
							this.qcAgreementPercent = summedPercents / totalResponses;
							this.busy = false;
							this.loading = false;
						})
						.catch((err) => {
							console.log(err);
							this.busy = false;
							this.loading = false;
						});
				})
				.catch((err) => {
					this.busy = false;
					this.loading = false;
					notie.error(this.$i18n.t("notie.load_qc_sets_fail"), err);
				});
		},

		resetSetAgreement() {
			this.qcSetResults = [];
			this.qcAgreementAny = false;
			this.qcAgreementPercent = NaN;
			this.qcExact = 0;
			this.qcAdj = 0;
			this.qcNonadj = 0;
		},

		calculateSetAgreement(responses) {
			let completeResponses = 0;
			let totalResponses = 0;
			let exact = 0;
			let adj = 0;
			let nonadj = 0;

			_.each(responses, (row) => {
				totalResponses++;
				if (row.user_score) {
					completeResponses++;
					_.each(row.user_score.trait_scores, (us) => {
						let ts = _.find(row.response.true_score.trait_scores, {
							trait_id: us.trait_id,
						});
						let diff = Math.abs(us.score - ts.score);
						if (diff == 0) {
							exact++;
						} else if (diff <= 1) {
							adj++;
						} else {
							nonadj++;
						}
					});
				}
			});

			return {
				completeResponses: completeResponses,
				totalResponses: totalResponses,
				exact,
				adj,
				nonadj,
			};
		},

		setQCType(type) {
			this.qcType = type;
			store.set(this, "dash.userqc.qcType", this.qcType);

			this.loadSetLists();
		},

		clickPicker() {
			let picker = document.getElementById("section-item-picker");
			picker.click();
		},
	},
};
</script>
