<template>
	<div class="d-flex flex-row align-items-center">
		<ul class="m-1 pagination b-pagination pagination-md">
			<li class="page-item">
				<a class="page-link" id="pagination-goto" @click="showGoto = !showGoto">
					<i class="far fa-paper-plane" />
				</a>
				<b-popover :show.sync="showGoto" target="pagination-goto" placement="topright">
					<template slot="title">{{ $t('pagination.go_to_page') }}</template>
					<div class="w">
						<div class="mx-3 d-flex flex-row">
							<input class="form-control" type="number" @keyup.enter="doGotoPage"
								v-model.number="gotoPage" data-dismiss="dropdown">
							<button class="btn btn-primary ml-2 goto-play" @click="doGotoPage">
								<i class="fa fa-play" />
							</button>
						</div>
					</div>
				</b-popover>
			</li>
		</ul>
		<b-pagination class="m-1" :disabled="disabled" :value="value" :limit="limit" :size="size" :align="align"
			:hideGotoEndButtons="hideGotoEndButtons" :ariaLabel="ariaLabel" :labelFirstPage="labelFirstPage"
			:firstText="firstText" :labelPrevPage="labelPrevPage" :prevText="prevText" :labelNextPage="labelNextPage"
			:nextText="nextText" :labelLastPage="labelLastPage" :lastText="lastText" :labelPage="labelPage"
			:hideEllipsis="hideEllipsis" :ellipsisText="ellipsisText" :perPage="perPage" :totalRows="totalRows"
			:ariaControls="ariaControls" @input="emitInput($event)"></b-pagination>
	</div>
</template>

<style scoped>
.goto-play {
	color: white !important;
	background-color: #53a6fa !important;
	border-color: #53a6fa !important;
}
</style>

<script>

export default {
	name: "MzPagination",
	props: [
		"disabled",
		"value",
		"limit",
		"size",
		"align",
		"hideGotoEndButtons",
		"ariaLabel",
		"labelFirstPage",
		"firstText",
		"labelPrevPage",
		"prevText",
		"labelNextPage",
		"nextText",
		"labelLastPage",
		"lastText",
		"labelPage",
		"hideEllipsis",
		"ellipsisText",
		"perPage",
		"totalRows",
		"ariaControls"
	],

	data() {
		return {
			gotoPage: null,
			showGoto: false
		};
	},
	mounted() { },
	watch: {},
	methods: {
		emitInput(value) {
			this.gotoPage = value;
			this.showGoto = false;
			this.$emit("input", value);
		},
		doGotoPage() {
			this.showGoto = false;
			if (this.gotoPage < 1) {
				this.gotoPage = 1;
			}
			if (this.gotoPage > this.totalRows) {
				this.gotoPage = this.totalRows;
			}
			this.$emit("input", this.gotoPage);
		}
	}
};
</script>
