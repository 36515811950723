<!-- Template for a new page -->
<template>
	<video-layout-gallery v-if="uiState.layout == 'gallery'" :uiState="uiState" :participants="participants" />
	<video-layout-focus v-else-if="uiState.layout == 'focus'" :uiState="uiState" :participants="participants" />
</template>

<style scoped>
</style>

<script>
//UI Components
import VideoLayoutGallery from "@/vues/VideoMeeting/components/VideoLayoutGallery";
import VideoLayoutFocus from "@/vues/VideoMeeting/components/VideoLayoutFocus";

//Libraries
import _ from "lodash";

//Services

export default {
	name: "VideoLayout",
	props: ["engine", "participants", "uiState"],
	components: { VideoLayoutGallery, VideoLayoutFocus },
	data() {
		return {};
	},
	created() {},
	mounted() {},

	computed: {},
	watch: {},

	methods: {},
};
</script>