<template>
	<div :class="{ snote: !integrated }" class="bg-white">
		<div ref="note" id="note" class="note"></div>

		<b-modal v-model="insertImageModal" headerClass="indigo">
			<template v-slot:modal-title>
				<h3 class="modal-title full-bar-title">Add Image</h3>
			</template>
			<div class="p-2">
				<template v-if="!s3ImageURL">
					<div class="form-group pl-2 pr-3">
						<label>URL:</label>
						<input type="text" class="form-control" v-model="extImageURL" />
					</div>
				</template>
				<template v-if="!extImageURL">
					<button class="ml-2 btn btn-primary" @click="clickPickImage">Choose file...</button>
					<input
						id="image-input"
						class="d-none"
						type="file"
						accept=".bmp,.jpeg,.jpg,.png,.svg,.tiff,.webp"
						@change="pickImage"
					/>
				</template>
				<img v-if="imageURL" :src="imageURL" style="max-width: 450px" />
			</div>
			<template v-slot:modal-footer>
				<button type="button" class="btn btn-secondary" @click="insertImageModal = false">Cancel</button>
				<button
					type="button"
					class="btn"
					:class="{ [imageURL ? 'btn-success' : 'btn-secondary']: true }"
					:disabled="!imageURL"
					@click="insertImage"
				>
					Add
				</button>
			</template>
		</b-modal>

		<b-modal v-model="insertLinkModal" headerClass="indigo">
			<template v-slot:modal-title>
				<h3 class="modal-title full-bar-title">Insert Link</h3>
			</template>
			<div class="p-2">
				<template>
					<div class="form-group pl-2 pr-3">
						<label>Text to display</label>
						<br />
						<input type="text" class="form-control" v-model="linkText" />
					</div>
				</template>
				<template>
					<div class="form-group pl-2 pr-3">
						<label>To what URL should this link go? </label>
						<br />
						<input type="text" class="form-control" v-model="linkUrl" />
					</div>
				</template>
			</div>
			<template v-slot:modal-footer>
				<button type="button" class="btn btn-secondary" @click="insertLinkModal = false">Cancel</button>
				<button
					type="button"
					class="btn"
					:class="{ [linkUrl ? 'btn-success' : 'btn-secondary']: true }"
					:disabled="!linkUrl"
					@click="insertLink"
				>
					Add
				</button>
			</template>
		</b-modal>

		<b-modal v-model="insertEditLinkModal" headerClass="indigo">
			<template v-slot:modal-title>
				<h3 class="modal-title full-bar-title">Edit Link</h3>
			</template>
			<div class="p-2">
				<template>
					<div class="form-group pl-2 pr-3">
						<label>Text to display</label>
						<br />
						<input type="text" class="form-control" v-model="editLinkText" />
					</div>
				</template>
				<template>
					<div class="form-group pl-2 pr-3">
						<label>To what URL should this link go? </label>
						<br />
						<input type="text" class="form-control" v-model="editLinkUrl" />
					</div>
				</template>
			</div>
			<template v-slot:modal-footer>
				<button type="button" class="btn btn-secondary" @click="insertEditLinkModal = false">Cancel</button>
				<button
					type="button"
					class="btn"
					:class="{ [editLinkUrl ? 'btn-success' : 'btn-secondary']: true }"
					:disabled="!editLinkUrl"
					@click="editLink"
				>
					Update
				</button>
			</template>
		</b-modal>
	</div>
</template>

<script>
require("summernote/dist/summernote.css");
import ImageService from "@/services/ImageService";
import Notie from "@/services/NotieService";
import $ from "jquery";
import Utils from "@/services/Utils";
import sn from "summernote";

import { MathfieldElement } from "mathlive";

const MATH_EQU_ATTR = "math-equ";

export default {
	name: "RichText",
	props: {
		initial: true,
		options: true,
		text: true,
		initSettings: true,
		refresh: true,
		integrated: { type: Boolean, default: false },
		useEquationEditor: false,
	},
	data() {
		let _this = this;
		//Toolbar options
		// toolbar: [
		// 	['style', ['bold', 'italic', 'underline', 'clear']],
		// 	['font', ['strikethrough', 'superscript', 'subscript']],
		// 	['fontsize', ['fontsize']],
		// 	['color', ['color']],
		// 	['para', ['ul', 'ol', 'paragraph']],
		// 	['height', ['height']]
		// ]
		var defaults = {
			lang: this.$i18n.locale,
			toolbar: [
				["style", ["bold", "italic", "underline", "strikethrough", "clear"]],
				["fontsize", ["fontsize"]],
				["para", ["ul", "ol", "paragraph"]],
				["insert", ["mathEq"]],
				["meta", ["undo", "redo"]],
			],
			height: 300,
			placeholder: "Write message here...",
			callbacks: {
				onBlur: (e) => {
					_this.$emit("inputBlur", e);
				},
				onFocus: (e) => {
					_this.$emit("inputFocus", e);
				},
				onEnter: (e) => {
					_this.$emit("inputEnter", e);
				},
			},
		};
		return {
			mut_text: _.cloneDeep(this.text),
			mut_opts: _.extend(defaults, this.options),

			insertImageModal: false,
			uploadingImage: false,
			buttonContext: null,
			extImageURL: null,
			s3ImageURL: null,
			insertLinkModal: false,
			linkText: null,
			linkUrl: null,
			insertEditLinkModal: false,
			editLinkButtonContext: null,
			editLinkUrl: null,
			editLinkText: null,
			insertLinkButtonContext: null,
			mathFields: {},
			pastMathFieldValues: [],
		};
	},
	mounted() {
		this.initialize(this.initial);
	},

	watch: {
		initial() {
			this.loadInitial();
		},
		refresh() {
			this.loadInitial();
		},
	},

	destroyed() {
		this.destroy();
	},

	computed: {
		imageURL() {
			if (this.s3ImageURL) {
				return this.s3ImageURL;
			}

			return this.extImageURL;
		},
	},

	methods: {
		loadInitial() {
			var el = $(this.$el).find(".note");
			el.summernote("code", this.initial);
		},

		changed(we, contents, $editable) {
			if ($editable && $editable.length > 0) {
				this.$emit("update:editable", $editable[0]);
			}
			this.fixMathFields();
			this.$emit("update:text", this.subMathFields(contents));
		},

		manualChange() {
			const el = $(this.$el).find(".note");
			this.changed(null, el.summernote("code"));
		},

		// sadly we must update all math fields every time there is a change in summernote since summernote likes to copy math fields add remove their customization
		fixMathFields() {
			const seenIds = {};
			let needsRefresh = false;
			Array.from(document.getElementsByTagName("math-field")).forEach((mfe) => {
				this.addCustomSettingsToMathField(mfe);

				if (seenIds[mfe.id] !== undefined) {
					mfe.remove();
					return;
				}

				seenIds[mfe.id] = mfe.value;
				// if (seenIds[mfe.id] === "" && this.pastMathFieldValues[mfe.id]) {
				// 	// undo was used, will need to set values and resend content
				// 	seenIds[mfe.id] = this.pastMathFieldValues[mfe.id];
				// 	needsRefresh = true;
				// }
			});
			this.pastMathFieldValues = seenIds;

			setTimeout(
				// this is really hacky and kinda terrible, try to find a better solution (Max's note to self)
				() => {
					Object.entries(seenIds).forEach(([key, val]) => {
						const elem = document.getElementById(key);
						if (elem) {
							// sometimes the value gets erased by summernote, have to manually set it
							elem.setValue(val);

							// insert nodes so the curser can be placed before and after a math field
							if (elem.previousElementSibling === null) {
								const span = document.createElement("span");
								span.innerText = " ";
								elem.parentElement.insertBefore(span, elem);
							}
							if (elem.nextSibling === null) {
								const span = document.createElement("span");
								span.innerText = " ";
								elem.parentElement.appendChild(span);
							}
						}
					});
					// if (needsRefresh) {
					// 	const el = $(this.$el).find(".note");
					// 	this.changed(null, el.summernote("code"));
					// }
				},
				10
			);
		},

		subMathFields(contentsStr) {
			const shouldReplaceFunc = (child) => child.tagName.toLocaleUpperCase() === "MATH-FIELD";
			const subChildFunc = (child) => {
				const newChild = document.createElement("span");
				newChild.setAttribute(MATH_EQU_ATTR, MATH_EQU_ATTR);
				newChild.innerHTML = `\\(${document
					.getElementById(child.id)
					.value.replaceAll("\\exponentialE", "e")}\\)`;
				return newChild;
			};
			return this.replaceTagsInStr(contentsStr, shouldReplaceFunc, subChildFunc);
		},

		addMathFields(contentsStr) {
			console.log("addMathFields");
			const shouldReplaceFunc = (child) => child.hasAttribute(MATH_EQU_ATTR);
			const subChildFunc = (child) => this.createMathField(child.innerText);
			return this.replaceTagsInStr(contentsStr, shouldReplaceFunc, subChildFunc);
		},

		replaceTagsInStr(contentsStr, shouldReplaceFunc, subChildFunc) {
			const recursiveFunc = (parent) => {
				Array.from(parent.children).forEach((child) => {
					const newChildFunc = shouldReplaceFunc(child) ? subChildFunc : recursiveFunc;
					parent.replaceChild(newChildFunc(child), child);
				});
				return parent;
			};

			const div = document.createElement("div");
			div.innerHTML = contentsStr;
			return recursiveFunc(div).innerHTML;
		},

		createMathField(value) {
			const mfe = new MathfieldElement();
			mfe.setValue(value.slice(2, value.length - 2));
			mfe.id = Utils.generateUUID();

			this.addCustomSettingsToMathField(mfe);

			this.mathFields[mfe.id] = mfe;
			return mfe;
		},

		addCustomSettingsToMathField(mfe) {
			// mfe.keybindings = this.mathFieldKeyBindings(mfe);
			mfe.mathModeSpace = "\\:";

			mfe.addEventListener("focus", () => {
				window.mathVirtualKeyboard.show();
			});

			mfe.addEventListener("change", () => {
				window.mathVirtualKeyboard.hide();
				const el = $(this.$el).find("note");
				const range = $.summernote.range;
				el.summernote("editor.setLastRange", range.create(mfe.nextSibling, 0, mfe.nextSibling, 0).select());
				// if (mfe.value == "") {
				// 	mfe.remove();
				// 	this.manualChange();
				// }
			});

			mfe.addEventListener("beforeinput", (event) => {
				if (event.inputType === "deleteContentBackward" && mfe.value === "") {
					mfe.remove();
					delete this.mathFields[mfe.id];
					event.preventDefault();
				}
			});

			mfe.addEventListener("unmount", (event) => {
				event.preventDefault();
			});
		},

		mathFieldKeyBindings(mfe) {
			return [
				...mfe.keybindings, // preserve existing keybindings
				{
					key: "[Enter]",
					command: ["insert", ""], // exits editor for some reason
				},
			];
		},

		destroy() {
			$(this.$el).find("note").summernote("destroy");
			$(this.$el).find("note-editor").summernote("destroy");
		},

		initialize(innerHTML) {
			const initText = this.addMathFields(this.initial || "");

			var el = $(this.$el).find(".note");
			this.debug("Element to initialize summernote", el);

			var customInsertButton = this.customizeImageInsertButton(this.mut_opts);
			var customLinkButton = this.customizeLinkInsertButton(this.mut_opts, customInsertButton);
			this.customizeEditLinkButton(this.mut_opts, customInsertButton, customLinkButton);

			if (this.useEquationEditor) {
				this.addEquationButton();
			}

			el.summernote(this.mut_opts);
			el.on("summernote.change", this.changed);

			if (initText) {
				el.summernote("code", initText);
			} else if (this.initSettings) {
				_.each(this.initSettings, (value, setting) => {
					el.summernote(setting, value);
				});
			}

			Object.values(this.mathFields).forEach((mfe) => {
				// have to do this on init to get proper math field rendering
				const node = document.getElementById(mfe.id);
				if (node) {
					node.setValue(mfe.value);
					this.addCustomSettingsToMathField(node);
				}
			});

			this.changed(null, initText);

			Utils.throttleMathJAX();
		},

		addEquationButton() {
			const el = $(this.$el).find(".note");
			const mathEq = (context) => {
				var ui = $.summernote.ui;
				var btn = ui.button({
					container: el,
					contents: '<i class="fa fa-sigma"/>',
					tooltip: "Add Math Equation",
					click: () => {
						const mfe = this.createMathField("");
						context.invoke("editor.insertNode", mfe);
						mfe.focus();
						window.mathVirtualKeyboard.show();
					},
				});
				return btn.render();
			};
			this.mut_opts.buttons = {
				mathEq,
				...this.mut_opts.buttons,
			};
		},

		customizeImageInsertButton(opts) {
			let found = false;
			if (!opts) return;
			if (!opts.toolbar) return;
			for (let group of opts.toolbar) {
				if (group[0] && group[1] && group[1].length && group[0] == "insert") {
					for (let i in group[1]) {
						if (group[1][i] == "picture") {
							group[1][i] = "customImage";
							found = true;
						}
					}
				}
			}

			if (found) {
				var el = $(this.$el).find(".note");
				var customButton = (context) => {
					var ui = $.summernote.ui;

					// create button
					var button = ui.button({
						contents: '<i class="note-icon-picture"/>',
						container: el,
						click: () => {
							this.clearImage();
							this.insertImageModal = true;
							this.buttonContext = context;
						},
					});

					return button.render(); // return button as jquery object
				};

				this.mut_opts.buttons = {
					customImage: customButton,
				};
				return customButton;
			}
		},

		customizeLinkInsertButton(opts, customInsertButton) {
			if (!opts) return;
			if (!opts.toolbar) return;
			for (let group of opts.toolbar) {
				if (group[0] && group[1] && group[1].length && group[0] == "insert") {
					for (let i in group[1]) {
						if (group[1][i] == "link") {
							group[1][i] = "customLink";
						}
					}
				}
			}

			var el = $(this.$el).find(".note");
			var customLinkButton = (context) => {
				var ui = $.summernote.ui;

				// create button
				var button = ui.button({
					contents: '<i class="note-icon-link"/>',
					container: el,
					click: () => {
						this.clearLink();
						this.insertLinkModal = true;
						this.insertLinkButtonContext = context;
					},
				});

				return button.render(); // return button as jquery object
			};

			this.mut_opts.buttons = {
				customImage: customInsertButton,
				customLink: customLinkButton,
			};

			return customLinkButton;
		},

		customizeEditLinkButton(opts, customInsertButton, customLinkButton) {
			if (!opts) return;
			if (!opts.popover) return;
			if (!opts.popover.link) return;
			for (let group of opts.popover.link) {
				if (group[0] && group[1] && group[1].length && group[0] == "link") {
					for (let i in group[1]) {
						if (group[1][i] == "linkDialogShow") {
							group[1][i] = "customEditLink";
						}
					}
				}
			}

			var el = $(this.$el).find(".note");
			var customEditLinkButton = (context) => {
				var ui = $.summernote.ui;

				// create button
				var button = ui.button({
					contents: '<i class="note-icon-link"/>',
					container: el,
					click: () => {
						this.insertEditLinkModal = true;
						this.editLinkButtonContext = context;
						var e = context.invoke("editor.getLinkInfo");
						this.editLinkText = e.text;
						this.editLinkUrl = e.url;
					},
				});

				return button.render(); // return button as jquery object
			};

			this.mut_opts.buttons = {
				customImage: customInsertButton,
				customLink: customLinkButton,
				customEditLink: customEditLinkButton,
			};
		},

		clearImage() {
			this.s3ImageURL = null;
			this.extImageURL = null;
		},

		clearLink() {
			this.linkUrl = null;
			this.linkText = null;
		},

		placeCursorAtEnd() {
			let el = $(this.$el).find(".note-editable");
			el = el ? el[0] : null;
			if (!el) {
				this.logError("Failed to find contenteditable element");
			}

			var range = document.createRange();
			var sel = window.getSelection();
			var childLength = el.childNodes.length;
			if (childLength > 0) {
				var lastNode = el.childNodes[childLength - 1];
				var lastNodeChildren = lastNode.childNodes.length;
				range.setStart(lastNode, lastNodeChildren);
				range.collapse(true);
				sel.removeAllRanges();
				sel.addRange(range);
			}
		},

		pickImage(event) {
			if (!event.target.files || !event.target.files[0]) {
				console.log(event);
				console.log("Failed to pick file");
			}
			this.loading = true;
			ImageService.addImage(event.target.files[0])
				.then((resp) => {
					console.log(resp.data);
					this.s3ImageURL = resp.data;
					this.$emit("input", resp.data);
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to upload image", err);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		insertImage(callback) {
			console.log("insertImage", this.imageURL);
			this.buttonContext.invoke("editor.insertImage", this.imageURL);
			this.buttonContext.invoke("editor.pasteHTML", "<br>");
			this.insertImageModal = false;
			setTimeout(() => {
				this.placeCursorAtEnd();
			}, 200);
		},

		insertLink(callback) {
			console.log("insertLink", this.editLink);
			var isHttp = false;
			var isHttps = false;
			if (this.linkUrl) {
				if (this.linkUrl.toUpperCase().indexOf("HTTP://") != -1) {
					isHttp = true;
				}
				if (this.linkUrl.toUpperCase().indexOf("HTTPS://") != -1) {
					isHttps = true;
				}
				if (!isHttp && !isHttps) {
					this.linkUrl = "http://" + this.linkUrl;
				}
			}
			this.insertLinkButtonContext.invoke("editor.createLink", {
				text: this.linkText,
				url: this.linkUrl,
				isNewWindow: true,
			});
			this.insertLinkModal = false;
			this.insertEditLinkModal = false;
		},

		editLink(callback) {
			console.log("editLink", this.editLinkUrl);
			var isHttp = false;
			var isHttps = false;
			if (this.editLinkUrl) {
				if (this.editLinkUrl.toUpperCase().indexOf("HTTP://") != -1) {
					isHttp = true;
				}
				if (this.editLinkUrl.toUpperCase().indexOf("HTTPS://") != -1) {
					isHttps = true;
				}
				if (!isHttp && !isHttps) {
					this.editLinkUrl = "http://" + this.editLinkUrl;
				}
			}
			var e = this.editLinkButtonContext.invoke("editor.getLinkInfo");
			e.text = this.editLinkText;
			e.url = this.editLinkUrl;
			this.editLinkButtonContext.invoke("editor.restoreRange");
			this.editLinkButtonContext.invoke("editor.createLink", e);
			this.insertLinkModal = false;
			this.insertEditLinkModal = false;
		},

		clickPickImage() {
			$("#image-input").click();
		},
	},
};
</script>

<style>
math-field {
	/* font-size: 1rem; */
	min-width: 100px;
}

body {
	--keyboard-zindex: 3000;
}

math-field::part(menu-toggle) {
	display: none;
}
math-field::part(virtual-keyboard-toggle) {
	display: none;
}
</style>

<style scoped>
.snote {
	/* background-color: white; */
	margin-bottom: 20px;
}
.dropdown-menu {
	color: initial;
	font-size: initial;
}
</style>
