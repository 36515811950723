import axios from "axios";

var backendKey = null;

export default {
	getKey() {
		return new Promise((resolve, reject) => {
			resolve(this.getLocalKey());
		});
	},

	getLocalKey() {
		var host = window.location.hostname;
		if (host == "app.oscarscore.com") {
			return "app.oscarscore.com,835532998476473#B13FjS7wWMr44QZhXc8NXYBRDZtVTNqV5ZqJGMr5UTFRFbBBDcmljMwomc7E7anxWR656VkxmMwFEVx3kb6AVSkVTe7Jjd5E4LygTdFFlemF4a0ZVSv8WdIFTMEZkVZx4VhZjTSl5KZp4bqdFWMd4UzEzVNlkezJUQolVVJZXOiVXQNF6Uh94aMVXZpNnZXJlNYdXZh5mV6JHarZWeSlGWNpUN9g5U4MFS7tmdppWbQBlSStWZzcWaQhnZGJGZJpmYyEGNQd5bkFUVPVGMXpXWnZDd82mdD3ib4tyTQhkNqJnSLB5Z53iarNVQQZ4R4IHc9o6SiojITJCLigDM8EUMxIzMiojIIJCL4YzN7MDM5ITM0IicfJye=#Qf35VfiUVQLFlI0IyQiwiI7EjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICOzUDN7ADIwMTNwQjMwIjI0ICdyNkIsISbvNmLlJ7bjNnchN6cv9CcwFmI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLiMzN4YzN4gTO9IzM5UzM8IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBlTbZ3kUUlVOqhUbUxEZq3Udk56L8NHWHp5TrAje434a5pXSMp5SE3Cc9tCOKZTOplVNYJlRrEndMJmZVV5cn96YqNWR7ZkWxZGSTlVWvdEWo3WRvV7bLBnZ55GUsNFO4RuR"; // v17
			// return "app.oscarscore.com,442189295528168#B0hg5UENzK5YGbh9UYwljUSFVU0hDchhGeYFlb7ZlR7gWcJNzK0pVas3CbypXe9pHO0BDRNZjY0dGdLJ7LBdEULRzbLdTNtpnMOJ7YMhUM826RPhHOE9US8pFMr3kWYJnZJFDWuF5ZDp7MyIlaBx6R7s4Q7U4TRZWZ9ZzZ83mZRlHWXVlcx8UaEFmNxElZUxWWQx4LulmUBhnTB36byNEOvE4byJXZ7BVTjRHVTRVesxETYV6N4kzYhVEawZjMElFbCZmRoJ7RsxEZoNmRoR6Z8YkYxN7LUdHex56Zx8WbpVTdvclZGFXV8Q5QlZGM88WaIFkI0IyUiwiIGFTQyQkQxcjI0ICSiwSN4gDO8kjNxUTM0IicfJye#4Xfd5nIMFUUTJiOiMkIsISNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiUjM6MzNwAiMygDMyIDMyIiOiQncDJCLi46bj9SZy36YzJXYjN7buAHchJiOiMXbEJCLiQnbl5GcvxWZ6VGRgoVTiojIh94QiwiI8YTM8ITN5kjM9gTMyQDNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TU55mbCFGavRmc6hmWBh4cXhXahpWcPZHUwlTYuVTOh5kdaJEZrVmaGhFOLJ5STl5YnR4SkBTdkljVuh7R5UzdzFUSFh4UWlXWzclTrMVeaRGWwh5VRJTNOVkVxJ6MJtmRxU4U"; // v15
		} else if (host == "www.oscarscore.uk") {
			return "www.oscarscore.uk,759731248768982#B13deLSxkb6Q4du36d5MjQKZ4SRJFVHxWYh5EOiFVb9YVUxBlRPJ6T7xmZzpnZZZTU5QnYiFEer9mZqV5YuZlY8RUSSdHbR96cpJjcrd4LUJDVvVjenlFTuVTQwF7QyFkR6ElTkBVY4g7duJTcHdGU6siTQlVbsFHT4R4RBJjW0pVWO3UNx2ib8BFSTVGaZVjU6RTc8pUTEp5N85EURRWZ8R5VBZXZtlTb9dTMQJ4Q5knW4dVRqdDeyQjYkRVewZnNrVGe54WQWtEWUZ5R62GWtRldkhXQ096TolkZ9ckRaRDUv3mdRVmMFZmUzY4LNZ6SxQkI0IyUiwiIwM4NzcTRGZjI0ICSiwCM9gzM4cjN7ATM0IicfJye=#Qf35VfiUVQLFlI0IyQiwiI7EjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsIyNyAjM8ADIwMTNwQjMwIjI0ICdyNkIsIya59SZy36YzJXYjN7buc7d7JiOiMXbEJCLiQnbl5GcvxWZ6VGRgoVTiojIh94QiwiIygTO8YzN8QjMxMzN9UzNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUNVWRNllZV5EdhZndohmStJWcyoGStlWY4FWMWp5YYpke5JDUKRXOaNTM6YjSml5UrJUZ9Q4RzdEdlZEeF9EeVhlUvYjZvlWeHt4V7sENrdmdVt4K7Z5QvlXU94mbBtyMMFhN"; // v17
			// return "www.oscarscore.uk,368228167421326#B0kNdJHU0p7LNt6Qyp6VENWO7gEdmVzV4JVUjFWZIdVNpdkbDFUZjhUOFRjbOJkZPxGbYh6ajtSVhV5QjVXVtFUbiFGRnp7LlN6dHhjNxcnTZh4LuNjWkhmY4dmMOVWNOR5V4lHbllVRhxURjJDO4tSNKdUTklHVDFVOspUYG5WZzMXVrQmc8o7crlnU0RDSpxEbxtSdRZVNFJXbyMHaHJWSHR6YCVEUTdDW9UkTvhzLuVHU7R6RvRkNN3UUzVEMURlcUJ5SwEnNGJEU6RkYIlVZwBXSvUXNSFTeKF7LvQXWtVDM7UTdKtSVUNXNZJ4T5YUaiojITJCLiM4QCN4M8UkI0ICSiwiN7AjN9IjMyATM0IicfJye35XX3JCTBF5UiojIDJCLiUTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIxUDNzcDMgIjM8AjMyAjMiojI4J7QiwiIrVnLlJ7bjNnchN6cv9yd7dnI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLiYjMzEjM4cjNxgjMygjNzIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7J4RQlmYyxmeCJzcnZnVYFmdGJnYzl5S9ckb9RWT4IUZ7UTd6J5cnBzc9BVSLtWa4JGR8tCVoplbqNUNrknaZFTbrgVQDVjYpJDWFd4M5YTTNdnUJdGVlNEbKJEaCBVOQB2eJc"; // v15
		} else if (host == "staging.oscarscore.com") {
			return "staging.oscarscore.com,623263634715488#B15Z4F6b7I4bBxkRxZEaDR4a03SNOBFMBlUNmJ4LnpVTuBDcudkb05GRvUFMoN4R9tUWnFUMEVFZBZkViZDV99UUUhGewx6QLBnZiZ5QrYEZzxWUChTQRVFVxQVer2kcBh5Ll3WSWFDeyITbz3mTTJmWaFHSsZEZkZGWyI7RwNnYRZHZSdXQyUWUywmWiVUT8JzZ58GaKllML34RiB7K6MmNkBDUjVEdwVnNRVDd84ERPdET4lGc8t4auZVcVdVO6hmdZtmYil4dFNjTkFzdvQmTvZHSC96SYpnQ8MUQo3kQIRVRpRDSrsiV4dTVIlUN5lHTWZlViojITJCLiETNEZUQxE4MiojIIJCLxMTOwkDM7czN0IicfJye#4Xfd5nIVF4SRJiOiMkIsIyNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLicDM5UzNwACMzUDM4IDMyIiOiQncDJCLi46bj9SZy36YzJXYjN7bucmbpdWY4NnI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLigDO4UTM7QzM6MjNyMjM6IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP73UcUZVaURXSxATRt9mb9V7NVdGeHF6dIhEVtVzdRdTRWZ5TrUUQn56L6RjW7Q7LlJDZUVHdnFHVFBzQrplUMNDS92URalVNlFFUmB5aqVFSvQDaPZGe7p4K9hTRQRMUZp"; // v17
			// return "staging.oscarscore.com,321748295734636#B1NMhtWZoRkZIVzdzkHSp9mRndkcJNFawZUQwNlSxllZTl7KzJGcT94VklVNLZzR6NWQpN6bOZnZHVDbzk4bxQ7doh5SDdlZ5JmNvVnapVnerhleth6KKV6NTRFTJZ4b0RXYxAnesVGRthFNsxUSvAzaylHa4NEdK34QmtEUiJ7LQtWNH5EcvYTerdUWHFXRVB5KXt6dTNDbhZ4MNlkQRVXNBt4d6xGZ7cXSTh6b4RHT5ZDaFZkaORGaixGULRGVmBVW5IWWwlHcVNzKLJEWyVnWv3SewA5My8kaQtWaMl7QtBDTQtkM6h6NSZkWwAlS7QneEFEUiojITJCLiIkMyQTQBFjI0ICSiwyM4UDM4kjM5gTM0IicfJye#4Xfd5nIXR5QaJiOiMkIsIiNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLicTN8ADOwACOxkDMzIDMyIiOiQncDJCLi46bj9SZy36YzJXYjN7bucmbpdWY4NnI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLiYzM6QzM7UTOygDN7EjMzIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7VUdL5WOahVYrcVZ93SNuR6V7IUOHl7NLdzUVRzM8oEN9ZlNuRkZpFXTy4Wa8l6cYpEZwg7V88EeCNWNTZFTjdEZQFDT6pEOmVzTThTb5QFTnhGMlZEMBFWMzkVMlNJaLJ"; // v16
			// return "staging.oscarscore.com,899216931139484#B13K4kVVC3EZsBlZGVUaKlXRDRVQklFdthTYMdUUTB5ZsBnV6tES0FDWtN6c42GUQRlbEBzQ5dVOxB5bxNlayZ6aPlDMINkMRhkZjFWW6VVMkVHbOVESDdzc4c7SFFzYLZHOTVld9FVcQNmNpdUShFkMH5kaO9ERkR5RydWb7d4b4VHWQpXM7UlerwkQnVDZYlTRaJTVtFHcpdTM5tGTrg5YBtGUzFzL0ZkSUJFZTpXexN4KyJkVtFzQEJGbWVHM996U4Z6TJBTQZNFVaFlQ5RUapVTYNZTRQdVS7ZlNvw4cZ96UZRHN5kzT6MzNLFHamVFSDxEZiojITJCLiUTR5YENBZkMiojIIJCLzcTNxAzM7YTO0IicfJye#4Xfd5nIXR5QaJiOiMkIsIiNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiMzM8MzNwACNxkDMzIDMyIiOiQncDJCLi46bj9SZy36YzJXYjN7bucmbpdWY4NnI0IyctRkIsICduVWbw3GblZXZEBiWNJiOiEmTDJCLiQDO4kzMxEzM9YTMykTO8IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBtWWMZzczl4Vz34RLNXRClmQC5GbQRmZrBDdvFDdJ34RalGOSVXW0F6SY5ERx8WTx44NlVzMzlEZTJmeWZ6KPpVethlWylTM0JnSmxmazZFU0hGZj34NRNlaxcnT55zZ5Z"; // Old?
		}
	},

	keyIdentifier(key) {
		if (!key) {
			return "(blank)";
		}
		let parts = key.split("#");
		return parts[0];
	},
};
