<template>
	<div class="lightbox" :class="{ active: contentElement }">
		<div class="lightbox-background" @click.self="hide">
			<div class="lightbox-content-container" ref="contentContainer"></div>
		</div>
	</div>
</template>

<style scoped>
.lightbox {
	display: none;
}
.lightbox.active {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.lightbox.active .lightbox-background {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0 0 0 / 50%);
	backdrop-filter: blur(2px);
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	animation: fade ease 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.lightbox.active .lightbox-content-container {
	background-color: white;
	max-height: 100%;
	max-width: 100%;
	padding: 6px;
	border-radius: 4px;
	cursor: default;
	transform-origin: center right;
	animation: expand ease 0.25s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes expand {
	0% {
		transform: scale(0.1);
	}
	100% {
		transform: scale(1);
	}
}
</style>

<script>
import Lightbox from "@/services/LightboxService";

export default {
	name: "Lightbox",
	props: [],

	data() {
		return {
			lb: Lightbox,
			contentElement: null,
		};
	},
	created() {
		Lightbox.bind(this);
	},
	destroyed() {
		Lightbox.unbind(this);
	},
	watch: {
		contentElement() {
			if (this.contentElement && this.contentElement.style) {
				this.contentElement.style.width = "";
				this.contentElement.style.maxWidth = "100%";
				this.contentElement.style.maxHeight = "100%";
			}
			this.debug("Got content element", this.contentElement);
			this.$refs.contentContainer.replaceChildren(this.contentElement);
		},
	},
	methods: {
		hide() {
			this.contentElement = null;
		},
	},
};
</script>
