<template>
	<div class="box p-2">
		<span class="float-right text-muted">
			<a class="no-select" v-tippy :title="$t('tooltip.refresh')" @click="getCounts">
				<i class="fa fa-sync text-sm" :class="{ 'fa-spin': loading }"></i>
			</a>
		</span>
		<h2>{{ title }}</h2>
		<b-row>
			<b-col :class="{ invalid: !fromDate }">
				<label>{{ $t("fields.from_date") }}</label>
				<label v-if="!fromDate">({{ $t("fields.must_select_date") }})</label>
				<config-date v-model="fromDate" />
			</b-col>
			<b-col :class="{ invalid: !toDate }">
				<label>{{ $t("fields.to_date") }}</label>
				<label v-if="!toDate">({{ $t("fields.must_select_date") }})</label>
				<config-date v-model="toDate" />
			</b-col>
		</b-row>
		<loading v-if="loading" type="large" class="text-center" />
		<div v-else>
			<div class="py-3">
				<canvas v-chartjs="chartOptions" style="width: 100%; height: 350px" id="asdf"></canvas>
			</div>

			<h5 class="text-center">Time Span: {{ timeSpan }}</h5>
		</div>
	</div>
</template>

<script>
import ReportingService from "@/services/ReportingService";
import Notie from "@/services/NotieService";

import ConfigDate from "../ConfigDate.vue";
import Loading from "../global/Loading.vue";

require("@/directives/chartjs");

export default {
	components: { ConfigDate, Loading },
	name: "TenantDashGraph",

	props: {
		exported: Boolean,
		uniqueRefs: Boolean,
	},

	data() {
		const fromDate = new Date();
		fromDate.setDate(fromDate.getDate() - 6);
		return {
			loading: true,
			fromDate,
			toDate: new Date(),
			labels: [],
			data: [],
			timeSpan: "Daily",
		};
	},

	created() {
		this.getCounts();
	},

	watch: {
		fromDate() {
			this.getCounts();
		},

		toDate() {
			this.getCounts();
		},

		exported() {
			this.getCounts();
		},

		uniqueRefs() {
			this.getCounts();
		},
	},

	methods: {
		async getCounts() {
			const { fromDate, toDate, exported, uniqueRefs } = this;

			if (!(fromDate && toDate)) {
				this.labels = [];
				this.data = [];
				return;
			}

			this.loading = true;

			this.sendLoadingNotification();

			try {
				const {
					data: { time_span, counts },
				} = await ReportingService.getTenantSubmittedRespCounts(fromDate, toDate, exported, uniqueRefs);

				this.timeSpan = time_span;
				this.labels = counts.map(({ label }) => label);
				this.data = counts.map(({ count }) => (count === 0 ? null : count)); //bars will not display null values (but will for 0)
			} catch (err) {
				Notie.error("Failed to get graph data", err);
				console.log(err);
			}

			this.loading = false;
		},

		async sendLoadingNotification() {
			await new Promise((res) => setTimeout(res, 3000)); // wait 3 seconds

			if (this.loading) {
				Notie.info("Loading graph data...", "this might take a while");
			}
		},
	},

	computed: {
		title() {
			return this.exported
				? this.$i18n.t("TenantDashboard.exported_resps")
				: this.$i18n.t("TenantDashboard.imported_resps");
		},

		chartOptions() {
			const { labels, data, exported } = this;
			const backgroundColor = exported ? "rgba(0, 0, 188, 0.2)" : "rgba(0, 188, 0, 0.2)";

			return {
				type: "bar",
				data: {
					labels,
					datasets: [
						{
							label: this.title,
							data,
							fill: true,
							backgroundColor,
							borderWidth: 2,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBorderColor: "grey",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 2,
							pointHoverRadius: 4,
							pointHoverBorderColor: "#fff",
							pointHoverBorderWidth: 2,
							pointRadius: [],
							pointHitRadius: 40,
							spanGaps: false,
							minBarLength: 8,
						},
					],
				},
				options: {
					tooltips: {
						displayColors: false,
						titleFontSize: 14,
						bodyFontSize: 14,
					},
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								display: true,
							},
						],
						yAxes: [
							{
								display: true,
								ticks: {
									suggestedMin: 0,
									suggestedMax: 10,
								},
							},
						],
					},
					legend: {
						display: false,
					},
					layout: {
						padding: {
							top: 10,
						},
					},
				},
			};
		},
	},
};
</script>
