//Driver for normal scoring (1st/2nd) Reads

import axios from "axios";

export default {
	icon: "check",
	name: "Scoring",
	allowUpdateScore: true, //Only for QC sets
	showScores() {
		return false;
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: true,
	canChoosePreviousAnnotations: true,

	getNext(ctx) {
		console.log("Normal Scoring Driver", ctx);
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		let params = {};

		if (ctx.skipqc) {
			params.skipqc = true;
		}
		if (ctx.qualonly) {
			params.qualonly = true;
		}
		if (ctx.resp) {
			params.r = ctx.resp;
		}
		if (ctx.isAuditing) {
			params.isAuditing = ctx.isAuditing;
		}
		console.log("params", params);
		var promise = axios.get(`/v1/score/${sid}/${iid}`, { params: params });
		promise.then(function (resp) {
			console.log("NormalSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},

	getResponse(ctx, responseID) {
		//Only for QC sets
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		let params = {
			r: responseID,
			qualonly: ctx.qualonly,
		};

		var promise = axios.get(`/v1/score/${sid}/${iid}`, { params: params });
		promise.then(function (resp) {
			console.log("NormalSD GetResponse:" + resp.data.response.ref_id);
		});
		return promise;
	},

	submitScore(score, response, keep, forcedRepeatScore) {
		score.response_id = response.id;

		var promise = axios.post("/v1/legacy/submit", score, {
			params: { keep: keep, r: forcedRepeatScore },
		});
		promise.then(function (resp) {
			console.log("NormalSD Submitted");
		});
		return promise;
	},

	checkSectionForResponses(sectionID) {
		return axios.get(`/v1/can_score_section/${sectionID}`);
	},
};
