<template>
	<div id="app">
		<router-view :key="$route.fullPath" />
	</div>
</template>

<script>
import Vue from "vue";
//Load any custom css/js that should be in the global scope.
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import "../node_modules/roboto-fontface";

import "../node_modules/summernote/dist/summernote-bs4.css";

import "@/assets/css/apply.css";
import "@/assets/css/notie.css";
import "@/services/Common";
import notie from "notie";

require("@fullcalendar/bootstrap/main.css");
require("@fullcalendar/core/main.css");
require("@fullcalendar/daygrid/main.css");
require("@fullcalendar/timegrid/main.css");
require("@fullcalendar/list/main.css");
require("@/assets/css/fullcalendar.css");
require("@fullcalendar/bootstrap/main.js");

//Global components
import Navbar from "@/components/global/Navbar";
Vue.component("navbar", Navbar);
import Footer from "@/components/global/Footer";
Vue.component("mzfooter", Footer);
import Sidenav from "@/components/global/Sidenav";
Vue.component("sidenav", Sidenav);
import Loading from "@/components/global/Loading";
Vue.component("loading", Loading);
import "../node_modules/vue-select/dist/vue-select.css";

import Page from "@/components/global/Page";
Vue.component("page", Page);

//Global directives
require("@/directives/spinner");
require("@/directives/break-underscores");

// Polyfills
require("@/polyfills/polyfills.js");

import vSelect from "vue-select";
//Patch the vue-select component so that it is not deselectable by default
//Adding the "deselectable" property will return it back to its previous behavior
vSelect.methods.oldSelect = vSelect.methods.select;
vSelect.methods.select = function (option) {
	if (option.disabled) {
		return;
	}
	if (this.isOptionSelected(option) && !this.deselectable) {
		this.onAfterSelect(option);
	} else {
		this.oldSelect(option);
	}
};
vSelect.props.deselectable = {
	type: Boolean,
	default: false,
};
vSelect.computed.showClearButton = function () {
	return this.deselectable && !this.multiple && !this.open && this.mutableValue != null;
};
// Patch the vue-select component so that if you hold shift while selecting an option in a
// dropdown that allows multiple selection, the dropdown will not close.
window.addEventListener("keydown", (e) => {
	if (e.key == "Shift") {
		vSelect.isShiftKeyDown = true;
	}
});
window.addEventListener("keyup", (e) => {
	if (e.key == "Shift") {
		vSelect.isShiftKeyDown = false;
	}
});
vSelect.methods.onAfterSelect = function (option) {
	if (this.closeOnSelect && !vSelect.isShiftKeyDown) {
		this.open = !this.open;
		this.searchEl.blur();
	}

	if (this.clearSearchOnSelect) {
		this.search = "";
	}
};
Vue.component("v-select", vSelect);

Vue.filter("replaceIfExists", function (def, rep) {
	if (rep && rep != "") {
		return rep;
	} else {
		return def;
	}
});

//Plugins
import Meta from "vue-meta";
Vue.use(Meta);
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import VueTippy from "vue-tippy";
Vue.use(VueTippy, {
	arrow: true,
	arrowType: "sharp",
	// duration: [350, 100000]
	// theme: "gradient"
});
import VueLocalStorage from "vue-localstorage";
Vue.use(VueLocalStorage, { name: "ls", namespace: "mzd" });

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
Vue.use(VueToast);

//Must be imported after BootstrapVue is loaded, in order to override certain css (e.g. table sort arrows)
import "@/assets/css/app.css";

notie.setOptions({
	positions: {
		alert: "bottom",
	},
});

//Configure mathjax delimiters
MathJax.Hub.Config({
	tex2jax: {
		inlineMath: [
			["$", "$"],
			["\\(", "\\)"],
		],
	},
});

import PrettyLogger from "@/services/PrettyLogger";
Vue.mixin({
	methods: {
		log(...inputs) {
			let componentName = this?.$options?.name || "OSCAR";
			PrettyLogger.log(componentName, ...inputs);
		},
		debug(...inputs) {
			let componentName = this?.$options?.name || "OSCAR";
			PrettyLogger.debug(componentName, ...inputs);
		},
		logWarn(...inputs) {
			let componentName = this?.$options?.name || "OSCAR";
			PrettyLogger.logWarn(componentName, ...inputs);
		},
		logError(...inputs) {
			let componentName = this?.$options?.name || "OSCAR";
			PrettyLogger.logError(componentName, ...inputs);
		},
	},
});

import "material-design-icons-iconfont/dist/material-design-icons.css";

import axios from "axios";
import TimeoutService from "@/services/TimeoutService";
import AuthService from "@/services/AuthService";
import moment from "moment";

export default {
	name: "App",

	data() {
		return {};
	},

	created() {
		axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
		axios.interceptors.request.use((config) => {
			this.addPageHeaders(config);

			if (config.url == "/v1/version") {
				return config;
			}

			if (!config.headers["X-No-Timeout"]) {
				TimeoutService.updateTimeout();
			}

			return config;
		});
		if (AuthService.getUserPromise) {
			TimeoutService.updateTimeout();
		} else {
			console.log("App started but not logged in");
		}

		window.addEventListener("keydown", (e) => {
			if ((e.altKey == true || e.metaKey == true) && (e.key == "k" || e.keyCode == 75)) {
				if (!this.$i18n.showKeys) {
					this.$i18n.showKeys = true;
					this.$i18n.prevLocale = this.$i18n.locale;
					let newLang = "keys";
					this.$i18n.locale = newLang;
				} else {
					this.$i18n.showKeys = false;
					this.$i18n.locale = this.$i18n.prevLocale;
					delete this.$i18n.prevLocale;
				}
			}
		});
	},

	methods: {
		addPageHeaders(config) {
			let loc = this.$router.resolve(location.hash);
			config.headers["X-Referer-Page"] = loc.resolved.name;
			config.headers["X-Referer-Params"] = JSON.stringify(loc.resolved.params);
		},
	},
};
</script>

<style>
/* Any non-scoped global css should go into src/assets/app.css or an individual css file in that location and imported.  */
/* Any VUE styles that are not scoped e.g. <style scoped> get put into the minified webpack css and are loaded first. */
/* This means that any css loaded via import/require will overwrite them. Scoped css is immune to this as VUE changes the names of the classes to be specific to the vue*/
</style>
