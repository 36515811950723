<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ItemEdit.Quotas.title") }}</div>

		<div class="row m-0 py-4 px-2">
			<div>
				<table class="table">
					<thead>
						<tr>
							<th class="w-dropdown">{{ $t("fields.role") }}</th>
							<th class="w-dropdown">{{ $t("fields.limit_type") }}</th>
							<th class="w-number">{{ $t("fields.limit") }}</th>
							<th class="w-dropdown">{{ $t("fields.score_type") }}</th>
							<th class="w-dropdown">{{ $t("fields.timeframe") }}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!item.quotas || !item.quotas.length">
							<td colspan="7" class="text-center">{{ $t("ItemEdit.Quotas.error.no_quotas") }}</td>
						</tr>
						<tr v-for="quota in item.quotas" :key="quota.id">
							<td class="w-dropdown">
								<config-select :options="selectableRoles" byField="id" v-model="quota.role_id" />
							</td>
							<td class="w-dropdown">
								<config-select
									:options="fs.quotaLimitTypes()"
									sortBy="sequence"
									byField="id"
									v-model="quota.limit_type"
									@input="limitTypeChanged(quota)"
								/>
							</td>
							<td class="w-number">
								<input
									v-if="quota.limit_type == 0"
									class="form-control"
									v-model.number="quota.limit"
									type="number"
									min="0"
								/>
								<percent-input
									v-if="quota.limit_type == 1"
									class="form-control"
									v-model.number="quota.limit"
									type="number"
									min="0"
									max="100"
								/>
							</td>
							<td class="w-dropdown">
								<config-select
									:options="fs.quotaScoreTypes()"
									sortBy="sequence"
									byField="id"
									v-model="quota.score_type"
								/>
							</td>
							<td class="w-dropdown">
								<config-select
									:options="fs.quotaTimeframes()"
									byField="id"
									v-model="quota.timeframe"
									@input="timeframeTypeChanged(quota)"
								/>
							</td>
							<td class="w90">
								<button
									@click="removeQuota(quota)"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									title="Remove Quota"
								>
									<i class="fa fa-trash"></i>
								</button>
							</td>
						</tr>
						<tr>
							<td colspan="4">
								<button
									@click="addQuota()"
									style="max-width: 600px"
									class="btn btn-sm btn-block btn-success m-auto"
								>
									{{ $t("ItemEdit.Quotas.add") }}
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<style>
.w-dropdown {
	width: 200px;
}
.w-number {
	min-width: 120px;
	width: 120px;
}
</style>

<script>
import ConfigSelect from "@/components/ConfigSelect";
import PercentInput from "@/components/PercentInput";
import fs from "@/services/FormatService";
import Utils from "../../services/Utils";

export default {
	name: "ItemEditQuotas",

	props: ["id", "item", "client", "defaultTab"],

	components: { ConfigSelect, PercentInput },

	data() {
		return {
			fs: fs,
			quotaRoles: {},
		};
	},

	computed: {
		selectableRoles() {
			let roles = _.cloneDeep(this.client.roles);
			roles.push({
				name: "All",
				meta: true,
				id: true,
			});

			return roles;
		},
	},

	created() {},

	methods: {
		addQuota() {
			if (!this.item.quotas) {
				this.$set(this.item, "quotas", []);
			}
			this.item.quotas.push({
				id: Utils.generateUUID(),
				limit_type: 0,
				limit: 100,
				role_id: true,
				score_type: 1,
				timeframe: 1,
			});
		},

		removeQuota(quota) {
			this.item.quotas = _.without(this.item.quotas, quota);
		},
	},
};
</script>
