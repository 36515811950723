import axios from "axios";

export default {
	icon: "verified_user",
	name: "Backreading",
	showScores() {
		return true;
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: true,

	getNext(ctx) {
		const { section_id, item_id } = ctx;
		return axios.get(`/v1/backread/${section_id}/${item_id}`);
	},

	submitScore(score, response, keep, forcedRepeatScore) {
		score.response_id = response.id;
		return axios.post("/v1/legacy/submit", score, {
			params: { keep: keep, r: forcedRepeatScore }
		});

	}
};
