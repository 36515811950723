export default {
	forName(name) {
		return {
			log: (...inputs) => this.log(name, ...inputs),
			debug: (...inputs) => this.debug(name, ...inputs),
			logWarn: (...inputs) => this.logWarn(name, ...inputs),
			logError: (...inputs) => this.logError(name, ...inputs),
		}
	},

	log(name, ...inputs) {
		let badgeOscarGreenCss =
			"background-color: rgb(0, 150, 136); color: rgba(255, 255, 255, 1); font-size: 0.7rem; padding: 2px 4px; border-radius: 4px";
		console.log(`%c${name}`, badgeOscarGreenCss, ...inputs)
	},

	debug(name, ...inputs) {
		let badgeBlueCss =
			"background-color: #007bff; color: rgba(255, 255, 255, 1); font-size: 0.7rem; padding: 2px 4px; border-radius: 4px";
		let time = (new Date()).toLocaleTimeString()
		console.log(`%c${name} (${time})`, badgeBlueCss, ...inputs)
	},

	logWarn(name, ...inputs) {
		let badgeYellowCss = "background-color: #fd9c03; color: rgba(255, 255, 255, 1); padding: 2px 4px; border-radius: 4px"
		console.log(`%c${name}`, badgeYellowCss, ...inputs)
	},

	logError(name, ...inputs) {
		let badgeRedCss = "background-color: #dc1632; color: rgba(255, 255, 255, 1); padding: 2px 4px; border-radius: 4px"
		console.log(`%c${name}`, badgeRedCss, ...inputs)
	}
}