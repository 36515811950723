<template>
	<div class="box" style="min-height: 406px">
		<div class="padding">
			<span class="float-right text-muted">
				<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
					<i class="fa fa-sync text-sm" :class="{'fa-spin': busy}"></i>
				</a>
			</span>
			<h6 class="pb-2">{{$t('Dashboard.assignments')}}</h6>

			<!-- Teams -->
			<h2 v-if="teams" class="text-muted text-center mt-5">
				<span v-tippy="{theme: 'popover', html:`#team-list`, placement: 'left-end'}">
					<i class="far fa-users"></i>
					{{teams.length}} {{teams.length == 1 ? this.$i18n.t('Dashboard.team') : this.$i18n.t('Dashboard.teams')}}
				</span>
			</h2>
			<div v-if="teams" class="d-none p-0" :id="`team-list`">
				<span class="p-2 float-right text-very-muted">
					<i class="fas fa-users faw"></i>
					{{teams.length}}
				</span>
				<div class="p-2 b-b">{{$t('Dashboard.teams')}}</div>
				<div class="p-2">
					<li v-for="row in teams" :key="row.id">{{row.name}}</li>
				</div>
			</div>

			<!-- Sections -->
			<h2 v-if="sections" class="text-muted text-center mt-5">
				<span v-tippy="{theme: 'popover', html:`#section-list`, placement: 'left-end'}">
					<i class="far fa-briefcase"></i>
					{{sections.length}} {{sections.length == 1 ? this.$i18n.t('Dashboard.section') : this.$i18n.t('Dashboard.sections')}}
				</span>
			</h2>
			<div v-if="sections" class="d-none p-0" :id="`section-list`">
				<span class="p-2 float-right text-very-muted">
					<i class="fas fa-briefcase faw"></i>
					{{sections.length}}
				</span>
				<div class="p-2 b-b">{{$t('Dashboard.sections')}}</div>
				<div class="p-2">
					<li v-for="row in sections" :key="row.id">{{row.name}}</li>
				</div>
			</div>

			<!-- Items -->
			<h2 v-if="items" class="text-muted text-center mt-5">
				<span v-tippy="{theme: 'popover', html:`#item-list`, placement: 'left-end'}">
					<i class="far fa-book"></i>
					{{items.length}} {{items.length == 1 ? this.$i18n.t('Dashboard.item') : this.$i18n.t('Dashboard.items')}}
				</span>
			</h2>
			<div v-if="items" class="d-none p-0" :id="`item-list`">
				<span class="p-2 float-right text-very-muted">
					<i class="fas fa-book faw"></i>
					{{items.length}}
				</span>
				<div class="p-2 b-b">{{$t('Dashboard.items')}}</div>
				<div class="p-2">
					<li v-for="row in items" :key="row.id">{{row.name}}</li>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
a.btn-subtle {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a.btn-subtle:hover {
	opacity: 1;
}
</style>

<script>
import ConfigService from "@/services/ConfigService";

export default {
	name: "DashboardAssignments",

	data() {
		return {
			teams: null,
			sections: null,
			items: null,

			busy: false
		};
	},
	created() {
		this.loadData();
	},
	watch: {},
	methods: {
		loadData() {
			this.busy = true;
			Promise.all([
				ConfigService.listUserTeams(),
				ConfigService.listUserSections()
			])
				.then(resps => {
					//resps[0] - listUserTeams
					this.teams = resps[0].data;

					//resps[1] - listUserSections
					this.sections = resps[1].data;

					let haveItems = {};
					this.items = [];
					_.each(this.sections, s => {
						_.each(s.items, i => {
							if (!haveItems[i.id]) {
								this.items.push(i);
								haveItems[i.id] = true;
							}
						});
					});
					this.busy = false;
				})
				.catch(err => {
					console.error(
						"Failed to load dashboard data for assignments"
					);
					console.error(err);
					this.busy = false;
				})
		}
	}
};
</script>
