<!-- Template for a new page -->
<template>
	<div class="d-flex flex-column flex h100">
		<template v-if="uiState.meeting && uiState.meeting.name">
			<template v-if="uiState.engineReady && !uiState.inSession && !uiState.leftMeeting">
				<precall
					:uiState="uiState"
					:devices="devices"
					:meetingUsers="meetingUsers"
					:localParticipant="localParticipant"
					:error="error"
				/>
			</template>
			<template v-else-if="uiState.inSession && !uiState.leftMeeting">
				<video-call
					:uiState="uiState"
					:devices="devices"
					:participants="participants"
					:localParticipant="localParticipant"
					:meetingUsers="meetingUsers"
				/>
			</template>
			<template v-else-if="uiState.leftMeeting">
				<postcall :uiState="uiState" :meeting="uiState.meeting" :localParticipant="localParticipant"> </postcall>
			</template>
		</template>
		<template v-else>
			<loading type="page" />
		</template>
		<audio ref="audio" />
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<script>
//UI Components
import Precall from "@/vues/VideoMeeting/components/Precall";
import VideoCall from "@/vues/VideoMeeting/components/VideoCall";
import Postcall from "@/vues/VideoMeeting/components/Postcall";

//Libraries
import _ from "lodash";

//Services
import VideoMeetingService from "@/services/VideoMeetingService";
import Chime from "@/vues/VideoMeeting/services/ChimeEngine";
import Signal from "@/vues/VideoMeeting/services/SignalEngine";
import Notie from "@/services/NotieService";
import Store from "@/services/Store";

// Global CSS
import "@/vues/VideoMeeting/VideoMeeting.css";

export default {
	name: "VideoMeeting",
	props: ["user", "params", "query"],
	components: { Precall, VideoCall, Postcall },
	data() {
		return {
			Chime: Chime,

			meetingID: this.params.id,

			password: "",
			username: "",

			meetingUsers: [],

			uiState: {
				meeting: null,
				permissions: {
					microphone: {
						allowed: false,
						blocked: false,
						changing: false,
					},
					camera: {
						allowed: false,
						blocked: false,
						changing: false,
					},
					voiceFocus: false,
					backgroundBlur: false,
				},
				inSession: false,
				joiningSession: false,
				leftMeeting: false,
				leftMeetingMessage: "",
				noRejoin: false,
				engineReady: false,
				sessionReady: false,
				socketReady: false,
				speaking: [],
				voiceFocus: false,
				changingVoiceFocus: false,
				backgroundBlur: false,
				changingBackgroundBlur: false,
				layout: Store.getDefault(this, "VideoMeeting.layout", "gallery"),
				sidebarTab: Store.getDefault(this, "VideoMeeting.sidebarTab", ""),
				authed: false,
				password: "",
				username: "",
				chatScrolledUp: false,
				unreadMessages: 0,
			},

			devices: {
				audioInputs: [],
				audioInputId: "",

				audioOutputs: [],
				audioOutputId: "",

				videoInputs: [],
				videoInputId: "",
			},

			error: "",

			participants: [],
			localParticipant: {
				userId: null,
				attendeeId: null,
				name: null,
				initials: null,
				video: null,
				videoLoading: false,
				tileID: null,
				screensharing: false,
				muted: false,
				volumeLevel: null,
				volumeBuffer: [],
				speaking: null,
				handUp: false,
				local: false,
				isContent: false,
				role: null,
				capabilities: {
					audio: "SendReceive",
					video: "SendReceive",
					content: "SendReceive",
					chat: "SendReceive",
				},
			},
		};
	},
	async created() {
		if (this.params.password) {
			this.uiState.password = this.params.password;
			this.uiState.username = "";
		}

		await Chime.init(this, this.meetingID);
		Chime.registerAudioOutputElement(this.$refs.audio);
	},

	beforeDestroy() {
		this.debug("beforeDestroy");
		if (!this.uiState.inSession) {
			Chime.destroy();
			Signal.disconnect();
		}
	},

	watch: {
		"uiState.layout"() {
			Store.set(this, "VideoMeeting.layout", this.uiState.layout);
		},
		"uiState.sidebarTab"() {
			Store.set(this, "VideoMeeting.sidebarTab", this.uiState.sidebarTab);
		},
	},
	methods: {},
};
</script>
