<!-- Template for a new page -->
<template>
	<div class="d-flex flex-column justify-content-start flex h-100">
		<div class="top-bar theme d-flex flex-row align-items-center justify-content-center px-3">
			<div v-if="uiState.meeting && uiState.meeting.name" class="meeting-title">
				<span class="text-muted">{{ $t("VideoMeeting.joining") }}</span> {{ uiState.meeting.name }}
			</div>
			<div v-else class="meeting-title">{{ $t("VideoMeeting.loading") }} <loading type="icon" /></div>
		</div>
		<div
			class="mt-4 d-flex flex-row justify-content-center align-items-start w-100 px-5 py-3 scroll-y"
			style="overflow: hidden"
		>
			<template v-if="error">
				<div class="meeting-panel border" style="width: 400px">
					<div class="meeting-panel-header theme-lighten-3 text-white">{{ $t("VideoMeeting.error") }}</div>
					<div class="meeting-panel-content">
						<div>{{ $t("VideoMeeting.unexpected_error") }}</div>
						<div class="text-danger _600 mt-2"><i class="far fa-exclamation-triangle mr-2" />{{ error }}</div>
						<hr />
						<div>
							{{ $t("VideoMeeting.error_advice") }}
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="d-flex flex-column scroll-y" style="width: 532px; max-height: 100%">
					<div class="meeting-panel preview-container border">
						<div class="meeting-panel-header theme-lighten-3 text-white">
							{{ $t("VideoMeeting.device_preview") }}
						</div>
						<div class="meeting-panel-content pb-0">
							<div class="video-feed-container mt-1" style="height: 270px">
								<video-feed :uiState="uiState" :participant="localParticipant" class="video-feed" />
							</div>
							<control-bar
								class="my-4"
								:disabledControls="{ raise: true, share: true, hangUp: true }"
								:uiState="uiState"
								:localParticipant="localParticipant"
							/>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column scroll-y" style="width: 400px; max-height: 100%">
					<div v-if="uiState.password" class="meeting-panel settings-container border mb-3">
						<div class="meeting-panel-header theme-lighten-3 text-white">User</div>
						<div class="meeting-panel-content">
							<label>{{ $t("VideoMeeting.display_name") }}</label>
							<input
								type="text"
								class="form-control"
								:readonly="!uiState.password"
								v-model="localParticipant.name"
								placeholder="Enter your name..."
								:class="{ 'is-invalid': !localParticipant.name || nameIsTaken(localParticipant.name) }"
							/>
							<small v-if="!localParticipant.name" class="text-danger"> Please choose a name </small>
							<small v-if="nameIsTaken(localParticipant.name)" class="text-danger"> That name is taken </small>
						</div>
					</div>

					<div class="meeting-panel settings-container border">
						<div class="meeting-panel-header theme-lighten-3 text-white">
							{{ $t("VideoMeeting.device_settings") }}
						</div>
						<div class="meeting-panel-content scroll-y">
							<device-settings :uiState="uiState" :devices="devices" />
						</div>
					</div>
				</div>
			</template>
		</div>
		<div v-if="!error" class="bottom-bar my-4 d-flex flex-row justify-content-center align-items-center">
			<button
				v-if="!uiState.joiningSession"
				class="btn btn-large px-4 btn-primary"
				:class="{ 'btn-secondary': !canJoin }"
				:disabled="!canJoin"
				@click="joinMeeting"
			>
				{{ $t("VideoMeeting.join_meeting") }}
			</button>
			<button v-else class="btn btn-large px-4 btn-primary" disabled>
				{{ $t("VideoMeeting.joining_in_progress") }} <loading type="icon" class="ml-1" />
			</button>
		</div>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-bar {
	min-height: 3.5rem;
	width: 100%;
}
.meeting-title {
	font-size: 20px;
	text-align: center;
}
.bottom-bar {
	width: 100%;
}
.btn-large {
	font-size: 24px;
	font-weight: 300;
}
</style>

<script>
//UI Components
import VideoFeed from "@/vues/VideoMeeting/components/VideoFeed";
import ControlBar from "@/vues/VideoMeeting/components/ControlBar";
import DeviceSettings from "@/vues/VideoMeeting/components/DeviceSettings";

//Libraries
import _ from "lodash";

//Services
import Chime from "@/vues/VideoMeeting/services/ChimeEngine";
import Store from "@/services/Store";
import fs from "@/services/FormatService";

export default {
	name: "Precall",
	props: ["meeting", "uiState", "devices", "meetingUsers", "localParticipant", "error"],
	components: { VideoFeed, ControlBar, DeviceSettings },
	data() {
		return {
			initCheck: null,
		};
	},

	created() {
		Chime.maybeStartPrecallVideo();
		Chime.maybeStartPrecallAudio();
	},

	destroyed() {},

	watch: {
		"localParticipant.name"() {
			this.localParticipant.initials = fs.initials(this.localParticipant);
		},
	},

	computed: {
		canJoin() {
			return (
				this.localParticipant &&
				this.localParticipant.initials &&
				(!this.uiState.password || !this.nameIsTaken(this.localParticipant.name))
			);
		},
	},

	methods: {
		async joinMeeting() {
			if (this.uiState.password) {
				this.uiState.username = this.localParticipant.name;
				Store.set(this, "VideoMeeting.anonUsername", this.uiState.username);
			}
			await Chime.createSession();
			Chime.startSession();
		},

		nameIsTaken(name) {
			for (let meetingUser of this.meetingUsers) {
				if (meetingUser.full_name == name && meetingUser.role != "pseudo-participant") {
					return true;
				}
			}
			return false;
		},
	},
};
</script>
