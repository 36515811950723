<template>
	<page customNavBar>
		<template>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="site.loaded == 0" />
				<div v-if="site.loaded != 0" class="navbar-text nav-title flex" id="pageTitle">{{ site.name }}</div>
			</div>
		</template>
		<!-- Page content goes here -->
		<div class="h-100" v-if="site.loaded != 0">
			<div class="d-sm-flex b-t h-100">
				<div class="w w-auto-xs light bg b-r">
					<div class="py-3">
						<div class="nav-active-border left b-primary">
							<ul class="nav flex-column nav-sm">
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 1, show: tab == 1 }"
										data-toggle="tab"
										data-target="#tab-1"
									>
										{{ $t("SiteConfig.site_details") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('details') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 2, show: tab == 2 }"
										data-toggle="tab"
										data-target="#tab-2"
										>{{ $t("SiteConfig.users") }}</a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<edit-pane :cancel="loadData" :save="saveSite" :dirty="dirty" :valid="valid">
					<div class="tab-content h-100">
						<!-- Section Details -->
						<site-edit-details
							id="tab-1"
							:site="site"
							:valid="valid"
							:defaultTab="tab == 1"
						></site-edit-details>

						<!-- Item Setup -->
						<site-edit-users
							id="tab-2"
							:site="site"
							:dirty="dirty"
							:valid="valid"
							:save="saveSite"
							:defaultTab="tab == 2"
						></site-edit-users>
					</div>
				</edit-pane>

				<save-optional-modal
					:dirty="dirty"
					:valid="valid"
					:save="saveSite"
					:next="saveOptNext"
					:cancel="saveOptCancel"
					objectText="Site"
					:objectName="site.name"
					actionText="leave the page"
					v-model="saveOptModal"
				/>
			</div>
		</div>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}
</style>

<script>
import SiteEditDetails from "@/components/site/SiteEditDetails";
import SiteEditUsers from "@/components/site/SiteEditUsers";
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import ObservationService from "@/services/ObservationService";
import ValidationService from "@/services/ValidationService";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import Utils from "@/services/Utils";

export default {
	name: "SiteEdit",

	props: ["user", "params", "query"],

	components: {
		"site-edit-details": SiteEditDetails,
		"site-edit-users": SiteEditUsers,
		"edit-pane": EditPane,
		"save-optional-modal": SaveOptionalModal,
	},

	data() {
		return {
			fs: fs,
			tab: 1,
			site: {
				users: [],
				loaded: 0,
			},
			uploadingItem: { name: "" },
			dirty: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	computed: {},

	created() {
		this.loadData();
		this.initValidation();

		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: "You must assign a name",
					func: () => {
						return this.site && this.site.name && this.site.name != "";
					},
				},
				seats: {
					group: "details",
					errorMsg: "Seats must be greater than 0",
					func: () => {
						return this.site && this.site.seats > 0;
					},
				},
			});
		},

		blankSite() {
			return {
				users: [],
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"site",
				(newc, old) => {
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;
			var loaded = this.site.loaded;
			loaded++;

			if (this.params.id != "new") {
				return ObservationService.getSite(this.params.id)
					.then((resp) => {
						this.site = resp.data;
						this.site.loaded = loaded;
						this.site.inUse = true;
						this.watchChanges();
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.load_site_error"), err);
					});
			} else {
				this.site = this.blankSite();
				this.site.loaded = loaded;
				this.$set(this.site, "name", "New Site");
				this.$set(this.site, "ref_id", "new_site");
				this.watchChanges();
			}
		},

		showValidationHints() {
			this.validationHints = true;
		},

		getValidationErrors() {
			let errors = [];
			_.each(this.validators, (va) => {
				if (!va.func()) {
					errors.push(va.errorMsg);
				}
			});
			return errors;
		},

		saveSite() {
			return ObservationService.saveSite(this.site)
				.then((resp) => {
					notie.info("Site Saved");
					if (this.site.id != resp.data.id) {
						this.$router.replace(`/sites/${resp.data.id}`);
					}
					this.loadData();
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to save site", err);
				});
		},

		canSave() {
			return this.dirty;
		},
	},
};
</script>
