<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("ItemSetup.title") }}</div>

				<!-- Show inactive -->
				<div class="checkbox mr-3">
					<label class="md-check">
						<input v-model="showInactive" type="checkbox" />
						<i class="theme-accent"></i>
						{{ $t("ItemSetup.show_inactive") }}
					</label>
				</div>

				<!-- Create new -->
				<div v-if="canEditItems" class="py-0 btn-group">
					<button
						@click="goToNewItem"
						class="btn btn-sm theme-accent btn-block"
						v-b-tooltip
						:title="$t('tooltip.new_item')"
					>
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("ItemSetup.new_item") }}</span>
					</button>

					<!-- <template v-if="user.client.previewer"> -->
					<template
						v-if="
							user.client.previewer &&
							user.role.page_config_items &&
							user.role.page_config_sections &&
							user.role.page_config_rubrics
						"
					>
						<button
							type="button"
							class="btn btn-sm theme-accent dropdown-toggle dropdown-toggle-split"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							style="
								border-top-right-radius: 0.25rem !important;
								border-bottom-right-radius: 0.25rem !important;
							"
						></button>

						<div class="dropdown-menu dropdown-menu-right">
							<a @click="openPreviewerImportModal" class="dropdown-item">{{
								$t("ItemSetup.import_from_previewer")
							}}</a>
						</div>
					</template>
				</div>

				<!-- Search field -->
				<div>
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('ItemSetup.search_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>

		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="table_fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Name -->
					<template #cell(name)="data">
						<div class="constrained-cell" v-tippy="{ interactive: true }" :title="data.item.name">
							{{ data.item.name }}
						</div>
					</template>

					<template #cell(sections)="{ item: { sections } }">
						<span
							v-if="sections && sections.length > 0"
							v-tippy
							:title="fs.listNames(sections, $i18n.t('tooltip.sections'))"
						>
							<i class="fas fa-book faw"></i>
							{{ (sections && sections.length) || 0 }}
						</span>
						<span v-if="!(sections && sections.length > 0)" class="text-muted">{{
							$t("data_description.none")
						}}</span>
					</template>

					<!-- Item count -->
					<template #cell(items)="data">{{ data.item.items.length }}</template>

					<!-- Rubric name -->
					<template #cell(rubric)="data">
						<span v-tippy :title="data.item.rubric.name">{{ data.item.rubric.name }}</span>
					</template>

					<!-- Scoring type -->
					<template #cell(type)="data">{{ fs.itemTypeName(data.item.type) }}</template>

					<!-- Active -->
					<template #cell(active)="data">
						<div class="mx-auto w40">
							<label class="ui-switch ui-switch-md theme-accent">
								<input @change="setActive(data.item)" type="checkbox" v-model="data.item.active" />
								<i></i>
							</label>
						</div>
					</template>

					<!-- Actions -->
					<template #cell(actions)="data">
						<nobr>
							<div class="mx-auto w90">
								<a
									:href="`#/items/${data.item.id}`"
									class="btn btn-sm btn-icon btn-rounded theme-accent m-0"
									v-tippy
									:title="$t('tooltip.edit')"
								>
									<i class="far fa-edit"></i>
								</a>
								<button
									@click="copyingItem = data.item"
									class="btn btn-sm btn-icon btn-rounded btn-primary m-0"
									v-tippy
									:title="$t('tooltip.copy')"
								>
									<i class="far fa-copy"></i>
								</button>
								<button
									v-if="!data.item.inProgress"
									@click="deleting = data.item"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									v-tippy
									:title="$t('tooltip.delete')"
								>
									<i class="far fa-trash"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Removing..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>

		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-header">
				<h5>{{ $t("ItemSetup.delete_item_modal.title") }}</h5>
				<button type="button" aria-label="Close" class="close" @click="deleting = false">×</button>
			</template>
			<div class="row p-4">
				{{ $t("ItemSetup.delete_item_modal.confirm_delete") }}&nbsp;
				<strong class="text-theme">{{ deleting.name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">
					{{ $t("ItemSetup.delete_item_modal.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="removeItem(deleting)">
					{{ $t("ItemSetup.delete_item_modal.delete") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="copyItemModal" :visible="copyingItem != false" v-if="copyingItem" :key="copyItemModalKey">
			<template slot="modal-header">
				<h5>Copy Item</h5>
				<button type="button" aria-label="Close" class="close" @click="resetCopyItemModal()">×</button>
			</template>

			<b-container class="">
				<b-row class="p-4 b-b mb-4">
					Configure the following parameters prior to copying&nbsp;
					<strong class="text-theme">{{ copyingItem.name }}</strong>
				</b-row>
				<b-row class="mb-2 px-4">
					<input cols="2" class="mr-2" v-model="nItemsToCopy" type="number" min="1" />
					<i cols="3" class="theme"></i> Number of copies
				</b-row>
				<b-row class="px-4 mb-2">
					<div class="checkbox">
						<label class="md-check">
							<input v-model="enableIntegrations" type="checkbox" />
							<i class="theme"></i> Enable Integrations
						</label>
					</div>
				</b-row>
			</b-container>

			<template slot="modal-footer">
				<button class="btn danger btn-flat" @click="resetCopyItemModal()">
					{{ $t("ItemSetup.copy_item_modal.cancel") }}
				</button>
				<button v-if="canCopyItem" class="btn success btn-flat" @click="copyItem(copyingItem)">
					{{ $t("ItemSetup.copy_item_modal.copy") }}
				</button>
				<button v-else class="btn btn-flat" disabled="true" @click="copyItem(copyingItem)">
					{{ $t("ItemSetup.copy_item_modal.copy") }}
				</button>
			</template>
		</b-modal>

		<previewer-item-import-modal
			:client="user.client"
			:visible="showPreviewerImportModal"
			@hide="showPreviewerImportModal = false"
			@itemsAdded="refreshTable"
		></previewer-item-import-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}
.w90 {
	width: 90px;
}
.constrained-cell {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>

<script>
//UI Components

//Libraries

//Services
import ItemService from "@/services/ItemService";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

import PreviewerItemImportModal from "@/components/PreviewerItemImportModal";

import { v4 as uuidv4 } from "uuid";

export default {
	name: "ItemSetup",

	props: ["user"],

	components: { PreviewerItemImportModal },

	data() {
		return {
			fs: fs,
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			active: false,
			sortBy: null,
			sortDesc: false,
			showInactive: true,
			loading: true,
			deleting: false,

			// Copy Item Modal
			copyingItem: false,
			enableIntegrations: false,
			nItemsToCopy: 1,
			copyItemModalKey: uuidv4(),

			showPreviewerImportModal: false,
		};
	},

	created() {
		this.sortBy = store.get(this, "items.sortBy");
		this.sortDesc = store.getDefault(this, "items.sortDesc", false);
		this.showInactive = store.getDefault(this, "items.showInactive", true);

		if (store.get(this, "openPreviewerImportModal")) {
			store.set(this, "openPreviewerImportModal", null);
			this.openPreviewerImportModal();
		}
	},

	computed: {
		table_fields() {
			const { canEditItems, $i18n } = this;
			const fields = [
				{
					key: "name",
					label: $i18n.t("fields.name"),
					sortable: true,
				},
				{
					key: "ref_id",
					label: $i18n.t("fields.ref_id"),
					sortable: true,
				},
				{
					key: "sections",
					label: $i18n.t("fields.sections"),
				},
				{
					key: "rubric",
					label: $i18n.t("fields.rubric"),
				},
				{
					key: "type",
					label: $i18n.t("fields.resolution_rule"),
				},
				{
					key: "active",
					label: $i18n.t("fields.active"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid switch-fix",
					sortable: true,
				},
			];

			if (canEditItems) {
				return fields.concat({
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				});
			}

			return fields;
		},

		canEditItems() {
			const { user } = this;
			return user && user.role && user.role.page_config_items && !user.role.item_active_toggle;
		},
		canCopyItem() {
			if (this.nItemsToCopy > 0) {
				return true;
			} else {
				return false;
			}
		},
	},

	watch: {
		sortBy() {
			store.set(this, "items.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "items.sortDesc", this.sortDesc);
		},
		showInactive() {
			store.set(this, "items.showInactive", this.showInactive);
			this.refreshTable();
		},
	},

	methods: {
		resetCopyItemModal() {
			this.enableIntegrations = false;
			this.copyingItem = false;
			this.nItemsToCopy = 1;
			this.copyItemModalKey = uuidv4();
		},

		provider(ctx) {
			ctx.active = !this.showInactive;
			return ItemService.listItems(ctx)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.loading = false;
					return resp.data.items;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_items_fail"), err);
					this.loading = false;
					return [];
				});
		},

		setActive(item) {
			var activeState = (item.active && "active") || "inactive";
			ItemService.saveItem(item)
				.then((resp) => {
					this.$i18n.t("notie.item_set_to", { name: item.name, activeState: activeState });
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.save_item_fail"), err);
				});
		},

		removeItem(item) {
			item.inProgress = true;
			ItemService.removeItem(item.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.item_deleted"));
					this.refreshTable();
					item.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.delete_item_fail"), err);
					item.inProgress = false;
					this.$forceUpdate();
				});

			this.deleting = false;
		},

		copyItem(item) {
			const vm = this;
			item.inProgress = true;
			if (this.nItemsToCopy <= 0) {
				notie.error("Copying is only valid if the number of copies requested is 1 or greater");
				item.inProgress = false;
				this.$forceUpdate();
			}

			const enableIntegrations = this.enableIntegrations;
			const nItemsToCopy = this.nItemsToCopy;
			ItemService.copyItem(item.id, enableIntegrations, nItemsToCopy)
				.then((resp) => {
					if (enableIntegrations) {
						notie.info(
							vm.$i18n.t("ItemSetup.copy_item_modal.item_copied", {
								num: nItemsToCopy,
								s: nItemsToCopy > 1 ? "s" : "",
							}),
							vm.$i18n.t("ItemSetup.copy_item_modal.integrations_enabled", {
								s: nItemsToCopy > 1 ? "s" : "",
							})
						);
					} else {
						notie.info(
							vm.$i18n.t("ItemSetup.copy_item_modal.item_copied", {
								num: nItemsToCopy,
								s: nItemsToCopy > 1 ? "s" : "",
							}),
							vm.$i18n.t("ItemSetup.copy_item_modal.integrations_disabled", {
								s: nItemsToCopy > 1 ? "s" : "",
							})
						);
					}
					this.refreshTable();
					item.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(vm.$i18n.t("notie.copy_item_fail"), err);
					item.inProgress = false;
					this.$forceUpdate();
				});
			this.copyingItem = false;
			this.resetCopyItemModal();
		},

		refreshTable() {
			this.$refs.table.refresh();
		},

		goToNewItem() {
			this.$router.push("/items/new");
		},

		openPreviewerImportModal() {
			this.showPreviewerImportModal = true;
		},
	},
};
</script>
