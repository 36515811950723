import axios from "axios";
import _ from "lodash";

/**
 * ConfigService
 *
 * Service used for obtaining lists of sections, items, etc for filling dropdowns.
 *
 *
 */

export default {
	//Admin lists (everything on tenant)
	listProjects(ctx) {
		return axios.get("/v1/admin/projects/detailed", { params: ctx });
	},

	listMyProjects() {
		return axios.get("/v1/admin/my_projects/detailed");
	},

	listProjectsShallow(ctx) {
		return axios.get("/v1/admin/projects/shallow", { params: ctx });
	},

	// List the full project/section/item hierarchy, but with shallow objects
	listProjectsShallowPreloaded(ctx) {
		return new Promise((resolve, reject) => {
			axios
				.get("/v1/admin/projects+sections/shallow", { params: ctx })
				.then(resp => {
					let projects = resp.data.projects

					let sectionMap = {}
					_.each(resp.data.sections, section => {
						if (!section.project_id) return
						if (!sectionMap[section.project_id]) sectionMap[section.project_id] = []

						sectionMap[section.project_id].push(section)
					})

					_.each(projects, project => {
						project.sections = sectionMap[project.id]
					})

					resolve({ data: projects });
				})
				.catch(err => {
					reject(err);
				})
		});
	},

	listMyProjectsShallow(ctx) {
		return axios.get("/v1/admin/my_projects/shallow", { params: ctx });
	},

	listProjectSections(projectID) {
		return axios.get(`/v1/admin/project/${projectID}/sections`)
	},

	listProjectSectionsWithItems(projectID) {
		return axios.get(`/v1/admin/project/${projectID}/sections?items=true`)
	},

	listTeams(ctx) {
		return axios.get("/v1/admin/teams");
	},

	listSections(ctx) {
		return axios.get("/v1/admin/sections", { params: ctx });
	},

	listSectionsNoPreload(ctx) {
		return axios.get("/v1/admin/sections/noPreload", { params: ctx });
	},

	//List sections but with only the info needed to list them in a dropdown
	listSectionsShallow(ctx) {
		return axios.get("/v1/shallow/sections", { params: ctx });
	},

	getSection(id) {
		return axios.get(`/v1/admin/section/${id}/items`);
	},

	getItem(id) {
		return axios.get(`/v1/item/${id}`)
	},

	//User lists (only what the user is assigned to)
	listUserTeams() {
		return axios.get("/v1/teams");
	},

	listUserSections() {
		return axios.get("/v1/sections");
	},

	listUserProjects(active) {
		let ctx = {}
		if (active == false || active == true) {
			ctx.active = active
		}
		return axios.get("/v1/projects", { params: ctx })
	}
};
