<template>
	<div class="spread-container" ref="container">
		<div v-if="mediaLoaded == null" class="grid-center padding">
			<loading type="large" class="grid-overlap" style="color: rgba(70, 90, 110, 0.85); line-height: 0">
			</loading>
			<i class="fas fa-file-excel fa-lg text-extra-muted grid-overlap" v-tippy title="Loading file..." />
		</div>
		<div v-if="mediaLoaded == false" class="grid-center padding">
			<div class="text-center">
				<i class="fas fa-file-code fa-2x text-extra-muted" />
				<div class="text-extra-muted font-initial">File failed to load</div>
			</div>
		</div>

		<div v-if="mediaLoaded == true" class="d-flex flex-row w-100">
			<div class="spread-formula-header">
				<i class="spread-formula-icon fas fa-lg fa-function" />
				<input type="text" readonly class="spread-formula-box" :id="`formulaTextBox-${_uid}`" />
			</div>
			<button
				v-show="edited"
				v-b-popover
				:id="`spreadsheet-edited-${page.id}`"
				class="btn btn-danger edited-button"
			>
				{{ $t("Viewer.edited") }}<i class="fas fa-info-circle ml-1" />
			</button>
			<b-popover
				:show.sync="showEditPopover"
				:target="`spreadsheet-edited-${page.id}`"
				triggers="hover focus"
				placement="bottomleft"
			>
				<template slot="title">{{ $t("Viewer.spreadsheet_edited") }}</template>
				<div>
					<p>{{ $t("Viewer.this_spreadsheet_has_been_edited") }}</p>
					<p>{{ $t("Viewer.spreadsheet_changes_not_saved") }}</p>
					<div class="d-flex flex-row justify-content-center mb-2">
						<button class="btn btn-danger" @click="revert">{{ $t("Viewer.revert") }}</button>
					</div>
				</div>
			</b-popover>
			<button v-b-popover :id="`spreadsheet-version-${page.id}`" class="btn btn-light version-button">
				<i class="fas fa-info-circle" />
			</button>
			<b-popover
				:show.sync="showEditPopover"
				:target="`spreadsheet-version-${page.id}`"
				triggers="hover focus"
				placement="bottomleft"
			>
				<div>
					<h4>{{ $t("Viewer.spreadjs") }} 17.0.10</h4>
					<h6 class="mb-0">
						{{ $t("Viewer.released") }} {{ new Date(Date.UTC(2024, 5, 31, 6, 0, 0)).toLocaleDateString() }}
					</h6>
					<template v-if="local">
						<p class="mt-3">Click here to compare this file in other versions of SpreadJS:</p>
						<div class="d-flex flex-row justify-content-center mb-2">
							<button class="btn btn-danger" @click="spreadjsCompare">{{ $t("Viewer.compare") }}</button>
						</div>
					</template>
				</div>
			</b-popover>
		</div>
		<gc-spread-sheets
			v-if="keyLoaded && mediaLoaded != false"
			ref="spread"
			hostClass="spread-host"
			:allowUserEditFormula="true"
			@workbookInitialized="init"
			:tabStripVisible="false"
		>
		</gc-spread-sheets>
	</div>
</template>

<script>
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import "@grapecity/spread-sheets-vue";
import * as GC from "@grapecity/spread-sheets";
import store from "../../services/Store";
import SpreadJSKey from "@/services/SpreadJSKey";

export default {
	name: "ExcelMedia",
	props: {
		page: true,
		chan: true,
		resizeContainer: true,
		xPadding: { type: Number, default: 0 },
		yPadding: { type: Number, default: 0 },
	},
	data() {
		return {
			events: [],
			keyLoaded: false,
			spread: null,
			resizeContainerEl: null,
			height: null,
			width: null,
			sizes: [0.5, 0.65, 0.8, 1, 1.2, 1.5, 1.8, 2],
			zoomScale: 0,
			fbx: null,
			local: location.hostname == "localhost",

			edited: false,
			showEditPopover: false,
		};
	},

	computed: {
		mediaLoaded() {
			if (!this.page) {
				return null;
			}
			if (this.page.failedToLoad) {
				return false;
			}
			return true;
		},
	},

	watch: {},

	created() {
		if (this.chan) {
			this.chan.$emit("increment-media-to-load");
			if (this.page && this.page.failedToLoad) {
				this.chan.$emit("increment-media-failed");
			} else if (this.page) {
				this.chan.$emit("increment-media-loaded");
			}
		}

		//Authenticate the SpreadJS library
		SpreadJSKey.getKey().then((licenseKey) => {
			console.log("SpreadJS license key:", licenseKey);
			this.keyLoaded = true;
			GC.Spread.Sheets.LicenseKey = licenseKey;
		});

		window.addEventListener("resize", this.sizeToContainer);
		let viewport = document.getElementById(this.resizeContainer);
		console.log("viewport", viewport);
		if (viewport) {
			var ro = new ResizeObserver(this.sizeToContainer);
			ro.observe(viewport);
		}

		if (!this.chan) return;

		var zoom = () => {
			this.doZoom();
		};
		this.events.push({ key: "zoom-in", val: zoom });
		this.events.push({ key: "zoom-out", val: zoom });
		this.chan.$on("zoom-in", zoom);
		this.chan.$on("zoom-out", zoom);
	},

	mounted() {
		this.sizeToContainer();
	},

	destroyed() {
		window.removeEventListener("resize", this.sizeToContainer);

		if (!this.chan) {
			return;
		}

		_.each(this.events, (e) => {
			this.chan.$off(e.key, e.val);
		});
	},

	methods: {
		init(spread) {
			console.log("INIT EXCEL MEDIA");
			this.spread = spread;

			//Load the provided spreadsheet data into our spread object
			this.loadSpreadData(spread);

			//Prevent editing or adding new workbooks
			spread.options.newTabVisible = false;
			//spread.options.tabEditable = false; -- doesn't work

			// if (!this.unprotected) {
			// 	console.log("DO PROTECTION");
			// 	//Ensure each sheet in write-protected
			// 	spread.suspendPaint();
			// 	spread.suspendEvent();
			// 	for (let i = 0; i < spread.getSheetCount(); i++) {
			// 		let sheet = spread.getSheet(i);

			// 		sheet.options.isProtected = true;
			// 		sheet.options.protectionOptions.allowDeleteColumns = false;
			// 		sheet.options.protectionOptions.allowDeleteRows = false;
			// 		sheet.options.protectionOptions.allowDragInsertColumns = false;
			// 		sheet.options.protectionOptions.allowDragInsertRows = false;
			// 		sheet.options.protectionOptions.allowEditObjects = true;
			// 		sheet.options.protectionOptions.allowFilter = false;
			// 		sheet.options.protectionOptions.allowInsertColumns = false;
			// 		sheet.options.protectionOptions.allowInsertRows = false;
			// 		sheet.options.protectionOptions.allowResizeColumns = true;
			// 		sheet.options.protectionOptions.allowResizeRows = true;
			// 		sheet.options.protectionOptions.allowSelectLockedCells = true;
			// 		sheet.options.protectionOptions.allowSelectUnlockedCells = true;
			// 		sheet.options.protectionOptions.allowSort = false;
			// 		sheet.options.protectionOptions.formatCells = true;
			// 		sheet.options.protectionOptions.formatColumns = false;
			// 		sheet.options.protectionOptions.formatRows = false;
			// 		sheet.options.protectionOptions.scenarios = false;

			// 		// Lock all cells
			// 		// Although spreadsheets that have no locked cells defined will become all locked by default,
			// 		// spreadsheets with locked cells defined will be initially rendered with non-locked cells having no edit protection
			// 		// Therefore, we must lock all cells in order to get edit protection on everything
			// 		let allCells = sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount());
			// 		let result = allCells.locked(true);

			// 		//sheet.options.tabEditable = false; - doesn't work
			// 	}
			// 	spread.resumeEvent();
			// 	spread.resumePaint();
			// }

			//Bind our formula input to the spreadsheet
			var fbx = new GC.Spread.Sheets.FormulaTextBox.FormulaTextBox(
				document.getElementById(`formulaTextBox-${this._uid}`)
			);
			fbx.workbook(spread);
			this.fbx = fbx;
		},

		loadSpreadData(spread) {
			if (this.page.failedToLoad) {
				return;
			}

			if (this.page.type == "excel") {
				spread.fromJSON(this.page.json);
			} else if (this.page.type == "csv") {
				spread.getSheet(0).setCsv(0, 0, this.page.csvString, "\r", ",", "none");
			}

			spread.suspendPaint();
			spread.suspendEvent();
			for (let i = 0; i < spread.getSheetCount(); i++) {
				let sheet = spread.getSheet(i);
				spread.options.allowUserEditFormula = true;

				// Detect any changes to the spreadsheet
				let triggerEdited = () => {
					this.edited = true;
					// We could just refresh the spreadsheet immediately on any attempted change, but doing so also
					// reverts the scroll position of the spreadsheet to its starting position, which isn't
					// an ideal user experience, so we're not doing that
					// this.loadSpreadData(spread);
				};
				sheet.bind(GC.Spread.Sheets.Events.CellChanged, triggerEdited);
				sheet.bind(GC.Spread.Sheets.Events.DragFillBlock, triggerEdited);
				sheet.bind(GC.Spread.Sheets.Events.ClipboardPasting, triggerEdited);
				sheet.bind(GC.Spread.Sheets.Events.UserFormulaEntered, triggerEdited);
				sheet.bind(GC.Spread.Sheets.Events.RangeChanged, triggerEdited);
			}
			spread.resumeEvent();
			spread.resumePaint();

			this.doZoom();
			this.sizeToContainer();
		},

		sizeToContainer() {
			let container = this.$refs.container;
			let spreadEl = this.$refs.spread;
			if (spreadEl) spreadEl = spreadEl.$el;
			let viewport = document.getElementById(this.resizeContainer);
			console.log("viewport", viewport, "spreadEl", spreadEl);

			if (spreadEl && viewport) {
				let newHeight = viewport.offsetHeight - this.yPadding;
				let newWidth = viewport.offsetWidth - this.xPadding;
				console.log("newHeight", viewport.offsetHeight, this.yPadding);
				console.log("newWidth", viewport.offsetWidth, this.xPadding);
				let changedSize = this.height != newHeight || this.width != newWidth;
				if (this.spread && changedSize) {
					this.height = newHeight;
					this.width = newWidth;
					spreadEl.style.height = this.height + "px";
					spreadEl.style.width = this.width + "px";
					this.spread.refresh();
				}
			}
		},

		doZoom() {
			var zoom = store.get(this, "viewerScale");
			this.zoomScale = this.sizes[zoom] || this.sizes[4];

			for (let i = 0; i < this.spread.getSheetCount(); i++) {
				let sheet = this.spread.getSheet(i);
				sheet.zoom(this.zoomScale);
			}
		},

		revert() {
			this.showEditPopover = false;
			this.loadSpreadData(this.spread);
			setTimeout(() => {
				this.edited = false;
			}, 200);
		},

		spreadjsCompare() {
			const link = document.createElement("a");
			link.href = "/static/docs/spreadjs_compare_direct.html?url=" + encodeURIComponent(this.page.url);
			link.setAttribute("target", "_blank");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	},
};
</script>

<style scoped>
.spread-container {
	margin-top: 20px;
	position: relative;
	margin-left: 20px;
	margin-right: 20px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
}
.spread-host {
	width: 100%;
	height: 600px;
}

.spread-formula-header {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	padding-bottom: 1px;
	background-color: rgb(230, 230, 230);
}

.spread-formula-icon {
	padding-left: 8px;
	padding-right: 7px;
}

.spread-formula-box {
	flex-grow: 1;
	-moz-box-shadow: inset 0 0 1px #000000;
	-webkit-box-shadow: inset 0 0 1px #000000;
	box-shadow: inset 0 0 1px #000000;
}

.edited-button {
	height: 29px;
	line-height: 0;
	border-radius: 0;
	border-bottom: 2px solid rgb(118, 118, 118);
	border-top: 2px solid rgb(118, 118, 118);
	border-right: 2px solid rgb(118, 118, 118);
	z-index: 2;
}

.version-button {
	height: 29px;
	line-height: 0;
	border-radius: 0;
	border-bottom: 2px solid rgb(118, 118, 118);
	border-top: 2px solid rgb(118, 118, 118);
	border-right: 2px solid rgb(118, 118, 118);
	border-left: 2px solid rgb(118, 118, 118);
	z-index: 2;
	margin-left: -2px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-left: 3px;
	padding-right: 3px;
	width: 29px;
}
</style>
