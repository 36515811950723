<template>
	<div v-if="rubric" class="rubric" :class="{ disabled: disabled }">
		<!-- Metadata display -->

		<template v-if="showMetadataKeys.length > 0">
			<div class="card box mt-2 mb-3">
				<div class="text-center py-1 _600 b-b">Info</div>
				<div class="px-2 py-2">
					<div v-for="(mk, i) in showMetadataKeys" :key="i" :class="{ 'mt-2': i != 0 }">
						<span class="text-muted"> {{ mk.display_name }}: </span>
						<div v-if="meta[mk.key]">
							{{ meta[mk.key] }}
						</div>
						<div v-else class="text-muted">({{ $t("data_description.none") }})</div>
					</div>
				</div>
			</div>
		</template>

		<!-- Check list -->
		<template v-if="rubric.checklist">
			<div v-if="rubric.checklist_items.length > 0" class="mt-2 pb-1">
				{{ $t("RubricEdit.checklist") }}
			</div>

			<template v-for="(ci, i) in rubric.checklist_items">
				<div :key="i">
					<template v-if="ci.annotation">
						<div
							:id="`checkdd-${i}`"
							:key="`checkdd-${i}`"
							class="mt-2"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							<label class="md-check" style="flex-basis: 20%">
								<input type="checkbox" :checked="checked(ci.id)" @click="toggleCheck(ci.id)" />
								<i class="theme-accent"></i>
								<span class="mr-2">{{ ci.name }}</span>
							</label>
						</div>
						<div :key="`anno-${i}`" class="card mt-1 px-2 py-1">
							<span v-if="!getAR(ci.id)" class="text-extra-muted">No annotation specified</span>
							<div v-if="getAR(ci.id)" class="d-flex flex-row align-items-center">
								<div
									class="annotation-number"
									:class="{ [getHighlightColorClass(getAR(ci.id).annotation.color)]: true }"
								>
									{{ getAR(ci.id).sequence }}
								</div>
								<div class="annotation-preview flex mr-1">{{ getAnnotationText(getAR(ci.id)) }}</div>
							</div>
						</div>
						<div
							:id="`menu-${i}`"
							:key="`menu-${i}`"
							class="dropdown-menu dropdown-menu-left"
							:aria-labelledby="`checkdd-${i}`"
						>
							<template v-if="annoRegions && annoRegions.length > 0">
								<a
									v-for="ar in annoRegions"
									:key="ar.id"
									class="dropdown-item d-flex flex-row px-2"
									style="max-width: 215px"
									:class="{
										active:
											ar.timestamp ==
											(score.checklist[ci.id] && score.checklist[ci.id].annotation_id),
									}"
									@click="selectCheckAnno(ci.id, ar)"
								>
									<div
										class="annotation-number"
										:class="{ [getHighlightColorClass(ar.annotation.color)]: true }"
									>
										{{ ar.sequence }}
									</div>
									<div class="annotation-preview flex mr-1">{{ getAnnotationText(ar) }}</div>
								</a>
							</template>
							<template v-else>
								<div class="text-extra-muted text-center">No annotations</div>
							</template>
						</div>
					</template>
					<template v-if="!ci.annotation">
						<div :key="`check-${i}`" class="mt-2">
							<label class="md-check" style="flex-basis: 20%">
								<input type="checkbox" :checked="checked(ci.id)" @click="toggleCheck(ci.id)" />
								<i class="theme-accent"></i>
								<span class="mr-2">{{ ci.name }}</span>
							</label>
						</div>
					</template>
				</div>
			</template>
			<hr />
		</template>
		<!-- Meta Traits -->
		<template v-for="trait in rubric.meta_traits">
			<div :key="trait.id" class="mt-2">
				<span>
					{{ trait.name }}
					<span class="text-danger" v-if="trait.required">*</span>
				</span>
				<div v-if="trait.meta_trait_type == 1">
					<input
						@input="calculateScore()"
						type="text"
						v-model="trait.value"
						class="form-control"
						maxlength="4096"
					/>
				</div>
				<div v-if="trait.meta_trait_type == 2">
					<textarea
						@input="calculateScore()"
						v-model="trait.value"
						class="form-control meta-text-area"
						maxlength="4096"
					></textarea>
				</div>
				<div v-if="trait.meta_trait_type == 3">
					<select @change="calculateScore()" v-model="trait.value" class="form-control">
						<option value disabled>Select</option>
						<option v-for="o in trait.select_options" :key="o" :value="o">{{ o }}</option>
					</select>
				</div>
			</div>
		</template>
		<template v-for="(trait, i) in pagedTraits">
			<div :key="trait.id" class="mt-2 pb-1 _600 b-b" v-if="trait.separator">
				{{ trait.name }}
			</div>
			<div
				:key="trait.id"
				class="trait mt-2"
				v-if="!(trait.separator || trait.is_parent)"
				v-show="!trait.hidden"
				:tabindex="i + 1"
				:data-trait-id="trait.id"
			>
				<!-- Numeric Trait -->
				<template v-if="trait.style == 1">
					<span>{{ trait.name }}</span>
					<slider
						:min="trait.min"
						:max="trait.max"
						:step="trait.step"
						:spds="trait.score_point_descriptors"
						:traitID="trait.id"
						v-model="trait.score"
						@input="selectSlider(trait, trait.score)"
						:class="{ 'mb-2': rubric.collect_evidence }"
					/>
				</template>
				<template v-else>
					<span>{{ trait.name }}</span>
					<div
						class="trait-row"
						:class="{ disabled: disabled }"
						:key="`score-points-${i}`"
						v-for="(traitRow, i) in traitRows(trait.condition_codes_only ? [] : trait.score_points)"
					>
						<template v-for="sp in traitRow">
							<button
								:id="scoreButtonID(trait, sp)"
								@click="select(trait, sp)"
								class="score-point btn waves-effect waves-light"
								:class="{ active: isSelected(sp), disabled: spDisabled(trait, sp) }"
								:key="scoreButtonID(trait, sp)"
								tabindex="-1"
								:disabled="spDisabled(trait, sp)"
							>
								{{ sp.val }}
								<div
									v-if="sp.freq != undefined"
									class="text-xs"
									:class="{
										[fdNotHighest(trait, sp) ? '_400' : '_600']: true,
										'text-extra-muted': sp.freq == 0,
										'bg-secondary': sp.val == 1,
									}"
								>
									{{ sp.freq }}%
								</div>
								<i
									v-if="sp.res_mark"
									class="fas res-mark"
									:class="sp.res_mark.icon"
									v-tippy
									:title="sp.res_mark.title"
								/>
							</button>
							<b-popover
								v-if="hasDescriptor(trait, sp)"
								:target="scoreButtonID(trait, sp)"
								:key="`tt-${sp.val}`"
								placement="bottomright"
								:title="`${trait.name} - ${sp.val}`"
								:delay="{ show: 750, hide: 0 }"
								triggers="mouse hover"
							>
								<div v-html="getDescriptor(trait, sp)" class="mb-2"></div>
							</b-popover>
						</template>
					</div>
					<div
						v-for="(spRow, i) in traitRows(trait.sp_condition_codes)"
						:key="`score-point-condition-codes-${i}`"
						class="trait-row"
						:class="{ disabled: disabled }"
					>
						<template v-for="sp in spRow">
							<button
								:disabled="ccDisabled(sp)"
								@click="select(trait, sp)"
								:class="{ active: isSelected(sp), disabled: ccDisabled(sp) }"
								:key="scoreButtonID(trait, sp)"
								class="score-point btn waves-effect waves-light"
								tabindex="-1"
							>
								<div
									class="h-100"
									style="display: flex; justify-content: center; align-items: center"
									:id="scoreButtonID(trait, sp)"
								>
									{{ sp.val }}
								</div>
								<i
									v-if="sp.res_mark"
									class="fas res-mark"
									:class="sp.res_mark.icon"
									v-tippy
									:title="sp.res_mark.title"
								/>
							</button>
							<b-popover
								v-if="hasCondDescriptor(trait, sp)"
								:target="scoreButtonID(trait, sp)"
								:key="`tt-${sp.val}`"
								placement="bottomright"
								:title="`${trait.name} - ${sp.val}`"
								:delay="{ show: 750, hide: 0 }"
								triggers="mouse hover"
							>
								<div v-html="getCondDescriptor(trait, sp)" class="mb-2"></div>
							</b-popover>
							<b-popover
								v-if="ccDisabled(sp)"
								:target="scoreButtonID(trait, sp)"
								:key="`tt-${sp.val}`"
								placement="bottomright"
								:title="`${trait.name} - ${sp.val}`"
								:delay="{ show: 750, hide: 0 }"
								triggers="mouse hover"
							>
								{{ ccDisabled(sp) }}
							</b-popover>
						</template>
					</div>
				</template>

				<!-- Collect Evidence -->
				<input
					v-if="rubric.collect_evidence && !rubric.large_evidence"
					@input="calculateScore()"
					:placeholder="$t('RubricSetup.evidence_placeholder')"
					maxlength="1024"
					type="text"
					v-model="trait.evidence2"
					class="form-control"
				/>
				<textarea
					v-if="rubric.collect_evidence && rubric.large_evidence"
					@input="calculateScore()"
					:placeholder="$t('RubricSetup.evidence_placeholder')"
					maxlength="1024"
					rows="4"
					v-model="trait.evidence2"
					class="form-control"
				/>

				<!-- ScorePoint code stuff -->
				<div v-if="trait.score_point_codes.length" class="mt-2">
					<v-select
						class="bg-white"
						:disabled="!trait.active_score_point_codes.length"
						:searchable="false"
						multiple
						label="text"
						@input="spCodeChanged(trait)"
						v-model="trait.selected_score_point_codes"
						:options="trait.active_score_point_codes"
						v-tippy
						:class="traitCommentCodeError(trait) ? 'border border-danger text-danger' : ''"
						:title="traitCommentCodeError(trait)"
						:key="`${traitCommentCodeError(trait)}-${trait.selected_score_point_codes}`"
						deselectable
					>
						<template slot="option" slot-scope="option">
							<div class="d-center">{{ option.code }}: {{ option.text }}</div>
						</template>
						<template slot="selected-option" slot-scope="option">
							<span v-if="option">{{ option.code }}</span>
						</template>
					</v-select>
				</div>
			</div>
		</template>

		<div v-if="chooseRes">
			<hr />
			<span>{{ $t("RubricSetup.choose_score_to_keep") }}</span>
			<div class="trait-row">
				<button
					@click="selectResChoice(1)"
					:class="{ active: resChoice == 1 }"
					class="wide score-point btn waves-effect waves-light"
				>
					{{ $t("buttons.first") }}
				</button>
				<button
					@click="selectResChoice(2)"
					:class="{ active: resChoice == 2 }"
					class="wide score-point btn waves-effect waves-light"
				>
					{{ $t("buttons.second") }}
				</button>
				<button
					@click="selectResChoice(3)"
					:class="{ active: resChoice == 3 }"
					class="wide score-point btn waves-effect waves-light"
				>
					{{ $t("buttons.double_score") }}
				</button>
			</div>
		</div>
	</div>
</template>

<style>
/* .highlight-btn {
	width: 23px;
	height: 23px;
	border: 1px solid #111;
	border-radius: 12px;
	margin: 5px 8px;
	display: inline-block;
	cursor: pointer;
}

.highlight-btn.selected {
	box-shadow: #666 0 0 4px 2px;
} */

.highlight-blue {
	background-color: rgb(41, 182, 246);
}

.highlight-red {
	background-color: rgba(244, 67, 54, 0.75);
}

.highlight-yellow {
	background-color: rgb(248, 240, 100);
}

.highlight-green {
	background-color: rgb(139, 195, 74);
}

.highlight-orange {
	background-color: rgb(246, 154, 77);
}

.highlight-purple {
	background-color: rgb(143, 100, 150);
}

.highlight-pink {
	background-color: rgba(217, 102, 196);
}

.annotation-number {
	font-family: sans-serif;
	font-size: 14px;
	height: 20px;
	min-width: 20px;
	border-radius: 50%;
	margin-right: 6px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-weight: 800;
	color: white;
	line-height: 1.65;
}

.v-select .dropdown-menu {
	overflow: hidden;
}

.v-select li > a {
	white-space: normal;
}

.meta-text-area {
	height: 90px;
}

.trait-row > button.wide {
	flex-basis: 33%;
	white-space: normal;
}

.res-mark {
	position: absolute;
	top: 3px;
	right: 4px;
	font-size: 0.8rem;
	opacity: 0.5;
}

.annotation-preview {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

button.btn-subtle {
	opacity: 1;
	transition: background-color 0.25s;
	min-width: 22px;
	width: 22px;
	height: 22px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
	padding: 0;
}

button.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.anno-row {
	border-radius: 12px;
}

.pickable {
	background-color: #eeeeee;
	transition: background-color 0.15s ease-in-out;
}

.pickable:hover {
	background-color: #dddddd;
}

.continuation-number {
	cursor: pointer;
	transition: filter 0.15s ease-in-out;
}

.continuation-number:hover {
	filter: brightness(80%);
}

.annotation-number {
	font-family: sans-serif;
	font-size: 14px;
	height: 20px;
	min-width: 20px;
	border-radius: 50%;
	margin-right: 6px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-weight: 800;
	color: white;
	line-height: 1.65;
}

.annotation-preview {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.trait:focus {
	box-shadow: 0 0 5px rgb(81 203 238);
}
</style>

<script>
import _ from "lodash";
import Slider from "@/components/Slider";
import { CONDITION_CODE_ACTIONS, FINAL_SCORE_RULES, TRAIT_RESOLUTION_TYPES } from "@/services/Constants.js";

const { SYMBOL, FLAG, VALUE, VALUE_LINKED, SYMBOL_LINKED, SYMBOL_AND_VALUE, SYMBOL_AND_VALUE_LINKED } =
	CONDITION_CODE_ACTIONS;

export default {
	name: "Rubric",
	components: { Slider },
	props: {
		outerRubric: true,
		item: true,
		chooseRes: true,
		reset: true,
		initScore: true,
		resFirstScore: true,
		resSecondScore: true,
		resScore: false,
		brScore: false,
		finalScore: false,
		showScores: {
			type: Boolean,
			default: true,
		},
		fd: true,
		noPages: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		chan: true,
		metadataKeys: true,
		meta: true,
		user: true,
		mode: false,
		externalFinalScore: null,
		filterStrictBlanks: false,
	},
	data() {
		return {
			rubric: this.outerRubric,
			current_desc: null,
			trait_descriptors: {},
			trait_cond_descriptors: {},
			score: {},
			resChoice: 0,
			complete: false,

			currentPage: 0,
			numPages: 1,

			pageNav: {
				canGoPrev: false,
				canGoNext: false,
				prev: () => {},
				next: () => {},
				complete: false,
				pageRefID: null,
				name: null,
			},

			annoRegions: null,
			picking: null,
		};
	},

	computed: {
		pagedTraits() {
			if (!this.rubric) {
				return [];
			}

			if (this.item && this.item.trait_resolution_adjacency && this.item.trait_resolution_adjacency.length > 0) {
				if (this.mode === "RESOLUTION") {
					let pagedTraits = [];
					_.each(this.rubric.traits, (t) => {
						if (this.checkTraitAdjacency(t)) {
							pagedTraits.push(t);
						}
					});
					return pagedTraits;
				} else if (this.mode === "ADJUDICATION") {
					return this.rubric.traits.filter((trait) => this.wasScoredInRes(trait));
				}
			}

			if (this.numPages <= 1) {
				return this.rubric.traits;
			}

			let i = -1;
			let pagedTraits = [];
			_.each(this.rubric.traits, (t) => {
				if (t.separator) {
					i++;
				}
				if (i == this.currentPage) {
					pagedTraits.push(t);
				}
			});
			return pagedTraits;
		},

		pageTrait() {
			if (this.numPages <= 1) {
				return {};
			}

			let i = -1;
			let pageTrait = {};
			_.each(this.rubric.traits, (t) => {
				if (t.separator) {
					i++;
					if (i == this.currentPage) {
						pageTrait = t;
					}
				}
			});

			return pageTrait;
		},

		appealTraits() {
			const { AVERAGE, WEIGHTED_SUM, DOUBLE } = FINAL_SCORE_RULES;
			const { item, rubric } = this;
			if (!rubric) return [];

			const { traits } = rubric;
			if (!(item && traits)) return traits;

			const { final_score_type } = item.api_export_config;

			switch (final_score_type) {
				case WEIGHTED_SUM.id:
					return traits.map((trait) => ({
						...trait,
						max: trait.max * this.getTraitWeight(trait) * 2,
					}));
				case DOUBLE.id:
					return traits.map((trait) => ({
						...trait,
						max: trait.max * this.getTraitWeight(trait) * 2,
					}));
				case AVERAGE.id:
				case "":
					return traits.map((trait) => ({
						...trait,
						step: trait.step / 2,
					}));
			}

			return traits;
		},

		showMetadataKeys() {
			let smk = [];
			if (!(this.metadataKeys && this.meta && this.user)) {
				return smk;
			}

			_.each(this.metadataKeys, (mk) => {
				console.log(
					"check mk",
					mk,
					!mk.admin_only || this.user.role.admin_metadata,
					mk.show_blank || this.meta[mk.key],
					(!mk.admin_only || this.user.role.admin_metadata) && (mk.show_blank || this.meta[mk.key])
				);
				if ((!mk.admin_only || this.user.role.admin_metadata) && (mk.show_blank || this.meta[mk.key])) {
					smk.push(mk);
				}
			});

			return smk;
		},
	},

	watch: {
		outerRubric: {
			handler: function (newVal) {
				if (newVal === undefined) {
					this.rubric = undefined;
					return;
				}
				this.rubric = _.cloneDeep(newVal);
				this.setup();
			},
			deep: true,
			immediate: true,
		},
		//User of the rubric can modify this value to a random string, we'll watch it and reset everything if it changes
		reset() {
			this.setup();
		},
	},

	created() {
		this.$emit("update:score", this.initScore);
		this.$emit("update:keypressHandler", this.keypressHandler);

		if (!this.chan) return;
		this.chan.$on("anno-regions", this.setAnnoRegions);
	},

	destroyed() {
		if (!this.chan) return;
		this.chan.$off("anno-regions", this.setAnnoRegions);
	},

	methods: {
		setAnnoRegions(annoRegions) {
			// Remove checked items that no longer exist, in case of deletions
			let anyDeleted = false;
			console.log("setAnnoRegions", this.score, this.score.checklist);
			if (this.score && this.score.checklist) {
				_.each(this.score.checklist, (c) => {
					console.log("checklist", c);
					if (c.annotation_id) {
						let ar = _.find(annoRegions, { timestamp: c.annotation_id });
						console.log("find", annoRegions, ar);
						if (!ar) {
							c.checked = false;
							c.annotation_id = null;
							anyDeleted = true;
						}
					}
				});
			}

			console.log("SET ANNO REGIONS", annoRegions);
			this.annoRegions = annoRegions;

			if (anyDeleted) {
				this.calculateScore();
				this.$forceUpdate();
			}
		},

		setup() {
			console.log(this.mode);
			if (this.finalScore || this.mode == "APPEAL") {
				this.rubric.traits = this.appealTraits;
			}
			this.rubric.traits = _.sortBy(this.rubric.traits, "sequence");

			this.resChoice = 0;

			this.numPages = 0;
			if (!this.noPages) {
				_.each(this.rubric.traits, (t) => {
					if (t.separator) {
						this.numPages++;
					}
				});
			}
			if (this.numPages <= 1) {
				this.$emit("update:pageNav", null);
			} else {
				this.pageNav.canGoPrev = false;
				this.pageNav.canGoNext = true;
				this.pageNav.prev = this.prevPage;
				this.pageNav.next = this.nextPage;
				this.pageNav.complete = false;
				this.pageNav.pageRefID = this.pageTrait.page_ref_id;
				this.pageNav.name = this.pageTrait.name;
				this.$emit("update:pageNav", this.pageNav);
			}

			_.each(this.rubric.meta_traits, this.setupMetaTrait);
			_.each(this.rubric.traits, this.setupTrait);
			if (!this.score.checklist) {
				this.$set(this.score, "checklist", {});
			}
			_.each(this.rubric.checklist_items, (ci) => {
				if (!this.score.checklist[ci.id]) {
					this.score.checklist[ci.id] = {
						checked: false,
						annotation_id: "",
					};
				}
			});

			if (this.initScore) {
				this.fillScore(this.initScore);
			}
			if (this.resFirstScore && this.resSecondScore && this.showScores) {
				let resAgreed = this.markResScores(this.resFirstScore, this.resSecondScore);
				if (this.resScore) {
					resAgreed = this.markAdjudicationScores(this.resScore);
				}
				if (!this.initScore && !this.finalScore) {
					this.fillScore(resAgreed);
				}
			}

			this.calculateScore();
		},

		scoreButtonID(trait, sp) {
			return `trait-${trait.id}-sp-${sp.val}-ccid-${sp.id}`;
		},

		prevPage() {
			this.currentPage--;
			if (this.currentPage < 0) {
				this.currentPage = 0;
			}
			if (this.currentPage <= 0) {
				this.pageNav.canGoPrev = false;
			} else {
				this.pageNav.canGoPrev = true;
			}
			if (this.currentPage >= this.numPages - 1) {
				this.pageNav.canGoNext = false;
			} else {
				this.pageNav.canGoNext = true;
			}
			this.pageNav.pageRefID = this.pageTrait.page_ref_id;
			this.isComplete();
		},

		nextPage() {
			this.currentPage++;
			if (this.currentPage > this.numPages - 1) {
				this.currentPage = this.numPages - 1;
			}
			if (this.currentPage <= 0) {
				this.pageNav.canGoPrev = false;
			} else {
				this.pageNav.canGoPrev = true;
			}
			if (this.currentPage >= this.numPages - 1) {
				this.pageNav.canGoNext = false;
			} else {
				this.pageNav.canGoNext = true;
			}
			this.pageNav.pageRefID = this.pageTrait.page_ref_id;
			this.isComplete();
		},

		markResScores(f, s) {
			let agreementScore = { trait_scores: [] };
			_.each(this.rubric.traits, (trait) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				let fsp = _.find(f.trait_scores, { trait_id: trait.id });
				let ssp = _.find(s.trait_scores, { trait_id: trait.id });
				let fs = fsp.condition == "" ? fsp.score : fsp.condition;
				let ss = ssp.condition == "" ? ssp.score : ssp.condition;

				if (fs == ss) {
					agreementScore.trait_scores.push(fsp);
				}

				_.each(trait.score_points, (sp) => {
					console.log("check res", sp, fs, ss);
					if (sp.val == fs) {
						sp.res_mark = { value: 1, icon: "fa-check", title: this.$t("tooltip.first_scorers_choice") };
					}
					if (sp.val == ss) {
						if (sp.res_mark) {
							sp.res_mark = {
								value: 3,
								icon: "fa-check-double",
								title: this.$t("tooltip.both_scorers_choice"),
							};
						} else {
							sp.res_mark = {
								value: 2,
								icon: "fa-check",
								title: this.$t("tooltip.second_scorers_choice"),
							};
						}
					}
				});
			});

			return agreementScore;
		},

		markAdjudicationScores(resScore) {
			const { resFirstScore, resSecondScore } = this;

			const nonResScores = {};
			const agreementScore = { trait_scores: [] };

			resFirstScore.trait_scores.forEach((ts) => {
				const { trait_id, score, condition } = ts;
				const sp = this.conditionCodeSelected(ts) ? condition : score;
				nonResScores[trait_id] = [sp];
			});

			resSecondScore.trait_scores.forEach((ts) => {
				const { trait_id, score, condition } = ts;
				const sp = this.conditionCodeSelected(ts) ? condition : score;
				nonResScores[trait_id] = [sp];
			});

			resScore.trait_scores.forEach((trait) => {
				const traitVal = this.conditionCodeSelected(trait) ? trait.condition : trait.score;
				const sp = this.rubric.traits
					.find((t) => t.id === trait.trait_id)
					.score_points.find((sp) => sp.val === traitVal);

				if (!sp) return;

				if (sp.res_mark) {
					if (sp.res_mark.value === 3) {
						sp.res_mark = { icon: "fa-check-square", title: this.$t("tooltip.all_scorers_choice") };
					} else {
						sp.res_mark.icon = "fa-check-double";
						sp.res_mark.title = `${sp.res_mark.title}<br>& ${this.$t("tooltip.resolution_scorers_choice")}`;
					}
				} else {
					sp.res_mark = { icon: "fa-check", title: this.$t("tooltip.resolution_scorers_choice") };
				}

				if (nonResScores[trait.trait_id].includes(traitVal)) {
					agreementScore.trait_scores.push(trait);
				}
			});

			return agreementScore;
		},

		fillScore(score) {
			if (!score) {
				if (this.rubric.prefill_score) {
					_.each(this.rubric.traits, (trait) => {
						if (trait.separator) return;
						if (trait.is_parent) return;

						this.select(trait, trait.score_points[0]);
					});
				}
				return;
			}

			this.resChoice = score.resolution_choice;

			_.each(score.trait_scores, (tsp) => {
				let trait = _.find(this.rubric.traits, { id: tsp.trait_id });
				if (trait) {
					trait.score = tsp.score;
					trait.evidence = tsp.evidence;
					trait.evidence2 = tsp.evidence2;
					var activeSp;

					// Handle whether or not a numerical score point is selected on the app Viewer
					_.each(trait.score_points, (sp) => {
						if ((tsp.score === sp.val || tsp.condition === sp.val) && tsp.condition_code_id === "") {
							sp.selected = true;
							activeSp = sp;
						} else {
							sp.selected = false;
						}
					});

					// Handle whether or not a condition code score point is selected on the app Viewer
					_.each(trait.sp_condition_codes, (sp) => {
						if (tsp.condition_code_id === sp.id) {
							sp.selected = true;
							activeSp = sp;
						} else {
							sp.selected = false;
						}
					});

					if (trait.score_point_codes.length && activeSp) {
						trait.active_score_point_codes = _.filter(trait.score_point_codes, (code) => {
							return code.score_point === activeSp.val || code.condition_code === activeSp.val;
						}).map((code) => {
							code.trait_id = trait.id;
							return code;
						});
						if (!trait.active_score_point_codes) {
							trait.active_score_point_codes = [];
						}
					} else {
						trait.active_score_point_codes = [];
					}

					let selectedSPCs = trait.evidence.split(":");
					trait.selected_score_point_codes = _.filter(trait.active_score_point_codes, (spc) => {
						return selectedSPCs.includes(spc.code);
					});
				}
			});

			_.each(score.meta_trait_scores, (msp) => {
				let trait = _.find(this.rubric.meta_traits, {
					id: msp.meta_trait_id,
				});
				if (trait) {
					trait.value = msp.value;
				}
			});
		},

		setupTrait(trait) {
			if (trait.separator) return;
			if (trait.is_parent) return;

			trait.evidence = "";
			trait.evidence2 = "";
			let trait_fd = _.find(this.fd, { trait_id: trait.id });
			if (trait_fd && trait_fd.total == 0) {
				trait_fd = null;
			}
			var max_freq = 0;

			//Numeric Traits
			var step = trait.step;
			var sps = [];
			var min = trait.min;
			var max = trait.max;

			var skip = trait.masked_score_points.map(function (mask) {
				return mask.mask_value;
			});

			if (this.rubric.reverse_score_points) {
				for (var i = max; i >= min; i -= step) {
					if (_.includes(skip, i)) {
						continue;
					}
					sps.push({
						val: i,
						selected: false,
					});
				}
			} else {
				for (var i = min; i <= max; i += step) {
					if (_.includes(skip, i)) {
						continue;
					}
					sps.push({
						val: i,
						selected: false,
					});
				}
			}

			//Fill fd data if we've got it
			if (trait_fd) {
				_.each(sps, (sp) => {
					let count = trait_fd.counts[sp.val];
					if (!count) {
						count = 0;
					}
					let freq = Math.round((count * 100) / trait_fd.total);
					max_freq = Math.max(max_freq, freq);
					sp.freq = freq;
				});
			}

			trait.score_points = sps;

			//Condition Codes
			var conditionCodes = [];
			trait.sp_condition_codes = [];
			_.each(trait.condition_codes, (code) => {
				if (!code.admin_only || (this.user && this.user.role.admin_flags)) {
					var sp = {
						val: code.symbol,
						score: code.score,
						selected: false,
						condition_type: code.action,
						alert_id: code.alert_id,
						id: code.id,
					};
					conditionCodes.push(sp);
				}
			});

			//Fill fd data if we've got it
			if (trait_fd) {
				_.each(conditionCodes, (sp) => {
					let count = trait_fd.counts[sp.val];
					if (!count) {
						count = 0;
					}
					let freq = Math.round((count * 100) / trait_fd.total);
					max_freq = Math.max(max_freq, freq);
					sp.freq = freq;
				});
			}

			if (trait.inline_condition_codes) {
				trait.score_points = trait.score_points.concat(conditionCodes);
			} else {
				trait.sp_condition_codes = conditionCodes;
			}
			trait.active_score_point_codes = [];

			if (max_freq != 0) {
				trait.max_freq = max_freq;
			}

			trait.selected_score_point_codes = trait.selected_score_point_codes || [];
		},

		setupMetaTrait(trait) {
			if (trait.meta_trait_type == 3) {
				trait.select_options = trait.options.split("|");
			}
			trait.value = "";
		},

		selectSlider(trait, score) {
			let sp = _.find(trait.score_points, { val: score });
			if (!sp) {
				console.error("Failed to find slider score", score);
				return;
			}

			this.select(trait, sp);
		},

		ccDisabled(sp) {
			if (this.ccDisabledDueToBlankFlag(sp)) return this.$i18n.t("tooltip.strict_blank_cc_disabled");
			return null;
		},

		ccDisabledDueToBlankFlag(sp) {
			const { item, filterStrictBlanks } = this;
			if (!(item && filterStrictBlanks)) return false;
			return (item.alerts || [])
				.filter(({ strict_blank }) => strict_blank)
				.map(({ code }) => code)
				.includes(sp.val);
		},

		select(trait, sp) {
			if (this.disabled) {
				return;
			}

			const oldCC = trait.sp_condition_codes.find((cc) => cc.selected);

			this.doSelect(trait, sp);

			_.each(this.rubric.traits, (rt) => {
				if (rt.linked_to == trait.id || rt.id == trait.linked_to) {
					let linkedSp = _.find(rt.score_points, { val: sp.val });
					if (linkedSp) {
						this.doSelect(rt, linkedSp);
					}
				}

				if (this.isLinkedAction(sp.condition_type) || (oldCC && this.isLinkedAction(oldCC.condition_type))) {
					rt.score_points
						.filter((sp) => this.isLinkedAction(sp.condition_type))
						.forEach((sp) => {
							sp.selected = false;
						});

					rt.sp_condition_codes
						.filter((cc) => this.isLinkedAction(cc.condition_type))
						.forEach((cc) => {
							cc.selected = false;
						});
				}

				if (this.isLinkedAction(sp.condition_type)) {
					let linkedSp = _.find(rt.score_points, { val: sp.val });
					if (linkedSp) {
						this.doSelect(rt, linkedSp);
					}
					linkedSp = _.find(rt.sp_condition_codes, { val: sp.val });
					if (linkedSp) {
						this.doSelect(rt, linkedSp);
					}
				}
			});

			const { selected_score_point_codes } = trait;
			const linkedSelectedSpCodes = selected_score_point_codes.filter(({ linked }) => linked);
			this.debug("linkedSelectedSpCodes", linkedSelectedSpCodes);
			if (linkedSelectedSpCodes.length == 0) {
				this.unlinkCommentCodes();
			}

			this.calculateScore();
		},

		doSelect(trait, sp) {
			console.log("doSelect", trait, sp);
			if (!sp.selected) {
				trait.evidence = "";
				trait.selected_score_point_codes = [];
			}

			_.each(trait.score_points, (sp) => {
				sp.selected = false;
			});
			_.each(trait.sp_condition_codes, (sp) => {
				sp.selected = false;
			});
			trait.complete = true;
			sp.selected = true;

			//Update the available score_point_codes
			if (trait.score_point_codes.length) {
				trait.active_score_point_codes = _.filter(trait.score_point_codes, (code) => {
					return code.score_point === sp.val || code.condition_code === sp.val;
				}).map((code) => {
					code.trait_id = trait.id;
					return code;
				});
				if (!trait.active_score_point_codes) {
					trait.active_score_point_codes = [];
				}
			} else {
				trait.active_score_point_codes = [];
			}
		},

		isLinkedAction(action) {
			return [VALUE_LINKED.id, SYMBOL_LINKED.id, SYMBOL_AND_VALUE_LINKED.id].includes(action);
		},

		isSymbolAction(action) {
			return [SYMBOL.id, SYMBOL_LINKED.id, SYMBOL_AND_VALUE.id, SYMBOL_AND_VALUE_LINKED.id].includes(action);
		},

		isValueAction(action) {
			return [VALUE.id, VALUE_LINKED.id, SYMBOL_AND_VALUE.id, SYMBOL_AND_VALUE_LINKED.id].includes(action);
		},

		isSymbolAndValueAction(action) {
			return [SYMBOL_AND_VALUE.id, SYMBOL_AND_VALUE_LINKED.id].includes(action);
		},

		selectResChoice(choice) {
			this.resChoice = choice;

			this.calculateScore();
		},

		spCodeChanged(trait) {
			this.recalculateEvidence(trait);
			this.linkCommentCodes(trait);
			this.calculateScore();
		},

		linkCommentCodes(trait) {
			this.logWarn("linkCommentCodes", trait);
			const { selected_score_point_codes, active_score_point_codes } = trait;

			const linkedSelectedSpCodes = selected_score_point_codes.filter(({ linked }) => linked);

			if (linkedSelectedSpCodes.length > 0) {
				this.debug("linkedSelectedSpCodes", linkedSelectedSpCodes);
				const selectedSP = this.selectedScorePoint(trait);
				if (selectedSP === undefined) return;

				this.rubric.traits.forEach((otherTrait) => {
					const sp = this.scorePointWithValue(otherTrait, selectedSP.val);
					if (!sp) return;
					this.doSelect(otherTrait, sp);
					this.tryAddingLinkedCommentsToTrait(otherTrait, linkedSelectedSpCodes);
				});
			} else {
				this.unlinkCommentCodes();
			}

			const unselectedLinkedSpCodes = active_score_point_codes.filter(
				(comment) =>
					comment.linked &&
					!linkedSelectedSpCodes.some((otherComment) => this.commentContentsMatch(comment, otherComment))
			);

			this.rubric.traits.forEach((otherTrait) => {
				this.tryRemovingLinkedCommentsToTrait(otherTrait, unselectedLinkedSpCodes);
			});

			this.linkedTraits(trait).forEach((linkedTrait) => {
				if (linkedTrait.link_comments || trait.link_comments) {
					linkedTrait.selected_score_point_codes = selected_score_point_codes.map((code) => ({
						...code,
						trait_id: linkedTrait.id,
					}));
				}
			});
		},

		unlinkCommentCodes() {
			this.rubric.traits.forEach((trait) => {
				const { selected_score_point_codes } = trait;
				if (!selected_score_point_codes) {
					return;
				}
				const linkedSelectedSpCodes = selected_score_point_codes.filter(({ linked }) => linked);
				if (linkedSelectedSpCodes.length > 0) {
					trait.selected_score_point_codes = [];
					this.setupTrait(trait);
				}
			});
		},

		tryAddingLinkedCommentsToTrait(trait, comments) {
			comments.forEach((comment) => {
				this.tryAddLinkedCommentToTrait(trait, comment);
				this.recalculateEvidence(trait);
			});
		},

		tryRemovingLinkedCommentsToTrait(trait, comments) {
			trait.selected_score_point_codes = trait.selected_score_point_codes.filter(
				(comment) => !comments.some((otherComment) => this.commentContentsMatch(comment, otherComment))
			);
			this.recalculateEvidence(trait);
		},

		recalculateEvidence(trait) {
			trait.evidence = trait.selected_score_point_codes.map(({ code }) => code).join(":");
		},

		tryAddLinkedCommentToTrait(trait, comment) {
			if (!this.hasComment(trait, comment)) return;
			if (!this.selectedComment(trait, comment)) {
				trait.selected_score_point_codes.push({
					...comment,
					trait_id: trait.id,
				});
			}
		},

		selectedComment(trait, comment) {
			return trait.selected_score_point_codes.some((otherComment) =>
				this.commentContentsMatch(comment, otherComment)
			);
		},

		hasComment(trait, comment) {
			return trait.score_point_codes.some((otherComment) => this.commentContentsMatch(comment, otherComment));
		},

		commentContentsMatch(commentA, commentB) {
			return (
				commentA.linked === commentB.linked &&
				commentA.score_point === commentB.score_point &&
				commentA.code === commentB.code
			);
		},

		selectedScorePoint(trait) {
			return trait.score_points.find(({ selected }) => selected);
		},

		scorePointWithValue(trait, value) {
			return trait.score_points.find(({ val }) => val === value);
		},

		linkedTraits(targetTrait) {
			return this.rubric.traits.filter(
				({ id, linked_to }) => targetTrait.id === linked_to || id === targetTrait.linked_to
			);
		},

		calculateScore() {
			this.isComplete();
			if (!this.complete) {
				this.$forceUpdate();
				this.$emit("update:score", null);
				return;
			}

			this.score = {
				response_id: this.response_id,
				qc_response_id: this.qc_response_id,
				checklist: this.score.checklist,
				trait_scores: [],
				meta_trait_scores: [],
			};

			//Numeric Traits
			_.each(this.rubric.traits, (trait) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				var tsp = {
					trait_id: trait.id,
					score: -1,
					condition: "",
					evidence: trait.evidence,
					evidence2: trait.evidence2,
					condition_code_id: "",
				};

				_.each(trait.score_points, (sp) => {
					console.log("process sp", sp, sp.selected);
					if (sp.selected) {
						if (!sp.condition_type) {
							tsp.score = sp.val;
						}
						// Score with code
						if (this.isSymbolAction(sp.condition_type)) {
							tsp.condition = sp.val;
						}
						// Add a flag
						if (sp.condition_type == FLAG.id) {
							this.score.doFlag = sp.alert_id;
						}
						// Score with value
						if (this.isValueAction(sp.condition_type)) {
							tsp.score = sp.score;
						}
					}
				});

				_.each(trait.sp_condition_codes, (sp) => {
					console.log("process cc", sp, sp.selected);
					if (sp.selected) {
						// Score with code
						if (this.isSymbolAction(sp.condition_type)) {
							tsp.condition = sp.val;
						}

						// Add a flag
						if (sp.condition_type == FLAG.id) {
							this.score.doFlag = sp.alert_id;
						}
						// Score with value
						if (this.isValueAction(sp.condition_type)) {
							tsp.score = sp.score;
						}

						// Add the id of the condition code
						tsp.condition_code_id = sp.id;
					}
				});

				if (
					this.item &&
					this.item.trait_resolution_adjacency &&
					this.item.trait_resolution_adjacency.length > 0 &&
					["RESOLUTION", "ADJUDICATION"].includes(this.mode)
				) {
					let found = false;
					_.each(this.pagedTraits, (t) => {
						// If this trait is one of the ones displayed, it can be resolved
						if (t.id == trait.id) {
							found = true;
						}

						// If this resolvable trait has a selected condition code that is a linked action...
						let linkedCondition = "";
						_.each(t.score_points, (sp) => {
							if (sp.selected && this.isLinkedAction(sp.condition_type)) {
								linkedCondition = sp.val;
							}
						});
						_.each(t.sp_condition_codes, (sp) => {
							if (sp.selected && this.isLinkedAction(sp.condition_type)) {
								linkedCondition = sp.val;
							}
						});
						if (linkedCondition) {
							_.each(trait.score_points, (sp) => {
								if (this.isLinkedAction(sp.condition_type) && sp.val == linkedCondition) {
									if (this.isSymbolAction(sp.condition_type)) {
										tsp.condition = sp.val;
									}
									if (this.isValueAction(sp.condition_type)) {
										tsp.val = sp.score;
									}

									found = true;
								}
							});
							_.each(trait.sp_condition_codes, (sp) => {
								if (this.isLinkedAction(sp.condition_type) && sp.val == linkedCondition) {
									if (this.isSymbolAction(sp.condition_type)) {
										tsp.condition = sp.val;
									}
									if (this.isValueAction(sp.condition_type)) {
										tsp.val = sp.score;
									}
									found = true;
								}
							});
						}
					});
					//trait was not scored in resolution due to not needing a score
					if (!found) {
						tsp.score = -1;
						tsp.condition = "NS";
						tsp.evidence = "";
						tsp.evidence2 = "";
						tsp.condition_code_id = "";
					}
				}

				this.score.trait_scores.push(tsp);
			});

			//Meta Traits
			_.each(this.rubric.meta_traits, (mt) => {
				var msp = {
					meta_trait_id: mt.id,
					value: mt.value,
				};
				this.score.meta_trait_scores.push(msp);
			});

			this.score.resolution_choice = this.resChoice;

			this.$forceUpdate();
			this.$nextTick(() => {
				this.$emit("update:score", this.score);
			});
		},

		isComplete() {
			var scoreComplete = true,
				metaComplete = true,
				conditionComplete = false,
				resChoiceComplete = true,
				checklistComplete = true;

			var pageScoreComplete = true,
				pageConditionComplete = false;

			//Check all traits for completeness
			_.each(this.rubric.traits, (trait) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				var selected = false;
				_.each(trait.score_points, (s) => {
					if (s.selected) {
						selected = true;
						if (s.condition_type == FLAG.id) {
							conditionComplete = true;
						}

						if (this.traitCommentCodeError(trait)) {
							scoreComplete = false;
						}

						if (["APPEAL"].includes(this.mode) && this.externalFinalScore != null) {
							_.each(this.externalFinalScore.trait_scores, (x) => {
								if (x.trait_id == trait.id && !trait.hidden) {
									if (
										s.val < x.score ||
										((s.condition_type == FLAG.id || s.condition_type == SYMBOL_LINKED.id) &&
											s.val != x.condition)
									) {
										scoreComplete = false;
									}
								}
							});
						}
					}
				});
				_.each(trait.sp_condition_codes, (s) => {
					if (s.selected) {
						selected = true;
						if (s.condition_type == FLAG.id && !["APPEAL"].includes(this.mode)) {
							conditionComplete = true;
						}

						if (this.traitCommentCodeError(trait)) {
							scoreComplete = false;
						}
						//if external trait score is zero then allow a condition code to be submitted otherwise no condition codes allowed
						//OSCAR uses -1 for condition codes and external systems use 0 for a score value
						if (["APPEAL"].includes(this.mode) && this.externalFinalScore != null) {
							_.each(this.externalFinalScore.trait_scores, (x) => {
								if (x.trait_id == trait.id && !trait.hidden) {
									if (
										(s.condition_type == FLAG.id || s.condition_type == SYMBOL_LINKED.id) &&
										x.score > 0
									) {
										scoreComplete = false;
									}
								}
							});
						}
					}
				});

				if (
					this.item &&
					this.item.type == "TRAIT_RESOLUTION" &&
					["RESOLUTION", "ADJUDICATION"].includes(this.mode)
				) {
					let found = false;
					_.each(this.pagedTraits, (t) => {
						if (t.id == trait.id) {
							found = true;
						}
					});
					if (found) {
						if (!selected) {
							console.log(trait.id, "not selected");
							scoreComplete = false;
						}
					}
				} else if (!selected) {
					console.log(trait.id, "not selected");
					scoreComplete = false;
				}

				//check if score value matches trait's externalFinalScore
			});

			//Check traits of current page only
			//I don't think the pageConditionComplete variable is actually necessary...
			_.each(this.pagedTraits, (trait) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				var selected = false;
				_.each(trait.score_points, (s) => {
					if (s.selected) {
						selected = true;
						if (s.condition_type == FLAG.id) {
							pageConditionComplete = true;
						}

						if (trait.score_point_codes && trait.score_point_codes.length && !trait.evidence) {
							let commentCodes = _.find(trait.score_point_codes, { score_point: s.val });
							if (commentCodes) {
								pageScoreComplete = false;
							}
						}
					}
				});
				_.each(trait.sp_condition_codes, (s) => {
					if (s.selected) {
						selected = true;
						if (s.condition_type == FLAG.id) {
							pageConditionComplete = true;
						}
					}
				});

				if (!selected) {
					pageScoreComplete = false;
				}
			});
			this.pageNav.complete = pageScoreComplete;

			//TODO Check meta traits
			_.each(this.rubric.meta_traits, (mt) => {
				if (mt.required && mt.value == "") {
					metaComplete = false;
				}
			});

			if (this.chooseRes) {
				if (!this.resChoice) {
					resChoiceComplete = false;
				}
			}

			// Checklist items
			_.each(this.rubric.checklist_items, (ci) => {
				if (ci.required && !(this.score.checklist[ci.id] && this.score.checklist[ci.id].checked)) {
					checklistComplete = false;
				}
			});

			//TODO update to include other complete settings
			this.complete =
				resChoiceComplete && checklistComplete && ((scoreComplete && metaComplete) || conditionComplete);
			// (scoreComplete && metaComplete && spCodeComplete) ||
			console.log("Response Complete", this.complete);
			console.log(resChoiceComplete, checklistComplete, scoreComplete, metaComplete, conditionComplete);
			this.$emit("update:complete", this.complete);
		},

		isSelected(sp) {
			return sp.selected;
		},

		getDescriptor(trait, sp) {
			if (typeof sp.val == "string") {
				return this.getCondDescriptor(trait, sp);
			}

			let obj = _.find(trait.score_point_descriptors, { val: sp.val });
			return obj && obj.desc;
		},

		hasDescriptor(trait, sp) {
			if (typeof sp.val == "string") {
				return this.hasCondDescriptor(trait, sp);
			}

			let obj = _.find(trait.score_point_descriptors, { val: sp.val });
			if (!obj) {
				return false;
			}
			return !(obj.desc == null || obj.desc == "");
		},

		getCondDescriptor(trait, sp) {
			let obj = _.find(trait.score_point_descriptors, { code: sp.val });
			return obj && obj.desc;
		},

		hasCondDescriptor(trait, sp) {
			let obj = _.find(trait.score_point_descriptors, { code: sp.val });
			if (!obj) {
				return false;
			}
			return !(obj.desc == null || obj.desc == "");
		},

		fdNotHighest(trait, sp) {
			if (!trait.max_freq || sp.freq == undefined) {
				return false;
			}

			return sp.freq < trait.max_freq;
		},

		getHighlightColorClass(color) {
			return `highlight-${color}`;
		},

		getAnnotationText(ar) {
			console.log(ar.timestamp);
			let text = "";
			for (let chunk of ar.chunks) {
				text += chunk.innerHTML;
			}
			console.log(text);

			return text;
		},

		checked(id) {
			return this.score.checklist[id] && this.score.checklist[id].checked;
		},

		toggleCheck(id) {
			console.log("toggle check", id);
			this.score.checklist[id].checked = !this.score.checklist[id].checked;
		},

		selectCheckAnno(id, ar) {
			console.log("selectCheckAnno", id, ar);
			if (this.score.checklist[id].checked && this.score.checklist[id].annotation_id == ar.timestamp) {
				this.score.checklist[id].checked = false;
				this.score.checklist[id].annotation_id = null;
			} else {
				this.score.checklist[id].checked = true;
				this.score.checklist[id].annotation_id = ar.timestamp;
			}
			this.calculateScore();
			this.$forceUpdate();
		},

		getAR(id) {
			if (!this.score.checklist[id]) return null;
			return _.find(this.annoRegions, { timestamp: this.score.checklist[id].annotation_id });
		},

		getHighlightColorClass(color) {
			return `highlight-${color}`;
		},

		getAnnotationText(ar) {
			console.log(ar.timestamp);
			let text = "";
			for (let chunk of ar.chunks) {
				text += chunk.innerHTML;
			}
			console.log(text);

			return text;
		},

		getFocusedTrait() {
			let el = document.activeElement;
			if (!el) return null;
			if (!el.classList.contains("trait")) return null;

			let traitID = el.getAttribute("data-trait-id");
			for (let trait of this.pagedTraits) {
				if (trait.id == traitID) {
					return trait;
				}
			}
			return null;
		},

		keypressHandler(e) {
			let trait = this.getFocusedTrait();
			if (!trait) return;

			if (e.keyCode == 39 || e.keyCode == 107) {
				// Right arrow or numpad +
				this.keypressTraitNext(trait);
			} else if (e.keyCode == 37 || e.keyCode == 109) {
				// Left arrow or numpad -
				this.keypressTraitPrev(trait);
			} else if (e.keyCode >= 48 && e.keyCode <= 57) {
				// Number keys
				let num = e.keyCode - 48;
				this.keypressTraitNumber(trait, num);
			} else if (e.keyCode >= 96 && e.keyCode <= 105) {
				// Numpad number keys
				let num = e.keyCode - 96;
				this.keypressTraitNumber(trait, num);
			} else if (e.keyCode >= 65 && e.keyCode <= 90) {
				// Letters
				let letter = "abcdefghijklmnopqrstuvwxyz"[e.keyCode - 65];
				this.keypressTraitLetter(trait, letter);
			}
		},

		keypressTraitNumber(trait, num) {
			for (let sp of trait.score_points) {
				if (sp.val == num) {
					this.select(trait, sp);
				}
			}
		},

		keypressTraitLetter(trait, letter) {
			let lower = letter.toLowerCase();
			let upper = letter.toUpperCase();
			for (let sp of trait.sp_condition_codes) {
				if (sp.val.startsWith(lower) || sp.val.startsWith(upper)) {
					this.select(trait, sp);
				}
			}
		},

		keypressTraitNext(trait) {
			let t = trait.score_points ? trait.score_points.findIndex((o) => o.selected) : -1;
			let c = trait.sp_condition_codes ? trait.sp_condition_codes.findIndex((o) => o.selected) : -1;

			if (t == -1 && c == -1) {
				// Nothing selected
				if (trait.score_points.length > 0) {
					this.select(trait, trait.score_points[0]);
				} else if (trait.sp_condition_codes.length > 0) {
					this.select(trait, trait.sp_condition_codes[0]);
				}
			} else if (t == trait.score_points.length - 1 && trait.sp_condition_codes.length > 0) {
				// Special case to progress from numeric SPs to condition codes
				this.select(trait, trait.sp_condition_codes[0]);
			} else if (t != -1 && t < trait.score_points.length - 1) {
				// Numeric SP is selected and it's not the last one
				this.select(trait, trait.score_points[t + 1]);
			} else if (c != -1 && c < trait.sp_condition_codes.length - 1) {
				// Condition code is selected and it's not the last one
				this.select(trait, trait.sp_condition_codes[c + 1]);
			}
		},

		keypressTraitPrev(trait) {
			let t = trait.score_points ? trait.score_points.findIndex((o) => o.selected) : -1;
			let c = trait.sp_condition_codes ? trait.sp_condition_codes.findIndex((o) => o.selected) : -1;

			if (t == -1 && c == -1) {
				// Nothing selected
				if (trait.sp_condition_codes.length > 0) {
					this.select(trait, trait.sp_condition_codes[trait.sp_condition_codes - 1]);
				} else if (trait.score_points.length > 0) {
					this.select(trait, trait.score_points[trait.score_points.length - 1]);
				}
			} else if (c == 0 && trait.score_points.length > 0) {
				// Special case to regress from condition codes to numeric SPs
				this.select(trait, trait.score_points[trait.score_points.length - 1]);
			} else if (c > 0) {
				// Condition code is selected and it's not the first one
				this.select(trait, trait.sp_condition_codes[c - 1]);
			} else if (t > 0) {
				// Numeric SP is selected and it's not the first one
				this.select(trait, trait.score_points[t - 1]);
			}
		},

		wasScoredInRes(trait) {
			const { resScore } = this;
			if (resScore) {
				const score = resScore.trait_scores.find(({ trait_id }) => trait_id === trait.id);
				if (score) {
					return score.condition !== "NS";
				}
			}
			return true;
		},

		checkTraitAdjacency(trait) {
			const { item, mode, resFirstScore, resSecondScore, brScore } = this;
			if (!(item && item.type == "TRAIT_RESOLUTION" && mode == "RESOLUTION")) {
				return true;
			}

			if (item.backread_to_resolution && brScore) {
				return (
					this.checkTraitAdjScores(trait, brScore, resFirstScore) &&
					this.checkTraitAdjScores(trait, brScore, resSecondScore)
				);
			}

			return this.checkTraitAdjScores(trait, resFirstScore, resSecondScore);
		},

		checkTraitAdjScores(trait, score1, score2) {
			const { NO_RES, EXACT_ADJ, EXACT } = TRAIT_RESOLUTION_TYPES;
			const { item } = this;

			const traitResAdj = item.trait_resolution_adjacency.find(({ trait_id }) => trait_id === trait.id);
			const firstTsScore = score1.trait_scores.find(({ trait_id }) => trait_id === trait.id);
			const secondTsScore = score2.trait_scores.find(({ trait_id }) => trait_id === trait.id);

			const firstIsCondition = this.conditionCodeSelected(firstTsScore);
			const secondIsCondition = this.conditionCodeSelected(secondTsScore);

			const bothCC = firstIsCondition && secondIsCondition;

			if (!bothCC && (firstIsCondition || secondIsCondition)) return true;

			const ccMatch = firstTsScore.condition === secondTsScore.condition;
			const diff = Math.abs(firstTsScore.score - secondTsScore.score);

			if (!traitResAdj) return false;
			switch (traitResAdj.trait_resolution_adjacent_type_id) {
				case NO_RES.id:
					return false;
				case EXACT_ADJ.id:
					if (bothCC) return !ccMatch;
					return diff > 1;
				case EXACT.id:
					if (bothCC) return !ccMatch;
					return diff > 0;
			}
			return true;
		},

		conditionCodeSelected(traitScore) {
			const { score, condition, trait_id } = traitScore;

			if (score === -1) return true;
			if (condition === "") return false;

			const trait = this.getTrait(trait_id);
			if (!trait) return false;
			const cc = trait.condition_codes.find(({ symbol }) => symbol === condition);
			if (!cc) return false;

			return this.isSymbolAndValueAction(cc.action);
		},

		getTrait(traitID) {
			if (!(this.item && this.item.rubric)) return null;
			return this.item.rubric.traits.find(({ id }) => id === traitID);
		},

		traitRows(score_points) {
			if (score_points.length === 0) return [];
			if (score_points.length < 8) return [score_points];
			const rowCount = Math.ceil(score_points.length / 8);
			const rowLen = Math.ceil(score_points.length / rowCount);
			const rows = [];
			for (let i = 0; i < score_points.length; i += rowLen) {
				rows.push(score_points.slice(i, i + rowLen));
			}
			return rows;
		},

		spDisabled(trait, sp) {
			const { finalScore } = this;
			if (!finalScore) return false;
			const finalTrait = (finalScore.trait_scores || []).find(({ trait_id }) => trait_id === trait.id);
			if (!finalTrait) return false;
			return sp.val !== -1 && finalTrait.score !== -1 && sp.val < finalTrait.score;
		},

		traitCommentCodeError(trait) {
			let sp = trait.score_points.find(({ selected }) => selected);

			if (!sp) {
				sp = trait.sp_condition_codes.find(({ selected }) => selected);
				if (!sp) return false;
			}
			const spHasCommentCodes = (trait.score_point_codes || []).some(
				({ score_point, condition_code }) => score_point === sp.val || condition_code === sp.val
			);
			if (!spHasCommentCodes) {
				return false;
			}

			let limit = trait.score_point_code_limits.find(
				({ score_point, condition_code }) => score_point === sp.val || condition_code === sp.val
			);
			if (!limit) {
				// default limit, requires at least 1
				limit = {
					min: 1,
					max: 1000,
				};
			}

			const codesSelected = trait.selected_score_point_codes.length;
			if (limit.min > codesSelected) {
				return `Must select at least ${limit.min} comment${limit.min > 1 ? "s" : ""}`;
			}
			if (limit.max < codesSelected) {
				return `You have exceeded the maximum of ${limit.max} comments`;
			}

			return false;
		},

		getTraitWeight({ weight }) {
			return weight === 0 ? 1 : weight;
		},
	},
};
</script>
