<template>
	<b-container>
		<b-row class="pl-2">
			<b-col cols="9"
				><h5 class="text-theme">
					{{ $t("fields.set_name")
					}}<arrow-select
						class="pl-3"
						@forward="setPage++"
						@back="setPage--"
						:showForward="setPage < maxSetPages - 1"
						:showBack="setPage > 0"
					/></h5
			></b-col>
			<b-col
				><h5 class="text-theme">
					{{ $t("fields.state") }}
				</h5></b-col
			>
		</b-row>

		<div class="pt-3 pb-1 pl-2 border-top" v-for="{ id, set, stat: { state } } in displayedSets" :key="id">
			<b-row>
				<b-col cols="9">
					<h5>{{ set.name }}</h5>
				</b-col>
				<b-col :class="stateData(state).textTheme">
					<h5>{{ stateData(state).name }}</h5>
				</b-col>
			</b-row>
		</div>
		<div v-if="noSets" class="text-muted pt-3 text-center h-100 d-flex container justify-content-center">
			<h5>{{ $t("Dashboard.no_sets") }}</h5>
		</div>
	</b-container>
</template>

<script>
import ArrowSelect from "@/components/ArrowSelect";

import QCService from "@/services/QCService";
import notie from "@/services/NotieService";
import { QC_STATES, idToObject } from "@/services/Constants";

const SETS_PER_PAGE = 4;

export default {
	name: "DashboardQcResultsSetView",

	components: {
		ArrowSelect,
	},

	props: {
		user: Object,
		section: Object,
		item: Object,
		qcType: Number,
	},

	data() {
		return {
			setPage: 0,
			qcData: [],
		};
	},

	created() {
		this.loadQcData();
	},

	watch: {
		qcType() {
			this.loadQcData();
		},

		item() {
			this.loadQcData();
		},
	},

	computed: {
		noSets() {
			return this.qcData.length == 0;
		},
		displayedSets() {
			const { qcData, setPage } = this;
			return qcData.slice(setPage * SETS_PER_PAGE, (setPage + 1) * SETS_PER_PAGE);
		},

		maxSetPages() {
			return Math.floor(this.qcData.length / SETS_PER_PAGE);
		},
	},

	methods: {
		async loadQcData() {
			try {
				const { qcType, section, item } = this;
				if (!(section && item)) return;
				const { data } = await QCService.getMyQcPortfolio(qcType, section.id, item.id);
				this.qcData = data;
				this.setPage = 0;
			} catch (e) {
				notie.error("Failed to get qc data", e);
			}
		},

		stateData(state) {
			return idToObject(QC_STATES, state);
		},
	},
};
</script>

<style></style>
