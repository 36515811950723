var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row",class:{ 'with-annotation-aside': _vm.showAnnotationAside }},[_c('div',{ref:"essay",staticClass:"essay",class:{ 'essay-nopad': _vm.fit_to_content || _vm.noPad }},[(_vm.page.label)?_c('span',{staticClass:"label text-theme"},[_vm._v(_vm._s(_vm.page.label))]):_vm._e(),_c('p',{staticClass:"respText"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.page.text)}})]),_vm._l((_vm.annotationRegions),function(ar){return _c('div',{key:ar.timestamp,staticClass:"annotation-margin",style:({ top: `${ar.anchorTop}px` }),attrs:{"id":`overlay-${ar.timestamp}`},on:{"mouseenter":function($event){return _vm.applyHoverToGroup(ar.timestamp)},"mouseleave":function($event){return _vm.applyUnhoverToGroup(ar.timestamp)}}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
					placement: 'top-end',
					theme: 'popover',
					interactive: true,
					reactive: true,
					hideOnClick: false,
					trigger: 'hover',
					html: `#overlay-popup-${ar.timestamp}`,
				}),expression:"{\n\t\t\t\t\tplacement: 'top-end',\n\t\t\t\t\ttheme: 'popover',\n\t\t\t\t\tinteractive: true,\n\t\t\t\t\treactive: true,\n\t\t\t\t\thideOnClick: false,\n\t\t\t\t\ttrigger: 'hover',\n\t\t\t\t\thtml: `#overlay-popup-${ar.timestamp}`,\n\t\t\t\t}"}],staticClass:"annotation-side-border",class:{ [`highlight-${ar.annotation.color}`]: true },style:({ height: `${ar.anchorHeight}px` }),attrs:{"id":`overlay-border-${ar.timestamp}`}}),_c('div',{staticClass:"overlay-popup",attrs:{"id":`overlay-popup-${ar.timestamp}`}},[(!ar.editing)?_c('span',{domProps:{"innerHTML":_vm._s(ar.annotation.text)}}):_vm._e(),(ar.editing)?_c('div',{staticClass:"d-flex flex-row"},[_c('RichText',{staticClass:"flex mb-0",attrs:{"initial":_vm.annotationInput,"text":_vm.annotationOutput,"options":{ height: 104 }},on:{"update:text":function($event){_vm.annotationOutput=$event}}}),(_vm.item.rubric && _vm.item.rubric.checklist && _vm.item.rubric.checklist_items && _vm.score)?_c('div',{staticClass:"pl-2 ml-2 border-left d-flex flex-column"},_vm._l((_vm.item.rubric.checklist_items),function(ci,i){return _c('label',{key:i,staticClass:"md-check",staticStyle:{"flex-basis":"20%"}},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.checked(ci.id)},on:{"click":function($event){return _vm.toggleCheck(ci.id)}}}),_c('i',{staticClass:"theme-accent"}),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(ci.name))])])}),0):_vm._e(),_c('div',{staticClass:"pl-2 ml-2 border-left d-flex flex-column"},[_c('div',{staticClass:"flex d-flex flex-row"},[_c('div',{staticClass:"flex d-flex flex-column"},[_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-blue",class:{
										selected: ar.annotation.color == 'blue',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'blue')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-red",class:{
										selected: ar.annotation.color == 'red',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'red')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-yellow",class:{
										selected: ar.annotation.color == 'yellow',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'yellow')}}})]),_c('div',{staticClass:"flex d-flex flex-column ml-1"},[_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-green",class:{
										selected: ar.annotation.color == 'green',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'green')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-orange",class:{
										selected: ar.annotation.color == 'orange',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'orange')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-purple",class:{
										selected: ar.annotation.color == 'purple',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'purple')}}})])]),_c('div',{staticClass:"btn btn-sm",on:{"click":function($event){return _vm.saveAnnotationRegion(ar)}}},[_vm._v("Save")])])],1):_vm._e()]),(ar.editable)?_c('div',{staticClass:"position-relative"},[_c('a',{staticClass:"btn-subtle overlay-btn position-absolute",staticStyle:{"font-size":"18px"},style:({ top: `${ar.buttonOffset}px` }),on:{"click":function($event){return _vm.toggleEditAnnotation(ar)}}},[_c('i',{staticClass:"fa-comment",class:{ [ar.editing ? 'fas' : 'far']: true }})]),_c('a',{staticClass:"btn-subtle overlay-btn position-absolute",staticStyle:{"font-size":"18px","left":"28px"},style:({ top: `${ar.buttonOffset}px` }),on:{"click":function($event){return _vm.removeAnnotationRegion(ar.timestamp)}}},[_c('i',{staticClass:"far fa-trash"})])]):_vm._e(),(!ar.editable)?_c('div',[_c('a',{staticClass:"overlay-btn position-absolute",staticStyle:{"font-size":"18px","left":"6px"},style:({ top: `${ar.buttonOffset}px` })},[_c('i',{staticClass:"far fa-comment-lines"})])]):_vm._e()])})],2),(_vm.showAnnotationAside)?_c('div',{staticClass:"annotation-aside"},_vm._l((_vm.annotationRegions),function(anno,i){return _c('div',{key:i,staticClass:"d-flex flex-row align-items-start"},[_c('div',{staticClass:"annotation-number",class:{ [_vm.getHighlightColorClass(anno.annotation.color)]: true }},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(anno.annotation.text)}})])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }