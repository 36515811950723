<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap">
				<!-- Page title -->
				<loading type="header" v-if="course.loaded == 0" />
				<div v-if="course.loaded != 0" class="navbar-text nav-title flex" id="pageTitle">
					{{ course.name }}
				</div>
			</div>
		</template>
		<div class="content-main" style="background-color: white">
			<!-- Page content goes here -->
			<div class="h-100" v-if="course.loaded != 0">
				<div class="d-sm-flex b-t h-100">
					<div class="w w-auto-xs light bg b-r">
						<div class="py-3">
							<div class="nav-active-border left b-primary">
								<ul class="nav flex-column nav-sm">
									<li class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab === 1 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-1"
											@click="tab = 1"
										>
											{{ $t("TrainingEdit.Details.title") }}
											<i
												class="valid-hint text fa fa-angle-left"
												:class="{ invalid: !valid.group('details') }"
											></i>
										</a>
									</li>
									<li class="nav-item" v-if="course.id">
										<a
											class="nav-link"
											:class="{ active: tab === 2 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-2"
											@click="tab = 2"
										>
											{{ $t("TrainingEdit.Sections.title") }}
											<i
												class="valid-hint text fa fa-angle-left"
												:class="{ invalid: !valid.group('sections') }"
											></i>
										</a>
									</li>
									<li class="nav-item" v-if="course.id">
										<a
											class="nav-link"
											:class="{ active: tab === 3 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-3"
											@click="tab = 3"
										>
											{{ $t("TrainingEdit.items") }}
											<i
												class="valid-hint text fa fa-angle-left"
												:class="{ invalid: !valid.group('items') }"
											></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<edit-pane :cancel="loadData" :save="saveTrainingCourse" :dirty="dirty" :valid="valid">
						<div class="tab-content pos-rlt">
							<!-- Course Details -->
							<training-edit-details
								id="tab-1"
								:course="course"
								:valid="valid"
								:defaultTab="tab == 1"
							></training-edit-details>

							<!-- Section Assignment -->
							<training-edit-sections
								id="tab-2"
								:course="course"
								:defaultTab="tab == 2"
								:allSections="allSections"
								v-model="assignedSections"
							></training-edit-sections>

							<!-- Item Assignment -->
							<training-edit-items
								id="tab-3"
								:course="course"
								:defaultTab="tab == 3"
								:allItems="allItems"
								v-model="assignedItems"
							></training-edit-items>
						</div>
					</edit-pane>
					<save-optional-modal
						:dirty="dirty"
						:valid="valid"
						:save="saveTrainingCourse"
						:next="saveOptNext"
						:cancel="saveOptCancel"
						objectText="Course"
						:objectName="course.name"
						actionText="leave the page"
						v-model="saveOptModal"
					/>
				</div>
			</div>
		</div>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}

.vali-hint {
	color: transparent;
	transition: color 0.3s;
	font-size: 1rem;
}

.invalid {
	color: red;
}
</style>

<script>
import TrainingEditDetails from "@/components/training/TrainingEditDetails";
import TrainingEditSections from "@/components/training/TrainingEditSections";
import TrainingEditItems from "@/components/training/TrainingEditItems";
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import TrainingService from "@/services/TrainingService";
import ValidationService from "@/services/ValidationService";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import BB from "bluebird";
import TrainingScormService from "@/services/TrainingScormService";
import SectionService from "@/services/SectionService";
import ItemService from "@/services/ItemService";
import Store from "@/services/Store";

var SCORM = 1;
var RESOURCE = 2;
var QUALIFICATION = 3;
var PRACTICE = 4;

export default {
	name: "TrainingEdit",

	props: ["user", "params", "query"],

	components: {
		TrainingEditDetails,
		TrainingEditSections,
		EditPane,
		SaveOptionalModal,
		TrainingEditItems,
	},

	data() {
		return {
			fs: fs,
			tab: 1,
			course: {
				elements: [],
				loaded: 0,
			},
			oldAssignedSections: [],
			oldAssignedItems: [],
			assignedSections: [],
			assignedItems: [],
			allSections: [],
			allItems: [],
			client: null,
			dirty: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	computed: {
		isNewCourse() {
			return this.params.id === "new";
		},
	},

	created() {
		this.loadData();
		this.initValidation();
		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {
		"course.name"() {
			if (this.autoRef) {
				let genRef = fs.toGoodRefID(this.course.name);
				if (this.course.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.course.ref_id = genRef;
				}
			}
		},
		"course.ref_id"() {
			this.checkAutoRef();
		},
		assignedSections() {
			const { assignedSections, oldAssignedSections } = this;
			if (assignedSections.length !== oldAssignedSections.length) {
				this.dirty = true;
			}
		},
		assignedItems() {
			const { assignedItems, oldAssignedItems } = this;
			if (assignedItems.length !== oldAssignedItems.length) {
				this.dirty = true;
			}
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				// Adds a small delay prior to navigating to the next page
				// in case the next page needs to fetch updated data that
				// this save is persisting to the DB. Note, this will not
				// always work, but should work in most cases where the
				// data written asynchronously to the DB is sufficiently small
				// and where the network speed is sufficiently fast. Don't
				// expect this to be effective on dial-up.
				setTimeout(() => {
					next();
				}, 150);
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: "You must assign a name",
					func: () => {
						return this.course && this.course.name && this.course.name != "";
					},
				},
				ref: {
					group: "details",
					errorMsg: "You must assign a reference ID",
					func: () => {
						return this.course && this.course.ref_id && this.course.ref_id != "";
					},
				},
				ref_char: {
					group: "details",
					errorMsg:
						"Reference IDs may not contain spaces or any of the following characters: ! # % ^ * ( )  / [ ] { } < > ? | ' \" :",
					func: () => {
						return this.course && this.course.ref_id && fs.isGoodRefID(this.course.ref_id);
					},
				},

				expires_after_days: {
					group: "details",
					errorMsg: "Days Expires After Completion Must be above 0",
					func: () => {
						const { course } = this;
						return (
							course &&
							(!course.expires ||
								course.expires_at_specific_date ||
								fs.isNaturalNumberStr(course.expires_after_days))
						);
					},
				},
			});
		},

		async getSections() {
			try {
				const {
					data: { sections },
				} = await SectionService.listSections();
				this.allSections = sections;
				this.oldAssignedSections = sections.filter(({ training_course_ids }) =>
					training_course_ids.includes(this.course.id)
				);
				this.assignedSections = [...this.oldAssignedSections];
			} catch (err) {
				Notie.error("Failed to get Sections", err);
			}
		},

		async getItems() {
			try {
				const {
					data: { items },
				} = await ItemService.listItems();
				this.allItems = items;
				this.oldAssignedItems = items.filter(({ training_course_ids }) =>
					(training_course_ids || []).includes(this.course.id)
				);
				this.assignedItems = [...this.oldAssignedItems];
			} catch (err) {
				Notie.error("Failed to get Items", err);
			}
		},

		blankCourse() {
			return {
				name: this.$i18n.t("TrainingSetup.new_course"),
				element: [],
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"course",
				(newc, old) => {
					console.log(newc, old);
					console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;
			var loaded = this.course.loaded;
			loaded++;

			if (!this.isNewCourse) {
				TrainingService.getTrainingCourse(this.params.id).then((r) => {
					this.course = r.data;
					this.course.loaded = loaded;
					if (!this.course.expires_at_specific_date) {
						this.course.expires_at = new Date();
					}

					this.getSections();
					this.getItems();

					this.watchChanges();
				});
			} else {
				this.course = this.blankCourse();
				this.course.loaded = loaded;
				this.course.name = this.$i18n.t("TrainingSetup.new_course");
				this.course.ref_id = "new_course";

				this.watchChanges();
				this.checkAutoRef();
			}
		},

		checkAutoRef() {
			let genRef = fs.toGoodRefID(this.course.name);
			this.autoRef = this.course.ref_id.toLowerCase() == genRef.toLowerCase();
		},

		async saveTrainingCourse() {
			let uploads = [];
			this.course.expires_after_days = Number.parseInt(this.course.expires_after_days);
			this.course.priority = Number.parseInt(this.course.priority);
			for (const [i, el] of this.course.elements.entries()) {
				switch (el.type) {
					case SCORM:
						if (!el.scorm) break;
						if (el.upload_scorm_file) {
							uploads.push({
								type: SCORM,
								file: el.upload_scorm_file,
								index: i,
							});
						} else if (el.fromPool) {
							uploads.push({
								type: SCORM,
								pool: el.fromPool,
								index: i,
							});
						}
						break;
					case RESOURCE:
						if (el.resource && el.upload_resource_file) {
							uploads.push({
								type: RESOURCE,
								file: el.upload_resource_file,
								index: i,
							});
						} else if (!(el.resource && el.resource.path)) {
							Notie.error("Must include Resource file", `No file added for element "${el.name}"`);
							return;
						}
				}
			}

			if (!this.isNewCourse) {
				const okToUpdate = await this.$bvModal.msgBoxConfirm(this.$i18n.t("TrainingEdit.warning_update"), {
					title: this.$i18n.t("TrainingEdit.confirm_update"),
					centered: true,
				});
				if (!okToUpdate) return;
			}
			this.saveSectionChanges();
			this.saveItemChanges();
			let errors = [];
			return BB.map(
				uploads,
				(u) => {
					console.log("in map", u);
					if (u.type == SCORM) {
						if (u.file) {
							return TrainingService.uploadScormPackage(u.file)
								.then((r) => {
									console.log("SCORM DATA", r.data);
									this.course.elements[u.index].scorm.s3_dir = r.data.path;
									this.course.elements[u.index].scorm.import_record_id = r.data.import_record_id;
								})
								.catch((e) => {
									errors.push(e);
									console.error(e);
									Notie.error("Failed to upload SCORM package", e);
								});
						} else if (u.pool) {
							const { selectedLocation, selectedPackage } = u.pool;
							if (selectedPackage.verified) {
								const { s3_dir, import_record_id } = selectedPackage;
								this.course.elements[u.index].scorm.s3_dir = s3_dir;
								this.course.elements[u.index].scorm.import_record_id = import_record_id;
							} else {
								return TrainingScormService.addExternalScormPackage(
									selectedLocation.id,
									selectedPackage.ref_id
								)
									.then(({ data: { import_record_id, path } }) => {
										this.course.elements[u.index].scorm.s3_dir = path;
										this.course.elements[u.index].scorm.import_record_id = import_record_id;
									})
									.catch((e) => {
										errors.push(e);
										console.error(e);
										Notie.error("Failed to add External SCORM package", e);
									});
							}
						}
					} else if (u.type == RESOURCE) {
						return TrainingService.uploadResourceFile(u.file)
							.then((r) => {
								console.log("RESOURCE DATA", r.data);
								this.course.elements[u.index].resource.path = r.data.path;
								this.course.elements[u.index].resource.mime_type = r.data.mimeType;
							})
							.catch((e) => {
								errors.push(e);
								console.error(e);
								Notie.error("Failed to upload resource file", e);
							});
					}
				},
				{ concurrency: 2 }
			)
				.then(() => {
					if (errors.length == 0) {
						this.doSaveTrainingCourse();
					}
				})
				.catch((e) => {
					console.error("OUTER", e);
					Notie.error("Failed to upload files", e);
				});
		},

		async saveSectionChanges() {
			const { assignedSections, oldAssignedSections } = this;

			const toRemove = oldAssignedSections
				.filter((section) => !assignedSections.includes(section))
				.map((section) => this.removeSection(section));
			const toAdd = assignedSections
				.filter((section) => !oldAssignedSections.includes(section))
				.map((section) => this.addSection(section));

			await Promise.all(toRemove.concat(toAdd));
		},

		async addSection(section) {
			section.training_course_ids = (section.training_course_ids || []).concat(this.course.id);
			try {
				await SectionService.saveSection(section);
			} catch (err) {
				Notie.error("Failed to add Section", err);
			}
		},

		async removeSection(section) {
			section.training_course_ids = (section.training_course_ids || []).filter((id) => id !== this.course.id);
			try {
				await SectionService.saveSection(section);
			} catch (err) {
				Notie.error("Failed to add Section", err);
			}
		},

		async saveItemChanges() {
			const { assignedItems, oldAssignedItems } = this;

			const toRemove = oldAssignedItems
				.filter((item) => !assignedItems.includes(item))
				.map((item) => this.removeItem(item));
			const toAdd = assignedItems
				.filter((item) => !oldAssignedItems.includes(item))
				.map((item) => this.addItem(item));

			await Promise.all(toRemove.concat(toAdd));
		},

		async addItem(item) {
			item.training_course_ids = (item.training_course_ids || []).concat(this.course.id);
			try {
				await ItemService.saveItem(item);
			} catch (err) {
				Notie.error("Failed to add training course ids to item", err);
			}
		},

		async removeItem(item) {
			item.training_course_ids = (item.training_course_ids || []).filter((id) => id !== this.course.id);
			try {
				await ItemService.saveItem(item);
			} catch (err) {
				Notie.error("Failed to update training course ids for item", err);
			}
		},

		doSaveTrainingCourse() {
			this.currentlySaving = true;
			// The trainingEdit.savingCourse store var is a signal
			// to the MyTraining vue component to not fetch course data
			// until either the course save is complete, or errors out.
			// This prevents stale data from loading in the other component
			// if the user quickly navigates there post save, or if network
			// hiccups prevent fast/efficient DB writing.
			//
			// Becuase this fn is called in an async fn, the vue router
			// does not wait for the save to complete before navigating.
			// Therefore, if course data is loaded prior to save completion,
			// stale data will (and does) get presented to the user sometimes.
			// without a wait signal like this.
			Store.set(this, "trainingEdit.savingCourse", true);
			return TrainingService.saveTrainingCourse(this.course)
				.then((r) => {
					Store.set(this, "trainingEdit.savingCourse", false);
					this.currentlySaving = false;

					Notie.info(this.$i18n.t("notie.training_course_saved"));
					if (this.course.id !== r.data.id) {
						this.$router.replace(`/training/${r.data.id}`);
					}
					this.loadData();
				})
				.catch((e) => {
					Store.set(this, "trainingEdit.savingCourse", false);
					this.currentlySaving = false;

					console.log(e);
					Notie.error(this.$i18n.t("notie.save_training_course_fail"), e);
				});
		},

		canSave() {
			return this.dirty;
		},

		startBatchImport() {
			alert("start batch import");
		},
	},
};
</script>
