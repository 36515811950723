//Driver for normal scoring (1st/2nd) Reads

import axios from "axios";

export default {
	icon: "search",
	name: "ReviewScoringDriver",
	allowUpdateScore: true, //Only for QC sets
	showScores() {
		return false;
	},
	sidebarType() {
		return "normal";
	},
	canBeEscalated: true,

	getNext(ctx) {
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		let params = {};
		// debugger;

		if (ctx.skipqc) {
			params.skipqc = true;
		}
		var promise = axios.get(`/v1/review/${sid}/${iid}`, { params: params });
		promise.then(function(resp) {
			console.log("ReviewSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},

	getResponse(ctx, responseID) {},

	submitScore(score, response, keep) {
		score.response_id = response.id;
		var url = "/v1/legacy/submit";

		var promise = axios.post(url, score, {
			params: { keep: keep }
		});
		promise.then(function(resp) {
			console.log("ReviewSD Submitted");
		});
		return promise;
	}
};
