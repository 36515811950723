<template>
	<div class="flex-row" :class="{ 'with-annotation-aside': showAnnotationAside }">
		<div ref="essay" class="essay" :class="{ 'essay-nopad': fit_to_content || noPad }">
			<span class="label text-theme" v-if="page.label">{{ page.label }}</span>
			<p class="respText">
				<span v-html="page.text"></span>
			</p>

			<div
				v-for="ar in annotationRegions"
				:key="ar.timestamp"
				class="annotation-margin"
				:style="{ top: `${ar.anchorTop}px` }"
				:id="`overlay-${ar.timestamp}`"
				@mouseenter="applyHoverToGroup(ar.timestamp)"
				@mouseleave="applyUnhoverToGroup(ar.timestamp)"
			>
				<div
					class="annotation-side-border"
					:class="{ [`highlight-${ar.annotation.color}`]: true }"
					:style="{ height: `${ar.anchorHeight}px` }"
					:id="`overlay-border-${ar.timestamp}`"
					v-tippy="{
						placement: 'top-end',
						theme: 'popover',
						interactive: true,
						reactive: true,
						hideOnClick: false,
						trigger: 'hover',
						html: `#overlay-popup-${ar.timestamp}`,
					}"
				></div>
				<div :id="`overlay-popup-${ar.timestamp}`" class="overlay-popup">
					<span v-if="!ar.editing" v-html="ar.annotation.text"></span>
					<div class="d-flex flex-row" v-if="ar.editing">
						<RichText
							:initial="annotationInput"
							:text.sync="annotationOutput"
							:options="{ height: 104 }"
							class="flex mb-0"
						/>
						<div
							v-if="item.rubric && item.rubric.checklist && item.rubric.checklist_items && score"
							class="pl-2 ml-2 border-left d-flex flex-column"
						>
							<label
								v-for="(ci, i) in item.rubric.checklist_items"
								:key="i"
								class="md-check"
								style="flex-basis: 20%"
							>
								<input type="checkbox" :checked="checked(ci.id)" @click="toggleCheck(ci.id)" />
								<i class="theme-accent"></i>
								<span class="mr-2">{{ ci.name }}</span>
							</label>
						</div>
						<div class="pl-2 ml-2 border-left d-flex flex-column">
							<div class="flex d-flex flex-row">
								<div class="flex d-flex flex-column">
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-blue"
										:class="{
											selected: ar.annotation.color == 'blue',
										}"
										@click="changeHighlightColor(ar, 'blue')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-red"
										:class="{
											selected: ar.annotation.color == 'red',
										}"
										@click="changeHighlightColor(ar, 'red')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-yellow"
										:class="{
											selected: ar.annotation.color == 'yellow',
										}"
										@click="changeHighlightColor(ar, 'yellow')"
									></button>
								</div>
								<div class="flex d-flex flex-column ml-1">
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-green"
										:class="{
											selected: ar.annotation.color == 'green',
										}"
										@click="changeHighlightColor(ar, 'green')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-orange"
										:class="{
											selected: ar.annotation.color == 'orange',
										}"
										@click="changeHighlightColor(ar, 'orange')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-purple"
										:class="{
											selected: ar.annotation.color == 'purple',
										}"
										@click="changeHighlightColor(ar, 'purple')"
									></button>
								</div>
							</div>
							<div class="btn btn-sm" @click="saveAnnotationRegion(ar)">Save</div>
						</div>
					</div>
				</div>
				<div v-if="ar.editable" class="position-relative">
					<a
						class="btn-subtle overlay-btn position-absolute"
						style="font-size: 18px"
						@click="toggleEditAnnotation(ar)"
						:style="{ top: `${ar.buttonOffset}px` }"
					>
						<i class="fa-comment" :class="{ [ar.editing ? 'fas' : 'far']: true }" />
					</a>
					<a
						class="btn-subtle overlay-btn position-absolute"
						style="font-size: 18px; left: 28px"
						@click="removeAnnotationRegion(ar.timestamp)"
						:style="{ top: `${ar.buttonOffset}px` }"
					>
						<i class="far fa-trash" />
					</a>
				</div>
				<div v-if="!ar.editable">
					<a
						class="overlay-btn position-absolute"
						style="font-size: 18px; left: 6px"
						:style="{ top: `${ar.buttonOffset}px` }"
					>
						<i class="far fa-comment-lines" />
					</a>
				</div>
			</div>
		</div>
		<div v-if="showAnnotationAside" class="annotation-aside">
			<div v-for="(anno, i) in annotationRegions" :key="i" class="d-flex flex-row align-items-start">
				<div class="annotation-number" :class="{ [getHighlightColorClass(anno.annotation.color)]: true }">
					{{ i + 1 }}
				</div>
				<div v-html="anno.annotation.text"></div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import _ from "lodash";
import store from "../../services/Store";
import Utils from "../../services/Utils";
import RAS from "@/services/ResponseAuditService";
import Notie from "@/services/NotieService";
import RichText from "@/components/RichText";
require("@/text_highlighter/TextHighlighter");

export default {
	name: "TextMedia",
	props: ["page", "chan", "zoom", "item", "score", "noPad"],
	components: { RichText },

	data() {
		let showAllAnnotations = store.get(this, "showAllAnnotations");
		return {
			events: [],
			sizes: ["9px", "12px", "15px", "18px", "22px", "27px", "32px", "36px"],
			fit_to_content: false,
			mathjaxEnabled: false,
			showAllAnnotations: showAllAnnotations,

			highlighter: null,
			highlightInterface: null,
			selectingHighlight: false,

			annotations: null,
			annotationRegions: [],
			annotationInput: null,
			annotationOutput: null,
			annotationOrigColor: "blue",

			timer: 0,

			rangeHTMLs: {},

			logData: null,
		};
	},

	created() {
		// console.log("CREATE TEXT MEDIA", this._uid);
		let _this = this;
		this.highlightInterface = {
			setAnnotations(annos) {
				_this.setAnnotations(annos);
			},
			getAnnotations() {
				return _this.getAnnotations();
			},
		};
		this.$emit("update:highlighter", this.highlightInterface);

		if (!this.chan) {
			return;
		}

		if (this.item && this.item.fit_to_content) {
			this.fit_to_content = true;
		}

		this.chan.$emit("annotation-off");

		var zoom = () => {
			var ele = this.$refs.essay;
			var size = store.get(this, "viewerScale");
			var px = this.sizes[size];
			$(ele).css("font-size", px);
		};
		this.events.push({ key: "zoom-in", val: zoom });
		this.events.push({ key: "zoom-out", val: zoom });
		this.chan.$on("zoom-in", zoom);
		this.chan.$on("zoom-out", zoom);

		var invert = () => {
			$(this.$refs.essay).toggleClass("inverted");
		};
		this.events.push({ key: "invert", val: invert });
		this.chan.$on("invert", invert);

		var mathjax = () => {
			this.toggleMathjax();
		};
		this.events.push({ key: "mathjax", val: mathjax });
		this.chan.$on("mathjax", mathjax);

		this.chan.$on("annotation", this.toggleAnnotation);
		this.events.push({ key: "annotation", val: this.toggleAnnotation });
		this.chan.$on("annotation-interrupt", this.interruptAnnotation);
		this.events.push({ key: "annotation-interrupt", val: this.interruptAnnotation });
		this.chan.$on("annotation-cancel", this.cancelAnnotation);
		this.events.push({ key: "annotation-cancel", val: this.cancelAnnotation });

		this.chan.$on("all-annotations", this.toggleAllAnnotations);
		this.events.push({ key: "all-annotations", val: this.toggleAllAnnotations });
	},

	mounted() {
		var zoom = store.get(this, "viewerScale");
		var size = this.sizes[zoom] || this.sizes[1];
		$(this.$refs.essay).css("font-size", size);
		if (this.item && this.item.use_mathjax) {
			this.toggleMathjax(this.item.mathjax_delimiter);
		}
		let _this = this;
		this.highlighter = new TextHighlighter(this.$refs.essay, {
			onBeforeHighlight(range) {
				_this.rangeHTMLs[range] = range.startContainer.parentNode.innerHTML;
				RAS.recordAction("Before highlight", { range: range, text: range.toString() });
				if (_this.selectingHighlight) {
					if (_this.highlighter.rangeHasHighlightNonDestructive(range)) {
						Notie.error("Cannot overlap highlights");
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			},

			onAfterHighlight(range, highlights, timestamp) {
				if (highlights.length == 0) {
					if (_this.rangeHTMLs[range]) {
						range.startContainer.parentNode.innerHTML = _this.rangeHTMLs[range];
						// Since regenerating the HTML breaks DOM element references, we need to
						// ensure all annotationRegion objects point to current DOM elements
						_this.rehookAnnotationRegions();
						RAS.recordAction("After highlight", {
							timestamp: timestamp,
							action: "Cancel because no highlight chunks",
						});
						_this.disableAnnotation();
						Notie.error("No text selected");
						return;
					}
				} else {
					delete _this.rangeHTMLs[range];
				}
				timestamp = timestamp.toString();
				let annotation = _this.createAnnotation(timestamp);
				_this.annotationRegions.push({
					timestamp: timestamp,
					chunks: highlights,
					annotation: annotation,
					editable: true,
				});
				RAS.recordAction("Create annotation", { timestamp: timestamp });
				_this.hookGroupHover(highlights, timestamp);
				_this.positionAnnotationOverlays();
				_this.disableAnnotation();

				//Open the tooltip in edit mode right after the highlight is created
				//We need to wait three ticks here: 1 for the annotation region overlay to be created,
				//2 for the tippy code to hook into it, and 3 for the tooltip to position itself properly
				_this.$nextTick(() => {
					_this.$nextTick(() => {
						_this.$nextTick(() => {
							let ar = _.find(_this.annotationRegions, {
								timestamp: timestamp,
							});

							if (!ar) {
								console.error("Failed to find annotation region", timestamp, _this.annotationRegions);
								return;
							}
							_this.toggleEditAnnotation(ar);
						});
					});
				});

				if (!_this.chan) return;
				_this.chan.$emit("has-annotations");
				_this.chan.$emit("anno-regions", _this.annotationRegions);
			},

			color: null,
			highlightedClass: "_highlighter-blue",
		});

		this.positionAnnotationOverlays();
		new ResizeObserver(this.positionAnnotationOverlays).observe(this.$refs.essay);

		if (this.chan) {
			this.chan.$emit("anno-regions", this.annotationRegions);
		}
		this.logData = _.cloneDeep(RAS.currentLog);
	},

	beforeDestroy() {
		let annos = this.getAnnotations();
		if (this.logData) {
			if (this.logData.actions) delete this.logData.actions;
			this.logData.type = "AnnotationBackup";
			this.logData.annotations = annos;
			_LTracker.push(this.logData);
		}
	},

	destroyed() {
		var _this = this;
		_.each(this.events, (e) => {
			_this.chan.$off(e.key, e.val);
		});
	},

	computed: {
		showAnnotationAside() {
			return this.showAllAnnotations && this.annotationRegions && this.annotationRegions.length > 0;
		},
	},

	methods: {
		toggleMathjax() {
			if (this.mathjaxEnabled) {
				let tempContent = this.page.text;
				this.page.text = " ";
				this.$forceUpdate();
				this.$nextTick(() => {
					this.page.text = tempContent;
					this.$forceUpdate();
					this.mathjaxEnabled = false;
				});
			} else {
				Utils.throttleMathJAX();
				this.mathjaxEnabled = true;
			}
		},

		/* createHighlightOverlay(chunks) {
			let minLeft = Infinity;
			let minTop = Infinity;
			let maxRight = 0;
			let maxBottom = 0;
			_.each(chunks, chunk => {
				chunk = $(chunk);
				minLeft = Math.min(minLeft, chunk.position().left);
				minTop = Math.min(minTop, chunk.position().top);
				maxRight = Math.max(
					maxRight,
					chunk.position().left + chunk.width()
				);
				maxBottom = Math.max(
					maxBottom,
					chunk.position().top + chunk.height()
				);
			});

			//Top and bottom of the main square will vary depending on if we make hat or shoe
			let mainTop = null;
			let mainBottom = null;
			let essayRect = this.$refs.essay.getBoundingClientRect();
			let x = essayRect.left;
			let y = essayRect.top;

			//If the left side of the first piece isn't in line with the leftmost edge,
			//let's make a hat piece
			let first = chunks[0].getClientRects();
			first = first[0];
			if (first.left - x - minLeft > 1) {
				console.log("MAKE A HAT");
				let interimY = Infinity;

				//To get this interim Y, we want to look through the top-left corners of every
				//chunk, filter by ones that are to the left of the hat's left edge, and then
				//pick the topmost one
				_.each(chunks, chunk => {
					let rects = chunk.getClientRects();
					_.each(rects, rect => {
						if (rect.left - x < first.left - x) {
							interimY = Math.min(interimY, rect.top - y);
						}
					});
				});

				//Now that we know the interim Y, we now know enough to create the hat piece
				//and set the top of the main piece
				mainTop = interimY;
				this.createBoxOverlay(
					first.left - x,
					maxRight,
					minTop,
					interimY
				);
			} else {
				//If no hat, the main box's top is the bounding region's top
				mainTop = minTop;
			}

			//If the right side of the last piece isn't in line with the rightmost edge,
			//let's make a shoe piece
			let last = chunks[chunks.length - 1].getClientRects();
			last = last[last.length - 1];
			if (maxRight - (last.right - x) > 1) {
				console.log("MAKE A SHOE");
				let interimY = 0;

				//To get this interim Y, we want to look through the bottom-right corners of
				//every chunk, filter by ones that are to the right of the shoe's right edge,
				//and then pick the bottommost one
				_.each(chunks, chunk => {
					let rects = chunk.getClientRects();
					_.each(rects, rect => {
						if (rect.right - x > last.right - x) {
							interimY = Math.max(interimY, rect.bottom - y);
						}
					});
				});

				//Now that we know the interim Y, we now know enough to create the hat piece
				//and set the top of the main piece
				mainBottom = interimY;
				this.createBoxOverlay(
					minLeft,
					last.right - x,
					interimY,
					maxBottom
				);
			} else {
				//If no shoe, the main box's bottom is the bounding region's bottom
				mainBottom = maxBottom;
			}
			console.log("MAKE MAIN");
			this.createBoxOverlay(minLeft, maxRight, mainTop, mainBottom);
		}, */

		createBoxOverlay(left, right, top, bottom) {
			let overlay = document.createElement("div");
			overlay.style.position = "absolute";
			overlay.style.left = `${left}px`;
			overlay.style.width = `${right - left}px`;
			overlay.style.top = `${top}px`;
			overlay.style.height = `${bottom - top}px`;
			overlay.style.pointerEvents = "none";
			//overlay.style.border = "1px solid black";
			overlay.style.backgroundColor = "#a0a0ff40";
			this.$refs.essay.appendChild(overlay);
			return overlay;
		},

		serializeHighlight(timestamp) {
			let groups = this.highlighter.getHighlights({ grouped: true });
			// console.log(timestamp, groups);
			let group = _.find(groups, { timestamp: timestamp.toString() });
			if (!group) {
				console.log(`Could not serialize highlight group, timestamp ${timestamp} not found`);
			}
			let json = this.highlighter.serializeHighlights(group.chunks);
			return json;
		},

		deserialize(serialized) {
			this.highlighter.deserializeHighlights(serialized);
		},

		interruptAnnotation() {
			if (this.selectingHighlight) {
				this.disableAnnotation();
			}
		},

		toggleAnnotation() {
			if (!this.selectingHighlight) {
				this.enableAnnotation();
			} else {
				this.disableAnnotation();
			}
			this.highlighter.doHighlight();
		},

		cancelAnnotation() {
			var element = $(this.$refs.essay);
			if (!element) return;
			this.selectingHighlight = false;
			$(element).css("cursor", "default");
		},

		disableAnnotation() {
			RAS.recordAction("Disable annotations");
			this.cancelAnnotation();
			this.chan.$emit("annotation-off");
		},

		enableAnnotation() {
			RAS.recordAction("Enable annotations");
			var element = $(this.$refs.essay);
			this.selectingHighlight = true;
			$(element).css("cursor", "alias");
			this.chan.$emit("annotation-on");
		},

		hookGroupHover(highlights, timestamp) {
			_.each(highlights, (highlight) => {
				highlight.addEventListener("mouseover", () => {
					this.applyHoverToGroup(timestamp);
				});
				highlight.addEventListener("mouseleave", () => {
					this.applyUnhoverToGroup(timestamp);
				});
			});
		},

		applyHoverToGroup(timestamp) {
			let ar = _.find(this.annotationRegions, {
				timestamp: timestamp,
			});

			if (!ar) {
				console.error("Failed to find annotation region", timestamp, typeof timestamp, this.annotationRegions);
				return;
			}

			ar.hovered = true;

			let highlights = this.highlighter.getHighlightGroup(timestamp);
			_.each(highlights, (highlight) => {
				highlight.classList.add("hovered");
			});

			let overlay = document.getElementById(`overlay-${timestamp}`);
			if (overlay) {
				overlay.classList.add("hovered");
			}

			let border = document.getElementById(`overlay-border-${timestamp}`);
			border._tippy.show();
		},

		applyUnhoverToGroup(timestamp, force) {
			let ar = _.find(this.annotationRegions, {
				timestamp: timestamp,
			});
			if (!force && ar && ar.editing) {
				return;
			}

			let highlights = this.highlighter.getHighlightGroup(timestamp);
			_.each(highlights, (highlight) => {
				highlight.classList.remove("hovered");
			});

			let overlay = document.getElementById(`overlay-${timestamp}`);
			if (overlay) {
				overlay.classList.remove("hovered");
			}

			let border = document.getElementById(`overlay-border-${timestamp}`);

			//Delay the hide until next tick to ensure that we're actually not hovered
			//over anything anymore. Prevents flickering between contiguous regions
			this.$nextTick(() => {
				this.$nextTick(() => {
					if (!ar.hovered) {
						border._tippy.hide();
					}
				});
			});
			ar.hovered = false;
		},

		createAnnotation(timestamp) {
			let annotation = {
				id: timestamp,
				user_id: "5cd9b8c9098fc151a4780de2",
				type: 0, //Text highlight
				color: "blue",
				// text: `Annotation ${this.annotationRegions.length + 1}`,
				editable: true,
				text: "",
			};
			return annotation;
		},

		positionAnnotationOverlays() {
			if (!(this.annotationRegions && this.annotationRegions.length > 0)) {
				return;
			}

			_.each(this.annotationRegions, (ar) => {
				try {
					let minY = Infinity;
					let maxY = 0;
					_.each(ar.chunks, (chunk) => {
						chunk = $(chunk);
						let top = chunk.position().top;
						minY = Math.min(top, minY);

						let bottom = chunk.position().top + chunk.height() + 8;
						maxY = Math.max(bottom, maxY);
					});

					const pos = $(ar.chunks[0]).position();
					if (pos) {
						ar.firstX = pos.left;
					}
					ar.anchorTop = Math.floor(minY);
					ar.anchorHeight = maxY - minY;
				} catch (e) {
					console.error("Failed to position annotation region", ar.timestamp, "-", e);
				}
			});

			//Sort overlays first by vertical position, then by x position of the start of the highlighted region
			this.annotationRegions = _.sortBy(this.annotationRegions, "anchorTop", "firstX");

			//Keep pushing regions down until they don't overlap
			let filledTo = 0;
			let buttonHeight = 24;
			_.each(this.annotationRegions, (ar, i) => {
				if (ar.anchorTop < filledTo) {
					//If vertical space is filled in the current region's intended y coordinate, push it down until space is empty
					ar.buttonOffset = filledTo - ar.anchorTop;
					filledTo += buttonHeight;
				} else {
					//If vertical space is free, don't push down, and update the amount of filled vertical space
					ar.buttonOffset = 0;
					filledTo = ar.anchorTop + buttonHeight;
				}
			});
		},

		toggleEditAnnotation(ar) {
			if (ar.editing) {
				ar.annotation.color = this.annotationOrigColor;

				this.applyUnhoverToGroup(ar.timestamp, true);

				//Delaying this to prevent the tooltip from flickering back into non-edit mode during its hiding animation.
				//Tippy has on onHidden callback that would be better here, but I couldn't get it to fire.
				//So we're using setTimeout as a hacky replacement. It works, though.
				setTimeout(() => {
					ar.editing = false;
					this.$forceUpdate();
				}, 200);
			} else {
				this.annotationOrigColor = ar.annotation.color;
				ar.editing = true;
				this.annotationInput = ar.annotation.text;

				let el = document.getElementById(`overlay-border-${ar.timestamp}`);
				let popup = document.getElementById(`overlay-popup-${ar.timestamp}`);

				if (!popup) {
					//If edit is toggled from an outside source, the actual region might not be in a hovered state yet
					//If this is the case, change it to hovered before opening the tooltip
					this.applyHoverToGroup(ar.timestamp);
					el._tippy.popperInstance.update();
				}
				el._tippy.onMount = () => {
					new ResizeObserver(el._tippy.popperInstance.update).observe(popup);
				};
			}

			this.$forceUpdate();
		},

		removeAnnotationRegion(timestamp) {
			let ar = _.find(this.annotationRegions, { timestamp, timestamp });
			if (!ar) {
				console.log(`Tried to remove annotation region ${timestamp} but it did not exist`);
			}

			RAS.recordAction("Remove annotation", { timestamp: timestamp });

			_.each(ar.chunks, (chunk) => {
				this.highlighter.removeHighlights(chunk);
			});

			this.annotationRegions = _.without(this.annotationRegions, ar);
			this.positionAnnotationOverlays();

			this.chan.$emit("anno-regions", this.annotationRegions);
		},

		// Ensures that annotation regions retain DOM references even after the HTML may have been regenerate
		rehookAnnotationRegions() {
			_.each(this.annotationRegions, (ar) => {
				let chunks = this.highlighter.getHighlightGroup(ar.timestamp);
				ar.chunks = chunks;
				this.positionAnnotationOverlays();
			});
		},

		removeAllAnnotationRegions() {
			_.each(this.annotationRegions, (_, timestamp) => {
				this.removeAnnotationRegion(timestamp);
			});
		},

		changeHighlightColor(ar, color) {
			this.doChangeHighlightColor(ar.timestamp, color);
			ar.annotation.color = color;
		},

		doChangeHighlightColor(timestamp, color) {
			let colors = ["blue", "red", "yellow", "green", "orange", "purple"];
			_.each(colors, (c) => {
				this.highlighter.removeClassFromGroup(`_highlighter-${c}`, timestamp);
			});
			this.highlighter.addClassToGroup(`_highlighter-${color}`, timestamp);
		},

		saveAnnotationRegion(ar) {
			ar.annotation.text = this.annotationOutput;
			this.annotationOrigColor = ar.annotation.color;
			this.toggleEditAnnotation(ar);
		},

		setAnnotations(annos) {
			this.annotations = annos;
			this.highlighter.removeHighlights();
			this.annotationRegions = [];

			_.each(this.annotations, (annotation) => {
				this.deserialize(annotation.region);
				let chunks = this.highlighter.getHighlightGroup(annotation.id);
				let timestamp = annotation.id;
				this.annotationRegions.push({
					timestamp: timestamp,
					chunks: chunks,
					annotation: annotation,
					editable: annotation.editable,
				});

				this.positionAnnotationOverlays();
				this.hookGroupHover(chunks, timestamp);
				this.doChangeHighlightColor(annotation.id, annotation.color);
			});

			if (this.chan) {
				this.chan.$emit("anno-regions", this.annotationRegions);
			}
		},

		getAnnotations() {
			let annos = [];

			let allSerialized = this.highlighter.serializeHighlightsSeparate();
			_.each(allSerialized, (s) => {
				let ar = _.find(this.annotationRegions, { timestamp: s.timestamp });
				if (!ar) {
					console.error("Failed to find region for serialized highlight", s.timestamp);
					return;
				}

				let anno = ar.annotation;
				anno.region = s.serialized;
				annos.push(anno);
			});

			return annos;
		},

		toggleAllAnnotations() {
			this.showAllAnnotations = !this.showAllAnnotations;
			store.set(this, "showAllAnnotations", this.showAllAnnotations);
			this.positionAnnotationOverlays();
		},

		getHighlightColorClass(color) {
			return `highlight-${color}`;
		},

		checked(id) {
			return this.score.checklist[id] && this.score.checklist[id].checked;
		},

		toggleCheck(id) {
			console.log("toggle check", id);
			this.score.checklist[id].checked = !this.score.checklist[id].checked;
		},
	},
};
</script>

<style scoped>
.inverted {
	background-color: black;
	color: white;
}
.label {
	position: relative;
	top: 10px;
	left: 15px;
}

.debug {
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: #c0c0ff;
}

.annotation-margin {
	position: absolute;
	width: 60px;
	right: 10px;
	display: flex;
	flex-direction: row;
}
.annotation-side-border {
	position: absolute;
	width: 3px;
	left: -5px;
	height: 100px;
	opacity: 0;
	transition: opacity 0.15s;
	pointer-events: none;
}
.annotation-margin.hovered .annotation-side-border {
	opacity: 1;
}

a.btn-subtle {
	margin-top: -2px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
	transition: opacity 0.15s;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.annotation-margin:not(.hovered) a.overlay-btn {
	opacity: 0.35;
}

.highlight-btn {
	width: 23px;
	height: 23px;
	border: 1px solid #111;
	border-radius: 12px;
	margin: 5px 8px;
	display: inline-block;
	cursor: pointer;
}
.highlight-btn.selected {
	box-shadow: #666 0 0 4px 2px;
}
.highlight-blue {
	background-color: rgb(41, 182, 246);
}
.highlight-red {
	background-color: rgba(244, 67, 54, 0.75);
}
.highlight-yellow {
	background-color: rgb(248, 240, 100);
}
.highlight-green {
	background-color: rgb(139, 195, 74);
}
.highlight-orange {
	background-color: rgb(246, 154, 77);
}
.highlight-purple {
	background-color: rgb(143, 100, 150);
}

.annotation-number {
	font-family: sans-serif;
	font-size: 16px;
	height: 24px;
	min-width: 24px;
	border-radius: 50%;
	margin-top: 2px;
	margin-left: -10px;
	margin-right: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-weight: 800;
	color: white;
	line-height: 1.65;
}
</style>
