<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("IeaTodItemSetup.title") }}</div>

				<!-- Search field -->
				<div>
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('IeaTodItemSetup.search_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>

		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="table_fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					sort-icon-left
				>
					<!-- workflow_state -->
					<template #cell(workflow_state)="data">
						{{ data.item.workflow_state }}
					</template>

					<!-- Actions -->
					<template #cell(details)="data">
						<a
							:href="`#/iea_tod_items/${data.item.tenantId}/${data.item.projectId}/${data.item.itemId}`"
							class="btn btn-sm btn-icon btn-rounded theme-accent m-0"
							v-tippy
							:title="$t('tooltip.edit')"
						>
							<i class="far fa-edit"></i>
						</a>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>

		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}
.w90 {
	width: 90px;
}
.constrained-cell {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>

<script>
//UI Components

//Libraries

//Services
import IEAService from "@/services/IEAService";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

export default {
	name: "IeaTodItemSetup",

	props: ["user"],

	components: {},

	data() {
		return {
			table_fields: [
				{
					key: "tenantId",
					label: this.$i18n.t("fields.iea_tenant_id"),
					sortable: true,
				},
				{
					key: "projectId",
					label: this.$i18n.t("fields.admin_name"),
					sortable: true,
				},
				{
					key: "itemId",
					label: this.$i18n.t("fields.item_id"),
					sortable: true,
				},
				{
					key: "workflow_state",
					label: this.$i18n.t("fields.state"),
					sortable: true,
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "details",
					label: this.$i18n.t("fields.details"),
					tdClass: "text-center",
					thClass: "text-center",
				},
			],
			fs: fs,
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			active: false,
			sortBy: null,
			sortDesc: false,
			showInactive: true,
			loading: true,
			deleting: false,
		};
	},

	created() {
		this.sortBy = store.get(this, "ieaTodItems.sortBy");
		this.sortDesc = store.getDefault(this, "ieaTodItems.sortDesc", false);
		this.showInactive = store.getDefault(this, "ieaTodItems.showInactive", true);
	},

	computed: {
		table_fieldsz() {
			const { $i18n } = this;
			const fields = [
				{
					key: "tenantId",
					label: $i18n.t("fields.iea_tenant_id"),
					sortable: true,
				},
				{
					key: "projectId",
					label: $i18n.t("fields.admin_name"),
					sortable: true,
				},
				{
					key: "itemId",
					label: $i18n.t("fields.item_id"),
					sortable: true,
				},
				{
					key: "workflow_state",
					label: $i18n.t("fields.state"),
					sortable: false,
				},
				{
					key: "details",
					label: $i18n.t("fields.details"),
					tdClass: "text-center",
					thClass: "text-center",
					sortable: false,
				},
			];

			return fields;
		},
	},

	watch: {
		sortBy() {
			store.set(this, "ieaTodItems.sortBy", this.sortBy);
			console.log("this.sortB");
			console.log(this.sortBy);
		},
		sortDesc() {
			store.set(this, "ieaTodItems.sortDesc", this.sortDesc);
		},
		showInactive() {
			store.set(this, "ieaTodItems.showInactive", this.showInactive);
			this.refreshTable();
		},
	},

	methods: {
		provider(ctx) {
			return IEAService.listTODItems(ctx, this.user.client.iea_tenant_id)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.loading = false;
					return resp.data.items;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_items_fail"), err);
					this.loading = false;
					return [];
				});
		},

		refreshTable() {
			this.$refs.table.refresh();
		},

		goToNewItem() {
			this.$router.push("/items/new");
		},

		openPreviewerImportModal() {
			this.showPreviewerImportModal = true;
		},
	},
};
</script>
