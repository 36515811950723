<template>
	<div class="video-box">
		<div v-if="mediaLoaded == false" class="grid-center padding">
			<div class="text-center" style="line-height: 1.4">
				<i class="fas fa-file-code fa-2x text-extra-muted" />
				<div class="text-extra-muted font-initial">Audio failed to load</div>
			</div>
		</div>

		<template v-else>
			<video
				class="mzd-video"
				preload="auto"
				ref="audio"
				controls
				controlsList="nodownload noplaybackrate"
				@loadeddata="loadedData"
				@error="loadedError"
				@timeupdate="timeupdate"
				@seeking="timeupdate"
				@ended="ended"
			>
				<source :src="page.url" />
				Your browser does not support the video tag.
			</video>
			<i class="far fa-volume-up text-very-muted audio-icon"></i>
		</template>
	</div>
</template>

<script>
import Notie from "@/services/NotieService";
import { REQUIRE_VIEW_ALL_TYPES } from "@/services/Constants";
import _ from "lodash";

export default {
	name: "AudioMedia",
	props: {
		page: Object,
		chan: {
			type: Object,
			required: false,
		},
		item: {
			type: Object,
			required: false,
		},
	},
	data() {
		const {
			item,
			$route: {
				meta: { user },
			},
		} = this;
		return {
			events: [],
			pastTime: 0,
			canSeek: user.role.not_required_to_view_all || !item || !item.require_view_all,
			mediaLoaded: null,
		};
	},

	watch: {},

	created() {
		if (!this.chan) {
			return;
		}
		this.events.push({ key: "set-playback-speed", val: this.setPlaybackSpeed });
		this.chan.$on("set-playback-speed", this.setPlaybackSpeed);
		this.chan.$emit("increment-media-to-load");
	},

	destroyed() {
		const _this = this;
		_.each(this.events, (e) => {
			if (_this.chan) {
				_this.chan.$off(e.key, e.val);
			}
		});
	},

	computed: {
		mustViewLength() {
			const { item, $refs } = this;
			const { FULL_TIME, EXACT_TIME, PERCENT } = REQUIRE_VIEW_ALL_TYPES;

			if (!item) return 0;
			const { require_view_all_config } = item;
			if (!require_view_all_config) return 0;

			switch (require_view_all_config.type) {
				case FULL_TIME.id:
					return $refs.audio.duration;
				case EXACT_TIME.id:
					return require_view_all_config.seconds;
				case PERCENT.id:
					return (require_view_all_config.percent / 100) * $refs.audio.duration;
			}

			return 0;
		},

		cannotSkipMsg() {
			const defaultMsg = "Must listen to the complete audio";
			const { item } = this;
			const { FULL_TIME, EXACT_TIME, PERCENT } = REQUIRE_VIEW_ALL_TYPES;

			if (!item) return defaultMsg;
			const { require_view_all_config } = item;
			if (!require_view_all_config) return defaultMsg;

			switch (require_view_all_config.type) {
				case FULL_TIME.id:
					return defaultMsg;
				case EXACT_TIME.id:
					return `Must listen to ${require_view_all_config.seconds} seconds of the audio`;
				case PERCENT.id:
					return `Must listen to ${require_view_all_config.percent}% of the audio`;
			}

			return defaultMsg;
		},
	},

	methods: {
		setPlaybackSpeed(playbackRate) {
			this.$refs.audio.playbackRate = playbackRate;
		},

		timeupdate() {
			const {
				pastTime,
				canSeek,
				$refs: { audio },
				mustViewLength,
			} = this;
			if (!canSeek && audio.seeking && Math.abs(pastTime - audio.currentTime) > 0.01) {
				this.$refs.audio.currentTime = pastTime;
				this.throttledNotieUpdate();
			}
			if (audio.currentTime > mustViewLength) this.ended();
			this.pastTime = audio.currentTime;
		},

		throttledNotieUpdate() {
			if (!this.throttledNotieUpdate.func) {
				this.throttledNotieUpdate.func = _.throttle(
					() => {
						Notie.info(this.cannotSkipMsg);
					},
					5000,
					{ trailing: false }
				);
			}
			this.throttledNotieUpdate.func();
		},

		ended() {
			this.canSeek = true;
			this.$emit("viewedAll");
		},

		loadedData(ev) {
			if (this.mediaLoaded) {
				// Needed because this seems to fire a second time when the media finished playback
				return;
			}

			console.log("LOADED DATA", ev);
			this.mediaLoaded = true;
			if (this.chan) {
				this.chan.$emit("increment-media-loaded");
			}
		},

		loadedError(ev) {
			this.mediaLoaded = false;
			if (this.chan) {
				this.chan.$emit("increment-media-failed");
			}
		},
	},
};
</script>

<style scoped>
.mzd-video {
	width: 100%;
}
.video-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 800px;
	min-width: 400px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}
.audio-icon {
	position: absolute;
	font-size: 8rem;
	left: calc(50% - 4rem);
	top: calc(50% - 5.8rem);
}
</style>
