<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportScorers.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportScorers.scorers") }}
				</div>
			</div>
		</template>

		<div class="d-flex flex-column flex scroll-x scroll-y">
			<div class="row mx-0 px-3 pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportScorers.project") }}</label>
						<config-select :options="projects" v-model="selectedProject"></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportScorers.section") }}</label>
						<config-select
							:nullOption="anyItems"
							nullText="All sections"
							:options="sections"
							v-model="selectedSection"
							:mustBeFilled="true"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportScorers.item") }}</label>
						<config-select
							:nullOption="anyItems"
							nullText="All items"
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
							sortBy="section_item_sequence"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportScorers.team") }}</label>
						<config-select :nullOption="true" :options="teams" v-model="selectedTeam"></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("ReportScorers.from_date") }}
							<user-timezone />
						</label>
						<config-date v-model="fromDate" :config="{ showClear: true }"></config-date>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("ReportScorers.to_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="toDate"
							:config="{ showClear: true }"
							defaultTime="11:59 PM"
						></config-date>
					</div>
				</div>
				<div class="col-12 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-center form-group">
						<button
							v-if="reportStats && reportStats.length > 0"
							class="btn btn-sm btn-icon btn-rounded theme-accent mx-1"
							style="padding-top: 10px"
							v-tippy
							:title="$t('tooltip.export')"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							<i class="fas fa-file-export" style="margin-left: 2px"></i>
						</button>
						<div v-if="reportStats && reportStats.length > 0" class="dropdown-menu dropdown-menu-right w">
							<a class="dropdown-item" @click="dlPdf()">
								<i class="far fa-file-pdf fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportScorers.export_to_pdf") }}</label>
							</a>
							<a class="dropdown-item" @click="dlCsv()">
								<i class="far fa-file-excel fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportScorers.export_to_csv") }}</label>
							</a>
						</div>
						<div class="py-0 ml-1">
							<button
								@click="getStats"
								class="btn btn-sm theme-accent btn-block flex-fixed"
								v-b-tooltip
								:title="$t('tooltip.run_report')"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline">&nbsp;{{ $t("ReportScorers.run_report") }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="reportStats && reportStats.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div class="flex">
					<div class="card border-0">
						<report-table
							class="condensed-table mb-0"
							:fields="fields"
							:totals="reportTotals"
							perPage="15"
							:content="reportStats"
							totalsClass="theme-lighten-3 text-on-color"
						>
							<template #cell(scorer)="data">
								<span v-if="!data.item.user">{{ data.item.scorer }}</span>
								<span
									v-if="data.item.user"
									v-tippy
									:title="data.item.user.scorer_id != '' ? data.item.user.full_name : null"
									>{{ fs.scorerID(data.item.user) }}</span
								>
							</template>
							<template #cell(total_time)="data">{{ fs.medDuration(data.item.total_time) }}</template>
							<template #cell(avg_time)="data">{{ fs.medDuration(data.item.avg_time) }}</template>
							<template #cell(rate)="data">{{ fs.fixed1d(data.item.rate) }}/h</template>
							<template #cell(flag_count)="data">
								{{ data.item.flag_unscored_count + data.item.flag_scored_count }}
								<span
									v-if="data.item.flag_scored_count > 0"
									class="text-xxs text-muted"
									v-tippy
									:title="$t('tooltip.flagged_with_score')"
									>({{ data.item.flag_scored_count }})</span
								>
							</template>
							<template #cell(res_required)="data">
								{{ data.item.res_required_total }}
								<span
									v-if="data.item.res_required_incomplete > 0"
									class="text-xxs text-muted"
									v-tippy
									:title="$t('tooltip.not_yet_complete')"
									>({{ data.item.res_required_incomplete }})</span
								>
							</template>
							<template #cell(res_required_percent)="data">{{
								fs.fixedPercent1d(data.item.res_required_percent)
							}}</template>
							<template #cell(res_changed_percent)="data">{{
								fs.fixedPercent1d(data.item.res_changed_percent)
							}}</template>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="reportStats && reportStats.length == 0" class="d-flex flex-row justify-content-center">
				<h3 class="text-muted">{{ $t("ReportScorers.report_returned_no_results") }}</h3>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-long {
	display: none;
}

.d-abr {
	display: table-cell;
}

@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}

	.d-abr {
		display: none;
	}
}

.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ConfigService from "@/services/ConfigService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import fs from "@/services/FormatService";
import FileService from "@/services/FileService";
import moment from "moment";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import ReportTable from "@/components/ReportTable";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "ReportScorers",

	props: ["user"],

	components: { ConfigDate, ConfigSelect, ReportTable, UserTimezone },

	data() {
		return {
			fs: fs,
			scorerStats: null,
			totalsRow: null,

			fields: [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
				{
					key: "scorer",
					label: this.$i18n.t("fields.scorer"),
					tdClass: "table-border-right",
					thClass: "text-center table-border-right",
					sortable: true,
				},
				{
					key: "total_time",
					label: this.$i18n.t("fields.score_time"),
					tdClass: "text-center",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "avg_time",
					label: this.$i18n.t("fields.avg_time"),
					tdClass: "text-center",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "rate",
					label: this.$i18n.t("fields.rate"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "total",
					label: this.$i18n.t("fields.scores"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "res_count",
					label: this.$i18n.t("fields.resolutions"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "val_count",
					label: this.$i18n.t("fields.validity"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "cal_count",
					label: this.$i18n.t("fields.calibration"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "flag_count",
					label: this.$i18n.t("fields.flags"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "sum",
					label: this.$i18n.t("fields.total"),
					tdClass: "text-right table-border-right",
					thClass: "text-center table-border-right",
					sortable: true,
				},
				{
					key: "res_required",
					label: this.$i18n.t("fields.resolution_required"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "res_required_percent",
					label: this.$i18n.t("special_chars.percent"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "res_changed",
					label: this.$i18n.t("fields.resolution_disagreed"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "res_changed_percent",
					label: this.$i18n.t("special_chars.percent"),
					tdClass: "text-right",
					thClass: "text-center",
					sortable: true,
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
			],
			reportTotals: null,
			reportStats: null,

			projects: [],
			selectedProject: null,

			teams: [],
			selectedTeam: null,

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			fromDate: null,
			toDate: null,

			running: false,
			loading: true,
		};
	},

	created() {
		this.storagePrefix = "scorers.";
		this.selectedUser = store.bind(this, "selectedUser");
		this.fromDate = store.bind(this, "fromDate");
		this.toDate = store.bind(this, "toDate");

		let teamCall = ConfigService.listTeams;
		if (this.user.role.limit_teams) {
			teamCall = ConfigService.listUserTeams;
		}
		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		Promise.all([ConfigService.listProjectsShallowPreloaded(ctx), teamCall()])
			.then((resps) => {
				console.log(resps);
				//resps[0] - projects
				this.projects = resps[0].data;
				this.selectedProject = store.bindProject(this, this.projects);
				if (this.selectedProject) {
					this.sections = this.selectedProject.sections;
					this.selectedSection = store.bindSection(this, this.sections);
					this.selectedItem = store.bindItem(this, this.selectedSection);
				}

				//resps[1] - teams
				this.teams = resps[1].data.teams;
				if (this.user.role.limit_teams) {
					this.teams = resps[1].data;
				}
				this.selectedTeam = store.bindTeam(this, this.teams, true);
				this.loading = false;
			})
			.catch((err) => {
				console.log(err);
				notie.error(this.$i18n.t("notie.load_config_options_fail"), err);
				this.loading = false;
			});
	},

	watch: {
		selectedProject() {
			if (this.selectedProject && this.selectedProject.sections) {
				this.sections = this.selectedProject.sections;
			} else {
				this.sections = [];
			}
		},
		selectedSection() {
			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
			} else {
				this.items = [];
			}
		},
	},

	methods: {
		anyItems() {
			let hasItems = false;
			if (this.selectedProject && this.selectedProject.sections) {
				_.each(this.selectedProject.sections, (section) => {
					if (section.items && section.items.length > 0) {
						hasItems = true;
					}
				});
			}
			return hasItems;
		},

		pcr(num, den) {
			if (den == 0) {
				return "-";
			}
			if (num == 0) {
				return "0.00";
			}

			var res = (num / den) * 100;
			return res.toFixed(2);
		},

		getStats() {
			if (this.running) {
				return;
			}
			var proj = this.selectedProject;
			var team = this.selectedTeam;
			var sec = this.selectedSection;
			var item = this.selectedItem;
			var fDate = this.fromDate;
			var tDate = this.toDate;

			var ctx = {};

			if (proj) {
				ctx.project_id = proj.id;
			}

			if (sec) {
				ctx.section_id = sec.id;
			}

			if (item) {
				ctx.item_id = item.id;
			}

			if (fDate && fDate != "") {
				ctx.start_date = fDate;
			}
			if (tDate && tDate != "") {
				ctx.end_date = tDate;
			}

			if (team) {
				ctx.team_id = team.id;
			}
			this.running = true;
			ReportingService.getScorerStats(ctx)
				.then((resp) => {
					console.log(resp);
					this.running = false;

					this.scorerStats = resp.data;
					_.each(this.scorerStats, (stat) => {
						stat.total = stat.total - stat.res_count;
						stat.sum =
							stat.val_count + stat.cal_count + stat.res_count + stat.total + stat.flag_unscored_count;
						stat.rate = 3600 / stat.avg_time;
						stat.res_required_total = stat.res_required + stat.res_required_incomplete;
						stat.res_required_percent = (stat.res_required_total * 100) / stat.total;
						stat.res_changed_percent = (stat.res_changed * 100) / stat.total;
					});
					this.totalsRow = {
						total: 0,
						val_count: 0,
						cal_count: 0,
						res_count: 0,
						flag_unscored_count: 0,
						flag_scored_count: 0,
						sum: 0,
						total_time: 0,
						total_system_time: 0,
						res_required_total: 0,
						res_changed: 0,
					};
					_.each(this.scorerStats, (stat) => {
						this.totalsRow.total += stat.total;
						this.totalsRow.val_count += stat.val_count;
						this.totalsRow.cal_count += stat.cal_count;
						this.totalsRow.res_count += stat.res_count;
						this.totalsRow.flag_scored_count += stat.flag_scored_count;
						this.totalsRow.flag_unscored_count += stat.flag_unscored_count;
						this.totalsRow.sum += stat.sum;
						this.totalsRow.total_time += stat.total_time;
						this.totalsRow.total_system_time += stat.total_system_time;
						this.totalsRow.res_required_total += stat.res_required_total;
						this.totalsRow.res_required_incomplete += stat.res_required_incomplete;
						this.totalsRow.res_changed += stat.res_changed;
					});

					//Convert total time to millis
					_.each(this.scorerStats, (stat) => {
						stat.total_system_time = this.getTimeMillis(stat.total_system_time);
					});
					this.totalsRow.total_system_time = this.getTimeMillis(this.totalsRow.total_system_time);
					this.totalsRow.avg_time =
						this.totalsRow.total_time / (this.totalsRow.total + this.totalsRow.res_count);
					this.totalsRow.rate = 3600 / this.totalsRow.avg_time;
					this.totalsRow.scorer = `${this.$i18n.t("fields.totals")} (${this.scorerStats.length})`;
					this.totalsRow.res_required_percent =
						(this.totalsRow.res_required_total * 100) / this.totalsRow.total;
					this.totalsRow.res_changed_percent = (this.totalsRow.res_changed * 100) / this.totalsRow.total;

					this.reportTotals = this.totalsRow;
					this.reportStats = this.scorerStats;
				})
				.catch((err) => {
					this.running = false;
					console.log(err);
					notie.error(this.$i18n.t("notie.load_stats_fail"), err);
				});
		},

		fixed(time) {
			if (time == Number.POSITIVE_INFINITY || isNaN(time)) {
				return "——";
			} else {
				return time.toFixed(1);
			}
		},

		fixedPercent(percent) {
			if (percent == Number.POSITIVE_INFINITY || isNaN(percent)) {
				return "——";
			} else {
				return `${percent.toFixed(1)}%`;
			}
		},

		getTime(time) {
			if (time == 0) {
				return "——";
			} else if (!time) {
				return "——";
			} else {
				return moment().startOf("day").seconds(time).format("HH:mm:ss");
			}
		},

		getTimeMillis(duration) {
			//duration+=86400000
			var milliseconds = parseInt((duration % 1000) / 100),
				seconds = parseInt((duration / 1000) % 60),
				minutes = parseInt((duration / (1000 * 60)) % 60),
				hours = parseInt(duration / (1000 * 60 * 60));

			if (isNaN(seconds)) {
				seconds = 0;
			}
			if (isNaN(minutes)) {
				minutes = 0;
			}
			if (isNaN(hours)) {
				hours = 0;
			}

			hours = hours < 10 ? "0" + hours : hours;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			return hours + ":" + minutes + ":" + seconds;
		},

		getTimeSeconds(duration) {
			if (duration == 0) {
				return "——";
			} else if (!duration) {
				return "——";
			} else {
				//duration+=86400000
				var milliseconds = parseInt((duration % 1000) / 100),
					seconds = parseInt(duration % 60),
					minutes = parseInt((duration / 60) % 60),
					hours = parseInt(duration / (60 * 60));

				hours = hours < 10 ? "0" + hours : hours;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;

				return hours + ":" + minutes + ":" + seconds;
			}
		},

		getDate(date) {
			if (!date) {
				return "——";
			} else if (date.startsWith("000")) {
				return "——";
			} else {
				date = moment(date).format("l LT");
				if (date) {
					return date;
				}
			}
		},

		dlPdf() {
			FileService.scorersReportPDF(this);
		},

		dlCsv() {
			FileService.scorersReportCSV(this);
		},
	},
};
</script>
