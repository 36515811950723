<template>
	<div class="box d-flex flex-column" :class="{ 'stretch-box': stretch }">
		<!-- Title -->
		<div v-if="!bulletin.editing" class="no-grow box-header theme-lighten-3 d-flex flex-row align-items-center">
			<h2 class="flex">{{ bulletin.title }}</h2>
			<a
				v-if="bulletin.role_ids && bulletin.role_ids.length > 0"
				v-tippy
				:title="`Only visible to poster and users with role ${getRoleNames(bulletin.role_ids)}`"
				class="btn-subtle"
				style="margin-right: 0"
			>
				<i class="far fa-user-shield"></i>
			</a>
			<a
				v-if="user.role.bulletin_board_edit && !noEdit && !deleting"
				@click="remove"
				v-tippy
				:title="$t('tooltip.delete')"
				class="btn-subtle"
				style="margin-right: 0"
			>
				<i class="far fa-trash"></i>
			</a>
			<a v-if="deleting" v-tippy title="Deleting...">
				<loading type="icon" />
			</a>
			<a
				v-if="user.role.bulletin_board_edit && !noEdit"
				@click="edit"
				v-tippy
				:title="$t('tooltip.edit')"
				class="btn-subtle"
			>
				<i class="far fa-edit"></i>
			</a>
		</div>
		<div v-if="bulletin.editing" class="no-grow box-header theme-lighten-3 d-flex flex-row align-items-center">
			<h2 class="flex">
				<input ref="titleEdit" class="md-input" v-model="bulletin.title" />
			</h2>
			<a
				v-tippy
				:title="$t('tooltip.limit_visibility_role')"
				class="btn-subtle"
				style="margin-right: 0"
				data-toggle="dropdown"
				aria-expanded="false"
			>
				<i
					class="fa-user-shield"
					:class="{
						[!bulletin.role_ids || bulletin.role_ids.length == 0 ? 'far' : 'fas']: true,
					}"
				></i>
			</a>
			<!-- <div class="dropdown-menu dropdown-menu-right">
				<a
					v-for="tag in tags"
					:key="tag.id"
					class="dropdown-item"
					@click="selectTag(tag)"
				>
					<i
						class="fa-tag"
						:class="{ [selected(tag) ? 'fas' : 'far']: true }"
					></i>
					<span>{{ tag.label }}</span>
				</a>
				<hr class="my-2" />
				<a class="dropdown-item" @click="selectTag()">
					<i class="far fa-tag"></i>
					<span>Clear</span>
				</a>
			</div> -->
			<div class="dropdown-menu dropdown-menu-right">
				<a
					class="dropdown-item theme-hover"
					:class="{
						active: !bulletin.role_ids || bulletin.role_ids.length == 0,
					}"
					@click="selectRole(bulletin, null)"
				>
					<span>{{ $t("data_description.all") }}</span>
				</a>
				<hr class="my-2" />
				<a
					v-for="role in user.client.roles"
					:key="role.id"
					class="dropdown-item theme-hover"
					:class="{
						active: bulletin.role_ids && bulletin.role_ids.includes(role.id),
					}"
					@click="selectRole(bulletin, role.id)"
				>
					<i
						class="far"
						:class="{
							'fa-check': bulletin.role_ids && bulletin.role_ids.includes(role.id),
						}"
					/>
					<span>{{ role.name }}</span>
				</a>
			</div>
			<a @click="cancel" v-tippy :title="$t('tooltip.cancel')" class="btn-subtle" style="margin-right: 0">
				<i class="far fa-times"></i>
			</a>
			<a
				v-if="!posting"
				@click="post"
				v-tippy
				:title="bulletin.id ? $t('tooltip.save') : $t('tooltip.save')"
				class="btn-subtle"
			>
				<i class="far fa-comment"></i>
			</a>
			<a v-if="posting" v-tippy title="Posting..." class="btn-subtle">
				<loading type="icon" />
			</a>
		</div>

		<!-- Body -->
		<div v-if="!bulletin.editing" class="flex box-body light lt landing-box" :class="{ 'stretch-body': stretch }">
			<div v-html="bulletin.body" class="scroll-x scroll-y mh-100"></div>
			<span class="text-muted text-xxs footer"
				>{{ $t("timestamp.by") }} {{ bulletin.user.first_name }} {{ bulletin.user.last_name }}
				{{ $t("timestamp.at") }} {{ formatDate(bulletin.created_at) }}</span
			>
		</div>
		<div v-if="bulletin.editing" class="flex box-body light lt landing-box" :class="{ 'stretch-body': stretch }">
			<RichText
				:initial="bodyInit"
				:text.sync="bulletin.body"
				class="mb-0"
				:initSettings="{ justifyCenter: true, fontSize: 36 }"
				:options="richTextOptions"
				useEquationEditor="true"
			/>
		</div>
	</div>
</template>

<style scoped>
.box-header {
	color: rgba(70, 90, 110, 0.85) !important;
	background-color: #f3f6f9 !important;
}
.box-body {
	background-color: white;
}
a.btn-subtle {
	margin-top: -6px;
	margin-bottom: -6px;
	margin-right: -6px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
i.fa-edit {
	margin-left: 2px;
}
.md-input {
	height: 30px;
	margin-top: -6px;
	margin-bottom: -6px;
}
.box-body {
	padding-top: 2rem !important;
	padding-bottom: 1.5rem !important;
}
.footer {
	position: absolute;
	right: 0.25rem;
	bottom: 0.25rem;
}
.stretch-box {
	height: 100%;
}
.stretch-body {
	height: calc(100% - 50px);
}
.dropdown-item.active {
	color: inherit;
	background-color: rgba(0, 0, 0, 0.1);
}
</style>

<script>
import BulletinService from "@/services/BulletinService";
import moment from "moment";
import notie from "@/services/NotieService";
import Utils from "@/services/Utils";

import RichText from "@/components/RichText";

export default {
	name: "Bulletin",

	props: {
		bulletin: {
			default: null,
		},
		user: {
			default() {
				return { role: {} };
			},
		},
		noEdit: {
			type: Boolean,
			default: false,
		},
		stretch: {
			type: Boolean,
			default: false,
		},
	},

	components: { RichText },

	data() {
		return {
			bodyInit: _.cloneDeep(this.bulletin.body),
			titleInit: _.cloneDeep(this.bulletin.title),
			posting: false,
			deleting: false,

			richTextOptions: {
				toolbar: [
					["style", ["bold", "italic", "underline", "strikethrough", "clear"]],
					["fontsize", ["fontsize"]],
					["para", ["ul", "ol", "paragraph"]],
					["meta", ["undo", "redo"]],
					["insert", ["picture", "link", "video", "table", "hr", "mathEq"]],
				],
				popover: {
					image: [
						["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
						["float", ["floatLeft", "floatRight", "floatNone"]],
						["remove", ["removeMedia"]],
					],
					link: [["link", ["linkDialogShow", "unlink"]]],
					table: [
						["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
						["delete", ["deleteRow", "deleteCol", "deleteTable"]],
					],
					air: [
						["color", ["color"]],
						["font", ["bold", "underline", "clear"]],
						["para", ["ul", "paragraph"]],
						["table", ["table"]],
						["insert", ["link", "picture"]],
					],
				},
				height: 300,
				placeholder: this.$i18n.t("rich_text.placeholder"),
			},
		};
	},
	created() {
		Utils.throttleMathJAX();
	},
	watch: {
		"bulletin.editing"() {
			if (!this.bulletin.editing) {
				this.posting = false;
			}
		},
	},
	methods: {
		post() {
			this.posting = true;
			BulletinService.saveBulletin(this.bulletin)
				.then((resp) => {
					this.$emit("change");
				})
				.catch((err) => {
					notie.error("Failed to save bulletin", err);
					this.$emit("change");
				});
		},

		edit() {
			if (!this.deleting) {
				this.bodyInit = _.cloneDeep(this.bulletin.body);
				this.$set(this.bulletin, "editing", true);
				this.$nextTick(() => {
					this.$refs.titleEdit.focus();
				});
			}
		},

		cancel() {
			if (!this.posting) {
				this.bulletin.title = this.titleInit;
				this.bulletin.body = this.bodyInit;
				this.$set(this.bulletin, "editing", false);
				this.$emit("cancel");
			}
		},

		remove() {
			this.deleting = true;
			BulletinService.removeBulletin(this.bulletin.id)
				.then((resp) => {
					this.$emit("change");
				})
				.catch((err) => {
					notie.error("Failed to delete bulletin", err);
					this.$emit("change");
				});
		},

		selectRole(bulletin, roleID) {
			if (roleID == null) {
				bulletin.role_ids = [];
				return;
			}

			if (!bulletin.role_ids) bulletin.role_ids = [];

			let index = bulletin.role_ids.indexOf(roleID);
			if (index == -1) {
				bulletin.role_ids.push(roleID);
			} else {
				this.$delete(bulletin.role_ids, index);
			}
			console.log("Set bulletin", bulletin, "to role", bulletin.role_ids);
		},

		getRoleNames(roleIDs) {
			if (!roleIDs || roleIDs.length == 0) {
				return "none";
			}

			let roleNames = [];
			_.each(roleIDs, (roleID) => {
				let role = _.find(this.user.client.roles, { id: roleID });
				if (role) {
					roleNames.push(`'${role.name}'`);
				} else {
					roleNames.push(`'Unknown role ID: ${roleID}'`);
				}
			});

			switch (roleNames.length) {
				case 1:
					return roleNames[0];
					break;
				case 2:
					return `${roleNames[0]} or ${roleNames[1]}`;
					break;
				default:
					roleNames[roleNames.length - 1] = `or ${roleNames[roleNames.length - 1]}`;
					return roleNames.join(", ");
			}
		},

		formatDate(date) {
			return moment(date).format("LT l");
		},
	},
};
</script>
