<template>
	<div :class="{ 'd-inline-block': compact }">
		<a class="btn-subtle d-inline-block" data-toggle="dropdown" aria-expanded="false">
			<i class="fa-tag" :class="{ [selectedTags.length > 0 ? 'fas' : 'far']: true }"></i>
			<span v-if="!compact && selectedTags.length > 0">{{
				selectedTags.length == 1 ? fs.qcTagObject(selectedTags[0]).label : fs.qcTagAbrs(selectedTags)
			}}</span>
			<span v-if="!compact && selectedTags.length == 0">Select tag(s)...</span>
		</a>
		<div class="dropdown-menu dropdown-menu-right">
			<a v-for="tag in tags" :key="tag.id" class="dropdown-item" @click="selectTag(tag)">
				<i class="fa-tag" :class="{ [selected(tag) ? 'fas' : 'far']: true }"></i>
				<span>{{ tag.label }}</span>
			</a>
			<hr class="my-2" />
			<a class="dropdown-item" @click="selectTag()">
				<i class="far fa-tag"></i>
				<span>Clear</span>
			</a>
		</div>
	</div>
</template>

<style scoped>
a.btn-subtle {
	border-radius: 0.35rem;
	padding-left: 3px;
	padding-right: 3px;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.2);
}
</style>

<script>
import fs from "@/services/FormatService";

export default {
	name: "QcTagSelect",
	props: {
		value: true,
		compact: {
			type: Boolean,
			default: false,
		},
		tags: true,
		customTags: true,
	},
	components: {
		"vue-select": require("vue-select"),
	},
	data() {
		return {
			fs: fs,
			selectedTags: (this.value || []).concat(this.customTags || []),

			clearable: false,
		};
	},

	computed: {
		selectedQcTags() {
			return this.selectedTags.filter((val) => typeof val === "number");
		},

		selectedCustomTags() {
			return this.selectedTags.filter((val) => typeof val !== "number");
		},
	},

	methods: {
		selectTag(tag) {
			if (!(tag && tag.id)) {
				this.selectedTags = [];
				this.emitVal(false);
			} else {
				let index = this.selectedTags.indexOf(tag.id);
				if (index >= 0) {
					this.$delete(this.selectedTags, index);
					this.emitVal(false);
				} else {
					this.selectedTags.push(tag.id);
					this.emitVal(true);
				}
			}
		},
		emitVal(action) {
			this.$emit("input", this.selectedQcTags);
			this.$emit("update:customTags", this.selectedCustomTags);
			if (action) {
				this.$emit("added", this.selectedQcTags, this.selectedCustomTags);
			} else {
				this.$emit("removed", this.selectedQcTags, this.selectedCustomTags);
			}
		},
		selected(tag) {
			return this.selectedTags.includes(tag.id);
		},
	},
};
</script>
