<template>
	<div class="d-flex flex-column flex h100 page-body">
		<navbar />
		<div class="app" style="overflow: hidden">
			<sidenav :refresh="sideNavRefresh" />
			<div id="content" class="app-content box-shadow-3" style="overflow: hidden">
				<!-- Optional navbar at top of page -->
				<slot name="navbar" v-if="customNavBar" />
				<div v-else class="navbar navbar-light bg-light b-b">
					<a v-if="title" class="navbar-brand">{{ title }}</a>
					<slot name="navbar" />
				</div>

				<slot v-if="customBody" />
				<div v-else class="content-main">
					<slot />
				</div>
			</div>
		</div>

		<mzfooter />
	</div>
</template>

<script>
export default {
	name: "Page",

	props: {
		title: String,
		customBody: Boolean,
		customNavBar: Boolean,
		sideNavRefresh: Number,
	},
};
</script>
