<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			GMAC Counts
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<div class="col-12 col-sm-6">
				<div class="form-group">
					<label>
						{{ $t("ReportScorers.from_date") }}
						<user-timezone />
					</label>
					<config-date v-model="fromDate" :config="{ showClear: true }"></config-date>
				</div>
				<div class="form-group">
					<label>
						{{ $t("ReportScorers.to_date") }}
						<user-timezone />
					</label>
					<config-date v-model="toDate" :config="{ showClear: true }" defaultTime="11:59 PM"></config-date>
				</div>
				<button v-if="!running" @click="getCounts" class="btn btn-success">Run</button>
				<button v-if="running" class="btn btn-success" disabled>
					<loading type="icon" class="mr-1" />Running...
				</button>
			</div>
			<div v-if="result" class="col-12 col-sm-6">
				<hr class="d-sm-none" />
				<label>Result</label>
				<div class="result-panel p-3">
					<pre v-if="result" class="form-control mb-0">{{ result }}</pre>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import ConfigDate from "@/components/ConfigDate";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "GmacCounts",
	props: ["environment"],
	components: { ConfigDate, UserTimezone },

	data() {
		return {
			expanded: Store.get(this, "adminTasks.gmacCounts.expanded"),
			running: false,

			fromDate: null,
			toDate: null,

			result: null,
		};
	},
	created() {
		let now = moment();
		now.subtract(1, "week");
		this.fromDate = now.clone().startOf("month");
		this.toDate = now.clone().endOf("month");
	},
	watch: {
		expanded() {
			Store.set(this, "adminTasks.gmacCounts.expanded", this.expanded);
		},
	},
	methods: {
		getCounts() {
			this.result = null;
			this.running = true;

			let ctx = {
				fromDate: this.fromDate,
				toDate: this.toDate,
			};

			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.post("/v1/admin_tasks/gmac_counts", ctx, config)
				.then((r) => {
					this.running = false;
					this.result = r.data;
				})
				.catch((e) => {
					this.running = false;
					console.error(e);
					Notie.error("Failed to get GMAC Counts", e);
				});
		},
	},
};
</script>
