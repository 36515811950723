<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ItemEdit.Quality.title") }}</div>

		<!-- Training -->
		<div class="row m-0">
			<form role="form" class="pt-4 px-4 col-2">
				<div class="form-group _600">{{ $t("ItemEdit.Quality.practice") }}</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.uses_practice" />
						<i></i>
					</label>
				</div>
			</form>
		</div>
		<hr class="mt-0 mb-2 mx-2" />
		<!-- Validity -->

		<val-config
			v-show="item.validity_config"
			:item="item"
			:originalItem="originalItem"
			:valid="valid"
			:hasValStats="true"
		/>
		<hr class="mt-0 mb-2 mx-2" />
		<!-- Qualification -->
		<div class="row m-0">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600 margin-overlap">
					{{ $t("ItemEdit.Quality.qualification.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.qualification_config.enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-show="item.qualification_config.enabled">
				<div class="row">
					<div
						class="col-md-3 col-6 form-group"
						v-tippy="{ theme: 'popover' }"
						:title="$t('ItemEdit.Quality.qualification.must_see_explanation')"
						:class="{ invalid: !(valid.field('must_see_min') && valid.field('must_see')) }"
					>
						<label>{{ $t("ItemEdit.Quality.qualification.must_see") }}</label>
						<input
							type="number"
							class="form-control"
							v-model.number="item.qualification_config.must_see"
							min="0"
						/>
					</div>
					<div
						class="col-md-3 col-6 form-group"
						v-tippy="{ theme: 'popover' }"
						:title="mustPassTooltip"
						:class="{ invalid: !(valid.field('must_pass_min') && valid.field('must_see')) }"
					>
						<label>{{ $t("ItemEdit.Quality.qualification.must_pass") }}</label>
						<input
							type="number"
							class="form-control"
							v-model.number="item.qualification_config.must_pass"
							min="0"
						/>
					</div>
					<div class="col-md-6 col-12 form-group align-self-end mb-4">
						<label
							class="md-check"
							v-tippy="{ theme: 'popover' }"
							:title="$t('ItemEdit.Quality.qualification.lock_on_fail_explanation')"
						>
							<input v-model="item.qualification_config.lock_on_fail" type="checkbox" />
							<i class="theme-accent"></i> {{ $t("ItemEdit.Quality.qualification.lock_on_fail") }}
						</label>
					</div>
				</div>
				<qc-req-config :item="item" />
			</div>
		</div>

		<hr class="mt-0 mb-2 mx-2" />
		<!-- Calibration -->
		<cal-config v-if="item.calibration_config" :calConfig="item.calibration_config" />
		<hr class="mt-0 mb-2 mx-2" />
		<!-- Rate Exception -->
		<div class="row m-0">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600 margin-overlap">
					{{ $t("ItemEdit.Quality.rate_exception.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.exception_config.rate_enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div class="p-3 col-9" v-if="item.exception_config.rate_enabled">
				<b-row class="font-weight-bold border-bottom">
					<b-col cols="4">
						<label>{{ $t("fields.type") }}</label>
					</b-col>
					<b-col>
						<label>{{ $t("fields.min_max") }}</label>
					</b-col>
					<b-col>
						<label>{{ $t("fields.window") }}</label>
					</b-col>
					<b-col>
						<label>{{ "Seconds/WPM" }}</label>
					</b-col>
					<b-col>
						<label> {{ $t("fields.lockout_user") }} </label>
					</b-col>
					<b-col> </b-col>
				</b-row>
				<b-row v-for="rateExc in item.exception_config.rate_exceptions" :key="rateExc.id" class="py-2">
					<b-col cols="4">
						<config-select :options="rateExceptionTypes" byField="id" v-model="rateExc.type" />
					</b-col>
					<b-col>
						<config-select :options="minMax" byField="id" v-model="rateExc.max" />
					</b-col>
					<b-col>
						<b-input type="number" min="1" v-model.number="rateExc.window" />
					</b-col>
					<b-col>
						<b-input type="number" min="1" v-model.number="rateExc.count" />
					</b-col>
					<b-col align-self="center">
						<div class="p-1" />
						<label class="ui-switch ui-switch-md theme-accent">
							<input type="checkbox" v-model="rateExc.lockout" />
							<i></i>
						</label>
					</b-col>
					<b-col>
						<button
							@click="removeRateException(rateExc)"
							class="btn btn-sm btn-icon btn-rounded red text-white m-0"
							title="Remove Rate Exception"
						>
							<i class="fa fa-trash"></i>
						</button>
					</b-col>
				</b-row>
				<b-button class="theme-accent w-100 m-2" @click="addRateException">
					{{ $t("ItemEdit.Quality.rate_exception.add_rate_exception") }}
				</b-button>
			</div>
		</div>
		<hr class="mt-0 mb-2 mx-2" />
		<div class="row m-0 mb-4">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600 margin-overlap">{{ $t("ItemEdit.Quality.fd_exception.title") }}</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.exception_config.fd_enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-show="item.exception_config.fd_enabled">
				<div class="row">
					<div class="col-md-3 form-group">
						<label>{{ $t("ItemEdit.Quality.fd_exception.scores_before_check") }}</label>
						<input
							class="form-control"
							type="number"
							v-model.number="item.exception_config.fd_min_scores"
							min="1"
						/>
					</div>
				</div>
				<template v-for="exceptionTrait in item.exception_config.fd_traits">
					<div
						class="row"
						v-if="item.rubric && item.exception_config.fd_traits"
						:key="exceptionTrait.trait_id"
					>
						<div class="col-md-12">
							<strong>{{ getTraitName(exceptionTrait.trait_id) }}</strong>
						</div>
						<div class="col-md-2 form-group">
							<label>{{ $t("ItemEdit.Quality.fd_exception.variance") }}</label>
							<percent-input v-model="exceptionTrait.variance_percent" />
						</div>
						<div class="col-md-10">
							<div class="d-flex flex-rows">
								<div
									class="mt-2"
									style="width: 50px"
									v-for="sp in exceptionTrait.score_point_percents"
									:key="sp.score_point"
								>
									<input class="text-center" type="checkbox" v-model="sp.enabled" />
									<br />
									<div class="text-center">
										{{ sp.score_point }}
									</div>
									<input
										v-model.number="sp.percent"
										type="range"
										min="0"
										max="100"
										orient="vertical"
										style="
											display: inline-block;
											-webkit-appearance: slider-vertical;
											width: 50px;
											height: 150px;
										"
									/>
									<br />
									<div class="text-center">{{ sp.percent }}%</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style>
</style>

<script>
import PercentInput from "@/components/PercentInput";
import fs from "@/services/FormatService";
import QcRuleConfig from "@/components/QcRuleConfig";
import _ from "lodash";
import Utils from "@/services/Utils";
import ConfigSelect from "@/components/ConfigSelect";
import QcReqConfig from "./ItemEditQual/QcReqConfig";

import ValConfig from "./ItemEditQual/ValConfig";
import CalConfig from "./ItemEditQual/CalConfig";

import { RATE_EXCEPTION_TYPES } from "@/services/Constants";
const { AVG_RESP_TIME } = RATE_EXCEPTION_TYPES;

export default {
	name: "ItemEditQuality",

	props: ["id", "item", "originalItem", "hasValStats", "valid", "defaultTab"],

	components: { PercentInput, QcRuleConfig, ConfigSelect, QcReqConfig, ValConfig, CalConfig },

	data() {
		return {
			fs: fs,
			revertQualReq: null,
			editingQcReq: null,
			mustPassTooltip: `
<p>
	${this.$i18n.t("ItemEdit.Quality.qualification.must_pass_explanation_1")}
</p>
<p>
	<small class="text-muted">
		${this.$i18n.t("ItemEdit.Quality.qualification.must_pass_explanation_2")}
	</small>
</p>
<p class="mb-0">
	<small class="text-muted">
		${this.$i18n.t("ItemEdit.Quality.qualification.must_pass_explanation_3")}
	</small>
</p>`,
		};
	},

	watch: {
		"item.validity_config.enabled"() {
			if (this.item.validity_config.calibration == undefined) {
				this.item.validity_config.calibration = false;
			}
			if (this.item.validity_config.interval == undefined) {
				this.item.validity_config.interval = 0;
			}
			if (this.item.validity_config.feedback == undefined) {
				this.item.validity_config.feedback = false;
			}
			if (this.item.validity_config.uses_threshold == undefined) {
				this.item.validity_config.uses_threshold = false;
			}
			if (this.item.validity_config.window == undefined) {
				this.item.validity_config.window = 1;
			}
			if (this.item.validity_config.interval_low === undefined) {
				this.item.validity_config.interval_low = 0;
			}
			if (this.item.validity_config.interval_high === undefined) {
				this.item.validity_config.interval_high = 0;
			}
			if (this.item.validity_config.threshold == undefined) {
				this.item.validity_config.threshold = 0;
			}
			if (this.item.validity_config.window_rules == undefined) {
				this.item.validity_config.window_rules = [];
			}
			if (this.item.validity_config.threshold_rule == undefined) {
				this.item.validity_config.threshold_rule = {};
			}
		},
		"item.qualification_config.enabled"() {
			if (this.item.qualification_config.pass_percent == undefined) {
				this.item.qualification_config.pass_percent = 0;
			}
			if (this.item.qualification_config.pass_by_trait == undefined) {
				this.item.qualification_config.pass_by_trait = false;
			}
			if (
				!this.item.qualification_config.must_see &&
				!this.item.qualification_config.must_pass &&
				!this.item.qualification_config.lock_on_fail &&
				!(this.item.qualification_config.requirements && this.item.qualification_config.requirements.length > 0)
			) {
				this.item.qualification_config.must_see = 1;
				this.item.qualification_config.must_pass = 1;
				this.item.qualification_config.lock_on_fail = true;
			}
		},
		"item.calibration_config.enabled"() {
			if (this.item.calibration_config.pass_percent == undefined) {
				this.item.calibration_config.pass_percent = 0;
			}
			if (this.item.calibration_config.pass_by_trait == undefined) {
				this.item.calibration_config.pass_by_trait = false;
			}
		},
		"item.exception_config.rate_enabled"() {
			const { exception_config } = this.item;
			const { rate_min_scores, rate_max_seconds } = exception_config;
			if (rate_min_scores === undefined || rate_min_scores < 1) {
				exception_config.rate_min_scores = 1;
			}
			if (rate_max_seconds === undefined || rate_max_seconds < 1) {
				exception_config.rate_max_seconds = 1;
			}
		},
		"item.exception_config.fd_enabled"() {
			const { calibration_config, exception_config } = this.item;
			if (calibration_config.pass_percent === undefined) {
				calibration_config.pass_percent = 0;
			}
			if (calibration_config.pass_by_trait === undefined) {
				calibration_config.pass_by_trait = false;
			}
			if (exception_config.fd_min_scores === undefined || exception_config.fd_min_scores < 1) {
				exception_config.fd_min_scores = 1;
			}
		},
	},

	computed: {
		rate_min_per_hour() {
			return 3600 / this.item.exception_config.rate_min_seconds;
		},
		rate_max_per_hour() {
			return 3600 / this.item.exception_config.rate_max_seconds;
		},

		showQualReqModal() {
			return !this.editingQcReq;
		},

		rateExceptionTypes() {
			return Object.values(RATE_EXCEPTION_TYPES);
		},

		minMax() {
			return [
				{ id: false, name: this.$i18n.t("fields.min") },
				{ id: true, name: this.$i18n.t("fields.max") },
			];
		},
	},

	created() {
		this.validateFDConfig();
	},

	methods: {
		getTraitName(trait_id) {
			var name = "??";
			if (this.item.rubric) {
				_.each(this.item.rubric.traits, (t) => {
					if (t.id == trait_id) {
						name = t.name;
					}
				});
			}
			return name;
		},

		validateFDConfig() {
			if (this.item.rubric) {
				var rubricTraitIds = _.map(this.item.rubric.traits, "id");
				var configTraitIds = [];
				if (this.item.exception_config.fd_traits) {
					configTraitIds = this.item.exception_config.fd_traits.map((t) => t.trait_id);
				}
				if (!this.isSame(rubricTraitIds, configTraitIds)) {
					this.setupFD();
				}
			}
		},

		setupFD() {
			this.item.exception_config.fd_traits = [];
			if (this.item.rubric) {
				_.each(this.item.rubric.traits, (trait) => {
					if (trait.separator) return;
					if (trait.is_parent) return;

					var exceptionFD = {
						trait_id: trait.id,
						variance_percent: 10,
						score_point_percents: [],
					};
					var defaultPercent = Math.floor(100 / (trait.max - trait.min + 1));
					for (var i = trait.min; i <= trait.max; i++) {
						exceptionFD.score_point_percents.push({
							score_point: i,
							percent: defaultPercent,
						});
					}
					this.item.exception_config.fd_traits.push(exceptionFD);
				});
			}
		},

		isSame(arrayOne, arrayTwo) {
			var a = arrayOne,
				b = arrayTwo;

			if (arrayOne.length <= arrayTwo.length) {
				a = arrayTwo;
				b = arrayOne;
			}
			return _.isEmpty(_.difference(a.sort(), b.sort()));
		},

		addRateException() {
			const { exception_config } = this.item;
			if (!exception_config.rate_exceptions) {
				exception_config.rate_exceptions = [];
			}
			exception_config.rate_exceptions.push({
				id: Utils.generateUUID(),
				type: AVG_RESP_TIME.id,
				max: false,
				window: 100,
				count: 1,
				lockout: false,
			});
		},

		removeRateException(rateExc) {
			this.item.exception_config.rate_exceptions = this.item.exception_config.rate_exceptions.filter(
				({ id }) => id !== rateExc.id
			);
		},
	},
};
</script>
