<template>
	<div class="flex d-flex flex-column w-100 chat-input-container" ref="container">
		<div v-show="focused" class="flex-row align-items-center mb-1" :class="{ 'd-flex': focused }">
			<div id="snote-toolbar" class="flex"></div>
			<button class="btn btn-subtle" style="margin-right: 46px" @click="hideToolbar">
				<i class="fas fa-times" />
			</button>
		</div>

		<template v-if="canChat">
			<div class="d-flex flex-row align-items-center">
				<RichText
					:text.sync="text"
					:editable.sync="htmlContents"
					:initial="''"
					:refresh="refresh"
					class="flex form-control-margin-fix"
					:options="richTextOptions"
					@keydown="keydown"
					@inputBlur="inputBlur"
					@inputFocus="inputFocus"
					@inputEnter="inputEnter"
				/>
				<button class="ml-2 btn btn-rounded btn-icon btn-md btn-primary" @click="submitCurrentContents">
					<i class="fas fa-paper-plane" />
				</button>
			</div>

			<DropZone
				class="chat-drop-zone d-flex flex-row"
				:uploading="uploadingFiles"
				:uploadProgress="uploadProgress"
				@fileDropped="fileDropped"
				style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; overflow: hidden"
			>
				<template #drop>
					<h4 class="text-muted mb-0">Drop an image here...</h4>
				</template>
			</DropZone>
		</template>
		<div v-else class="text-center py-1 text-extra-muted">You're not allowed to chat</div>
	</div>
</template>

<style scoped>
.chat-input-container {
	padding: 0.25rem;
	position: relative;
}

.chat-drop-zone {
	background-color: rgba(128 200 200 / 50%);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.dropping {
	background-color: lightgrey;
}
</style>

<script>
import Utils from "@/services/Utils";
import RichText from "@/components/RichText";
import DropZone from "@/components/DropZone";

import ImageService from "@/services/ImageService";

export default {
	name: "ChatInput",
	props: {
		placeholder: {
			type: String,
			default: "",
		},
		canChat: true,
	},
	components: { RichText, DropZone },
	data() {
		return {
			text: "",
			htmlContents: null,
			refresh: 0,
			focused: false,

			uploadingFiles: false,
			uploadProgress: 0,

			richTextOptions: {
				toolbarContainer: "#snote-toolbar",
				disableResizeEditor: true,
				toolbar: [
					["insert", ["picture"]],
					["style", ["bold", "italic", "underline", "strikethrough"]],
				],
				popover: {
					image: [
						["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
						["float", ["floatLeft", "floatRight", "floatNone"]],
						["remove", ["removeMedia"]],
					],
				},
				height: "",
				placeholder: this.placeholder || this.$i18n.t("rich_text.placeholder"),
				disableDragAndDrop: true,
			},
		};
	},
	computed: {},
	mounted() {
		let el = this.$refs.container;
		this.debug("editor", el.querySelector(".note-editable"));
	},
	watch: {},
	methods: {
		onInput(e) {
			console.log("ON INPUT");
			this.value = e.target.innerText;
			this.$emit("update:text", e.target.innerText);
		},

		keydown(e) {
			this.debug("keydown", e, e.keyCode);
			e = e || window.event;
			if (e.keyCode == 13 && !e.shiftKey) {
			}
		},

		submitCurrentContents() {
			let contents = this.htmlContents.cloneNode(true);
			Utils.trimHtml(contents);
			this.$emit("submit", contents.innerHTML);
			this.refresh++;
			this.focused = false;
		},

		inputBlur() {
			// Don't hide on blur, we'll do that later
		},

		inputFocus() {
			this.focused = true;
			this.debug("Focused", this.focused);
		},

		hideToolbar() {
			this.focused = false;
			this.debug("Hide toolbar, Focused", this.focused);
		},

		inputEnter(e) {
			if (!e.shiftKey) {
				e.preventDefault();
				this.submitCurrentContents();
			}
		},

		fileDropped(event) {
			console.log("___FILE DROP___");
			console.log(event.dataTransfer.files);
			_.each(event.dataTransfer.files, (file) => {
				this.debug("Handle file", file);
			});

			let onUploadProgress = (progress) => {
				this.debug("Progress", progress);
			};

			this.uploadingFiles = true;
			ImageService.addImage(event.dataTransfer.files[0], onUploadProgress)
				.then((resp) => {
					this.debug("Got image URL", resp.data);
					this.s3ImageURL = resp.data;
					this.uploadingFiles = false;
				})
				.catch((err) => {
					this.logError(err);
					Notie.error("Failed to upload image", err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
