import axios from "axios";
import Notie from "@/services/NotieService";

/**
 * @description
 * Service for anything scoring workflow related.
 */
export default {
	/**
	 * @description
	 * Returns a promise of sections this user is assigned to.
	 */
	mySections(sortMode) {
		let params = {
			sort: sortMode,
		};
		return axios.get("/v1/sections", { params: params });
	},

	/**
	 * @description
	 * Returns a promise of statistics for a user by section
	 */
	sectionStats() {
		return axios.get("/v1/stat/sections");
	},

	myAssignedResponses(query, ctx) {
		let body = _.extend(query, ctx);
		return axios.post(`/v1/my/assigned_responses`, body);
	},

	myAssignedResponsesCount(sectionID, itemID, scoreType) {
		return axios.get(`/v1/my/count/assigned_responses/${sectionID}/${itemID}/${scoreType}`);
	},

	getItem(id) {
		return axios.get(`/v1/item/${id}`);
	},

	getBackreadingStats(
		section_id,
		item_id,
		team_ids,
		from,
		until,
		score_from,
		score_until,
		user_id,
		target,
		below_target,
		score_type,
		trait_agreement,
		exported,
		trait_scores
	) {
		return axios.post(`/v1/stat/backreading`, {
			section_id,
			item_id,
			team_ids,
			from,
			until,
			score_from,
			score_until,
			user_id,
			target,
			below_target,
			score_type,
			trait_agreement,
			exported,
			trait_scores,
		});
	},

	unlockResponse(resp) {
		return axios.post(`/v1/admin/unlock/${resp.id}`);
	},

	selfUnlockResponse(respID) {
		return axios.post(`/v1/admin/self_unlock/${respID}`);
	},

	canScore(user, resp) {
		if (resp.locked) {
			return false;
		}

		if (resp.protected) {
			return false;
		}

		//Unscored, rel, res, review
		if (
			resp.state == 1 ||
			resp.state == 2 ||
			(resp.state == 3 && user.role.has_resolution) ||
			(resp.state == 4 && user.role.has_adjudication) ||
			resp.state == 30
		) {
			return true;
		}

		//Complete with no BR
		if (resp.state == 10 && !resp.has_backread && user.role.page_backreading) {
			return true;
		}

		return false;
	},

	canAssignedScore(user, resp, item) {
		if (item.deliver_either_order) {
			if (
				(resp.state == 1 || resp.state == 2) &&
				(resp.first_scorer_id == user.id || resp.second_scorer_id == user.id)
			) {
				return true;
			}
		}

		console.log("can assigned score", user, resp);
		if (resp.state == 1 && resp.first_scorer_id == user.id) {
			return true;
		}
		if (resp.state == 2 && resp.second_scorer_id == user.id) {
			return true;
		}
		if (resp.state == 3 && resp.res_scorer_id == user.id) {
			return true;
		}

		return false;
	},

	auditScore(resp, router, keep, forcedRepeatScore) {
		const task = {
			1: "scoring",
			2: "scoring",
			3: "resolution",
			4: "adjudication",
			10: "backread",
			30: "review",
		}[resp.state];

		if (!task) {
			Notie.error("Cannot score a response while it is withheld");
			return;
		}
		return this.auditQueueUp(resp.id, forcedRepeatScore)
			.then((data) => {
				var url = `/${task}/${resp.section_id}/${resp.item_id}`;
				var query = {
					return: true,
					skipqc: true,
					resp: resp.id,
					isAuditing: true,
				};
				if (keep) {
					query.keep = true;
				}
				if (forcedRepeatScore) {
					query.r = true;
				}
				//setTimeout(function () {
				router.push({ path: url, query: query });
				//}, 200)
			})
			.catch((data) => {
				console.log(data);
				Notie.error("Failed to audit response", data);
			});
	},

	rescore(resp, score, router) {
		const query = {
			return: true,
			skipqc: true,
			resp: resp.id,
			isAuditing: true,
		};
		const path = `/rescore/auditing/${resp.section_id}/${resp.item_id}/${resp.id}/${score.id}`;
		router.push({ path, query });
	},

	auditQueueUp(id) {
		return axios.get(`/v1/admin/auditqueue/${id}`);
	},

	resetScore(responseID, scoreID) {
		return axios.delete(`/v1/reset/${responseID}/${scoreID}`);
	},

	escalate(escalateRequest) {
		return axios.post("/v1/admin/escalate", escalateRequest);
	},

	flagResponse(flagRequest) {
		return axios.post("/v1/flag", flagRequest);
	},

	deleteFlag(responseID, flagID) {
		return axios.delete(`/v1/flag/${responseID}/${flagID}`);
	},

	updateFlag(responseID, flag) {
		return axios.post(`/v1/flag/update/${responseID}/${flag.id}`, flag);
	},

	getStateAfterRelease(responseID) {
		return axios.get(`/v1/flag/release_state/${responseID}`);
	},

	releaseFromFlagged(responseID) {
		return axios.post(`/v1/flag/release/${responseID}`);
	},

	releaseToRewithhold(responseID) {
		return axios.post(`/v1/flag/release/${responseID}`, null, { params: { rewithhold: true } });
	},

	markReviewed(responseID, flagID, val) {
		let ctx = { reviewed: val };
		return axios.post(`/v1/flag/review/${responseID}/${flagID}`, ctx);
	},

	loadAlertCounts(sectionID, itemID) {
		return axios.get(`/v1/flag_counts/${sectionID}/${itemID}`);
	},

	getIndTargets(sectionID, itemID) {
		return axios.get(`/v1/backreading/ind_targets/${sectionID}/${itemID}`);
	},

	setIndTarget(sectionID, itemID, userID, percent) {
		let target = {
			user_id: userID,
			percent: percent,
		};
		return axios.post(`/v1/backreading/ind_targets/${sectionID}/${itemID}`, target);
	},

	getRescores(sectionID, itemID) {
		return axios.get(`/v1/rescore_requests/${sectionID}/${itemID}`);
	},

	deleteRescore(rrID) {
		return axios.delete(`/v1/rescore_request/${rrID}`);
	},
};
