import _ from "lodash";
import axios from "axios";

export default {
	//The amount of time to display the warning countdown before requiring password again
	warn_period: 20000, //20 seconds
	//The amount of inaccuracy allowable between the warning countdown starting vs. getting timeout from server
	grace_period: 1000, //1 second
	//The (predicted) amount of time after making a request before the server will sweep the session
	logout_after: 600000, //10 minutes
	callbacks: {},
	server_timeout: 0,
	last_touch: null,

	updateTimeout(milliseconds = this.logout_after - this.warn_period) {
		this.clearTimeout();
		this.last_touch = new Date();
		this.timeout = setTimeout(() => {
			this.doTimeout();
		}, milliseconds);
	},

	doTimeout() {
		console.log("timeout occurred, callbacks", this.callbacks);
		axios
			.get("/v1/timeout", { headers: { "X-No-Timeout": true } })
			.then(resp => {
				this.server_timeout = resp.data.timeout;
				if (!isNaN(this.server_timeout)) {
					if (
						this.server_timeout - this.warn_period <
						this.grace_period
					) {
						_.each(this.callbacks, (callback, name) => {
							callback();
						});
					} else {
						this.updateTimeout(
							this.server_timeout - this.warn_period
						);
					}
				}
			})
			.catch(err => {
				console.error("Failed to get timeout");
				console.log(err);
			});
	},

	clearTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	},

	getServerTimeout() {
		return this.server_timeout;
	},

	getTimeout() {
		if (this.last_touch) {
			let elapsed = new Date() - this.last_touch;
			return this.logout_after - elapsed;
		} else {
			return logout_after;
		}
	},

	getWarnPeriod() {
		return this.warn_period;
	},

	registerCallback(name, func) {
		this.callbacks[name] = func;
	},

	clearCallback(name) {
		delete this.callbacks[name];
	},

	clearCallbacks() {
		this.callbacks = {};
	},

	clear() {
		this.clearCallbacks();
		this.clearTimeout();
	}
};
