<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportDaily.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportQuotas.quotas") }}
				</div>
			</div>
		</template>

		<div class="d-flex flex-column flex scroll-x scroll-y">
			<div class="row mx-0 flex-fixed px-3 pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.project") }}</label>
						<config-select required :options="projects" v-model="selectedProject"></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.section") }}</label>
						<config-select :options="sections" v-model="selectedSection" :mustBeFilled="true">
						</config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.item") }}</label>
						<config-select
							:options="items"
							v-model="selectedItem"
							:mustBeFilled="true"
							sortBy="section_item_sequence"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("ReportDaily.team") }}</label>
						<config-select :options="teams" :nullOption="true" v-model="selectedTeam"> </config-select>
					</div>
				</div>
				<div class="col-12 col-lg-8 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-center form-group">
						<div class="py-0 ml-1">
							<button
								@click="getQuotaStats"
								:disabled="!canRun()"
								class="btn btn-sm btn-secondary btn-block flex-fixed"
								:class="{ 'theme-accent': canRun() }"
								v-b-tooltip
								:title="$t('tooltip.run_report')"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline">&nbsp;{{ $t("ReportQuotas.run_report") }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="quotaStats && quotaStats.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div class="flex">
					<div class="card border-0 box-shadow-3">
						<report-table
							class="condensed-table mb-0"
							:fields="fields"
							:content="quotaStats"
							totalsClass="theme-lighten-3 text-on-color"
						>
							<template #cell(scorer)="data">
								<span v-if="!data.item.user">{{ data.item.scorer }}</span>
								<span
									v-if="data.item.user"
									v-tippy
									:title="data.item.user.scorer_id != '' ? data.item.user.full_name : null"
								>
									{{ fs.scorerID(data.item.user) }}
								</span>
							</template>
							<template #cell(normal_hourly)="data">
								<span v-if="data.item.normal_hourly_quota">
									{{ data.item.normal_hourly }} / {{ data.item.normal_hourly_quota }}
								</span>
								<span v-if="!data.item.normal_hourly_quota" class="text-extra-muted">
									{{ $t("ReportQuotas.no_quota") }}
								</span>
							</template>
							<template #cell(normal_daily)="data">
								<span v-if="data.item.normal_daily_quota">
									{{ data.item.normal_daily }} / {{ data.item.normal_daily_quota }}
								</span>
								<span v-if="!data.item.normal_daily_quota" class="text-extra-muted">
									{{ $t("ReportQuotas.no_quota") }}
								</span>
							</template>
							<template #cell(normal_overall)="data">
								<span v-if="data.item.normal_overall_quota">
									{{ data.item.normal_overall }} / {{ data.item.normal_overall_quota }}
								</span>
								<span v-if="!data.item.normal_overall_quota" class="text-extra-muted">
									{{ $t("ReportQuotas.no_quota") }}
								</span>
							</template>
							<template #cell(res_hourly)="data">
								<span v-if="data.item.res_hourly_quota">
									{{ data.item.res_hourly }} / {{ data.item.res_hourly_quota }}
								</span>
								<span v-if="!data.item.res_hourly_quota" class="text-extra-muted">
									{{ $t("ReportQuotas.no_quota") }}
								</span>
							</template>
							<template #cell(res_daily)="data">
								<span v-if="data.item.res_daily_quota">
									{{ data.item.res_daily }} / {{ data.item.res_daily_quota }}
								</span>
								<span v-if="!data.item.res_daily_quota" class="text-extra-muted">
									{{ $t("ReportQuotas.no_quota") }}
								</span>
							</template>
							<template #cell(res_overall)="data">
								<span v-if="data.item.res_overall_quota">
									{{ data.item.res_overall }} / {{ data.item.res_overall_quota }}
								</span>
								<span v-if="!data.item.res_overall_quota" class="text-extra-muted">
									{{ $t("ReportQuotas.no_quota") }}
								</span>
							</template>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="quotaStats && quotaStats.length == 0" class="d-flex flex-row justify-content-center">
				<h3 class="text-muted">{{ $t("ReportQuotas.report_returned_no_results") }}</h3>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ar {
	text-align: center;
}

.d-long {
	display: none;
}

.d-abr {
	display: table-cell;
}

@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}

	.d-abr {
		display: none;
	}
}

.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}

.t-margin {
	width: 15px;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import UserService from "@/services/UserService";
import ConfigService from "@/services/ConfigService";
import FileService from "@/services/FileService";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import moment from "moment";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import ReportTable from "@/components/ReportTable";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "ReportQuotas",

	props: ["user"],

	components: {
		ConfigDate,
		ConfigSelect,
		ConfigUserSearch,
		ReportTable,
		UserTimezone,
	},

	data() {
		return {
			fs: fs,
			quotaStats: null,

			fields: [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
				},
				{
					key: "scorer",
					label: this.$i18n.t("fields.scorer"),
					tdClass: "table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
				{
					key: "normal_hourly",
					label: this.$i18n.t("ReportQuotas.hourly"),
					tdClass: "text-center table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
				{
					key: "normal_daily",
					label: this.$i18n.t("ReportQuotas.daily"),
					tdClass: "text-center table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
				{
					key: "normal_overall",
					label: this.$i18n.t("ReportQuotas.overall"),
					tdClass: "text-center table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
				{
					key: "res_hourly",
					label: this.$i18n.t("ReportQuotas.res_hourly"),
					tdClass: "text-center table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
				{
					key: "res_daily",
					label: this.$i18n.t("ReportQuotas.res_daily"),
					tdClass: "text-center table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
				{
					key: "res_overall",
					label: this.$i18n.t("ReportQuotas.res_overall"),
					tdClass: "text-center table-border-right v-mid",
					thClass: "text-center table-border-right v-mid",
					sortable: true,
				},
			],

			projects: [],
			selectedProject: null,

			teams: [],
			selectedTeam: null,

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			running: false,
			loading: true,
		};
	},

	created() {
		this.storagePrefix = "quotas.";

		let teamCall = ConfigService.listTeams;
		if (this.user.role.limit_teams) {
			teamCall = ConfigService.listUserTeams;
		}

		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		Promise.all([ConfigService.listProjectsShallowPreloaded(ctx), teamCall()])
			.then((resps) => {
				//resps[0] - projects
				this.projects = resps[0].data;
				this.selectedProject = store.bindProject(this, this.projects);
				this.sections = this.selectedProject.sections;
				this.selectedSection = store.bindSection(this, this.sections);
				this.selectedItem = store.bindItem(this, this.selectedSection);

				//resps[1] - teams
				this.teams = resps[1].data.teams;
				if (this.user.role.limit_teams) {
					this.teams = resps[1].data;
				}
				this.selectedTeam = store.bindTeam(this, this.teams, true);
				this.loading = false;
			})
			.catch((err) => {
				notie.error(this.$i18n.t("notie.load_config_options_fail"), err);
				this.loading = false;
			});
	},

	watch: {
		selectedProject() {
			if (this.selectedProject && this.selectedProject.sections) {
				this.sections = this.selectedProject.sections;
			} else {
				this.sections = [];
			}
		},
		selectedSection() {
			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
			} else {
				this.items = [];
			}
		},
	},

	methods: {
		getQuotaStats() {
			if (this.running) {
				return;
			}
			var proj = this.selectedProject;
			var team = this.selectedTeam;
			var sec = this.selectedSection;
			var item = this.selectedItem;

			//Just return if we don't have required fields
			if (!this.canRun()) {
				this.data = [];
				return;
			}

			var queryJson = {
				section_id: this.selectedSection.id,
				item_id: this.selectedItem.id,
			};

			if (this.selectedTeam && this.selectedTeam.id) {
				queryJson.team_id = this.selectedTeam.id;
			}

			this.running = true;
			ReportingService.getQuotaStats(queryJson)
				.then((resp) => {
					this.running = false;
					this.quotaStats = resp.data;
				})
				.catch((err) => {
					this.running = false;
					console.log(err);
					notie.error("Failed to load quota stats", err);
				});
		},

		canRun() {
			return this.selectedProject && this.selectedSection && this.selectedItem;
		},
	},
};
</script>
