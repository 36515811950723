<template>
	<div class="tab-pane" :class="{active: defaultTab, show: defaultTab}" :id="id">
		<div class="p-4 b-b _600 nav-title">{{$t('ItemEdit.FlagCodes.title')}}</div>
		<div class="p-4 col-12">
			<div class="checkbox form-group">
				<label class="md-check">
					<input
						:checked="allActive"
						type="checkbox"
						@click="toggleAllAlerts()"
						:disabled="item.isScored && allActive"
					/>
					<i class="theme-accent"></i> {{$t('ItemEdit.FlagCodes.enable_all')}}
				</label>
			</div>
			<hr class="mt-0 mb-3" />

			<div class="checkbox form-group" v-for="alert in item.avail_alerts" :key="alert.alert_id">
				<label class="md-check">
					<input
						:checked="itemHasAlert(alert)"
						type="checkbox"
						@click="toggleAlert(alert)"
						:disabled="item.isScored && itemHasAlert(alert)"
					/>
					<i class="theme-accent"></i>
					{{alert.code | replaceIfExists(alert.description)}}
				</label>
			</div>
		</div>
	</div>
</template>

<style>
</style>

<script>
import notie from "@/services/NotieService";

export default {
	name: "ItemEditFlags",

	props: ["id", "item", "defaultTab"],

	data() {
		return {};
	},

	computed: {
		allActive() {
			let all = _.every(this.item.avail_alerts, alert => {
				return this.itemHasAlert(alert);
			});
			console.log("ALL ACTIVE?", all);
			return all;
		}
	},

	methods: {
		itemHasAlert(alert) {
			return _.includes(this.item.alert_ids, alert.alert_id);
		},

		//Only add by re-creating the list because that keeps things in order
		buildItemAlerts(enableAll) {
			let newAlerts = [];
			let newAlertIDs = [];
			_.each(this.item.avail_alerts, alert => {
				if (
					enableAll ||
					_.includes(this.item.alert_ids, alert.alert_id)
				) {
					newAlerts.push(alert);
					newAlertIDs.push(alert.alert_id);
				}
			});

			this.item.alerts = newAlerts;
			this.item.alert_ids = newAlertIDs;
		},

		addItemAlert(alert) {
			if (!_.includes(this.item.alert_ids, alert.alert_id)) {
				this.item.alert_ids.push(alert.alert_id);
				this.buildItemAlerts();
			}
		},

		removeItemAlert(alert) {
			this.item.alerts = _.reject(this.item.alerts, {
				alert_id: alert.alert_id
			});
			this.item.alert_ids = _.without(
				this.item.alert_ids,
				alert.alert_id
			);
		},

		toggleAlert(alert) {
			if (this.itemHasAlert(alert)) {
				this.removeItemAlert(alert);
			} else {
				this.addItemAlert(alert);
			}
		},

		toggleAllAlerts() {
			if (this.allActive) {
				this.item.alerts = [];
				this.item.alert_ids = [];
			} else {
				this.buildItemAlerts(true);
			}
		}
	}
};
</script>
