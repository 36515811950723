<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("TenantSetup.title") }}</div>

				<!-- Create new -->
				<div class="py-0">
					<a href="#/tenants/new" class="btn btn-sm theme-accent btn-block">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("TenantSetup.new_tenant") }}</span>
					</a>
				</div>

				<!-- Search field -->
				<div>
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('TenantSetup.search_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>
		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="table_fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Projects count -->
					<template #cell(projects)="data">
						<span
							v-if="data.item.projects && data.item.projects.length > 0"
							v-tippy
							:title="listNames(data.item.projects, $i18n.t('tooltip.projects'))"
						>
							<i class="fas fa-box-open faw"></i>
							{{ (data.item.projects && data.item.projects.length) || 0 }}
						</span>
						<span v-if="!(data.item.projects && data.item.projects.length > 0)" class="text-muted">{{
							$t("data_description.none")
						}}</span>
					</template>

					<!-- Teams count -->
					<template #cell(teams)="data">
						<span
							v-if="data.item.teams && data.item.teams.length > 0"
							v-tippy
							:title="listNames(data.item.teams, $i18n.t('tooltip.teams'))"
						>
							<i class="fas fa-users faw"></i>
							{{ (data.item.teams && data.item.teams.length) || 0 }}
						</span>
						<span v-if="!(data.item.teams && data.item.teams.length > 0)" class="text-muted">{{
							$t("data_description.none")
						}}</span>
					</template>

					<!-- Actions -->
					<template #cell(actions)="data">
						<nobr>
							<div class="mx-auto w60">
								<a
									:href="`#/tenants/${data.item.id}`"
									class="btn btn-sm btn-icon btn-rounded hover-darken theme-accent m-0"
									v-tippy
									:title="$t('tooltip.edit')"
								>
									<i class="far fa-edit"></i>
								</a>
								<button
									v-if="!data.item.inProgress"
									@click="deleting = data.item"
									class="btn btn-sm btn-icon btn-rounded hover-darken red text-white m-0"
									v-tippy
									:title="$t('tooltip.delete')"
								>
									<i class="far fa-trash"></i>
								</button>
								<button
									v-if="data.item.inProgress"
									class="btn btn-sm btn-icon btn-rounded m-0"
									v-tippy
									title="Removing..."
								>
									<loading type="icon" />
								</button>
							</div>
						</nobr>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>

		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("TenantSetup.delete_tenant_modal.title") }}</template>
			<div class="row p-4">
				{{ $t("TenantSetup.delete_tenant_modal.confirm_delete") }}&nbsp;
				<strong class="text-theme">{{ deleting.name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">{{ $t("buttons.cancel") }}</button>
				<button class="btn danger btn-flat" @click="removeClient(deleting)">{{ $t("buttons.delete") }}</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w60 {
	width: 60px;
}
.w40 {
	width: 40px;
}
.w30 {
	width: 30px;
}
</style>

<script>
//UI Components

//Libraries

//Services
import TenantService from "@/services/TenantService";
import AuthService from "@/services/AuthService";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import sanitizeHtml from "sanitize-html";

export default {
	name: "TenantSetup",

	props: ["user"],

	data() {
		return {
			table_fields: [
				{ key: "name", label: this.$i18n.t("fields.name"), sortable: true },
				{ key: "projects", label: this.$i18n.t("fields.project"), sortable: true },
				{ key: "teams", label: this.$i18n.t("fields.teams"), sortable: true },
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				},
			],
			currentPage: 1,
			perPage: 16,
			totalRows: 0,
			filter: "",
			sortBy: null,
			sortDesc: false,
			loading: true,

			refreshNavbar: false,

			deleting: false,
		};
	},

	components: {},

	created() {
		this.sortBy = store.get(this, "clients.sortBy");
		this.sortDesc = store.getDefault(this, "clients.sortDesc", false);
		this.showInactive = store.getDefault(this, "clients.showInactive", true);
	},

	watch: {
		sortBy() {
			store.set(this, "clients.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "clients.sortDesc", this.sortDesc);
		},
		showInactive() {
			store.set(this, "clients.showInactive", this.showInactive);
			this.refreshTable();
		},
	},

	methods: {
		provider(ctx) {
			return TenantService.listClients(ctx)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					let cs = resp.data.clients;

					let dir = (ctx.sortDesc && "desc") || "asc";
					if (ctx.sortBy == "projects") {
						cs = _.orderBy(
							cs,
							[
								(c) => {
									return c.projects ? c.projects.length : 0;
								},
							],
							[dir]
						);
					} else if (ctx.sortBy == "teams") {
						cs = _.orderBy(
							cs,
							[
								(c) => {
									return c.teams ? c.teams.length : 0;
								},
							],
							[dir]
						);
					}
					this.loading = false;
					return cs;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_sections_fail"), err);
					this.loading = false;
					return [];
				});
		},

		removeClient(client) {
			AuthService.queueRefresh();
			client.inProgress = true;
			TenantService.removeClient(client.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.tenant_deleted"));
					this.refreshNavbar = new Date();
					this.refreshTable();
					client.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.tenant_delete_fail"), err);
					client.inProgress = false;
					this.$forceUpdate();
				});
			this.deleting = false;
		},

		listNames(objects, title) {
			if (objects && objects.length > 0) {
				let nameList = `${title}:<br /><br /><ul class='pl-4 pr-3'>`;
				_.each(objects, (object) => {
					nameList += `<li style="text-align: left;">${sanitizeHtml(object.name)}</li>`;
				});

				return nameList + "</ul>";
			} else {
				return "";
			}
		},

		refreshTable() {
			this.$refs.table.refresh();
		},
	},
};
</script>
