//Driver for audit scoring any Reads

import axios from "axios";

export default {
	icon: "check",
	name: "QC Score",
	showScores() {
		return false;
	},
	sidebarType() {
		return "qc_true_score";
	},
	preloadTrueScore: true,

	//Driver methods
	getNext(ctx) {
		var promise = axios.get(`/v1/admin/qc_response/${ctx.qc_response_id}`);
		promise.then(function(resp) {
			console.log("QCTrueScore GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	},
	submitScore(score, response) {
		response.true_score = score;
		response.true_score.qc_response_id = response.id;
		response.true_score.client_id = response.client_id;
		response.true_score.section_id = response.section_id;
		response.true_score.item_id = response.item_id;
		var promise = axios.post(`/v1/admin/qc_response`, response);

		return promise;
	}
};
