<template>
    <b-modal :title="$t('RFResponseReport.final_annotation')" size="lg" body-class="p-0" visible hide-footer
        hide-header-close no-close-on-esc no-close-on-backdrop>
        <div class="modal-body modal-v-limit d-flex flex-column p-0">
            <div class="pt-3 px-3 pb-0">
                <RichText :initial="initFeedback" :text.sync="feedback" :refresh="feedbackRefresh"
                    class="mb-3 border" />
            </div>
            <hr class="w-100 my-0" />
            <div class="div-scroll px-3">
                <media :response="response" :chan="chan" :noMargin="true" />
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-flat btn-primary mr-auto" @click="close">
                {{ $t("buttons.cancel") }}
            </button>
            <button class="btn btn-flat btn-danger" @click="applyFeedback(response, ' ')">
                {{ $t("buttons.none") }}
            </button>
            <button @click="applyFeedback(response, feedback)" class="btn btn-flat theme" data-dismiss="modal">
                {{ $t("buttons.save") }}
            </button>
        </div>

    </b-modal>
</template>

<script>
import Vue from "vue";

import Notie from "@/services/NotieService";
import QCService from "@/services/QCService";

import RichText from "@/components/RichText";
import Media from "@/components/viewer/Media";

export default {
    name: "FinalAnnotationModal",

    components: {
        RichText,
        Media
    },

    props: {
        response: Object
    },

    data() {
        const { feedback } = this.response;
        return {
            feedback,
            initFeedback: feedback,
            feedbackRefresh: new Date(),
            chan: new Vue(),
        }
    },

    methods: {
        applyFeedback(response, feedback) {
            if (!response.true_score) {
                Notie.error(this.$i18n.t("notie.no_annotation_without_true_score"));
            }

            this.submitting = true;
            QCService.submitTrueScore(response.true_score, response.id, feedback)
                .then((resp) => {
                    Notie.info(this.$i18n.t("notie.annotation_applied"));
                    if (feedback != null) {
                        response.feedback = feedback;
                    }
                    this.$forceUpdate();
                    this.submitting = false;
                    this.close();
                })
                .catch((err) => {
                    Notie.error(this.$i18n.t("notie.apply_annotation_fail"), err);
                    this.submitting = false;
                });
        },

        close() {
            this.$emit("close");
        }
    },
}
</script>
