<template>
	<div class="element-box card shadow">
		<div class="d-flex flex-row align-items-center">
			<div class="pl-2 pr-4 icon-container text-muted">
				<div class="drag-handle">
					<i class="far fa-sort text-muted"></i>
				</div>
				<i :class="icon" class="fa fa-lg"></i>
			</div>
			<div class="flex row">
				<div class="col-8 form-group">
					<label class="sequence-offset">{{ element.sequence }}.</label>
					<label>{{ $t("fields.name") }}</label>
					<input v-model="element.name" type="text" class="form-control" />
				</div>
				<div class="col-4 form-group">
					<label>{{ $t("fields.type") }}</label>
					<v-select
						:options="elementTypes"
						:reduce="({ id }) => id"
						v-model="element.type"
						label="name"
						key="id"
						@input="typeChanged"
					/>
				</div>
				<div class="col-12">
					<div class="row">
						<!-- TODO: component could be cleaner if each element type was own component -->
						<details-scorm
							v-if="element.type === SCORM.id"
							:course="course"
							:element="element"
							:scormLocations="scormLocations"
							class="col-10"
						/>
						<details-resource
							v-if="element.type === RESOURCE.id"
							:course="course"
							:element="element"
							class="col-10"
						/>
						<details-qualification
							v-if="element.type === QUALIFICATION.id"
							:course="course"
							:element="element"
							:sections="sections"
							class="col-10"
						/>
						<details-qc-set
							v-if="element.type === PRACTICE.id"
							:course="course"
							:element="element"
							:setLocation="element.practice_location"
							:sections="sections"
							:qcType="QC_TYPES.PRACTICE.id"
							class="col-10"
						/>
						<details-qc-set
							v-if="element.type === EXEMPLAR.id"
							:course="course"
							:element="element"
							:setLocation="element.exemplar_location"
							:sections="sections"
							:qcType="QC_TYPES.EXEMPLAR.id"
							class="col-10"
						/>
						<div class="col-2 mt-2 d-flex flex-row justify-content-end align-items-end">
							<button
								class="mb-2 btn btn-sm btn-icon btn-rounded red text-white"
								v-tippy
								:title="$t('TrainingEdit.Details.delete_element')"
								@click="deleteElement()"
							>
								<i data-v-daef840c="" class="fas fa-trash"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.drag-handle {
	position: absolute;
	top: 4px;
	left: 12px;
}

.sequence-offset {
	position: absolute;
	right: calc(100% - 6px);
}

.icon-container {
	text-align: center;
}

.icon-container > i {
	width: 24px;
}
</style>

<script>
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";

import DetailsResource from "./Details/DetailsResource";
import DetailsScorm from "./Details/DetailsScorm";
import DetailsQualification from "./Details/DetailsQualification";
import DetailsQcSet from "./Details/DetailsQcSet";

import { TRAINING_ELEMENT_TYPES, QC_TYPES } from "@/services/Constants";
const { SCORM, RESOURCE, QUALIFICATION, PRACTICE, EXEMPLAR } = TRAINING_ELEMENT_TYPES;

export default {
	name: "TrainingDetailsElement",

	components: {
		DetailsScorm,
		DetailsResource,
		DetailsQualification,
		DetailsQcSet,
	},

	props: {
		course: Object,
		element: Object,
		scormLocations: Array,
		sections: Array,
	},

	data() {
		return {
			TRAINING_ELEMENT_TYPES,
			QC_TYPES,

			SCORM,
			RESOURCE,
			QUALIFICATION,
			PRACTICE,
			EXEMPLAR,

			Utils,
		};
	},

	computed: {
		icon() {
			return fs.trainingElementTypeIcon(this.element.type);
		},

		elementTypes() {
			return Object.values(TRAINING_ELEMENT_TYPES);
		},
	},

	methods: {
		deleteElement() {
			this.$emit("delete");
		},

		typeChanged() {
			const { element } = this;
			if (element.type === QUALIFICATION.id && !element.qual_location) {
				element.qual_location = {
					section_id: null,
					item_id: null,
				};
			}
			if (element.type === SCORM.id && !element.scorm) {
				element.scorm = {
					s3_dir: null,
				};
			}
			if (element.type === RESOURCE.id && !element.resource) {
				element.resource = {
					path: null,
					mime_type: null,
				};
			}
			if (element.type === PRACTICE.id && !element.practice_location) {
				element.practice_location = {
					section_id: null,
					item_id: null,
					set_id: null,
				};
			}

			if (element.type === EXEMPLAR.id && !element.exemplar_location) {
				element.exemplar_location = {
					section_id: null,
					item_id: null,
					set_id: null,
				};
			}
			element.setMeta("showDetails", false);
		},
	},
};
</script>
