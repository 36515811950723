<template>
	<div class="p-5">
		<iframe ref="irisFrame" :src="iFrameUrl" width="100%" height="650"></iframe>
	</div>
</template>

<script>
require("@/services/Iris/jquery.min");
require("@/services/Iris/util_xdm");

import { createIrisClient } from "@/services/Iris/ItemService";
import ResponseMediaService from "@/services/ResponseMediaService";
import notie from "@/services/NotieService";

const VENDER_ID = "C76CB481-4E38-4904-B6D2-69D697700474";

export default {
	name: "IrisMedia",

	data() {
		return {
			iFrameUrl: "",
		};
	},

	props: {
		response: Object,
	},

	async mounted() {
		const { response } = this;
		try {
			const urlResp = await ResponseMediaService.getCambiumUrl();
			this.iFrameUrl = urlResp.data;
			if (!window.iris) {
				// only one iris client can be initialized on a window, otherwise only one iris response will render
				window.iris = createIrisClient();
			}

			const {
				data: { jwt, url },
			} = await ResponseMediaService.getIrisToken(response.id, response.qc_type != null);
			this.iFrameUrl = url;

			const client = await window.iris.create(this.$refs.irisFrame);
			if (response.meta.advanced_format && response.meta.advanced_format == 1) {
				await client.loadContent(VENDER_ID, jwt);
			} else {
				await client.loadToken(VENDER_ID, jwt);
			}
		} catch (err) {
			notie.error("Failed to get IRIS Response", err);
		}
	},
};
</script>
