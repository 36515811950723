<template>
	<div v-if="page && page.data && page.data.responses">
		<div
			v-for="(resp, i) in page.data.responses"
			v-html="resp.val"
			:key="i"
			class="essay"
			:class="{ 'essay-nopad': fit_to_content || noPad }"
		>
			<span class="label text-theme" v-if="page.label">{{ page.label }}</span>
			<p class="respText">
				<span v-html="page.text"></span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "CogniaMedia",
	props: ["item", "page", "response"],
	data() {
		return {
			cogniaConfig: {
				basePath: "https://nm-uat.cognia.org/authoring",
				js: [
					// "js/jquery.js",
					// "Assets/default/Js/underscore-min.js",
					// "Assets/default/Js/tinymce4/js/tinymce/plugins/mathquill/js/mathquill.min.js",
					// "Assets/default/Js/raphael-min.js",
					// "Assets/default/Js/raphael.sketchpad.js",
					// "Assets/default/Js/lighthouse/editor/jpicker/jpicker-1.1.6.js",
					// "js/lighthouse-en.js",
					// "Assets/default/Js/mathinput.js",
				],
				css: [
					// "css/lighthouse-editor.css",
					"Assets/default/Js/tinymce4/js/tinymce/plugins/mathquill/css/mathquill.css",
					// "Assets/default/Js/tinymce4/js/tinymce/plugins/mathquill/css/mathkeyboard.css",
				],
			},
			loadJSCount: 0,
			responseHTMLs: [],
		};
	},

	computed: {},

	watch: {},

	created() {
		if (window.Lighthouse) {
			console.log("LIGHTHOUSE IS ALREADY LOADED", Lighthouse);
			this.render();
		} else {
			this.loadCogniaScripts(this.render);
		}

		this.parseResponseJSON(this.page);

		this.render();
	},

	mounted() {},

	beforeDestroy() {},

	methods: {
		parseResponseJSON(page) {
			let data = JSON.parse(page.text);
			page.data = data;
		},

		loadCogniaScripts(callback) {
			let basePath = this.cogniaConfig.basePath;
			let jsFiles = this.cogniaConfig.js;
			let cssFiles = this.cogniaConfig.css;

			if (!basePath) {
				console.error("No base path in Cognia config");
				return;
			}

			if (cssFiles && Array.isArray(cssFiles) && cssFiles.length > 0) {
				for (let cssFile of cssFiles) {
					let fileName = basePath + cssFile;
					console.log("Loading CSS files", fileName);

					let fileEl = document.createElement("link");
					fileEl.setAttribute("type", "text/css");
					fileEl.setAttribute("rel", "Stylesheet");
					fileEl.setAttribute("href", fileName);
					document.body.appendChild(fileEl);
				}
			} else {
				console.error("No CSS files to load");
			}

			if (jsFiles && Array.isArray(jsFiles) && jsFiles.length > 0) {
				this.loadJS(jsFiles, 0, basePath, callback);
			} else {
				console.error("No JS files to load");
			}
		},

		loadJS(files, i, basePath, callback) {
			let fileName = basePath + files[i];
			console.log("Loading JS file", fileName);

			let fileEl = document.createElement("script");
			fileEl.setAttribute("type", "text/javascript");
			fileEl.setAttribute("src", fileName);
			fileEl.onload = () => {
				if (i + 1 < files.length) {
					this.loadJS(files, i + 1, basePath, callback);
				}

				this.loadedJS(callback);
			};
			document.body.appendChild(fileEl);
			this.loadJSCount++;
		},

		loadedJS(callback) {
			this.loadJSCount--;
			if (this.loadJSCount === 0 && callback) {
				callback();
			}
		},

		render() {
			console.log("DO THE RENDER NOW");
		},
	},
};
</script>

<style scoped>
.video-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 800px;
	min-width: 200px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
	line-height: 0;
	aspect-ratio: 16/9;
}

.response-box {
	padding-top: 16px;
	padding-right: 16px;
	padding-left: 16px;
	margin: 16px;
}
</style>
