<template>
	<div class="d-flex flex-row align-items-center position-relative">
		<div class="meeting-avatar-small meeting-avatar-offline ml-2 my-1">
			<h6 class="mb-0">{{ fs.initials(meetingUser) }}</h6>
		</div>
		<div class="mx-2 flex overflow-hidden">
			<div>{{ meetingUser.full_name }}</div>
			<div v-if="meetingUser.scorer_id" class="text-xxs text-muted">{{ meetingUser.scorer_id }}</div>
			<div v-else class="text-xxs text-muted">(Anonymous)</div>
		</div>
	</div>
</template>

<style scoped>
.icon-container {
	width: 24px;
	text-align: center;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";

export default {
	name: "ParticipantListRowOffline",
	props: {
		meetingUser: true,
	},
	components: {},
	data() {
		return {
			fs: fs,
		};
	},
	created() {},
	mounted() {},

	watch: {},

	computed: {},

	methods: {},
};
</script>
