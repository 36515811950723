<template>
	<div>
		<!-- <div id="tooltip">
			<div class="max-w-md w-full py-4 px-8">
				<div v-html="tooltipHTML"></div>
			</div>
		</div> -->

		<div class="container d-flex flex-column">
			<!-- <div
				class="slider-container"
				v-tippy="{
					html: '#tooltip',
					reactive: true,
					theme: 'popover',
					animateFill: false,
					placement: 'left',
					hideOnClick: false,
					show: tooltipHTML != '',
				}"
			> -->
			<div class="slider-container">
				<div ref="slider" class="slider flex">
					<div class="line">
						<div v-if="value != null" class="line-background" :style="{ width: bgOffset }"></div>
						<div v-for="(tick, i) in ticks" :key="`tick-${i}`" class="tick" :style="{ left: tick.left }">
							<div v-if="shouldShowTick(tick.number)" class="tick-text">{{ tick.number }}</div>
						</div>
						<div
							v-if="value != null"
							class="dot"
							:class="getColorClass()"
							:style="{ left: markOffset }"
						></div>
					</div>
				</div>
			</div>
			<div class="score-aside mx-auto">
				{{ value }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HundredSlider",

	props: ["value", "min", "max", "step", "spds"],

	data() {
		return {
			ticks: [],
			labels: [],
		};
	},

	mounted() {
		this.$refs.slider.addEventListener("mousedown", this.startSlide);
		document.addEventListener("mouseup", this.endSlide);
		this.constructMarks();
	},

	destroyed() {
		document.removeEventListener("mouseup", this.endSlide);
	},

	computed: {
		markOffset() {
			let range = this.max - this.min;
			let mark = (this.value - this.min) * (100 / range);
			return `calc(${mark}% - 8px)`;
		},

		bgOffset() {
			let range = this.max - this.min;
			let mark = (this.value - this.min) * (100 / range);
			return `${mark}%`;
		},

		tooltipHTML() {
			let message = "Click the slider to select a score";
			if (this.value == null || this.value == undefined) {
				return message;
			}
			if (!(this.spds && this.spds.length > 0)) {
				return "";
			}

			let spd = _.find(this.spds, { val: this.value });
			if (!spd) {
				return "Could not find SPD";
			}

			return spd.desc;
		},
	},

	watch: {
		marks() {
			this.constructMarks();
		},

		min() {
			this.constructMarks();
		},

		max() {
			this.constructMarks();
		},

		step() {
			this.constructMarks();
		},
	},

	methods: {
		getColorClass() {
			return { [`si-${this.color}`]: true };
		},

		startSlide(event) {
			this.doSlide(event.offsetX);
			this.$refs.slider.addEventListener("mousemove", this.doSlideEvent);
		},

		endSlide() {
			this.$refs.slider.removeEventListener("mousemove", this.doSlideEvent);
		},

		doSlideEvent(event) {
			this.doSlide(event.offsetX);
		},

		doSlide(x) {
			console.log("event", x);
			let range = this.max - this.min;
			range /= this.step;

			let width = this.$refs.slider.clientWidth;
			let value = Math.round((x / width) * range) + this.min / this.step;
			value *= this.step;

			if (value < this.min) {
				value = this.min;
			}
			if (value > this.max) {
				value = this.max;
			}

			console.log("EMIT VALUE", value);

			this.$emit("input", value);
		},

		constructMarks() {
			this.ticks = [];
			let pos = 0;

			let range = this.max - this.min;

			for (let i = this.min; i <= this.max; i += this.step) {
				let mark = (i - this.min) * (100 / range);
				this.ticks.push({
					number: i,
					left: `calc(${mark}% - 1.5px)`,
				});
			}
		},

		shouldShowTick(num) {
			let range = this.max - this.min;
			if (range >= 80) {
				return num % 20 == 0;
			} else if (range >= 40) {
				return num % 10 == 0;
			} else if (range >= 20) {
				return num % 5 == 0;
			} else if (range >= 16) {
				return num % 4 == 0;
			} else if (range >= 10) {
				return num % 2 == 0;
			} else if (range > 4) {
				return num % 1 == 0;
			} else if (range > 1.25) {
				return num % 0.5 == 0;
			}
			return true;
		},

		mouseover() {
			console.log("MOUSE OVER");
		},

		mouseleave() {
			console.log("MOUSE LEAVE");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slider {
	padding-top: 13.5px;
	padding-bottom: 35px;
	cursor: pointer;
}

.slider-container {
	padding-top: 21px;
	padding-right: 16px;
	margin-right: -16px;
	padding-left: 16px;
	margin-left: -16px;
	margin-top: -22px;
}

.line {
	position: relative;
	border: 1px solid rgb(200, 200, 200);
	height: 10px;
	border-radius: 10px;
	transition: border-color 0.1s ease-in-out;
}

.line-background {
	position: absolute;
	background-color: var(--theme-color-lighten-4);
	height: 8px;
	border-radius: 8px;
	width: 0%;
	content: "";
	transition: background-color 0.1s ease-in-out;
}

.slider-container:hover .line {
	border: 1px solid rgb(160, 160, 160);
}

.slider-container:hover .line-background {
	background-color: var(--theme-color-lighten-3);
}

.tick {
	position: absolute;
	top: -13.5px;
	height: 30px;
	/* border-left: 3px solid rgb(200, 200, 200); */
	border-left: 3px solid rgba(200, 200, 200, 0);
	pointer-events: none;
}

.tick-text {
	position: absolute;
	top: 28px;
	width: 50px;
	left: -26px;
	text-align: center;
	font-size: 16px;
}

.label-table {
	width: 100%;
	table-layout: fixed;
}

.label {
	user-select: none;
	-webkit-user-select: none;
	pointer-events: none;
	font-size: 18px;
	font-weight: 600;
	padding: 0.5rem;
	text-align: center;
}

.dot {
	position: absolute;
	top: -4px;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-color: var(--theme-color);
	pointer-events: none;
}

.number-line-end {
	position: absolute;
	right: -13px;
	top: -7px;
	height: 17px;
	width: 12px;
	border-left: 12px solid rgb(200, 200, 200);
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	pointer-events: none;
}

.score-aside {
	text-align: center;
	width: 50px;
	height: 30px;
	font-size: 16px;
	border: 1px solid rgba(200, 200, 200);
	border-radius: 4px;
	line-height: 28px;
}
</style>
