<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a v-if="set" href="/#/rf_setup" class="navbar-brand">{{
					$t("RFSetResponses.title", {
						name: set.name,
					})
				}}</a>
				<a href="/#/rf_setup" class="navbar-brand right">{{ $t("buttons.back") }}</a>
			</div>
		</template>
		<div class="padding">
			<div class="row">
				<div class="col col-s6">
					<div class="d-flex flex-row justify-content-between align-items-center mb-2" style="height: 35px">
						<h5 class="mb-0">{{ $t("RFSetResponses.current_responses") }}</h5>
					</div>
					<table class="table">
						<thead>
							<tr class="px-0">
								<th></th>
								<th>{{ $t("fields.id") }}</th>
								<th>{{ $t("fields.sequence") }}</th>
								<th>{{ $t("fields.scores") }}</th>
								<th>{{ $t("fields.true_score") }}</th>
								<th class="text-center">{{ $t("fields.actions") }}</th>
							</tr>
						</thead>
						<!-- <tbody> -->
						<tbody v-if="!currentResponses.length">
							<tr v-if="!currentResponses.length">
								<td colspan="5">{{ $t("data_description.no_responses_set") }}</td>
							</tr>
						</tbody>

						<draggable
							v-model="currentResponses"
							:options="{ handle: '.drag-handle' }"
							@sort="setSorted"
							:element="'tbody'"
						>
							<tr v-for="resp in currentResponses" :key="resp.id">
								<td class="p-custom py-0 v-mid">
									<div class="m-auto px-2 drag-handle flex-fixed pointer">
										<i class="far fa-sort text-muted"></i>
									</div>
								</td>

								<td>{{ resp.ref_id }}</td>
								<td>{{ resp.sequence }}</td>
								<td>
									<span v-if="resp.qc_scores.length == 0" class="text-very-muted">——</span>
									<span v-if="resp.qc_scores.length > 0">{{ resp.qc_scores.length }}</span>
								</td>
								<td>
									<span class="row" v-if="resp.true_score">
										<inline-score
											:score="resp.true_score"
											:rubric="item && item.rubric"
											:type="20"
											tooltip
										/>
										<final-annotation-button
											class="pl-2"
											:response="resp"
										></final-annotation-button>
									</span>
									<span v-if="!resp.true_score">--</span>
								</td>
								<td class="p-custom py-0 v-mid text-center">
									<a
										:href="`#/rf_true_score/${resp.section_id}/${resp.item_id}/${resp.id}?return=true`"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.edit')"
									>
										<i class="far fa-edit"></i>
									</a>
									<a
										@click="deleteResp(resp)"
										class="btn btn-sm btn-icon btn-rounded red text-white m-0"
										v-tippy
										:title="$t('tooltip.delete')"
									>
										<i class="far fa-trash"></i>
									</a>
								</td>
							</tr>
						</draggable>
					</table>
				</div>
				<div class="col col-s6">
					<div class="d-flex flex-row justify-content-between align-items-center mb-2" style="height: 35px">
						<h5 class="mb-0">{{ $t("RFSetResponses.available_responses") }}</h5>
						<a @click="attemptImport" class="btn-subtle" v-tippy :title="$t('tooltip.upload_responses')">
							<i class="fas fa-file-upload fa-lg mx-2" />
						</a>
					</div>
					<table class="table b-table">
						<thead>
							<tr>
								<th class="p-custom py-0 v-mid">
									<a
										@click="assignAll()"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.add_all')"
									>
										<i class="far fa-plus-circle"></i>
									</a>
								</th>
								<th>{{ $t("fields.id") }}</th>
								<th
									@click="sortByWords()"
									class="sorting"
									:class="{ sorting_asc: wordSortDir == true, sorting_desc: wordSortDir == false }"
								>
									{{ $t("fields.word_count") }}
								</th>
								<th>{{ $t("fields.scores") }}</th>
								<th>{{ $t("fields.true_score") }}</th>
								<th class="text-center">{{ $t("fields.actions") }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="!availableResponses.length">
								<td colspan="4">No Responses Available</td>
							</tr>
							<tr v-for="resp in availableResponses" :key="resp.id">
								<td class="p-custom py-0 v-mid">
									<a
										@click="assign(resp)"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.add_to_set')"
									>
										<i class="far fa-plus"></i>
									</a>
								</td>
								<td>{{ resp.ref_id }}</td>
								<td>
									<span :class="{ 'text-very-muted': !resp.word_count }">{{
										resp.word_count || "——"
									}}</span>
								</td>
								<td>
									<span v-if="resp.qc_scores && resp.qc_scores.length">
										{{ resp.qc_scores.length }}
									</span>
									<span v-else class="text-very-muted">——</span>
								</td>
								<td>
									<span class="row" v-if="resp.true_score">
										<inline-score
											:score="resp.true_score"
											:rubric="item && item.rubric"
											:type="20"
											tooltip
										/>
										<final-annotation-button
											class="pl-2"
											:response="resp"
										></final-annotation-button>
									</span>
									<span v-if="!resp.true_score">--</span>
								</td>
								<td class="p-custom py-0 v-mid text-center">
									<a
										:href="`#/rf_true_score/${resp.section_id}/${resp.item_id}/${resp.id}?return=true`"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.edit')"
									>
										<i class="far fa-edit"></i>
									</a>
									<a
										@click="deleteResp(resp)"
										class="btn btn-sm btn-icon btn-rounded red text-white m-0"
										v-tippy
										:title="$t('tooltip.delete')"
									>
										<i class="far fa-trash"></i>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<response-import-modal
			id="importModal"
			:visible="importingItem != false"
			:section="section"
			:item="item"
			:qcType="5"
			@cancel="importingItem = false"
			@refresh="init"
		/>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-lg {
	max-width: 90%;
}

.btn-subtle {
	opacity: 0.21;
	transition: opacity 0.25s;
}

.btn-subtle:hover {
	opacity: 0.6;
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCConfigService from "@/services/QCConfigService";
import SectionService from "@/services/SectionService";
import ItemService from "@/services/ItemService";

import ConfigSelect from "@/components/ConfigSelect";
import ResponseImportModal from "../../components/ResponseImportModal";
import InlineScore from "../../components/InlineScore";
import FinalAnnotationButton from "../../components/range_finding/FinalAnnotationButton";
import Notie from "@/services/NotieService";
import draggable from "vuedraggable";
import $ from "jquery";

import Media from "@/components/viewer/Media";

export default {
	name: "RFSetResponses",
	data() {
		return {
			user: null,
			setID: this.$route.params.setID,
			set: {},
			currentResponses: [],
			availableResponsesUnsorted: [],
			wordSortDir: null,

			section: null,
			item: null,
			importingItem: false,

			files: [],
			loaded: 0,
			uploading: false,

			editResponse: null,
		};
	},
	components: {
		ConfigSelect,
		draggable,
		Media,
		ResponseImportModal,
		InlineScore,
		FinalAnnotationButton,
	},
	created() {
		AuthService.getUser().then((user) => {
			this.user = user;
		});
		this.init();
	},
	watch: {},
	computed: {
		availableResponses() {
			if (this.wordSortDir == null) {
				return this.availableResponsesUnsorted;
			}

			if (this.wordSortDir) {
				return _.orderBy(this.availableResponsesUnsorted, "word_count", "asc");
			} else {
				return _.orderBy(this.availableResponsesUnsorted, "word_count", "desc");
			}
		},
	},
	methods: {
		init() {
			QCConfigService.getQcSet(this.setID).then((resp) => {
				this.set = resp.data;
				QCConfigService.listSetResponses(this.set.id).then((resp) => {
					this.currentResponses = resp.data;
					this.currentResponses = _.sortBy(this.currentResponses, (r) => {
						return r.sequence;
					});
				});
				QCConfigService.listAvailableResponses(this.set.id).then((resp) => {
					if (resp.data[0]) {
						this.availableResponsesUnsorted = resp.data[0].responses;
					} else {
						this.availableResponsesUnsorted = [];
					}
				});
				SectionService.getSection(this.set.section_id).then((resp) => {
					this.section = resp.data;
				});
				ItemService.getItem(this.set.item_id).then((resp) => {
					this.item = resp.data;
				});
			});
		},

		assign(response) {
			QCConfigService.assignToSet(response.id, this.set.id, response.feedback).then(() => {
				this.init();
			});
		},

		assignAll() {
			QCConfigService.assignManyToSet(_.map(this.availableResponses, "id"), this.set.id)
				.then(() => {
					this.init();
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.assign_responses_fail"));
				});
		},

		attemptImport(item) {
			this.importingItem = this.selectedItem;
		},

		selectFiles() {
			$("#fileInput").click();
		},

		mediaSelected(event) {
			this.files = event.target.files;
		},

		setSorted() {
			var counter = 1;
			_.each(this.currentResponses, (resp) => {
				// if (set) {
				if (resp.sequence != counter) {
					resp.sequence = counter;
					QCConfigService.saveQcResponse(resp);
				}
				// set.sequence = counter;
				counter++;
				// }
			});
		},

		editResp(resp) {
			this.editResponse = resp;
			$("#editRespModal").modal("show");
		},

		deleteResp(resp) {
			QCConfigService.deleteQcResponse(resp).then(() => {
				this.init();
			});
		},

		sortByWords() {
			if (!this.wordSortDir) {
				this.wordSortDir = true;
			} else {
				this.wordSortDir = false;
			}
		},
	},
};
</script>
