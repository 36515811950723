import axios from "axios";

function addOneUser(user, to) {
	let uid = typeof user == "string" ? user : user && user.id;
	to.push({ user: { id: uid } });
}

function resolveToUsers(toUsers) {
	let to = [];
	if (typeof toUsers == "string") {
		addOneUser(toUsers, to);
	} else if (toUsers && toUsers.length) {
		_.each(toUsers, u => {
			addOneUser(u, to);
		});
	} else {
		addOneUser(toUsers, to);
	}
	return to;
}

export default {
	listMail(mailType, ctx) {
		return axios.get(`/v1/user/mail/type/${mailType}`, { params: ctx });
	},

	listAllMail(mailType) {
		return this.listMail(mailType);
	},

	saveDraft(mail, toUsers) {
		mail.to = resolveToUsers(toUsers);
		mail.sent = false;
		return axios.post("/v1/user/mail/message", mail);
	},

	//Param @toUsers can be a single User object, an array of User objects,
	//a single User ID string, or an array of User ID strings
	sendMail(mail, toUsers) {
		mail.to = resolveToUsers(toUsers);
		mail.sent = true;
		return axios.post("/v1/user/mail/message", mail);
	},

	deleteMail(mailID) {
		return axios.delete(`/v1/user/mail/message/${mailID}`);
	},

	getCounts() {
		return axios.get("/v1/user/mail/counts");
	},

	getUnread() {
		return axios.get("/v1/user/mail/unread");
	},

	view(mailID) {
		return axios.post(`/v1/user/mail/view/${mailID}`);
	},

	trash(mailID) {
		return axios.post(`/v1/user/mail/trash/${mailID}`);
	},

	untrash(mailID) {
		return axios.post(`/v1/user/mail/untrash/${mailID}`);
	},

	archive(mailID) {
		return axios.post(`/v1/user/mail/archive/${mailID}`);
	},

	unarchive(mailID) {
		return axios.post(`/v1/user/mail/unarchive/${mailID}`);
	},

	label(mailID, label) {
		return axios.post(`/v1/user/mail/label/${mailID}/${label}`);
	},

	unlabel(mailID, label) {
		return axios.delete(`/v1/user/mail/label/${mailID}/${label}`);
	},

	addLabel(label) {
		return axios.post(`/v1/user/maillabels/${label}`);
	},

	deleteLabel(label) {
		return axios.delete(`/v1/user/maillabels/${label}`);
	},

	getResponseFrom(mailID) {
		return axios.get(`/v1/user/mail/response/from/${mailID}`);
	},

	getRescoreFrom(mailID) {
		return axios.get(`/v1/user/mail/rescore/from/${mailID}`);
	}
};
