export default {
	lightboxes: [],

	bind(vm) {
		this.lightboxes.push(vm)
	},

	unbind(vm) {
		let i = this.lightboxes.indexOf(vm)
		if(i >= 0) {
			this.lightboxes.splice(i, 1)
		}
	},

	showElement(el) {
		for(let lightbox of this.lightboxes) {
			lightbox["contentElement"] = el.cloneNode()
		}
	}
}