<template>
	<b-modal :visible="visible" v-if="visible">
		<template slot="modal-title">{{ $t("RFSetResponses.import_modal.title") }}</template>
		<div class="row p-4">
			<div class="col-12 form-group">
				<h5>{{ $t("RFSetResponses.import_modal.import_to", { name: item.name }) }}</h5>
			</div>
			<div class="col-12 form-group">
				<label>{{ $t("RFSetResponses.import_modal.import_type") }}</label>
				<v-select :options="importOptions" v-model="importType"></v-select>
			</div>

			<!--No import type selected-->
			<div v-if="!importType" class="col-12">
				<p>{{ $t("RFSetResponses.import_modal.supported_methods") }}</p>
				<ul style="list-style-type: circle">
					<li style="list-style-type: circle">{{ $t("RFSetResponses.import_modal.upload_csv") }}</li>
					<li style="list-style-type: circle">
						{{ $t("RFSetResponses.import_modal.import_from_ftp") }}
						<span style="color: red">(NYI)</span>
					</li>
					<li style="list-style-type: circle">
						{{ $t("RFSetResponses.import_modal.import_from_drive") }}
						<span style="color: red">(NYI)</span>
					</li>
				</ul>
			</div>

			<!--Import CSV-->
			<div v-if="(importType && importType.id == 1) || (importType && importType.id == 6)" class="col-12">
				<div class="form-group">
					<input id="response-import-input-1" class="d-none" type="file" @change="selectImportFile" />
					<button class="btn hover-darken theme-accent btn-block" @click="click('response-import-input-1')">
						{{ $t("buttons.select_file") }}
					</button>
				</div>
				<div class="form-group">
					<input type="text" class="form-control" v-model="importingFile.name" readonly />
				</div>
				<div class="form-group">
					<p v-if="!this.qcType && importType && importType.id == 6" style="color: red">
						{{ $t("RFSetResponses.import_modal.meta_data_warning") }}
					</p>
					<p>{{ $t("RFSetResponses.import_modal.things_to_remember") }}</p>
					<ul style="list-style-type: circle">
						<li style="list-style-type: circle">{{ $t("RFSetResponses.import_modal.only_csv") }}</li>
						<li style="list-style-type: circle">
							{{ $t("RFSetResponses.import_modal.columns_description") }}
						</li>
						<li v-if="!this.qcType" style="list-style-type: circle">
							{{ $t("RFSetResponses.import_modal.third_column") }}
						</li>
						<li v-if="!this.qcType && importType && importType.id == 6" style="list-style-type: circle">
							{{ $t("RFSetResponses.import_modal.meta_data") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("RFSetResponses.import_modal.header_row") }}
						</li>
					</ul>
				</div>
			</div>

			<!--Import Zip file-->
			<div v-if="importType && importType.id == 5" class="col-12">
				<div class="form-group">
					<input
						id="zip-import-input"
						class="d-none"
						type="file"
						accept="application/zip"
						@change="selectImportFile"
					/>
					<button class="btn hover-darken theme-accent btn-block" @click="click('zip-import-input')">
						{{ $t("buttons.select_file") }}
					</button>
				</div>
				<div class="form-group">
					<input type="text" class="form-control" v-model="importingFile.name" readonly />
				</div>
				<div class="form-group">
					<p>{{ $t("RFSetResponses.import_modal.things_to_remember") }}</p>
					<ul style="list-style-type: circle">
						<li style="list-style-type: circle">{{ $t("RFSetResponses.import_modal.only_zip") }}</li>
						<li style="list-style-type: circle">
							{{ $t("RFSetResponses.import_modal.index_file") }}
						</li>
					</ul>
				</div>
			</div>

			<!--Upload files-->
			<div v-if="importType && importType.id == 2" class="col-12">
				<div class="form-group">
					<input
						v-if="!forceDownload"
						id="response-import-input-2"
						class="d-none"
						type="file"
						multiple="multiple"
						hidden
						accept="audio/x-wav, image/gif, image/jpeg, image/png, application/pdf, video/quicktime, video/mp4, video/m4v, video/webm, audio/m4a, audio/mp3, text/csv, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/html"
						@change="selectUploadFiles"
					/>
					<input
						v-if="forceDownload"
						id="response-import-input-2"
						class="d-none"
						type="file"
						multiple="multiple"
						hidden
						@change="selectUploadFiles"
					/>
					<button class="btn hover-darken theme-accent btn-block" @click="click('response-import-input-2')">
						{{ $t("buttons.select_files") }}
					</button>
				</div>
				<div class="form-group">
					<input type="text" class="form-control" v-model="newFilesNames" readonly />
				</div>
				<div class="form-group">
					<p>{{ $t("RFSetResponses.import_modal.things_to_remember") }}</p>
					<ul style="list-style-type: circle">
						<li style="list-style-type: circle">
							{{ $t("RFSetResponses.import_modal.file_becomes_response") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("RFSetResponses.import_modal.name_becomes_resp_id") }}
						</li>
					</ul>
				</div>
				<div v-if="!qcType" class="checkbox">
					<label
						class="md-check"
						v-tippy
						title="Instead of rendering the media within the scoring viewer, show the rater a link with which to download it"
					>
						<input v-model="forceDownload" type="checkbox" />
						<i class="theme-accent"></i> Show media as download link
					</label>
				</div>
				<div
					v-if="newFiles && newFiles.length == 1 && newFiles[0].file && isIFrameType(newFiles[0].file.type)"
					class="row"
				>
					<hr class="w-100" />
					<div class="col-12">
						<label>Preferred Aspect Ratio</label>
					</div>
					<div class="col-6">
						<label>Width</label>
						<input class="form-control" type="number" v-model="prefWidth" />
					</div>
					<div class="col-6">
						<label>Height</label>
						<input class="form-control" type="number" v-model="prefHeight" />
					</div>
				</div>
			</div>

			<!--Upload folders-->
			<div v-if="importType && importType.id == 3" class="col-12">
				<div class="form-group">
					<input
						v-if="!forceDownload"
						id="response-import-input-3"
						class="d-none"
						type="file"
						multiple="multiple"
						webkitdirectory
						hidden
						accept="audio/x-wav, image/gif, image/jpeg, image/png, application/pdf, video/quicktime, video/mp4, video/m4v, audio/m4a, audio/mp3, text/csv, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						@change="selectUploadFolders"
					/>
					<input
						v-if="forceDownload"
						id="response-import-input-3"
						class="d-none"
						type="file"
						multiple="multiple"
						webkitdirectory
						hidden
						@change="selectUploadFolders"
					/>
					<button
						class="btn hover-darken theme-accent btn-block"
						@click="click('response-import-input-3')"
						:disabled="!canWebkitDirectory"
					>
						Select folder(s)...
					</button>
				</div>
				<template v-if="canWebkitDirectory">
					<div class="form-group">
						<input type="text" class="form-control" v-model="newFilesNames" readonly />
					</div>
					<div class="form-group">
						<p>Things to remember when uploading folders:</p>
						<ul style="list-style-type: circle">
							<li style="list-style-type: circle">Each folder will become an individual response</li>
							<li style="list-style-type: circle">The name of the folder will become the response ID</li>
							<li style="list-style-type: circle">
								Each file within the folder will become a separate media element on the response
							</li>
							<li style="list-style-type: circle">
								Media elements will be sequenced in alphabetical order
							</li>
							<li style="list-style-type: circle">
								You can choose nested folders, but all files should be at the same depth
							</li>
							<li style="list-style-type: circle">
								The name of the file, until the first ".", can be used as a rubric page ref ID (for
								paged rubrics)
							</li>
						</ul>
					</div>
				</template>
				<template v-if="!canWebkitDirectory" class="form-group">
					<p>Your browser does not support uploading multiple folders at a time.</p>
					<p>Please use an up-to-date version of Chrome, Firefox, or Edge.</p>
				</template>
				<div v-if="!qcType" class="checkbox">
					<label
						class="md-check"
						v-tippy
						title="Instead of rendering the media within the scoring viewer, show the rater a link with which to download it"
					>
						<input v-model="forceDownload" type="checkbox" />
						<i class="theme-accent"></i> Show media as download link
					</label>
				</div>
			</div>

			<!--Import Google Drive-->
			<!-- <div v-if="importType && importType.id == 3" class="col-12">
				<h5 style="color: red;">Not yet implemented</h5>
				<div class="form-group">
					<label>Folder URL</label>
					<input type="text" class="form-control">
				</div>
				<div class="form-group">
					<p>Things to remember when importing responses from Google Drive:</p>
					<ul style="list-style-type: circle">
						<li style="list-style-type: circle">Each file in the folder will become an individual response</li>
						<li style="list-style-type: circle">The name of the file will become the response ID</li>
						<li style="list-style-type: circle">The folder must be shared with
							<span style="color: blue">oscar.responses@mzdevinc.com</span>
						</li>
					</ul>
				</div>
			</div>-->
		</div>
		<template slot="modal-footer">
			<div v-if="importType.id == 2 && uploadingResponses" class="flex">
				{{ $t("data_description.uploaded", { uploaded: uploaded, length: newFiles.length }) }}
				<b-progress :value="uploaded" :max="newFiles.length" show-progress animated></b-progress>
			</div>
			<button class="btn primary btn-flat" @click="stopImport">{{ $t("buttons.cancel") }}</button>
			<button
				v-if="(importType.id == 1 && !importingResponses) || (importType.id == 6 && !importingResponses)"
				class="btn btn-flat"
				@click="importResponses(section, item, importingFile)"
				:disabled="!importingFile"
				:class="{ success: importingFile }"
			>
				{{ $t("buttons.import") }}
			</button>
			<button
				v-if="(importType.id == 1 && importingResponses) || (importType.id == 6 && importingResponses)"
				class="btn btn-secondary btn-flat"
				disabled
			>
				{{ $t("buttons.importing") }}&nbsp;&nbsp;
				<loading type="icon" />
			</button>

			<button
				v-if="(importType.id == 2 || importType.id == 3) && !uploadingResponses && newFiles.length == 0"
				class="btn btn-flat"
				disabled
			>
				{{ $t("buttons.upload") }}
			</button>
			<button
				v-if="importType.id == 2 && !uploadingResponses && newFiles.length > 0"
				class="btn btn-flat success"
				@click="uploadResponses(section, item, newFiles)"
			>
				{{ $t("buttons.upload_num", { length: newFiles.length }) }}
			</button>
			<button
				v-if="importType.id == 3 && !uploadingResponses && newFiles.length > 0"
				class="btn btn-flat success"
				@click="uploadResponses(section, item, newFiles)"
			>
				Upload {{ newFiles.length }} folders...
			</button>
			<button
				v-if="importType.id == 5 && !uploadingResponses"
				class="btn btn-flat"
				@click="uploadContentPackage(section, item, importingFile)"
				:disabled="!importingFile"
				:class="{ success: importingFile }"
			>
				{{ $t("buttons.upload") }}
			</button>
			<button
				v-if="(importType.id == 2 || importType.id == 3 || importType.id == 5) && uploadingResponses"
				class="btn btn-secondary btn-flat"
				disabled
			>
				{{ $t("buttons.uploading") }}&nbsp;&nbsp;
				<loading type="icon" />
			</button>
		</template>
	</b-modal>
</template>

<style scoped>
</style>

<script>
import QCConfigService from "@/services/QCConfigService";
import SectionService from "@/services/SectionService";
import bb from "bluebird";

import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";

export default {
	name: "ResponseImportModal",
	props: ["section", "item", "qcType", "visible"],

	data() {
		return {
			importingFile: false,
			importingResponses: false,
			importOptions: [
				{ label: this.$i18n.t("import_options.csv"), id: 1 },
				{ label: this.$i18n.t("import_options.csv_with_meta_data"), id: 6 },
				{ label: this.$i18n.t("import_options.files"), id: 2 },
				{ label: this.$i18n.t("import_options.folder"), id: 3 },
				// { label: "Import from Google Drive", id: 4 },
				{ label: this.$i18n.t("import_options.html_content_package_zip"), id: 5 },
			],
			importType: null,
			forceDownload: false,
			prefWidth: 16,
			prefHeight: 9,

			uploadingResponses: false,
			newFiles: [],
			processed: 0,
			uploaded: 0,
			duplicates: [],
			errors: [],

			canWebkitDirectory: false,
		};
	},
	created() {
		this.importType = this.importOptions[0];

		let testInput = document.createElement("input");
		this.canWebkitDirectory = typeof testInput.webkitdirectory == "boolean";
	},
	watch: {},
	computed: {
		newFilesNames() {
			let names = [];
			_.each(this.newFiles, (file) => {
				names.push(file.name);
			});
			return names.join(", ");
		},
	},
	methods: {
		selectImportFile(event) {
			const file = event.target.files[0];
			if (file) {
				if (fs.hasUnicodeChars(file.name)) {
					this.nonAsciiError();
					return;
				}
				this.importingFile = file;
			}
		},

		stopImport() {
			this.$emit("cancel");
			this.importingFile = false;
			this.importingResponses = false;
			this.uploadingResponses = false;
		},

		importResponses(section, item, file) {
			console.log(file.type);
			if (file.type != "text/csv" && file.type != "application/vnd.ms-excel") {
				Notie.error(this.$i18n.t("notie.must_be_csv"));
				return;
			}

			var data = new FormData();
			data.append("file", file);
			data.append("itemId", item.id);
			data.append("sectionId", section.id);
			data.append("importTypeId", this.importType.id);

			if (this.qcType) {
				this.importToQC(data);
			} else {
				this.importToLive(data);
			}
		},

		importToLive(data) {
			this.importingResponses = true;
			SectionService.uploadResponses(data)
				.then((resp) => {
					this.stopImport();
					Notie.info(
						this.$i18n.t("notie.uploaded_responses_to", {
							num: resp.data.numResponses,
							name: this.item.name,
						})
					);
					this.$emit("refresh");
				})
				.catch((err) => {
					console.log(err);
					this.importingResponses = false;
					if (err.response.data.includes("duplicate key")) {
						Notie.error(this.$i18n.t("notie.upload_fail"), this.$i18n.t("error.csv_resp_id_exists"));
					} else {
						Notie.error(this.$i18n.t("notie.upload_fail"), err);
					}
				});
		},

		importToQC(data) {
			data.append("qcType", this.qcType);
			this.importingResponses = true;
			QCConfigService.uploadResponses(data)
				.then((resp) => {
					this.stopImport();
					Notie.info(
						this.$i18n.t("notie.uploaded_responses_to", {
							num: resp.data.numResponses,
							name: this.item.name,
						})
					);
					this.$emit("refresh");
				})
				.catch((err) => {
					console.log(err);
					this.importingResponses = false;
					if (err.response.data.includes("duplicate key")) {
						Notie.error(this.$i18n.t("notie.upload_fail"), this.$i18n.t("error.csv_resp_id_exists"));
					} else {
						Notie.error(this.$i18n.t("notie.upload_fail"), err);
					}
				});
		},

		selectUploadFiles(event) {
			console.log("upload event", event);
			_.each(event.target.files, (file) => {
				this.addNewFile(file);
			});
		},

		addNewFile(file) {
			var suggestedName = file.name.split(".");
			if (suggestedName.length > 1) {
				//Pop off the extension if it exists
				suggestedName.pop();
			}
			suggestedName = suggestedName.join("");

			if (fs.hasUnicodeChars(suggestedName)) {
				this.nonAsciiError();
				return;
			}

			this.newFiles.push({
				name: suggestedName,
				file: file,
				uploading: false,
			});

			console.log("___ADD NEW FILE___");
			console.log(file);
		},

		selectUploadFolders(event) {
			let folderMap = {};

			_.each(event.target.files, (file) => {
				let parts = file.webkitRelativePath.split("/");
				if (parts.length < 2) {
					console.log(
						`Failed to process file in folder - not enough parts in path ${file.webkitRelativePath}`
					);
					return;
				}

				let folderName = parts[parts.length - 2];
				if (fs.hasUnicodeChars(folderName)) {
					this.nonAsciiError();
					return;
				}
				if (!folderMap[folderName]) {
					folderMap[folderName] = [];
				}
				folderMap[folderName].push(file);
			});

			_.each(folderMap, (files, folderName) => {
				let filteredFiles = _.filter(files, (f) => {
					return f.type != "";
				});
				if (filteredFiles.length == 0) {
					return;
				}
				let sortedFiles = _.sortBy(filteredFiles, "name");
				this.newFiles.push({
					name: folderName,
					files: sortedFiles,
					uploading: false,
				});
			});
			this.newFiles = _.sortBy(this.newFiles, "name");
		},

		uploadResponses() {
			if (this.qcType) {
				this.uploadToQC();
			} else {
				this.uploadToLive();
			}
		},

		uploadToQC() {
			this.processed = 0;
			this.uploaded = 0;
			this.duplicates = [];
			this.errors = [];
			this.uploadingResponses = true;
			bb.map(
				this.newFiles,
				(f) => {
					if (f.file) {
						let width = 0;
						let height = 0;
						if (this.isIFrameType(f.file.type)) {
							width = this.prefWidth;
							height = this.prefHeight;
						}
						QCConfigService.addQCResponseFile(
							this.section.id,
							this.item.id,
							this.qcType,
							f.file,
							width,
							height
						)
							.then((resp) => {
								this.processed++;
								this.uploaded++;
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
							})
							.catch((err) => {
								this.processed++;
								console.log({ error: err });
								if (err.response.status == 409) {
									this.duplicates.push(f.name);
								} else {
									this.errors.push(`Uploading '${f.name}' failed: ${Notie.extractErrorMessage(err)}`);
								}
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
								console.log(err);
							});
					} else {
						QCConfigService.addQCResponseFiles(this.section.id, this.item.id, this.qcType, f.files, f.name)
							.then((resp) => {
								this.processed++;
								this.uploaded++;
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
							})
							.catch((err) => {
								this.processed++;
								console.log({ error: err });
								if (err.response.status == 409) {
									this.duplicates.push(f.name);
								} else {
									this.errors.push(`Uploading '${f.name}' failed: ${Notie.extractErrorMessage(err)}`);
								}
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
								console.log(err);
							});
					}
				},
				{ concurrency: 2 }
			);
		},

		uploadToLive() {
			this.processed = 0;
			this.uploaded = 0;
			this.duplicates = [];
			this.errors = [];
			this.uploadingResponses = true;
			bb.map(
				this.newFiles,
				(f) => {
					if (f.file) {
						let width = 0;
						let height = 0;
						if (this.isIFrameType(f.file.type) && this.prefWidth && this.prefHeight) {
							width = this.prefWidth;
							height = this.prefHeight;
						}
						SectionService.addResponseFile(
							this.section.id,
							this.item.id,
							f.file,
							this.forceDownload,
							width,
							height
						)
							.then((resp) => {
								this.processed++;
								this.uploaded++;
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
							})
							.catch((err) => {
								this.processed++;
								console.log({ error: err });
								if (err.response.status == 409) {
									this.duplicates.push(f.name);
								} else {
									this.errors.push(`Uploading '${f.name}' failed: ${Notie.extractErrorMessage(err)}`);
								}
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
								console.log(err);
							});
					} else {
						SectionService.addResponseFiles(this.section.id, this.item.id, f.files, this.forceDownload)
							.then((resp) => {
								this.processed++;
								this.uploaded++;
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
							})
							.catch((err) => {
								this.processed++;
								console.log({ error: err });
								if (err.response.status == 409) {
									this.duplicates.push(f.name);
								} else {
									this.errors.push(`Uploading '${f.name}' failed: ${Notie.extractErrorMessage(err)}`);
								}
								if (this.processed == this.newFiles.length) {
									this.finishUpload();
								}
								console.log(err);
							});
					}
				},
				{ concurrency: 2 }
			);
		},

		uploadContentPackage(section, item, file) {
			console.log(file.type);
			if (!(file.type == "application/zip" || file.type == "application/x-zip-compressed")) {
				Notie.error(this.$i18n.t("notie.must_be_zip"));
				return;
			}

			var data = new FormData();
			data.append("file", file);
			data.append("itemId", item.id);
			data.append("sectionId", section.id);
			if (this.prefWidth && this.prefHeight) {
				data.append("width", this.prefWidth);
				data.append("height", this.prefHeight);
			}

			if (this.qcType) {
				this.contentPackageToQC(data);
			} else {
				this.contentPackageToLive(data);
			}
		},

		contentPackageToLive(data) {
			this.uploadingResponses = true;
			SectionService.uploadContentPackage(data)
				.then((resp) => {
					this.stopImport();
					Notie.info(
						this.$i18n.t("notie.uploaded_content_package_to", {
							name: this.item.name,
						})
					);
					this.$emit("refresh");
				})
				.catch((err) => {
					console.log(err);
					this.uploadingResponses = false;
					if (err.response.data.includes("duplicate key")) {
						Notie.error(
							this.$i18n.t("notie.upload_fail"),
							this.$i18n.t("error.resp_id_exists", { refID: "whatever it is" })
						);
					} else {
						Notie.error(this.$i18n.t("notie.upload_fail"), err);
					}
				});
		},

		contentPackageToQC(data) {
			data.append("qcType", this.qcType);
			this.uploadingResponses = true;
			QCConfigService.uploadContentPackage(data)
				.then((resp) => {
					this.stopImport();
					Notie.info(
						this.$i18n.t("notie.uploaded_content_package_to", {
							name: this.item.name,
						})
					);
					this.$emit("refresh");
				})
				.catch((err) => {
					console.log(err);
					this.uploadingResponses = false;
					if (err.response.data.includes("duplicate key")) {
						Notie.error(
							this.$i18n.t("notie.upload_fail"),
							this.$i18n.t("error.resp_id_exists", { refID: "whatever it is" })
						);
					} else {
						Notie.error(this.$i18n.t("notie.upload_fail"), err);
					}
				});
		},

		finishUpload() {
			this.newFiles = [];
			this.uploadingResponses = false;
			this.stopImport();
			let messages = [];
			console.log("Finish", this.uploaded, this.duplicates, this.errors);
			if (this.uploaded > 0) {
				messages.push(
					`Uploaded ${this.uploaded} ${this.uploaded == 1 ? "response" : "responses"} to ${this.item.name}`
				);
			}
			if (this.duplicates.length > 0) {
				if (this.duplicates.length == 1) {
					messages.push(
						`Could not upload ${
							this.duplicates.length
						} file because reference ID already exists: ${this.duplicates.join(", ")}`
					);
				} else {
					messages.push(
						`Could not upload ${
							this.duplicates.length
						} files because reference IDs already exist: ${this.duplicates.join(", ")}`
					);
				}
			}
			_.each(this.errors, (error) => {
				messages.push(error);
			});
			Notie.info(messages.join("<br />"));
			this.$emit("refresh");
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},

		isIFrameType(mt) {
			let match = mt == "text/html" || mt == "application/zip" || mt == "application/x-zip-compressed";
			return match;
		},

		nonAsciiError() {
			Notie.error(this.$i18n.t("notie.add_file_fail"), this.$i18n.t("error.file_ascii"));
		},
	},
};
</script>
