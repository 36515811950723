<template>
	<div class="row">
		<div v-if="!sections || sections.length == 0" class="text-center p-4 w-100">
			<h3 class="m-0 text-very-muted">{{ $t("data_description.no_matching_items") }}</h3>
		</div>
		<project-dash-section v-for="section in sortedSections" :key="section.id" :section="section" />
	</div>
</template>

<style scoped></style>

<script>
import ProjectDashSection from "./ProjectDashSection";

import DashboardService from "@/services/DashboardService";

export default {
	name: "ProjectDashSections",

	components: { ProjectDashSection },

	props: ["project", "labels"],

	data() {
		return {
			busy: false,
			sections: [],
		};
	},
	created() {
		this.loadData();
	},

	computed: {
		sortedSections() {
			const { sections } = this;
			return (sections || []).sort((sectionA, sectionB) => sectionA.name.localeCompare(sectionB.name));
		},
	},

	watch: {
		"project.id"() {
			this.loadData();
		},
		labels() {
			this.loadData();
		},
	},
	methods: {
		loadData() {
			if (!this.project) {
				return;
			}
			if (this.busy) {
				return;
			}

			this.busy = true;
			console.log("Get completion sections");
			DashboardService.getProjectCompletionSections(this.project.id, this.labels)
				.then((resp) => {
					this.sections = resp.data;
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});
		},
	},
};
</script>
