<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ResGroupSetup.title") }}
				</div>

				<!-- Create new -->
				<div class="py-0">
					<a href="#/resolution_groups/new" class="btn btn-sm hover-darken theme-accent btn-block">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("ResGroupSetup.new_group") }}</span>
					</a>
				</div>

				<!-- Search field -->
				<div class="collapse navbar-collapse no-grow order-lg-1" id="navbarToggler">
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('ResGroupSetup.search_groups_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>
		<!-- Page Content -->
		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Elements -->
					<template #cell(elements)="data">
						<span v-if="data.item.elements.length > 0">
							<i class="fas fa-layer-group faw"></i>
							{{ data.item.elements.length }}
						</span>
						<span v-if="data.item.elements.length == 0" class="text-muted"> None </span>
					</template>

					<!-- Scoring type -->
					<template #cell(type)="data">{{ fs.itemTypeName(data.item.type) }}</template>

					<!-- Edit -->
					<template #cell(edit)="data">
						<div class="mx-auto w60">
							<a
								:href="`#/resolution_groups/${data.item.id}`"
								class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
								v-tippy
								:title="$t('tooltip.edit')"
							>
								<i class="far fa-edit"></i>
							</a>
							<button
								v-if="!data.item.inProgress"
								@click="deleting = data.item"
								class="btn btn-sm btn-icon btn-rounded hover-darken red text-white m-0"
								v-tippy
								:title="$t('tooltip.delete')"
							>
								<i class="far fa-trash"></i>
							</button>
							<button
								v-if="data.item.inProgress"
								class="btn btn-sm btn-icon btn-rounded m-0"
								v-tippy
								:title="$t('tooltip.removing')"
							>
								<loading type="icon" />
							</button>
						</div>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">
							{{ $t("data_description.no_records") }}
						</div>
					</template>
				</b-table>
			</div>
		</div>
		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("ResGroupSetup.delete_res_group") }}</template>
			<div class="row p-4">
				{{ $t("modal.confirm_delete") }}&nbsp; <strong class="text-theme">{{ deleting.name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="removeResGroup(deleting)">
					{{ $t("buttons.delete") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}
.w60 {
	width: 62px;
}
</style>

<script>
//UI Components
//@ ts-check
//Libraries
//import _ from "lodash";

//Services
import ResGroupService from "@/services/ResGroupService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import fs from "@/services/FormatService";

export default {
	name: "ResGroupSetup",
	data() {
		return {
			fs: fs,
			fields: [
				{ key: "name", label: this.$i18n.t("fields.name"), sortable: true, thStyle: { width: "50%" } },
				{
					key: "elements",
					label: this.$i18n.t("fields.elements"),
					sortable: false,
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "type",
					label: this.$i18n.t("fields.resolution_rule"),
					sortable: true,
				},
				// {key: "role_id", sortable: true},
				// {key: "team_id", sortable: true},
				// {key: "scoring_allowed", label: "Scoring Allowed", thStyle: {"text-align":"center"}, tdClass: "p-0 pt-2"},
				// {key: "login_allowed", label: "Login Allowed", thStyle: {"text-align":"center"}, tdClass: "p-0 pt-2"},
				{
					key: "edit",
					label: this.$i18n.t("fields.edit"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				},
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			sortBy: null,
			sortDesc: false,
			loading: true,

			deleting: false,
		};
	},

	components: {},

	created() {
		this.sortBy = store.get(this, "resGroups.sortBy");
		this.sortDesc = store.getDefault(this, "resGroups.sortDesc", false);
	},

	watch: {
		sortBy() {
			store.set(this, "resGroups.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "resGroups.sortDesc", this.sortDesc);
		},
	},

	methods: {
		provider(ctx) {
			return ResGroupService.listResGroups(ctx)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.loading = false;
					return resp.data.res_groups;
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to load resolution groups", err);
					this.loading = false;
					return [];
				});
		},

		removeResGroup(resGroup) {
			resGroup.inProgress = true;
			ResGroupService.removeResGroup(resGroup.id)
				.then((resp) => {
					notie.info("Resolution group deleted");
					this.refreshTable();
					resGroup.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error("Failed to delete resolution group", err);
					resGroup.inProgress = false;
					this.$forceUpdate();
				});
			this.deleting = false;
		},

		refreshTable() {
			this.$refs.table.refresh();
		},
	},
};
</script>
