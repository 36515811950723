<template>
	<div>
		<div v-for="(media, i) in copyleaksMedia" :key="i">
			<hr v-if="i > 0" class="my-2" style="margin-left: -12px; margin-right: -12px" />
			<div class="d-flex flex-row flex-wrap align-items-center mb-2">
				<h5 class="flex mb-0 mr-2" style="max-width: 100%">
					<template v-if="copyleaksMedia.length == 1">
						{{ response.ref_id }}
					</template>
					<template v-else>
						{{ media.label || `Page ${i + 1}` }}
					</template>
				</h5>
				<button
					v-if="media.copyleaks.status == 5"
					class="btn btn-xs btn-primary"
					style="font-size: 1rem"
					@click="goToCopyleaksReport(i)"
				>
					<i class="fas fa-file-pdf mr-2" />{{ $t("Copyleaks.full_report") }}
				</button>
				<div
					v-else-if="media.copyleaks.status == 4 && !media.copyleaks.error"
					class="d-flex flex-column justify-content-center align-items-center"
				>
					<div>{{ $t("Copyleaks.status_completed_scan") }}</div>
				</div>
				<div
					v-else-if="media.copyleaks.error"
					class="d-flex flex-column justify-content-center align-items-center"
				>
					<div>{{ $t("Copyleaks.report_failed") }}:</div>
					<div>{{ media.copyleaks.error }}</div>
				</div>
			</div>
			<table v-if="media.copyleaks.status == 5 || media.copyleaks.status == 4" class="table-bordered">
				<tr>
					<td class="px-2">{{ $t("Copyleaks.identical_words") }}</td>
					<td class="b-l px-2 text-center _600">{{ media.copyleaks.identical_words }}</td>
				</tr>
				<tr>
					<td class="px-2">{{ $t("Copyleaks.minor_changed_words") }}</td>
					<td class="b-l px-2 text-center _600">{{ media.copyleaks.similar_words }}</td>
				</tr>
				<tr>
					<td class="px-2">{{ $t("Copyleaks.paraphrased_words") }}</td>
					<td class="b-l px-2 text-center _600">{{ media.copyleaks.paraphrased_words }}</td>
				</tr>
				<tr>
					<td class="b-t px-2 _600">{{ $t("Copyleaks.overall_similarity_score") }}</td>
					<td class="b-t b-l px-2 text-center _600">{{ media.copyleaks.match_percent }}%</td>
				</tr>
			</table>
			<div
				v-else-if="media.copyleaks.status == 3"
				class="d-flex flex-column justify-content-center align-items-center"
			>
				<div>{{ $t("Copyleaks.status_error") }}</div>
				<div>{{ media.copyleaks.error }}</div>
			</div>
			<div
				v-else-if="media.copyleaks.status == 2"
				class="d-flex flex-column justify-content-center align-items-center"
			>
				<div>{{ $t("Copyleaks.status_rejected") }}</div>
			</div>
			<div
				v-else-if="media.copyleaks.status == 1"
				class="d-flex flex-column justify-content-center align-items-center"
			>
				<div>{{ $t("Copyleaks.status_accepted") }}</div>
			</div>
			<div
				v-else-if="media.copyleaks.status == 0"
				class="d-flex flex-column justify-content-center align-items-center"
			>
				<div>{{ $t("Copyleaks.status_not_submitted") }}</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import ResponseMediaService from "@/services/ResponseMediaService";
import Notie from "@/services/NotieService";

export default {
	name: "CopyleaksResults",
	props: ["response"],

	data() {
		return {};
	},
	created() {},

	computed: {
		copyleaksMedia() {
			let results = [];
			for (let media of this.response.media) {
				if (media.copyleaks) {
					results.push(media);
				}
			}
			return results;
		},
	},

	watch: {},
	methods: {
		goToCopyleaksReport(mediaIndex) {
			ResponseMediaService.getCopyleaksReportURL(this.response.id, mediaIndex)
				.then((r) => {
					window.open(r.data, "_blank");
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to open Copyleaks report", e);
				});
		},
	},
};
</script>
