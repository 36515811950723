<template>
	<div class="d-flex flex-column flex h100">
		<navbar></navbar>
		<div class="app" style="overflow: hidden">
			<sidenav></sidenav>
			<div class="app-content box-shadow-3 d-flex flex w-100" style="overflow: hidden">
				<div class="navbar navbar-expand-lg b-b">
					<!-- Page title -->
					<div class="navbar-text nav-title d-flex flex-row align-items-center" id="pageTitle">
						<i class="fas fa-table mr-2 text-muted" />
						<span class="text-muted">Custom Config Sheets</span>
						<span class="far fa-fw fa-angle-right text-muted"></span>
						<span v-if="!loading">Item Spreadsheet</span>
						<loading type="header" v-if="loading" />
					</div>
				</div>

				<!-- Page Content -->
				<div
					class="d-flex flex flex-row h100 mb-auto align-items-stretch"
					style="background-color: white; overflow: auto"
				>
					<div id="content" class="flex d-flex flex-column spread-bg" style="overflow: hidden">
						<div class="spread-container" ref="container">
							<!-- <div class="d-flex flex-row w-100">
								<div class="spread-formula-header">
									<i class="spread-formula-icon fas fa-lg fa-function" />
									<input type="text" readonly class="spread-formula-box" :id="`formulaTextBox-${_uid}`" />
								</div>
								<button
									v-show="edited"
									v-b-popover
									:id="`spreadsheet-edited-${page.id}`"
									class="btn btn-danger edited-button"
								>
									{{ $t("Viewer.edited") }}<i class="fas fa-info-circle ml-1" />
								</button>
								<b-popover
									:show.sync="showEditPopover"
									:target="`spreadsheet-edited-${page.id}`"
									triggers="hover focus"
									placement="bottomleft"
								>
									<template slot="title">{{ $t("Viewer.spreadsheet_edited") }}</template>
									<div>
										<p>{{ $t("Viewer.this_spreadsheet_has_been_edited") }}</p>
										<p>{{ $t("Viewer.spreadsheet_changes_not_saved") }}</p>
										<div class="d-flex flex-row justify-content-center mb-2">
											<button class="btn btn-danger" @click="revert">{{ $t("Viewer.revert") }}</button>
										</div>
									</div>
								</b-popover>
							</div> -->
							<gc-spread-sheets
								v-if="keyLoaded"
								ref="spread"
								hostClass="spread-host"
								:allowUserEditFormula="true"
								@workbookInitialized="init"
								@cellChanged="cellChanged"
								@rangeChanged="rangeChanged"
								:tabStripVisible="false"
							>
							</gc-spread-sheets>
							<div v-if="loading" class="busy-overlay d-flex flex-column justify-content-center">
								<loading type="page" />
							</div>
						</div>
					</div>
					<div class="side-panel d-flex scroll-y">
						<div class="w-100 d-flex flex-column">
							<div v-if="loading">
								<h5 id="loadingMessage">Page loaded</h5>
							</div>

							<template v-if="Object.keys(changedCells).length > 0">
								<div class="text-primary">
									<i class="fas fa-table mr-2" />{{
										$t("ConfigSheets.num_rows_changed", { num: numRowsChanged() })
									}}
								</div>
								<hr class="w-100 my-2" />
							</template>

							<div v-if="getCellErrors().length == 0 && !anyNonuniqueErrors()" class="text-muted">
								({{ $t("ConfigSheets.no_errors") }})
							</div>
							<div v-else>
								<h5>{{ $t("ConfigSheets.errors") }}:</h5>
							</div>
							<div
								v-if="getCellErrors().length > 0"
								class="scroll-y"
								style="margin-right: -16px; padding-right: 16px"
							>
								<div v-for="error in getCellErrors()" class="mt-2 text-danger" :key="error.cellName">
									<i class="fas fa-exclamation-triangle" />
									<span class="_600">{{ error.cellName }}</span
									>:
									<span v-html="error.error"></span>
								</div>
							</div>
							<template v-for="(rowSets, col) in nonuniqueColMap">
								<div v-for="(rows, i) in rowSets" class="mt-2 text-danger" :key="`${col}-${i}`">
									<i class="fas fa-exclamation-triangle" />
									<span class="_600">{{ concatCellNames(col, rows) }}</span
									>: {{ $t("ConfigSheets.values_must_be_unique") }}
								</div>
							</template>
							<div class="mt-auto d-flex flex-row">
								<button
									v-if="dataRows.length == initialRowCount"
									class="btn btn-primary my-2"
									@click="openRowsModal"
									v-tippy
									:title="$t('ConfigSheets.add_rows')"
								>
									<i class="fas fa-plus" />
								</button>
								<button
									v-else
									class="btn btn-primary my-2"
									@click="openRowsModal"
									v-tippy
									:title="$t('ConfigSheets.adjust_rows')"
								>
									<i class="fas fa-table" />
								</button>

								<button v-if="saving" class="btn btn-secondary ml-auto my-2" disabled>
									<loading type="icon" class="mr-1" />{{ $t("buttons.saving") }}...
								</button>
								<button
									v-else-if="
										Object.keys(changedCells).length > 0 &&
										Object.keys(cellErrors).length == 0 &&
										!anyNonuniqueErrors()
									"
									class="btn btn-success ml-auto my-2"
									@click="saveSheet"
								>
									{{ $t("ConfigSheets.save_all_changes") }}
								</button>
								<button
									v-else
									class="btn btn-secondary ml-auto my-2"
									:class="{ 'btn-danger': Object.keys(cellErrors).length || anyNonuniqueErrors() }"
									disabled
								>
									{{ $t("ConfigSheets.save_all_changes") }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="rowsModal" v-model="showRowsModal" @hide="showRowsModal = false">
			<template slot="modal-header">
				<div class="mx-3">
					<h5 class="modal-title">{{ $t("ConfigSheets.adjust_rows") }}</h5>
				</div>
			</template>

			<div class="pt-3">
				<div class="d-flex flex-row">
					<div class="card h-100 p-3" style="width: 50%">
						<div class="text-lg mx-auto" style="margin-top: 22px; margin-bottom: 5px">
							{{ initialRowCount }}
						</div>
						<h5 class="text-center mt-3">{{ $t("ConfigSheets.initial_rows") }}</h5>
					</div>
					<div class="card h-100 p-3 ml-3" style="width: 50%">
						<input
							class="form-control text-lg mt-3 mx-auto"
							style="width: 100px"
							type="number"
							v-model.number="newRowCount"
							:min="initialRowCount"
						/>
						<h5 class="text-center mt-3">{{ $t("ConfigSheets.current_rows") }}</h5>
					</div>
				</div>
				<div v-if="newRowCount < dataRows.length" class="mt-3">
					<i class="fas fa-exclamation-triangle text-warning" />
					<span class="text-warning">{{
						$t("ConfigSheets.rows_will_be_removed", { num: dataRows.length - newRowCount })
					}}</span>
				</div>
				<div v-if="newRowCount > dataRows.length" class="mt-3">
					<i class="fas fa-exclamation-triangle text-success" />
					<span class="text-success">{{
						$t("ConfigSheets.rows_will_be_added", { num: newRowCount - dataRows.length })
					}}</span>
				</div>
			</div>

			<template slot="modal-footer">
				<button class="btn btn-secondary" @click="showRowsModal = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn btn-primary" @click="changeRowCountFromModal(newRowCount)">
					{{ $t("buttons.okay") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="licenseKeyModal" v-model="showLicenseKeyModal" @hide="showLicenseKeyModal = false">
			<template slot="modal-header">
				<div class="mx-3">
					<h5 class="modal-title">Custom License Key</h5>
				</div>
			</template>

			<div>
				<div class="mb-3">Set a (local) custom license key for Spread JS:</div>
				<textarea class="form-control" v-model="customLicenseKey" rows="16" />
			</div>

			<template slot="modal-footer">
				<button class="btn btn-secondary" @click="showLicenseKeyModal = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn btn-danger" @click="setLicenseKey('')">Clear Custom Key</button>
				<button class="btn btn-success" @click="setLicenseKey(customLicenseKey)">Set Custom Key</button>
			</template>
		</b-modal>

		<mzfooter></mzfooter>
	</div>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}

.spread-bg {
	background-color: rgb(245 245 245 / 100%);
}

.spread-container {
	position: relative;
	margin-top: 16px;
	margin-bottom: 16px;
	margin-left: 16px;
	margin-right: 16px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
}

.side-panel {
	width: 230px;
	height: 100%;
	bottom: 0;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 8px;
	padding-bottom: 16px;

	overflow: auto;
	background-color: #eeeeee;
	border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.invalid {
	color: red;
}

.busy-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(220 220 220 / 50%);
}
</style>

<script>
import ConfigSheetService from "@/services/ConfigSheetService";
import ItemService from "@/services/ItemService";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import "@grapecity/spread-sheets-vue";
import * as GC from "@grapecity/spread-sheets";
import SpreadJSKey from "@/services/SpreadJSKey";

import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import BB from "bluebird";
import TenantService from "@/services/TenantService";
import RubricService from "@/services/RubricService";

function equivalent(a, b) {
	if ((a == null && b == "") || (a == "" && b == null)) {
		return true;
	}
	return a == b;
}

export default {
	name: "ConfigSheetView",

	props: ["user", "params"],

	components: {},

	data() {
		return {
			fs: fs,

			xPadding: 32,
			yPadding: 32,

			keyLoaded: false,
			spread: null,
			sheetDef: null,
			columnHandlers: [],
			dataRows: [],
			initialRowCount: 0,
			nonuniqueColMap: {},
			headerRowOffset: 1,

			loading: true,
			loadingMessage: "Page loaded",
			saving: false,
			clearing: false,
			cellErrors: {},
			blankNewRows: {},
			changedCells: {},

			showRowsModal: false,
			newRowCount: 0,

			showLicenseKeyModal: false,
			customLicenseKey: SpreadJSKey.getKey(),
			client: null,
			rubrics: null,
			items: null,
		};
	},

	created() {
		//Authenticate the SpreadJS library
		SpreadJSKey.getKey().then((licenseKey) => {
			console.log("SpreadJS license key:", licenseKey);
			this.keyLoaded = true;
			GC.Spread.Sheets.LicenseKey = licenseKey;
		});

		window.addEventListener("resize", this.sizeToContainer);
		let viewport = document.getElementById("content");
		console.log("viewport", viewport);
		if (viewport) {
			var ro = new ResizeObserver(this.sizeToContainer);
			ro.observe(viewport);
		}

		window.addEventListener("keydown", this.handleKeypress);

		Promise.all([TenantService.getClient()])
		.then((resps) => {
			this.client = resps[0].data;
		})
		.catch((err) => {
				console.log(err);
				notie.error(this.$i18n.t("notie.load_client_fail"), err);
		});

		
	},

	destroyed() {
		window.removeEventListener("resize", this.sizeToContainer);
		window.removeEventListener("keydown", this.handleKeypress);
	},

	watch: {},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		setLoadingMessage(message) {
			let el = document.getElementById("loadingMessage");
			if (el) {
				el.innerText = message;
			}
		},

		async init(spread) {
			this.spread = spread;

			// Turn off auto-formatting for all cells; just treat them like strings
			var defaultstyle = new GC.Spread.Sheets.Style();
			defaultstyle.formatter = "@";
			let activeSheet = spread.getActiveSheet();
			if (!activeSheet) {
				console.error("Spreadsheet does not appear to have loaded properly");
				return;
			}
			activeSheet.setDefaultStyle(defaultstyle);

			let sheetDef = this.getSheetDefinition(this.params.id);
			this.sheetDef = sheetDef;
			this.loadSheetData(spread, sheetDef);

			//Prevent editing or adding new workbooks
			spread.options.newTabVisible = false;
		},

		async loadSheetData(spread, sheetDef) {
			try {
				var objs = await this.loadData(sheetDef);
				this.dataRows = objs;
				this.initialRowCount = objs.length;
			} catch (e) {
				console.error(e);
				Notie.error("Failed to load sheet data", e);
				return;
			}
			this.layoutSheetData(spread, sheetDef, objs);
			this.sizeToContainer();
		},

		async resetSheetData() {
			this.loading = true;
			this.cellErrors = {};
			this.changedCells = {};
			this.columnHandlers = [];
			let sheet = this.spread.getActiveSheet();
			let rowCount = sheet.getRowCount();
			// let colCount = sheet.getColumnCount();
			let range = sheet.getRange(0, -1, rowCount, -1);
			range.clear(GC.Spread.Sheets.StorageType.style);
			this.loadSheetData(this.spread, this.sheetDef);
		},

		getSheetDefinition(id) {
			let sheetDef = _.find(this.user.client.config_sheets, { id: id });
			if (!sheetDef) {
				Notie.error("Failed to find sheet definition", id);
			}
			return sheetDef;
		},

		async loadData(sheetDef) {
			switch (sheetDef.acts_on) {
				case "items":
					this.setLoadingMessage("Loading items...");
					this.$forceUpdate();
					let resp = await ItemService.listItems({ prefillIsScored: true });
					this.rubrics = await ConfigSheetService.getRubrics();
					this.items = resp.data.items;
					//convert
					this.items.forEach((item) => {
						if (item.blank_scoring_config.alert_id){
							let result = ''
							this.client.alerts.forEach((alert) => {
								if (alert.id == item.blank_scoring_config.alert_id){
									result = alert.code
								}
							})
							item.blank_scoring_config.alert_id = result
						}
						if (item.metadata_reliability_config && item.metadata_reliability_config.length > 0){
							let result = ''
							item.metadata_reliability_config.forEach((config) => {
								let k = config.key;
								let v = config.value;
								let p = config.percent;
								result = result + k + ',' + v + ',' + p + ';'
							});
							item.metadata_reliability_config = result
						}
						if (item.alert_ids && item.alert_ids.length > 0){
							let result = ''
							this.client.alerts.forEach((alert) => {
								item.alert_ids.forEach((alert_id) => {
								if (alert.id == alert_id){
									result = result + alert.code + ';'
								}
								});
							})
							item.alert_ids = result
						}
						if (item.qualification_config && item.qualification_config.requirements && item.qualification_config.requirements.length > 0){
							let result = ''
							item.qualification_config.requirements.forEach((config) => {
								let type = config.type;
								let r = config.requirement;
								let trait_id = "";
								let _rubric
								this.rubrics.forEach((rubric) => {
									if (rubric.id == item.rubric_id){
										_rubric = rubric
									}
								})
								
								if(config.trait_id){
									_rubric.traits.forEach((trait) => {
											if(trait.id == config.trait_id){
												trait_id = trait.name
												result = result + type + ',' + r + ',' + trait_id + ';'
											}
										})
								}else{
									result = result + type + ',' + r + ',' + trait_id + ';'
								}
							});
							item.qualification_config.requirements = result
						}
						if (item.validity_config && item.validity_config.window_rules && item.validity_config.window_rules.length > 0){
							let result = ''
							item.validity_config.window_rules.forEach((config) => {
								let rule = config.rule;
								let pass = config.pass_percent;
								let trat_pass = config.trait_pass_percent;
								let omaha_min_max = config.omaha_min_max;
								let omaha_max_non_adj = config.omaha_max_non_adj;
								let omaha_max_weighted_score_diff = config.omaha_max_weighted_score_diff;
								result = result + rule + ',' + pass + ',' + trat_pass + ',' + omaha_min_max +',' + omaha_max_non_adj +',' + omaha_max_weighted_score_diff +';'
							});
							item.validity_config.window_rules = result
						}
					});
					return this.items
				default:
					Notie.error(`Unknown config type ${sheetDef.acts_on}`);
					return;
			}
		},

		async layoutSheetData(spread, sheetDef, objs) {
			// this.dirty = false;
			// if (this.unwatch) {
			// 	this.unwatch();
			// }
			var sheet = spread.getActiveSheet();

			// First iterate through the header values to see if we need a second row for section titles
			for (let col = 0; col < sheetDef.columns.length; col++) {
				let column = sheetDef.columns[col];
				let handler = ConfigSheetService.getHandler(sheetDef.acts_on, column);
				let parts = handler.displayName.split(" - ");
				if (parts.length > 1) {
					this.headerRowOffset = 2;
					break;
				}
			}

			sheet.frozenRowCount(this.headerRowOffset);
			sheet.setRowCount(objs.length + this.headerRowOffset, GC.Spread.Sheets.SheetArea.viewport);
			sheet.setColumnCount(sheetDef.columns.length, GC.Spread.Sheets.SheetArea.viewport);
			sheet.options.isProtected = true;
			sheet.getRange(this.headerRowOffset, -1, objs.length - 1 + this.headerRowOffset, -1).locked(false);

			// for (let row = 0; row < sheet.getRowCount(GC.Spread.Sheets.SheetArea.viewport); row++) {
			// 	if (row < this.headerRowOffset) {
			// 		sheet.setValue(row, 0, "", GC.Spread.Sheets.SheetArea.rowHeader);
			// 	} else {
			// 		sheet.setValue(row, 0, `${row + 1 - this.headerRowOffset}`, GC.Spread.Sheets.SheetArea.rowHeader);
			// 	}
			// }

			this.suspendPaint(spread);
			setTimeout(() => this.layoutColumnProgressive(0, spread, sheetDef, objs, sheet), 1);
		},

		// Columns are laid out in a function that does one column at a time, and then uses a setTimeout break
		// to allow the browser DOM to update. This allows us to show loading progress message while all this
		// heavy Javascript is running.
		async layoutColumnProgressive(col, spread, sheetDef, objs, sheet) {
			let objType = sheetDef.acts_on;
			let column = sheetDef.columns[col];
			let handler = ConfigSheetService.getHandler(sheetDef.acts_on, column);
			// console.log("hander", column, handler);
			this.columnHandlers.push(handler);
			if (handler.init) {
				await handler.init();
			}

			this.setLoadingMessage(`Populating column: ${handler.displayName}`);
			this.$forceUpdate();

			let headerParts = handler.displayName.split(" - ");
			if (headerParts.length == 1) {
				sheet
					.getCell(this.headerRowOffset - 1, col)
					.text(headerParts[0])
					.font("bold normal 15px normal");
			} else if (headerParts.length == 2) {
				sheet
					.getCell(this.headerRowOffset - 2, col)
					.text(headerParts[0])
					.font("bold normal 15px normal");
				sheet
					.getCell(this.headerRowOffset - 1, col)
					.text(headerParts[1])
					.font("bold normal 15px normal");
			}

			for (let row = 0; row < objs.length; row++) {
				let obj = objs[row];
				sheet.setValue(row + this.headerRowOffset, col, handler.toFieldValue(objType, obj));
				if (ConfigSheetService.shouldProtectField(objType, obj, column)) {
					sheet.getCell(row + this.headerRowOffset, col).locked(true);
					this.setCellBgColor(sheet, row + this.headerRowOffset, col, "#f0f0f0");
				}
			}

			sheet.autoFitColumn(col);

			if (col + 1 < sheetDef.columns.length) {
				setTimeout(() => {
					this.layoutColumnProgressive(col + 1, spread, sheetDef, objs, sheet);
				}, 1);
			} else {
				this.loading = false;
				this.resumePaint(spread);
			}
		},

		cellChanged(eventType, e) {
			if (this.loading) {
				return;
			}
			if (e.propertyName != "value") {
				return;
			}
			console.log("Change cell", e.row, e.col, "from", e.oldValue, "to", e.newValue);

			let changedCells = [{ row: e.row, col: e.col }];
			this.rangeChanged("cellChanged", { changedCells: changedCells });
		},

		rangeChanged(eventType, e) {
			if (this.clearing) {
				return;
			}
			if (this.loading) {
				return;
			}
			this.clearing = true;
			this.$nextTick(() => (this.clearing = false));
			console.log("RANGE CHANGED", eventType, e);
			var sheet = this.spread.getActiveSheet();
			this.suspendPaint(sheet);

			console.log("CHANGED CELLS BEFORE", e.changedCells);
			e.changedCells = this.includeConflictCells(e.changedCells);
			console.log("CHANGED CELLS AFTER", e.changedCells);

			let mustCheckUniqueColumns = {};
			for (let cc of e.changedCells) {
				console.log("check changed", cc);
				let newValue = sheet.getValue(cc.row, cc.col);
				let mcuCol = this.applyCellChange(sheet, cc.row, cc.col, newValue);
				if (mcuCol != undefined) {
					mustCheckUniqueColumns[mcuCol] = true;
				}
			}

			for (let mcu in Object.keys(mustCheckUniqueColumns)) {
				this.checkUnique(sheet, mcu);
			}

			this.resumePaint(sheet);
			this.$forceUpdate();
		},

		applyCellChange(sheet, row, col, newVal) {
			console.log("APPLY CELL CHANGE", row, col, newVal);
			if (row == "00") {
				console.error("Row '00' effected, which is unexpected, ignoring it");
				return;
			}

			let objType = this.sheetDef.acts_on;

			if (row - this.headerRowOffset >= this.initialRowCount) {
				let rowContents = this.getRowContents(sheet, row);
				if (!this.hasAnyContent(rowContents)) {
					this.blankNewRows[`${row + 1}`] = "New row has no content";
				} else {
					delete this.blankNewRows[`${row + 1}`];
				}
			}

			let { columnHandler, rowObj } = this.getCellData(row, col);
			let { valid, errMsg } = columnHandler.validate(objType, rowObj, newVal);
			let cellName = this.getCellName(row, col);

			if (valid) {
				delete this.cellErrors[cellName];
				if (row < this.initialRowCount && equivalent(newVal, columnHandler.toFieldValue(objType, rowObj))) {
					sheet.getCell(row, col).clear(GC.Spread.Sheets.StorageType.style);
					delete this.changedCells[cellName];
				} else {
					this.setCellBgColor(sheet, row, col, "#ccddff");
					this.changedCells[cellName] = true;
				}
			} else {
				this.cellErrors[cellName] = errMsg;
				this.setCellBgColor(sheet, row, col, "#ffaaaa");
			}

			if (columnHandler.enforceUnique) {
				return col;
			}
		},

		checkUnique(sheet, col) {
			this.nonuniqueColMap[col] = [];

			const rowCount = sheet.getRowCount();
			let cellValues = {};
			for (let row = this.headerRowOffset; row < rowCount; row++) {
				let val = sheet.getValue(row, col);
				console.log("check unique row", row, col, val);
				if (val == null) {
					continue;
				}
				if (!cellValues[val]) {
					cellValues[val] = [];
				}
				cellValues[val].push(row);
			}
			console.log("UNIQUE CELL VALUES", cellValues);
			for (let val of Object.keys(cellValues)) {
				if (cellValues[val].length > 1) {
					let cellNames = [];
					for (let row of cellValues[val]) {
						cellNames.push(this.getCellName(row, col));
						this.setCellBgColor(sheet, row, col, "#ffbbaa");
					}
					this.nonuniqueColMap[col].push(cellValues[val]);
				}
			}
		},

		getCellData(row, col) {
			let columnHandler = this.columnHandlers[col];
			let rowObj = this.dataRows[row - this.headerRowOffset];
			return { columnHandler: columnHandler, rowObj: rowObj };
		},

		getCellName(row, col) {
			let colName = fs.spreadsheetColLetter(col);
			return `${colName}${row + 1}`;
		},

		concatCellNames(col, rows) {
			let cellNames = [];
			for (let row of rows) {
				cellNames.push(this.getCellName(row, col));
			}
			if (!cellNames || cellNames.length < 1) {
				return "ERROR";
			}
			if (cellNames.length == 1) {
				return cellNames[0];
			}
			let str = cellNames.slice(0, -1).join(", ");
			return str + " & " + cellNames[cellNames.length - 1];
		},

		anyNonuniqueErrors() {
			for (let rowSets of Object.values(this.nonuniqueColMap)) {
				if (rowSets && rowSets.length > 0) {
					return true;
				}
			}
			return false;
		},

		transformBackToArray(docs) {
			Object.entries(docs).forEach((entry) => {
				const [key, value] = entry;
				if(value["metadata_reliability_config"]){
					let configArray = value["metadata_reliability_config"].split(';');
					let configArrayCounter = 1
					let ar = []
					configArray.forEach((config) => {
						let values = config.split(',');
						let c = {}
						if (configArray.length > configArrayCounter){
							c.id = crypto.randomUUID();
							c.key = values[0]
							c.value = values[1]
							c.percent = parseInt(values[2])
							ar.push(c)
						}
						configArrayCounter = configArrayCounter + 1
					})
					value["metadata_reliability_config"] = ar
				}
				if(value["validity_config.window_rules"]){
					let configArray = value["validity_config.window_rules"].split(';');
					let configArrayCounter = 1
					let ar = []
					configArray.forEach((config) => {
						let values = config.split(',');
						let c = {}
						if (configArray.length > configArrayCounter){
							c.rule = values[0]
							c.pass_percent = parseInt(values[1])
							c.trait_pass_percent = parseInt(values[2])
							c.omaha_min_max = Boolean(values[3])
							c.omaha_max_non_adj = parseInt(values[4])
							c.omaha_max_weighted_score_diff = Double(values[5])
							ar.push(c)
						}
						configArrayCounter = configArrayCounter + 1
					})
					value["validity_config.window_rules"] = ar
				}
				if(value["alert_ids"]){
					let flagCodeArray = value["alert_ids"].split(';');
					let ar = []
					flagCodeArray.forEach((flagCode) => {
						this.client.alerts.forEach((alert) => {
							if (alert.code == flagCode){
								ar.push(alert.id)
							}
						})
					})
					value["alert_ids"] = ar
				}
				if(value["qualification_config"]){
					let qualification_config = value["qualification_config"];
					let requirements = qualification_config.requirements
					let configArray = [];
					if(requirements){
						configArray = requirements.split(';');
					}
					let ar = [];
					let configArrayCounter = 1;
					configArray.forEach((config) => {
						let values = config.split(',');
						let c = {}
						if (configArray.length > configArrayCounter){
							c.id = crypto.randomUUID();
							c.type = values[0];
							c.applies_to = 0;
							c.window = 0;
							c.requirement = parseInt(values[1]);
							c.trait_id = values[2];
							let _rubric
							let _item
							if(c.trait_id){
								this.items.forEach((item) => {
									if(item.id == key){
										_item = item
									}
								})
								this.rubrics.forEach((rubric) => {
									if (rubric.id == _item.rubric_id){
										_rubric = rubric
									}
								})
								_rubric.traits.forEach((trait) => {
										if(trait.name == c.trait_id){
											c.trait_id = trait.id
										}
									})
							}
							ar.push(c)
						}
						configArrayCounter = configArrayCounter + 1
					})
					value["qualification_config"].requirements = ar
				}
				if(value["validity_config"]){
					let validity_config = value["validity_config"];
					let window_rules = validity_config.window_rules;
					let configArray = [];
					if(window_rules){
						configArray = window_rules.split(';');
					}
					let ar = [];
					let configArrayCounter = 1;
					configArray.forEach((config) => {
						let values = config.split(',');
						let c = {}
						if (configArray.length > configArrayCounter){
							c.rule = values[0];
							c.pass_percent = parseInt(values[1]);
							c.trat_pass = Boolean(values[2]);
							c.omaha_min_max = parseInt(values[3]);
							c.omaha_max_non_adj = parseInt(values[4]);
							c.omaha_max_weighted_score_diff = parseInt(values[5]);
							ar.push(c)
						}
						configArrayCounter = configArrayCounter + 1
					})
					value["validity_config"].window_rules = ar
				}

				if (value["blank_scoring_config"]){
					let result = ''
					this.client.alerts.forEach((alert) => {
						if (alert.code == value["blank_scoring_config"].alert_id){
							result = alert.id
						}
					})
					value["blank_scoring_config.alert_id"] = result
				}
		})
	},

		async saveSheet() {
			let { updateDocs, createDocs } = this.createUpdateDocs();
			console.log("UPDATE DOCS", updateDocs);
			console.log("CREATE DOCS", createDocs);
			this.transformBackToArray(updateDocs);
			switch (this.sheetDef.acts_on) {
				case "items":
					return this.saveItems(updateDocs, createDocs);
				default:
					Notie.error(`Unknown config type ${this.sheetDef.acts_on}`);
					return;
			}
		},

		async saveItems(updateDocs, createDocs) {
			this.saving = true;
			this.$forceUpdate();
			try {
				await ItemService.bulkUpdateItems(updateDocs);
			} catch (e) {
				console.log({ ERRORTHING: e });
				console.error(e);
				if (e.response && e.response.data && e.response.data.errors) {
					Notie.error("Failed to update items", e.response.data.errors.join("\n"));
				} else {
					Notie.error("Failed to update items", e);
				}
				this.saving = false;
				return;
			}
			for (let createDoc of createDocs) {
				try {
					await ItemService.saveItem(createDoc.doc);
				} catch (e) {
					console.error(e);
					Notie.error(`Failed to create new item from row ${createDoc.row + 1}`, e);
					this.saving = false;
					return;
				}
			}
			this.saving = false;
			this.resetSheetData();
			return;
		},

		createUpdateDocs() {
			let updateDocs = {};
			let createDocs = [];
			let sheet = this.spread.getActiveSheet();
			const rowCount = sheet.getRowCount();

			for (let row = this.headerRowOffset; row < rowCount; row++) {
				let rowContents = this.getRowContents(sheet, row);
				if (row - this.headerRowOffset < this.initialRowCount) {
					// Could be changed or unchanged
					let { anyChanged, id, updateDoc } = this.createUpdateDoc(row, rowContents);
					if (anyChanged) {
						updateDocs[id] = updateDoc;
					}
				} else if (this.hasAnyContent(rowContents)) {
					let createDoc = this.createNewDoc(rowContents);
					createDocs.push({ row: row, doc: createDoc });
				}
			}

			return { updateDocs: updateDocs, createDocs: createDocs };
		},

		getRowContents(sheet, row) {
			let contents = [];
			for (let col = 0; col < this.columnHandlers.length; col++) {
				contents.push(sheet.getValue(row, col));
			}
			return contents;
		},

		hasAnyContent(rowContents) {
			for (let cellContents of rowContents) {
				if (cellContents != null) {
					return true;
				}
			}

			return false;
		},

		createUpdateDoc(row, rowContents) {
			let obj = this.dataRows[row - this.headerRowOffset];
			let objType = this.sheetDef.acts_on;
			console.log("CREATE UPDATE DOC", row, rowContents, obj);
			let anyChanged = false;
			let updateDoc = {};
			for (let col = 0; col < this.columnHandlers.length; col++) {
				let handler = this.columnHandlers[col];

				let origFieldValue = handler.toFieldValue(objType, obj);
				if (rowContents[col] != origFieldValue) {
					anyChanged = true;
					handler.toUpdateDocument(objType, obj, updateDoc, rowContents[col]);
				}
			}

			return { anyChanged: anyChanged, id: obj.id, updateDoc: updateDoc };
		},

		createNewDoc(rowContents) {
			let obj = {};
			let objType = this.sheetDef.acts_on;
			let updateDoc = {};
			for (let col = 0; col < this.columnHandlers.length; col++) {
				let handler = this.columnHandlers[col];

				handler.toUpdateDocument(objType, obj, updateDoc, rowContents[col]);
			}

			return updateDoc;
		},

		sizeToContainer() {
			let spreadEl = this.$refs.spread;
			if (spreadEl) spreadEl = spreadEl.$el;
			let viewport = document.getElementById("content");
			console.log("viewport", viewport, "spreadEl", spreadEl);

			if (spreadEl && viewport) {
				let newHeight = viewport.offsetHeight - this.yPadding;
				let newWidth = viewport.offsetWidth - this.xPadding;
				console.log("newHeight", viewport.offsetHeight, this.yPadding);
				console.log("newWidth", viewport.offsetWidth, this.xPadding);
				let changedSize = this.height != newHeight || this.width != newWidth;
				if (this.spread && changedSize) {
					this.height = newHeight;
					this.width = newWidth;
					spreadEl.style.height = this.height + "px";
					spreadEl.style.width = this.width + "px";
					this.spread.refresh();
				}
			}
		},

		openRowsModal() {
			this.newRowCount = this.dataRows.length;
			this.showRowsModal = true;
		},

		changeRowCountFromModal(newCount) {
			this.changeRowCount(newCount);
			this.showRowsModal = false;
		},

		changeRowCount(newCount) {
			console.log("Change row count", newCount);

			let sheet = this.spread.getActiveSheet();
			this.suspendPaint(sheet);
			sheet.setRowCount(newCount + this.headerRowOffset, GC.Spread.Sheets.SheetArea.viewport);

			let currentCount = this.dataRows.length;
			if (newCount > currentCount) {
				let changedCells = [];
				for (let row = currentCount + this.headerRowOffset; row < newCount + this.headerRowOffset; row++) {
					this.dataRows.push({});
					for (let col = 0; col < this.sheetDef.columns.length; col++) {
						changedCells.push({ row: row, col: col });
					}
				}
				this.rangeChanged("rowCountChanged", { changedCells: changedCells });
				sheet
					.getRange(currentCount + this.headerRowOffset, -1, newCount + this.headerRowOffset, -1)
					.locked(false);
			}
			if (newCount < currentCount) {
				for (let row = newCount + this.headerRowOffset; row < currentCount + this.headerRowOffset; row++) {
					this.clearRowFromChangedCells(row);
				}
				this.dataRows = this.dataRows.slice(0, newCount);

				for (let col = 0; col < this.sheetDef.columns.length; col++) {
					let column = this.sheetDef.columns[col];
					let handler = ConfigSheetService.getHandler(this.sheetDef.acts_on, column);
					if (handler.enforceUnique) {
						this.checkUnique(sheet, col);
					}
				}
			}
			this.resumePaint(sheet);
		},

		clearRowFromChangedCells(row) {
			for (let col = 0; col < this.sheetDef.columns.length; col++) {
				let cellName = this.getCellName(row, col);
				delete this.changedCells[cellName];
				delete this.cellErrors[cellName];
			}
		},

		setCellBgColor(sheet, row, col, color) {
			sheet.getCell(row, col).backColor(color);
			sheet
				.getCell(row, col)
				.setBorder(new GC.Spread.Sheets.LineBorder("lightgray", GC.Spread.Sheets.LineStyle.thin), {
					all: true,
				});
		},

		numRowsChanged() {
			let rowMap = {};
			for (let key of Object.keys(this.changedCells)) {
				let rowPart = key.replace(/[^0-9]/g, "");
				console.log("convert", key, "to", rowPart);
				rowPart = parseInt(rowPart);
				rowMap[rowPart] = true;
			}
			return Object.keys(rowMap).length;
		},

		suspendPaint(spread) {
			// return;
			spread.suspendPaint();
		},

		resumePaint(spread) {
			spread.resumePaint();
		},

		getCellErrors() {
			let showErrors = [];

			for (let cellName of Object.keys(this.cellErrors)) {
				let rowPart = cellName.replace(/[^0-9]/g, "");
				if (!this.blankNewRows[rowPart]) {
					showErrors.push({ cellName: cellName, error: this.cellErrors[cellName] });
				}
			}

			for (let rowName of Object.keys(this.blankNewRows)) {
				showErrors.push({ cellName: rowName, error: this.blankNewRows[rowName] });
			}

			return showErrors;
		},

		// Extend the range of changed cells to include any cells that the defined cells are in conflict with
		// (due to a uniqueness restraint on the column, i.e. in the case of ref_id)
		includeConflictCells(changedCells) {
			console.log("includeConflictCells", changedCells);
			// First, take the array format of changed cells and convert it to a column/row map
			let cellMap = {};
			for (let cc of changedCells) {
				console.log("cc", cc);
				if (!cellMap[cc.col]) {
					cellMap[cc.col] = {};
				}
				cellMap[cc.col][cc.row] = true;
			}

			// Iterate through all columns in the map
			for (let c of Object.keys(cellMap)) {
				// Only continue if there are current non-unique conflicts for the column
				if (this.nonuniqueColMap[c]) {
					// Iterate through all rows that have been defined in the change set
					for (let r of Object.keys(cellMap[c])) {
						// Iterate through all sets of rows involved in a non-unique conflict
						for (let rowSet of this.nonuniqueColMap[c]) {
							// If any row in the conflicted rowSet is a row in the change set...
							let found = false;
							for (let row of rowSet) {
								if (row == r) {
									found = true;
								}
							}
							// Then add all rows in the conflicted rowSet to the map of cols and rows in the change set
							if (found) {
								for (let row of rowSet) {
									cellMap[c][row] = true;
								}
							}
						}
					}
				}
			}

			// Finally, convert the col/row map back to the array format
			let newChangedCells = [];
			for (let c of Object.keys(cellMap)) {
				console.log("CELL MAP C", c, cellMap[c]);
				for (let r of Object.keys(cellMap[c])) {
					console.log("CELL MAP R", c, r);
					newChangedCells.push({ row: parseInt(r), col: parseInt(c) });
				}
			}
			return newChangedCells;
		},

		handleKeypress(e) {
			if ((e.altKey == true || e.metaKey == true) && (e.key == "b" || e.keyCode == 66)) {
				this.showLicenseKeyModal = !this.showLicenseKeyModal;
			}
		},

		setLicenseKey(key) {
			this.customLicenseKey = key;
			SpreadJSKey.setKey(key);
			window.location.reload(true);
		},
	},
};
</script>
