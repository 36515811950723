<template>
	<div class="row">
		<div
			v-if="element.qual_location"
			class="col-5 mb-2"
			:class="{
				invalid: !element.qual_location.section_id,
				'invalid-b': !element.qual_location.section_id,
			}"
		>
			<label>{{ $t("fields.section") }}</label>
			<config-select
				:options="sections"
				v-model="element.qual_location.section_id"
				hideNone
				byField="id"
				@input="sectionChangedQual"
				:disabled="course.isCompleted"
			/>
		</div>
		<div
			v-if="element.qual_location"
			class="col-5 mb-2"
			:class="{
				invalid: !element.qual_location.item_id,
				'invalid-b': !element.qual_location.item_id,
			}"
		>
			<label>{{ $t("fields.item") }}</label>
			<config-select
				:options="element.meta('items')"
				v-model="element.qual_location.item_id"
				hideNone
				byField="id"
				@input="itemChangedQual"
				:disabled="course.isCompleted || !element.qual_location.section_id"
			/>
		</div>
		<div class="col-10 mt-3 mb-2 align-self-end">
			<div v-if="element.qual_location.item_id" class="border px-3 py-2">
				<loading v-if="!element.meta('sets')" type="table" class="text-muted" />
				<div v-else>
					<div class="d-flex flex-row align-items-center">
						<h5 class="flex mb-0">
							{{
								$tc("data_description.num_sets", element.meta("sets").length, {
									num: element.meta("sets").length,
								})
							}}
						</h5>
						<button
							class="btn btn-icon btn-rounded btn-subtle"
							@click="toggleDetails(element)"
							v-tippy
							:title="$t('TrainingEdit.Details.details')"
						>
							<i
								class="far fa-angle-up anim-rotate"
								:class="{ 'rotate-180': element.meta('showDetails') }"
							/>
						</button>
					</div>
					<div v-if="element.meta('showDetails')">
						<hr class="my-2" />
						<div v-for="(set, i) in element.meta('sets')" :key="set.id" class="d-flex flex-row pb-2">
							<div class="flex">
								{{ i + 1 }}. {{ set.name }}
								<span class="text-muted">(Pass Percent {{ set.pass_percent }}%)</span>
							</div>
						</div>
						<button class="btn btn-primary mr-2 mb-1" @click="goToQualification(element)">
							{{ $t("TrainingEdit.Details.configure_sets") }}
						</button>
						<hr />
						<h5>
							{{ $t("TrainingEdit.Details.requirements") }}
						</h5>
						<div v-if="element.meta('item') && element.meta('item').qualification_config" class="mb-2">
							<template v-if="!element.meta('item').qualification_config.enabled">
								<h5 class="mb-0">Qualification not configured</h5>
							</template>
							<template v-else>
								<div class="row">
									<div class="col-4">
										<label class="_600"> {{ $t("ItemEdit.Quality.qualification.must_see") }} </label
										>:
										{{ element.meta("item").qualification_config.must_see }}
									</div>
									<div class="col-4">
										<label class="_600">
											{{ $t("ItemEdit.Quality.qualification.must_pass") }} </label
										>:
										{{ element.meta("item").qualification_config.must_pass }}
									</div>
									<div class="col-4">
										<span v-if="element.meta('item').qualification_config.lock_on_fail">
											{{ $t("ItemEdit.Quality.qualification.lock_on_fail") }}
										</span>
									</div>
									<div class="col-12">
										<div class="_600">
											{{ $t("ItemEdit.Quality.qualification.cross_set_requirements") }}:
										</div>
										<div
											v-for="req in element.meta('item').qualification_config.requirements"
											:key="req.id"
										>
											{{ getRequirementText(element.meta("item"), req) }}
										</div>
									</div>
								</div>
							</template>
						</div>
						<button class="btn btn-primary mr-2 mb-1" @click="goToQualificationSettings(element)">
							{{ $t("TrainingEdit.Details.configure_requirements") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
button.btn-subtle {
	opacity: 1;
	transition: background-color 0.25s;
	min-width: 28px;
	width: 28px;
	height: 28px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	padding: 0;
}

button.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
</style>

<script>
import BB from "bluebird";

import ConfigService from "@/services/ConfigService";
import QCService from "@/services/QCService";
import QCConfigService from "@/services/QCConfigService";

import Store from "@/services/Store";
import Notie from "@/services/NotieService";

import ConfigSelect from "@/components/ConfigSelect";

import { QC_TYPES } from "@/services/Constants";
const { QUALIFICATION } = QC_TYPES;

export default {
	names: "DetailsQualification",

	components: {
		ConfigSelect,
	},

	props: {
		course: Object,
		element: Object,
		sections: Array,
	},

	created() {
		const { element } = this;
		if (!element.qual_location) {
			element.qual_location = {};
		}
		const {
			qual_location: { section_id, item_id },
		} = element;
		if (section_id) {
			this.sectionChangedQual();
		}
		if (item_id) {
			this.itemChangedQual();
		}
	},

	methods: {
		getRequirementText(item, req) {
			if (!(item && item.rubric && item.rubric.traits && item.rubric.traits.length)) {
				console.warn("No rubric present for qualification requirements");
				return "no rubric";
			}

			return QCService.getRequirementText(this.$i18n, item.rubric, req);
		},

		sectionChangedQual() {
			const { element } = this;
			if (!element.qual_location) {
				console.error("Malformed element has no qual_location field");
				return;
			}
			ConfigService.getSection(element.qual_location.section_id)
				.then((r) => {
					element.setMeta("items", r.data.items);
					let item = _.find(element.meta("items"), { id: element.qual_location.item_id });
					if (!item) {
						element.qual_location.item_id = null;
					}

					this.$forceUpdate();
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get section", e);
				});
		},

		itemChangedQual() {
			const { element } = this;
			if (!element.qual_location) {
				console.error("Malformed element has no qual_location field");
				return;
			}

			element.setMeta("sets", null);
			BB.props({
				sets: QCConfigService.listQCSets(
					element.qual_location.section_id,
					element.qual_location.item_id,
					QUALIFICATION.id
				),
				item: ConfigService.getItem(element.qual_location.item_id),
			})

				.then((r) => {
					element.setMeta("sets", r.sets.data);
					element.setMeta("item", r.item.data);

					this.$forceUpdate();
				})
				.catch((e) => {
					console.error(e);
					Notie.error("Failed to get qualification sets", e);
				});
			this.$forceUpdate();
		},

		goToQualification(element) {
			if (!element.qual_location) {
				console.error("Malformed element has no qual_location field");
				return;
			}
			Store.set(this, "audit.r.selectedSection", element.qual_location.section_id);
			Store.set(this, "audit.r.selectedItem", element.qual_location.item_id);
			Store.set(this, "qc.setup.qc_type", QUALIFICATION.id);
			this.$router.push("/qc_setup");
		},

		goToQualificationSettings(element) {
			if (!element.qual_location) {
				console.error("Malformed element has no qual_location field");
				return;
			}
			this.$router.push(`/items/${element.qual_location.item_id}?tab=6`);
		},

		toggleDetails(element) {
			element.setMeta("showDetails", !element.meta("showDetails"));
			this.$forceUpdate();
		},
	},
};
</script>
