import axios from "axios";

export default {
	getExternalLink(linkID) {
		return axios.get(`/v1/external_link/${linkID}`)
	},

	getExternalLinkData(linkID) {
		return axios.get(`/v1/external_link_data/${linkID}`)
	},

	createExternalLink(component, meta, accessLimit, durationHours) {
		let ctx = {
			component: component,
			meta: meta,
			access_limit: accessLimit,
			duration_hours: durationHours,
		}

		return axios.post(`/v1/create_link`, ctx)
	}
};
