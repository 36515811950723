<template>
	<div class="position-relative bar" :style="{background: gradient}">
		<div class="value-marker" :style="{left: `calc(${valuePer}% - 1px)`}">

		</div>
	</div>
</template>

<style scoped>
.bar {
	border-radius: 0.5rem;
	height: 10px;
	border: 2px solid rgba(50, 70, 90, 0.2);
}
.value-marker {
	position: absolute;
	top: -1px;
	bottom: -1px;
	width: 2px;
	background-color: rgba(50, 70, 90, 0.4);
}
</style>
<script>
export default {
	name: "ExceptionGauge",
	props: ["min", "max", "safeMin", "safeMax", "value"],

	data() {
		return {};
	},
	created() {},
	watch: {},
	computed: {
		range() {
			return this.max - this.min;
		},
		safeMinPer() {
			return (this.safeMin - this.min) * 100 / this.range;
		},
		safeMaxPer() {
			return (this.safeMax - this.min) * 100 / this.range;
		},
		valuePer() {
			return (this.value - this.min) * 100 / this.range;
		},
		gradient() {
			let us = "rgba(255, 0, 0, 0.3)"; //unsafe zone color
			let s = "rgba(0, 128, 0, 0.4)"; //safe zone color
			let sl = `${this.safeMinPer}%`; //lower edge of safe zone
			let su = `${this.safeMaxPer}%`; //upper edge of safe zone
			return `linear-gradient(to right, ${us} 0%, ${us} ${sl}, ${s} ${sl}, ${s} ${su}, ${us} ${su}, ${us} 100%)`;
		}
	},
	methods: {}
};
</script>
