<template>
	<div class="h-100">
		<div class="d-flex flex-row">
			<div class="flex">
				<h5 class="_600">
					<span class="text-muted float-right">
						<a @click="loadData" class="no-select btn-subtle" v-tippy title="Refresh">
							<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
						</a>
					</span>
					<span class="text-muted float-right px-3">
						<a @click="csvExport" class="no-select btn-subtle" v-tippy title="CSV Export">
							<i class="fa fa-download text-sm"></i>
						</a>
					</span>

					<span v-if="totalScoresWeek">{{ totalScoresWeek.toLocaleString() }}</span>
					<span v-if="!totalScoresWeek" class="text-very-muted">——</span>
				</h5>
				<h6 class="text-muted mb-0">{{ $t("fields.scores") }}</h6>
			</div>
		</div>
		<div class="py-3">
			<canvas id="chart-line-2" v-chartjs="scoreChart" style="height: 200px"></canvas>
		</div>
	</div>
</template>

<style scoped>
table {
	width: 100%;
}
td {
	padding-bottom: 2rem;
}
</style>

<script>
import DashboardService from "@/services/DashboardService";
import FileService from "@/services/FileService";
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";
require("@/directives/chartjs");
import moment from "moment";

export default {
	name: "SuperDashScoreVolume",

	props: ["team", "date"],

	data() {
		return {
			fs: fs,
			busy: false,
			totalTime: 0,
			totalTimeWeek: 0,
			totalScoresWeek: 0,
			scoreChart: {
				type: "line",
				data: {
					labels: ["S", "M", "T", "W", "T", "F", "S"],
					datasets: [
						{
							label: "Scores",
							data: [],
							fill: false,
							backgroundColor: "rgba(0, 188, 212, 0.)",
							borderColor: "rgba(0, 188, 212, 0.25)",
							pointBorderColor: "rgba(0, 188, 212, 0.4)",
							borderWidth: 4,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 3,
							pointHoverRadius: 4,
							pointHoverBorderColor: "rgba(0, 188, 212, 0.8)",
							pointHoverBorderWidth: 3,
							pointRadius: [],
							pointHitRadius: 10,
							spanGaps: false,
							yAxisID: "scores",
						},
						{
							label: "Rate",
							data: [],
							fill: false,
							backgroundColor: "rgba(212, 148, 0, 0.1)",
							borderColor: "rgba(212, 148, 0, 0.25)",
							pointBorderColor: "rgba(212, 148, 0, 0.4)",
							borderWidth: 4,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 3,
							pointHoverRadius: 4,
							pointHoverBorderColor: "rgba(212, 148, 0, 0.8)",
							pointHoverBorderWidth: 3,
							pointRadius: [],
							pointHitRadius: 10,
							spanGaps: false,
							yAxisID: "time",
						},
					],
				},
				options: {
					tooltips: {
						mode: "point",
						displayColors: false,
						titleFontSize: 14,
						bodyFontSize: 14,
						callbacks: {
							title: (items, data) => {
								let i = items[0].index;
								let day = this.date.day(i);
								return `${day.format(this.$i18n.t("Dashboard.alternate_day_format"))}`;
							},
							label: (item, data) => {
								let i = item.datasetIndex;
								let j = item.index;
								let axis = data.datasets[i].yAxisID;
								let val = data.datasets[i].data[j];
								if (axis == "scores") {
									return `Scores: ${val}`;
								} else if (axis == "time") {
									return `Avg Time: ${fs.shortDuration(val)}`;
								}
							},
						},
					},
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								display: true,
							},
						],
						yAxes: [
							{
								id: "scores",
								display: false,
								ticks: {
									suggestedMin: 0,
								},
							},
							{
								id: "time",
								display: false,
								ticks: {
									suggestedMin: 0,
								},
							},
						],
					},
					legend: {
						display: false,
					},
					layout: {
						padding: {
							top: 10,
						},
					},
				},
			},
		};
	},
	created() {
		this.loadData();
	},
	watch: {
		"team.id"() {
			this.loadData();
		},

		date() {
			this.loadData();
		},
	},
	methods: {
		loadData() {
			const { team, date } = this;

			if (!(team && date)) {
				return;
			}

			this.busy = true;
			DashboardService.getTeamsScoreVolume(team.id, date)
				.then((resp) => {
					this.loadChartData(resp.data.daily_stats, resp.data.start_time, resp.data.end_time);
					this.totalTime = resp.data.total_time;
					this.totalTimeWeek = resp.data.total_time_week;
					this.totalScoresWeek = resp.data.total_scores_week;
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});
		},

		async csvExport() {
			const {
				date,
				team,
				$route: {
					meta: {
						user: { client },
					},
				},
			} = this;
			if (!(team && date)) {
				return;
			}
			const weekRange = Utils.displayWeekRange(moment(date).startOf("week"), moment(date).endOf("week"));
			const fileName = `${client.name}_${team.name}_${weekRange}_${moment().format("M.D.YY-H.mm.ss")}.csv`;
			const { data } = await DashboardService.weeklyScoresAndTimeExportCSV(date, team.id);
			FileService.downloadFile(fileName, data, "text/csv;charset=utf-8;");
		},

		loadChartData(stats, start) {
			for (let d = 0; d < 7; d++) {
				let day = moment(start).day(d);
				let isFuture = day.isAfter(moment(), "day");
				let score = 0;
				let time = 0;
				if (stats && stats[d]) {
					score = stats[d].count;
					time = stats[d].time;
				}
				console.log("time", time);
				time = time / score;
				time = isNaN(time) || score === 0 ? 0 : time;
				if (isFuture) {
					score = NaN;
					time = NaN;
				}

				this.scoreChart.data.datasets[0].data[d] = score;
				this.scoreChart.data.datasets[0].pointRadius[d] = 4;

				this.scoreChart.data.datasets[1].data[d] = time;
				this.scoreChart.data.datasets[1].pointRadius[d] = 4;
			}
		},
	},
};
</script>
