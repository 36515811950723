<template>
	<div class="d-flex flex-row message-line">
		<div v-if="message.hidden" class="mx-auto text-extra-muted">Message deleted</div>
		<template v-else>
			<div class="left-space">
				<div v-if="!message.followup" class="meeting-avatar-small mx-2 mt-1">
					<h6 class="mb-0">{{ message.user.initials }}</h6>
				</div>
				<template v-else>
					<div class="message-name-small">
						{{ message.user.full_name }}
					</div>
					<div class="message-time-small">
						{{ message.created_at_short }}
					</div>
				</template>
			</div>
			<div class="flex chat-message-container" @click="handleClick">
				<div v-if="!message.followup" class="message-header">
					<div class="message-name text-theme">
						{{ message.user.full_name }}
					</div>
					<div class="message-time">
						{{ message.created_at_short }}
					</div>
				</div>
				<div class="message" v-html="message.body.text" />
			</div>
			<div class="right-space">
				<template v-if="canAdmin">
					<a class="message-action-button" data-toggle="dropdown">
						<i class="fas fa-ellipsis-v" />
					</a>
					<div class="dropdown-menu dropdown-menu-right py-0 animate fadeIn">
						<a class="dropdown-item" @click="deleteMessage">
							<i class="far fa-trash"></i>
							<span>Delete message</span>
						</a>
						<a class="dropdown-item" @click="muteChat">
							<i class="far fa-comment-slash"></i>
							<span>Remove user's chat permission</span>
						</a>
					</div>
				</template>
			</div>
		</template>
	</div>
</template>

<style scoped>
.bg-red {
	background-color: red;
}
.message-line {
	padding-top: 4px;
	padding-bottom: 4px;
}
.message-line:hover {
	background-color: rgba(0, 0, 0, 0.035);
}
.left-space {
	min-width: 56px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.right-space {
	min-width: 26px;
}
.message-header {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
}
.message-name {
	font-weight: 600;
}
.message-time {
	font-size: 0.7rem;
	opacity: 0.5;
	margin-left: 0.5rem;
	align-self: end;
	margin-bottom: 2px;
}
.message-name-small {
	max-width: 56px;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
	margin-top: -4px;
	font-size: 0.6rem;
	opacity: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.message-line:hover .message-name-small {
	opacity: 0.5;
}
.message-time-small {
	max-width: 56px;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
	margin-bottom: -4px;
	font-size: 0.6rem;
	opacity: 0;
}
.message-line:hover .message-time-small {
	opacity: 0.5;
}
.message-action-button {
	width: 23px;
	height: 23px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 0.25s;
}
.message-action-button:hover {
	background-color: rgba(0, 0, 0, 0.2);
}
.message-line:hover .message-action-button {
	opacity: 0.5;
}
</style>

<script>
//UI Components

//Libraries

//Services
import VideoMeetingService from "@/services/VideoMeetingService";
import Lightbox from "@/services/LightboxService";
import Notie from "@/services/NotieService";

export default {
	name: "ChatMessage",
	props: ["meetingID", "canAdmin", "message"],
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},

	watch: {},

	computed: {},

	methods: {
		handleClick(event) {
			if (event.target && event.target.nodeName == "IMG") {
				Lightbox.showElement(event.target);
				this.log("Show image in lightbox", event.target);
			}
		},

		async deleteMessage() {
			try {
				await VideoMeetingService.deleteChatMessage(this.meetingID, this.message._id);
			} catch (e) {
				Notie.error("Failed to delete chat message", e);
			}
		},

		async muteChat() {
			try {
				await VideoMeetingService.setAttendeeChatCapability(
					this.meetingID,
					this.message.user.attendee_id,
					"Receive"
				);
				Notie.info(`${this.message.user.full_name}'s permission to chat was disabled`);
			} catch (e) {
				Notie.error("Failed to set attendee chat capability", e);
			}
		},
	},
};
</script>
