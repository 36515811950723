import axios from "axios";

class JobRunner {
	constructor() {
		this.jobHandles = []
		this.config = null
	}

	// Return a promise that will also poll for updates every <pollInterval> milliseconds, calling
	// <updateFunc> every time. <updateFunc> receives the full response object, just like a normal
	// axios promise would - use r.data to access the content. <call> must be an endpoint that
	// returns a job ID and nothing more. The promise will resolve when job progress endpoint
	// returns a success status that is not 206 - this indicates that the job is finished.
	//
	// When <getUpdates> is false, the progress that is returned will just be a snapshot of the
	// current state of the job. When getUpdates is true, the updateFunc's data will contain an
	// array of all progress updates since the last time it was called. This can be used to implement
	// functionality like a live console.
	startJob(call, updateFunc, pollInterval, getUpdates) {
		return new Promise((resolve, reject) => {
			call.then(r => {
				console.log("Got job")
				let jobID = r.data

				let handle = setInterval(() => {
					let url = `/v1/job_progress/${jobID}`
					if (getUpdates) {
						url += "?updates=true"
					}
					axios.get(url, this.config).then(r => {
						if (r.status == 206) {
							updateFunc(r)
						} else {
							this.endJob(handle)
							if (getUpdates) {
								// The job finished with some un-read updates left,
								// so create a second phantom response to feed into the
								// updates function so that nothing gets lost
								let rUpdates = _.cloneDeep(r)
								rUpdates.data = rUpdates.data.updates
								updateFunc(rUpdates)
								r.data = r.data.result
								resolve(r)
							} else {
								resolve(r)
							}
						}
					}).catch(e => {
						this.endJob(handle)
						reject(e)
					})
				}, pollInterval)

				this.jobHandles.push(handle)
			}).catch(e => {
				reject(e)
			})
		})
	}

	endJob(handle) {
		clearInterval(handle)
		this.jobHandles = _.without(this.jobHandles, handle)
	}

	endAllJobs() {
		for (let handle of this.jobHandles) {
			clearInterval(handle)
		}
		this.jobHandles = []
	}

	setConfig(config) {
		this.config = config
	}
}

export default {
	newJobRunner() {
		return new JobRunner()
	}
}