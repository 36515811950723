<template>
	<FullCalendar
		defaultView="dayGridMonth"
		:plugins="calendarPlugins"
		:header="header"
		:events="processedEvents"
		:displayEventTime="viewMode != 'dayGridMonth'"
		@eventClick="eventClick"
		:datesRender="datesRender"
		:eventRender="eventRender"
		class="full-calendar"
		themeSystem="bootstrap"
		:height="height"
	/>
</template>

<style scoped>
</style>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import $ from "jquery";

export default {
	name: "MzCalendar",

	props: ["events", "scheduleConfigs", "project", "personal", "height", "initViewMode"],

	components: { FullCalendar },

	data() {
		return {
			calendarPlugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin],
			header: {
				left: "prev,next today",
				center: "title",
				right: "dayGridMonth,timeGridWeek,listMonth",
			},
			viewMode: this.initViewMode || "dayGridMonth",
			processedEvents: [],
		};
	},

	created() {},

	watch: {
		events() {
			this.processEvents();
		},
	},

	methods: {
		eventClick(info) {
			if (info.event.extendedProps.meta && info.event.extendedProps.meta.placeholder) {
				return;
			}
			this.$emit("eventClick", info);
		},

		// dayRender(info) {
		// 	let dateStr = info.date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
		// 	console.log("day render", this.scheduleConfigs, dateStr, this.scheduleConfigs[dateStr]);
		// 	if (this.scheduleConfigs && this.scheduleConfigs[dateStr] && this.scheduleConfigs[dateStr].locked) {
		// 		let lock = document.createElement("i");
		// 		lock.className = "fas fa-lock cal-top-left";
		// 		info.el.appendChild(lock);
		// 	}
		// },

		getStatusClasses(satisfied, filled) {
			let className = "";
			if (satisfied == -1) {
				className = "blue";
			} else if (satisfied == 0) {
				className = "red";
			} else if (satisfied == 0.5) {
				className = "yellow";
			} else if (satisfied == 1) {
				className = "green";
			}

			if (filled) {
				className = className + "-cal";
			} else {
				className = className + "-outline-lh15";
			}

			return className;
		},

		getStatusDot(satisfied) {
			let color = "";
			if (satisfied == -1) {
				color = "#2196f3";
			} else if (satisfied == 0) {
				color = "#f44336";
			} else if (satisfied == 0.5) {
				color = "#ffc107";
			} else if (satisfied == 1) {
				color = "#4caf50";
			}
			return color;
		},

		eventRender(info) {
			//Prevent placeholders from showing as clickable, and don't do any other render stuff for them
			if (info.event.extendedProps.meta.placeholder) {
				info.el.style.cursor = "default";

				if (this.viewMode != "dayGridMonth") {
					return false;
				} else {
					return true;
				}
			}

			if (this.personal && info.event.extendedProps.render && !info.event.extendedProps.render.filled) {
				//Hide available time slots in My Schedule for List mode, always
				if (this.viewMode == "listMonth") {
					return false;
				}
				//Hide available time slots in My Schedule for Week mode, if showing all projects
				if (this.viewMode == "timeGridWeek" && this.project.id == "all") {
					return false;
				}
			}

			//This makes it so HTML will render in titles
			if (info.view.type == "dayGridMonth" || info.view.type == "timeGridWeek") {
				$(info.el).find(".fc-title").html(info.event.title);
			} else if (info.view.type == "listMonth") {
				$(info.el).find(".fc-list-item-title").html(info.event.title);
			}
		},

		datesRender(info) {
			this.viewMode = info.view.type;
			this.processEvents();
			this.$emit("update:viewMode", this.viewMode);
		},

		renderSplitTitle(left, right, boldLeft, fadeRight) {
			let leftHTML = this.renderTitleEl(left, boldLeft, false, true);
			let rightHTML = this.renderTitleEl(right, false, fadeRight, false);
			return `<div class="d-flex flex-row">${leftHTML}${rightHTML}</div>`;
		},

		renderTitleEl(text, bold, fade, flex) {
			if (flex) {
				if (fade) {
					return `<span class="flex text-extra-muted">${text}</span>`;
				} else if (bold) {
					return `<strong class="flex">${text}</strong>`;
				} else {
					return `<span class="flex">${text}</span>`;
				}
			} else {
				if (fade) {
					return `<span class="text-extra-muted">${text}</span>`;
				} else if (bold) {
					return `<strong>${text}</strong>`;
				} else {
					return `<span>${text}</span>`;
				}
			}
		},

		processEvents() {
			let allProjectsMode = false;
			let shiftsMode = false;
			let personalMode = this.personal;
			if (this.project) {
				if (this.project.id == "all") {
					allProjectsMode = true;
				}
				if (this.project.use_shifts) {
					shiftsMode = true;
				}
			}

			let processed = [];
			_.each(this.events, (event) => {
				let render = event.render;
				if (personalMode && this.viewMode == "listMonth" && !render.filled) {
					//Don't even render events on the list if they're not filled
					return;
				}

				if (render.lock) {
					//Leave lock background events as they are
					processed.push(event);
					return;
				}

				if (personalMode) {
					if (this.viewMode == "dayGridMonth") {
						if (allProjectsMode) {
							event.title = render.project;
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else if (shiftsMode) {
							event.title = render.time;
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else {
							event.title = this.renderSplitTitle(render.time, render.hours, false, !render.filled);
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						}
					} else if (this.viewMode == "timeGridWeek") {
						if (allProjectsMode) {
							if (render.shiftName) {
								event.title = `${render.project}, ${render.shiftName}`;
							} else {
								event.title = render.project;
							}
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else if (shiftsMode) {
							event.title = `<strong>${render.shiftName}</strong>`;
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else {
							event.title = `${render.hours}`;
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						}
					} else if (this.viewMode == "listMonth") {
						if (allProjectsMode) {
							if (render.shiftName) {
								event.title = `${render.project}, ${render.shiftName}`;
							} else {
								event.title = render.project;
							}
							event.classNames = "";
							event.backgroundColor = this.getStatusDot(render.satisfied);
						} else if (shiftsMode) {
							event.title = render.shiftName;
							event.classNames = "";
							event.backgroundColor = this.getStatusDot(render.satisfied);
						} else {
							event.title = `${render.hours}`;
							event.classNames = "";
							event.backgroundColor = this.getStatusDot(render.satisfied);
						}
					}
				} else {
					if (this.viewMode == "dayGridMonth") {
						if (allProjectsMode) {
							event.title = render.project;
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else if (shiftsMode) {
							event.title = this.renderSplitTitle(render.time, render.users, true, !render.filled);
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else {
							event.title = this.renderSplitTitle(render.hours, render.users, true, !render.filled);
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						}
					} else if (this.viewMode == "timeGridWeek") {
						if (allProjectsMode) {
							event.title = render.project;
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else if (shiftsMode) {
							if (render.users) {
								event.title = `<strong>${render.shiftName}</strong>: ${render.users}`;
							} else {
								event.title = `<strong>${render.shiftName}</strong>`;
							}
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						} else {
							if (render.hours && render.users) {
								event.title = `${render.hours} | ${render.users}`;
							} else {
								event.title = render.hours || render.users;
							}
							event.classNames = this.getStatusClasses(render.satisfied, render.filled);
							event.backgroundColor = "";
						}
					} else if (this.viewMode == "listMonth") {
						if (allProjectsMode) {
							event.title = render.project;
							event.classNames = "";
							event.backgroundColor = this.getStatusDot(render.satisfied);
						} else if (shiftsMode) {
							if (render.users) {
								event.title = `<strong>${render.shiftName}</strong>: ${render.users}`;
							} else {
								event.title = `<strong>${render.shiftName}</strong>`;
							}
							event.classNames = "";
							event.backgroundColor = this.getStatusDot(render.satisfied);
						} else {
							if (render.hours && render.users) {
								event.title = `${render.hours} | ${render.users}`;
							} else {
								event.title = render.hours || render.users;
							}
							event.classNames = "";
							event.backgroundColor = this.getStatusDot(render.satisfied);
						}
					}
				}

				processed.push(event);
			});

			if (allProjectsMode && this.viewMode == "dayGridMonth") {
				processed = this.sortAndAlignEvents(processed);
			}

			this.processedEvents = processed;
		},

		sortAndAlignEvents(events) {
			let filledEvents = [];
			let seqsToFill = {};
			let map = {};
			_.each(events, (event) => {
				let date = event.meta.date;
				let seq = event.meta.projectSeq;
				if (!map[date]) map[date] = {};
				map[date][seq] = true;
				seqsToFill[seq] = true;
				console.log("SEQ", seq, event);

				let newEvent = _.cloneDeep(event);
				let time = this.getSlottedTime(date, seq);
				newEvent.start = time;
				newEvent.end = time;
				filledEvents.push(newEvent);
			});

			_.each(map, (filledSeqs, date) => {
				_.each(seqsToFill, (_, seq) => {
					if (!filledSeqs[seq]) {
						let newEvent = this.blankEvent(date, seq);
						filledEvents.push(newEvent);
					}
				});
			});

			return filledEvents;
		},

		blankEvent(date, seq) {
			let time = this.getSlottedTime(date, seq);
			return {
				title: "——",
				start: time,
				end: time,
				backgroundColor: "transparent",
				borderColor: "transparent",
				textColor: "transparent !important",
				meta: {
					placeholder: true,
				},
			};
		},

		//Takes a date in "L" format ("02/28/2020") and an n, and returns a date set to the n'th minute of that day
		//Used to force the events in the monthly calendar to sort how we want
		getSlottedTime(dateStr, seq) {
			let date = new Date(dateStr);
			date = new Date(date.getTime() + seq * 60000); //Add a minute for each slot

			return date;
		},
	},
};
</script>
