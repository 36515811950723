<template>
	<list-config
		:title="title"
		:elemName="$t('fields.requirement')"
		v-model="qcConfig.requirements"
		:newElem="addNewReq"
		:elemText="getRequirementText"
	>
		<template #modal="{ editingElem, ok, close }">
			<qc-req-modal :item="item" :editingQcReq="editingElem" :isVal="isVal" @ok="ok" @close="close" />
		</template>
	</list-config>
</template>

<script>
import QCService from "@/services/QCService";
import Utils from "@/services/Utils";
import QcReqModal from "./QcReqModal";
import ListConfig from "@/components/ListConfig";

export default {
	name: "QcReqConfig",

	components: {
		ListConfig,
		QcReqModal,
	},

	props: {
		item: Object,
		isVal: Boolean,
	},

	computed: {
		qcConfig() {
			const { isVal, item } = this;
			if (!item) {
				return { requirements: [] };
			}
			return isVal ? item.validity_config : item.qualification_config;
		},

		title() {
			const { isVal, $i18n } = this;
			return isVal
				? $i18n.t("ItemEdit.Quality.qualification.requirements")
				: $i18n.t("ItemEdit.Quality.qualification.cross_set_requirements");
		},
	},

	methods: {
		addNewReq() {
			return {
				id: Utils.generateUUID(),
				type: this.isVal ? "trait_exact" : "exact",
				requirement: 50,
				trait_id: null,
				new: true,
			};
		},

		getRequirementText(req) {
			if (!(this.item && this.item.rubric && this.item.rubric.traits && this.item.rubric.traits.length)) {
				console.warn("No rubric present for requirements");
				return;
			}

			return QCService.getRequirementText(this.$i18n, this.item.rubric, req);
		},
	},
};
</script>