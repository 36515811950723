<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("QCPortfolio.title") }}
					<template v-if="queryUser">
						<span class="far fa-fw fa-angle-right"></span>
						{{ queryUser.first_name }} {{ queryUser.last_name }}
					</template>
				</div>
				<!-- <div class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
							@click="attemptExport()" v-tippy="{ placement: 'bottom' }"
							:title="$t('tooltip.export_scores')">
							<i class="fas fa-lg fa-download"></i>
						</div> -->
			</div>
		</template>

		<div
			class="d-flex flex flex-row h100 mb-auto align-items-stretch"
			style="background-color: white; overflow: auto"
		>
			<div class="aside d-flex scroll-y">
				<div class="modal-dialog w-md light b-r b-t white p-2 audit-search d-flex flex-column">
					<div v-if="staticType" class="card py-2 px-3 mb-3">
						<label class="mb-0 text-muted">{{ $t("QCPortfolio.qc_type") }}</label>
						<div class="_600">{{ getQcTypeName(qcType) }}</div>
					</div>
					<div v-else class="form-group">
						<label>{{ $t("QCPortfolio.qc_type") }}</label>
						<v-select :options="qcTypes" label="name" :reduce="(o) => o.id" v-model="qcType"> </v-select>
					</div>

					<template v-if="!mineOnly">
						<div v-if="staticUser" class="card py-2 px-3 mb-3">
							<label class="mb-0 text-muted">{{ $t("QCPortfolio.user") }}</label>
							<div v-if="queryUser" class="_600">{{ fs.nameWithScorerID(queryUser) }}</div>
						</div>
						<div v-else class="form-group">
							<label>{{ $t("QCPortfolio.user") }}</label>
							<config-user-search v-if="initedUser" :clientID="user.client.id" v-model="queryUser" />
						</div>
					</template>

					<div v-if="staticSection" class="card py-2 px-3 mb-3">
						<label class="mb-0 text-muted">{{ $t("QCPortfolio.section") }}</label>
						<div v-if="selectedSection" class="_600">{{ selectedSection.name }}</div>
					</div>
					<div v-else class="form-group">
						<label>{{ $t("QCPortfolio.section") }}</label>
						<config-select
							:options="sections"
							v-model="selectedSection"
							:nullOption="true"
							nullText="All Sections & Items"
						></config-select>
					</div>

					<template v-if="selectedSection">
						<div v-if="staticItem" class="card py-2 px-3 mb-3">
							<label class="mb-0 text-muted">{{ $t("QCPortfolio.item") }}</label>
							<div v-if="selectedItem" class="_600">{{ selectedItem.name }}</div>
						</div>
						<div v-else class="form-group">
							<label>{{ $t("QCPortfolio.item") }}</label>
							<config-select
								:options="items"
								v-model="selectedItem"
								:nullOption="true"
								nullText="All Items"
							></config-select>
						</div>
					</template>

					<!-- <div
									class="btn-group align-self-end mb-2"
									role="group"
									style="height: 35px"
									aria-label="First group"
								>
									<button
										type="button"
										class="btn mb-2"
										:class="{ theme: view == 'single' }"
										style="height: 35px"
										@click="view = 'single'"
										v-tippy
										:title="$t('tooltip.preview')"
									>
										<i class="fas fa-window-maximize" />
									</button>
									<button
										type="button"
										class="btn mb-2"
										:class="{ theme: view == 'list' }"
										style="height: 35px"
										@click="view = 'list'"
										v-tippy
										:title="$t('tooltip.list')"
									>
										<i class="fas fa-th-list" />
									</button>
								</div> -->
				</div>
			</div>
			<div class="flex d-flex flex-column" style="overflow: hidden">
				<div class="flex scroll-y scroll-x">
					<template v-if="!busy">
						<div v-if="selectedSection && selectedItem && qualStat" class="card box shadow my-3 mx-4">
							<div class="text-center my-3">
								<template v-if="qualStat.overall_stat.state == 2">
									<h1 class="text-success">
										{{ $t("Dashboard.qualified") }}
									</h1>
									<div
										v-if="qualStat.overall_stat.override_user"
										style="margin-top: -12px"
										class="text-muted text-xxs"
									>
										{{
											`Manual qualification by:
																										${qualStat.overall_stat.override_user.scorer_id}`
										}}
									</div>
									<div v-if="qualStat.overall_stat.override_at" class="text-muted text-xxs">
										{{ fs.medDate(qualStat.overall_stat.override_at) }}
									</div>
								</template>
								<template v-else-if="qualStat.overall_stat.state === 3">
									<h1 class="text-danger">
										{{ $t("Dashboard.not_qualified") }}
									</h1>
								</template>
								<template v-else>
									<h1 class="text-warning">
										{{ $t("Dashboard.pending") }}
									</h1>
									<div
										v-if="qualStat.overall_stat.override_user"
										style="margin-top: -12px"
										class="text-muted text-xxs"
									>
										{{
											`Qualification removed by:
																										${qualStat.overall_stat.override_user.scorer_id}`
										}}
									</div>
									<div v-if="qualStat.overall_stat.override_at" class="text-muted text-xxs">
										{{ fs.medDate(qualStat.overall_stat.override_at) }}
									</div>
								</template>
								<template v-if="user.role.page_qc_report">
									<a class="btn btn-subtle menu-button" data-toggle="dropdown" aria-expanded="false"
										><i class="fas fa-ellipsis-v"
									/></a>
									<div
										class="dropdown-menu dropdown-menu-right"
										x-placement="bottom-end"
										style="
											position: absolute;
											transform: translate3d(-70px, 35px, 0px);
											top: 0px;
											left: 0px;
											will-change: transform;
										"
									>
										<a
											v-if="qualStat.overall_stat.state != 2"
											class="dropdown-item"
											data-toggle="modal"
											data-target="#setModal"
											@click="manualQualify"
										>
											<i class="far fa-sort-amount-down faw"></i>
											<label class="mb-0">{{
												$t("Dashboard.grant_qualification_override")
											}}</label>
										</a>
										<a
											v-if="qualStat.overall_stat.state == 2"
											class="dropdown-item"
											data-toggle="modal"
											data-target="#setModal"
											@click="manualUnqualifyConfirm"
										>
											<i class="far fa-sync faw"></i>
											<label class="mb-0">{{ $t("Dashboard.remove_qualification") }}</label>
										</a>
										<a
											class="dropdown-item"
											data-toggle="modal"
											data-target="#setModal"
											@click="deleteQualConfirm"
										>
											<i class="far fa-trash faw"></i>
											<label class="mb-0">{{ $t("QCPortfolio.qual_delete_scores") }}</label>
										</a>
									</div>
								</template>
							</div>
							<div class="text-center my-2 row">
								<div class="col-6">
									<h1>
										{{ getNumSetsCompleted(qualStat.set_stats) }}
									</h1>
									<h6 class="text-muted">
										{{ $t("Dashboard.sets_completed") }}
									</h6>
								</div>
								<div class="col-6">
									<h1>
										{{ getNumSetsPassed(qualStat.set_stats) }}
									</h1>
									<h6 class="text-muted">
										{{ $t("Dashboard.sets_passed") }}
									</h6>
								</div>
							</div>

							<table v-if="qualStat.overall_stat.requirements" class="table mb-0">
								<tr>
									<th class="v-mid">{{ $t("fields.requirement") }}</th>
									<th colspan="2" class="text-center v-mid">{{ $t("fields.result") }}</th>
								</tr>
								<tbody>
									<template v-for="(comp, i) in getRequirementComps()">
										<tr :key="i" style="height: 58px">
											<td class="v-mid">
												<h6 class="mb-0">{{ getRequirementText(comp.req) }}</h6>
												<p v-if="comp.subReq" class="mb-0 text-muted">
													({{ getSubRequirementsText(comp.subReq) }})
												</p>
											</td>
											<div v-if="comp.stat">
												<template v-if="comp.stat.drop_set_id">
													<td class="text-center v-mid">
														<h6 class="mb-0">
															{{ getSetName(comp.stat.drop_set_id) }}
														</h6>
													</td>
												</template>
												<template v-else>
													<td class="text-center v-mid">
														<h3 class="mb-0">
															{{ getRequirementValue(comp.req, comp.stat.value) }}
														</h3>
													</td>
													<td class="text-center v-mid">
														<i
															v-if="comp.stat.passed"
															class="fas fa-2x fa-check text-success"
														></i>
														<i v-else class="fas fa-2x fa-times text-danger"></i>
													</td>
												</template>
											</div>
											<div v-else>
												<td class="text-center v-mid">
													<h6 class="mb-0">
														{{ "Used by other Requirement" }}
													</h6>
												</td>
											</div>
										</tr>
									</template>
								</tbody>
							</table>
						</div>

						<div v-for="{ section, items } in groupedRows" :key="section.id">
							<div v-for="{ item, rows } in items" :key="item.id">
								<validity-rule-stats
									v-if="valSelected"
									:section="section"
									:item="item"
									:user="queryUser"
								/>
								<portfolio-set
									class="card box shadow my-3 mx-4"
									v-for="row in rows"
									:key="row.id"
									:set="row.set"
									:stat="row.stat"
									:rubric="row.rubric"
									:sectionName="row.section_name"
									:itemName="row.item_name"
									:showSection="true"
									:showItem="true"
									:hideState="valSelected"
									:queryUser="queryUser"
									:fromMyTrainingPage="fromMyTrainingPage"
								></portfolio-set>
							</div>
						</div>
						<div
							v-if="canQuery && rows.length == 0"
							class="d-flex flex-row justify-content-center w-100 my-4 text-center"
						>
							<h1 class="card box py-3 px-5">
								<span class="text-extra-muted">{{ $t("QCPortfolio.no_results_found") }}</span>
							</h1>
						</div>
					</template>
					<div v-else class="text-center py-3">
						<loading type="large" />
					</div>
				</div>
			</div>
		</div>

		<b-modal id="unqualifyConfirm" :visible="showUnqualifyConfirm" @hide="showUnqualifyConfirm = false">
			<template slot="modal-title">{{ $t("Dashboard.remove_qualification") }}</template>
			<div class="row px-4 pb-4 pt-3">
				<div class="py-3 px-2 mx-3 border modal-details-box">
					<div v-if="selectedSection" class="col-12">
						<span class="_600 mr-1">{{ $t("fields.section") }}:</span>
						<span>{{ selectedSection.name }}</span>
					</div>
					<div v-if="selectedItem" class="col-12">
						<span class="_600 mr-1">{{ $t("fields.item") }}:</span>
						<span>{{ selectedItem.name }}</span>
					</div>
					<div v-if="queryUser" class="col-12">
						<span class="_600 mr-1">{{ $t("fields.user") }}:</span>
						<span>{{ queryUser.full_name }}</span>
					</div>
				</div>
				<div class="col-12 mt-4">{{ $t("QCPortfolio.qual_reset_confirm") }}</div>
				<div class="col-12 mt-3">{{ $t("QCPortfolio.qual_reset_existing_scores") }}</div>
				<template v-if="hasMoreQualSets">
					<div class="col-12 mt-3">{{ $t("QCPortfolio.qual_reset_has_next_set_behavior") }}</div>
				</template>
				<template v-else>
					<div class="col-12 mt-3 text-danger">
						<i class="fas fa-exclamation-triangle" style="vertical-align: -1px" />
						{{ $t("QCPortfolio.qual_reset_no_next_set_behavior") }}
					</div>
				</template>
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="showUnqualifyConfirm = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="manualUnqualify">
					{{ $t("Dashboard.remove_qualification") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="deleteQualConfirm" :visible="showDeleteQualConfirm" @hide="showDeleteQualConfirm = false">
			<template slot="modal-title">{{ $t("QCPortfolio.qual_delete_scores") }}</template>
			<div class="row px-4 pb-4 pt-3">
				<div class="py-3 px-2 mx-3 border modal-details-box">
					<div v-if="selectedSection" class="col-12">
						<span class="_600 mr-1">{{ $t("fields.section") }}:</span>
						<span>{{ selectedSection.name }}</span>
					</div>
					<div v-if="selectedItem" class="col-12">
						<span class="_600 mr-1">{{ $t("fields.item") }}:</span>
						<span>{{ selectedItem.name }}</span>
					</div>
					<div v-if="queryUser" class="col-12">
						<span class="_600 mr-1">{{ $t("fields.user") }}:</span>
						<span>{{ queryUser.full_name }}</span>
					</div>
				</div>
				<div class="col-12 mt-4">{{ $t("QCPortfolio.qual_delete_confirm") }}</div>
				<div class="col-12 mt-3">{{ $t("QCPortfolio.qual_delete_remove_qual") }}</div>
				<div class="col-12 mt-3 text-danger">
					<i class="fas fa-exclamation-triangle" style="vertical-align: -1px" />
					{{ $t("QCPortfolio.qual_delete_no_undo") }}
				</div>
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="showDeleteQualConfirm = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button class="btn danger btn-flat" @click="deleteQual">
					{{ $t("QCPortfolio.delete_all") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scroll {
	overflow: auto;
}

.title-bar-button {
	margin: -8px -16px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}

.title-bar-button:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.title-bar-button a {
	line-height: 56px;
}

.menu-button {
	position: absolute;
	right: 6px;
	top: 6px;
}

.modal-details-box {
	width: calc(100% - 2.5rem);
	border-radius: 1rem;
	box-shadow:
		0 2px 2px 0 rgb(0 0 0 / 16%),
		0 2px 4px 0 rgb(0 0 0 / 12%);
	-webkit-box-shadow:
		0 2px 2px 0 rgb(0 0 0 / 16%),
		0 2px 4px 0 rgb(0 0 0 / 12%);
}
</style>

<script>
import PortfolioSet from "@/components/QC/QCPortfolio/PortfolioSet";
import ValidityRuleStats from "@/components/QC/QCPortfolio/ValidityRuleStats";

import ConfigService from "@/services/ConfigService";
import UserService from "@/services/UserService";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import ConfigSelect from "@/components/ConfigSelect";
import QCService from "@/services/QCService";
import QCConfigService from "@/services/QCConfigService";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import Utils from "@/services/Utils";
import Store from "@/services/Store";
import BB from "bluebird";
import CompensationQueueTimeService from "@/services/CompensationQueueTimeService";

import { QC_TYPES } from "@/services/Constants";
const { QUALIFICATION, VALIDITY } = QC_TYPES;
const MESSAGE = "only_one_active_tab_or_window_allowed_for_training";
var bc;

export default {
	name: "QCPortfolio",

	props: ["user"],

	components: { ConfigUserSearch, ConfigSelect, PortfolioSet, ValidityRuleStats },

	data() {
		let QC_TYPE_PRACTICE = { name: this.$i18n.t("qc_types.practice"), id: 1 };
		let QC_TYPE_QUALIFICATION = { name: this.$i18n.t("qc_types.qualification"), id: 2 };
		let QC_TYPE_VALIDITY = { name: this.$i18n.t("qc_types.validity"), id: 3 };
		let QC_TYPE_CALIBRATION = { name: this.$i18n.t("qc_types.calibration"), id: 4 };

		return {
			broadcastChannel: null,
			Utils: Utils,
			fs: fs,

			loading: true,
			initedUser: false,
			initedSection: false,
			busy: false,

			qcTypes: [QC_TYPE_PRACTICE, QC_TYPE_QUALIFICATION, QC_TYPE_VALIDITY, QC_TYPE_CALIBRATION],

			qcType: null,
			projectID: null,
			sectionID: null,
			itemID: null,
			userID: null,

			mineOnly: false,
			staticType: false,
			staticProject: false,
			staticSection: false,
			staticItem: false,
			staticUser: false,

			selectedProject: null,
			selectedSection: null,
			selectedItem: null,
			queryUser: null,

			projects: null,
			sections: null,
			items: null,
			rows: [],
			qualStat: null,
			qualSets: null,

			showUnqualifyConfirm: false,
			showDeleteQualConfirm: false,
			compensationStartTime: null,
			fromMyTrainingPage: false,
		};
	},

	created() {
		if (!this.qcType) {
			this.qcType = Store.getDefault(this, "qcPortfolio.qcType", 1);
		}
		this.showHiddenProjects = Store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		ConfigService.listSectionsShallow(ctx)
			.then((r) => {
				this.sections = r.data.sections;

				let newSection = null;
				if (this.sectionID) {
					newSection = _.find(this.sections, { id: this.sectionID });
				} else {
					newSection = Store.resolveSelectedSection(this, this.sections, "qcPortfolio.selectedSection");
				}
				if (this.selectedSection == newSection) {
					this.initedSection = true;
					this.loadRows();
				} else {
					this.selectedSection = newSection;
				}
			})
			.catch((e) => {
				console.error(e);
				Notie.error("Failed to get sections", e);
			});

		if (this.$route.path.includes("my_training")) {
			this.fromMyTrainingPage = true;
			this.broadcastChannel = new BroadcastChannel("oscar_broadcast_channel");
			this.broadcastChannel.postMessage(MESSAGE);
			this.broadcastChannel.onmessage = (event) => {
				if (event.data == MESSAGE) {
					console.log(event.data, "received, Go to Landing");
					this.$router.push("/landing");
				}
			};

			window.addEventListener("beforeunload", this.updateCompensationQueueTime);

			BB.props({
				compensationStartTime: CompensationQueueTimeService.getCompensationStartTime(),
			})
				.then((resps) => {
					this.compensationStartTime = resps.compensationStartTime.data;
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to get compensationStartTime", err);
				});
		}
	},

	watch: {
		qcType() {
			if (this.staticType) return;
			if (!this.qcType) return;
			Store.set(this, "qcPortfolio.qcType", this.qcType);

			this.loadRows();
		},

		selectedSection() {
			if (!this.selectedSection) {
				this.items = [];
				this.selectedItem = null;
				if (!this.staticSection) {
					Store.set(this, "qcPortfolio.selectedSection", null);
				}
				this.loadRows();
			} else {
				ConfigService.getSection(this.selectedSection.id)
					.then((r) => {
						if (!this.staticSection) {
							Store.set(this, "qcPortfolio.selectedSection", this.selectedSection.id);
						}

						this.items = r.data.items;

						let newItem = null;
						if (this.itemID) {
							newItem = _.find(this.items, { id: this.itemID });
						} else {
							newItem = Store.resolveSelectedItem(this, r.data, "qcPortfolio.selectedItem");
						}
						if (this.selectedItem == newItem) {
							this.loadRows();
						} else {
							this.selectedItem = newItem;
						}
						this.initedSection = true;
					})
					.catch((e) => {
						console.error(e);
						Notie.error("Failed to get items", e);
					});
			}
		},

		selectedItem() {
			if (!this.staticItem) {
				if (!this.selectedItem) {
					Store.set(this, "qcPortfolio.selectedItem", null);
				} else {
					Store.set(this, "qcPortfolio.selectedItem", this.selectedItem.id);
				}
			}

			this.loadRows();
		},

		queryUser() {
			if (!this.staticUser) {
				if (this.queryUser) {
					Store.set(this, "qcPortfolio.queryUser", this.queryUser);
				}
			}

			this.loadRows();
		},
	},

	computed: {
		groupedRows() {
			const { rows, sections } = this;
			const sectionMap = {};
			(rows || []).forEach((row) => {
				const {
					stat: { section_id, item_id },
				} = row;
				if (!sectionMap[section_id]) {
					const section = sections.find(({ id }) => id === section_id);
					if (!section) return;
					sectionMap[section_id] = {
						section,
						itemMap: {},
					};
				}
				const { section, itemMap } = sectionMap[section_id];
				if (!itemMap[item_id]) {
					itemMap[item_id] = {
						item: section.items.find(({ id }) => id === item_id),
						rows: [],
					};
				}

				itemMap[item_id].rows.push(row);
			});

			return Object.values(sectionMap).map(({ section, itemMap }) => ({
				section,
				items: Object.values(itemMap),
			}));
		},

		valSelected() {
			return this.qcType === VALIDITY.id;
		},

		canQuery() {
			return this.qcType && this.queryUser;
		},

		hasMoreQualSets() {
			if (this.qcType != 2) return false;
			if (!(this.qualStat && this.qualStat.set_stats)) return false;
			if (!this.qualSets) return false;

			let unscored = this.qualSets.length;
			console.log("sets", unscored);
			for (let stat of this.qualStat.set_stats) {
				if (stat.state == 2 || stat.state == 3) {
					// Either passed or failed, which means it's finished
					console.log("stat", stat.id);
					unscored--;
				}
			}
			console.log("result", unscored, unscored > 0);
			return unscored > 0;
		},
	},

	beforeRouteEnter(to, from, next) {
		next((vm) => {
			// parse query elements
			if (to.query.qc) {
				vm.qcType = parseInt(to.query.qc);
			}
			vm.staticType = to.query.sq;
			if (vm.staticType && !vm.qcType) {
				next(false);
				return;
			}

			vm.projectID = to.query.proj;
			vm.staticProject = to.query.sp;
			if (vm.staticProject && !vm.projectID) {
				next(false);
				return;
			}

			vm.sectionID = to.query.sect;
			vm.staticSection = to.query.ss;
			if (vm.staticSection && !vm.sectionID) {
				next(false);
				return;
			}

			vm.itemID = to.query.item;
			vm.staticItem = to.query.si;
			if (vm.staticItem && !vm.itemID) {
				next(false);
				return;
			}

			vm.userID = to.query.user;
			vm.staticUser = to.query.su;
			console.log("BEFORE ROUTE USER", vm.userID);
			if (vm.$route.params.mineOnly) {
				vm.userID = vm.user.id;
				vm.mineOnly = true;
				vm.staticUser = true;
			}
			if (vm.staticUser && !vm.userID) {
				next(false);
				return;
			}

			let fromReport = from.path.indexOf("qc_reporting") != -1;
			let fromDashboard = from.path.indexOf("dashboard") != -1;
			if (fromReport || fromDashboard) {
				vm.return = "back";
			} else {
				vm.return = "scoring";
			}

			if (vm.userID) {
				if (vm.mineOnly) {
					vm.queryUser = vm.user;
					vm.queryUser.name = fs.nameWithScorerID(vm.queryUser);
					vm.initedUser = true;
					next();
				} else {
					UserService.getUser(vm.userID)
						.then((r) => {
							vm.queryUser = r.data;
							vm.queryUser.name = fs.nameWithScorerID(vm.queryUser);
							vm.initedUser = true;
							next();
						})
						.catch((e) => {
							console.log(e);
							Notie.error("Failed to get user", e);
							next();
						});
				}
			} else {
				vm.queryUser = Store.get(vm, "qcPortfolio.queryUser");
				vm.initedUser = true;
				next();
			}
		});
	},

	beforeDestroy() {
		if (this.broadcastChannel) {
			this.broadcastChannel.close();
		}

		if (this.fromMyTrainingPage) {
			this.updateCompensationQueueTime();
			window.removeEventListener("beforeunload", this.updateCompensationQueueTime);
		}
	},

	methods: {
		async loadRows() {
			if (!(this.initedUser && this.initedSection)) return;

			if (!this.canQuery) {
				this.rows = [];
				return;
			}
			this.rows = [];
			this.qualStat = null;

			this.busy = true;

			const { mineOnly, qcType, queryUser, selectedSection, selectedItem } = this;
			const userID = queryUser ? queryUser.id : null;
			const sectionID = selectedSection ? selectedSection.id : null;
			const itemID = selectedItem ? selectedItem.id : null;
			const isQualSet = sectionID && itemID && this.qcType === QUALIFICATION.id;

			const rows = mineOnly
				? QCService.getMyQcPortfolio(qcType, sectionID, itemID)
				: QCService.getQcPortfolio(qcType, userID, sectionID, itemID);

			if (isQualSet) {
				const qual = mineOnly
					? QCService.getMyQualStatus(sectionID, itemID)
					: QCService.getQualStatus(sectionID, itemID, userID);

				const sets = QCConfigService.listQCSets(sectionID, itemID, qcType);

				try {
					const r = await BB.props({ rows, qual, sets });

					this.rows = r.rows.data;
					this.rows = _.sortBy(this.rows, "stat.updated_at");

					this.prepScoreComps(this.rows);
					this.busy = false;

					this.qualStat = r.qual.data;

					this.qualSets = r.sets.data;
				} catch (e) {
					console.error(e);
					Notie.error("Failed to get portfolio sets", e);
					this.busy = false;
				}
			} else {
				try {
					const { data } = await rows;
					this.rows = data;
					this.rows = _.sortBy(this.rows, "stat.updated_at");

					this.prepScoreComps(this.rows);

					this.busy = false;
				} catch (e) {
					console.error(e);
					Notie.error("Failed to get portfolio sets", e);
					this.busy = false;
				}
			}
		},

		// Collate the comparisons of actual scores and true scores into a structure that allows them to quickly accessed side-by-side for display
		prepScoreComps(rows) {
			for (let row of rows) {
				let numericTraits = this.numericTraits(row.rubric);
				let traitMap = {};
				for (let trait of numericTraits) {
					traitMap[trait.id] = trait;
				}
				row.rubric.traitMap = traitMap;

				for (let resp of row.set.responses) {
					if (!row.rubric) {
						console.error("No rubric for row", row);
						continue;
					}
					if (resp.qc_scores === null || !resp.qc_scores[0]) {
						// console.error("No user score for response", resp);
						continue;
					}
					if (!resp.true_score) {
						console.error("No true score for response", resp);
						continue;
					}
					if (
						!(
							numericTraits.length == resp.qc_scores[0].trait_scores.length &&
							numericTraits.length == resp.true_score.trait_scores.length
						)
					) {
						console.error(
							"Trait number mismatch",
							numericTraits.length,
							resp.qc_scores[0].trait_scores.length,
							resp.true_score.trait_scores.length,
							row,
							resp
						);

						continue;
					}

					numericTraits = _.sortBy(numericTraits, "sequence");

					let comps = [];
					for (let trait of numericTraits) {
						let sp = _.find(resp.qc_scores[0].trait_scores, { trait_id: trait.id });
						let tsp = _.find(resp.true_score.trait_scores, { trait_id: trait.id });
						if (!(sp && tsp)) {
							console.error("Trait mismatch", trait, sp, tsp);
							continue;
						}

						let comp = {
							trait_id: trait.id,
							scored: sp,
							actual: tsp,
						};
						let agreement = this.spAgreement(sp, tsp, trait.step);
						comp[agreement] = true;
						comps.push(comp);
					}
					resp.comps = comps;
				}
			}
		},

		calculateAgreements(rows) {
			for (let row of rows) {
				row.stat.agreement = this.exactAgreement(row.set.responses);
			}
		},

		exactAgreement(resps) {
			let totalTraits = 0;
			let agreedTraits = 0;
			for (let resp of resps) {
				for (let comp of resp.comps) {
					totalTraits++;
					if (comp.exact) {
						agreedTraits++;
					}
				}
			}

			let percent = (100 * agreedTraits) / totalTraits;
			return percent.toFixed(1);
		},

		sortTraits(rubric, trait_scores) {
			sorted = [];
			for (let trait of this.numericTraits(rubric)) {
				for (let sp of trait_scores) {
					if (sp.trait_id == trait.id) {
						sorted.push(sp);
					}
				}
			}

			return sorted;
		},

		spAgreement(sp1, sp2, step) {
			let diff = sp1.score - sp2.score;
			if (diff < step && diff > -1 * step) {
				return "exact";
			}
			if (diff == step) {
				return "adj_high";
			}
			if (diff == -1 * step) {
				return "adj_low";
			}
			if (diff > 0) {
				return "nonadj_high";
			}
			if (diff < 0) {
				return "nonadj_low";
			}
		},

		getRequirementComps() {
			let qualConfig = this.selectedItem.qualification_config;
			const { overall_stat } = this.qualStat;

			if (
				!(
					qualConfig &&
					qualConfig.requirements &&
					qualConfig.requirements.length > 0 &&
					overall_stat &&
					overall_stat.requirements
				)
			) {
				return [];
			}

			let comps = [];
			for (let stat of overall_stat.requirements) {
				const reqs = qualConfig.requirements.filter((req) => stat.requirement_id === req.id);
				_.each(reqs, (req) => {
					if (!req) return null;
					const reqCopy = { ...req };
					if (QCService.isTraitAgreementReq(reqCopy) || QCService.isTraitAggregate(reqCopy)) {
						reqCopy.trait_id = stat.trait_id;
					}
					let subReq = null;
					if (QCService.isTraitAggregate(reqCopy)) {
						let subReqs = qualConfig.requirements.filter(
							(req) =>
								QCService.isTraitAgreementReq(req) &&
								(req.trait_id === null || req.trait_id === stat.trait_id)
						);
						console.log("SUBREQS", subReqs);
						subReq = {
							reqs: subReqs,
							trait_id: reqCopy.trait_id,
						};
					}
					comps.push({
						req: reqCopy,
						subReq,
						stat,
					});
				});
			}

			console.log("COMPS", comps);
			return comps;
		},

		getRequirementText(req) {
			return QCService.getRequirementText(this.$i18n, this.selectedItem.rubric, req);
		},

		getSubRequirementsText(subReq) {
			if (!(subReq.reqs && subReq.reqs.length && subReq.reqs.length > 0)) {
				return "(unknown)";
			}

			let subReqTexts = [];
			for (let i = 0; i < subReq.reqs.length; i++) {
				let req = subReq.reqs[i];
				subReqTexts.push(QCService.getRequirementText(this.$i18n, this.selectedItem.rubric, req));
			}
			return subReqTexts.join(" & ");
		},

		getRequirementValue(req, value) {
			return QCService.getRequirementValue(req, value);
		},

		getSetName(setID) {
			for (let row of this.rows) {
				if (row.set && row.set.id == setID) {
					return row.set.name;
				}
			}

			return "(unknown)";
		},

		getNumSetsCompleted(sets) {
			let completed = 0;
			for (let set of sets) {
				if (set.state == 2 || set.state == 3) {
					completed++;
				}
			}
			return completed;
		},

		getNumSetsPassed(sets) {
			let passed = 0;
			for (let set of sets) {
				if (set.state == 2) {
					passed++;
				}
			}
			return passed;
		},

		getQcTypeName(qcType) {
			let obj = _.find(this.qcTypes, { id: qcType });
			console.log("GET QC TYPE", qcType, obj);
			if (!obj) return "(unknown)";
			return obj.name;
		},

		manualQualify() {
			QCService.overrideQual(this.selectedSection.id, this.selectedItem.id, this.queryUser.id)
				.then(() => {
					Notie.info(this.$i18n.t("notie.user_granted_qualification"));
					this.loadRows();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.failed_qualification_override"), err);
				});
		},

		manualUnqualifyConfirm() {
			this.showUnqualifyConfirm = true;
		},

		deleteQualConfirm() {
			this.showDeleteQualConfirm = true;
		},

		manualUnqualify() {
			this.showUnqualifyConfirm = false;
			QCService.removeQual(this.selectedSection.id, this.selectedItem.id, this.queryUser.id)
				.then(() => {
					Notie.info(this.$i18n.t("notie.removed_qualification"));
					this.loadRows();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.failed_remove_qualification"), err);
				});
		},

		deleteQual() {
			this.showDeleteQualConfirm = false;
			QCService.deleteAllQual(this.selectedSection.id, this.selectedItem.id, this.queryUser.id)
				.then(() => {
					Notie.info(this.$i18n.t("notie.deleted_qual"));
					this.loadRows();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.failed_delete_qual"), err);
				});
		},

		numericTraits(rubric) {
			return rubric.traits.filter(({ separator, is_parent }) => !(separator || is_parent));
		},

		updateCompensationQueueTime() {
			if (this.fromMyTrainingPage && this.compensationStartTime.compensation_start_time) {
				CompensationQueueTimeService.updateCompensationQueueTime(
					this.userID,
					this.itemID,
					"MY_QC_PORTFOLIO",
					this.compensationStartTime.compensation_start_time,
					"000000000000000000000000",
					2
				);
			}
		},
	},
};
</script>
