<template>
    <div>
        <a @click="modelOpen = true" v-tippy="{ placement: 'bottom' }" :title="$t('RFResponseReport.final_annotation')">
            <i :class="{
                [response.feedback == ' ' || !response.feedback
                    ? 'far fa-comment-alt'
                    : 'fas fa-comment-alt-check']: true,
            }" />
        </a>
        <final-annotation-modal v-if="modelOpen" @close="modelOpen = false"
            :response="response"></final-annotation-modal>
    </div>
</template>

<style scoped>

</style>

<script>
import FinalAnnotationModal from './FinalAnnotationModal.vue';

export default {
    name: "FinalAnnotationButton",

    components: {
        FinalAnnotationModal
    },

    props: {
        response: Object,
    },

    data() {
        return {
            modelOpen: false
        }
    },
}
</script>
