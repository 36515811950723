<template>
	<b-modal :visible="visible" v-if="visible" size="lg" id="previewerItemImportModal" @hide="emitHide">
		<template slot="modal-title">{{ $t("ItemSetup.PreviewerItemImportModal.title") }}</template>
		<div v-if="!importPage" class="modal-scroll-side-by-side">
			<div class="modal-scroll-left" style="width: 60%">
				<div class="mb-1" :class="{ 'text-extra-muted': !canRun1, 'text-theme': canRun1 }">
					1. Pick Items<i v-if="canRun1" class="fas fa-check-circle ml-1" />
				</div>
				<div class="card row mx-0">
					<div class="col-12 form-group">
						<label class="mt-2">{{ $t("ItemSetup.PreviewerItemImportModal.previewer_tenant") }}</label>
						<loading v-if="loadingTenants" type="input" />
						<v-select v-else :options="previewerTenants" v-model="selectedTenant" label="name">
							<template #selected-option="tenant">
								<span>{{ tenant.name }}</span>
								<span class="ml-1 text-xxs text-muted">(ID: {{ tenant.id }})</span>
							</template>
							<template #option="tenant">
								<span>{{ tenant.name }}</span>
								<span class="text-xxs text-muted">(ID: {{ tenant.id }})</span>
							</template>
						</v-select>
					</div>
					<template v-if="selectedTenant">
						<div id="testmap-list" class="col-12">
							<!-- <h5 class="mb-2">{{ $t("ItemSetup.PreviewerItemImportModal.previewer_items") }}</h5> -->
							<div class="d-flex flex-row align-items-center">
								<h5 class="mb-2">
									{{ $t("ItemSetup.PreviewerItemImportModal.previewer_testmaps") }}
									<span v-if="testmaps && testmaps.length > 0">({{ testmaps.length }})</span>
								</h5>
								<button
									class="ml-auto btn btn-sm btn-secondary-outline mb-2 text-xxs"
									@click="expandAllTestmaps"
								>
									Expand All
								</button>
							</div>
							<div v-if="loadingTestmaps" class="box card mb-3 p-2">
								<div class="text-center my-4">
									<loading type="large" />
								</div>
							</div>
							<div
								v-for="(testmap, i) in testmaps"
								:key="i"
								class="box card mb-3 pt-2 px-2 testmap"
								:class="{ 'pb-2': !testmap.expanded }"
							>
								<div class="d-flex flex-row align-items-center">
									<div>
										<div>{{ testmap.title }}</div>
										<div v-if="testmap.identifier" class="text-muted text-xxs" v-break-underscores>
											{{ testmap.identifier }}
										</div>
										<div v-else class="text-danger text-xxs">
											<i class="fas warning-sm fa-sm fa-exclamation-triangle" />
											{{ $t("ItemSetup.PreviewerItemImportModal.no_identifier") }}
										</div>
										<div v-if="testmap.formId" class="text-muted text-xxs" v-break-underscores>
											{{ testmap.formId }}
										</div>
										<div v-else class="text-danger text-xxs">
											<i class="fas warning-sm fa-exclamation-triangle" />
											{{ $t("ItemSetup.PreviewerItemImportModal.no_form_id") }}
										</div>
									</div>
									<div
										v-if="testmap.itemResult"
										class="ml-auto px-2"
										v-tippy
										:title="scannedItemCountTooltip(testmap)"
									>
										<h5 class="mb-0 text-center">
											{{ testmap.itemResult.items.length }}
										</h5>
										<div class="text-center nowrap">
											{{
												$tc(
													"ItemSetup.PreviewerItemImportModal.previewer_items",
													testmap.itemResult.items.length
												)
											}}
											<i class="far fa-info-circle" />
										</div>
									</div>
									<div
										v-else
										class="ml-auto px-2"
										v-tippy
										:title="unscannedItemCountTooltip(testmap)"
									>
										<h5 class="mb-0 text-center text-muted">
											{{ testmap.itemCount }}
										</h5>
										<div class="text-center text-muted nowrap">
											{{
												$tc(
													"ItemSetup.PreviewerItemImportModal.previewer_items",
													testmap.itemCount
												)
											}}
										</div>
									</div>
									<div
										v-if="canExpand(testmap)"
										class="px-2 clickable-side"
										@click="toggleTestmap(testmap)"
									>
										<i
											class="fas fa-lg fa-angle-left anim-rotate"
											:class="{ 'rotate-90': testmap.expanded }"
										/>
									</div>
								</div>
								<template v-if="testmap.expanded">
									<div v-if="testmap.loadingItems" class="text-center my-4">
										<loading type="large" />
										<h6 class="mb-0 text-muted">{{ testmap.loadingItemsProgress }}</h6>
									</div>
									<div
										v-else-if="testmap.itemResult && testmap.itemResult.items.length > 0"
										class="px-2"
									>
										<div
											v-for="(item, i) of testmap.itemResult.items"
											:key="item.identifier"
											class="import-item-row testmap-item"
											:class="{ 'b-b': i < testmap.itemResult.items - 1 }"
										>
											<div class="d-flex flex-row justify-content-between align-items-center">
												<template v-if="itemIDMap[item.identifier] && !item.import">
													<i
														class="fas fa-lg fa-times-circle text-muted"
														style="width: 20px"
													/>
													<div class="ml-1 flex">
														<div class="">{{ item.identifier }}</div>
														<div
															class="text-danger text-xxs clickable"
															style="margin-top: -5px"
															@click="scrollToItem(item.identifier)"
														>
															Already selected
														</div>
													</div>
												</template>
												<template v-else>
													<label class="md-check">
														<input
															v-model="item.import"
															type="checkbox"
															@change="itemImportChanged(item)"
														/>
														<i class="theme-accent" />
													</label>
													<div class="ml-1 flex">{{ item.identifier }}</div>
												</template>
												<div
													v-if="
														item.outcome_declarations &&
														item.outcome_declarations.length > 0
													"
													class="text-right"
												>
													<span
														v-for="od of item.outcome_declarations"
														:key="od.identifier"
														class="ml-1 badge badge-info"
													>
														{{ od.identifier }}
														<span class="text-muted"
															>({{ od.normal_minimum }}-{{ od.normal_maximum }})</span
														>
													</span>
												</div>
												<div v-else class="text-muted">
													({{ $t("ItemSetup.PreviewerItemImportModal.no_traits_defined") }})
												</div>
											</div>
										</div>
									</div>
									<h5 v-else class="text-center text-extra-muted my-3">
										{{ $t("ItemSetup.PreviewerItemImportModal.no_human_items") }}
									</h5>
								</template>
							</div>
							<h5 v-if="testmaps && testmaps.length <= 0" class="text-center text-extra-muted my-3">
								{{ $t("ItemSetup.PreviewerItemImportModal.no_testmaps") }}
							</h5>
							<!-- <div v-if="loadingItems" class="box card mb-3 p-2">
									<div class="text-center my-4">
										<loading type="large" />
										<h6 class="mb-0 text-muted" v-if="loadingItemsProgress">
											{{ loadingItemsProgress }}
										</h6>
									</div>
								</div>
								<template v-else-if="previewerItemResult">
									<div class="box card mb-2 p-2 d-flex flex-row justify-content-between">
										<div>
											Scanned: <strong>{{ previewerItemResult.scanned }}</strong>
										</div>
										<div>
											Failed to parse: <strong>{{ previewerItemResult.could_not_parse }}</strong>
										</div>
										<div>
											Ignored: <strong>{{ previewerItemResult.ignored }}</strong>
										</div>
									</div>
									<div
										v-for="item of previewerItemResult.items"
										:key="item.identifier"
										class="b-b import-item-row"
									>
										<div class="d-flex flex-row justify-content-between align-items-center">
											<label class="md-check">
												<input v-model="item.import" type="checkbox" />
												<i class="theme-accent" />
											</label>
											<div class="ml-1 flex">{{ item.identifier }}</div>
											<div
												v-if="item.outcome_declarations && item.outcome_declarations.length > 0"
												class="text-right"
											>
												<span
													v-for="od of item.outcome_declarations"
													:key="od.identifier"
													class="ml-1 badge badge-info"
												>
													{{ od.identifier }}
													<span class="text-muted"
														>({{ od.normal_minimum }}-{{ od.normal_maximum }})</span
													>
												</span>
											</div>
											<div v-else class="text-muted">
												({{ $t("ItemSetup.PreviewerItemImportModal.no_traits_defined") }})
											</div>
										</div>
									</div>
								</template> -->
						</div>
					</template>
				</div>
			</div>
			<div class="modal-scroll-right" style="width: 40%">
				<div class="form-group">
					<div class="mb-1" :class="{ 'text-extra-muted': !canRun2, 'text-theme': canRun2 }">
						2. Item Settings Template<i v-if="canRun2" class="fas fa-check-circle ml-1" />
					</div>
					<div class="card row mx-0">
						<div class="col-12 form-group">
							<label class="mt-2">
								{{ $t("ItemSetup.PreviewerItemImportModal.template_item") }}
							</label>
							<v-select :options="existingItems" v-model="templateItem" label="name">
								<template #selected-option="item">
									<span>{{ item.name }}</span>
									<span class="ml-1 text-xxs text-muted">({{ item.ref_id }})</span>
								</template>
								<template #option="item">
									<span>{{ item.name }}</span>
									<span class="text-xxs text-muted">({{ item.ref_id }})</span>
								</template>
							</v-select>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="mb-1" :class="{ 'text-extra-muted': !canRun3, 'text-theme': canRun3 }">
						3. Rubrics & Sections<i v-if="canRun3" class="fas fa-check-circle ml-1" />
					</div>
					<div class="card row mx-0">
						<div class="col-12 form-group">
							<label class="mt-2">{{ $t("ItemSetup.PreviewerItemImportModal.create_rubrics") }}</label>
							<v-select
								:options="combineRubricsOptions"
								:reduce="(o) => o.id"
								v-model="combineRubrics"
								label="name"
							>
								<template #selected-option="o">
									<div>{{ o.name }}</div>
								</template>
								<template #option="o">
									<div>{{ o.name }}</div>
									<div v-if="o.desc" class="text-xxs text-muted">{{ o.desc }}</div>
								</template>
							</v-select>
						</div>
						<div class="col-12 form-group">
							<label>{{ $t("ItemSetup.PreviewerItemImportModal.create_sections") }}</label>
							<v-select
								:options="combineSectionsOptions"
								:reduce="(o) => o.id"
								v-model="combineSections"
								label="name"
							>
								<template #selected-option="o">
									<div>{{ o.name }}</div>
								</template>
								<template #option="o">
									<div>{{ o.name }}</div>
									<div v-if="o.desc" class="text-xxs text-muted">{{ o.desc }}</div>
								</template>
							</v-select>
						</div>

						<div v-if="combineSections != 'none'" class="col-12 form-group">
							<label
								v-tippy
								title="Any new sections that are created will be in this project. Existing sections will not have their project changed."
								>Project</label
							>
							<v-select :options="projects" v-model="selectedProject" label="name"></v-select>
						</div>
					</div>
				</div>
				<div v-if="client.iea_integration" class="form-group">
					<div class="mb-1 d-flex flex-row">
						<span
							:class="{
								'text-extra-muted': !(enableTODModels && canRun4),
								'text-theme': enableTODModels && canRun4,
							}"
							>4. IEA TOD Models<i v-if="enableTODModels && canRun4" class="fas fa-check-circle ml-1"
						/></span>

						<label class="ml-auto ui-switch ui-switch-sm theme-accent" style="margin-top: 3px">
							<input type="checkbox" v-model="enableTODModels" />
							<i></i>
						</label>
					</div>
					<div v-if="enableTODModels" class="card">
						<div v-if="combineSections == 'none'" class="row mx-3 mt-2">
							<div class="text-danger text-xxs">
								<i class="fas fa-exclamation-triangle" style="font-size: 14px" />You must set 'Create
								Sections' to a value other than 'None'
							</div>
						</div>
						<div v-else-if="!selectedProject" class="row mx-3 mt-2">
							<div class="text-danger text-xxs">
								<i class="fas fa-exclamation-triangle" style="font-size: 14px" />You must select a
								project.
							</div>
						</div>
						<div
							v-else-if="!(selectedProject.iea_project && selectedProject.iea_project.projectId)"
							class="row mx-3 mt-2"
						>
							<div class="text-danger text-xxs">
								<i class="fas fa-exclamation-triangle" style="font-size: 14px" />The currently selected
								project does not have an IEA Admin defined. You must select a project that is configured
								for IEA integration.
							</div>
						</div>
						<div v-if="!hasIeaIntegration(templateItem)" class="row mx-3 mt-2">
							<div class="text-danger text-xxs">
								<i class="fas fa-exclamation-triangle" style="font-size: 14px" />You must use a Template
								Item with IEA integration configured.
							</div>
						</div>
						<div v-if="!allSubjectsDefined()" class="row mx-3 mt-2">
							<div class="text-danger text-xxs">
								<i class="fas fa-exclamation-triangle" style="font-size: 14px" />You must choose a
								subject for each item group.
							</div>
						</div>
						<div class="row mx-0 mb-2">
							<div class="col-12 mt-2">
								<label class="md-check md-check-lg check-label">
									<input type="checkbox" v-model="groupPrompt" />
									<i class="theme-accent"></i>
									Separate Unique Prompts
								</label>
							</div>
						</div>
						<div class="row mx-0">
							<template v-if="numToImport > 0 && todItemGroups">
								<div v-for="(group, i) in todItemGroups" :key="i" class="item-group-row mx-0 p-2">
									<div class="d-flex flex-row align-items-center mb-1">
										<input
											class="flex form-control"
											:class="{ 'is-invalid': group.incompatibleRubric }"
											type="text"
											v-model="group.identifier"
											placeholder="Model identifier"
											@input="checkExistingTod(group)"
										/>

										<i
											v-if="group.incompatibleRubric"
											class="ml-2 fas fa-lg fa-exclamation-triangle text-danger"
											v-tippy
											title="An IEA model with this ID already exists, and its rubric is not compatible. You must change this item ID."
										/>
										<i
											v-if="group.existingRubric"
											class="ml-2 fas fa-lg fa-exclamation-triangle text-warning"
											v-tippy
											title="An IEA model with this ID already exists, and it's rubric is compatible. However, it may have been created using a different prompt, subject, or grade. You may want to change this item ID."
										/>

										<i
											class="ml-2 fas fa-lg clickable-icon"
											:class="getSubjectIcon(group.subject)"
											data-toggle="dropdown"
											aria-expanded="false"
										/>
										<div class="dropdown-menu dropdown-menu-right">
											<a
												v-for="s in subjects"
												:key="s.id"
												class="dropdown-item"
												:class="{ theme: group.subject == s.id }"
												@click="setSubject(group, s.id)"
											>
												<i
													:class="{
														[s.icon]: true,
														fas: group.subject == s.id,
														far: group.subject != s.id,
													}"
												></i>
												<span>{{ s.name }}</span>
											</a>
										</div>

										<template v-if="group.items && group.items.length">
											<i
												v-if="group.items && group.items.length > 0"
												class="ml-2 far fa-lg fa-info-circle clickable-icon"
												v-tippy="{
													html: `#tod-group-tooltip-${i}`,
													interactive: true,
													theme: 'popover',
													trigger: 'click',
												}"
											/>
											<div :id="`tod-group-tooltip-${i}`" class="hidden">
												<div
													v-if="group.subject != null || group.grade != null"
													class="d-flex flex-row form-group"
												>
													<div v-if="group.subject != null" style="width: 100%">
														Subject:
														<span v-if="group.subject">{{ group.subject }}</span>
														<span v-else class="text-muted">(Blank)</span>
													</div>
													<div v-if="group.grade" style="width: 100%">
														Grade:
														<span v-if="group.grade">{{ group.grade }}</span>
														<span v-else class="text-muted">(Blank)</span>
													</div>
												</div>
												<div v-if="group.prompt != null" class="form-group">
													<div>Prompt:</div>
													<div
														v-if="group.prompt"
														v-html="group.prompt"
														class="card scroll-x scroll-y px-4"
														style="max-width: 500px; max-height: 300px"
													></div>
													<div v-else class="text-center text-muted">(None)</div>
												</div>
												<div class="form-group">
													<div>Rubric:</div>
													<div
														v-if="
															group.items[0].outcome_declarations &&
															group.items[0].outcome_declarations.length > 0
														"
													>
														<span
															v-for="od of group.items[0].outcome_declarations"
															:key="od.identifier"
															class="mr-1 badge badge-info"
														>
															{{ od.identifier }}
															<span class="text-muted"
																>({{ od.normal_minimum }}-{{ od.normal_maximum }})</span
															>
														</span>
													</div>
													<div v-else class="text-muted">
														({{
															$t("ItemSetup.PreviewerItemImportModal.no_traits_defined")
														}})
													</div>
												</div>
											</div>
										</template>
									</div>
									<div>
										<div v-for="(item, j) in group.items" :key="j" class="badge badge-success mr-1">
											{{ item.identifier }}
										</div>
									</div>
								</div>
							</template>
							<div v-else class="col-12">
								<h5 class="text-center text-extra-muted my-3">No item groups</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="importPage" class="row p-2 scroll-y">
			<div class="col-12">
				<div class="form-group">
					<div class="mb-1 text-extra-muted">4. Creating Configuration...</div>
				</div>
				<div class="result-panel p-3">
					<loading v-if="!importResults" type="page" />
					<pre v-if="importResults" class="form-control mb-0 reverse-console" style="max-height: 600px">{{
						importResults
					}}</pre>
				</div>
			</div>
		</div>
		<template slot="modal-footer">
			<div v-if="!importPage && numToImport > 0" class="mr-auto badge-pill badge-success">
				{{ $tc("ItemSetup.PreviewerItemImportModal.num_to_import", numToImport, { num: numToImport }) }}
			</div>
			<button v-if="!importPage" class="btn primary btn-flat" @click="close">{{ $t("buttons.cancel") }}</button>
			<button
				v-if="!importPage"
				class="btn btn-flat"
				@click="doImport"
				:disabled="!canRun"
				:class="{ 'btn-success': canRun }"
			>
				{{ $t("buttons.import") }}
			</button>
			<button v-if="importPage && importing" class="btn btn-secondary btn-flat" disabled>
				{{ $t("buttons.importing") }}&nbsp;&nbsp;
				<loading type="icon" />
			</button>
			<button v-if="importPage && !importing" class="btn btn-secondary btn-flat" @click="close">
				{{ $t("buttons.okay") }}
			</button>
		</template>
	</b-modal>
</template>

<style scoped>
.import-item-row {
	margin-left: -1rem;
	margin-right: -1rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.75rem;
}
.import-item-row:nth-child(even) {
	background-color: #f4f4f4;
}

.item-group-row {
	width: 100%;
}
.item-group-row:nth-child(odd) {
	background-color: #f4f4f4;
}

.clickable-side {
	margin-top: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: -0.5rem;
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	transition: background-color 0.15s;
}
.clickable-side:hover {
	background-color: rgba(0 0 0 / 10%);
}
.warning-sm {
	font-size: 10px;
	vertical-align: 0px;
}

.clickable-icon {
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 0.15s;
}
.clickable-icon:hover {
	opacity: 1;
}
.clickable {
	cursor: pointer;
}

.testmap-item {
	transition: box-shadow 0.5s;
}
.highlight {
	box-shadow: inset 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
</style>

<script>
import PreviewerService from "@/services/PreviewerService";
import IeaService from "@/services/IEAService";
import ItemService from "@/services/ItemService";
import ProjectService from "@/services/ProjectService";
import ProgressJobService from "@/services/ProgressJobService";
import fs from "@/services/FormatService";
import bb from "bluebird";

import Notie from "@/services/NotieService";

export default {
	name: "PreviewerItemImportModal",
	props: ["client", "visible"],

	data() {
		let subjects = fs.subjectTypes();
		let validSubjects = {};
		_.each(subjects, (s) => {
			validSubjects[s.id] = true;
		});

		return {
			loadingTenants: false,
			previewerTenants: [],
			selectedTenant: null,

			loadingItems: false,
			loadingItemsProgress: null,
			previewerItemResult: null,

			loadingTestmaps: false,
			testmaps: null,
			itemIDMap: {},

			projects: [],
			selectedProject: null,

			existingItems: [],
			templateItem: null,

			combineRubricsOptions: fs.combineRubricsTypes(),
			combineSectionsOptions: fs.combineSectionsTypes(),
			combineRubrics: "1_per_item",
			combineSections: "none",
			subjects: subjects,
			validSubjects: validSubjects,

			jobRunner: ProgressJobService.newJobRunner(),

			importPage: false,
			importing: false,
			importResults: "",

			enableTODModels: false,
			todItems: [],
			itemPromptMap: {},
			itemPromptMapSize: 0,
			groupPrompt: true,
			groupSubject: true,
			groupGrade: true,
		};
	},
	created() {
		if (this.visible) {
			this.init();
		}
	},
	destroyed() {
		this.jobRunner.endAllJobs();
	},
	watch: {
		selectedTenant() {
			if (this.selectedTenant) {
				// this.loadItems(this.selectedTenant.id);
				this.loadTestmaps(this.selectedTenant.id);
			} else {
				// this.loadingItems = false;
				// this.previewerItemResult = false;
				this.loadingTestmaps = false;
				this.testmaps = false;
			}
		},
		visible() {
			if (this.visible) {
				this.init();
			}
		},
		templateItem() {
			if (this.client.iea_integration && this.templateItem && this.templateItem.cf_config.admin_name) {
				this.loadExistingTodModels(this.templateItem.cf_config.admin_name);
			}
		},
	},
	computed: {
		numToImport() {
			let numToImport = 0;
			_.each(this.testmaps, (testmap) => {
				if (!testmap.itemResult) {
					return;
				}
				_.each(testmap.itemResult.items, (item) => {
					if (item.import) {
						numToImport++;
					}
				});
			});
			console.log("numToImport", numToImport, numToImport > 0);
			return numToImport;
		},

		canRun1() {
			if (!this.selectedTenant) {
				return false;
			}

			return this.numToImport > 0;
		},

		canRun2() {
			return this.templateItem;
		},

		canRun3() {
			return this.combineSections == "none" || this.selectedProject;
		},

		canRun4() {
			if (!this.enableTODModels) {
				return true;
			}
			if (!this.todItemGroups) {
				return false;
			}
			if (!this.hasIeaIntegration(this.templateItem)) {
				return false;
			}
			if (!this.allSubjectsDefined()) {
				return false;
			}

			for (let group of this.todItemGroups) {
				if (group.identifier == "") {
					return false;
				}
				if (group.incompatibleRubric) {
					return false;
				}
			}
			return true;
		},

		canRun() {
			return this.canRun1 && this.canRun2 && this.canRun3 && this.canRun4;
		},

		todItemGroups() {
			if (this.numToImport <= 0) {
				return null;
			}

			return this.calculateTodItemGroups();
		},

		canEnableTOD() {
			return (
				this.combineSections != "none" &&
				this.selectedProject &&
				this.selectedProject.iea_project &&
				this.selectedProject.iea_project.projectId != ""
			);
		},
	},
	methods: {
		init() {
			this.loadProjects();
			this.loadPreviewerTenants();
			this.loadExistingItems();
		},

		loadProjects() {
			this.loadingProjects = true;
			ProjectService.listAllProjects()
				.then((r) => {
					this.loadingProjects = false;
					this.projects = r.data.projects;
				})
				.catch((e) => {
					this.loadingProjects = false;
					console.error(e);
					Notie.error("Failed to load projects", e);
				});
		},

		loadPreviewerTenants() {
			this.loadingTenants = true;
			PreviewerService.getTenants()
				.then((r) => {
					this.loadingTenants = false;
					this.previewerTenants = r.data;
				})
				.catch((e) => {
					this.loadingTenants = false;
					console.log(e);
					Notie.error("Failed to load tenants from Previewer", e);
				});
		},

		loadExistingItems() {
			this.loadingExistingItems = true;
			ItemService.listItems()
				.then((r) => {
					this.loadingExistingItems = false;
					this.existingItems = r.data.items;
				})
				.catch((e) => {
					this.loadingExistingItems = false;
					console.log(e);
					Notie.error("Failed to load tenants from Previewer", e);
				});
		},

		loadExistingTodModels(adminName) {
			this.loadingExistingTodModels = true;
			IeaService.getTODItems(this.client.iea_tenant_id, adminName)
				.then((r) => {
					console.log("RESPONSE", r);
					this.loadingExistingTodModels = false;
					this.todItems = r.data;
					_.each(this.todItemGroups, (group) => {
						this.checkExistingTod(group);
					});
				})
				.catch((e) => {
					this.loadingExistingTodModels = false;
					Notie.error("Failed to get existing TOD items", e);
					console.error(e);
				});
		},

		loadItems(tenantID) {
			this.loadingItems = true;
			this.loadingItemsProgress = null;

			let updateProgress = (r) => {
				this.loadingItemsProgress = r.data.msg;
			};
			let pollInterval = 500;

			this.jobRunner
				.startJob(PreviewerService.getItemsWithDetails(tenantID), updateProgress, pollInterval)
				.then((r) => {
					this.loadingItems = false;
					this.previewerItemResult = r.data;
					_.each(this.previewerItemResult.items, (item) => {
						if (!this.itemIDMap[item.identifier]) {
							this.$set(item, "import", true);
							this.itemImportChanged(item);
						}
					});
				})
				.catch((e) => {
					this.loadingItems = false;
					console.log({ "Failed to import items from Previewer": e });
					console.error(e);
					Notie.error("Failed to import items from Previewer", e);
				});
		},

		loadTestmaps(tenantID) {
			PreviewerService.getTestmaps(tenantID)
				.then((r) => {
					this.loadingTestmaps = false;
					this.testmaps = r.data;
					_.each(this.testmaps, (tm) => {
						this.$set(tm, "expanded", false);
						this.$set(tm, "loadingItems", false);
						this.$set(tm, "loadingItemsProgress", "");
						this.$set(tm, "itemResult", null);
					});
				})
				.catch((e) => {
					this.loadingTestmaps = false;
					console.error(e);
					Notie.error("Failed to load testmaps from Previewer", e);
				});
		},

		loadTestmapItems(testmap) {
			testmap.loadingItems = true;
			testmap.loadingItemsProgress = null;

			let updateProgress = (r) => {
				testmap.loadingItemsProgress = r.data.msg;
			};
			let pollInterval = 500;

			let tenantID = testmap.tenantId;
			let tmIdentifier = testmap.identifier;
			let formID = testmap.formId;

			this.jobRunner
				.startJob(
					PreviewerService.getTestmapItemsWithDetails(tenantID, tmIdentifier, formID),
					updateProgress,
					pollInterval
				)
				.then((r) => {
					testmap.loadingItems = false;
					testmap.itemResult = r.data;
					_.each(testmap.itemResult.items, (item) => {
						if (!this.itemIDMap[item.identifier]) {
							this.$set(item, "import", true);
							this.itemImportChanged(item);
						}
					});
				})
				.catch((e) => {
					testmap.loadingItems = false;
					console.log({ "Failed to load items from Previewer": e });
					console.error(e);
					Notie.error("Failed to load items from Previewer", e);
				});
		},

		doImport() {
			if (!this.canRun) {
				return;
			}

			let req = {
				tenant_id: this.selectedTenant.id,
				template_item_id: this.templateItem.id,
				combine_rubrics: this.combineRubrics,
				combine_sections: this.combineSections,
				items: [],
			};
			if (this.combineSections != "none") {
				req.project_id = this.selectedProject.id;
			}
			for (let testmap of this.testmaps) {
				if (!testmap.itemResult) {
					continue;
				}
				for (let item of testmap.itemResult.items) {
					if (item.import) {
						req.items.push(this.toReqItem(item));
					}
				}
			}

			if (this.enableTODModels) {
				if (!(this.templateItem && this.templateItem.cf_config.admin_name)) {
					Notie.error("No admin name to create IEA item models");
					return;
				}

				let group_reqs = [];
				_.each(this.todItemGroups, (g) => {
					let req = {
						iea_projectId: this.templateItem.cf_config.admin_name,
						iea_itemId: g.identifier,
						subject: g.subject,
						item_ref_ids: g.items.map((i) => i.identifier),
					};

					group_reqs.push(req);
				});
				req.tod_item_groups = group_reqs;
			}

			let updatesFunc = (r) => {
				let msgs = r.data;
				for (let msg of msgs) {
					this.importResults += msg + "\n";
				}
			};
			let pollInterval = 500;

			this.importPage = true;
			this.importing = true;
			this.jobRunner
				.startJob(PreviewerService.importItemSet(req), updatesFunc, pollInterval, true)
				.then((r) => {
					this.importing = false;
					this.importResults += r.data;
				})
				.catch((e) => {
					this.importing = false;
					console.error(e);
					console.log("error", { error: e });
					Notie.error("Failed to import items from Previewer", e);
				});
		},

		toReqItem(pitem) {
			let item = {
				id: pitem.id,
				ref_id: pitem.identifier,
				traits: [],
			};
			for (let od of pitem.outcome_declarations) {
				item.traits.push({
					name: od.identifier,
					external_id: od.identifier,
					min: od.normal_minimum,
					max: od.normal_maximum,
				});
			}
			return item;
		},

		close(itemsAdded) {
			this.$bvModal.hide("previewerItemImportModal");
			if (itemsAdded) {
				this.$emit("itemsAdded", true);
			}
		},

		emitHide(event) {
			this.$emit("hide", event);
		},

		toggleTestmap(testmap) {
			if (testmap.expanded) {
				testmap.expanded = false;
			} else {
				testmap.expanded = true;
				if (!(testmap.itemResult || testmap.loadingItems)) {
					this.loadTestmapItems(testmap);
				}
			}
		},

		expandAllTestmaps() {
			for (let testmap of this.testmaps) {
				if (!testmap.expanded && this.canExpand(testmap)) {
					this.toggleTestmap(testmap);
				}
			}
		},

		canExpand(testmap) {
			return testmap.identifier && testmap.formId;
		},

		unscannedItemCountTooltip(testmap) {
			let tooltip = "";
			tooltip += `There are ${testmap.itemCount} possible items in this testmap.<br>`;
			tooltip += `Expand to scan and filter to human-scorable items only.`;
			return tooltip;
		},

		scannedItemCountTooltip(testmap) {
			let tooltip = "<table>";
			tooltip += `<tr class="text-muted"><td>Scanned:</td><td class="pl-2">${testmap.itemResult.scanned}</td></tr>`;
			tooltip += `<tr class="text-muted"><td>Failed to parse:</td><td class="pl-2">${testmap.itemResult.could_not_parse}</td></tr>`;
			tooltip += `<tr class="text-muted"><td>Ignored:</td><td class="pl-2">${testmap.itemResult.ignored}</td></tr>`;
			tooltip += `<tr><td>Human-scorable:</td><td class="pl-2">${testmap.itemResult.items.length}</td></tr>`;
			tooltip += `</table>`;
			return tooltip;
		},

		calculateTodItemGroups() {
			let groupMap = {};

			// Collect all currently selected items
			_.each(this.testmaps, (testmap) => {
				if (!testmap.itemResult) {
					return;
				}
				_.each(testmap.itemResult.items, (item) => {
					if (item.import) {
						// For each one, create a slug based on the values we're grouping by, and put the item in the bucket for that slug
						let slug = this.getItemSlug(
							testmap,
							item,
							true,
							this.groupPrompt,
							this.groupSubject,
							this.groupGrade
						);

						let subject = testmap.subject;
						if (!subject) {
							subject = this.guessTestmapSubject(testmap);
						}

						if (!groupMap[slug]) {
							groupMap[slug] = {
								identifier: "",
								items: [],
								rubricKey: this.rubricToString(item.outcome_declarations),
								prompt: this.groupPrompt ? item.prompt : null,
								subject: this.groupSubject ? subject : null,
								grade: this.groupGrade ? testmap.grade : null,
								invalid: false,
								checkingValid: true,
								slug: slug,
							};
						}
						groupMap[slug].items.push(item);
					}
				});
			});

			// At this point we have items groups by their relevant properties.
			// Now, let's try to find a maximal shared item ID for each one.
			for (let group of Object.values(groupMap)) {
				if (group.items.length == 0) {
					group.identifier = "";
				} else if (group.items.length == 1) {
					group.identifier = group.items[0].identifier;
				} else {
					let ids = [];
					for (let item of group.items) {
						ids.push(item.identifier);
					}
					let commonPrefix = this.commonPrefix(ids);
					commonPrefix = commonPrefix.replace(/[_-\s]+$/, "");
					group.identifier = commonPrefix;
				}
			}

			// And now check if the identifiers we just generated for them collide with existing TOD items
			for (let group of Object.values(groupMap)) {
				this.checkExistingTod(group);
			}

			return Object.values(groupMap);
		},

		getItemSlug(testmap, item, groupRubric, groupPrompt, groupSubject, groupGrade) {
			let slugParts = [];
			if (groupRubric) {
				slugParts.push(this.rubricToString(item.outcome_declarations));
			}
			if (groupPrompt) {
				slugParts.push(this.aliasPrompt(item.prompt_stripped));
			}
			if (groupSubject) {
				slugParts.push(testmap.subject);
			}
			if (groupGrade) {
				slugParts.push(testmap.grade);
			}
			return slugParts.join("|");
		},

		guessTestmapSubject(testmap) {
			let searchTitle = testmap.title.toLowerCase();
			if (searchTitle.includes("math")) {
				return "math";
			} else if (searchTitle.includes("science")) {
				return "science";
			} else if (searchTitle.includes("social studies")) {
				return "social_studies";
			} else if (searchTitle.includes("reading")) {
				return "reading";
			} else if (searchTitle.includes("writing")) {
				return "writing";
			}

			return null;
		},

		aliasPrompt(prompt) {
			if (!this.itemPromptMap[prompt]) {
				this.itemPromptMap[prompt] = `prompt-${this.itemPromptMapSize}`;
				this.itemPromptMapSize++;
			}

			return this.itemPromptMap[prompt];
		},

		rubricToString(outcomeDeclarations) {
			let traitStrings = [];
			for (let od of outcomeDeclarations) {
				traitStrings.push(`${od.normal_minimum};${od.normal_maximum}`);
			}
			return (traitStrings = traitStrings.join(":"));
		},

		commonPrefix(strs) {
			let i = 0;
			// while all strings have the same character at position i, increment i
			while (strs[0] && strs[0][i] && strs.every((str) => str[i] === strs[0][i])) {
				i++;
			}
			return strs[0].substring(0, i);
		},

		itemImportChanged(item) {
			console.log("Item import changed", item.identifier, item.import);
			if (item.import) {
				this.itemIDMap[item.identifier] = true;
			} else {
				delete this.itemIDMap[item.identifier];
			}
		},

		scrollToItem(itemID) {
			for (let i in this.testmaps) {
				let testmap = this.testmaps[i];
				if (!testmap.itemResult) {
					return;
				}
				for (let j in testmap.itemResult.items) {
					let item = testmap.itemResult.items[j];
					if (item.import == true && item.identifier == itemID) {
						this.scrollToItemByIndexes(i, j);
					}
				}
			}
		},

		scrollToItemByIndexes(testmapIndex, itemIndex) {
			let testmapList = document.getElementById("testmap-list");
			let testmapEls = testmapList.querySelectorAll(".testmap");
			if (testmapIndex >= testmapEls.length) {
				console.error(`Testmap index ${testmapIndex} is out of range (${testmapEls.length})`);
				return;
			}
			let testmapEl = testmapEls[testmapIndex];
			let itemEls = testmapEl.querySelectorAll(".testmap-item");
			if (itemIndex >= itemEls) {
				console.error(`Item index ${itemIndex} is out of range (${itemEls.length})`);
				return;
			}
			let itemEl = itemEls[itemIndex];
			itemEl.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
			itemEl.classList.add("highlight");
			setTimeout(() => {
				itemEl.classList.remove("highlight");
			}, 2000);
		},

		checkGroupIdentifier(group) {
			if (!group.identifier) {
				group.invalid = "blank";
				return;
			}

			for (let g of this.todItemGroups) {
				if (g !== group && g.identifier == group.identifier) {
					if (g.rubricKey != group.rubricKey) {
						group.invalid = "local";
						return;
					}
				}
			}

			for (let model of this.existingTodModels) {
				if (model.identifier == group.identifier) {
					if (model.rubricKey != group.rubricKey) {
						group.invalid = "server";
						return;
					}
				}
			}

			group.invalid = false;
		},

		hasIeaIntegration(item) {
			if (!item) return false;
			if (!item.cf_config) return false;
			if (!item.cf_config.system_user_id) return false;
			if (!item.cf_config.catchall_flag_id) return false;

			return true;
		},

		setSubject(group, subject) {
			group.subject = subject;
			this.$forceUpdate();
		},

		getSubjectIcon(subject) {
			let s = _.find(this.subjects, { id: subject });
			if (!s) {
				return "fa-question text-danger";
			}

			return s.icon;
		},

		allSubjectsDefined() {
			if (!this.todItemGroups) return true;

			for (let group of this.todItemGroups) {
				if (!(group.subject && this.validSubjects[group.subject])) {
					return false;
				}
			}
			return true;
		},

		checkExistingTod(group) {
			if (!(this.todItems && this.todItems.length > 0)) {
				group.incompatibleRubric = false;
				group.existingRubric = false;
				this.$forceUpdate();
				return;
			}

			for (let todItem of this.todItems) {
				if (todItem.itemId == group.identifier) {
					if (todItem.rubric_key == group.rubricKey) {
						group.incompatibleRubric = false;
						group.existingRubric = true;
					} else {
						group.incompatibleRubric = true;
						group.existingRubric = false;
					}
					this.$forceUpdate();
					return;
				}
			}

			group.incompatibleRubric = false;
			group.existingRubric = false;
			this.$forceUpdate();
		},

		getCantEnableTODTooltip() {
			if (this.combineSections == "none") {
				return "You must create sections during import in order to create TOD models at the same time.";
			}
			if (!this.selectedProject) {
				return "You must select a project in order to create TOD models.";
			}
			if (!(this.selectedProject.iea_project && this.selectedProject.iea_project.projectId != "")) {
				return "The selected project does not have an IEA admin defined. You must choose a project that is configure for IEA integration.";
			}
		},
	},
};
</script>