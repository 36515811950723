<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("Scoring.title") }}
					<span class="far fa-fw fa-angle-right"></span>
					{{ $t("AssignedResponseList.assigned_responses") }}
				</div>
			</div>
		</template>
		<!-- Page Content -->
		<div
			class="d-flex flex flex-row h100 mb-auto align-items-stretch"
			style="background-color: white; overflow: auto"
		>
			<div class="aside d-flex scroll-y">
				<div class="modal-dialog w-md light b-r b-t white p-2 audit-search d-flex flex-column">
					<div class="card px-3 py-2 form-group">
						<div v-if="section" class="form-group">
							<label>{{ $t("AssignedResponseList.section") }}</label>
							<h5 class="mb-0">{{ section.name }}</h5>
						</div>
						<div v-if="item" class="form-group">
							<label>{{ $t("AssignedResponseList.item") }}</label>
							<h5 class="mb-0">{{ item.name }}</h5>
						</div>
						<div v-if="item" class="mb-1">
							<label>{{ $t("AssignedResponseList.scored") }}</label>
							<h5 class="mb-0">
								{{ scoredCount }} <span class="text-muted">out of</span> {{ assignedCount }}
							</h5>
						</div>
					</div>
					<div class="form-group">
						<label>{{ $t("AssignedResponseList.scored") }}</label>
						<v-select
							:options="scoredOptions"
							:searchable="false"
							:clearable="false"
							:reduce="(o) => o.value"
							v-model="scored"
						></v-select>
					</div>
					<div class="form-group">
						<label>{{ $t("AssignedResponseList.state") }}</label>
						<v-select
							:options="states"
							:searchable="false"
							:clearable="false"
							:reduce="(o) => o.value"
							v-model="state"
						></v-select>
					</div>
					<div class="form-group">
						<label>{{ $t("AssignedResponseList.response_id") }}</label>
						<input v-model="ref_id" type="text" class="form-control" />
					</div>
					<!-- <div class="form-group">
									<label>{{ $t("AssignedResponseList.exclusivity_code") }}</label>
									<input v-model="exclusivity_code" type="text" class="form-control" />
								</div> -->
					<div v-for="mk in queryMetadataKeys" :key="mk.id" class="form-group">
						<label>
							<i class="far fa-list-ul fa-fw text-muted mr-1" v-tippy :title="$t('Auditing.metadata')"></i
							>{{ mk.display_name }}
						</label>
						<input v-model="meta[mk.key]" type="text" class="form-control" />
					</div>

					<div class="btn-group align-self-end" role="group" style="height: 35px" aria-label="First group">
						<button
							type="button"
							class="btn mb-2"
							:class="{ theme: view == 'single' }"
							style="height: 35px"
							@click="view = 'single'"
							v-tippy
							:title="$t('tooltip.preview')"
						>
							<i class="fas fa-window-maximize" />
						</button>
						<button
							type="button"
							class="btn mb-2"
							:class="{ theme: view == 'list' }"
							style="height: 35px"
							@click="view = 'list'"
							v-tippy
							:title="$t('tooltip.list')"
						>
							<i class="fas fa-th-list" />
						</button>
					</div>
				</div>
			</div>
			<div class="flex d-flex flex-column" style="overflow: hidden">
				<div class="flex scroll-y scroll-x" v-if="this.item != null && !this.item.show_other_assigned_scorer">
					<!-- Page content goes here -->
					<b-table
						ref="table"
						striped
						hover
						:show-empty="true"
						:items="provider"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:busy.sync="busy"
					>
						<!-- Select -->

						<!-- Ref ID -->
						<template #cell(ref_id)="data">
							<i
								v-if="data.item.rescore_score_id"
								class="fas fa-undo fa-fw text-danger mr-1"
								v-tippy
								:title="$t('tooltip.rescore_requested')"
							></i>
							{{ data.item.ref_id }}
							<template v-if="queryMetadataKeys">
								<i
									class="far fa-list-ul fa-fw text-muted ml-1"
									:id="`meta-${data.item.id}`"
									v-b-popover
								></i>
								<b-popover :target="`meta-${data.item.id}`" triggers="hover">
									<template slot="title">{{ $t("Auditing.metadata") }}:</template>
									<li v-for="mk in queryMetadataKeys" :key="mk.id">
										<span class="text-muted">{{ mk.display_name }}</span>
										<div v-if="data.item.meta[mk.key]">
											{{ data.item.meta[mk.key] }}
										</div>
										<div v-else class="text-extra-muted">({{ $t("data_description.none") }})</div>
									</li>
								</b-popover>
							</template>
						</template>

						<!-- State -->
						<template #cell(state)="data">
							{{ getState(data.item.state) }}
						</template>

						<!-- Score -->
						<template #cell(score)="data">
							<inline-score
								v-if="data.item.score"
								:score="data.item.score"
								:rubric="item && item.rubric"
								tooltip
							/>
						</template>

						<!-- Actions -->
						<template #cell(actions)="data">
							<div class="w-100 text-center">
								<template v-if="canScore(data.item) && !data.item.score">
									<a
										v-if="!data.item.loading"
										@click="score(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
										v-tippy
										:title="$t('tooltip.score')"
									>
										<i v-if="data.item.state <= 2" class="material-icons tiny">check</i>
										<i
											v-if="data.item.state > 2 && data.item.state != 10"
											class="material-icons tiny"
											>done_all</i
										>
										<i
											v-if="data.item.state == 10 && !data.item.has_backread"
											class="material-icons tiny"
											>verified_user</i
										>
									</a>
									<a
										v-if="data.item.loading"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
									>
										<loading type="icon" />
									</a>
								</template>
								<template
									v-else-if="!data.item.score && data.item.assigned_scorer_id == user.scorer_id"
								>
									<i
										class="fas fa-question text-muted"
										v-tippy="{ theme: 'popover', placement: 'left-start' }"
										:title="$t('tooltip.waiting_for_first_assigned_score')"
									/>
								</template>
								<template v-if="data.item.rescore_score_id">
									<a
										@click="goToRescore(data.item)"
										class="btn btn-sm btn-icon btn-rounded btn-danger text-white m-0"
										v-tippy
										:title="$t('tooltip.rescore')"
									>
										<i class="fas fa-undo" />
									</a>
								</template>
							</div>
						</template>
					</b-table>

					<media
						v-if="view == 'single' && currentItems && currentItems[0]"
						:response="!busy && currentItems[0]"
						noChan
					/>
				</div>
				<div class="flex scroll-y scroll-x" v-if="this.item != null && this.item.show_other_assigned_scorer">
					<!-- Page content goes here -->
					<b-table
						ref="table"
						striped
						hover
						:show-empty="true"
						:items="provider"
						:fields="fieldsIncludingOtherAssignedScorerId"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:busy.sync="busy"
					>
						<!-- Select -->

						<!-- Ref ID -->
						<template #cell(ref_id)="data">
							<i
								v-if="data.item.rescore_score_id"
								class="fas fa-undo fa-fw text-danger mr-1"
								v-tippy
								:title="$t('tooltip.rescore_requested')"
							></i>
							{{ data.item.ref_id }}
							<template v-if="queryMetadataKeys">
								<i
									class="far fa-list-ul fa-fw text-muted ml-1"
									:id="`meta-${data.item.id}`"
									v-b-popover
								></i>
								<b-popover :target="`meta-${data.item.id}`" triggers="hover">
									<template slot="title">{{ $t("Auditing.metadata") }}:</template>
									<li v-for="mk in queryMetadataKeys" :key="mk.id">
										<span class="text-muted">{{ mk.display_name }}</span>
										<div v-if="data.item.meta[mk.key]">
											{{ data.item.meta[mk.key] }}
										</div>
										<div v-else class="text-extra-muted">({{ $t("data_description.none") }})</div>
									</li>
								</b-popover>
							</template>
						</template>

						<!-- other_assigned_scorer_id -->
						<template #cell(other_assigned_scorer_id)="data">
							{{ data.item.other_assigned_scorer_id }}
						</template>

						<!-- State -->
						<template #cell(state)="data">
							{{ getState(data.item.state) }}
						</template>

						<!-- Score -->
						<template #cell(score)="data">
							<inline-score
								v-if="data.item.score"
								:score="data.item.score"
								:rubric="item && item.rubric"
								tooltip
							/>
						</template>

						<!-- Actions -->
						<template #cell(actions)="data">
							<div class="w-100 text-center">
								<template v-if="canScore(data.item) && !data.item.score">
									<a
										v-if="!data.item.loading"
										@click="score(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
										v-tippy
										:title="$t('tooltip.score')"
									>
										<i v-if="data.item.state <= 2" class="material-icons tiny">check</i>
										<i
											v-if="data.item.state > 2 && data.item.state != 10"
											class="material-icons tiny"
											>done_all</i
										>
										<i
											v-if="data.item.state == 10 && !data.item.has_backread"
											class="material-icons tiny"
											>verified_user</i
										>
									</a>
									<a
										v-if="data.item.loading"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
									>
										<loading type="icon" />
									</a>
								</template>
								<template
									v-else-if="!data.item.score && data.item.assigned_scorer_id == user.scorer_id"
								>
									<i
										class="fas fa-question text-muted"
										v-tippy="{ theme: 'popover', placement: 'left-start' }"
										:title="$t('tooltip.waiting_for_first_assigned_score')"
									/>
								</template>
								<template v-if="data.item.rescore_score_id">
									<a
										@click="goToRescore(data.item)"
										class="btn btn-sm btn-icon btn-rounded btn-danger text-white m-0"
										v-tippy
										:title="$t('tooltip.rescore')"
									>
										<i class="fas fa-undo" />
									</a>
								</template>
							</div>
						</template>
					</b-table>

					<media
						v-if="view == 'single' && currentItems && currentItems[0]"
						:response="!busy && currentItems[0]"
						noChan
					/>
				</div>
				<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
					<a class="flex mx-3"
						>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
						{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
					>
					<div>
						<mz-pagination
							size="md"
							class="m-1"
							:total-rows="totalRows"
							v-model="currentPage"
							:per-page="perPage"
						></mz-pagination>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}
.w30 {
	width: 30px;
}
.audit-search > .form-group {
	margin-bottom: 5px;
}
.score-badge {
	font-size: 1rem;
	line-height: 1.5rem;
}
.badge {
	width: 1.25rem;
	height: 1.5rem;
	padding: 0 0;
}
.empty-badge {
	box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.mi-small {
	font-size: 1rem;
}
.ats-table {
	white-space: nowrap;
}
label {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.dot {
	height: 5px;
	width: 5px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}
.nodot {
	height: 5px;
	width: 5px;
	border-radius: 50%;
	display: inline-block;
}
.btn-topnav {
	margin-top: -8px;
	margin-bottom: -8px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}
.btn-topnav:last-child {
	margin-right: -16px;
	margin-left: -16px;
}
.btn-topnav:hover {
	background-color: rgba(0, 0, 0, 0.075);
}
.btn-topnav a {
	line-height: 56px;
}
.topnav-margins {
	margin-top: -8px;
	margin-bottom: -8px;
}
</style>

<script>
//UI Components
//Libraries
//import _ from "lodash";
import moment from "moment";
import $ from "jquery";

//Services
import ScoringService from "../../services/ScoringService";
import SectionService from "@/services/SectionService";
import ItemService from "@/services/ItemService";
import NormalScoringDriver from "@/services/ScoringDrivers/NormalScoringDriver";
import fs from "../../services/FormatService";
import Notie from "../../services/NotieService";
import store from "../../services/Store";
import BB from "bluebird";

import TableMultiselect from "../../components/TableMultiselect";
import ConfigUserSearch from "../../components/ConfigUserSearch";
import ConfigLabelSearch from "../../components/ConfigLabelSearch";
import ConfigSelect from "../../components/ConfigSelect";
import Score from "../../components/Score";
import InlineScore from "../../components/InlineScore";
import Media from "../../components/viewer/Media";
import ResponseImportModal from "../../components/ResponseImportModal";
import MzPagination from "../../components/MzPagination";

export default {
	name: "AssignedResponseList",
	props: ["user"],
	components: {
		Score,
		Media,
		TableMultiselect,
		ConfigUserSearch,
		ConfigLabelSearch,
		ConfigSelect,
		ResponseImportModal,
		MzPagination,
		InlineScore,
	},
	data() {
		let states = [
			{ label: "　", value: 0 }, //Any
			{ label: fs.responseState(1, this.$i18n), value: 1 }, //Unscored
			{ label: fs.responseState(2, this.$i18n), value: 2 }, //Reliability
			{ label: fs.responseState(3, this.$i18n), value: 3 }, //Resolution
			{ label: fs.responseState(10, this.$i18n), value: 10 }, //Complete
			{ label: fs.responseState(20, this.$i18n), value: 20 }, //Withheld
			{ label: fs.responseState(40, this.$i18n), value: 40 }, //Awaiting Resolution
		];

		var scoredOptions = [
			{ label: "　", value: null }, //Any
			{ label: this.$i18n.t("AssignedResponseList.not_scored"), value: false }, //Unscored
			{ label: this.$i18n.t("AssignedResponseList.scored"), value: true }, //Scored
		];

		var state = store.get(this, "arl.state");
		var scored = store.get(this, "arl.scored");

		var ref_id = store.getDefault(this, "arl.ref_id", "");
		var exclusivity_code = store.getDefault(this, "arl.exclusivity_code", "");

		var sortBy = store.get(this, "arl.sortBy");
		var sortDesc = store.get(this, "arl.sortDesc");
		var currentPage = store.get(this, "arl.currentPage");
		// debugger;

		var view = store.getDefault(this, "arl.view", "list");

		//Dynamically bound fields
		return {
			vm: this,
			fs: fs,
			loading: true,
			fields: [
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id_short"), sortable: true },
				{ key: "state", label: this.$i18n.t("fields.state"), sortable: true },
				{
					key: "score",
					label: this.$i18n.t("fields.score"),
					tdClass: "p-custom p-0 v-mid",
					sortable: false,
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					tdClass: "p-custom p-0 v-mid",
					thClass: "text-center",
				},
			],
			fieldsIncludingOtherAssignedScorerId: [
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id_short"), sortable: true },
				{
					key: "other_assigned_scorer_id",
					label: this.$i18n.t("fields.other_assigned_scorer_id"),
					sortable: true,
				},
				{ key: "state", label: this.$i18n.t("fields.state"), sortable: true },
				{
					key: "score",
					label: this.$i18n.t("fields.score"),
					tdClass: "p-custom p-0 v-mid",
					sortable: false,
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					tdClass: "p-custom p-0 v-mid",
					thClass: "text-center",
				},
			],
			currentPage: currentPage,
			perPage: view == "list" ? 20 : 1,
			totalRows: 0,
			filter: "",
			sortBy: sortBy,
			sortDesc: sortDesc,
			busy: null,
			currentItems: [],
			query: null,
			view: view,

			states: states,
			scoredOptions: scoredOptions,

			sectionID: null,
			section: null,
			itemID: null,
			item: null,
			assignedCount: null,
			scoredCount: null,

			state: state,
			scored: scored,
			ref_id: ref_id ? ref_id : "",
			exclusivity_code: exclusivity_code,
			meta: {},
		};
	},

	created() {
		this.sectionID = this.$route.params.section_id;
		this.itemID = this.$route.params.item_id;

		this.loadConfigData();
		this.refreshTableDelayed = _.debounce(() => {
			this.$refs.table.refresh();
		}, 500);
		this.refreshTable = _.debounce(() => {
			this.$refs.table.refresh();
		}, 10);
	},

	computed: {
		queryMetadataKeys() {
			let qmk = [];
			if (!(this.user && this.user.client && this.user.client.metadata_keys)) {
				return qmk;
			}

			_.each(this.user.client.metadata_keys, (mk) => {
				if (!mk.admin_only || this.user.role.admin_metadata) {
					qmk.push(mk);
				}
			});

			return qmk;
		},
	},

	watch: {
		state() {
			this.refreshTable();
			store.set(this, "arl.state", this.state);
		},
		scored() {
			this.refreshTable();
			store.set(this, "arl.scored", this.scored);
		},
		ref_id() {
			this.refreshTable();
			store.set(this, "arl.ref_id", this.ref_id);
		},
		exclusivity_code() {
			this.refreshTable();
			store.set(this, "arl.exclusivity_code", this.exclusivity_code);
		},
		view(newVal, oldVal) {
			if (newVal == "list" && oldVal != "list") {
				this.currentPage = Math.floor((this.currentPage - 1) / 20) + 1;
				this.perPage = 20;
			}
			if (newVal == "single" && oldVal != "single") {
				this.currentPage = (this.currentPage - 1) * 20 + 1;
				this.perPage = 1;
			}
			store.set(this, "arl.view", this.view);
		},
	},

	methods: {
		loadConfigData() {
			BB.props({
				section: SectionService.getSection(this.sectionID),
				item: ItemService.getItem(this.itemID),
				counts: ScoringService.myAssignedResponsesCount(this.sectionID, this.itemID, 12),
			}).then((resps) => {
				this.loading = false;
				this.section = resps.section.data;
				this.item = resps.item.data;

				this.assignedCount = resps.counts.data.assigned;
				this.scoredCount = resps.counts.data.scored;

				this.refreshTable();
			});
		},

		provider(ctx) {
			store.set(this, "arl.sortBy", this.sortBy);
			store.set(this, "arl.sortDesc", this.sortDesc);
			store.set(this, "arl.currentPage", this.currentPage);
			if (this.loading) {
				return [];
			}
			let query = this.getQuery();
			return ScoringService.myAssignedResponses(query, ctx)
				.then((resp) => {
					this.query = query;
					this.totalRows = resp.data.totalRows;
					this.currentItems = resp.data.rows;
					return this.currentItems;
				})
				.catch((err) => {
					console.log("Failed to get assigned responses", err);
					return [];
				});
		},

		getQuery() {
			let queryMeta = {};
			_.each(this.meta, (v, k) => {
				if (v != "") {
					queryMeta[k] = v;
				}
			});

			let query = {
				section_id: this.section && this.section.id,
				item_id: this.item && this.item.id,
				scored: this.scored,
				state: this.state,
				score_type: 12, // Just first and second scores for now
				ref_id: this.ref_id,
				// exclusivity_code: this.exclusivity_code,
				meta: queryMeta,
			};

			return query;
		},

		getQueryAll() {
			let query = {
				section_id: this.section && this.section.id,
				item_id: this.item && this.item.id,
			};

			return query;
		},

		getState(state) {
			// console.log(state);
			var stateObj = _.find(this.states, { value: state });
			if (stateObj) {
				return stateObj.label;
			}
			return "?";
		},

		format(date) {
			return moment(date).format(this.$i18n.t("Auditing.date_format"));
		},

		//Scoring stuff
		canScore(resp) {
			return ScoringService.canAssignedScore(this.user, resp, this.item);
		},

		score(resp) {
			if (!this.user.active) {
				Notie.error(this.$i18n.t("notie.cannot_score_response"), this.$i18n.t("notie.user_not_active"));
				return;
			}

			this.selected_resp = resp;
			var scr = _.find(resp.scores, { user_id: this.user.id });
			if (scr) {
				this.showAlreadyScoredModal(resp);
				return;
			}
			this.scoreResponse(resp);
		},

		scoreResponse(resp) {
			if (!(this.section && this.item && resp)) {
				return;
			}

			resp.loading = true;
			this.$forceUpdate();
			NormalScoringDriver.getResponse(
				{
					section_id: this.section.id,
					item_id: this.item.id,
				},
				resp.id
			)
				.then(() => {
					this.$router.push(`/scoring/${this.section.id}/${this.item.id}?resp=${resp.id}&return=true`);
					resp.loading = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.cannot_score_item", { item: this.item.name }), err);
					resp.loading = false;
					this.$forceUpdate();
				});
		},

		goToRescore(resp) {
			if (!(this.section && this.item && resp && resp.rescore_score_id)) {
				return;
			}
			this.$router.push(
				`/rescore/${this.section.id}/${this.item.id}/${resp.id}/${resp.rescore_score_id}?return=true`
			);
		},
	},
};
</script>
