<template>
	<div>
		<div class="video-box brightcove-sizer">
			<video
				:data-video-id="page.videoID"
				data-player="rJVWMIv9"
				data-embed="default"
				controls=""
				data-application-id
				class="video-js"
				data-setup='{ "playbackRates": [0.5, 1, 1.25, 1.5, 2] }'
			></video>
		</div>
	</div>
</template>

<script>
export default {
	name: "BrightcoveMedia",
	props: ["item", "page", "response"],
	data() {
		return {
			brightcoveScript: null,
		};
	},

	computed: {},

	watch: {},

	created() {},

	mounted() {
		this.loadBrightcovePlayer();
	},

	beforeDestroy() {
		this.removeBrightcovePlayer();
	},

	methods: {
		loadBrightcovePlayer() {
			let s = document.createElement("script");
			s.src = "https://players.brightcove.net/5079812791001/rJVWMIv9_default/index.min.js";
			document.head.appendChild(s);
			this.brightcoveScript = s;
		},

		removeBrightcovePlayer() {
			if (!this.brightcoveScript) {
				console.error("Could not remove Brightcove script: not found");
				return;
			}
			this.brightcoveScript.parentNode.removeChild(this.brightcoveScript);
		},
	},
};
</script>

<style scoped>
.video-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 800px;
	min-width: 200px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
	line-height: 0;
	aspect-ratio: 16/9;
}
</style>
