<template>
	<div class="box" style="height: 421px">
		<div class="d-flex flex-row h-100">
			<div class="flex d-flex flex-column h-100">
				<div class="no-shrink padding pb-2">
					<span class="float-right text-muted">
						<a
							@click="exportUserExceptions"
							class="no-select btn-subtle mr-4"
							v-tippy
							:title="$t('Dashboard.export_user_exceptions')"
						>
							<i class="fas fa-download align-self-end" />
						</a>
						<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
							<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
						</a>
					</span>
					<h6>
						{{ $t("Dashboard.user_activity") }}
						<span class="text-muted text-sm">({{ activeRows }}/{{ totalRows }})</span>
					</h6>
				</div>
				<div class="flex scroll-y inset px-3 pb-3 pt-0">
					<table>
						<super-dash-user-activity-row
							v-for="row in rows"
							:key="row.user.id"
							class="b-b"
							:activeUser="user"
							:user="row.user"
							:role_name="row.role_name"
							:current_session="row.current_session"
							:time="row.time"
							:initActive="row.active"
							:team_assignments="team_assignments"
						/>
						<tr v-if="rows.length < totalRows" style="border-top: 0px solid black">
							<td colspan="5">
								<div class="text-center p-2">
									<a
										v-if="totalRows - showRows > perPage && !busy"
										@click="loadMore()"
										class="btn btn-sm white"
										>Show {{ perPage.toLocaleString() }} More</a
									>
									<a v-if="!busy" @click="loadAll()" class="btn btn-sm white">
										Show {{ (totalRows - showRows).toLocaleString() }} More
										<span class="text-muted text-xxs">({{ $t("data_description.all") }})</span>
									</a>
									<loading v-if="busy" type="icon" style="font-size: 30px" class="text-muted" />
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
table {
	width: 100%;
}
td {
	padding: 0.75rem 0.5rem;
	vertical-align: middle;
}

.icon {
	width: 50px;
}
</style>

<script>
import ActivityService from "@/services/ActivityService";
import DashboardService from "@/services/DashboardService";
import TenantService from "@/services/TenantService";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import FileService from "@/services/FileService";
import moment from "moment";

import SuperDashUserActivityRow from "./SuperDashUserActivityRow";

require("@/directives/chartjs");

export default {
	name: "SuperDashUserActivity",

	props: ["team", "user"],

	components: {
		SuperDashUserActivityRow,
	},

	data() {
		return {
			fs: fs,

			rows: [],
			totalRows: 0,
			activeRows: 0,
			perPage: 10,
			showRows: null,

			roleNames: {},

			team_assignments: [],

			busy: false,
		};
	},
	created() {
		this.showRows = this.perPage;
		this.loadData();
	},
	watch: {
		"team.id"() {
			this.loadData();
		},
	},
	methods: {
		loadData() {
			if (this.busy) {
				return;
			}

			let tid = this.team && this.team.id;

			let ctx = {
				currentPage: 1,
				perPage: this.showRows,
			};
			if (this.team) {
				ctx.teamIDs = [this.team.id];
			}

			this.busy = true;
			Promise.all([
				ActivityService.getUsersCurrentActivity(ctx),
				DashboardService.getTeamAssignments(tid),
				TenantService.getClient(),
			])
				.then((resps) => {
					//resps[0] - activities
					this.rows = resps[0].data.activities;
					this.totalRows = resps[0].data.totalRows;
					this.activeRows = resps[0].data.activeRows;

					_.each(this.rows, (r) => {
						if (!r.active) {
							r.exception = { reason: "Unknown" };
						}
						if (r.lockout_msg.trim()) {
							r.exception = { reason: r.lockout_msg };
						}
					});

					//give one person an exception for mockup
					// let rand = Math.floor(Math.random() * this.rows.length);
					// this.rows[rand].exception = { reason: "Validity" };
					// console.log("Exception added to", rand, this.rows[rand]);

					//resps[1] - assignments
					this.team_assignments = resps[1].data;

					//resps[2] - client
					this.roleNames = {};
					_.each(resps[0].data.roles, (role) => {
						this.roleNames[role.id] = role.name;
					});
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});
		},

		async exportUserExceptions() {
			const { team } = this;
			try {
				const { data } = await ActivityService.getUserExceptionLogs(team && team.id);
				FileService.downloadText(`user_exceptions_${moment().format("YY-MM-DD")}.csv`, data);
			} catch (err) {
				Notie.error("Failed to get User Exception Logs", err);
			}
		},

		loadMore() {
			this.showRows += this.perPage;
			this.loadData();
		},

		loadAll() {
			this.showRows = 0;
			this.loadData();
		},
	},
};
</script>
