//Driver for audit scoring any Reads

import axios from "axios";

export default {
	icon: "check",
	name: "Auditing",
	showFlaggedForRel: true,

	showScores() {
		return false;
	},
	sidebarType() {
		return "auditing";
	},
	canBeEscalated: true,

	getNext(ctx) {
		var promise = axios.get(`/v1/fetch/${ctx.response_id}`);
		promise.then(function (resp) {
			console.log("AuditSD GetNext:" + resp.data.response.ref_id);
		});
		return promise;
	}
	// submitScore(score, response) {
	// 	score.response_id = response.id;

	// 	var promise = axios.post("/v1/legacy/submit", score);
	// 	promise.then(function(resp) {
	// 		console.log("NormalSD Submitted");
	// 	});
	// 	return promise;
	// }
};
