<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("APILogs.loopback_title") }}</div>

				<div class="ml-2">
					<button
						type="button"
						@click="loadData"
						class="btn no-border no-bg no-shadow"
						v-tippy
						:title="$t('tooltip.refresh')"
					>
						<i class="fa fa-sync" :class="{ 'fa-spin': refreshing }"></i>
					</button>
				</div>
			</div>
		</template>

		<!-- Search field -->
		<div id="navbarToggler">
			<form class="input-group m-2 my-lg-0">
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("APILogs.sectionID") }}</label>
						<span class="input-group-btn">
							<button
								v-if="!filterSectionID"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filterSectionID"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filterSectionID = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filterSectionID"
							class="form-control"
							:placeholder="$t('APILogs.search_sectionID')"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("APILogs.itemID") }}</label>
						<span class="input-group-btn">
							<button
								v-if="!filterItemID"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filterItemID"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filterItemID = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filterItemID"
							class="form-control"
							:placeholder="$t('APILogs.search_itemID')"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("APILogs.responseID") }}</label>
						<span class="input-group-btn">
							<button
								v-if="!filterResponseID"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filterResponseID"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filterResponseID = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filterResponseID"
							class="form-control"
							:placeholder="$t('APILogs.search_responseID')"
						/>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>{{ $t("APILogs.well_formed") }}</label>
						<span class="input-group-btn">
							<button
								v-if="filterSuccessStatus.id == 1"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filterSuccessStatus.id != 1"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filterSuccessStatus = '1'"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<config-select
							style="background-color: white"
							:options="options"
							sortBy="-"
							v-model="filterSuccessStatus"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("APILogs.from_date") }}
							<user-timezone />
						</label>
						<config-date v-model="filterFromDate" :config="{ showClear: true }"></config-date>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-4">
					<div class="form-group">
						<label>
							{{ $t("APILogs.to_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="filterToDate"
							:config="{ showClear: true }"
							defaultTime="11:59 PM"
						></config-date>
					</div>
				</div>
			</form>
		</div>

		<div class="content-main scroll-x scroll-y">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="messages"
					:fields="table_fields"
					:current-page="1"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:busy.sync="busy"
					fixed
				>
					<!-- Time Received -->
					<template #cell(time_received)="data">
						<span>{{ fs.fullDate(data.item.time_received) }}</span>
					</template>

					<!-- Well-formed -->
					<template #cell(parse_error)="data">
						<i v-if="!data.item.parse_error" class="text-success fas fa-check fa-2x" />
						<i
							v-if="data.item.parse_error"
							class="text-danger fas fa-times fa-2x"
							v-tippy
							:title="data.item.parse_error"
						/>
					</template>

					<!-- Body -->
					<template #cell(body)="data">
						<pre class="preview">{{ data.item.body }}</pre>
					</template>

					<!-- Actions -->
					<template #cell(actions)="data">
						<button @click="openDetailsModal(data.item)" class="btn btn-sm btn-block theme-accent">
							<i class="fas fa-fw fa-brackets-curly"></i>
							<span class="hidden-folded d-inline">{{ $t("APILogs.view_full_json") }}</span>
						</button>
					</template>

					<template slot="empty">
						<div class="d-flex flex-row justify-content-center">
							<loading type="table" v-if="loading" />
							<h5 v-if="!loading" class="text-extra-muted">{{ $t("data_description.no_records") }}</h5>
						</div>
					</template>
				</b-table>
			</div>
		</div>
		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="detailsModal" :visible="viewingMessage != null" @hide="viewingMessage = null" size="md">
			<template slot="modal-title">{{ $t("APILogs.received_score_data") }}</template>
			<div v-if="viewingMessage" class="modal-scroll" style="margin-bottom: -0.5rem">
				<pre v-if="!prettify">{{ viewingMessage.body }}</pre>
				<pre v-if="prettify">{{ viewingMessage.prettified }}</pre>
				<div class="d-flex flex-row justify-content-between mt-1 mx-2">
					<div>
						<i
							v-if="viewingMessage.parse_error"
							class="text-danger fas fa-times fa-2x"
							v-tippy
							:title="viewingMessage.parse_error"
						/>
					</div>
					<div>
						<span class="text-muted">{{ fs.fullDate(viewingMessage.time_received) }}</span>
					</div>
				</div>
			</div>
			<template slot="modal-footer">
				<div class="d-flex flex-row justify-content-between align-items-center w-100">
					<div>
						<div class="mb-1">{{ $t("APILogs.prettify_json") }}</div>
						<label class="ui-switch ui-switch-md theme-accent">
							<input type="checkbox" v-model="prettify" />
							<i></i>
						</label>
					</div>
					<div>
						<button class="btn primary btn-flat" @click="closeDetailsModal">
							{{ $t("buttons.done") }}
						</button>
					</div>
				</div>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w30 {
	width: 30px;
}

.w60 {
	width: 60px;
}

.constrained-cell {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.modal-scroll {
	max-height: calc(100vh - 250px);
}

.top-bar {
	padding-left: calc(15px + 1rem);
	padding-right: calc(15px + 1rem);
	margin-top: -6px;
	padding-bottom: 6px;
}

.table {
	table-layout: fixed;
}

pre {
	white-space: pre-wrap;
	display: block;
	word-wrap: break-word;
	margin-bottom: 0;
	width: 100%;
	padding: 0;
	margin: 0;
	font-size: 12px;
	line-height: 20px;
	background: #efefef;
	border: 1px solid #ccc;
	border-radius: 6px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

pre.preview {
	overflow: auto;
	overflow-y: hidden;
	text-overflow: ellipsis;
	max-height: 74px;
	line-height: 20px;
}
</style>

<script>
//UI Components
//Libraries
import moment from "moment";

//Services
import APILogsService from "@/services/APILogsService";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";
import UserTimezone from "@/components/UserTimezone";
import ConfigDate from "@/components/ConfigDate";
import ConfigSelect from "@/components/ConfigSelect";

export default {
	name: "APILogsLoopback",

	props: ["user"],

	components: {
		UserTimezone,
		ConfigDate,
		ConfigSelect,
	},

	data() {
		return {
			vm: this,
			fs: fs,
			table_fields: [
				{
					key: "time_received",
					label: this.$i18n.t("APILogs.time_received"),
					sortable: true,
					thStyle: { width: "175px" },
				},
				{
					key: "parse_error",
					label: this.$i18n.t("APILogs.well_formed"),
					tdClass: "text-center",
					thStyle: { "text-align": "center", width: "110px" },
				},
				{
					key: "body",
					label: this.$i18n.t("APILogs.body"),
					thStyle: { "text-align": "center", "min-width": "200px" },
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center", width: "175px" },
				},
			],

			messages: [],
			viewingMessage: null,
			prettify: true,

			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			active: false,
			sortBy: "time_received",
			sortDesc: true,
			busy: false,

			refreshTimeout: 1000,
			loading: true,
			refreshing: false,
			autoRefresh: null,
			filterResponseID: "",
			filterItemID: "",
			filterSectionID: "",
			filterToDate: null,
			filterFromDate: null,
			filterSuccessStatus: 1,
			options: [
				{ name: "pass and fail", id: 1 },
				{ name: "pass", id: 2 },
				{ name: "fail", id: 3 },
			],
		};
	},

	created() {
		this.loadData();

		this.autoRefreshFunction = () => {
			this.loadData(true);
			this.updateRefreshTimeout();
			this.scheduleRefresh();
		};

		this.scheduleRefresh();
	},

	destroyed() {
		clearTimeout(this.autoRefresh);
	},

	watch: {
		currentPage() {
			this.loadData();
		},
		filter() {
			this.loadData();
		},
		sortBy() {
			this.loadData();
		},
		sortDesc() {
			this.loadData();
		},
		filterResponseID() {
			this.loadData();
		},
		filterSectionID() {
			this.loadData();
		},
		filterItemID() {
			this.loadData();
		},
		filterToDate() {
			this.loadData();
		},
		filterFromDate() {
			this.loadData();
		},
		filterSuccessStatus() {
			this.loadData();
		},
	},

	methods: {
		loadData(noBusy) {
			let ctx = {
				currentPage: this.currentPage,
				perPage: this.perPage,
				filter: this.filter,
				sortBy: this.sortBy,
				sortDesc: this.sortDesc,
				active: this.showComplete,
				filterResponseID: this.filterResponseID,
				filterSectionID: this.filterSectionID,
				filterItemID: this.filterItemID,
				filterToDate: this.filterToDate,
				filterFromDate: this.filterFromDate,
				filterSuccessStatus: this.filterSuccessStatus.id,
			};

			this.refreshing = true;
			if (!noBusy) {
				this.busy = true;
			}
			return APILogsService.listLoopbackMessages(ctx, noBusy)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.messages = resp.data.messages;
					this.loading = false;
					this.refreshing = false;
					this.busy = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("APILogs.fail_get_messages"), err);
					this.loading = false;
					this.refreshing = false;
					this.busy = false;
					return [];
				});
		},

		updateRefreshTimeout() {
			//Exponential backoff until 60 second interval
			this.refreshTimeout *= 2;
			if (this.refreshTimeout > 60000) {
				this.refreshTimeout = 60000;
			}
		},

		scheduleRefresh() {
			if (this.autoRefresh) {
				clearTimeout(this.autoRefresh);
			}
			this.autoRefresh = setTimeout(this.autoRefreshFunction, this.refreshTimeout);
		},

		touchRefresh() {
			this.refreshTimeout = 1000;
			this.scheduleRefresh();
		},

		openDetailsModal(message) {
			this.viewingMessage = message;
			this.prettify = true;
			this.viewingMessage.prettified = JSON.stringify(JSON.parse(message.body), null, 2);
		},

		closeDetailsModal() {
			this.viewingMessage = null;
		},
	},
};
</script>
