<template>
	<div class="calculator">
		<div class="screen">
			<div class="equation text-theme">{{equation}} </div>
			<div class="main-input">{{message}}</div>
		</div>
		<div class="buttons">
			<button v-on:click="clear" class="button theme-lighten-2 text-white">{{ !value ? 'AC' : 'C' }}</button>
			<button v-on:click="inverse" class="button theme-lighten-2 text-white">+/-</button>
			<button v-on:click="percentage" class="button theme-lighten-2 text-white">%</button>
			<button v-on:click="operator('/')" class="button theme text-white">/</button>
			<button v-on:click="input('7')" class="button theme-lighten-2 text-white">7</button>
			<button v-on:click="input('8')" class="button theme-lighten-2 text-white">8</button>
			<button v-on:click="input('9')" class="button theme-lighten-2 text-white">9</button>
			<button v-on:click="operator('*')" class="button theme text-white">*</button>
			<button v-on:click="input('4')" class="button theme-lighten-2 text-white">4</button>
			<button v-on:click="input('5')" class="button theme-lighten-2 text-white">5</button>
			<button v-on:click="input('6')" class="button theme-lighten-2 text-white">6</button>
			<button v-on:click="operator('-')" class="button theme text-white">-</button>
			<button v-on:click="input('1')" class="button theme-lighten-2 text-white">1</button>
			<button v-on:click="input('2')" class="button theme-lighten-2 text-white">2</button>
			<button v-on:click="input('3')" class="button theme-lighten-2 text-white">3</button>
			<button v-on:click="operator('+')" class="button theme text-white">+</button>
			<button id="zero" v-on:click="input('0')" class="button theme-lighten-2 text-white">0</button>
			<button v-on:click="input('.')" class="button theme-lighten-2 text-white">.</button>
			<button v-on:click="equal" class="button theme text-white">=</button>
		</div>
	</div>
</template>

<style scoped>
.calculator {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 17rem;
	pointer-events: all;
}
.calculator .screen {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.5rem;
	margin-left: 3px;
	margin-right: 3px;
	text-align: right;
	border: none;
	background-color: #1d1f20;
	border-radius: 0.5rem;
	opacity: 0.9;
}
.calculator .screen .equation {
	font-size: 1.5rem;
	line-height: 1.5rem;
	text-align: right;
	border: none;
	color: #ffffff;
	border-radius: 0.5rem;
	min-height: 30px;
}
.calculator .screen .main-input {
	font-size: 3rem;
	line-height: 3rem;
	margin-bottom: 4px;
	text-align: right;
	border: none;
	color: #ffffff;
	border-radius: 0.5rem;
	height: 48px;
}
.calculator .buttons {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	font-size: 1.5rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.calculator .buttons .button {
	width: calc(25% - 0.2rem);
	padding: 0.5rem 0;
	margin: 0.1rem;
	border-radius: 0.5rem;
	text-align: center;
	box-sizing: border-box;
	-webkit-transition: box-shadow 0.3s;
	transition: box-shadow 0.3s;
}
.calculator .buttons .button:hover {
	-webkit-filter: opacity(0.8);
	filter: opacity(0.8);
}
.calculator .buttons .button:active {
	box-shadow: 2px 2px 10px #666 inset;
}
.calculator .buttons #zero {
	text-align: left;
	padding-left: 2rem;
	-webkit-box-flex: 2;
	-ms-flex-positive: 2;
	flex-grow: 2;
}
</style>

<script>
let orderOfOperations = {
	"+": 10,
	"-": 10,
	"*": 20,
	"/": 20,
	"=": 100
};

export default {
	name: "Calculator",

	data() {
		return {
			value: "",
			message: "0",
			eqTree: [],
			doEqual: false,
			cal: ""
		};
	},
	created() {},
	watch: {},
	computed: {
		equation() {
			let str = this.eqString(this.eqTree);
			if (this.doEqual) {
				str += " =";
			}
			return str == "" ? " " : str;
		}
	},
	methods: {
		input(digit) {
			if (this.doEqual) this.clear();
			if (digit == "." && this.message.includes(".")) return;
			else if (this.message == "0" || this.message == " ") {
				this.message = digit;
				this.value = digit;
			} else {
				this.value += digit;
				this.message = this.value;
			}
			this.num(this.value);
		},
		clear() {
			if (this.value == "") {
				this.eqTree = [];
				this.message = "0";
			} else {
				this.message = " ";
			}
			this.value = "";
			this.doEqual = false;
			// console.log("cleared", this.eqTree);
		},
		num(num) {
			this.doEqual = false;

			let newNode = { type: "num", val: num };
			if (!this.eqTree.length) {
				this.eqTree = [newNode];
			}

			let lastNode = this.eqTree[this.eqTree.length - 1];

			if (lastNode.type == "num") {
				//We want to replace the existing number with new number, so delete the current one
				this.eqTree.pop();
			}

			this.eqTree.push(newNode);
		},
		operator(opt) {
			if (!this.eqTree.length) return;
			this.doEqual = false;

			let lastNode = this.eqTree[this.eqTree.length - 1];

			if (lastNode.type == "op") {
				//We want to replace the existing operator with new operator, so delete the current one
				this.eqTree.pop();
			}

			if (this.eqTree.length == 1 && Array.isArray(this.eqTree[0])) {
				//The entire equation tree is just a wrapper for a deeper tree, so unwrap it
				//This will happen if an op that requires wrapping is replaced with an op that doesn't
				this.eqTree = this.eqTree[0];
			}

			lastNode = this.eqTree[this.eqTree.length - 1];
			let ooo = orderOfOperations[opt];
			let newNode = { type: "op", val: opt, ooo: ooo };
			let lowestOoo = 100;
			_.each(this.eqTree, op => {
				if (op.ooo) {
					lowestOoo = Math.min(op.ooo, lowestOoo);
				}
			});

			if (ooo > lowestOoo) {
				this.eqTree = [this.eqTree, newNode];
			} else {
				this.eqTree.push(newNode);
			}

			this.message = " ";
			this.value = "";

			// console.log(this.eqTree);
		},
		percentage() {
			this.message = this.message / 100;
			this.value = this.message;
			this.num(this.value);
		},
		inverse() {
			if (this.message == 0) return false;
			if (this.message[0] == "-") this.message = this.message.substr(1);
			else this.message = "-" + this.message;
			this.value = this.message;
			this.num(this.value);
		},
		equal() {
			if (!this.eqTree.length) return;
			this.doEqual = true;
			this.message = this.eval(this.eqTree);
			this.value = "";
			// console.log("eqTree", this.eqTree, "is", this.message);
		},
		eval(eqTree) {
			let eq = "";
			_.each(eqTree, op => {
				if (Array.isArray(op)) {
					eq += this.eval(op);
				} else {
					if (op.val < 0) {
						eq += `(${op.val})`;
					} else {
						eq += op.val;
					}
				}
			});
			// console.log("eval", eq);
			return eval(eq);
		},
		eqString(eqTree) {
			let strs = [];
			_.each(eqTree, op => {
				if (Array.isArray(op)) {
					strs.push(`(${this.eqString(op)})`);
				} else {
					if (op.val < 0) {
						strs.push(`(${op.val})`);
					} else {
						strs.push(op.val);
					}
				}
			});
			return strs.join(" ");
		}
	}
};
</script>
