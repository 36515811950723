<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">{{ $t("ItemEdit.ViewerControls.title") }}</div>
		<div class="row m-0">
			<form role="form" class="p-4 col-sm-4">
				<p>{{ $t("ItemEdit.ViewerControls.scoring_options.title") }}</p>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.zoom_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.zoom") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.angle_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.angle") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.ruler_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.ruler.title") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.rotate_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.rotate") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.invert_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.invert_colors") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.calc_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.calculator") }}
					</label>
				</div>
				<div class="checkbox" v-if="item.use_mathjax">
					<label class="ui-check">
						<input v-model="item.mathjax_toggle_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.toggle_formulas") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.notes_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.notes") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.annotations_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.annotations") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.playback_speed_enabled" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.scoring_options.playback_speed") }}
					</label>
				</div>
				<hr class="mb-4" />
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.fit_to_content" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.options.fit_page") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.show_timeout_timer" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.options.timeout_timer") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.hide_response_id" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.options.hide_resp_id") }}
					</label>
				</div>
				<div class="checkbox">
					<label
						class="ui-check"
						v-tippy="{ theme: 'popover', placement: 'right-start' }"
						title="Greatly reduces the loading time of large media files, such as PDFs, but causes each scorer to lock 2 responses at a time"
					>
						<input v-model="item.prefetch_media" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.options.prefetch_media") }}
					</label>
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.use_mathjax" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.options.format_formulas.title") }}
					</label>
				</div>
				<div class="form-group" v-if="item.use_mathjax" :class="{ invalid: !valid.field('mathjax_delimiter') }">
					<label>{{ $t("ItemEdit.ViewerControls.options.format_formulas.delimiter") }}</label>
					<input type="text" class="form-control" v-model="item.mathjax_delimiter" />
				</div>
				<div class="checkbox">
					<label class="ui-check">
						<input v-model="item.show_word_count" type="checkbox" />
						<i class="dark-white"></i> {{ $t("ItemEdit.ViewerControls.options.show_word_count") }}
					</label>
				</div>
			</form>
			<form role="form" class="p-4 col-sm-3">
				<div class="form-group">
					<label v-if="item.clip_percent >= 0">{{
						$t("ItemEdit.ViewerControls.clip_percent_from_top")
					}}</label>
					<label v-else>{{ $t("ItemEdit.ViewerControls.clip_percent_from_bottom") }}</label>
					<percent-input v-model="item.clip_percent" :negative="true" />
				</div>
				<div class="form-group">
					<label v-if="item.clip_v_percent >= 0">{{
						$t("ItemEdit.ViewerControls.clip_percent_from_left")
					}}</label>
					<label v-else>{{ $t("ItemEdit.ViewerControls.clip_percent_from_right") }}</label>
					<percent-input v-model="item.clip_v_percent" :negative="true" />
				</div>
				<div class="row">
					<div class="form-group col-6" v-if="item.ruler_enabled">
						<label>{{ $t("ItemEdit.ViewerControls.scoring_options.ruler.pixels") }}</label>
						<input type="number" class="form-control" v-model.number="item.ruler_dpi" />
					</div>
					<div class="form-group col-6" v-if="item.ruler_enabled">
						<label>{{ $t("ItemEdit.ViewerControls.scoring_options.ruler.unit") }}</label>
						<config-select :options="units" byField="id" v-model="item.ruler_units" />
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<style>
.invalid {
	color: red;
}
</style>

<script>
import PercentInput from "@/components/PercentInput";
import ConfigSelect from "@/components/ConfigSelect";
import fs from "@/services/FormatService";

var units = fs.rulerUnitTypes();

export default {
	name: "ItemEditViewer",

	props: ["id", "item", "defaultTab", "valid"],

	components: {
		PercentInput,
		ConfigSelect,
	},

	data() {
		return {
			units: units,
		};
	},

	watch: {
		"item.ruler_enabled"() {
			if (this.item.ruler_enabled && !this.item.ruler_dpi) {
				this.item.ruler_dpi = 72;
			}
			if (this.item.ruler_enabled && !this.item.ruler_units) {
				this.item.ruler_units = 1;
			}
		},
		"item.ruler_units"(newVal, oldVal) {
			if (newVal && oldVal) {
				let newUnit = _.find(units, { id: newVal });
				let oldUnit = _.find(units, { id: oldVal });

				let scale = oldUnit.relative / newUnit.relative;

				this.item.ruler_dpi = this.item.ruler_dpi * scale;
			}
		},
	},

	created() {},

	methods: {},
};
</script>
