<template>
	<div class="d-flex flex-column align-items-center flex h100 page-body">
		<exemplar-set
			v-if="set && item"
			:set="set"
			:item="item"
			:numPages.sync="numPages"
			:currentPage="currentPage"
			:compensationStartTime="compensationStartTime"
			class="scroll-x scroll-y"
		/>
		<exemplar-nav :set="set" :numPages="numPages" v-model="currentPage" />
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import ExemplarSet from "@/components/ExemplarSet";
import ExemplarNav from "@/components/ExemplarNav";
import QCService from "@/services/QCService";
import QCConfigService from "@/services/QCConfigService";
import ItemService from "@/services/ItemService";
import Notie from "@/services/NotieService";
import BB from "bluebird";
import CompensationQueueTimeService from "@/services/CompensationQueueTimeService";

export default {
	name: "ExemplarSetPage",
	data() {
		return {
			set: null,
			item: null,
			responses: null,
			numPages: 0,
			currentPage: 1,
			compensationStartTime: null,
		};
	},

	components: { ExemplarSet, ExemplarNav },

	props: ["user", "params"],

	watch: {},

	computed: {},

	created() {
		this.init();
	},
	methods: {
		init() {
			BB.props({
				item: ItemService.getItem(this.params.item_id),
				set: QCService.getQCSet(this.params.set_id),
				responses: QCConfigService.listSetResponses(this.params.set_id),
				compensationStartTime: CompensationQueueTimeService.getCompensationStartTime(),
			})
				.then((resps) => {
					this.item = resps.item.data;
					this.set = resps.set.data;
					this.set.responses = _.sortBy(resps.responses.data, "sequence");
					this.compensationStartTime = resps.compensationStartTime.data;
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to load exemplar set", err);
				});
		},
	},
};
</script>


