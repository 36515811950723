<template>
    <v-select :options="themes" aria-hidden="true" :value="selectedTheme" @input="emitTheme" :clearable="clearable" :disabled="disabled">
        <template slot="selected-option" slot-scope="option">
            <i class="fa fa-circle" :style="{color: `rgb(${option.r*1.1}, ${option.g*1.1}, ${option.b*1.1}) !important`}"></i> {{option.label}}
        </template>
        <template slot="option" slot-scope="option">
            <i class="fa fa-circle" :style="{color: `rgb(${option.r*1.1}, ${option.g*1.1}, ${option.b*1.1}) !important`}"></i> {{option.label}}
        </template>
    </v-select>
</template>

<style scoped>
</style>

<script>
import vSelect from "vue-select";

export default {
	name: "ThemeSelect",
	props: ["themes", "value", "disabled"],
	components: {
		"vue-select": require("vue-select")
	},
	data() {
		return {
			selectedTheme: {},
			clearable: false
		};
	},
	created() {
		this.selectTheme(this.value);
	},
	watch: {
		value: function(val) {
			this.selectTheme(val);
		}
	},
	methods: {
		emitTheme(theme) {
			if (theme) {
				this.$emit("input", theme.name);
			}
		},
		selectTheme(theme) {
			if (theme) {
				this.selectedTheme = _.find(this.themes, { name: theme });
			}
		}
	}
};
</script>
