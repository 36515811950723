<template>
	<page :title="$t('Forecast.project_forecast')" customBody>
		<div class="flex scroll-x scroll-y">
			<div class="row mx-0 flex-fixed px-3 pt-3 box mb-2">
				<div class="col-12 col-lg-4">
					<div class="form-group">
						<label>{{ $t("Forecast.project") }}</label>
						<config-select
							nullText="No projects"
							:options="projects"
							v-model="selectedProject"
						></config-select>
					</div>
				</div>
				<div class="col-12 col-lg-8 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-column">
						<div
							v-if="burndownStats && burndownStats.length > 0"
							class="d-flex flex-row justify-content-end mb-1"
						>
							<div class="_600">{{ $t("Forecast.submitted_times") }}</div>
							<div class="mx-2" v-tippy :title="$t('Forecast.use_goal_times_tooltip')">
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="useGoalTimes" />
									<i></i>
								</label>
							</div>
							<div class="_600">{{ $t("Forecast.goal_times") }}</div>
						</div>
						<div class="d-flex flex-row align-items-center form-group">
							<div class="py-0 ml-1">
								<button
									v-if="burndownStats && burndownStats.length > 0"
									@click="openImportIngestModal"
									class="btn btn-sm btn-block flex-fixed"
									v-b-tooltip
									:class="{ [selectedProject ? 'btn-primary' : 'btn-secodary']: true }"
									:disabled="!selectedProject"
								>
									<i v-if="!running" class="far fa-inventory"></i>
									<loading v-if="running" type="icon" />
									<span class="hidden-folded d-inline"
										>&nbsp;{{ $t("Forecast.import_predictions") }}</span
									>
								</button>
							</div>
							<div class="py-0 ml-1">
								<button
									@click="getStats"
									class="btn btn-sm btn-secondary btn-block flex-fixed"
									v-b-tooltip
									:class="{ 'theme-accent': selectedProject }"
									:disabled="!(selectedProject && sections)"
								>
									<i v-if="!running" class="far fa-clipboard-list"></i>
									<loading v-if="running" type="icon" />
									<span class="hidden-folded d-inline">&nbsp;{{ $t("Forecast.run_forecast") }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="burndownStats && burndownStats.length > 0" class="d-flex flex-row">
				<div class="flex">
					<!-- Missing completion rate warning -->
					<div
						v-if="missingPredictionLevel == 2"
						class="card border-0 box-shadow-3 mx-3 mb-2 py-2 px-3 d-flex flex-row align-items-center"
					>
						<i class="fas fa-exclamation-triangle text-danger" style="font-size: 30px" />
						<div class="ml-3 flex">
							<div>
								{{ $t("Forecast.completion_rates_missing") }}
							</div>
							<div class="mt-2">{{ $t("Forecast.completion_rates_missing_2") }}</div>
						</div>
						<button class="btn btn-danger" @click="adjustRatesModal = true">
							{{ $t("Forecast.adjust_rates") }}
						</button>
					</div>

					<!-- Overridden completion rate warning -->
					<div
						v-if="missingPredictionLevel == 1"
						class="card border-0 box-shadow-3 mx-3 mb-2 py-2 px-3 d-flex flex-row align-items-center"
					>
						<i
							class="fas fa-exclamation-circle text-warning"
							style="font-size: 30px; width: 33.75px; padding-left: 2px"
						/>
						<div class="ml-3 flex">
							<div>
								{{ $t("Forecast.completion_rates_overridden") }}
							</div>
							<div class="mt-2">{{ $t("Forecast.completion_rates_overridden_2") }}</div>
						</div>
						<button class="btn btn-warning text-white" @click="adjustRatesModal = true">
							{{ $t("Forecast.adjust_rates") }}
						</button>
					</div>

					<!-- Unsaved goal time changes warning -->
					<div
						v-if="numChangedConfigs > 0"
						class="card border-0 box-shadow-3 mx-3 mb-2 py-2 px-3 d-flex flex-row align-items-center"
					>
						<i
							class="fas fa-calendar-exclamation text-primary"
							style="font-size: 30px; width: 33.75px; padding-left: 2px"
						/>
						<div class="ml-3 flex">
							<div>
								{{ $t("Forecast.confirm_goal_changes") }}
							</div>
							<div class="mt-2">
								{{ $t("Forecast.confirm_goal_changes_2") }}
							</div>
						</div>
						<button class="btn btn-primary text-white" @click="showSaveGoalsModal">
							{{ $t("Forecast.save_goals") }}
						</button>
					</div>

					<div class="card border-0 box-shadow-3 mb-3 mt-2">
						<table class="table">
							<tr>
								<th class="pl-4">{{ $t("Forecast.date") }}</th>
								<th colspan="2">{{ $t("Forecast.available_work_time") }}</th>
								<th>{{ $t("Forecast.additional_responses") }}</th>
								<th>
									{{ $t("Forecast.complete_responses") }}
									<a
										class="btn-subtle ml-1"
										:class="{
											'cog-red': missingPredictionLevel == 2,
											'cog-yellow': missingPredictionLevel == 1,
										}"
										@click="adjustRatesModal = true"
									>
										<i
											class="fas fa-lg fa-cog"
											v-tippy
											:title="$t('Forecast.adjust_completion_rates')"
										/>
									</a>
								</th>
								<th>{{ $t("Forecast.outstanding") }}</th>
								<th>{{ $t("Forecast.note") }}</th>
								<th></th>
							</tr>
							<template v-for="row in projectionResults">
								<tr :key="`main-${row.date}`">
									<td class="pl-4">{{ moment(row.date, "MM/DD/YYYY").format("ddd, ll") }}</td>
									<template v-if="!useGoalTimes">
										<td colspan="2">
											{{
												$t("Forecast.hours_abr", {
													hours: fs.fixed1d(row.availableWorkMinutes / 60),
												})
											}}
										</td>
									</template>
									<template v-if="useGoalTimes">
										<td>
											{{
												$t("Forecast.hours_abr", {
													hours: fs.fixed1d(row.u_availableWorkMinutes / 60),
												})
											}}
										</td>
										<td>
											<a
												:id="`edit-goal-${row.date}`"
												class="btn-subtle-around"
												v-tippy
												:title="row.goalTimeTooltip"
											>
												<span
													:class="{
														'text-very-muted':
															row.g_availableWorkMinutes <= row.u_availableWorkMinutes,
													}"
												>
													{{
														$t("Forecast.hours_abr_add", {
															hours: fs.fixed1d(
																(row.availableWorkMinutes -
																	row.u_availableWorkMinutes) /
																	60
															),
														})
													}}
												</span>
											</a>
											<b-popover
												v-if="!selectedProject.use_shifts"
												:show.sync="row.showGoalEdit"
												:target="`edit-goal-${row.date}`"
												triggers="click"
												placement="bottomright"
											>
												<template slot="title">{{ $t("Forecast.change_goal_time") }}</template>
												<div class="w">
													<table style="font-size: 0.9rem">
														<tr>
															<td class="v-mid text-muted">
																{{ $t("Forecast.scheduled") }}:
															</td>
															<td class="v-mid" style="padding-left: 29px">
																<span>
																	{{
																		fs.fixed1d(row.u_availableWorkMinutes / 60)
																	}} </span
																><span class="text-muted">{{
																	$t("Forecast.hours")
																}}</span>
															</td>
														</tr>
														<tr>
															<td class="v-mid pt-2 text-muted">
																{{ $t("Forecast.goal") }}:
															</td>
															<td class="v-mid pl-3 pt-2">
																<input
																	class="form-control"
																	style="width: 100px"
																	type="number"
																	v-model.number="row.newGoalTime"
																/>
															</td>
														</tr>
													</table>

													<div class="float-right my-3 mx-1">
														<button
															class="btn btn-secondary"
															@click="row.showGoalEdit = false"
														>
															{{ $t("Forecast.cancel") }}
														</button>
														<button class="btn btn-primary" @click="adjustGoalTime(row)">
															{{ $t("Forecast.ok") }}
														</button>
													</div>
												</div>
											</b-popover>
											<b-popover
												v-if="selectedProject.use_shifts"
												:show.sync="row.showGoalEdit"
												:target="`edit-goal-${row.date}`"
												triggers="click"
												placement="bottomright"
											>
												<template slot="title">{{ $t("Forecast.change_goal_times") }}</template>
												<div>
													<table style="font-size: 0.9rem">
														<template
															v-if="getDayShifts(selectedProject, row.date).length > 0"
														>
															<tr>
																<td class="v-mid text-muted"></td>
																<td class="v-mid pl-3">
																	{{ $t("Forecast.scheduled") }}
																</td>
																<td class="v-mid pl-3">
																	{{ $t("Forecast.goal") }}
																</td>
															</tr>
															<tr
																v-for="shift in getDayShifts(selectedProject, row.date)"
																:key="shift.id"
															>
																<td class="pt-1">
																	<div>
																		<span>{{ shift.start }}</span> to
																		<span>{{ shift.end }}</span>
																	</div>
																</td>
																<td class="pt-1 pl-3 text-center">
																	{{ row.shift_counts[shift.id] }}
																</td>
																<td class="pt-1 pl-3">
																	<input
																		class="form-control"
																		style="width: 80px"
																		type="number"
																		v-model.number="
																			row.new_shift_thresholds[shift.id]
																		"
																	/>
																</td>
															</tr>
															<tr>
																<td class="v-mid pt-2 text-muted">
																	{{ $t("Forecast.total") }}:
																</td>
																<td class="v-mid pt-2 pl-3">
																	<span>{{
																		fs.fixed1d(row.u_availableWorkMinutes / 60)
																	}}</span>
																	<span class="text-muted">{{
																		$t("Forecast.hours")
																	}}</span>
																</td>
																<td class="v-mid pt-2 pl-3">
																	<span>{{
																		fs.fixed1d(getNewShiftWorkTime(row) / 60)
																	}}</span>
																	<span class="text-muted">{{
																		$t("Forecast.hours")
																	}}</span>
																</td>
															</tr>
														</template>
														<template
															v-if="getDayShifts(selectedProject, row.date).length == 0"
														>
															{{ $t("Forecast.no_shifts") }}
														</template>
													</table>

													<div class="float-right my-3 mx-1">
														<button
															class="btn btn-secondary"
															@click="row.showGoalEdit = false"
														>
															{{ $t("Forecast.cancel") }}
														</button>
														<button class="btn btn-primary" @click="adjustGoalTime(row)">
															{{ $t("Forecast.ok") }}
														</button>
													</div>
												</div>
											</b-popover>
										</td>
									</template>
									<td>{{ row.addedCount }}</td>
									<td>{{ row.completedCount }}</td>
									<td>{{ row.outstandingCount }}</td>
									<td>
										<template v-if="row.noteType == 'expired'">
											<a class="text-danger clickable" @click="openExpirationModal(row)">
												<i class="fas fa-exclamation-triangle mr-1" />{{
													$t("Forecast.n_expired", { n: row.expiredCount })
												}}
											</a>
										</template>
										<template v-if="row.noteType == 'finished'">
											<span class="text-success">
												<i class="fas fa-badge-check mr-1" />{{ $t("Forecast.work_finished") }}
											</span>
										</template>
									</td>
									<td>
										<a
											@click="row.showDetails = !row.showDetails"
											:class="{
												[row.steps && row.steps.length > 0
													? 'btn-subtle'
													: 'btn-subtle text-very-muted']: true,
											}"
										>
											<i
												v-if="!row.showDetails"
												class="fas fa-lg fa-caret-up"
												v-tippy
												:title="$t('Forecast.show_details')"
											></i>
											<i
												v-if="row.showDetails"
												class="fas fa-lg fa-caret-down"
												v-tippy
												:title="$t('Forecast.hide_details')"
											></i>
										</a>
									</td>
								</tr>
								<tr v-if="row.showDetails" :key="`details-${row.date}`">
									<td colspan="7" class="p-custom p-0">
										<table class="mb-2">
											<tr>
												<td colspan="4" class="no-border p-custom px-4 pt-2 pb-0 _600">
													{{ $t("Forecast.ingests") }}
													<a class="btn-subtle ml-2" @click="openAddIngestModal(row.date)">
														<i
															class="fas fa-plus"
															v-tippy
															:title="$t('Forecast.add_ingest_prediction')"
														/>
													</a>
												</td>
											</tr>
											<tr v-if="!(row.ingests && row.ingests.length > 0)">
												<td
													colspan="4"
													class="text-very-muted no-border p-custom pl-5 pr-4 pt-2 pb-0"
												>
													{{ $t("Forecast.none_parentheses") }}
												</td>
											</tr>
											<tr v-for="(ingest, i) in row.ingests" :key="`i-${i}`">
												<td class="no-border p-custom pl-5 pr-4 pt-2 pb-0">
													{{ sectionNames[ingest.section_id] }}
												</td>
												<td class="no-border p-custom px-4 pt-2 pb-0">
													{{ itemNames[ingest.item_id] }}
												</td>
												<td class="no-border p-custom px-4 pt-2 pb-0">
													{{ $t("Forecast.add") }} <strong>{{ ingest.count }}</strong>
												</td>
												<td class="no-border p-custom px-4 pt-2 pb-0">
													<a class="btn-subtle" @click="removeIngest(ingest.id)">
														<i
															class="fas fa-trash"
															v-tippy
															title="Remove this prediction"
														/>
													</a>
												</td>
											</tr>
											<tr>
												<td colspan="4" class="no-border p-custom px-4 pt-2 pb-0 _600">
													{{ $t("Forecast.work") }}
												</td>
											</tr>
											<tr v-if="!(row.steps && row.steps.length > 0)">
												<td
													colspan="4"
													class="text-very-muted no-border p-custom pl-5 pr-4 pt-2 pb-0"
												>
													{{ $t("Forecast.none_parentheses") }}
												</td>
											</tr>
											<tr v-for="(step, i) in row.steps" :key="`w-${i}`">
												<td class="no-border p-custom pl-5 pr-4 pt-2 pb-0">
													{{ sectionNames[step.section_id] }}
												</td>
												<td class="no-border p-custom px-4 pt-2 pb-0">
													{{ itemNames[step.item_id] }}
												</td>
												<td class="no-border p-custom px-4 pt-2 pb-0">
													{{ $t("Forecast.complete") }}
													<strong>{{ step.completed }}</strong>
												</td>
												<td class="no-border p-custom px-4 pt-2 pb-0">
													{{ $t("Forecast.ingested_date", { date: step.ingest_date }) }}
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</template>
							<tr>
								<td colspan="7">
									<div class="text-center p-2">
										<a @click="loadMore()" class="btn btn-sm white">{{
											$t("Forecast.show_7_more_days")
										}}</a>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<div v-if="burndownStats && burndownStats.length == 0" class="d-flex flex-row justify-content-center">
				<h3 class="text-muted">{{ $t("Forecast.no_previous_activity") }}</h3>
			</div>
		</div>

		<b-modal id="importIngestModal" size="lg" v-model="importIngestModal" @hide="cleanupIngestModal">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("import_ingest_predictions") }}</h5>
			</template>
			<div class="pt-4 pb-3 px-5 modal-scroll" style="margin: -1rem">
				<div class="form-group">
					<input id="ingest-import" class="d-none" type="file" @change="selectIngestImportFile" />
					<button class="btn hover-darken theme-accent btn-block" @click="click('ingest-import')">
						{{ $t("Forecast.select_file") }}
					</button>
				</div>
				<div class="form-group">
					<p>{{ $t("Forecast.ingest_reminder_header") }}</p>
					<ul style="list-style-type: circle" class="mb-0">
						<li style="list-style-type: circle">{{ $t("Forecast.ingest_reminder_1") }}</li>
						<li style="list-style-type: circle">
							{{ $t("Forecast.ingest_reminder_2") }}
						</li>
						<li style="list-style-type: circle">{{ $t("Forecast.ingest_reminder_3") }}</li>
					</ul>
					<table class="my-2 mx-auto">
						<tr>
							<td class="py-2 px-4 border">{{ $t("Forecast.year") }}</td>
							<td class="py-2 px-4 border">{{ $t("Forecast.month") }}</td>
							<td class="py-2 px-4 border">{{ $t("Forecast.day") }}</td>
							<td class="py-2 px-4 border">{{ $t("Forecast.section_ref_id") }}</td>
							<td class="py-2 px-4 border">{{ $t("Forecast.item_ref_id") }}</td>
							<td class="py-2 px-4 border">{{ $t("Forecast.response_count") }}</td>
						</tr>
						<tr>
							<td colspan="9" style="border: 0px solid transparent">
								<a
									class="float-right text-theme actual-link"
									href="/static/templates/OSCAR Template - Ingest Predictions.csv"
									download
									>{{ $t("Forecast.download_template") }}</a
								>
							</td>
						</tr>
					</table>

					<ul style="list-style-type: circle">
						<li style="list-style-type: circle">{{ $t("Forecast.ingest_overwrite_warning") }}</li>
					</ul>
				</div>
			</div>
			<template slot="modal-footer">
				<button @click="importIngestModal = false" class="btn btn-flat btn-danger">
					{{ $t("Forecast.cancel") }}
				</button>
				<button class="btn btn-flat btn-primary">{{ $t("Forecast.import") }}</button>
			</template>
		</b-modal>

		<b-modal id="addIngestModal" v-model="addIngestModal">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Forecast.add_ingest_prediction_title") }}</h5>
			</template>
			<div class="pt-4 pb-3 px-5 modal-scroll" style="margin: -1rem">
				<div class="row" v-if="addIngestRow">
					<div class="col-12 form-group">
						<label>{{ $t("Forecast.date") }}</label>
						<date-picker v-model="addIngestRow.date" :config="dateConfig" disabled></date-picker>
					</div>
					<div class="col-12 form-group">
						<label>{{ $t("Forecast.section") }}</label>
						<config-select :options="addIngestSections" v-model="addIngestRow.section"></config-select>
					</div>
					<div class="col-12 form-group">
						<label>{{ $t("Forecast.item") }}</label>
						<config-select :options="addIngestItems" v-model="addIngestRow.item"></config-select>
					</div>
					<div class="col-12 form-group">
						<label>{{ $t("Forecast.count") }}</label>
						<input
							type="number"
							class="form-control"
							:placeholder="$t('Forecast.count')"
							v-model.number="addIngestRow.count"
						/>
					</div>
				</div>
			</div>
			<template slot="modal-footer">
				<button @click="addIngestModal = false" class="btn btn-flat btn-danger">
					{{ $t("Forecast.cancel") }}
				</button>
				<button
					@click="saveIngestRow"
					class="btn btn-flat btn-primary"
					:disabled="!canAddIngest"
					:class="{ [canAddIngest ? 'btn-primary' : 'btn-secondary']: true }"
				>
					{{ $t("Forecast.save") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="adjustRatesModal" size="lg" v-model="adjustRatesModal">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Forecast.adjust_completion_rates_title") }}</h5>
			</template>
			<div class="pt-4 pb-0 modal-scroll" style="margin: -1rem">
				<table v-if="burndownStats" class="table w-100 mb-0">
					<tr>
						<th class="pl-5">{{ $t("Forecast.section") }}</th>
						<th>{{ $t("Forecast.item") }}</th>
						<th>{{ $t("Forecast.status") }}</th>
						<th>{{ $t("Forecast.avg_seconds_per_completion") }}</th>
					</tr>
					<tr v-for="(row, i) in burndownStats" :key="i" :class="{ 'text-muted': !row.used }">
						<td class="pl-5 v-mid">{{ sectionNames[row.section_id] }}</td>
						<td class="v-mid">{{ itemNames[row.item_id] }}</td>
						<td class="v-mid">
							<template v-if="row.used">
								<span
									v-if="row.time_per_completion && !row.u_time_per_competion"
									class="_600 text-success"
								>
									{{ $t("Forecast.calculated") }}
								</span>
								<span
									v-if="row.time_per_completion && row.u_time_per_competion"
									class="_600 text-warning"
								>
									{{ $t("Forecast.user_override") }}
								</span>
								<span
									v-if="!row.time_per_completion && !row.u_time_per_competion"
									class="_600 text-danger"
								>
									{{ $t("Forecast.missing") }}
								</span>
								<span
									v-if="!row.time_per_completion && row.u_time_per_competion"
									class="_600 text-success"
								>
									{{ $t("Forecast.user_defined") }}
								</span>
							</template>
							<span v-if="!row.used">
								{{ $t("Forecast.not_used") }}
							</span>
						</td>
						<td>
							<input
								type="number"
								class="form-control"
								:placeholder="row.time_per_completion"
								v-model.number="row.u_time_per_completion"
							/>
						</td>
					</tr>
				</table>
			</div>
			<template slot="modal-footer">
				<button @click="adjustRatesModal = false" class="btn btn-flat btn-danger">
					{{ $t("Forecast.cancel") }}
				</button>
				<button @click="saveRates" class="btn btn-flat btn-primary">
					{{ $t("Forecast.save") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="saveGoalsModal" size="lg" v-model="saveGoalsModal">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">Save Schedule Goal Changes</h5>
			</template>
			<div class="pt-4 pb-0 px-4 modal-scroll">
				<table
					v-if="configsToSave && configsToSave.length > 0 && selectedProject.use_shifts"
					class="table w-100 b-b b-l b-r"
				>
					<tr>
						<th>{{ $t("Forecast.date") }}</th>
						<th>{{ $t("Forecast.shift") }}</th>
						<th>{{ $t("Forecast.old") }}</th>
						<th></th>
						<th>{{ $t("Forecast.new") }}</th>
					</tr>
					<template v-for="row in configsToSave">
						<tr v-for="(shift, j) in getDayShifts(selectedProject, row.date)" :key="j">
							<td class="v-mid">{{ moment(row.date, "MM/DD/YYYY").format("ddd, ll") }}</td>

							<td class="v-mid">
								<div>
									<span>{{ shift.start }}</span> to
									<span>{{ shift.end }}</span>
								</div>
							</td>
							<td class="v-mid">
								<span v-if="row.old_shift_threshold">{{
									fs.fixed1d(row.old_shift_thresholds[shift.id] * getShiftLength(shift))
								}}</span>
								<span v-else class="text-very-muted">(?)</span>
								<span class="text-muted">{{ $t("Forecast.hours") }}</span>
							</td>
							<td class="v-mid">
								<i class="fas fa-arrow-alt-right" />
							</td>
							<td class="v-mid">
								<span v-if="row.shift_threshold">{{
									fs.fixed1d(row.shift_thresholds[shift.id] * getShiftLength(shift))
								}}</span>
								<span v-else class="text-very-muted">(?)</span>
								<span class="text-muted">{{ $t("Forecast.hours") }}</span>
							</td>
						</tr>
					</template>
				</table>

				<table
					v-if="configsToSave && configsToSave.length > 0 && !selectedProject.use_shifts"
					class="table w-100 b-b b-l b-r"
				>
					<tr>
						<th>{{ $t("Forecast.date") }}</th>
						<th>{{ $t("Forecast.old") }}</th>
						<th></th>
						<th>{{ $t("Forecast.new") }}</th>
					</tr>
					<tr v-for="(row, i) in configsToSave" :key="i">
						<td class="v-mid">{{ moment(row.date, "MM/DD/YYYY").format("ddd, ll") }}</td>

						<td v-if="!row.old_threshold_type" class="v-mid text-very-muted">
							{{ $t("Forecast.none_parentheses") }}
						</td>
						<td v-if="row.old_threshold_type == 1" class="v-mid text-very-muted">
							{{ $t("Forecast.n_users", { n: row.old_threshold }) }} users
						</td>
						<td v-if="row.old_threshold_type == 2" class="v-mid text-very-muted">
							{{ row.old_threshold }}
						</td>
						<td v-if="row.old_threshold_type == 3" class="v-mid">
							<span class="_600">{{ row.old_threshold }}</span>
							<span class="text-muted">{{ $t("Forecast.hours_suffix") }}</span>
						</td>

						<td class="v-mid">
							<i class="fas fa-arrow-alt-right" />
						</td>
						<td v-if="row.old_shift_thresholds">
							{{ sumShifts(row.shift_thresholds) }}
						</td>
						<td v-else>
							<span class="_600">{{ row.threshold }}</span>
							<span class="text-muted">{{ $t("Forecast.hours_suffix") }}</span>
						</td>
					</tr>
				</table>

				<div class="mt-4 mb-2 text-md text-right">
					{{ $t("Forecast.save_goals_confirmation") }}
				</div>
			</div>
			<template slot="modal-footer">
				<button @click="saveGoalsModal = false" class="btn btn-flat btn-secondary">
					{{ $t("Forecast.cancel") }}
				</button>
				<button v-if="!savingGoals" @click="saveGoals" class="btn btn-flat btn-primary">
					{{ $t("Forecast.save") }}
				</button>
				<button v-if="savingGoals" disabled class="btn btn-flat btn-primary">
					{{ $t("Forecast.saving") }} <loading type="icon" />
				</button>
			</template>
		</b-modal>

		<b-modal id="expirationModal" size="lg" v-model="expirationModal">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Forecast.prevent_expirations") }}</h5>
			</template>
			<div class="p-4 modal-scroll" style="margin: -1rem">
				<h1 v-if="expirationRow" class="text-danger">
					{{ $t("Forecast.n_expired_responses", { n: expirationRow.expiredCount }) }}
				</h1>
			</div>
			<template slot="modal-footer">
				<button @click="expirationModal = false" class="btn btn-flat btn-danger">
					{{ $t("Forecast.cancel") }}
				</button>
				<button class="btn btn-flat btn-primary">
					{{ $t("Forecast.solve") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-long {
	display: none;
}
.d-abr {
	display: table-cell;
}
@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}
	.d-abr {
		display: none;
	}
}
.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}
.t-margin {
	width: 15px;
}
.table td,
.table th {
	white-space: nowrap;
}
h5,
h6 {
	margin-bottom: 0;
}
a.btn-subtle i {
	opacity: 0.5;
	transition: opacity 0.25s;
}
a.btn-subtle:hover i {
	opacity: 1;
}

a.btn-subtle-around {
	opacity: 1;
	transition: background-color 0.25s;
	border-radius: 2rem;
	padding: 0.1rem 0.25rem;
}
a.btn-subtle-around:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.cog-red i {
	opacity: 1 !important;
	color: #ea2e49 !important;
}
.cog-yellow i {
	opacity: 1 !important;
	color: #fdab29 !important;
}

i[class*="ion-"],
i[class*=" ion-"] {
	font-size: inherit;
	vertical-align: 0px !important;
}

.clickable:hover {
	color: inherit;
	text-decoration-line: underline !important;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ScheduleService from "@/services/ScheduleService";
import ConfigService from "@/services/ConfigService";
import ThemeService from "@/services/ThemeService";
import FileService from "@/services/FileService";
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import moment from "moment";
import BB from "bluebird";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import DatePicker from "vue-bootstrap-datetimepicker";
import $ from "jquery";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigDate from "@/components/ConfigDate";
import ReportTable from "@/components/ReportTable";

export default {
	name: "ProjectForecast",

	props: ["user"],

	components: { ConfigDate, ConfigSelect, ReportTable, DatePicker },

	data() {
		return {
			projectionNum: 14,

			fs: fs,
			moment: moment,
			burndownAvg: null,
			burndownStats: null,
			outstanding: null,
			scheduleDays: null,
			scheduleConfigs: null,
			projectionResults: null,
			ingestRows: null,
			importIngestModal: false,
			missingPredictionLevel: 0,
			useGoalTimes: store.getDefault(this, "forecast.useGoalTimes", false),
			changedConfigs: {},
			numChangedConfigs: null,

			addIngestModal: false,
			addIngestRow: null,
			addIngestSections: null,
			addIngestItems: null,

			adjustRatesModal: false,

			configsToSave: null,
			saveGoalsModal: false,
			savingGoals: false,

			expirationModal: false,
			expirationRow: null,

			sectionNames: {},
			itemNames: {},

			prediction: {
				comp_per_scorer: 0,
				scorers: 0,
				comp_per_day: 0,
				scores_per_response: 0,
				scores: 0,
				finishDate: null,
				finishComp: 0,
				scoreHours: 0,
				responses: 0,
				min_responses: 0,
				adjustment: 0,
			},

			dateConfig: {
				format: "L",
				viewMode: "days",
				icons: {
					time: "fa fa-clock-o",
					date: "fa fa-calendar",
					up: "fa fa-chevron-up",
					down: "fa fa-chevron-down",
					previous: "fa fa-chevron-left",
					next: "fa fa-chevron-right",
					today: "fa fa-sun-o",
					clear: "fa fa-trash",
					close: "fa fa-remove",
				},
			},

			fields: [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
				{
					key: "date",
					label: "Day",
					tdClass: "text-center table-border-right",
					thClass: "text-center table-border-right",
				},
				{
					key: "scores",
					label: "Scores",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "time_per_score",
					label: "Time/Score",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "complete",
					label: "Completed",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "scorers",
					label: "Scorers",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "complete_per_scorer",
					label: "Completed/Scorer",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "remaining",
					label: "Remaining",
					tdClass: "text-center",
					thClass: "text-center",
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
					thClass: "text-center",
				},
			],

			projects: [],
			selectedProject: null,

			sections: null,
			selectedSection: null,

			items: [],
			selectedItem: null,

			fromDate: null,
			toDate: null,

			running: false,
			loading: true,

			chartData: {
				type: "line",
				data: {
					labels: [],
					datasets: [
						{
							label: "History",
							data: [],
							fill: true,
							lineTension: 0.4,
							// backgroundColor: hexToRGB(app.color.primary, 0.2),
							// borderColor: app.color.primary,
							borderWidth: 2,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							// pointBorderColor: app.color.primary,
							pointBackgroundColor: "#fff",
							pointBorderWidth: 2,
							pointHoverRadius: 4,
							// pointHoverBackgroundColor: app.color.primary,
							pointHoverBorderColor: "#fff",
							pointHoverBorderWidth: 2,
							pointRadius: 4,
							pointHitRadius: 10,
							spanGaps: false,
						},
						{
							label: "Projected",
							data: [],
							fill: false,
							lineTension: 0.4,
							// backgroundColor: hexToRGB(app.color.primary, 0.2),
							borderColor: ThemeService.getThemeColorHex(),
							borderWidth: 2,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 1.0,
							borderJoinStyle: "miter",
							// pointBorderColor: app.color.primary,
							pointBackgroundColor: "#fff",
							pointBorderWidth: 2,
							pointHoverRadius: 4,
							// pointHoverBackgroundColor: app.color.primary,
							pointHoverBorderColor: "#fff",
							pointHoverBorderWidth: 2,
							pointRadius: 4,
							pointHitRadius: 10,
							spanGaps: false,
						},
					],
				},
				options: {
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								id: "sp",
								scaleLabel: {
									display: "true",
									labelString: "Day",
								},
							},
						],
						yAxes: [
							{
								id: "freq",
								scaleLabel: {
									display: "true",
									labelString: "Remaining Responses",
								},
								ticks: {
									suggestedMin: 0,
									suggestedMax: 25,
								},
							},
						],
					},
				},
			},
		};
	},

	created() {
		if (store.get(this, "burndown.auto")) {
			this.running = true;
		}
		ConfigService.listProjectsShallow()
			.then((resp) => {
				this.projects = resp.data;
				this.selectedProject = store.resolveFromList(this, this.projects, "id", 0, "forecast.selectedProject");

				this.loading = false;
			})
			.catch((err) => {
				console.log(err);
				notie.error("Failed to load projects", err);
				this.loading = false;
			});
	},

	watch: {
		selectedProject() {
			this.sections = null;
			if (!this.selectedProject) return [];

			store.set(this, "forecast.selectedProject", this.selectedProject ? this.selectedProject.id : null);
			console.log("SET forecast.selectedProject", this.selectedProject ? this.selectedProject.id : null);

			ConfigService.listProjectSectionsWithItems(this.selectedProject.id)
				.then((r) => {
					this.sections = r.data;
				})
				.catch((e) => {
					console.error(e);
					notie.error("Failed to load sections", e);
				});
		},

		addIngestRow() {
			console.log("CHANGE ROW", this.addIngestRow.section && this.addIngestRow.section.items);
			if (this.addIngestRow.section && this.addIngestRow.section.items) {
				this.addIngestItems = this.addIngestRow.section.items;
			} else {
				this.addIngestItems = [];
			}
		},

		"addIngestRow.section"() {
			console.log("CHANGE SECTION", this.addIngestRow.section && this.addIngestRow.section.items);
			if (this.addIngestRow.section && this.addIngestRow.section.items) {
				this.addIngestItems = this.addIngestRow.section.items;
			} else {
				this.addIngestItems = [];
			}
		},

		"addIngestRow.item"() {
			if (this.addIngestRow.item) {
				let existingPrediction = _.find(this.ingestRows, {
					date: this.addIngestRow.date,
					section_id: this.addIngestRow.section.id,
					item_id: this.addIngestRow.item.id,
				});
				if (existingPrediction) {
					this.$set(this.addIngestRow, "count", existingPrediction.count);
				} else {
					this.addIngestRow.count = null;
				}
			} else {
				this.addIngestRow.count = null;
			}
		},

		useGoalTimes() {
			if (this.projectionResults) {
				this.processData();
			}
			store.set(this, "forecast.useGoalTimes", this.useGoalTimes);
		},
	},

	computed: {
		canAddIngest() {
			return (
				this.addIngestRow &&
				this.addIngestRow.section &&
				this.addIngestRow.item &&
				Number.isInteger(this.addIngestRow.count) &&
				this.addIngestRow.count >= 0
			);
		},

		anyChangedConfigs() {
			let count = 0;
			_.each(this.changedConfigs, () => {
				count++;
			});
			return count > 0;
		},
	},

	methods: {
		getStats() {
			if (this.running) {
				return;
			}
			var proj = this.selectedProject;
			if (proj.use_shifts) {
				proj.shiftMap = {};
				_.each(proj.shifts, (shift) => {
					proj.shiftMap[shift.id] = shift;
				});
			}
			let ctxs = [];
			_.each(this.sections, (section) => {
				_.each(section.items, (item) => {
					ctxs.push({
						section_id: section.id,
						section_name: section.name,
						item_id: item.id,
						item_name: item.name,
						now: moment().toISOString(true),
					});

					this.sectionNames[section.id] = section.name;
					this.itemNames[item.id] = item.name;
				});
			});

			this.running = true;
			this.burndownStats = [];
			this.outstanding = [];
			let statCall = BB.map(ctxs, (ctx) => {
				return BB.props({
					stats: ReportingService.getBurndown(ctx),
					outstanding: ReportingService.getOutstandingByDay(ctx),
				}).then((resps) => {
					let burndown = resps.stats.data;
					burndown.section_id = ctx.section_id;
					burndown.item_id = ctx.item_id;
					this.burndownStats.push(burndown);

					let outstanding = { days: resps.outstanding.data };
					outstanding.section_id = ctx.section_id;
					outstanding.item_id = ctx.item_id;
					this.outstanding.push(outstanding);
				});
			}).then((r) => {
				this.running = false;
			});

			let scheduleCall = ScheduleService.getScheduleDays(proj.id).then((resp) => {
				this.scheduleDays = resp.data;
			});

			let scheduleConfigCall = ScheduleService.getScheduleConfigs(proj.id).then((resp) => {
				this.scheduleConfigs = resp.data;
			});

			let ingestCall = this.loadIngests();

			BB.all([statCall, scheduleCall, scheduleConfigCall, ingestCall]).then(() => {
				//Now we know all relevant data is loaded
				this.processData();
			});
		},

		processData() {
			//1. Calculate expected completion rate for each section/item
			let sectionItems = {};
			_.each(this.burndownStats, (stat) => {
				stat.u_time_per_completion = stat.u_time_per_completion || null;
				stat.time_per_completion = stat.time_per_completion || null;

				let slug = `${stat.section_id}-${stat.item_id}`;
				sectionItems[slug] = {
					scores: 0,
					score_time: 0,
					complete: 0,
					u_time_per_completion: stat.u_time_per_completion,
				};

				_.each(stat.days, (statDay) => {
					sectionItems[slug].scores += statDay.scores;
					sectionItems[slug].score_time += statDay.score_time;
					sectionItems[slug].complete += statDay.complete;
				});
				if (sectionItems[slug].complete > 0) {
					stat.time_per_completion = sectionItems[slug].score_time / sectionItems[slug].complete;
					sectionItems[slug].time_per_completion = stat.time_per_completion;
				}
			});

			//2.a. Partition outstanding response counts into days, further into section/item buckets
			let days = {};
			_.each(this.outstanding, (outStat) => {
				_.each(outStat.days, (outDay) => {
					let date = this.formatStructDate(outDay.date);
					if (!days[date]) days[date] = {};
					let slug = `${outStat.section_id}-${outStat.item_id}`;
					days[date][slug] = outDay.count;
				});
			});
			console.log("days", days);
			//2.b. Look into the future and add any predicted ingests
			let ingestDays = {};
			_.each(this.ingestRows, (ingest) => {
				let now = moment();
				let time = moment(ingest.date, "MM/DD/YYYY");

				let slug = `${ingest.section_id}-${ingest.item_id}`;
				let prediction = ingest.count;
				let existing = days[ingest.date] && days[ingest.date][slug];
				if (existing) {
					//If there are existing responses that were ingested on the day of this prediction,
					//assume those responses are part of the prediction and subtract them from the prediction
					//this *should* only happen on the current day, but it's written to apply to generic cases just in case
					prediction = Math.max(0, prediction - existing);
				}
				days[ingest.date] = days[ingest.date] || {};
				days[ingest.date][slug] = prediction;

				ingestDays[ingest.date] = ingestDays[ingest.date] || [];
				ingestDays[ingest.date].push({
					id: ingest.id,
					section_id: ingest.section_id,
					item_id: ingest.item_id,
					count: ingest.count,
				});
			});
			//Sort days by date
			let sortedDays = [];
			_.each(days, (counts, date) => {
				sortedDays.push({
					timestamp: moment(date, "MM/DD/YYYY").unix(),
					date: date,
					counts: counts,
				});
			});
			sortedDays = _.sortBy(sortedDays, "timestamp"); //TODO: make this sorting actually work

			//3. Partition schedules into days
			let scheduleMap = {};
			_.each(this.scheduleDays, (scheduleDay) => {
				scheduleMap[scheduleDay.date] = scheduleMap[scheduleDay.date] || [];
				scheduleMap[scheduleDay.date].push(scheduleDay);
			});
			//And configs also, so we can get goals
			let scheduleConfigMap = {};
			_.each(this.scheduleConfigs, (scheduleConfig) => {
				scheduleConfigMap[scheduleConfig.date] = scheduleConfig;
			});

			//4. Determine projection length and iterate through projection

			//If any rows have details open, save that so we can keep them open
			let openRows = {};
			_.each(this.projectionResults, (row) => {
				openRows[row.date] = row.showDetails;
			});

			this.missingPredictionLevel = 0;
			let projectionDay = moment();
			this.projectionResults = [];
			projectionDay.add(-1, "days");
			for (let i = 1; i <= this.projectionNum; i++) {
				projectionDay.add(1, "days");
				let date = projectionDay.format("MM/DD/YYYY");

				let resultRow = {
					ingests: ingestDays[date] || [],
					steps: [],
					showDetails: openRows[date],
				};

				let addedCount = 0;
				_.each(resultRow.ingests, (ingest) => {
					addedCount += ingest.count;
				});

				//4.a. Use schedule to determine how much work time is available
				let availableWorkMinutes = 0;
				if (this.useGoalTimes) {
					let u_availableWorkMinutes = this.getScheduleWorkMinutes(
						this.selectedProject,
						scheduleMap[date],
						i == 1
					);
					resultRow.u_availableWorkMinutes = u_availableWorkMinutes;
					let g_availableWorkMinutes = this.getGoalWorkMinutes(this.selectedProject, scheduleConfigMap[date]);
					resultRow.g_availableWorkMinutes = g_availableWorkMinutes;

					availableWorkMinutes = Math.max(u_availableWorkMinutes, g_availableWorkMinutes);
				} else {
					availableWorkMinutes = this.getScheduleWorkMinutes(this.selectedProject, scheduleMap[date], i == 1);
				}
				resultRow.availableWorkMinutes = availableWorkMinutes;

				//4.b. Iterate through outstanding responses by day they were ingested
				let completedCount = 0;
				_.each(sortedDays, (outStat) => {
					if (outStat.counts == false || availableWorkMinutes <= 0) {
						//Either the day was finished in a previous iteration, or there's no work time left
						return;
					}

					let ingestDay = moment(outStat.date, "MM/DD/YYYY");
					if (ingestDay.isAfter(projectionDay)) {
						//Don't complete any work that is predicted for future days
						return;
					}

					let requiredWorkSeconds = 0;
					_.each(outStat.counts, (count, slug) => {
						//4.b.2. Use estimated completion times by section/item to calculate work time to complete the outstanding responses
						sectionItems[slug].used = true;
						let prediction = sectionItems[slug];
						let ftpc = 0;
						let utpc = prediction.u_time_per_completion;
						let ctpc = prediction.time_per_completion;

						if (!utpc && !ctpc) {
							//If there is no prediction at all for this section/item, leave ftpc at 0 and set missing level to maximum
							this.missingPredictionLevel = 2;
						} else if (utpc && !ctpc) {
							//If there's a user defined prediction but no past history, set the user prediction
							ftpc = utpc;
						} else if (utpc && ctpc) {
							//If there's both a user prediction and a calculated prediction, set the user prediction but set missing level to medium
							this.missingPredictionLevel = Math.max(this.missingPredictionLevel, 1); // (increase from 0 to 1, but don't decrease from 2 to 1)
							ftpc = utpc;
						} else {
							//If there's a calculated prediction and no user defined prediction, set the calculated prediction
							ftpc = ctpc;
						}

						requiredWorkSeconds += count * ftpc;
					});
					let requiredWorkMinutes = requiredWorkSeconds / 60;

					//4.b.3. Reduce response counts based on available work time
					//(complete entirely if available time > required time)
					let allZero = true;
					let ratio = availableWorkMinutes / requiredWorkMinutes;
					ratio = ratio > 1 ? 1 : ratio;

					for (let slug in outStat.counts) {
						let toComplete = Math.round(outStat.counts[slug] * ratio);
						completedCount += toComplete;

						let slugParts = slug.split("-");
						resultRow.steps.push({
							section_id: slugParts[0],
							item_id: slugParts[1],
							ingest_date: outStat.date,
							completed: toComplete,
						});

						outStat.counts[slug] -= toComplete;
						if (outStat.counts[slug] != 0) {
							allZero = false;
						}
					}

					//4.b.4. Reduce available work time by amount of work time "used"
					//Remember we're iterating through day of *ingested* response, not days of work time
					//In the next iteration of this loop, the work day will be the same, we'll just be
					///completing responses that were ingested on more recent days
					//Available work time doesn't get reset until we move to a new *work* day
					availableWorkMinutes -= Math.min(requiredWorkMinutes, availableWorkMinutes);

					if (allZero) {
						//4.b.5. If the partial count reductions completed the day, mark it entirely complete
						//This allows further iterations to quickly skip this day without re-processing
						outStat.counts = false;
					}
				});

				//4.c. Count total outstanding responses and copy results of this day's work into output object
				let outstandingCount = 0;
				let expiredCount = 0;
				for (let outStat of sortedDays) {
					let ingestDay = moment(outStat.date, "MM/DD/YYYY");
					if (ingestDay.isAfter(projectionDay)) {
						//Don't include any predicted counts from future days
						continue;
					}

					let dayCount = 0;
					if (outStat.counts == false) {
						//This day was completed, don't add anything to final count
					} else {
						_.each(outStat.counts, (count) => {
							dayCount += count;
						});
					}

					let expiryDays = this.selectedProject.turnaround; //TODO: Make this configurable, somehow
					if (dayCount > 0) {
						if (projectionDay.diff(ingestDay, "days") >= expiryDays) {
							expiredCount += dayCount;
						}
					}

					outstandingCount += dayCount;
				}

				resultRow.date = date;
				resultRow.outstandingCount = outstandingCount;
				resultRow.expiredCount = expiredCount;
				resultRow.completedCount = completedCount;
				resultRow.addedCount = addedCount;
				resultRow.showGoalEdit = false;
				resultRow.newGoalTime = Math.round((resultRow.g_availableWorkMinutes / 60) * 10) / 10 || 0;
				resultRow.goalTimeTooltip = this.goalTimeTooltip(resultRow);
				resultRow.noteType = "";
				if (resultRow.expiredCount > 0) {
					resultRow.noteType = "expired";
				}

				if (resultRow.outstandingCount == 0 && resultRow.completedCount != 0) {
					resultRow.noteType = "finished";
				}

				if (this.selectedProject.use_shifts) {
					let config = scheduleConfigMap[date];
					let shiftThresholds = (config && config.shift_thresholds) || {};

					resultRow.shift_counts = {};
					resultRow.new_shift_thresholds = {};
					let schedules = scheduleMap[date];
					_.each(this.selectedProject.shifts, (shift) => {
						resultRow.new_shift_thresholds[shift.id] = shiftThresholds[shift.id] || 0;
						resultRow.shift_counts[shift.id] = 0;
						if (schedules) {
							_.each(schedules, (schedule) => {
								if (schedule.shift_ids.includes(shift.id)) {
									resultRow.shift_counts[shift.id]++;
								}
							});
						}
					});
				}

				this.projectionResults.push(resultRow);
			}

			//5. Save which section/items were used, for display in rate adjustment modal
			_.each(this.burndownStats, (stat) => {
				let slug = `${stat.section_id}-${stat.item_id}`;
				stat.used = sectionItems[slug].used;
			});
		},

		loadMore() {
			this.projectionNum += 7;
			this.processData();
		},

		formatStructDate(structDate) {
			let date = moment()
				.year(structDate.year)
				.month(structDate.month - 1)
				.date(structDate.day)
				.format("MM/DD/YYYY");
			return date;
		},

		getScheduleWorkMinutes(project, schedules, isToday) {
			let now = moment();
			let nowMinutes = Utils.minutesFromMidnight(now.format("LT"));
			let workMinutes = 0;
			_.each(schedules, (schedule) => {
				let usableMinutes = this.getScheduleMinutes(schedule, project, nowMinutes, isToday);
				workMinutes += usableMinutes;
			});
			return workMinutes;
		},

		getGoalWorkMinutes(project, scheduleConfig) {
			if (!scheduleConfig) {
				return 0;
			}
			if (project.use_shifts) {
				if (!scheduleConfig.shift_thresholds) {
					return 0;
				}
				let workMinutes = 0;
				_.each(scheduleConfig.shift_thresholds, (num, shiftID) => {
					let shift = project.shiftMap[shiftID];
					if (!shift) {
						return 0;
					}

					let startMinutes = Utils.minutesFromMidnight(shift.start);
					let endMinutes = Utils.minutesFromMidnight(shift.end);
					let duration = Math.max(endMinutes - startMinutes, 0); //If duration is less than 0, ignore it

					workMinutes += duration * num;
				});
				return workMinutes;
			} else {
				if (scheduleConfig.threshold_type != 3) {
					return 0;
				}

				return scheduleConfig.threshold * 60;
			}
		},

		getNewShiftWorkTime(row) {
			let project = this.selectedProject;
			let workMinutes = 0;
			_.each(row.new_shift_thresholds, (num, shiftID) => {
				let shift = project.shiftMap[shiftID];
				if (!shift) {
					return 0;
				}

				let startMinutes = Utils.minutesFromMidnight(shift.start);
				let endMinutes = Utils.minutesFromMidnight(shift.end);
				let duration = Math.max(endMinutes - startMinutes, 0); //If duration is less than 0, ignore it

				workMinutes += duration * num;
			});
			return workMinutes;
		},

		getShiftLength(shift) {
			let project = this.selectedProject;

			if (!shift) {
				return 0;
			}

			let startMinutes = Utils.minutesFromMidnight(shift.start);
			let endMinutes = Utils.minutesFromMidnight(shift.end);
			let duration = Math.max(endMinutes - startMinutes, 0); //If duration is less than 0, ignore it

			return duration / 60;
		},

		//Calculate the number of usable minutes in a schedule object
		//Uses free time if the project uses free time, or shifts if the project uses shifts
		//If schedule is for the current day (indicated by isToday),
		//minutes from before the current time (indicated by nowMinutes) are excluded
		getScheduleMinutes(schedule, project, nowMinutes, isToday) {
			if (project.use_shifts) {
				let availableMinutes = 0;
				_.each(schedule.shift_ids, (shiftID) => {
					let shift = project.shiftMap[shiftID];
					if (!shift) {
						return;
					}

					let startMinutes = Utils.minutesFromMidnight(shift.start);
					startMinutes = isToday ? Math.max(startMinutes, nowMinutes) : startMinutes;
					let endMinutes = Utils.minutesFromMidnight(shift.end);

					let duration = Math.max(endMinutes - startMinutes, 0); //If duration is less than 0, ignore it
					availableMinutes += duration;
				});
				return availableMinutes;
			} else {
				let startMinutes = Utils.minutesFromMidnight(schedule.start_time);
				startMinutes = isToday ? Math.max(startMinutes, nowMinutes) : startMinutes;
				let endMinutes = Utils.minutesFromMidnight(schedule.end_time);

				let duration = Math.max(endMinutes - startMinutes, 0); //If duration is less than 0, ignore it
				return duration;
			}
		},

		getShiftCounts(project, schedules) {
			let shiftCounts = {};
			_.each(schedules, (schedule) => {
				_.each(schedule.shift_ids, (sid) => {
					shiftCounts[sid] = shiftCounts[sid] || 0;
					shiftCounts[sid]++;
				});
			});
			return shiftCounts;
		},

		getDayShifts(project, date) {
			let weekday = moment(date, "MM/DD/YYYY").weekday();
			let shifts = _.filter(project.shifts, { weekday: weekday + 1 });
			return shifts;
		},

		isExpiredDate(dateStr, expiryDays) {
			if (expiryDays == 0) {
				return false;
			}

			let date = moment(dateStr, "MM/DD/YYYY");
			date.add(expiryDays, "days");
			let now = moment();

			return now.isAfter(date);
		},

		fixed(time) {
			if (time == Number.POSITIVE_INFINITY) {
				return "——";
			} else {
				return time.toFixed(2);
			}
		},

		getTime(time) {
			if (!time) {
				return "——";
			} else if (time < 3600) {
				return moment().startOf("day").seconds(time).format("mm:ss");
			} else {
				return moment().startOf("day").seconds(time).format("HH:mm:ss");
			}
		},

		getTimeMillis(duration) {
			//duration+=86400000
			var milliseconds = parseInt((duration % 1000) / 100),
				seconds = parseInt((duration / 1000) % 60),
				minutes = parseInt((duration / (1000 * 60)) % 60),
				hours = parseInt(duration / (1000 * 60 * 60));

			if (isNaN(seconds)) {
				seconds = 0;
			}
			if (isNaN(minutes)) {
				minutes = 0;
			}
			if (isNaN(hours)) {
				hours = 0;
			}

			hours = hours < 10 ? "0" + hours : hours;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			return hours + ":" + minutes + ":" + seconds;
		},

		getTimeSeconds(duration) {
			if (duration == 0) {
				return "——";
			} else if (!duration) {
				return "——";
			} else {
				//duration+=86400000
				var milliseconds = parseInt((duration % 1000) / 100),
					seconds = parseInt(duration % 60),
					minutes = parseInt((duration / 60) % 60),
					hours = parseInt(duration / (60 * 60));

				hours = hours < 10 ? "0" + hours : hours;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;

				return hours + ":" + minutes + ":" + seconds;
			}
		},

		getDate(date) {
			if (!(date && date.year && date.month && date.day)) {
				return "——";
			} else {
				date = moment()
					.year(date.year)
					.month(date.month - 1)
					.date(date.day)
					.format("MMM D");
				if (date) {
					return date;
				}
			}
		},

		pcr(num, den) {
			if (den == 0) {
				return "-";
			}
			if (num == 0) {
				return "0.00";
			}

			var res = (num / den) * 100;
			return res.toFixed(2);
		},

		clearChart() {
			this.chartData.data.labels = [];
			this.chartData.data.datasets[0].data = [];
			this.chartData.data.datasets[1].data = [];
		},

		addChartStat(label, actual, projected) {
			this.chartData.data.labels.push(label);
			this.chartData.data.datasets[0].data.push(actual);
			this.chartData.data.datasets[1].data.push(projected);
		},

		isToday(date) {
			let today = moment();
			return date.year == today.year() && date.month - 1 == today.month() && date.day == today.date();
		},

		loadIngests() {
			return ReportingService.getIngestPredictions(this.selectedProject.id)
				.then((resp) => {
					this.ingestRows = resp.data;
				})
				.catch((e) => {
					console.log(e);
					notie.error("Failed to get ingest predictions", e);
				});
		},

		openImportIngestModal() {
			this.ingestRows = [];
			// this.loadIngests().then(() => {
			// 	_.each(this.ingestRows, row => {
			// 		row.section = _.find(this.selectedProject.sections, { id: row.section_id });
			// 		if (row.section) {
			// 			row.item = _.find(row.section.items, { id: row.item_id });
			// 		}
			// 	});
			// })
			this.importIngestModal = true;
		},

		openAddIngestModal(date) {
			console.log("openAddIngestModal");
			this.addIngestRow = {
				date: date,
				item: null,
				section: null,
				count: null,
			};
			this.addIngestSections = this.sections;
			this.addIngestModal = true;

			if (this.addIngestSections.length > 0) {
				this.addIngestRow.section = this.addIngestSections[0];
				this.addIngestItems = this.addIngestRow.section.items;
			}
		},

		saveIngestRow() {
			let ingestRow = {
				date: this.addIngestRow.date,
				section_id: this.addIngestRow.section.id,
				item_id: this.addIngestRow.item.id,
				count: this.addIngestRow.count,
			};
			this.saveIngest([ingestRow])
				.then(() => {
					notie.info(this.$i18n.t("Forecast.save_prediction"));
					this.addIngestModal = false;
				})
				.catch((e) => {
					console.log(e);
					notie.error(this.$i18n.t("Forecast.errors.save_prediction"), e);
				});
		},

		saveIngest(ingest) {
			_.each(ingest, (row) => {
				row.project_id = this.selectedProject.id;
			});
			return ReportingService.saveIngestPredictions(ingest).then(() => {
				if (this.projectionResults && this.projectionResults.length > 0) {
					this.loadIngests().then(() => {
						this.processData();
					});
				}
			});
		},

		removeIngest(predictionID) {
			return ReportingService.removeIngestPrediction(predictionID)
				.then((resp) => {
					notie.info(this.$i18n.t("Forecast.delete_prediction"));
					this.loadIngests().then((r) => {
						this.processData();
					});
				})
				.catch((e) => {
					console.log(e);
					notie.error(this.$i18n.t("Forecast.errors.delete_prediction"), e);
				});
		},

		selectIngestImportFile(event) {
			let file = event.target.files[0];

			if (file.type != "text/csv" && file.type != "application/vnd.ms-excel") {
				this.importingFile = null;
				notie.error("File must be CSV!");
				return;
			}

			this.importing = true;

			var reader = new FileReader();
			reader.onload = (e) => {
				let ingestCSV = FileService.CSVToArray(e.target.result);
				let ingestRows = this.processIngestCSV(ingestCSV);

				this.saveIngest(ingestRows)
					.then((resp) => {
						this.importing = false;
						this.importIngestModal = false;
						notie.success(this.$i18n.t("Forecast.n_predictions_saved", { n: ingestRows.length }));
					})
					.catch((e) => {
						console.log(e);
						notie.error(this.$i18n.t("Forecast.errors.import_predictions"), e);
					});
			};

			reader.readAsText(file);
		},

		processIngestCSV(ingestCSV) {
			let messages = [];
			if (ingestCSV.length == 0) {
				messages.push({
					message: "Could not parse input CSV",
					result: "",
					type: "error",
				});
			}

			let predictions = [];
			let anyErrors = false;
			let numProcessed = 0;
			for (var i = 1; i < ingestCSV.length; i++) {
				let csvRow = ingestCSV[i];
				let error = false;
				let prediction = {};
				if (csvRow.length >= 6) {
					numProcessed++;
					prediction.line = i;

					let year = parseInt(csvRow[0]);
					let month = parseInt(csvRow[1]);
					let day = parseInt(csvRow[2]);
					let date = moment()
						.year(year)
						.month(month - 1)
						.date(day);
					prediction.date = date.format("MM/DD/YYYY");

					let sectionRef = csvRow[3];
					let section = _.find(this.sections, { ref_id: sectionRef });
					if (!section) {
						messages.push({
							message: `Line ${i}: No matching section found`,
							result: `Prediction not processed`,
							type: "error",
						});
						error = true;
						anyErrors = true;
						continue;
					}
					prediction.section_id = section.id;

					let itemRef = csvRow[4];
					let item = _.find(section.items, { ref_id: itemRef });
					if (!item) {
						messages.push({
							message: `Line ${i}: No matching item found`,
							result: `Prediction not processed`,
							type: "error",
						});
						error = true;
						anyErrors = true;
						continue;
					}
					prediction.item_id = item.id;

					prediction.count = parseInt(csvRow[5]);
				} else if (csvRow.length == 1 && csvRow[0] == "") {
					//Don't even display an error if there's only one empty column
					error = true;
				} else {
					numProcessed++;
					messages.push({
						message: `Line ${i}: Not enough columns`,
						result: `Prediction not processed`,
						type: "error",
					});
					error = true;
					anyErrors = true;
				}

				if (
					!error &&
					!_.every(csvRow, (field) => {
						return field == "";
					})
				) {
					predictions.push(prediction);
				}
			}

			return predictions;

			// return {
			// 	requests: requests,
			// 	messages: messages,
			// 	proceed: requests.length > 0 && !anyErrors,
			// 	num: numProcessed
			// };
		},

		saveRates() {
			this.processData();
			this.adjustRatesModal = false;
		},

		cleanupIngestModal() {
			let input = $("ingest-import");
			input.replaceWith(input.val("").clone(true));
		},

		goalTimeTooltip(row) {
			let date = row.date;
			let u_awt = row.u_availableWorkMinutes;
			let g_awt = row.g_availableWorkMinutes;
			if (row.newGoalTime) {
				g_awt = row.newGoalTime * 60;
			}
			let tooltip = `<table class="text-left">`;
			tooltip += `<tr><td colspan="2" class="pb-2 text-extra-muted _600">${moment(date, "MM/DD/YYYY").format(
				"LL"
			)}</td></tr>`;
			tooltip += `<tr><td>${this.$i18n.t("Forecast.scheduled_work_time")}:</td><td class="pl-3 _600">${fs.fixed1d(
				u_awt / 60
			)}${this.$i18n.t("Forecast.hours_suffix")}</td></tr>`;

			if (g_awt > 0) {
				tooltip += `<tr><td>${this.$i18n.t("Forecast.goal_work_time")}:</td><td class="pl-3 _600">${fs.fixed1d(
					g_awt / 60
				)}${this.$i18n.t("Forecast.hours_suffix")}</td></tr>`;
			} else {
				tooltip += `<tr><td>${this.$i18n.t(
					"Forecast.goal_work_time"
				)}:</td><td class="pl-3 _600"><span class="text-extra-muted">${this.$i18n.t(
					"Forecast.none_set"
				)}</span></td></tr>`;
			}

			if (u_awt >= g_awt) {
				tooltip += `<tr><td colspan="2" class="pt-2">${this.$i18n.t(
					"Forecast.using_scheduled_total"
				)}</td></tr>`;
			} else {
				tooltip += `<tr><td colspan="2" class="pt-2">${this.$i18n.t("Forecast.added_n_to_reach_goal", {
					n: fs.fixed1d((g_awt - u_awt) / 60),
				})}</td></tr>`;
			}
			tooltip += `</table>`;
			tooltip += `<div class="_600 mt-2 text-primary">${this.$i18n.t("Forecast.click_to_edit")}</div>`;

			return tooltip;
		},

		adjustGoalTime(row) {
			let use_shifts = this.selectedProject.use_shifts;
			let newGoalMinutes = row.newGoalTime * 60;
			if (use_shifts) {
				newGoalMinutes = this.getNewShiftWorkTime(row);
			}
			if (newGoalMinutes < row.u_availableWorkMinutes) {
				notie.error(this.$i18n.t("Forecast.goal_time_too_low"));
			} else {
				let scheduleConfig = _.find(this.scheduleConfigs, { date: row.date });
				if (scheduleConfig) {
					if (use_shifts) {
						scheduleConfig.old_shift_thresholds = scheduleConfig.shift_thresholds;
						scheduleConfig.shift_thresholds = row.new_shift_thresholds;
					} else {
						scheduleConfig.old_threshold_type = scheduleConfig.threshold_type;
						scheduleConfig.old_threshold = scheduleConfig.threshold;
						scheduleConfig.threshold_type = 3;
						scheduleConfig.threshold = row.newGoalTime;
					}
				} else {
					if (use_shifts) {
						scheduleConfig = {
							date: row.date,
							project_id: this.selectedProject.id,
							shift_thresholds: row.new_shift_thresholds,
							threshold: 0,
							threshold_type: 0,
							user_labels: {},
						};
					} else {
						scheduleConfig = {
							date: row.date,
							project_id: this.selectedProject.id,
							shift_thresholds: {},
							threshold: row.newGoalTime,
							threshold_type: 3,
							user_labels: {},
						};
					}
					this.scheduleConfigs.push(scheduleConfig);
				}

				this.changedConfigs[row.date] = scheduleConfig;

				let count = 0;
				for (let obj in this.changedConfigs) count++;
				this.numChangedConfigs = count;

				this.processData();
				row.showGoalEdit = false;
				let button = document.getElementById(`edit-goal-${row.date}`);
				console.log(button._tippy);
				button._tippy.title = this.goalTimeTooltip(row);
				button._tippy.popperInstance.update();
			}
		},

		showSaveGoalsModal() {
			this.configsToSave = [];
			_.each(this.changedConfigs, (config) => {
				this.configsToSave.push(config);
			});
			this.saveGoalsModal = true;
		},

		saveGoals() {
			this.savingGoals = true;
			BB.map(this.configsToSave, (config) => {
				return ScheduleService.saveScheduleConfig(config);
			})
				.then((resp) => {
					this.savingGoals = false;
					notie.success(
						this.$i18n.tc("Forecast.save_schedule_days", this.configsToSave.length, {
							n: this.configsToSave.length,
						})
					);
					this.saveGoalsModal = false;
					this.configsToSave = [];
					this.changedConfigs = {};
					this.processData();
				})
				.catch((e) => {
					this.savingGoals = false;
					console.log(e);
					notie.error(e);
				});
		},

		openExpirationModal(row) {
			this.expirationRow = row;
			this.expirationModal = true;
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},

		sumShifts(shiftMap) {
			let sum = 0;
			_.each(shiftMap, (count) => {
				sum += count;
			});
			return sum;
		},
	},
};
</script>
