<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b flex-nowrap flex-fixed">
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("ReportUserAgreement.reporting") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ReportUserAgreement.user_agreement") }}
				</div>
			</div>
		</template>
		<div class="d-flex flex-column flex scroll-x scroll-y">
			<div class="row mx-0 px-3 pt-3 box no-shrink" v-spinner-overlay="loading">
				<div class="col-12 col-lg-6 col-xl-3">
					<div class="form-group">
						<label>{{ $t("ReportUserAgreement.project") }}</label>
						<config-select :options="projects" v-model="selectedProject"></config-select>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-3">
					<div class="form-group">
						<label>{{ $t("ReportUserAgreement.section") }}</label>
						<config-select :options="sections" v-model="selectedSection"></config-select>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-xl-3">
					<div class="form-group">
						<label>{{ $t("ReportUserAgreement.item") }}</label>
						<config-select
							:options="items"
							v-model="selectedItem"
							sortBy="section_item_sequence"
						></config-select>
					</div>
				</div>
				<div class="form-group col-9">
					<b-row>
						<b-col>
							<label class="mb-0">{{ $t("ReportScorers.team") }}</label>
							<config-select :nullOption="true" :options="teams" v-model="selectedTeam"></config-select>
						</b-col>
						<b-col>
							<label class="no-grow mb-0 mr-3">{{
								`${$t("fields.scored_at")} ${$t("fields.from_date")}`
							}}</label>
							<user-timezone />
							<config-date
								:key="fromDateGreaterThatToDate"
								v-model="fromDate"
								:config="{ showClear: true }"
								:invalid="fromDateGreaterThatToDate"
								v-tippy
								:title="fromDateGreaterThatToDate && $t('Auditing.from_date_greater_than_from')"
							></config-date>
						</b-col>
						<b-col>
							<label class="no-grow mb-0 mr-3">{{
								`${$t("fields.scored_at")} ${$t("fields.to_date")}`
							}}</label>
							<user-timezone />
							<config-date
								:key="fromDateGreaterThatToDate"
								v-model="toDate"
								:config="{ showClear: true }"
								:invalid="fromDateGreaterThatToDate"
								v-tippy
								:title="fromDateGreaterThatToDate && $t('Auditing.from_date_greater_than_from')"
							></config-date>
						</b-col>
					</b-row>
				</div>
				<div class="col-12 d-flex flex-row align-items-end justify-content-end">
					<div class="d-flex flex-row align-items-center form-group">
						<button
							v-if="reportStats && reportStats.length > 0"
							class="btn btn-sm btn-icon btn-rounded theme-accent mx-1"
							style="padding-top: 10px"
							v-tippy
							:title="$t('tooltip.export')"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							<i class="fas fa-file-export" style="margin-left: 2px"></i>
						</button>
						<div
							v-if="reportStats && reportStats.length > 0"
							class="dropdown-menu dropdown-menu-right w-md"
						>
							<a class="dropdown-item" @click="dlPdf()">
								<i class="far fa-file-pdf fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportUserAgreement.export_to_pdf") }}</label>
							</a>
							<a class="dropdown-item" @click="dlPdfWithTraits()">
								<i class="far fa-file-pdf fa-fw fa-lg"></i>
								<label class="mb-0">
									{{ $t("ReportUserAgreement.export_to_pdf") }}
									<span class="text-muted">(detailed)</span>
								</label>
							</a>
							<a class="dropdown-item" @click="dlCsv()">
								<i class="far fa-file-excel fa-fw fa-lg"></i>
								<label class="mb-0">{{ $t("ReportUserAgreement.export_to_csv") }}</label>
							</a>
							<a class="dropdown-item" @click="dlCsvWithTraits()">
								<i class="far fa-file-excel fa-fw fa-lg"></i>
								<label class="mb-0">
									{{ $t("ReportUserAgreement.export_to_csv") }}
									<span class="text-muted">(detailed)</span>
								</label>
							</a>
						</div>
						<div class="py-0 ml-1">
							<button
								@click="getStats"
								:disabled="!canRun"
								:class="{ [canRun ? 'theme-accent' : 'btn-secondary']: true }"
								class="btn btn-sm btn-block flex-fixed"
								v-b-tooltip
								:title="$t('tooltip.run_report')"
							>
								<i v-if="!running" class="far fa-clipboard-list"></i>
								<loading v-if="running" type="icon" />
								<span class="hidden-folded d-inline"
									>&nbsp;{{ $t("ReportUserAgreement.run_report") }}</span
								>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="reportStats && reportStats.length > 0"
				class="flex d-flex flex-row box-shadow-3 pb-3 scroll-y scroll-x"
				style="min-height: 100px"
			>
				<div class="flex">
					<div class="card border-0">
						<report-table
							class="condensed-table mb-0"
							:fields="fields"
							:totals="reportTotals"
							:content="reportStats"
							ref="rTable"
							per-page="15"
							totalsClass="theme-lighten-3 text-on-color"
						>
							<!-- Scorer -->
							<template #head(scorer)="data">
								<div>{{ data.label }}</div>
								<a @click="toggleAllExpand" class="btn-subtle">
									<span class="text-muted _400 pr-1" :key="allExpanded">{{
										allExpanded ? $t("tooltip.hide_all_details") : $t("tooltip.show_all_details")
									}}</span>
									<i v-if="!allExpanded" class="far fa-plus"></i>
									<i v-if="allExpanded" class="far fa-minus"></i>
								</a>
							</template>
							<template #cell(scorer)="data">
								<a @click="toggleExpand(data.item)" class="float-right btn-subtle">
									<i
										v-if="!data.item.expand"
										class="far fa-plus"
										v-tippy
										:title="$t('tooltip.show_details')"
									></i>
									<i
										v-if="data.item.expand"
										class="far fa-minus"
										v-tippy
										:title="$t('tooltip.hide_details')"
									></i>
								</a>
								<span v-if="!data.item.user">{{ data.item.scorer }}</span>
								<span
									v-if="data.item.user"
									v-tippy
									:title="data.item.user.scorer_id != '' ? data.item.user.full_name : null"
									>{{ fs.scorerID(data.item.user) }}</span
								>
								<div v-show="data.item.expand">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ trait.trait_name }}
										</div>
									</template>
								</div>
							</template>

							<!-- IRR Exact -->
							<template #cell(irr_total)="{ item: { irr_total, expand, traits } }">
								<span>{{ irr_total / traits.length }}</span>
								<div v-show="expand">
									<template v-for="trait in traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ trait.irr_total }}
										</div>
									</template>
								</div>
							</template>
							<template #cell(irr_exact_percent)="data">
								<span :class="{ 'text-very-muted': data.item.irr_total == 0 }">{{
									fs.fixedPercent1d(data.item.irr_exact_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.irr_total > 0">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.irr_exact_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(irr_total)="{ label, field: { sublabel } }">
								<div>{{ label }}</div>
								<div class="text-muted _400">{{ sublabel }}</div>
							</template>
							<template #head(irr_exact_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>
							<!-- IRR Exact or Adjacent -->
							<template #cell(irr_exact_adj_percent)="data">
								<span :class="{ 'text-very-muted': data.item.irr_total == 0 }">{{
									fs.fixedPercent1d(data.item.irr_exact_adj_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.irr_total > 0">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.irr_exact_adj_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(irr_exact_adj_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>

							<!-- Validity normal agreement -->
							<template #cell(val_total)="{ item: { val_total, expand, traits } }">
								<span>{{ val_total / traits.length }}</span>
								<div v-show="expand">
									<template v-for="trait in traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ trait.val_total }}
										</div>
									</template>
								</div>
							</template>
							<template #head(val_total)="{ label, field: { sublabel } }">
								<div>{{ label }}</div>
								<div class="text-muted _400">{{ sublabel }}</div>
							</template>

							<template #cell(val_exact_percent)="data">
								<span :class="{ 'text-very-muted': data.item.val_total == 0 }">{{
									fs.fixedPercent1d(data.item.val_exact_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.val_total > 0">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.val_exact_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(val_exact_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>

							<!-- Validity full agreement -->
							<template #cell(val_exact_adj_percent)="data">
								<span :class="{ 'text-very-muted': data.item.val_total == 0 }">{{
									fs.fixedPercent1d(data.item.val_exact_adj_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.val_total > 0">
									<template v-for="trait in data.item.traits">
										<div v-if="!(trait.separator || trait.is_parent)" :key="trait.trait_id">
											<span class="text-muted px-1">•</span>
											{{ fs.fixedPercent1d(trait.val_exact_adj_percent) }}
										</div>
									</template>
								</div>
							</template>
							<template #head(val_exact_adj_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>

							<!-- Resolution required -->
							<template #cell(res_required_total)="{ item: { res_required } }">
								<span>{{ res_required }}</span>
							</template>
							<template #head(res_required_total)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>
							<template #cell(res_required_percent)="data">
								<span :class="{ 'text-very-muted': data.item.res_total == 0 }">{{
									fs.fixedPercent1d(data.item.res_required_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.res_total > 0" class="text-muted">
									{{ data.item.res_required }} {{ $t("ReportUserAgreement.out_of") }}
									{{ data.item.res_total }}
								</div>
							</template>
							<template #head(res_required_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>
							<!-- Resolution disagreed -->
							<template #cell(res_changed_percent)="data">
								<span :class="{ 'text-very-muted': data.item.res_required == 0 }">{{
									fs.fixedPercent1d(data.item.res_changed_percent)
								}}</span>
								<div v-show="data.item.expand && data.item.res_required > 0" class="text-muted">
									{{ data.item.res_changed }} {{ $t("ReportUserAgreement.out_of") }}
									{{ data.item.res_required }}
								</div>
							</template>
							<template #head(res_changed_percent)="data">
								<div>{{ data.label }}</div>
								<div class="text-muted _400">{{ data.field.sublabel }}</div>
							</template>
						</report-table>
					</div>
				</div>
			</div>
			<div v-if="reportStats && reportStats.length == 0" class="d-flex flex-row justify-content-center">
				<h3 class="text-muted">Report returned no results</h3>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-long {
	display: none;
}

.d-abr {
	display: table-cell;
}

@media (min-width: 1100px) {
	.d-long {
		display: table-cell;
	}

	.d-abr {
		display: none;
	}
}

.unconstrained {
	width: auto;
	max-width: none;
	min-width: 100%;
}

i[class*="ion-"],
i[class*=" ion-"] {
	font-size: inherit;
	vertical-align: inherit;
}

a.btn-subtle i {
	opacity: 0.35;
	transition: opacity 0.25s;
}

a.btn-subtle:hover i {
	opacity: 1;
}

.trait-table tr {
	background-color: unset !important;
}

.trait-table td {
	border-top: unset;
}
</style>

<script>
//Libraries

//Services
import ReportingService from "@/services/ReportingService";
import ConfigService from "@/services/ConfigService";
import notie from "@/services/NotieService";
import store from "@/services/Store";
import fs from "@/services/FormatService";
import FileService from "@/services/FileService";
import moment from "moment";

import ConfigSelect from "@/components/ConfigSelect";
import ConfigLabelSearch from "@/components/ConfigLabelSearch";
import ReportTable from "@/components/ReportTable";
import ConfigDate from "@/components/ConfigDate";
import UserTimezone from "@/components/UserTimezone";

export default {
	name: "ReportUserAgreement",

	props: ["user"],

	components: { ConfigLabelSearch, ConfigSelect, ReportTable, ConfigDate, UserTimezone },

	data() {
		return {
			fs: fs,

			fields: [
				{
					key: "margin-left",
					label: "",
					tdClass: "t-margin",
				},
				{
					key: "scorer",
					label: this.$i18n.t("fields.scorer"),
					tdClass: "",
					thClass: "",
					sortable: true,
				},
				{
					key: "irr_total",
					label: this.$i18n.t("fields.irr"),
					sublabel: this.$i18n.t("ReportUserAgreement.total"),
					tdClass: "table-border-left",
					thClass: "table-border-left",
					sortable: true,
				},
				{
					key: "irr_exact_percent",
					label: this.$i18n.t("fields.irr"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact"),
					tdClass: "",
					thClass: "",
					sortable: true,
				},
				{
					key: "irr_exact_adj_percent",
					label: this.$i18n.t("fields.irr"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact_adjacent"),
					tdClass: "",
					thClass: "",
					sortable: true,
				},
				{
					key: "val_total",
					label: this.$i18n.t("fields.validity"),
					sublabel: this.$i18n.t("ReportUserAgreement.total"),
					tdClass: "table-border-left v-mid",
					thClass: "table-border-left v-mid",
					sortable: true,
				},
				{
					key: "val_exact_percent",
					label: this.$i18n.t("fields.validity"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact"),
					tdClass: "",
					thClass: "",
					sortable: true,
				},
				{
					key: "val_exact_adj_percent",
					label: this.$i18n.t("fields.validity"),
					sublabel: this.$i18n.t("ReportUserAgreement.exact_adjacent"),
					tdClass: "table-border-right v-mid",
					thClass: "table-border-right v-mid",
					sortable: true,
				},
				{
					key: "res_required_total",
					label: this.$i18n.t("fields.resolution"),
					sublabel: this.$i18n.t("ReportUserAgreement.total"),
					tdClass: "v-mid",
					thClass: "v-mid",
					sortable: true,
				},
				{
					key: "res_required_percent",
					label: this.$i18n.t("fields.resolution"),
					sublabel: this.$i18n.t("ReportUserAgreement.required"),
					tdClass: "v-mid",
					thClass: "v-mid",
					sortable: true,
				},
				{
					key: "res_changed_percent",
					label: this.$i18n.t("fields.resolution"),
					sublabel: this.$i18n.t("ReportUserAgreement.disagreed"),
					tdClass: "v-mid",
					thClass: "v-mid",
					sortable: true,
				},
				{
					key: "margin-right",
					label: "",
					tdClass: "t-margin",
				},
			],
			userStats: null,

			reportStats: null,
			reportTotals: null,
			showIncomplete: true,

			projects: [],
			selectedProject: null,

			labels: [],

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			teams: [],
			selectedTeam: null,

			fromDate: null,
			toDate: null,

			allExpanded: false,

			loading: true,
			running: false,
		};
	},

	created() {
		this.storagePrefix = "user_agreement.";
		this.showIncomplete = store.bind(this, "showIncomplete");

		if (store.get(this, "user_agreement.auto")) {
			this.running = true;
		}

		this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
		var ctx = {};
		ctx.showHiddenProjects = this.showHiddenProjects;
		ConfigService.listProjectsShallowPreloaded(ctx)
			.then((resp) => {
				console.log("listProjectsShallowPreloaded");
				console.log(resp);
				this.projects = resp.data;
				this.selectedProject = store.bindProject(this, this.projects);
				if (this.selectedProject) {
					this.sections = this.selectedProject.sections;
					this.selectedSection = store.bindSection(this, this.sections);
					this.selectedItem = store.bindItem(this, this.selectedSection);
				}

				if (store.get(this, "user_agreement.auto")) {
					store.set(this, "user_agreement.auto", null);
					this.running = false;
					this.getStats();
				}
				this.loading = false;
			})
			.catch((err) => {
				console.log(err);
				notie.error(this.$i18n.t("notie.load_config_options_fail"), err);
				this.loading = false;
			});

		this.loadTeams();
	},

	watch: {
		selectedProject() {
			if (this.selectedProject && this.selectedProject.sections) {
				this.sections = this.selectedProject.sections;
			} else {
				this.sections = [];
			}
		},
		selectedSection() {
			if (this.selectedSection && this.selectedSection.items) {
				this.items = this.selectedSection.items;
				if (
					this.items.length > 0 &&
					(!this.selectedItem || !_.find(this.items, { id: this.selectedItem.id }))
				) {
					this.selectedItem = this.items[0];
				}
			} else {
				this.items = [];
			}
		},
	},

	computed: {
		canRun() {
			return this.selectedSection && this.selectedSection.id && this.selectedItem && this.selectedItem.id;
		},

		fromDateGreaterThatToDate() {
			const { fromDate, toDate } = this;
			if (!(fromDate && toDate)) return false;
			return fromDate > toDate;
		},
	},

	methods: {
		async getStats() {
			const { running, canRun, selectedSection, selectedItem, selectedTeam, toDate, fromDate } = this;

			if (running || !canRun || !(selectedSection && selectedItem)) return;

			this.userStats = null;
			this.validitySets = [];
			this.reportStats = null;
			this.reportTotals = null;

			this.running = true;

			try {
				const {
					data: { stats, totals },
				} = await ReportingService.getAgreementUsers(
					selectedSection.id,
					selectedItem.id,
					null,
					selectedTeam && selectedTeam.id,
					fromDate,
					toDate
				);
				this.reportStats = stats;
				this.reportTotals = totals;
			} catch (err) {
				notie.error("Failed to run report", err);
				console.log(err);
			} finally {
				this.running = false;
			}
		},

		async loadTeams() {
			const { user } = this;
			if (!user) return;

			try {
				if (user.role.limit_teams) {
					const { data } = await ConfigService.listUserTeams();
					this.teams = data;
				} else {
					const {
						data: { teams },
					} = await ConfigService.listTeams();
					this.teams = teams;
				}
			} catch (err) {
				notie.error("Failed to load teams", err);
				console.log(err);
			}
		},

		toggleAllExpand() {
			this.allExpanded = !this.allExpanded;
			this.reportStats = this.reportStats.map((stat) => ({
				...stat,
				expand: this.allExpanded,
			}));
			this.reportTotals = { ...this.reportTotals, expand: this.allExpanded };
		},

		showStat(stat, i, set) {
			if (this.showIncomplete) {
				return stat.validityComplete[i] > 0;
			} else {
				return stat.validityComplete[i] == set.responses;
			}
		},

		fillItem(row) {
			//real
			let section = _.find(this.sections, { id: row.section_id });

			//mockup
			section = this.sections[0];

			if (section && section.items) {
				//real
				let item = _.find(section.items, { id: row.item_id });

				//mockup
				item = section.items[0];

				if (item) {
					this.selectedSection = section;
					this.selectedItem = item;
					this.getStats();
				}
			}
		},

		isTotalRow(item) {
			if (item.forceHead || item.forceFoot) {
				return true;
			} else {
				return false;
			}
		},

		toggleExpand(stat) {
			stat.expand = !stat.expand;
			this.$refs.rTable.$forceUpdate();
		},

		dlPdf() {
			FileService.userAgreementReportPDF(this, false);
		},

		dlCsv() {
			FileService.userAgreementReportCSV(this, false);
		},

		dlPdfWithTraits() {
			FileService.userAgreementReportPDF(this, true);
		},

		dlCsvWithTraits() {
			FileService.userAgreementReportCSV(this, true);
		},
	},
};
</script>
