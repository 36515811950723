import axios from "axios";

export default {
	listSections(ctx) {
		return axios.get("/v1/admin/sections", { params: ctx });
	},

	getSection(id) {
		return axios.get(`/v1/admin/section/${id}`);
	},

	isScored(id) {
		return axios.get(`/v1/admin/section_scored/${id}`);
	},

	saveSection(section) {
		return axios.post("/v1/admin/section", section);
	},

	removeSection(id) {
		return axios.delete(`/v1/admin/section/${id}`);
	},

	//Param "inverse" gets Items not assigned to the section
	getSectionItems(sectionId, ctx) {
		return axios.get(`/v1/admin/si/${sectionId}`, { params: ctx });
	},

	addItem(sectionId, itemId) {
		return axios.post(`/v1/admin/si/${sectionId}/${itemId}`);
	},

	removeItem(sectionId, itemId) {
		return axios.delete(`/v1/admin/si/${sectionId}/${itemId}`);
	},

	//Param "inverse" gets Teams not assigned to the section
	getSectionTeams(sectionId, ctx) {
		return axios.get(`/v1/admin/st/${sectionId}`, { params: ctx });
	},

	addTeam(sectionId, teamId) {
		return axios.post(`/v1/admin/st/${sectionId}/${teamId}`);
	},

	removeTeam(sectionId, teamId) {
		return axios.delete(`/v1/admin/st/${sectionId}/${teamId}`);
	},

	importScoresCSV(formData) {
		return axios.post("/v1/admin/import/scores/csv", formData, {
			headers: { "Content-Type": undefined }
		});
	},

	importSchoolCodesCSV(formData) {
		return axios.post("/v1/admin/import/school_codes/csv", formData, {
			headers: { "Content-Type": undefined }
		});
	},

	uploadResponses(formData) {
		return axios.post("/v1/admin/upload/responses", formData, {
			headers: { "Content-Type": undefined }
		});
	},

	uploadContentPackage(formData) {
		return axios.post("/v1/admin/upload/content_package", formData, {
			headers: { "Content-Type": undefined }
		});
	},

	addResponseFile(sectionID, itemID, file, forceDownload, width, height) {
		let form = new FormData();
		form.set("file", file);
		form.set("width", width)
		form.set("height", height)
		console.log("addResponseFile", width, height)

		if (forceDownload) {
			form.set("forceDownload", true)
		}

		return axios.post(
			`/v1/admin/upload/response/${sectionID}/${itemID}`,
			form
		);
	},

	addResponseFiles(sectionID, itemID, files, forceDownload) {
		let form = new FormData();
		_.each(files, file => {
			form.append("file", file);
		});

		if (forceDownload) {
			form.set("forceDownload", true)
		}

		return axios.post(
			`/v1/admin/upload/response_multi/${sectionID}/${itemID}`,
			form
		);
	},

	getScoredItems(sectionID) {
		return axios.get(`/v1/admin/section_scored/${sectionID}/items`)
	},

	getItemWithResponses(id) {
		return axios.get(`/v1/admin/section/${id}/items_with_responses`)
	},

	updateSectionItemSequence(sectionID, sectionItems){
		let form = new FormData();
		form.set("sectionItems", sectionItems);
		return axios.post(`/v1/admin/update_si_sequence/${sectionID}`, form)
	},
};
