<template>
	<page customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="editUser.loaded == 0" />
				<div v-if="editUser.loaded != 0" class="navbar-text nav-title flex" id="pageTitle">
					<i
						class="fas fa-fw fa-lock-alt"
						v-if="!enoughAccess"
						v-tippy="{ placement: 'bottom-start' }"
						:title="$t('UserEdit.access_level')"
					></i>
					{{ editUser.first_name }} {{ editUser.last_name }} ({{ editUser.scorer_id }})
				</div>
			</div>
		</template>
		<div class="h-100" v-if="editUser.loaded != 0">
			<div class="d-sm-flex b-t h-100">
				<edit-pane :cancel="loadData" :save="saveUser" :dirty="dirty" :valid="valid">
					<div class="p-4 b-b _600 nav-title">{{ $t("UserEdit.title") }}</div>
					<div class="col-sm-10 col-12">
						<div class="row m-0 pt-4">
							<div
								v-if="editUser.clients && editUser.clients.length > 1"
								class="col-12 mb-2"
								style="margin-top: -0.75rem"
							>
								<small class="text-muted" v-tippy :title="getCrossTenantSettingsTooltip()">
									<i class="fa fas fa-info-circle mr-1" />
									{{ $t("UserEdit.cross_tenant_settings") }}
								</small>
							</div>
							<div class="col-sm-6 col-12">
								<div class="form-group" :class="{ invalid: !valid.field('name') }">
									<label>{{ $t("UserEdit.first_name") }}</label>
									<input
										type="text"
										class="form-control"
										v-model="editUser.first_name"
										:disabled="!enoughAccess || !canEdit"
									/>
								</div>
							</div>
							<div class="col-sm-6 col-12">
								<div class="form-group">
									<label>{{ $t("UserEdit.last_name") }}</label>
									<input
										type="text"
										class="form-control"
										v-model="editUser.last_name"
										:disabled="!enoughAccess || !canEdit"
									/>
								</div>
							</div>
							<div class="col-sm-6 col-12">
								<div class="form-group" :class="{ invalid: !valid.field('email') }">
									<label>{{ $t("UserEdit.email") }}</label>
									<input
										type="text"
										class="form-control"
										v-model="editUser.email"
										:disabled="!enoughAccess || !canEdit"
									/>
									<small
										v-if="
											editUser.email &&
											params.id == 'new' &&
											!editUser.setPass &&
											valid.field('email')
										"
										class="text-warning"
									>
										{{ $t("UserEdit.invite_email", { email: editUser.email }) }}
									</small>
								</div>
							</div>
							<div class="col-6 col-sm-3 p-0">
								<div class="form-group col-12">{{ $t("UserEdit.archived") }}</div>
								<div class="form-group col-12">
									<label class="ui-switch ui-switch-md theme-accent">
										<input
											type="checkbox"
											v-model="editUser.archived"
											:disabled="!enoughAccess || !canEdit"
										/>
										<i></i>
									</label>
								</div>
							</div>
							<div class="col-12 col-sm-6 form-group" v-if="enoughAccess">
								<button class="btn theme-accent btn-block" @click="togglePass">
									{{ $t("UserEdit.set_password") }}
									<i
										class="far fa-fw fa-angle-up anim-rotate"
										:class="{ 'rotate-180': editUser.setPass }"
									></i>
								</button>
							</div>
							<div class="col-12 col-sm-6"></div>
							<div class="row m-0 w-100" v-show="editUser.setPass">
								<div
									class="col-12 col-sm-6 form-group"
									:class="{
										invalid: !valid.field('passwordLength') || !valid.field('passwordCharacters'),
									}"
								>
									<label>{{ $t("UserEdit.new_password") }}</label>
									<input
										type="password"
										class="form-control"
										v-model="editUser.password"
										autocomplete="new-password"
									/>
								</div>
								<div class="col-12 col-sm-6 form-group d-flex flex-column justify-content-end">
									<div style="min-height: 28px">
										<div
											class="my-0 text-xs text-muted"
											v-for="error in valid.errors(['passwordLength', 'passwordCharacters'])"
											:key="error"
										>
											{{ error }}
										</div>
									</div>
								</div>
								<div
									class="col-12 col-sm-6 form-group"
									:class="{ invalid: !valid.field('passwordMatch') }"
								>
									<label>{{ $t("UserEdit.confirm_password") }}</label>
									<input
										type="password"
										class="form-control"
										v-model="editUser.password2"
										autocomplete="new-password"
									/>
								</div>
								<div class="col-12 col-sm-6 form-group d-flex flex-column justify-content-end">
									<div
										class="mt-1 mb-0 text-xs text-muted"
										v-for="error in valid.errors('passwordMatch')"
										:key="error"
										style="min-height: 28px"
									>
										{{ error }}
									</div>
								</div>
							</div>
							<div
								class="col-12 col-sm-6 form-group"
								v-if="params.id != 'new' && this.user.role.force_password_reset"
							>
								<button class="btn theme-accent btn-block" @click="reset(editUser.email)">
									{{ $t("UserEdit.force_password_reset") }}
								</button>
							</div>

							<div class="col-12">
								<hr class="mt-2" />
							</div>

							<div
								v-if="editUser.clients && editUser.clients.length > 1"
								class="col-12 mb-2"
								style="margin-top: -0.25rem"
							>
								<small
									class="text-muted"
									v-tippy
									:title="$t('UserEdit.tenant_specific_settings_tooltip')"
								>
									<i class="fa fas fa-info-circle mr-1" />
									{{ $t("UserEdit.tenant_specific_settings") }}
								</small>
							</div>
							<div class="col-sm-6 col-12">
								<div class="form-group" :class="{ invalid: !valid.field('scorerId') }">
									<label>{{ $t("UserEdit.scorer_id") }}</label>
									<input
										type="text"
										class="form-control"
										v-model.trim="editUser.scorer_id"
										:disabled="!enoughAccess"
									/>
								</div>
							</div>
							<div class="form-group col-sm-6 col-12">
								<label>{{ $t("UserEdit.teams") }}</label>
								<v-select
									:options="availTeams"
									v-model="sTeams"
									label="name"
									multiple
									:disabled="!enoughAccess"
								></v-select>
							</div>
							<div class="form-group col-sm-6 col-12">
								<label>{{ $t("UserEdit.school_code") }}</label>
								<v-select v-model="editUser.school_codes" multiple taggable :disabled="!enoughAccess">
									<template slot="no-options">
										<span>Type to enter school codes</span>
									</template>
								</v-select>
							</div>
							<div class="form-group col-sm-3 col-6" :class="{ invalid: !valid.field('role') }">
								<label>{{ $t("UserEdit.role") }}</label>
								<v-select
									:options="accessibleRoles"
									v-model="sRole"
									label="name"
									:disabled="!enoughAccess"
								></v-select>
							</div>
							<div class="col-6 col-sm-3 p-0">
								<div class="form-group col-12">{{ $t("UserEdit.active") }}</div>
								<div class="form-group col-12">
									<label class="ui-switch ui-switch-md theme-accent">
										<input type="checkbox" v-model="editUser.active" :disabled="!enoughAccess" />
										<i></i>
									</label>
								</div>
							</div>
						</div>
					</div>
				</edit-pane>
				<save-optional-modal
					:dirty="dirty"
					:valid="valid"
					:save="saveUser"
					:next="saveOptNext"
					:cancel="saveOptCancel"
					objectText="User"
					:objectName="user.first_name + ' ' + user.last_name"
					actionText="leave the page"
					v-model="saveOptModal"
				/>
			</div>
		</div>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}
</style>

<script>
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import UserService from "@/services/UserService";
import TeamService from "@/services/TeamService";
import TenantService from "@/services/TenantService";
import ValidationService from "@/services/ValidationService";
import notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import AuthService from "@/services/AuthService";
import BB from "bluebird";

export default {
	name: "UserEdit",

	props: ["user", "params", "query"],

	components: { EditPane, SaveOptionalModal },

	data() {
		return {
			tab: 1,
			editUser: {
				team_ids: [],
				role_id: null,
				role: null,
				setPass: false,
				password: null,
				password2: null,
				loaded: 0,
			},
			availTeams: [],
			sTeams: [],
			roles: [],
			sRole: null,
			dirty: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	computed: {
		accessibleRoles() {
			return _.filter(this.roles, (role) => {
				return this.user.role.access >= role.access;
			});
		},
		enoughAccess() {
			if (!(this.user.role && this.editUser.role)) {
				return true;
			}
			return this.user.role.access >= this.editUser.role.access;
		},
	},

	created() {
		this.loadData();
		this.initValidation();

		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {
		sRole: function (newVal, oldVal) {
			console.log("ROLE", newVal);
			if (!newVal) {
				this.editUser.role_id = null;
				this.editUser.role = null;
			} else if (this.editUser.role_id != newVal.id) {
				this.editUser.role_id = newVal.id;
				this.editUser.role = newVal;
			}
		},
		sTeams: {
			handler: function (newVal, oldVal) {
				var newIds = _.map(newVal, "id");
				if (_.xor(this.editUser.team_ids, newIds).length > 0) {
					this.editUser.team_ids = newIds;
					this.dirty = true;
				}
			},
			deep: true,
		},
		"editUser.email"() {
			let trimmed = this.editUser.email.replace(/\s/g, "");
			if (trimmed != this.editUser.email) {
				this.editUser.email = trimmed;
			}
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: this.$i18n.t("error.assign_first_name"),
					func: () => {
						return this.editUser && this.editUser.first_name && this.editUser.first_name != "";
					},
				},
				role: {
					group: "details",
					errorMsg: this.$i18n.t("error.choose_role"),
					func: () => {
						return this.editUser && this.editUser.role_id;
					},
				},
				email: {
					group: "details",
					errorMsg: this.$i18n.t("error.valid_email"),
					func: () => {
						let re = /\S+@\S+\.\S+/;
						return this.editUser && this.editUser.email && re.test(this.editUser.email);
					},
				},
				passwordLength: {
					group: "details",
					errorMsg: this.$i18n.t("error.password_length"),
					func: () => {
						if (!(this.editUser && this.editUser.password)) return true;
						return this.editUser.password == "" || this.editUser.password.length >= 8;
					},
				},
				passwordCharacters: {
					group: "details",
					errorMsg: this.$i18n.t("error.password_characters"),
					func: () => {
						if (!(this.editUser && this.editUser.password)) return true;
						let hasLower = false;
						let hasUpper = false;
						let hasDigit = false;

						for (let i = 0; i < this.editUser.password.length; i++) {
							let charCode = this.editUser.password.charCodeAt(i);
							console.log("Check", this.editUser.password[i], charCode);
							if (charCode >= 48 && charCode <= 57) {
								hasDigit = true;
							}
							if (charCode >= 65 && charCode <= 90) {
								hasUpper = true;
							}
							if (charCode >= 97 && charCode <= 122) {
								hasLower = true;
							}
						}
						console.log(hasLower, hasUpper, hasDigit);
						return hasLower && hasUpper && hasDigit;
					},
				},
				scorerId: {
					group: "details",
					errorMsg: this.$i18n.t("error.scorer_id_field_empty"),
					func: () => {
						if (this.editUser && this.editUser.scorer_id) return true;
					},
				},
				passwordMatch: {
					group: "details",
					errorMsg: this.$i18n.t("error.password_match"),
					func: () => {
						return (
							this.editUser &&
							(this.editUser.password == null || this.editUser.password == this.editUser.password2)
						);
					},
				},
			});
		},

		blankUser() {
			return {
				team_ids: [],
				role_id: null,
				role: null,
				setPass: false,
				password: null,
				password2: null,
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"editUser",
				(newc, old) => {
					console.log(newc);
					console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			console.log("LOAD USER");
			this.dirty = false;
			var loaded = this.editUser.loaded;
			loaded++;

			if (this.params.id != "new") {
				return BB.props({
					user: UserService.getUser(this.params.id),
					canEdit: UserService.canEditUser(this.params.id),
					teams: TeamService.listTeams(),
					client: TenantService.getClient("current"),
				})
					.then((resps) => {
						// getUser
						this.editUser = resps.user.data;
						this.editUser.loaded = loaded;

						// canEdit
						this.canEdit = resps.canEdit.data;

						// listTeams
						this.availTeams = resps.teams.data.teams || [];
						if (this.availTeams.length > 0 && this.editUser.team_ids && this.editUser.team_ids.length > 0) {
							this.sTeams = _.filter(this.availTeams, (at) => {
								return _.includes(this.editUser.team_ids, at.id);
							});
						}

						// getClient
						this.roles = resps.client.data.roles;
						if (this.roles.length > 0 && this.editUser) {
							if (this.editUser.role) {
								this.sRole = _.find(this.roles, {
									id: this.editUser.role.id,
								});
							} else {
								this.sRole = null;
							}
						}

						this.watchChanges();
					})
					.catch((err) => {
						console.log(err);
						notie.error(this.$i18n.t("notie.load_user_error"), err);
					});
			} else {
				return BB.props({
					teams: TeamService.listTeams(),
					client: TenantService.getClient("current"),
				})
					.then((resps) => {
						// listTeams
						this.availTeams = resps.teams.data.teams || [];

						// canEdit
						// Doesn't need a call, we assume any new user will be editable, otherwise we wouldn't be on this page
						this.canEdit = true;

						// getClient
						this.roles = resps.client.data.roles;

						this.editUser = this.blankUser();
						this.editUser.loaded = loaded;
						this.editUser.first_name = "Oscar";
						this.editUser.last_name = "Grouch";
						this.watchChanges();
					})
					.catch((err) => {
						console.log(err);
						notie.error(this.$i18n.t("notie.load_user_error"), err);
					});
			}
		},

		showValidationHints() {
			this.validationHints = true;
		},

		getValidationErrors() {
			let errors = [];
			_.each(this.validators, (va) => {
				if (!va.func()) {
					errors.push(va.errorMsg);
				}
			});
			return errors;
		},

		saveUser() {
			var dontSendTeams = this.user.teams;
			this.user.teams = null;
			this.trimCredentials();
			return UserService.saveUser(this.editUser)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.user_saved"));
					if (this.editUser.id != resp.data.id) {
						this.$router.replace(`/users/${resp.data.id}`);
					}
					this.loadData();
				})
				.catch((err) => {
					console.log(err.response);
					if (err.response.data.message == "A user already exists with that Scorer ID for tenant") {
						this.editUser.scorer_id = null;
					}
					notie.error(this.$i18n.t("notie.save_user_fail"), err);
				});
			this.user.teams = dontSendTeams;
		},

		trimCredentials() {
			if (this.editUser.password) {
				this.editUser.password = this.editUser.password.trim();
			}
		},

		canSave() {
			return this.dirty;
		},

		togglePass() {
			if (this.editUser.setPass) {
				this.$set(this.editUser, "setPass", false);
				this.$set(this.editUser, "password", null);
				this.$set(this.editUser, "password2", null);
			} else {
				this.$set(this.editUser, "setPass", true);
				this.$set(this.editUser, "password", "");
				this.$set(this.editUser, "password2", "");
			}
		},

		reset(email) {
			var _this = this;
			console.log(email);
			AuthService.resetPasswordOnSession(email)
				.then((resp) => {
					notie.success(
						this.$i18n.t("notie.reset_email_sent", { email: email }),
						this.$i18n.t("notie.check_email_to_continue")
					);
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.invalid_email_to_reset"), err);
				});
		},

		getCrossTenantSettingsTooltip() {
			if (this.canEdit) {
				return this.$i18n.t("UserEdit.cross_tenant_settings_tooltip");
			} else {
				return (
					this.$i18n.t("UserEdit.cross_tenant_settings_tooltip") +
					"<br/><br/>" +
					this.$i18n.t("UserEdit.cross_tenant_settings_disabled")
				);
			}
		},
	},
};
</script>
