import { render, staticRenderFns } from "./EscalateScoreButton.vue?vue&type=template&id=cfa6f462&scoped=true"
import script from "./EscalateScoreButton.vue?vue&type=script&lang=js"
export * from "./EscalateScoreButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa6f462",
  null
  
)

export default component.exports