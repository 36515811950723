<template>
	<page :title="$t('TenantDashboard.title')">
		<div class="row mx-0 no-grow px-2 pt-3 b-b box mb-0">
			<div class="flex d-flex flex-row flex-wrap">
				<div class="col-12 py-2 pl-4">
					<b-row>
						<label
							class="md-check md-check-md"
							v-tippy
							:title="$t('TenantDashboard.unique_refids_tooltip')"
						>
							<input type="checkbox" v-model="uniqueRefs" />
							<i class="theme-accent"></i>
							{{ $t("TenantDashboard.unique_refids") }}
						</label>
					</b-row>
				</div>
			</div>
		</div>
		<div class="row no-gutters white nav-active-bg">
			<div class="card-header" style="width: 100%">
				<ul class="nav nav-tabs card-header-tabs">
					<li class="nav-item">
						<a :class="{ active: !exported }" class="nav-link no-select" @click="exported = false">{{
							$t("TenantDashboard.imported")
						}}</a>
					</li>
					<li class="nav-item">
						<a :class="{ active: exported }" class="nav-link no-select" @click="exported = true">{{
							$t("TenantDashboard.exported")
						}}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="padding">
			<div class="row">
				<div class="col-6">
					<tenant-dash-resp-count :exported="exported" :uniqueRefs="uniqueRefs" />
				</div>
				<div class="col-6">
					<tenant-dash-resp-count :exported="exported" :uniqueRefs="uniqueRefs" last-week />
				</div>
				<div class="col-12">
					<tenant-dash-graph :exported="exported" :uniqueRefs="uniqueRefs" />
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import TenantDashRespCount from "@/components/tenant_dashboard/TenantDashRespCount";
import TenantDashGraph from "@/components/tenant_dashboard/TenantDashGraph";

import store from "@/services/Store";

export default {
	name: "TenantDashboard",
	props: ["user"],
	data() {
		const uniqueRefs = store.getDefault(this, "TenantDashboard.uniqueRefs", false);

		return {
			uniqueRefs,
			exported: false,
		};
	},
	components: {
		TenantDashRespCount,
		TenantDashGraph,
	},
	watch: {
		uniqueRefs() {
			store.set(this, "TenantDashboard.uniqueRefs", this.uniqueRefs);
		},
	},
};
</script>


