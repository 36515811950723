<template>
	<div>
        <div class="lrn-box">
            <span class="learnosity-item" :data-reference="page.learnosity_packet.request.items[0]"></span>
	    </div>
    </div>
</template>

<script>
export default {
	name: "LearnosityMedia",
	props: ["page"],
	data() {
		return {
            lrnApp:null,
		};
	},

	watch: {},

	created() { 
    },

    beforeDestroy(){
        this.lrnApp.questionsApp().stopAllSimpleFeatures()
        if(this.lrnApp && this.lrnApp.destroy){
            this.lrnApp.destroy();
        }
    },

	mounted() {
        console.log(this.page)
        this.lrnApp = window.LearnosityItems.init(this.page.learnosity_packet);
    },

	methods: {}
};
</script>

<style scoped>
.lrn-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
    padding:20px;
	max-width: 1000px;
	min-width: 400px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}
</style>
