import axios from "axios";

export default {
	// If param "crossTenant" is set to true, the call will attempt to include users from other clients
	// that have the same account ID as the requested client.
	// Requesting a client other than the one the user is currently in will only work in the requesting user
	// has the PageConfigTenants permission (or superuser).
	listUsers(clientId, ctx) {
		return axios.get(`/v1/admin/client/${clientId}/users`, { params: ctx });
	},

	getUser(id) {
		return axios.get(`/v1/admin/user/${id}`);
	},

	canEditUser(id) {
		return axios.get(`/v1/admin/user/${id}/can_edit`);
	},

	saveUser(user) {
		if (user.password || user.password == "") {
			return axios.post("/v1/admin/users+pass", {
				password: user.password,
				user: user
			});
		} else {
			return axios.post("/v1/admin/users", user);
		}
	},

	removeUser(id) {
		return axios.delete(`/v1/admin/user/${id}`);
	},

	validateImportUsers(request) {
		return axios.post(`/v1/admin/import/users/valid`, request);
	},

	importUsers(request) {
		return axios.post(`/v1/admin/import/users`, request);
	},

	exportUsers(request) {
		return axios.post(`/v1/admin/export/users`, request);
	},

	exportUserErrors(request) {
		return axios.post(`/v1/admin/export/usererrors`, request);
	},

	activate(user) {
		return axios.post(`/v1/admin/user/${user.id}/activate`);
	},

	deactivate(user) {
		return axios.post(`/v1/admin/user/${user.id}/deactivate`);
	},

	archive(user) {
		return axios.post(`/v1/admin/user/${user.id}/archive`);
	},

	unarchive(user) {
		return axios.post(`/v1/admin/user/${user.id}/unarchive`);
	},

	addUserToClient(clientID, user) {
		return axios.post(`/v1/admin/client/${clientID}/adduser`, user);
	},

	removeUserFromClient(clientID, userID) {
		return axios.delete(
			`/v1/admin/client/${clientID}/removeuser/${userID}`
		);
	},

	updateProfile(profile) {
		let update = {
			user: profile,
			password: profile.password,
			current_password: profile.current_password
		};
		return axios.post("/v1/profile", update);
	},

	changePlaybackSpeed(playback_speed) {
		return axios.post("/v1/change_playback", { playback_speed });
	},

	getPersonalNote(itemID) {
		return axios.get(`/v1/personal_note/${itemID}`);
	},

	savePersonalNote(itemID, note) {
		return axios.post(`/v1/personal_note/${itemID}`, note);
	},

	getUserNames(userIDs) {
		return axios.get(`/v1/user_names`, { params: { userIDs: userIDs } });
	},

	listScorerIDs() {
		return axios.get(`/v1/admin/user_scorer_ids`)
	}
};
