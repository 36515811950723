<template>
	<v-select
		:filterable="false"
		:options="userOptions"
		@search="searchUsers"
		label="name"
		@input="emitUser"
		:value="value"
		:multiple="multiple"
		:placeholder="placeholder"
		:disabled="disabled"
	>
		<template slot="no-options">
			<span v-if="!includeTeams">{{ $t("config_select.search_users_placeholder") }}</span>
			<span v-if="includeTeams">{{ $t("config_select.search_users_and_teams_placeholder") }}</span>
		</template>

		<template slot="option" slot-scope="option">
			<div v-if="option.name">
				<span>{{ option.name }}</span>
				<span v-if="option.users" class="float-right text-small text-muted">
					{{ option.users.length }}
					<i class="far faw fa-users"></i>
				</span>
			</div>
			<template v-if="!option.name"></template>

			<div
				v-if="matchSchoolCodes.length > 0 && schoolCodeMismatchWithInclusivity(option)"
				class="text-xxs text-muted"
				style="margin-top: 2px"
			>
				<i class="fas fa-exclamation-triangle text-danger" style="font-size: 12px; line-height: 12px" />
				<template v-if="schoolCodeMatchInclusive">
					<template v-if="matchSchoolCodes.length > 1">
						{{ $t("config_select.school_code_mismatch_multiple") }}
					</template>
					<template v-if="matchSchoolCodes.length == 1">
						{{ $t("config_select.school_code_mismatch_single") }}
					</template>
				</template>
				<template v-else>
					<template v-if="matchSchoolCodes.length > 1">
						{{ $t("config_select.school_code_mismatch_exclusive_multiple") }}
					</template>
					<template v-if="matchSchoolCodes.length == 1">
						{{ $t("config_select.school_code_mismatch_exclusive_single") }}
					</template>
				</template>
			</div>
		</template>

		<template slot="selected-option" slot-scope="option">
			<i
				v-if="matchSchoolCodes.length > 0 && schoolCodeMismatchWithInclusivity(option)"
				class="fas fa-exclamation-triangle text-danger mr-1"
				style="font-size: 14px"
			/>
			<span>{{ option.name }}</span>
			<a
				v-if="option.users"
				@click="expandTeam(option.id)"
				class="text-small text-muted mx-1"
				v-tippy
				:title="$t('tooltip.expand_to_individuals')"
			>
				{{ option.uniques }}
				<i class="far faw fa-users"></i>
				<i class="far faw fa-caret-right"></i>
			</a>
		</template>
	</v-select>
</template>

<style scoped></style>

<script>
import UserService from "@/services/UserService";
import TeamService from "@/services/TeamService";
import fs from "@/services/FormatService";

export default {
	name: "ConfigUserSearch",

	props: {
		clientID: true,
		value: true,
		placeholder: true,
		includeTeams: {
			type: Boolean,
			default: false,
		},
		supervisorOnly: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		showEmails: {
			type: Boolean,
			default: false,
		},
		matchSchoolCodes: {
			type: Array,
			default: () => {
				return [];
			},
		},
		hideUnmatchedSchoolCodes: {
			type: Boolean,
			default: false,
		},
		schoolCodeMatchInclusive: {
			type: Boolean,
			default: false,
		},
		disabled: true,
		filterSectionID: {
			type: String,
			default: "",
		},
		exclude: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},

	data() {
		return {
			fs: fs,
			users: [],
		};
	},

	created() {},

	watch: {
		value() {
			if (this.value && this.value.length) {
				let seenBefore = {};
				for (let i = 0; i < this.value.length; ) {
					if (this.value[i].users) {
						i++;
					} else {
						let entry = this.value[i];
						if (seenBefore[entry.id]) {
							this.value.splice(i, 1);
						} else {
							seenBefore[entry.id] = true;
							i++;
						}
					}
				}

				for (let i = 0; i < this.value.length; i++) {
					if (this.value[i].users) {
						this.value[i].uniques = 0;
						for (let j = 0; j < this.value[i].users.length; j++) {
							let entry = this.value[i].users[j];
							if (!seenBefore[entry.id]) {
								this.value[i].uniques++;
							}
						}
					}
				}
			}
		},
	},

	computed: {
		userOptions() {
			_.each(this.users, (o) => {
				if (o.users) {
					//This is a team listing
					_.each(o.users, (user) => {
						if (this.showEmails) {
							user.name = fs.nameWithEmail(user);
						} else {
							user.name = fs.nameWithScorerID(user);
						}
					});
				} else {
					if (this.showEmails) {
						o.name = fs.nameWithEmail(o);
					} else {
						o.name = fs.nameWithScorerID(o);
					}
				}
			});

			return this.users;
		},
	},

	methods: {
		searchUsers(search, loading) {
			loading(true);
			let userCtx = {
				perPage: this.includeTeams ? 5 : 8,
				currentPage: 1,
				active: true,
				filter: search,
				filterSectionID: this.filterSectionID,
			};
			if (this.supervisorOnly) {
				userCtx.supervisor = "y";
			}
			if (this.hideUnmatchedSchoolCodes && this.matchSchoolCodes && this.matchSchoolCodes.length > 0) {
				userCtx.school_codes = this.matchSchoolCodes;
				if (this.schoolCodeMatchInclusive) {
					userCtx.school_codes_inclusive = this.schoolCodeMatchInclusive;
				}
			}
			if (this.exclude && this.exclude.length > 0) {
				userCtx.exclude = this.exclude;
			}
			let searches = [UserService.listUsers(this.clientID, userCtx)];
			if (this.includeTeams && !this.supervisorOnly) {
				let teamCtx = {
					perPage: 5,
					currentPage: 1,
					filter: search,
					filterSectionID: this.filterSectionID,
				};
				searches.push(TeamService.listTeamsWithUsers(teamCtx));
			}

			Promise.all(searches)
				.then((resps) => {
					//resps[0] - users
					_.each(resps[0].data.users, (user) => {});
					this.users = resps[0].data.users;

					//resps[1] - teams (conditionally)
					if (this.includeTeams && !this.supervisorOnly) {
						let teams = resps[1].data.teams;
						if (this.users.length > 0 && teams.length > 0) {
							this.users = this.users.concat({ name: "" });
						}
						this.users = this.users.concat(teams);
					}

					loading(false);
				})
				.catch((err) => {
					console.log(err);
					this.users = [{ name: "Error loading users" }];
				});
		},

		expandTeam(teamID) {
			if (this.value && this.value.length) {
				for (let i = 0; i < this.value.length; ) {
					let entry = this.value[i];
					if (entry.users && entry.id == teamID) {
						this.value.splice(i, 1, ...entry.users);
					} else {
						i++;
					}
				}

				let seenBefore = {};
				for (let i = 0; i < this.value.length; ) {
					let entry = this.value[i];
					if (seenBefore[entry.id]) {
						this.value.splice(i, 1);
					} else {
						seenBefore[entry.id] = true;
						i++;
					}
				}
			}
		},

		emitUser(user) {
			if (!user) {
				this.users = [];
			}
			this.$emit("input", user);
		},

		schoolCodeMismatchWithInclusivity(user) {
			if (this.schoolCodeMatchInclusive) {
				return this.schoolCodeMismatch(user);
			} else {
				return !this.schoolCodeMismatch(user);
			}
		},

		schoolCodeMismatch(user) {
			if (!(this.matchSchoolCodes && this.matchSchoolCodes.length > 0)) return true;
			if (!(user.school_codes && user.school_codes.length > 0)) return true;

			for (let msc of this.matchSchoolCodes) {
				let found = false;
				for (let usc of user.school_codes) {
					if (msc == usc) {
						found = true;
						break;
					}
				}
				if (!found) return true;
			}
			return false;
		},
	},
};
</script>
