<template>
	<page customBody customNavBar>
		<template #navbar>
			<div class="navbar navbar-expand-lg b-b">
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					<span>{{ $t("Mail.title") }}</span>
					<span>
						<i class="far fa-fw fa-angle-right"></i>
						{{ boxName }}
					</span>
					<span v-if="tab == 'label'">
						<i class="far fa-fw fa-angle-right"></i>
						{{ pickedLabel || "Unknown" }}
					</span>
					<span v-if="reloading">
						<i class="far fa-fw fa-angle-right"></i>
						<loading type="inline-header" />
					</span>
				</div>

				<!-- Search field -->
				<div v-if="tab != 'mail'">
					<div class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button type="button" class="btn no-border no-bg no-shadow" disabled>
								<i class="fa fa-search"></i>
							</button>
						</span>
						<input type="text" v-model="filter" class="form-control" :placeholder="searchPlaceholder" />
					</div>
				</div>
			</div>
		</template>

		<div class="d-flex flex" v-if="!loading">
			<div class="fade aside aside-sm" id="content-aside">
				<div class="modal-dialog d-flex flex-column w-md light lt b-r white" id="mail-nav">
					<div class="scrollable hover">
						<div class="m-4" v-if="user.role.mail_send_all || user.role.mail_send_supervisor">
							<button
								@click="compose()"
								class="btn btn-sm theme-accent btn-block"
								v-tippy
								:title="$t('tooltip.compose_new_mail')"
							>
								<i class="far fa-fw fa-plus"></i>
								<span class="hidden-folded d-inline">{{ $t("Mail.compose") }}</span>
							</button>
						</div>
						<div class="sidenav my-3">
							<nav class="nav-border b-theme" data-nav>
								<ul class="nav">
									<li :class="{ active: tab == 'inbox' }">
										<a @click="pickTab('inbox')">
											<span v-if="mailCounts" class="nav-badge">
												<b
													v-if="mailCounts.inbox_important > 0"
													class="badge badge-pill danger"
													>{{ mailCounts.inbox_important }}</b
												>
												<b
													v-if="
														mailCounts.inbox_important == 0 && mailCounts.inbox_unread > 0
													"
													class="badge badge-pill success"
													>{{ mailCounts.inbox_unread }}</b
												>
												<b
													v-if="mailCounts.inbox_unread == 0 && mailCounts.inbox > 0"
													class="badge badge-pill"
													>{{ mailCounts.inbox }}</b
												>
											</span>
											<span class="nav-icon">
												<i class="far fa-inbox"></i>
											</span>
											<span class="nav-text name">{{ $t("Mail.inbox") }}</span>
										</a>
									</li>
									<li :class="{ active: tab == 'sent' }">
										<a @click="pickTab('sent')">
											<span v-if="mailCounts" class="nav-badge">
												<b v-if="mailCounts.sent > 0" class="badge badge-pill">{{
													mailCounts.sent
												}}</b>
											</span>
											<span class="nav-icon">
												<i class="far fa-paper-plane"></i>
											</span>
											<span class="nav-text name">{{ $t("Mail.sent") }}</span>
										</a>
									</li>
									<li :class="{ active: tab == 'drafts' }">
										<a @click="pickTab('drafts')">
											<span v-if="mailCounts" class="nav-badge">
												<b v-if="mailCounts.drafts > 0" class="badge badge-pill">{{
													mailCounts.drafts
												}}</b>
											</span>
											<span class="nav-icon">
												<i class="far fa-pencil-alt"></i>
											</span>
											<span class="nav-text name">{{ $t("Mail.drafts") }}</span>
										</a>
									</li>
									<li :class="{ active: tab == 'archived' }">
										<a @click="pickTab('archived')">
											<span v-if="mailCounts" class="nav-badge">
												<b v-if="mailCounts.archived > 0" class="badge badge-pill">{{
													mailCounts.archived
												}}</b>
											</span>
											<span class="nav-icon">
												<i class="far fa-archive"></i>
											</span>
											<span class="nav-text name">{{ $t("Mail.archive") }}</span>
										</a>
									</li>
									<li class="nav-header">
										<div class="b-t mt-3 pt-3 pb-2 text-muted">
											{{ $t("Mail.labels") }}
										</div>
									</li>
									<li
										v-for="label in labels"
										:key="label"
										:class="{ active: tab == 'label' && pickedLabel == label }"
										class="d-flex flex-row"
									>
										<a @click="pickLabel(label)" class="link flex label-left">
											<span class="nav-text name label-left-text">{{ label }}</span>
										</a>
										<span v-if="mailCounts" class="no-grow label-right">
											<b
												v-if="getLabelCount(label) > 0"
												class="text-muted px-0 badge badge-pill"
												>{{ getLabelCount(label) }}</b
											>
											<a
												@click="deleteLabel(label)"
												class="text-muted d-inline-block"
												v-tippy
												:title="$t('tooltip.delete')"
											>
												<span class="nav-text">
													<i class="far fa-fw fa-trash"></i>
												</span>
											</a>
										</span>
									</li>
								</ul>
							</nav>
						</div>
					</div>
					<div class="p-2-3 mt-auto">
						<div class="input-group">
							<input
								type="text"
								class="form-control form-control-sm"
								:placeholder="$t('Mail.new_label_placeholder')"
								v-model="newLabel"
							/>
							<span class="input-group-btn">
								<button @click="addLabel" class="btn btn-default btn-sm no-shadow" type="button">
									<i class="fa fa-plus"></i>
								</button>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="d-flex flex-column flex pos-rlt">
				<div class="flex">
					<div class="panels" :class="{ 'show-compose': composing }">
						<!-- List mails -->
						<div v-if="tab != 'mail'" class="content-panel trans d-flex flex-column scroll-y">
							<div v-for="mail in mails" :key="mail.id" class="list animate fadeIn">
								<mail-row
									:mail="mail"
									:rowClick="pickMail"
									:sent="tab == 'sent' || tab == 'drafts'"
									:canDelete="tab == 'drafts'"
									:markImportant="tab == 'inbox' && isImportant(mail)"
									:markUnread="tab == 'inbox' && isUnread(mail)"
									:labels="getUserLabels(mail)"
									@change="mailRowMadeChange"
								/>
							</div>

							<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
								<a class="flex mx-3"
									>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }}
									{{ $t("pagination.of") }} {{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
								>
								<div>
									<b-pagination
										size="md"
										class="m-1"
										:total-rows="totalRows"
										v-model="currentPage"
										:per-page="perPage"
									></b-pagination>
								</div>
							</div>
						</div>

						<!-- View whole mail -->
						<div v-if="tab == 'mail'" class="content-panel trans">
							<mail-view
								:mail="showMail"
								:sent="prevTab == 'sent' || prevTab == 'drafts'"
								:padWide="!composing"
								:labels="labels"
								:currentUser="user"
								@change="mailViewMadeChange"
								@exit="mailViewExit"
								@reply="mailViewReply"
							/>
						</div>

						<!-- Compose new mail sidebar -->
						<div class="compose-panel trans b-l p-3 scroll-y">
							<div>
								<div class="form-group">
									<label v-if="!toMultipleUsers()">{{ $t("Mail.to_user") }}</label>
									<label v-if="toMultipleUsers()">{{ $t("Mail.to_users") }}</label>
									<span v-if="user.role.send_external_email" class="checkbox float-right">
										<label class="md-check" v-tippy :title="$t('tooltip.mail_to_external_email')">
											<input v-model="compMail.to_external" type="checkbox" />
											<i class="theme-accent"></i> {{ $t("Mail.external_email") }}
										</label>
									</span>
									<config-user-search
										:clientID="user.client && user.client.id"
										v-model="compMail.toUsers"
										includeTeams
										:supervisorOnly="sendToSupervisorOnly()"
										:disabled="!canChangeTo()"
										:showEmails="compMail.to_external"
										multiple
									/>
								</div>
								<div class="form-group">
									<label>{{ $t("Mail.subject") }}</label>
									<input v-model="compMail.subject" class="form-control" />
								</div>
								<div class="form-group">
									<label>{{ $t("Mail.body") }}</label>
									<span
										v-if="user.role.mail_mark_important && !compMail.to_external"
										class="checkbox float-right"
									>
										<label class="md-check" v-tippy :title="$t('tooltip.mail_cannot_score')">
											<input v-model="compMail.important" type="checkbox" />
											<i class="theme-accent"></i> {{ $t("Mail.important") }}
										</label>
									</span>
									<RichText
										:text.sync="compMail.body"
										:initial="editorInit"
										:refresh="editorRefresh"
									></RichText>
								</div>
							</div>

							<button class="btn btn-primary" @click="closeCompose()">
								{{ $t("buttons.cancel") }}
							</button>
							<button class="btn theme float-right" @click="sendMail()">
								{{ $t("buttons.send") }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<style scoped>
.w-md {
	width: 12rem;
}
.trans {
	-webkit-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1);

	-webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.panels {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
}
.content-panel {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
}
.show-compose > .content-panel {
	right: 50%;
}
.compose-panel {
	position: absolute;
	right: -25%;
	width: 25%;
	top: 0;
	bottom: 0;
}
.show-compose > .compose-panel {
	right: 0;
	width: 50%;
}
.label-left {
	padding-right: 0 !important;
}
.label-left-text {
	overflow: hidden;
	text-overflow: ellipsis;
}
.label-right {
	padding-right: 1.25rem;
}
</style>
<script>
import MailService from "@/services/MailService";
import notie from "@/services/NotieService";
import moment from "moment";
import store from "@/services/Store";

import RichText from "@/components/RichText";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import MailRow from "@/components/mail/MailRow";
import MailView from "@/components/mail/MailView";

export default {
	name: "Mail",

	props: ["user", "params", "query"],

	components: { RichText, ConfigUserSearch, MailRow, MailView },

	data() {
		return {
			tab: "inbox",
			prevTab: null,
			labels: null,
			newLabel: null,
			pickedLabel: null,
			perPage: 10,
			currentPage: 1,
			totalRows: 0,
			filter: null,
			composing: false,
			compBody: null,
			compMail: {
				toUsers: null,
				subject: null,
				body: null,
			},
			editorRefresh: null,
			editorInit: null,
			showMail: null,

			enterMail: null,

			mailCounts: null,
			mails: null,

			loading: true,
			reloading: true,
			refreshing: false,
			clearFilter: false,
		};
	},

	created() {
		this.enterMail = store.get(this, "mail.showMail");
		this.pickTab("inbox");
		this.searchDebounce = _.debounce(this.getMails, 500);

		let composeTo = store.get(this, "mail.composeTo");
		if (composeTo) {
			store.set(this, "mail.composeTo", null);
			this.compMail = {
				toUsers: [composeTo],
				subject: null,
				body: null,
			};
			this.compose();
		}
	},

	computed: {
		boxName() {
			switch (this.tab) {
				case "inbox":
					return this.$i18n.t("Mail.inbox");
					break;
				case "sent":
					return this.$i18n.t("Mail.sent");
					break;
				case "drafts":
					return this.$i18n.t("Mail.drafts");
					break;
				case "archived":
					return this.$i18n.t("Mail.archive");
					break;
				case "trash":
					return this.$i18n.t("Mail.trash");
					break;
				case "label":
					return this.$i18n.t("Mail.labels");
				case "mail":
					let fn = this.showMail.from.first_name;
					let ln = this.showMail.from.last_name;
					return `From ${fn} ${ln}`;
				default:
					return this.$i18n.t("Mail.unknown");
			}
		},

		searchPlaceholder() {
			switch (this.tab) {
				case "inbox":
					return this.$i18n.t("Mail.inbox_placeholder");
					break;
				case "sent":
					return this.$i18n.t("Mail.sent_placeholder");
					break;
				case "drafts":
					return this.$i18n.t("Mail.drafts_placeholder");
					break;
				case "archived":
					return this.$i18n.t("Mail.archived_placeholder");
					break;
				case "trash":
					return this.$i18n.t("Mail.trash_placeholder");
					break;
				case "label":
					return this.$i18n.t("Mail.label_placeholder");
				default:
					return this.$i18n.t("Mail.default_placeholder");
			}
		},

		toUsers() {
			let seenBefore = {};
			let users = [];
			if (this.compMail) {
				_.each(this.compMail.toUsers, (val) => {
					if (val.users) {
						let team = val;
						_.each(team.users, (user) => {
							if (!seenBefore[user.id]) {
								seenBefore[user.id] = true;
								users.push(user);
							}
						});
					} else {
						let user = val;
						if (!seenBefore[user.id]) {
							seenBefore[user.id] = true;
							users.push(user);
						}
					}
				});
			}

			return users;
		},
	},

	watch: {
		"compMail.toUsers"() {
			if (this.compMail && this.compMail.id) {
				this.triggerDraftAutosave();
			}
		},
		"compMail.subject"() {
			if (this.compMail && this.compMail.id) {
				this.triggerDraftAutosave();
			}
		},
		"compMail.body"() {
			if (this.compMail && this.compMail.id) {
				this.triggerDraftAutosave();
			}
		},
		"compMail.important"() {
			if (this.compMail && this.compMail.id) {
				this.triggerDraftAutosave();
			}
		},
		currentPage() {
			this.getMails();
		},
		perPage() {
			this.getMails();
		},
		filter() {
			if (!this.refreshing) {
				this.searchDebounce();
			}
		},
	},

	methods: {
		getMails(refresh) {
			if (this.refreshing) {
				return;
			}

			if (!refresh) {
				this.reloading = true;
				this.mails = null;
			}
			this.refreshing = true;

			if (this.clearFilter) {
				this.filter = null;
				this.clearFilter = false;
			}

			let ctx = {
				perPage: this.perPage,
				currentPage: this.currentPage,
				filter: this.filter,
			};
			if (this.tab == "label") {
				ctx.label = this.pickedLabel;
			}
			return Promise.all([MailService.getCounts(), MailService.listMail(this.tab, ctx)])
				.then((resps) => {
					//resps[0] - getCounts
					this.mailCounts = resps[0].data;
					this.labels = [];
					_.each(this.mailCounts.labels, (labelDef) => {
						this.labels.push(labelDef.name);
					});

					//resps[1] - listMail
					this.mails = resps[1].data.mails;
					this.totalRows = resps[1].data.totalRows;

					if (this.enterMail) {
						//Try to show a specific mail on page load, if it can be found
						let findEnterMail = _.find(this.mails, {
							id: this.enterMail,
						});
						if (findEnterMail) {
							this.enterMail = null;
							store.set(this, "mail.showMail", null);
							this.pickMail(findEnterMail);
						}
					}
					this.loading = false;
					this.reloading = false;
					this.refreshing = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.get_mail_fail"), err);
					this.loading = false;
					this.reloading = false;
					this.refreshing = false;
				});
		},

		pickTab(box) {
			let refresh = this.tab == box;
			if (!refresh) {
				this.clearFilter = true;
			}
			this.tab = box;
			this.getMails(refresh);
		},

		pickLabel(label) {
			this.tab = "label";
			this.pickedLabel = label;
			this.getMails();
		},

		pickMail(mail) {
			if (this.tab == "drafts") {
				this.loadCompMail(mail);
				this.composing = true;
			} else {
				if (this.tab != "mail") {
					this.prevTab = this.tab;
					this.tab = "mail";
				}
				this.showMail = mail;
			}
		},

		sendToSupervisorOnly() {
			return !this.user.role.mail_send_all && this.user.role.mail_send_supervisor;
		},

		canChangeTo() {
			return this.user.role.mail_send_all || this.user.role.mail_send_supervisor;
		},

		canCompose() {
			return this.user.role.mail_send_all || this.user.role.mail_send_supervisor;
		},

		compose() {
			if (!this.composing) {
				this.composing = true;
				return MailService.saveDraft(this.compMail, this.toUsers)
					.then((resp) => {
						this.compMail.id = resp.data.id;
						this.bindDraftAutosave();
						console.log("Draft got an ID", this.compMail.id);
					})
					.catch((err) => {
						console.error("Failed to get an ID for first draft");
						console.error(err);
					});
			}
		},

		closeCompose() {
			let cleanup = () => {
				this.composing = false;
				this.unbindDraftAutosave();
				this.compMail = {
					toUsers: null,
					subject: null,
					body: null,
				};
				this.editorInit = null;
				this.editorRefresh = new Date();
				this.getMails(true);
			};

			let promise = this.doDraftAutosave();
			if (promise) {
				promise.then(cleanup).catch(cleanup);
			} else {
				cleanup();
			}
		},

		loadCompMail(mail) {
			mail.toUsers = [];
			_.each(mail.to, (userMail) => {
				userMail.user.name = this.getName(userMail.user);
				mail.toUsers.push(userMail.user);
			});
			this.bindDraftAutosave();
			this.compMail = _.cloneDeep(mail);
			this.editorInit = this.compMail.body;
		},

		loadReplyMail(mail) {
			let toUser = _.cloneDeep(mail.from);
			toUser.name = this.getName(toUser);
			this.compMail = {
				toUsers: [toUser],
			};

			if (mail.subject.startsWith("Re:")) {
				this.compMail.subject = mail.subject;
			} else {
				this.compMail.subject = "Re: " + mail.subject;
			}
			this.compMail.reply_to = mail.id;

			this.editorInit =
				`<p><br /></p><div><hr class="my-4"></hr><p class="text-muted">${this.$i18n.t("Mail.from")} ${
					toUser.name
				} ${this.$i18n.t("Mail.on")} ${this.getExactSentTime(mail.updated_at)}</p></div>` + mail.body;
		},

		toMultipleUsers() {
			return (
				this.compMail &&
				this.compMail.toUsers &&
				this.compMail.toUsers.length &&
				this.compMail.toUsers.length > 1
			);
		},

		sendMail() {
			this.sending = true;
			return MailService.sendMail(this.compMail, this.toUsers)
				.then((resp) => {
					notie.success(this.$i18n.t("notie.mail_sent"));
					this.compMail = {
						toUsers: null,
						subject: null,
						body: null,
					};
					this.editorInit = null;
					this.closeCompose();
					this.sending = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.send_mail_fail"), err);
					this.sending = false;
				});
		},

		mailViewExit() {
			this.pickTab(this.prevTab || "inbox");
		},

		mailViewReply(mail) {
			this.loadReplyMail(mail);
			this.compose();
		},

		mailViewMadeChange(newMail) {
			this.showMail = newMail;
			this.getMails(true);
		},

		mailRowMadeChange() {
			this.getMails(true);
		},

		addLabel() {
			MailService.addLabel(this.newLabel)
				.then((resp) => {
					this.newLabel = null;
					this.getMails(true);
				})
				.catch((err) => {
					console.error(err);
					notie.error(this.$i18n.t("notie.add_label_fail"), err);
				});
		},

		deleteLabel(label) {
			MailService.deleteLabel(label)
				.then((resp) => {
					this.getMails(true);
				})
				.catch((err) => {
					console.error(err);
					notie.error(this.$i18n.t("notie.delete_label_fail"), err);
				});
		},

		isImportant(mail) {
			return mail.important;
		},

		isUnread(mail) {
			let to = _.find(mail.to, (to) => {
				return to.user.id == this.user.id;
			});
			return to && !to.viewed;
		},

		getSentTime(date) {
			return moment(date).fromNow();
		},

		getExactSentTime(date) {
			return moment(date).format("LT LL");
		},

		bindDraftAutosave() {
			this.triggerDraftAutosave = _.debounce(this.doDraftAutosave, 5000);
		},

		unbindDraftAutosave() {
			this.triggerDraftAutosave = () => {
				console.log("Autosave has been unbound");
			};
		},

		doDraftAutosave() {
			if (this.compMail.id) {
				console.log("Do draft save");
				return MailService.saveDraft(this.compMail, this.toUsers)
					.then((resp) => {
						console.log("Draft of currently-composing mail auto-saved");
					})
					.catch((err) => {
						console.error("Failed to save draft");
						console.error(err);
					});
			} else {
				console.log("Didn't autosave draft because it had no ID");
			}
		},

		triggerDraftAutosave() {
			console.log("Autosave not yet initialized for the current draft");
		},

		getLabelCount(label) {
			let count = 0;
			_.each(this.mailCounts.labels, (lc) => {
				if (lc.name == label) {
					count = lc.count;
				}
			});

			return count;
		},

		getNames(users) {
			if (users.first_name || users.last_name) {
				//Just a single user
				return this.getName(users);
			} else {
				let names = [];
				_.each(users, (user) => {
					if (user.first_name || user.last_name) {
						//Actual user object
						names.push(this.getName(user));
					} else {
						//UserMail object
						if (user.user && (user.user.first_name || user.user.last_name)) {
							//Yes, UserMail object
							names.push(this.getName(user.user));
						} else {
							//No, we don't know what this is!
							names.push(this.$i18n.t("Mail.unknown_caps"));
						}
					}
				});

				return names.join(", ");
			}
		},

		getName(user) {
			let name = "";
			if (user.first_name) {
				name = user.first_name;
				if (user.last_name) {
					name = name + " " + user.last_name;
				}
			} else {
				name = user.last_name;
			}
			return name;
		},

		getUserLabels(mail) {
			let labels = [];
			_.each(mail.to, (to) => {
				if (to.user.id == this.user.id) {
					labels = to.labels;
				}
			});

			return labels;
		},
	},
};
</script>
