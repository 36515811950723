<template>
	<div class="card p-3 mx-3 mb-3" :class="{ clickable: !expanded }" @click="expanded = true">
		<h3 class="text-center clickable" :class="{ 'mb-0': !expanded }" @click.stop="expanded = !expanded">
			Test S3 Bucket/Download
		</h3>
		<h3 class="expand-button clickable mb-0" @click.stop="expanded = !expanded">
			<i class="fas fa-caret-left anim-rotate no-select" :class="{ 'rotate-90': expanded }" />
		</h3>
		<div v-if="expanded" class="row">
			<div class="col-12 d-flex flex-row align-items-end">
				<div class="form-group mr-3">
					<label>S3 Bucket Name</label>
					<input class="form-control" type="text" v-model="bucketName" />
				</div>
				<div class="form-group">
					<button v-if="bucketRunning" class="btn btn-secondary" disabled>
						<loading type="icon" class="mr-1" />Running...
					</button>
					<button v-else-if="bucketSuccess" class="btn btn-success" @click="testBucket">
						<i class="fas fa-check mr-1" />Access
					</button>
					<button v-else-if="bucketFailure" class="btn btn-danger" @click="testBucket">
						<i class="fas fa-times mr-1" />No Access
					</button>
					<button v-else class="btn btn-primary" @click="testBucket" :disabled="!bucketName">
						Test Access
					</button>
				</div>
			</div>
			<div class="col-12 d-flex flex-row align-items-end">
				<div class="form-group mr-3">
					<label>File Path</label>
					<input class="form-control" type="text" v-model="filePath" />
				</div>
				<div class="form-group">
					<button v-if="fileRunning" class="btn btn-secondary" disabled>
						<loading type="icon" class="mr-1" />Downloading...
					</button>
					<button v-else-if="fileSuccess" class="btn btn-success" @click="testDownload">
						<i class="fas fa-check mr-1" />Downloaded
					</button>
					<button v-else-if="fileFailure" class="btn btn-danger" @click="testDownload">
						<i class="fas fa-times mr-1" />Failed
					</button>
					<button v-else class="btn btn-primary" @click="testDownload" :disabled="!(bucketName && filePath)">
						Download
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.clickable {
	cursor: pointer;
}
.expand-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.result-panel {
	background-color: #f7f7f7;
	border-radius: 10px;
	border: 1px solid #f0f0f0;
}
</style>

<script>
import FileService from "@/services/FileService";

import axios from "axios";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";

export default {
	name: "SetupPerfTest",
	props: ["environment", "clients"],
	components: {},

	data() {
		return {
			expanded: Store.get(this, "adminTasks.testBucket.expanded"),

			bucketName: Store.getDefault(this, "adminTasks.testBucket.bucketName", ""),
			filePath: Store.getDefault(this, "adminTasks.testBucket.filePath", ""),

			bucketRunning: false,
			bucketSuccess: false,
			bucketFailure: false,

			fileRunning: false,
			fileSuccess: false,
			fileFailure: false,
		};
	},

	created() {},

	mounted() {},

	watch: {
		expanded() {
			Store.set(this, "adminTasks.testBucket.expanded", this.expanded);
		},
		bucketName() {
			Store.set(this, "adminTasks.testBucket.bucketName", this.bucketName);
		},
		filePath() {
			Store.set(this, "adminTasks.testBucket.filePath", this.filePath);
		},
	},

	computed: {},

	methods: {
		testBucket() {
			this.bucketRunning = true;

			let ctx = {
				bucketName: this.bucketName,
			};

			let config = {};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			axios
				.post("/v1/admin_tasks/test_bucket", ctx, config)
				.then((r) => {
					this.bucketRunning = false;
					this.bucketSuccess = true;
					setTimeout(() => {
						this.bucketSuccess = false;
						this.$forceUpdate();
					}, 3000);
				})
				.catch((e) => {
					this.bucketRunning = false;
					this.bucketFailure = true;
					setTimeout(() => {
						this.bucketFailure = false;
						this.$forceUpdate();
					}, 3000);
					console.error(e);
					Notie.error("Bucket access failed", e);
				});
		},

		testDownload() {
			this.fileRunning = true;

			let ctx = {
				bucketName: this.bucketName,
				filePath: this.filePath,
			};

			let config = {
				responseType: "blob",
			};
			if (this.environment) {
				config.headers = {
					"X-Mongo-Connection": this.environment.connection,
					"X-Mongo-Database": this.environment.db,
				};
			}

			console.log("SEND TESTDOWNLOAD");
			axios
				.post("/v1/admin_tasks/test_download", ctx, config)
				.then((r) => {
					console.log("RETURNED", r);

					this.fileRunning = false;
					this.fileSuccess = true;
					setTimeout(() => {
						this.fileSuccess = false;
						this.$forceUpdate();
					}, 3000);

					console.log("CONTENT TYPE", r.headers["content-type"]);

					let parts = this.filePath.split("/");
					let filename = parts[parts.length - 1];
					FileService.downloadFile(filename, r.data, r.headers["content-type"]);
				})
				.catch((e) => {
					console.log("ERROR", e);

					this.fileRunning = false;
					this.fileFailure = true;
					setTimeout(() => {
						this.fileFailure = false;
						this.$forceUpdate();
					}, 3000);
					console.error(e);
					Notie.error("File download access failed", e);
				});
		},
	},
};
</script>
