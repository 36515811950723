<template>
	<div class="d-flex flex-column align-items-center flex h100 page-body">
		<div class="scp-header">
			<h4 v-if="set && set.qc_type == 5" class="mb-0 ml-3">{{ $t("QCSetDocumentPage.rf_set_document_view") }}</h4>
			<h4 v-else class="mb-0 ml-3">{{ $t("QCSetDocumentPage.qc_set_document_view") }}</h4>
			<div v-if="!unauth" class="ml-auto d-flex flex-row">
				<div v-if="externalLinkURL">
					<input
						ref="linkInput"
						class="form-control condensed-input mr-2"
						v-model="externalLinkURL"
						readOnly
						style="width: 290px"
					/>
					<div class="sub-text">
						<span>{{ $t("QCSetDocumentPage.external_link") }}</span>
						•
						<span>{{ createLinkExpires.hours }} {{ createLinkExpires.hours == 1 ? "hour" : "hours" }}</span>
						•
						<span
							>{{ createLinkAccessLimit.uses }}
							{{ createLinkAccessLimit.uses == 1 ? "use" : "uses" }}</span
						>
					</div>
				</div>
				<button v-if="externalLinkURL" type="button" class="btn btn-primary mr-2" @click="copyExternalLink">
					{{ externalLinkCopied ? "Copied!" : "Copy" }}
				</button>
				<button
					v-if="!externalLinkURL"
					type="button"
					class="btn btn-primary mr-2"
					v-tippy
					title="External Link"
					data-toggle="modal"
					data-target="#createLinkModal"
				>
					<i class="fas fa-link fa-lg" />
				</button>
				<button type="button" class="btn btn-success mr-1" v-tippy title="Print" @click="printPage">
					<i class="fas fa-print fa-lg" />
				</button>
				<div
					class="exit-button b-l d-flex flex-column justify-content-center"
					@click="goBack()"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.exit')"
				>
					<!-- Exit -->
					<i class="fas fa-lg fa-times" />
				</div>
			</div>
		</div>

		<qc-set-document v-if="set && item" :set="set" :item="item" :section="section" class="scroll-x scroll-y" />

		<div class="modal" id="createLinkModal">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("ExternalLink.create_link") }}</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 form-group">
								<div>{{ $t("ExternalLink.create_link_explanation") }}</div>
							</div>
							<div class="col-12 form-group">
								<label>{{ $t("ExternalLink.duration") }}</label>
								<v-select :options="expiresOptions" v-model="createLinkExpires" />
							</div>
							<div class="col-12">
								<label>{{ $t("ExternalLink.access_limit") }}</label>
								<v-select class="" :options="accessLimitOptions" v-model="createLinkAccessLimit" />
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button v-if="!creatingLink" class="btn btn-primary" @click="createExternalLink">
							{{ $t("ExternalLink.generate_new_link") }}
						</button>
						<button v-else class="btn btn-primary" disabled>
							<loading type="icon" class="mr-1" /> {{ $t("ExternalLink.generate_new_link") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.exit-button {
	margin-top: -8px;
	margin-bottom: -8px;
	margin-right: -8px;
	margin-left: 8px;
	padding-right: 2px;
	width: 52px;
	text-align: center;
	cursor: pointer;
}
.exit-button:hover,
.exit-button.show-print {
	background-color: rgba(0, 0, 0, 0.075);
}
.exit-button a {
	line-height: 64px;
}

.scp-header {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #eeeeee;
	border: 1px solid #dddddd;
	padding: 0.5rem;
	z-index: 2;
}

.condensed-input {
	margin-top: -2px;
	padding: 0.1rem 0.75rem;
}

.sub-text {
	font-size: 0.6rem;
	line-height: 1rem;
	margin-bottom: -6px;
	margin-left: 12px;
}

@media print {
	#exemplar-container {
		height: 100%;
	}
}

@page {
	size: 8.5in 11in;
}
</style>

<script>
import QcSetDocument from "@/components/QCSetDocument";
import QCService from "@/services/QCService";
import QCConfigService from "@/services/QCConfigService";
import ItemService from "@/services/ItemService";
import SectionService from "@/services/SectionService";
import ExternalLinkService from "@/services/ExternalLinkService";
import Notie from "@/services/NotieService";
import BB from "bluebird";
import Utils from "@/services/Utils";
import $ from "jquery";

var expiresOptions = [
	{
		hours: 0,
		label: "No expiration",
	},
	{
		hours: 1,
		label: "1 hour",
	},
	{
		hours: 4,
		label: "4 hours",
	},
	{
		hours: 12,
		label: "12 hours",
	},
	{
		hours: 24,
		label: "1 day",
	},
	{
		hours: 48,
		label: "2 days",
	},
	{
		hours: 168,
		label: "1 week",
	},
];

var accessLimitOptions = [
	{
		uses: 0,
		label: "No limit",
	},
	{
		uses: 1,
		label: "1 use",
	},
	{
		uses: 5,
		label: "5 uses",
	},
	{
		uses: 10,
		label: "10 uses",
	},
	{
		uses: 50,
		label: "50 uses",
	},
	{
		uses: 100,
		label: "100 uses",
	},
];

export default {
	name: "QCSetDocumentPage",
	data() {
		return {
			expiresOptions: expiresOptions,
			accessLimitOptions: accessLimitOptions,
			createLinkExpires: expiresOptions[1],
			createLinkAccessLimit: accessLimitOptions[1],
			externalLinkURL: null,
			externalLinkCopied: false,
			creatingLink: false,

			set: null,
			item: null,
			section: null,
			responses: null,
			numPages: 0,
			currentPage: 1,
			unauth: false,
		};
	},

	components: { QcSetDocument },

	props: ["user", "params"],

	watch: {},

	computed: {},

	created() {
		this.init();
	},
	methods: {
		init() {
			if (this.params.link_id) {
				ExternalLinkService.getExternalLinkData(this.params.link_id)
					.then((r) => {
						let meta = r.data.server_data;
						console.log("LOAD", r, meta);

						this.item = meta.item;
						this.section = meta.section;
						this.set = meta.set;
						this.set.responses = _.sortBy(meta.responses, "sequence");
						Utils.throttleMathJAX();
					})
					.catch((e) => {
						console.log(e);
						Notie.error("Failed to load qc set", e);
					});

				this.unauth = true;
			} else {
				BB.props({
					item: ItemService.getItem(this.params.item_id),
					section: SectionService.getSection(this.params.section_id),
					set: QCService.getQCSet(this.params.set_id),
					responses: QCConfigService.listSetResponses(this.params.set_id),
				})
					.then((resps) => {
						this.item = resps.item.data;
						this.section = resps.section.data;
						this.set = resps.set.data;
						this.set.responses = _.sortBy(resps.responses.data, "sequence");
						Utils.throttleMathJAX();
					})
					.catch((err) => {
						console.log(err);
						Notie.error("Failed to load qc set", err);
					});
			}
		},

		printPage() {
			let id = `exemplar-container`;
			let el = document.getElementById(id);
			if (!el) {
				console.log("Could not find element", id);
				Notie.error("Failed to print page", "Page not found");
				return;
			}
			Utils.printElement(el);
		},

		createExternalLink() {
			let meta = {
				section_id: this.params.section_id,
				item_id: this.params.item_id,
				set_id: this.params.set_id,
			};

			this.creatingLink = true;
			ExternalLinkService.createExternalLink(
				"QCSetDocument",
				meta,
				this.createLinkAccessLimit.uses,
				this.createLinkExpires.hours
			)
				.then((r) => {
					this.creatingLink = false;
					this.externalLinkURL = window.location.host + "/#/ex/" + r.data;
					$("#createLinkModal").modal("hide");
				})
				.catch((e) => {
					this.creatingLink = false;
					console.error(e);
					Notie.error("Failed to create link", e);
				});
		},

		linkFromID(linkID) {
			return `${window.location.host}/#/ex/${linkID}`;
		},

		copyExternalLink() {
			// this.$refs.linkInput.focus();
			this.$refs.linkInput.select();
  			this.$refs.linkInput.setSelectionRange(0, 99999);
			navigator.clipboard.writeText(this.$refs.linkInput.value);
			setTimeout(() => {
				this.$refs.linkInput.selectionStart = this.$refs.linkInput.selectionEnd;
				this.externalLinkCopied = true;
			}, 200);
		},

		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>


