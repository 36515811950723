<!-- Template for a new page -->
<template>
	<div ref="container">
		<div ref="videoContainer" class="video-feed-inner"></div>

		<div v-if="participant.videoLoading" class="loading-overlay">
			<loading type="page" />
		</div>
		<div v-else-if="!participant.video" class="avatar-overlay">
			<div class="meeting-avatar">
				<h1 class="mb-0">{{ initials }}</h1>
			</div>
			<div class="mt-1 d-flex flex-column align-items-center">
				<div v-if="participant.isContent" style="margin-bottom: -2px">
					<i class="far fa-presentation mr-1" style="font-size: 0.8rem" />Screenshare
				</div>
				<div v-if="!participant.isContent">
					<i v-if="participant.muted && !participant.isContent" class="text-muted far fa-volume-slash" />
					{{ name }}
				</div>
			</div>
			<div v-if="!participant.muted" class="mt-1 volume-bar-container">
				<div class="volume-bar">
					<div class="volume-bar-tick" />
					<i class="volume-icon-overlay far fa-volume mr-1" />
					<div class="volume-bar-progress" :style="{ width: `${Math.ceil(participant.volumeLevel)}%` }"></div>
				</div>
			</div>
		</div>
		<div v-if="participant.handUp" class="hand-overlay hand-wave">👋</div>
		<div v-if="participant.video || participant.videoLoading" class="details-overlay">
			<div v-if="participant.isContent" style="margin-bottom: -2px">
				<i class="far fa-presentation mr-1" style="font-size: 0.8rem" />Screenshare
			</div>
			<div v-if="!participant.isContent">
				{{ name }}
			</div>
			<div v-if="!participant.muted" class="volume-bar-container">
				<div class="volume-bar">
					<div class="volume-bar-tick" />
					<i class="volume-icon-overlay far fa-volume mr-1" />
					<div class="volume-bar-progress" :style="{ width: `${Math.ceil(participant.volumeLevel)}%` }"></div>
				</div>
			</div>
			<div class="text-xxs" style="margin-bottom: -4px">
				<i v-if="participant.muted && !participant.isContent" class="far fa-volume-slash text-muted mr-1" />
				<span v-if="participant.isContent">{{ name }} | </span
				><span class="text-muted">{{ videoWidth }} x {{ videoHeight }}</span>
			</div>
		</div>
		<div class="volume-ring" :class="{ active: speaking }"></div>
	</div>
</template>

<style scoped>
.loading-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.volume-ring {
	position: absolute;
	top: 4px;
	bottom: 4px;
	left: 4px;
	right: 4px;
	border-radius: 10px;
	opacity: 0;
	border: 4px solid #0c9dfd;
	transition: opacity 0.25s;
}
.volume-ring.active {
	opacity: 0.75;
}

.volume-bar {
	border: 1px solid white;
}
.volume-bar-progress {
	background-color: white;
}
.volume-bar-tick {
	border-left: 1px solid white;
}

.avatar-overlay {
	position: absolute;
	top: 4px;
	bottom: 4px;
	left: 4px;
	right: 4px;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: rgba(255 255 255 / 75%);
}

.details-overlay {
	position: absolute;
	bottom: 12px;
	right: 12px;
	color: white;
	text-align: right;
	text-shadow: 1px 1px 2px rgba(0 0 0 / 50%), 0 0 0.5em rgba(0 0 0 / 50%), 0 0 1em rgba(0 0 0 / 100%),
		0 0 0.2em rgba(0 0 0 / 100%);
}

.hand-overlay {
	position: absolute;
	top: 0;
	left: 12px;
	font-size: 40px;
}

/* From Jake Jarvis https://jarv.is/notes/css-waving-hand-emoji/ */
.hand-wave {
	animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
	animation-duration: 2.5s; /* Change to speed up or slow down */
	animation-iteration-count: infinite; /* Never stop waving :) */
	transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes wave-animation {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: rotate(14deg);
	} /* The following five values can be played with to make the waving more or less extreme */
	20% {
		transform: rotate(-8deg);
	}
	30% {
		transform: rotate(14deg);
	}
	40% {
		transform: rotate(-4deg);
	}
	50% {
		transform: rotate(10deg);
	}
	60% {
		transform: rotate(0deg);
	} /* Reset for the last half to pause */
	100% {
		transform: rotate(0deg);
	}
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import Notie from "@/services/NotieService";
import Utils from "@/services/Utils";

export default {
	name: "VideoFeed",
	props: {
		uiState: true,
		participant: {
			required: true,
		},
	},
	components: {},
	data() {
		return {
			videoWidth: 480,
			videoHeight: 272,
			clearVideoEventListeners: null,
		};
	},
	created() {},
	mounted() {
		if (this.participant.video) {
			this.injectVideo();
		}
	},
	destroyed() {},

	watch: {
		"participant.video"() {
			if (this.participant.video) {
				this.injectVideo();
			} else {
				this.releaseVideo();
			}
		},
	},

	computed: {
		speaking() {
			return this.participant.speaking && !this.participant.muted;
		},

		name() {
			return this.participant.name || this.participant.placeholder_name || "(Unknown)";
		},

		initials() {
			return this.participant.initials || this.participant.placeholder_initials || "?";
		},
	},

	methods: {
		injectVideo() {
			this.debug("injectVideo", this.participant.video);
			this.$refs.videoContainer.replaceChildren(this.participant.video);

			let video = this.participant.video;
			video.addEventListener("loadedmetadata", this.handleResize);
			video.addEventListener("resize", this.handleResize);
			video.addEventListener("play", this.videoStartedPlaying);

			this.clearVideoEventListeners = () => {
				video.removeEventListener("loadedmetadata", this.handleResize);
				video.removeEventListener("resize", this.handleResize);
				video.removeEventListener("play", this.videoStartedPlaying);
			};

			this.handleResize();
		},

		releaseVideo() {
			if (this.clearVideoEventListeners) {
				this.clearVideoEventListeners();
				this.clearVideoEventListeners = null;
			}
			this.$refs.videoContainer.replaceChildren();
		},

		handleResize() {
			if (!this.participant.video) {
				this.logWarn("handleResize on VideoFeed with no video");
				return;
			}

			let newWidth = this.participant.video.videoWidth;
			let newHeight = this.participant.video.videoHeight;

			if (newWidth != this.videoWidth || newHeight != this.videoHeight) {
				this.debug;
				this.$emit("videoResize");
			}

			this.videoWidth = newWidth;
			this.videoHeight = newHeight;
		},

		videoStartedPlaying() {
			this.log("Video started playing");
			this.participant.videoLoading = false;
		},
	},
};
</script>