import axios from "axios";
import _ from "lodash"

export default {
	enableLogging: true,
	currentLog: null,

	initLog(response, user, item) {
		if (!this.enableLogging) return

		this.currentLog = {
			type: "ResponseAuditLog",
			responseID: response.id,
			refID: response.ref_id,
			userName: user.first_name + " " + user.last_name,
			userID: user.id,
			itemID: response.item_id,
			actions: []
		}

		if (item) {
			this.currentLog.itemName = item.name
		}
	},

	recordAction(action, details) {
		if (!this.enableLogging) return

		if (!this.currentLog) return

		let time = new Date().toISOString()

		let record = {
			time: time,
			action: action
		}
		_.each(details, (val, key) => {
			record[key] = val
		})
		this.currentLog.actions.push(record)


		console.log("ResponseAuditLog action:", time, action, details)
	},

	isLogSubstantial() {
		if (!this.enableLogging) return
		if (!this.currentLog) return false

		let actionCount = 0

		_.each(this.currentLog.actions, action => {
			if (!(action.action == "Got response" || action.action == "Before highlight")) {
				actionCount++
			}
		})

		return actionCount > 0
	},

	sendLog() {
		if (!this.enableLogging) return
		if (!this.currentLog) return

		if (!this.isLogSubstantial()) {
			console.log("Do not send ResponseAuditLog, no annotation actions", this.currentLog)
			return
		}

		let sendLog = _.cloneDeep(this.currentLog)

		// console.log("_LTracker", _LTracker)
		console.log("Sending ResponseAuditLog", sendLog)
		_LTracker.push(sendLog)
		this.currentLog = null
	},

	sendPartialLog() {
		if (!this.enableLogging) return
		if (!this.currentLog) return

		if (!this.isLogSubstantial()) {
			console.log("Do not send ResponseAuditLog, no annotation actions", this.currentLog)
			return
		}

		let sendLog = _.cloneDeep(this.currentLog)

		// console.log("_LTracker", _LTracker)
		console.log("Sending partial ResponseAuditLog", sendLog)
		_LTracker.push(sendLog)
	}
};