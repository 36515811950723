<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					{{ client.name }}
					<div
						v-if="client.iea_integration"
						class="ml-2 badge badge-color-iea text-white"
						v-tippy
						:title="$t('TenantEdit.features.iea_description')"
					>
						{{ $t("TenantEdit.features.iea") }}
					</div>
					<div
						v-if="client.pearson_compensation"
						class="ml-2 badge badge-color-p-comp text-white"
						v-tippy
						:title="$t('TenantEdit.features.p-comp_description')"
					>
						{{ $t("TenantEdit.features.p-comp") }}
					</div>
					<div
						v-if="client.altus"
						class="ml-2 badge badge-color-altus text-white"
						v-tippy
						:title="$t('TenantEdit.features.altus_description')"
					>
						{{ $t("TenantEdit.features.altus") }}
					</div>
					<div
						v-if="client.previewer"
						class="ml-2 badge badge-color-previewer text-white"
						v-tippy
						:title="$t('TenantEdit.features.previewer_description')"
					>
						{{ $t("TenantEdit.features.previewer") }}
					</div>
				</div>
			</div>
		</template>

		<div class="content-main" style="background-color: white">
			<!-- Page content goes here -->
			<div class="h-100" v-if="!loading">
				<div class="d-sm-flex b-t h-100">
					<div class="w w-auto-xs light bg b-r" style="box-sizing: content-box">
						<div class="py-3">
							<div class="nav-active-border left b-primary">
								<!-- Nav by page -->
								<ul class="nav flex-column nav-sm">
									<li class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab == 1, show: tab == 1 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-1"
										>
											{{ $t("TenantEdit.Sidebar.setup") }}
											<i
												class="valid-hint text fa fa-angle-left"
												:class="{ invalid: !valid.group('setup') }"
											></i>
										</a>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab == 2, show: tab == 2 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-2"
											>{{ $t("TenantEdit.Sidebar.flag_codes") }}</a
										>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab == 3, show: tab == 3 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-3"
											>{{ $t("TenantEdit.Sidebar.metadata") }}</a
										>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab == 4, show: tab == 4 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-4"
											>{{ $t("TenantEdit.IntegrationKeys.title") }}</a
										>
									</li>
									<li class="nav-item" v-if="user.role.page_config_tenants">
										<a
											class="nav-link"
											:class="{ active: tab == 5, show: tab == 5 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-5"
											>{{ $t("TenantEdit.Sidebar.import_users") }}</a
										>
									</li>
									<li
										class="nav-item"
										v-if="user.role.page_config_forms || user.role.page_config_sites"
									>
										<a
											class="nav-link"
											:class="{ active: tab == 6, show: tab == 6 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-6"
											>{{ $t("TenantEdit.Sidebar.observation") }}</a
										>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab == 7, show: tab == 7 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-7"
											>{{ $t("TenantEdit.Sidebar.backreading") }}</a
										>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab == 8, show: tab == 8 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-8"
											>{{ $t("TenantEdit.Sidebar.config_sheets") }}</a
										>
									</li>
									<li v-if="client.id == '000000000000000000000000'" class="nav-item">
										<a
											class="nav-link"
											:class="{ active: tab == 9, show: tab == 9 }"
											href="#"
											data-toggle="tab"
											data-target="#tab-9"
											>{{ $t("TenantEdit.Sidebar.role_setup") }}</a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<edit-pane
						class="scroll-x"
						style="width: 100%"
						:cancel="loadData"
						:save="saveClient"
						:dirty="dirty"
						:valid="valid"
					>
						<div class="tab-content pos-rlt h-100" ref="content">
							<!-- Overall Settings -->
							<div class="tab-pane" :class="{ active: tab == 1, show: tab == 1 }" id="tab-1">
								<div class="p-4 b-b _600 nav-title">{{ $t("TenantEdit.Setup.title") }}</div>
								<div class="row m-0">
									<form role="form" class="p-4 col-sm-6">
										<div class="form-group" :class="{ invalid: !valid.field('name') }">
											<label>{{ $t("TenantEdit.Setup.tenant_name") }}</label>
											<input type="text" class="form-control" v-model="client.name" />
										</div>
										<div
											class="form-group"
											:class="{
												invalid: !valid.field('ref') || !valid.field('ref_char'),
											}"
										>
											<label>{{ $t("TenantEdit.Setup.ref_id") }}</label>
											<input type="text" class="form-control" v-model.trim="client.ref_id" />
											<small class="form-text text-muted">{{
												$t("TenantEdit.Setup.ref_id_description")
											}}</small>
										</div>
										<div v-if="client.iea_integration" class="form-group">
											<label>{{ $t("TenantEdit.Setup.iea_tenant_id") }}</label>
											<input
												type="text"
												class="form-control"
												readonly
												v-model.trim="client.iea_tenant_id"
											/>
										</div>
										<div class="form-group" style="padding-top: 12px">
											<label>{{ $t("TenantEdit.Setup.language") }}</label>
											<v-select :options="langs" v-model="locale"></v-select>
										</div>
										<div class="form-group" v-if="client.survey_enabled">
											<label>{{ $t("TenantEdit.Setup.survey_url") }}</label>
											<input type="url" class="form-control" v-model="client.survey_url" />
										</div>
									</form>
									<form role="form" class="p-4 col-sm-6">
										<!-- Time Zone -->
										<div class="form-group">
											<label>
												{{ $t("TenantEdit.Setup.time_zone") }}
												<span class="text-xxs text-muted"
													>({{ $t("TenantEdit.Setup.time_zone_reason") }})</span
												>
											</label>
											<v-select :options="timezones" v-model="timezone"></v-select>
										</div>

										<div class="checkbox form-group">
											<label
												class="md-check"
												v-tippy="{ theme: 'popover' }"
												:title="$t('TenantEdit.Setup.protect_responses_on_export_tooltip')"
											>
												<input type="checkbox" v-model="client.protect_on_export" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.protect_responses_on_export") }}
											</label>
										</div>
										<div class="checkbox form-group">
											<label class="md-check">
												<input type="checkbox" v-model="client.survey_enabled" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.survey_reason") }}
											</label>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="checkbox form-group">
													<label class="md-check">
														<input type="checkbox" v-model="client.sla_enabled" />
														<i class="theme-accent"></i>
														{{ $t("TenantEdit.Setup.sla") }}
													</label>
												</div>
											</div>
											<div v-if="client.sla_enabled" class="col-6">
												<div class="form-group">
													<label>
														{{ $t("TenantEdit.Setup.sla_hours") }}
													</label>
													<v-select
														label="name"
														:options="hourOptions"
														v-model="client.sla_hours"
														:reduce="(o) => o.id"
													></v-select>
												</div>
											</div>
										</div>
										<div class="checkbox form-group">
											<label class="md-check">
												<input type="checkbox" v-model="client.use_res_groups" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.use_resolution_groups") }}
											</label>
										</div>
										<div class="checkbox form-group">
											<label class="md-check">
												<input type="checkbox" v-model="client.terms_required" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.terms_required") }}
											</label>
										</div>
										<div v-if="client.pearson_comp" class="checkbox form-group">
											<label class="md-check">
												<input type="checkbox" v-model="client.score_code_use_zero" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.score_code_use_zero") }}
											</label>
										</div>
										<div class="checkbox form-group">
											<label class="md-check">
												<input type="checkbox" v-model="client.enforce_unique_responses" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.enforce_unique_responses") }}
											</label>
										</div>
										<div class="checkbox form-group">
											<label class="md-check">
												<input type="checkbox" v-model="client.auto_export_appeals" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.auto_export_appeals") }}
											</label>
										</div>
										<div class="checkbox form-group">
											<label class="md-check">
												<input type="checkbox" v-model="client.appeals_second_score" />
												<i class="theme-accent"></i>
												{{ $t("TenantEdit.Setup.appeals_second_score") }}
											</label>
										</div>
										<template v-if="client.enforce_unique_responses">
											<div class="form-group">
												<label
													v-tippy="{ theme: 'popover' }"
													:title="$t('TenantEdit.Setup.unique_meta_key_tooltip')"
												>
													{{ $t("TenantEdit.Setup.unique_meta_key") }}
												</label>
												<input
													type="text"
													class="form-control"
													v-model="client.unique_response_key"
													:placeholder="$t('fields.response_ref_id')"
												/>
											</div>
											<div class="form-group">
												<label
													v-tippy="{ theme: 'popover' }"
													:title="$t('TenantEdit.Setup.limit_roles_tooltip')"
												>
													{{ $t("TenantEdit.Setup.limit_roles") }}:
												</label>
												<v-select
													:options="client.roles"
													v-model="limitUniqueRoles"
													label="name"
													multiple
												></v-select>
											</div>
										</template>

										<div class="form-group">
											<label>{{ $t("TenantEdit.Setup.send_email") }}</label>
											<v-select :options="scanIntervals" v-model="scanInterval"></v-select>
										</div>
										<!--	<div class="form-group">
													<label>{{$t('TenantEdit.Setup.refresh_interval')}}</label>
													<input type="number" v-model="client.refresh_int" />
												</div> -->
										<div v-if="showScanRoles" class="form-group">
											<label>{{ $t("TenantEdit.Setup.to_roles") }}</label>
											<v-select
												:options="client.roles"
												v-model="scanRoles"
												label="name"
												multiple
											></v-select>
										</div>
										<div v-if="showScanTime" class="form-group">
											<label>At Time:</label>
											<date-picker
												v-model="client.new_responses_time"
												:config="timeConfig"
											></date-picker>
										</div>
									</form>
									<form role="form" class="px-4 pb-4 pt-0 col-12" v-if="client.terms_required">
										<div class="form-group">
											<div class="d-flex flex-row">
												<label style="width: 50%">Terms:</label>
												<span
													v-if="client.terms_required && termsChanged && !firstTerms"
													class="checkbox pl-4"
												>
													<label
														class="md-check"
														v-tippy="{ theme: 'popover' }"
														:title="$t('tooltip.have_to_accept_updated_terms')"
													>
														<input v-model="reacceptTerms" type="checkbox" />
														<i class="theme-accent"></i> Force Re-Accept
													</label>
												</span>
											</div>
											<RichText
												:text.sync="client.terms_text"
												:initial="originalTermsText"
												:refresh="refresh"
											></RichText>
										</div>
									</form>
								</div>
							</div>

							<!-- Flag Codes -->
							<div class="tab-pane" :class="{ active: tab == 2, show: tab == 2 }" id="tab-2">
								<div class="p-4 b-b _600 nav-title">{{ $t("TenantEdit.FlagCodes.title") }}</div>
								<div class="row m-0">
									<div class="col-lg-10">
										<table class="table">
											<thead>
												<tr>
													<th>{{ $t("fields.code") }}</th>
													<th>{{ $t("fields.description") }}</th>
													<th>{{ $t("fields.action") }}</th>
													<th>
														<span
															v-tippy="{ theme: 'popover' }"
															:title="$t('tooltip.flag_is_alert_tooltip')"
														>
															{{ $t("fields.is_alert") }}
														</span>
													</th>
													<th>
														<span
															v-tippy="{ theme: 'popover' }"
															:title="$t('tooltip.flag_alert_email_on_confirm')"
														>
															{{ $t("fields.is_alert_email_on_confirm") }}
														</span>
													</th>
													<th>
														<span
															v-tippy="{ theme: 'popover' }"
															:title="$t('tooltip.flag_requires_score_tooltip')"
														>
															{{ $t("fields.requires_score") }}
														</span>
													</th>
													<th>
														<span
															v-tippy="{ theme: 'popover' }"
															:title="$t('tooltip.flag_admin_only_tooltip')"
														>
															{{ $t("fields.admin_only") }}
														</span>
													</th>
													<th>
														<span
															v-tippy="{ theme: 'popover' }"
															:title="$t('tooltip.flag_restricted_review_tooltip')"
														>
															{{ $t("fields.restricted_review") }}
														</span>
													</th>
													<th>
														<span
															v-tippy="{ theme: 'popover' }"
															:title="$t('tooltip.flag_strict_blank_tooltip')"
														>
															{{ $t("fields.strict_blank") }}
														</span>
													</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr v-if="!client.alerts.length">
													<td colspan="5" class="text-center">
														{{ $t("data_description.no_flag_codes") }}
													</td>
												</tr>
												<tr v-for="alert in client.alerts" :key="alert.id">
													<td class="p-1 pt-2">
														<input
															class="form-control w-64"
															type="text"
															v-model="alert.code"
														/>
													</td>
													<td class="p-1 pt-2">
														<input
															class="form-control"
															type="text"
															v-model="alert.description"
														/>
													</td>
													<td class="p-1 pt-2">
														<config-select
															style="width: 160px"
															:options="fs.flagActionTypes()"
															v-model="alert.action"
															byField="id"
															sortBy="sequence"
															@input="flagActionChanged(alert)"
														/>
													</td>
													<td class="text-center p-1 pt-2">
														<label class="md-check md-check-md" v-if="alert.action != 1">
															<input type="checkbox" v-model="alert.is_alert" />
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="text-center p-1 pt-2">
														<label
															class="md-check md-check-md"
															v-if="alert.action != 1 && alert.is_alert"
														>
															<input
																type="checkbox"
																v-model="alert.email_only_on_confirm"
															/>
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="text-center p-1 pt-2">
														<label class="md-check md-check-md" v-if="alert.action != 1">
															<input
																type="checkbox"
																v-model="alert.requires_score"
																:disabled="alert.action == 2"
															/>
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="text-center p-1 pt-2">
														<label class="md-check md-check-md">
															<input type="checkbox" v-model="alert.admin_only" />
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="text-center p-1 pt-2">
														<label class="md-check md-check-md">
															<input type="checkbox" v-model="alert.restricted_review" />
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="text-center p-1 pt-2">
														<label class="md-check md-check-md">
															<input type="checkbox" v-model="alert.strict_blank" />
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="p-1 pt-2">
														<button
															@click="removeFlag(alert)"
															class="btn btn-danger btn-sm"
															v-if="isFlagCodeRemovable(alert.id)"
														>
															{{ $t("TenantEdit.FlagCodes.remove") }}
														</button>
														<button
															v-tippy="{ theme: 'popover' }"
															:title="$t('tooltip.flag_code_exists_on_response')"
															class="btn btn-sm disabled"
															v-if="!isFlagCodeRemovable(alert.id)"
														>
															{{ $t("TenantEdit.FlagCodes.remove") }}
														</button>
													</td>
												</tr>
												<tr>
													<td colspan="6" class="text-center">
														<button
															@click="
																client.alerts.push({
																	code: vm.$i18n.t(
																		'TenantEdit.FlagCodes.blank_flag_code'
																	),
																	description: vm.$i18n.t(
																		'TenantEdit.FlagCodes.blank_flag_code_description'
																	),
																	action: 0,
																})
															"
															class="btn btn-success btn-block"
														>
															{{ $t("TenantEdit.FlagCodes.new_flag_code") }}
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>

							<!-- Metadata -->
							<div class="tab-pane" :class="{ active: tab == 3, show: tab == 3 }" id="tab-3">
								<div class="p-4 b-b _600 nav-title">
									<span v-tippy :title="$t('TenantEdit.Metadata.info_tooltip')">
										{{ $t("TenantEdit.Metadata.title") }}
										<i class="fas fa-fw fa-info-circle ml-1" />
									</span>
								</div>
								<div class="row m-0">
									<div class="col-sm-10">
										<table class="table">
											<thead>
												<tr>
													<th>{{ $t("fields.key") }}</th>
													<th>{{ $t("fields.display_name") }}</th>
													<th class="text-center">
														{{ $t("fields.show_when_blank") }}
													</th>
													<th class="text-center">{{ $t("fields.admin_only") }}</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr v-if="!client.metadata_keys.length">
													<td colspan="5" class="text-center">
														{{ $t("data_description.no_metadata") }}
													</td>
												</tr>
												<tr v-for="mk in client.metadata_keys" :key="mk.id">
													<td class="p-1 pt-2">
														<input class="form-control" type="text" v-model="mk.key" />
													</td>
													<td class="p-1 pt-2">
														<input
															class="form-control"
															type="text"
															v-model="mk.display_name"
														/>
													</td>
													<td class="text-center p-1 pt-2">
														<label class="md-check md-check-md">
															<input type="checkbox" v-model="mk.show_blank" />
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="text-center p-1 pt-2">
														<label class="md-check md-check-md">
															<input type="checkbox" v-model="mk.admin_only" />
															<i class="theme-accent"></i>
														</label>
													</td>
													<td class="p-1 pt-2">
														<button @click="removeMK(mk)" class="btn btn-danger btn-sm">
															{{ $t("buttons.remove") }}
														</button>
													</td>
												</tr>
												<tr>
													<td colspan="6" class="text-center">
														<button
															@click="
																client.metadata_keys.push({
																	key: '',
																	display_name: '',
																	admin_only: false,
																	show_blank: false,
																})
															"
															class="btn btn-success btn-block"
														>
															{{ $t("TenantEdit.Metadata.new_metadata_key") }}
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>

							<!-- Integration Keys -->
							<div class="tab-pane" :class="{ active: tab == 4, show: tab == 4 }" id="tab-4">
								<div class="p-4 b-b _600 nav-title">
									{{ $t("TenantEdit.IntegrationKeys.title") }}
								</div>
								<div class="row m-0">
									<div class="col-sm-8">
										<table class="table">
											<thead>
												<tr>
													<th>{{ $t("fields.credential_id") }}</th>
													<th>{{ $t("fields.type") }}</th>
													<th>{{ $t("fields.key") }}</th>
													<th>{{ $t("fields.bucket") }}</th>
													<th>{{ $t("fields.region") }}</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr v-if="!credentials.length">
													<td colspan="5" class="text-center">
														{{ $t("data_description.no_credentials") }}
													</td>
												</tr>
												<tr v-for="cred in credentials" :key="cred.id">
													<td>{{ cred.cred_id }}</td>
													<td>{{ cred.type }}</td>
													<td>{{ cred.key }}</td>
													<td>{{ cred.bucket }}</td>
													<td>{{ cred.region }}</td>
													<td>
														<button
															@click="removeCredential(cred)"
															class="btn btn-danger btn-sm"
														>
															{{ $t("TenantEdit.FlagCodes.remove") }}
														</button>
													</td>
												</tr>
												<tr>
													<td colspan="6" class="text-center">
														<button
															data-toggle="modal"
															data-target="#exampleModal"
															@click="addCredential()"
															class="btn btn-success btn-block"
														>
															{{ $t("TenantEdit.IntegrationKeys.new_credential") }}
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>

							<!-- User Assignment -->
							<div
								class="tab-pane h-100"
								:class="{ active: tab == 5, show: tab == 5 }"
								id="tab-5"
								v-if="user.role.page_config_tenants"
							>
								<div class="flex-v h-100">
									<div class="p-4 b-b _600 nav-title flex-fixed">
										{{ $t("TenantEdit.ImportUsers.title") }}
									</div>
									<div class="flex-grow flex-v">
										<dual-table
											class="table-nowrap"
											:leftLabel="$t('TenantEdit.ImportUsers.current_users')"
											:leftSearch="$t('TenantEdit.ImportUsers.search_placeholder_current')"
											:leftItems="hasProvider"
											:leftFields="hasFields"
											:leftControl="hasControl"
											:rightLabel="$t('TenantEdit.ImportUsers.available_users')"
											:rightSearch="$t('TenantEdit.ImportUsers.search_placeholder_available')"
											:rightItems="canProvider"
											:rightFields="canFields"
											:rightControl="canControl"
											:enableSorting="false"
										>
											<!-- Left table slots -->
											<!-- Name Virtual Column -->
											<template #cell(left:name)="data">
												<span
													v-tippy
													:title="`<div>${data.item.scorer_id}</div><div>${data.item.email}</div>`"
													>{{ data.item.full_name }}</span
												>
											</template>

											<!-- Role Column -->
											<template #cell(left:role)="data">
												<v-select
													@input="changeRole(data.item)"
													:options="accessibleRoles"
													v-model="data.item.sRole"
													:disabled="data.item.busy"
													label="name"
												></v-select>
											</template>

											<!-- Actions -->
											<template #cell(left:actions)="data">
												<nobr>
													<div class="mx-auto w30">
														<button
															v-if="!data.item.sRole"
															class="btn btn-sm btn-icon btn-rounded text-white m-0"
															disabled
															v-tippy
														>
															<i class="far fa-plus"></i>
														</button>
														<button
															v-if="data.item.sRole && !data.item.inProgress"
															class="btn btn-sm btn-icon btn-rounded danger text-white m-0"
															@click="attemptRemoveUser(data.item)"
															v-tippy
															:title="$t('tooltip.remove_from_tenant')"
														>
															<i class="far fa-minus"></i>
														</button>
														<button
															v-if="data.item.sRole && data.item.inProgress"
															class="btn btn-sm btn-icon btn-rounded m-0"
															v-tippy
															title="Importing..."
														>
															<loading type="icon" />
														</button>
													</div>
												</nobr>
											</template>

											<!-- Right table slots -->
											<!-- Name Virtual Column -->
											<template #cell(right:name)="data">
												<span
													v-tippy
													:title="`<div>${data.item.scorer_id}</div><div>${data.item.email}</div>`"
													>{{ data.item.full_name }}</span
												>
											</template>

											<!-- Import Role Column -->
											<template #cell(right:import_as)="data">
												<v-select
													:options="accessibleRoles"
													v-model="data.item.sRole"
													label="name"
												></v-select>
											</template>

											<!-- Actions -->
											<template #cell(right:actions)="data">
												<nobr>
													<div class="mx-auto w30">
														<button
															v-if="!data.item.sRole"
															class="btn btn-sm btn-icon btn-rounded text-white m-0"
															disabled
															v-tippy
														>
															<i class="far fa-plus"></i>
														</button>
														<button
															v-if="data.item.sRole && !data.item.inProgress"
															class="btn btn-sm btn-icon btn-rounded success text-white m-0"
															@click="attemptImportUser(data.item)"
															v-tippy
															:title="$t('tooltip.import_to_tenant')"
														>
															<i class="far fa-plus"></i>
														</button>
														<button
															v-if="data.item.sRole && data.item.inProgress"
															class="btn btn-sm btn-icon btn-rounded m-0"
															v-tippy
															title="Importing..."
														>
															<loading type="icon" />
														</button>
													</div>
												</nobr>
											</template>
										</dual-table>
									</div>
								</div>
							</div>

							<!-- Observation -->
							<div class="tab-pane" :class="{ active: tab == 6, show: tab == 6 }" id="tab-6">
								<div class="p-4 b-b _600 nav-title">
									{{ $t("TenantEdit.Observation.booking_ingest") }}
								</div>
								<div class="row p-4">
									<div class="col-12 col-sm-6 form-group">
										<label>{{ $t("TenantEdit.Observation.ingest_endpoint_url") }}</label>
										<input
											type="text"
											class="form-control"
											readonly
											:value="constructObsBookingURL()"
										/>
									</div>
									<div class="col-12 col-sm-6 form-group align-self-end">
										<button type="button" class="btn btn-primary" @click="copyObsBookingURL()">
											{{ $t("buttons.copy") }}
										</button>
									</div>
								</div>
								<div class="p-4 b-b _600 nav-title">
									<span>{{ $t("TenantEdit.Observation.score_passback") }}</span>
									<label class="ui-switch ui-switch-md theme-accent ml-3" style="margin-bottom: -4px">
										<input type="checkbox" v-model="client.obs_score_enabled" />
										<i></i>
									</label>
								</div>
								<div class="row p-4" v-if="client.obs_score_enabled">
									<div class="col-9 form-group">
										<label>{{ $t("TenantEdit.Observation.passback_endpoint_url") }}</label>
										<input type="text" class="form-control" v-model="client.obs_score_url" />
									</div>
									<div class="col-12 col-sm-6 form-group" :class="{ invalid: !valid.field('name') }">
										<label>{{ $t("TenantEdit.Observation.basic_auth_username") }}</label>
										<input type="text" class="form-control" v-model="client.obs_score_username" />
									</div>
									<div class="col-12 col-sm-6 form-group" :class="{ invalid: !valid.field('name') }">
										<label>{{ $t("TenantEdit.Observation.basic_auth_password") }}</label>
										<input type="text" class="form-control" v-model="client.obs_score_password" />
									</div>
								</div>
							</div>

							<!-- Backreading -->
							<div class="tab-pane" :class="{ active: tab == 7, show: tab == 7 }" id="tab-7">
								<div class="p-4 b-b _600 flex-fixed">
									<div class="form-group">
										<label class="no-grow mb-0 mr-3"
											>{{ $t("TenantEdit.Backreading.blind_scoring") }}:</label
										>
										<label class="ui-switch ui-switch-md theme-accent">
											<input type="checkbox" v-model="client.backreading_blind_scoring" />
											<i></i>
										</label>
									</div>
								</div>
								<div class="p-4 b-b _600 flex-fixed">
									<div class="form-group">
										<label class="no-grow mb-0 mr-3"
											>{{ $t("TenantEdit.Backreading.exclude_resolution_score") }}:</label
										>
										<label class="ui-switch ui-switch-md theme-accent">
											<input
												type="checkbox"
												v-model="client.backreading_exclude_resolution_score"
											/>
											<i></i>
										</label>
									</div>
								</div>
								<div class="p-4 b-b _600 flex-fixed">
									<div class="form-group">
										<label class="no-grow mb-0 mr-3"
											>{{ $t("TenantEdit.Backreading.exclude_exported_resps") }}:</label
										>
										<label class="ui-switch ui-switch-md theme-accent">
											<input
												type="checkbox"
												v-model="client.backreading_exclude_exported_resps"
											/>
											<i></i>
										</label>
									</div>
								</div>
							</div>

							<!-- Config Sheets -->
							<tenant-edit-config-sheets
								id="tab-8"
								:client="client"
								:valid="valid"
								:defaultTab="tab == 8"
							/>

							<!-- Role Setup -->
							<div class="tab-pane" :class="{ active: tab == 9, show: tab == 9 }" id="tab-9">
								<div class="p-4 b-b _600 nav-title">{{ $t("TenantEdit.Setup.title") }}</div>
								<div class="p-4">
									<div class="row">
										<div class="col-12 col-sm-6 col-lg-4 form-group">
											<label>{{ $t("TenantEdit.RoleSetup.tenant_to_copy_roles_from") }}</label>
											<config-select
												:options="clients || []"
												v-model="selectedClient"
												:nullOption="true"
												:nullText="$t('TenantEdit.RoleSetup.none')"
											>
											</config-select>
										</div>

										<div class="col-12 col-sm-6 col-lg-4 form-group">
											<label v-if="selectedClient">{{
												$t("TenantEdit.RoleSetup.initial_roles")
											}}</label>
											<label v-else>{{ $t("TenantEdit.RoleSetup.default_roles") }}</label>
											<div v-if="selectedClient" class="list-group box">
												<a
													v-for="role in clientOrderedRoles(selectedClient)"
													:key="role.id"
													class="list-group-item d-flex flex-row align-items-center"
												>
													<span>{{ role.name }} ({{ role.access }})</span>
													<span v-if="role.topRole"
														><i
															class="fas fa-user text-success ml-2"
															v-tippy
															:title="$t('TenantEdit.RoleSetup.top_role')"
													/></span>
												</a>
											</div>
											<div v-else class="list-group box">
												<a
													v-for="role in legacyRoles"
													:key="role.id"
													class="list-group-item d-flex flex-row align-items-center"
												>
													<span>{{ role.name }} ({{ role.access }})</span>
													<span v-if="role.topRole"
														><i
															class="fas fa-user text-success ml-2"
															v-tippy
															:title="$t('TenantEdit.RoleSetup.top_role')"
													/></span>
												</a>
											</div>
										</div>
										<div class="col-12 text-warning">
											<div v-if="selectedClient" class="">
												{{ $t("TenantEdit.RoleSetup.role_edit_later") }}
											</div>
											<div v-else class="">
												{{ $t("TenantEdit.RoleSetup.role_setup_later") }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</edit-pane>
					<save-optional-modal
						:dirty="dirty"
						:valid="valid"
						:save="saveClient"
						:next="saveOptNext"
						:cancel="saveOptCancel"
						objectText="Tenant"
						:objectName="client.name"
						actionText="leave the page"
						v-model="saveOptModal"
					/>

					<save-required-modal
						:dirty="dirty"
						:valid="valid"
						:save="saveClient"
						:next="saveReqNext"
						objectText="Tenant"
						:objectName="client.name"
						:actionText="saveReqAction"
						v-model="saveReqModal"
					/>
				</div>
			</div>
		</div>

		<!-- Add Credential Modal -->
		<div
			class="modal fade"
			id="exampleModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("TenantEdit.IntegrationKeys.new_credential_modal.title") }}</h5>
					</div>
					<div class="modal-body">
						<div autocomplete="off" class="p-1 col-sm-12">
							<div class="form-group">
								<label
									v-if="
										newCredential.type == 'API Key & Secret' ||
										newCredential.type == 'Basic Authentication'
									"
									>{{ $t("TenantEdit.IntegrationKeys.new_credential_modal.credential_name") }}</label
								>
								<label v-else>{{
									$t("TenantEdit.IntegrationKeys.new_credential_modal.credential_id")
								}}</label>
								<input type="text" class="form-control" v-model="newCredential.cred_id" />
							</div>
							<div class="form-group">
								<label>{{ $t("TenantEdit.IntegrationKeys.new_credential_modal.type") }}</label>

								<v-select :options="credTypes" v-model="newCredential.type"></v-select>
								<!-- <input type="text" disabled="disabled" class="form-control" v-model="newCredential.type"> -->
							</div>
							<div class="form-group">
								<label>{{
									newCredential.type == "Basic Authentication"
										? $t("TenantEdit.IntegrationKeys.new_credential_modal.username")
										: $t("TenantEdit.IntegrationKeys.new_credential_modal.key")
								}}</label>
								<input type="text" class="form-control" v-model="newCredential.key" />
							</div>
							<div class="form-group" v-if="newCredential.type != 'EMMA'">
								<label>{{
									newCredential.type == "Basic Authentication"
										? $t("TenantEdit.IntegrationKeys.new_credential_modal.password")
										: $t("TenantEdit.IntegrationKeys.new_credential_modal.secret")
								}}</label>
								<input
									autocomplete="off"
									type="password"
									class="form-control"
									v-model="newCredential.secret"
								/>
							</div>
							<div class="form-group" v-if="newCredential.type == 'S3'">
								<label>{{ $t("TenantEdit.IntegrationKeys.new_credential_modal.bucket") }}</label>
								<input
									autocomplete="off"
									type="text"
									class="form-control"
									v-model="newCredential.bucket"
								/>
							</div>
							<div class="form-group" v-if="newCredential.type == 'S3'">
								<label>{{ $t("TenantEdit.IntegrationKeys.new_credential_modal.region") }}</label>
								<input
									autocomplete="off"
									type="text"
									class="form-control"
									v-model="newCredential.region"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary danger" data-dismiss="modal">
							{{ $t("TenantEdit.IntegrationKeys.new_credential_modal.close") }}
						</button>
						<button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveCredential()">
							{{ $t("TenantEdit.IntegrationKeys.new_credential_modal.save_changes") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-auto {
	z-index: -1;
}
.w30 {
	width: 30px;
}
</style>

<script>
//Libraries
import notie from "@/services/NotieService";

//Services
import TenantService from "@/services/TenantService";
import AuthService from "@/services/AuthService";
import ValidationService from "@/services/ValidationService";
import UserService from "@/services/UserService";
import ThemeService from "@/services/ThemeService";
import fs from "@/services/FormatService";
import LegacyRoles from "@/services/LegacyRoles";
import Utils from "@/services/Utils";
import moment from "moment";

//Components
import EditPane from "@/components/EditPane";
import ThemeSelect from "@/components/ThemeSelect";
import DualTable from "@/components/DualTable";
import SaveOptionalModal from "@/components/SaveOptionalModal";
import SaveRequiredModal from "@/components/SaveRequiredModal";
import RichText from "@/components/RichText";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import DatePicker from "vue-bootstrap-datetimepicker";
import ConfigSelect from "@/components/ConfigSelect";

import TenantEditConfigSheets from "@/components/tenant/TenantEditConfigSheets";

const timeFormat = "h:mm A";
const timeConfig = {
	format: timeFormat,
	icons: {
		time: "fa fa-clock-o",
		date: "fa fa-calendar",
		up: "fa fa-chevron-up",
		down: "fa fa-chevron-down",
		previous: "fa fa-chevron-left",
		next: "fa fa-chevron-right",
		today: "fa fa-sun-o",
		clear: "fa fa-trash",
		close: "fa fa-remove",
	},
};

export default {
	name: "TenantEdit",

	components: {
		EditPane,
		ThemeSelect,
		DualTable,
		SaveOptionalModal,
		SaveRequiredModal,
		RichText,
		DatePicker,
		ConfigSelect,
		TenantEditConfigSheets,
	},

	props: ["user", "params", "query"],

	data() {
		return {
			vm: this,
			fs: fs,
			legacyRoles: LegacyRoles,
			timeConfig: timeConfig,
			tab: 1,
			mode: "",
			client: {},
			loading: true,
			successMsg: "",
			errMsg: "",
			dirty: false,
			credentials: [],
			newCredential: {},
			flagCodesRemovableDetails: {},

			reacceptTerms: false,
			originalTermsText: "",
			refresh: null,

			hasFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{
					key: "role",
					label: this.$i18n.t("fields.role"),
					thStyle: { width: "33%" },
					tdStyle: { width: "33%" },
					tdClass: "p-custom py-0 v-mid condensed-v-select",
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
					sortable: true,
				},
			],
			hasControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 20,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},
			hasBusy: false,
			canFields: [
				{
					key: "name",
					label: this.$i18n.t("fields.name"),
					sortable: true,
				},
				{
					key: "import_as",
					label: this.$i18n.t("fields.import_as"),
					thStyle: { width: "33%" },
					tdStyle: { width: "33%" },
					tdClass: "p-custom py-0 v-mid condensed-v-select",
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom py-0 v-mid",
					sortable: true,
				},
			],
			canControl: {
				totalRows: 0,
				currentPage: 1,
				perPage: 20,
				filter: "",
				sortBy: "",
				sortDesc: false,
				emptyText: "",
			},

			credTypes: ["API Key & Secret", "Basic Authentication", "S3", "LEARNOSITY", "EMMA", "IEA"],

			timezones: fs.timezones,
			timezone: null,

			hourOptions: fs.slaHours(),

			langs: fs.langs,
			locale: null,

			scanIntervals: [],
			scanInterval: null,
			scanRoles: [],
			limitUniqueRoles: [],

			clients: [],
			selectedClient: null,

			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},

			saveReqModal: false,
			saveReqAction: "continue",
			saveReqNext: () => {},

			saveCount: 0,
		};
	},

	created() {
		this.loadData();
		if (this.user.role.page_config_tenants) this.loadClients();
		this.initValidation();

		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {
		"client.name"() {
			if (this.autoRef) {
				let genRef = this.generateAutoRef(this.client.name);
				if (this.client.ref_id.toLowerCase() != genRef.toLowerCase()) {
					this.client.ref_id = genRef;
				}
			}
		},
		"client.ref_id"() {
			this.checkAutoRef();
		},
		"newCredential.type"() {
			if (this.newCredential.type == "EMMA") {
				this.newCredential.cred_id = "Emma-Api-Key";
			}
		},
		timezone() {
			console.log("Change timezone to", this.timezone);
			if (this.timezone && this.client.timezone != this.timezone.string) {
				this.client.timezone = this.timezone.string;
			}
		},
		locale() {
			if (this.locale && this.client.locale != this.locale.string) {
				this.client.locale = this.locale.string;
			}
		},
		scanInterval() {
			console.log("Change scan interval to", this.scanInterval);
			if (this.scanInterval && this.client.new_responses_interval != this.scanInterval) {
				this.client.new_responses_interval = this.scanInterval.string;
			}
		},
		scanRoles() {
			_.each(this.client.roles, (role) => {
				let enabled = _.find(this.scanRoles, { id: role.id });
				if (enabled) {
					role.receive_new_responses_emails = true;
				} else {
					role.receive_new_responses_emails = false;
				}
			});
		},
		limitUniqueRoles() {
			_.each(this.client.roles, (role) => {
				let enabled = _.find(this.limitUniqueRoles, { id: role.id });
				if (enabled) {
					role.limit_unique_responses = true;
				} else {
					role.limit_unique_responses = false;
				}
			});
		},
	},

	computed: {
		orderedRoles() {
			return this.clientOrderedRoles(this.client);
		},
		accessibleRoles() {
			return _.filter(this.client.roles, (role) => {
				return this.user.role.access >= role.access;
			});
		},
		firstTerms() {
			console.log("updated year", moment(this.client.terms_updated_at).year());
			return moment(this.client.terms_updated_at).year() == 0;
		},
		termsChanged() {
			return this.client.terms_text != this.originalTermsText;
		},
		showScanRoles() {
			return this.scanInterval && (this.scanInterval.string == "daily" || this.scanInterval.string == "hourly");
		},
		showScanTime() {
			return this.scanInterval && this.scanInterval.string == "daily";
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "setup",
					errorMsg: "You must assign a name",
					func: () => {
						return this.client && this.client.name && this.client.name != "";
					},
				},
				ref: {
					group: "setup",
					errorMsg: "You must assign a reference ID",
					func: () => {
						return this.client && this.client.ref_id && this.client.ref_id != "";
					},
				},
				ref_char: {
					group: "setup",
					errorMsg:
						"Reference IDs may not contain spaces or any of the following characters: ! # % ^ * ( )  / [ ] { } < > ? | ' \" :",
					func: () => {
						return this.client && this.client.ref_id && fs.isGoodRefID(this.client.ref_id);
					},
				},
			});
		},

		blankClient() {
			return {
				roles: [
					RoleService.getPrefillRole("DIRECTOR"),
					RoleService.getPrefillRole("ADMIN"),
					RoleService.getPrefillRole("RESOLUTION"),
					RoleService.getPrefillRole("TEAM_LEAD"),
					RoleService.getPrefillRole("SCORER"),
					RoleService.getPrefillRole("BASIC_SCORER"),
				],
				alerts: [],
				credentials: [],
				metadata_keys: [],
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"client",
				(newc, old) => {
					console.log(newc, old);
					console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadClients() {
			return TenantService.listClients()
				.then((r) => {
					this.clients = r.data.clients;
				})
				.catch((e) => {
					console.error(e);
					notie.error("Failed to load clients", e);
				});
		},

		clientOrderedRoles(client) {
			if (!(client && client.roles)) {
				console.error("Client had no roles", client);
				return [];
			}
			let clients = _.orderBy(client.roles, ["access", "name"], ["desc", "asc"]);
			if (clients.length > 0) {
				clients[0].topRole = true;
			}
			console.log("client ordered role", clients);
			return clients;
		},

		loadData() {
			this.dirty = false;

			this.scanIntervals = fs.scanIntervals(this.$i18n);

			ThemeService.getThemes().then((themes) => {
				this.themes = themes;
			});

			var reqId = "";
			if (this.params.id != "current") {
				reqId = this.params.id;
			}

			if (this.params.id != "new") {
				return TenantService.getClient(reqId).then((resp) => {
					let client = resp.data;
					TenantService.prepIncomingData(client);
					this.client = client;
					if (this.client.roles.length > 0) {
						this.sRole = _.find(
							_.orderBy(this.client.roles, ["access", "name"], ["desc", "asc"]),
							(role) => {
								return this.user.role.access >= role.access;
							}
						);
						this.loading = false;
						this.originalTermsText = this.client.terms_text;
						if (!this.client.timezone) {
							this.$set(this.client, "timezone", "America/New_York");
						}

						this.timezone = _.find(this.timezones, {
							string: this.client.timezone,
						});

						if (!this.client.locale) {
							this.$set(this.client, "locale", "en");
						}
						this.locale = _.find(this.langs, {
							string: this.client.locale,
						});

						this.scanInterval = _.find(this.scanIntervals, {
							string: this.client.new_responses_interval,
						});

						this.scanRoles = [];
						_.each(this.client.roles, (role) => {
							if (role.receive_new_responses_emails) {
								console.log("ROLE", role.id, "HAD PERM");
								this.scanRoles.push(role);
							}
						});

						this.limitUniqueRoles = [];
						_.each(this.client.roles, (role) => {
							if (role.limit_unique_responses) {
								this.limitUniqueRoles.push(role);
							}
						});

						this.refresh = new Date();
						this.checkAutoRef();
						this.watchChanges();

						TenantService.listCredentialsSafe(this.client.id).then((resp) => {
							this.credentials = resp.data;
						});
					}
					if (!this.client.metadata_keys) {
						this.client.metadata_keys = [];
					}
					this.getFlagCodeRemovableData();
				});
			} else {
				return TenantService.getEmptyClient().then((resp) => {
					this.client = resp.data;
					this.sRole = this.client.roles[0];
					this.loading = false;
					this.$set(this.client, "name", "New Client");
					this.$set(this.client, "ref_id", "new_client");

					this.$set(this.client, "timezone", "America/New_York");
					this.timezone = _.find(this.timezones, this.client.timezone);

					this.$set(this.client, "locale", "en");
					this.locale = _.find(this.langs, this.client.locale);

					this.$set(this.client, "new_responses_interval", "hourly");
					this.scanInterval = _.find(this.scanIntervals, {
						string: this.client.new_responses_interval,
					});

					this.scanRoles = [];

					this.$set(this.client, "new_responses_time", "9:00 PM");
					this.$set(this.client, "config_sheets", []);

					this.watchChanges();
				});
			}
		},

		hasProvider(ctx) {
			if (this.client.id && this.client.id != "000000000000000000000000") {
				this.hasControl.emptyText = this.$i18n.t("data_description.no_records");
				return UserService.listUsers(this.client.id, ctx)
					.then((resp) => {
						this.hasControl.totalRows = resp.data.totalRows;
						_.each(resp.data.users, (user) => {
							let role = _.find(this.client.roles, {
								id: user.role_id,
							});
							user.sRole = role;
						});
						return resp.data.users;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.load_available_users_fail"), err);
					});
			} else {
				this.hasControl.emptyText = "Cannot import users to a client before saving";
				return [];
			}
		},

		canProvider(ctx) {
			ctx.inverse = true;
			if (this.user.role.page_config_tenants) {
				ctx.crossTenant = true;
			}
			if (this.client.id && this.client.id != "000000000000000000000000") {
				this.canControl.emptyText = this.$i18n.t("data_description.no_records");
				return UserService.listUsers(this.client.id, ctx)
					.then((resp) => {
						this.canControl.totalRows = resp.data.totalRows;
						var lowestRole = _.minBy(this.client.roles, "access");
						_.each(resp.data.users, (user) => {
							user.sRole = lowestRole;
						});
						return resp.data.users;
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.load_available_users_fail"), err);
					});
			} else {
				this.canControl.emptyText = "Cannot import users to a client before saving";
				return [];
			}
		},

		saveClient() {
			AuthService.queueRefresh();
			if (this.reacceptTerms) {
				this.client.terms_updated_at = moment();
			}
			console.log("METADATA_KEYS", this.client.metadata_keys);
			this.client.metadata_keys = _.filter(this.client.metadata_keys, (o) => {
				console.log("o", o, o.key, o.key != "");
				return o.key != "";
			});
			console.log("METADATA_KEYS2", this.client.metadata_keys);
			this.client.refresh_int = parseInt(this.client.refresh_int);
			console.log("this.client");
			console.log(this.client);

			if (this.selectedClient) {
				this.client.roles = this.selectedClient.roles;
			}

			let saveClient = TenantService.prepOutgoingData(this.client);

			return TenantService.saveClient(saveClient)
				.then((resp) => {
					notie.success(this.$i18n.t("notie.tenant_saved"));
					if (this.client.id != resp.data.id) {
						this.$router.replace("/tenants/" + resp.data.id);
					}
					// else {
					// 	this.$router.replace(
					// 		"/tenants/" +
					// 			resp.data.id +
					// 			"?rand=" +
					// 			Math.floor(Math.random() * 1000000)
					// 	);
					// }
					this.loadData();
					ThemeService.refresh();
					this.saveCount++;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.save_tenant_fail"), err);
				});
		},

		removeFlag(flag) {
			this.client.alerts = _.without(this.client.alerts, flag);
		},

		removeMK(mk) {
			let index = this.client.metadata_keys.indexOf(mk);
			if (index == -1) {
				return;
			}
			this.$delete(this.client.metadata_keys, index);
		},

		isRoleActive(role) {
			if (role && this.sRole) {
				return this.sRole.id == role.id;
			} else {
				return false;
			}
		},

		addCredential() {
			// debugger;
			console.log("opening");
			this.newCredential = {
				client_id: this.client.id,
				type: "API Key & Secret",
			};
		},

		saveCredential() {
			TenantService.saveCredential(this.newCredential)
				.then((resp) => {
					this.loadData();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.save_credentials_fail"), err);
				});
		},

		removeCredential(credential) {
			TenantService.removeCredential(credential.id).then((resp) => {
				this.loadData();
			});
		},

		attemptImportUser(user) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.importUser(user);
				};
				this.saveReqAction = "import a user";
				this.saveReqModal = true;
			} else {
				this.importUser(user);
			}
		},

		importUser(user) {
			user.role_id = user.sRole.id;
			UserService.addUserToClient(this.client.id, user)
				.then((resp) => {
					notie.info(
						this.$i18n.t("notie.user_imported_as", {
							fname: user.first_name,
							lname: user.last_name,
							role: user.sRole.name,
						})
					);
					this.refreshTables();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.import_user_fail"), err);
				});
		},

		attemptRemoveUser(user) {
			if (this.dirty) {
				this.saveReqNext = () => {
					this.removeUser(user);
				};
				this.saveReqAction = "remove a user";
				this.saveReqModal = true;
			} else {
				this.removeUser(user);
			}
		},

		removeUser(user) {
			UserService.removeUserFromClient(this.client.id, user.id)
				.then((resp) => {
					notie.info(
						this.$i18n.t("notie.user_removed_from_tenant", {
							fname: user.first_name,
							lname: user.last_name,
						})
					);
					this.refreshTables();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.remove_user_fail"), err);
				});
		},

		// Convenience method to scroll a heading into view.
		// Not required for scrollspy to work
		scrollIntoView(evt) {
			evt.preventDefault();
			const href = evt.target.getAttribute("href");
			const el = href ? document.querySelector(href) : null;
			if (el) {
				this.$refs.content.scrollTop = el.offsetTop;
			}
		},

		getRoleName(roleId) {
			var role = _.find(this.client.roles, { id: roleId });
			return (role && role.name) || "Undefined";
		},

		refreshTables() {
			if (this.hasControl && this.hasControl.refresh) {
				this.hasControl.refresh();
			} else {
				console.error("Left (has assigned) table had no refresh defined");
			}
			if (this.canControl && this.canControl.refresh) {
				this.canControl.refresh();
			} else {
				console.error("Right (can assign) table had no refresh defined");
			}
		},

		checkAutoRef() {
			let genRef = this.generateAutoRef(this.client.name);
			this.autoRef = this.client.ref_id.toLowerCase() == genRef.toLowerCase();
		},

		generateAutoRef(name) {
			return name && fs.toGoodRefID(name);
		},

		changeRole(user) {
			if (user.role_id != user.sRole.id) {
				let oldRole = _.find(this.client.roles, { id: user.role_id });
				user.role_id = user.sRole.id;
				this.$set(user, "busy", true);
				UserService.saveUser(user)
					.then((resp) => {
						user.busy = false;
						notie.info(
							this.$i18n.t("notie.changed_user_role", {
								fname: user.first_name,
								lname: user.last_name,
								role: user.sRole.name,
							})
						);
						this.refreshTables();
					})
					.catch((err) => {
						user.role_id = oldRole && oldRole.id;
						user.sRole = oldRole;
						notie.error(this.$i18n.t("notie.change_user_role_fail"), err);
					});
			}
		},

		flagActionChanged(alert) {
			if (alert.action == 2) {
				alert.requires_score = true;
			}
		},

		constructObsBookingURL() {
			let l = window.location;
			let parts = window.location.href.split("/#/tenants/");
			return `${parts[0]}/v1/api/observation/tenant/${this.client.ref_id}/booking`;
		},

		isFlagCodeRemovable(alertId) {
			//if undefined then return true
			//as this is a new flag code not been saved to the database
			if (!alertId) {
				return true;
			}
			return this.flagCodesRemovableDetails[alertId];
		},
		getFlagCodeRemovableData() {
			TenantService.checkIfFlagCodesAreRemoveable(this.client.id).then((resp) => {
				this.flagCodesRemovableDetails = resp.data;
			});
		},
	},
};
</script>
