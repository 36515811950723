<template>
	<thead class="text-center">
		<tr>
			<th v-if="hideState" rowspan="4" colspan="2" class="v-mid" />
			<th v-if="!hideState" rowspan="2" class="v-mid">
				<h3 v-if="stat.state == 2" class="mb-0 text-success">{{ $t("QCPortfolio.pass") }}</h3>
				<h3 v-if="stat.state == 3" class="mb-0 text-danger">{{ $t("QCPortfolio.fail") }}</h3>
			</th>
			<th
				v-if="!hideState"
				rowspan="2"
				class="v-mid b-l"
				v-tippy="{
					theme: 'popover',
					html: `#pf-${stat.qc_set_id}`,
					placement: 'right',
				}"
			>
				<h4 class="mb-0" :class="{ 'text-success': stat.state == 2, 'text-danger': stat.state == 3 }">
					{{ percent }}%
				</h4>
			</th>

			<div v-if="!hideState" :id="`pf-${stat.qc_set_id}`" class="d-none p-0">
				<table class="table table-hover mb-0">
					<thead>
						<tr>
							<th colspan="2">
								{{ $t("QCReport.pass_fail_determination") }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(expRow, i) in stat.explanations" :key="i">
							<td>{{ expRow.label }}</td>
							<td
								:class="{
									'text-danger': expRow.color == 1,
									'text-warning': expRow.color == 2,
									'text-success': expRow.color == 3,
								}"
							>
								{{ expRow.stat }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<template v-for="trait in numericTraits">
				<th :key="trait.id" colspan="2" class="b-l">{{ trait.name }}</th>
			</template>
		</tr>
		<tr class="text-xxs">
			<template v-for="(_, i) in numericTraits">
				<th :key="`score-${i}`" class="b-l">{{ $t("QCPortfolio.scored") }}</th>
				<th :key="`true-${i}`">{{ $t("QCPortfolio.actual") }}</th>
			</template>
		</tr>
	</thead>
</template>

<script>
import fs from "@/services/FormatService";

export default {
	name: "PortfolioSetHeader",

	props: {
		hideState: Boolean,
		stat: Object,
		numericTraits: Array,
	},

	computed: {
		percent() {
			return fs.fixed1d(this.stat.percent);
		},
	},
};
</script>

<style>
</style>