<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-light bg-light b-b">
				<a class="flex navbar-brand" style="cursor: default">
					<span @click="back()" style="cursor: pointer">Rangefinding</span>
					<i v-if="response" class="far fa-angle-right"></i>
					<span v-if="response">{{ response.ref_id }}</span>
				</a>

				<a
					v-if="user.role.allow_print && response"
					@click="print(response)"
					target="_blank"
					class="title-bar-button b-l ml-0 mr-0 d-flex flex-column justify-content-center"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.print')"
				>
					<i class="fas fa-lg fa-print"></i>
				</a>
				<a
					@click="init()"
					class="title-bar-button b-l ml-0 mr-0 d-flex flex-column justify-content-center"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.refresh')"
				>
					<i class="far fa-lg fa-sync-alt" :class="{ 'fa-spin': refreshing }"></i>
				</a>
				<div class="title-bar-button b-l ml-0 d-flex flex-column justify-content-center" @click="back()">
					{{ $t("buttons.back") }}
				</div>
			</div>
		</template>

		<div class="content-main" v-if="!loading">
			<div class="d-flex flex-row h100">
				<div class="b-r p-0">
					<div class="px-3 py-2">
						<div class="d-flex flex-row flex-wrap">
							<strong class="flex">{{ $t("RFResponseReport.true_score") }}</strong>
							<qc-tag-select
								:tags="allTags"
								:customTags="response.custom_tags"
								v-model="response.qc_tags"
								@added="(qcTags, customTags) => addQcTags(response.id, qcTags, customTags)"
								@removed="(qcTags, customTags) => removeQcTags(response.id, qcTags, customTags)"
							/>
						</div>
						<rubric
							:outer-rubric="rubric"
							:score.sync="resolvedScore"
							:complete.sync="rubricComplete"
							:initScore="response.true_score"
							:fd="fd"
							:user="user"
						/>
					</div>

					<table class="table">
						<thead>
							<tr>
								<th>{{ $t("RFResponseReport.scores") }}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr
								class="pointer"
								v-if="reportRows && reportRows.length > 0"
								:class="{ active: row == selectedRow }"
								v-for="row in reportRows"
								:key="row.score.id"
								@click="selectedRow = row"
							>
								<td>
									<span
										v-if="row.user"
										v-tippy
										:title="row.user.scorer_id != '' ? row.user.full_name : null"
										>{{ fs.scorerID(row.user) }}</span
									>
								</td>
								<td>
									<inline-score :score="row.score" :rubric="rubric" :type="15" tooltip />
								</td>
							</tr>
							<tr v-if="reportRows && reportRows.length == 0">
								<td colspan="2">
									<h3 class="mb-0 text-very-muted text-center">
										{{ $t("data_description.no_scores") }}
									</h3>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col light scroll">
					<div class="row light">
						<div v-if="nav" class="col-sm-4">
							<div class="box h100 d-flex flex-column">
								<div class="box-header light">
									<h3>{{ nav.set_name }}</h3>
								</div>
								<div class="flex box-body d-flex flex-column justify-content-center">
									<div class="d-flex flex-row align-items-center">
										<button
											class="btn btn-sm"
											@click="goNav(nav.prev)"
											:class="{ theme: nav.prev }"
											:disabled="!nav.prev"
										>
											<i class="fas fa-arrow-left"></i>
										</button>
										<div class="flex text-center">
											{{
												$t("RFResponseReport.current_page", {
													current: nav.current,
													total: nav.total,
												})
											}}
										</div>
										<button
											class="btn btn-sm"
											@click="goNav(nav.next)"
											:class="{ theme: nav.next }"
											:disabled="!nav.next"
										>
											<i class="fas fa-arrow-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-8">
							<div class="box h100">
								<div class="box-header light d-flex flex-row align-items-center">
									<h3 class="flex">{{ $t("RFResponseReport.comment") }}</h3>

									<a
										class="btn-subtle margin-overlap"
										@click="editFinalFeedback"
										v-if="response.true_score"
										v-tippy="{ placement: 'bottom' }"
										:title="$t('RFResponseReport.final_annotation')"
									>
										<i
											:class="{
												[response.feedback == ' ' || !response.feedback
													? 'far fa-comment-alt'
													: 'fas fa-comment-alt-check']: true,
											}"
										/>
									</a>
								</div>
								<div v-if="selectedRow" class="box-body">
									<p
										v-if="selectedRow.score.comment"
										class="m-0"
										v-html="selectedRow.score.comment.text"
									></p>
									<h5 v-if="!selectedRow.score.comment" class="m-0 text-very-muted text-center _400">
										{{ $t("data_description.no_comment") }}
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div class="row scroll">
						<div class="col-sm-12 control-area">
							<media :response="response" :chan="chan" />
							<viewer-controls
								v-if="chan && item"
								:chan="chan"
								:item="item"
								:user="user"
								class="control-hover trans"
							></viewer-controls>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="finalFeedbackModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog modal-lg-resp">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("RFResponseReport.final_annotation") }}</h5>
					</div>
					<div class="modal-body modal-v-limit d-flex flex-column p-0">
						<div class="pt-3 px-3 pb-0">
							<RichText
								:initial="initFeedback"
								:text.sync="feedback"
								:refresh="feedbackRefresh"
								class="mb-3 border"
							/>
						</div>
						<hr class="w-100 my-0" />
						<div class="flex position-relative control-area" style="overflow: hidden">
							<div class="h-100 div-scroll px-3">
								<media :response="response" :chan="chan" :noMargin="true" />
								<viewer-controls
									v-if="chan && item"
									:chan="chan"
									:item="item"
									:user="user"
									class="control-hover trans"
								></viewer-controls>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary mr-auto" @click="constructFinalFeedback">
							{{ $t("buttons.generate_from_comments") }}
						</button>
						<button class="btn btn-flat btn-danger" data-dismiss="modal" @click="saveTrueScore(' ')">
							{{ $t("buttons.none") }}
						</button>
						<button @click="saveTrueScore(feedback)" class="btn btn-flat theme" data-dismiss="modal">
							{{ $t("buttons.save") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scroll {
	overflow: auto;
}

.title-bar-button {
	margin: -8px -16px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}

.title-bar-button:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.title-bar-button a {
	line-height: 56px;
}

a.btn-subtle {
	border-radius: 0.35rem;
	padding-left: 3px;
	padding-right: 3px;
}

a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.margin-overlap {
	margin-top: -0.5rem;
	margin-bottom: -0.5rem;
}

.control-hover {
	opacity: 0;
	z-index: 20;
}

.control-area:hover .control-hover,
.control-hover:hover {
	opacity: 1;
}

.trans {
	-webkit-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 200ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

	-webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	-o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
</style>

<script>
import AuthService from "@/services/AuthService";
import QCService from "@/services/QCService";
import ScoringService from "@/services/ScoringService";
import Notie from "@/services/NotieService";
import Score from "../../components/Score";
import Rubric from "../../components/Rubric";
import InlineScore from "@/components/InlineScore";
import QcTagSelect from "@/components/QcTagSelect";
import RichText from "@/components/RichText";
import Media from "../../components/viewer/Media";
import ViewerControls from "@/components/viewer/ViewerControls";
import UserService from "@/services/UserService";
import fs from "@/services/FormatService";
import store from "../../services/Store";
import BB from "bluebird";
import Vue from "vue";

import $ from "jquery";

export default {
	name: "RFResponseReport",
	data() {
		return {
			fs: fs,
			response: null,
			nav: null,
			reportRows: null,
			qcSet: null,
			item: null,
			rubric: null,
			fd: null,

			resolvedScore: null,
			rubricComplete: false,

			selectedRow: null,

			initFeedback: null,
			feedbackRefresh: null,
			feedback: null,

			loading: true,
			loadingScore: true,
			refreshing: true,

			chan: new Vue(),
		};
	},

	components: {
		Rubric,
		Score,
		InlineScore,
		QcTagSelect,
		Media,
		ViewerControls,
		RichText,
	},

	props: ["user", "params", "query"],

	watch: {
		resolvedScore: {
			handler() {
				if (this.rubricComplete && !this.submitting && !this.loadingScore) {
					if (!this.response.feedback) {
						this.constructFinalFeedback();
						$("#finalFeedbackModal").modal("toggle");
					} else {
						this.saveTrueScore();
					}
				}
			},
			deep: true,
		},
	},

	computed: {
		setPrev() {
			return true;
		},
		setNext() {
			return true;
		},
		anyComments() {
			let any = false;
			_.each(this.reportRows, (row) => {
				if (row.score && row.score.comment) {
					any = true;
				}
			});
			return any;
		},

		allTags() {
			return fs.qcTags().concat(this.customTags);
		},

		customTags() {
			if (!this.user) return [];
			return (this.user.client.custom_rf_tags || []).map((tagName) => ({
				label: tagName,
				abr: tagName,
				id: tagName, // should all be unique strs
			}));
		},
	},

	created() {
		store.set(this, "viewerScale", 3);

		this.loading = true;
		this.init();
	},
	methods: {
		init() {
			this.refreshing = true;
			let index = 0;
			if (this.reportRows && this.selectedRow) {
				index = this.reportRows.indexOf(this.selectedRow);
			}
			BB.props({
				response: QCService.getQcResponse(this.params.respID, this.query.set, this.query.sortedRFResponses),
				rows: QCService.getQcResponseScores(this.params.respID),
			})
				.then((resps) => {
					this.response = resps.response.data.response;
					this.nav = resps.response.data.nav;
					this.reportRows = resps.rows.data;
					this.applyCommentsToScores(this.response, this.reportRows);
					ScoringService.getItem(this.response.item_id)
						.then((itemResp) => {
							this.selectedRow = this.reportRows[index];

							this.item = itemResp.data;
							this.item.calc_enabled = false;
							this.rubric = itemResp.data.rubric;
							this.fd = this.calculateFD(_.map(this.reportRows, "score"), this.rubric);

							this.loading = false;
							setTimeout(() => {
								this.loadingScore = false;
							}, 500);
							this.refreshing = false;
						})
						.catch((err) => {
							console.log(err);
							Notie.error(this.$i18n.t("notie.response_item_load_fail"), err);
							this.refreshing = false;
						});
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.response_load_fail"), err);
					this.refreshing = false;
				});
		},

		applyCommentsToScores(resp, rows) {
			_.each(rows, (row) => {
				let comment = _.find(resp.comments, { score_id: row.score.id });
				if (comment) {
					row.score.comment = comment;
				}
			});
		},

		calculateFD(scores, rubric) {
			let fd = [];
			_.each(rubric.traits, (trait) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				let trait_fd = {
					trait_id: trait.id,
					counts: {},
				};
				let total = 0;
				_.each(scores, (score) => {
					total++;
					let sp = _.find(score.trait_scores, { trait_id: trait.id });
					if (sp) {
						if (!trait_fd.counts[sp.score]) {
							trait_fd.counts[sp.score] = 0;
						}
						trait_fd.counts[sp.score]++;
					}
				});
				trait_fd.total = total;
				fd.push(trait_fd);
			});

			return fd;
		},

		addQcTags(respID, tags, customTags) {
			QCService.applyQcTags(respID, tags, customTags)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.tag_applied"));
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.apply_tag_fail"), err);
				});
		},

		removeQcTags(respID, tags, customTags) {
			QCService.applyQcTags(respID, tags, customTags)
				.then((resp) => {
					if (tags.length == 0) {
						Notie.info(this.$i18n.t("notie.tags_removed"));
					} else {
						Notie.info(this.$i18n.t("notie.tag_removed"));
					}
				})
				.catch((err) => {
					if (tags.length == 0) {
						Notie.error(this.$i18n.t("notie.remove_tags_fail"), err);
					} else {
						Notie.error(this.$i18n.t("notie.remove_tag_fail"), err);
					}
				});
		},

		constructFinalFeedback() {
			let commentTexts = [];

			if (this.initFeedback && this.initFeedback !== " ") {
				commentTexts.push(`<div class="_600">Existing Feedback:</div><div>${this.initFeedback}</div>`);
			}

			_.each(this.reportRows, (row) => {
				if (row.score && row.score.comment) {
					let un = row.user.full_name;
					let ct = row.score.comment.text;
					commentTexts.push(`<div class="_600">${un}:</div><div>${ct}</div>`);
				}
			});
			this.initFeedback = commentTexts.join("<p></p>");
			this.feedbackRefresh = new Date();
		},

		editFinalFeedback() {
			this.initFeedback = this.response.feedback;
			$("#finalFeedbackModal").modal("toggle");
		},

		saveTrueScore(feedback) {
			if (feedback == "") {
				feedback = " ";
			}
			this.submitting = true;
			QCService.submitTrueScore(this.resolvedScore, this.response.id, feedback)
				.then((resp) => {
					Notie.info(this.$i18n.t("notie.resolved_score_saved"));
					if (feedback != null) {
						this.response.feedback = feedback;
					}
					this.submitting = false;
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.save_resolved_score_fail"), err);
					this.submitting = false;
				});
		},

		back() {
			this.$router.go(-1);
		},

		goNav(respID) {
			this.$router.replace(
				`/rf_response/${respID}?set=${this.query.set}&sortedRFResponses=${this.query.sortedRFResponses}`
			);
		},

		pc(num, den) {
			return ((num / den) * 100).toFixed(2);
		},

		print(response) {
			var windowRef = window.open();
			windowRef.location = `/v1/print/rf/${response.id}`;
		},
	},
};
</script>
