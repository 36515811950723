<template>
	<page customNavBar>
		<template #navbar>
			<div id="navbar" class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="section.loaded == 0" />
				<div v-if="section.loaded != 0" class="navbar-text nav-title flex" id="pageTitle">
					<i
						class="fas fa-fw fa-lock-alt"
						v-if="section.isScored"
						v-tippy="{ placement: 'bottom-start' }"
						title="This section has been used for scoring, so some options can no longer be changed"
					></i>
					{{ section.name }}
				</div>
			</div>
		</template>
		<div class="h-100" v-if="section.loaded != 0">
			<div class="d-sm-flex b-t h-100">
				<div class="w w-auto-xs light bg b-r">
					<div class="py-3">
						<div class="nav-active-border left b-primary">
							<ul class="nav flex-column nav-sm">
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 1, show: tab == 1 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-1"
									>
										{{ $t("SectionEdit.Sidebar.details") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('details') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 2, show: tab == 2 }"
										data-toggle="tab"
										data-target="#tab-2"
										>{{ $t("SectionEdit.Sidebar.items") }}</a
									>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 3, show: tab == 3 }"
										data-toggle="tab"
										data-target="#tab-3"
										>{{ $t("SectionEdit.Sidebar.teams") }}</a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<edit-pane :cancel="loadData" :save="saveSection" :dirty="dirty" :valid="valid">
					<div class="tab-content h-100">
						<!-- Section Details -->
						<section-edit-details
							id="tab-1"
							:section="section"
							:valid="valid"
							:defaultTab="tab == 1"
						></section-edit-details>

						<!-- Item Setup -->
						<section-edit-items
							id="tab-2"
							:section="section"
							:dirty="dirty"
							:valid="valid"
							:save="saveSection"
							:defaultTab="tab == 2"
						></section-edit-items>

						<!-- Team Setup -->
						<section-edit-teams
							id="tab-3"
							:section="section"
							:dirty="dirty"
							:valid="valid"
							:save="saveSection"
							:defaultTab="tab == 3"
						></section-edit-teams>
					</div>
				</edit-pane>
				<save-optional-modal
					:dirty="dirty"
					:valid="valid"
					:save="saveSection"
					:next="saveOptNext"
					:cancel="saveOptCancel"
					objectText="Section"
					:objectName="section.name"
					actionText="leave the page"
					v-model="saveOptModal"
				/>
			</div>
		</div>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}
</style>

<script>
import SectionEditDetails from "@/components/section/SectionEditDetails";
import SectionEditTeams from "@/components/section/SectionEditTeams";
import SectionEditItems from "@/components/section/SectionEditItems";
import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import SectionService from "@/services/SectionService";
import ValidationService from "@/services/ValidationService";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";

export default {
	name: "SectionEdit",

	props: ["user", "params", "query"],

	components: {
		"section-edit-details": SectionEditDetails,
		"section-edit-teams": SectionEditTeams,
		"section-edit-items": SectionEditItems,
		"edit-pane": EditPane,
		"save-optional-modal": SaveOptionalModal,
	},

	data() {
		return {
			fs: fs,
			tab: 1,
			section: {
				items: [],
				item_ids: [],
				teams: [],
				team_ids: [],
				project_id: null,
				project: null,
				loaded: 0,
			},
			dirty: false,
			valid: {},
			saveOptModal: false,
			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	computed: {},

	created() {
		this.loadData();
		this.initValidation();

		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: "You must assign a name",
					func: () => {
						return this.section && this.section.name && this.section.name != "";
					},
				},
				ref: {
					group: "details",
					errorMsg: "You must assign a reference ID",
					func: () => {
						return this.section && this.section.ref_id && this.section.ref_id != "";
					},
				},
				ref_char: {
					group: "details",
					errorMsg:
						"Reference IDs may not contain spaces or any of the following characters: ! # % ^ * ( )  / [ ] { } < > ? | ' \" :",
					func: () => {
						return this.section && this.section.ref_id && fs.isGoodRefID(this.section.ref_id);
					},
				},
				project: {
					group: "details",
					errorMsg: "You must choose a project",
					func: () => {
						return this.section && this.section.project && this.section.project_id;
					},
				},
				training_course: {
					group: "details",
					errorMsg: "You must select a training course",
					func: () => {
						return (
							this.section &&
							(!this.section.requiresTraining || (this.section.training_course_ids || []).length > 0)
						);
					},
				},
			});
		},

		blankSection() {
			return {
				items: [],
				item_ids: [],
				teams: [],
				team_ids: [],
				project_id: null,
				project: null,
				loaded: 0,
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"section",
				(newc, old) => {
					// console.log(newc, old);
					// console.log(Utils.diff(newc, old));
					// console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;
			var loaded = this.section.loaded;
			loaded++;

			if (this.params.id != "new") {
				return Promise.all([SectionService.getSection(this.params.id), SectionService.isScored(this.params.id)])
					.then((resps) => {
						//resps[0] - getSection
						this.section = resps[0].data;
						this.section.loaded = loaded;
						this.$set(
							this.section,
							"requiresTraining",
							(this.section.training_course_ids || []).length > 0 ? true : false
						);

						//resps[1] - isScored
						this.section.isScored = resps[1].data;

						this.watchChanges();
					})
					.catch((err) => {
						notie.error(this.$i18n.t("notie.section_not_found"));
						this.$router.replace("/sections/new");
					});
			} else {
				this.section = this.blankSection();
				this.section.loaded = loaded;
				this.$set(this.section, "name", "New Section");
				this.$set(this.section, "ref_id", "new_section");
				this.watchChanges();
			}
		},

		saveSection() {
			if (!this.section.requiresTraining) {
				this.section.training_course_ids = [];
			}
			return SectionService.saveSection(this.section)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.section_saved"));
					if (this.section.id != resp.data.id) {
						this.$router.replace("/sections/" + resp.data.id);
					}
					this.loadData();
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.save_section_fail"), err);
				});
		},

		canSave() {
			return this.dirty;
		},
	},
};
</script>
