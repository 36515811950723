<template>
	<table class="table table-striped">
		<tr v-if="currentSection">
			<td :colspan="columns">
				<div class="d-flex flex-row align-items-center">
					<h3 class="my-2 ml-2">{{ currentSection.name }}</h3>
					<button
						v-tippy
						:title="$t('Observation.mark_no_shows')"
						class="btn btn-icon btn-rounded btn-danger ml-auto mr-2"
						@click="openNoShowModal"
					>
						<i class="fas fa-user-alt-slash" />
					</button>
				</div>
			</td>
		</tr>
		<template v-if="session && currentSection">
			<tr>
				<td class="v-mid">
					<div class="text-muted text-md">Stations</div>
				</td>
				<td v-for="(station, i) in session.stations" :key="i">
					<div
						v-tippy
						:title="stationTooltip(station.sequence, station.candidate)"
						class="text-lg text-center _600"
					>
						{{ station.sequence }}
					</div>
				</td>
			</tr>
			<tr v-for="task in currentSection.tasks" :key="task.id">
				<td>
					<div class="text-md">{{ task.name }}</div>
					<div class="text-muted">{{ task.description }}</div>
				</td>
				<td v-for="(station, i) in session.stations" :key="i" class="v-mid text-center">
					<template v-if="isAbsent(station.candidate)">
						<div class="text-extra-muted">Absent</div>
					</template>
					<template v-else>
						<button
							v-if="getScore(station.candidate, task.id)"
							class="btn score-button score-button-yes"
							@click="openSubTaskModal(station.candidate, task)"
						>
							Yes
						</button>
						<button
							v-else
							class="btn btn-danger score-button score-button-no"
							@click="openSubTaskModal(station.candidate, task)"
						>
							<div>No</div>
							<div class="text-xxs">
								<span v-for="(code, i) in getCodes(station.candidate, task.id)" :key="i">{{
									code
								}}</span>
							</div>
						</button>
					</template>
				</td>
			</tr>
		</template>

		<b-modal id="subTaskModal" v-model="showSubTaskModal" @hide="showSubTaskModal = false">
			<template slot="modal-header">
				<div class="mx-3">
					<h5 class="modal-title">Choose Ineffectives</h5>
					<div v-if="scoringTask">{{ scoringTask.name }} - {{ scoringTask.description }}</div>
				</div>
			</template>

			<div class="pt-3" v-if="scoringTask && scoringCandidate">
				<div class="d-flex flex-row justify-content-around">
					<div class="text-center mx-2">
						<h5>{{ scoringCandidate.first_name }} {{ scoringCandidate.last_name }}</h5>
						<div class="text-muted">Candidate</div>
					</div>
					<div class="text-center mx-2">
						<h5>
							{{ getModalScore() }}
							<span class="text-xxs">
								<span v-for="(code, i) in getModalCodes()" :key="i">{{ code }}</span>
							</span>
						</h5>
						<div class="text-muted">Score</div>
					</div>
				</div>
				<hr />

				<div class="card modal-scroll" style="max-height: calc(100vh - 350px)">
					<h6 class="px-3 py-2 mb-0">Choose a reason that this task was not completed successfully:</h6>
					<table class="table table-striped mb-0">
						<tbody>
							<tr
								v-for="subtask in scoringTask.subtasks"
								:key="subtask.id"
								class="clickable hover-darken"
								@click="toggleCode(subtask.id)"
							>
								<td class="v-mid">
									<div class="check-container" style="pointer-events: none">
										<label class="md-check md-check-lg check-label">
											<input type="checkbox" :checked="scoringCodes.includes(subtask.id)" />
											<i class="theme-accent"></i>
										</label>
									</div>
								</td>
								<td
									class="text-md text-center v-mid pl-3"
									:class="{ 'text-very-muted': !scoringCodes.includes(subtask.id) }"
								>
									{{ subtask.code }}
								</td>
								<td class="v-mid">
									{{ subtask.name }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<template slot="modal-footer">
				<button class="mx-auto btn btn-primary btn-lg" @click="applyScoreFromModal()">
					{{ $t("buttons.save") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="noShowModal" v-model="showNoShowModal" @hide="showNoShowModal = false">
			<template slot="modal-header">
				<div class="mx-3">
					<h5 class="modal-title">{{ $t("Observation.mark_no_shows") }}</h5>
				</div>
			</template>

			<div v-if="session">
				<table class="w-100">
					<thead>
						<tr>
							<th style="width: 20px"></th>
							<th style="min-width: 90px">Station</th>
							<th style="width: 100%">Candidate</th>
							<th></th>
							<th style="width: 20px"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(station, i) in session.stations" :key="i">
							<template v-if="station.no_show">
								<td></td>
								<td class="text-danger">No Show</td>
								<td class="text-muted text-strikethrough">
									{{ station.candidate.first_name }} {{ station.candidate.last_name }}
								</td>
								<td>
									<button
										class="btn btn-success btn-icon btn-sm btn-rounded mr-3"
										v-tippy
										:title="$t('Observation.mark_as_present')"
										@click="unmarkAsNoShow(station)"
									>
										<i class="fas fa-plus" />
									</button>
								</td>
								<td></td>
							</template>
							<template v-else>
								<td></td>
								<td>{{ station.sequence }}</td>
								<td>{{ station.candidate.first_name }} {{ station.candidate.last_name }}</td>
								<td>
									<button
										class="btn btn-danger btn-icon btn-sm btn-rounded mr-3"
										v-tippy
										:title="$t('Observation.mark_as_no_show')"
										@click="markAsNoShow(station)"
									>
										<i class="fas fa-times" />
									</button>
								</td>
								<td></td>
							</template>
						</tr>
					</tbody>
				</table>
			</div>

			<template slot="modal-footer">
				<button class="btn btn-primary" @click="applyNoShows()">
					{{ $t("buttons.apply") }}
				</button>
			</template>
		</b-modal>
	</table>
</template>

<style scoped>
label {
	position: absolute;
	right: 9px;
	top: 6px;
	z-index: 2;
}
input {
	position: absolute;
	left: 0px;
	top: 0px;
	padding-right: 26px;
	z-index: 1;
}

.score-button {
	min-width: 51px;
	min-height: 51px;
}

.check-container {
	position: relative;
	text-align: center;
	width: 20px;
	height: 23px;
}
.md-check-lg {
	top: 0 !important;
	right: -10px !important;
	transform: scale(1.25);
}

.clickable {
	cursor: pointer;
}

.hover-darken:hover {
	background-color: rgb(238, 238, 238);
}
</style>

<script>
import _ from "lodash";

export default {
	name: "ObservationScoreCard",
	props: ["form", "session", "showSectionID", "scores"],

	data() {
		return {
			scoringCandidate: null,
			scoringTask: null,
			scoringCodes: [],
			showSubTaskModal: false,
			showNoShowModal: false,
		};
	},
	created() {
		this.applyAbsents();
	},
	computed: {
		currentSection() {
			if (!this.showSectionID && this.showSectionID != "") return null;
			let section = _.find(this.form.sections, { id: this.showSectionID });
			return section;
		},
		columns() {
			if (!this.session && this.session.stations) return 1;
			return this.session.stations.length + 1;
		},
	},
	watch: {
		currentSection() {
			this.applyAbsents();
		},
	},
	methods: {
		openSubTaskModal(candidate, task) {
			this.scoringCandidate = candidate;
			this.scoringTask = task;
			this.scoringCodes = [];
			this.showSubTaskModal = true;

			for (let c in this.scores) {
				if (this.scores[c].candidate_id == candidate.id) {
					for (let s in this.scores[c].section_scores) {
						if (this.scores[c].section_scores[s].section_id == this.currentSection.id) {
							for (let t in this.scores[c].section_scores[s].task_scores) {
								if (this.scores[c].section_scores[s].task_scores[t].task_id == task.id) {
									this.scoringCodes = _.clone(this.scores[c].section_scores[s].task_scores[t].codes);
								}
							}
						}
					}
				}
			}
		},

		openNoShowModal() {
			this.showNoShowModal = true;
		},

		applyNoShows() {
			this.applyAbsents();
			this.showNoShowModal = false;
			console.log("Emit no shows changed");
			this.$emit("noShowsChanged");
		},

		getModalScore() {
			if (this.scoringCodes && this.scoringCodes.length > 0) {
				return 0;
			}

			return 1;
		},

		applyScoreFromModal() {
			let score = this.getModalScore();
			this.applyScore(this.scoringCandidate, this.scoringTask, score, this.scoringCodes);

			this.showSubTaskModal = false;
		},

		applyNo(candidate, task) {
			this.applyScore(candidate, task, 0, this.scoringCodes);
		},

		applyYes(candidate, task) {
			this.applyScore(candidate, task, 1, []);
		},

		applyAbsents() {
			for (let station of this.session.stations) {
				if (station.no_show) {
					this.applyAbsent(station.candidate);
				} else {
					this.applyNotAbsent(station.candidate);
				}
			}
		},

		applyAbsent(candidate) {
			for (let c in this.scores) {
				if (this.scores[c].candidate_id == candidate.id) {
					for (let s in this.scores[c].section_scores) {
						if (this.scores[c].section_scores[s].section_id == this.currentSection.id) {
							this.scores[c].section_scores[s].absent = true;
						}
					}
				}
			}
		},

		applyNotAbsent(candidate) {
			for (let c in this.scores) {
				if (this.scores[c].candidate_id == candidate.id) {
					for (let s in this.scores[c].section_scores) {
						if (this.scores[c].section_scores[s].section_id == this.currentSection.id) {
							this.scores[c].section_scores[s].absent = false;
						}
					}
				}
			}
		},

		applyScore(candidate, task, value, codes) {
			for (let c in this.scores) {
				if (this.scores[c].candidate_id == candidate.id) {
					for (let s in this.scores[c].section_scores) {
						if (this.scores[c].section_scores[s].section_id == this.currentSection.id) {
							for (let t in this.scores[c].section_scores[s].task_scores) {
								if (this.scores[c].section_scores[s].task_scores[t].task_id == task.id) {
									this.scores[c].section_scores[s].task_scores[t].value = value;
									this.scores[c].section_scores[s].task_scores[t].codes = codes;
								}
							}
						}
					}
				}
			}
		},

		isAbsent(candidate) {
			for (let c in this.scores) {
				if (this.scores[c].candidate_id == candidate.id) {
					for (let s in this.scores[c].section_scores) {
						if (this.scores[c].section_scores[s].section_id == this.currentSection.id) {
							return this.scores[c].section_scores[s].absent;
						}
					}
				}
			}
		},

		getScore(candidate, taskID) {
			for (let c in this.scores) {
				if (this.scores[c].candidate_id == candidate.id) {
					for (let s in this.scores[c].section_scores) {
						if (this.scores[c].section_scores[s].section_id == this.currentSection.id) {
							for (let t in this.scores[c].section_scores[s].task_scores) {
								if (this.scores[c].section_scores[s].task_scores[t].task_id == taskID) {
									return this.scores[c].section_scores[s].task_scores[t].value;
								}
							}
						}
					}
				}
			}
		},

		getCodes(candidate, taskID) {
			for (let c in this.scores) {
				if (this.scores[c].candidate_id == candidate.id) {
					for (let s in this.scores[c].section_scores) {
						if (this.scores[c].section_scores[s].section_id == this.currentSection.id) {
							for (let t in this.scores[c].section_scores[s].task_scores) {
								if (this.scores[c].section_scores[s].task_scores[t].task_id == taskID) {
									let codeSymbols = [];
									let task = _.find(this.currentSection.tasks, { id: taskID });
									if (task) {
										_.each(this.scores[c].section_scores[s].task_scores[t].codes, (cid) => {
											let codeDef = _.find(task.subtasks, { id: cid });
											if (codeDef) {
												codeSymbols.push(codeDef.code);
											}
										});
									}
									codeSymbols.sort();
									return codeSymbols;
								}
							}
						}
					}
				}
			}
		},

		getModalCodes() {
			let codeSymbols = [];

			if (!(this.scoringCodes && this.scoringTask)) {
				console.error("Failed to get codes/task", this.scoringCodes, this.scoringTask);
				return codeSymbols;
			}

			_.each(this.scoringCodes, (cid) => {
				let codeDef = _.find(this.scoringTask.subtasks, { id: cid });
				if (codeDef) {
					codeSymbols.push(codeDef.code);
				}
			});
			codeSymbols.sort();
			return codeSymbols;
		},

		toggleCode(code, loc) {
			if (this.scoringCodes.includes(code)) {
				this.scoringCodes = _.without(this.scoringCodes, code);
			} else {
				this.scoringCodes.push(code);
			}
		},

		stationTooltip(num, user) {
			return `<div class="text-muted text-xxs">Station ${num}</div><div>${user.first_name} ${user.last_name}</div>`;
		},

		markAsNoShow(station) {
			station.no_show = true;
			this.$forceUpdate();
		},

		unmarkAsNoShow(station) {
			station.no_show = false;
			this.$forceUpdate();
		},
	},
};
</script>
