<template>
	<div class="row">
		<div class="col-12">
			<label class="_600">{{ title }}</label>
		</div>
		<div v-for="elem in value" :key="elem.id" class="qual-req-card col-md-12 col-12">
			<div class="card box mb-2 py-2 px-3 d-flex flex-row align-items-center">
				<div class="text-md _600 mr-3">{{ getElemText(elem) }}</div>
				<button
					class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0 ml-auto"
					v-tippy
					:title="$t('ListConfig.edit', { elemName })"
					@click="editElem(elem)"
				>
					<i class="fa fa-cog"></i>
				</button>
				<button
					class="btn btn-sm btn-icon btn-rounded red text-white m-0 ml-1"
					v-tippy
					:title="$t('ListConfig.remove', { elemName })"
					@click="removeElem(elem)"
				>
					<i class="fa fa-trash"></i>
				</button>
			</div>
		</div>
		<div class="col-12">
			<button
				class="btn btn-sm btn-icon btn-rounded success text-white mt-2 mb-3"
				@click="addNewElem"
				v-tippy
				:title="$t('ListConfig.add', { elemName })"
			>
				<i class="far fa-plus"></i>
			</button>
		</div>

		<slot name="modal" v-if="editingElem" v-bind="{ editingElem, ok, close }" />
	</div>
</template>

<script>
export default {
	name: "valueConfig",

	components: {},

	props: {
		value: Array,
		title: String,
		elemName: String,
		newElem: Function,
		elemText: Function,
	},

	data() {
		return {
			editingElem: null,
			revertElem: null,
		};
	},

	methods: {
		ok() {
			const toAdd = this.editingElem;
			this.editingElem = null;
			if (toAdd.new) {
				toAdd.new = false;
				this.emitList(this.value.concat(toAdd));
			}
		},

		close() {
			this.editingElem = null;
		},

		editElem(elem) {
			this.revertElem = _.cloneDeep(elem);
			this.editingElem = elem;
		},

		removeElem(toRemove) {
			this.emitList(this.value.filter((elem) => elem !== toRemove));
		},

		addNewElem() {
			this.editingElem = { new: true, ...this.newElem() };
		},

		emitList(list) {
			this.$emit("input", list);
		},

		getElemText(elem) {
			return this.elemText(elem);
		},
	},
};
</script>