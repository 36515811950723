<template>
	<div>
		<div class="box mb-0">
			<div v-if="correct" class="box-header success">
				<h3>{{ $t("Viewer.correct_qc_score", { qcName }) }}</h3>
			</div>
			<div v-else class="box-header danger">
				<h3>{{ $t("Viewer.incorrect_qc_score", { qcName }) }}</h3>
			</div>
			<div class="box-body">
				<p class="m-0">{{ $t("Viewer.please_review_feedback") }}</p>
			</div>
		</div>

		<score
			:score="userScore"
			:rubric="rubric"
			:compareScore="response.true_score"
			:type="scoreType"
			style="max-width: 460px"
			class="my-3"
		/>

		<div class="box h100 mb-3">
			<div class="box-header light">
				<h3>{{ $t("QCUserSetReport.feedback") }}</h3>
			</div>
			<div class="box-body">
				<p class="m-0" v-html="response.feedback"></p>
			</div>
		</div>

		<button class="btn theme mb-5 float-right" @click="ok">
			{{ $t("buttons.ok") }}
		</button>
		<div class="my-5"></div>
	</div>
</template>

<script>
import Score from "@/components/Score";
import { QC_TYPES, SCORE_TYPES, idToName } from "@/services/Constants";

export default {
	name: "QcFeedback",

	components: {
		Score,
	},

	props: {
		userScore: Object,
		response: Object,
		rubric: Object,
		correct: Boolean,
	},

	methods: {
		ok() {
			this.$emit("ok");
		},
	},

	computed: {
		qcName() {
			return idToName(QC_TYPES, this.response.qc_type);
		},

		scoreType() {
			const { PRACTICE, VALIDITY } = QC_TYPES;

			switch (this.response.qc_type) {
				case PRACTICE.id:
					return SCORE_TYPES.PRACTICE.id;
				case VALIDITY.id:
					return SCORE_TYPES.VALIDITY.id;
			}
		},
	},
};
</script>

<style></style>
