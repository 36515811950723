<template>
	<page :title="$t('Dashboard.title')">
		<div class="row mx-0 no-grow px-2 pt-3 b-b box mb-0">
			<div class="col-12 col-sm-6 d-flex align-items-center pb-3">
				<label class="no-grow mb-0 mr-3" style="width: 50px">{{ $t("fields.team") }}:</label>
				<div class="flex">
					<config-select
						:options="teams"
						v-model="selectedTeam"
						:nullOption="true"
						style="max-width: 300px"
					></config-select>
				</div>
			</div>
		</div>
		<div class="padding">
			<div class="row">
				<div class="col-4 col-xl-4">
					<super-dash-assignments :team="selectedTeam" :user="user" />
				</div>
				<div class="col-8">
					<super-dash-weekly :team="selectedTeam" />
				</div>

				<div class="col-12">
					<super-dash-user-activity :team="selectedTeam" :user="user" />
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-main {
	background-color: #f3f6f9;
}
</style>

<script>
import _ from "lodash";
import ConfigService from "@/services/ConfigService";
import notie from "@/services/NotieService";
import store from "@/services/Store";

import ConfigSelect from "@/components/ConfigSelect";
import SuperDashAssignments from "@/components/super_dashboard/SuperDashAssignments";
import SuperDashUserActivity from "@/components/super_dashboard/SuperDashUserActivity";
import SuperDashWeekly from "@/components/super_dashboard/SuperDashWeekly";

export default {
	name: "Dashboard",
	props: ["user"],
	data() {
		return {
			teams: [],
			selectedTeam: null,
		};
	},
	components: {
		ConfigSelect,
		SuperDashAssignments,
		SuperDashUserActivity,
		SuperDashWeekly,
	},
	created() {
		this.storagePrefix = "superdash.";

		let teamCall = ConfigService.listTeams;
		if (this.user.role.limit_teams) {
			teamCall = ConfigService.listUserTeams;
		}

		teamCall()
			.then((resp) => {
				this.teams = resp.data.teams;
				if (this.user.role.limit_teams) {
					this.teams = resp.data;
				}
				this.loading = false;
			})
			.catch((err) => {
				console.log(err);
				notie.error("Failed to load config options", err);
				this.loading = false;
			});

		// let r = this.$router.resolve(
		// 	"/scoring/5ac42d8d424cf80001f31178/5ac42d8d424cf80001f31179"
		// );
		// console.log("THING", r.resolved);
	},
	mounted() {},
	methods: {},
};
</script>


