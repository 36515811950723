<template>
	<b-modal :visible="visible" v-if="visible">
		<template slot="modal-title">{{ $t("ScoreImportModal.title") }}</template>
		<div class="row p-4">
			<div class="col-12 form-group">
				<h5>{{ $t("ScoreImportModal.import_to", { name: item.name }) }}</h5>
			</div>
			<div class="col-12 form-group">
				<label>{{ $t("ScoreImportModal.import_type") }}</label>
				<v-select :options="importOptions" v-model="importType"></v-select>
			</div>

			<!--No import type selected-->
			<div v-if="!importType" class="col-12">
				<p>{{ $t("ScoreImportModal.supported_methods") }}</p>
				<ul style="list-style-type: circle">
					<li style="list-style-type: circle">{{ $t("ScoreImportModal.upload_csv") }}</li>
					<li style="list-style-type: circle">
						{{ $t("ScoreImportModal.import_from_ftp") }}
						<span style="color: red">(NYI)</span>
					</li>
					<li style="list-style-type: circle">
						{{ $t("ScoreImportModal.import_from_drive") }}
						<span style="color: red">(NYI)</span>
					</li>
				</ul>
			</div>

			<!--Import CSV-->
			<div v-if="importType && (importType.id == 1 || importType.id == 2)" class="col-12">
				<div class="form-group">
					<input id="response-import-input-1" class="d-none" type="file" @change="selectImportFile" />
					<button class="btn hover-darken theme-accent btn-block" @click="click('response-import-input-1')">
						{{ $t("buttons.select_file") }}
					</button>
				</div>
				<div class="form-group">
					<input type="text" class="form-control" v-model="importingFile.name" readonly />
				</div>
				<div v-if="importType.id == 1" class="form-group">
					<p>{{ $t("ScoreImportModal.things_to_remember") }}</p>
					<ul style="list-style-type: circle" class="mb-0">
						<li style="list-style-type: circle">{{ $t("ScoreImportModal.only_csv") }}</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.only_first_second") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.not_reversible") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.header_row") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.columns_description") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.third_column") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.example") }}
						</li>
					</ul>
					<div class="scroll-x">
						<table class="table my-2 mx-auto table-bordered">
							<tr>
								<th class="px-2 py-0">{{ $t("fields.response_ref_id") }}</th>
								<th class="px-2 py-0">{{ $t("fields.scorer_id") }}</th>
								<template v-for="trait in item.rubric.traits">
									<th v-if="!(trait.separator || trait.is_parent)" class="px-2 py-0" :key="trait.id">
										{{ trait.name }}
									</th>
								</template>
							</tr>
							<tr>
								<td class="px-2 py-0">response_0001</td>
								<td class="px-2 py-0">scorer_0001</td>
								<template v-for="trait in item.rubric.traits">
									<td v-if="!(trait.separator || trait.is_parent)" class="px-2 py-0" :key="trait.id">
										{{ trait.min }}
									</td>
								</template>
							</tr>
							<tr>
								<td class="px-2 py-0">...</td>
								<td class="px-2 py-0">...</td>
								<template v-for="trait in item.rubric.traits">
									<td v-if="!(trait.separator || trait.is_parent)" class="px-2 py-0" :key="trait.id">
										...
									</td>
								</template>
							</tr>
						</table>
					</div>
				</div>
				<div v-if="importType.id == 2" class="form-group">
					<p>{{ $t("ScoreImportModal.school_codes_remember") }}</p>
					<ul style="list-style-type: circle" class="mb-0">
						<li style="list-style-type: circle">{{ $t("ScoreImportModal.only_csv") }}</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.school_codes_header") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.school_codes_columns") }}
						</li>
						<li style="list-style-type: circle">
							{{ $t("ScoreImportModal.example") }}
						</li>
					</ul>
					<div class="scroll-x">
						<table class="table my-2 mx-auto table-bordered">
							<tr>
								<th class="px-2 py-0">{{ $t("fields.response_ref_id") }}</th>
								<th class="px-2 py-0">{{ $t("fields.school_code") }}</th>
							</tr>
							<tr>
								<td class="px-2 py-0">response_0001</td>
								<td class="px-2 py-0">school_0001</td>
							</tr>
							<tr>
								<td class="px-2 py-0">...</td>
								<td class="px-2 py-0">...</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
		<template slot="modal-footer">
			<button class="btn primary btn-flat" @click="stopImport">{{ $t("buttons.cancel") }}</button>
			<button
				v-if="(importType.id == 1 || importType.id == 2) && !importingScores"
				class="btn btn-flat"
				@click="importScores(section, item, importingFile)"
				:disabled="!importingFile"
				:class="{ success: importingFile }"
			>
				{{ $t("buttons.import") }}
			</button>
			<button
				v-if="(importType.id == 1 || importType.id == 2) && importingScores"
				class="btn btn-secondary btn-flat"
				disabled
			>
				{{ $t("buttons.importing") }}&nbsp;&nbsp;
				<loading type="icon" />
			</button>
		</template>
	</b-modal>
</template>

<style scoped></style>

<script>
import SectionService from "@/services/SectionService";
import bb from "bluebird";

import Notie from "@/services/NotieService";

export default {
	name: "ScoreImportModal",
	props: ["section", "item", "visible"],

	data() {
		return {
			importingFile: false,
			importingScores: false,
			importOptions: [
				{ label: this.$i18n.t("import_options.scores_csv"), id: 1 },
				{ label: this.$i18n.t("import_options.school_codes_csv"), id: 2 },
			],
			importType: null,
		};
	},
	created() {
		this.importType = this.importOptions[0];
	},
	watch: {},
	computed: {
		newFilesNames() {
			let names = [];
			_.each(this.newFiles, (file) => {
				names.push(file.name);
			});
			return names.join(", ");
		},
	},
	methods: {
		selectImportFile(event) {
			if (event.target.files[0]) {
				this.importingFile = event.target.files[0];
			}
		},

		stopImport() {
			this.$emit("cancel");
			this.importingFile = false;
			this.importingScores = false;
		},

		importScores(section, item, file) {
			console.log(file.type);
			if (file.type != "text/csv" && file.type != "application/vnd.ms-excel") {
				Notie.error(this.$i18n.t("notie.must_be_csv"));
				return;
			}

			var data = new FormData();
			data.append("file", file);
			data.append("itemId", item.id);
			data.append("sectionId", section.id);

			if (this.importType.id == 1) {
				this.importToLive(data);
			} else if (this.importType.id == 2) {
				this.importSchoolCodes(data);
			}
		},

		importToLive(data) {
			this.importingScores = true;
			SectionService.importScoresCSV(data)
				.then((resp) => {
					this.stopImport();
					Notie.info(
						this.$i18n.t("notie.uploaded_scores_to", {
							num: resp.data.num_scores,
							name: this.item.name,
						})
					);
					this.$emit("refresh");
				})
				.catch((err) => {
					console.log(err);
					this.importingScores = false;
					Notie.error(this.$i18n.t("notie.upload_fail"), err);
				});
		},

		importSchoolCodes(data) {
			this.importingScores = true;
			SectionService.importSchoolCodesCSV(data)
				.then((resp) => {
					this.stopImport();
					Notie.info(
						this.$i18n.t("notie.uploaded_school_codes_to", {
							num: resp.data.num_scores,
							name: this.item.name,
						})
					);
					this.$emit("refresh");
				})
				.catch((err) => {
					console.log(err);
					this.importingScores = false;
					Notie.error(this.$i18n.t("notie.upload_fail"), err);
				});
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},
	},
};
</script>
