var render = function render(){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"text-center"},[_c('tr',[(_vm.hideState)?_c('th',{staticClass:"v-mid",attrs:{"rowspan":"4","colspan":"2"}}):_vm._e(),(!_vm.hideState)?_c('th',{staticClass:"v-mid",attrs:{"rowspan":"2"}},[(_vm.stat.state == 2)?_c('h3',{staticClass:"mb-0 text-success"},[_vm._v(_vm._s(_vm.$t("QCPortfolio.pass")))]):_vm._e(),(_vm.stat.state == 3)?_c('h3',{staticClass:"mb-0 text-danger"},[_vm._v(_vm._s(_vm.$t("QCPortfolio.fail")))]):_vm._e()]):_vm._e(),(!_vm.hideState)?_c('th',{directives:[{name:"tippy",rawName:"v-tippy",value:({
				theme: 'popover',
				html: `#pf-${_vm.stat.qc_set_id}`,
				placement: 'right',
			}),expression:"{\n\t\t\t\ttheme: 'popover',\n\t\t\t\thtml: `#pf-${stat.qc_set_id}`,\n\t\t\t\tplacement: 'right',\n\t\t\t}"}],staticClass:"v-mid b-l",attrs:{"rowspan":"2"}},[_c('h4',{staticClass:"mb-0",class:{ 'text-success': _vm.stat.state == 2, 'text-danger': _vm.stat.state == 3 }},[_vm._v(" "+_vm._s(_vm.percent)+"% ")])]):_vm._e(),(!_vm.hideState)?_c('div',{staticClass:"d-none p-0",attrs:{"id":`pf-${_vm.stat.qc_set_id}`}},[_c('table',{staticClass:"table table-hover mb-0"},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("QCReport.pass_fail_determination"))+" ")])])]),_c('tbody',_vm._l((_vm.stat.explanations),function(expRow,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(expRow.label))]),_c('td',{class:{
								'text-danger': expRow.color == 1,
								'text-warning': expRow.color == 2,
								'text-success': expRow.color == 3,
							}},[_vm._v(" "+_vm._s(expRow.stat)+" ")])])}),0)])]):_vm._e(),_vm._l((_vm.numericTraits),function(trait){return [_c('th',{key:trait.id,staticClass:"b-l",attrs:{"colspan":"2"}},[_vm._v(_vm._s(trait.name))])]})],2),_c('tr',{staticClass:"text-xxs"},[_vm._l((_vm.numericTraits),function(_,i){return [_c('th',{key:`score-${i}`,staticClass:"b-l"},[_vm._v(_vm._s(_vm.$t("QCPortfolio.scored")))]),_c('th',{key:`true-${i}`},[_vm._v(_vm._s(_vm.$t("QCPortfolio.actual")))])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }