<template>
	<page customNavBar customBody>
		<template #navbar>
			<div id="navbar" class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<div class="navbar-text nav-title flex" id="pageTitle">{{ $t("TeamSetup.title") }}</div>

				<!-- Show inactive -->
				<div class="checkbox mr-3">
					<label class="md-check">
						<input v-model="showInactive" type="checkbox" />
						<i class="theme-accent"></i>
						{{ $t("TeamSetup.show_inactive") }}
					</label>
				</div>

				<!-- Create new -->
				<div class="py-0">
					<a href="#/teams/new" class="btn btn-sm hover-darken theme-accent btn-block">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("TeamSetup.new_team") }}</span>
					</a>
				</div>

				<!-- Search field -->
				<div class="collapse navbar-collapse no-grow order-lg-1" id="navbarToggler">
					<form class="input-group m-2 my-lg-0">
						<span class="input-group-btn">
							<button
								v-if="!filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="cursor: default; width: 35px"
							>
								<i class="fa fa-search"></i>
							</button>
							<button
								v-if="filter"
								type="button"
								class="btn no-border no-bg no-shadow"
								style="width: 35px"
								@click="filter = ''"
							>
								<i class="fa fa-times"></i>
							</button>
						</span>
						<input
							type="text"
							v-model="filter"
							class="form-control"
							:placeholder="$t('TeamSetup.search_teams_placeholder')"
						/>
					</form>
				</div>
			</div>
		</template>

		<!-- Page Content -->
		<div class="content-main">
			<div class>
				<!-- Page content goes here -->
				<b-table
					ref="table"
					id="teamtable"
					responsive="lg"
					striped
					hover
					:show-empty="true"
					:items="provider"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<!-- Active -->
					<template #cell(active)="data">
						<div class="mx-auto w40">
							<label class="ui-switch ui-switch-md theme-accent">
								<input @change="setActive(data.item)" type="checkbox" v-model="data.item.active" />
								<i></i>
							</label>
						</div>
					</template>

					<!-- Actions -->
					<template #cell(actions)="data">
						<div class="mx-auto w60">
							<a
								:href="`#/teams/${data.item.id}`"
								class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
								v-tippy
								:title="$t('tooltip.edit')"
							>
								<i class="far fa-edit"></i>
							</a>
							<button
								v-if="!data.item.inProgress"
								@click="deleting = data.item"
								class="btn btn-sm btn-icon btn-rounded hover-darken red text-white m-0"
								v-tippy
								:title="$t('tooltip.delete')"
							>
								<i class="far fa-trash"></i>
							</button>
							<button
								v-if="data.item.inProgress"
								class="btn btn-sm btn-icon btn-rounded m-0"
								v-tippy
								title="Removing..."
							>
								<loading type="icon" />
							</button>
						</div>
					</template>

					<template slot="empty">
						<loading type="table" v-if="loading" />
						<div v-if="!loading">{{ $t("data_description.no_records") }}</div>
					</template>
				</b-table>
			</div>
		</div>

		<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
			<a class="flex mx-3"
				>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
				{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
			>
			<div>
				<b-pagination
					size="md"
					class="m-1"
					:total-rows="totalRows"
					v-model="currentPage"
					:per-page="perPage"
				></b-pagination>
			</div>
		</div>

		<b-modal id="deleteConfirm" :visible="deleting != false" v-if="deleting">
			<template slot="modal-title">{{ $t("TeamSetup.delete_team") }}</template>
			<div class="row p-4">
				{{ $t("modal.confirm_delete") }}&nbsp; <strong class="text-theme">{{ deleting.name }}</strong
				>?
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="deleting = false">{{ $t("buttons.cancel") }}</button>
				<button class="btn danger btn-flat" @click="removeTeam(deleting)">{{ $t("buttons.delete") }}</button>
			</template>
		</b-modal>
	</page>
</template>

<style scoped>
.w40 {
	width: 40px;
}
.w60 {
	width: 62px;
}
</style>

<script>
//UI Components

//Libraries
import _ from "lodash";

//Services
import AuthService from "@/services/AuthService";
import TeamService from "@/services/TeamService";
import ViewportService from "@/services/ViewportService";
import store from "@/services/Store";
import notie from "@/services/NotieService";

export default {
	name: "TeamSetup",

	props: ["user"],

	data() {
		return {
			fields: [
				{ key: "name", label: this.$i18n.t("fields.name"), sortable: true },
				{ key: "desc", label: this.$i18n.t("fields.description"), sortable: true },
				{
					key: "active",
					label: this.$i18n.t("fields.active"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid switch-fix",
					sortable: true,
				},
				{
					key: "actions",
					label: this.$i18n.t("fields.actions"),
					thStyle: { "text-align": "center" },
					tdClass: "p-custom p-0 v-mid",
				},
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			filter: "",
			sortBy: null,
			sortDesc: false,
			showInactive: true,
			loading: true,

			deleting: false,
		};
	},

	components: {},

	created() {
		//When resizing the window, change the number of rows in the BTable
		//based on the window height, but not too often
		// this.resizeFunc = _.debounce(() => {
		// 	ViewportService.resizeBTable("navbar", "pages", 46, 45, this);
		// }, 250);
		// window.addEventListener("resize", this.resizeFunc);

		// this.perPage = ViewportService.estimateBTable(120, 104, 46, 45);

		this.sortBy = store.get(this, "teams.sortBy");
		this.sortDesc = store.getDefault(this, "teams.sortDesc", false);
		this.showInactive = store.getDefault(this, "teams.showInactive", true);
	},

	destroyed() {
		window.removeEventListener("resize", this.resizeFunc);
	},

	watch: {
		sortBy() {
			store.set(this, "teams.sortBy", this.sortBy);
		},
		sortDesc() {
			store.set(this, "teams.sortDesc", this.sortDesc);
		},
		showInactive() {
			store.set(this, "teams.showInactive", this.showInactive);
			this.refreshTable();
		},
	},

	methods: {
		provider(ctx) {
			ctx.active = !this.showInactive;
			return TeamService.listTeams(ctx)
				.then((resp) => {
					this.totalRows = resp.data.totalRows;
					this.loading = false;
					return resp.data.teams;
				})
				.catch((err) => {
					console.log(err);
					notie.error(this.$i18n.t("notie.load_teams_fail"), err);
					this.loading = false;
					return [];
				});
		},

		setActive(team) {
			var activeState = (team.active && "active") || "inactive";
			TeamService.saveTeam(team)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.team_set_to", { name: team.name, activeState: activeState }));
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.save_teams_fail"), err);
				});
		},

		removeTeam(team) {
			team.inProgress = true;
			TeamService.removeTeam(team.id)
				.then((resp) => {
					notie.info(this.$i18n.t("notie.team_deleted"));
					this.refreshTable();
					team.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.delete_team_fail"), err);
					team.inProgress = false;
					this.$forceUpdate();
				});
			this.deleting = false;
		},

		refreshTable() {
			this.$refs.table.refresh();
		},
	},
};
</script>
